import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Dialog from '../../components/Dialog';
import ChangeFacilityController from '../controllers/ChangeFacilityController';

class ChangeFacilityDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new ChangeFacilityController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) =>  result.Cancel ? true : this.props.controller.save();

    render = () => {
        var binder = new Binder(this);
        var formatResult = item => {
            var spanSAP = item.result.useSAP ? <span className='user-facility-sap float-right select2-tag'> SAP</span> : null;
            if (item.result.useSAP) {
                return <span>{item.text} {spanSAP}</span>;
            }
            return <span>{item.text}</span>;
        };

        return (
            <Dialog {...this.props} header='Change Facility' width={400} height={210} id='change-facility-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <form role='form'>
                        <Form.AutoComplete formatResult={formatResult} formatSelection={formatResult} focus={true} label='Type to filter the list and select your new facility:' bind={binder.bind('facility')} search={this.props.controller.facilitySearch()} allowClear={false} />
                    </form>
                </Dialog.Body>
                <Dialog.SaveCancelButtons  saving={this.props.controller.saving}/>
            </Dialog>
        );
    }
}

export default ChangeFacilityDialog;