import React, { useEffect } from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Router, useHistory, useLocation } from 'react-router-dom';
import store, { set } from 'store';
import Jumbotron from '../../components/Jumbotron';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Dialog from '../../components/Dialog';
import GroupBadge from '../../components/GroupBadge';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import JobProgressAssetsController from '../controllers/JobProgressAssetsController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import ServiceLevelHelper from '../services/ServiceLevelHelper';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import messages from '../../services/Messages';
import QRSticker from '../components/QRSticker';
import Table from '../../components/Table';
import JobProgressDetailsGroupDialog from '../dialogs/JobProgressDetailsGroupDialog';
import JobSowGroupController from '../controllers/JobSowGroupController';
import Searchers from '../utility/Searchers';
import Dropdown from '../../components/Dropdown';
import ActionIcon from '../../components/ActionIcon';

const FamilyCodeFilter = (props) => {

  const controller = props.controller;
  const binder = props.binder;
  const warningClassName = binder.bind('familyCode')()
    ? 'err-msg'
    : null;
  return (
    <div>
      <Form.AutoComplete bind={binder.bind('familyCode')} prependLabelIcon='fa-filter' label='Family Code' placeholder='family code filter' search={props.controller.familyCodeSearch()} minimumResultsForSearch={-1} enabled={!controller.isLoading} />
    </div>
  );
}

const Groups = (props) => {

  useEffect(() => {
    props.controller.state.multiSelectGroups = '';
    props.controller.commit();
  }, []);


  const handleClick = (groupNumber) => {
    const controller = props.controller;
    controller.toggleGroupSelection(groupNumber);
  }

  const renderGroups = (fnOnClick, groups) => {
    return groups.map(group => {
      const variant = (group.selected) ? 'danger' : 'secondary';
      const groupDisplay = group.value.groupName ? group.value.groupName : group.value.groupNumber;
      return <GroupBadge key={group.value.groupNumber} variant={variant} className={'mr-1'} onClick={() => { fnOnClick(group.value.groupNumber) }} dataToggle="tooltip" dataPlacement="top" title={group.value.groupName}> {groupDisplay}</GroupBadge>;
    });
  }

  const clearGroupSelection = () => {
    props.controller.deselectAllGroups();
  }

  const handleEditGroups = () => {
    const header = 'Group Name Maintenance';
    const controller = props.controller;
    const jobId = controller.state.job.id;
    const jobSOWGroupController = new JobSowGroupController({ jobId });

    Dialog.showDialog(<JobProgressDetailsGroupDialog controller={jobSOWGroupController} header={header} />);
  }

  const binder = props.binder;
  const groups = binder.bind('groups')() || [];
  const distinctGroup = [];
  groups.forEach(group => {
    if (distinctGroup.indexOf(group.value.groupNumber) < 0) {
      distinctGroup.push(group.value.groupNumber);
    }
  });
  const controller = props.controller;
  const groupView = controller.state.isButtonView
    ? <><TransitionGroup component='div' transitionName='grow-shrink' transitionEnterTimeout={300} transitionLeaveTimeout={200} id='group-badges'>
      {renderGroups(handleClick, groups)}
    </TransitionGroup  ></>
    :<><Form.MultiSelect id='group-multiselect' className='groupNumber-select' placeholder='Click here to select Groups' bind={binder.bind('multiSelectGroups')} search={Searchers.groupNumberandNameSearch(controller.state.job.id)} style={{ height: 'auto' }} /></>
  const setGroupView=(viewoption)=>{
    controller.setGroupView(viewoption);
  }
  return (
    <div id='groups' className='form-group col-12'>
      <div className='row'>
        <div className=''><Form.Label name='Groups' prependLabelIcon='fa-filter' /> </div>
        <div className='ml-3'><GroupBadge variant='danger' pill={false} onClick={clearGroupSelection} >Clear Group Selection</GroupBadge></div>
        <div className='ml-1'><GroupBadge icon='fa-cubes' variant='primary' pill={false} onClick={handleEditGroups}>Edit Group Names</GroupBadge></div>
       {controller.state.isButtonView ?<div className='ml-1'><GroupBadge icon='fa-list' variant='secondary'  pill={false} onClick={()=>{setGroupView(false)}}>Dropdown View</GroupBadge></div>:
        <div className='ml-1'><GroupBadge icon='fa-circle' variant='info'  pill={false} onClick={()=>{setGroupView(true)}}>Button View</GroupBadge></div>}
      </div>
      {groupView}
    </div>
  );
}

const Asset = (props) => {

  useEffect(() => {
    const selectedSerial = props.selectedSerial;
    const asset = props.asset;
    if (selectedSerial && asset.primarySerialNumber == selectedSerial) {
      props.controller.toggleSelection(asset);
      document.getElementById(selectedSerial).scrollIntoView(false);
    }
  }, []);

  const toggleSelection = (asset) => {
    props.controller.toggleSelection(asset);
  }

  const getTestStatusIcon = (test) => {
    if (!test || !test.isActive) {
      return <i className={'fas fa-sm fa-question test-status-icon not-started'} />;
    }

    let statusIcon = null;
    switch (test.testResult) {
      case AppConstants.eInspectionTestResult.Failed:
        statusIcon = <i className={'far fa-sm fa-thumbs-down test-status-icon text-danger'} />;
        break;

      case AppConstants.eInspectionTestResult.Passed:
        statusIcon = <i className={'far fa-sm fa-thumbs-up test-status-icon text-success'} />;
        break;

      case AppConstants.eInspectionTestResult.NotPerformed:
        statusIcon = <i className={'fas fa-sm fa-times test-status-icon not-performed'} />;
        break;

      case AppConstants.eInspectionTestResult.TemporarilyFailed:
      case AppConstants.eInspectionTestResult.FailedInspection:
        statusIcon = <i className='far fa-sm fa-thumbs-down test-status-icon temp-failed' />;
        break;

      case AppConstants.eInspectionTestResult.OSRepair:
        statusIcon = <i className='fas fa-sm fa-wrench test-status-icon text-success' />;
        break;

      case AppConstants.eInspectionTestResult.WeldRepair:
        statusIcon = <i className='fas fa-sm fa-wrench test-status-icon text-danger' />;
        break;
    }
    return statusIcon;
  }

  const getWaitingStatus = (asset) => {
    if (!asset) {
      return null;
    }

    let element = null;
    if (asset.isWaitingForReapplyServiceLevels) {
      element = <div><i className='fas fa-clock asset-waiting-reapply-service-levels' title='Waiting for Reapply Service Levels to complete' /></div>;
    }
    else if (asset.isSAPFacility && asset.isWaitingForLockForInspection) {
      element = <div><i className='fas fa-clock asset-waiting-lock-for-inspection' title='Waiting for Lock for Inspection request to complete' /></div>;
    }
    else if (asset.isSAPFacility && !asset.serviceOrderNumber) {
      element = <div><i className='far fa-circle  no-service-order-number' title='No SAP SO # yet' /></div>;
    }
    return element;
  }

  const controller = props.controller;
  const job = props.job;
  const asset = props.asset;
  const selectedSerial = props.selectedSerial;
  let className = ' asset';

  if (asset.selected) {
    className += ' selected';
  }

  const lastTestResult = asset.lastTestResult;
  const testStatus = asset.lastTestResult && asset.lastTestResult.isActive
    ? <span>{lastTestResult.status} &ndash; {lastTestResult && lastTestResult.testedBy} {DateTimeFormatter.formatDate(lastTestResult && lastTestResult.testDate)}</span>
    : <span>{lastTestResult && lastTestResult.status}</span>;
  const testStatusIcon = getTestStatusIcon(asset.lastTestResult);
  const assetStatus = asset.assetStatus;
  let statusColourClass = 'asset-status status' + asset.assetStatus; // e.g.: statusScrapped
  if (assetStatus === 'Weld Repair' || assetStatus === 'Failed Inspection')
    statusColourClass = 'asset-status text-danger'
  if (assetStatus === 'OS/Machining Repair') 
    statusColourClass = 'asset-status statusOSRepair'  
  let rejectReason = asset.rejectReason;
  if (asset.isScrapped || assetStatus === 'Weld Repair') {
    rejectReason = ' – ' + rejectReason;
  }

  let shipped = '';
  let shippedLabel;

  if (asset.hasShipped) {
    shippedLabel = <Badge className='text-danger' variant='info'>S</Badge>;
    const shippedDate = DateTimeFormatter.formatDate(asset.shippedDate);
    if (shippedDate === AppConstants.MinDate) {
      shippedDate = ' «date unknown»';
    }
    shipped = 'Shipped to ' + asset.shipToLocation + ' ' + shippedDate + ' (ticket #' + asset.shippingTicketNumber + ')';
  }

  const distinctTestsForSelectedServiceLevels = ServiceLevelHelper.findDistinctTests(asset.serviceLevels);
  const hasTestsInConflict = distinctTestsForSelectedServiceLevels.reduce((acc, curr) => {
    return acc || ServiceLevelHelper.hasTestsInConflict(asset.serviceLevels, curr);
  }, false);
  const isChanged = asset.transactionId != Utils.emptyGuid;
  const divSAPStatus = job.useSAP
    ? <div className='sap-token'>{'SAP Status: ' + (asset.sapStatus || 'not set')}</div>
    : null;
  const divSAPServiceOrderNumber = job.useSAP
  ?  <div className='sap-token'>SAP SO #:   { asset.serviceOrderNumber != null ? (<span style={{color : isChanged ? "#00008B" : "none" , fontWeight : isChanged ? "bold" : "none"}}> {asset.serviceOrderNumber} </span>) : 'not set'}</div>
    : null;
  const divSAPError = (job.useSAP && asset.sapErrorMessage)
    ? <div className='col-lg-11 sap-error' style={{ fontSize: 'smaller' }}>Error: {asset.sapErrorCode} {asset.sapErrorMessage}</div>
    : null;
  const waitingStatus = getWaitingStatus(asset);

  const divIServError = (job.useSAP && asset.inteservErrorMessage)
    ? <div className='sap-error' style={{ fontSize: 'smaller' }}>Warning: {asset.inteservErrorMessage}</div>
    : null;
  const divEquipmentNumber = <div className='sap-token'>{'Equipment #: ' + (asset.equipmentNumber || 'not set')}</div>;
  const divReceivingTicketNumber = <div className='sap-token'>{'Receiving Ticket #: ' + (asset.receivingTicketNumber || '')}</div>;

  // default in case there are no selected service levels
  let noSelectedServiceLevels;
  if (asset.serviceLevels === null) {
    noSelectedServiceLevels = <li className='service-level-in-conflict'>Service levels not loaded yet</li>;
  }
  else if (!asset.serviceLevels.length) {
    noSelectedServiceLevels = <li className='err-msg'>No service levels available</li>;
  }
  else {
    noSelectedServiceLevels = <li className='service-level-no-conflict'>No service level selected</li>;
  }

  const selectedServiceLevels = (asset.serviceLevels || []).filter(serviceLevel => serviceLevel.performed);
  const serviceLevelList = asset.isWaitingForReapplyServiceLevels
    ? <li className='service-level-no-conflict'>Reapplying service levels ...</li>
    : selectedServiceLevels.length
      ? selectedServiceLevels.map(serviceLevel => {
        return <li key={serviceLevel.jobSOWServiceLevelId} className={hasTestsInConflict ? 'service-level-in-conflict' : 'service-level-no-conflict'}>{serviceLevel.serviceLevelName}</li>;
      })
      : noSelectedServiceLevels;

  let billOfMaterial = null;
  if (SAPService.isSAPFacility()) {
    const selectedServiceLevel = selectedServiceLevels.length && selectedServiceLevels[0];
    if (selectedServiceLevel) {
      const bom = controller.getBillOfMaterial(selectedServiceLevel);
      if (bom) {
        billOfMaterial = <span className='bill-of-material'>{bom.description}</span>;
      }
    }
  }

  const primarySerialNumber = (LoginService.isCoordinator || LoginService.isSAPRep)
    ? <Form.Link to={'/assetdetail/' + asset.assetId} value={asset.primarySerialNumber} />
    : <span className='field-data'>{asset.primarySerialNumber}</span>;
  const assetEditLink = assetStatus == 'Passed' ? assetStatus : <Form.Link className={statusColourClass} to={'/asset/' + asset.assetId + '/jobprogressassets/'+ controller.state.job.id} value={assetStatus} />;
  return (
    <li id={asset.primarySerialNumber} className={'list-group-item' + className} onClick={() => { toggleSelection(asset); }}>
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='d-flex justify-content-start'>
              <div className='mr-1'><Badge>{asset.groupNumber}</Badge>{shippedLabel}</div>
              <div>{primarySerialNumber}</div>
              <div className='ml-1'>{waitingStatus} </div>
            </div>
            {divSAPStatus}
            {divSAPServiceOrderNumber}
            {divEquipmentNumber}
            {divReceivingTicketNumber}
            <ul className='list-group service-level-list' style={{listStyleType: 'none'}}>
              {serviceLevelList}
              {billOfMaterial}
            </ul>
            <div className='shipped-date'>{shipped}</div>
          </div>
          <div className='col-md-8'>
            <div className='field-label'>{asset.description}</div>
            <div className='test'>{testStatusIcon} {testStatus}</div>
            <div id='asset-status' className={statusColourClass}>{assetEditLink}<span className={statusColourClass}>{asset.assetStatus == 'Passed' ? '' : rejectReason}</span></div>
            {divIServError}
          </div>
          {divSAPError}
        </div>
      </div>
    </li>
  );
}

const AssetList = (props) => {

  // let history = useHistory();

  // const handleShipTransfer = (target) => {
  //   const controller = props.controller;
  //   const job = controller.state.job;

  //   // only allow shipping of assets that have not shipped yet and are included in the current family code filter
  //   //const assets     = controller.getShippableAssets();
  //   //store.set(target + '-assets', { job: job, assets: assets });
  //   history.push('/' + target + 'assets/' + job.id);
  // }

  // const handleShip = (target) => {
  //   const controller = props.controller;
  //   const job = controller.state.job;

  //   // only allow shipping of assets that have not shipped yet and are included in the current family code filter
  //   //const assets     = controller.getShippableAssets();
  //   //store.set(target + '-assets', {job: job});
  //   history.push('/' + target + 'assets/' + job.id);
  // }

  // const handleRemove = () => {
  //   props.controller.removeAssets();
  // }

  const handleLockForInspection = (assets) => {
    props.controller.lockForInspection(assets);
  }

  const selectAll = () => {
    props.controller.selectAll();
  }

  const selectNone = () => {
    props.controller.selectNone();
  }

  const cancelLoadAssets = () => {
    props.controller.cancelLoadAssets();
  }
  const handleReceiveAssetAgain = () => {
    const assets = props.controller.getVisibleAssets();
    const selectedAssets = assets.filter(asset => asset.selected);
    props.controller.handleReceiveAssetAgain(selectedAssets);
  }
  const pushFailedAssets = () => {
    props.controller.pushFailedAssets();
  }

  const selectScrapped = () => {
    props.controller.selectScrapped();
  }

  const handleGenerateQRCodes = () => {
    const state = props.controller.state;
    const searchResults = state.assets;
    let qrSerialValues = [];
    const filterSearchResults = searchResults.filter(result => result.selected == true);
    const groupNumbers = state.groups.filter(group => group.selected);
    if (groupNumbers.length || props.controller.state.familyCode) {
      qrSerialValues = filterSearchResults.length ? filterSearchResults : props.controller.getVisibleAssets();
    }
    else {
      qrSerialValues = filterSearchResults.length ? filterSearchResults : searchResults;
    }
    const generatedQRCodes = <div>{qrSerialValues.map(row => <QRSticker qrValue={row} />)}</div>;
    var newWindow = window.open('', '_blank');
    const title = 'QR code'
    newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="css/application.css"> </head><body>');
    ReactDOM.render(generatedQRCodes, newWindow.document.body);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    $(newWindow).on('load', function () {
      newWindow.print();
    });
  }

  const controller = props.controller;
  const job = controller.state.job;
  const assets = controller.getVisibleAssets();
  const shippedAssets =[... new Set(assets.filter(asset => asset.hasShipped).map(x=> x.assetId+''+ x.jobSOWId))];
  const scrappedAssets =[... new Set( assets.filter(asset => asset.lastTestResult && asset.lastTestResult.testResult == AppConstants.eInspectionTestResult.Failed).map(x=> x.assetId+''+ x.jobSOWId))];
  const osRepairAssets = [... new Set(assets.filter(asset => asset.lastTestResult && asset.lastTestResult.testResult == AppConstants.eInspectionTestResult.OSRepair).map(x=> x.assetId+''+ x.jobSOWId))];
  const weldRepairAssets = [... new Set(assets.filter(asset => asset.lastTestResult && asset.lastTestResult.testResult == AppConstants.eInspectionTestResult.WeldRepair).map(x=> x.assetId+''+ x.jobSOWId))];
  const modifiedAssets = assets.filter(asset => asset.transactionId != Utils.emptyGuid);
  let failedAssets = [... new Set (assets.filter(asset => asset.lastTestResult && asset.lastTestResult.testResult == AppConstants.eInspectionTestResult.FailedInspection).map(x=> x.assetId+''+ x.jobSOWId))];
  // const shippableAssets = controller.getShippableAssets();
  // const transferrableAssets = controller.getTransferrableAssets();
  // const removableAssets = controller.getRemovableAssets();
  const lockableAssets = controller.getLockableAssets();
  const selectedAssets = assets.filter(asset => asset.selected);
  // const shipEnabled = (!controller.isLoading && (((job.useSAP && job.isInspecting) || (!job.useSAP && !job.isComplete)) && shippableAssets.length));
  // const transferEnabled = (!controller.isLoading && (((job.useSAP && !job.isComplete) || (!job.useSAP && !job.isComplete)) && transferrableAssets.length));
  // const removeEnabled = (!controller.isLoading && !controller.state.isLockInspInProgress && (removableAssets.length && (removableAssets.length === selectedAssets.length)));
  let modCount = 0;
  const key = 'assetId';
  const modifiedAssetsByAssetId = [...new Map(modifiedAssets.map(item =>
    [item[key], item])).values()];
  const selectedAssetsByAssetId = [...new Map(selectedAssets.map(item =>
  [item[key], item])).values()];
  selectedAssetsByAssetId.forEach(x => {
    var isAvailable = modifiedAssets.filter(y => y.assetId == x.assetId);
    if(isAvailable.length > 0){
      ++modCount;
    }
  })
  const totalAssetModifiedCount = selectedAssets.length > 0 ? modCount : modifiedAssetsByAssetId.length;
  const canReAdd = (!controller.isLoading && !controller.state.isLockInspInProgress && ((job.useSAP && !job.isComplete) || (!job.useSAP && !job.isComplete)) && selectedAssets.length && controller.getReceivableAssets(selectedAssets));
  const lockForInspectionEnabled = job && job.canLockAssetsForInspection && lockableAssets.length && !controller.state.isLockInspInProgress;
  const selectedSerial = props.selectedSerial;
  const canGenerateQR = assets.length;
  assets.sort(function (a, b) {
    // sort shipped assets to the bottom
    if (a.hasShipped !== b.hasShipped) {
      return a.hasShipped ? 1 : -1;
    }

    // first, sort by group number
    if (a.groupNumber !== b.groupNumber) {
      return (a.groupNumber - b.groupNumber);
    }

    // then by serial number
    return a.primarySerialNumber.localeCompare(b.primarySerialNumber);
  });
  const filterIcon = (props.controller.state.familyCode)
    ? <span><i className='fa fa-xs fa-filter' />&nbsp;&nbsp;</span>
    : '';
  const shippedCountLabel = shippedAssets.length + ' shipped';
  const scrappedCountLabel = scrappedAssets.length + ' scrapped';
  const osRepairCountLabel = osRepairAssets.length + ' os/machining repair';
  const weldRepairCountLabel = weldRepairAssets.length + ' weld repair';
  const modifiedAssetsCountLabel = totalAssetModifiedCount + ' modified service order';
  const failedCountLabel = failedAssets.length + ' failed';
  //const selectedLabel = (controller.getSelectedCount() + ' / ' + assets.length + ' selected');
  const familyCode = props.controller.state.familyCode;
  const loadingMessage = controller.isLoading && (job.assetCount > 0) &&
       <CSSTransition classNames='fade' timeout={{ enter: 300, exit: 300 }}><ul className='list-group font-italic'>{'Loaded ' + assets.length + ' of ' + Utils.pluralize('asset', 's', job.assetCount) + ' ...'}</ul></CSSTransition>
  const filteredAssets = assets.filter((value, index, self) =>
       index === self.findIndex((t) => (
         t.jobSOWId === value.jobSOWId && t.assetId === value.assetId
       ))
   );
   const selectedLabel = (controller.getSelectedCount() + ' / ' + filteredAssets.length + ' selected');
   const assetEntries = filteredAssets.map(asset => {
    return (familyCode && (familyCode.id !== asset.familyCodeId))
      ? null
      : <CSSTransition classNames='fade' timeout={{ enter: 300, exit: 300 }}><ul className='list-group w-100'><Asset key={asset.jobSOWId} controller={controller} job={job} asset={asset} selectedSerial={selectedSerial} /></ul></CSSTransition>;
  });
  const noResults = !loadingMessage && !assetEntries && <div className='no-search-results'>No assets on this job</div>;
  /*  var cancelButton             = loadingMessage
                                    ? <Button icon='fa-ban' variant='danger' size='md' className='m-1' onClick={cancelLoadAssets} >Cancel</Button>
                                    : null; */
  const msgIncompleteLoad = (!controller.isLoading && controller.cancelAssetLoading) &&
                            <CSSTransition classNames='fade' timeout={{ enter: 300, exit: 300 }}><ul className='list-group text-danger font-weight-bold'>{'Cancel selected - only ' + assets.length + ' of ' + job.assetCount + ' assets loaded'}</ul></CSSTransition>;
  const isSAPFacility = SAPService.isSAPFacility();

  const failedcount = controller.getFailedCount();

  failedAssets = (isSAPFacility && failedcount > 0 && <span> &nbsp;<Button icon='far fa-circle' variant='danger' size='sm' className='' onClick={pushFailedAssets} >Create Failed Assets SO's ...</Button> </span>);

  return (
    <div className='row'>
      <div className='col-md-12'>
        {LoginService.isCoordinator &&
          <span>
            <Button icon='fa-square' variant='success' size='sm' className='my-1' onClick={selectAll} disabled={controller.isLoading}>Select All</Button>
            &nbsp;
              <Button icon='far fa-square' variant='danger' size='sm' className='my-1' onClick={selectNone} disabled={controller.isLoading}>Select None</Button>
            &nbsp;
              {/* <Button icon='fa-qrcode' variant='primary' size='sm' className='my-1' onClick={handleGenerateQRCodes} disabled={!canGenerateQR}>Print QR</Button> */}
            &nbsp;
            <Button icon='far fa-thumbs-down' variant='info' size='sm' className='m-1' onClick={selectScrapped}>Select Scrapped</Button>
            &nbsp;
              <Button icon='fa-download' variant='success' size='sm' className='my-1' onClick={handleReceiveAssetAgain} disabled={!canReAdd}>Receive Asset Again</Button>
          </span>
        }
      </div>
      <div className='col-md-12 mb-1'>
        <div className='d-flex justify-content-between'>
          <div>
            <Badge variant='info' className='ml-1 defects-count-badge'>{shippedCountLabel}</Badge>
            <Badge variant='danger' className='ml-1'>{scrappedCountLabel}</Badge>
            <Badge variant='info' className='ml-1 defects-count-badge'>{failedCountLabel}</Badge>
            <Badge variant='info' className='ml-1 defects-count-badge'>{osRepairCountLabel}</Badge>
            <Badge variant='info' className='ml-1 defects-count-badge'>{weldRepairCountLabel}</Badge>
            <Badge variant='info' className='ml-1 defects-count-badge'>{modifiedAssetsCountLabel}</Badge>
          </div>
          <div>
            <Badge variant='info' className='ml-1'>{filterIcon}{selectedLabel}</Badge>
          </div>
        </div>
        {/* {cancelButton} */}
        {/* {failedAssets} */}
      </div>
      <div className='col-md-12'>
        <TransitionGroup component='ul' className='asset-list'>
          {loadingMessage}
          {msgIncompleteLoad}
          <div className='row'>{assetEntries}</div>
          {noResults}
        </TransitionGroup>
      </div>

      {LoginService.isCoordinator &&
        <div className='col-12'>
          <div className='d-flex justify-content-between'>
            {/* <div>
              <Button icon='fa-truck fa-flip-horizontal' disabled={!shipEnabled} variant='success' size='sm' className='btn-text-wrap mr-1 mt-1' onClick={() => { handleShip('ship'); }}> Ship ...</Button>
              <Button icon='fa-exchange-alt' disabled={!transferEnabled} variant='warning' size='sm' className='btn-text-wrap mr-1 mt-1' onClick={() => { handleShipTransfer('transfer'); }}> Transfer ...</Button>
            </div>
            <div>
              <Button icon='fa-trash' disabled={!removeEnabled} variant='danger' size='sm' className='btn-text-wrap mr-1 mt-1' onClick={handleRemove}> Remove ...</Button> */}
              <div className='d-block mr-0 ml-auto'>
                {isSAPFacility ? <Button icon='fa-lock' disabled={!lockForInspectionEnabled} variant='primary' size='sm' className='btn-text-wrap mr-1 mt-1' onClick={() => { handleLockForInspection(lockableAssets); }}>Lock for Inspection ...</Button> : null}
              </div>
            {/* </div> */}
        </div>
      </div> }
      </div>
  );
}

const AssetButtons = (props) => {

  let history = useHistory();
  
  const handleReassignDescription = (evt) => {
    evt.preventDefault();

    const asset = props.controller.getSelectedAsset();
    history.push('/jobprogress/' + asset.assetId);

    
    // const job = props.controller.state.job;
    // const customer = {
    //   id: job.customerId,
    //   name: job.customerName
    // };

    // const asset = props.controller.getSelectedAsset();

    // const assetDto = {
    //   customer: customer,
    //   asset: asset
    // };
    // store.set(asset.assetId, assetDto);
    // history.push('/jobprogressasset/' + asset.assetId);
  }

  const handleShipTransfer = (target) => {
    const controller = props.controller;
    const job = controller.state.job;

    // only allow shipping of assets that have not shipped yet and are included in the current family code filter
    //const assets     = controller.getShippableAssets();
    //store.set(target + '-assets', { job: job, assets: assets });
    history.push('/' + target + 'assets/' + job.id);
  }

  const handleShip = (target) => {
    const controller = props.controller;
    const job = controller.state.job;

    // only allow shipping of assets that have not shipped yet and are included in the current family code filter
    //const assets     = controller.getShippableAssets();
    //store.set(target + '-assets', {job: job});
    history.push('/' + target + 'assets/' + job.id);
  }

  const handleGenerateQRCodes = () => {
    const state = props.controller.state;
    const searchResults = [...new Map(state.assets.map(item =>[item["assetId"]+'-'+item["jobSOWId"], item])).values()];
    let qrSerialValues = [];
    const filterSearchResults = searchResults.filter(result => result.selected == true);
    const groupNumbers = state.groups.filter(group => group.selected);
    if (groupNumbers.length || props.controller.state.familyCode) {
      qrSerialValues = filterSearchResults.length ? filterSearchResults : props.controller.getVisibleAssets() ;
    }
    else {
      qrSerialValues = filterSearchResults.length ? filterSearchResults : searchResults;
    }
    
    const generatedQRCodes = <div>{qrSerialValues.map(row => <QRSticker qrValue={row} />)}</div>;
    var newWindow = window.open('', '_blank');
    const title = 'QR code'
    newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="css/application.css"> </head><body>');
    ReactDOM.render(generatedQRCodes, newWindow.document.body);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    $(newWindow).on('load', function () {
      newWindow.print();
    });
  }

  const handleRemove = () => {
    props.controller.removeAssets();
  }

  const controller = props.controller;
  const job = controller.state.job;
  //const canEditDescription = !controller.isLoading && controller.canEditAssetDescription(controller.getSelectedAsset());
  const handleModifiedTrans = () =>{
    const queryData = {
      selectedAssets: [...new Set(selectedAssets.map(x => x.primarySerialNumber))],
      tabKey : '5',
      jobNo: job.jobNumber
    };
    history.push({
      pathname: '/transactions',
      state: { params: queryData }
    });
  }
  const assets = controller.getVisibleAssets();
  const canGenerateQR = assets.length;
  const shippableAssets = controller.getShippableAssets();
  const transferrableAssets = controller.getTransferrableAssets();
  const removableAssets = controller.getRemovableAssets();
  const selectedAssets = controller.getSelectedAssets();
  const asset = controller.getSelectedAsset();
  const removeEnabled = (!controller.isLoading && !controller.state.isLockInspInProgress && (removableAssets.length && (removableAssets.length === selectedAssets.length)));
  const descriptionButtonEnabled = LoginService.isCoordinator && selectedAssets.length > 0 && !controller.isLoading && asset.sapStatus == 'REL';
  const shipEnabled = (!controller.isLoading && (((job.useSAP && job.isInspecting) || (!job.useSAP && !job.isComplete)) && shippableAssets.length));
  const transferEnabled = (!controller.isLoading && (((job.useSAP && !job.isComplete) || (!job.useSAP && !job.isComplete)) && transferrableAssets.length));
  const modifiedAssets = assets.filter(asset => asset.transactionId != Utils.emptyGuid);
  let modCount = 0;
  const key = 'assetId';
  const arrayUniqueByKey = [...new Map(selectedAssets.map(item =>    
  [item[key], item])).values()];  
  arrayUniqueByKey.forEach(x => {
    var isAvailable = modifiedAssets.filter(y => y.assetId == x.assetId && y.jobSOWId == x.jobSOWId);
    if(isAvailable.length > 0){
      modCount += isAvailable.length;
    }
  })
  const modifiedAssetTransactionsCountLabel = selectedAssets.length > 0 ? modCount : modifiedAssets.length;

  return (
    // <div>
    //   <Button icon='fa-retweet' disabled={!canEditDescription} variant='warning' size='sm' className='m-1' onClick={handleReassignDescription}>Reassign Description ...</Button>
    // </div>
<div className="col pr-1">
    <div className="row">
      <Button icon='fa-retweet' disabled={!descriptionButtonEnabled} variant='warning' size='sm' className='m-1' onClick={handleReassignDescription}>Reassign Materials ...</Button>
        <Dropdown.Button variant={'warning'} size={'sm'} className='m-1' title='Actions' >
            <Dropdown.MenuItem icon='fa-qrcode' disabled={!canGenerateQR} onClick={() => { handleGenerateQRCodes() }}>Print QR</Dropdown.MenuItem>
            <Dropdown.MenuItem icon='fa-truck fa-flip-horizontal' disabled={!shipEnabled} onClick={() => { handleShip('ship') }}>Ship</Dropdown.MenuItem>
            <Dropdown.MenuItem icon='fa-exchange-alt' disabled={!transferEnabled} onClick={() => { handleShipTransfer('transfer')}}>Transfer</Dropdown.MenuItem>
            <Dropdown.MenuItem icon='fa-trash' disabled={!removeEnabled} onClick={() => { handleRemove() }}>Remove</Dropdown.MenuItem>
      </Dropdown.Button>
      <div>
       <Button variant='info' size='sm' className='m-1' onClick={() => handleModifiedTrans()} >Modified Transactions 
       
        </Button>
        { 
          modifiedAssetTransactionsCountLabel > 0 && 
         <span class="md-2 mr-1 mt-2 ml-1 badge badge-pill badge-warning">  {modifiedAssetTransactionsCountLabel}</span>
        }
        </div>
    </div>
  </div>
    // <div className="col pr-1">
    // <div className="row">
    // <Button icon='fa-retweet' disabled={!canEditDescription} variant='warning' size='sm' className='m-1' onClick={handleReassignDescription}>Reassign Materials ...</Button>
    //   {/* <Button icon='fa-retweet' disabled={!descriptionButtonEnabled} variant='warning' size='sm' className='m-1' onClick={handleReassignDescription}>Reassign Materials ...</Button>
    //     <Dropdown.Button variant={'warning'} size={'sm'} className='m-1' title='Actions' > */}
    //     <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Actions' >
    //         <Dropdown.MenuItem icon='fa-qrcode' disabled={!canGenerateQR} onClick={() => { handleGenerateQRCodes() }}>Print QR</Dropdown.MenuItem>
    //         <Dropdown.MenuItem icon='fa-truck fa-flip-horizontal' disabled={!shipEnabled} onClick={() => { handleShip('ship') }}>Ship</Dropdown.MenuItem>
    //         <Dropdown.MenuItem icon='fa-exchange-alt' disabled={!transferEnabled} onClick={() => { handleShipTransfer('transfer')}}>Transfer</Dropdown.MenuItem>
    //         <Dropdown.MenuItem icon='fa-trash' disabled={!removeEnabled} onClick={() => { handleRemove() }}>Remove</Dropdown.MenuItem>
    //   </Dropdown.Button>
    // </div>
    // </div>
  );
}

const AssetDetails = (props) => {

  let history = useHistory();

  const navigateToCustomererDescription = (asset) => {
    if (asset.customerDescriptionId) {
      history.push('/customerdescription/' + asset.customerDescriptionId);
    }
  }

  const navigateToCustomerLocation = (customerLocationId) => {
    if (customerLocationId) {
      history.push('/customerlocations/' + customerLocationId);
    }
  }

  const canNavigateToShippingTicket = () => {
    return LoginService.isTechnician;
  }

  const navigateToShippingTicketDetails = (shippingTicketId) => {
    if (shippingTicketId) {
      history.push('/shippingtickets/' + shippingTicketId);
    }
  }

  const getScrapDetails = (asset) => {
    let scrapDetails = '';
    if (asset.scrapDetails) {
      const sh = asset.scrapDetails;
      scrapDetails = ` Failed ${sh.serviceLevelName} :: ${sh.testName} on job ${sh.jobNumber} at ${sh.facilityName} on ${DateTimeFormatter.formatDate(sh.scrapDate)} by ${sh.technician}`;
    }
    return scrapDetails;
  }

  let asset;
  let bornOnDate;
  let receivedDate;
  let shippedDate;
  let shippingTicketElement;
  let statusColourClass;

  const controller = props.controller;
  const selectedCount = controller.getSelectedCount();

  if (selectedCount > 1) {
    asset = '';
  }
  else {
    asset = controller.getSelectedAsset();
    bornOnDate = DateTimeFormatter.formatDate(asset.bornOnDate);
    receivedDate = DateTimeFormatter.formatDate(asset.receivedDate);
    shippedDate = asset.hasShipped ? DateTimeFormatter.formatDate(asset.shippedDate) : '';
    statusColourClass = 'status' + asset.assetStatus; // e.g.: statusScrapped
    if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
      statusColourClass = 'asset-status text-danger'
    if (asset.assetStatus === 'OS/Machining Repair') 
      statusColourClass = 'asset-status statusOSRepair'
    if (asset.shippingTicketNumber === '0') {
      shippingTicketElement = <Form.StaticData label='Shipping Ticket #' />;
    }
    else if (!canNavigateToShippingTicket()) {
      shippingTicketElement = <Form.StaticData label='Shipping Ticket #' value={asset.shippingTicketNumber} />;
    }
    else {
      shippingTicketElement = <Form.Link label='Shipping Ticket #' value={asset.shippingTicketNumber} onClick={() => { navigateToShippingTicketDetails(asset.shippingTicketId); }} />;
    }

    if (shippedDate === AppConstants.MinDate) {
      shippedDate = ' «date unknown»';
    }
  }

  const scrapDetails = getScrapDetails(asset);
  const testStatus = asset.lastTestResult && asset.lastTestResult.status;
  const fieldDescription = LoginService.isAdministrator
    ? <a className='active-link' onClick={() => { navigateToCustomererDescription(asset); }}>{asset.description}</a>
    : <span>{asset.description}</span>;

  let locationElement = <Form.StaticData label='Current Location' value={asset.assetLocation} />;
  if (LoginService.isAdministrator) {
    locationElement = <Form.Link label='Current Location' value={asset.assetLocation} onClick={() => { navigateToCustomerLocation(asset.assetLocationId); }} />;
  }

  return (
    <Jumbotron id='job-progress-assets-details' className='mb-1 col-sm-12' >
      <div className='row'>
        <div className='col-lg-6'><Form.StaticData label='Primary Serial Number' value={asset.primarySerialNumber} /></div>
        <div className='col-lg-6'><Form.StaticData label='Secondary Serial Number' value={asset.secondarySerialNumber} /></div>
      </div>

      <div className='row'>
        <div className='col-lg-6'><Form.StaticData label='Born On Date' value={bornOnDate} /></div>
        <div className='col-lg-6'>{locationElement}</div>
      </div>

      <div className='row'>
        <div className='col-lg-6'><Form.StaticData label='Equipment Number' value={asset.equipmentNumber} /></div>
      </div>

      <Form.StaticData label='Description' value={fieldDescription} />

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Received By' value={asset.receivedBy && asset.receivedBy.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Received Date' value={receivedDate} /></div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Shipped By' value={asset.shippedBy && asset.shippedBy.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Shipped Date' value={shippedDate} /></div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Ship To Location' value={asset.shipToLocation} /></div>
        <div className='col-md-6'>{shippingTicketElement}</div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Test Status' value={testStatus} className='test' /></div>
        <div className='col-md-6'><Form.StaticData label='Scrap Reason' value={asset.rejectReason} className={statusColourClass} /></div>
      </div>

      {scrapDetails &&
        <div className='row'>
          <div className='col-md-12'><Form.StaticData label='Scrap Details' value={scrapDetails} className='statusScrapped' /></div>
        </div>
      }
    </Jumbotron>
  );
}

const ServiceLevels = (props) => {

  let history = useHistory();

  const handleEditServiceLevels = (evt) => {
    evt.preventDefault();
    const controller = props.controller;
    const jobId = controller.state.job.id;
    const jobNumber = controller.state.job.jobNumber;
    const customerName = controller.state.job.customerName;
    const assets = controller.getSelectedAssets().filter(asset => controller.canEditServiceLevels(asset));
    // controller.state.assets.filter(asset => (asset.selected && controller.canEditServiceLevel(asset)));
    const data = { jobId: jobId, jobNumber: jobNumber, customerName: customerName, assets: assets };

    controller.cacheAssets(assets);
    messages.channel('service-levels').action('assign-data').post(data);
    //store.set('select-service-levels', data);
    history.push('/selectservicelevels/' + jobId);
  }

  const handleReapplyServiceLevels = () => {
    props.controller.reapplyServiceLevels();
  }

  const controller = props.controller;
  const distinctServiceLevels = ServiceLevelHelper.findSelectedIntersectingServiceLevels(controller.state.assets);
  const extraServiceLevels = ServiceLevelHelper.findSelectedNonIntersectingServiceLevels(controller.state.assets);
  const selectedAssetCount = controller.getSelectedCount();
  const serviceLevelDisabled = controller.isLoading || !controller.canEditServiceLevels();
  const reapplyServiceLevelsDisabled = controller.isLoading || !controller.canReapplyServiceLevels();
  const distinctServiceLevelBody = (selectedAssetCount === 0)
    ? <Table.Row key={-1}><Table.Data colSpan='4'>No assets selected</Table.Data></Table.Row>
    : distinctServiceLevels.length
      ? distinctServiceLevels.map(serviceLevel => {
        var bom = controller.getBillOfMaterial(serviceLevel);
        var bomValue = bom
          ? <Table.Data>{bom.description}</Table.Data>
          : <Table.Data style={{ fontStyle: 'italic' }}>No Bill of Material</Table.Data>;
        return (
          <Table.Row key={serviceLevel.jobSOWServiceLevelId}>
            <Table.Data>{serviceLevel.serviceLevelName}</Table.Data>
            <Table.Data>{serviceLevel.fmcServiceLevelName}</Table.Data>
            {bomValue}
          </Table.Row>
        );
      })
      : (selectedAssetCount === 1)
        ? <Table.Row><Table.Data colSpan='4' className='no-search-results'>No selected service levels for the selected asset</Table.Data></Table.Row>
        : <Table.Row><Table.Data colSpan='4' className='no-search-results'>No selected service levels in common for the selected assets</Table.Data></Table.Row>;
  const blankLine = (extraServiceLevels.length)
    ? <Table.Row key={-2}><Table.Data colSpan='4'></Table.Data></Table.Row>
    : null;
  const extrasHeader = extraServiceLevels.length
    ? <Table.Row key={-3}><Table.Data colSpan='3' className='extras-header'>Selected service levels not in common for the selected assets</Table.Data></Table.Row>
    : null;
  const extrasBody = (extraServiceLevels.length)
    ? extraServiceLevels.map(serviceLevel => {
      const bom = serviceLevel.billOfMaterial
        ? <Table.Data>{serviceLevel.billOfMaterial}</Table.Data>
        : <Table.Data style={{ fontStyle: 'italic' }}>No Bill of Material</Table.Data>;
      return (
        <Table.Row key={serviceLevel.serviceLevelId}>
          <Table.Data>{serviceLevel.serviceLevelName}</Table.Data>
          <Table.Data>{serviceLevel.fmcServiceLevelName}</Table.Data>
          {bom}
        </Table.Row>
      );
    })
    : null;
  const faEditServiceLevel = props.job.useSAP ? 'far fa-circle' : 'far fa-check-square';

  return (
    <div id='service-levels' className='col-12'>
      <div className='h5'>Selected Service Levels</div>
      <Button icon={faEditServiceLevel} disabled={serviceLevelDisabled} variant='primary' size='sm' className='m-1' onClick={handleEditServiceLevels}>Edit ...</Button>
      <Button icon='fas fa-sync' disabled={reapplyServiceLevelsDisabled} variant='secondary' size='sm' className='m-1' onClick={handleReapplyServiceLevels}>Reapply Service Levels ...</Button>
      <Table className='table table-striped table-bordered table-hover table-sm' >
          <Table.Header>
            <Table.Row>
              <Table.Head className='bg-primary table-dark'>Service Level</Table.Head>
              <Table.Head className='bg-primary table-dark'>FMC Service Level Name</Table.Head>
              <Table.Head className='bg-primary table-dark'>Bill of Material</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {distinctServiceLevelBody}
            {blankLine}
            {extrasHeader}
            {extrasBody}
          </Table.Body>
        </Table>
    </div>
  );
}
const JobProgressAssetsPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class JobProgressAssetsPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    this.load();
  }

  static defaultProps = {
    controller: new JobProgressAssetsController()
  }

  load = () => {
    const jobId = this.props.match.params.jobId;
    if (!jobId) {
      this.props.history.goBack();
    }

    this.props.controller.loadJobAssets(jobId);
    this.props.controller.setGroupView(true);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.load();
    }
  }

  render = () => {
    const controller = this.props.controller;
    const state = controller.state;
    const binder = new Binder(this);
    const job = state.job;
    const jobLink = LoginService.isCoordinator
      ? <Form.Link to={'/inspection/' + job.id} value={job.jobNumber} />
      : <span>{job.jobNumber}</span>;
    const selectedSerial = this.props.match.params.serial || '';

    return (
      <Page pageTitle='InteServ · Job Progress Assets' id='job-progress-assets-page'>
        <JobProgressAssetsPageHeader pageTitle={'Job Progress Details'} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex justify-content-start font-weight-bold h4'>{jobLink} &ndash; {job.customerName} &ndash; {job.customerLocation}</div>
                </div>
              </div>
            </div>

            <div className='col-md-7'>
              <FamilyCodeFilter controller={controller} binder={binder} />
              <Groups controller={controller} binder={binder} />
              <AssetList controller={controller} binder={binder} selectedSerial={selectedSerial} />
            </div>
            <div className='col-md-5'>
              {LoginService.isCoordinator && <AssetButtons controller={controller} />}
              <AssetDetails controller={controller} binder={binder} />
              {LoginService.isCoordinator && <div className='row'><ServiceLevels controller={controller} job={job} /></div>}
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default JobProgressAssetsPage;