import React from 'react';
import { ButtonGroup as RBButtonGroup} from 'react-bootstrap';

const ButtonGroup = (props) => {
    return(
        <RBButtonGroup {...props}>
            {props.children}
        </RBButtonGroup>
    );
}

export default ButtonGroup;