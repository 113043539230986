import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import ComponentUpdateRow from '../pages/ComponentUpdateRow';
import co from '../../lib/Co';
import Table from '../../components/Table';
import MentionsInput from '../components/MentionsInput';
import LoginService from '../services/LoginService';

const ComponentValueTable = (props) => {

    const handleAddNewRow = () => {
        props.controller.addNewRow();
    }
    const controller = props.controller;
    const componentValueRows = controller.state.componentvalues.length > 0
        ? controller.state.componentvalues.map(row => <ComponentUpdateRow key={row.key} asset={controller.state.asset[0]} controller={row} />)
        : <Table.Row><Table.Data colSpan="8" style={{ color: 'Red' }}>No BOM selected. Please add components.</Table.Data></Table.Row>;
    const addNewRow = controller.state.asset && controller.state.asset[0].sapStatus != 'TECO'
        ? <Table.Row>
            <Table.Data colSpan='10'> <Button icon='fa fa-plus-square' variant='success' size='sm' onClick={handleAddNewRow}>Click to add a new transaction</Button></Table.Data>
        </Table.Row>
        : null;
    const fldMessage = controller.state.componentvalues.length > 0
        ? <Table.Row><Table.Data colSpan='10'><div className='notemessage'>*Rows highlighted in RED are deleted entries in SAP</div></Table.Data></Table.Row>
        : null;

    return (
        <div id='ComponentValue-div' className='form-group col-12'>
            <Table id='ComponentValue-table' responsive={false}>
                <Table.Header>
                    <Table.Row>
                        <Table.Head></Table.Head>
                        <Table.Head className='col-minimumReading'>Service Order</Table.Head>
                        <Table.Head className='col-minimumReading'>Operation</Table.Head>
                        <Table.Head style={{ width: '100px' }} className='col-minimumReading'>Part Number</Table.Head>
                        <Table.Head className='col-actualReading'>Reserved</Table.Head>
                        <Table.Head className='col-actualReading'>Issued</Table.Head>
                        <Table.Head className='col-actualReading'>Plant</Table.Head>
                        <Table.Head className='col-minimumReading'>Description</Table.Head>
                    </Table.Row>
                </Table.Header>
                <Table.Body style={{ 'font-size': '12px'}}>
                    {componentValueRows}
                </Table.Body>
                <Table.Footer>
                    {fldMessage}
                    {addNewRow}
                </Table.Footer>
            </Table>
        </div>
    );
}

class ComponentUpdateDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (evt) =>{ 
        // (result.Close || result.OK) ? true : this.props.controller.save();
        if (evt.Save) {
            return this.props.controller.save();
        }
        else if(evt.Email)
        {
           this.props.controller.showEmailScreen(); 
            return false;
        }
        else if(evt.SendEmail)
        {
           this.props.controller.emailPartsList(); 
            return false;
        }
        else {
            return true;
        }
    }
    handleEmailClose=(evt)=>{
        return true;
    }
    mentionsInputChange = (text, usersToNotify, displayText) => {
        this.props.controller.handleNotesChange(text, usersToNotify, displayText);
    }
   
    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const isEmailScreenOpted = controller?.state?.isEmailScreenOpted;
        const actionButtons = (controller.state.componentvalues.length > 0 && controller.state.asset[0].sapStatus != 'TECO') ? <Dialog.SaveCloseButtons saving={this.props.controller.saving} /> : <Dialog.OKButtons />;
        const ComponentValuesTitle = !isEmailScreenOpted ? 'Component Update' : 'Email Parts List';
        const errors = controller.state.errors;

        return (
            
            <Dialog {...this.props} modal={true} width={750} height={!isEmailScreenOpted ? 500 : 300} header={ComponentValuesTitle} id='compupdate-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='ComponentUpdateValues-dlg-body' className='col-sm-12'>
                        <div className='row' style={{ display:'block' }}>
    {
                            !isEmailScreenOpted ?
                            <form role='form'>
                                <ComponentValueTable controller={controller} />
                            </form> : 
                            <div>
                            <MentionsInput onChange = {this.mentionsInputChange} data={LoginService.users || []} value={this.props.controller.state.noteText} />
                            </div>
    }
                        </div>
                    </div>
                </Dialog.Body>
                {/* {actionButtons} */}
      {
      !isEmailScreenOpted ?  <Dialog.Buttons>
           {controller.state.componentvalues.length > 0 ?  <Dialog.Button  variant='primary' size='sm' className='btn' icon='fa-solid fa-envelope'  result={{ Email: true }}>Email Parts List</Dialog.Button> : <></>}
            <Dialog.Button variant='success' size='sm' className='btn' icon='fa-save' result={{ Save: true }}>Save</Dialog.Button>
            <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Close: true }}>Close</Dialog.Button>
        </Dialog.Buttons>
        :
        <Dialog.Buttons>
            <Dialog.Button variant='success' size='sm' className='btn' icon='fa-save' result={{ SendEmail: true }}>Save</Dialog.Button>
            <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Close: true }}>Close</Dialog.Button>
        </Dialog.Buttons>
    }
            </Dialog>
           
        );
    }
}

export default ComponentUpdateDialog;