import store from 'store';
import BaseController from '../../lib/BaseController';
import ReceivingTimeRowController from './ReceivingTimeRowController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService                   from '../services/LoginService';
class ReceivingTimeController extends BaseController {
  constructor(receivingId,canaddTime) {
    super('receivingTime',{
      errors          : {},
      receivingTimeData   : [], 
      receivingId       : receivingId,
      hours:0,
      minutes:0,
      technician:'' ,
      canaddTime:canaddTime  
    });
    this.saving = false;
    this.loadReceivingTime();
  }
  
  // validatereceivingTime(state) {
  //   if (!state.receivingTime.length) {
  //     state.errors.receivingTime = 'At least one row must be specified';
  //     return;
  //   }
  //   state.receivingTime.forEach(row => row.validate());
  // }

  // canSave() {    
  //   this.validatereceivingTime(this.state);    
  //   var errors    = Object.keys(this.state.errors);
  //   var rowErrors = this.state.receivingTime.reduce((acc, curr) => {
  //     return acc + Object.keys(curr.state.errors).length;
  //   }, 0);  
    
  //   if (!errors.length && !rowErrors) {
  //     return true;
  //   }

  //   this.commit();
  //   return false;
  // }

  // addNewReceivingTimeRow = (receivingValue) => {
  //   this.state.receivingTimeData.push(new ReceivingTimeRowController(this, receivingValue));
  //   this.commit();
  // }  

  loadReceivingTime = () => {     
        ajax.get('lookup/getReceivingTime/', { receivingId: this.state.receivingId }).then(userreceivingTime=>{
          this.state.receivingTimeData = userreceivingTime;
          this.commit();
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
      });  
  }

  saveReceivingValues = () => {       
      // if(!this.canSave()){         
      //   return false;
      // }
      // if(!flag){
        const hours   = parseFloat(isNaN(this.state.hours) ? 0 : this.state.hours);
        const minutes = parseFloat(isNaN(this.state.minutes) ? 0 : this.state.minutes);
        const value   = ((60 * (isNaN(hours)?0:hours)) + (isNaN(minutes)?0:minutes));  
        // if(hours>23)
        // {
        //   notification.action('error').post("Hours cannot be more than 23");
        //   return false;
        // }
        // if(minutes>60)
        // {
        //   notification.action('error').post("Minutes cannot be more than 60");
        //   return false;
        // }
        if(value<=0)
        {
            notification.action('error').post("Receiving Time should be greater than 0");
            return false;
        }
        if(parseFloat(value).toFixed(1)<=0)
        {
          notification.action('error').post("After rounding to 1 decimal, time will become 0. Receiving Time should be greater than 0");
          return false;
        } 
        const loginInfo = store.get('InteServLoginInfo');
        const availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
        if(value>availableTime)
        {
            notification.action('error').post("Receiving Time should not be more than Available Production Time");
            return false;
        } 
        if(loginInfo.isBreakOut)
        {
          notification.action('error').post("Currently on Unpaid break");
          return false;
        } 
        this.saving = true;
        this.commit();  
        const parameters = {
          receivingId         : this.state.receivingId,
          TechnicianId: LoginService.loginInfo.userId,
          TransactionDate : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
          Value: value
        };
        ajax.post('receiving/saveTime', parameters).then(response => {
          notification.action('success').post('Saved the receiving Time'); 
          this.saving=false;       
          this.state.hours=0;
          this.state.minutes=0;
          this.state.receivingTimeData=[];
          //messages.channel('productiveTime').action('update').post(response);     
          this.commit();
          this.loadReceivingTime();
          return true;
        }).catch(err => {
          this.saving = false;
          this.commit(); 
          notification.action('error').post({ msg: err.message, title: 'Error' });
      }); 
  } 
}

export default ReceivingTimeController;