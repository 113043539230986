const roles = {
  Administrator: 'Administrator',
  SuperUser: 'Super User',
  Coordinator: 'Coordinator',
  SAPRep: 'SAP ISR',
  JobProgressAsset: 'JobProgressAsset',
  DataClerk: 'Data Clerk',
  Technician: 'Technician'
};

const getRoleClassName = (roleName) => {
  var className = 'user-role ';
  switch (roleName) {
    case roles.Administrator:
      className += 'user-administrator';
      break;

      case roles.SuperUser:
        className += 'user-super-user';
        break;

    case roles.Coordinator:
      className += 'user-coordinator';
      break;

    case roles.SAPRep:
      className += 'user-sap-rep';
      break;

    case roles.Technician:
      className += 'user-technician';
      break;

    default:
      break;
  }
  return className;
}

const Roles = {
  Administrator: roles.Administrator,
  Coordinator: roles.Coordinator,
  SAPRep: roles.SAPRep,
  JobProgressAsset: roles.JobProgressAsset,
  DataClerk: roles.DataClerk,
  Technician: roles.Technician,
  SuperUser: roles.SuperUser,
  getRoleClassName: getRoleClassName
};

export default Roles 