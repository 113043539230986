import React from 'react';
import $ from 'jquery';
import Router, { useHistory } from 'react-router-dom';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Shortcut from '../../components/Shortcut';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import MentionsInput from '../components/MentionsInput';
import NotesController from '../controllers/NotesController';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Note = (props) => {
    const note = props.note;
    const user = note.user;
    const userName = user && user.name;
    const roleName = note && note.roleName;
    const baseDateTime = props.baseDateTime;
    const dateAdded = note.dateAdded;
    const noteDateTime = DateTimeFormatter.formatFullDateTime(dateAdded) + ' UTC';
    let noteAge;
    if (baseDateTime === dateAdded) {
        noteAge = '';
    }
    else {
        const elapsedSeconds = DateTimeFormatter.getMoment(dateAdded).diff(DateTimeFormatter.getMoment(baseDateTime), 'seconds');
        if (elapsedSeconds === 0) {
            noteAge = '';
        }
        else if (elapsedSeconds < 60) {
            noteAge = 'a few seconds later';
        }
        else {
            noteAge = DateTimeFormatter.formatDuration(elapsedSeconds / 60, false) + ' later';
        }
    }

    const isLoggedInUser = LoginService.isLoggedInUser(note.user && note.user.id);
    const classUserName = isLoggedInUser
        ? 'note-user user-current'
        : 'note-user user-other';
    const classRoleName = 'user-role user-' + roleName.toLowerCase();
    const classDate = 'note-date';
    const classText = isLoggedInUser
        ? 'note-text note-current-user'
        : 'note-text note-other-user';
    const text = <div className={classText}>{Utils.makeParagraphElements(note.text)}</div>;
   
    return (
        <div className='row col-12 note-element'>
            <div className='col-md-2' style={{ cursor: 'pointer' }} title={DateTimeFormatter.formatFullDateTime(dateAdded) + ' UTC'}>
                <div className={classUserName}>{userName}</div>
                <div className={classRoleName}>{roleName}</div>
                <div className={classDate}>{noteDateTime}</div>
                <div className={classDate}>{noteAge}</div>
            </div>
            <div className='col-md-10'>{text}</div>
        </div>
    );
}

const NotesInput = (props) => {
    const mentionsInputChange = (text, usersToNotify, displayText) => {
        props.controller.handleNotesChange(text, usersToNotify, displayText);
    }
    const binder = props.binder;
    const controller = props.controller;
    return (
        <div>
            <div style={{ marginTop: '10px' }}>
                <MentionsInput onChange={mentionsInputChange} data={LoginService.users || []} value={controller.state.noteText} />
                {/* <Form.TextArea id='noteText' placeholder='Type new message here' rows={3} bind={binder.bind('noteText')} /> */}
            </div>
        </div>
    );
}

class Notes extends React.Component {
    componentDidMount = () => {
        // const stream = this.props.controller.flash.delay(0);
        // stream.onValue(this.flash);
        // this.dispose = () => stream.offValue(this.flash);
    }

    flash = (noteId) => {
        const notes = $('#all-notes').find('#' + noteId + ' .note-text');
        notes.velocity({ backgroundColor: '#FFD1B2' }, { duration: 600 })
            .velocity('reverse');
    }

    render = () => {
        const data = this.props.notes || [];
        const baseDateTime = data.length && data[0].dateAdded;
        const noNotes = (data.length === 0) ? <div className='col-12 help-block'>No notes have been created yet</div> : null;
        const notes = data.map(note => <CSSTransition><Note key={note.noteId} note={note} baseDateTime={baseDateTime} /></CSSTransition>);
        const canAddNotes = this.props.canAddNotes;
        const displayQuotes = data.length === 0
        ? noNotes
        : notes

        return (
            <div className='col-12'>
                <TransitionGroup component='div' transitionName='fade' transitionEnterTimeout={300} transitionLeaveTimeout={200} id='all-notes'>
                    {displayQuotes}
                </TransitionGroup>
            </div>
        );
    }
}

const AddNotes = (props) => {
    let history = useHistory();
    
    const handleAddNote = () => {
        props.controller.addNote();
    }
    const handleClose = () => {
        history.goBack();
    }
    const canAddNotes = props.canAddNotes;
    const controller = props.controller;
    const binder = props.binder;
    const saveInProgress = controller.state.saveInProgress;

    return (
        <div className='col-12'>
            {canAddNotes && <NotesInput controller={controller} binder={binder} />}
            {!canAddNotes && <div className='col-12 help-block'>This job is closed – you are not able to add to the conversation</div>}
            <div className='mt-2'>
                <div className='d-flex justify-content-end mr-1'>
                    {canAddNotes &&
                        <Shortcut shortcutKey='ctrl+s' preventDefault action={handleAddNote}>
                            <Button id='btn-save' icon='fa-plus-circle' variant='success' size='sm' className='m-1 action-button' disabled={saveInProgress} onClick={handleAddNote} >Add Note</Button>
                        </Shortcut>
                    }
                    <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
                </div>
            </div>
        </div>
    );
}

const NotesPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class NotesPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);       
        this.load();
    }

    load = () => {
        const params = this.props.match.params;
        const id = params.id;
        const noteType = parseInt(params.notetype, 10);
        this.props.controller.initializeController(noteType);
        this.props.controller.load(id);
    }
    
    componentDidMount = () => {
        this.initializeMixin();
        this.demandJobNotes();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.location !== this.props.location) {
            this.load();
          }
    }

    static defaultProps = {
        controller: new NotesController()
    }

    componentWillUnmount = () => {
        this.props.controller.state.noteText = '';
        this.props.controller.commit();
    }

    handleLeftArrow = () => {
        this.props.history.goBack();
    }

    render() {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const title = controller.getTitle();
        const notes = controller.getNotes();
        const canAddNotes = controller.canAddNotes();

        return (
            <Page {...this.props} pageTitle='InteServ · Job Notes' id='notes-page'>
                <NotesPageHeader pageTitle={title} />
                <Page.Content>
                    <div className='row'>
                        <AddNotes controller={controller} binder={binder} canAddNotes={canAddNotes} />
                        <Notes controller={controller} binder={binder} notes={notes} canAddNotes={canAddNotes} />
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default NotesPage;