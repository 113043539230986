import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import Utils from '../utility/Utils';
import messages from '../../services/Messages';
import co from '../../lib/Co';

class TransferToIdleAssetsController extends BaseController {
  constructor(customerId, facilityId, assets, boneyards) {
    super('transfer-to-idle-assets', {
      customerId: customerId,
      facilityId: facilityId,
      assets: assets,
      boneyards: boneyards,
      selectedBoneyard: '',
      receivingLocation: '',
      costCenter: '',
      removeFromContainer: false,
      errors: {},
      saving: false
    });

    this._validator = new Validator();
    this._validator.add('selectedBoneyard', 'Idle Assets or Program Iron Location', [{ required: true }]);
    this._validator.add('receivingLocation', 'Receiving Location', [{ required: true }]);
  }

  receivingLocationSearch = () => {
    const self = this;
    return function* (searchTerm) {
      var parameters = {
        customerId: self.state.customerId,
        facilityId: self.state.facilityId,
        searchTerm: searchTerm
      };
      const results = yield ajax.get('lookup/searchCustomerLocationsForFacility', parameters); // IList<CustomerLocationLookupDto>
      return results;
    };
  }

  canTransferToIdleAssets = () => {
    let success = true;

    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (errors.length > 0) {
      success = false;
    }

    if (!this.state.selectedBoneyard) {
      notification.action('warning').post('You must select an Idle Assets or Program Iron Location');
      success = false;
    }

    if (!this.state.receivingLocation) {
      notification.action('warning').post('You must select a Receiving Location');
      success = false;
    }

    return success;
  }

  getDestination = () => {
    const boneyardName = this.state.selectedBoneyard.name;
    if (Utils.contains(boneyardName, 'program')) {
      return 'Program Iron';
    }
    else if (Utils.contains(boneyardName, 'Staged')) {
      return 'Staged';
    }
    return 'Idle Assets';
  }

  save = () => {
    if (!this.canTransferToIdleAssets()) {
      return false;
    }
    const containerAssets = this.state.assets.filter(asset => asset.container && asset.container.id != Utils.emptyGuid);
    const state = this.state;
    const destination = this.getDestination();

    const parameters = {
      customerId: state.customerId,
      facilityId: state.facilityId,
      boneyardId: state.selectedBoneyard.id,
      receivingLocationId: state.receivingLocation.id,
      removeFromContainer: state.removeFromContainer,
      costCenter: state.costCenter.id,
      assets: state.assets.map(asset => {
        return {
          assetId: asset.assetId,
          customerId: asset.customer.id,
          currentLocationId: asset.currentLocation.id,
          primarySerialNumber: asset.primarySerialNumber,
          eAssetStatus: asset.eAssetStatus,
          plantCode: asset.plantCode,
          equipmentNumber: asset.equipmentNumber
        };
      })
    };
    this.saving = true;
    this.commit();
    return ajax.post('receiving/transferToIdleAssets', parameters).then(result => {
      this.saving = false;
      this.commit();
      this.channel.action('saved').post();

      let assetText = ' asset';
      if (this.state.assets.length !== 1) {
        assetText += 's';
      }
      if (containerAssets.length > 0) {
        messages.channel('load-container-assets').action('reload').post();
      }
      const msg = 'Transferred ' + this.state.assets.length + assetText + ' to ' + this.state.selectedBoneyard.name;
      notification.action('info').post({ msg: msg, title: 'Transfer to ' + destination + ' Complete' });
      return true;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default TransferToIdleAssetsController;
