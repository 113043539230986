import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import FileNameRowController from './FileNameRowController';

class AwoAttachmentController extends BaseController {
    constructor() {
        super('awo-attachment', AwoAttachmentController.getInitialState());

        this.adding = false;
        
        
    }

    static getInitialState = () => {
        const initialState = {
            files: [],
            filelink :  `${process.env.REACT_APP_API_SSL}://${process.env.REACT_APP_API_HOST_NAME}${process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : ''}/${process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : ''}${process.env.REACT_APP_API}/lookup/downloadawoattachments/`,
            awoDetails : null
        };
        return initialState;
    }

    load = (awoId) => {
        if (awoId === Utils.emptyGuid) {
            this.adding = true;
            this.state = AwoAttachmentController.getInitialState(awoId);
            this.commit();
        }
        else {
            this.adding = false;
            this.state.files = [];            
            this.commit();
            co(this, function* () {
                var results = yield ajax.get('lookup/getawoattachments', { awoId: awoId });                
                this.state.files = results;
                this.commit();                
              
            });
        }
    }
    removeattachment = (docId,awoId)=>{
        co(this, function* () {
            var results = yield ajax.get('awoprogess/removeattachment', { docId: docId });                
            if(results)
            {
                    const message = `Attachment removed successfully`
                    notifications.action('success').post(message);
                    this.load(awoId);
            }
        });
       
    }

    uploadFile = (res , awoId) => {
        
        if (this.state.files.find(f => f.FileName === res.file.name)) {
            notifications.action('error').post({ msg: 'File name already exists', title: 'File Error' });
            return;
        }
        if (res && (res.file.size / 1048576).toFixed(1) < 25) {
            let name = '';
            if (navigator.userAgent.match(/iPad/i) != null) {
                //name = res.file.name.split('.')[0] + '_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' }) + '.' + res.file.name.split('.')[1].toLowerCase();               
                name = res.file.name;
            }
            else {
                name = res.file.name;
            }
            const newFile = {                
                AwoId : awoId,
                FileName : name,
                FileData : res.imageUrl
            };
            co(this, function* () {
                var results = yield ajax.post('awoprogess/addawoattachment', newFile);                
                if(results)
                {
                        const message = `Attachment added successfully`
                        notifications.action('success').post(message);
                        this.load(awoId);
                }
                else{
                    notifications.action('error').post({ msg: 'Unable to add attachment', title: 'Upload Error' });  
                }
            });
            
        }
        else {
            notifications.action('error').post({ msg: 'File size is larger that 25 MB', title: 'File Error' });
        }
        
    }
    getAWODetails = (awoId)=>{
        co(this, function* () {
            var results = yield ajax.get('lookup/awo/getawo', { awoId: awoId });
            if(results){
                console.log(results);
                this.state.awoDetails = results;
                this.commit(); 
            }
        });
    }
}

export default AwoAttachmentController;
