import BaseController from '../../lib/BaseController';
import DimensionValueRowController from './DimensionValueRowController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import Utils from '../utility/Utils';

class DimensionValuesController extends BaseController {
  constructor(asset, isViewMode) {
    super('dimensionValues', {
      errors: {},
      dimensionValues: [],
      dimensionValuesId: Utils.emptyGuid,
      asset: asset,
      isViewMode: isViewMode
    });
    this.saving = false;
    this.loadMinimumDimensionReading();
  }

  validatedimensionValues = (state) => {
    if (!state.dimensionValues.length) {
      state.errors.dimensionValues = 'At least one row must be specified';
      return;
    }
    state.dimensionValues.forEach(row => row.validate());
  }

  canSave = () => {
    this.validatedimensionValues(this.state);
    var errors = Object.keys(this.state.errors);
    var rowErrors = this.state.dimensionValues.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!errors.length && !rowErrors) {
      return true;
    }

    this.commit();
    return false;
  }

  addNewRow = () => {
    this.state.dimensionValues.push(new DimensionValueRowController(this));
    this.commit();
  }

  addNewDimensionReadingRow = (dimensionValue) => {
    this.state.dimensionValues.push(new DimensionValueRowController(this, dimensionValue));
    this.commit();
  }

  removeRow = (dimensionValues) => {
    var idx = this.state.dimensionValues.indexOf(dimensionValues);
    if (idx === -1) {
      return;
    }
    this.state.dimensionValues.splice(idx, 1);
    this.commit();
  }

  loadMinimumDimensionReading = () => {
    const asset = this.state.asset;
    ajax.get('lookup/getDimensionValues/', { assetId: asset.assetId, manDescId: asset.manufacturerDescriptionId, jobsowId: asset.jobSOWId}).then(assetDimensionValues => {
      if (assetDimensionValues != null) {
        var dimensionValues = assetDimensionValues.dimensionValueList;
        this.state.dimensionValuesId = assetDimensionValues.dimensionValuesId;
        dimensionValues.forEach(dimensionValue => this.addNewDimensionReadingRow(dimensionValue));
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  saveDimensionValues = () => {
    if (!this.canSave()) {
      return false;
    }
    const parameters = {
      assetId: this.state.asset.assetId,
      dimensionValuesId: this.state.dimensionValuesId,
      dimensionValueList: this.state.dimensionValues.map(dimensionValue => dimensionValue.state),
      jobsowId: this.state.asset.jobSOWId
    };
    this.saving=true;
    this.commit();
    return ajax.post('inspection/saveDimensionValues', parameters).then(response => {
      this.saving=false;
      this.commit();
      notification.action('success').post('Saved the dimension reading');
      return response;
    }).catch(err => {
      this.saving=false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default DimensionValuesController;