import React from 'react';
import Form from './Form';

const buildDate = (binder, props) => {
    return <Form.Date className={props.className} label={props.label} bind={binder.bind(props.name)} />;
}

const buildInput = (binder, props) => {
    return <Form.Input className={props.className} label={props.label} bind={binder.bind(props.name)} />;
}

const buildCheckbox = (binder, props) => {
    return <Form.CheckBox className={props.className} label={props.label} bind={binder.bind(props.name)} />;
}

const buildAutoComplete = (controller, binder, props) => {
    return <Form.AutoComplete className={props.className} label={props.label} bind={binder.bind(props.name)} search={controller.searchLookupList(props.search)} />;
}  

const buildControl = (controller, binder, props) => {
  switch (props.type) {
    case 'checkbox':
      return buildCheckbox(binder, props);

    case 'input':
      return buildInput(binder, props);

    case 'autocomplete':
      return buildAutoComplete(controller, binder, props);

    case 'date':
      return buildDate(binder, props);

    default:
      return null;
  }
}

export default buildControl;
