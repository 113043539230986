import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class EditCustomerController extends BaseController {
  constructor() {
    super('edit-customer', EditCustomerController.getInitialState(''));

    this.adding = false;
    this.isSaveInProgress = false;
    this._validator = new Validator();
    this._validator.add('customerName', 'Customer Name', [{ required: true }]);
  }

  static getInitialState = (customerId) => {
    return {
      customerId: customerId,
      customerName: '',
      sapCustomerName: '',
      isRevenueGenerating: true,
      archived: false,
      failInspection: false,
      notes: '',
      errors: {},
      isSaving: false,
      allowEquipmentStatusUpdate: false,
      multiRejectCapable: false,
      gaugeManagement:true,
      showExpirationDate: false,
      files: []
    };
  }

  load = (customerId) => {
    if (customerId === Utils.emptyGuid) {
      this.adding = true;
      this.state = EditCustomerController.getInitialState(customerId);
      this.commit();
    }
    else 
    {
      this.adding = false;
      ajax.get('lookup/getCustomer/', { customerId: customerId }).then(results => {
        this.merge(results);
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // UpdateCustomerDto
    }
  }

  merge = (customer) => {
    if (!customer) {
      return;
    }

    this.state.customerId                 = customer.customerId;
    this.state.customerName               = customer.customerName;
    this.state.sapCustomerName            = customer.sapCustomerName;
    this.state.isRevenueGenerating        = customer.isRevenueGenerating;
    this.state.archived                   = customer.archived;
    this.state.failInspection             = customer.failInspection;
    this.state.notes                      = customer.notes;
    this.state.allowEquipmentStatusUpdate = customer.allowEquipmentStatusUpdate;
    this.state.multiRejectCapable         = customer.multiRejectCapable;
    this.state.gaugeManagement            = customer.gaugeManagement;
    this.state.showExpirationDate         = customer.showExpirationDate;
    this.state.errors = {};
  }

  canSave = () => {
    this._validator.validateAll(this.state);

    var errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }

  save = (cb) => {
    if (!this.canSave()) {
      return;
    }

    const s = this.state;
    const customerId = s.customerId;
    const parameters = {
      customerId:             customerId,
      customerName:           s.customerName,
      sapCustomerName:        s.sapCustomerName,
      isRevenueGenerating:    s.isRevenueGenerating,
      archived:               s.archived,
      failInspection:         s.failInspection,
      notes:                  s.notes,
      allowEquipmentStatusUpdate: s.allowEquipmentStatusUpdate,
      showExpirationDate:     s.showExpirationDate,
      multiRejectCapable:         s.multiRejectCapable,
      gaugeManagement:            s.gaugeManagement
    };
    this.state.isSaving = true;
    this.commit();
    ajax.post('customer/save', parameters).then(() => {
      this.state.isSaving = false;
      this.commit();
      if (this.adding) {
        notification.action('success').post('Saved new Customer');
      }
      else {
        notification.action('success').post('Saved changes for ' + s.customerName);
      }

      // use in CustomerController to re-select edited form
      // if it's a new form, it will be the 1st entry in the list
      const postData = this.adding ? null : customerId;
      messages.channel('edit-customer').action('saved').post(postData);
      cb();
    }).catch(err => {
      this.state.isSaving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: UpdateCustomerDto, out: void
  }
}

export default EditCustomerController;