import React from 'react';
import _ from 'immutable';
import $ from 'jquery';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import TransferAssetsToContainerController from '../controllers/TransferAssetsToContainerController';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import TransferAssetsToContainerDialog    from '../dialogs/TransferAssetsToContainerDialog';
import emitter from '../utility/Emitter';
import ChangeAssetOwnershipDialog   from '../dialogs/ChangeAssetOwnershipDialog';
import ChangeAssetOwnershipController from '../controllers/ChangeAssetOwnershipController';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import ReceivedAssetDailogController from './ReceivedAssetDailogController';
import ReceiveAssetDialog from '../dialogs/ReceiveAssetDialog';
import store from 'store';
class SerializedAssetSearchController extends BaseController {
    constructor() {
        super('asset-search', SerializedAssetSearchController.getInitialState(''));
        const self   = this;
        this.flash = emitter();
        this._validator = new Validator();
    
        messages.channel('login').action('loggedin').subscribe(data => {
            if (data) {
                this.setCustomer();
            }
            else {
                this.clearSearch();
                this.state.errors     = [];
                this.state.searchResults     = [];
                this.state.generatedQRCodes  = [];
                this.state.totalResultsCount = 0;
            }
        });

        messages.channel('receiving').action('unable-to-receive-asset').subscribe(data => {
            if (!data || !data.assetId) {
                return;
            }
            const assets = this.state.searchResults.filter(result => result.assetId === data.assetId);
            if (!assets.length) {
                return;
            }
            const asset               = assets[0];
            asset.cannotReceive       = true;
            asset.reasonCannotReceive = data.msg;
            this.commit();
        });

        messages.channel('receiving').action('received-asset').subscribe(assetId => {
            if (!assetId) {
                return;
            }
            const assets = this.state.activeTab == 4
                         ?this.state.selectedAssets.filter(result => result.assetId === assetId)
                         :this.state.searchResults.filter(result => result.assetId === assetId);
                         
            if (!assets.length) {
                return;
            }
            const asset                  = assets[0];
            asset.onReceivedAssetsList = true;
            asset.selected             = false;
            asset.cannotReceive        = false;
            delete asset.reasonCannotReceive;
            notification.action('info').post({msg: 'Added asset ' + asset.primarySerialNumber + ' to Received Assets list'});
            this.commit();
        });

        messages.channel('update-cyclecount').action('saved').subscribe(assetId => {
          if (!assetId) {
            return;
          }
    
          const assets = this.state.searchResults.filter(result => result.assetId === assetId);
          if (!assets.length) {
            return;
          }    
          const asset                 = assets[0];
          ajax.get('lookup/searchAssetsById', { assetId:assetId }).then(updatedAsset=>{
            $.extend(asset, updatedAsset.searchResults[0]);
            this.commit();
            this.flash.emit(updatedAsset.searchResults[0].assetId);
          }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
          });
        });      
    }

    static getInitialState = () => {
      return {
        customer                      : '',
        familyCode                    : '',
        manufacturer                  : '',
        nominalSize                   : '',
        cwp                           : '',
        degree                        : '',
        id                            : '',
        od                            : '',
        length                        : '',
        style                         : '',
        service                       : '',
        mfgItemNo                     : '',
        status                        : '',
        container                     : '',
        customerLocation              : '',
        currentLocation               : '',
        shippingTicket                : '',
        primarySerialNumber           : '',
        secondarySerialNumber         : '',
        batchNumber                   : '',
        manufacturerDescriptionTokens : '',
        searchResults                 : [],
        selectedResult                : '',
        totalResultsCount             : 0,
        activeTab                     : 1,
        showSearchCriteria            : true,
        equipmentNumber               : '',
        generatedQRCodes              : [],
        qrTabSearchTerm               : '',
        assets                        : [],
        selectedAssets                : [],
        groups                        : [],
        jobNumbers                     : '',
        plannedStartDate              : '',
        cycleFromDate                 : '',
        cycleToDate                   : '',
        so                            : '',
        po                            : '',
        groupNumber                   : '',
        groupName                     : '',
        soStatus                      : '',
        equipmentStatus               : '',
        errors                        : {},
        assetMgmtSearchResults        : [],
        transferErrors                : [],
        customerJob                   : '',
        plantCode                     : '',
        functionalLocation            : '',
        objectType                    : '',
        costCenter                    : '',
        assetNumber                   : '',
        equipmentsStatus              : '',
        mfgItemNos                    : '',
        imgCount                      : 0,
        activeTabName                 : 'simple',
        awoNumbers: '',
      };
    }

  setCustomer = () => { 
    if (!LoginService.isCustomer) {
      return;
    }

    this.state.customer = {
      id  : LoginService.loginInfo && LoginService.loginInfo.customerId,
      name: LoginService.loginInfo && LoginService.loginInfo.customerName
    };
  }

  toggleSearchCriteriaVisibility = () => {
    this.state.showSearchCriteria = !this.state.showSearchCriteria;
    this.commit();
  }

  customerChanged = () => {
    this.state.customerLocation  = '';
    this.state.currentLocation   = '';
    this.state.searchResults     = [];
    this.state.totalResultsCount = 0;
    this.state.generatedQRCodes  = [];
    this.state.jobNumbers         = '';
    this.state.awoNumbers = '';
  }

  jobNumberChanged = () => {
    this.state.soStatus = '';
    this.state.groupNumber = '';
    this.state.awoNumbers = '';
  }

  canMultiSelect = () => {
    return (!LoginService.isCustomer && this.isCustomerSelected());
  }

  toggleSelection = (item) => {
    let selectedAssets = this.getSelectedAssets();
    if (!this.canMultiSelect()) {
      if (selectedAssets.length) {
        selectedAssets[0].selected = false;
      }
      item.selected = true;
    }
    else {
      item.selected = !item.selected;
      if (selectedAssets.length === 10) {
        notification.action('warning').post({ title: 'Maximum Selection Exceeded', msg: 'A maximum of 10 assets can be selected at one time to be received' });
        item.selected = false;
      }
    }
    this.commit();
  }

  clearSelection = () => {
    if (this.state.activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement) {
      this.state.assetMgmtSearchResults.forEach(a => a.selected = false);
    }
    else {
      this.state.searchResults.forEach(a => a.selected = false);
    }
    this.commit();
  }

  selectAll = () => {
    if (this.state.activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement) {
      this.state.assetMgmtSearchResults.forEach(a => a.selected = true);
    }
    else {
      this.state.searchResults.forEach(a => a.selected = true);
    }
    this.commit();
  }

  getSelectedAssets = () => {
    const searchResults = this.state.activeTabName == AppConstants.SerializedAssetSearchTab.QRCode
                        ? this.state.selectedAssets || []
                        : this.state.activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement
                        ? (this.state.assetMgmtSearchResults || []).filter(a => a.selected)
                        : (this.state.searchResults || []).filter(a => a.selected)
    return searchResults;
  }

  setActiveTab = (tabNumber) => {
    this.state.activeTab = tabNumber;
    this.commit();
    if (this.state.activeTabName == AppConstants.SerializedAssetSearchTab.MassTransfer){
      this._validator.add('customer', 'Customer', [{required: true}]);
    }
    else {
      this._validator.remove('customer');
      this.state.errors = {};
    }
    this.commit();
  }

  tabShow = (tabHash) => {

    this.state.activeTabName = tabHash;
    switch (tabHash) {

      case 'simple':
        this.state.activeTab = 1;
        break;
      case 'customer':
        this.state.activeTab = 2;
        break;
      case 'advanced':
        this.state.activeTab = 3;
        break;
      case 'qr':
        this.state.activeTab = 4;
        break;
      case 'assetManagement':
        this.state.activeTab = 5;
        break;
      case 'transfer':
        this.state.activeTab = 6;
        break;
    }
    this.commit()
  }

  setMfgItemNoDisplayValue = (result) => {
    const mfgItemNo = result.mfgItemNo && result.mfgItemNo.name;
    result.mfgItemNoDisplayValue = (!mfgItemNo || mfgItemNo.startsWith('N/A')) ? '' : mfgItemNo;
  }

  clearSearch = () => {
    this.state.customer                      = '';
    this.state.familyCode                    = '';
    this.state.manufacturer                  = '';
    this.state.nominalSize                   = '';
    this.state.cwp                           = '';
    this.state.degree                        = '';
    this.state.id                            = '';
    this.state.od                            = '';
    this.state.length                        = '';
    this.state.style                         = '';
    this.state.service                       = '';
    this.state.mfgItemNo                     = '';
    this.state.status                        = '';
    this.state.container                     = '';
    this.state.customerLocation              = '';
    this.state.currentLocation               = '';
    this.state.shippingTicket                = '';
    this.state.primarySerialNumber           = '';
    this.state.secondarySerialNumber         = '';
    this.state.batchNumber                   = '';
    this.state.manufacturerDescriptionTokens = '';
    this.state.equipmentNumber               = '';
    this.state.qrTabSearchTerm               = '';
    this.state.selectedAssets                = [];
    this.state.assets                        = [];
    this.state.groups                        = [];
    this.state.jobNumbers                     = '';
    this.state.cycleFromDate                 = '';
    this.state.cycleToDate                   = '';
    this.state.so                            = '';
    this.state.po                            = '';
    this.state.groupNumber                   = '';
    this.state.groupName                     = '';
    this.state.soStatus                      = '';
    this.state.equipmentStatus               = '';
    this.state.errors                        = {};
    this.state.transferErrors                = [];
    this.state.customerJob                   = '';
    this.state.plantCode                     = '';
    this.state.functionalLocation            = '';
    this.state.objectType                    = '';
    this.state.costCenter                    = '';
    this.state.assetNumber                   = '';
    this.state.assetMgmtSearchResults        = [];
    this.state.searchResults                 = [];
    this.state.totalResultsCount             = 0;
    this.state.equipmentsStatus              = '';
    this.state.mfgItemNos                    = '';
    this.state.imgCount                      = 0;
    this.state.awoNumbers = '';
    
    $('.input-select').select2('data', []);
    $('.jobNumber-select').select2('data', []);
    $('.awoNumber-select').select2('data', []);
    this._validator.remove('cycleFromDate');
    this._validator.remove('cycleToDate');
    this.setCustomer();
    this.commit();
  }

  handleRfidSearch = (value) => {
    let serialNumber = { value: '' };
    if (!Utils.tryProcessAsRfidTag(value, serialNumber)) {
      return;
    }
    const parameters = {
      rfidValue: value
    };

    this.state.totalResultsCount = 0;
    this.state.searchResults = [];
    this.state.generatedQRCodes = [];
    this.state.isLoading = true;
    ajax.get('lookup/searchAssetsRfid', parameters).then(results => {
      if (results.totalResultsCount > 0) {
        this.clearSearch();
        this.state.primarySerialNumber = results.searchResults[0].primarySerialNumber;
      }
      this.processSearchResults(results);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  handleSearch = () => {
    switch (this.state.activeTabName) {
      case AppConstants.SerializedAssetSearchTab.Simple:
        this.handleSimpleSearch();
        return;

      case AppConstants.SerializedAssetSearchTab.Customer:
        this.handleCustomerSearch();
        return;

      case AppConstants.SerializedAssetSearchTab.Advanced:
        this.handleAdvancedSearch();
        return;

      case AppConstants.SerializedAssetSearchTab.QRCode:
        this.handleQRcodeSearch();
        return;
      
      case AppConstants.SerializedAssetSearchTab.AssetManagement:
        this.handleAssetManagementSearch();
        return;

      case AppConstants.SerializedAssetSearchTab.MassTransfer:
        this.handleMassTransferSearch();
        return;

      default:
        // should never get here
        this.handleSimpleSearch();
        return;
    }
  }

  validateSearch = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
    let errors = Object.keys(this.state.errors);
    this.commit();
    if (errors.length > 0) {
      return false;
    }
    if (this.state.cycleFromDate && this.state.cycleToDate) {
      const fromDate = new Date(this.state.cycleFromDate);
      this._validator.add('cycleToDate', 'To date must be greater than or equal to From date.', [{ isGreaterThanEqualsDate: { params:  fromDate}}]);
      this.commit();
      this._validator.validateAll(this.state);
      const dateErrors = Object.keys(this.state.errors);
      this.commit();
      if (dateErrors.length > 0) {
        return false;
      }
    }   
    return true;
  }

  handleSimpleSearch = (exportType = null, downloadToExcel = false) => {
      var parameters = {
        primarySerialNumber           : this.state.primarySerialNumber,
        secondarySerialNumber         : this.state.secondarySerialNumber,
        equipmentNumber               : this.state.equipmentNumber,
        manufacturerDescriptionTokens : this.state.manufacturerDescriptionTokens
      };
      if (!downloadToExcel){
        this.state.totalResultsCount = 0;
        this.state.searchResults     = [];
        this.state.generatedQRCodes  = [];
        this.state.isLoading         = true;
        ajax.get('lookup/searchAssetsSimple', parameters).then(results => {
          this.processSearchResults(results);
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }
      else{
        parameters.activeTab = this.state.activeTab;
        parameters.exportType = exportType;
        const selectedAssets = this.getSelectedAssets();
        if(exportType === 'Export Selected' && !selectedAssets.length){
          return;
        }
        parameters.selectedAssetIds = selectedAssets.map(asset => asset.assetId);
        ajax.post('lookup/exportSerializedAssetsAsExcel', parameters).then(excelData => {
          this.export(excelData);
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }
  }

  isCustomerSelected = () => {
    return (this.state.customer && this.state.customer.id);
  }

  handleCustomerSearch = (exportType = null, downloadToExcel = false) => {
    if (!this.isCustomerSelected()) {
      notification.action('warning').post({msg: 'You must first select a customer', title: 'Customer Selection Required'});
    }
    let groupNumber = '';
    if(this.state.groupNumber){
      const groupNumberIndex = this.state.groupNumber.name.indexOf('-');
      groupNumber = groupNumberIndex >=0 
                    ? this.state.groupNumber.name.substring(0,groupNumberIndex-1) 
                    : this.state.groupNumber.name;
    }
    const parameters = {
        customerId         : this.state.customer         ? this.state.customer.id                          : Utils.emptyGuid,
        customerLocationId : this.state.customerLocation ? this.state.customerLocation.id                  : Utils.emptyGuid,
        currentLocationId  : this.state.currentLocation  ? this.state.currentLocation.id                   : Utils.emptyGuid,
        jobNumberIds       : this.state.jobNumbers       ? this.state.jobNumbers.map(number => number.id)  : '',
        container          : this.state.container,
        groupNumber        : groupNumber,
        soStatus           : this.state.soStatus ? this.state.soStatus.name : '',
      };
      if(!downloadToExcel){
        this.state.totalResultsCount = 0;
        this.state.searchResults     = [];
        this.state.generatedQRCodes  = [];
        this.state.isLoading         = true;
        ajax.post('lookup/searchAssetsCustomer', parameters).then(results=>{
          this.processSearchResults(results);
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }
      else{
        parameters.activeTab = this.state.activeTab;
        parameters.exportType = exportType;
        const selectedAssets = this.getSelectedAssets();
        if(exportType === 'Export Selected' && !selectedAssets.length){
          return;
        }
        parameters.selectedAssetIds = selectedAssets.map(asset => asset.assetId);
        ajax.post('lookup/exportSerializedAssetsAsExcel', parameters).then(excelData=>{
          this.export(excelData);
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        }); 
      }
  }

  handleAdvancedSearch = (exportType = null, downloadToExcel = false) => {
    if (!this.validateSearch()) {
      return;
    }
    let groupNumber = '';
    let equipmentStatus = '';
    if (this.state.groupNumber) {
      const groupNumberIndex = this.state.groupNumber.name.indexOf('-');
      groupNumber = groupNumberIndex >= 0
        ? this.state.groupNumber.name.substring(0, groupNumberIndex - 1)
        : this.state.groupNumber.name;
    }

    if (this.state.equipmentStatus) {
      const equipmentStatusIndex = this.state.equipmentStatus.name.indexOf('-');
      equipmentStatus = this.state.equipmentStatus.name.substring(0, equipmentStatusIndex - 1)
    }

    const parameters = {
      customerId: this.state.customer ? this.state.customer.id : Utils.emptyGuid,
      familyCodeId: this.state.familyCode ? this.state.familyCode.id : Utils.emptyGuid,
      manufacturerId: this.state.manufacturer ? this.state.manufacturer.id : Utils.emptyGuid,
      nominalSizeId: this.state.nominalSize ? this.state.nominalSize.id : Utils.emptyGuid,
      cwpId: this.state.cwp ? this.state.cwp.id : Utils.emptyGuid,
      degreeId: this.state.degree ? this.state.degree.id : Utils.emptyGuid,
      idId: this.state.id ? this.state.id.id : Utils.emptyGuid,
      odId: this.state.od ? this.state.od.id : Utils.emptyGuid,
      lengthId: this.state.length ? this.state.length.id : Utils.emptyGuid,
      styleId: this.state.style ? this.state.style.id : Utils.emptyGuid,
      serviceId: this.state.service ? this.state.service.id : Utils.emptyGuid,
      mfgItemNo: this.state.mfgItemNo ? this.state.mfgItemNo.name : '',
      assetStatus: this.state.status ? this.state.status.id : -1,
      container: this.state.container,
      shippingTicket: this.state.shippingTicket,
      customerLocationId: this.state.customerLocation ? this.state.customerLocation.id : Utils.emptyGuid,
      currentLocationId: this.state.currentLocation ? this.state.currentLocation.id : Utils.emptyGuid,
      jobNumberIds: this.state.jobNumbers ? this.state.jobNumbers.map(number => number.id) : '',
      primarySerialNumber: this.state.primarySerialNumber,
      secondarySerialNumber: this.state.secondarySerialNumber,
      batchNumber: this.state.batchNumber,
      manufacturerDescriptionTokens: this.state.manufacturerDescriptionTokens,
      equipmentNumber: this.state.equipmentNumber,
      cycleFromDate: this.state.cycleFromDate,
      cycleToDate: this.state.cycleToDate,
      so: this.state.so,
      po: this.state.po,
      groupNumber: groupNumber,
      groupName: this.state.groupName ? this.state.groupName.name : '',
      soStatus: this.state.soStatus ? this.state.soStatus.name : '',
      equipmentStatus: equipmentStatus,
      customerJob: this.state.customerJob
    };

    if (!downloadToExcel) {
      this.state.totalResultsCount = 0;
      this.state.searchResults = [];
      this.state.generatedQRCodes = [];
      this.state.isLoading = true;
      ajax.post('lookup/searchAssetsAdvanced', parameters).then(results => {
        this.processSearchResults(results);
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
    else {
      parameters.activeTab = this.state.activeTab;
      parameters.exportType = exportType;
      const selectedAssets = this.getSelectedAssets();
        if(exportType === 'Export Selected' && !selectedAssets.length){
          return;
        }
      parameters.selectedAssetIds = selectedAssets.map(asset => asset.assetId);
      ajax.post('lookup/exportSerializedAssetsAsExcel', parameters).then(excelData => {
        this.export(excelData);
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
  }

  handleQRcodeSearch = (downloadToExcel = false) => {
    if (!this.validateSearch()) {
      return;
    }
    let groupNumber = '';
    let equipmentStatus = '';
    if(this.state.groupNumber){
      const groupNumberIndex = this.state.groupNumber.name.indexOf('-');
      groupNumber = groupNumberIndex >=0 
                    ? this.state.groupNumber.name.substring(0,groupNumberIndex-1) 
                    : this.state.groupNumber.name;
    }

    if(this.state.equipmentStatus){
      const equipmentStatusIndex = this.state.equipmentStatus.name.indexOf('-');
      equipmentStatus = this.state.equipmentStatus.name.substring(0,equipmentStatusIndex-1)
    }

    const parameters = {
        customerId                    : this.state.customer         ? this.state.customer.id         : Utils.emptyGuid,
        familyCodeId                  : this.state.familyCode       ? this.state.familyCode.id       : Utils.emptyGuid,
        manufacturerId                : this.state.manufacturer     ? this.state.manufacturer.id     : Utils.emptyGuid,
        nominalSizeId                 : this.state.nominalSize      ? this.state.nominalSize.id      : Utils.emptyGuid,
        cwpId                         : this.state.cwp              ? this.state.cwp.id              : Utils.emptyGuid,
        degreeId                      : this.state.degree           ? this.state.degree.id           : Utils.emptyGuid,
        idId                          : this.state.id               ? this.state.id.id               : Utils.emptyGuid,
        odId                          : this.state.od               ? this.state.od.id               : Utils.emptyGuid,
        lengthId                      : this.state.length           ? this.state.length.id           : Utils.emptyGuid,
        styleId                       : this.state.style            ? this.state.style.id            : Utils.emptyGuid,
        serviceId                     : this.state.service          ? this.state.service.id          : Utils.emptyGuid,
        mfgItemNo                     : this.state.mfgItemNo        ? this.state.mfgItemNo.name      : '',
        assetStatus                   : this.state.status           ? this.state.status.id           : -1,
        container                     : this.state.container,
        shippingTicket                : this.state.shippingTicket,
        customerLocationId            : this.state.customerLocation ? this.state.customerLocation.id : Utils.emptyGuid,
        currentLocationId             : this.state.currentLocation  ? this.state.currentLocation.id  : Utils.emptyGuid,
        jobNumberIds                   : this.state.jobNumbers       ? this.state.jobNumbers.map(number => number.id)        : '',
        primarySerialNumber           : this.state.primarySerialNumber,
        secondarySerialNumber         : this.state.secondarySerialNumber,
        batchNumber                   : this.state.batchNumber,
        manufacturerDescriptionTokens : this.state.manufacturerDescriptionTokens,
        equipmentNumber               : this.state.equipmentNumber,
        cycleFromDate                 : this.state.cycleFromDate,
        cycleToDate                   : this.state.cycleToDate,
        so                            : this.state.so,
        po                            : this.state.po,
        groupNumber                   : groupNumber,
        groupName                     : this.state.groupName ? this.state.groupName.name : '',
        soStatus                      : this.state.soStatus ? this.state.soStatus.name : '',
        equipmentStatus               : equipmentStatus,
        customerJob                   : this.state.customerJob
      };

      if(!downloadToExcel){
        this.state.totalResultsCount = 0;
        this.state.searchResults     = [];
        this.state.generatedQRCodes  = [];
        this.state.selectedAssets    = [];
        this.state.assets            = [];
        this.state.groups            = [];
        this.state.isLoading         = true;
        ajax.post('lookup/searchAssetsAdvanced', parameters).then(results=>{
        this.state.isLoading         = false;
        this.state.assets            = results.searchResults;
        this.state.totalResultsCount = results.totalResultsCount;
        this.commit();
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }
  }

  handleAssetManagementSearch = (exportType = null, downloadToExcel = false) => {
    if (!this.validateSearch()) {
      return;
    }
    let equipmentsStatus = [];
    if(this.state.equipmentsStatus){
      let equipmentStatusIndex = '';
      this.state.equipmentsStatus.forEach(status =>{
        equipmentStatusIndex = status.name.indexOf('-');
        equipmentsStatus.push(status.name.substring(0,equipmentStatusIndex-1));
      }
      );
    }
      const parameters = {
        customerId                    : this.state.customer         ? this.state.customer.id         : Utils.emptyGuid,
        plantCode                     : this.state.plantCode,
        equipmentNumber               : this.state.equipmentNumber,
        primarySerialNumber           : this.state.primarySerialNumber,
        equipmentsStatus              : equipmentsStatus ? equipmentsStatus :'',
        mfgItemNos                    : this.state.mfgItemNos        ? this.state.mfgItemNos.map(number => number.name)      : '',
        currentLocationId             : this.state.currentLocation  ? this.state.currentLocation.id  : Utils.emptyGuid,
        container                     : this.state.container,
        functionalLocation            : this.state.functionalLocation,
        jobNumberIds                  : this.state.jobNumbers       ? this.state.jobNumbers.map(number => number.id)        : '',
        objectType                    : this.state.objectType       ? this.state.objectType.map(number => number.name)        : '',
        costCenter                    : this.state.costCenter,
        assetNumber                   : this.state.assetNumber,                 
        shippingTicket                : this.state.shippingTicket,
        cycleFromDate                 : this.state.cycleFromDate,
        cycleToDate                   : this.state.cycleToDate,
        familyCodeId                  : this.state.familyCode       ? this.state.familyCode.id       : Utils.emptyGuid,
        nominalSizeId                 : this.state.nominalSize      ? this.state.nominalSize.id      : Utils.emptyGuid,
        manufacturerDescriptionTokens: this.state.manufacturerDescriptionTokens,
        awoNumberIds: this.state.awoNumbers ? this.state.awoNumbers.map(number => number.id) : ''
      };

      if(!downloadToExcel){
        this.state.totalResultsCount      = 0;
        this.state.assetMgmtSearchResults = [];
        this.state.generatedQRCodes       = [];
        this.state.isLoading              = true;
        ajax.post('lookup/searchAssetsForAssetManagement', parameters).then(results=>{
          this.state.isLoading              = false;
        this.state.assetMgmtSearchResults = results.searchResults;
        this.state.totalResultsCount      = results.totalResultsCount;
        results.searchResults.forEach(this.setMfgItemNoDisplayValue);
        this.commit();
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }
      else{
        parameters.activeTab = this.state.activeTab;
        parameters.exportType = exportType;
        const selectedAssets = this.getSelectedAssets();
        if(exportType === 'Export Selected' && !selectedAssets.length){
          return;
        }
        parameters.selectedAssetIds = selectedAssets.map(asset => asset.assetId);
        
        ajax.post('lookup/exportSerializedAssetsAsExcel', parameters).then(excelData=>{
          this.export(excelData);
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        }); 
      }
  }

  handleMassTransferSearch = () =>  {
    if (!this.validateSearch()) {
      return;
    }
    let groupNumber = '';
    let equipmentStatus = '';
    if(this.state.groupNumber){
      const groupNumberIndex = this.state.groupNumber.name.indexOf('-');
      groupNumber = groupNumberIndex >=0 
                    ? this.state.groupNumber.name.substring(0,groupNumberIndex-1) 
                    : this.state.groupNumber.name;
    }

    if(this.state.equipmentStatus){
      const equipmentStatusIndex = this.state.equipmentStatus.name.indexOf('-');
      equipmentStatus = this.state.equipmentStatus.name.substring(0,equipmentStatusIndex-1)
    }
      const parameters = {
        customerId                    : this.state.customer         ? this.state.customer.id         : Utils.emptyGuid,
        familyCodeId                  : this.state.familyCode       ? this.state.familyCode.id       : Utils.emptyGuid,
        manufacturerId                : this.state.manufacturer     ? this.state.manufacturer.id     : Utils.emptyGuid,
        nominalSizeId                 : this.state.nominalSize      ? this.state.nominalSize.id      : Utils.emptyGuid,
        cwpId                         : this.state.cwp              ? this.state.cwp.id              : Utils.emptyGuid,
        degreeId                      : this.state.degree           ? this.state.degree.id           : Utils.emptyGuid,
        idId                          : this.state.id               ? this.state.id.id               : Utils.emptyGuid,
        odId                          : this.state.od               ? this.state.od.id               : Utils.emptyGuid,
        lengthId                      : this.state.length           ? this.state.length.id           : Utils.emptyGuid,
        styleId                       : this.state.style            ? this.state.style.id            : Utils.emptyGuid,
        serviceId                     : this.state.service          ? this.state.service.id          : Utils.emptyGuid,
        mfgItemNo                     : this.state.mfgItemNo        ? this.state.mfgItemNo.name      : '',
        assetStatus                   : this.state.status           ? this.state.status.id           : -1,
        container                     : this.state.container,
        shippingTicket                : this.state.shippingTicket,
        customerLocationId            : this.state.customerLocation ? this.state.customerLocation.id : Utils.emptyGuid,
        currentLocationId             : this.state.currentLocation  ? this.state.currentLocation.id  : Utils.emptyGuid,
        jobNumberIds                   : this.state.jobNumbers       ? this.state.jobNumbers.map(number => number.id)        : '',
        primarySerialNumber           : this.state.primarySerialNumber,
        secondarySerialNumber         : this.state.secondarySerialNumber,
        batchNumber                   : this.state.batchNumber,
        manufacturerDescriptionTokens : this.state.manufacturerDescriptionTokens,
        equipmentNumber               : this.state.equipmentNumber,
        cycleFromDate                 : this.state.cycleFromDate,
        cycleToDate                   : this.state.cycleToDate,
        so                            : this.state.so,
        po                            : this.state.po,
        groupNumber                   : groupNumber,
        groupName                     : this.state.groupName ? this.state.groupName.name : '',
        soStatus                      : this.state.soStatus ? this.state.soStatus.name : '',
        equipmentStatus               : equipmentStatus,
        customerJob                   : this.state.customerJob
      };
      this.state.totalResultsCount = 0;
      this.state.searchResults     = [];
      this.state.generatedQRCodes  = [];
      this.state.selectedAssets    = [];
      this.state.assets            = [];
      this.state.groups            = [];
      this.state.transferErrors    = [];
      this.state.isLoading         = true;
      ajax.post('lookup/searchAssetsAdvanced', parameters).then(results=>{
        this.state.isLoading         = false;
      this.state.assets            = results.searchResults;
      this.state.totalResultsCount = results.totalResultsCount;
      this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }
  processSearchResults = (results) => {
    this.state.isLoading         = false;
    this.state.searchResults     = results.searchResults;
    this.state.totalResultsCount = results.totalResultsCount;
    results.searchResults.forEach(this.setMfgItemNoDisplayValue);
    this.commit();
  }

  receiveAssets = () => {
    const selectedAssets = this.getSelectedAssets();
    if (!selectedAssets.length) {
      return;
    }
    const currentPlantCode = store.get('currentFacility').plantCode;
    var selectableassets =  selectedAssets.filter(x=>(x.plantCode != currentPlantCode && !!x.plantCode) || x.functionalLocation || x.container);
    var candirectreceiveassets  =  selectedAssets.filter(x=> !selectableassets.includes(x));
    if(candirectreceiveassets.length>0)
    {
      var assetIds = candirectreceiveassets.map(asset => asset.assetId);
      messages.channel('receiving').action('add-to-list').post(assetIds);
      this.commit();
    }
    if(selectableassets.length>0)
      {
        const self = this;
        Dialog.showDialog(<ReceiveAssetDialog
               controller={new ReceivedAssetDailogController(self,selectableassets)}  header='Receive Assets'            
               />);
      }
      else{
      if (!selectedAssets.length) {
        return;
      }
      
      }
  }

  assignToContainer = () => {
    const selectedAssets = this.getSelectedAssets();
    if (!selectedAssets.length) {
      return;
    }
    const assetIds = selectedAssets.map(asset => asset.assetId);
    const customer = this.state.customer;
    Dialog.showDialog(<TransferAssetsToContainerDialog controller={new TransferAssetsToContainerController(customer, null, assetIds)} header='Transfer Assets to Container' />);
  }

  initializeGroups = () => {
    let groups = [];
    this.state.assets.filter(asset => {
      if (groups.indexOf(asset.groupNumber) === -1) {
        groups.push(asset.groupNumber);
      }
    });
  
    groups.sort(function(a, b) {
      return (a - b);
    });
  
    this.state.groups = groups.map(group => {
      return {
        value    : group,
        selected : false
      };
    });
  }
  
  selectNoAssets = () => {
    this.state.assets.forEach(asset => {
      asset.selected = false;
    });
    this.commit();
  }

  unselectAllAssetManagementResults= () => {
    this.state.assetMgmtSearchResults.forEach(asset => {
      asset.selected = false;
    });
    this.commit();
  }
  
  selectNoSelectedAssets = () => {
    this.state.selectedAssets.forEach(asset => {
      asset.selected = false;
    });
    this.commit();
  }
  
  selectAllAssets = () => {
    this.state.assets.forEach(asset => {
      asset.selected = true;
    });
    this.commit();
  }
  
  selectAllSelectedAssets = () => {
    this.state.selectedAssets.forEach(asset => {
      asset.selected = true;
    });
    this.commit();
  }
  
  toggleSelection = (assets) => {
    const values = this.state.activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement ? [...this.state.assetMgmtSearchResults] : [...this.state.searchResults]
    values.forEach(row => {
      const asset = assets.find(item => row.assetId == item.assetId);
      if(asset){
        row.selected = true;
      }
      else{
        row.selected = false;
      }
    });
    if(this.state.activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement){
      this.state.assetMgmtSearchResults = values;
    }
    else{
      this.state.searchResults = values;
    }
    this.commit();
  }

  toggleQRAssetSelection = (asset) =>{
    asset.selected = !asset.selected;
    this.commit();
  }
  
  toggleGroupSelection = (clickedGroup) => {
    this.state.groups.forEach(group => {
      if (group.value === parseInt(clickedGroup, 10)) {
        const groupNumber = group.value;
        group.selected = !group.selected;
  
        this.state.assets
          .filter(asset => (asset.groupNumber === groupNumber))
          .forEach(asset => {
            asset.selected = group.selected;
          }
        );
      }
    });
    this.commit();
  }
  
  selectAndTransfer = (asset) => {
    asset.selected = true;
    if (_.List(this.state.assets).contains(asset)) {
      this.moveToSelected();
    }
    else {
      this.removeFromSelected();
    }
  }
  
  moveToSelected = () => {
    let i = this.state.assets.length;
    while (i--) {
     let asset = this.state.assets[i];
      if (asset.selected) {
        asset.selected = false;
        this.state.assets.splice(i, 1);
        this.state.selectedAssets.push(asset);
      }
    }
  
    // sort selected list
    this.state.selectedAssets.sort(function(a, b) {
      return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
    });
    this.commit();
  }
  
  removeFromSelected = () => {
    let i = this.state.selectedAssets.length;
    while (i--) {
      let asset = this.state.selectedAssets[i];
      if (asset.selected) {
        asset.selected = false;
        this.state.selectedAssets.splice(i, 1);
        this.state.assets.push(asset);
      }
    }
  
    // sort primary list
    this.state.assets.sort(function(a, b) {
      return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
    });
    this.commit();
  }

  customerChanged = () => {
    if(!this.state.customer){
      this.state.jobNumbers = '';
    }
    this.commit();
  }

  jobNumbersChanged = () => {
    if (this.state.jobNumbers && this.state.jobNumbers.length > 1) {
      this.state.groupNumber = '';
    }
    if (!this.state.jobNumbers) {
      this.state.groupNumber = '';
      this.state.soStatus = '';
     }
    this.state.awoNumbers = '';
    $('.awoNumber-select').select2('data', []);
    this.commit();
  }
  awoNumbersChanged = () => {
    this.state.jobNumbers = '';
    $('.jobNumber-select').select2('data', []);
     this.commit();
   }
  updateCycleCount = (assets,plannedStartDate) => {
    const parameter = {
      asset : assets,
      plannedStartDate : plannedStartDate
    };
      ajax.post('receiving/updateAssetStartDate', parameter).then(results=>{
        if(results){
          notification.action('success').post('Saved Cycle Count date for ' + Utils.pluralize('asset', 's', assets.length));
          assets.forEach(asset => {
            messages.channel('update-cyclecount').action('saved').post(asset.assetId)
          });
        }
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  createNewTransfer = () => {
    const self = this;
    const assets =  this.state.selectedAssets;
    co(this, function*() {
        yield Dialog.showDialogWaitForResult(
            <ChangeAssetOwnershipDialog
                controller={new ChangeAssetOwnershipController(assets)}
                parentController={self}
                hideCustomerLocation={true}/>);
    });
  }

  setTransferErrors = (errors) => {
    this.state.transferErrors = errors ? errors : [];
    this.state.selectedAssets = [];
    this.commit();
  }

  validationChange = () => {
    if(this.state.cycleFromDate || this.state.cycleToDate){
      this._validator.add('cycleToDate', 'Cycle To Date', [{required: true}]);
      this._validator.add('cycleFromDate', 'Cycle From Date', [{required: true}]);
    }
    else if(!this.state.cycleFromDate && !this.state.cycleToDate) {
      this._validator.remove('cycleToDate');
      this._validator.remove('cycleFromDate');
    }
    this.commit();
  }

  cycleFromDateChanged = () => {
      this.validationChange();
  }

  cycleToDateChanged = () =>{
    this.validationChange();
  }

  getByteData = (exportType = null) => {
    switch (this.state.activeTabName) {
      case AppConstants.SerializedAssetSearchTab.Simple:
        this.handleSimpleSearch(exportType,true);
        return;

      case AppConstants.SerializedAssetSearchTab.Customer:
        this.handleCustomerSearch(exportType,true);
        return;

      case AppConstants.SerializedAssetSearchTab.Advanced:
        this.handleAdvancedSearch(exportType,true);
        return;

      case AppConstants.SerializedAssetSearchTab.AssetManagement:
        this.handleAssetManagementSearch(exportType,true);
        return;

      default: 
        this.handleSimpleSearch(true);
        break;
    }
}

  export = (excelData) => {
    co(this, function* () {
      try {
        const s = this.state;
        const blob = Utils.convertBase64toBlob(excelData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const fileName = 'SerializedAssetList.xlsx';
        const fileURL = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.download = fileName;
        if (navigator.userAgent.match(/iPad/i) != null) {
          window.open(fileURL);
        }
        else {
          tempLink.click();
        }
        tempLink.remove();
        notification.action('success').post('Exported successfully');
      }
      catch (err) {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }
    });
  }

  redirectToAssembly = (awoId, cb) => {
    const data = { awoId: awoId };
    messages.channel('apply-assembly-levels').action('assign-data').post(data);
    cb();
  }

}

export default SerializedAssetSearchController;