import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import store from 'store';
import ActionIcon from '../../components/ActionIcon';
import Dialog from '../../components/Dialog';
import Jumbotron from '../../components/Jumbotron';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Table from '../../components/Table';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import Roles from '../utility/Roles';
import GroupBadge from '../../components/GroupBadge';
import FacilitiesController from '../controllers/FacilitiesController';
import SelectFacilityCustomerLocationsController from '../controllers/SelectFacilityCustomerLocationsController';
import SelectFacilityUsersController from '../controllers/SelectFacilityUsersController';
import SelectFacilityCustomerLocationsDialog from '../dialogs/SelectFacilityCustomerLocationsDialog';
import SelectFacilityUsersDialog from '../dialogs/SelectFacilityUsersDialog';
import Search from '../components/Search';
import LoginService from '../services/LoginService';

const SearchHeader = (props) => {
    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.searchFacilities();
    }

    const handleMouseSearch = () => {
        props.controller.searchFacilities();
    }

    const clearSearch = () => {
        props.controller.clearSearch();
    }
    const binder = props.binder;
    return (
        <div id='search-criteria' className='col-md-12'>
            <div className='row'>
                <div className='search-container col-md-8' onKeyDown={handleKeyboardSearch}>
                    <Search className='mb-2' id='searchText' inputClassName='search-input' label='Enter search text' placeholder='facility name' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
                </div>
            </div>
        </div>
    );
}

const ResultsList = (props) => {

    const handleRowClick = (data) => {
        props.controller.loadDetails(data);
    }

    const getCellProps = (cell) => {
        if (cell && cell.column.id === 'useSAP' && cell.value && !cell.row.isSelected) {
            const className = 'sap-facility'
            const rowProps = { className: className };
            return rowProps;
        }
    }

    const controller = props.controller;
    const data = controller.state.searchResults || [];
    const loadingMessage = controller.state.isLoading && 'Loading facilities that match the search criteria defined above';
    const noResultsMessage = !loadingMessage && !data.length && 'No facilities meet the search criteria defined above';
    const displayMessage = noResultsMessage || loadingMessage;
    const matchesCount = controller.state.searchResults.length;
    const matchesLabel = Utils.pluralize('match', 'es', matchesCount);
    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'archived',
                Cell: cellInfo => cellInfo.cell.value
                    ? <span className='status-column archived'><i className='fa fa-archive' title='Facility is archived' /></span>
                    : <span />
            },
            {
                Header: 'Facility',
                accessor: 'facilityName'
            },
            {
                Header: 'City',
                accessor: 'city'
            },
            {
                Header: 'State',
                accessor: 'state'
            },
            {
                Header: 'Plant Code',
                accessor: 'plantCode'
            },
            {
                Header: 'Use SAP',
                accessor: 'useSAP',
                Cell: (cellInfo) => {
                    let sapYesStatusClassName = 'text-center';
                    return <div className={sapYesStatusClassName}>{Utils.formatBool(cellInfo.cell.value)}</div>
                }
            }
        ],
        []
    )
    return (
        <div className='col-md-12'>
            <div className='d-flex justify-content-end'>
                <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
            </div>
            <div className='facility-search-result'>
                <Grid id='results-table' columns={columns} data={data} getCellProps={getCellProps} onRowClick={handleRowClick} noDataText={displayMessage} enableDefaultRowSelect={true} />
            </div>
        </div>
    );
}

const CustomerRow = (props) => {
    return (
        <Table.Row>
            <Table.Data className='status-column' />
            <Table.Data style={{ fontWeight: 'bold' }}>{props.customerName}</Table.Data>
        </Table.Row>
    );
}

const CustomerLocationRow = (props) => {
    let history = useHistory();

    const handleEdit = (facility, location) => {
        const data = {
            customerLocationId: location.id,
            customerId: facility.customerId,
            customerName: facility.customerName
        };
        store.set('edit-customer-location', data);
        history.push('/customerlocations/' + location.id);
    }
    const facility = props.facility;
    const location = props.location;
    let statusClassName = 'status-column';
    if (location.archived) {
        statusClassName += ' archived';
    }
    const statusCell = (location.archived)
        ? <Table.Data className={statusClassName}><i className='fa fa-archive' title='Location is archived' /></Table.Data>
        : <Table.Data className='status-column' />;
    const spanFMCLocation = location.fmcLocation
        ? <span className='fmc-location'>&nbsp;&nbsp;TechnipFMC</span>
        : null;

    return (
        <Table.Row>
            {statusCell}
            <Table.Data>&nbsp;&nbsp;&nbsp;&nbsp;<a className='select-link' onClick={() => { handleEdit(facility, location); }}>{location.name}</a>{spanFMCLocation}</Table.Data>
        </Table.Row>
    );
}

const CustomerLocations = (props) => {

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.filterCustomerLocations();
    }

    const handleMouseSearch = () => {
        props.controller.filterCustomerLocations();
    }

    const clearSearch = () => {
        props.controller.clearLocationSearch();
    }

    const handleSelectLocations = () => {
        co(function* () {
            const state = props.controller.state;
            const customerLocations = state.allCustomerLocations || [];
            const facility = {
                facilityId: state.selectedFacility.facilityId,
                facilityName: state.selectedFacility.facilityName
            };

            yield Dialog.showDialogWaitForResult(
                <SelectFacilityCustomerLocationsDialog controller={new SelectFacilityCustomerLocationsController(facility, customerLocations)} header='Select Facility Customer Locations' />
            );
        });
    }

    const binder = props.binder;
    const controller = props.controller;
    const facility = controller.state.selectedFacility;
    let sortedLocations = controller.state.filteredCustomerLocations;

    sortedLocations.sort((a, b) => {
        let result = a.customerName.localeCompare(b.customerName);

        if (result === 0) {
            result = a.name.localeCompare(b.name);
        }
        return result;
    });

    let locations = [];
    let customerName = '';
    sortedLocations.forEach(location => {
        if (customerName !== location.customerName) {
            customerName = location.customerName;
            locations.push(<CustomerRow key={Utils.newGuid()} customerName={customerName} />);
        }
        locations.push(<CustomerLocationRow key={location.id} facility={facility} location={location} />);
    });

    const count = sortedLocations.length;
    const canAdd = facility;
    const loadingMessage = controller.state.isLoadingLocations && <Table.Row><Table.Data colSpan='4' className='loading'>Loading ...</Table.Data></Table.Row>;
    let noResultsMessage = null;
    if (!loadingMessage && !count) {
        if (controller.state.searchLocationText) {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='2' className='no-search-results'>No locations match the search criteria</Table.Data>
            </Table.Row>;
        }
        else {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='2' className='no-search-results'>No locations for this facility</Table.Data>
            </Table.Row>;
        }
    }

    return (
        <div className='no-gutters'>
            <div className='col-sm-12' onKeyDown={handleKeyboardSearch}>
                <span>
                    <Form.Input id='searchLocationText' className='search-input' placeholder='location name' bind={binder.bind('searchLocationText')} />
                    <ActionIcon icon='fa-times-circle' className='clearable' action={clearSearch} />
                    <Button icon='fa-filter' variant='primary' size='sm' className='btn-search-icon' onClick={handleMouseSearch} />
                </span>
            </div>
            <div className='form-group col-sm-12 table-container'>
                <Table id='locations-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head colSpan={2}>
                                <div className='d-flex justify-content-between'>
                                    <div><i className='fa fa-globe' />&nbsp;&nbsp;Locations&nbsp;&nbsp;</div>
                                    <GroupBadge icon='fa-globe' variant='warning' pill={false} disabled={!canAdd} className='' onClick={handleSelectLocations}>Select ...</GroupBadge>
                                    <div><Badge variant='info' title='Number of Facility Locations' className='pull-right'>{count}</Badge></div>
                                </div>
                            </Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {locations}
                        {loadingMessage}
                        {noResultsMessage}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

const FacilityUserRow = (props) => {

    let history = useHistory();

    const handleEdit = (facility, user) => {
        const data = {
            userId: user.id,
            facilityId: facility.facilityId,
            facilityName: facility.facilityName
        };
        store.set('edit-user', data);
        history.push('/users/' + user.id);
    }

    const handleRemoveUser = (user) => {
        props.controller.removeUserAccess(user);
    }

    const facility = props.facility;
    const user = props.user;
    let statusClassName = 'status-column';
    if (!user.loginEnabled) {
        statusClassName += ' archived';
    }

    const statusCell = (user.loginEnabled)
        ? <Table.Data className='status-column' />
        : <Table.Data className={statusClassName}><i className='fa fa-ban' title="User's login is disabled" /></Table.Data>;

    const roleClassName = Roles.getRoleClassName(user.roleName);

    return (
        <Table.Row>
            <Table.Data className='status-column'><ActionIcon icon='fa-trash' className='remove-handle' title="Remove user's access to facility" action={() => handleRemoveUser(user)} /></Table.Data>
            {statusCell}
            <Table.Data><a className='select-link' onClick={() => { handleEdit(facility, user); }}>{user.name}</a></Table.Data>
            <Table.Data>{user.loginName}</Table.Data>
            <Table.Data><span className={roleClassName}>{user.roleName}</span></Table.Data>
        </Table.Row>
    );
}

const FacilityUsers = (props) => {

    let history = useHistory();

    const handleAdd = (facility) => {
        const data = {
            userId: Utils.emptyGuid,
            facilityId: facility.facilityId,
            facilityName: facility.facilityName
        };
        store.set('edit-user', data);
        history.push('/users/' + Utils.emptyGuid);
    }

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.filterFacilityUsers();
    }

    const handleMouseSearch = () => {
        props.controller.filterFacilityUsers();
    }

    const clearSearch = () => {
        props.controller.clearUserSearch();
    }

    const handleUserAccess = () => {
        co(function* () {
            const state = props.controller.state;
            const users = state.allFacilityUsers || [];
            const facility = {
                facilityId: state.selectedFacility.facilityId,
                facilityName: state.selectedFacility.facilityName
            };
            yield Dialog.showDialogWaitForResult(
                <SelectFacilityUsersDialog controller={new SelectFacilityUsersController(facility, users)} header='Select Facility Users' />
            );
        });
    }
    const binder = props.binder;
    const controller = props.controller;
    const facility = controller.state.selectedFacility;
    const canAdd = facility;
    const users = controller.state.filteredFacilityUsers.map(user => <FacilityUserRow key={user.id} facility={facility} user={user} controller={controller} />);
    const count = users.length;
    const loadingMessage = controller.state.isLoadingUsers && <Table.Row><Table.Data colSpan='5' className='loading'>Loading ...</Table.Data></Table.Row>;
    let noResultsMessage = null;
    if (!loadingMessage && !count) {
        if (controller.state.searchUserText) {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='5' className='no-search-results'>No users match the search criteria</Table.Data>
            </Table.Row>;
        }
        else {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='5' className='no-search-results'>No users for this facility</Table.Data>
            </Table.Row>;
        }
    }

    const styleCheckBox = {
        height: '16px',
        width: '16px'
    };

    return (
        <div className='no-gutters'>
            <div className='col-sm-12' onKeyDown={handleKeyboardSearch}>
                <span>
                    <Form.Input style={{ fontSize: '1.5rem', height: '32px', marginBottom: '-10px' }} id='searchUserText' className='search-input' placeholder='user name' bind={binder.bind('searchUserText')} />
                    <ActionIcon icon='fa-times-circle' className='clearable' action={clearSearch} />
                    <Button icon='fa-filter' variant='primary' size='sm' className='btn-search-icon' onClick={handleMouseSearch} />
                </span>
            </div>
            <div className='form-group col-sm-12 table-container'>
                <Table id='users-table' responsive='false'>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head colSpan={5} className='sorting' role='columnheader'>
                                <div className='d-flex justify-content-between'>
                                    <div><i className='fa fa-users' />&nbsp;&nbsp;Users&nbsp;&nbsp;
                                        <GroupBadge icon='fa-plus-circle' variant='success' pill={false} disabled={!canAdd} className='' onClick={() => { handleAdd(facility); }}>New ...</GroupBadge></div>
                                    <div><GroupBadge icon='fa-users' variant='warning' pill={false} disabled={!canAdd} className='' onClick={handleUserAccess}>User Access ...</GroupBadge></div>
                                    <div><div className='d-inline-flex show-all-result'><Form.CheckBox className='mr-2 mt-1' style={styleCheckBox} enabled={canAdd} disabled={!canAdd} bind={binder.bind('searchEnabledUsersOnly')} /><Form.Label name='Enabled only' /></div></div>
                                    <div><Badge variant='info' title='Number of Facility Users' className='count-badge'>{count}</Badge></div>
                                </div>
                            </Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users}
                        {loadingMessage}
                        {noResultsMessage}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

const Details = (props) => {
    const binder = props.binder;
    const controller = props.controller;
    const selectedFacility = controller.state.selectedFacility;

    return (
        <Jumbotron {...props} id='facility-details' className='form-group'>
            <div className='row'>
                <div className='col-sm-12'><Form.StaticData label='Facility' value={selectedFacility.facilityName} /></div>
                <div className='col-sm-12 address'><Form.StaticData label='Address' value={selectedFacility.address1} /></div>

                {selectedFacility.address2 &&
                    <div className='col-sm-12 address'><Form.StaticData value={selectedFacility.address2} /></div>}

                {selectedFacility.address3 &&
                    <div className='col-sm-12'><Form.StaticData value={selectedFacility.address3} /></div>}

                <div>&nbsp;</div>
            </div>

            <div className='row'>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='City' value={selectedFacility.city} /></div>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='State' value={selectedFacility.state} /></div>
            </div>

            <div className='row'>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Postal Code' value={selectedFacility.postalCode} /></div>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Region' value={selectedFacility.region && selectedFacility.region.name} /></div>
            </div>

            <div className='row'>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Country' value={selectedFacility.country && selectedFacility.country.name} /></div>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Phone' value={selectedFacility.phone} /></div>
            </div>

            <div className='row'>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Fax' value={selectedFacility.fax} /></div>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Use SAP' value={selectedFacility && Utils.formatBool(selectedFacility.useSAP)} /></div>
            </div>

            <div className='row'>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Plant Code' value={selectedFacility.plantCode} /></div>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Location Code' value={selectedFacility.locationCode} /></div>
            </div>

            <div className='row'>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Division' value={selectedFacility.division} /></div>
                <div className='col-sm-12 col-md-6'><Form.StaticData label='Sales Office' value={selectedFacility.salesOffice} /></div>
            </div>
            <CustomerLocations controller={controller} binder={binder} />
            <FacilityUsers controller={controller} binder={binder} />
        </Jumbotron>
    );
}

const Buttons = (props) => {

    const handleComplete = () => {
        props.controller.complete();
    }

    const controller = props.controller;
    const facility = controller.state.selectedFacility;
    const canEdit = facility;
    const isSuperUser = LoginService.isInRole(Roles.SuperUser)
    const buttonText = isSuperUser ? 'View Details ...' : 'Edit ...'
    return (
        <div id='facility-buttons'>
            <Button icon='fas fa-pencil-alt' disabled={!canEdit} to={'/facilities/' + facility.facilityId} variant='primary' size='sm' className='action-button m-1'> {buttonText}</Button>
            <Button icon='fa-plus-circle' to={'/facilities/' + Utils.emptyGuid} variant='success' size='sm' disabled={isSuperUser} className='action-button m-1' >Create New ...</Button>
        </div>
    );
}

const FacilitiesPageHeader = (props) => <PageHeader title={props.pageTitle} icon='far fa-building' />

class FacilitiesPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();
    }

    static defaultProps = {
        controller: new FacilitiesController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Page {...this.props} pageTitle='InteServ · Facilities' id='facility-page'>
                <FacilitiesPageHeader pageTitle={'Facilities'} />
                <Page.Content>
                    <div className='row'>
                        <div {...this.props} className='col-md-8'>
                            <div className='row'>
                                <SearchHeader controller={controller} binder={binder} />
                                <ResultsList controller={controller} binder={binder} />
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Buttons controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <Details controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
};

export default FacilitiesPage;
