import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
class AWOGroupRowController extends BaseController {
  constructor(parentController, obj) {    
    super('awo-group-row-controller-' + AWOGroupRowController.key++, {
      group: obj.group,
      positionPackage: obj.positionPackage,
      equipmentDescription: obj.equipmentDescription,
      dependentEquipment: obj.dependentEquipment,
      dependentChild: obj.dependentChild,
      isChild: obj.isChild,
      system_GUID: obj.system_GUID,
      serial_dependent_GUID: obj.serial_dependent_GUID,
      order:obj.order,
      errors: {},
      serial_GUID: obj.serial_GUID,
      isAssemblyStarted : obj.isAssemblyStarted,
      order: obj.order ? obj.order : ''
    });

    this.parentController = parentController;
    this._validator = new Validator();
    this._validator.add('group', 'Group', [{ required: true }]);
    this._validator.add('positionPackage', 'Position / Package', [{ required: true }]);
    this._validator.add('equipmentDescription', 'Equipment Description', [{ required: true }]);
    this._validator.add('dependentEquipment', 'Dependent Equipment Description', [{ required: true }]);
    this._validator.add('isChild', 'Parent/Child', [{ required: true }]);
    this._validator.add('order', 'Order', [{ required: true }]);
  }

  static key = 1;

  // primarySerialNumberChanged = () => {
  //   co(this, function* () {
  //     var uniqueSerialNumber = yield this.parentController.isSerialNumberUnique(this.state.primarySerialNumber, AppConstants.eSerialNumberType.Primary);
  //     this.state.customerName = uniqueSerialNumber.customerName;
  //     this.state.currentLocationName = uniqueSerialNumber.currentLocationName;
  //     this.state.isPrimaryUnique = uniqueSerialNumber.isUnique;
  //     this.commit();
  //   });
  // }

  remove = () => {
    this.parentController.removeFromList(this);
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
    const errors = this.state.errors;
    return errors;
  }
  dependentChildChanged = () => {
    this.state.dependentChild = this.state.dependentChild;
    this.commit();
    this.parentController.commit();
  }
  groupChanged = () => {
    if (this.state.group) {
      if (this.parentController.getgroupAssemblyDetails) {
        this.parentController.getgroupAssemblyDetails(this.state.group.id, this);
      }
    }
  }
  resetGroup = () => {
    this.state.group = '';
    this.commit();
    this.parentController.commit();
  }
  resetParent = () => {
    this.state.isChild = '';
    this.commit();
    this.parentController.commit();
  }
  isChildChanged = () => {    
    if (this.state.isChild && this.state.isChild.id === 0 && this.state.group) {
      if (this.parentController.isDuplicateParent) {
        this.parentController.isDuplicateParent(this.state.group.id, this)
      }
    }

  }

  awoGroupSearch = (state) => {
    const parentController = this.parentController
    
    return function* (searchTerm) { 
      var ischild  = state?.isChild?.id;
      if([0,1].includes(ischild))
      {        
      var results = yield ajax.get('lookup/awo/searchGroupList', { searchTerm: searchTerm });       
      const groups = parentController.getSelectedParentGroups();
      const excludedgroups = parentController.getStartedAssembly ? parentController.getStartedAssembly() : [];
      if (ischild === 0 && groups.length > 0 ) {        
        
        results = results.filter(x => !groups.includes(x.name));
      }
      if(ischild===1 && groups.length > 0)
      {
        results = results.filter(x => groups.includes(x.name));
      }
      if(ischild===1 && groups.length === 0 )    
      {
        notification.action('error').post('Please add Parent first before selecting a child');
        return null;
      } 
      
      return   results.filter(x => !excludedgroups.includes(x.name));      
    }
    else{
      notification.action('error').post('Please select Parent/Child first.');
    }
    };
  }
}

export default AWOGroupRowController;
