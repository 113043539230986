import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class SendEmailController extends BaseController {
    constructor(assetIds) {
      super('send-email', {
        assetList     : assetIds || [],
        emailId       : '',
        errors        : {}
      });
      this.saving = false;
  }

  
  verifyEmailId = () => { 
    const validEmail = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/).test(this.state.emailId);
    let proceed    = true;
    if(!validEmail){
      this.state.errors.emailId = 'Please enter valid E-mail address';
      this.commit();
      proceed = false;
    }
    return proceed;
  }

  save = () => {
    const assetList     = this.state.assetList
    if(this.verifyEmailId()){
    const parameters = {
      assetIds  : assetList,
      emailId   : this.state.emailId,
      userDate  : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())
    };
    this.saving = true;
    this.commit();
    return ajax.post('receiving/emailassets', parameters).then(results => {
      this.saving = false;
      this.commit();
      notification.action('success').post('Successfully sent');        
      return true;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
  }
}

export default SendEmailController;