import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';

class ReleaseNotesController extends BaseController {
  constructor() {
    super('releaseNotes', {
      searchActive: true,
      searchCompleted: false,
      searchText: '',
      searchResults: [],
      selectedForm: '',
    });
  }

  clearResults = () => {
    this.state.searchResults = [];
    this.state.selectedForm = '';
  }

  clearSearch = () => {
    this.state.searchText = '';
    this.commit();
  }

  searchReleaseNotes = () => {
    this.state.results = [];
    const searchText = this.state.searchText;
    const parameters = {
      searchTerm: searchText
    };
    ajax.get('lookup/getReleaseNotes', parameters).then(results => {
      this.state.searchResults = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });  // IList<DropoffLookupDto>
  }
}

export default ReleaseNotesController;
