import React from 'react';
import PropTypes from 'prop-types';

const Page = (props) => {

    const transition = props.canAddTransition ? 'grow-fade-in' : null;
    var title = props.pageTitle;
    if (title) {
      document.title = title;
    }

    return (
      <div {...props} className={'page ' + transition} >
        {props.children}
      </div>
    );
}

Page.Header = (defaultProps) => {

  const props = {
    className: '',
    removeHeaderSpace: false,
    ...defaultProps
  }

  const style = {
    margin: 0,
    borderBottomWidth: 0,
    boxShadow: '0 2px 5px 1px rgba(0, 0, 0, 0.16), 0 2px 10px 1px rgba(0, 0, 0, 0.12)',
    height: '3.75rem',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    backgroundColor: '#FFFFFF',
    zIndex: 1500,
    justifyContent: 'unset', 
    ...props.style
  }

  return (
    <div {...props} className={'page-header navbar sticky-top justify-content-between' + props.className} style={style} title=''>
      {props.children}
    </div> 
  );
}

Page.Content = (defaultProps) => {

  const props = {
    className: '',
    removeHeaderSpace: false,
    ...defaultProps
  }

  const style = {
    paddingTop: 15,
    ...props.style
  }

  return (
    <div {...props} className={'content ' + props.className} style={style}>
      <div className='col'>{props.children}</div>
    </div>
  );
}

Page.propTypes = {
    canAddTransition: PropTypes.bool,
    pageTitle: PropTypes.string,
    transition: PropTypes.string
}

export default Page;