import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';

class AssemblyWorkOrderRowController extends BaseController {
  constructor(parentController, obj) {
    super('assembly-work-order-row-controller-' + AssemblyWorkOrderRowController.key++, {
      awoDescription             :       obj.awoDescription ? obj.awoDescription : '',
      awoCustomer                :       obj.awoCustomer ? obj.awoCustomer : '',
      pad                        :       obj.pad ? obj.pad : '',
      wellName                   :       obj.wellName ? obj.wellName : '',
      system                     :       obj.system ? obj.system : '',
      cwp                        :       obj.cwp ? obj.cwp : '',
      equipmentRequiredDate      :       obj.equipmentRequiredDate ? obj.equipmentRequiredDate : '',
      awoGroupRows               :       obj.awoGroupRows,
      fileName                   :       obj.fileName,
      file                       :       obj.file,
      serviceLine                :       obj.serviceLine,
      containerDescription       :       obj.containerDescription,
      wellColor                  :       obj.wellColor,
      awoNumber                  :       obj.awoNumber,
      functionalLocation         :       obj.functionalLocation,
      order                      :       obj.order,
      existingAWO_Id             :       obj.existingAWO_Id,
      existingFileName           :       obj.existingFileName,
      errors: {}
    });

    this.parentController = parentController;
    this._validator = new Validator();
    this._validator.add('group', 'Group', [{ required: true }]);
    this._validator.add('positionPackage', 'Position/Package', [{ required: true }]);
    this._validator.add('equipmentDescription', 'Equipment Description', [{ required: true }]);
    this._validator.add('dependentEquipment', 'Dependent Equipment Description', [{ required: true }]);
  }

  static key = 1;

  remove = () => {
    this.parentController.removeFromConfigList(this);
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

}

export default AssemblyWorkOrderRowController;
