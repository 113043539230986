import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
 class TechnicalDocRowController extends BaseController {
    constructor(Document_GUID=null,Cust_Desc_GUID=null,Document_Name=null,Document_Link=null){
        super("",{
            Document_GUID : Document_GUID,
            Cust_Desc_GUID : Cust_Desc_GUID,
            Document_Name:Document_Name,
            Document_Link:Document_Link
        })
    }
}
export default TechnicalDocRowController;