import React, { useMemo } from 'react';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import Utils from '../utility/Utils';

const AssignedLaborCodesTable = (props) => {

    const controller = props.controller;
    const data = controller.state.assignedLaborCodes || [];   

    const handleRemoveRow = (item) => {
        var removeRow = props.removeRow.bind(props.controller, item);
        removeRow();
    }

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'delete',
                isVisible: props.removeRow,
                Cell: (cellInfo) => <ActionIcon icon='fa-trash' className='remove-handle' action={() => { handleRemoveRow(cellInfo.row.original)}} />
            },
            {
                Header: 'Mfg Part #',
                accessor: 'mfgItemNo',
            },
            {
                Header: 'Manufacturer Description',
                accessor: 'manDescDescription',
            },
            {
                Header: 'Labor Code',
                accessor: 'laborCodeName',
            },
            {
                Header: 'Is Reject Labor Code',
                accessor: 'isRejectLaborCode',
                Cell: (cellInfo) => Utils.formatBool(cellInfo.cell.value)
            },
            {
                Header: 'Labor Code Description',
                accessor: 'laborCodeDescription',
            },
        ],
        [props.removeRowFn]
    )

    return (
        <div>
            <Grid columns={columns} data={data} showPagination={false} noDataText={props.isLoading ? 'Loading assigned labor codes that meet the search criteria defined above' : 'No assigned labor codes meet the search criteria defined above'} />
        </div>
    )
}

export default AssignedLaborCodesTable;