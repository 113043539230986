import React from 'react';
import Form from '../../components/Form';

const SearchOptions = (propsParam) => {

    const props = {
        title                 : 'Search Options',
        containsEnabled       : true,
        doesNotContainEnabled : true,
        startsWithEnabled     : true,
        endsWithEnabled       : true,
        exactMatchEnabled     : true,
        showAllResultsEnabled : true,
        ...propsParam
    }

    const binder       = props.binder;
    const enabled      = props.enabled !== undefined ? props.enabled : true;

    return(
        <div>
            <Form.Label name={props.title} />
            {props.containsEnabled       && <Form.RadioInput enabled={enabled} disabled={!enabled} bind={binder.bind('searchContains')} label='Contains' />}
            {props.doesNotContainEnabled && <Form.RadioInput enabled={enabled} disabled={!enabled} bind={binder.bind('searchDoesNotContain')} label='Does Not Contain' />}
            {props.startsWithEnabled     && <Form.RadioInput enabled={enabled} disabled={!enabled} bind={binder.bind('searchStartsWith')} label='Starts with' />}
            {props.endsWithEnabled       && <Form.RadioInput enabled={enabled} disabled={!enabled} bind={binder.bind('searchEndsWith')} label='Ends with' />}
            {props.exactMatchEnabled     && <Form.RadioInput enabled={enabled} disabled={!enabled} bind={binder.bind('searchExactMatch')} label='Exact match' />}
            {props.showAllResultsEnabled && <div className='d-inline-flex'><Form.CheckBox className='ml-2 mr-1' bind={binder.bind('showAllResults')} /><Form.Label name='Show All Results' /></div>}
        </div>
    );
}

export default SearchOptions;