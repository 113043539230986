import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import ChangeAssetOwnershipDialog from '../dialogs/ChangeAssetOwnershipDialog';
import ChangeAssetOwnershipController from '../controllers/ChangeAssetOwnershipController';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import UpdateBelowMinimumReadingsDialog from '../dialogs/UpdateBelowMinimumReadingsDialog';
import UpdateBelowMinimumReadingsController from './UpdateBelowMinimumReadingsController';
import TechnicalDocRowController from './TechnicalDocRowController';
import PSMDetailsDialog from '../dialogs/PSMDetailsDialog';
import PSMDetailsController from './PSMDetailsController';

class SerializedAssetDetailController extends BaseController {
  constructor() {
    super('serialized-asset', {
      currentAsset: '',
      files: [],
      loading: false,
      assetId: null,
      certs: []
    });

    messages.channel('edit-asset').action('saved').subscribe(savedAsset => {
      this.state.currentAsset = savedAsset;
      this.commit();
    });

    // notification from server
    messages.channel('edit-asset').action('updated').subscribe(asset => {
      var assetId = asset && asset.assetId;
      if (!assetId) {
        return;
      }

      var currentAsset = this.state.currentAsset;
      if (currentAsset && (currentAsset.assetId === assetId)) {
        this.loadAsset(assetId);
      }
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state.currentAsset = '';
        this.state.certs = [];
        this.commit();
      }
    });

    messages.channel('change-facility').action('changed').subscribe(() => {
      this.state.currentAsset = '';
      this.state.certs = [];
      this.commit();
    });
  }

  toggleCollapsed(performedServiceLevel) {
    performedServiceLevel.collapsed = !performedServiceLevel.collapsed;
    this.commit();
  }

  collapseAllServiceLevels(asset) {
    asset.serviceHistory.forEach(sh => sh.performedServiceLevels.forEach(sl => sl.collapsed = true));
  }

  loadAsset(assetId) {
    const self = this;

    co(this, function* () {
      try {
        if (assetId) {
          this.state.currentAsset = '';
          this.state.certs = [];
          this.state.loading = true;
          var result = yield ajax.get('lookup/getSerializedAsset/', { assetId: assetId }); // SerializedAssetDto
          this.state.loading = false;

          if (result !== null) {
            if (LoginService.isCustomer && LoginService.loginInfo.customerName != result.customer.name) {
              notification.action('error').post({ title: 'InteServ Security Violation', msg: 'You do not have the appropriate security rights to view this asset Information' });
            }
            else {
              this.collapseAllServiceLevels(result);
              this.state.currentAsset = result;
              this.state.certs = result.certificateAttachments
              this.commit();
            }
          }
        }
      }
      catch (err) {
        notification.action('error').post(err.message);
      }
    });
  }

  remove(asset, cb) {
    co(this, function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={250} header='Confirm Asset Removal'>
          <div>
            <h4>The asset with serial number {asset.primarySerialNumber} will be permanently removed from the system</h4>
            <h5 style={{ marginBottom: '10px' }}>Please confirm you wish to continue</h5>
          </div>
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }

      yield ajax.post('receiving/removeAsset', { assetId: asset.assetId }); // in: RemoveAssetDto
      notification.action('success').post(`Asset ${asset.primarySerialNumber} removed successfully`);
      if (cb) {
        cb(true);
      }
    });
  }

  changeOwnership = (asset) => {
    co(this, function* () {
      const routedFrom = 'AssetDetail';
      yield Dialog.showDialogWaitForResult(<ChangeAssetOwnershipDialog controller={new ChangeAssetOwnershipController(asset, routedFrom)} />);
    });
  }

  uploadCertFile = (res, file) => {
    const newFile = {
      certificateId: file.id,
      fileName: file.fileName,
      fileSize: res.file.size,
      fileType: file.fileType,
      imgUrl: res.imageUrl,
      serialGuid: file.serialGuid,
      dateAdded: Date.now()
    };
    this.saveCert(newFile);
  }



  arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
  }

  loadCerts = (assetId) => {
    this.state.loading = true;
    this.state.assetId = assetId ? assetId : this.state.assetId;
    if (this.state.assetId) {
      ajax.get('lookup/getcertificates/', { serialId: this.state.assetId }).then(results => {
        if (results !== null) {
          this.state.certs = results;
        }
        this.state.loading = false;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
    this.commit();
  }

  saveAfterDialog = (newFile, overwrite) => {
    const s = this.state;
    this.state.loading = true;
    const parameters = {};
    parameters.files = [newFile];
    parameters.overwrite = overwrite;

    if (!overwrite)
      s.certs.push(newFile);
    ajax.post('customercert/uploadcertificate', parameters).then(() => {
      notification.action('success').post('Asset Certificate uploaded.');
      this.loadCerts(s.currentAsset.assetId);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
    this.commit();
  }

  saveCert = (newFile) => {
    const s = this.state;
    const oldfile = s.certs.find(f => {
      return f.fileName === newFile.fileName
    });

    // if(oldfile) {

    co(this, function* () {
      if (oldfile) {
        var result = yield Dialog.showDialogWaitForResult(
          <Dialog.OKCancelDialog className='delete-confirm-dialog' header='Confirm overwrite'>
            {'Are you sure you want to overwrite the file'}
          </Dialog.OKCancelDialog>
        );
        if (!result.OK) {
          this.commit();
          return;
        }

        //update old certificate id to new file
        newFile.certificateId = oldfile.certificateId;
      }
      this.saveAfterDialog(newFile, oldfile ? true : false);
    });
    // }
  }

  viewCertFile = (certId) => {
    if (certId) {
      this.state.cert = {};
      this.state.loading = true;

      ajax.get('customercert/getcertificate/', { certId: certId }).then(results => {
        this.state.loading = false;
        if (results !== null) {
          let fType = results.files[0].fileType;
          let fName = results.files[0].fileName;
          let b64String = results.files[0].imgUrl;

          let b64File = Utils.contains(b64String, ';base64,') ? b64String.split(';base64,')[1] : b64String;

          let applicationType = 'plain/text';
          switch (fType) {
            case 'txt':
              applicationType = 'plain/text'; break;
            case 'pdf':
            case '.pdf':
              applicationType = 'application/pdf'; break;
            case 'gif':
              applicationType = 'image/gif'; break;
            case 'jpg':
            case 'jpeg':
            case 'png':
              applicationType = 'image/jpg'; break;
          }


          if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/Chrome/i) != null) {
            const blob = Utils.convertBase64toBlob(b64File, applicationType);
            const fileName = fName;
            const fileURL = URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.download = fileName;
            tempLink.click();
            tempLink.remove();
          } else {
            let file = Utils.convertBase64toBlob(b64File, applicationType);
            let fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
          }
          // const blob = Utils.convertBase64toBlob(b64File, applicationType);
          // const fileURL = URL.createObjectURL(blob);
          // const tempLink = document.createElement('a');
          // tempLink.href = fileURL;
          // tempLink.download = fName;
          // tempLink.click();
          // tempLink.remove();  

          // URL.revokeObjectURL(blobUrl);
        }
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
  }

  deleteCertFile = (certId) => {
    this.state.loading = true;
    ajax.delete('customercert/removecertificate/', { certId: certId }).then(() => {
      notification.action('success').post('Asset certificate successfully deleted.');
      this.loadCerts(this.state.currentAsset.assetId);
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  downloadCoC = (serial) => {
    ajax.getExternal(AppConstants.CoCReportAPIURL, { serialNumbers: serial }).then(response => {
      if (response && response.File) {
        const pdfData = response.File;
        const blob = Utils.convertBase64toBlob(pdfData, 'application/pdf');
        const fileName = 'Certificate' + '.pdf';
        const fileURL = URL.createObjectURL(blob);

        if (navigator.userAgent.match(/iPad/i) != null) {
          window.open(fileURL);
        }

        else {
          const tempLink = document.createElement('a');
          tempLink.href = fileURL;
          tempLink.download = fileName;
          tempLink.click();
          tempLink.remove();
        }
      }
      else {
        notification.action('error').post({ msg: 'Invalid Serial to download certificate', title: 'Error' });
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  downloadCoCWithGUID = (serialGUID) => {
    ajax.post('security/getSerialNumberForCoc', serialGUID).then(serial => {
      if (serial) {
        this.downloadCoC(serial);
      }
      else {
        notification.action('error').post({ msg: 'Invalid Serial to download certificate', title: 'Error' });
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  UnscrapAsset = () => {
    const asset = this.state.currentAsset;
    co(this, function* () {
      try {
        if (asset.jobSOWId) {
          var response = yield ajax.get('gauge/unscrapAsset/', { jobSowId: asset.jobSOWId, assetId: asset.assetId });
          if (response && !response.unscrapped) {
            yield Dialog.showDialogWaitForResult(<UpdateBelowMinimumReadingsDialog controller={new UpdateBelowMinimumReadingsController(asset, response.readings)} />);
          }
          else {
            notification.action('success').post('Asset unscrapped successfully');
            this.loadAsset(asset.assetId);
          }
          this.commit();
        }
      }
      catch (error) {
        notification.action('error').post(error.message);
      }
    });
  }

  getAssetUtilizationByteData = () => {
    const assetId = this.state.currentAsset.assetId;
    const primarySerial = this.state.currentAsset.primarySerialNumber;
    const dateStamp = DateTimeFormatter.formatFileNameDate(DateTimeFormatter.today());
    const fileName = 'Asset_Utilization_' + primarySerial+'_'+dateStamp + '.xlsx';

    ajax.post('lookup/exportAssetUtilizationExcel', { assetId }).then(excelData => {
      this.export(excelData,fileName);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });

  }

  getServiceHistoryByteData = () => {
    const assetId = this.state.currentAsset.assetId;
    const primarySerial = this.state.currentAsset.primarySerialNumber;
    const dateStamp = DateTimeFormatter.formatFileNameDate(DateTimeFormatter.today());
    const fileName = 'Service History_' + primarySerial+'_'+dateStamp + '.xlsx';

    ajax.post('lookup/exportServiceHistoryExcel', { assetId }).then(excelData => {
      this.export(excelData,fileName);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });

  }
  getContainerHistoryByteData = () => {
    const assetId = this.state.currentAsset.assetId;
    const primarySerial = this.state.currentAsset.primarySerialNumber;
    const dateStamp = DateTimeFormatter.formatFileNameDate(DateTimeFormatter.today());
    const fileName = 'Container History_' + primarySerial+'_'+dateStamp + '.xlsx';

    ajax.post('lookup/exportContainerHistoryExcel', { assetId }).then(excelData => {
      this.export(excelData,fileName);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });

  }

  export = (excelData, fileName) => {
    co(this, function* () {
      try {
        const s = this.state;
        const blob = Utils.convertBase64toBlob(excelData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const fileURL = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.download = fileName;
        if (navigator.userAgent.match(/iPad/i) != null) {
          window.open(fileURL);
        }
        else {
          tempLink.click();
        }
        tempLink.remove();
        notification.action('success').post('Exported successfully');
      }
      catch (err) {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }
    });
  }
  getTechnicalDocumentRows = () => {
    const rows = [];
    const technicalDocuments = this.state.currentAsset.technicalDocuments;
    if (technicalDocuments && technicalDocuments.length > 0) {
      technicalDocuments.forEach((x) => { rows.push(new TechnicalDocRowController(x.Document_GUID, x.Cust_Desc_GUID, x.Document_Name, x.Document_Link)) });
    }
    return rows;
  }
  viewTechnicalDoc = () => {
    const custDescId = this.state.currentAsset.description.id;
    const technicalDocuments = this.getTechnicalDocumentRows();
    co(this, function* () {
      Dialog.showDialog(<PSMDetailsDialog controller={new PSMDetailsController(this, '', '', '', '', '', true, true, custDescId, technicalDocuments)} header="Technical Documents" />);
    });
  }

}


export default SerializedAssetDetailController;