import store from 'store';
import AdhocTransactionRowController from '../controllers/AdhocTransactionRowController';
import EstimatedAdhocTransactionRowController from '../controllers/EstimatedAdhocTransactionRowController';
import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import SAPService from '../services/SAPService';


class AdhocTransactionsController extends BaseController {
  constructor() {
    super('adhoc-transactions', {
      job: '',
      adhocServiceOrder: '',
      transactions: [],
      rows: [],
      errors: {},
      isSaveInProgress: false,
      estimatedTransaction: []
    });

    this.operations = [];

    messages.channel('login').action('loggedin').subscribe(loginInfo => {
      if (loginInfo) {
        this.loadOperations();
      }
    });
  }

  load = (jobId) => {
    this.clear();
    if (!jobId) {
      this.commit();
      return;
    }

    co(this, function* () {
      try {
        this.state.job = yield ajax.get('lookup/getJob', { jobId: jobId }); // JobLookupDto
        this.state.adhocServiceOrder = yield ajax.get('lookup/getAdhocServiceOrder', { jobId: jobId }); // JobAdhocServiceOrderDto
        this.state.transactions = yield ajax.get('lookup/getAdhocTransactions', { jobId: jobId }); // IList<AdhocTransactionDto>
        const adhoctrans = yield ajax.get('lookup/getEstimatedAdhocTransactions', { jobId: jobId }); // IList<AdhocTransactionDto> 
        this.state.estimatedTransaction = [];
        this.pushEstimatedTransactionRow(adhoctrans);
        this.commit();
      }
      catch (err) {
        notifications.action('error').post({ msg: err.message, title: 'Error loading adhoc transactions' });
      }
    });
  }

  loadOperations = () => {
    co(this, function* () {
      const results = yield ajax.get('lookup/getAdhocServiceLevelOperations'); // IList<LookupDto>
      this.operations = results;
      return results;
    });
  }
  pushEstimatedTransactionRow = (transactions) => {
    transactions.forEach(row => {
      this.state.estimatedTransaction.push(new EstimatedAdhocTransactionRowController(this, row));
    });
  }
  operationSearch = () => {
    return function* () {
      // if (self.operations.length) {
      //   return self.operations;
      // }

      // return self.loadOperations();
      const results = yield ajax.get('lookup/getAdhocServiceLevelOperations');
      return results;
    };
  }

  estimatedOperationSearch = () => {
    const self = this;
    return function* () {
      let results = yield ajax.get('lookup/getAdhocServiceLevelOperations');
      const operations = self.state.estimatedTransaction.map(item => item.state.operation ? item.state.operation.name : '').filter(item => item);
      results = results.filter(item => !operations.includes(item.name));
      return results;
    };
  }

  clear = () => {
    this.state.job = '';
    this.state.transactions = [];
    this.state.rows = [];
    this.state.errors = {};
    this.state.estimatedTransaction = [];
  }

  addNewRow = () => {
    this.state.rows.push(new AdhocTransactionRowController(this));
    this.commit();
  }
  addNewEstimatedRow = () => {
    this.state.estimatedTransaction.push(new EstimatedAdhocTransactionRowController(this, ''));
    this.commit();
  }
  removeRow = (row) => {
    const idx = this.state.rows.indexOf(row);
    if (idx === -1) {
      return;
    }

    this.state.rows.splice(idx, 1);
    this.commit();
  }
  removeEstimateRow = (row) => {
    const idx = this.state.estimatedTransaction.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.estimatedTransaction.splice(idx, 1);
    this.commit();
  }
  validateRows = (state) => {
    this.state.rows.forEach(row => {
      const operation = row.state.operation;
      const unitOfMeasure = row.state.unitOfMeasure;
      const value = row.state.value;
      let hours = row.state.hours;
      let minutes = row.state.minutes;

      if (operation) {
        delete row.state.errors.operation;
      }
      else {
        row.state.errors.operation = 'An Operation must be selected';
      }

      delete row.state.errors.value;
      delete row.state.errors.hours;
      delete row.state.errors.minutes;

      if (unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) {
        if (!value) {
          row.state.errors.operation = ' ';
          row.state.errors.value = 'A Value must be specified';
        }
      }
      else {
        // if(technician)
        // {
        //   delete row.state.errors.technician;
        // }
        // else
        // {
        //   row.state.errors.technician = 'Technician must be specified';          
        // }

        // if(date === '')
        // {
        //   row.state.errors.date = 'Date must be specified';
        // }
        // else
        // {
        //   delete row.state.errors.date;
        // }
        // const compareTo=moment(date).format("YYYY-MM-DD");
        // const Today=moment().startOf('day').format("YYYY-MM-DD");
        // const isLessThanEquals = (compareTo <= Today);
        // if(!isLessThanEquals)
        // {
        //   row.state.errors.date = 'Date must be less than or equal to today';
        // }
        // else
        // {
        //   delete row.state.errors.date;       
        // }
        if (hours > 24) {
          row.state.errors.hours = 'Hours cannot be more than 24';
        }
        if (minutes > 60) {
          row.state.errors.minutes = 'Minutes cannot be more than 60';
        }
        hours = parseFloat(isNaN(hours) ? 0 : hours);
        minutes = parseFloat(isNaN(minutes) ? 0 : minutes);
        const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
        if (value <= 0) {
          row.state.errors.hours = 'A value for either Hours or Minutes (or both) must be specified';
        }
        else if (parseFloat(value.toFixed(1)) <= 0) {
          row.state.errors.minutes = 'After rounding to 1 decimal, time will become 0. Adhoc Time should be greater than 0';
        }
        else if (value > 1440) {
          row.state.errors.hours = 'Time cannot be more than 24';
        }
        // if (!hours && !minutes) {
        //   row.state.errors.hours = 'A value for either Hours or Minutes (or both) must be specified';
        // }
        // else {
        //   if (hours === '') {
        //     row.state.errors.hours = 'Hours must be specified';
        //   }
        //   if (minutes === '') {
        //     row.state.errors.minutes = 'Minutes must be specified';
        //   }
      }
    });
  }
  validateEstimatedRows = (state) => {
    state.estimatedTransaction.forEach(row => {
      const operation = row.state.operation;
      const unitOfMeasure = row.state.unitOfMeasure;
      const value = row.state.value;
      let   hours = row.state.hours;
      let   minutes = row.state.minutes;
      const technician = row.state.technician;
      const date = row.state.date;
      if (operation) {
        delete row.state.errors.operation;
      }
      else {
        row.state.errors.operation = 'An Operation must be selected';
      }
      delete row.state.errors.value;
      delete row.state.errors.hours;
      delete row.state.errors.minutes;

      if (unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) {
        if (value < 0) {
          row.state.errors.operation = ' ';
          row.state.errors.value = 'A Value must be specified';
        }
      }
      else {
        // if(hours>23)
        // {         
        //   row.state.errors.hours = 'Hours cannot be more than 23';
        // }
        if (minutes > 60) {
          row.state.errors.minutes = 'Minutes cannot be more than 60';
        }
        hours = parseFloat(isNaN(hours) ? 0 : hours);
        minutes = parseFloat(isNaN(minutes) ? 0 : minutes);
        const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
        if (value < 0) {
          row.state.errors.hours = 'A value for either Hours or Minutes (or both) must be specified';
        }
        else if (parseFloat(value.toFixed(1)) < 0) {
          row.state.errors.minutes = 'After rounding to 1 decimal, time will become 0. Estimated Adhoc Time should be greater than 0';
        }
      }
    });
  }
  calculateValue = (transaction) => {
    if (transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) {
      return transaction.value;
    }
    const hours = parseFloat(isNaN(transaction.hours) ? 0 : transaction.hours);
    const minutes = parseFloat(isNaN(transaction.minutes) ? 0 : transaction.minutes);
    const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
    return value;
  }
  calculateTimeValue = (transaction) => {
    if (transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) {
      return 0;
    }
    const hours = parseFloat(isNaN(transaction.hours) ? 0 : transaction.hours);
    const minutes = parseFloat(isNaN(transaction.minutes) ? 0 : transaction.minutes);
    const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
    return value;
  }

  canSave = () => {
    if (this.state.rows.length == 0)
      return false;
    this.validateRows(this.state);

    const rowErrors = this.state.rows.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!rowErrors) {
      return true;
    }

    this.commit();
    return false;
  }
  canSaveEstimated = () => {
    if (this.state.estimatedTransaction.length == 0)
      return false;
    this.validateEstimatedRows(this.state);
    const estimatedRowErrors = this.state.estimatedTransaction.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!estimatedRowErrors) {
      return true;
    }
    this.commit();
    return false;
  }
  save = () => {

    if (!this.canSave()) {
      return;
    }
    const loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notifications.action('error').post("Currently Not Clocked In");
        return;
      }
      if (loginInfo.isClockedOut) {
        notifications.action('error').post("ClockedOut for the day");
        return;
      }
      if (loginInfo.isBreakOut) {
        notifications.action('error').post("Currently on Unpaid break");
        return false;
      }
      const transactions = this.state.rows.map(row => { return { value: this.calculateTimeValue(row.state) }; });
      let TotalValue = 0;
      transactions.forEach(element => {
        TotalValue = TotalValue + element.value;
      });
      const availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (TotalValue > availableTime) {
        notifications.action('error').post("Adhoc Time should not be more than Available Production Time");
        return;
      }
    }

    co(this, function* () {
      try {
        const transactions = this.state.rows.map(row => {
          return {
            adhocServiceLevelOperationId: row.state.operation.id,
            value: this.calculateValue(row.state),
            notes: row.state.notes,
            technician: row.state.technician,
            date: DateTimeFormatter.formatDate(row.state.date)
          };
        });
        this.state.isSaveInProgress = true;
        this.commit();
        const transactionCount = transactions.length;
        const jobId = this.state.job.id;
        const parameters = {
          jobId: jobId,
          adhocServiceOrderId: this.state.adhocServiceOrder.adhocServiceOrderId,
          transactions: transactions,
          today: DateTimeFormatter.formatDate(DateTimeFormatter.today())
        };

        const prodTime = yield ajax.post('jobprogress/addAdhocTransactions', parameters); // in: AddAdhocTransactionDto, out: void
        const msg = 'Added ' + Utils.pluralize('adhoc transaction', 's', transactionCount) + ' for job ' + this.state.job.jobNumber;
        //messages.channel('productiveTime').action('update').post(prodTime);
        notifications.action('info').post({ msg: msg, title: 'Adhoc Transaction' });
        this.state.rows = [];
        this.state.transactions = yield ajax.get('lookup/getAdhocTransactions', { jobId: jobId }); // IList<AdhocTransactionDto>
        this.state.isSaveInProgress = false;
        this.commit();
      }
      catch (err) {
        this.state.isSaveInProgress = false;
        this.commit();
        notifications.action('error').post(err.message);
      }
    });
  }
  saveEstimated() {
    if (!this.canSaveEstimated()) {
      return;
    }
    co(this, function* () {
      try {
        const transactions = this.state.estimatedTransaction.map(row => {
          return {
            adhocServiceLevelOperationId: row.state.operation.id,
            adhocServiceOrderTransactionId: row.state.adhocTransactionId,
            value: this.calculateValue(row.state),
            notes: row.state.notes,
            technician: row.state.technician,
            date: DateTimeFormatter.formatDate(row.state.date),
            recordStatus: row.state.recordStatus
          };
        });
        this.state.isSaveInProgress = true;
        this.commit();
        const transactionCount = transactions.length;
        const jobId = this.state.job.id;
        const parameters = {
          jobId: jobId,
          adhocServiceOrderId: this.state.adhocServiceOrder.adhocServiceOrderId,
          transactions: transactions,
          today: DateTimeFormatter.formatDate(DateTimeFormatter.today())
        };

        const prodTime = yield ajax.post('jobprogress/addEstimatedAdhocTransactions', parameters); // in: AddAdhocTransactionDto, out: void
        //const msg = 'Added ' + Utils.pluralize('estimated adhoc transaction', 's', transactionCount) + ' for job ' + this.state.job.jobNumber;
        const msg = 'Saved ' + Utils.pluralize('estimated adhoc transaction', 's', transactionCount) + ' for job ' + this.state.job.jobNumber;
        //messages.channel('productiveTime').action('update').post(prodTime);
        notifications.action('info').post({ msg: msg, title: 'Estimated Adhoc Transaction' });
        const adhocTransaction = yield ajax.get('lookup/getEstimatedAdhocTransactions', { jobId: jobId }); // IList<AdhocTransactionDto>
        this.state.estimatedTransaction = [];
        this.pushEstimatedTransactionRow(adhocTransaction);
        this.state.isSaveInProgress = false;
        this.commit();
      }
      catch (err) {
        this.state.isSaveInProgress = false;
        this.commit();
        notifications.action('error').post(err.message);
      }
    });
  }
}

export default AdhocTransactionsController;

