import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import SerialReadingRowController from '../controllers/SerialReadingRowController';

class ReadingsConfirmationController extends BaseController {
  constructor(jobId, serials) {
    super('readings-confirmation', {
      jobId: jobId,
      readingsValue: [],
      readings: [],
      readingsHeader: [],
      approveAll: false,
      Serials: serials
    });
    this.saving = false;
    this.getSerialReadings();
  }

  getSerialReadings = () => {
    const self = this;
    co(function* () {
      try {
        const s = self.state;
        const result = yield ajax.get('gauge/getReadingsForConfirmation', { jobId: s.jobId });
        if (result.length) {
          s.readingsValue = result;
          var checkArray = [];
          s.readingsHeader = [];
          s.readingsValue.forEach(row => {
            if (s.Serials.find(item => item == row.serial)) {
              row.approve = false;
              s.readings.push(new SerialReadingRowController(row, self));
              if (!checkArray.includes(row.jobSOWId)) {
                checkArray.push(row.jobSOWId);
                s.readingsHeader.push({ serial: row.serial, description: row.description, jobSOWId: row.jobSOWId });
              }
            }
          });
          self.commit();
        }
      }
      catch (err) {
        notifications.action('error').post({ msg: err.message, title: 'Error in loading serials' });
      }
    });
  }
  save = () => {
    var readings = this.state.readings.map(reading => reading.state) || [];
    var selectedSerial = readings.filter(reading => reading.approve);

    this.saving = true;
    this.commit();
    return ajax.post('gauge/saveSerialReadingsFromDevice', selectedSerial).then(() => {
      this.saving = false;
      this.commit();
      var websocket = new WebSocket("ws://localhost:8068");
      websocket.onopen = function (evt) {
        var param = {
          Type: "Delete",
        };
        websocket.send(JSON.stringify(param))
      };
      websocket.onmessage = function (evt) {
        var responsedata = JSON.parse(evt.data);
        if (responsedata.ResponseType == "S") {
          notifications.action('success').post(responsedata.ResponseMessage);
        }
        else if (responsedata.ResponseType == "E") {
          notifications.action('error').post(responsedata.ResponseMessage);
        }
      };
      websocket.onerror = function (evt) {
        notifications.action('error').post(evt.data);
      };
      messages.channel('reload-asset-readings').action('changed').post();
      notifications.action('success').post('Serial readings updated successfully');
      return true;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notifications.action('error').post({ msg: err.message, title: 'Error saving serial readings Data' });
      return false;
    });
  }

  approveAllChanged = () => {
    var approveAll = this.state.approveAll;
    this.state.readings.map(row => {
      row.state.approve = approveAll;
      row.commit();
    });
    this.commit();
  }
}

export default ReadingsConfirmationController;