import React from 'react';
import { Table as RBTable} from 'react-bootstrap';
import Cx from 'classnames';
import styled from 'styled-components';

const BaseTable = (defaultProps) => {
    const props = {
        hover: true,
        small: true,
        dark: false,
        striped: true,
        bordered: true,
        borderless: false,
        responsive: true,
        ...defaultProps
    }

    return (
        <RBTable {...props} size={props.small ? 'sm' : ''} variant={props.dark ? 'dark' : ''}>
            {props.caption ? <caption>{props.caption}</caption> : null}
            {props.children}
        </RBTable>
    );
}

const Table = styled(BaseTable)`
    thead > tr > th, tbody > tr > td {
        .form-group {
            margin-bottom: 0;
        }
    }
    tbody > tr {
        cursor: pointer;
    }
`;

Table.Header = (props) => {
    return <thead {...props}>{props.children}</thead>;
}

Table.Row = (props) => {
    const classes = Cx({
        'table-primary': props.isSelected,
    });
    const className = props.className ? props.className.concat(' ' + classes) : classes;

    return <tr {...props} className={className}>{props.children}</tr>;
}

Table.Head = (props) => {
    return <th {...props} className={'bg-primary table-dark align-middle text-center justify-content ' + props.className}>{props.children}</th>;
}

Table.Data = (props) => {
    return <td {...props} className={'align-middle ' + props.className}>{props.children}</td>;
}

Table.Body = (props) => {
    return <tbody {...props}>{props.children}</tbody>;
}

Table.Footer = (props) => {
    return <tfoot {...props}>{props.children}</tfoot>;
}

export default Table;