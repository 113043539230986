import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';

class SerialReadingRowController extends BaseController {
  constructor(reading, parentController) {
    super('serial-reading-row'+ SerialReadingRowController.key++, {
      readingId        : reading ? reading.readingId   : '',
      minimumDimension : reading ? reading.minimumDimension : '',
      readingValue     : reading ? reading.readingValue : '',
      newDimension     : reading ? reading.newDimension : '',
      approve          : reading ? reading.approve     : true,
      scopeSerial      : reading ? reading.scopeSerial : '',
      Previous_Reading : reading ? reading.Previous_Reading : 0,
      jobSOWId         : reading ? reading.jobSOWId : Utils.emptyGuid,
      serial           : reading ? reading.serial   : Utils.emptyGuid
    });
    this.parentController = parentController;
  }

  static key = 1;

  getClassName() {
    var className = '';
    var readingValue = this.state.readingValue;
    var previousReading = this.state.Previous_Reading;
    var minimumDimension = this.state.minimumDimension;
    if (!readingValue) {
      className = 'reading-missing';
    }
    else {
      if (readingValue < minimumDimension) {
        className = 'below-min';
      }
      else if (minimumDimension && readingValue < (minimumDimension * 1.1)) {
        className = 'near-min';
      }
      else if (previousReading && previousReading > 0) {
        if (readingValue >= (previousReading + 0.03)) {
          className = 'above-prev';
        }
        else if (readingValue < (previousReading - 0.05)) {
          className = 'below-prev';
        }
      }
    }
    return className;
  }  
}

export default SerialReadingRowController;
