import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import ReceivingTicketDetailController from '../controllers/ReceivingTicketDetailController';
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import AppConstants from '../utility/AppConstants';

const ReceivingTicketHeader = (props) => {
  const ticket = props.ticket;

  return (
    <div>
      <div className='row'>
        <div className='col-md-4'><Form.StaticData label='Receiving Ticket #' value={ticket.ticketNumber} /></div>
        <div className='col-md-4'> <Form.StaticData label='FMC Job Number' value={ticket.jobNumber} /></div>
        <div className='col-md-4'><Form.StaticData label='Receiving Date' value={DateTimeFormatter.formatDate(ticket.receivedDate)} /></div>
      </div>

      <div className='row'>
        <div className='col-md-4'><Form.StaticData label='Receiving ID' value={ticket.receivingID} /></div>
        <div className='col-md-4'><Form.StaticData label='Customer Job Number' value={ticket.customerJobNumber} /></div>
      </div>
    </div>
  );
}

const ReceivingTicketLocations = (props) => {

  const getToAddressElements = (facility) => {
    let elements = [];
    if (facility) {
      elements.push(facility.facilityName);
      elements.push(facility.address1);
      elements.push(facility.address2);
      elements.push(facility.address3);
      elements.push(facility.cityState);
      if (facility.phone) {
        elements.push('Phone: ' + facility.phone);
      }
    }
    return elements;
  }

  const getFromFacilityAddressElements = (customerLocation) => {
    let elements = [];
    if (customerLocation) {
      elements.push(customerLocation.customerName);
      elements.push(customerLocation.locationName);
      elements.push(customerLocation.address1);
      elements.push(customerLocation.address2);
      elements.push(customerLocation.cityState);
    }
    return elements;
  }

  const ticket = props.ticket;
  const border = 'solid 1px #CCC';
  const toAddress = getToAddressElements(ticket.receivingFacility)
    .filter(elem => elem !== '')
    .map(elem => <Form.StaticData style={{ marginBottom: 0 }} value={elem} />);
  const fromAddress = getFromFacilityAddressElements(ticket.customerLocation)
    .filter(elem => elem !== '')
    .map(elem => <Form.StaticData style={{ marginBottom: 0 }} value={elem} />);

  return (
    <div className='col-12 mb-2' style={{ border: border }}>
      <div className='row'>
        <div className='col-6' style={{ borderRight: border }}>
          <Form.StaticData label='To' style={{ marginBottom: 0 }} />
          {toAddress}
        </div>

        <div className='col-6'>
          <Form.StaticData label='From' style={{ marginBottom: 0 }} />
          {fromAddress}
        </div>
      </div>
    </div>
  );
}

const ReceivingTicketNotes = (props) => {

  const handleEdit = () => {
    props.controller.editNote();
  }

  const handleSave = () => {
    props.controller.saveNotes();
  }

  const handleCancel = () => {
    props.controller.cancelSaveNotes();
  }

  const binder = props.binder;
  const ticket = props.ticket;
  const buttons = props.editing
    ? <div className='no-print'>
      <Button icon='fa-save' variant='success' size='sm' className='mr-1 action-button' onClick={handleSave}>Save</Button>
      <Button icon='fa-times' variant='secondary' size='sm' className='mr-1 action-button' onClick={handleCancel}>Cancel</Button>
    </div>
    : <Button icon='fa-pencil=alt' variant='secondary' size='sm' className='no-print mr-1' onClick={handleEdit}>Edit</Button>;
  const ctlNote = props.editing
    ? <Form.TextArea rows={3} label='Notes' bind={binder.bind('notes')} />
    : <div className='row col-xs-12' style={{ marginTop: '10px' }}><Form.StaticData label='Notes' value={ticket.notes} /></div>;
  const ctlSystemNote = ticket.systemNotes
    ? <div className='col-sm-12 no-print' style={{ marginTop: '10px' }}>
      <Form.StaticData label='System Notes' value={Utils.makeParagraphElements(ticket.systemNotes, { margin: '0' })} className='Receiving-ticket-system-note' />
    </div>
    : null;

  return (
    <div>
      {ctlNote}
      {buttons}
      {ctlSystemNote}
    </div>
  );
}

const ReceivingTicketAssetHeader = (props) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.Head>Item</Table.Head>
        <Table.Head>Primary</Table.Head>
        <Table.Head>Secondary</Table.Head>
        <Table.Head>Equipment Number</Table.Head>
        <Table.Head>Description</Table.Head>
        <Table.Head>Asset Status</Table.Head>
      </Table.Row>
    </Table.Header>
  );
}

const AssetRow = (props) => {

  const asset = props.asset;
  const item = props.item;
  let statusColourClass = 'status' + asset.assetStatus; // e.g.: statusScrapped
  if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
    statusColourClass = 'asset-status statusWeldRepair'
  if (asset.assetStatus === 'OS/Machining Repair') 
    statusColourClass = 'asset-status statusOSRepair'
  const alignmentStyle = { textAlign: 'center' };

  return (
    <Table.Row>
      <Table.Data style={alignmentStyle}>{item}</Table.Data>
      <Table.Data>{asset.primarySerialNumber}</Table.Data>
      <Table.Data>{asset.secondarySerialNumber}</Table.Data>
      <Table.Data>{asset.equipmentNumber}</Table.Data>
      <Table.Data className='col-wrap'>{asset.description}</Table.Data>
      <Table.Data style={alignmentStyle} className={statusColourClass}>{asset.assetStatus}</Table.Data>
    </Table.Row>
  );
}

const TotalAssetsCount = (props) => {

  const ticket = props.ticket;
  const count = ticket && ticket.assets && ticket.assets.length;

  return <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold'>Total Assets Received: {count}</Table.Data></Table.Row>;
}


const Buttons = (props) => {
  let history = useHistory();

  const handleClose = () => {
    history.goBack();
  }

  const editing = props.controller.state.editingNote;

  return (
    <div className='col-12 no-print'>
      <span className='d-flex justify-content-end'>
        <Button id='btn-cancel' variant='secondary' size='sm' className='action-button' onClick={handleClose} disabled={editing} >Close</Button>
      </span>
    </div>
  );
}

const ReceivingTicketDetailPageHeader = (props) => <PageHeader title={props.pageTitle} showBackNavigation={true} />


class ReceivingTicketDetailPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const ticketId = this.props.match.params.ticketId;
    this.props.controller.loadTicket(ticketId);
  }
  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }
  static defaultProps = {
    controller: new ReceivingTicketDetailController()
  }
  render = () => {
    const controller = this.props.controller;
    const ticket = controller.state;
    const title = 'Receiving Ticket - ' + ticket.ticketNumber;
    let assets = (ticket && ticket.assets) || [];
    let item = 1;
    const passedAssets = assets
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Passed)
      .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
    const scrappedAssets = assets
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped)
      .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
    const unknownAssets = assets
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Unknown)
      .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
    const pendingAssets = assets
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Pending)
      .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
    const osRepairAssets = assets
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.OSRepair)
      .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
    const weldRepairAssets = assets
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.WeldRepair)
      .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
    const failedInspectionAssets = assets
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.FailedInspection)
      .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);

    const passedAssetsHeader = passedAssets.length
      ? <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold statusPassed'>Passed = {passedAssets.length}</Table.Data></Table.Row>
      : null;

    const scrappedAssetsHeader = scrappedAssets.length
      ? <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold statusScrapped'>Scrapped = {scrappedAssets.length}</Table.Data></Table.Row>
      : null;

    const unknownAssetsHeader = unknownAssets.length
      ? <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold statusUnknown'>Unknown = {unknownAssets.length}</Table.Data></Table.Row>
      : null;

    const pendingAssetsHeader = pendingAssets.length
      ? <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold statusPending'>Pending = {pendingAssets.length}</Table.Data></Table.Row>
      : null;
    const osRepairAssetsHeader = osRepairAssets.length
      ? <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold statusOSRepair'>OS/Machining Repair = {osRepairAssets.length}</Table.Data></Table.Row>
      : null;
    const weldRepairAssetsHeader = weldRepairAssets.length
      ? <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold statusWeldRepair'>Weld Repair = {weldRepairAssets.length}</Table.Data></Table.Row>
      : null;
    const failedInspectionAssetsHeader = failedInspectionAssets.length
      ? <Table.Row><Table.Data colSpan={6} className='field-data font-weight-bold statusFailedInspection'>Failed Inspection = {failedInspectionAssets.length}</Table.Data></Table.Row>
      : null;

    return (
      <Page pageTitle='InteServ · Receiving Ticket Detail' id='Receiving-ticket-detail-page'>
        <ReceivingTicketDetailPageHeader pageTitle={title} />
        <Page.Content>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12'>
                <ReceivingTicketHeader ticket={ticket} />
              </div>
              <div className='col-12'>
                <ReceivingTicketLocations ticket={ticket} />
              </div>
              {/* <ReceivingTicketNotes     ticket={ticket} controller={controller} binder={binder} editing={controller.state.editingNote} className='no-pod-upload' /> */}

              <div id='search-results' className='form-group col-12 table-container'>
                <Table id='results-table'>
                  <ReceivingTicketAssetHeader />
                  <Table.Body>
                    {passedAssetsHeader}
                    {passedAssets}
                    {scrappedAssetsHeader}
                    {scrappedAssets}
                    {osRepairAssetsHeader}
                    {osRepairAssets}
                    {weldRepairAssetsHeader}
                    {weldRepairAssets}
                    {failedInspectionAssetsHeader}
                    {failedInspectionAssets}
                    {unknownAssetsHeader}
                    {unknownAssets}
                    {pendingAssetsHeader}
                    {pendingAssets}
                    <TotalAssetsCount ticket={ticket} />
                  </Table.Body>
                </Table>
              </div>
              <Buttons controller={controller} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default ReceivingTicketDetailPage;
