import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification'
import Utils from '../utility/Utils';
import messages from '../../services/Messages';

class LaborCodeController extends BaseController {
  constructor() {
    super('laborCodes', {
      searchResults : [],
      selectedItem  : '',
      searchText    : ''
    });

    messages.channel('edit-labor-code').action('saved').subscribe(laborCodeId => {
      this.search(laborCodeId);
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state.searchText    = '';
        this.state.selectedItem  = '';
        this.state.searchResults = [];
      }
    });
  }

  isSelected = (laborCode) => {
    if (!this.state.selectedItem) {
      return false;
    }
    return (laborCode.laborCodeId === this.state.selectedItem.laborCodeId);
  }

  select = (laborCode) => {
    this.state.selectedItem = laborCode;
    this.commit();
  }

  search = (selectedLaborCodeId) => {
      this.state.results = [];
      const searchText     = this.state.searchText;
      const parameters     = {
        searchTerm : searchText
      };

      this.state.isLoading     = true;
      ajax.get('lookup/searchLaborCode', parameters).then(results=>{
        this.state.isLoading     = false;
        this.state.searchResults = results;
  
        if (selectedLaborCodeId) {
          // labor code to select was specified
          this.selectLaborCode(selectedLaborCodeId);
        }
        else if (results.length > 0) {
          // auto-select the 1st customer - this will be the newest one based on ordering
          this.state.selectedItem = results[0];
          this.selectLaborCode(results[0].laborCodeId);
        }
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<LaborCodeDto>
  }

  clearSearch = () => {
    this.state.searchText = '';
    this.state.searchResults = [];
    this.commit();
    document.getElementById('searchText').focus();
  }

  selectLaborCode = (laborCodeId) => {
    this.state.searchResults.forEach(item => {
      if (item.laborCodeId === laborCodeId) {
        this.select(item);
      }
    });
  }

  new = () => {
    this.state.selectedItem = {
      laborCodeId       : Utils.emptyGuid,
      laborCodeName     : '',
      description       : '',
      isRejectLaborCode : false,
      multiRejectCapable: false
    };
    document.getElementById('laborCode').focus();
    this.commit();
  }
}

export default LaborCodeController;