import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class CompletionDateDialog extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    const assets = this.props.assets;
    this.props.controller.load(assets);
  }
  componentDidMount = () => {
    this.initializeMixin();
  }


  handleClose = (result) => (result.Close) ? true : this.props.controller.save();

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const errors = controller.state.errors;
    const title = 'Scheduled completion date';

    return (
      <Dialog className='completion-date-dialog' {...this.props} height={200} width={300} modal={true} header={title} onClose={this.handleClose}>
        <Dialog.Body>
          <div className='col-md-12'>
            <div className='row'><div className='col'><Form.Date label='Completion date' className='required' bind={binder.bind('completionDate')} error={errors.completionDate} options={{ startDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()), orientation: 'bottom auto' }} /></div></div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCloseButtons saving={this.props.saving} />;
          </Dialog>
    )
  }
}

export default CompletionDateDialog;