import React from 'react';
import { useHistory } from 'react-router-dom';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import GroupBadge from '../../components/GroupBadge';
import Form from '../../components/Form';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import PageHeader from '../components/PageHeader';
import AdminQueryController from '../controllers/AdminQueryController';
import Authentication from '../services/authentication';
import Utils from '../utility/Utils';
import Table from '../../components/Table';

const CategoryRow = (props) => {

  const style = {
    backgroundColor: '#F6EABA'
  };

  return (
    <Table.Row>
      <Table.Data className='font-weight-bold' style={style}>{props.category}</Table.Data>
    </Table.Row>
  );
}

const ReportRow = (props) => {
  const selectQuery = (query) => {
    props.selectQuery(query);
  }
  const name = props.query.name;
  const className = props.className;
  const query = props.query;
  const parameterIcon = query.parameters.length
    ? <i className='fa fa-filter query-parameters' />
    : null;

  return (
    <Table.Row onClick={() => selectQuery(query)}>
      <Table.Data className={className}>{name} {parameterIcon}</Table.Data>
    </Table.Row>
  );
}

const QueryList = (props) => {
  const selectQuery = (query) => {
    props.controller.selectQuery(query);
  }

  const handleRefreshClick = () => {
    props.controller.refreshQueries();
  }

  const queries = props.controller.state.queryList || [];
  let rows = [];
  let category = '';
  queries.forEach((query, i) => {
    let className = query.selected ? 'selected query-name' : 'query-name';
    if (category !== query.category) {
      category = query.category;
      rows.push(<CategoryRow key={i} className='' category={category} />);
    }
    rows.push(<ReportRow key={query.id} className={className} query={query} selectQuery={selectQuery} />);
  });
  const refreshButton = <GroupBadge icon='fa-sync-alt' variant='secondary' pill={false} size='sm' className='float-right' onClick={handleRefreshClick}>Refresh</GroupBadge>;

  return (
    <div>
      <Form.Header className='alert-warning col-sm-12'><span>Queries</span>{refreshButton}</Form.Header>
      <div className='row form-group table-container queries-table'>
        <div className='col-md-12'>
          <Table>
            <Table.Body>
              {rows}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
}

const QueryResults = (props) => {

  let history = useHistory();

  const parseHeader = (header) => {
    let value = '';
    for (let i = 0; i < header.length; ++i) {
      let char = header[i];
      if (char === char.toUpperCase()) {
        value += ' ';
      }
      value += char;
    }
    return value;
  }

  const getKeys = (allKeys) => {
    let keys = [];
    for (let i = 0; i < allKeys.length; ++i) {
      let key = allKeys[i];
      if (key.indexOf('_GUID') === -1) {
        keys.push(key);
      }
    }
    return keys;
  }

  const makeGuidKey = (key) => {
    return key + '_GUID';
  }

  const hasIdField = (allKeys, guidKey) => {
    return Utils.arrayContains(allKeys, guidKey);
  }

  const navigateToSerializedAssetDetail = (guidValue) => {
    history.push('/assetdetail/' + guidValue);
  }

  const navigateToDropoff = (guidValue) => {
    history.push('/dropoff/' + guidValue);
  }

  const navigateToInspectionJob = (guidValue) => {
    history.push('/inspection/' + guidValue);
  }

  const navigateToCustomerServiceLevel = (guidValue) => {
    history.push('/customerservicelevel/' + guidValue);
  }

  const navigateToLaborCode = (guidValue) => {
    history.push('/laborcodes/' + guidValue);
  }

  const navigateToManufacturerDescription = (guidValue) => {
    history.push('/manufacturerdescription/' + guidValue);
  }

  const navigateToCustomerDescription = (guidValue) => {
    history.push('/customerdescription/' + guidValue);
  }

  const navigateToUser = (guidValue) => {
    history.push('/users/' + guidValue);
  }

  const getNavigationTarget = (guidKey, guidValue) => {
    switch (guidKey.toLowerCase()) {
      case 'primaryserialnumber_guid':
      case 'serialnumber_guid':
        return () => navigateToSerializedAssetDetail(guidValue);

      case 'jobnumber_guid':
        return () => navigateToInspectionJob(guidValue);

      case 'dropoffnumber_guid':
        return () => navigateToDropoff(guidValue);

      case 'customerservicelevel_guid':
        return () => navigateToCustomerServiceLevel(guidValue);

      case 'laborcode_guid':
        return () => navigateToLaborCode(guidValue);

      case 'manufacturerdescription_guid':
      case 'mfgpartnumber_guid':
        return () => navigateToManufacturerDescription(guidValue);

      case 'customerdescription_guid':
        return () => navigateToCustomerDescription(guidValue);

      case 'receivinguser_guid':
      case 'shippinguser_guid':
      case 'recordedby_guid':
      case 'user_guid':
        return () => navigateToUser(guidValue);

      default:
        return null;
    }
  }

  const executeQuery = () => {
    props.controller.executeQuery();
  }

  const makeLinkCell = (index, guidKey, guidValue, displayValue) => {
    const clickHandler = getNavigationTarget(guidKey, guidValue);
    if (clickHandler) {
      return <Table.Data key={index}><a className='select-link' onClick={clickHandler}>{displayValue}</a></Table.Data>;
    }
    return null;
  }

  const makeCell = (displayValue, allKeys, key, index, rowData) => {
    const guidKey = makeGuidKey(key);
    if (hasIdField(allKeys, guidKey)) {
      var keyValue = rowData[guidKey];
      var linkCell = makeLinkCell(index, guidKey, keyValue, displayValue);
      if (linkCell) {
        return linkCell;
      }
    }
    return <Table.Data key={index}>{displayValue}</Table.Data>;
  }

  const controller = props.controller;
  const query = controller.getSelectedQuery();
  const queryData = controller.state.queryData || {};
  let rows = [];
  let keys;
  let header;

  if (!query) {
    header = 'Results';
    keys = [''];
  }
  else {
    header = query.name;
    if (!Utils.isArray(queryData) || !queryData.length) {
      keys = [''];
    }
    else {
      let allKeys = Object.keys(queryData[0]);
      keys = getKeys(allKeys);

      for (let i = 0; i < queryData.length; ++i) {
        let rowData = [];
        let queryDataRow = queryData[i];

        for (let j = 0; j < keys.length; ++j) {
          let key = keys[j];
          let token = queryDataRow[key];
          rowData.push(token);
        }
        let row = <Table.Row key={i}>{rowData.map((d, x) => makeCell(d, allKeys, keys[x], x, queryDataRow))}</Table.Row>;
        rows.push(row);
      }
      for (let idx = 0; idx < keys.length; ++idx) {
        keys[idx] = parseHeader(keys[idx]);
      }
    }
  }
  const queryButton = query && query.parameters && query.parameters.length
    ? <Button icon='fa-table' variant='info' size='sm' className='m-1' onClick={executeQuery} >Edit ...</Button>
    : <Button icon='fa-table' variant='info' size='sm' className='m-1' onClick={executeQuery} enabled={query} >Load</Button>

  return (
    <div>
      <Form.Header className='alert-warning'>
        {queryButton}
        {header}
        <Badge className='float-right m-1' variant='info'>{rows.length} results</Badge>
      </Form.Header>

      <div className='row form-group table-container query-results-table'>
        <div className='col-md-12'>
          <Table>
            <Table.Header>
              <Table.Row>
                {keys.map((hdr, hdrIdx) => <Table.Head key={hdrIdx}>{hdr}</Table.Head>)}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
}

const AdminQueriesPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-bomb' />

class AdminQueryPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.loadQueriesIfEmpty();
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new AdminQueryController()
  }

  render = () => {
    const controller = this.props.controller;
    const title = "De're da bomb!";

    return (
      <Page {...this.props} pageTitle='InteServ · Admin Queries' id='admin-queries-page'>
        <AdminQueriesPageHeader pageTitle={'Admin Queries'} />
        {/* <PageHeader><span className='fa fa-bomb' title={title} /><span className='h2' title={title}> Admin Queries</span></PageHeader> */}
        <Page.Content>
          <div className='row'>
            <div className='col-md-3 col-sm-12'><QueryList controller={controller} /></div>
            <div className='col-md-9 col-sm-12'><QueryResults controller={controller} /></div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default AdminQueryPage;
