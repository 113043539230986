import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import Controller from '../../mixins/Controller';


class TorqueValueRow extends React.Component {


  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);

    this.binder = new Binder(this);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleRemoveRow = () => {
    this.props.controller.removeRow();
  }

  render = () => {
    const controller = this.props.controller;
    const parentController = controller.parentController;
    const binder = this.binder;
    const errors = controller.state.errors;
    const isViewMode = parentController.state.isViewMode;
    const location = controller.state.location;
    const torqueRange = controller.state.torqueRange;
    const canDeleteRow = controller.state.minimumReadingMappedId == Utils.emptyGuid && !isViewMode;
    const tdAction = canDeleteRow
      ? <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle'  action={this.handleRemoveRow} /></Table.Data>
      : <Table.Data className='col-action'></Table.Data>;
    const fldloc = <Form.StaticData value={location} />
    const fldTorqueRange = <Form.StaticData value={torqueRange} />
    let actualReadingProps = {
      id: controller.key + 'actualReading',
      bind: binder.bind('actualReading'),
      placeholder: 'Enter a value',
    }
    if (!controller.state.isNotApplicable) {
      actualReadingProps.error = errors.actualReading;
      actualReadingProps.className = 'required';
    }
    const fldactualReading = controller.state.isNotApplicable ? <Form.StaticData value="0" /> : <Form.Input {...actualReadingProps} />
    const fldIsNotApplicable = <Form.CheckBox bind={binder.bind('isNotApplicable')} />
    return (
      <Table.Row>
        {tdAction}
        <Table.Data className='col-location'>{fldloc}</Table.Data>
        <Table.Data className='col-torqueRange'>{fldTorqueRange}</Table.Data>
        <Table.Data className='col-notApplicable'>{fldIsNotApplicable}</Table.Data>
        <Table.Data className='col-actualReading' >{fldactualReading}</Table.Data>
      </Table.Row>
    );
  }
}

export default TorqueValueRow;
