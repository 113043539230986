import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import SignaturePad from '../../components/SignaturePad';
import Searchers from '../utility/Searchers';

class GaugeAssetsScrapDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel ? true : this.props.controller.save();

    handleClearSignature = () => {
        this.props.controller.clearSignature();
    }

    handleUpdateSignature = (signature) => {
        this.props.controller.updateSignature(signature);
    }

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const errors = controller.state.errors;
        const familyCode = controller.state.asset.familyCode;
        const scrapReason = <Form.AutoComplete focus={true} label='Reject Reason' bind={binder.bind('rejectReason')} error={errors.rejectReason} search={Searchers.rejectReasonSearch(familyCode)} className='required' />

        return (
            <Dialog {...this.props} allowKeyDownEvent={true} className='gauge-asset-scrap-dialog' header={this.props.header} modal={true} width={600} height={600} id='gauge-scrap-asset' onClose={this.handleClose}>
                <Dialog.Body>
                    <div className='gauge-scrap-asset-dialog-content'>
                        <div className='col-md-12'>{scrapReason}</div>
                        <div className='col-md-12'><Form.Input label='Approved By' placeholder='please enter your name' className='required' bind={binder.bind('approverName')} error={errors.approverName} /></div>
                        <div className='col-md-12'><SignaturePad id='signature-pad' className='col-sm-6 required' label='Approver Signature' backgroundColor='#F000' onUpdateSignature={this.handleUpdateSignature} showClear={true} bind={binder.bind('approverSignature')} error={errors.approverSignature} /></div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons saving={controller.saving} />
            </Dialog>
        );
    }
}

export default GaugeAssetsScrapDialog;
