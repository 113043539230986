import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';

class DropOffTimeRowController extends BaseController {
    constructor(parentController, dropOffTime) {
      super('dropOffTime-row-controller-' + DropOffTimeRowController.key++, {
        technician              : dropOffTime ? dropOffTime.Technician          : '',
        tvalue                   : dropOffTime ? dropOffTime.Value          : '',
        transactionDate                 : dropOffTime ? dropOffTime.TransactionDate           : '',
        tjobNumber                 : dropOffTime ? dropOffTime.jobNumber           : '',
        errors                  : {}
      });
  
      this.parentController = parentController;
      this._validator       = new Validator();
      this._validator.add('TransactionDate', 'TransactionDate', [{required: true}]);
      this._validator.add('Technician', 'Technician', [{required: true}]);
      this._validator.add('Value', 'Value', [{required: true}]);
    }  
    static key = 1;
}

export default DropOffTimeRowController;