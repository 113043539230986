import React, { useMemo, useEffect } from 'react';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';

const ProfitCostCenterTable = (props) => {
  const controller = props.controller;

  const handleAddNewRow = () => {
    controller.addNewProfitCostCenterRow();
  }

  const renderRowErrors = () => {
    const rowErrors = controller.state.errors && controller.state.errors.profitCostCenters;
    if (rowErrors) {
      return props.controller.state.profitCostCenters.length == 0
        ? <div className='help-block'>{rowErrors}</div>
        : null;
    }
  }

  // var receivingCostCenters = Utils.filterDeletedRecords(controller.state.receivingCostCenters) || [];
  const data = [...controller.state.profitCostCenters];
  // const rows = receivingCostCenters.map(center => <ReceivingCostCenterRow key={center.key} controller={center} />);
  const canEdit = data.length + 1 <= 100 ? true : false;
  const divClassName = controller.state.errors.profitCostCenters ? ' has-error' : '';
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return isSuperUser ? <span></span> : <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
        }
      },
      {
        Header: 'Job Type',
        accessor: 'state.jobType',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.AutoComplete bind={binder.bind('jobType')} readOnly={isSuperUser} className='required' error={errors.jobType} search={Utils.createSearch(AppConstants.JobType)} minimumResultsForSearch={-1} />
        }
      },
      {
        Header: 'Accounting Indicator',
        accessor: 'state.accountingIndicator',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.AutoComplete bind={binder.bind('accountingIndicator')} readOnly={isSuperUser} className='required' error={errors.accountingIndicator} search={Utils.createSearch(AppConstants.AccountingIndicators)} minimumResultsForSearch={-1} />;
        }
      },
      {
        Header: 'Profit Center',
        accessor: 'state.profitCenter',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('profitCenter')} className='required' readOnly={isSuperUser} error={errors.profitCenter} maxLength="250" />;
        }
      },
      {
        Header: 'Cost Center',
        accessor: 'state.costCenter',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('costCenter')} className='required' readOnly={isSuperUser} error={errors.costCenter} maxLength="250" />;
        }
      },
      {
        Header: 'Short Text',
        accessor: 'state.shortText',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('shortText')} className='required' readOnly={isSuperUser} error={errors.shortText} maxLength="250" />
            ;
        }
      },
      {
        Header: 'Long Text',
        accessor: 'state.longText',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('longText')} className='required' readOnly={isSuperUser} error={errors.longText} maxLength="500" />;
        }
      },
    ],
    []);
  return (
    <div className={divClassName}>
      <div className='mb-2'><span className="h5">Profit Cost Center</span></div>
      <Grid id='profit-cost-results-table' columns={columns} data={data} editable={canEdit} canAddNewRow={!isSuperUser} onAddNewRow={handleAddNewRow} showPagination={false} />
      {renderRowErrors()}
    </div>
  );
}


export default ProfitCostCenterTable;
