import React, { useRef, useMemo}  from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Button';
import SignaturePadJs from 'signature_pad';
import Form from './Form';

const StyledSignaturePad = styled.div`
    canvas.required {
        box-shadow: 0 0 0.25rem #d9534f !important;
        border-color: #d9534f !important;
    }
`;

const SignaturePad = (defaultProps) => {

    const signatureCanvas = useRef();  
    const props = {
        width: 180,
        height: 100,
        showBorder: true,
        showClear: false,
        clearIcon:'fa-eraser',
        clearLabel: 'Clear',
        clearClassName: 'btn-sm',
        options: {},
        ...defaultProps
    };

    const signaturePad = useMemo(() => (
        signatureCanvas.current
            ? new SignaturePadJs(signatureCanvas.current, {
                ...props.options, onEnd: () => {
                    if (!props.onUpdateSignature || (typeof props.onUpdateSignature !== 'function')) {
                        return;
                    }

                    props.onUpdateSignature(signaturePad.toDataURL());
                }
            })
            : null
    ), [signatureCanvas.current]);

    const clear = () => {
        if (signaturePad) {
            signaturePad.clear();
            if (!props.onUpdateSignature || (typeof props.onUpdateSignature !== 'function')) {
                return;
            }
            props.onUpdateSignature(null);
        }
    }

    useMemo(() => {
        if (signaturePad && props.disabled) {
            signaturePad.off();
            signaturePad.clear();
            const signature = props.signature;
            if (signature) {
                signaturePad.fromDataURL(signature);
            }
        }
       
    }, [props.disabled, props.signature, signaturePad]);
    
    useMemo(() => {
        if (signaturePad && props.bind) {
            const signature = props.bind();
            if (!signature) {
                signaturePad.off();
                signaturePad.clear();
                signaturePad.on();
            }
            else {
                signaturePad.fromDataURL(signature);
            }
        }
    }, []);

    const divClassName = !!props.error ? 'has-error' : '';
    const style = props.showBorder ? { border: 'solid 1px #ccc' } : null;

    const label = props.label ? <Form.Label prependLabelIcon={props.prependLabelIcon} appendLabelIcon={props.appendLabelIcon} hint={props.hint} name={props.label} /> : null;
    const error = props.error ? <Form.FieldError error={props.error} /> : null;

    let className = props.className;
    if (className && (props.signature || (props.bind && props.bind()))) {
        className = className.replace('required', '');
    }

    return(
        <StyledSignaturePad className={divClassName}>
            {props.error ? error : label}
            <div className='signature-pad'>
                <canvas ref={signatureCanvas} className={className} width={props.width} height={props.height} style={style} />
            </div>
            {props.showClear && !props.disabled ? <Button icon={props.clearIcon} variant='danger' size='sm' className={props.clearClassName} onClick={clear}>{props.clearLabel}</Button> : null}
        </StyledSignaturePad>
    );
}

SignaturePad.propTypes = {
    onUpdateSignature: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    showBorder: PropTypes.bool,
    showClear: PropTypes.bool,
    clearIcon: PropTypes.string,
    clearLabel:  PropTypes.string,
    clearClassName:  PropTypes.string,
    options: PropTypes.object
}

export default SignaturePad;