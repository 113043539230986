import React from 'react';
import { useHistory, useLocation } from 'react-router'
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Page from '../../components/Page';
import EditLaborCodeController from '../controllers/EditLaborCodeController';
import PageHeader from '../components/PageHeader';
import Dialog from '../../components/Dialog';
import SelectManDescController from '../controllers/SelectManDescController';
import SelectManDescDialog from '../dialogs/SelectManDescDialog';
import SelectedManDescTable from './SelectedManDescTable';
import Utils from '../utility/Utils';
import Authentication from '../services/authentication';
import co from '../../lib/Co';

const EditForm = (props) => {
  const handleManDesc = () => {
    var state = props.controller.state;
    var laborCode = state;
    var manufacturerDescriptions = state.manufacturerDescriptions;
    var controller = new SelectManDescController(laborCode, manufacturerDescriptions);

    co(function* () {
      yield Dialog.showDialogWaitForResult(
        <SelectManDescDialog controller={controller} header='Select Manufacturer Descriptions' />
      );
    });
  }

  const binder = props.binder;
  const controller = props.controller;
  const errors = controller.state.errors;
  const item = controller.state;
  const allItems = item.manufacturerDescriptions || [];
  const filteredItems = controller.filterItems(allItems);
  const matchesCount = filteredItems.length;
  const totalCount = allItems.length;
  const matchesLabel = (matchesCount === totalCount)
    ? Utils.pluralize('match', 'es', matchesCount)
    : matchesCount + ' / ' + totalCount + ' matches';

  return (
    <div>
      <div className='row'>
        <div className='col-md-2'><Form.Input label='Labor Code Name' bind={binder.bind('laborCodeName')} error={errors.laborCodeName} className='required' /></div>
        <div className='col-md-2'><Form.CheckBox label='Reject Code?' bind={binder.bind('isRejectLaborCode')} /></div>
        <div className='col-md-4'><Form.CheckBox label='Multi-Reject Capable' bind={binder.bind('multiRejectCapable')} /></div>
      </div>

      <div className='row'>
        <div className='col-md-8'><Form.Input label='Description' bind={binder.bind('description')} error={errors.description} className='required' /></div>
      </div>

      <div className='row'>
        <Buttons controller={controller} />
      </div>

      {!controller.adding &&
        <div className='row'>
          <div className='col-sm-12'>
            <Form.Header className='alert-warning mt-2'>
              <div className='d-flex justify-content-between'>
                <div>Selected Manufacturer Descriptions</div>
                <div className='float-right'><Button variant='info' size='sm' className='float-right' onClick={handleManDesc} disabled={controller.adding}>Select ...</Button></div>
              </div>
            </Form.Header>
          </div>
          <div className='col-sm-12'>
            <SelectedManDescTable controller={controller} items={filteredItems} badgeText={matchesLabel} binder={binder} />
          </div>
        </div>
      }
    </div>
  );
}

const Buttons = (props) => {
  let history = useHistory();

  const handleSave = () => {
    props.controller.save(() => {
      history.goBack();
    });
  }

  const handleCancel = () => {
    history.goBack();
  }

  return (
    <div className='col-md-12'>
      <span className='float-right'>
        <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave}>Save</Button>
        <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleCancel}>Cancel</Button>
      </span>
    </div>
  );
}

const EditLaborCodePageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class EditLaborCodePage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const id = this.props.match.params.laborCodeId;
    this.props.controller.load(id);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new EditLaborCodeController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const name = controller.state.laborCodeName;
    const title = (controller.adding ? 'Create ' : 'Edit ') + 'Labor Code' + (name ? (' — ' + name) : '');

    return (
      <Page {...this.props} pageTitle='InteServ · Edit Labor Code' id='edit-laborcode-page'>
        <EditLaborCodePageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div className='col-12'>
              <EditForm controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default EditLaborCodePage;