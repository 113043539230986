import React from 'react';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Jumbotron from '../../components/Jumbotron';

const JobSummary = (props) => {
    const job                    = props.job;
    const customer               = job && job.customerName;
    const customerLocation       = job && job.customerLocation;
    const jobNumber              = job && job.jobNumber;
    const thirdPartyJobNumber    = job && job.thirdPartyJobNumber;
    const jobDescription         = job && job.jobDescription;
    const notes                  = job && job.jobNotes;
    const jobDate                = job && DateTimeFormatter.formatDate(job.jobDate);
    const displayRequestedDate   = job && DateTimeFormatter.formatDate(job.requiredDate);
    let   requestedDate          = job && job.requestedDate;
    requestedDate                = job && DateTimeFormatter.getMoment(requestedDate).format('YYYYMMDD');
    const today                  = job && DateTimeFormatter.today().format('YYYYMMDD');
    let   requestedDateClassName = '';

    if (today > requestedDate) {
      requestedDateClassName += ' late-error';
    }
    else if (today === requestedDate) {
      requestedDateClassName += ' late-warning';
    }

    const divClassName   = props.hide ? 'invisible' : 'form-group col-md-12';
    const isSAPFacility  = SAPService.isSAPFacility();

    return (
      <Jumbotron id='job-summary-fields' className={divClassName}>
        <div className='row'>
          <div className='col-md-6'><Form.StaticData  label='Job Number'              value={jobNumber} /></div>
          <div className='col-md-6'><Form.StaticData  label='3rd Party Job Number'    value={thirdPartyJobNumber} /></div>
        </div>
        <div className='row'>
          <div className='col-md-6' ><Form.StaticData label='Customer'                value={customer} /></div>
          <div className='col-md-6' ><Form.StaticData label='Customer Location'       value={customerLocation} /></div>
        </div>
        <div className='row'>
          <div className='col-md-12'><Form.StaticData label='Job Description'         value={jobDescription} /></div>
        </div>
        <div className='row'>
          <div className='col-md-6' ><Form.StaticData label='Job Date'                value={jobDate} /></div>
          <div className='col-md-6' ><Form.StaticData label='Customer Requested Date' value={displayRequestedDate} className={requestedDateClassName} /></div>
        </div>
        <div className='row'>
          <div className='col-md-12'><Form.StaticData label='Job Notes'               value={notes} /></div>
        </div>
        {LoginService.isAdministrator && isSAPFacility && <div className='col-md-12'><Form.StaticData label='SAP Status' value={job && job.sapStatus} /></div>}
      </Jumbotron>
    );
}

export default JobSummary;