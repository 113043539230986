import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
class CompleteJobDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  handleKeyPress = (evt) => {
    return false;
  }

  render = () => {
    const binder = new Binder(this);
    const showAdhocMessage = SAPService.isSAPFacility();

    return (
      <Dialog {...this.props} header={this.props.header} width={550} height={300} id='complete-job-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div className='row'>
              <div className='form-group col-md-12'>

                {showAdhocMessage && <h6 className='text-success font-weight-bold'>All Adhoc Transactions must be complete at this point. Once a job is marked as complete, no further transactions can be created.</h6>}
                <div className='my-1'><h6>Please confirm you wish to complete the job: <span className='font-weight-bold'>{this.props.controller.state.job.jobNumber}</span></h6></div>
                <div className='d-flex justify-content-center mt-3'>
                  <Form.Date label='Return Date:' className='required' error={this.props.controller.state.errors.returnDate} onKeyPress={this.handleKeyPress} bind={binder.bind('returnDate')} options={{ endDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()) }} />
                </div>
              </div>
            </div>
          </form>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
      </Dialog>
    );
  }
}

export default CompleteJobDialog;
