import React, { useMemo } from 'react';
import ActionIcon from '../../components/ActionIcon';
import LoginService from '../services/LoginService';
import Roles from '../utility/Roles';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Grid from '../../components/GridWithWidth';
import Form from '../../components/Form';
import { utcMillisecond } from 'd3';
import Utils from '../utility/Utils';

const AssetUtilizationTable = (props) => {

  const canNavigateToInspection = (data) => {
    if (data.isComplete && LoginService.isInRole(Roles.Technician)) {
      return false;
    }
    return (LoginService.isTechnician && (data.facilityId === LoginService.loginInfo.facilityId));
  }

  const canNavigateToShippingTicketDetails = (data) => {
    if (LoginService.isTechnician && !LoginService.isCustomer) {
      if (data.awo_Guid === Utils.emptyGuid) {
        return true;
      }
    }
    return false;
  }

  const getReceivedDate = (data) => {
    const receivingTicketElement = canNavigateToShippingTicketDetails(data)
      ? <Form.Link to={'/receivingtickets/' + data.shippingTicketId} value={data.shippingTicketNumber} />
      : <span className='shipping-ticket'>{data.shippingTicketNumber}</span>;
    return (
      <span className='d-inline-flex'>
        {data.dateIn}
        &nbsp;
        {receivingTicketElement}
      </span>
    );
  }

  const data = props.value || [];

  const getColumnWidth = (accessor, headerText) => {
    const rows = data
    const maxWidth = 400
    const magicSpacing = 9
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    )
    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  const wasScrappedOnThisShippingTicket = (data, scrapDetails) => {
    return (data && scrapDetails && (data.shippingTicketNumber === scrapDetails.shippingTicketNumber));
  }

  const getShippedDate = (data, scrapDetails) => {
    const shippingTicketElement = canNavigateToShippingTicketDetails(data)
      ? <Form.Link to={'/shippingtickets/' + data.shippingTicketId} value={data.shippingTicketNumber} />
      : <span className='shipping-ticket'>{data.shippingTicketNumber}</span>;
    const scrapShippingTicketIndicator = wasScrappedOnThisShippingTicket(data, scrapDetails)
      ? <ActionIcon icon='fa-circle' className='statusScrapped' title='Asset was scrapped on this shipping ticket' />
      : null;
    return (
      <span className='d-inline-flex'>
        {data.dateOut}
        &nbsp;
        {shippingTicketElement}
        &nbsp;
        {scrapShippingTicketIndicator}
      </span>
    );
  }

  const wasScrappedOnThisJob = (data, scrapDetails) => {
    return (data && scrapDetails && (data.jobNumber === scrapDetails.jobNumber));
  }

  const scrapDetails = props.scrapDetails;
  const loadingMessage = props.isLoading && 'Loading utilization for asset';
  const noResultsMessage = !loadingMessage && !data.length && 'No results for the current asset';

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'default',
        disableSortBy: true,
        Cell: cellInfo => {
          const display = wasScrappedOnThisJob(cellInfo.row.original, scrapDetails)
            ? <ActionIcon icon='fa-circle' className='statusScrapped' title='Asset was scrapped on this job' />
            : null;
          return <span>{display}</span>
        }
      },
      {
        Header: 'Job Number',
        accessor: 'jobNumber',
        minWidth: getColumnWidth('jobNumber', 'Job Number'),
        disableSortBy: true,
        Cell: cellInfo => {
          const jobControl = canNavigateToInspection(cellInfo.row.original)
            ? <Form.Link to={'/inspection/' + cellInfo.row.original.jobId} value={cellInfo.cell.value} />
            : <span>{cellInfo.cell.value}</span>;
          return <div>{jobControl}</div>
        }
      },
      {
        Header: 'Facility',
        accessor: 'facilityName',
        minWidth: getColumnWidth('facilityName', 'Facility'),
        disableSortBy: true,
        Cell: cellInfo => {
          const spanSAP = cellInfo.row.original.isSAPFacility ? <span className='user-facility-sap mt-1 ml-1'> SAP</span> : null;
          return <div className='d-inline-flex'>{cellInfo.cell.value}{spanSAP}</div>
        }
      },
      {
        Header: 'Location',
        accessor: 'Location',
        minWidth: getColumnWidth('Location', 'Location'),
        disableSortBy: true
      },
      {
        Header: 'Type',
        accessor: 'UType',
        minWidth: getColumnWidth('UType', 'Type'),
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'TestUnit',
        minWidth: 160,
        disableSortBy: true,
        Cell: cellInfo => {
          const dateControl = (cellInfo.row.original.UType == 'Received')
            ? getReceivedDate(cellInfo.row.original)
            : getShippedDate(cellInfo.row.original, scrapDetails);
          return <div>{dateControl}</div>
        }
      },
      {
        Header: 'Status',
        accessor: 'SAPStatus',
        minWidth: getColumnWidth('SAPStatus', 'Status'),
        disableSortBy: true,
      },
      {
        Header: 'Days',
        accessor: 'daysInUse',
        minWidth: getColumnWidth('daysInUse', 'Days'),
        disableSortBy: true,
      },
    ],
    [getReceivedDate, getShippedDate, scrapDetails]
  )
  return (
    <div id='asset-utilization-table'>
      <Grid id='results-table' responsive={false} showPagination={false} columns={columns} data={data} noDataText={loadingMessage || noResultsMessage} />
    </div>
  );
}

export default AssetUtilizationTable;
