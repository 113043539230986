import React, { useMemo, useEffect } from 'react';
import ActionIcon from '../../components/ActionIcon';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import PageHeader from '../components/PageHeader';
import ManufacturerDescriptionController from '../controllers/ManufacturerDescriptionController';
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import ManufacturerDescriptionLookup from './ManufacturerDescriptionLookup';
import Jumbotron from '../../components/Jumbotron';

const ResultsList = (props) => {

  const handleRowClick = (item) =>{
    props.controller.selectItem(item);
  }

  const handleDelete = (item) => {
    props.controller.delete(item);
  }

  const controller = props.controller;
  const data = controller.state.searchResults;
  const loadingMessage = 'Loading manufacturer descriptions that meet the search criteria defined above'
  const noResultsMessage = 'No manufacturer descriptions meet the search criteria defined above';
  const displayMessage = controller.state.isLoading
    ? loadingMessage
    : noResultsMessage;
  const matchesCount = (controller.state.searchResults || []).length;
  const totalRecordCount=(controller.state.searchResults || []).length>0? controller.state.searchResults[0].totalCount:0;
  const matchesLabel =matchesCount === totalRecordCount ? Utils.pluralize('Match', 'es', matchesCount):matchesCount +' of '+totalRecordCount+' '+ Utils.pluralize('Match', 'es', matchesCount, false);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'manDescId',
        Cell: cellInfo => <Form.Link to={'/manufacturerdescription/' + cellInfo.cell.value} value='Edit' />
      },
      {
        Header: 'Mfg Part #',
        accessor: 'mfgItemNo',
        Cell: (cellInfo) => <span>{cellInfo.cell.value.name}</span>
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Has Diagram',
        accessor: 'hasDiagram',
        Cell: cellInfo => <span>{Utils.formatBool(cellInfo.cell.value)}</span>
      },
      {
        Header: 'Customer Count',
        accessor: 'customerCount',
      },
      {
        Header: 'Asset Count',
        accessor: 'assetCount',
      },
      {
        Header: '',
        accessor: 'default',
        Cell: cellInfo => !cellInfo.row.original.assetCount
          ? <ActionIcon icon='fa-trash' className='remove-handle' title='Delete manufacturer description' action={() => handleDelete(cellInfo.row.original)} />
          : null
      },
    ],
    []
  )
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText={displayMessage} enableDefaultRowSelect={true} />
    </div>
  );
}

const Details = (props) => {

  const controller = props.controller;
  const item = controller.getSelection();
  const verified = item.verified;
  const isVerified = verified && verified.isVerified;
  const verifiedBy = isVerified
    ? (verified && verified.verifiedBy && verified.verifiedBy.name)
    : null;
  const dateVerified = isVerified
    ? (verified && verified.dateVerified)
    : null;
  const lastUpdated = item
    ? item.updatedBy && item.updatedBy.name + ' on ' + DateTimeFormatter.formatDate(item.dateUpdated)
    : '';

  // special handling for item.length - length is a reserved keyword, so have to add extra check to ensure item has a value

  return (
    <Jumbotron {...props} className='form-group no-gutter'>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Mfg Item #' value={item.mfgItemNo && item.mfgItemNo.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Alt Item #' value={item.altItemNo} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Description' value={item.description} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Family Code' value={item.familyCode && item.familyCode.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Length' value={item && item.length && item.length.name} /></div>
        <div className='col-md-6'><Form.StaticData label='CWP' value={item.cwp && item.cwp.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Style' value={item.style && item.style.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Nominal Size' value={item.nominalSize && item.nominalSize.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Manufacturer' value={item.manufacturer && item.manufacturer.name} /></div>
        <div className='col-md-6'><Form.StaticData label='ID' value={item.id && item.id.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='OD' value={item.od && item.od.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Degree' value={item.degree && item.degree.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Service' value={item.service && item.service.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Type Approval' value={item.typeApproval && item.typeApproval.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Temp Range' value={item.tempRange && item.tempRange.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Load Rating' value={item.loadRating && item.loadRating.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Customer Count' value={item.customerCount} /></div>
        <div className='col-md-6'><Form.StaticData label='Asset Count' value={item.assetCount} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Has Diagram' value={Utils.formatBool(item.hasDiagram)} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Notes' value={item.notes} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Last Updated' value={lastUpdated} /></div>
      </div>
      <div className='row'>
        <div className='col-md-4'><Form.StaticData label='Verified' value={Utils.formatBool(isVerified)} /></div>
        {isVerified && <div className='col-md-4'><Form.StaticData label='Verified By' value={verifiedBy} /></div>}
        {isVerified && <div className='col-md-4'><Form.StaticData label='Verified On' value={DateTimeFormatter.formatDate(dateVerified)} /></div>}
      </div>
    </Jumbotron>
  );
}

const Buttons = (props) => {

  const controller = props.controller;
  const item = controller.state.selectedResult;
  const canEdit = !!item;

  return (
    <div className='buttons mb-2'>
      <Button to={'/manufacturerdescription/' + item.manDescId} icon='fa-pencil-alt' disabled={!canEdit} variant='primary' size='sm' className='btn-action m-1 action-button'>Edit ...</Button>
      <Button to={'/manufacturerdescription/' + Utils.emptyGuid} icon='fa-plus-circle' variant='success' size='sm' className='btn-action m-1 action-button'>Create New ...</Button>
    </div>
  );
}

const ManufacturerDescriptionPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-th-large' />

class ManufacturerDescriptionPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new ManufacturerDescriptionController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const title = 'Manufacturer Description';

    return (
      <Page {...this.props} pageTitle='InteServ · Manufacturer Description' id='man-desc-page'>
        <ManufacturerDescriptionPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div {...this.props} className='col-md-8'>
              <div className='row'>
                <div className='col-md-12'>
                  <ManufacturerDescriptionLookup binder={binder} searchResultsController={controller} searchResults={controller.state.searchResults} />
                </div>
                <div className='col-md-12'>
                  <ResultsList binder={binder} controller={controller} />
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='row'>
                <div className='col-md-12'>
                  <Buttons controller={controller} binder={binder} />
                </div>
                <div className='col-md-12'>
                  <Details controller={controller} binder={binder} />
                </div>
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
};

export default ManufacturerDescriptionPage;
