import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import Table from '../../components/Table';

class JobSowGroupRow extends React.Component{
    constructor(props, context) {
        super(props, context);    
        Object.assign(this, Controller);
    }
    
    componentDidMount = () => {
        this.initializeMixin();
    }

    render(){
        const controller = this.props.controller;
        const binder = new Binder(this);
        const groupNumber = controller.state.groupNumber;       
        return (
            <Table.Row>
                <Table.Data><Form.StaticData value={groupNumber} /></Table.Data>
                <Table.Data><Form.Input bind={binder.bind('groupName')} maxLength="30" /></Table.Data>
            </Table.Row>
        );
    }
}

export default JobSowGroupRow;
