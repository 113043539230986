import React, {useMemo} from 'react';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Searchers from '../utility/Searchers';

const ManDescTorqueReadingsTable = (props) => {
  const controller = props.controller;

  const totalReadingCount = controller.state.torqueReadings.reduce((acc, curr) => acc + curr.state.quantity, 0);

  const handleAddNewRow = () => {
    props.controller.addNewTorqueReadingRow();
  }

  const handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  const handleKeyUp = (evt) => {
    try {
      const value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }

      if (value > 200) {
        evt.target.value = 200;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }
  
  const divClassName = controller.state.errors.torqueReadings ? ' has-error' : '';

  const data = [...controller.state.torqueReadings];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',    
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <ActionIcon  icon='fa-trash' className='remove-handle' action={controller.removeRow} />;
        }
      },
      {
        Header: 'ID',
        accessor: 'state.readingId',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('readingId')} className='required id-field' error={errors.readingId} placeholder='#'/>;
        }
      },
      {
        Header: 'Torque Location',
        accessor: 'state.torqueLocation',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.AutoComplete bind={binder.bind('torqueLocation')} className='required' error={errors.torquelocation} search={Searchers.searchTorqueLocation()} minimumResultsForSearch={-1} />
        }
      },
      {
        Header: 'Torque Range',
        accessor: 'state.torqueRange',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.AutoComplete bind={binder.bind('torqueRange')} className='required' error={errors.torqueRange} search={Searchers.searchTorqueRange()} minimumResultsForSearch={-1} />;
        }
      },
    ],
    []);
    return (
      <div className={divClassName}>
        <Grid id='man-desc-torque-table' columns={columns} responsive={false} data={data} editable={true} canAddNewRow={false} onAddNewRow={handleAddNewRow} showPagination={false} noDataText='No torque readings exist yet'/>
      </div>
  );
}

export default ManDescTorqueReadingsTable;