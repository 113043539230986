import _ from 'immutable';
import $ from 'jquery';
import React from 'react';
import BaseController from '../../lib/BaseController';
import AppConstants from '../utility/AppConstants';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import Dialog from '../../components/Dialog';
import store from 'store';
import SAPService from '../services/SAPService';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import co from '../../lib/Co';
import Table from '../../components/Table';

class ShipAssetsToContainerDialogController extends BaseController {
    constructor(customer, selectedAssetIds, containerId, selectedAssets) {
        super('ship-assets', {
            customerId: customer && customer.id,
            selectedAssetIds: selectedAssetIds,
            assets: [],
            removeFromContainer: true,
            containerId: containerId,
            jobNumber: '',
            awoNumber: '',
            selectedAssets: selectedAssets,
            customerName: customer && customer.name,
            isExistingJob: false,
            groupNumber: '',
            groupName: '',
        });
        this.clear();
    }

    load = (jobId) => {
        this.clear();
        if (!jobId) {
            this.commit();
            return;
        }
        this.loadJob(jobId);
        this.loadShippableAssets(jobId);
    }

    loadJob = (jobId) => {
        ajax.get('lookup/getJob', { jobId: jobId }).then(job => {
            this.state.jobId = jobId;
            this.state.jobNumber = job.jobNumber;
            this.state.customerId = job.customerId;
            this.state.customerName = job.customerName;
            this.state.customerLocation = job.customerLocation;
            this.state.facilityId = job.facilityId;
            this.state.facilityName = job.facilityName;
            this.state.isSaveInProgress = false;
            this.state.hours = 0;
            this.state.minutes = 0;
            this.state.Technician = '';
            this.commit();
        }); // JobLookupDto
    }

    loadShippableAssets = (jobId) => {
        ajax.get('lookup/getShippableAssets', { jobId: jobId }).then(results => {
            this.state.assets = results;
            this.initializeGroups();
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // IList<ShippableTransferrableAssetDto>
    }

    clear = () => {
        this.state.receivingLocation = '';
        this.state.isSaveInProgress = false;
        this.state.errors = [];
        this.commit();
    }

    awoNumberChanged = () => {
        if (this.state.awoNumber) 
        {
            this.state.jobNumber = '';
            this.state.customerShipLocation = '';
            this.state.customerContainer = '';
            this.commit();
        }
    }


    initializeGroups = () => {
        let groups = [];
        this.state.assets.filter(asset => {
            if (groups.indexOf(asset.groupNumber) === -1) {
                groups.push(asset.groupNumber);
            }
        });

        groups.sort(function (a, b) {
            return (a - b);
        });

        this.state.groups = groups.map(group => {
            return {
                value: group,
                selected: false
            };
        });
    }
    receivingLocationSearch = () => {
        const self = this;
        return (searchTerm) => ajax.get('lookup/searchCustomerLocationsForFacility', {
            facilityId: LoginService.loginInfo.facilityId,
            customerId: self.state.customerId,
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    customerShipLocationChanged = () => {
        this.state.customerContainer = '';
        this.state.awoNumber = '';
        if (this.state.customerShipLocation && this.state.jobNumber) {
            this.state.jobNumber = '';
            this.commit();
        }
    }

    customerContainerSearch = () => { 
        const self = this;
        if(this.state.customerShipLocation)
        {
        return (searchTerm) => ajax.get('lookup/searchCustomerContainersForLocation', {
            customerLocationId: self.state.customerShipLocation.id,
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
        }
        if(this.state.awoNumber){             
              return (searchTerm) => ajax.get('lookup/searchCustomerContainersByFunctionalLocation', {
                customerId: self.state.customerId,
                searchTerm: searchTerm,
                functionalLocation : ''
            }).then(result => result).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
        }
    }

    customerShipLocationSearch = () => {
        const self = this;
        return (searchTerm) => ajax.get('lookup/searchCustomerLocations', {
            facilityId: LoginService.loginInfo.facilityId,
            customerId: self.state.customerId,
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    customerShipLocationSearchShipping = () => {
        const self = this;
        return (searchTerm) => ajax.get('lookup/searchCustomerLocations', {
            facilityId: LoginService.loginInfo.facilityId,
            customerId: self.state.customerId,
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    selectNoAssets = () => {
        this.state.assets.forEach(asset => {
            asset.selected = false;
        });
        this.commit();
    }

    selectScrappedAssets = () => {
        this.selectNoAssets();
        this.state.assets
            .filter(asset => this.isAssetScrapped(asset))
            .forEach(asset => {
                asset.selected = true;
            });
        this.commit();
    }

    selectNoSelectedAssets = () => {
        this.state.selectedAssets.forEach(asset => {
            asset.selected = false;
        });
        this.commit();
    }

    selectAllAssets = () => {
        this.state.assets.forEach(asset => {
            asset.selected = true;
        });
        this.commit();
    }

    selectAllSelectedAssets = () => {
        this.state.selectedAssets.forEach(asset => {
            asset.selected = true;
        });
        this.commit();
    }

    toggleSelection = (asset) => {
        asset.selected = !asset.selected;
        this.commit();
    }

    toggleGroupSelection = (clickedGroup) => {
        this.state.groups.forEach(group => {
            if (group.value === parseInt(clickedGroup, 10)) {
                var groupNumber = group.value;
                group.selected = !group.selected;

                this.state.assets
                    .filter(asset => (asset.groupNumber === groupNumber))
                    .forEach(asset => {
                        asset.selected = group.selected;
                    }
                    );
            }
        });
        this.commit();
    }

    selectAndTransfer = (asset) => {
        asset.selected = true;
        if (_.List(this.state.assets).contains(asset)) {
            this.moveToSelected();
        } else {
            this.removeFromSelected();
        }
    }

    moveToSelected = () => {
        let i = this.state.assets.length;
        while (i--) {
            let asset = this.state.assets[i];
            if (asset.selected) {
                asset.selected = false;
                this.state.assets.splice(i, 1);
                this.state.selectedAssets.push(asset);
            }
        }

        // sort selected list
        this.state.selectedAssets.sort(function (a, b) {
            return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
        });
        this.commit();
    }

    removeFromSelected = () => {
        let i = this.state.selectedAssets.length;
        while (i--) {
            let asset = this.state.selectedAssets[i];
            if (asset.selected) {
                asset.selected = false;
                this.state.selectedAssets.splice(i, 1);
                this.state.assets.push(asset);
            }
        }

        // sort primary list
        this.state.assets.sort(function (a, b) {
            return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
        });
        this.commit();
    }

    selectCurrentAsset = (asset) => {
        asset.selected = true;
        this.state.currentAsset = asset;
        this.commit();
    }

    clearSignature = () => {
        this.state.shippedBySignature = '';
        this.commit();
    }

    updateSignature = (signature) => {
        this.state.shippedBySignature = signature;
        this.commit();
    }

    isAssetScrapped = (asset) => {
        return (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped);
    }

    canShipAssets = () => {
        let success = true;
        if (this.state.customerShipLocation && !this.state.shippedBySignature) {
            notification.action('warning').post('Your signature is required');
            success = false;
        }

        if (!this.state.customerShipLocation && !this.state.jobNumber && !this.state.awoNumber) {
            //notification.action('warning').post('You must select a Customer Ship Location');
            notification.action('warning').post('You must select a Jobnumber or AWONumber or Ship To');
            success = false;
        }
        if (!this.state.receivingLocation) {
            notification.action('warning').post('You must select a Receiving Location');
            success = false;
        }
        // if (this.state.customerShipLocation.error) {
        //     notification.action('warning').post('Selected Customer Ship Location has the following error: '
        //         + this.state.customerShipLocation.error);
        //     success = false;
        // }

        if (this.state.selectedAssetIds.length === 0) {
            notification.action('warning').post('You must select at least one asset');
            success = false;
        }

        if (!success) {
            this.commit();
        }

        return success;
    }

    customerContainerChanged = () => {
        this.state.removeFromContainer = false;
        this.commit();
    }

    jobNumberChanged = () => {
        if (this.state.jobNumber) {
            this.state.customerShipLocation = '';
            this.state.customerContainer = ''
            this.state.carrier = '';
            this.state.trackingNumber = '';
            this.state.weight = '';
            this.state.awoNumber = '';
            this.state.groupName='';
            this.state.groupNumber='';
            this.state.isExistingJob=this.state.jobNumber.isExistingJob;
            this.commit();
        }else{
            this.state.isExistingJob=false;
            this.state.groupName='';
            this.state.groupNumber='';
        }
    }

    showAssignCompleteDialog = (result) => {
        co(this, function* () {
            const jobNumber = result.jobNumber;
            const groupNumber = result.groupNumber;
            const groupName = result.groupName;
            const assetCount = result.assetCount;
            const receivingTicketNumber = result.receivingTicketNumber;
            const jobDescription = result.jobDescription;
            const response = yield Dialog.showDialogWaitForResult(
                <Dialog.OKDialog width={600} height={300} header='Assets Assigned to Job' popup='childPopup'>
                    <Table borderless={true} id='assign-assets-complete-dialog'>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Job #</Table.Data><Table.Data className='value text-left'>{jobNumber}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group #</Table.Data><Table.Data className='value text-left'>{groupNumber}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group Name</Table.Data><Table.Data className='value text-left'>{groupName}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}># of Assets Assigned</Table.Data><Table.Data className='value text-left'>{assetCount}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Receiving Ticket #</Table.Data><Table.Data className='value text-left'>{receivingTicketNumber}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Job Description</Table.Data><Table.Data className='value job-description text-left'><div>{jobDescription}</div></Table.Data></Table.Row>
                    </Table>
                </Dialog.OKDialog>, 'childPopup'
            );
            if (response.OK) {

                const closeParentDialog = Dialog.handleResult({ popup: 'popup', Cancel: true });  // (document.getElementsByClassName('w2ui-msg-buttons'));
                closeParentDialog(new Event('click'));
            }
        });
    }

    showAssignToAWOCompleteDialog = (result) => {
        co(this, function* () {
            const awoNumber = result.awoNumber;
            const groupNumber = result.groupNumber;
            const groupName = result.groupName;
            const assetCount = result.assetCount;
            const receivingTicketNumber = result.receivingTicketNumber;
            const jobDescription = result.jobDescription;
            const response = yield Dialog.showDialogWaitForResult(
                <Dialog.OKDialog width={600} height={300} header='Assets Assigned to AWO' popup='childPopup'>
                    <Table borderless={true} id='assign-assets-complete-dialog'>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>AWO #</Table.Data><Table.Data className='value text-left'>{awoNumber}</Table.Data></Table.Row>
                        { //<Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group #</Table.Data><Table.Data className='value text-left'>{groupNumber}</Table.Data></Table.Row>
                            //  <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group Name</Table.Data><Table.Data className='value text-left'>{groupName}</Table.Data></Table.Row>
                        }
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}># of Assets Assigned</Table.Data><Table.Data className='value text-left'>{assetCount}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Receiving Ticket #</Table.Data><Table.Data className='value text-left'>{receivingTicketNumber}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>AWO Description</Table.Data><Table.Data className='value job-description text-left'><div>{jobDescription}</div></Table.Data></Table.Row>
                    </Table>
                </Dialog.OKDialog>, 'childPopup'
            );
            if (response.OK) {

                const closeParentDialog = Dialog.handleResult({ popup: 'popup', Cancel: true });  // (document.getElementsByClassName('w2ui-msg-buttons'));
                closeParentDialog(new Event('click'));
            }
        });
    }


    showShipReceiveAssetsCompleteDialog = (result) => {
        co(this, function* () {
            const customer = result.customer;
            const shipLocation = result.shipLocation;
            const receivingTicketNumber = result.receivingTicketNumber;
            const shippingTicketNumber = result.shippingTicketNumber;
            const assetCount = result.assetCount;
            const response = yield Dialog.showDialogWaitForResult(
                <Dialog.OKDialog width={600} height={300} header='Assets Assigned to Job' popup='childPopup'>
                    <Table borderless={true} id='assign-assets-complete-dialog'>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Customer</Table.Data><Table.Data className='value text-left'>{customer}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Ship Location</Table.Data><Table.Data className='value text-left'><div>{shipLocation}</div></Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Receiving Ticket #</Table.Data><Table.Data className='value text-left'>{receivingTicketNumber}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Shipping Ticket #</Table.Data><Table.Data className='value text-left'><div>{shippingTicketNumber}</div></Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}># of Assets Transferred</Table.Data><Table.Data className='value text-left'>{assetCount}</Table.Data></Table.Row>
                    </Table>
                </Dialog.OKDialog>, 'childPopup'
            );
            if (response.OK) {

                const closeParentDialog = Dialog.handleResult({ popup: 'popup', Cancel: true });  // (document.getElementsByClassName('w2ui-msg-buttons'));
                closeParentDialog(new Event('click'));
            }
        });
    }

    save = (cb) => {
        if (!this.canShipAssets()) {
            return;
        }
        this.state.isSaveInProgress = true;
        this.commit();
        let msg = '';
        if (this.state.jobNumber) {
            var state = this.state;
            var loginInfo = store.get('InteServLoginInfo');
            if (SAPService.isSAPFacility() && SAPService.useTTS()) {
                if (!loginInfo.isClockedIn) {
                    notification.action('error').post("Currently Not Clocked In");
                    return false;
                }
                if (loginInfo.isClockedOut) {
                    notification.action('error').post("ClockedOut for the day");
                    return false;
                }

                if (loginInfo.isBreakOut) {
                    notification.action('error').post("Currently on Unpaid break");
                    return false;
                }
            }
          
            const parameters = {
                customerId: state.customerId,
                facilityId: LoginService.loginInfo.facilityId,
                jobId: state.jobNumber ? state.jobNumber.id : '',
                receivingLocationId: state.receivingLocation ? state.receivingLocation.id : '',
                jobNotes: state.jobNotes,
                removeFromContainer: state.removeFromContainer,
                groupNumber: state.groupNumber && state.groupNumber.name,
                groupName: state.groupName,
                TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
                assets: state.selectedAssets.map(asset => {
                    return {
                        assetId: asset.assetId,
                        customerId: asset.customerId,
                        currentLocationId: asset.currentLocationId,
                        primarySerialNumber: asset.primarySerialNumber,
                        eAssetStatus: asset.eAssetStatus,
                        equipmentNumber: asset.equipmentNumber,
                        plantCode: asset.plantCode
                    };
                })
            };
            ajax.post('receiving/assignAssetsToJob', parameters).then(results => {
                this.channel.action('saved').post();
                let assetText = ' asset' + ((results.assetCount !== 1) ? 's' : '');
                msg = 'Assigned ' + results.assetCount + assetText + ' to job ' + results.jobNumber + ' in group number ' + results.groupNumber;
                notification.action('info').post({ msg: msg, title: 'Assign Assets to Job Complete' });
                //messages.channel('productiveTime').action('update').post(results.prodTime); 
                this.showAssignCompleteDialog(results);
                $('.ship-buttons .btn-default').trigger('click');
                $('.selected.selected-container').trigger('click');
                this.state.isSaveInProgress = false;
                this.commit();
                return true;
            }).catch(err => {
                this.state.isSaveInProgress = false;
                this.commit();
                notification.action('error').post({ msg: err.message, title: 'Error' });
                return false;
            });
        }
        else if (this.state.awoNumber) {
            var state = this.state;
            var loginInfo = store.get('InteServLoginInfo');
            
            const parameters = {
                customerId: state.customerId,
                facilityId: LoginService.loginInfo.facilityId,
                awoId: state.awoNumber ? state.awoNumber.id : '',
                receivingLocationId: state.receivingLocation ? state.receivingLocation.id : '',
                awoNotes: state.jobNotes,
                removeFromContainer: state.removeFromContainer,
                containerId: this.state.customerContainer.id,
                TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
                assets: state.selectedAssets.map(asset => {
                    return {
                        assetId: asset.assetId,
                        customerId: asset.customerId,
                        currentLocationId: asset.currentLocationId,
                        primarySerialNumber: asset.primarySerialNumber,
                        eAssetStatus: asset.eAssetStatus,
                        equipmentNumber: asset.equipmentNumber,
                        plantCode: asset.plantCode
                    };
                })
            };
            ajax.post('receiving/assignAssetsToAWO', parameters).then(results => {
                this.channel.action('saved').post();
                let assetText = ' asset' + ((results.assetCount !== 1) ? 's' : '');
                msg = 'Assigned ' + results.assetCount + assetText + ' to AWO ' + results.awoNumber;
                notification.action('info').post({ msg: msg, title: 'Assign Assets to AWO Complete' });
                //messages.channel('productiveTime').action('update').post(results.prodTime); 
                this.showAssignToAWOCompleteDialog(results);
                $('.ship-buttons .btn-default').trigger('click');
                $('.selected.selected-container').trigger('click');
                this.state.isSaveInProgress = false;
                this.commit();
                return true;
            }).catch(err => {
                this.state.isSaveInProgress = false;
                this.commit();
                notification.action('error').post({ msg: err.message, title: 'Error' });
                return false;
            });
        }
        else {
            const selectedAssetIds = this.state.selectedAssetIds;   // use only the ids

            const parameters = {
                receivingLocationId: this.state.receivingLocation && this.state.receivingLocation.id,
                customerLocationId: this.state.customerShipLocation.id,
                containerId: this.state.customerContainer.id,
                carrier: this.state.carrier || '',
                trackingNumber: this.state.trackingNumber || '',
                weight: this.state.weight || '',
                notes: this.state.notes || '',
                shippedByName: LoginService.loginInfo.fullName || '',
                shippedBySignature: this.state.shippedBySignature || '',
                assets: selectedAssetIds,
                transactionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
                facilityId: LoginService.loginInfo.facilityId,
                removeFromContainer: this.state.removeFromContainer,
                costCenterId: this.state.costCenter && this.state.costCenter.id,
                adnocActiveJobId: this.state.activeJobsOfADNOC && this.state.activeJobsOfADNOC.id,
            };

            ajax.post('container/shipAssets', parameters).then((results) => {
                msg = 'Shipped ' + selectedAssetIds.length + ' asset' + (selectedAssetIds.length === 1 ? '' : 's') + ' to container';
                notification.action('info').post({ msg: msg, title: 'Assets Shipped' });
                this.showShipReceiveAssetsCompleteDialog(results);
                $('.ship-buttons .btn-default').trigger('click');
                $('.selected.selected-container').trigger('click');
                this.state.isSaveInProgress = false;
                this.commit();
                return true;
                //messages.channel('edit-job-asset-details').action('saved').post();
                //messages.channel('productiveTime').action('update').post(prodTime);
            }).catch(err => {
                this.state.isSaveInProgress = false;
                this.commit();
                notification.action('error').post({ msg: err.message, title: 'Error' });
                return false;
            }); // in: ShipAssetsDto, out: void
        }
    }
    getActiveJobsOfADNOC = () => {
        const customerId = this.state.customerId;
        return (searchTerm) => ajax.get('lookup/searchExistingJobsForFacility', {
            searchTerm: searchTerm,
            customerId: customerId,
            facilityId: LoginService.loginInfo.facilityId
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    groupNumberSearch = () => {
        const jobId = this.state.jobNumber && this.state.jobNumber.id;
        return function* () {
          const parameters = { jobId: jobId };
          // var results = yield ajax.get('lookup/searchJobGroupNumbers', parameters); // IList<LookupDto>
          const results = yield ajax.get('lookup/searchJobGroupNamesAndNumbers', parameters);
          return results;
        };
      }

      groupNumberChanged = () => {
        this.state.groupName = this.state.groupNumber ? this.state.groupNumber.desc : '';
        this.commit();
      }
}

export default ShipAssetsToContainerDialogController;