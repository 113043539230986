import React from 'react';
import { useHistory } from 'react-router-dom';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Utils from '../utility/Utils';
import Authentication from '../services/authentication';
import ShippingDetails from '../pages/ShippingDetails';
import ShipReceivedAssetsController from '../controllers/ShipReceivedAssetsController';
import List from '../../components/List';
import SAPService from '../services/SAPService';
import Searchers from '../utility/Searchers';

const AssetsToShip = (props) => {
    const controller = props.controller;
    const assets = controller.state.assets || [];

    return (
        <div className='col-md-6'>
            <div className='row'>
                <List className='list-group asset-list'>
                    {assets.map(asset => <Asset key={asset.assetId} controller={controller} asset={asset} />)}
                </List>
            </div>
        </div>
    );
}

const Asset = (props) => {
    const controller = props.controller;
    const asset = props.asset;
    const className = 'list-group-item asset';
    const assetStatus = asset.assetStatus;
    let rejectReason = asset.rejectReason;
    let statusColourClass = 'asset-status status' + asset.assetStatus; // e.g.: statusScrapped
    if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
        statusColourClass = 'asset-status statusWeldRepair'
    if (asset.assetStatus === 'OS/Machining Repair') 
        statusColourClass = 'asset-status statusOSRepair'
    if (controller.isAssetScrapped(asset)) {
        if (asset.assetStatus != 'Passed' && asset.assetStatus != 'Failed Inspection')
            rejectReason = ' - ' + rejectReason;
    }

    return (
        <List.Item className={className + ' p-2'} active={asset.selected} style={{ background: '#d3e0d7' }}>
            <span className='field-data font-weight-bold'>{asset.primarySerialNumber}</span>
            <span className='ml-1' style={{ fontSize: '.7rem' }}>{asset.description.name}</span>
            <span id='asset-status' className={statusColourClass}> {assetStatus}</span>
            {assetStatus != 'Passed' ? <span className={statusColourClass}>{rejectReason}</span> : null}
        </List.Item>
    )
}

const Buttons = (props) => {
    let history = useHistory();

    const handleSave = () => {
        props.controller.save(() => {
            history.goBack();
        });
    }

    const handleClose = () => {
        history.goBack();
    }

    const saveInProgress = props.controller.state.IsSaveInProgress;
    return (
        <div className='col-12'>
            <div className='mb-1 d-flex justify-content-end'>
                <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' disabled={saveInProgress} onClick={handleSave}>Save</Button>
                <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
            </div>
        </div>
    );

}

const ReceivingLocation = (props) => {
    const binder = props.binder;
    const controller = props.controller;
    const mandatoryCostCenterField= <Form.AutoComplete label='Cost Center' dataToggle="tooltip" dataPlacement="top" bind={binder.bind('costCenter')} className='required' error={controller.state.errors.costCenter} search={Searchers.searchReceivngCostCenter(controller.state.facilityId)} />;
    const optionalCostCenterFiled=<Form.AutoComplete label='Cost Center' dataToggle="tooltip" dataPlacement="top" bind={binder.bind('costCenter')} search={Searchers.searchReceivngCostCenter(controller.state.facilityId)} />;
    
   return (
        <div>
        <div className='row'>
            <div className='col-md-6'>
                <Form.AutoComplete label='Receiving Location' id='receivingLocation' className='required' error={controller.state.errors.receivingLocation} bind={binder.bind('receivingLocation')} search={controller.receivingLocationSearch()} />
            </div>
            </div> 
            <div className='row'>
                {(SAPService.isSAPFacility() && controller.state.customerName === 'TechnipFMC Drilling & Completions') ? 
                SAPService.IsCostCenterMandatory()?<div className='col-md-6'>{mandatoryCostCenterField}</div>:null
                : null}
           
                {(SAPService.isSAPFacility() && controller.state.customerName === 'TechnipFMC Drilling & Completions') ? 
                SAPService.IsCostCenterMandatory()?null: <div className='col-md-6'>{optionalCostCenterFiled}</div>
                : null}
            </div> 
        </div>
    );
}

const ShipReceivedAssetsPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} icon='fa-download fa-truck' />

class ShipReceivedAssetsPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }
    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
        const query = this.props.location.queryData || {};
        if (!query) {
            this.goBack();
        }
        this.props.controller.load(query);
    }

    static defaultProps = {
        controller: new ShipReceivedAssetsController()
    }

    handleClearSignature = () => {
        this.props.controller.clearSignature();
    }

    handleUpdateSignature = (signature) => {
        this.props.controller.updateSignature(signature);
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const data = controller.state;
        const customerName = data.customerName;
        const assets = controller.state.assets || [];
        const canRemoveFromContainer = assets.reduce((acc, curr) => {
            return acc || (curr.containerId !== Utils.emptyGuid);
        }, false);
        const removeFromContainerDisable = data.customerContainer ? true : false;
        const removeFromContainer = canRemoveFromContainer
            ? <div>
                <Form.CheckBox label='Remove from Container' bind={binder.bind('removeFromContainer')} disabled={removeFromContainerDisable} enabled={!removeFromContainerDisable} />
            </div>
            : null;

        return (
            <Page {...this.props} pageTitle='InteServ · Receive/Ship Assets' id='ship-received-assets-page'>
                <ShipReceivedAssetsPageHeader pageTitle={'Receive & Ship Assets'} />
                <Page.Content>
                    <div className='row'>
                        <div className='form-group col-md-12'>
                            <div className='font-weight-bold' style={{ fontSize: '1.5rem' }}>{customerName}</div>
                            <AssetsToShip controller={controller} />
                        </div>
                        <div className='col-md-12'>
                            {removeFromContainer}
                        </div>
                        <div className='col-md-12'>
                            <ReceivingLocation controller={controller} binder={binder} />
                        </div>
                        <div className='col-md-12 shipping-detail-content'>
                        <ShippingDetails controller={controller} binder={binder} clearSignature={this.handleClearSignature} updateSignature={this.handleUpdateSignature} required={true} errors= {controller.state.errors.shippedBySignature} parentPage='shipreceivedassetspage'/>
                        </div>
                        <Buttons controller={controller} />
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default ShipReceivedAssetsPage;

