import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import Utils from '../utility/Utils';

class PrintCertificateOptionController extends BaseController {
  constructor(assetIds) {
    super('print-certificate-option', {
      assetList: assetIds || [],
      emailId: '',
      errors: {},
      standardReport: true,
      nonStandardReport: false
    });
    this.saving = false;
  }

  save = () => {
    const assetList = this.state.assetList
    const parameters = {
      assetIds: assetList,
      standardNonStandard: this.state.standardReport ? false : true
    };
    const reportName = 'AssetCertification'
    this.saving = true;
    this.commit();
    return ajax.post('receiving/generateAssetCert', parameters).then(results => {
      this.saving = false;
      this.commit();
      if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/Chrome/i) != null) {
        const blob = Utils.convertBase64toBlob(results, 'application/pdf');
        const fileName = reportName + '.pdf';
        const fileURL = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.download = fileName;
        tempLink.click();
        tempLink.remove();
      } else {
        let file = Utils.convertBase64toBlob(results, 'application/pdf');
        let fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
      }
      notification.action('success').post('Report generated sucessfully');
      return true;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
  standardReportChanged = () => {
    this.state.nonStandardReport = false;
  }

  nonStandardReportChanged = () => {
    this.state.standardReport = false;
  }
}

export default PrintCertificateOptionController;