import React, { useEffect } from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import LoginController from '../controllers/LoginController';
import Page from '../../components/Page';
import Shortcut from '../../components/Shortcut';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import Utils from '../utility/Utils';
import LoginResetPasswordDialog from '../dialogs/LoginResetPasswordDialog';
import LoginResetPasswordController from '../controllers/LoginResetPasswordController';

const Login = (props) => {

    useEffect(() => {
        const ebodyClassnv = process.env.REACT_APP_ENV_NAME;
        document.body.classList.add(ebodyClassnv);
        return () => {
            document.body.classList.remove(ebodyClassnv);
        };
    }, []);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        props.controller.loginFromKeyboard();
    }

    const handleResetPassword = (evt) => {
        Dialog.showDialog(<LoginResetPasswordDialog header={'Reset Password'} controller={new LoginResetPasswordController()} />);
    }

    const handleCoCDownload = () => {
        const hashLoc = window.location.hash;
        const serialGUID = hashLoc.split('/')[2];
        props.controller.getCoCCertificate(serialGUID);
    }

    const binder = props.binder;
    const hashLoc = window.location.hash;
    const flag = hashLoc && Utils.contains(hashLoc, 'assetdetail') && hashLoc.split('/').length == 3 && Utils.isValidGuid(hashLoc.split('/')[2])
    const href=window.location.href
    console.log("href: "+href);
    const isProdEnv = href && (Utils.contains(href, 'dev') || Utils.contains(href, 'uat')) ? false : true;
    const cocLink = flag
        ? <span>Print <span className='select-link' onClick={handleCoCDownload} >CoC</span> from TechnipFMC manufacturing</span>
        : <span>Print <a className='select-link' href='https://coc.apps.technipfmc.com/' target='_blank'>CoC</a> from TechnipFMC manufacturing</span>;

    const dataBook = isProdEnv === true ? <span>Print electronic  <a className='select-link' href='https://databook.apps.technipfmc.com' target='_blank'>Data Books</a> and/or material test reports for individual or multiple assets from TechnipFMC Manufacturing.</span>
            : <span>Print electronic  <a className='select-link' href='https://databook.lab.technipfmc.com' target='_blank'>Data Books</a> and/or material test reports for individual or multiple assets from TechnipFMC Manufacturing.</span>;

return (
    <div className='login row justify-content-center'>
        <div className='col-12 col-md-8 col-lg-6'>
            <div className='main-login grow-fade-in'>
                <div className='box-login'>
                    <h3>Enter your username and password to login</h3>
                    <Shortcut shortcutKey='enter' preventDefault action={handleSubmit} />
                    <form className='form-login keyboard' action='index.html' onSubmit={handleSubmit}>
                        <div className='form-group form-actions'>
                            <Form.Label name='Username' />
                            <span className='input-icon'>
                                <Form.BlurInput type='text' name='username' className='form-control username' bind={binder.bind('username')} autoFocus='true' autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' />
                                <i className='fa fa-user' />
                            </span>
                        </div>
                        <div className='form-group form-actions'>
                            <Form.Label name='Password' />
                            <span className='input-icon'>
                                <Form.BlurInput type='password' name='password' className='form-control password' bind={binder.bind('password')} autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' />
                                <i className='fa fa-lock' />
                            </span>
                        </div>
                        <div className='form-actions'>
                            <Form.Link className='select-link' onClick={handleResetPassword} value='Reset Password' ></Form.Link>
                        </div>
                        <div className='form-actions col-md-12'>
                            <div className='inteserv-links'> {cocLink} </div>
                            <div className='inteserv-links'>{dataBook}</div>
                            <Button icon='fas fa-sign-in-alt' variant='info' size='lg' onClick={handleSubmit} type='submit' className='float-right'>Login</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
)
}

class LoginPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new LoginController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    render() {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Page {...this.props} pageTitle='InteServ · Login'>
                <div className='container'>
                    <Login binder={binder} controller={controller} />
                </div>
                <div className='copyright' />
            </Page>
        );
    }
}

export default LoginPage;