import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class CustomerAssemblyLevelTestRowController extends BaseController {
    constructor(parentController, test) {
        super('customer-assembly-level-test-row-controller-' + CustomerAssemblyLevelTestRowController.key++, {
            
            customerAssemblyLevelTestId: test && test.customerAssemblyLevelTestId  ? test.customerAssemblyLevelTestId : Utils.emptyGuid,
            assemblylevelOperation: test ? test.assemblylevelOperation : '',
            operationName: test ? test.operationName : '',
            operationCode: test ? test.operationCode : '',
            operationOrder: test ? test.operationOrder : 1,
            errors: {}
        });
        this.parentController = parentController;
        this._validator = new Validator();
        this._validator.add('assemblylevelOperation', 'Assembly Level Operation', [{ required: true }]);
        this._validator.add('operationName', 'Operation Name', [{ required: true }]);
  
    }

    static key = 1;

    assemblylevelOperationChanged = (data) => {
        const newValue = data && data.new && data.new.name;
        if (!newValue) {
            return;
        }
        this.state.operationName = newValue;
    }

    autoCode = () => {
        const value = '00' + this.state.operationOrder + '0';
        this.state.operationCode = value.substr(value.length - 4);
        this.commit();
    }

    setOrder = (value) => {
        this.state.operationOrder = value;
        this.commit();
    }

    canRemoveRow = () => {
        return true;
        // var value = this.state.customerServiceLevelTestId === Utils.emptyGuid;
        // return value;
    }

    removeRow = () => {
        if (!this.canRemoveRow()) {
            return;
        }
        this.parentController.removeTestRow(this);
    }
}

export default CustomerAssemblyLevelTestRowController;