import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Search from '../components/Search';
import GroupBadge from '../../components/GroupBadge';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import Utils from '../utility/Utils';
import Binder from '../../lib/Binder';

const Item = (props) => {

    const toggleSelection = (item) => {
        props.controller.toggleSelection(item);
    }

    const selectAndTransfer = (item) => {
        props.controller.selectAndTransfer(!!props.isSource, item);
    }

    const item = props.item;
    const className  = 'item';
    return (
        <List.Item className={className + ' p-2'} active={item.selected} onClick={() => { toggleSelection(item); }} onDoubleClick={() => { selectAndTransfer(item); }} style={{ cursor: 'pointer' }} >
            <span className='font-weight-bold'>{item.mfgItemNo}</span>
            <span className='ml-1'>{item.description}</span>
        </List.Item>
    );
}

const SourceItems = (props) => {
    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.search();
    }

    const handleMouseSearch = () => {
        props.controller.search();
    }

    const clearSearch = () => {
        props.controller.clearSearch();
    }

    const selectNone = () => {
        props.controller.selectNoSourceItems();
    }

    const selectAll = () => {
        props.controller.selectAllSourceItems();
    }

    const binder = props.binder;
    const controller = props.controller;
    const sourceItems = controller.state.sourceItems;
    const availableLabel = Utils.pluralize('match', 'es', sourceItems.length);

    return (
        <div>
            <div className='row'>
                <div className='col'>
                <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
                <Badge className='m-1 float-right' variant='info'>{availableLabel}</Badge>
                </div>
            </div>
            <div className='row no-gutters'>
                <div className='col-md-12'>
                    <Jumbotron className='man-description-list'>
                        <List id='man-desc-list'>
                            {
                              sourceItems.map(item => {
                                return <Item controller={controller} item={item} isSource={props.isSource} />;
                            })
                            }
                        </List>
                    </Jumbotron>
                </div>
            </div>
        </div>
    );
}

const SelectedItems = (props) => {

    const selectNone = () => {
        props.controller.selectNoSelectedItems();
    }

    const selectAll = () => {
        props.controller.selectAllSelectedItems();
    }

    const controller = props.controller;
    const selectedItems = controller.state.selectedItems;
    const selectedLabel = selectedItems.length + ' selected';

    return (
        <div>
            <div className='row'>
                <div className='col'>
                    <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
                    <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
                    <Badge className='m-1' variant='info'>{selectedLabel}</Badge>
                </div>
            </div>
            <div className='row no-gutters'>
                <div className='col-md-12'>
                    <Jumbotron className='man-description-list'>
                        <List id='selected-man-desc-list'>
                            {
                                selectedItems.map(item => {
                                    return <Item controller={controller} item={item} useritem={true} />;
                                })
                            }
                        </List>
                    </Jumbotron>
                </div>
            </div>
        </div>
    );
}

const TransferButtons = (props) => {
    const select = () => {
        props.controller.moveToSelected();
    }

    const unselect = () => {
        props.controller.removeFromSelected();
    }

    // const selectDisabled = !this.props.controller.state.sourceItems.reduce((acc, curr) => {
    //   return acc.selected | curr.selected;
    // }, false);
    let selectDisabled = false;

    // const unselectDisabled = !this.props.controller.state.selectedItems.reduce((acc, curr) => {
    //   return acc.selected | curr.selected;
    // }, false);
    let unselectDisabled = false;

    return (
        <div className='row'>
            <div className='col'>
                <div>
                    <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
                    <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect} >Unselect</Button>
                </div>
            </div>
        </div>
    );
}

class SelectManDescDialog extends React.Component{

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render = () => {
    const binder     = new Binder(this);
    const controller = this.props.controller;
    const labelText  = 'Select the Manufacturer Descriptions to associate with the Labor Code';

    return (
        <Dialog {...this.props} header={this.props.header} width={710} height={640} id='select-man-desc-dlg' onClose={this.handleClose}>
            <Dialog.Body>
                <div className='row'>
                    <div className='col'>
                        <div className='row text-center'><div className='col h6'>{labelText}</div></div>
                        <div className='row align-items-center'>
                            <div className='col-md-5'><SourceItems controller={controller} binder={binder} isSource={true} /></div>
                            <div className='col-md-2'><TransferButtons controller={controller} /></div>
                            <div className='col-md-5'><SelectedItems controller={controller} /></div>
                        </div>
                    </div>
                </div>
            </Dialog.Body>
            <Dialog.SaveCancelButtons />
        </Dialog>
    );
  }
}

export default SelectManDescDialog;