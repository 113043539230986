import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'immutable';

class DropZone extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = { isDragActive: false };
    }

    static defaultProps = {
        multiple: true,
        disabled: false
    }

    onDragLeave = (e) => {
        this.setState({ isDragActive: false });
    }

    onDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';

        this.setState({
            isDragActive: true
        });
    }

    onDrop = (e) => {
        e.preventDefault();

        this.setState({
            isDragActive: false
        });

        var files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        }
        else if (e.target) {
            files = e.target.files;
        }

        _.Seq(files).forEach(f => this._createPreview(f));
    }

    onClick = () => {
        ReactDOM.findDOMNode(this.refs.fileInput).click();
    }

    _createPreview = (file) => {
        var reader = new FileReader();
        reader.onloadend = (e) => {
            var newFile = { file: file, imageUrl: e.target.result };
            if (this.props.onDrop) {
                this.props.onDrop(newFile);
            }
        };
        reader.readAsDataURL(file);
    }

    render = () => {
        let className = this.props.className ? this.props.className.concat(' dropzone') : 'dropzone';
        if (this.state.isDragActive) {
            className += ' active';
        }

        var multiple = this.props.multiple;
        var disabled = this.props.disabled;

        const style = {
            display: 'block',
            width: this.props.width || 100,
            height: this.props.height || 100,
            borderWidth: 1,
            padding: 5,
            borderStyle: this.state.isDragActive ? 'solid' : 'dashed',
            ...this.props.style
          };
          

        return (
            <span className={className} style={style} onClick={this.onClick} onDragLeave={this.onDragLeave} onDragOver={this.onDragOver} onDrop={this.onDrop}>
                <input style={{ display: 'none' }} type='file' multiple={multiple} disabled={disabled} ref='fileInput' onChange={this.onDrop} />
                {this.props.children}
            </span>
        );
    }
}

DropZone.propTypes = {
    onDrop: PropTypes.func.isRequired,
    size: PropTypes.number,
    style: PropTypes.object
}

export default DropZone;