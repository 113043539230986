import BaseController from '../../lib/BaseController';

class AssetScrapSignatureController extends BaseController {
  constructor(scrapSignatureData) {
    super('scrap-signature', {
      jobId              : scrapSignatureData.jobId,
      assetId            : scrapSignatureData.assetId,
      primarySerialNumber: scrapSignatureData.primarySerialNumber,
      signatureName      : scrapSignatureData.signatureName,
      signature          : scrapSignatureData.signature,
      dateAdded          : scrapSignatureData.dateAdded,
      recordedByName     : scrapSignatureData.recordedByName,
      failingTestName    : scrapSignatureData.failingTestName,
      rejectReason       : scrapSignatureData.rejectReason
    });
  }
}

export default AssetScrapSignatureController;
