import ReactDOM from 'react-dom';
import $ from 'jquery'; 

const KeyboardNavigation = {
  canMove(ctl, direction) {
    if (!ctl.is(':text')) {
      return true;
    }

    var caretPosition = this.caret(ctl[0]) + direction;
    return ((caretPosition < 0) || (caretPosition > ctl.val().length));
  },

  caret(ctrl, start) {
    if (!ctrl) {
      return;
    }

    if (start !== undefined) {
      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(start,start);
      }
      else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', start);
        range.moveStart('character', start);
        range.select();
      }
      return;
    }

    var CaretPos = 0; // IE Support
    if (document.selection) {
      ctrl.focus();
      var Sel = document.selection.createRange();
      Sel.moveStart('character', -ctrl.value.length);
      CaretPos = Sel.text.length;
    }

    // Firefox support
    else if (ctrl.selectionStart || (ctrl.selectionStart === '0')) {
      CaretPos = ctrl.selectionStart;
    }
    return (CaretPos);
  },

  selectRange(ctrl, start, end) {
    if (!ctrl) {
      return;
    }

    if (start !== undefined) {
      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(start,end);
      }
      else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', start);
        range.select();
      }
      return;
    }

    var CaretPos = 0; // IE Support
    if (document.selection) {
      ctrl.focus();
      var Sel = document.selection.createRange();
      Sel.moveStart('character', -ctrl.value.length);
      CaretPos = Sel.text.length;
    }

    // Firefox support
    else if (ctrl.selectionStart || (ctrl.selectionStart === '0')) {
      CaretPos = ctrl.selectionStart;
      ctrl.selectionEnd = end;
    }
    return (CaretPos);
  },

  moveToNextControl(ctl, direction) {
    ctl = $(ctl);
    if (!this.canMove(ctl, direction)) {
      return false;
    }

    var fields = $(ReactDOM.findDOMNode(this)).find('button:visible,td[tabindex="0"]:visible,.select2-focusser:visible,input:not(.select2-input,.select2-offscreen):visible,textarea:visible,select:visible');
    var index  = fields.index(ctl);

    index = index + direction;
    if (index < 0) {
      index = 0;
    }

    if (index >= fields.length) {
      index = fields.length - 1;
    }

    return this.setFocus(fields.eq(index));
  },

  moveToNextRow(ctl, direction) {
    var rows          = $(ReactDOM.findDOMNode(this)).find('.tabrow');
    var row           = $(ctl).parents('.tabrow');
    var fields        = row.find('button:visible,.select2-focusser:visible,td[tabindex="0"]:visible,span[tabindex="0"]:visible,input:not(.select2-input,.select2-offscreen):visible,textarea:visible,select:visible');
    var currentRowIdx = rows.index(row);
    var currentColIdx = fields.index(ctl);
    currentRowIdx    += direction;
    if ((currentRowIdx < 0) || (currentRowIdx > rows.length)) {
      return false;
    }
    var nextRowFields = rows.eq(currentRowIdx).find('button:visible,td[tabindex="0"]:visible,span[tabindex="0"]:visible,.select2-focusser:visible,input:not(.select2-input,.select2-offscreen):visible,textarea:visible,select:visible');
    return this.setFocus(nextRowFields.eq(currentColIdx));
  },

  setFocus(field) {
    field.focus();
    if (field.length === 0) {
      return true;
    }

    field = field[0];
    if (field.type === 'text') {
      this.selectRange(field, 0, field.value.length);
    }
    return true;
  },

  handleKeyDown(evt, id) {
    if ((evt.key === 'Enter') && evt.shiftKey) {
      evt.key = 'ShiftEnter';
    }
    if ((evt.key === 'Enter') && evt.ctrlKey) {
      evt.key = 'Ctrl' + evt.key;
    }

    switch(evt.key) {
      case 'ArrowRight':
        if (this.moveToNextControl(evt.target, 1)) {
          evt.preventDefault();
        }
        evt.stopPropagation();
        return;

      case 'ArrowLeft':
        if (this.moveToNextControl(evt.target, -1)) {
          evt.preventDefault();
        }
        evt.stopPropagation();
        return;

      case 'ArrowUp':
      case 'ShiftEnter':
        this.moveToNextRow(evt.target, -1);
        evt.preventDefault();
        evt.stopPropagation();
        return;

      case 'ArrowDown':
      case 'Enter':
        this.moveToNextRow(evt.target, 1);
        evt.preventDefault();
        evt.stopPropagation();
        return;

      default:
        return;
    }
  },

  handleDialogKeyDown(evt, id) {
    if (evt.shiftKey) {
      evt.key = 'Shift' + evt.key;
    }

    if (evt.ctrlKey) {
      evt.key = 'Ctrl' + evt.key;
    }

    switch(evt.key) {
      case 'Tab':
      case 'ArrowRight':
        if (this.moveToNextControl(evt.target, 1)) {
          evt.preventDefault();
        }
        evt.stopPropagation();
        return;

      case 'ShiftTab':
      case 'ArrowLeft':
        if (this.moveToNextControl(evt.target, -1)) {
          evt.preventDefault();
        }
        evt.stopPropagation();
        return;

      case 'ArrowUp':
      case 'ShiftEnter':
        this.moveToNextRow(evt.target, -1);
        evt.preventDefault();
        evt.stopPropagation();
        return;

      case 'ArrowDown':
      case 'Enter':
        this.moveToNextRow(evt.target, 1);
        evt.preventDefault();
        evt.stopPropagation();
        return;

      default:
        return;
    }
  },

  handleKeyDown2(evt, id) {
    switch(evt.key) {
      case 'ArrowRight':
        if (this.moveToNextControl(evt.target, 1)) {
          evt.preventDefault();
        }
        evt.stopPropagation();
        return;

      case 'ArrowLeft':
        if (this.moveToNextControl(evt.target, -1)) {
          evt.preventDefault();
        }
        evt.stopPropagation();
        return;

      case 'ArrowUp':
        this.moveToNextRow(evt.target, -1);
        evt.stopPropagation();
        return true;

      case 'ArrowDown':
        this.moveToNextRow(evt.target, 1);
        evt.stopPropagation();
        return true;

      default:
        return;
    }
  }
};

export default KeyboardNavigation;