import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class CustomerServiceLevelTestRowController extends BaseController {
    constructor(parentController, test) {
        super('customer-service-level-test-row-controller-' + CustomerServiceLevelTestRowController.key++, {
            customerServiceLevelTestId: test ? test.customerServiceLevelTestId : Utils.emptyGuid,
            parentServiceLevelTest: test ? test.parentServiceLevelTest : '',
            testName: test ? test.testName : '',
            operationCode: test ? test.operationCode : '',
            operationOrder: test ? test.operationOrder : 1,
            errors: {}
        });

        this.parentController = parentController;
        this._validator = new Validator();
        // this._validator.add('value'    , 'Value'    , [{required: true}]);
    }

    static key = 1;

    parentServiceLevelTestChanged = (data) => {
        const newValue = data && data.new && data.new.name;
        if (!newValue) {
            return;
        }
        this.state.testName = newValue;
    }

    autoCode = () => {
        const value = '00' + this.state.operationOrder + '0';
        this.state.operationCode = value.substr(value.length - 4);
        this.commit();
    }

    setOrder = (value) => {
        this.state.operationOrder = value;
        this.commit();
    }

    canRemoveRow = () => {
        return true;
        // var value = this.state.customerServiceLevelTestId === Utils.emptyGuid;
        // return value;
    }

    removeRow = () => {
        if (!this.canRemoveRow()) {
            return;
        }
        this.parentController.removeTestRow(this);
    }
}

export default CustomerServiceLevelTestRowController;