import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';

class EstimatedAdhocTransactionRowController extends BaseController {
    constructor(parentController, transaction) {
      super('estimated-adhoc-transaction-row-controller-' + EstimatedAdhocTransactionRowController.key++, {
        adhocTransactionId : transaction ? transaction.adhocTransactionId : '',
        operation          : transaction ? { id: transaction.adhocServiceLevelOperationId, name: transaction.operationDescription} : '',
        value              : transaction && transaction.value ? transaction.value : '0',
        unitOfMeasure      : transaction ? transaction.unitOfMeasure : '',
        notes              : transaction ? transaction.notes : '',
        errors             : {},
        hours              : transaction && transaction.value ? (transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Minutes || transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours) ? (transaction.value / 60).toFixed(1) : '0' : '0' ,
        minutes            : transaction && transaction.value ? transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Minutes ? transaction.value % 60 : '0' : '0' ,
        technician         : transaction ? transaction.technician : '',
        date               : transaction ? DateTimeFormatter.formatDate(transaction.date) : '',
        recordStatus       : transaction ? transaction.recordStatus : AppConstants.RecordStatus.Added
      });
      this.parentController = parentController;
      this._validator       = new Validator();
      // this._validator.add('operation', 'Operation', [{required: true}]);
      // this._validator.add('value'    , 'Value'    , [{required: true}]);   
    }

    static key = 1;
  
    operationChanged = () => {
      this.state.unitOfMeasure = this.state.operation ? this.state.operation.unitOfMeasure : '';
      this.state.value         = 0;
      this.state.hours         = 0;
      this.state.minutes       = 0;   
      this.commit();
      if (this.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars) {  
          this.clearValues();
      }
      else
        this.setValues();
    }
  
    setValues = () =>
    {
      this.state.technician=LoginService.loginInfo.fullName;
      this.state.date=DateTimeFormatter.formatDate(DateTimeFormatter.today());
      this.commit();
    }
    clearValues = () =>
    {
      this.state.technician='';
      this.state.date='';
      this.commit();
    }
  
    updateRecordStatus = () => {
      this.state.recordStatus = this.state.recordStatus === AppConstants.RecordStatus.Unchanged ? AppConstants.RecordStatus.Modified : this.state.recordStatus;
      this.commit();
    }
  
    hoursChanged = () => {
      this.updateRecordStatus();
    }
  
    minutesChanged = () => {
      this.updateRecordStatus();
    }
  
    valueChanged = () =>{
      this.updateRecordStatus();
    }
  
    removeRow = () => {
      const recordStatus = this.state.recordStatus;
      if(recordStatus === AppConstants.RecordStatus.Added){
        this.parentController.removeEstimateRow(this);
      }
  
      else{
        this.state.recordStatus = AppConstants.RecordStatus.Deleted;
        this.commit();
        this.parentController.commit(); 
      }
    }
  }
    
  export default EstimatedAdhocTransactionRowController;
  