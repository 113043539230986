import React from 'react';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import Badge from '../../components/Badge';
import Utils from '../utility/Utils';
import Table from '../../components/Table';

const AssignedTestRow = (props) => {

    const data                = props.data;
    const serviceLevelControl = LoginService.isSuperUser
                                ? <Form.Link to={'/customerservicelevel/' + data.customerServiceLevelId} disabled={!data.laborCodeId} value={data.customerServiceLevelName} /> 
                                : <span>{data.customerServiceLevelName}</span>;
    const spanSAP             = data.isSAPServiceLevel
                                ? <span className='sap-service-level mt-1 ml-1'> SAP</span>
                                : null;

    return (
      <Table.Row>
        <Table.Data><span className='d-inline-flex'>{serviceLevelControl}{spanSAP}</span></Table.Data>
        <Table.Data>{data.intervalDays}</Table.Data>
        <Table.Data>{data.lastDate}</Table.Data>
        <Table.Data>{data.nextDate}</Table.Data>
      </Table.Row>
    );
}

const AssignedLaborCodeRow = (props) => {

    var data             = props.data;
    var laborCodeControl = LoginService.isSuperUser
                            ? <Form.Link to={'/laborcodes/' + data.laborCodeId} disabled={!data.laborCodeId} value={data.laborCodeName} /> 
                            : <span>{data.laborCodeName}</span>;
    var badge            = props.includeLaborCodeHeader
                            ? <Badge variant='info'>{props.count}</Badge>
                            : null;

    return (
      <Table.Row>
        <Table.Data>{props.includeLaborCodeHeader && <span className='field-label float-right'>{badge} {Utils.pluralize('Labor Code', 's', props.count, false)}:</span> }</Table.Data>
        <Table.Data colSpan={3}>{laborCodeControl}</Table.Data>
      </Table.Row>
    );
}

class AssignedServiceLevelsTable extends React.Component{
  
  getAssignedTestRows = (tests) => {
    let results = [];

    for (let i = 0; i < tests.length; ++i) {
      let test = tests[i];
      results.push(<AssignedTestRow key={test.customerServiceLevelId} data={test} />);

      for (let j = 0; j < test.assignedLaborCodes.length; ++j) {
        let assignedLaborCode = test.assignedLaborCodes[j];
        results.push(<AssignedLaborCodeRow key={assignedLaborCode.assignedLaborCodeId} data={assignedLaborCode} count={test.assignedLaborCodes.length} includeLaborCodeHeader={j === 0} />);
      }
    }

    return results;
  }

  render = () => {
    const props            = this.props;
    const tests            = props.value || [];
    const results          = this.getAssignedTestRows(tests);    
    const colCount         = 4;
    const loadingMessage   = props.isLoading && <Table.Row><Table.Data colSpan={colCount} className='loading'>Loading assigned tests for asset</Table.Data></Table.Row>;
    const noResultsMessage = !loadingMessage && !results.length && <Table.Row><Table.Data colSpan={colCount} className='no-search-results'>No results for the current asset</Table.Data></Table.Row>;
    const badge            = <Badge variant='info' className='float-right'>{(tests || []).length}</Badge>;

    return (
      <div className='row mb-2'>
        <div className='col-sm-12'>
          <Form.Header className='alert-warning p-3'>Assigned Service Levels{badge}</Form.Header>
          <div id='assigned-service-levels' className='search-results'>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.Head className='col-service-level'>Service Level</Table.Head>
                  <Table.Head className='col-interval-days'>Interval Days</Table.Head>
                  <Table.Head className='col-last-date'>Last Date</Table.Head>
                  <Table.Head className='col-next-date'>Next Date</Table.Head>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {results}
                {loadingMessage}
                {noResultsMessage}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignedServiceLevelsTable;
