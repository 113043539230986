class Channel {
  constructor() {
    this.subscribers = [];
  }

  post = (data) => {
    this.dispatch(data);
  }

  dispatch = (data) => {
    if (this.subscribers.length === 0) {
      return;
    }
    this.subscribers.forEach(fn => fn(data));
  }

  map = (fn) => {
    let c = new Channel();

    this.subscribe(x => {
      c.post(x);
    });

    c.post = function (data) {
      c.dispatch(fn(data));
    };
    return c;
  }

  merge = (channel) => {
    let c = new Channel();

    this.subscribe(data => {
      c.post(data);
    });

    channel.subscribe(data => {
      c.post(data);
    });
    return c;
  }

  where = (fn) => {
    let c = new Channel();

    this.subscribe(x => {
      if (fn(x)) {
        c.post(x);
      }
    });

    c.post = function (data) {
      if (!fn(data)) {
        return;
      }

      c.dispatch(data);
    };
    return c;
  }

  close = () => {
    this.subscribers = [];
  }

  subscribe = (fn) => {
    if (!fn) {
      return { dispose() { } };
    }

    this.subscribers.push(fn);
    return { dispose: () => { this.subscribers.splice(this.subscribers.indexOf(fn), 1); } };
  }
}

export default Channel;