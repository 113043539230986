import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';

class ManufacturerDescriptionNotesController {
  constructor(parent) {
    this.parent  = parent;
    this.manDesc = '';
    this.notes   = [];
  }

  getNotes = () => {
    return this.notes;
  }

  getTitle = () => {
    const manDesc = this.manDesc;
    return 'Manufacturer Description Notes — ' + (manDesc && manDesc.mfgItemNo && manDesc.mfgItemNo.name);
  }

  load = (manDescId) => {
    ajax.get('lookup/getManufacturerDescription/', { manDescId: manDescId }).then(manDesc=>{
      this.manDesc = manDesc;
      this.parent.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  }); // out: CustomerDescriptionDto
  }

  loadNotes = (manDescId, flashNewNote) => {
    ajax.get('lookup/getManufacturerDescriptionNotes/', { manDescId: manDescId }).then(results=>{
      this.notes  = results;
      this.parent.commit();
  
      if (flashNewNote) {
        var newNote = results[results.length - 1];
        if(newNote.noteId!='undefined')
          this.parent.flashNote(newNote.noteId);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  }); // out: IList<NoteDto>
  }

  canAddNotes = () => {
    return ((LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep) || (LoginService.isTechnician));
  }

  addNote = (text,usersToNotify) => {
    const manDescId  = this.manDesc.manDescId;
    const parameters = {
      entityId : manDescId,
      noteType : AppConstants.NoteTypes.ManufacturerDescription,
      text     : text,
      usersToNotify: usersToNotify
    };
    this.parent.state.saveInProgress = true;
    this.parent.commit();
        ajax.post('manufacturerDescription/addNote', parameters).then(()=>{
          this.loadNotes(manDescId, true);
          this.parent.clearNoteText();
          this.loadNotes(manDescId, true);
          this.parent.state.saveInProgress = false;
          this.parent.commit();
        }).catch(err => {
          this.parent.state.saveInProgress = false;
          this.parent.commit();
          notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: AddNoteDto, out: void
  }
}

export default ManufacturerDescriptionNotesController;