import React from 'react';
import store from 'store';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class ScannedQRAssetsController extends BaseController {
  constructor() {
    super('scannedqrassets', {
      assetList        : [],
      standardReport   : false,
      nonStandardReport: false,
      qrCanvasVisibility : false,
      displayMessage     : false
    });
    this.actionPerformed = '';

    messages.channel('receiving').action('unable-to-receive-asset').subscribe(data => {
      if (!data || !data.assetId) {
          return;
      }
      const assets = this.state.assetList.filter(result => result.id === data.assetId);
      if (!assets.length) {
          return;
      }
      let asset                 = assets[0];
      asset.cannotReceive       = true;
      asset.reasonCannotReceive = data.msg;
      this.commit();
  });

  messages.channel('receiving').action('received-asset').subscribe(assetId => {
      if (!assetId) {
          return;
      }
      const assets = this.state.assetList.filter(result => result.id === assetId);
                   
      if (!assets.length) {
          return;
      }
      let asset                  = assets[0];
      asset.onReceivedAssetsList = true;
      asset.cannotReceive        = false;
      delete asset.reasonCannotReceive;
      notification.action('info').post('Added asset ' + asset.name + ' to Received Assets list');
      this.commit();
  });
  }

  clearResults = () => {
    this.state.assetList      = [];
    this.clearQRAssetsStore();
    this.commit();
  }

  clearQRAssetsStore = () => {
     store.remove('qrAssetsList');
  }

  loadFromStore = () => {
    const storedList = store.get('qrAssetsList');
    if(storedList){
      const customerId=LoginService.isCustomer && LoginService.loginInfo && LoginService.loginInfo.customerId;
      if(customerId)
      {
        const fAssets=storedList.filter(result => result.customerId === customerId);
        if(fAssets.length!=storedList.length)
        {
          this.state.displayMessage=true;
        }
        else
        {
          this.state.displayMessage=false;
        }
        this.state.assetList=fAssets;
        this.commit();
      }
      else
      {
        this.state.displayMessage=false;
        this.state.assetList = storedList;
        this.commit();
      }
    }
  }

  addAssetsToReceivingList = () => {
    this.actionPerformed = 'receiving';
    LoginService.autoLogin();
    this.commit();
  }

  printAssetCertificate = () => {
    this.actionPerformed = 'certificate';
    LoginService.autoLogin();
    this.commit();
  }

  handleReceiveAssets = () => {
    const assetIds = this.state.assetList.map(asset => asset.id);
    messages.channel('receiving').action('add-to-list').post(assetIds);
  }

  pushAssetsToMessageChannel = () => {
    const assetIds = this.state.assetList;
    messages.channel('receiving').action('add-to-list').post(assetIds);
  }

  removeScannedAsset = (asset) => {
    let scannedAssets = this.state.assetList;
    scannedAssets.splice(scannedAssets.indexOf(asset), 1);
    this.state.assetList = scannedAssets;
    this.commit();
    this.loadToStore();
  }

  loadToStore = () => {
    const customerId=LoginService.isCustomer && LoginService.loginInfo && LoginService.loginInfo.customerId;
    if(customerId)
    {
      var filteredAssets=this.state.assetList.filter(result => result.customerId === customerId);
      if(this.state.assetList.length!=filteredAssets.length)
      {
        this.state.displayMessage=true;
      }
      else
      {
        this.state.displayMessage=false;
      }
      this.state.assetList=filteredAssets;
      this.commit();
    }
    else
    {
      this.state.displayMessage=false;
      this.commit();
    }
    store.set('qrAssetsList', this.state.assetList);
  }
  
  toggleQrVisiblity = () => {
    this.state.qrCanvasVisibility = !this.state.qrCanvasVisibility;
    this.commit();
  }

  findAssetWithQRCode = (code) => {
    if(!code){
      return '';
    }
    let url = code.split('/');
    if(url.length === 6 && Utils.contains(code, 'http') && Utils.contains(code, 'scannedqrasset')) {
      var assetId = url[url.length - 1];
      this.loadAsset(assetId);
    }
    else{
      notification.action('warning').post({msg: 'Invalid QR'});
    }
  }

  loadAsset = (assetId) => {
      if (assetId) {
        ajax.post('security/getSerialNumberForQRAssets', assetId).then(result=>{
          if (result !== null) {
            let alreadyExist = false;
            this.state.assetList.forEach(asset =>{
              if(asset.id == result.id){
                alreadyExist = true;
                return;
              }
            });
            if(!alreadyExist){
              this.state.assetList.push(result);
              this.commit();
              this.loadToStore();
            }
          }
          else{
            notification.action('error').post({msg: 'Asset not found'});
          }
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
          return false;
        }); 
      }
  }
}
export default ScannedQRAssetsController;