import React from 'react';
import { Jumbotron as RBJumbotron} from 'react-bootstrap';
import styled from 'styled-components'

const BaseJumbotron = (props) => {
    return(
        <RBJumbotron {...props}>
            {props.children}
        </RBJumbotron>
    );
}

const Jumbotron = styled(BaseJumbotron)`
    padding: 0.9375rem !important;
    margin-bottom: unset;
`;

export default Jumbotron;