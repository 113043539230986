import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Keypad from '../../components/Keypad';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import SAPService from '../services/SAPService';
import AppConstants from '../utility/AppConstants';

class RecordAssemblyOperationTimeDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }


    handleClose = (result) => result.Cancel ? true : this.props.controller.save();


    render = () => {
        const binder = new Binder(this);
        const state = this.props.controller.state;
        const errors = state.errors;
        const testName = state.testName;
        const configsCount = state.awoSOWId.length;
        const message = <h5 className='col-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '5px' }}>How many minutes did you spend performing the <span className='font-weight-bold text-primary'>{testName}</span> operation for {(configsCount === 1 ? 'this' : 'these ')} {Utils.pluralize('group', 's', configsCount)}?</h5>;
        const value = binder.bind('assemblyMinutes')();
        const keypadClassname = 'col-md-6';
        const facilityId = LoginService.loginInfo.facilityId;
        const backDatingNote = <div className='col-sm-12 backdating-note-message'>{AppConstants.BackDatingNote}</div>;
        const notes =configsCount === 1
            ? <div className='col-md-12'><Form.TextArea id='notes' label='Notes' bind={binder.bind('notes')} maxLength='1000' /></div>
            : null;
        return (
            <Dialog {...this.props} allowKeyDownEvent={true} header={this.props.header} modal={true} width={500} height={500} id='inspection-time-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <form role='form'>
                        <div className='row'>
                            {message}
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-6 text-center'>
                                        <div className='keypad-value my-2'>{value} min</div>
                                        <Keypad className='inspection-keypad my-2' bind={binder.bind('assemblyMinutes')} allowDecimal={true} minValue={0} maxValue={720} />
                                    </div>
                                    <div id='pass-dialog-right-div' className='col-md-6'>
                                        <div className='row'>
                                            {LoginService.isCoordinator && !SAPService.useTTS() &&
                                                <div className='col-md-12'>
                                                    <Form.AutoComplete label='Technician' bind={binder.bind('technician')} search={Searchers.activeUsersForFacility(facilityId)} />
                                                    <Form.Date display='inline-block' label='Assembly Date:' bind={binder.bind('assemblyDate')} error={errors.inspectionDate} />
                                                </div>
                                            }
                                            {notes}
                                            {LoginService.isCoordinator && !SAPService.useTTS() && backDatingNote}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dialog.Body>
                <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
            </Dialog>
        );
    }
}

export default RecordAssemblyOperationTimeDialog;
