import React, { useMemo } from 'react';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Dialog from '../../components/Dialog';
import Grid from '../../components/Grid';
import Authentication from '../services/authentication';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import AssetChartDialog from '../dialogs/AssetChartDialog';
import AssetChartController from '../controllers/AssetChartController';

const AssetPressureTestChartTable = (props) => {

  const canNavigateToInspection = (data) => {
    return (LoginService.isTechnician && (data.facilityId === LoginService.loginInfo.facilityId));
  }

  const showChart = (chart) => {
    co(function* () {
      yield Dialog.showDialogWaitForResult(<AssetChartDialog controller={new AssetChartController(chart)} header='Pressure Test Chart' />);
    });
  }

  const handleDisplayChart = (data) => {
    if (!data || !data.chartHeaderId) {
      return;
    }
    ajax.get('lookup/getAssetChart', { chartHeaderId: data.chartHeaderId }).then(result => {
      if (result) {
        showChart(result);
      }
      else {
        notification.action('warning').post({ msg: 'No chart data available for asset with serial number ' + data.primarySerialNumber, title: 'Asset Chart Not Found' });
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  });  
  }

  const data = props.value || [];
  const loadingMessage = props.isLoading && 'Loading pressure test charts for asset';
  const noResultsMessage = !loadingMessage && !data.length && 'No results for the current asset';

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'default',
        disableSortBy: true,
        Cell: cellInfo => {
          const iconChart = Authentication.isTechnician
            ? <ActionIcon icon='fa-line-chart' className='btn-chart' action={() => handleDisplayChart(cellInfo.row.original)} />
            : null;
          return <span>{iconChart}</span>
        }
      },
      {
        Header: 'Job Number',
        accessor: 'jobNumber',
        disableSortBy: true,
        Cell: cellInfo => {
          const jobControl = canNavigateToInspection(cellInfo.row.original)
            ? <Form.Link to={'/inspection/' + cellInfo.row.original.jobId} value={cellInfo.cell.value} />
            : <span>{cellInfo.cell.value}</span>;
          const spanSAP = cellInfo.row.original.isSAPFacility ? <span className='user-facility-sap'> SAP</span> : null;
          return <div>{jobControl}{spanSAP}</div>
        }
      },
      {
        Header: 'Facility',
        accessor: 'facilityName',
        disableSortBy: true,
      },
      {
        Header: 'Job Date',
        accessor: 'jobDate',
        disableSortBy: true,
        Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
      },
      {
        Header: 'Test Unit',
        accessor: 'TestUnit',
        disableSortBy: true,
      },
    ],
    [handleDisplayChart]
  )
  return (
    <div id='selected-labor-codes'>
      <Grid id='results-table' responsive={false} showPagination={false} columns={columns} data={data}  noDataText={loadingMessage || noResultsMessage}/>
    </div>
  );
}

export default AssetPressureTestChartTable;
