import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import ManualPressureTestController from '../controllers/ManualPressureTestController';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Utils from '../utility/Utils';
import Authentication from '../services/authentication';
import PressureTestSettingsTable from './PressureTestSettingsTable';
import Jumbotron from '../../components/Jumbotron';

const Header = (props) => {
  const data = props.controller.state;
  const job = data.job && data.job.name;
  const customer = data.customer && data.customer.name;
  const familyCode = data.familyCode && data.familyCode.name;
  const cwp = data.cwp && data.cwp.name;

  return (
    <Jumbotron className='manual-pressure-test-header'>
      <div className='row'>
        <div className='col-md-3' ><Form.StaticData label='Job' value={job} /></div>
        <div className='col-md-3' ><Form.StaticData label='Customer' value={customer} /></div>
        <div className='col-md-3' ><Form.StaticData label='Family Code' value={familyCode} /></div>
        <div className='col-md-3' ><Form.StaticData label='CWP' value={cwp} /></div>
      </div>
    </Jumbotron>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleSave = () => {
    props.controller.save();
  }

  const handleClose = () => {
    history.goBack();
  }

  const canSave = true; // TODO: fill in logic - pressure test [(optional)] not complete and job not complete

  return (
    <div>
      <div className='mt-2'>
        <div className='edit-buttons float-right'>
          {canSave && <Button id='btn-save' icon='fa-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave}>Save</Button>}
          <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
        </div>
      </div>
    </div>
  );
}

const CustomerDescriptionSettings = (props) => {
  return (
    <div className='mt-2'>
      <Form.Header className='alert-warning'>Customer Description Pressure Test Settings</Form.Header>
      <PressureTestSettingsTable editable={false} source='Manual' pressureTestSettings={props.controller.state.customerDescriptionSettings || []} />
    </div>
  );
}

const ManualSettings = (props) => {
  const controller = props.controller;
  const editable = controller.state.canEdit;
  const settings = controller.state.overrideSettings || [];
  const pressureTestCount = settings.length;
  const pressureTestBadge = Utils.pluralize('Pressure Test', 's', pressureTestCount);
  const noResults = !editable && !settings.length
    ? 'There are no overriding settings for this asset on this job'
    : null;
  return (
    <div className='mt-2'>
      <Form.Header className='alert-warning'>
        Manual Settings for this Job
          <span className='float-right' style={{ marginTop: '-1px' }}>
          {editable && <Button icon='fa-plus-circle' variant='success' size='sm' className='' onClick={() => { controller.addNewPressureTestRow(); }}>Add ...</Button>}
          <Badge variant='info'>{pressureTestBadge}</Badge>
        </span>
      </Form.Header>
      <PressureTestSettingsTable editable={editable} source='Manual' controller={controller} pressureTestSettings={props.controller.state.overrideSettings} />
      {/* <div className='col-md-12 no-search-results ml-2' style={{ marginTop: '-20px'}}>{noResults}</div> */}
    </div>
  );
}

const ManualPressureTestPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class ManualPressureTestPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const query = this.props.location.state && this.props.location.state.params;
    this.props.controller.load(query);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandViewCustomerDescription();
  }

  static defaultProps = {
    controller: new ManualPressureTestController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const title = 'Manual Pressure Tests';

    return (
      <Page {...this.props} pageTitle='InteServ · Manual Pressure Tests' id='manual-pressure-test-page'>
        <ManualPressureTestPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <Header controller={controller} />
              <CustomerDescriptionSettings controller={controller} />
              <ManualSettings controller={controller} binder={binder} />
              <Buttons controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default ManualPressureTestPage;
