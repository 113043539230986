import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import $ from 'jquery'
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import TopPagination from '../components/TopPagination';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Utils from '../utility/Utils';
import MonitorInterfaceController from '../controllers/MonitorInterfaceController'
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import Search from '../components/Search';
import Searchers from '../utility/Searchers';
import GroupBadge from '../../components/GroupBadge';
import notification from '../../services/Notification';
import AppConstants from '../utility/AppConstants';
import ActionIcon from '../../components/ActionIcon';

const ResultsList = (props) => {

  let history = useHistory();

  //   componentDidMount() {
  //     var stream = this.props.controller.flash.delay(0);
  //     stream.onValue(this.flash);
  //     this.dispose = () => stream.offValue(this.flash);
  //  },

  //  componentWillUnmount() {
  //    this.dispose();
  //  },

  //  flash(jobId) {
  //    $('#monitor-transactions-table').find('#' + jobId + ' td')
  //      .velocity({ backgroundColor: '#FFFF9F' }, { duration: 1000 })
  //      .velocity('reverse');
  //  }
  const controller = props.controller;
  const noRowsMessage = 'No records found'
  const data = controller.state.searchResults || [];
  const matchesCount = controller.state.searchResults.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);
  const retryJob = controller.state.searchResults.filter(Api => Api.isSelected == true);
  const selectNoneEnable = retryJob.length ? false : true;
  const selectedData = data.filter(d => d.isSelected);
  const selectionType = useMemo(() => {
    let type = 'none';
    if (data.length !== 0 && selectedData.length !== 0) {
      if (data.length === selectedData.length) {
        type = 'all';
      }
      else if (selectedData.length > 0) {
        type = 'some';
      }
      else {
        type = 'none';
      }
    }
    return type;

  }, [data.length, selectedData.length]);

  const getRowProps = (row) => {
    const rowProps = {};
    const resultFilter = props.controller.state.searchResults.filter(Api => Api.isSelected == true);
    const resultFilterApi = resultFilter.length ? resultFilter[0].API : '';
    if (resultFilterApi != '' && resultFilterApi != row.original.API) {
      // notification.action('warning').post('Please select the transaction with similar API type');
      rowProps.canSelect = false;
    }
    return rowProps;
  }

  const handleRowClick = (job) => {
    if (job && job.length) {
      props.controller.selectJobRow(job);
    }
    else {
      props.controller.selectNone();
    }
  }

  const getCellProps = (cell) => {
    if (cell.row.original.isRetryFailed) {
      const className = 'text-danger';
      const rowProps = { className: className };
      return rowProps;
    }
  }

  const loadAssetDetails = (evt, job) => {
    evt.preventDefault();
    if (job.Serial) {
      if (job.Serial == 'Adhoc SO')
        history.push('/adhoctransactions/' + job.JobId);
      else
        history.push('/jobprogressassets/' + job.JobId + '/' + job.Serial);
    }
    else {
      history.push('/jobprogressassets/' + job.JobId);
    }
  }

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  const selectNone = () => {
    props.controller.selectNone();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Facility Name',
        accessor: 'FacilityName',
      },
      {
        Header: 'Customer Name',
        accessor: 'CustomerName',
      },
      {
        Header: 'Job Number',
        accessor: 'JobNumber',
        Cell: cellInfo => {
          const jobNumberField = LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep
            ? <Form.Link onClick={(evt) => { loadAssetDetails(evt, cellInfo.row.original); }} value={cellInfo.cell.value} />
            : cellInfo.cell.value;
          return <span>{jobNumberField}</span>
        }
      },
      {
        Header: 'Serial',
        accessor: 'Serial',
      },
      {
        Header: 'Group Number',
        accessor: 'GroupNumber',
      },
      {
        Header: 'Service Order Number',
        accessor: 'ServiceOrderNumber',
      },
      {
        Header: 'SAP Error Message',
        accessor: 'SAPErrorMessage',
      },
      {
        Header: 'API',
        accessor: 'API'
      },
      {
        Header: 'SAP Status',
        accessor: 'SAPStatus',
      },
      {
        Header: 'Error Date',
        accessor: 'ErrorDate',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
      },
    ],
    []
  )
  return (
    <div className='col-md-12'>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'>
          <GroupBadge pill={false} icon='far fa-square' variant='danger' size='sm' className='m-1' onClick={selectNone} disabled={selectNoneEnable} >Select none</GroupBadge>
          <Badge variant='info' className='m-1'>{matchesLabel}</Badge>
          <Badge variant='info' className='m-1 badge-inverse'> {retryJob.length + ' ' + 'Selected'}</Badge>
        </div>
      </div>
      <Grid id='results-table' columns={columns} data={data} getRowProps={getRowProps} getCellProps={getCellProps} onRowClick={handleRowClick} allowMultiSelect={true} selectionType={selectionType} noDataText={noRowsMessage} onPageChange={handlePageChange} />
    </div>
  );
}



///AWO result List to display in  AWO SAP transactions grid



const AWOResultsList = (props) => {

  let history = useHistory();

  const controller = props.controller;
  const noRowsMessage = 'No records found'
  const data = controller.state.searchResults || [];
  const matchesCount = controller.state.searchResults.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);
  const retryJob = controller.state.searchResults.filter(Api => Api.isSelected == true);
  const selectNoneEnable = retryJob.length ? false : true;
  const selectedData = data.filter(d => d.isSelected);
  const selectionType = useMemo(() => {
    let type = 'none';
    if (data.length !== 0 && selectedData.length !== 0) {
      if (data.length === selectedData.length) {
        type = 'all';
      }
      else if (selectedData.length > 0) {
        type = 'some';
      }
      else {
        type = 'none';
      }
    }
    return type;

  }, [data.length, selectedData.length]);

  const getRowProps = (row) => {
    const rowProps = {};
    const resultFilter = props.controller.state.searchResults.filter(Api => Api.isSelected == true);
    const resultFilterApi = resultFilter.length ? resultFilter[0].API : '';
    if (resultFilterApi != '' && resultFilterApi != row.original.API) {
      // notification.action('warning').post('Please select the transaction with similar API type');
      rowProps.canSelect = false;
    }
    return rowProps;
  }

  const handleRowClick = (awo) => {
    if (awo && awo.length) {
      props.controller.selectJobRow(awo);
    }
    else {
      props.controller.selectNone();
    }
  }

  const getCellProps = (cell) => {
    if (cell.row.original.isRetryFailed) {
      const className = 'text-danger';
      const rowProps = { className: className };
      return rowProps;
    }
  }

  const loadAssetDetails = (evt, awo) => {
    evt.preventDefault();
    
    const cb = () => { history.push('assembly') };
      controller.redirectToAssembly(awo.AWOId,cb);
  }

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  const selectNone = () => {
    props.controller.selectNone();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Facility Name',
        accessor: 'FacilityName',
      },
      {
        Header: 'Customer Name',
        accessor: 'CustomerName',
      },
      {
        Header: 'AWO Number',
        accessor: 'AWONumber',
        Cell: cellInfo => {
          const jobNumberField = LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep
            ? <Form.Link onClick={(evt) => { loadAssetDetails(evt, cellInfo.row.original); }} value={cellInfo.cell.value} />
            : cellInfo.cell.value;
          return <span>{jobNumberField}</span>
        }
      },
      {
        Header: 'Serial',
        accessor: 'Serial',
      },
      {
        Header: 'Group Number',
        accessor: 'GroupNumber',
      },
      {
        Header: 'Service Order Number',
        accessor: 'ServiceOrderNumber',
      },
      {
        Header: 'SAP Error Message',
        accessor: 'SAPErrorMessage',
      },
      {
        Header: 'API',
        accessor: 'API'
      },
      {
        Header: 'SAP Status',
        accessor: 'SAPStatus',
      },
      {
        Header: 'Error Date',
        accessor: 'ErrorDate',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
      },
    ],
    []
  )
  return (
    <div className='col-md-12'>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'>
          <GroupBadge pill={false} icon='far fa-square' variant='danger' size='sm' className='m-1' onClick={selectNone} disabled={selectNoneEnable} >Select none</GroupBadge>
          <Badge variant='info' className='m-1'>{matchesLabel}</Badge>
          <Badge variant='info' className='m-1 badge-inverse'> {retryJob.length + ' ' + 'Selected'}</Badge>
        </div>
      </div>
      <Grid id='results-awo-table' columns={columns} data={data} getRowProps={getRowProps} getCellProps={getCellProps} onRowClick={handleRowClick} allowMultiSelect={true} selectionType={selectionType} noDataText={noRowsMessage} onPageChange={handlePageChange} />
    </div>
  );
}


const NotesList = (props) => {

  const controller = props.controller;
  const noRowsMessage = 'No records meet the search criteria defined above'
  const data = controller.state.notesSearchResults || [];;
  const matchesCount = controller.state.notesSearchResults.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Job Number',
        accessor: 'JobNumber',
        Cell: cellInfo => {
          const jobNumberField = cellInfo.row.original.NoteType == 8
            ? <Form.Link to={'/jobattachments/' + cellInfo.row.original.JobId + '/' + cellInfo.row.original.JobNumber} value={cellInfo.cell.value} />
            : <Form.Link to={'/jobprogressassets/' + cellInfo.row.original.JobId} value={cellInfo.cell.value} />;
          return <span>{jobNumberField}</span>
        }
      },
      {
        Header: 'Serial Number',
        accessor: 'SerialNumber',
        Cell: cellInfo => {
          const equipmentNumber = cellInfo.row.original.EquipmentNumber != '' ? cellInfo.row.original.EquipmentNumber : '-';
          const serialNumberField = cellInfo.row.original.NoteType == 8
            ? <Form.Link to={'/inspectionattachments/' + cellInfo.row.original.SerialId + '/' + cellInfo.row.original.SerialNumber + '/' + equipmentNumber} value={cellInfo.cell.value} />
            : <Form.Link to={'/assetdetail/' + cellInfo.row.original.SerialId} value={cellInfo.cell.value} />;
          return <span>{serialNumberField}</span>
        }
      },
      {
        Header: 'Equipment Number',
        accessor: 'EquipmentNumber',
      },
      {
        Header: 'Service Order Number',
        accessor: 'ServiceOrderNumber',
      },
      {
        Header: 'Notes',
        accessor: 'Notes',
      },
      {
        Header: 'Added By',
        accessor: 'AddedBy',
      },
      {
        Header: 'Added Date',
        accessor: 'AddedOn',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
      },

    ],
    []
  )
  return (
    <div className='col-md-12'>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'>
          <Badge variant='info' className='m-1'>{matchesLabel}</Badge>
        </div>
      </div>
      <Grid id='results-table' columns={columns} data={data} noDataText={noRowsMessage} onPageChange={handlePageChange} />
    </div>
  );
}
const ModifiedTransactionsList = (props) => {

  const controller = props.controller;
  const noRowsMessage = 'No records meet the search criteria defined above'
  const data = controller.state.modifiedTransactionResults || [];;
  const matchesCount = controller.state.modifiedTransactionResults?.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount? matchesCount : 0);

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Job Number',
        accessor: 'JobNumber',
        Cell: cellInfo => {
          const jobNumberField = cellInfo.row.original.NoteType == 8
            ? <Form.Link to={'/jobattachments/' + cellInfo.row.original.JobId + '/' + cellInfo.row.original.JobNumber} value={cellInfo.cell.value} />
            : <Form.Link to={'/jobprogressassets/' + cellInfo.row.original.JobId} value={cellInfo.cell.value} />;
          return <span>{jobNumberField}</span>
        }
      },
      {
        Header: 'Serial Number',
        accessor: 'SerialNumber',
        Cell: cellInfo => {
          const equipmentNumber = cellInfo.row.original.EquipmentNumber != '' ? cellInfo.row.original.EquipmentNumber : '-';
          const serialNumberField = cellInfo.row.original.NoteType == 8
            ? <Form.Link to={'/inspectionattachments/' + cellInfo.row.original.SerialId + '/' + cellInfo.row.original.SerialNumber + '/' + equipmentNumber} value={cellInfo.cell.value} />
            : <Form.Link to={'/assetdetail/' + cellInfo.row.original.SerialId} value={cellInfo.cell.value} />;
          return <span>{serialNumberField}</span>
        }
      },
      {
        Header: 'Equipment Number',
        accessor: 'EquipmentNumber',
      },
      {
        Header: 'Service Order Number',
        accessor: 'ServiceOrderNumber',
      },
      {
        Header: 'Notes',
        accessor: 'Notes',
      },
      {
        Header: 'Modified By',
        accessor: 'ModifiedBy',
      },
      {
        Header: 'Modified Date',
        accessor: 'DateModified',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
      },

    ],
    []
  )
  return (
    <div className='col-md-12'>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'>
          <Badge variant='info' className='m-1'>{matchesLabel}</Badge>
        </div>
      </div>
      <Grid id='results-table' columns={columns} pageSizes ={[{ Id: 25, Name: '25' },  { Id: 50, Name: '50' }, { Id: 100, Name: '100' }, { Id: 200, Name: '200' },{ Id: 10000, Name: 'All' }]}  data={data} noDataText={noRowsMessage} onPageChange={handlePageChange} />
    </div>
  );
}

const MasterDataErrorList = (props) => {

  const controller = props.controller;
  const noRowsMessage = 'No records meet the search criteria defined above'
  const data = controller.state.mdErrorsSearchResults || [];;
  const matchesCount = controller.state.mdErrorsSearchResults.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Facility Name',
        accessor: 'FacilityName',
      },
      {
        Header: 'Customer Name',
        accessor: 'CustomerName',
      },
      {
        Header: 'Job Number',
        accessor: 'JobNumber',
        Cell: cellInfo => {
          const jobNumberField = LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep
            ? <Form.Link to={'/jobprogressassets/' + cellInfo.row.original.JobId + '/' + cellInfo.row.original.Serial} value={cellInfo.cell.value} />
            : <Form.Link to={'/jobprogressassets/' + cellInfo.row.original.JobId} value={cellInfo.cell.value} />;
          return <span>{jobNumberField}</span>
        }
      },
      {
        Header: 'Serial',
        accessor: 'Serial',
      },
      {
        Header: 'Group Number',
        accessor: 'GroupNumber',
      },
      {
        Header: 'Service Order Number',
        accessor: 'ServiceOrderNumber',
      },
      {
        Header: 'Error Message',
        accessor: 'MasterDataErrorMessage',
      },

    ],
    []
  )
  return (
    <div className='col-12'>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'><Badge variant='info' className='m-1'>{matchesLabel}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} noDataText={noRowsMessage} onPageChange={handlePageChange} />
    </div>
  );
}


const ManDescRequestList = (props) => {
  const controller = props.controller;
  const noRowsMessage = 'No records meet the search criteria defined above'
  const data = controller.state.manuDescRequestSearchResult || [];
  const matchesCount = controller.state.manuDescRequestSearchResult.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);
  const handleDelete = (item) => {
    props.controller.DeleteManDescRequest(item?.ManDescRequestGuid);
  }

  const handlePageChange = () => {
    TopPagination.Toggle();
  }



  const handleRowClick = (job) => {
    props.controller.selectManDescRequestRow(job);
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'default',
        Cell: cellInfo => !cellInfo.row.original.assetCount
          ? <ActionIcon icon='fa-trash' className='remove-handle' title='Delete manufacturer description request' action={() => handleDelete(cellInfo.row.original)} />
          : null
      },
      {
        Header: 'Mfg Part #',
        accessor: 'MfgPart',
        Cell: cellInfo => {
          const jobNumberField = LoginService.isAdministrator || LoginService.isSuperUser || LoginService.isCoordinator || LoginService.isSAPRep
            ? <Form.Link to={'/manufacturerdescription/' + cellInfo.row.original.ManDescGUID} value={cellInfo.cell.value} />
            : <span> cellInfo.row.original.MfgPart</span>;
          return <span>{jobNumberField}</span>
        }
      },
      {
        Header: 'Name',
        accessor: 'AddedByName',
      },
      {
        Header: 'Date',
        accessor: 'DateAdded',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
      },

    ],
    []
  )
  return (
    <div className='col-12'>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'>
          <Badge variant='info' className='m-1'>{matchesLabel}</Badge>
        </div>
      </div>
      <Grid id='description-requests-table' columns={columns} data={data} noDataText={noRowsMessage} onPageChange={handlePageChange} onRowClick={handleRowClick} allowMultiSelect={true} />
    </div>
  );
}


const CustDescRequestList = (props) => {
  const controller = props.controller;
  const noRowsMessage = 'No records meet the search criteria defined above'
  const data = controller.state.custDescRequestSearchResult || [];
  const matchesCount = controller.state.custDescRequestSearchResult.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  const handleCustDescRowClick = (job) => {
    props.controller.selectCustDescRequestRow(job);
  }

  const handleDelete = (item) => {
    props.controller.DeleteCustDescRequest(item?.CustDescRequestGuid);
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'default',
        Cell: cellInfo => !cellInfo.row.original.assetCount
          ? <ActionIcon icon='fa-trash' className='remove-handle' title='Delete customer description request' action={() => handleDelete(cellInfo.row.original)} />
          : null
      },
      {
        Header: 'Customer Description',
        accessor: 'CustomerDescription',
        Cell: cellInfo => {
          const jobNumberField = LoginService.isAdministrator || LoginService.isSuperUser
            ? <Form.Link to={'/customerdescription/' + cellInfo.row.original.CustDescGUID} value={cellInfo.cell.value} />
            : <span> cellInfo.row.original.CustomerDescription</span>;
          return <span>{jobNumberField}</span>
        }
      },
      {
        Header: 'Name',
        accessor: 'AddedByName',
      },
      {
        Header: 'Date',
        accessor: 'DateAdded',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
      },

    ],
    []
  )
  return (
    <div className='col-12'>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'>
          <Badge variant='info' className='m-1'>{matchesLabel}</Badge>
        </div>
      </div>
      <Grid id='customer-description-requests-table' columns={columns} data={data} noDataText={noRowsMessage} onPageChange={handlePageChange} onRowClick={handleCustDescRowClick} allowMultiSelect={true} />
    </div>
  );
}

const EquipmentUpdateErrorList = (props) => {

  const controller = props.controller;
  const noRowsMessage = 'No records meet the search criteria defined above'
  const data = controller.state.equipmentUpdateSearchResults || [];;
  const matchesCount = controller.state.equipmentUpdateSearchResults.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Equipment Number',
        accessor: 'equipmentNumber',
      },
      {
        Header: 'Request Message',
        accessor: 'reqMessage',
      },
      {
        Header: 'Error Message',
        accessor: 'errorMessage',
        Cell: cellInfo => {
          const warningMessage = cellInfo.row.original.warningMessage
            ? <span><br /><span className='text-warning'>{cellInfo.row.original.warningMessage}</span></span>
            : null;
          return <div>{cellInfo.cell.value}{warningMessage}</div>
        }
      },
      {
        Header: 'Error Date',
        accessor: 'dateOccured',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
      },

    ],
    []
  )
  return (
    <div className=''>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='mt-2 mr-2'><Badge variant='info' className='m-1'>{matchesLabel}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} noDataText={noRowsMessage} onPageChange={handlePageChange} />
    </div>
  );
}

const SAPTransactions = (props) => {

  const retryMessageClick = () => {
    const retryJob = props.controller.state.searchResults.filter(Api => Api.isSelected == true);
    props.controller.retryMessage(retryJob);
  }

  const handleExportClick = (e) => {
    const { controller } = props;
    if (controller.state.isLoading) {
      e.preventDefault();
    }
    else {
      props.controller.export();
    }
  }

  const handleSearch = () => {
    props.controller.searchJobs();
  }

  const handleClearSearch = () => {
    props.controller.clearSearchText();
  }

  var controller = props.controller;
  var binder = props.binder;
  var retryJob = controller.state.searchResults.filter(Api => Api.isSelected == true);
  var retryButton = retryJob.length && (LoginService.isAdministrator || LoginService.isCoordinator) ? <Button id='btn-retry' className='m-1' variant='primary' size='sm' disabled={controller.state.isRetryInProgress} onClick={retryMessageClick}>Retry</Button> : '';

  return (
    <div className='col-md-12 pt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} clear={handleClearSearch} search={handleSearch}></Search>
        </div>
        <div className='col-md-6'>
          <div className='row float-right mt-4'>
            <div>{retryButton}</div>
            <div><Button id='monitor-transactions-export' variant='primary' size='sm' className='m-1' icon='fa-file-excel' onClick={handleExportClick}>Export to excel</Button></div>
          </div>
        </div>
      </div>
      <div className='row'>
        <ResultsList controller={controller} binder={binder} />
      </div>
    </div>
  );
}


const AWOSAPTransactions = (props) => {
  
  const retryMessageClick = () => {
    const retryJob = props.controller.state.searchResults.filter(Api => Api.isSelected == true);
    props.controller.retryAWOTransaction(retryJob);
  }

  const handleExportClick = (e) => {
    const { controller } = props;
    if (controller.state.isLoading) {
     e.preventDefault();
    }
    else {
     props.controller.exportAWOs();
    }
  }

  const handleSearch = () => {
    props.controller.searchAWOs();
  }

  const handleClearSearch = () => {
    props.controller.clearAWOSearchText();
  }

  var controller = props.controller;
  var binder = props.binder;
  //var result = controller.searchAWOs();
  var retryJob = controller.state.searchResults.filter(Api => Api.isSelected == true);
  var retryButton = retryJob.length && (LoginService.isAdministrator || LoginService.isCoordinator) ? <Button id='btn-retry' className='m-1' variant='primary' size='sm' disabled={controller.state.isRetryInProgress} onClick={retryMessageClick}>Retry</Button> : '';

  return (
    <div className='col-md-12 pt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} clear={handleClearSearch} search={handleSearch}></Search>
        </div>
        <div className='col-md-6'>
          <div className='row float-right mt-4'>
            <div>{retryButton}</div>
            <div><Button id='monitor-transactions-export' variant='primary' size='sm' className='m-1' icon='fa-file-excel' onClick={handleExportClick}>Export to excel</Button></div>
          </div>
        </div>
      </div>
      <div className='row'>
        <AWOResultsList controller={controller} binder={binder} />
      </div>
    </div>
  );
}




const TransactionalNotes = (props) => {

  const handleSearch = () => {
    props.controller.searchTransactionalNotes();
  }

  const handleClearSearch = () => {
    props.controller.clearNotesSearchText();
  }

  const searchTransactionalNotesWithJob = () => {
    props.controller.searchTransactionalNotesWithJob();
  }

  const clearSearchCriteria = () => {
    props.controller.clearSearchCriteria();
  }
  const controller = props.controller;
  const binder = props.binder;
  const errors = controller.state.errors;
  return (
    <div className='col-md-12 pt-2'>
      <div className='align-items-center row'>
        <div className='job-search col-md-5'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('notesSearchText')} clear={handleClearSearch} search={handleSearch}></Search>
        </div>
        <div className='d-flex justify-content-center col-md-1'>
          <span className='font-weight-bold'>Or</span>
        </div>
        <div className='job-search-group col-md-6'>
          <div className='col-md-12'><Form.MultiSelect id='jobNumber-multiSelect' className='transactional-jobNumber-select' label='Job Number' placeholder='Click here to select JobNumbers' bind={binder.bind('jobNumbers')} search={Searchers.searchFacilityJobNumber(LoginService.loginInfo.facilityId)} /></div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-4'><Form.Date className='reduced-text-area' label='From Date' bind={binder.bind('fromDate')} options={{ endDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()) }} error={errors.fromDate} /></div>
              <div className='col-md-4'><Form.Date className='reduced-text-area' label='To Date' bind={binder.bind('toDate')} options={{ endDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()) }} error={errors.toDate} /></div>
              <div className='mt-4'><Button id='btn-search' icon='fa-save' variant='primary' size='sm' className='m-1' onClick={searchTransactionalNotesWithJob}>Search</Button></div>
              <div className='mt-4'><Button id='btn-clear' icon='fa-times' variant='info' size='sm' className='m-1' onClick={clearSearchCriteria}>Clear</Button></div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <NotesList controller={controller} />
      </div>
    </div>
  );
}
const ModifiedTransactions = (props) => {

  useEffect(() => {
    props.controller.loadData(props.data);
  }, []);

  const handleClearSearch = () => {
    props.controller.clearModifiedTransSearchText();
  }

  const searchModifiedTransactions = () => {
    props.controller.searchModifiedTransactions();
  }

  const searchModifiedTransactionsWithJobAndSerial = () => {
    props.controller.searchModifiedTransactionsWithJobAndSerial();
  }

  const handleExportClick = (e) => {
    const { controller } = props;
    if (controller.state.isLoading) {
      e.preventDefault();
    }
    else {
      props.controller.exportMonitorTransactions();
    }
  }

  const clearModifiedTransSearchCriteria = () => {
    props.controller.clearModifiedTransSearchCriteria();
  }
  const controller = props.controller;
  const binder = props.binder;
  const errors = controller.state.errors;
  return (
    <div className='col-md-12 pt-2'>
      <div className='align-items-center row'>
        <div className='job-search col-md-5'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('modifiedTransSearchText')} clear={handleClearSearch} search={searchModifiedTransactions}></Search>
        </div>
        <div className='d-flex justify-content-center col-md-1'>
          <span className='font-weight-bold'>Or</span>
        </div>
        <div className='job-search-group col-md-6'>
          <div className='col-md-9'><Form.MultiSelect style={{'width':'125%'}} id='jobNumber-modifiedTransc-multiSelect' className='transactional-modifiedTransc-jobNumber-select' label='Job Number' placeholder='Click here to select Job Numbers' bind={binder.bind('modifiedTransJobNumbers')} search={Searchers.searchFacilityJobNumber(LoginService.loginInfo.facilityId)} /></div>
          <div className='d-flex justify-content-center col-md-1' style={{ 'padding-bottom': '1rem','padding-top': '1rem'}}>
            <span className='font-weight-bold'>Or</span>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-6'><Form.Input placeholder={"allows multiple values"} label='Serial Number' bind={binder.bind('modifiedTransSerialNumbers')} hint='allows partial matches' /></div>
              <div className='mt-4'><Button id='btn-search' icon='fa-save' variant='primary' size='sm' className='m-1' onClick={searchModifiedTransactionsWithJobAndSerial}>Search</Button></div>
              <div className='mt-4'><Button id='btn-clear' icon='fa-times' variant='info' size='sm' className='m-1' onClick={clearModifiedTransSearchCriteria}>Clear</Button></div>
              <div style={{ 'padding-top': '1.4rem' }}><Button id='modified-transactions-export' variant='primary' size='sm' className='m-1' icon='fa-file-excel' onClick={handleExportClick}>Export to Excel</Button></div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <ModifiedTransactionsList controller={controller} />
      </div>
    </div>
  );
}
const MasterDataErrors = (props) => {

  const handleSearch = () => {
    props.controller.searchMdErrors();
  }

  const handleClearSearch = () => {
    props.controller.clearMdErrorsSearchText();
  }
  const controller = props.controller;
  const binder = props.binder;

  return (
    <div className='col-md-12 pt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('mdErrorsSearchText')} clear={handleClearSearch} search={handleSearch}></Search>
        </div>
      </div>
      <div className='row'>
        <MasterDataErrorList controller={controller} />
      </div>
    </div>
  );
}



const ManufacturerDescRequest = (props) => {

  const handleSearch = () => {
    props.controller.searchManuDescRequest();
  }

  const handleClearSearch = () => {
    props.controller.clearManuDescSearchText();
  }

  let history = useHistory();
  const controller = props.controller;
  const binder = props.binder;
  const selectedData = controller.state.manuDescRequestSearchResult.filter(Api => Api.isSelected == true);
  const canShowNotes = selectedData.length == 1;

  const handleAddNotes = () => {
    const manDesc = selectedData;
    if (!manDesc && manDesc.length != 1) {
      return;
    }
    history.push('/notes/' + AppConstants.NoteTypes.ManufacturerDescription + '/' + manDesc[0].ManDescGUID);
  }

  var notesButton = canShowNotes && (LoginService.isAdministrator || LoginService.isSuperUser) ? <Button id='btn-Notes' className='m-1' icon='fa-comments' variant='primary' size='sm' onClick={() => { handleAddNotes(); }}>Notes</Button> : '';
  return (
    <div className='col-md-12 pt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('manuDescRequestSearchText')} clear={handleClearSearch} search={handleSearch}></Search>
        </div>

        <div className='col-md-6'>
          <div className='row float-right mt-4'>
            <div>{notesButton}</div>
          </div>
        </div>

      </div>
      <div className='row'>
        <ManDescRequestList controller={controller} />
      </div>
    </div>
  );
}


const CustomerDescriptionRequests = (props) => {

  const handleSearch = () => {
    props.controller.searchCustDescRequest();
  }

  const handleClearSearch = () => {
    props.controller.clearCustuDescSearchText();
  }

  let history = useHistory();
  const controller = props.controller;
  const binder = props.binder;
  const selectedData = controller.state.custDescRequestSearchResult.filter(Api => Api.isSelected == true);
  const canShowNotes = selectedData.length == 1;

  const handleCustAddNotes = () => {
    const manDesc = selectedData;
    if (!manDesc && manDesc.length != 1) {
      return;
    }
    history.push('/notes/' + AppConstants.NoteTypes.CustomerDescription + '/' + manDesc[0].CustDescGUID);
  }

  var notesButton = canShowNotes && (LoginService.isAdministrator || LoginService.isSuperUser) ? <Button id='btn-Notes' className='m-1' icon='fa-comments' variant='primary' size='sm' onClick={() => { handleCustAddNotes(); }}>Notes</Button> : '';
  return (
    <div className='col-md-12 pt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('custDescRequestSearchText')} clear={handleClearSearch} search={handleSearch}></Search>
        </div>

        <div className='col-md-6'>
          <div className='row float-right mt-4'>
            <div>{notesButton}</div>
          </div>
        </div>

      </div>
      <div className='row'>
        <CustDescRequestList controller={controller} />
      </div>
    </div>
  );
}

const EquipmentUpdateErrors = (props) => {

  const handleSearch = () => {
    props.controller.searchEquipmentUpdateErrors();
  }

  const handleClearSearch = () => {
    props.controller.clearEquipmentUpdateErrorSearch();
  }

  const controller = props.controller;
  const binder = props.binder;

  return (
    <div className='col-md-12 pt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('equipmentUpdateErrorSearch')} clear={handleClearSearch} search={handleSearch}></Search>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <EquipmentUpdateErrorList controller={controller} />
        </div>
      </div>
    </div>
  );
}

const MonitorInterfacePageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-binoculars' />

class MonitorInterfacePage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.tabShow('1');
    this.props.controller.updateTabValue(this.props.location?.state?.params.tabKey);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
   // this.props.controller.updateTabValue("1");
  }

  static defaultProps = {
    controller: new MonitorInterfaceController()
  }
  handleSelect(key) {
    this.props.controller.updateTabValue(key);
  }
  render = () => {
    const controller = this.props.controller;
    const sapTransactionsTabTitle = 'SAP Transactions';
    const transactionalNotesTabTitle = 'Transactional Notes';
    const mdErrorsTabTitle = 'Master Data Errors';
    const equipmentUpdateErrorTitle = 'Equipment Update Errors';
    const ManufactureDescReqTabTitle = 'Manufacturer Description Requests';
    const customerDescriptionRequests = 'Customer Description Requests';
    const AWOSapTransactionsTabTitle='AWO SAP Transactions';
    return (
      <Page {...this.props} pageTitle='InteServ · Monitor Transactions' id='jmonitor-transaction-page'>
        <MonitorInterfacePageHeader pageTitle={'Monitor Transactions'} />
        <Page.Content>
          <div className='row'>
            <div className='col'>
            <Tabs activeKey={controller.state.tabValue} onSelect={(e) => this.handleSelect(e)}>
                <Tab eventKey={1} title={sapTransactionsTabTitle}>
                  <Tab.Pane>
                    <div className='row'>
                    {controller.state.tabValue === "1" ?  <SAPTransactions controller={controller} binder={new Binder(this)} /> : <></>}
                    </div>
                  </Tab.Pane>
                </Tab>
                <Tab eventKey={8} title={AWOSapTransactionsTabTitle}>
                  <Tab.Pane>
                    <div className='row'>
                    {controller.state.tabValue === "8" ?  <AWOSAPTransactions controller={controller} binder={new Binder(this)} /> : <></>}                      
                    </div>
                  </Tab.Pane>
                </Tab>
                <Tab eventKey={2} title={transactionalNotesTabTitle}>
                  <Tab.Pane>
                    <div className='row'>
                    {controller.state.tabValue === "2" ? <TransactionalNotes controller={controller} binder={new Binder(this)} /> : <></>} 
                    </div>
                  </Tab.Pane>
                </Tab>
                <Tab eventKey={3} title={mdErrorsTabTitle}>
                  <Tab.Pane>
                    <div className='row'>
                    {controller.state.tabValue === "3" ? <MasterDataErrors controller={controller} binder={new Binder(this)} /> : <></>} 
                    </div>
                  </Tab.Pane>
                </Tab>
                <Tab eventKey={4} title={equipmentUpdateErrorTitle}>
                  <Tab.Pane>
                    <div className='row'>
                    {controller.state.tabValue === "4" ? <EquipmentUpdateErrors controller={controller} binder={new Binder(this)} /> : <></>}
                    </div>
                  </Tab.Pane>
                </Tab>
                <Tab eventKey={5} title={'Modified Transactions'}>
                    <Tab.Pane>
                      <div className='row'>
                      {controller.state.tabValue === "5" ? <ModifiedTransactions controller={controller} binder={new Binder(this)} data={this.props.location?.state?.params} /> : <></>}
                      </div>
                    </Tab.Pane>
                  </Tab>
                  {LoginService.isSuperUser && <Tab eventKey={6} title={ManufactureDescReqTabTitle}>
                    <Tab.Pane>
                      <div className='row'>
                      {controller.state.tabValue === "6" ?  <ManufacturerDescRequest controller={controller} binder={new Binder(this)} /> : <></>}
                      </div>
                    </Tab.Pane>
                  </Tab>}
                  {LoginService.isSuperUser && <Tab eventKey={7} title={customerDescriptionRequests}>
                  <Tab.Pane>
                    <div className='row'>
                    {controller.state.tabValue === "7" ? <CustomerDescriptionRequests controller={controller} binder={new Binder(this)} /> : <></>}
                    </div>
                  </Tab.Pane>
                </Tab>}
              </Tabs>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
};

export default MonitorInterfacePage;
