import _ from 'immutable';
import $ from 'jquery';
import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';

class TransferAssetsToJobController extends BaseController {
    constructor() {
        super('transfer-assets-to-job', {
            job: '',
            assets: [],
            removeFromContainer: false,
            isSaving: false,
            groups: [],
            multiSelectGroups: [],
            newJob: '',
            groupNumber: '',
            groupName: '',
        });
    }

    load = (jobId) => {
        this.clear();
        if (!jobId) {
            this.commit();
            return;
        }
        this.loadJob(jobId);
        this.loadTransferrableAssets(jobId);
    }

    loadJob = (jobId) => {
        ajax.get('lookup/getJob', { jobId: jobId }).then(results => {
            this.state.job = results;
            this.commit()
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    loadTransferrableAssets = (jobId) => {
        ajax.get('lookup/getTransferrableAssets', { jobId: jobId }).then(results => {
            this.state.assets = results;
            this.initializeGroups();
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    clear = () => {
        this.state.job = '';
        this.state.requestingCustomer = '';
        this.state.selectedAssets = [];
        this.state.groups = [];
        this.state.assets = [];
        this.state.newJob = '';
        this.state.groupNumber = 
        this.state.groupName = '';
    }

    initializeGroups = () => {
        let groups = [];

        this.state.assets.filter(asset => {
            if (groups.indexOf(asset.groupNumber) === -1) {
                groups.push({ groupNumber: asset.groupNumber, groupName: asset.groupName });
            }
        });

        groups.sort(function (a, b) {
            return (a.groupNumber - b.groupNumber);
        });

        this.state.groups = groups.map(group => {
            return {
                value: group,
                selected: false
            };
        });
    }

    activeJobSearch = () => {
        const self = this;
        return (searchTerm) => ajax.get('lookup/searchActiveJobs_Trans', {
            facilityId: self.state.job.facilityId,
            customerId: self.state.job.customerId,
            searchTerm: searchTerm
        }).then(results => {
            results = results.filter(result => ((result.id != self.state.job.id) && (result.accountingIndicator == self.state.job.accountingIndicator)));
            return results;
        });
    }

    selectNoAssets = () => {
        this.state.assets.forEach(asset => {
            asset.selected = false;
        });
        this.state.multiSelectGroups = '';
        $('#group-multiselect').select2('data', []);
        this.commit();
    }

    selectNoSelectedAssets = () => {
        this.state.selectedAssets.forEach(asset => {
            asset.selected = false;
        });
        this.commit();
    }

    selectAllAssets = () => {
        this.state.assets.forEach(asset => {
            asset.selected = true;
        });
        this.commit();
    }

    selectAllSelectedAssets = () => {
        this.state.selectedAssets.forEach(asset => {
            asset.selected = true;
        });
        this.commit();
    }

    toggleSelection = (asset) => {
        asset.selected = !asset.selected;
        this.commit();
    }

    deselectAllGroups = () => {
        this.state.multiSelectGroups = '';
        $('#group-multiselect').select2('data', []);
        this.state.groups.forEach(group => { group.selected = false; });
        this.selectNoAssets();
        this.commit();
    }

    toggleGroupSelection = (clickedGroup) => {
        this.state.groups.forEach(group => {
            if (group.value.groupNumber === parseInt(clickedGroup, 10)) {
                const groupNumber = group.value.groupNumber;
                group.selected = !group.selected;

                this.state.assets
                    .filter(asset => (asset.groupNumber === groupNumber))
                    .forEach(asset => {
                        asset.selected = group.selected;
                    }
                    );
            }
        });
        this.commit();
    }

    multiSelectGroupsChanged = () => {
        if (this.state.multiSelectGroups) {
            const selectedGroups = this.state.multiSelectGroups.map(group => group.id);
            this.state.groups.forEach(group => {
                if (selectedGroups.includes(group.value.groupNumber)) {
                    group.selected = true;
                    this.selectSelectedGroupAssets(group.value.groupNumber, true);
                }
                else {
                    group.selected = false;
                    this.selectSelectedGroupAssets(group.value.groupNumber, false);
                }
            });
        }
        else {
            this.state.groups.forEach(group => {
                group.selected = false;
                this.selectSelectedGroupAssets(group.value.groupNumber, false);
            });
        }
        this.commit();
    }

    selectSelectedGroupAssets = (groupNumber, status) => {
        this.state.assets
            .filter(asset => (asset.groupNumber === groupNumber))
            .forEach(asset => {
                asset.selected = status;
            }
            );
    }

    selectAndTransfer = (asset) => {
        asset.selected = true;
        if (_.List(this.state.assets).contains(asset)) {
            this.moveToSelected();
        }
        else {
            this.removeFromSelected();
        }
    }

    moveToSelected = () => {
        let i = this.state.assets.length;
        while (i--) {
            let asset = this.state.assets[i];
            if (asset.selected) {
                asset.selected = false;
                this.state.assets.splice(i, 1);
                this.state.selectedAssets.push(asset);
            }
        }

        // sort selected list
        this.state.selectedAssets.sort(function (a, b) {
            return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
        });
        this.commit();
    }

    removeFromSelected = () => {
        let i = this.state.selectedAssets.length;
        while (i--) {
            const asset = this.state.selectedAssets[i];
            if (asset.selected) {
                asset.selected = false;
                this.state.selectedAssets.splice(i, 1);
                this.state.assets.push(asset);
            }
        }

        // sort primary list
        this.state.assets.sort(function (a, b) {
            return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
        });
        this.commit();
    }

    selectCurrentAsset = (asset) => {
        asset.selected = true;
        this.state.currentAsset = asset;
        this.commit();
    }

    isAssetScrapped = (asset) => {
        return (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped);
    }

    canTransferToJob = () => {
        let success = true;

        if (this.state.selectedAssets.length === 0) {
            notification.action('warning').post('You must select at least one asset');
            success = false;
        }

        if (!this.state.newJob) {
            notification.action('warning').post('You must select either a New or an Existing job');
            success = false;
        }

        if (this.state.newJob.id === this.state.job.id) {
            notification.action('warning').post('You must select a different job from the current one');
            success = false;
        }

        if (!success) {
            this.commit();
        }

        return success;
    }

    save = (cb) => {
        if (!this.canTransferToJob()) {
            return;
        }
        this.state.isSaving = true;
        this.commit();
        var selectedAssetIds = this.state.selectedAssets.map(asset => { return asset.jobSOWId; });   // use only the ids
        var parameters = {
            jobId: this.state.job.id,
            targetJobId: this.state.newJob.id,
            requestingCustomer: this.state.requestingCustomer,
            removeFromContainer: this.state.removeFromContainer,
            assets: selectedAssetIds,
            groupNumber: this.state.groupNumber && this.state.groupNumber.name,
            groupName: this.state.groupName,
        };

        ajax.post('jobprogress/transferAssetsToJob', parameters).then(results => {
            this.state.isSaving = false;
            this.commit();
            var msg = 'Transferred ' + Utils.pluralize('asset', 's', selectedAssetIds.length) + ' from job ' + this.state.job.jobNumber + ' to job ' + this.state.newJob.name;
            notification.action('info').post({ msg: msg, title: 'Job Assets Transferred' });
            messages.channel('edit-job-asset-details').action('saved').post();
            cb();
        }).catch(err => {
            this.state.isSaving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    groupNumberSearch = () => {
        const jobId = this.state.newJob && this.state.newJob.id;
        return function* (searchTerm) {
            const parameters = { jobId: jobId };
            const results = yield ajax.get('lookup/searchJobGroupNamesAndNumbers', parameters);
            return results;
        };
    }
    
    selectScrapped = () => {
        this.selectNoAssets();
        this.state.assets
          .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped)
          .forEach(asset => asset.selected = true);
    }
}

export default TransferAssetsToJobController;
