import React from 'react';
import store from 'store';
import { useLocation } from 'react-router-dom';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import FieldAssetManagementController from '../controllers/FieldAssetManagementController';
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import notifications from '../../services/Notification';
import Search from '../components/Search';
import Jumbotron from '../../components/Jumbotron';
import QRScanner from '../components/QRScanner';

const LookupAsset = (props) => {

  const binder = props.binder;
  const controller = props.controller;
  const asset = props.asset;
  const canEdit = !!asset;

  const handleQRInvalid = () => {
    notifications.action('warning').post({ msg: 'Invalid QR Code!', title: 'QR Code' });
    controller.clearSearchResults();
  }

  const qrScanner = controller.state.qrCanvasVisibility
    ? <QRScanner handleScan={controller.findAssetWithQRCode} handleQRInvalid={handleQRInvalid} />
    : '';

  const editButton = <Button icon='fa-edit' id='edit-asset' to={'/editfieldasset/' + asset.assetId} variant='info' size='md' className='btn-block' disabled={!canEdit}>Edit Asset ...</Button>

  return (
    <div className='col-12'>
      <div className='row'>
        <div className='col-12'><SearchBox asset={asset} controller={controller} binder={binder} /></div>
        <div className='col-12'> {qrScanner} </div>
        <div className='col-12'><AssetDescription asset={asset} controller={controller} /></div>
        <div className='col-12'>{editButton} </div>
      </div>
    </div>
  );
}

const SearchBox = (props) => {
  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.findAsset();
  }

  const handleChange = (evt) => {
    props.controller.handleRfidSearch(evt.target.value);
  }

  const handleMouseSearch = () => {
    props.controller.findAsset();
  }

  const clearSearch = () => {
    props.controller.clearSearchText();
  }

  const handleQrVisiblity = () => {
    props.controller.toggleQrVisiblity();
  }

  const asset = props.asset;
  const canProcess = props.controller.canProcess(asset) ? '' : 'true';
  const binder = props.binder;
  const placeholder = 'search criteria'; // 'primary or secondary serial number or scan rfid tag'
  const qrShowButton = props.controller.state.qrCanvasVisibility
    ? <Button icon='fa-qrcode' variant='info' size='sm' className='' onClick={handleQrVisiblity}>Hide QR Scanner</Button>
    : <Button icon='fa-qrcode' variant='info' size='sm' className='' onClick={handleQrVisiblity}>Show QR Scanner</Button>

  return (
    <div className='row search-container' onChange={handleChange} onKeyDown={handleKeyboardSearch}>
      <div className='col-12'> <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' prependLabelIcon='fa-rss' placeholder={placeholder} autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search></div>
      <div className='col-12 text-center qr-enable-button'>{qrShowButton}</div>
    </div>
  );
}

const AssetDescription = (props) => {

  let location = useLocation();

  const handleGetChartData = () => {
    props.controller.getChart();
  }

  const handleRfid = () => {
    var asset = props.asset;
    store.set(asset.assetId, asset);
    store.set('callingRoute', location.pathname);
    this.transitionTo('editrfid', { assetId: asset.assetId });
  }

  const getNextInspection = (asset) => {
    if (!asset || (asset.status === 'Scrapped')) {
      return null;
    }

    const nextInspectionDate = DateTimeFormatter.formatDate(asset.nextInspectionDate);
    if (asset && !nextInspectionDate) {
      return 'No History';
    }

    return `${asset.nextInspectionServiceLevel} ${nextInspectionDate}`;
  }

  const getAssetStatus = (asset) => {
    let assetStatus = asset.status;
    if (asset.rejectReason) {
      if (assetStatus != 'Passed')
        assetStatus += ' (' + asset.rejectReason + ')';
    }
    return assetStatus;
  }

  const getScrapDetails = (asset) => {
    let scrapDetails = '';
    if (asset.scrapDetails) {
      const sh = asset.scrapDetails;
      scrapDetails = ` Failed ${sh.serviceLevelName} :: ${sh.testName} on job ${sh.jobNumber} at ${sh.facilityName} on ${DateTimeFormatter.formatDate(sh.scrapDate)}`;
    }
    return scrapDetails;
  }

  const getRFIDStatus = (asset) => {
    if (LoginService && LoginService.isCustomer) {
      if (LoginService.loginInfo.customerId == asset.customer.id) {
        return true;
      }
      return false;
    }
    else {
      return true;
    }
  }

  let asset = props.asset;
  const bornOnDate = DateTimeFormatter.formatDate(asset.bornOnDate);
  const inServiceDate = DateTimeFormatter.formatDate(asset.inServiceDate);
  if (asset.status === 'Scrapped') {
    asset.nextInspectionDate = null;
  }

  let classNextInspection = null;
  let inspectionIcon = null;
  const daysUntilNextInspection = DateTimeFormatter.getMoment(asset.nextInspectionDate).diff(DateTimeFormatter.today(), 'days');

  if (daysUntilNextInspection <= 0) {
    classNextInspection = 'late-error';
    inspectionIcon = asset && 'late-error fa-circle';
  }
  else if (daysUntilNextInspection < 90) {
    classNextInspection = 'late-warning';
    inspectionIcon = 'late-warning fa-circle';
  }

  const nextInspection = getNextInspection(asset); // asset && DateTimeFormatter.formatDate(asset.nextInspectionDate);
  if ((nextInspection === '') || (nextInspection === 'No History')) {
    inspectionIcon = null;
  }

  const container = asset.container;
  let statusColourClass = 'status' + asset.status; // e.g. statusScrapped
  if (asset.status === 'Weld Repair' || asset.status === 'Failed Inspection')
    statusColourClass = 'asset-status statusWeldRepair'
    if (asset.status === 'OS/Machining Repair') 
    statusColourClass = 'asset-status statusOSRepair'
  const assetStatus = getAssetStatus(asset);
  const enableRFID = asset ? getRFIDStatus(asset) : false;
  const scrapDetails = getScrapDetails(asset);
  const canGetChartData = (asset && asset.hasChart)
    ? 'true'
    : '';
  const hasRfidTag = asset
    ? asset.rfidTagId || asset.rfidTagId2
      ? 'Yes'
      : 'No'
    : '';
  const isTPRS = asset
    ? asset.isTPRS
      ? 'Yes'
      : 'No'
    : '';

  return (
    <Jumbotron className='my-2'>
    <div id='asset-description'>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Primary Serial Number' value={asset.primarySerialNumber} /></div>
        <div className='col-md-6'><Form.StaticData label='Secondary Serial Number' value={asset.secondarySerialNumber} /></div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Batch Number' value={asset.batchNumber} /></div>
        {/* <div className='col-md-3'><Form.StaticData label='RFID Tag' value={hasRfidTag} /></div>
              <div className='col-md-3'><Form.StaticData label='TPRS' value={isTPRS} /></div> */}
      </div>

      <Form.StaticData label='Description' value={asset.description && asset.description.name} />
      <Form.StaticData label='Customer' value={asset.customer && asset.customer.name} />

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Container' value={container && container.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Status' value={assetStatus} className={statusColourClass} /></div>
      </div>

      {scrapDetails &&
        <div className='row'>
          <div className='col-md-12'><Form.StaticData label='Scrap Details' value={scrapDetails} className='statusScrapped' /></div>
        </div>
      }

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Born On Date' value={bornOnDate} /></div>
        <div className='col-md-6'><Form.StaticData label='In Service Date' value={inServiceDate} /></div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Next Inspection' value={nextInspection} className={classNextInspection} icon={inspectionIcon} /></div>
        <div className='col-md-6'><Form.StaticData label='Current Location' value={asset.currentLocation && asset.currentLocation.name} /></div>
      </div>

      <Form.StaticData label='Notes' value={asset.notes} className='field-notes' />

      <Button icon='fa-chart-line' variant='info' size='sm' className='' disabled={!canGetChartData} onClick={handleGetChartData}>Chart ...</Button>
      {/* &nbsp;
            <Button icon='fa-rss' variant='primary' size='sm' className='' disabled={!asset && !enableRFID}           onClick={handleRfid}>RFID ...</Button> */}
    </div>
    </Jumbotron>
  );
}
const FieldAssetManagementPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-bullseye' />

class FieldAssetManagementPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }
  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new FieldAssetManagementController()
  }
  render = () => {
    const controller = this.props.controller;
    const state = controller.state;
    const binder = new Binder(this);
    return (
      <Page {...this.props} pageTitle='InteServ · Iron Management Portal' id='field-asset-management-page'>
        <FieldAssetManagementPageHeader pageTitle={'Iron Management Portal'} />
        <Page.Content>
          <div className='row'>
            <LookupAsset controller={controller} binder={binder} asset={state.currentAsset} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}
export default FieldAssetManagementPage;

