import store from 'store';
import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants from '../utility/AppConstants';
import DropoffRowController from './DropoffRowController';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';

class EditDropoffController extends BaseController {
    constructor() {
        super('edit-dropoff', EditDropoffController.getInitialState());
        this.adding              = false;
        this.applications        = [];
        this.equipmentCategories = [];
        this.serviceIntervals    = [];
        this.loadValidators();
      }
    
      load = (dropoffId) => {
        this.loadApplications();
        this.loadEquipmentCategories();
        this.loadServiceIntervals();
    
        if (dropoffId === Utils.emptyGuid) {
          this.adding = true;
          this.state  = EditDropoffController.getInitialState(dropoffId);
          this.updateValidators();
          this.commit();
        }
        else {
          this.adding = false;
          this.state  = EditDropoffController.getInitialState(dropoffId);
          this.commit();
          ajax.get('lookup/getDropoff/', { dropoffId: dropoffId }).then(results => {
            this.merge(results);
            this.updateValidators();
            this.commit();
          });
        }
      }
    
      merge = (dropoff) => {
        if (!dropoff) {
          return;
        }

        this.state                       = Object.assign({}, dropoff);
        this.state.destinationIdleAssets = (dropoff.destinationType === AppConstants.eDropoffDestination.IdleAssets);
        this.state.destinationJob        = (dropoff.destinationType === AppConstants.eDropoffDestination.AssignToJob);
        this.state.requestedDate         = (dropoff.requestedDate && DateTimeFormatter.formatDropdownDate(dropoff.requestedDate)) || '';
        this.state.errors                = {};
        this.state.rows                  = [];
    
        this.state.rows = dropoff.rows.map(row => new DropoffRowController(this, row));
      }
    
      static getInitialState = (dropoffId) => {
        return {
          dropoffId             : dropoffId,
          dropoffNumber         : '',
          customer              : '',
          customerLocation      : '',
          customerPO            : '',
          application           : '',
          equipmentCategory     : '',
          equipmentDetails      : '',
          isComplete            : '',
          job                   : '',
          serviceInterval       : '',
          service               : '',
          createdDate           : '',
          requestedDate         : '',
          deliveredByName       : '',
          deliveredBySignature  : '',
          destinationIdleAssets : false,
          destinationJob        : true,
          rows                  : [],
          errors                : {},
          hours                 : '0',
          minutes               : '0',
          TransactionDate       : '',
          isSaveInProgress      : false,
          isDownloadInProgress  : false
        };
      }

      customerChanged = () => {
        this.state.customerLocation = '';
        this.state.job              = '';
        this.commit();
      }
    
      destinationJobChanged = () => {
        this.state.destinationIdleAssets = false;
        this.updateValidators();
        this.commit();
      }
    
      destinationIdleAssetsChanged = () => {
        this.state.destinationJob  = false;
        this.state.serviceInterval = '';
        this.state.service         = '';
        this.state.requestedDate   = '';
        this.updateValidators();
        this.commit();
      }
    
      updateValidators = () => {
        this.state.errors = {};
        if (this.state.destinationJob) {
          this._validator.add('serviceInterval' , 'Service Interval'                                      , [{required: true}]);
          this._validator.add('service'         , 'Service to be Performed'                               , [{required: true}]);
          this._validator.add('requestedDate'   , 'Requested Date must be greater than or equal to today' , [{isGreaterThanEqualsDate: { params: DateTimeFormatter.today()}}]);
        }
        else {
          // this.state.destinationIdleAssets === true
          this._validator.remove('serviceInterval');
          this._validator.remove('service');
          this._validator.remove('requestedDate');
        }
      }
    
      loadApplications = () => {
        co(this, function*() {
          const results       = yield ajax.get('lookup/searchLookupList/Application', { searchTerm: '' }); // IList<LookupDto>
          this.applications = results;
          return results;
        });
      }
    
      loadEquipmentCategories = () => {
        co(this, function*() {
          const results              = yield ajax.get('lookup/searchLookupList/Equipment%20Category', { searchTerm: '' }); // IList<LookupDto>
          this.equipmentCategories = results;
          return results;
        });
      }
    
      loadServiceIntervals = () => {
        co(this, function*() {
          let results = yield ajax.get('lookup/searchLookupList/Service%20Interval', { searchTerm: '' }); // IList<LookupDto>
    
          // alpha-numeric sort so service intervals appear in ascending order of interval length
          // 'x months' < 'y years'
          // 'x months' < 'y months' if x < y
          results.sort((a, b) => {
            const aHasMonths = (a.name.indexOf('months') >= 0);
            const bHasMonths = (b.name.indexOf('months') >= 0);
            let result;
    
            // both end in 'months' or 'years'
            // determine the number of months/years and sort the smaller number to appear 1st
            if ((aHasMonths && bHasMonths) || (!aHasMonths && !bHasMonths)) {
              result = parseInt(a.name, 10) - parseInt(b.name, 10);
              return result;
            }
    
            // one ends in 'months', the other ends in 'years'
            // sort months before years
            result = aHasMonths ? -1 : 1;
            return result;
          });
          this.serviceIntervals = results;
          return results;
        });
      }
    
      loadValidators = () => {
        this._validator = new Validator();
        this._validator.add('customer'             , 'Customer'           , [{required: true}]);
        this._validator.add('customerLocation'     , 'Customer Location'  , [{required: true}]);
        this._validator.add('application'          , 'Application'        , [{required: true}]);
        this._validator.add('equipmentCategory'    , 'Equipment Category' , [{required: true}]);
        this._validator.add('equipmentDetails'     , 'Equipment Details'  , [{required: true}]);
        this._validator.add('deliveredByName'      , 'Delivered by name'  , [{required: true}]);
        this._validator.add('deliveredBySignature' , 'Signature'          , [{required: true}]);
      }
    
      customerSearch = () => {
        return function* (searchTerm) {
          const facilityId = LoginService.loginInfo.facilityId; 
          const results    = yield ajax.get('lookup/searchCustomersForFacility', {facilityId : facilityId, searchTerm: searchTerm}); // IList<LookupDto>
          return results;
        };
      }
    
      customerLocationSearch = () => {
        const self = this;
        return function* (searchTerm) {
          const customerId = (self.state.customer && self.state.customer.id);
          if (!customerId) {
            notifications.action('warning').post('Please select a customer so you can choose a Customer Location.');
            return null;
          }
            const facilityId = LoginService.loginInfo.facilityId; 
          const results    = yield ajax.get('lookup/searchCustomerLocations', { facilityId: facilityId, customerId: customerId, searchTerm: searchTerm }); // IList<CustomerLocationLookupDto>
          return results;
        };
      }
    
      applicationSearch = () => {
        const self = this;
        return function* (searchTerm) {
          if (self.applications.length) {
            return self.applications;
          }
          return self.loadApplications();
        };
      }
    
      equipmentCategorySearch = () => {
        const self = this;
        return function* (searchTerm) {
          if (self.equipmentCategories.length) {
            return self.equipmentCategories;
          }
          return self.loadEquipmentCategories();
        };
      }
    
      serviceIntervalSearch = () => {
        const self = this;
        return function* (searchTerm) {
          if (self.serviceIntervals.length) {
            return self.serviceIntervals;
          }
          return self.loadServiceIntervals();
        };
      }
    
      familyCodeSearch = () => {
        return function* (searchTerm) {
          const results = yield ajax.get('lookup/searchLookupList/Family%20Code', { searchTerm: searchTerm }); // IList<LookupDto>
          return results;
        };
      }
    
      nominalSizeSearch = () => {
        return function* (searchTerm) {
          // Nominal Size
          const results = yield ajax.get('lookup/searchLookupList/Nominal%20Size', { searchTerm: searchTerm }); // IList<LookupDto>
          return results;
        };
      }
    
      manufacturerSearch = () => {
        return function* (searchTerm) {
          // Manufacturer
          const results = yield ajax.get('lookup/searchLookupList/Manufacturer', { searchTerm: searchTerm }); // IList<LookupDto>
          return results;
        };
      }
    
      styleSearch = () => {
        return function* (searchTerm) {
          // Style
          const results = yield ajax.get('lookup/searchLookupList/Style', { searchTerm: searchTerm }); // IList<LookupDto>
          return results;
        };
      }
    
      endConnectionSearch = () => {
        return function* (searchTerm) {
          // Connection Type
          const results = yield ajax.get('lookup/searchLookupList/End%20Connection', { searchTerm: searchTerm }); // IList<LookupDto>
          return results;
        };
      }

      lengthSearch = () => {
        return function* (searchTerm) {
          // Length
          const results = yield ajax.get('lookup/searchLookupList/Length', { searchTerm: searchTerm }); // IList<LookupDto>
          return results;
        };
      }
    
      addNewRow = () => {
        this.state.rows.push(new DropoffRowController(this));
        this.commit();
      }
    
      removeRow = (row) => {
        const idx = this.state.rows.indexOf(row);
        if (idx === -1) {
          return;
        }
    
        this.state.rows.splice(idx, 1);
        this.commit();
      }
    
      clearSignature = () => {
        this.state.deliveredBySignature = '';
        this.commit();
      }
    
      updateSignature(signature) {
        this.state.deliveredBySignature = signature;
        this.commit();
      }
    
      validateRows = (state) => {
        if (!state.rows.length) {
          state.errors.rows = 'At least one row must be specified';
          return;
        }
        state.rows.forEach(row => row.validate());
      }
    
      canSave = () => {
        this.state.errors = {};
        this._validator.validateAll(this.state);
        this.validateRows(this.state);
    
        const errors    = Object.keys(this.state.errors);
        const rowErrors = this.state.rows.reduce((acc, curr) => {
          return acc + Object.keys(curr.state.errors).length;
        }, 0);
    
        if (!errors.length && !rowErrors) {
          return true;
        }
    
        this.commit();
        return false;
      }
    
      save = (cb) => {
        if (!this.canSave()) {
          return;
        }
    
        const s          = this.state;
        const dropoffId  = s.dropoffId;
        const hours   = parseFloat(isNaN(s.hours) ? 0 : s.hours);
        const minutes = parseFloat(isNaN(s.minutes) ? 0 : s.minutes);
        const value   = ((60 * (isNaN(hours)?0:hours)) + (isNaN(minutes)?0:minutes)); 
        const loginInfo = store.get('InteServLoginInfo');
        if(SAPService.isSAPFacility() && SAPService.useTTS())
        {    
            const sig=!!s.dropoffNumber;
            if(!sig)
            {
              if(!loginInfo.isClockedIn)
              {
                notifications.action('error').post("Currently Not Clocked In");
                return false;
              }
              if(loginInfo.isClockedOut)
              {
                notifications.action('error').post("ClockedOut for the day");
                return false;
              }
              // if(hours>23)
              // {
              //   notifications.action('error').post("Hours cannot be more than 23");
              //   return false;
              // }
              // if(minutes>60)
              // {
              //   notifications.action('error').post("Minutes cannot be more than 60");
              //   return false;
              // }
              const availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
              if(value<=0)
              {
                  notifications.action('error').post("Drop-Off Time should be greater than 0");
                  return false;
              }
              if(parseFloat(value).toFixed(1)<=0)
              {
                notifications.action('error').post("After rounding to 1 decimal, time will become 0. Drop-Off Time should be greater than 0");
                return false;
              } 
              if(value>availableTime)
              {
                  notifications.action('error').post("Drop-Off Time should not be more than Available Production Time");
                  return false;
              }
              if(loginInfo.isBreakOut)
              {
                notifications.action('error').post("Currently on Unpaid break");
                return false;
              }   
          }
        }
        this.state.isSaveInProgress=true;
        this.commit();
        const parameters = {
          dropoffId             : dropoffId,
          facility: {                                       
            id   : LoginService.loginInfo.facilityId,
            name : LoginService.loginInfo.facilityName
          },
          customer              : s.customer,
          customerLocation      : s.customerLocation,
          customerPO            : s.customerPO,
          application           : s.application,
          equipmentCategory     : s.equipmentCategory,
          equipmentDetails      : s.equipmentDetails,
          destinationType       : s.destinationIdleAssets
                                    ? AppConstants.eDropoffDestination.IdleAssets
                                    : AppConstants.eDropoffDestination.AssignToJob,
          job                   : s.job,
          serviceInterval       : s.serviceInterval,
          service               : s.service,
          createdDate           : s.createdDate,
          requestedDate         : s.requestedDate,
          rows                  : s.rows.map(row => row.state),
          deliveredByName       : s.deliveredByName,
          deliveredBySignature  : s.deliveredBySignature,
          value                 : value,
          TransactionDate       : DateTimeFormatter.formatDate(DateTimeFormatter.today())
        };
    
        co(this, function*() {
          try {
            const prodTime=yield ajax.post('dropoff/save', parameters); // in: UpdateDropoffDto, out: void
            if (this.adding) {
              notifications.action('success').post('Saved new Drop-Off Form');
            }
            else {
              notifications.action('success').post('Saved changes for Drop-Off Form');
            }
            //messages.channel('productiveTime').action('update').post(prodTime);
            // use in DropoffController to re-select edited form
            // if it's a new form, it will be the 1st entry in the list
            const postData = this.adding ? null : dropoffId;
            messages.channel('edit-dropoff').action('saved').post(postData);
            this.state.isSaveInProgress=false;
            this.commit();
            cb();
          }
          catch (err) {
            this.state.isSaveInProgress=false;
            this.commit();
            notifications.action('error').post({msg: err.message, title: 'Error Saving Drop-Off Form'});
          }
        });
      }
    
      download = () => {
        try {
          const s = this.state;
          const parameters = {
            dropoffId : s.dropoffId
          };
          co(this, function*() {
            this.state.isDownloadInProgress=true;
            this.commit();
            const pdfData   = yield ajax.post('dropoff/downloadReport', parameters); 
            this.state.isDownloadInProgress=false;
            this.commit();
            let file = Utils.convertBase64toBlob(pdfData, 'application/pdf');
            let fileURL = window.URL.createObjectURL(file);
            window.open(fileURL); 
            return true;
          });
    
        }
        catch (err) {
          this.state.isDownloadInProgress=false;
          this.commit();
          notifications.action('error').post(err);
        }
     }
    
}

export default EditDropoffController;