import React from 'react';
import store from 'store';
import $ from 'jquery';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
import Utils from '../utility/Utils';
import Form from '../../components/Form';
import CompleteAWODialog from '../dialogs/CompleteAWODialog';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
//import OverrideDialog from '../dialogs/OverrideDialog';
//import OverrideController from '../controllers/OverrideController';
//import CompleteJobController from '../controllers/CompleteJobController';
//import CompleteJobDialog from '../dialogs/CompleteJobDialog';

class AssemblyWorkOrderController extends BaseController {
  constructor() {
    super('assembly-work-order', {
      customer: '',
      assemblyWorkOrderSearch:'',
      selectedAWO: '',
      searchActive: true,
      searchCompleted: false,
      currentPage: 1,
      customers: [],
      searchResults: [],
      revenueGeneratingCustomers: [],
      allCustomers: [],
       awoStatus:'',
       completiondate : null,
       selectedrowId : [],
       selectionType : 'some'
    });

    const self = this;
    this.flash = emitter();

    // window.setTimeout(() => {
    //   this.searchAWOs();
    // }, 0);

    messages.channel('change-facility').action('changed').subscribe(() => {
      this.state.customer = '';
      this.state.searchResults = [];
      this.state.customers = [];
      this.state.selectedAWO = '';
      this.clearCache();
    });

    messages.channel('complete-awo').action('saved').subscribe(() => {
      this.searchAWOs();
    });

    messages.channel('edit-awo').action('saved').subscribe(() => {
      this.searchAWOs();
    });
    messages.channel('awo-status').action('saved').subscribe(data => {
      const jobs = self.state.searchResults.filter(result => result.id === data.awoId);
      if ((jobs === null) || !jobs.length) {
        return;
      }

      if (data.message && data.message.action) {
        notification.action(data.message.action).post(data.message);
      }

      // const job = jobs[0];
      // co(self, function* () {
      //   const updatedJob = yield ajax.get('lookup/getJob', { jobId: job.id }); // JobLookupDto
      //   $.extend(job, updatedJob);
      //   this.commit();
      //   this.flash.emit(job.id);
      // });
    });
    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        self.state.customer = '';
        self.clearResults();
        self.clearCache();
      }
    });
  
   }

  customerChanged = () => {
    this.clearResults();
   // document.getElementById('searchText').focus();
   // this.state.currentPage = 1;
    this.commit();
    this.searchAWOs();
  }

  searchActiveChanged = () => {
    this.state.searchCompleted = false;   
    this.state.currentPage = 1;
    this.commit();
   this.searchAWOs();
  }

  
  searchCompletedChanged = () => {
    this.state.searchActive = false;    
    this.state.currentPage = 1;
    this.commit();
    this.searchAWOs();
  }


  clearResults = () => {
    this.state.searchResults = [];
    this.state.selectedAWO = '';
  }

  clearCache = () => {
    this.state.revenueGeneratingCustomers = [];
    this.state.allCustomers = [];
  }

 

  
  searchAWOs = () => {
    this.clearResults();
    const facilityId = (LoginService.loginInfo && LoginService.loginInfo.facilityId);
    const customerId = (this.state.customer && this.state.customer.id);
    const searchText = this.state.searchText;
    if (!facilityId) {
      return;
    }
    const parameters = {
      facilityId: facilityId,
      customerId: customerId, 
      searchActive: this.state.searchActive,
      awoNumber: this.state.awoNumber,
      searchTerm: searchText,
     };

    this.state.searchResults = [];
    this.state.isLoading = true;
    this.commit();
    ajax.get('lookup/searchExistingAWOsForFacility', parameters).then(results => {
      this.state.isLoading = false;
      results.forEach(x=>x.selected = false);
      if (results.length > 0) {       
        results[0].selected = true;
        this.loadAWODetails(results[0]);        
      }
      this.state.searchResults = results;
      this.commit();
    }
    ).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  customerSearch = () => {
    const self = this;
    return function* () {
      if (!LoginService.loginInfo) {
        return;
      }

      let revenueGeneratingCustomersOnly = 0; // self.state.revenueGeneratingCustomersOnly ? 1 : 0;
      const facilityId = LoginService.loginInfo.facilityId;
      const searchActive = self.state.searchActive ? 1 : 0;
      const parameters = {
        facilityId: facilityId,
        searchTerm: '',
        revenueGeneratingCustomersOnly: 0,
        searchActive: searchActive
      };

      const results = yield ajax.get('lookup/searchCustomersWithJobsAtFacility', parameters); // IList<LookupDto>
      self.state.allCustomers = results;
      return results;
    };
  }
  
  loadCustomers = () => {
    if (!LoginService.loginInfo) {
      return;
    }

    let revenueGeneratingCustomersOnly = 0; // this.state.revenueGeneratingCustomersOnly ? 1 : 0;

    //check for cached values
    switch (revenueGeneratingCustomersOnly) {
      case 0:
        if (this.state.allCustomers.length) {
          this.state.customers = this.state.allCustomers;
          this.commit();
          return;
        }
        break;
      case 1:
        if (this.state.revenueGeneratingCustomers.length) {
          this.state.customers = this.state.revenueGeneratingCustomers;
          this.commit();
          return;
        }
        break;

      default:
        break;
    }

    const facilityId = LoginService.loginInfo.facilityId;
    const searchActive = this.state.searchActive ? 1 : 0;
    const parameters = {
      facilityId: facilityId,
      searchTerm: '',
     searchActive: searchActive
    };
      ajax.get('lookup/searchCustomersWithJobsAtFacility', parameters).then(results => {
        this.state.customers = results;
      switch (revenueGeneratingCustomersOnly) {
        case 0:
          this.state.allCustomers = results;
          break;

        case 1:
          this.state.revenueGeneratingCustomers = results;
          break;

        default:
          break;
      }
      this.commit();
      return results;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  isSelected = (job) => {
    if (!this.state.selectedAWO) {
      return false;
    }
    return (job.id === this.state.selectedAWO.id);
  }

  loadAWODetails = (awo) => {
    this.state.selectedAWO = awo;
    const awoId = (awo && awo.id);
    if (!awoId) {
      this.commit();
      return;
    } 
      this.commit();
  }

  canCreateNewAssemblyWorkOrder = () => {
    const facility = store.get('currentFacility');
    if (!facility) {
      return false;
    }
    return (!facility.useSAP || (facility.plantCode !== ''));
  }
  

  complete = (assemblyWorkOrder) => {
  return true;
  }

  delete = (assemblyWorkOrder) => {
  //  if (!job.canDelete) {
  //    return;
  //  }

  //  co(this, function* () {
  //    const result = yield Dialog.showDialogWaitForResult(
  //      <Dialog.OKCancelDialog className='confirm-invoice-request' width={500} height={300} header='Confirm Delete Request'>
  //        <h6>Please confirm you wish to delete job:</h6>
  //        <h5>{job.jobNumber}</h5>
  //      </Dialog.OKCancelDialog>
  //    );
  //    if (result.OK) {
  //      const parameters = {
  //        jobId: job.id,
  //        jobNumber: job.jobNumber
  //      };
  //      yield ajax.post('jobprogress/delete', parameters);
  //      this.searchJobs();
  //    }
  //  });
  return true;
  }

  selectNone = () => {    
    this.state.searchResults.forEach(x=>x.selected =false);
    this.state.selectionType = 'none';
    this.commit();
  }
  
  selectAll = () => {
    this.state.searchResults.forEach(x=>x.selected =true);
    this.state.selectionType = 'all';
    this.commit(); 
  }
  deleteAWO = () => {

    
    const {awoNumber , id :awoId} = this.state.selectedAWO;
    const spanStyle = { whiteSpace: 'pre-wrap'  };
    const divStyle = { marginTop: '10px' };
    const message1 = <span style={spanStyle}>Once an AWO is deleted, it is final. You cannot access the data again. </span>;
    const message2 = <span style={spanStyle}>Please confirm you wish to delete AWO: {awoNumber} </span>;

    co(this, function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.SaveCancel width={550} height={300} header='Confirm AWO Deletion'>
          <div>{message1}</div>
          <div style={divStyle}>{message2}</div>
        </Dialog.SaveCancel>
      );
     
      if (result.Save) {
        // 
        ajax.get('awoprogess/deleteawo', { awoId: awoId }).then(result => {
          if (result) {
            notification.action('success').post('AWO Deleted successfully');
            this.searchAWOs();
          }
          else{
            notification.action('error').post(`Unable to delete AWO [${awoNumber}]`);
          }
        });

      }
    });
  }
  completeAWO = () => {    
    const {awoNumber , id :awoId} = this.state.selectedAWO;
    Dialog.showDialog(<CompleteAWODialog controller={this} awonumber={awoNumber}></CompleteAWODialog>);
    
  }
 save = ()=>{ 
  const {awoNumber , id :awoId} = this.state.selectedAWO;
  const completiondate = this.state.completiondate
  if(completiondate)
  {
  ajax.get('awoprogess/completeawo', { awoId: awoId , returndate : DateTimeFormatter.formatDate(this.state.completiondate) }).then(result => {
    if (result) {
      notification.action('success').post(`Completed AWO : ${awoNumber} successfully.`);
      this.state.completiondate = null;
      this.commit();
      this.searchAWOs();
    }
    else{
      notification.action('error').post(`Unable to Completed AWO [${awoNumber}]`);
    }
  });
  return true;
}
else{
  notification.action('error').post(`Please select completion date for AWO [${awoNumber}]`);
}
 }
 clearSearchText = ()=>{
  this.state.awoNumber = '';
  this.commit();
 }
 setSelected=(awo)=>{
  this.state.searchResults.forEach(x=>x.selected = false);
   awo.forEach(x => { 
    var obj = this.state.searchResults.find(o=> o.id === x.id);
   obj.selected = true;
} )

this.state.selectionType = 'some';
  this.commit();  
 }
 assemblyWorkOrderSearchChanged=()=>{
  
 }
 exportSelected = ()=>{
  if(this.state.searchResults.filter(x=>x.selected ===true).length ===0)
  {
    notification.action('error').post(`Please select AWO from the list`);
  }
  else{
        var awoIds = this.state.searchResults.filter(x=>x.selected ===true).map(y=> y.id);
        if(awoIds.length>50)
        {
          notification.action('error').post(`Only 50 or less than 50 AWO's can be exported at once`);
          return;
        }
        setTimeout(() => {
          ajax.post('lookup/awo/exportdetails', awoIds)
          .then(x=> {
            if(x){
              notification.action('success').post(`Please wait while the file is downloading`);
              const url = `${process.env.REACT_APP_API_SSL}://${process.env.REACT_APP_API_HOST_NAME}${process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : ''}/${process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : ''}${process.env.REACT_APP_API}/lookup/awo/downloaddetails/?folderId=${x}`;
              const tempLink = document.createElement('a');
                    tempLink.href = url;
                    tempLink.click();
                    tempLink.remove();
            }}
            )
          .catch(x=> console.error(x))
        }, 0);
       
  }
 }
 isAWOEditable =(awoId, redirectToEditPage)=>{
  redirectToEditPage(awoId);
  return;
  }
  redirectToAssembly = (awoId,cb)=>{
    const data = {awoId: awoId};   
      messages.channel('apply-assembly-levels').action('assign-data').post(data);
      cb();
  }
}

export default AssemblyWorkOrderController;
