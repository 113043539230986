import React from 'react';
import Dialog from '../../components/Dialog';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import co from '../../lib/Co';
import SerialReadingRow  from '../pages/SerialReadingRow';
import Controller from '../../mixins/Controller';
import Table from '../../components/Table';

class ReadingsConfirmationDialog extends React.Component{

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
}

componentDidMount = () => {
    this.initializeMixin();
}  

handleClose = (result) =>  (result.Cancel || result.OK)? true : this.props.controller.save();

    // var unapproveItems = this.props.controller.state.readings.filter(reading => !reading.approve);
    // if(unapproveItems.length){
    //   co(this, function*() {
    //     var result = yield Dialog.showDialogWaitForResult(
    //       <Dialog.OKCancelDialog  className='save-confirm-dialog' header='Confirm Save'>
    //         {'One or more readings have not been approved, are you certain you would like to continue? Only completed or approved readings will be submitted'}
    //       </Dialog.OKCancelDialog>
    //     );
  
    //     if (result.OK) {
    //       this.props.controller.save();
    //     }
    //   });
    // } 

  getTableHeader = (binder) => {
    const approveAll = <Form.CheckBox className={'approve-check-header'} bind={binder.bind('approveAll')} />;
    return (
      <Table.Row className='reading-serial-header'>
        <Table.Head>Reading Id</Table.Head>
        <Table.Head>Min Dimension</Table.Head>
        <Table.Head>New Dimension</Table.Head>
        <Table.Head>Previous Reading</Table.Head>
        <Table.Head>Reading Value</Table.Head>
        <Table.Head>Scope Serial</Table.Head>
        <Table.Head className='approve-all-check'>{approveAll}</Table.Head>
      </Table.Row>
    );
  }


  getSerialHeader = (calHeader) => {
    const groupDisplay = calHeader.groupName ? calHeader.groupName : calHeader.groupNumber;
    return (
      <Table.Row className='service-history-performed-service-level-header'>
        <Table.Data colSpan={6}>
          <span style={{marginLeft: '10px', fontStyle: 'normal', fontWeight: 'bold', fontSize: '12px', color: 'black'}} className='field-label'>
             {calHeader.serial + ' - ' }</span>
          <span style={{fontStyle: 'normal', fontSize: '12px', color: 'gray'}} className='field-label'>
             {calHeader.description}</span>
             </Table.Data>
      </Table.Row>
    );
  }

  getSerialReadingHeader = () => {
    return (
      <Table.Row className='service-history-performed-test-header'>
        <Table.Data><span style={{marginLeft: '30px'}}>Reading Id</span></Table.Data>
        <Table.Data>Minimum Dimension</Table.Data>
        <Table.Data>New Dimension</Table.Data>
        <Table.Data>Reading Value</Table.Data>
      </Table.Row>
    );
  }

  addSerialReading = (rows, calHeader, calDetail) => {
    if (calDetail !== null) {
      // rows.push(this.getSerialReadingHeader());
      var serialReadingRow = calDetail.map(row => {
        if (row.state.serial == calHeader.serial && row.state.jobSOWId == calHeader.jobSOWId) {
          return <SerialReadingRow key={row.key} controller={row} />;
        }
      });
      rows.push(serialReadingRow);
    }
  }

  addSerialReadingRows = (rows, calHeader, calDetail) => {
      rows.push(this.getSerialHeader(calHeader));
      // if (!calHeader.collapsed) {
        this.addSerialReading(rows, calHeader, calDetail);
      // }
  }

  addSerialRows = (rows, dataHeader, dataRow, binder) => {
    rows.push(this.getTableHeader(binder));
    dataHeader.forEach(job => {
      this.addSerialReadingRows(rows, job, dataRow);
    });
  }

  render = () => {
    const binder        = new Binder(this);
    const props        = this.props;
    const controller   = this.props.controller;
    const dataRow      = props.controller.state.readings || [];
    const dataHeader   = props.controller.state.readingsHeader || [];
    let   results      = [];

    this.addSerialRows(results, dataHeader, dataRow, binder);

    return (
      <Dialog {...this.props} header='Confirm UT Readings' width={860} height={550} id='readings-confirmation-dialog' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <div className='readings-confirmation col-sm-12'>
              <div id='readings-confirmation-table' className='search-results'>
                <Table>
                  <Table.Body>
                    {results}
                    {/* {loadingMessage}
                    {noResultsMessage} */}
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div id='reading-legends' className='col-12'>
                <div className='row mb-1'>
                   <div className='col-3 d-flex'><div className='legend-min-110' /> &nbsp; <span>110% of Min</span></div>
                   <div className='col-3 d-flex'><div className='legend-below-prev' /> &nbsp; <span>Below Prev .050</span></div>
                   <div className='col-3 d-flex'><div className='legend-above-prev' /> &nbsp; <span>Above Prev .030</span></div>
                   <div className='col-3 d-flex'><div className='legend-below-min' /> &nbsp; <span>Below Min</span></div>
                </div>
                <div className='row mb-1'>
                  <div className='col-3 d-flex'><div className='legend-missing-reading' /> &nbsp; <span>Missing Reading</span></div>
                </div>
              </div>
          </div>
          <div id='childPopup' />
        </Dialog.Body>
        <Dialog.Buttons>
          <div className="ship-buttons">
            <Dialog.SaveCancelButtons saving = {controller.saving} />
          </div>
        </Dialog.Buttons>
      </Dialog>
    );
  }
}

export default ReadingsConfirmationDialog;