import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectManDescController extends BaseController {
  constructor(laborCode, selectedItems) {
    super('select-man-desc', {
      laborCode     : laborCode,
      selectedItems : Utils.clone(selectedItems),
      sourceItems   : [],
      searchText    : ''
    });
  }

  search = () => {
      // TODO: not sure why this is not binding when clicking on the search button (SelectManDescDialog.jsx) without tabbing out of the input control
      //       it may be related to being on dialog rather than a page (?)
      // var searchTerm         = this.state.searchText;
      const searchTerm         = this.state.searchText;
      const parameters = {
        searchTerm : searchTerm,
        customerId : '',
        maxResults : 500
      };

      ajax.get('lookup/searchManufacturerDescriptions2', { searchTerm: searchTerm }).then(results=>{
        results = results.map(r => {
          return {
            manDescLaborCodeId : r.manDescLaborCodeId,
            manDescId          : r.manDescId,
            mfgItemNo          : r.mfgItemNo && r.mfgItemNo.name,
            description        : r.description
          };
        });
        this.state.sourceItems = results;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });    // IList<ManufacturerDescriptionDto>
  }

  clearSearch = () => {
    this.state.searchText='';
    this.commit();
    document.getElementById('searchText').focus();
  }

  selectNoSourceItems = () => {
    this.state.sourceItems.forEach(item => {
      item.selected = false;
    });
    this.commit();
  }

  selectAllSourceItems = () => {
    this.state.sourceItems.forEach(item => {
      item.selected = true;
    });
    this.commit();
  }

  selectNoSelectedItems = () => {
    this.state.selectedItems.forEach(item => {
      item.selected = false;
    });
    this.commit();
  }

  selectAllSelectedItems = () => {
    this.state.selectedItems.forEach(item => {
      item.selected = true;
    });
    this.commit();
  }

  toggleSelection = (item) => {
    item.selected = !item.selected;
    this.commit();
  }

  moveToSelected = () => {
    const state = this.state;
    state.sourceItems.forEach(item => {
      if (item.selected) {
        item.selected = false;
        let found = false;
        for (let i in state.selectedItems) {
          if (state.selectedItems[i].manDescId === item.manDescId) {
            found = true;
            break;
          }
        }
        if (!found) {
          state.selectedItems.push({
            manDescLaborCodeId : Utils.emptyGuid,
            manDescId          : item.manDescId,
            mfgItemNo          : item.mfgItemNo,
            description        : item.description
          });
        }
      }
    });
    // sort selected list
    this.state.selectedItems.sort(function(a, b) {
      return (a.mfgItemNo.localeCompare(b.mfgItemNo));
    });
    this.commit();
  }

  removeFromSelected = () => {
    let i = this.state.selectedItems.length;
    while (i--) {
      const item = this.state.selectedItems[i];
      if (item.selected) {
        this.state.selectedItems.splice(i, 1);
      }
    }
    this.commit();
  }

  selectAndTransfer = (isSource, item) => {
    item.selected = true;
    if (isSource) {
      this.moveToSelected();
    }
    else {
      this.removeFromSelected();
    }
  }

  save = () => {
      const laborCode  = this.state.laborCode;
      const parameters = {
        laborCodeId              : laborCode.laborCodeId,
        manufacturerDescriptions : this.state.selectedItems
      };

      return ajax.post('laborCode/saveManDesc', parameters).then(()=>{
        messages.channel('edit-labor-code').action('saved').post(laborCode);
        notification.action('success').post('Saved changes to manufacturer description selection');
        return true;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
    }); // in: LaborCodeDto, out: void
  }
}

export default SelectManDescController;
