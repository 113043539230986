import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Validator from '../../lib/Validator';
import SAPService from '../services/SAPService';
import store from 'store';
import messages from '../../services/Messages';
import LoginService from '../services/LoginService';
import AssetsAlreadyInJobDialogBody from '../dialogs/AssetsAlreadyInJobDialogBody';
import AssetsAlreadyInJobController from '../controllers/AssetsAlreadyInJobController';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
import Table from '../../components/Table';

class AssignAssetsToAssemblyWorkOrderController extends BaseController {
  constructor(customerId, facilityId, assets) {
    super('assign-assets-to-assembly-work-order', {
      customerId: customerId,
      facilityId: facilityId,
      assets: assets,
      receivingLocation: '',
      existingAWO: '',
      newAWO: '',
      selectedAWO: '',
      receivingLocation: '',
      functionalLocation: '',
      errors: {},
      container: '',
      removeFromContainer: true,
      saving: false,
      containerDescription: '',
      functionalLocation: '',
      diableRemoveFromContainer: false
    });
    this.saving = false;
    this.receivedAsstes = assets;
    this.selectedAssets = [];
    this._validator = new Validator();
    const assignedToContainer = assets.reduce((acc, curr) => {
      return acc || (curr.container?.id !== Utils.emptyGuid);
    }, false);
    this.state.removeFromContainer = assignedToContainer;
    this._validator.add('receivingLocation', 'Receiving Location', [{ required: true }]);
  }

  containerDescriptionChanged = () => {
    this.state.diableRemoveFromContainer = false;
    this.state.removeFromContainer = false;
    if (this.state.containerDescription || this.state.functionalLocation) {
      this.state.diableRemoveFromContainer = true;
      this.state.removeFromContainer = false;
    }
    this.commit();
  }

  functionalLocationChanged = () => {
    this.state.removeFromContainer = false;
    this.state.diableRemoveFromContainer = false;
    if (this.state.containerDescription || this.state.functionalLocation) {
      this.state.diableRemoveFromContainer = true;
    }
    this.commit();
  }

  existingAWOChanged = () => {
    this.state.newAWO = '';
    this.state.selectedAWO = this.state.existingAWO;
    this.commit();
  }

  newAWOChanged = () => {
    this.state.existingAWO = '';
    this.state.selectedAWO = this.state.newAWO;
    this.commit();
  }


  existingAWOSearch = () => {
    const customerId = this.state.customerId;
    const facilityId = this.state.facilityId;

    return function* (searchTerm) {
      const parameters = {
        facilityId: facilityId,
        customerId: customerId,
        searchTerm: searchTerm
      };
      const results = yield ajax.get('lookup/awo/searchExistingAWOs', parameters); // IList<JobSearchResultDto>
      return results;
    };
  }

  newAWOSearch = () => {
    const customerId = this.state.customerId;
    const facilityId = this.state.facilityId;

    return function* (searchTerm) {
      const parameters = {
        customerId: customerId,
        facilityId: facilityId,
        searchTerm: searchTerm
      };
      const results = yield ajax.get('lookup/awo/searchNewAWOs', parameters);
      return results;
    };
  }

  validateAssetWithAWO = () => {
    return true;
  }

  functionalLocationSearch = () => {
    const jobId = this.state.existingJob && this.state.existingJob.id;
    return function* (searchTerm) {
      const parameters = { jobId: jobId };
      // var results = yield ajax.get('lookup/searchJobGroupNumbers', parameters); // IList<LookupDto>
      const results = yield ajax.get('lookup/searchJobGroupNamesAndNumbers', parameters);
      return results;
    };
  }

  showAssignCompleteDialog = (result) => {
    //co(this, function* () {
    //  const jobNumber = result.jobNumber;
    //  const groupNumber = result.groupNumber;
    //  const groupName = result.groupName;
    //  const assetCount = result.assetCount;
    //  const receivingTicketNumber = result.receivingTicketNumber;
    //  const jobDescription = result.jobDescription;

    //  const response = yield Dialog.showDialogWaitForResult(
    //    <Dialog.OKDialog width={600} height={300} header='Assets Assigned to Job' popup='childPopup'>
    //      <Table borderless={true} id='assign-assets-complete-dialog'>
    //        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{width: '200px'}}>Job #</Table.Data><Table.Data className='value text-left'>{jobNumber}</Table.Data></Table.Row>
    //        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{width: '200px'}}>Group #</Table.Data><Table.Data className='value text-left'>{groupNumber}</Table.Data></Table.Row>
    //        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{width: '200px'}}>Group Name</Table.Data><Table.Data className='value text-left'>{groupName}</Table.Data></Table.Row>
    //        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{width: '200px'}}># of Assets Assigned</Table.Data><Table.Data className='value text-left'>{assetCount}</Table.Data></Table.Row>
    //        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{width: '200px'}}>Receiving Ticket #</Table.Data><Table.Data className='value text-left'>{receivingTicketNumber}</Table.Data></Table.Row>
    //        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{width: '200px'}}>Job Description</Table.Data><Table.Data className='value text-left job-description'><div>{jobDescription}</div></Table.Data></Table.Row>
    //      </Table>
    //    </Dialog.OKDialog>, 'childPopup'
    //  );
    //  if (response.OK) {
    //    
    //    const closeParentDialog = Dialog.handleResult({popup: 'popup', Cancel:true});  // (document.getElementsByClassName('w2ui-msg-buttons'));
    //    closeParentDialog(new Event('click'));
    //  }
    //});
  }
  canAssignToAWO = () => {
    let success = true;
    if (!this.state.newAWO && !this.state.existingAWO) {
      notification.action('warning').post('Assembly Work Order required');
      success = false;
    }

    if (!this.state.receivingLocation) {
      notification.action('warning').post('You must select a Receiving Location');
      success = false;
    }
    // if (this.state.customerShipLocation.error) {
    //     notification.action('warning').post('Selected Customer Ship Location has the following error: '
    //         + this.state.customerShipLocation.error);
    //     success = false;
    // }

    if (this.state.assets.length === 0) {
      notification.action('warning').post('You must select at least one asset');
      success = false;
    }

    if (!success) {
      this.commit();
    }

    return success;
  }



  save = () => {
    if (!this.canAssignToAWO()) {
      return;
    }
    this.state.isSaveInProgress = true;
    this.commit();
    let msg = '';

    var state = this.state;
    var loginInfo = store.get('InteServLoginInfo');
    /* if (SAPService.isSAPFacility() && SAPService.useTTS()) {
       if (!loginInfo.isClockedIn) {
         notification.action('error').post("Currently Not Clocked In");
         return false;
       }
       if (loginInfo.isClockedOut) {
         notification.action('error').post("ClockedOut for the day");
         return false;
       }
 
       if (loginInfo.isBreakOut) {
         notification.action('error').post("Currently on Unpaid break");
         return false;
       }
     } */

    const parameters = {
      customerId: state.customerId,
      facilityId: LoginService.loginInfo.facilityId,
      awoId: state.selectedAWO ? state.selectedAWO.id : '',
      receivingLocationId: state.receivingLocation ? state.receivingLocation.id : '',
      //awoNotes: state.jobNotes,
      removeFromContainer: state.removeFromContainer,
      TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
      ContainerId: this.state.containerDescription ? this.state.containerDescription.id : '',
      functionalLocation: this.state.functionalLocation?.name,
      assets: state.assets.map(asset => {
        return {
          assetId: asset.assetId,
          customerId: asset.customer.id,
          currentLocationId: asset.currentLocation?.id,
          primarySerialNumber: asset.primarySerialNumber,
          eAssetStatus: asset.eAssetStatus,
          equipmentNumber: asset.equipmentNumber,
          plantCode: asset.plantCode
        };
      })
    };
    ajax.post('receiving/assignAssetsToAWOAndContainer', parameters).then(results => {
      this.channel.action('saved').post();
      let assetText = ' asset' + ((results.assetCount !== 1) ? 's' : '');
      msg = 'Assigned ' + results.assetCount + assetText + ' to AWO ' + results.awoNumber;
      notification.action('info').post({ msg: msg, title: 'Assign Assets to AWO Complete' });
      //messages.channel('productiveTime').action('update').post(results.prodTime); 
      this.showAssignToAWOCompleteDialog(results);
      // $('.ship-buttons .btn-default').trigger('click');
      //$('.selected.selected-container').trigger('click');
      this.state.isSaveInProgress = false;
      this.commit();
      return true;
    }).catch(err => {
      this.state.isSaveInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
    return true;
  }

  showAssignToAWOCompleteDialog = (result) => {
    co(this, function* () {
      const awoNumber = result.awoNumber;
      const groupNumber = result.groupNumber;
      const groupName = result.groupName;
      const assetCount = result.assetCount;
      const receivingTicketNumber = result.receivingTicketNumber;
      const jobDescription = result.jobDescription;
      const response = yield Dialog.showDialogWaitForResult(
        <Dialog.OKDialog width={600} height={300} header='Assets Assigned to AWO' popup='childPopup'>
          <Table borderless={true} id='assign-assets-to-awo-complete-dialog'>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>AWO #</Table.Data><Table.Data className='value text-left'>{awoNumber}</Table.Data></Table.Row>
            { //<Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group #</Table.Data><Table.Data className='value text-left'>{groupNumber}</Table.Data></Table.Row>
              //  <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group Name</Table.Data><Table.Data className='value text-left'>{groupName}</Table.Data></Table.Row>
            }
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}># of Assets Assigned</Table.Data><Table.Data className='value text-left'>{assetCount}</Table.Data></Table.Row>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Receiving Ticket #</Table.Data><Table.Data className='value text-left'>{receivingTicketNumber}</Table.Data></Table.Row>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>AWO Description</Table.Data><Table.Data className='value job-description text-left'><div>{jobDescription}</div></Table.Data></Table.Row>
          </Table>
        </Dialog.OKDialog>, 'childPopup'
      );
      if (response.OK) {

        const closeParentDialog = Dialog.handleResult({ popup: 'popup', Cancel: true });  // (document.getElementsByClassName('w2ui-msg-buttons'));
        closeParentDialog(new Event('click'));
      }
    });
  }


  assignSelectedAssets = (assets) => {
    this.selectedAssets = assets;
  }



  receivingLocationSearch = () => {
    const self = this;
    return (searchTerm) => ajax.get('lookup/searchCustomerLocationsForFacility', {
      facilityId: self.state.facilityId,
      customerId: self.state.customerId,
      searchTerm: searchTerm
    }).then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

}

export default AssignAssetsToAssemblyWorkOrderController;
