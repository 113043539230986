import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import { useHistory } from 'react-router-dom';
import Authentication from '../services/authentication';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Shortcut from '../../components/Shortcut';
import AddReleaseNotesController from '../controllers/AddReleaseNotesController';

const AddNote = (props) => {

  const binder = props.binder;
  const errors = props.controller.state.errors;

  return (
    <div className='row'>
      <div className='col-md-6 mt-2'>
        <Form.Date label='Released Date' className='required'
          bind={binder.bind('requestedDate')} error={errors.requestedDate} />
      </div>
      <div className='col-md-12 mt-2'>
        <Form.TextArea className='required' label='Release Notes' id='noteText' placeholder='Type new message here' rows={10} bind={binder.bind('noteText')} error={errors.noteText} />
      </div>
    </div>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleAddNote = () => {
    props.controller.addNote(() => {
      history.goBack();
    }, props.id);
  }

  const handleClose = () => {
    history.goBack();
  }

  const canAddNotes = props.canAddNotes;
  const saveButtonLabel = props.id ? 'Update' : 'Save';
  const saveInProgress = props.controller.state.saveInProgress;

  return (
    <div className='row'>
      <div className='col-md-12 mt-2'>
        <div className='edit-buttons float-right'>
          {canAddNotes &&
            <Shortcut shortcutKey='ctrl+s' preventDefault action={handleAddNote}>
              <Button id='btn-save' icon='fa-save' variant='primary' size='sm' className='m-1 action-button'
                onClick={handleAddNote} disabled={saveInProgress} >{saveButtonLabel}</Button>
            </Shortcut>
          }
          <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
        </div>
      </div>
    </div>
  );
}

const AddReleasePageHeader = (props) => <PageHeader title={props.pageTitle} showBackNavigation={true} />

class AddReleaseNotesPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const params = this.props.match.params;
    const id = params.rnId;
    this.props.controller.load(id);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandJobNotes();
  }

  static defaultProps = {
    controller: new AddReleaseNotesController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const rnId = this.props.match.params.rnId;
    const title = rnId ? "Edit Release Note" : "Add Release Note";
    const canAddNotes = controller.canAddNotes();

    return (
      <Page {...this.props} pageTitle='InteServ · Release Notes' id='notes-page'>
        <AddReleasePageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <AddNote controller={controller} binder={binder} />
              <Buttons controller={controller} id={rnId} canAddNotes={canAddNotes} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default AddReleaseNotesPage;