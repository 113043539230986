import React from 'react';
import store from 'store';
import AssetChartController from '../controllers/AssetChartController';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import AssetChartDialog from '../dialogs/AssetChartDialog';
import ChangeAssetOwnershipDialog from '../dialogs/ChangeAssetOwnershipDialog';
import ChangeAssetOwnershipController from '../controllers/ChangeAssetOwnershipController';

class ReceivingController extends BaseController {
  constructor() {
    super('receiving', {
      currentAsset: '',
      searchText: '',
      receivedAssets: [],
      boneyards: [],
      currentCustomerId: '',
      currentCustomerName: '',
      serviceHistory: [],
      qrCanvasVisibility: false,
    });

    this.receivedAssets = '';

    messages.channel('edit-asset').action('saved').subscribe(savedAsset => {
      this.state.currentAsset = savedAsset;
      this.state.searchText = savedAsset.primarySerialNumber; // it may have changed; update state so serial # appears in search box
      this.commit();
    });

    // notification from server
    messages.channel('edit-asset').action('updated').subscribe(asset => {
      var assetId = asset && asset.assetId;
      if (!assetId) {
        return;
      }

      var currentAsset = this.state.currentAsset;
      if (currentAsset && (currentAsset.assetId === assetId)) {
        this.loadAsset(assetId);
      }
    });

    messages.channel('create-new-asset').action('saved').subscribe(newAsset => {
      this.state.currentAsset = newAsset;
      this.state.searchText = newAsset.primarySerialNumber;
      this.state.serviceHistory = [];
      this.commit();
    });

    messages.channel('transfer-to-idle-assets').action('saved').subscribe(() => {
      this.clearResults();
    });

    messages.channel('assign-assets-to-job').action('saved').subscribe(() => {
      this.clearResults();
    });

    messages.channel('assign-assets-to-assembly-work-order').action('saved').subscribe(() => {
      this.clearResults();
    });

    messages.channel('ship-received-assets').action('saved').subscribe(() => {
      this.clearResults();
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.clearResults();
        this.state.currentAsset = '';
        this.state.searchText = '';
      }
    });

    messages.channel('change-facility').action('changed').subscribe(() => {
      this.clearResults();
      this.state.currentAsset = '';
      this.state.searchText = '';
      this.commit();
    });

    messages.channel('receiving').action('add-to-list').subscribe(assetIds => this.receiveAssets(assetIds));
  }

  clearResults = () => {
    this.state.receivedAssets = [];
    this.state.currentAsset = '';
    this.state.currentCustomerId = '';
    this.state.currentCustomerName = '';
    this.state.serviceHistory = [];
    this.clearReceivingStore();
    this.commit();
  }

  clearSearchText = () => {
    this.state.searchText = '';
    this.commit();
  }

  loadFromStore = () => {
    var receivedAssets = this.receivedAssets;//store.get('receiving-received-assets');
    if (receivedAssets) {
      this.state.receivedAssets = receivedAssets;

      var currentCustomerId = store.get('receiving-current-customer-id');
      if (currentCustomerId) {
        this.state.currentCustomerId = currentCustomerId;
      }

      var currentCustomerName = store.get('receiving-current-customer-name');
      if (currentCustomerName) {
        this.state.currentCustomerName = currentCustomerName;
      }

      var boneyards = store.get('receiving-boneyards');
      if (boneyards) {
        this.state.boneyards = boneyards;
      }
      this.commit();
    }
  }

  load = (assetId) => {
    this.loadAsset(assetId);
  }

  loadAsset = (assetId) => {
    this.loadFromStore();
    if (assetId) {
      ajax.get('lookup/getAsset/', { assetId: assetId }).then(result => {
        this.state.serviceHistory = [];
        if (result !== null) {
          this.state.currentAsset = result;
          this.state.searchText = result.primarySerialNumber;
          this.commit();
        }
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // ReceivedAssetDto
    }
  }

  handleRfidSearch = (value) => {
    var serialNumber = { value: '' };
    if (Utils.tryProcessAsRfidTag(value, serialNumber)) {
      this.state.searchText = value; // don't update searchText with decoded rfid tag - let server re-do that
      this.findAsset();
    }
  }

  findAssetWithQRCode = (code) => {
    if (!code) {
      return '';
    }
    var url = code.split('/');
    if (url.length === 6 && Utils.contains(code, 'http') && Utils.contains(code, 'scannedqrasset')) {
      var assetId = url[url.length - 1];
      if (assetId) {
        ajax.get('lookup/getAsset/', { assetId: assetId }).then(result => {
          this.state.serviceHistory = [];
          if (result !== null) {
            this.state.currentAsset = result;
            this.state.searchText = result.primarySerialNumber;
            this.commit();
            if (code) {
              this.findAsset(() => { this.receiveAssetAutomatically() });
            }
          }
          else {
            notification.action('error').post({ msg: 'Asset not found' });
          }
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // ReceivedAssetDto
      }
    }
    else {
      notification.action('warning').post({ msg: 'Invalid QR' });
    }
  }

  receiveAssetAutomatically = () => {
    if (this.canProcess(this.state.currentAsset)) {
      this.receiveAsset(this.state.currentAsset);
    }
  }

  findAsset = (callback) => {
    var searchText = this.state.searchText;
    if (!searchText) {
      return;
    }

    this.state.currentAsset = '';
    this.state.serviceHistory = [];

    this.state.searchText = searchText;
    ajax.get('lookup/searchAsset', { serialNumber: searchText }).then(result => {
      this.state.currentAsset = result;
      this.state.searchText = result.primarySerialNumber;
      notification.action('info').post({ msg: 'Found asset with serial number ' + result.primarySerialNumber + ': ' + result.description.name, title: 'Asset Found' });
      this.commit();
      if (callback) {
        callback();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error', position: 'toast-bottom-left' });
    }); // ReceivedAssetDto
  }

  getChart = () => {
    var asset = this.state.currentAsset;
    if (!asset || !asset.hasChart) {
      return;
    }
    ajax.get('lookup/getAssetChart', { chartHeaderId: asset.chartHeaderId }).then(result => {
      if (result) {
        this.showChart(result);
      }
      else {
        notification.action('warning').post({ msg: 'No chart data available for asset with serial number ' + asset.primarySerialNumber, title: 'Asset Chart Not Found' });
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });  // AssetChartDto
  }

  showChart = (chart) => {
    co(this, function* () {
      yield Dialog.showDialogWaitForResult(<AssetChartDialog controller={new AssetChartController(chart)} header='Pressure Test Chart' />);
    });
  }

  canPerformBoneyardSearch = (facilityId, customerId) => {
    if (!facilityId) {
      notification.action('warning').post({ msg: 'You don\'t have a current facility defined', title: 'Unable to locate Idle Asset locations' });
      return false;
    }

    if (!customerId) {
      return false;
    }
    return true;
  }

  getBoneyards = () => {
    var facilityId = LoginService.loginInfo && LoginService.loginInfo.facilityId;
    var facilityName = LoginService.loginInfo && LoginService.loginInfo.facilityName;
    var customerId = this.state.currentCustomerId;
    var customerName = this.state.currentCustomerName;

    if (!this.canPerformBoneyardSearch(facilityId, customerId)) {
      return;
    }
    ajax.get('lookup/getBoneyards', { customerId: customerId, facilityId: facilityId }).then(results => {
      if (results === null) {
        this.state.boneyards = [];
        store.remove('receiving-boneyards');
        notification.action('warning').post({ msg: 'No Idle Asset locations can be determined for ' + customerName + ' at ' + facilityName, title: 'No idle asset locations', position: 'toast-bottom-left' });
      }
      else {
        this.state.boneyards = results;
        store.set('receiving-boneyards', Utils.clone(results));
      }
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<LookupDto>
  }

  canProcess = (asset) => {
    return (asset && asset.isReceivable);
  }

  canAssignToJob = () => {
    return (this.state.receivedAssets.length > 0);
  }

  canTransferToIdleAssets = () => {
    return ((this.state.receivedAssets.length > 0) && (this.state.boneyards.length > 0) && this.state.currentCustomerName != 'TechnipFMC Drilling & Completions');
  }

  canShipAssets = () => {
    return (this.state.receivedAssets.length > 0);
  }

  isAssetReceived = (newAsset) => {
    var isReceived = false;
    this.state.receivedAssets.forEach(asset => {
      isReceived |= (newAsset.assetId === asset.assetId);
    });
    return isReceived;
  }

  canReceiveAsset = (asset) => {
    var msg;
    if (this.isAssetReceived(asset)) {
      msg = { msg: 'Asset with serial number ' + asset.primarySerialNumber + ' already exists in the Received Assets list', title: 'Unable to receive asset again' };
    }
    else if (this.state.currentCustomerId && (asset.customer.id !== this.state.currentCustomerId)) {
      msg = { msg: 'Cannot receive an asset for a different customer than has already been started', title: 'Unable to change customer while receiving' };
    }
    else if (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped) {
      msg = { msg: 'Cannot receive an asset that has previously been scrapped', title: 'Unable to receive scrapped iron' };
    }
    else if (asset.reasonNotReceivable) {
      msg = { msg: asset.reasonNotReceivable, title: 'Unable to receive asset' };
    }

    if (msg) {
      notification.action('warning').post(msg);
      messages.channel('receiving').action('unable-to-receive-asset').post({ assetId: asset.assetId, msg: msg.msg });
      return false;
    }


    return true;
  }



  canReceiveAssetCheck = (asset) => {
    var msg;
    if (this.isAssetReceived(asset)) {
      msg = { msg: 'Asset with serial number ' + asset.primarySerialNumber + ' already exists in the Received Assets list', title: 'Unable to receive asset again ' };
    }
    else if (this.state.currentCustomerId && (asset.customer.id !== this.state.currentCustomerId)) {
      msg = { msg: 'Cannot receive an asset for a different customer than has already been started', title: 'Unable to change customer while receiving' };
    }
    else if (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped) {
      msg = { msg: 'Cannot receive an asset that has previously been scrapped', title: 'Unable to receive scrapped iron' };
    }
    else if (asset.reasonNotReceivable) {
      msg = { msg: asset.reasonNotReceivable, title: 'Unable to receive asset' };
    }

    if (msg) {
      // notification.action('warning').post(msg);
      // messages.channel('receiving').action('unable-to-receive-asset').post({assetId: asset.assetId, msg: msg.msg});
      return false;
    }
    return true;
  }

  receiveAssets = (assetIds) => {
    if (!assetIds.length) {
      // not an array - see if it's an object
      assetIds = Object.values(assetIds);
      if (!assetIds.length) {
        // neither - nothing to do here
        return;
      }
    }

    ajax.post('lookup/getAssets', { assetIds: assetIds }).then(results => {
      var k = 0;
      results.forEach(asset => {
        if (this.canReceiveAssetCheck(asset)) {
          k++;
        }
      });
      results.forEach(asset => this.receiveAssetFromSerializedSearchPage(asset));
      notification.action('info').post({ msg: 'Added ' + Utils.pluralize('asset', 's', k) + ' to Received Assets list' });
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: IList<Guid>, out: IList<ReceivedAssetDto>
  }

  receiveAsset = (asset) => {
    if (!this.canReceiveAsset(asset)) {
      return;
    }
    this.state.searchText = '';
    
   const currentPlantCode = store.get('currentFacility').plantCode;
   const assetplantcode = asset.plantCode
   
   if ((assetplantcode != currentPlantCode && !!assetplantcode)
     || (asset.functionalLocation!='')
     || (asset?.container?.name!=''))
     {
     const functionalLocationmesage =   `${!!assetplantcode ? 'and is in' : 'This asset is in'} ${asset.functionalLocation}`;
     const Containermesage =   (`${!asset?.functionalLocation==''?' : ' : !!assetplantcode ? 'and is in' : 'This asset is in'} ${asset?.container?.name} ${!asset?.functionalLocation==''?'': `${!!asset.functionalMaintPlant ? `- ${asset.functionalMaintPlant}`:''}`}`).trimEnd();
     const defaultmessage = `You are logged into ${currentPlantCode}.${!!assetplantcode ? ` This asset belongs to ${assetplantcode.toUpperCase()}`:''}`
     const endmessage = `Do you wish to proceed?`;
     
     
     const message = <div><b>{`${defaultmessage} ${asset.functionalLocation!='' ? functionalLocationmesage : ''}${asset?.container?.name !='' ? Containermesage : ''}.`}<br/>{endmessage}</b></div>;     
     co(this, function* () {
       var result = yield Dialog.showDialogWaitForResult(
         <Dialog.YesNoDialog width={700} height={300} header='Warning'>
           {message}
         </Dialog.YesNoDialog>
       );
       if (!result.Yes) {
         return true;
       }
       this.state.receivedAssets.unshift(asset);
       if (this.state.receivedAssets.length === 1) {
         this.state.currentCustomerName = asset.customer.name;
         this.state.currentCustomerId = asset.customer.id;
         store.set('receiving-current-customer-id', Utils.clone(this.state.currentCustomerId));
         store.set('receiving-current-customer-name', Utils.clone(this.state.currentCustomerName));
         this.getBoneyards();
       }
       //this.state.currentAsset = '';
       this.receivedAssets = Utils.clone(this.state.receivedAssets);
       //store.set('receiving-received-assets', Utils.clone(this.state.receivedAssets));
       messages.channel('receiving').action('received-asset').post(asset.assetId);
       // update plantCode OR remove from container
       this.commit();
     });
   }
   else {
      this.state.receivedAssets.unshift(asset);
      if (this.state.receivedAssets.length === 1) {
        this.state.currentCustomerName = asset.customer.name;
        this.state.currentCustomerId = asset.customer.id;
        store.set('receiving-current-customer-id', Utils.clone(this.state.currentCustomerId));
        store.set('receiving-current-customer-name', Utils.clone(this.state.currentCustomerName));
        this.getBoneyards();
      }
      //this.state.currentAsset = '';
      this.receivedAssets = Utils.clone(this.state.receivedAssets);
      //store.set('receiving-received-assets', Utils.clone(this.state.receivedAssets));
      messages.channel('receiving').action('received-asset').post(asset.assetId);
      // update plantCode OR remove from container
      this.commit();
  }
  }
  receiveAssetFromSerializedSearchPage = (asset)=>{
    if (!this.canReceiveAsset(asset)) {
      return;
    }
      this.state.receivedAssets.unshift(asset);
      if (this.state.receivedAssets.length === 1) {
        this.state.currentCustomerName = asset.customer.name;
        this.state.currentCustomerId = asset.customer.id;
        store.set('receiving-current-customer-id', Utils.clone(this.state.currentCustomerId));
        store.set('receiving-current-customer-name', Utils.clone(this.state.currentCustomerName));
        this.getBoneyards();
      }
      //this.state.currentAsset = '';
      this.receivedAssets = Utils.clone(this.state.receivedAssets);
      //store.set('receiving-received-assets', Utils.clone(this.state.receivedAssets));
      messages.channel('receiving').action('received-asset').post(asset.assetId);
      // update plantCode OR remove from container
      this.commit();
  }
  

  clearReceivingStore = () => {
    store.remove('receiving-current-customer-id');
    store.remove('receiving-current-customer-name');
    //store.remove('receiving-received-assets');
    delete this.receivedAssets;
    store.remove('receiving-boneyards');
  }

  clearSearchResults = () => {
    this.clearResults();
    this.state.currentAsset = '';
    this.state.searchText = '';
    this.commit();
  }

  changeCustomer = (asset) => {
    co(this, function* () {
      yield Dialog.showDialogWaitForResult(<ChangeAssetOwnershipDialog controller={new ChangeAssetOwnershipController(asset)} />);
    });
  }

  removeReceivedAsset = (asset) => {
    var receivedAssets = this.state.receivedAssets;
    receivedAssets.splice(receivedAssets.indexOf(asset), 1);
    if (receivedAssets.length === 0) {
      this.state.currentCustomerId = '';
      this.state.currentCustomerName = '';
      this.state.boneyards = [];
      this.clearReceivingStore();
    }
    else {
      this.receivedAssets = Utils.clone(receivedAssets);
      //store.set('receiving-received-assets', Utils.clone(receivedAssets));
    }
    this.commit();
  }

  toggleQrVisiblity = () => {
    this.state.qrCanvasVisibility = !this.state.qrCanvasVisibility;
    this.commit();
  }

  isGreenTagLocation = () => {
    const currentLocation = this.state.currentAsset?.currentLocation?.name;
    const location = currentLocation?.replace(/\s+/g, '');
    return location?.toLowerCase().includes('greentag') ? true : false;
  }
}

export default ReceivingController;
