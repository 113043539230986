import React, { useMemo } from 'react';
import { Router, useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import AppConstants from '../utility/AppConstants';
import Controller from '../../mixins/Controller';
import SAPService from '../services/SAPService';
import Authentication from '../services/authentication';
import Grid from '../../components/Grid';
import Form from '../../components/Form';
import CreateNewAssetController from '../controllers/CreateNewAssetController';
import SerialNumberRow from '../pages/SerialNumberRow';
import Table from '../../components/Table';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';

const AddSerialNumbers = (props) => {
  const controller = props.controller;
  const binder = props.binder;

  const handleAddToList = () => {
    props.controller.addToList();
  }

  const renderRowErrors = () => {
    const rowErrors = props.controller.state.errors && props.controller.state.errors.addedSerialNumberRows;
    if (rowErrors) {
      return <Form.FieldError>{rowErrors}</Form.FieldError>;
    }
  }

  const handleSpaceKey = (evt) => {
    if (evt.which == 32) {
      evt.preventDefault();
    }
  }

  const getCellProps = (cell) => {
    let style = {};
    if (cell && cell.column.id === 'state.primarySerialNumber' && !cell.row.original.state.isPrimaryUnique) {
      style = {
        backgroundColor: '#F55C5C'
      };
    }
    if (cell && (cell.column.id === 'state.customerName' || cell.column.id === 'state.currentLocationName') && !cell.row.original.state.isPrimaryUnique) {
      style = {
        color: '#A94442',
        fontSize: '0.9rem !important',
        fontWeight: 'normal !important'
      };
    }
    if (cell && cell.column.id === 'state.secondarySerialNumber' && !cell.row.original.state.isSecondaryUnique) {
      style = {
        backgroundColor: 'yellow'
      };
    }
    const rowProps = { style: style };
    return rowProps;

  }


  const divClassName = controller.state.errors.addedSerialNumberRows ? ' has-error' : '';
  let data = [...controller.state.addedSerialNumberRows];
  data.sort((a, b) => { return a.state.primarySerialNumber.localeCompare(b.state.primarySerialNumber); });

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <ActionIcon icon='fa-trash' className='remove-handle' action={controller.remove} />
        }
      },
      {
        Header: 'Primary Serial #',
        accessor: 'state.primarySerialNumber',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <span><Form.Input bind={binder.bind('primarySerialNumber')} readOnly={false} error={errors.primarySerialNumber} /></span>
        }
      },
      {
        Header: 'Customer',
        accessor: 'state.customerName',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.customerName}</span>
        }
      },
      {
        Header: 'Current Location',
        accessor: 'state.currentLocationName',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.currentLocationName}</span>
        }
      },
      {
        Header: 'Secondary Serial #',
        accessor: 'state.secondarySerialNumber',
        Cell: (cellInfo, binder) => {
          return <Form.Input bind={binder.bind('secondarySerialNumber')} readOnly={false} />
        }
      },
      {
        Header: 'Equipment #',
        accessor: 'state.equipmentNumber',
        Cell: (cellInfo, binder) => {
          return <Form.NumberInput bind={binder.bind('equipmentNumber')} maxLength="8" readOnly={false} />
        }
      },
      {
        Header: 'Batch #',
        accessor: 'state.batchNumber',
        Cell: (cellInfo, binder) => {
          return <Form.Input bind={binder.bind('batchNumber')} maxLength='250' readOnly={false} />
        }
      },
      {
        Header: 'Born On Date',
        accessor: 'state.bornOnDate',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Date bind={binder.bind('bornOnDate')} error={errors.bornOnDate} />
        }
      },
    ],
    []);

  return (
    <div className={divClassName}>
      <div><Form.Header className='alert-warning'>Add Serial Numbers <Badge variant='info'> {data.length}</Badge></Form.Header></div>

      <div className='col-md-12 row'>
        <div className='col-md-3'><Form.Input id='create-asset-primary-serial-number' label='Primary Serial #' maxLength='18' className='uppercase required' bind={binder.bind('primarySerialNumber')} /></div>
        <div className='col-md-3'><Form.Input id='create-asset-secondary-serial-number' label='Secondary Serial #' className='uppercase' bind={binder.bind('secondarySerialNumber')} /></div>
        <div className='col-md-3'><Form.Input label='Batch #' maxLength='250' bind={binder.bind('batchNumber')} /></div>
        <div className='col-md-2'><Form.Date label='Born On Date:' className='required' bind={binder.bind('bornOnDate')} /></div>
        <div className='col-md-1'><Button id='add-to-list' variant='success' size='sm' icon='fa-check-circle' onClick={handleAddToList} style={{ marginTop: '25px', width: '120px' }}>Add to List</Button></div>
      </div>
      <div id='add-assets-table'>
        <Grid id='results-table' columns={columns} data={data} editable={true} getCellProps={getCellProps} canAddNewRow={false} showPagination={false} noDataText='' />
      </div>
      {renderRowErrors()}
    </div>
  );
}


const AddTFMCCustomerSerialNumbers = (props) => {
  const controller = props.controller;
  const binder = props.binder;

  const handleAddToList = () => {
    props.controller.addToTfmcAssetList();
  }

  const renderRowErrors = () => {
    const rowErrors = props.controller.state.errors && props.controller.state.errors.addedSerialNumberRows;
    if (rowErrors) {
      return <Form.FieldError>{rowErrors}</Form.FieldError>;
    }
  }

  const handleSpaceKey = (evt) => {
    if (evt.which == 32) {
      evt.preventDefault();
    }
  }

  const getCellProps = (cell) => {
    let style = {};
    if (cell && cell.column.id === 'state.primarySerialNumber' && !cell.row.original.state.isPrimaryUnique) {
      style = {
        backgroundColor: '#F55C5C'
      };
    }
 
    const rowProps = { style: style };
    return rowProps;

  }
  const disableAddToList = controller.state.addedSerialNumberRows.length > 49 ? true : false;
  const divClassName = controller.state.errors.addedSerialNumberRows ? ' has-error' : '';
  let data = [...controller.state.addedSerialNumberRows];
  data.sort((a, b) => { return a.state.primarySerialNumber.localeCompare(b.state.primarySerialNumber); });
  const facilityId = LoginService.loginInfo ? LoginService.loginInfo.facilityId : '';

  const tfmcAssetTablecolumns = useMemo(
    () => [
      {
        Header: 'Delete',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <ActionIcon icon='fa-trash' className='remove-handle' action={controller.remove} />
        }
      },
      {
        Header: 'Primary Serial #',
        accessor: 'state.primarySerialNumber',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <span><Form.Input bind={binder.bind('primarySerialNumber')} readOnly={false} error={errors.primarySerialNumber} /></span>
        }
      },
      {
        Header: 'Mfg. Part#',
        accessor: 'state.mfgPartNumber',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          const value = cellInfo.value ? cellInfo.value.name : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Object Type',
        accessor: 'state.objectType',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          const value = cellInfo.value ? cellInfo.value.name : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Acquisition Value',
        accessor: 'state.acquisitionValue',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Currency',
        accessor: 'state.currency',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value.name : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Manufacturer',
        accessor: 'state.manufacturer',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Manufacturer Country',
        accessor: 'state.mfgCountry',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value.name : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Inventory Number',
        accessor: 'state.inventoryNumber',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Born On Date',
        accessor: 'state.bornOnDate',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          const value = cellInfo.value ? cellInfo.value : '';
          return <Form.Date bind={binder.bind('bornOnDate')} error={errors.bornOnDate} />
        }
      },
      {
        Header: 'Asset Number',
        accessor: 'state.assetNumber',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value : '';
          return <span>{value}</span>
        }
      },
      {
        Header: 'Cost Center',
        accessor: 'state.costCenter',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value.name : '';
          return <span>{value}</span>
        }
      },
    ],
    [data]);

  return (
    <div className={divClassName}>
      <div><Form.Header className='alert-warning'>Add Serial Numbers <Badge variant='info'> {data.length}</Badge></Form.Header></div>

      <div className='row'>
      <div className='col-md-2'><Form.AutoComplete label='Object Type' bind={binder.bind('objectType')} search={controller.objectTypeSearch()} /></div>
        <div className='col-md-2'><Form.Input id='create-asset-Inventory-number' maxLength='25' label='Inventory Number' bind={binder.bind('inventoryNumber')} /></div>
        <div className='col-md-2'><Form.Input label='Acquisition Value' type='number' className='required' maxLength='250' bind={binder.bind('acquisitionValue')} /></div>
        <div className='col-md-2'><Form.AutoComplete label='Currency' className='required' bind={binder.bind('currency')} search={controller.currencySearch()} /></div>
        <div className='col-md-2'><Form.Date label='Born On Date:' className='required' bind={binder.bind('bornOnDate')} /></div>
        <div className='col-md-2'><Form.AutoComplete label='Mfg. Country' bind={binder.bind('mfgCountry')} search={controller.mfgCountrySearch()} /></div>
        <div className='col-md-2'><Form.Input type='number' label='Asset Number' maxLength='7' minLength='7' className='' bind={binder.bind('assetNumber')} /></div>
        <div className='col-md-2'><Form.AutoComplete label='Cost Center' className='required' bind={binder.bind('costCenter')} search={Searchers.searchCostCenter(facilityId)} /></div>
        <div className='col-md-2 dd-primary-serial'><Form.Input id='create-asset-primary-serial-number' maxLength='18' label='Primary Serial #' className='uppercase required' bind={binder.bind('primarySerialNumber')} /></div>
        <div className='col-md-2'><Form.AutoComplete label='Mfg Part #' className='required' bind={binder.bind('mfgPartNumber')} search={controller.mfgItemSearch()} /></div>
        <div className='col-md-2'><Form.Input id='create-asset-manufacturer-number' readOnly={true} label='Manufacturer' bind={binder.bind('manufacturer')} /></div>
         <div className='col-lg-2 div-add-to-list'><Button id='add-to-list' variant='success' size='sm' icon='fa-check-circle' onClick={handleAddToList} disabled={disableAddToList} className='btn-add-to-list'>Add to List</Button></div>
      </div>
      <div id='add-assets-table'>
        <Grid id='results-table' columns={tfmcAssetTablecolumns} data={data} editable={true} getCellProps={getCellProps} canAddNewRow={false} showPagination={false} noDataText='' />
      </div>
      {renderRowErrors()}
    </div>
  );
}


// const AddSerialNumbers = (props) => {

//   const handleAddToList = () => {
//     props.controller.addToList();
//   }

//   const renderRowErrors = () => {
//     const rowErrors = props.controller.state.errors && props.controller.state.errors.addedSerialNumberRows;
//     if (rowErrors) {
//       return <div className='help-block'>{rowErrors}</div>;
//     }
//   }

//   const handleSpaceKey = (evt) => {
//     if (evt.which == 32) {
//       evt.preventDefault();
//     }
//   }

//   const controller = props.controller;
//   const binder = props.binder;
//   let rows = controller.state.addedSerialNumberRows || [];
//   const isSAPFacility = SAPService.isSAPFacility();
//   const EqupNum = <Table.Head className='bg-primary table-dark'>Equipment #</Table.Head>;
//   rows.sort((a, b) => { return a.state.primarySerialNumber.localeCompare(b.state.primarySerialNumber); });
//   rows = rows.map(row => <SerialNumberRow key={row.key} controller={row} />);

//   var divClassName = 'form-group col-sm-12';
//   if (controller.state.errors.addedSerialNumberRows) {
//     divClassName += ' has-error';
//   }

//   return (
//     <div className={divClassName}>
//       <Form.Header className='alert-warning'>Add Serial Numbers <Badge variant='info'> {rows.length}</Badge></Form.Header>

//       <div className='col-md-12 row'>
//         <div className='col-md-3' onKeyDown={handleSpaceKey}><Form.Input id='create-asset-primary-serial-number' label='Primary Serial #' className='uppercase required' bind={binder.bind('primarySerialNumber')} /></div>
//         <div className='col-md-3'><Form.Input id='create-asset-secondary-serial-number' label='Secondary Serial #' className='uppercase' bind={binder.bind('secondarySerialNumber')} /></div>
//         <div className='col-md-3'><Form.Input label='Batch #' maxLength='250' bind={binder.bind('batchNumber')} /></div>
//         <div className='col-md-2'><Form.Date  label='Born On Date:' className='required' bind={binder.bind('bornOnDate')} /></div>
//         <div className='col-md-1'><Button id='add-to-list' variant='success' size='sm' icon='fa-check-circle' onClick={handleAddToList} style={{ marginTop: '25px', width: '120px' }}>Add to List</Button></div>
//       </div>

//       <div id='search-results' className='col-md-12' style={{height:'15rem', overflowY:'auto', borderBottom: 'solid 2px #DDD'}}>
//         <Table id='results-table'>
//           <Table.Header>
//             <Table.Row>
//               <Table.Head className='bg-primary table-dark col-action' />
//               <Table.Head className='bg-primary table-dark'>Primary Serial #</Table.Head>
//               <Table.Head className='bg-primary table-dark'>Customer</Table.Head>
//               <Table.Head className='bg-primary table-dark'>Current Location</Table.Head>
//               <Table.Head className='bg-primary table-dark'>Secondary Serial #</Table.Head>
//               {EqupNum}
//               <Table.Head className='bg-primary table-dark'>Batch #</Table.Head>
//               <Table.Head className='bg-primary table-dark'>Born On Date</Table.Head>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {rows}
//           </Table.Body>
//         </Table>
//         {renderRowErrors()}
//       </div>
//     </div>
//   );
// }

const EditForm = (props) => {
  const handleSearchProperties = (evt) => {
    evt.preventDefault();
    props.controller.handleSearch(AppConstants.eSearchType.Properties);
  }

  const selectRow = (asset) => {
    props.controller.selectAsset(asset);
  }

  const controller = props.controller;
  if (!controller.state.matchingAssets) {
    return null;
  }
  const binder = props.binder;
  const errors = controller.state.errors;
  const assets = controller.state.matchingAssets.map(asset => {
    let className = '';
    if (!asset.mfgPartNumber && (asset.id === controller.state.selectedMatchingAsset.id)) {
      className = 'selected-no-mfgpartno';
    }
    else if (asset.id === controller.state.selectedMatchingAsset.id) {
      className = 'selected';
    }
    else if (!asset.mfgPartNumber) {
      className = 'no-mfgitemno';
    }

    return (
      <Table.Row onClick={() => { controller.selectAsset(asset); }} asset={asset}>
        <Table.Data className={className}>{asset.mfgPartNumber}</Table.Data>
        <Table.Data className={className}>{asset.description}</Table.Data>
      </Table.Row>
    );
  });
  const isSAPFacility = SAPService.isSAPFacility();
  const customerField = isSAPFacility
    ? <div className='col-md-6'><Form.AutoComplete id='non-tfmc-customer' label='Customer' className='required' bind={binder.bind('customer')} search={controller.nonTfmcCustomerSearch()} /></div>
    : <div className='col-md-6'><Form.AutoComplete label='Customer Non-SAP' className='required' bind={binder.bind('customer')} search={controller.customerSearch()} /></div>

  let mfgPartNoWarningClass = 'not-selected-no-mfgpartno';
  const enableWarning = controller.state.selectedMatchingAsset && !controller.state.selectedMatchingAsset.mfgPartNumber
  return (
    <div className='col-12'>
      <div>
        <Form.Header className='alert-warning'>Asset Lookup</Form.Header>
      </div>

      <div id='customer' className='row'>
        {customerField}
        <div className='col-md-6'><Form.AutoComplete label='Mfg Part #' bind={binder.bind('mfgPartNumber')} search={controller.mfgItemSearch()} /></div>
      </div>

      <div id='search-criteria' className='row'>
        <div className='col-md-3'><Form.AutoComplete label='Family Code' bind={binder.bind('familyCode')} search={controller.familyCodeSearch()} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Length' bind={binder.bind('length')} search={controller.lengthSearch()} /></div>
        <div className='col-md-3'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={controller.cwpSearch()} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Style' bind={binder.bind('style')} search={controller.styleSearch()} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Nominal Size' bind={binder.bind('nominalSize')} search={controller.nominalSizeSearch()} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Manufacturer' bind={binder.bind('manufacturer')} search={controller.manufacturerSearch()} /></div>
        <div className='col-md-6'><span>&nbsp;</span><Button icon='fa-search' variant='info' size='sm' block={true} onClick={handleSearchProperties}>Search</Button>
        </div>
      </div>

      <div className='row'>
        <div id='search-results-header' className='col-md-12'>
          <Form.Header className='alert-warning'>Matching Descriptions <Badge variant='info'> {controller.state.matchingAssets.length}</Badge></Form.Header>
          {enableWarning && <div className='float-right'><Form.Label className={mfgPartNoWarningClass} name='Warning: no Mfg Part # for the selected asset' /></div>}
        </div>

        <div id='search-results' className='form-group col-md-12'>
          <Table id='results-table'>
            <Table.Header>
              <Table.Row>
                <Table.Head>Mfg Part #</Table.Head>
                <Table.Head>Description</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {assets}
            </Table.Body>
          </Table>
        </div>
      </div>

      <div className='row'>
        <div id='asset-properties' className='col-md-12'>
          <Form.Header className='alert-warning' heigth={50}>Asset Description</Form.Header>
          <div className='row'>
            <div className='col-md-3'><Form.AutoComplete id='customerLocation' label='Customer Location' className='required' error={errors.customerLocation} bind={binder.bind('customerLocation')} search={controller.customerLocationSearch()} /></div>
            <div className='col-md-3'><Form.Date label='In Service Date:' className='required' error={errors.inServiceDate} bind={binder.bind('inServiceDate')} /></div>
          </div>
        </div>
      </div>
    </div>
  );

}


const TFMCEditForm = (props) => {
  const handleSearchProperties = (evt) => {
    evt.preventDefault();
    props.controller.handleSearch(AppConstants.eSearchType.Properties);
  }

  const selectRow = (asset) => {
    props.controller.selectAsset(asset);
  }

  const controller = props.controller;
  if (!controller.state.matchingAssets) {
    return null;
  }
  const binder = props.binder;
  const errors = controller.state.errors;
  const assets = controller.state.matchingAssets.map(asset => {
    let className = '';
    if (!asset.mfgPartNumber && (asset.id === controller.state.selectedMatchingAsset.id)) {
      className = 'selected-no-mfgpartno';
    }
    else if (asset.id === controller.state.selectedMatchingAsset.id) {
      className = 'selected';
    }
    else if (!asset.mfgPartNumber) {
      className = 'no-mfgitemno';
    }

    return (
      <Table.Row onClick={() => { controller.selectAsset(asset); }} asset={asset}>
        <Table.Data className={className}>{asset.mfgPartNumber}</Table.Data>
        <Table.Data className={className}>{asset.description}</Table.Data>
      </Table.Row>
    );
  });

  let mfgPartNoWarningClass = 'not-selected-no-mfgpartno';
  const enableWarning = controller.state.selectedMatchingAsset && !controller.state.selectedMatchingAsset.mfgPartNumber

  return (
    <div className='col-12'>
      <div id='customer' className='row'>
        <div className='col-md-6'><Form.AutoComplete label='Customer' className='required' bind={binder.bind('customer')} search={controller.tfmcCustomerSearch()} /></div>
        <div className='col-md-2'><Form.Input label='Category' bind={binder.bind('category')} readOnly={true} /></div>
      </div>

      <div className='row'>
        <div id='search-results-header' className='col-md-12'>
          <Form.Header className='alert-warning'>Asset Location Description </Form.Header>


          <div id='search-criteria' className='row'>
            <div className='col-md-4'><Form.AutoComplete id='customerLocation' label='Customer Location' className='required' error={errors.customerLocation} bind={binder.bind('customerLocation')} search={controller.customerLocationSearchForTFMCAssets()} /></div>
            <div className='col-md-2'><Form.Input label='Maint Plant' bind={binder.bind('maintPlant')} readOnly={true} /></div>
            <div className='col-md-3'><Form.Date label='In Service Date:' className='required' error={errors.inServiceDate} bind={binder.bind('inServiceDate')} /></div>
          </div>
        </div>

      </div>

    </div>
  );

}

const Buttons = (props) => {
  let history = useHistory();

  const handleCreate = () => {
    props.controller.createAssets();
  }

  const handleClose = () => {
    history.goBack();
  }

  const validateNumberToCreate = (evt) => {
    const value = parseInt(evt.target.value, 10);
    if (value < 1) {
      value = 1;
    }
    if (value > 50) {
      value = 50;
    }
    evt.target.value = value;
  }

  const handleAddMultiple = () => {
    props.controller.addMultiple();
  }

  const handleClearList = () => {
    props.controller.clearList();
  }

  const binder = props.binder;

  return (
    <div className='col-md-12 mt-2' >
      <div className='row'>
        <div className='col-md-3'><Form.Input type='number' label='# to Create' bind={binder.bind('numberToCreate')} min={1} max={50} onInput={validateNumberToCreate} hint='range: 1-50' /></div>
        <div className='col-md-3' style={{ marginTop: '25px' }}>
          <Button variant='info' size='sm' onClick={handleAddMultiple} style={{ width: '120px' }}>Add Multiple</Button>
          <div style={{ display: 'inline-block', marginLeft: '15px' }}><Form.Link onClick={handleClearList} value='Clear list' /></div>
        </div>
      </div>

      <div className='d-flex justify-content-end m-2'>
        <Button icon='fa-plus-circle' variant='success' size='sm' className='mr-1 action-button' onClick={handleCreate} disabled={props.controller.state.isSaving}>Create</Button>
        <Button icon='fa-times' variant='secondary' size='sm' className='mr-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}


const TfmcAssetsTabButtons = (props) => {
  let history = useHistory();

  const handleCreate = () => {
    props.controller.createTfmcAssets();
  }

  const handleClose = () => {
    history.goBack();
  }

  const binder = props.binder;

  return (
    <div className='col-md-12 mt-2' >
      <div className='d-flex justify-content-end m-2'>
        <Button icon='fa-plus-circle' variant='success' size='sm' className='mr-1 action-button' onClick={handleCreate} disabled={props.controller.state.isSaving}>Create</Button>
        <Button icon='fa-times' variant='secondary' size='sm' className='mr-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const NonCustomerPropertyTabForNonSAPFacility = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  return (
    <div className='col-12'>
      <EditForm controller={controller} binder={binder} />
      <AddSerialNumbers controller={controller} binder={binder} />
      <Buttons controller={controller} binder={binder} />
    </div>);
}

const NonCustomerPropertyTab = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  return (
    <div className='col-12'>
      <TFMCEditForm controller={controller} binder={binder} />
      <AddTFMCCustomerSerialNumbers controller={controller} binder={binder} />
      <TfmcAssetsTabButtons controller={controller} binder={binder} />
    </div>);
}

const CustomerPropertyTab = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  return (
    <div className='col-12'>
      <EditForm controller={controller} binder={binder} />
      <AddSerialNumbers controller={controller} binder={binder} />
      <Buttons controller={controller} binder={binder} />
    </div>);
}

const CreateAssetPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class CreateNewAssetPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const searchText = this.props.match.params.searchText;
    this.props.controller.load(searchText);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new CreateNewAssetController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const title = 'Create New Asset';
    const isFMCCustomer = controller.state.isFmcCustomer;
    const isnonFMCcustomer = controller.state.isNonFMCCustomer;
    const showAllCustomer = controller.state.showAllCustomer;
    const isSAPFacility = SAPService.isSAPFacility();
    return (
      <Page {...this.props} pageTitle='InteServ · Create Asset' id='create-asset-page'>
        <CreateAssetPageHeader pageTitle={title} />
        <Page.Content>
          {isSAPFacility && showAllCustomer && <div id='customer' className='row'>
            <div className='col-md-4'>
              <Form.AutoComplete label='Customer' className='required' bind={binder.bind('allCustomer')} search={controller.customerSearch()} />
            </div>
          </div>
          }

          <div className='row content'>
            {!isSAPFacility && <NonCustomerPropertyTabForNonSAPFacility controller={controller} binder={binder} />}
            {(isSAPFacility && isnonFMCcustomer) && <CustomerPropertyTab controller={controller} binder={binder} />}
            {(isSAPFacility && isFMCCustomer) && <NonCustomerPropertyTab controller={controller} binder={binder} />}
          </div>

        </Page.Content>
      </Page>
    );
  }
}

export default CreateNewAssetPage;