import React from 'react';
import { MentionsInput as RMentionsInput , Mention } from 'react-mentions';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';

const BaseMentionsInput = (propsPrams) => {
  const props = {
      value: '',
      data: [],
      className: '',
      onChange: () => { },
      ...propsPrams
  };

  const displayTransform = (id, display) => {
      return display;
  }

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
      const onChange = props.onChange;    
      const mensionedUsers = mentions.map(item => ({id: item.id, name: item.display}));
      const usersToNotify = uniqBy(mensionedUsers, myUser => myUser.id);
      onChange(newValue, usersToNotify, newPlainTextValue);
  }

  return (
    <RMentionsInput
      value={props.value}
      onChange={handleChange}    
      displayTransform={displayTransform}
      allowSpaceInQuery={true}
      className={'mentions form-control ' + props.className}
    >
      <Mention
        trigger='@'
        markup='@{__id__||__display__}'
        data={props.data}
        className={'mentions__mention'}
      />
    </RMentionsInput>
  );
}

const MentionsInput = styled(BaseMentionsInput)`
  padding: .375rem .75rem;
  height: ${props => props.singleLine ? 'calc(1.5em + .75rem + 2px)' : 'auto !important'};
  border: unset;

  .mentions__input{
     width: unset;
  }

  .mentions__control {   
    textarea{
      display: block;
      width: 100%;
      height: ${props => props.singleLine ? 'calc(1.5em + .75rem + 2px)' : 'auto !important'};
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;    
    }
  }

  .mentions__highlighter{
    min-height: ${props => props.singleLine ? 'calc(1.5em + .75rem + 2px)' : '6.25rem !important'};
  }

  .mentions__suggestions{
    max-height: 21.88rem;
    overflow-y: auto;    
    margin-top: 2.188rem !important;
  }
  
  .mentions__suggestions__list {
    background-color: white;
    border: 0.0625rem solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
  }
  
  .mentions__suggestions__item {
    padding: 0.3rem 0.9375rem;
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.15);   
  }
  
  .mentions__suggestions__item--focused {
    background-color: #cee4e5;
  }
  
  .mentions__mention {
    background-color: #cee4e5;
    font
  }
`;

export default MentionsInput;