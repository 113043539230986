import ajax from '../../services/Ajax';
import AppConstants from './AppConstants';
import Utils from './Utils';

function facilitySearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getReportsFacilityLookupValues', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function customerSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function assemblyWorkOrderSearch(facilityId,ownerId=Utils.emptyGuid) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/SearchAssemblyWorkOrderrList', { searchTerm: searchTerm , facilityId : facilityId , ownerId : ownerId}); // IList<LookupDto>
    return results;
  };
}

function technipCustomerSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchtechnipCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}
function customerSearchByFacility(facilityId) {
  return function* (searchTerm) {
    var parameters = {
      facilityId: facilityId ? facilityId : Utils.emptyGuid,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/searchCustomerListbyFacility', parameters); // IList<LookupDto>
    return results;
  };
}

function reportsListNameSearch(listName) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getReportLookupValues', { listName: listName, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function reportsJobNumberSearch(jobNumberObj) {
  return function* (searchTerm) {
    jobNumberObj.searchTerm = searchTerm;
    var results = yield ajax.get('lookup/getReportsJobNumberLookupValues', jobNumberObj); // IList<LookupDto>
    return results;
  };
}

function searchFacilityJobNumber(facilityId) {
  return function* (searchTerm) {
    var parameters = {
      facilityId: facilityId ? facilityId : Utils.emptyGuid,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/getFacilityJobNumbers', parameters); // IList<LookupDto>
    return results;
  };
}

function getContainerList(containerObj) //{facilityId?}/{customerId?}/{isRental?/{searchTerm?}}" input param
{
  return function* (searchTerm) {
    containerObj.searchTerm = searchTerm;
    var results = yield ajax.get('lookup/getContainerList', containerObj); // IList<LookupDto>
    return results;
  };
}


function getFunctionalLocation(obj){
  return function* (searchTerm) {
    obj.searchTerm = searchTerm;
    var results = yield ajax.get('lookup/getFunctionalLocation', obj); // IList<LookupDto>
    return results;
  };
}

function getShippingTicketList(shipingticketObject) //{facilityId?}/{customerId?}/{searchTerm?}
{
  return function* (searchTerm) {
    shipingticketObject.searchTerm = searchTerm;
    var results = yield ajax.get('lookup/getShippingTicketList', shipingticketObject); // IList<LookupDto>
    return results;
  };
}

function getRecievedTicketList(recievingTicketObject) //{facilityId?}/{customerId?}/{searchTerm?}
{
  return function* (searchTerm) {
    recievingTicketObject.searchTerm = searchTerm;
    var results = yield ajax.get('lookup/getRecievedTicketList', recievingTicketObject); // IList<LookupDto>
    return results;
  };
}

function getServicelevelList(customerId) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getServicelevelList', { customerId: customerId, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function getServicelevelTestNamelist(serviceLevelId) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getServicelevelTestNamelist', { serviceLevelId: serviceLevelId, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}
function getTechnicianList(facilityId) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getTechnicianList', { facilityId: facilityId, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function getRegionList() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getRegionList', { searchTerm: searchTerm });
    return results;
  };
}

function ReportTypeSearch() {
  return Utils.createSearch(AppConstants.ReportFormat); // IList<LookupDto>
}

function ReportListSearch() {
  return Utils.createSearch(AppConstants.ReportList);
}

function customerLocationSearch(customerId) {
  return function* (searchTerm) {
    var parameters = {
      customerId: customerId ? customerId : Utils.emptyGuid,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/searchCustomerLocationList', parameters); // IList<CustomerLocationLookupDto>
    return results;
  };
}

function mfgItemSearch() {
  return function* (searchTerm) {
    // Mfg. Item #
    var results = yield ajax.get('lookup/searchLookupList/Mfg%20Item%20%23', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function serialNumbersSearch(facilityId, selectedSerialNumbers) {
  return function* (searchTerm) {
    var parameters = {
      searchTerm: searchTerm,
      facilityGUID: facilityId,
      selectedSerialNumbers: selectedSerialNumbers.length ? selectedSerialNumbers : '',
      isExclusive : true
    };
    var results = yield ajax.get('lookup/getSerialNumbers', parameters);
    return results;
  };
}

function mfgSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getitemlist/Manufacturer', { searchTerm: searchTerm });
    return results;
  }
}

function familyCodeSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getitemlist/Family%20Code', { searchTerm: searchTerm });
    return results;
  }
}

function styleSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getitemlist/Style', { searchTerm: searchTerm });
    return results;
  }
}

function nominalSizeSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getitemlist/Nominal%20Size', { searchTerm: searchTerm });
    return results;
  }
}

function lengthSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getitemlist/Length', { searchTerm: searchTerm });
    return results;
  }
}

function jobNumberSearch(customerId) {
  return function* (searchTerm) {
    var parameters = {
      customerId: customerId ? customerId : Utils.emptyGuid,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/searchJobs', parameters);
    return results;
  };
}

function containerSearch(customerId) {
  return function* (searchTerm) {
    var parameters = {
      customerId: customerId ? customerId : Utils.emptyGuid,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/searchContainers', parameters);
    return results;
  };
}

function listSearch(searchType) {
  if (!searchType) {
    return null;
  }

  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function getReportsLocationLookupValues(locationObject) //{facilityId?}/{customerId?}/{searchTerm?}
{
  return function* (searchTerm) {
    locationObject.searchTerm = searchTerm;
    var results = yield ajax.get('lookup/getReportsLocationLookupValues', locationObject); // IList<LookupDto>
    return results;
  };
}
function getReportsSLevelLookupValues() //{facilityId?}/{customerId?}/{searchTerm?}
{
  return function* (searchTerm) {
    var parameters = {
      facilityId: Utils.emptyGuid,
      customerId: '09673009-3F0C-47A4-9557-C34ABB9A2EF9',
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/getReportsSLevelsLookupValues', parameters); // IList<LookupDto>
    return results;
  };
}
function assetStatusSearch() {
  return Utils.createSearch(AppConstants.AssetStatus); // IList<LookupDto>
}

function assetActiveStatusSearch() {
  return Utils.createSearch(AppConstants.ActiveAssetStatus); // IList<LookupDto>
}

function assetNormalActiveStatusSearch() {
  return Utils.createSearch(AppConstants.NormalActiveAssetStatus); // IList<LookupDto>
}

function assetOSActiveStatusSearch() {
  return Utils.createSearch(AppConstants.OSActiveAssetStatus); // IList<LookupDto>
}

function sapProfileSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getitemlist/SAP%20Profile', { searchTerm: searchTerm });
    return results;
  }
}

function timeZoneSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchTimeZone', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function reportsMFGItemNoSearch(customerId,includedescription= false) 
{
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getMFGItemNoValues', { customerId: customerId, searchTerm: searchTerm , includesdescription : includedescription }); // IList<LookupDto>
    return results;
  };
}

const searchTorqueLocation = () => {
  return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchTorqueLocation', { searchTerm: searchTerm });
    return results;
  };
}

const searchDimensionLocation = () => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchDimensionalLocation', { searchTerm: searchTerm });
    return results;
  };
}

const searchTorqueRange = () => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchTorqueRange', { searchTerm: searchTerm });
    return results;
  };
}

var _testModes;
function testModeSearch() {
  return function* () {
    if (!_testModes) {
      var results = yield ajax.get('lookup/getTestModes'); // IList<LookupDto>
      _testModes = results;
    }
    return _testModes;
  };
}

var _roles;
function roleSearch() {
  return function* () {
    if (!_roles) {
      var results = yield ajax.get('lookup/getRoles'); // IList<LookupDto>
      _roles = results;
    }
    return _roles;
  };
}


var _calibrations;
function calibrationSearch() {
  return function* () {
    if (!_roles) {
      var results = yield ajax.get('lookup/getCalibrationList'); // IList<LookupDto>
      _roles = results;
    }
    return _roles;
  };
}

var _rejectCodes
function rejectCodeSearch(assetId) {
  return function* () {
    if (!_rejectCodes) {
      var results = yield ajax.get('lookup/GetRejectCodes', { assetId: assetId }); // IList<LookupDto>
      _rejectCodes = results;
    }
    return _rejectCodes;
  };
}

function activeUsersForFacility(facilityId) {
  return function* (searchTerm) {
    var parameters = {
      facilityId: facilityId,
      searchTerm: searchTerm,
      enabledUsersOnly: true,
      displayAccount: true
    };
    var results = yield ajax.get('lookup/getAllUsersForFacility', parameters);
    return results;
  };
}

function groupNumberSearch(jobNumber) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getJobGroupNameAndNumber', { jobId: jobNumber, searchTerm: searchTerm });
    return results;
  };
}

function groupNumberandNameSearch(jobNumber) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getJobGroupNumberName', { jobId: jobNumber, searchTerm: searchTerm });
    return results;
  };
}

function equipmentStatusSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchEquipmentStatus', { searchTerm: searchTerm });
    return results;
  };
}

function soStatusSearch() {
  return Utils.createSearch(AppConstants.SOStatus); // IList<LookupDto>
}

function activeUsersForSapFacilityWithEmployeeId(facilityId) {
  return function* (searchTerm) {
    var parameters = {
      facilityId: facilityId,
      searchTerm: searchTerm,
      enabledUsersOnly: true,
      displayAccount: true
    };
    var results = yield ajax.get('lookup/getAllUsersForSAPFacilityWithEmployeeId', parameters);
    return results;
  };
}

const sapFacilitySearch = () => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchFacilitiesForUser', { searchTerm: searchTerm, sapFacilitiesOnly: true });
    return results;
  };
}

const searchCostCenterWithFacilityId = (facilityId) => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchCostCenterWithFacilityId', { searchTerm: searchTerm, facilityId: facilityId });
    return results;
  };
}

function rejectReasonSearch(familyCodeId) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchRejectReasonLookupList', { familyCodeId: familyCodeId, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function searchActiveJobs(customerId, facilityId) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchActiveJobsForFacility', { facilityId: facilityId, customerId: customerId, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}


function searchActiveAWOs(customerId, facilityId) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchAWOByFacilityAndCustomer', { facilityId: facilityId, ownerId: customerId, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}


function searchServiceLevelsForCustomer(customerId) {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/SearchServiceLevelsForCustomer', { customerId: customerId, searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

const objectTypeSearch = () => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/getitemlist/Object%20Type', { searchTerm: searchTerm });
    return results;
  };
}

const searchAssetManagementMfgParts = () => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchAssetManagementMfgParts', { searchTerm: searchTerm });
    return results;
  };
}

const searchReceivngCostCenter = (facilityId) => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/searchReceivingCostCenter', { searchTerm: searchTerm, facilityId: facilityId });
    return results;
  };
}

const searchCostCenter = (facilityId) => {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/SearchCostCenter', { searchTerm: searchTerm, facilityId: facilityId });
    return results;
  };
}

const searchFacilityScopeSerials = (facilityId) => {
  return function* (searchTerm) {
    var parameters = {
      facilityId: facilityId ? facilityId : Utils.emptyGuid,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/getFacilityScopeSerials', parameters); // IList<LookupDto>
    return results;
  };
  
}
function awocustomerSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoServiceLineSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchAWOServiceLineList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function getCustomerContainerList(customerId) //{facilityId?}/{customerId?}/{isRental?/{searchTerm?}}" input param
{
  return function* (searchTerm) {
    var parameters = {
      customerId: customerId,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/searchCustomerContainers', parameters); // IList<LookupDto>
    return results;
  };
}

function getCustomerContainerListByFunctionalLocation(customerId, functionalLocation) //{facilityId?}/{customerId?}/{isRental?/{searchTerm?}}" input param
{
  return function* (searchTerm) {
    var parameters = {
      customerId: customerId,
      functionalLocation:functionalLocation,
      searchTerm: searchTerm
    };
    if(functionalLocation===undefined){
      parameters.functionalLocation="";
    }
   var results = yield ajax.get('lookup/searchCustomerContainersByFunctionalLocation', parameters); // IList<LookupDto>
    return results;
  };
}

function getCustomerFunctionalLocationList(customerId) //{facilityId?}/{customerId?}/{isRental?/{searchTerm?}}" input param
{
  return function* (searchTerm) {
    var parameters = {
      customerId: customerId,
      searchTerm: searchTerm
    };
    var results = yield ajax.get('lookup/awo/searchCustomerFunctionalLocationList', parameters); // IList<LookupDto>
    return results;
  };
}

function awoCWPSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchAWOCWPList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoWellColorSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchWellColorList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoSystemSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchSystemList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoGroupSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchGroupList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoPackageSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchPackageList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoEquipmentDescriptionSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchEquipmentDescriptionList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoDependentEquipmentDescriptionSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchDependentEquipmentDescriptionList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function awoParentChildSearch() {
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchParentChildList', { searchTerm: searchTerm }); // IList<LookupDto>
    return results;
  };
}

function searchAwoByFacility(facilityId){
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/searchawobyfacility', { facility : facilityId , searchTerm: searchTerm });     
    return results;
  };
}  

function searchAwoByOwner(ownerId,facilityId){
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/getawobyowners', { ownerId : ownerId , searchTerm: searchTerm , facilityId : facilityId });     
    return results;
  };
}  



const organizationSearch = () => Utils.createSearch(AppConstants.Organizations);

const jobTypeSearch = () => Utils.createSearch(AppConstants.JobType);

const unitOfMeasureSearch = () => Utils.createSearch(AppConstants.UnitOfMeasure);

const accountingIndicator = () => Utils.createSearch(AppConstants.AccountingIndicators);
function searchUnassingedassetsByAWO(awoId, isSAP = false){
  return function* (searchTerm) {
    var results = yield ajax.get('lookup/awo/getawounassignedassets', { awoId : awoId , isSAP : isSAP , searchTerm: searchTerm});     
    return results;
  };
}
export default {
  facilitySearch: facilitySearch,
  customerSearch: customerSearch,
  technipCustomerSearch: technipCustomerSearch,
  ReportTypeSearch: ReportTypeSearch,
  ReportListSearch: ReportListSearch,
  customerLocationSearch: customerLocationSearch,
  mfgItemSearch: mfgItemSearch,
  serialNumbersSearch: serialNumbersSearch,
  mfgSearch: mfgSearch,
  familyCodeSearch: familyCodeSearch,
  styleSearch: styleSearch,
  nominalSizeSearch: nominalSizeSearch,
  lengthSearch: lengthSearch,
  jobNumberSearch: jobNumberSearch,
  containerSearch: containerSearch,
  listSearch: listSearch,
  getContainerList: getContainerList,
  getFunctionalLocation: getFunctionalLocation,
  getShippingTicketList: getShippingTicketList,
  getRecievedTicketList: getRecievedTicketList,
  getServicelevelList: getServicelevelList,
  getTechnicianList: getTechnicianList,
  getRegionList: getRegionList,
  getServicelevelTestNamelist: getServicelevelTestNamelist,
  assetStatusSearch: assetStatusSearch,
  assetActiveStatusSearch: assetActiveStatusSearch,
  assetNormalActiveStatusSearch: assetNormalActiveStatusSearch,
  assetOSActiveStatusSearch: assetOSActiveStatusSearch,
  testModeSearch: testModeSearch,
  roleSearch: roleSearch,
  activeUsersForFacility: activeUsersForFacility,
  activeUsersForSapFacilityWithEmployeeId: activeUsersForSapFacilityWithEmployeeId,
  sapProfileSearch: sapProfileSearch,
  timeZoneSearch: timeZoneSearch,
  searchTorqueLocation: searchTorqueLocation,
  searchDimensionLocation: searchDimensionLocation,
  searchTorqueRange: searchTorqueRange,
  reportsListNameSearch: reportsListNameSearch,
  reportsJobNumberSearch: reportsJobNumberSearch,
  getReportsLocationLookupValues: getReportsLocationLookupValues,
  getReportsSLevelLookupValues: getReportsSLevelLookupValues,
  sapFacilitySearch: sapFacilitySearch,
  searchCostCenterWithFacilityId: searchCostCenterWithFacilityId,
  groupNumberSearch: groupNumberSearch,
  groupNumberandNameSearch: groupNumberandNameSearch,
  equipmentStatusSearch: equipmentStatusSearch,
  soStatusSearch: soStatusSearch,
  searchFacilityJobNumber: searchFacilityJobNumber,
  rejectReasonSearch: rejectReasonSearch,
  searchActiveJobs: searchActiveJobs,
  objectTypeSearch: objectTypeSearch,
  searchAssetManagementMfgParts: searchAssetManagementMfgParts,
  searchReceivngCostCenter: searchReceivngCostCenter,
  organizationSearch: organizationSearch,
  jobTypeSearch: jobTypeSearch,
  unitOfMeasureSearch: unitOfMeasureSearch,
  searchFacilityScopeSerials: searchFacilityScopeSerials,
  accountingIndicator: accountingIndicator,
  rejectCodeSearch: rejectCodeSearch,
  customerSearchByFacility: customerSearchByFacility,
  calibrationSearch: calibrationSearch,
  searchServiceLevelsForCustomer: searchServiceLevelsForCustomer,
  reportsMFGItemNoSearch: reportsMFGItemNoSearch,
  assemblyWorkOrderSearch:assemblyWorkOrderSearch,  
  searchCostCenter:searchCostCenter,
  awocustomerSearch : awocustomerSearch,
  awoCWPSearch: awoCWPSearch,
  awoServiceLineSearch: awoServiceLineSearch,
  getCustomerContainerList:getCustomerContainerList,
  getCustomerFunctionalLocationList:getCustomerFunctionalLocationList,
  awoWellColorSearch:awoWellColorSearch,
  awoSystemSearch:awoSystemSearch,
  searchAwoByFacility : searchAwoByFacility,
  getCustomerContainerListByFunctionalLocation:getCustomerContainerListByFunctionalLocation,
  awoGroupSearch:awoGroupSearch,
  awoPackageSearch:awoPackageSearch,
  awoEquipmentDescriptionSearch:awoEquipmentDescriptionSearch,
  awoDependentEquipmentDescriptionSearch:awoDependentEquipmentDescriptionSearch,
  awoParentChildSearch:awoParentChildSearch,
  searchActiveAWOs:searchActiveAWOs,
  searchAwoByOwner : searchAwoByOwner,
  searchUnassingedassetsByAWO: searchUnassingedassetsByAWO
};
