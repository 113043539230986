import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import ActionIcon from '../../components/ActionIcon';
import GroupBadge from '../../components/GroupBadge';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Badge from '../../components/Badge';
import List from '../../components/List';
import styled from 'styled-components';
import Disposable from '../../mixins/Disposable';
import messages from '../../services/Messages';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import MentionsInput from './MentionsInput';

const NotificationBellStyles = styled.div`
.notification-bell{
    margin-top: 0.375rem;
}

.notification-count{
    font-size: 0.75rem;
    position: absolute;
    max-width: 2.8125rem;
    margin-left: 0.1875rem;
    margin-top: -0.25rem;
}

.notification-count.more{
    margin-left: -0.625rem;   
}

.no-notification{
    width: 23.9375rem;
}

.popover{
    z-index: 1501;
    max-height: calc(100vh - 6.25rem);
    overflow-y: auto; 
    max-width: 25rem;
}

.popover-body{
    padding: 0;
}

.list-group-item {
    cursor: pointer;
    padding: 0.25rem 0.5rem !important;
}

.list-group-item:nth-child(odd) {
    background: #e7f8ff
}

.list-group-item:hover {
    background: #b8daff;
}

.user-notification-header{
    color: #512D6D;  
    font-size: 0.6875rem !important;   
    font-weight: 500;
    .form-group {
         margin-bottom: unset;
    }
    span div{
     display: inline-block;
    }
 }
 
 .user-notification-note{
     font-size: 0.875rem !important;  
     font-weight: 500;
 }
 
 .user-notification-addedon{
     color: #53565A;
     font-size: 0.6875rem !important;  
 }
   
.send{
    border-radius: 50%;
    height: 2.1875rem;
    width: 2.1875rem;
 }

 // Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
   .popover{
       max-width: 17.1875rem !important;
   }
   .no-notification{
        width: 17.0625rem !important;
   }
}
`;

const NotificationRow = (propsPrams) => {

    const history = useHistory();

    const getInitialState = () => {
        return {
            replyText: '',
            displayText: '',
            usersToNotify: [],
            showReplyBox: false,
        };
    }

    const [state, setState] = useState(getInitialState);

    const props = {
        getNotificationsCallback: () => { },
        ...propsPrams
    };

    const isReadOnly = () => {
        return props.item.notificationId == Utils.emptyGuid;
    };

    const stopPropagation = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const handleEntityClick = (item, e) => {
        stopPropagation(e);        
        const NoteTypes = AppConstants.NoteTypes;
        switch (item.noteType) {
            case NoteTypes.Job:
                history.push('/jobprogressassets/' + item.entityId);
                break;
            case NoteTypes.Asset:
                history.push('/assetdetail/' + item.entityId);
                break;
            case NoteTypes.ManufacturerDescription:
                history.push('/manufacturerDescription/' + item.entityId);
                break;
            case NoteTypes.CustomerDescription:
                history.push('/customerDescription/' + item.entityId);
                break;
            case NoteTypes.Reportgeneration:
                history.push('/offlinezipstatus/');
                break;

                case NoteTypes.CustomerDescription:                  
                    break;
                case NoteTypes.AWO:                   
                    const data = {awoId: item.entityId};   
                    messages.channel('apply-assembly-levels').action('assign-data').post(data);
                    history.push('/assembly/');
                break;                   
            default:
                break;
        }
    }
  
    const handleMenuItemclick = (item, e) => {
        if (!isReadOnly()) {
            history.push('/notes/' + item.noteType + '/' + item.entityId);
        }
        else {
            handleEntityClick(item, e);
        }
    }

    const handleMarkAsRead = (item, e) => {
        stopPropagation(e);
        const notificationId = item.notificationId;
        ajax.post('notifications/markAsRead', notificationId).then(() => {
            setState(getInitialState());
            props.getNotificationsCallback();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    const handleReply = (e) => {
        stopPropagation(e);
        const showReplyBox = !state.showReplyBox;
        setState({
            ...state,
            replyText: '',
            showReplyBox: showReplyBox
        });
    }

    const handleReplySend = (item, e) => {
        stopPropagation(e);
        const parameter = {
            notificationId: item.notificationId,
            entityId: item.entityId,
            noteType: item.noteType,
            text: state.displayText,
            usersToNotify: [{ id: props.item.addedById, name: props.item.addedByName }, ...state.usersToNotify]
        };

        ajax.post('notifications/replyNotification', parameter).then(() => {
            setState(getInitialState());
            props.getNotificationsCallback();
        });
    }

    const mentionsInputChange = (text, usersToNotify, displayText) => {
        setState({
            ...state,
            usersToNotify: usersToNotify,
            replyText: text,
            displayText: displayText
        });
    }

    const item = props.item;
    const NoteTypes = AppConstants.NoteTypes;
    const entity = item.noteType == NoteTypes.Job ? 'Job' : item.noteType == NoteTypes.Asset ? 'Asset' : item.noteType == NoteTypes.ManufacturerDescription ? 'Manufacturer Description' : item.noteType == NoteTypes.CustomerDescription ? 'Customer Description' : '';
    const replyText = state.showReplyBox
        ? <div className='user-notification-reply m-1' onClick={e => stopPropagation(e)}>
            <div className='row no-gutters'>
                <div className='col-10 col-sm-11'><MentionsInput onChange={mentionsInputChange} data={LoginService.users || []} value={state.replyText} /></div>
                <div className='col-2 col-sm-1'><Button icon='fa-paper-plane' variant='primary' size='sm' className='send m-1' onClick={e => handleReplySend(item, e)} disabled={!state.displayText} /></div>
            </div>
        </div>
        : null;
    const replyClass = state.showReplyBox ? 'fa fa-times' : 'fa fa-reply';
    const replyBtnLabel = state.showReplyBox ? 'Close' : 'Reply'
    const replyButton = !isReadOnly() ? <GroupBadge pill={false} variant='info' icon={replyClass} className='reply m-1' onClick={(e) => handleReply(e)}>{replyBtnLabel}</GroupBadge> : null;
    const readButton = !isReadOnly() ? <GroupBadge pill={false} variant='primary' icon='fa fa-check' className='reply m-1' onClick={(e) => handleMarkAsRead(item, e)}>Read</GroupBadge> : null;
    const desc = !isReadOnly() ? 'You have a note from ' + item.addedByName + ' for the ' + entity : entity;
    const addedOn = DateTimeFormatter.formatDateTime(item.addedOn ? item.addedOn : DateTimeFormatter.now());

    return (
        <List.Item onClick={(e) => handleMenuItemclick(item, e)} >
            <div className='user-notification-header font-italic' >
                <span className='mr-1 text-wrap'>{desc}<Form.Link className='ml-1' onClick={(e) => handleEntityClick(item, e)} value={item.entityName} /></span>
            </div>
            <div className='user-notification-content d-flex align-items-center'>
                <span className='user-notification-note mt-1 text-wrap'>{item.notes}</span>
            </div>
            <div className='user-notification-footer d-flex justify-content-between'>
                <span className='user-notification-addedon font-weight-bold mt-1'>{addedOn}</span>
                <div className='d-flex align-items-end'>
                    {replyButton}
                    {readButton}
                </div>
            </div>
            {replyText}
        </List.Item>
    );
}

const NotificationBell = Disposable((props) => {

    const getInitialState = () => LoginService.userNotifications ? LoginService.userNotifications : [];
    const [notifications, setNotifications] = useState(getInitialState());
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const container = useRef(null);

    const getUsersNotifications = () => {
        ajax.get('notifications/getUserNotifications').then((userNotifications) => {

            LoginService.userNotifications = userNotifications;
            setNotifications(userNotifications);
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    useEffect(() => {
        props.using(messages.channel('userNotification').action('send').subscribe((data) => {
            if (data.userId && LoginService.loginInfo) {
                if (LoginService.loginInfo.userId == data.userId) {
                    getUsersNotifications();
                }
            }
        }));
        return () => {
            props.terminate();
        };
    }, []);

    const stopPropagation = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const handleMarkAllAsRead = (e) => {
        stopPropagation(e);
        ajax.post('notifications/markAllAsRead').then(() => {
            setNotifications(getInitialState());
            getUsersNotifications();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    const count = notifications.length || 0;
    const countBadge = count ? <Badge className={(count > 999 ? 'more ' : '') + 'notification-count text-truncate'} variant='danger'>{count > 999 ? '999+' : count}</Badge> : null;
    const noNotificationMenuItem = <List.Item disabled={true} className='no-notification'>No Notification</List.Item>;
    const items = notifications.map(item => <NotificationRow item={item} getNotificationsCallback={getUsersNotifications} />);
    const menuItems = count ? items : noNotificationMenuItem;
    const readAllButton = count ? <GroupBadge pill={false} icon='fa fa-check' variant='primary' className='m-2' onClick={(e) => handleMarkAllAsRead(e)}>Read All</GroupBadge> : null;

    return (
        <NotificationBellStyles ref={container}>
            <a ref={target} onClick={() => setShow(!show)} className='d-flex text-decoration-none'>
                <ActionIcon className='notification-bell' icon='fas fa-bell' style={{ fontSize: '1.8rem' }} size='' />
                {countBadge}
            </a>
            <Overlay target={target.current} show={show} placement='bottom' container={container.current} rootClose={true} onHide={() => setShow(false)}>
                <Popover>
                    <Popover.Content>
                        <div className='d-flex justify-content-end'>{readAllButton}</div>
                        {menuItems}
                    </Popover.Content>
                </Popover>
            </Overlay>
        </NotificationBellStyles>
    );
});

export default NotificationBell;