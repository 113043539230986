import React from 'react';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Keypad from '../../components/Keypad';
import Searchers from '../utility/Searchers';
import SignaturePad from '../../components/SignaturePad';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import SAPService from '../services/SAPService';
import AppConstants from '../utility/AppConstants';
import Roles from '../utility/Roles';

class InspectionFailedDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount() {
    this.initializeMixin();
    //this.props.controller.getInspectionNotes();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  handleUpdateSignature = (signature) => {
    this.props.controller.updateSignature(signature);
  }
  handleFileUpload = (res) => {
    this.props.controller.uploadFile(res);
  }
  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const isSAPFacility = SAPService.isSAPFacility();
    const isWeldRepair = controller.state.isWeldRepair;
    const searchController = controller.searchController;
    const errors = controller.state.errors;
    const selectedCount = controller.state.assets.length;
    const testName = controller.state.testName;
    const message = isWeldRepair
      ? <h5 className='col-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '10px' }}>Please confirm you wish to weld repair the {Utils.pluralize('selected asset', 's', selectedCount)} for the <span className='font-weight-bold text-primary'>{testName}</span> test</h5>
      : <h5 className='col-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '10px' }}>Please confirm you wish to scrap the {Utils.pluralize('selected asset', 's', selectedCount)} for the <span className='font-weight-bold text-primary'>{testName}</span> test</h5>
    const msg = 'Last chance to record time against ' + (selectedCount === 1 ? 'this ' : 'these ') + Utils.pluralize(' asset', 's', selectedCount, false);
    const rfidWarning = 'Note: any RFID Tag ID values will be removed from ' + (selectedCount === 1 ? 'this asset' : 'these assets') + ' when you save your changes';
    const value = binder.bind('inspectionMinutes')();
    const zeroTimeEnabled = !!controller.state.vendor;
    const facilityId = LoginService.loginInfo.facilityId;
    const fldTechnician = isSAPFacility
      ? <Form.AutoComplete className='col-sm-12' label='Technician' bind={binder.bind('technician')} search={Searchers.activeUsersForSapFacilityWithEmployeeId(facilityId)} />
      : <Form.AutoComplete className='col-sm-12' label='Technician' bind={binder.bind('technician')} search={Searchers.activeUsersForFacility(facilityId)} />;
    const notes = controller.state.assets.length === 1
      ? <div className='col-md-12'><Form.TextArea id='notes' label='Notes' bind={binder.bind('notes')} maxLength='1000' /></div>
      : null;
    const backDatingNote = LoginService.loginInfo.role === Roles.Coordinator
      ? <div className='col-md-12 backdating-note-message'>{AppConstants.BackDatingNote}</div>
      : null;
    const assetId = controller.state.assets[0].assetId;
    const rejectCodeName=controller.state.rejectCodeName;
    const customerName=controller.state.customerName;
    const formatResult = function (item) {
      let vendorBackgroundColor = '';
      if (item.result.type !== undefined) {
        switch (item.result.type.toLowerCase()) {
          case '3rd party':
            vendorBackgroundColor = 'success';
            break;

          case 'customer':
            vendorBackgroundColor = 'info';
        }
        if (vendorBackgroundColor !== '') {
          return <span>{item.text}<Badge variant={vendorBackgroundColor} className='result-span'>{item.result.type}</Badge></span>;
        }
        return <span>{item.text}</span>;
      }
      return <span>{item.text}</span>;
    };

    const familyCode = controller.state.assets[0].familyCodeId;

    // var showGuageDropdown = false;
    // if(testName === 'Mag Particle Inspection (MPI)' 
    // || testName === 'Thickness (UT)' 
    // || testName === 'Gauge' 
    // || testName === 'Pressure Test') {
    //   showGuageDropdown = true;
    // }

    const width = 650;
    const height = 620;
    const warningMessage = isWeldRepair
      ? null
      : <div className='err-msg'><i className='fas fa-exclamation-triangle fa-2x' /><span> Scrapping will prevent adding additional information</span></div>;
    const showDialogDetails = controller.getShowDetailFlag();
    // const upload            = <span className='pull-right' style={{ marginTop: '-1px' }}>
    //                      <DropZone onDrop={this.handleFileUpload} multiple={false} disabled={controller.adding | controller.state.isSaveInProgress} >
    //                        <span className='field-hint'></span>
    //                        &nbsp;
    //                          <Button type='file' variant='success' size='sm' className='' icon='fa-upload' disabled={controller.adding | controller.state.isSaveInProgress} >Upload</Button>
    //                      </DropZone>
    //                    </span>
    ;
    const failInspectionField = controller.state.isfailInspection
      ? <div className='row'>
        <div id='col-scrap-asset' className='col-md-6'><Form.CheckBox label='Scrap' bind={binder.bind('scrap')} /></div>
        <div id='col-fail-asset' className='col-md-6'><Form.CheckBox label='Fail Inspection' bind={binder.bind('failInspection')} /></div>
      </div>
      : null;
    const rejectReasonField = controller.state.isfailInspection && controller.state.failInspection
      ? null
      : <Form.AutoComplete focus={true} label='Reject Reason' bind={binder.bind('rejectReason')} error={errors.rejectReason} search={Searchers.rejectReasonSearch(familyCode)} className='required' />;

      const rejectCodeField = controller.state.isfailInspection && controller.state.failInspection
      ?null
      :customerName==='Halliburton'
      ?<Form.StaticData label='Reject Code' value={rejectCodeName} />
      :controller.state.canShowRejectCodeDD
      ?<Form.AutoComplete focus={true} label='Reject Code' bind={binder.bind('rejectCode')} error={errors.rejectCode} search={Searchers.rejectCodeSearch(assetId)} className='required' />
      :null;

    return (
      <Dialog {...this.props} allowKeyDownEvent={true} className='scrap-asset-dialog' header={this.props.header} modal={true} width={width} height={height} id='scrap-asset-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div className='row'>
              <div className='col-md-12 fail-inspection-field'>
                {failInspectionField}
              </div>
              {message}
              <div className='col-md-12'>
                <div className='row' style={{ height: '20rem' }}>
                  <div className='text-center col-md-5'>
                    {msg}
                    <div className='keypad-value my-2'>{value} min</div>
                    <Keypad className='inspection-keypad my-2' bind={binder.bind('inspectionMinutes')} allowDecimal={true} minValue={0} maxValue={720} enabled={!controller.state.recordZeroTime} />
                  </div>

                  {showDialogDetails && <div id='scrap-asset-right-div' className='col-md-7' style={{ borderLeft: 'solid 1px #D8D8D8', height: '20rem', overflowY: 'auto' }}>
                    <div className='row'>
                      <div className='col-md-12'>
                        {LoginService.isCoordinator && !SAPService.useTTS() &&
                          <div className='row'>
                            <div className='col-md-12'>{fldTechnician}</div>
                            <div className='col-md-12'><Form.Date label='Inspection Date:' bind={binder.bind('inspectionDate')} error={errors.inspectionDate} /></div>
                          </div>
                        }
                        {
                          // showGuageDropdown ? 
                          // <div className='col-sm-12' style={{marginTop: '20px'}}>                    
                          //   <Form.AutoComplete focus={true} label='Gauges / Part No' 
                          //   bind={binder.bind('guageSerPrim')} 
                          //   search={controller.guageSerPrimData()} />
                          // </div> 
                          // : null
                        }

                        <div className='row'>
                          <div className='col-md-12'>{rejectReasonField}</div>
                          <div className='col-md-12'>{rejectCodeField}</div>
                          <div className='col-md-12'><Form.Input label='Approved By' placeholder='please enter your name' className='required' bind={binder.bind('approverName')} error={errors.approverName} /></div>
                          <div className='col-md-12'><SignaturePad id='signature-pad' className='required' label='Approver Signature' onUpdateSignature={this.handleUpdateSignature} bind={binder.bind('approverSignature')} error={errors.approverSignature} width={330} height={150} showClear={true} /></div>
                          <div className='col-md-12 mt-3'><Form.AutoComplete formatResult={formatResult} formatSelection={formatResult} label='3rd party Vendor / Customer' hint='optional' bind={binder.bind('vendor')} search={searchController.vendorSearch()} /></div>
                          {!SAPService.useTTS() && <div className='col-md-12'><Form.CheckBox label='Record 0 time' bind={binder.bind('recordZeroTime')} enabled={zeroTimeEnabled} disabled={!zeroTimeEnabled} /></div>}
                          {/* {upload} */}
                          {notes}
                          {!SAPService.useTTS() && backDatingNote}
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
              {/* <div className='col-md-12 mt-1 text-left' style={{ borderTop: 'solid 1px #D8D8D8' }}>{rfidWarning}</div> */}
              <div className='col-md-12 mt-1 text-left font-weight-bold text-danger'>{warningMessage}</div>
            </div>
          </form>
          <div id='attachmentPopup' />
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={this.props.controller.saving}>
        </Dialog.SaveCancelButtons>
      </Dialog>
    );
  }
}

export default InspectionFailedDialog;
