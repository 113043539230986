import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';

class ReceivingTimeRowController extends BaseController {
  constructor(parentController, receivingTime) {
    this.key = ReceivingTimeRowController.__key++;
    super('receivingTime-row-controller-' + this.key, {
      technician              : receivingTime ? receivingTime.Technician          : '',
      tvalue                   : receivingTime ? receivingTime.Value          : '',
      transactionDate                 : receivingTime ? receivingTime.TransactionDate           : '',
      errors                  : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('TransactionDate', 'TransactionDate', [{required: true}]);
    this._validator.add('Technician', 'Technician', [{required: true}]);
    this._validator.add('Value', 'Value', [{required: true}]);
  }  

}

ReceivingTimeRowController.__key = 1;

export default ReceivingTimeRowController;