import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Validator from '../../lib/Validator';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AdditionalTimeRowController from './AdditionalTimeRowController';
import LoginService from '../services/LoginService';

class AdditionalTimeController extends BaseController {
    constructor() {
        super('additionalTime', AdditionalTimeController.getInitialState()); 
        this.loadAdditionalTime();
        this._validator = new Validator();
        this._validator.add('reason', 'Reason', [{ required: true }]);
    }

    static getInitialState = () => {
        return {
          additionalTimeData: [],
          addProdTimeData: [],
          hours: 0,
          minutes: 0,
          base: '',
          costCenter: '',
          reason: '',
          errors: {},
          saving: false
        }
      }

    addNewadditionalTimeRow = (additionalValue) => {
        this.state.additionalTimeData.push(new AdditionalTimeRowController(this, additionalValue));
        this.commit();
    }

    addNewaddProdTimeRow = (addProdValue) => {
        this.state.addProdTimeData.push(addProdValue);
        this.commit();
    }

    loadAdditionalTime = () => {
        ajax.post('manhours/getAdditionalTime', { TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()) }).then((useradditionalTime) => {
            if (useradditionalTime.length > 0) {
                useradditionalTime.forEach(additionalValue => this.addNewadditionalTimeRow(additionalValue));
                this.commit();
            }
            ajax.post('manhours/getAddProdTime', { TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()) }).then((useraddProdTime) => {
                if (useraddProdTime.length > 0) {
                    useraddProdTime.forEach(addProdValue => this.addNewaddProdTimeRow(addProdValue));
                    this.commit();
                }
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    canSave = () => {
        const s = this.state;
        this._validator.validateAll(this.state);
        var errors = Object.keys(s.errors);
        if (!this.state.base) {
            if (errors.length) {
                this.removeErrorWithValue(errors, 'costCenter');
            }
        }
        if (!errors.length) {
            return true;
        }

        this.commit();
        return false;
    }

    saveAdditionalValues = () => {
        if (!this.canSave()) {
            return false;
        }
        this.saving = true;
        this.commit();
        var loginInfo = LoginService.loginInfo;
        var hours = parseFloat(isNaN(this.state.hours) ? 0 : this.state.hours);
        var minutes = parseFloat(isNaN(this.state.minutes) ? 0 : this.state.minutes);
        var value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
        if (value < 1) {
            notification.action('error').post("Additional Production Time should be minimum 1 min");
            return false;
        }

        if (value <= 0) {
            notification.action('error').post("Additional Production Time should be greater than 0");
            return false;
        }
        if (parseFloat(value).toFixed(1) <= 0) {
            notification.action('error').post("After rounding to 1 decimal, time will become 0. Additional Production Time should be greater than 0");
            return false;
        }
        var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
        if (value > availableTime) {
            notification.action('error').post("Additional Production Time should not be more than Available Production Time");
            return false;
        }
        if (loginInfo.isBreakOut) {
            notification.action('error').post("Currently on Unpaid break");
            return false;
        }

        var parameters = {
            TechnicianId: loginInfo.userId,
            TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
            Value: value,
            reason: this.state.reason,
            baseId: this.state.base ? this.state.base.id : '',
            costCenterId: this.state.costCenter ? this.state.costCenter.id : ''
        };

        return ajax.post('manhours/saveAdditionalTime', parameters).then(() => {
            notification.action('success').post('Saved the additional Time');
            this.state = AdditionalTimeController.getInitialState();

            const errors = Object.keys(this.state.errors);
            document.getElementById('s2id_costCenter').classList.remove('required');
            if (errors.length) {
                this.removeErrorWithValue(errors, 'costCenter');
            }
            this.saving = false;
            this.commit();
            this.loadAdditionalTime();
            return false;
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
            return false;
        });

    }

    removeErrorWithValue = (errors, value) => {
        if (errors.indexOf(value) !== -1) {
            errors.splice(errors.indexOf(value), 1);
        }
    }

    removeErrorWithKey = (errors, key) => {
        delete errors[key];
    }

    baseChanged = () => {
        if (this.state.base) {
            this._validator.add('costCenter', 'CostCenter', [{ required: true }]);
            this.removeErrorWithKey(this.state.errors, 'costCenter');
            document.getElementById('s2id_costCenter').classList.add('required');
        }
        else {
            const errors = Object.keys(this.state.errors);;
            document.getElementById('s2id_costCenter').classList.remove('required');
            if (errors.length) {
                this.removeErrorWithValue(errors, 'costCenter');
            }
        }
        this.state.costCenter = '';
        this.commit();
    }
}

export default AdditionalTimeController;