import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';

class EndConfigurationRowController extends BaseController {
  constructor(parentController, endConfig, manDescId) {
    super('end-configuration-row-controller-' + EndConfigurationRowController.key++, {
      manDescId         : manDescId,
      manDescConfigId   : endConfig ? endConfig.manDescConfigId   : Utils.emptyGuid,
      configurationId   : endConfig ? endConfig.configurationId   : '',
      endConnectionSize : endConfig ? endConfig.endConnectionSize : '',
      endConfiguration  : endConfig ? endConfig.endConfiguration  : '',
      endConnection     : endConfig ? endConfig.endConnection     : '',
      errors            : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('configurationId',   'ID',                  [{required: true}]);
    this._validator.add('endConnectionSize', 'End Connection Size', [{required: true}]);
    this._validator.add('endConfiguration',  'End Configuration',   [{required: true}]);
    this._validator.add('endConnection',     'End Connection',      [{required: true}]);
  }

  static key = 1;

  canRemoveRow = () => {
    return true;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeEndConfigurationRow(this);
  }
}
    
export default EndConfigurationRowController;
  