import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Searchers from '../utility/Searchers';
import RejectReasonController from '../controllers/RejectReasonController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import Search from '../components/Search';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';

const Item = (props) => {
  const toggleSelection = (item) => {
    if (!LoginService.isAdministrator) {
      return;
    }
    props.controller.toggleSelection(item);
  }

  const selectAndTransfer = (item) => {
    if (!LoginService.isAdministrator) {
      return;
    }
    props.controller.selectAndTransfer(!!props.isSource, item);
  }

  const item = props.item;
  let className = 'item';
  if (item.selected) {
    className += ' active';
  }

  return (
    <List.Item className={'list-group-item p-2 ' + className} onClick={() => { toggleSelection(item); }} onDoubleClick={() => { selectAndTransfer(item); }} >
      {item.name}
    </List.Item>
  );
}

const SourceItems = (props) => {

  const handleKeyboardFilter = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.filter();
  }

  const handleMouseSearch = () => {
    props.controller.filter();
  }

  const clearFilter = () => {
    props.controller.clearFilter();
  }

  const selectNone = () => {
    props.controller.selectNoSourceItems();
  }

  const binder = props.binder;
  const controller = props.controller;
  const sourceItems = controller.state.sourceItems;
  const filterText = controller.state.filterText;
  const filteredItems = sourceItems
    .filter(item => !filterText || (filterText && Utils.contains(item.name, filterText)))
    .map(item => <Item key={item.id} controller={controller} item={item} isSource={true} />);
  const totalCount = sourceItems.length;
  const matchesCount = filteredItems.length;
  const matchesLabel = (matchesCount === totalCount)
    ? Utils.pluralize('match', 'es', matchesCount)
    : matchesCount + ' / ' + totalCount + ' matches';
  const canSelect = LoginService.isAdministrator;

  return (
    <div className='row'>
      <div className='col-md-12 mb-2'>
        {canSelect && <Button icon='far fa-square' variant='danger' size='sm' className='' onClick={selectNone}>Select None</Button>}
      </div>
      <div className='col-md-12'>
        <Search className='mb-2' id='filterText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='filter text' autoFocus='true' bind={binder.bind('filterText')} clear={clearFilter} search={handleMouseSearch}></Search>
      </div>
      <div className='col-md-12'>
        <div className='d-flex justify-content-between mt-1'>
          <h5>Available</h5>
          <div><Badge variant='info'>{matchesLabel}</Badge></div>
        </div>
      </div>
      <div className='col-md-12'>
        <Jumbotron className='list-container'>
          <div className='source-list'>
            <List className='list-group'>{filteredItems}</List>
          </div>
        </Jumbotron>
      </div>
    </div>
  );
}

const SelectedItems = (props) => {

  const selectNone = () => {
    props.controller.selectNoSelectedItems();
  }

  const selectAll = () => {
    props.controller.selectAllSelectedItems();
  }

  const controller = props.controller;
  const selectedItems = controller.state.selectedItems;
  const selectedLabel = selectedItems.length; // + ' selected';
  let title = 'Selected';
  if (controller.state.selectedFamilyCode) {
    title += ' for ' + controller.state.selectedFamilyCode.name;
  }
  const canSelect = LoginService.isAdministrator;

  return (
    <div className='row'>
      <div className='col-md-12'>
        {canSelect && <div> <Button icon='fa-square' variant='success' size='sm' className='mr-1' onClick={selectAll}>Select All</Button>
          <Button icon='far fa-square' variant='danger' size='sm' className='mr-1' onClick={selectNone}>Select None</Button></div>}
      </div>
      <div className='col-md-12'>
        <div className='d-flex justify-content-between mt-1'>
          <h5>{title}</h5>
          <div><Badge variant='info'>{selectedLabel}</Badge></div>
        </div>
      </div>
      <div className='col-md-12'>
        <Jumbotron className='list-container'>
          <div className='selected-list'>
            <List className='list-group'>{
              selectedItems.map(item => {
                return <Item controller={controller} item={item} isSource={false} />;
              })
            }</List>
          </div>
        </Jumbotron>
      </div>
    </div>
  );
}

const TransferButtons = (props) => {

  const select = () => {
    props.controller.moveToSelected();
  }

  const unselect = () => {
    props.controller.removeFromSelected();
  }

  const enabled = props.controller.canTransfer();

  return (
    <div className='row m-2'>
      <Button icon='fa-arrow-right' variant='success' size='sm' className='btn-block' onClick={select} disabled={!enabled}>Select</Button>
      <Button icon='fa-arrow-left' variant='danger' size='sm' className='btn-block' onClick={unselect} disabled={!enabled} >Unselect</Button>
    </div>
  );
}

const Buttons = (props) => {
  const handleSave = () => {
    props.controller.save();
  }

  return (
    <div className='col-md-12'>
      <div className='d-flex justify-content-end'>
        <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-2' onClick={handleSave} disabled={props.controller.state.isSaving} style={{ width: '150px' }} >Save</Button>
      </div>
    </div>
  );
}

const RejectReasonPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-thumbs-down' />

class RejectReasonPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.loadAvailableRejectReasons();
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new RejectReasonController()
  }

  clearFilter = () => {
    this.props.controller.clearFilter();
  }

  render = () => {
    var controller = this.props.controller;
    var binder = new Binder(this);

    return (
      <Page {...this.props} pageTitle='InteServ · Reject Reason Mapping' id='reject-reason-page'>
        <RejectReasonPageHeader pageTitle={'Reject Reason Mapping'} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-6'>
              <Form.AutoComplete label='Family Code' prependLabelIcon='fa fa-filter' className='required' readOnly={false} bind={binder.bind('selectedFamilyCode')} search={Searchers.listSearch('family code')} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row align-items-center'>
                <div className='col-md-5'>
                  <SourceItems controller={controller} binder={binder} />
                </div>
                <div className='col-md-2'>
                  {LoginService.isAdministrator && <TransferButtons controller={controller} />}
                </div>
                <div className='col-md-5'>
                  <SelectedItems controller={controller} binder={binder} />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            {LoginService.isAdministrator && <Buttons controller={controller} />}
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default RejectReasonPage;
