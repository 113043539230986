import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class ProfitCostCenterRowController extends BaseController {
  constructor(parentController, profitCostCenter) {
    super('profit-cost-center-row-controller' + ProfitCostCenterRowController.key++, {
      profitCostCenterId  : profitCostCenter ? profitCostCenter.profitCostCenterId                : Utils.emptyGuid,
      profitCenter        : profitCostCenter ? profitCostCenter.profitCenter                      : '',
      costCenter          : profitCostCenter ? profitCostCenter.costCenter                        : '',
      shortText           : profitCostCenter ? profitCostCenter.shortText                         : '',
      longText            : profitCostCenter ? profitCostCenter.longText                          : '',      
      accountingIndicator : profitCostCenter ? { id:   profitCostCenter.accountingIndicatorId,
                                                 name: profitCostCenter.accountingIndicatorName}  : '',
      jobType             : profitCostCenter ? { id:   profitCostCenter.jobTypeId, 
                                                 name: profitCostCenter.jobTypeName }             : '',
      canDelete           : profitCostCenter ?  profitCostCenter.canDelete : true,                                                  
      errors              : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('profitCenter',       'Profit Center',           [{required: true}]);
    this._validator.add('costCenter',         'Cost Center',             [{required: true}]);
    this._validator.add('shortText',          'Short Text',              [{required: true}]);
    this._validator.add('longText',           'Long Text',               [{required: true}]);
    this._validator.add('jobType',             'Job Type',               [{required: true}]);
    this._validator.add('accountingIndicator', 'Accounting Indicator',   [{required: true}]);
  }

  static key = 1;

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

  canRemoveRow = () => {
    return this.state.canDelete;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeProfitCostCenterRow(this);
  }
}
  
export default ProfitCostCenterRowController;
