import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import AppConstants from '../utility/AppConstants';

class AwoNotesController {
  constructor(parent) {
    this.parent = parent;
    this.awo = '';
    this.notes = [];

    const self = this;
    messages.channel('awo-status').action('updated').subscribe(data => {  
      const awo = self.awo;
      if (!awo || (awo.id !== data.awoId)) {
       
        return;
      }
      notification.action(data.message.action).post(data.message);
      self.loadNotes(awo.id, true);
    });
  }

  getNotes = () => {
    return this.notes;
  }

  getTitle = () => {
    const awo = this.awo;
    return 'AWO Notes — ' + (awo && awo.awoNumber);
  }

   //TODO : Create method in controller
  load = (awoId) => {
    ajax.get('lookup/awo/getawo', { awoId: awoId }).then(awo => {
      this.awo = awo;
      this.parent.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // JobLookupDto
  }

  //TODO : Create method in controller
  loadNotes = (awoId, flashNewNote) => {
    ajax.get('lookup/awo/getawoNotes/', { awoId: awoId }).then(results => {
      this.notes = results;
      this.parent.commit();

      if (flashNewNote) {
        const newNote = results[results.length - 1];
        if (newNote.noteId != 'undefined')
          this.parent.flashNote(newNote.noteId);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<NoteDto>
  }

  canAddNotes = () => {
    return ((LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep) || (LoginService.isTechnician));
  }

  addNote = (text, usersToNotify) => {
    const awoId = this.awo.id;
    const parameters = {
      entityId: awoId,
      noteType: AppConstants.NoteTypes.AWO,
      text: text,
      usersToNotify: usersToNotify
    };
    this.parent.state.saveInProgress = true;
    this.parent.commit();

    
    ajax.post('awoprogess/addNote', parameters).then(() => {
      this.loadNotes(awoId, true);
      this.parent.clearNoteText();
      this.parent.state.saveInProgress = false;
      this.parent.commit();
    }).catch(err => {
      this.parent.state.saveInProgress = false;
      this.parent.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: AddNoteDto, out: void
  }
}

export default AwoNotesController;