import React from 'react';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Searchers from '../utility/Searchers';
import SignaturePad from '../../components/SignaturePad';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';

class InspectionNotPerformedDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount() {
    this.initializeMixin();
    //this.props.controller.getInspectionNotes();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  handleUpdateSignature = (signature) => {
    this.props.controller.updateSignature(signature);
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const isSAPFacility = SAPService.isSAPFacility();
    const testName = controller.state.testName;
    const selectedCount = controller.state.selectedAssets.length;
    const errors = controller.state.errors;
    const message = <h5 className='col-md-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '5px' }}>Please confirm you wish to NOT PERFORM the <span className='font-weight-bold text-primary'>{testName}</span> operation for the {Utils.pluralize('selected asset', 's', selectedCount, false)}</h5>;
    const height = LoginService.isCoordinator ? 600 : 515;
    const facilityId = LoginService.loginInfo.facilityId;
    const fldTechnician = isSAPFacility
      ? <Form.AutoComplete label='Technician' bind={binder.bind('technician')} search={Searchers.activeUsersForSapFacilityWithEmployeeId(facilityId)} />
      : <Form.AutoComplete label='Technician' bind={binder.bind('technician')} search={Searchers.activeUsersForFacility(facilityId)} />
    const notes = controller.state.selectedAssets.length === 1
      ? <Form.TextArea id='notes' label='Notes' bind={binder.bind('notes')} maxLength='1000' />
      : null;

    return (
      <Dialog {...this.props} allowKeyDownEvent={true} className='not-performed-dialog' header={this.props.header} modal={true} width={600} height={height} id='confirm-inspection-results-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='not-performed-dialog-content'>
            <form role='form'>
              <div className='row'>
                {message}
                <div className='col-md-7'>{fldTechnician}</div>
                <div className='col-md-5'><Form.Date display='inline-block' label='Inspection Date:' bind={binder.bind('inspectionDate')} error={errors.inspectionDate} /></div>
                <div className='col-md-12'><Form.Input label='Approved By' placeholder='please enter your name' className='required' bind={binder.bind('approverName')} error={errors.approverName} /></div>
                <div className='col-md-12'><SignaturePad id='signature-pad' className='required' width={250} height={150} label='Approver Signature' onUpdateSignature={this.handleUpdateSignature}  bind={binder.bind('approverSignature')} error={errors.approverSignature} showClear={true} /></div>
                <div className='col-md-12'>{notes}</div>
              </div>
            </form>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
      </Dialog>
    );
  }
}

export default InspectionNotPerformedDialog;
