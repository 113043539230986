import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import Table from '../../components/Table';

class AssetsAlreadyInJobRow extends React.Component{

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

    render = () => {
      const controller   = this.props.controller;
      const binder       = new Binder(this);
      const state        = controller.state;
      const selectColumn =  LoginService.isCoordinator 
                              ? <Table.Data className='col-select' >
                                  <Form.CheckBox bind={binder.bind('isSelected')} />
                                </Table.Data> 
                              : null;
        return (
          <Table.Row>
            {selectColumn}
            <Table.Data className='col-assets'>{state.primarySerialNumber}</Table.Data>
            <Table.Data className='col-description'>{state.description}</Table.Data>
          </Table.Row>
        )
    }
}

export default AssetsAlreadyInJobRow;