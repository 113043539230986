import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import InspectionGaugesRowController from './InspectionGaugesRowController';
import AppConstants from '../utility/AppConstants';
import $ from 'jquery';
class InspectionGaugesController extends BaseController {
  constructor(jobId, testName, assets, jobStatus, isNA) {
    super('inspection-gauges', {
      inspectionGaugeGUID: [],
      jobId: jobId,
      testName: testName,
      assets: assets,
      jobStatus: jobStatus,
      errors: {},
      rows: [],
      selectedSerialNumbers: '',
      assetJSONArray: assets && assets.length > 1 ? [{ 'id': 1, 'name': 'All Selected Primary Serial Numbers' }] : [],
      primrySerNumber: assets && assets.length == 1 && {id: 0 , name: assets[0].primarySerialNumber},
      isNotApplicable: isNA,
      disableSave:false
    });

    this.isEditMode = false;
    this.isLoading = false;
    this._validator = new Validator();
  }

  validateRowValues(state) {
    Utils.filterDeletedRecords(state.rows).forEach(row => row.validate());
  }

  load() {
    const s = this.state;
    co(this, function* () {
      try {
        const results = yield ajax.get('lookup/getInspectionGuageValues', { jobSOWIds: s.assets.map(x => x.jobSOWId), testName: s.testName });
        if (results != null && results.length > 0) {
          (results).forEach(result => {
            if (result) {
              this.state.inspectionGaugeGUID.push({ inspectionGaugeGUID: result.inspectionGaugeGUID, jobSOWId: result.jobSOWId });
              this.state.isNotApplicable = result.isNotApplicable;
              result.gaugeValues.forEach(row => {
                var assetName = s.assets.find(x => x.jobSOWId == result.jobSOWId).primarySerialNumber;
                const gaugeValueRow = {
                  gaugeRowGUID: row.gaugeRowGUID,
                  psn: assetName,
                  serialNumber: { id: row.serialNumberGUID, name: row.serialNumberName },
                  description: row.description,
                  calibrationDate: DateTimeFormatter.formatDate(row.calibrationDate),
                  expiryDate: DateTimeFormatter.formatDate(row.expiryDate),
                  recordStatus: row.recordStatus,
                  jobStatus: this.state.jobStatus,
                  assetStatus: this.state.assets[0].sapStatus
                }
                this.addNewRowData(gaugeValueRow);
              });

              if (result.gaugeValues.length) {
                this.isEditMode = true;
              }
              //this.getAddedRowsGaugeId();
            }
          });
        }
      }
      catch (err) {
        notifications.action('error').post({ msg: err.message, title: 'Error loading gauge values' });
      }

    });
  }

  handleSearch = () => {
    return this.state.assetJSONArray[0];
  }

  canSave() {
    this._validator.validateAll(this.state);
    this.validateRowValues(this.state);
    var errors = Object.keys(this.state.errors);
    const rows = Utils.filterDeletedRecords(this.state.rows);
    var rowErrors = rows.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!errors.length && !rowErrors) {
      this.state.errors = {};
      return true;
    }

    this.commit();
    return false;
  }

  *save() {
    const s = this.state;
    const rows = Utils.filterDeletedRecords(s.rows);   
    if(rows.length === 0 && !s.isNotApplicable){
      notifications.action('error').post({ msg: 'Please add at least one row' });
      return false;
    }
    if (!this.canSave()) {
      return false;
    }
    for (let i = 0; i < rows.length; i++) {
      // if (s.rows[i].state.calibrationDate && (DateTimeFormatter.getMoment(s.rows[i].state.calibrationDate).format('YYYYMMDD') > DateTimeFormatter.getMoment(s.rows[i].state.expiryDate).format('YYYYMMDD'))){
      if (rows[i].state.calibrationDate && (DateTimeFormatter.getMoment(rows[i].state.calibrationDate).format('YYYYMMDD') <= DateTimeFormatter.now().format('YYYYMMDD'))) {
        const message = 'Serial ' + rows[i].state.serialNumber.name + ' calibration date is past due';
        notifications.action('warning').post({ msg: message });
        return false;
      }
      if (!rows[i].state.calibrationDate) {
        const message = 'Serial ' + rows[i].state.serialNumber.name + ' cannot be added to ' + this.state.testName + ' due to no calibration date';
        notifications.action('error').post({ msg: message });
        return false;
      }
    }
    try {
      //disable save button
      this.state.disableSave= true;
      this.commit();
      const parameters = [];
      s.assets.forEach(function (asset, i) {
        const gauge = s.inspectionGaugeGUID.find(gauge => { return gauge.jobSOWId == asset.jobSOWId });
        const listValues = {
          jobSOWId: asset.jobSOWId,
          inspectionGaugeGUID: gauge ? gauge.inspectionGaugeGUID : null,
          gaugeValues: s.rows.filter(r => r.state.psn == asset.primarySerialNumber || r.state.psn === 'All Selected Primary Serial Numbers')
            .map(row => {
              return {
                gaugeRowGUID: row.state.gaugeRowGUID,
                serialNumberGUID: row.state.serialNumber ? row.state.serialNumber.id : '',
                recordStatus: row.state.recordStatus
              }
            }),
          testName: s.testName,
          isNotApplicable: s.isNotApplicable 
        }
        parameters.push(listValues);
      });
      const result = yield ajax.post('inspection/saveInspectionGaugeValues', parameters);
      let message = 'successfully';
      message = this.isEditMode ? 'Updated ' + message : 'Saved ' + message;
      notifications.action('success').post(message);
      var assetIds = s.assets.map(a => a.assetId);
      messages.channel('inspection').action('saved').post({ jobId: s.jobId, assetIds: assetIds });
      return result;
    }
    catch (err) {
      let message = err.message;
      message = Utils.contains(message, 'unique value') ? message.split('An entry')[0].trim() : message;
      notifications.action('error').post({ msg: message, title: 'Error saving gauge values' });
      //enable save button  
      this.state.disableSave= false;
      this.commit();
    }
  }


  addNewRowData(row) {
    this.state.rows.push(new InspectionGaugesRowController(this, row));
    this.commit();
  }

  constructJSONObject() {
    const assets = this.state.assets;
    var assetJSON = {};
    let i = 2;
    assets.forEach(asset => {
      if (this.state.assetJSONArray.find(item => item.name == asset.primarySerialNumber)) {
        return;
      }
      assetJSON.id = i;
      assetJSON.name = asset.primarySerialNumber;
      this.state.assetJSONArray.push({ ...assetJSON });
      ++i;
    });
    this.commit();
  }

  getAddedRowsGaugeId() {
    var gaugeIdString = '';
    var gaugeIds = [];
    const selectedAsset = this.state.primrySerNumber && this.state.primrySerNumber.name;  
    
    if (this.state.primrySerNumber && this.state.primrySerNumber.id != '1') {     
      gaugeIds = this.state.rows.filter(x => x.state.serialNumber && x.state.serialNumber.id != undefined && x.state.psn === selectedAsset && x.state.recordStatus !== AppConstants.RecordStatus.Deleted).map(x => x.state.serialNumber.id);
    } else {
      gaugeIds = this.state.rows.filter(x => x.state.serialNumber && x.state.serialNumber.id != undefined).map(x => x.state.serialNumber.id);
    }
    gaugeIds.forEach(function (gaugeId) {
      gaugeIdString = gaugeIdString + ',\'' + gaugeId + '\'';
    });
    return gaugeIdString;
  }

  updateDeletedRowStatus(serialNumber) {
    if (this.state.primrySerNumber?.name === 'All Selected Primary Serial Numbers') {
      let rows = this.state.rows;
      this.state.rows = rows.map(row => {
        if (row.state.serialNumber?.id == serialNumber.id) {
          row.state.recordStatus = AppConstants.RecordStatus.Deleted;
        }
        return row;
      })
    }
  }

  removeRow(data) {
    var idx = this.state.rows.indexOf(data);
    if (idx === -1) {
      return;
    }
    
    this.state.rows.splice(idx, 1);
    this.commit();    
  }

  serialNumbersSearch(facilityId) {
    const self = this;
    var selectedSerNumbers = this.getAddedRowsGaugeId();    
    return function* (searchTerm) {
      self.isLoading = true;
      self.commit();
      var parameters = {
        searchTerm: searchTerm,
        facilityGUID: facilityId,
        selectedSerialNumbers: selectedSerNumbers.length ? selectedSerNumbers : '',
        isExclusive : true
      };
      var results = yield ajax.get('lookup/getSerialNumbers', parameters);
      self.isLoading = false;
      self.commit();
      return results;
    };
  }

}

export default InspectionGaugesController;