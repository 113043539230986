import Immutable from 'immutable';
import Moment from 'moment';

const validators = {
    required: {
        isValid() {
            return ((this.value !== undefined) && (this.value !== null) && (this.value !== ''));
        },
        message: '{name} is required'
    },

    regex: {
        isValid(regex) {
            return new RegExp(regex).test(this.value);
        },
        message: '{name} is invalid'
    },

    isNull(number) {
        if (number === -1) {
            return true;
        }
        return ((number === undefined) || (number === null));
    },

    isNumeric: {
        isValid() {
            return (!isNaN(parseFloat(this.value)) && isFinite(this.value));
        },
        message: '{name} must be a number'
    },

    minValue: {
        isValid(min) {
            return (this.value >= min);
        },
        message: '{name} must be >= {args[0]}'
    },

    maxValue: {
        isValid(max) {
            return (this.value <= max);
        },
        message: '{name} must be <= {args[0]}'
    },

    minLength: {
        isValid(minChars) {
            if (validators.isNull(this.value)) {
                return false;
            }
            return (this.value.length >= minChars);
        },
        message: '{name} must be at least {args[0]} characters'
    },

    maxLength: {
        isValid(maxChars) {
            if (validators.isNull(this.value)) {
                return false;
            }
            return (this.value.length <= maxChars);
        },
        message: '{name} must be at most {args[0]} characters'
    },

    isLessThan: {
        isValid(other) {
            if (validators.isNull(this.value) || validators.isNull(other.value)) {
                return true;
            }
            return (this.value < other.value);
        },
        message: '{name} must be less than {args[0].name}'
    },

    isLessThanEqual: {
        isValid(other) {
            if (validators.isNull(this.value) || validators.isNull(other.value)) {
                return true;
            }
            return (this.value <= other.value);
        },
        message: '{name} must be less than or equal to {args[0].name}'
    },

    inRange: {
        isValid(range) {
            if (validators.isNull(this.value) || validators.isNull(range)) {
                return true;
            }
            return ((this.value >= range.min) && (this.value <= range.max));
        },
        message: '{name} must be a number from {args[0].min} - {args[0].max}'
    },

    requires: {
        isValid(other) {
            if (validators.isNull(this.value)) {
                return validators.isNull(other.value);
            }
            if (validators.isNull(other.value)) {
                return false;
            }
            return true;
        },

        message: '{name} and {args[0].name} are both required'
    },

    isGreaterThan: {
        isValid(other) {
            if (validators.isNull(this.value) || validators.isNull(other.value)) {
                return true;
            }
            return (this.value > other.value);
        },
        message: '{name} must be greater than {args[0].name}'
    },

    isGreaterThanEqual: {
        isValid(other) {
            if (validators.isNull(this.value) || validators.isNull(other.value)) {
                return true;
            }
            return (this.value >= other.value);
        },
        message: '{name} must be greater than or equal to {args[0].name}'
    },

    mustSelect: {
        isValid() {
            return (this.value.length > 0);
        },
        message: 'You must select at least one {name}'
    },

    isGreaterThanEqualsDate: {
        isValid(otherDate) {
            if (validators.isNull(this.value)) {
                return false;
            }
            return (Moment(this.value).startOf('day').format() >= Moment(otherDate).startOf('day').format());
        },
        message: '{name}'
    },

    isLessThanEqualsDate: {
        isValid(otherDate) {
            if (validators.isNull(this.value)) {
                return false;
            }
            var isLessThanEquals = (Moment(this.value).startOf('day').format() <= Moment(otherDate).startOf('day').format());
            return isLessThanEquals;
        },
        message: '{name}'
    }
};

class Validator {
    constructor() {
        this._validations = {};
    }

    add(name, displayName, validations) {
        var obj = this._validations[name];

        if (!obj) {
            obj = this._validations[name] = {};
        }

        obj.name = name;
        obj.displayName = displayName;
        obj.validations = validations;
    }

    remove(name) {
        delete this._validations[name];
    }

    removeAll() {
        this._validations = {};
      }

    get(name) {
        return this._validations[name];
    }

    validateAll(obj, errFn) {
        if (!errFn) {
            errFn = this.setError;
        }

        Immutable.Map(obj).forEach((v, k) => {
            var validation = this.get(k);
            if (!validation || !validation.validations) {
                return;
            }
            this.validate(obj, k, errFn);
        });
    }

    setError(obj, name, message) {
        if (!obj) {
            return;
        }

        if (message === null) {
            // clear existing error messages
            if (obj.errors) {
                delete obj.errors[name];
            }
            return;
        }
        // add error message for 'name'
        // note: this adds the 'errors' property to the passed obj
        // the caller (of validate / validateAll) will reference .errors to process them after the call (to validate / validateAll)
        if (!obj.errors) {
            obj.errors = {};
        }
        obj.errors[name] = message;
    }

    validate(obj, name, errFn) {
        if (!errFn) {
            errFn = this.setError;
        }

        var validation = this.get(name);
        errFn(obj, name, null);
        if (!validation || !validation.validations) {
            return;
        }

        var ctx = { name: name, value: obj[name] };

        for (var i = 0; i < validation.validations.length; i++) {
            var v = validation.validations[i];
            for (var prop in v) {
                var validatorFn = validators[prop].isValid;
                var message = v.message || validators[prop].message;
                var args = [];
                var params = v[prop].params;
                ctx.name = validation.displayName || name;
                if (params || (params === 0)) {
                    if (params.length) {
                        for (var j = 0; j < params.length; j++) {
                            var paramName = params[j];

                            var paramValidator = this.get(params[j]);
                            if (paramValidator) {
                                paramName = paramValidator.displayName;
                            }
                            args[j] = { name: paramName, value: obj[params[j]] };
                        }
                    } else {
                        args = [params];
                    }
                }

                if (!validatorFn.apply(ctx, args)) {
                    ctx.args = args;
                    errFn(obj, name, message.interpolate(ctx));
                    return;
                }
            }
        }
    }
}

export default Validator;