import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import AssetsAlreadyInJobRow from '../pages/AssetsAlreadyInJobRow';
import LoginService from '../services/LoginService';
import Table from '../../components/Table';


class AssetsAlreadyInJobDialogBody extends React.Component {


  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
    // const assets = this.props.assetList;
    // this.props.controller.load(assets);
  }

  render = () => {
    const controller = this.props.controller;
    const message = LoginService.isCoordinator ? null : <div style={{ paddingTop: 10 }}>The following asset(s) were already assigned to a job. Please contact the Coordinator.</div>
    const selectColumn = LoginService.isCoordinator ? <Table.Head className='col-select' /> : null;
    const rows = controller.state.assets.map((asset, index) => <AssetsAlreadyInJobRow key={index + 1} controller={asset} />);

    return (
      <Dialog.Body>
        <div className='assets-already-in-job-container'>
          {message}
          <Table id='assets-already-in-job'>
            <Table.Header>
              <Table.Row>
                {selectColumn}
                <Table.Head className='col-assets'>Asset</Table.Head>
                <Table.Head className='col-description'>Description</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows}
            </Table.Body>
          </Table>
        </div>
      </Dialog.Body>
    );
  }
}

export default AssetsAlreadyInJobDialogBody;
