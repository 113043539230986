import React, { useMemo, useEffect } from 'react';
import $ from 'jquery';
import ActionIcon from '../../components/ActionIcon';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import PageHeader from '../components/PageHeader';
import BillOfMaterialController from '../controllers/BillOfMaterialController';
import Authentication from '../services/authentication';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';

const ItemDetails = (props) => {
  const handleNew = () => {
    props.controller.newItem();
    $('#bomName').focus();
  }

  const handleSave = () => {
    props.controller.save();
  }

  const handleKeyDown = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.save();
  }

  const binder = props.binder;
  const errors = props.controller.state.errors;
  const isSuperUser = LoginService.isInRole(Roles.SuperUser)
  
  return (
    <div>
      <div><Button icon='fa-plus-circle' variant='success' size='sm' className='btn-action action-button' onClick={handleNew} disabled={isSuperUser}>Create New ...</Button></div>

      <div style={{ marginTop: '10px' }} onKeyDown={handleKeyDown}>
        <Form.Input label='Name' bind={binder.bind('name')} error={errors.name} className='required' id='bomName' />
        <Form.Input label='Description' bind={binder.bind('description')} error={errors.description} className='required' />
      </div>

      <div className='float-right'><Button icon='fa-save' variant='primary' size='sm' className='btn-action action-button' onClick={handleSave} disabled={props.controller.state.isSaving || isSuperUser}>Save</Button></div>
    </div>
  );
}


const ResultsList = (props) => {
  const handleRowClick = (item) => {
    props.controller.selectItem(item);
  };

  const controller = props.controller;
  const data = controller.state.searchResults || [];
  const loadingMessage = controller.state.isLoading && 'Loading BOMs that meet the search criteria defined above';
  const noResultsMessage = 'Click New to create a new BOM';

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
    ],
    []
  )
  return (
    <div id='bom-desc-results'>
      <Grid id='results-table' onRowClick={handleRowClick} showPagination={false} columns={columns} enableDefaultRowSelect={true} data={data} noDataText={loadingMessage || noResultsMessage} />
    </div>
  );
}

const BillOfMaterialPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fas fa-sort-numeric-down' />

class BillOfMaterialPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.load();
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new BillOfMaterialController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);

    return (
      <Page {...this.props} pageTitle='InteServ · BOM' id='bom-page'>
        <BillOfMaterialPageHeader pageTitle='BOM Management' />
        <Page.Content>
          <div className='row'>
            <div {...this.props} className='col-sm-8 col-md-8'>
              <ResultsList controller={controller} binder={binder} />
            </div>
            <div className='col-sm-4 col-md-4'>
              <ItemDetails controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default BillOfMaterialPage;
