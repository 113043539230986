import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import ConfigureCustomerController from '../controllers/ConfigureCustomerController';
import AppConstants from '../utility/AppConstants';
import Searchers from '../utility/Searchers';

const SearchHeader = (props) => {
    const binder = props.binder;
    const controller = props.controller;
    const errors = controller.state.errors;

    return (
        <div>
            <div id='search-criteria' className='row'>
                <div className='col-12'>
                    {/* TODO: Check how a lable is binded in other places of the app and implemente */}
                    <span>{binder.bind('customerName')} </span>
                </div>
                <div className='col-md-3'><Form.AutoComplete label='Rule' bind={binder.bind('eqType')} data={AppConstants.EqTypes} error={errors.eqType} className='required' /></div>
                <div className='col-md-3'><Form.AutoComplete label='Family Code' bind={binder.bind('familyCode')} search={controller.search('family code')} error={errors.familyCode} className='required' /></div>
                <div className='col-md-3'><Form.AutoComplete label='Nominal Size' bind={binder.bind('nominalSize')} search={controller.search('nominal size')} /></div>
                <div className='col-md-3'><Form.AutoComplete label='Length' bind={binder.bind('length')} search={controller.search('length')} /></div>
                <div className='col-md-3'><Form.AutoComplete label='Style' bind={binder.bind('style')} search={controller.search('style')} /></div>

                <div className='col-md-3'><Form.AutoComplete label='Manufacturer' bind={binder.bind('manufacturer')} search={controller.search('manufacturer')} /></div>
                <div className='col-md-3'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={controller.search('cwp')} /></div>
                <div className='col-md-3'><Form.AutoComplete label='Service' bind={binder.bind('service')} search={controller.search('service')} /></div>
                <div className='col-md-3'><Form.AutoComplete label='Degree' bind={binder.bind('degree')} search={controller.search('degree')} /></div>

                <div className='col-md-3'><Form.AutoComplete label='ID' bind={binder.bind('id')} search={controller.search('id')} /></div>
                <div className='col-md-3'><Form.AutoComplete label='OD' bind={binder.bind('od')} search={controller.search('od')} /></div>
                <div className='col-md-3'><Form.AutoComplete label='Mfg Part #' bind={binder.bind('mfgItemNo')} search={controller.mfgItemSearch()} /></div>
                <div className='col-md-3'><Form.AutoComplete label='EndConfiguration' bind={binder.bind('endConfiguration')} search={controller.search('end configuration')} /></div>
            </div>
        </div>
    );
}

const Buttons = (props) => {

    let history = useHistory();

    const handleSave = () => {
        props.controller.save();
    }

    const handleCancel = () => {
        history.goBack();
    }
    return (
        <div className='col-12'>
            <div>
                <div className='edit-buttons mr-2 d-flex justify-content-end' >
                    <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button>
                    <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleCancel}>Cancel</Button>
                </div>
            </div>
        </div>
    );
}

const ShowConfigurations = (props) => {

    const handleRemoveRow = (configurationId) => {
        props.controller.removeRow(configurationId);
    }
    const controller = props.controller;
    const data = controller.state.configurationsData || [];

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'archived',
                Cell: cellInfo => <ActionIcon icon='fa-trash' className='remove-handle' action={() => { handleRemoveRow(cellInfo.row.original.configurationId) }} />
            },
            {
                Header: 'Rule',
                accessor: 'rule'
            },
            {
                Header: 'Family Code',
                accessor: 'familyCode'
            },
            {
                Header: 'Nominal Size',
                accessor: 'nominalSize'
            },
            {
                Header: 'Length',
                accessor: 'length'
            },
            {
                Header: 'Style',
                accessor: 'style',
            },
            {
                Header: 'Manufacturer',
                accessor: 'manufacturer',
            },
            {
                Header: 'CWP',
                accessor: 'cwp',
            },
            {
                Header: 'Service',
                accessor: 'service',
            },
            {
                Header: 'Degree',
                accessor: 'degree',
            },
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'OD',
                accessor: 'od',
            },
            {
                Header: 'Mfg Part #',
                accessor: 'mfgItemNo',
            },
            {
                Header: 'EndConfiguration',
                accessor: 'endConfiguration',
            },
        ],
        []
    )
    return (
        <div className='col-md-12'>
            <div className='row'>
                <div className='col-12'>
                <Form.Header className='bg-warning'>Configurations</Form.Header>
                </div>
            </div>
            <Grid id='results-table' columns={columns} data={data} showPagination={false} />
        </div>
    );
}

const ConfigureCustomerPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class ConfigureCustomerPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        const id = this.props.match.params.customerId;
        this.props.controller.load(id);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();
    }

    static defaultProps = {
        controller: new ConfigureCustomerController()
    }


    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const title = 'Restrict Equipment Create';

        return (
            <Page {...this.props} pageTitle='InteServ · Restrict Equipment Create' id='confg-cust-page'>
                 <ConfigureCustomerPageHeader pageTitle={title} />
                 <Page.Content>
                <div className='row'>
                    <div {...this.props} className='col-md-12'>
                        <SearchHeader controller={controller} binder={binder} />
                    </div>
                    <Buttons controller={controller} />
                    <ShowConfigurations controller={controller} />
                </div>
                </Page.Content>
            </Page>
        );
    }
};

export default ConfigureCustomerPage;
