import React, { useEffect } from 'react';
import List from './List';
import ReactTreeView from 'react-simple-tree-menu';
import Form from 'react-bootstrap/Form';

const TreeView = (defaultProps) => {
    const props = {
        hasSearch: true,
        canSelectParentNode: false,
        ...defaultProps
    }
    const defaultPadding = 16;
    const iconSize = 8;
    const levelSpace = 16;
    const ToggleIcon = ({ on }) => <span className='mr-1'><i className={on ? 'fas fa-angle-down' : 'fas fa-angle-right'} /></span>;
    const ListItem = ({
        level = 0,
        hasNodes,
        isOpen,
        label,
        searchTerm,
        openNodes,
        toggleNode,
        matchSearch,
        focused,
        key,
        onClickItem = () => { },
        ...props
    }) => {
        const listItemProps = {
            ...props
        }
        if (!props.canSelectParentNode) {
            if (hasNodes) {
                listItemProps.onClick = () => { toggleNode && toggleNode(); }
            }
        }
        return (
            <List.Item className='py-2 px-4 d-flex'
                {...listItemProps}
                style={{
                    paddingLeft: defaultPadding + iconSize + level * levelSpace,
                    cursor: 'pointer',
                    boxShadow: focused ? '0px 0px 0px 0px #222' : 'none',
                    zIndex: focused ? 999 : 'unset',
                    position: 'relative',
                }}
            >
                {props.icon && (
                    <span><i className={props.icon + ' mr-1'} /></span>
                )}
                <div className='d-inline-block'>
                    {hasNodes && <ToggleIcon on={isOpen} />}
                    <span>{label}</span>
                </div>
            </List.Item>
        );
    }


    useEffect(() => {
        if (props.initialActiveKey && props.data.length) {
            const nodes = props.initialActiveKey.split("/");
            let currentNode = null;
            nodes.forEach((item, index) => {
                if (currentNode) {
                    const node = currentNode.nodes.find(node => node.key === item)
                    if (node) {
                        currentNode = node;
                    }
                    if (index + 1 === nodes.length) {
                        props.onClickItem(currentNode);
                    }
                }
                else {
                    const parentObject = props.data.find(node => node.key === item)
                    currentNode = parentObject || null;
                }
            });
        }
    }, [props.initialActiveKey, props.data]);

    return (
        <ReactTreeView
            {...props}
            data={props.data}
            onClickItem={(e) => {
                props.onClickItem(e);
            }}>
            {({ search, items }) => (
                <>
                    {props.hasSearch && <Form.Control type='text' onChange={e => search(e.target.value)} placeholder='Type and search' />}
                    <List>
                        {items.map(({ reset, ...props }) => (
                            <ListItem {...props} />
                        ))}
                    </List>
                </>
            )}
        </ReactTreeView>
    )
}

export default TreeView;