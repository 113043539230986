
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class InspectionTestNotesController extends BaseController {
  constructor(jobId, testName, assetId, notesId, notes) {
    super('inspection-test-notes', {
      notesId           : notesId ? notesId : Utils.emptyGuid,
      jobId             : jobId,
      testName          : testName,
      assetId           : assetId,
      notes             : notes ? notes : '' ,
      errors            : {},
      notesText : '',
    });

    this.saving = false;
    this._validator = new Validator();
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    let errors = Object.keys(this.state.errors);    
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  save = () => {
      if (!this.canSave()) {
        return;
      }
      const s          = this.state;
      const parameters = {
        notesId           : s.notesId,
        jobId             : s.jobId,
        testName          : s.testName,
        assetId           : s.assetId,
        notes             : s.notesText,
        usersToNotify     : s.usersToNotify
      };
      this.saving=true;
      this.commit();

      return ajax.post('inspection/addTestNotes', parameters).then(results=>{
        this.saving=false;
        this.commit();
        notification.action('success').post('Notes Saved successfully');
      messages.channel('reload-test-results').action('saved').post();
      return true;
      }).catch(err => {
        this.saving=false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
      });
    }
    handleNotesChange = (text, usersToNotify, displayText) => {
      // console.log("Called");
      this.state.usersToNotify = usersToNotify;
      this.state.notes = text;
      this.state.notesText = displayText;
      this.commit();
    }
}

export default InspectionTestNotesController;