
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';


class CompletionDateController extends BaseController {
  constructor() {
    super('completion-date', {
      assets: [],
      completionDate: '',
      errors: {}
    });
    this.saving = false;
  }

  bindStateData = (assets) => {
    this.state.assets = assets;
  }

  loadValidators = () => {
    this._validator = new Validator();
    this._validator.add('completionDate', 'Completion date', [{ required: true }]);
  }

  load = (assets) => {
    this.bindStateData(assets);
    this.loadValidators();
    this.commit();
  }

  canSave = () => {
    this._validator.validateAll(this.state);

    const errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }

  save = () => {
    if (!this.canSave()) {
      return;
    }
    const s = this.state;
    const parameter = {
      asset: s.assets,
      plannedStartDate: s.completionDate
    };
    this.saving = true;
    this.commit();
    return ajax.post('receiving/updateAssetCompletionDate', parameter).then(results => {
      this.saving = false;
      this.commit();
      notification.action('success').post({ msg: 'Scheduled completion date updated successfully.', title: 'Success' });
      return results;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default CompletionDateController;