import React from 'react';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import AdminQueryParametersController from'../controllers/AdminQueryParametersController';
import AdminQueryParametersDialog     from'../dialogs/AdminQueryParametersDialog';

class AdminQueryController extends BaseController {
  constructor() {
    super('adminqueries', {
      queryList  : [],
      queryData  : {}
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state.queryData = {};
      }
    });

    messages.channel('adminquery').action('data-loaded').subscribe(data => {
      if (!data) {
        return;
      }
      this.state.queryData = data;
      this.commit();
    });
  }

  getSelectedQuery = () => {
    const selectedQueries = this.state.queryList.filter(q => q.selected);
    return (selectedQueries.length === 1)
      ? selectedQueries[0]
      : null;
  }

  selectQuery = (query) => {
    this.state.queryData = {};
    this.state.queryList.forEach(q => q.selected = false);
    this.state.queryList.forEach(q => {
      if (q === query) {
        q.selected = true;
      }
    });
    this.commit();
  }

  assignDefaultCategory = (queries) => {
    queries.filter(q => !q.category).forEach(q => q.category = '« no category »');
  }

  refreshQueries = () => {
    this.state.queryList = [];
    this.state.queryData = {};
    this.loadQueriesIfEmpty();
  }

  loadQueriesIfEmpty = () => {
    if (!this.state.queryList || !this.state.queryList.length) {
      this.loadQueries();
    }
  }

  loadQueries = () => {
      this.state.results   = [];
      ajax.get('lookup/getQueries').then(results=>{
        this.assignDefaultCategory(results);
        this.state.queryList = results;
  
        if (results.length) {
          this.selectQuery(results[0]);
        }
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });    // IList<QueryDto>
  }

  executeQuery = () => {
    const query      = this.getSelectedQuery();
    const parameters = query.parameters || [];

    co(this, function*() {
      if (parameters.length) {
        var result = yield Dialog.showDialogWaitForResult(<AdminQueryParametersDialog controller={new AdminQueryParametersController(query)} />);
        if (!result.OK) {
          return;
        }
      }
      else {
        this.state.queryData = {};
        var results          = yield ajax.post('lookup/loadQuery', { queryId: query.queryId, parameters: parameters  }); // object
        messages.channel('adminquery').action('data-loaded').post(results);
      }
    });
  }
}

export default AdminQueryController;