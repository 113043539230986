import React from 'react';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import notification from '../../services/Notification';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Authentication from '../services/authentication';
import DropZone from '../../components/DropZone';
import EditManufacturerDescriptionController from '../controllers/EditManufacturerDescriptionController';
import SelectLaborCodesController from '../controllers/SelectLaborCodesController';
import CustomerDescriptionsTable from './CustomerDescriptionsTable';
import EndConfigurationsTable from './EndConfigurationsTable';
import ManufacturerDescriptionWallReadingsTable from './ManufacturerDescriptionWallReadingsTable';
import ManDescTorqueReadingsTable from './ManDescTorqueReadingsTable';
import ManDescDimensionsTable from './ManDescDimensionsTable';
import SelectedLaborCodeTable from './SelectedLaborCodeTable';
import SelectLaborCodesDialog from '../dialogs/SelectLaborCodesDialog';
import GroupBadge from '../../components/GroupBadge';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';

const Verify = (props) => {
  const handleVerify = () => {
    props.controller.verify();
  }
  const controller = props.controller;
  if (controller.adding) {
    return null;
  }

  const shouldVerify = ((controller.state.manufacturer && controller.state.manufacturer.name) === 'FMC');
  if (!shouldVerify) {
    return null;
  }

  const verified = props.verified;
  const isVerified = verified && verified.isVerified;
  if (!isVerified) {
    return (
      <div>&nbsp;<Button variant='warning' size='sm' className='btn-block' icon='fa-check-circle' style={{ marginTop: '4px' }} onClick={handleVerify}>Verify</Button></div>
    );
  }

  const verifiedBy = verified.verifiedBy.name + ' on ' + DateTimeFormatter.formatDate(verified.dateVerified);
  return (
    <Form.StaticData label='Verified By' value={verifiedBy} />
  );
}

const TechnicalInformation = (props) => {
  return null;

  // var controller = this.props.controller;
  // var binder     = this.props.binder;
  // return (
  //   <div>
  //     <Form.Header className='alert-warning col-sm-12'>Technical Information</Form.Header>
  //     <div className='col-sm-4'><Form.AutoComplete label='Type Approval'                 bind={binder.bind('typeApproval')}  search={controller.search('type approval')} enabled={false} /></div>
  //     <div className='col-sm-4'><Form.AutoComplete label='Temp Range'                    bind={binder.bind('tempRange')}     search={controller.search('temp range')}    enabled={false} /></div>
  //     <div className='col-sm-4'><Form.AutoComplete label='Load Rating'                   bind={binder.bind('loadRating')}    search={controller.search('load rating')}   enabled={false} /></div>
  //   </div>
  // );
}

const EditForm = (props) => {

  let history = useHistory();

  const showImageDialog = (item) => {
    if (!item.hasDiagram) {
      return;
    }
    let height = 700;
    let width = 660;
    let style = {
      backgroundColor: 'white',
      marginBottom: '8px'
    };
    const imgs = $('img');
    if (imgs.length) {
      const img = imgs[0];
      if (img.width > img.height) {
        style.width = '640px';
        height = (640 * img.height / img.width) + 120;
      }
      else {
        style.height = '580px';
        width = (580 * img.width / img.height) + 20;
      }
    }

    co(function* () {
      yield Dialog.showDialogWaitForResult(
        <Dialog.OKDialog width={width} height={height} header='Image Viewer'>
          <div id='image-dialog'>
            <img src={item.imgUrl + '?' + DateTimeFormatter.now()} style={style} />
          </div>
        </Dialog.OKDialog>
      );
    });
  }

  const handleSelectLaborCodes = () => {
    const state = props.controller.state;
    const manDesc = state;
    const manDescLaborCodes = state.laborCodes;
    const controller = new SelectLaborCodesController(manDesc, manDescLaborCodes);

    co(function* () {
      yield Dialog.showDialogWaitForResult(
        <SelectLaborCodesDialog controller={controller} header='Select Labor Codes' />
      );
    });
  }

  const handleUploadDiagram = (res) => {
    const newFile = {
      id: Utils.newGuid(),
      fileName: res.file.name,
      size: res.file.size,
      altText: '',
      caption: '',
      file: res.file,
      fileType: res.file.name.split('.')[1] || '',
      url: res.imageUrl
    };
    props.controller.uploadDiagram(res, newFile);
  }

  const handleRemoveDiagram = () => {
    props.controller.removeDiagram();
  }

  const handleAddNotes = (manDesc) => {
    if (!manDesc) {
      return;
    }
    history.push('/notes/' + AppConstants.NoteTypes.ManufacturerDescription + '/' + manDesc.manDescId);
  }

  const handleCreateCustomerDescription = (manDesc) => {
    if (!manDesc) {
      return;
    }
    const controller = props.controller;
    var style = { marginTop: '75px', marginBottom: '10px' };

    co(function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={250} header='Pick Customer'>
          <div id='pick-customer-dlg'>
            <div className='col-md-12 mb-1 h6'>Select the customer for whom you wish to associate this Manufacturer Description</div>
            <div className='col-md-12'>
              <Form.AutoComplete label='Customer' className='required' bind={binder.bind('customer')} search={controller.customerSearch()} />
            </div>
          </div>
        </Dialog.OKCancelDialog>
      );

      const customer = controller.state.customer;
      if (!result.OK || !customer) {
        controller.clearCustomerValue();
        return;
      }

      if (controller.isCustomerAlreadyPresent(customer)) {
        controller.clearCustomerValue();
        notification.action('error').post({ msg: 'A Customer Description already exists for ' + customer.name + ' for this Manufacturer Description', title: 'Customer Description Exists' });
        return;
      }
      const queryData = {
        manDescId: manDesc.manDescId,
        customer: customer
      }
      history.push({ pathname: '/customerdescription/' + Utils.emptyGuid, queryData: queryData });
      controller.clearCustomerValue();
    });
  }

  const binder = props.binder;
  const controller = props.controller;
  const errors = controller.state.errors;
  const item = controller.state;
  const endConfigurationCount = item.endConfigurations.length;
  const endConfigurationBadge = Utils.pluralize('End Configuration', 's', endConfigurationCount);
  const wallReadingCount = item.wallReadings.length;
  const wallReadingBadge = Utils.pluralize('Wall Reading', 's', wallReadingCount);
  const torqueReadingCount = item.torqueReadings.length;
  const dimensionCount = item.dimensions.length;
  const torqueReadingBadge = Utils.pluralize('Torque Reading', 's', torqueReadingCount);
  const dimensionBadge = Utils.pluralize('Dimension', 's', dimensionCount);
  const uploadReplaceDiagramText = item.hasDiagram
    ? 'Replace'
    : 'Upload';
  const canShowNotes = !controller.adding && item;
  const noteCountBadge = item.noteCount
    ? <Badge variant='info' className='note-badge ml-1'>{item.noteCount}</Badge>
    : null;
  const canCreateCustomerDescription = !controller.adding && item;
  const lastUpdated = item && item.dateUpdated
    ? (item.updatedBy && item.updatedBy.name + ' on ' + DateTimeFormatter.formatDate(item.dateUpdated))
    : 'never';
  const canAddNewEndConfigurationRow = (!controller.adding && (endConfigurationCount < 9));
  const dragDropText = 'Drag &amp; Drop';
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const isAdministrator = LoginService.isInRole(Roles.Administrator);
  const disableCreateCustomer = isSuperUser && item.updateWallReadings && item.wallReadings.length < 1;
 //const enablePressureCertificateDD= item.canShowCertificateTestPressure===true?true:false;
  
  const checkboxStyle = {
    height: 15,
    width: 15,
    backgroundColor: '#F1F1F1',
    position: 'absolute',
    left: 0,
    top: 3
  }

  const handleCheckBoxClick = () => {
    props.controller.toggleupdateWallReadingsSection();
  }
  // DropZone.disabled controls functionality
  // The contained Button.disabled property controls visual appearance of the button

  return (
    <div className='col-md-12'>
      <Form.Header className='alert-warning'>
        <div className='col-md-12'>
          <div className='d-flex justify-content-between row'>
            <div>
              Description Information
            </div>
            <div>
              <div>
                <Badge variant='info' className='m-1'>{item.assetCount} Assets</Badge>
                <Badge variant='info' className='m-1'>{item.customerCount} Customers</Badge>
              </div>
            </div>
          </div>
        </div>
      </Form.Header>
      <div className='row'>
        <div className='col-md-10'><Form.StaticData label='Description' value={item.description} /></div>
        <div className='col-md-2'><Form.StaticData label='Last Updated' value={lastUpdated} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.AutoComplete label='Family Code' className='required' error={errors.familyCode} bind={binder.bind('familyCode')} search={controller.search('family code')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Nominal Size' className='required' error={errors.nominalSize} bind={binder.bind('nominalSize')} search={controller.search('nominal size')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Length' className='required' error={errors.length} bind={binder.bind('length')} search={controller.search('length')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Style' className='required' error={errors.style} bind={binder.bind('style')} search={controller.search('style')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.AutoComplete label='Manufacturer' className='required' error={errors.manufacturer} bind={binder.bind('manufacturer')} search={controller.search('manufacturer')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='CWP' className='required' error={errors.cwp} bind={binder.bind('cwp')} search={controller.search('cwp')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Service' className='required' error={errors.service} bind={binder.bind('service')} search={controller.search('service')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Degree' className='required' error={errors.degree} bind={binder.bind('degree')} search={controller.search('degree')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.AutoComplete label='ID' className='required' error={errors.id} bind={binder.bind('id')} search={controller.search('id')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='OD' className='required' error={errors.od} bind={binder.bind('od')} search={controller.search('od')} /></div>
        <div className='col-md-3'><Form.Input label='Mfg Part #' bind={binder.bind('mfgItemNo')} /></div>
        <div className='col-md-3'><Form.Input label='Alt Part #' bind={binder.bind('altItemNo')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.CheckBox label='Consider as FMC Part' bind={binder.bind('isFMCPart')} /></div>
        <div className='col-md-3'><Form.CheckBox label='UT Scope' bind={binder.bind('isUTScope')} /></div>
       {/*
        <div className='col-md-6'>
          <span>Certificate Test Pressure</span>
          <div className='certificate-test-pressure'>
            <Form.CheckBox className='certificate-test-pressure-checkbox' label='' bind={binder.bind('canShowCertificateTestPressure')} />
            <div className='certificate-test-pressure-dd'>
              <Form.AutoComplete label='' className='required' error={errors.certificateTestPressure} enabled={enablePressureCertificateDD} bind={binder.bind('certificateTestPressure')} search={controller.search('certificate test pressure')} />
            </div>
          </div>
        </div>
       */
       }
      </div>
      
      <div className='row'>
        <div className='col-md-12'><Verify controller={controller} verified={controller.state.verified} /></div>
      </div>

      <TechnicalInformation controller={controller} binder={binder} />

      <div className='row'>
        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span>End Configurations<span className='font-italic' style={{ fontSize: '0.7rem' }}> 9 max</span></span></div>
              <div>
                <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={!canAddNewEndConfigurationRow} className='mr-1' onClick={() => { controller.addNewEndConfigurationRow(); }}>Add ...</GroupBadge>
                <Badge variant='info'>{endConfigurationBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <EndConfigurationsTable controller={controller} binder={binder} enabled={!controller.adding} />
        </div>

        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='row'>
              <div className='col-md-7 col-lg-7'>
                <div><span>Wall Readings</span></div>
                {(isSuperUser || isAdministrator) && <div className='update-wall-readings-header'><input type='checkbox' style={checkboxStyle} checked={item.updateWallReadings} onChange={() => handleCheckBoxClick()} className='mr-2' /><Form.Label name='Update Wall Readings' style={{ marginTop: 3 }} />
                </div>}
              </div>

              <div className='col-md-5 col-lg-5'>
                <div className='float-right'>
                  <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={controller.adding || isSuperUser} className='mr-1' onClick={() => { controller.addNewWallReadingRow(); }}>Add ...</GroupBadge>
                  <Badge variant='info'>{wallReadingBadge}</Badge>
                </div>
              </div>

            </div>
          </Form.Header>
          <ManufacturerDescriptionWallReadingsTable controller={controller} binder={binder} />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Torque Readings</span></div>
              <div>
                <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={controller.adding || isSuperUser} className='mr-1' onClick={() => { controller.addNewTorqueReadingRow(); }}>Add ...</GroupBadge>
                <Badge variant='info'>{torqueReadingBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <ManDescTorqueReadingsTable controller={controller} binder={binder} />
        </div>

        <div className='col-md-6'>
          <Form.Header className='alert-warning col-sm-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Dimensions</span></div>
              <div>
                <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={controller.adding || isSuperUser} className='mr-1' onClick={() => { controller.addNewDimensionRow(); }}>Add ...</GroupBadge>
                <Badge variant='info'>{dimensionBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <ManDescDimensionsTable controller={controller} binder={binder} />
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <Form.Header className='alert-warning diagram-label col-sm-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Diagram</span></div>
              <div className='d-flex'>
                <DropZone onDrop={handleUploadDiagram} multiple={false} disabled={controller.adding} style={{ borderStyle: 'none', padding: 'unset' }} width={170} height={23} >
                  <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
                  <GroupBadge type='file' variant='success' pill={false} icon='fa-upload' disabled={controller.adding}>{uploadReplaceDiagramText}</GroupBadge>
                </DropZone>
                <div>
                  <GroupBadge variant='danger' icon='fa-trash' pill={false} onClick={handleRemoveDiagram} disabled={controller.adding || !item.hasDiagram}>Remove</GroupBadge>
                </div>
              </div>
            </div>
          </Form.Header>

          <div className='col-sm-6'>
            {!controller.adding &&
              <div><img alt={item.imgAlt} src={item.imgUrl + '?' + DateTimeFormatter.now()} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '300px', maxWidth: '395px' }} onClick={() => showImageDialog(item)} /></div>
            }
          </div>
        </div>

        <div className='col-sm-6'>
          <Form.Header className='alert-warning col-sm-12'>
            <div className='d-flex justify-content-between'>
              <div>Manufacturer Notes</div>
              <div>
                <GroupBadge icon='fa-comments' disabled={!canShowNotes} variant='info' pill={false} className='' onClick={() => { handleAddNotes(item); }}>Notes ...</GroupBadge>
                {noteCountBadge}
              </div>
            </div>
          </Form.Header>
          <div className='col-sm-6'><Form.TextArea label='Manufacturer Notes' rows={9} bind={binder.bind('notes')} /></div>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <Form.Header className='alert-warning col-sm-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Selected Labor Codes</span></div>
              <div>
                <GroupBadge variant='info' pill={false} icon='far fa-hand-point-up' onClick={handleSelectLaborCodes} disabled={controller.adding}>Select ...</GroupBadge>
              </div>
            </div>
          </Form.Header>
          <SelectedLaborCodeTable items={item.laborCodes} />
        </div>

        <div className='col-sm-6'>
          <Form.Header className='alert-warning col-sm-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Customer Descriptions</span></div>
              <div>
                <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={!canCreateCustomerDescription || disableCreateCustomer} onClick={() => { handleCreateCustomerDescription(item); }}>Create New ...</GroupBadge>
              </div>
            </div>
          </Form.Header>
          <CustomerDescriptionsTable items={item.customerDescriptions} />
        </div>
      </div>
    </div>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleSave = () => {
    props.controller.save((manDescId) => {
      // after saving - navigate to same page with new id in address bar
      history.replace('/manufacturerdescription/' + manDescId);
      //this.replaceWith('editmanufacturerdescription', { manDescId: manDescId });
    });
  }

  const handleClose = () => {
    history.goBack();
  }

  return (
    <div className='col-md-12 mt-1'>
      <div className='d-flex justify-content-end'>
        <Button icon='fa-save' variant='primary' size='sm' id='btn-save' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving} >Save</Button>
        <Button icon='fa-times' variant='secondary' size='sm' id='btn-cancel' className='m-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const EditManufacturerDescriptionPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class EditManufacturerDescriptionPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const id = this.props.match.params.manDescId;
    this.props.controller.load(id);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new EditManufacturerDescriptionController()
  }


  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const title = (controller.adding ? 'Create ' : 'Edit ') + 'Manufacturer Description';

    return (
      <Page {...this.props} className='man-desc-background' pageTitle='InteServ · Edit Manufacturer Description' id='edit-man-desc-page'>
        <EditManufacturerDescriptionPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <EditForm controller={controller} binder={binder} />
            <Buttons controller={controller} binder={binder} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default EditManufacturerDescriptionPage;