import React, {useMemo} from 'react';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Searchers from '../utility/Searchers';

const ManDescDimensionsTable = (props) => {
  const controller = props.controller;

  const totalWallReadingCount = controller.state.dimensions.reduce((acc, curr) => acc + curr.state.quantity, 0);

  const handleAddNewRow = () => {
    props.controller.addNewDimensionRow();
  }
  
  const divClassName = controller.state.errors.dimensions ? ' has-error' : '';

  const data = [...controller.state.dimensions];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',   
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <ActionIcon  icon='fa-trash' className='remove-handle' action={controller.removeRow} />;
        }
      },
      {
        Header: 'ID',
        accessor: 'state.dimensionId',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('dimensionId')} className='required id-field' error={errors.dimensionId} placeholder='#' />
        }
      }, 
      {
        Header: 'Dimensional Location',
        accessor: 'state.dimensionLocation',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.AutoComplete bind={binder.bind('dimensionLocation')} className='' error={errors.torquelocation} search={Searchers.searchDimensionLocation()} minimumResultsForSearch={-1} />
        }
      },
      {
        Header: 'New Dimension',
        accessor: 'state.newDimension',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('newDimension')} className='required' error={errors.minimumDimension}  placeholder='Enter a value' />
        }
      },
      {
        Header: 'Minimum Dimension',
        accessor: 'state.minimumDimension',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('minimumDimension')} className='required' error={errors.minimumDimension}  placeholder='Enter a value' />
        }
      },
    ],
    []);
    return (
      <div className={divClassName}>
        <Grid id='results-table' columns={columns} data={data} editable={true} canAddNewRow={false} onAddNewRow={handleAddNewRow} showPagination={false} noDataText='No dimensions exist yet'/>
      </div>
  );
}

export default ManDescDimensionsTable;