import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';


class TransferAssetsToContainerController extends BaseController {
    constructor(customer, sourceContainer, assetIds) {
        super('transfer-assets', {
            customer: customer,
            sourceContainer: sourceContainer,
            targetContainer: '',
            assetIds: assetIds,
            errors: {}
        });

        this._validator = new Validator();
        this._validator.add('targetContainer', 'Target Container', [{ required: true }]);
    }

    containerSearch = () => {
        const self = this;
        return (searchTerm) => ajax.get('lookup/searchCustomerContainers', {
            customerId: self.state.customer.id,
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    canTransfer = () => {
        this._validator.validateAll(this.state);
        var errors = Object.keys(this.state.errors);
        if (!errors.length) {
            return true;
        }
        this.commit();
        return false;
    }

    transferAssets = () => {
        if (!this.canTransfer()) {
            return false;
        }

        const parameters = {
            sourceContainerId: this.state.sourceContainer && this.state.sourceContainer.containerId,
            targetContainerId: this.state.targetContainer.id,
            assetIds: this.state.assetIds
        };

        this.state.saving = true;
        return ajax.post('container/transferAssets', parameters).then(() => {
            const fromContainer = this.state.sourceContainer
                ? ' from ' + this.state.sourceContainer.description
                : '';
            notification.action('success').post('Transferred ' + Utils.pluralize('asset', 's', this.state.assetIds.length) + fromContainer + ' to ' + this.state.targetContainer.name + ' for ' + this.state.customer.name, 'Transferred Assets to Container');
            messages.channel('edit-container').action('saved').post();
            this.state.assetIds.forEach(assetId => {
                messages.channel('edit-asset-container').action('saved').post(assetId);
            });
            return true;
        }).catch(err => {
            this.state.saving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
            return false;
        }); // IN: TransferAssetsToContainerDto, OUT: void
    }
}
export default TransferAssetsToContainerController;
