import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';

class EditAssetCertificateController extends BaseController {
  constructor(jobId, jobNumber, certificate, serviceLevelIds, assetCount) {
    super('edit-asset-certificate', {
      jobId           : jobId,
      jobNumber       : jobNumber,
      certificate     : certificate,
      serviceLevelIds : serviceLevelIds,
      assetCount      : assetCount
    });
  }

  save = () => {
    const s = this.state;
    const parameters = {
      jobId           : s.jobId,
      jobNumber       : s.jobNumber,
      certificate     : s.certificate,
      serviceLevelIds : s.serviceLevelIds,
      assetCount      : s.assetCount
    };
    this.saving = true;
    this.commit();

    return ajax.post('inspection/saveAssetCertificate', parameters).then(()=>{
      messages.channel('edit-asset-certificate').action('saved').post(parameters);
      this.saving = false;
      this.commit();
      return true;
    }).catch(err => {
      this.saving=false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default EditAssetCertificateController;
