import React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import SAPService from '../services/SAPService';
import LoginService from '../services/LoginService';
import SerializedAssetDetailController from '../controllers/SerializedAssetDetailController';
import AppConstants from '../utility/AppConstants';
import Authentication from '../services/authentication';
import Dropdown from '../../components/Dropdown';
import Utils from '../utility/Utils';
import DropZone from '../../components/DropZone';
import QRSticker from '../components/QRSticker';
import JobSummary from '../views/JobSummary';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import List from '../../components/List';
import ServiceHistoryTable from './ServiceHistoryTable';
import AssetPressureTestChartTable from './AssetPressureTestChartTable';
import AssignedServiceLevelsTable from './AssignedServiceLevelsTable';
import AssetUtilizationTable from './AssetUtilizationTable';
import ContainerHistoyTable from './ContainerHistoyTable';
import CustomerCertificateTable from './CustomerCertificateTable';
import Jumbotron from '../../components/Jumbotron';

const AssetJobSummary = (props) => {

  let history = useHistory();

  const loadInspectionJob = (evt, job) => {
    history.push('/inspection/' + job.jobId);
  }
  const job = props.job;
  const customer = job && job.customerName;
  const customerLocation = job && job.customerLocation;
  const jobNumber = job && job.jobNumber;
  const thirdPartyJobNumber = job && job.thirdPartyJobNumber;
  const jobDescription = job && job.jobDescription;
  const notes = job && job.jobNotes;
  const jobDate = job && DateTimeFormatter.formatDate(job.jobDate);
  const displayRequestedDate = job && DateTimeFormatter.formatDate(job.requiredDate);
  let requestedDate = job && job.requestedDate;
  requestedDate = job && DateTimeFormatter.getMoment(requestedDate).format('YYYYMMDD');
  const today = job && DateTimeFormatter.today().format('YYYYMMDD');
  const isSameFacility = ((job && job.facilityId) == LoginService.loginInfo.facilityId) ? true : false;
  let requestedDateClassName = '';
  const jobNumberLink = (isSameFacility && !LoginService.isOnlySAPRep) ? <Form.Link label='Job Number' value={jobNumber} onClick={evt => { loadInspectionJob(evt, job); }} /> : <Form.StaticData label='Job Number' value={jobNumber} />;

  if (today > requestedDate) {
    requestedDateClassName += ' late-error';
  }
  else if (today === requestedDate) {
    requestedDateClassName += ' late-warning';
  }

  const divClassName = props.hide ? 'hidden' : 'form-group col-sm-12';
  const isSAPFacility = SAPService.isSAPFacility();
  return (
    <div id='job-summary-fields' className={divClassName}>
      <div className='row'>
        <div className='col-md-6'>
          {jobNumberLink}
        </div>
        <div className='col-md-6'><Form.StaticData label='3rd Party Job Number' value={thirdPartyJobNumber} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6' ><Form.StaticData label='Customer' value={customer} /></div>
        <div className='col-md-6' ><Form.StaticData label='Customer Location' value={customerLocation} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Job Description' value={jobDescription} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6' ><Form.StaticData label='Job Date' value={jobDate} /></div>
        <div className='col-md-6' ><Form.StaticData label='Customer Requested Date' value={displayRequestedDate} className={requestedDateClassName} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Job Notes' value={notes} /></div>
      </div>
      {LoginService.isSuperUser && isSAPFacility && <div className><Form.StaticData label='SAP Status' value={job && job.sapStatus} /></div>}
    </div>
  );
}

const AssetDetail = (props) => {

  let history = useHistory();

  const navigateToReceiving = (assetId) => {
    if (!assetId) {
      return;
    }
    if (LoginService.isCustomer) {
      history.push('/fieldasset/' + assetId);
    }
    else {
      history.push('/receiving/' + assetId);
    }
  }

  const navigateToCustomerDescription = (asset) => {
    if (!asset || !asset.description || !asset.description.id) {
      return;
    }
    history.push('/customerdescription/' + asset.description.id);
  }

  const navigateToCustomerLocation = (customerLocation) => {
    if (!customerLocation || !customerLocation.id) {
      return;
    }
    history.push('/customerlocations/' + customerLocation.id);
  }

  const handleGetChartData = () => {
    props.controller.getChart();
  }

  const handleRfid = () => {
    // const asset = props.asset;
    // // store.set(asset.assetId, asset);
    // this.transitionTo('editrfid', { assetId: asset.assetId });
  }

  const getAssetStatus = (asset) => {
    let assetStatus = asset.status;
    if (asset.rejectReason) {
      if (assetStatus != 'Passed' && assetStatus != 'Failed Inspection')
        assetStatus += ' (' + asset.rejectReason + ')';
    }
    return assetStatus;
  }

  const getScrapDetails = (asset) => {
    let scrapDetails = '';
    if (asset.scrapDetails) {
      const sh = asset.scrapDetails;
      scrapDetails = ` Failed ${sh.serviceLevelName} :: ${sh.testName} on job ${sh.jobNumber} at ${sh.facilityName} on ${DateTimeFormatter.formatDate(sh.scrapDate)} by ${sh.technician}`;
    }
    return scrapDetails;
  }

  const makeParagraphElement = (text) => {
    return text
      ? <p>{text}</p>
      : <br />;
  }

  const parseReassignNotes = (notes) => {
    if (!notes) {
      return '';
    }
    return notes
      .split('***')
      .filter(note => note)
      .map(makeParagraphElement);
  }

  const handleAttachFiles = () => {
    const asset = props.controller.state.currentAsset;
    const equipmentNumber = asset.equipmentNumber != '' ? asset.equipmentNumber : '-'
    history.push('/inspectionattachments/' + asset.assetId + '/' + asset.primarySerialNumber + '/' + equipmentNumber);
  }
  const viewTechnicalDoc = () => {
    props.controller.viewTechnicalDoc();
  }
  const handleAddNotes = (asset) => {
    if (asset) {
      history.push('/notes/' + AppConstants.NoteTypes.Asset + '/' + asset.assetId);
    }
  }

  const handleRemove = (asset) => {
    if (asset) {
      props.controller.remove(asset, response => {
        if (response) {
          history.goBack();
        }
      });
    }
  }

  const handleChangeOwnership = (asset) => {
    if (asset) {
      props.controller.changeOwnership(asset);
    }
  }

  const handleUnscrapAsset = () => {
    props.controller.UnscrapAsset();
  }

  const handleExportAssetUtilizationClick = (e) => {
    const { controller } = props;
    if (controller.state.loading) {
      e.preventDefault();
    }
    else {
      props.controller.getAssetUtilizationByteData();
    }
  }

  const handleGenerateQRCodes = () => {
    const qrSerialValues = {
      assetId: props.asset.assetId,
      primarySerialNumber: props.asset.primarySerialNumber,
      equipmentNumber: props.asset.equipmentNumber,
      mfgPartNumber: props.asset.mfgPartNumber,
      familyCode: props.asset.familyCode.name,
      nominalSize: props.asset.nominalSize.name,
      length: props.asset.length.name,
      cwp: props.asset.cwp.name,
      id: props.asset.id.name,
      style: props.asset.style.name,
      assetNumber: props.asset.assetNumber

    };
    const generatedQRCodes = <div>{<QRSticker qrValue={qrSerialValues} />}</div>;
    var newWindow = window.open('', '_blank');
    const title = 'QR code';
    newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="css/application.css"> </head><body>');
    ReactDOM.render(generatedQRCodes, newWindow.document.body);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    $(newWindow).on('load', function () {
      newWindow.print();
    });
  }
  const asset = props.asset;
  const bornOnDate = asset && DateTimeFormatter.formatDate(asset.bornOnDate);
  const inServiceDate = asset && DateTimeFormatter.formatDate(asset.inServiceDate);
  let nextInspectionDate = asset && DateTimeFormatter.formatDate(asset.nextInspectionDate);
  const completionDate = asset && DateTimeFormatter.formatDate(asset.completionDate);
  if (asset && !nextInspectionDate) {
    nextInspectionDate = 'No History';
  }

  if (asset.status === 'Scrapped') {
    asset.nextInspectionDate = null;
  }

  const assetStatus = getAssetStatus(asset);
  const userStatus = asset.userStatus;
  const scrapDetails = getScrapDetails(asset);
  const container = asset.container;
  let statusColourClass = 'status' + asset.status; // e.g. statusScrapped
  if (asset.status === 'Weld Repair' || asset.status === 'Failed Inspection')
    statusColourClass = 'asset-status statusWeldRepair'
  if (asset.status === 'OS/Machining Repair')
    statusColourClass = 'asset-status statusOSRepair'
  const hasRfidTag = asset
    ? (asset.rfidTagId || asset.rfidTagId2)
      ? 'Yes'
      : 'No'
    : '';
  const primarySerialNumberControl = !LoginService.isCustomer && LoginService.isTechnician
    ? <Form.StaticData label='Primary Serial Number' value={<a className='active-link' onClick={() => { navigateToReceiving(asset.assetId); }}>{asset.primarySerialNumber}</a>} />
    : <Form.StaticData label='Primary Serial Number' value={asset.primarySerialNumber} />;
  const descriptionControl = !LoginService.isCustomer && (LoginService.isTechnician || LoginService.isSAPRep)
    ? <Form.StaticData label='Description' value={<a className='active-link' onClick={() => { navigateToCustomerDescription(asset); }}>{asset.description && asset.description.name}</a>} />
    : <Form.StaticData label='Description' value={asset.description && asset.description.name} />;
  const dbi = !LoginService.isCustomer && (asset.manufacturerName === 'FMC')
    ? <Form.Link label="DBI" value={asset.mfgPartNumber} href={'http://ste1web.net.fmcti.com/cgi-bin/search/part-url.cgi?part=' + asset.mfgPartNumber} target='_blank' />
    : null;

  const psm = asset.manufacturerName === 'FMC' ? <Form.Link label="PSM" value={asset.psmNumber} href={asset.psmLink} target='_blank' /> : null;

  const customerLocationControl = LoginService.isSuperUser
    ? <Form.StaticData label='Customer Location' value={<a className='active-link' onClick={() => { navigateToCustomerLocation(asset.customerLocation); }}>{asset.customerLocation && asset.customerLocation.name}</a>} />
    : <Form.StaticData label='Customer Location' value={asset.customerLocation && asset.customerLocation.name} />;
  const currentLocationControl = LoginService.isSuperUser
    ? <Form.StaticData label='Current Location' value={<a className='active-link' onClick={() => { navigateToCustomerLocation(asset.currentLocation); }}>{asset.currentLocation && asset.currentLocation.name}</a>} />
    : <Form.StaticData label='Current Location' value={asset.currentLocation && asset.currentLocation.name} />;
  const utilizationCount = (asset.utilization || []).length === 100
    ? 'last 100'
    : (asset.utilization || []).length;
  const utilizationBadge = <Badge variant='info' className='float-right'>{utilizationCount}</Badge>;
  const chartBadge = <Badge variant='info' className='float-right'>{(asset.pressureTestCharts || []).length}</Badge>;
  const currentJob = asset.currentJob
    ? <div className='row'><AssetJobSummary job={asset.currentJob} /></div>
    : <div className='mt-5'><span className='no-search-results'>Not currently on job</span></div>;
  const reassignNotes = parseReassignNotes(asset.reassignNotes);
  const noteCountBadge = asset && asset.noteCount
    ? <div><Badge variant='warning' className='note-badge mt-2'>{asset.noteCount}</Badge></div>
    : null;

  const isTPRS = asset
    ? asset.isTPRS
      ? 'Yes'
      : 'No'
    : '';
  const actionButton = LoginService.isSuperUser ? <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Actions'>
    <Dropdown.MenuItem icon='fa-trash' disabled={!asset} onClick={() => { handleRemove(asset); }}>Remove ...</Dropdown.MenuItem>
    <Dropdown.MenuItem icon='fa-briefcase' disabled={!asset} onClick={() => { handleChangeOwnership(asset); }}>Change Ownership ...</Dropdown.MenuItem>
  </Dropdown.Button> : ((asset.customer && (asset.customer.name == 'TechnipFMC Fluid Control' || asset.customer.name == 'TechnipFMC In Transit') && (LoginService.isCoordinator || LoginService.isTechnician)) ? <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Actions'>
    <Dropdown.MenuItem icon='fa-briefcase' disabled={!asset} onClick={() => { handleChangeOwnership(asset); }}>Change Ownership ...</Dropdown.MenuItem>
  </Dropdown.Button> : null);
  const unscrapEnable = asset.status === 'Scrapped';

  /* -- removed cycle count as part of changes mentioned in PBI #116060
  const plannedStartDate = SAPService.isSAPFacility() && asset ? DateTimeFormatter.formatDate(asset.plannedStartDate) != '01-Jan-1900'
    ? <Form.StaticData label='Cycle Count Date' value={DateTimeFormatter.formatDate(asset.plannedStartDate)} />
    : <Form.StaticData label='Cycle Count Date' value='' />
    : '';
  */

  const scrappedDate = asset.status === 'Scrapped' && asset && DateTimeFormatter.formatDate(asset.scrappedDate) != '01-Jan-1900'
    ? <Form.StaticData label='Scrapped Date' value={DateTimeFormatter.formatDate(asset.scrappedDate)} />
    : <Form.StaticData label='Scrapped Date' value='' />;

  //Adding Scrapped Date as part of PBI #116060


  const downloadAssetUtilizationButton = <Button id='asset-utilization-export' disabled={navigator.userAgent.match(/iPad/i) != null || (props.isLoading) || (asset.utilization && !asset.utilization.length)} variant='primary' size='sm' className='badge btn export-btn' icon='fa-file-excel' onClick={handleExportAssetUtilizationClick}>Export to Excel</Button>

  return (
    <div className='asset-column'>
      <div className='row'>
        <div className='col-md-6'>{primarySerialNumberControl}</div>
        <div className='col-md-6'><Form.StaticData label='Secondary Serial Number' value={asset.secondarySerialNumber} /></div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Equipment Number' value={asset.equipmentNumber} /></div>
        <div className='col-md-6'><Form.StaticData label='Batch Number' style={{ wordBreak: 'break-word' }} value={asset.batchNumber} /></div>
      </div>

      {/* <div className='row'>
          <div className='col-sm-6'><Form.StaticData label='RFID Tag' value={hasRfidTag} /></div>
          <div className='col-md-3'><Form.StaticData label='TPRS'     value={isTPRS} /></div>
        </div> */}
      {descriptionControl}
      <div className='row'>
        <div className='col-md-6'>{dbi}</div>
        <div className='col-md-6'>{psm}</div>
        {completionDate != '01-Jan-1900' && assetStatus == 'OS/Machining Repair' && <div className='col-sm-6'><Form.StaticData label='Scheduled Completion Date' value={completionDate} className={statusColourClass} /> </div>}
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Customer' value={asset.customer && asset.customer.name} /></div>
        <div className='col-md-6'>{customerLocationControl}</div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Company Code' value={asset.companyCode} /></div>
        <div className='col-md-6'><Form.StaticData label='MaintPlant' value={asset.plantCode} /></div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='User Status' value={userStatus} className={statusColourClass} /></div>
        <div className='col-md-6'><Form.StaticData label='Status' value={assetStatus} className={statusColourClass} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Container' value={container} /></div>
        <div className='col-md-6'>{currentLocationControl}</div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Born On Date' value={bornOnDate} /></div>
        <div className='col-md-6'>{scrappedDate}</div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Acquisition Value' value={asset.acquisitionCurrency ? asset.acquisitionValue : ''} /></div>
        <div className='col-md-6'><Form.StaticData label='Acquisition Currency' value={asset.acquisitionCurrency} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Asset Number' value={asset.assetNumber} /></div>
        {/*<div className='col-md-6'><Form.StaticData label='Inventory Number' value={asset.inventoryNumber} /></div>  */}
        <div className='col-md-6'><Form.StaticData label='In Service Date' value={inServiceDate} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Object Type' value={asset.objectType} /></div>
        {/* <div className='col-md-6'><Form.StaticData label='Planning Plant'  value={asset.planningPlant} /></div> */}
        {/*<div className='col-md-6'>{plannedStartDate}</div>*/}
        <div className='col-md-6'><Form.StaticData label='Inventory Number' value={asset.inventoryNumber} /></div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <Form.StaticData label='Reassign Notes' value={reassignNotes} hint='newest to oldest' valueStyle={{ maxHeight: '150px', overflowY: 'auto', marginBottom: '15px' }} />
        </div>
      </div>

      {scrapDetails &&
        <div className='row col-md-12'>
          <Form.StaticData label='Scrap Details' value={scrapDetails} className='statusScrapped' />
        </div>
      }

      <div className='col-md-12 mb-2'>
        <div className='row'>
          {LoginService.isTechnician && !LoginService.isCustomer &&
            <span>
              {/* <Button icon='fa-rss' variant='info' size='sm' className='btn-action' disabled={!asset} style={{margin: '5px'}} onClick={this.handleRfid}>RFID ...</Button>  */}
              <Button icon='fa-qrcode' variant='primary' size='sm' className='m-1' disabled={!asset} onClick={handleGenerateQRCodes}>Print QR</Button>
            </span>
          }
          {actionButton}
          <Button icon='fa-plus-circle' variant='success' size='sm' className='m-1' disabled={!asset} onClick={handleAttachFiles}>Attach Files ...</Button>
          <Button icon='fa-file' variant='success' size='sm' className='m-1' disabled={!asset} onClick={viewTechnicalDoc}>Technical Documents ...</Button>
          <Button icon='far fa-thumbs-up' variant='success' size='sm' className='m-1' disabled={!asset || !unscrapEnable} onClick={handleUnscrapAsset}>Unscrap ...</Button>
        </div>
        <div className='row'>
          <Button icon='fa-comments' variant='info' size='sm' className='m-1' disabled={!asset} onClick={() => { handleAddNotes(asset); }}>Notes ...</Button>
          {noteCountBadge}
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12  mb-2'>
          <Form.Header className='alert-warning'>Asset Utilization {utilizationBadge}{downloadAssetUtilizationButton}</Form.Header>
          <AssetUtilizationTable value={asset.utilization} scrapDetails={asset.scrapDetails} isLoading={props.isLoading} />
        </div>

        <div className='col-md-12  mb-2'>
          <Form.Header className='alert-warning'>Pressure Test Charts{chartBadge}</Form.Header>
          <AssetPressureTestChartTable value={asset.pressureTestCharts} isLoading={props.isLoading} />
        </div>

        <div className='col-md-12'>
          <Form.Header className='alert-warning' >Current Job</Form.Header>
          {currentJob}
        </div>
      </div>
    </div>
  );
}

const ExternalLinks = (props) => {

  const controller = props.controller;

  const handleCoCDownload = () => {
    const primarySerial = controller.state.currentAsset.primarySerialNumber;
    if (primarySerial) {
      controller.downloadCoC(primarySerial);
    }
    else {
      const hashLoc = window.location.hash;
      if (hashLoc && Utils.contains(hashLoc, 'scannedqrasset') && hashLoc.split('/').length == 3 && Utils.isValidGuid(hashLoc.split('/')[2])) {
        const serialGUID = hashLoc.split('/')[2];
        controller.downloadCoCWithGUID(serialGUID);
      }
    }
  }
  return (
    <div className='mb-2'>
      <Form.Header className='alert-warning p-3'>External Links</Form.Header>
      <List>
        <List.Item><span>Print <span className='select-link' onClick={handleCoCDownload} >CoC</span> from TechnipFMC manufacturing</span> </List.Item>
      </List>
    </div>
  );
}

const SerializedAssetDetailPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class SerializedAssetDetailPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    const assetId = this.props.match.params.assetId;
    this.props.controller.loadAsset(assetId);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  static defaultProps = {
    controller: new SerializedAssetDetailController()
  }

  handleToggleCollapsed = (performedServiceLevel) => {
    this.props.controller.toggleCollapsed(performedServiceLevel);
  }

  handleUploadFile = (res) => {
    const assetId = this.props.match.params.assetId;
    const newFile = {
      id: Utils.newGuid(),
      serialGuid: assetId,
      fileName: res.file.name,
      size: res.file.size,
      altText: '',
      caption: '',
      file: res.file,
      imgUrl: res.imageUrl,
      fileType: res.file.name.split('.')[1] || ''
    };
    this.props.controller.uploadCertFile(res, newFile);
  }

  handleExportContainerHistoryClick = (e) => {
    const { controller } = this.props.controller;
    if (this.props.controller.state.loading) {
      e.preventDefault();
    }
    else {
      this.props.controller.getContainerHistoryByteData();
    }
  }

  render = () => {
    const controller = this.props.controller;
    const state = controller.state;
    const binder = new Binder(this);
    const downloadContainerHistoryButton = <Button id='asset-utilization-export' disabled={navigator.userAgent.match(/iPad/i) != null || (controller.state.loading) || (state.currentAsset.containerHistory && !state.currentAsset.containerHistory.length)} variant='primary' size='sm' className='badge btn export-btn' icon='fa-file-excel' onClick={this.handleExportContainerHistoryClick}>Export to Excel</Button>
    return (
      <Page pageTitle='InteServ · Serialized Asset Detail' id='asset-detail-page'>
        <SerializedAssetDetailPageHeader pageTitle={'Serialized Asset Detail'} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-6'>
                  <Jumbotron>
                    <AssetDetail controller={controller} binder={binder} asset={state.currentAsset} isLoading={controller.state.loading} />
                  </Jumbotron>
                </div>
                <div className='col-md-6'>
                  <Jumbotron>
                    <div className='asset-column'>
                      <ExternalLinks controller={controller} />
                      <AssignedServiceLevelsTable value={state.currentAsset.assignedServiceLevels} isLoading={controller.state.isLoading} />

                      <ServiceHistoryTable data={state.currentAsset.serviceHistory} scrapDetails={state.currentAsset.scrapDetails} isLoading={controller.state.isLoading} handleToggleCollapsed={this.handleToggleCollapsed} controller={controller} displayExport={true} />
                    </div>
                    <div className='row'>
                      <div className='col-md-12  mb-2'>
                        <Form.Header className='alert-warning'>Container History {downloadContainerHistoryButton}</Form.Header>
                        <ContainerHistoyTable value={state.currentAsset.containerHistory} isLoading={controller.state.isLoading} />
                      </div>
                    </div>
                    {/* &nbsp;
              <ContainerHistoryTable data={state.currentAsset.containerHistory} isLoading={controller.state.isLoading} handleToggleCollapsed={this.handleToggleCollapsed} /> */}

                    {// PBI #102160 
                    /*<DropZone className='mb-2' extendZone="true" onDrop={this.handleUploadFile} style={{ borderStyle: 'none', padding: 'unset' }} multiple={true} disabled={false} width={250} height={50} >
                        <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
                        <Button type='file' className='' variant='success' size='sm' icon='fa-upload' disabled={false} >Upload Certificate</Button>
                      </DropZone> 
                      <CustomerCertificateTable data={state.certs} isLoading={state.loading} controller={controller}></CustomerCertificateTable>
                   */}

                  </Jumbotron>
                </div>
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default SerializedAssetDetailPage;
