import React from 'react';
import LoginService from '../services/LoginService';
import notification from '../../services/Notification';
import Roles from '../utility/Roles';

const authentication = {

    demandRole(roles) {
        const appName = process.env.REACT_APP_APP_NAME;
        const pathName = this.props.location.pathname;

        // Only for admin and liberty customer to view the liberty page and download the reports
        if ((LoginService && LoginService.isCustomer && LoginService.isLibertycustomer && pathName === '/libertyreports')
            || LoginService.isAdministrator){
            return true;
        }
        else if (LoginService && !LoginService.isCustomer && !LoginService.isLibertycustomer && pathName === '/libertyreports'){
            var path = (pathName === '/libertyreports')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName === '/offlinezipstatus'){
            return true;
        }
        else if (!LoginService.isInRole(roles)) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName === '/') {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the requested page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('dropoff')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the requested page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('receiving')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('rejectreason')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('receiving')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        // else if(LoginService && LoginService.isCustomer && pathName.includes('assetrfid'))
        // {
        //   var path = (pathName === '/')
        //   ? 'requested'
        //             : pathName;
        //   notification.action('error').post({title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page'});
        //   this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
        //   return false;
        // }
        else if (LoginService && LoginService.isCustomer && pathName.includes('jobprogress')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('inspection')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('sapjobprogress')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('shippingtickets')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('receivingtickets')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('transactions')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('transferassets')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('shipassets')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('selectservicelevels')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('selectservicelevels')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('shipreceivedassets')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('customerdescription')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('job')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('createasset')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('quotesdetail')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('jobattachments')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('notes')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('containers')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('editasset')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService.isCustomer && pathName.includes('quotesdetail')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService.isCustomer && pathName.includes('/asset/')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('/asset/')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('assemblyworkorder')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        else if (LoginService && LoginService.isCustomer && pathName.includes('assembly')) {
            var path = (pathName === '/')
                ? 'requested'
                : pathName;
            notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page' });
            this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
            return false;
        }
        // else if(LoginService && LoginService.isCustomer && pathName.includes('editfieldasset'))
        // {
        //   var path = (pathName === '/')
        //   ? 'requested'
        //             : pathName;
        //   notification.action('error').post({title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit the ' + path + ' page'});
        //   this.props.history.push(LoginService.getDefaultRoute(LoginService.loginInfo));
        //   return false;
        // }
    },

    demandAdministrator() {
        this.demandRole([Roles.Administrator]);
    },
    demandSuperUser() {
        this.demandRole([Roles.Administrator, Roles.SuperUser]);
    },
    demandJobProgressAsset() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.SAPRep]);
    },

    demandCoordinator() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.SAPRep]);
    },

    demandDataClerk() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.DataClerk]);
    },

    demandSAPRep() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.SAPRep]);
    },

    demandTechnician() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.DataClerk, Roles.Technician, Roles.SAPRep]);
    },

    demandTechnicianWithoutDataClerk() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.Technician, Roles.SAPRep]);
    },

    demandJobNotes() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.SAPRep, Roles.Technician]);
    },

    demandSerializedAssetSearch() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.SAPRep, Roles.Technician]);
    },

    demandViewCustomerDescription() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.SAPRep, Roles.Technician]);
    },

    demandAdhoc() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.SAPRep, Roles.Technician]);
    },

    demandReports() {
        this.demandRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.SAPRep, Roles.Technician, Roles.DataClerk]);
    }
}


const isAuthenticated = () => {
    const appName = process.env.REACT_APP_APP_NAME;
    if (!LoginService.loginInfo) {
        notification.action('error').post({ title: appName + ' Security Violation', msg: 'You do not have the appropriate security rights to visit this page' });
        LoginService.logout();
        return false;
    }
    return true;
};

export default authentication;