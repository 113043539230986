import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Binder from '../../lib/Binder';
import GroupBadge from '../../components/GroupBadge';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import Search from '../components/Search';

const LocationRow = (props) => {

    const location = props.location;
    const className        = 'location ';
    const spanArchived     = location.archived
                            ? <span><i className='fa fa-archive archived' />&nbsp;&nbsp;</span>
                            : null;
    const spanFMCLocation = location.fmcLocation
                            ? <span className='fmc-location'>&nbsp;&nbsp;TechnipFMC</span>
                            : null;

    return (
        <List.Item className={className + ' p-2'} active={location.selected} onClick={() => { props.controller.toggleSelection(location); }} style={{ cursor: 'pointer' }}>
        <div className='row'>
          <div className='col-sm-12'>
            {spanArchived}
            <span className='field-data'>{location.name}</span>
            {spanFMCLocation}
          </div>
        </div>
      </List.Item>
    );
}

class SelectAuthorizedLocationsForContainerDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel ? true : this.props.controller.addLocations();

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const container = controller.state.container;
        const customer = controller.state.customer;
        const locations = controller.state.locations;
        const selectedLocations = controller.getSelectedLocations();
        const count = locations.length;
        let badgeText = controller.state.searchTerm ? 'No results' : ''; // default
        if (count) {
            badgeText = (selectedLocations.length + ' / ' + count + ' selected');
        }
        const header = <span>Please select the locations to add to container <span className='field-data'>{container.description}</span> for <span className='field-data'>{customer.name}</span></span>;

        return (
            <Dialog {...this.props} header='Select Authorized Locations for Container' width={600} height={585} id='select-container-locations-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div>
                        <div className='row'>
                            <div className='col'>
                                <Search className='mb-2' id='searchTerm' label={header} inputClassName='search-input text-uppercase' placeholder='location, city or state' autoFocus='true' bind={binder.bind('searchTerm')} clear={controller.clearSearchTerm} search={controller.locationSearch}></Search>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={this.props.controller.selectAll}>Select All</GroupBadge>
                                <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={this.props.controller.selectNone}>Select None</GroupBadge>
                                <Badge className='m-1' variant='info'>{badgeText}</Badge>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Jumbotron className='locations-list'>
                                    <List id='location-list' className='row'>
                                        {
                                            locations.map(location => {
                                                return <LocationRow key={location.containerLocationId} controller={controller} location={location} />;
                                            })
                                        }
                                    </List>
                                </Jumbotron>
                            </div>
                        </div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons />
            </Dialog>
        );
    }
}

export default SelectAuthorizedLocationsForContainerDialog;