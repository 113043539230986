import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import co from '../../lib/Co';
class InspectionTestNotesDialog extends React.Component{
    handleClose = (result) =>{
        co(this, function* () {
    if (result.Cancel) {
      return true;
    }

    return yield this.props.controller.save();
    });
    }

  render() {
    var binder       = new Binder(this);
    const controller = this.props.controller;
    const errors     = controller.state.errors;

    return (
      <Dialog {...this.props} model={true} className='inspection-notes-dialog' header='Gauges' width={500} height={250} onClose={this.handleClose}>
        <Dialog.Body>
          <div className='notes-content'>           
              {/* <Form.TextArea id='notes' rows={5} bind={binder.bind('notes')} maxLength='1000' /> */}
              <table>
               <thead>
                    <tr>
                      <td>
                        Part No.
                      </td>
                      <td>
                        Serial
                      </td>                    
                      <td>
                        Photo
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td width="40%">
                       1
                      </td>
                      <td width="40%">
                        Serial 1
                      </td>                    
                      <td width="40%">
                        Img
                      </td>
                    </tr>
                  </tbody>
               </table>
          </div>
        </Dialog.Body>
        <Dialog.OKButtons />
      </Dialog>
    );
  }
}

export default InspectionTestNotesDialog;
