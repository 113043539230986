import React from 'react';
import Form from '../../components/Form';
import Dialog from '../../components/Dialog';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';

class TransferToIdleAssetsDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const idleAssetsCount = controller.state.boneyards.length;
    const idleAssetsLabel = Utils.pluralize('location', 's', idleAssetsCount) + ' available';
    const verificationMessage = 'Upon receiving these items, ' + LoginService.loginInfo.fullName + ' acknowledges verification has taken place and all information is correct';
    const assets = controller.state.assets || [];
    const canRemoveFromContainer = assets.reduce((acc, curr) => {
      return acc || (curr.containerId !== Utils.emptyGuid);
    }, false);

    const removeFromContainer = canRemoveFromContainer
      ? <Form.CheckBox label='Remove from Container' bind={binder.bind('removeFromContainer')} />
      : null;

    return (
      <Dialog {...this.props} header={this.props.header} width={575} height={425} id='transfer-to-idle-assets-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <div className='col-md-12'>
              <Badge variant='info'>{idleAssetsLabel}</Badge>
              <Form.AutoComplete id='idle-assets-location' label='Idle Assets or Program Iron Location' className='required' error={controller.state.errors.selectedBoneyard} bind={binder.bind('selectedBoneyard')} data={controller.state.boneyards} minimumResultsForSearch={-1} />
            </div>
            <div className='col-md-12'>
              <Form.AutoComplete id='receiving-location' label='Receiving Location' className='required' error={controller.state.errors.receivingLocation} bind={binder.bind('receivingLocation')} search={controller.receivingLocationSearch()} />
              {/* <Form.AutoComplete label='Cost Center' dataToggle="tooltip" dataPlacement="top" bind={binder.bind('costCenter')} search={Searchers.searchReceivngCostCenter(controller.state.facilityId)}/> */}
            </div>
            <div className='col-md-12'>
              {removeFromContainer}
            </div>
            <div className='col-md-12'>
              <span className='h6 text-center verification-message'>{verificationMessage}</span>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={controller.state.saving} />
      </Dialog>
    );
  }
}

export default TransferToIdleAssetsDialog;
