import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Validator from '../../lib/Validator';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class CustomerLocationController extends BaseController {
    constructor() {
        super('edit-customer-location', CustomerLocationController.getInitialState('', '', ''));
        this.adding = false;
        this._validator = new Validator();
        this._validator.add('locationName', 'Location Name', [{ required: true }]);
    }

    load = (customerLocationId, customerId, customerName) => {
        if (customerLocationId === Utils.emptyGuid) {
            this.adding = true;
            this.state = CustomerLocationController.getInitialState(customerLocationId, customerId, customerName);
            this.commit();
        }
        else {
            this.adding = false;
            ajax.get('lookup/getCustomerLocation/', { customerLocationId: customerLocationId }).then(results => {
                this.merge(results);
                this.commit();
            }).then(result => result).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            }); // CustomerLocationDto
        }
    }

    merge = (location) => {
        if (!location) {
            return;
        }

        this.state = Object.assign({}, location);
        this.state.errors = {};
    }

    static getInitialState = (customerLocationId, customerId, customerName) => {
        return {
            customerLocationId: customerLocationId,
            locationName: '',
            customer: { id: customerId, name: customerName },
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            shale: '',
            region: '',
            country: '',
            shipTo: '',
            costCenter: '',
            soldTo: '',
            fmcLocation: '',
            rental: '',
            archived: false,
            errors: {},
            isSaving: false,
            userStatus: ''
        };
    }

    search = (searchType) => {
        if (!searchType) {
            return null;
        }

        return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), {
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    canSave = () => {
        this._validator.validateAll(this.state);

        var errors = Object.keys(this.state.errors);
        if (!errors.length) {
            return true;
        }

        this.commit();
        return false;
    }

    save = (cb) => {
        if (!this.canSave()) {
            return;
        }
        const s = this.state;
        if (!s.customer || s.customer.id === Utils.emptyGuid) {
            return;
        }
        const locationName = s.locationName;
        let eqStatus = '';
        if (s.userStatus) {
            const userStatusIndex = s.userStatus.name.indexOf('-');
            eqStatus = s.userStatus.name.substring(0, userStatusIndex - 1)
        }
        const parameters = {
            customerLocationId: s.customerLocationId,
            locationName: locationName,
            customer: s.customer,
            address1: s.address1,
            address2: s.address2,
            city: s.city,
            state: s.state,
            postalCode: s.postalCode,
            customerId: s.customer && s.customer.id,
            shaleId: s.shale && s.shale.id,
            regionId: s.region && s.region.id,
            countryId: s.country && s.country.id,
            shipTo: s.shipTo,
            costCenter: s.costCenter,
            soldTo: s.soldTo,
            fmcLocation: s.fmcLocation,
            rental: s.rental,
            archived: s.archived,
            customerName: s.customer && s.customer.name,
            userStatusId: eqStatus
        };
        this.state.isSaving = true;
        this.commit()
        ajax.post('customer/saveLocation', parameters).then(locationId => {
            this.state.isSaving = false;
            this.commit()
            if (this.adding) {
                notification.action('success').post('Saved new customer location ' + locationName);
                if (cb) {
                    this.adding = false;
                    cb(locationId);
                }
            }
            else {
                notification.action('success').post('Saved changes for customer location ' + locationName);
            }
            messages.channel('edit-customer-location').action('saved').post(s.customer.id);
        }).catch(err => {
            this.state.isSaving = false;
            this.commit()
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: CustomerLocationDto, out: Guid

        // use in CustomerController to reload locations
    }
}

export default CustomerLocationController;