import React from 'react';
import Pagination from '../../components/Pagination';

const TopPagination = () => {

    const handleTopPaginationClick = (event) => {
        const target = event.target;
        const name = target.localName === 'span' ? target.parentNode.id : target.id;
        if(name === 'prev'){            
            document.getElementsByName('previous')[0].click();
        }
        else{
            document.getElementsByName('next')[0].click();
        }
    }

    return (
        <Pagination id='topPagination'>
            <Pagination.Prev id='prev' onClick={handleTopPaginationClick} />
            <Pagination.Next id='next' onClick={handleTopPaginationClick} />
        </Pagination>
    );

}

TopPagination.Toggle = () => {
    const gridPagination = document.getElementsByClassName('grid-pagination')[0];
    const count = gridPagination ? gridPagination.children.length : 0;
    if (count) {
        const topPagination = document.getElementById('topPagination');      
        if (gridPagination.children.length > 2 && topPagination.children.length === 2) {            
            if (gridPagination.children[1].classList.contains('disabled')) {
                topPagination.children[0].classList.add('disabled')
            }
            else {
                topPagination.children[0].classList.remove('disabled')
            }
            if (gridPagination.children[count - 2].classList.contains('disabled')) {
                topPagination.children[1].classList.add('disabled')
            }
            else {
                topPagination.children[1].classList.remove('disabled')
            };
        }
    }
}


export default TopPagination;