import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import SignaturePad from '../../components/SignaturePad';
import Controller from '../../mixins/Controller';
import EditDropoffController from '../controllers/EditDropoffController';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import ActionIcon from '../../components/ActionIcon';

const RowsTable = (props) => {
  const controller = props.controller;
  const canEdit = !controller.state.isComplete;  

  const renderRowErrors = () => {
    var rowErrors = controller.state.errors && controller.state.errors.rows;
    if (rowErrors) {
      return <Form.FieldError>{rowErrors}</Form.FieldError>;
    }
  }

  const totalAssetCount = controller.state.rows.reduce((acc, curr) => acc + curr.state.quantity, 0);

  const handleAddNewRow = () => {
    props.controller.addNewRow();
  }

  const handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  const handleKeyUp = (evt) => {
    try {
      const value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }

      if (value > 200) {
        evt.target.value = 200;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }
  
  const divClassName = controller.state.errors.rows ? ' has-error' : '';

  const data = [...controller.state.rows];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        isVisible: canEdit,     
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <ActionIcon  icon='fa-trash' className='remove-handle' action={controller.removeRow} />
        }
      },
      {
        Header: 'Quantity',
        accessor: 'state.quantity',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.NumberInput className='required' type='number' min='1' readOnly={false} bind={binder.bind('quantity')} error={errors.quantity} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
            : cellInfo.cell.value;
        }
      },
      {
        Header: 'Family Code',
        accessor: 'state.familyCode',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete className='required' readOnly={false} bind={binder.bind('familyCode')} error={errors.familyCode} search={parentController.familyCodeSearch()} />
            : cellInfo.cell.value.name;
        }
      },
      {
        Header: 'Nominal Size',
        accessor: 'state.nominalSize',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete className='required' readOnly={false} bind={binder.bind('nominalSize')} error={errors.nominalSize} search={parentController.nominalSizeSearch()} />
            : cellInfo.cell.value.name;
        }
      },
      {
        Header: 'Manufacturer',
        accessor: 'state.manufacturer',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete readOnly={false} bind={binder.bind('manufacturer')} error={errors.manufacturer} search={parentController.manufacturerSearch()} />
            : cellInfo.cell.value.name;
        }
      },
      {
        Header: 'Style',
        accessor: 'state.style',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete readOnly={false} bind={binder.bind('style')} error={errors.style} search={parentController.styleSearch()} />
            : cellInfo.cell.value.name;
        }
      },
      {
        Header: 'Connection Type',
        accessor: 'state.endConnection',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete readOnly={false} bind={binder.bind('endConnection')} error={errors.endConnection} search={parentController.endConnectionSearch()} />
            : cellInfo.cell.value.name;
        }
      },
      {
        Header: 'Length',
        accessor: 'state.length',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete readOnly={false} bind={binder.bind('length')} error={errors.length} search={parentController.lengthSearch()} />
            : cellInfo.cell.value.name;
        }
      },
    ],
    [canEdit]);
    const caption = <span style={{color: '#000000'}}><b>Total Asset Count: </b>{totalAssetCount}</span>
    return (
      <div className={divClassName}>
        <Grid id='results-table' columns={columns} data={data} editable={canEdit} onAddNewRow={handleAddNewRow} caption={caption} showPagination={false}/>
        {renderRowErrors()}
      </div>
  );

}

class EditForm extends React.Component {

  clearSignature = () => {
    this.props.controller.clearSignature();
  }

  handleUpdateSignature = (signature) => {
    this.props.controller.updateSignature(signature);
  }

  handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  handleKeyUp = (evt) => {
    try {
      var value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }
      if (value > 100000) {
        evt.target.value = 100000;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }

  render = () => {
    const binder = this.props.binder;
    const controller = this.props.controller;
    const errors = controller.state.errors;
    const readonly = controller.state.isComplete;
    const canEdit = !readonly;
    const warning = 'Commencement of Charges: I hereby authorize TechnipFMC to perform service activities on the above listed product in accordance with TechnipFMC current invoice terms and conditions and understand that charges will commence when TechnipFMC takes possession of equipment and begins service.  Any terms or conditions contained in your acceptance, purchase order, inquiry, instructions or information to bidders which are in conflict or inconsistent with or add to the provisions of TechnipFMC current invoice terms and conditions, will not be acceptable or become a part of any resulting contract without our specific written consent.  Commencement of work by TechnipFMC shall not constitute consent to other terms or conditions. In accordance with prices in effect at the time of delivery the service charges will be invoiced when the work is complete and/or when the equipment is returned to customer. The invoice will include all labor and parts needed to perform the required service.';
    const signatureDisabled = !!controller.state.dropoffNumber; // has a value -- editing of signatures not allowed
    const signatureClassName = signatureDisabled ? '' : 'required';
    const serviceEnabled = controller.state.destinationJob;
    const fldServiceInterval = canEdit
      ? <Form.AutoComplete label='Service Interval' id='service-interval' className='required' bind={binder.bind('serviceInterval')} error={errors.serviceInterval} search={controller.serviceIntervalSearch()} minimumResultsForSearch={-1} />
      : <Form.StaticData label='Service Interval' value={controller.state.serviceInterval && controller.state.serviceInterval.name} />;
    const fldCompletionDate = canEdit
      ? <Form.Date label='Requested Completion Date' className='required' bind={binder.bind('requestedDate')} error={errors.requestedDate} />
      : <Form.StaticData label='Requested Completion Date' value={controller.state.requestedDate} />;
    const fldService = canEdit
      ? <Form.TextArea rows={3} label='Service to be Performed' className='required' bind={binder.bind('service')} error={errors.service} style={{ marginBottom: '10px' }} />
      : <Form.StaticData label='Service to be Performed' value={controller.state.service} />;

    const serviceFields = serviceEnabled
      ? <div className='row'>
        <div className='col-md-3'>
          {fldServiceInterval}
        </div>
        <div className='col-md-4'>
          {fldCompletionDate}
        </div>
        <div className='col-md-5'>
          {fldService}
        </div>
      </div>
      : null;

    const fldCustomer = canEdit
      ? <Form.AutoComplete label='Customer' id='customer' className='required' bind={binder.bind('customer')} error={errors.customer} search={controller.customerSearch()} />
      : <Form.StaticData label='Customer' value={controller.state.customer.name} />;
    const fldCustomerLocation = canEdit
      ? <Form.AutoComplete label='Customer Location' id='customer-location' className='required' bind={binder.bind('customerLocation')} error={errors.customerLocation} search={controller.customerLocationSearch()} />
      : <Form.StaticData label='Customer Location' value={controller.state.customerLocation.name} />;
    const fldApplication = canEdit
      ? <Form.AutoComplete label='Application' id='application' className='required' bind={binder.bind('application')} error={errors.application} search={controller.applicationSearch()} minimumResultsForSearch={-1} />
      : <Form.StaticData label='Application' value={controller.state.application.name} />;
    const fldEquipmentCategory = canEdit
      ? <Form.AutoComplete label='Equipment Category' id='equipment-category' className='required' bind={binder.bind('equipmentCategory')} error={errors.equipmentCategory} search={controller.equipmentCategorySearch()} minimumResultsForSearch={-1} />
      : <Form.StaticData label='Equipment Category' value={controller.state.equipmentCategory.name} />;
    const fldCustomerPO = canEdit
      ? <Form.Input label='Customer PO Number' disabled={readonly} bind={binder.bind('customerPO')} />
      : <Form.StaticData label='Customer PO Number' value={controller.state.customerPO} />;
    const fldEquipmentDetails = canEdit
      ? <Form.Input label='Equipment Details' className='required' disabled={readonly} bind={binder.bind('equipmentDetails')} error={errors.equipmentDetails} />
      : <Form.StaticData label='Equipment Details' value={controller.state.equipmentDetails} />;
    const fldDestination = canEdit
      ? <div>
        <Form.Label name='The equipment listed above will be:' />
        <Form.RadioInput disabled={readonly} bind={binder.bind('destinationJob')} label='Serviced' />
        <Form.RadioInput disabled={readonly} bind={binder.bind('destinationIdleAssets')} label='Stored until further notice (no service will be performed)'/>
      </div>
      : <Form.StaticData label='The equipment listed above will be' value={controller.state.destinationJob ? 'Serviced' : 'Stored until further notice (no service will be performed)'} />;
    const fldDeliveredBy = canEdit
      ? <Form.Input disabled={signatureDisabled} label='Delivered by' placeholder='please enter your name' className={signatureClassName} bind={binder.bind('deliveredByName')} error={errors.deliveredByName} />
      : <Form.StaticData label='Delivered by' value={controller.state.deliveredByName} />;
    const LoggedInuser = LoginService.loginInfo.fullName;
    const fldHours = (SAPService.isSAPFacility() && SAPService.useTTS() && !signatureDisabled)
      ? <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} error={errors.hours} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
      : null;  
    const fldMinutes = (SAPService.isSAPFacility() && SAPService.useTTS() && !signatureDisabled)
      ? <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
      : null;
    const fldTechnician = (SAPService.isSAPFacility() && SAPService.useTTS() && !signatureDisabled)
      ? <Form.StaticData label='Record Drop-Off Time for User' value={LoggedInuser} />
      : null;
    const dropofftime = (SAPService.isSAPFacility() && SAPService.useTTS() && !signatureDisabled)
      ? <div className='row'>
          <div className='col-md-4'>{fldTechnician}</div>
          {/* <div className='col-md-2'>{fldHours}</div> */}
          <div className='col-md-2'>{fldMinutes}</div>
        </div>
      : null;  
    const signatureLabel = signatureDisabled
      ? 'Signature'
      : 'Sign your name';

    return (
      <div className='col'>
        <div className='row'>
          <div className='col-md-6'>{fldCustomer}</div>
          <div className='col-md-6'>{fldCustomerLocation}</div>
        </div>
        <div className='row'>
          <div className='col-md-6'>{fldApplication}</div>
          <div className='col-md-6'>{fldEquipmentCategory}</div>
        </div>
        <div className='row'>
          <div className='col-md-3'>{fldCustomerPO}</div>
          <div className='col-md-9'>{fldEquipmentDetails}</div>
        </div>
        <div className='row'>
          <div className='col-12'><RowsTable controller={controller} binder={binder} /></div>
        </div>
        <div className='row'>
          <div className='col-12'>{fldDestination}</div>
        </div>
        {serviceFields}
        <div className='row'>
          <div className='col-12'>{fldDeliveredBy}</div>
        </div>
        <div className='row'>
          <div className='col-12'>{dropofftime}</div>
        </div>
        <div className='row'>
          <div className='col-md-4'> <SignaturePad id='signature-pad' className='required' width={400} height={200} label={signatureLabel} onUpdateSignature={this.handleUpdateSignature} signature={controller.state.deliveredBySignature} bind={binder.bind('deliveredBySignature')} error={errors.deliveredBySignature} disabled={signatureDisabled} showClear={true} /> </div>
        </div>
        <div className='row'>
          <div className='col-12'><div className='mt-2'><Form.StaticData label={warning} /></div></div>
        </div>
      </div>
    );
  }
}

const Buttons = (props) => {

  let history = useHistory();

  const handleDownload = () => {
    props.controller.download();
  }

  const handleSave = () => {
    props.controller.save(() => {
      history.goBack();
    });
  }

  const handleCancel = () => {
    history.goBack();
  }

  const controller = props.controller;
  const canEdit = !controller.state.isComplete;
  const editMode = controller.adding;
  const isSaveInProgress = controller.state.isSaveInProgress;
  const isDownloadInProgress = controller.state.isDownloadInProgress;
  const saveButton = canEdit
    ? <Button id='btn-save' icon='fa-save'  variant='primary' size='sm' className='edit-button action-button m-1' disabled={isSaveInProgress} onClick={handleSave}>Save</Button>
    : null;
  const cancelText = canEdit ? 'Cancel' : 'Close';
  const downloadButton = !editMode
    ? <Button id='btn-download' icon='fa-download'  variant='info' size='sm' className='edit-button action-button m-1' onClick={handleDownload} disabled={isDownloadInProgress}>Download</Button>
    : null;
  const layoutClass =  editMode ? 'justify-content-end' : 'justify-content-between';
  return (
    <div className='col-12'>
     <div className={'mb-1 d-flex ' + layoutClass }>
        {downloadButton}
        <div>
          {saveButton}
          <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='action-button edit-button m-1' onClick={handleCancel}>{cancelText}</Button>
        </div>
     </div>
    </div>
  );
}

const EditDropoffPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle}/>;

class EditDropoffPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    const param = this.props.match.params;
    this.props.controller.load(param.dropoffId);
  }

  static defaultProps = {
    controller: new EditDropoffController()
  }

  componentDidMount = () => {
    this.initializeMixin();
    //const param = this.props.match.params;
    //this.props.controller.load(param.dropoffId);
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const number = controller.state.dropoffNumber;
    const title = (controller.adding ? 'Create' : controller.state.isComplete ? 'View' : 'Edit') + ' Drop-Off' + (number ? (' — ' + number) : '');
    return (
      <Page {...this.props} pageTitle='InteServ · Edit Drop-Off' id='edit-dropoff-page'>
        <EditDropoffPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <EditForm controller={controller} binder={binder} />
            <Buttons controller={controller} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default EditDropoffPage;