import React, { useMemo, useEffect } from 'react';
import store from 'store';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import CustomerServiceLevelController from '../controllers/CustomerServiceLevelController';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Badge from '../../components/Badge';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';

const TestRowsTable = (props) => {
  const controller = props.controller;

  const renderRowErrors = () => {
    var rowErrors = controller.state.errors && controller.state.errors.tests;
    if (rowErrors) {
      return <Form.FieldError>{rowErrors}</Form.FieldError>;
    }
  }

  const handleAddNewRow = () => {
    controller.addNewTestRow();
  }

  const handleOperationCodeKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  const validateOperationOrder = (evt) => {
    let minValue = 1;
    const maxValue = controller.state.tests.length; // bit of a code smell
    const value = parseInt(evt.target.value, 10);
    if (isNaN(value)) {
      evt.target.value = minValue;
    }
    else if (value < minValue) {
      evt.target.value = minValue;
    }
    else if (value > maxValue) {
      evt.target.value = maxValue;
    }
  }
  const handleAutoCodeTests = () => {
    controller.autoCodeTests();
  }

  const divClassName = controller.state.errors.rows ? ' has-error' : '';
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const data = [...controller.state.tests];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return isSuperUser ? <span></span> : <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
        }
      },
      {
        Header: 'Master Service Level Test',
        accessor: 'state.parentServiceLevelTest',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return <Form.AutoComplete readOnly={false} readOnly={isSuperUser} bind={binder.bind('parentServiceLevelTest')} error={errors.parentServiceLevelTest} className='required' placeholder='select master service level test' search={parentController.search('service level test')} />
        }
      },
      {
        Header: 'Test Name',
        accessor: 'state.testName',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('testName')} readOnly={isSuperUser} error={errors.testName} />
        }
      },
      {
        Header: 'Code',
        accessor: 'state.operationCode',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('operationCode')} readOnly={isSuperUser} error={errors.operationCode} onKeyPress={handleOperationCodeKeyPress} />
        }
      },
      {
        Header: 'Order',
        accessor: 'state.operationOrder',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input type='number' bind={binder.bind('operationOrder')} readOnly={isSuperUser} error={errors.operationOrder} onInput={validateOperationOrder} />
        }
      },
    ],
    []);
  return (
    <div className={divClassName}>
      <div className='d-flex justify-content-end'>
        <div className='mt-2 mr-2'><Badge variant='info'>{data.length}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} addNewRowText='Click to add a new test' editable={true} onAddNewRow={handleAddNewRow} canAddNewRow={!isSuperUser} showPagination={false} />
      <div className='float-right'>
        <Button icon='fas fa-sort-numeric-down' variant='warning' size='sm' className='m-1' disabled={!data.length || isSuperUser} onClick={handleAutoCodeTests}>Auto-code Tests</Button>
      </div>
      {renderRowErrors()}
    </div>
  );
}

const BOMRowsTable = (props) => {
  const controller = props.controller;

  const renderRowErrors = () => {
    var rowErrors = controller.state.errors && controller.state.errors.tests;
    if (rowErrors) {
      return <Form.FieldError>{rowErrors}</Form.FieldError>;
    }
  }

  const handleAddNewRow = () => {
    props.controller.addNewBOMRow();
  }

  const handleAddDefaultBOM = () => {
    props.controller.addDefaultBOMRow();
  }

  const divClassName = controller.state.errors.rows ? ' has-error' : '';
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const data = [...controller.state.boms];
  const disableAddDefault= controller.state.boms.filter(x=>x.state.bom?.name=='N - No BOM Required').length>0;
  const isPrototypeCustomer = props.controller.state.customer && props.controller.state.customer.name === 'Prototype Customer Service Levels';
  const canAddNewBOMRow = !isSuperUser ;
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          const isNewRow =  controller.state.customerServiceLevelBOMId != Utils.undefined;
          return (isSuperUser && isNewRow) ? <span></span> : <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
        }
      },
      {
        Header: 'Bill of Material',
        accessor: 'state.bom',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          const isNewRow = controller.state.customerServiceLevelBOMId != Utils.emptyGuid;
          return <Form.AutoComplete readOnly={isSuperUser && isNewRow} bind={binder.bind('bom')} error={errors.parentServiceLevelBOM} className='required' placeholder='select Bill of Material' search={parentController.bomSearch()} />
        }
      },
    ],
    []);
  return (
    <div className={divClassName}>
      <div className='d-flex justify-content-end'>
        <div className='mt-2 mr-2'><Badge variant='info'>{data.length}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} canAddNewRow={canAddNewBOMRow} addNewRowText='Click to add a new Bill of Material' editable={true} onAddNewRow={handleAddNewRow} canAddDefaultValueButton={ true } onAddDefaultValue={handleAddDefaultBOM} disableAddDefaultValueButton={disableAddDefault} showPagination={false} />
      {renderRowErrors()}
    </div>
  );
}

const EditForm = (props) => {

  const handleDuplicate = () => {
    props.controller.duplicate();
  }

  const getData = () => {
    return props.controller.state.assignedLaborCodes || [];
  }

  const binder = props.binder;
  const controller = props.controller;
  const errors = controller.state.errors;
  const customer = controller.state.customer;
  const isPrototypeCustomer = customer && customer.name === 'Prototype Customer Service Levels';
  const isSAPServiceLevel = controller.state.isSAPServiceLevel;
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  // for use in 'Change Service Level' AutoComplete
  const sapMarker = isSAPServiceLevel ? <span className='user-facility-sap'> SAP</span> : null;
  const sapLabel = <span>SAP Service Level {sapMarker}</span>;
  const formatResult = item => {
    var spanSAP = item.result.isSAPServiceLevel ? <span className='sap-service-level pull-right select2-tag'> SAP</span> : null;
    if (item.result.isSAPServiceLevel) {
      return <span>{item.text} {spanSAP}</span>;
    }
    return <span>{item.text}</span>;
  };

  return (
    <div className='row'>
      <div className='col-md-12 h3'>{customer && customer.name}</div>

      {isPrototypeCustomer && <div className='col-md-12 mb-2'><Button variant='info' size='sm' className='m-1' disabled={isSuperUser} onClick={handleDuplicate}>Duplicate</Button></div>}

      <div className='col-md-4'><Form.AutoComplete label='Master Service Level 1' bind={binder.bind('parentServiceLevel')} placeholder='select master service level' readOnly={isSuperUser} search={controller.search('service level')} className='required' error={errors.parentServiceLevel} /></div>
      <div className='col-md-4'><Form.Input label='Customer Service Level Name' bind={binder.bind('customerServiceLevelName')} className='required' readOnly={isSuperUser} error={errors.customerServiceLevelName} /></div>
      <div className='col-md-4'><Form.Input type='number' label='Interval Days' bind={binder.bind('intervalDays')} readOnly={isSuperUser} error={errors.intervalDays} /></div>

      <div className='col-md-4'><Form.Input label='Alternate Name' bind={binder.bind('alternateName')} readOnly={isSuperUser} /></div>
      <div className='col-md-4'><Form.AutoComplete label='TechnipFMC Service Level' hint='Select Level 1 for required annual service; Select Level 2 for annual teardown service' readOnly={isSuperUser} bind={binder.bind('fmcServiceLevel')} placeholder='select fmc service level' search={controller.search('fmc service level')} /></div>
      <div className='col-md-4'><Form.Input type='number' label='Hierarchy Level' readOnly={isSuperUser} bind={binder.bind('hierarchyLevel')} error={errors.hierarchyLevel} /></div>

      <div className='col-md-2'><Form.CheckBox disabled={isSuperUser} label='Required' bind={binder.bind('required')} /></div>
      <div className='col-md-2'><Form.CheckBox disabled={isSuperUser} label='Active' bind={binder.bind('active')} /></div>
      <div className='col-md-2'><Form.CheckBox disabled={isSuperUser} label={sapLabel} bind={binder.bind('isSAPServiceLevel')} /></div>
      <div className='col-md-2'><Form.CheckBox disabled={isSuperUser} label='NISD' bind={binder.bind('nisd')} /></div>
      <div className='col-md-3'><Form.AutoComplete label='Change Service Level' readOnly={isSuperUser} bind={binder.bind('selectedServiceLevel')} placeholder='load different service level' search={controller.customerServiceLevelSearch()} formatResult={formatResult} formatSelection={formatResult} /></div>

      <div className='col-md-12'>
        <Form.Header className='alert-warning col-md-12'>Tests</Form.Header>
        <TestRowsTable controller={controller} binder={binder} />
      </div>

      {isSAPServiceLevel &&
        <div className='col-md-12'>
          <Form.Header className='alert-warning'>Bills of Material</Form.Header>
          <div><BOMRowsTable controller={controller} binder={binder} /></div>
        </div>
      }
    </div>
  );
}

const Buttons = (props) => {

  let history = useHistory();
  const disableSave = LoginService.isInRole(Roles.SuperUser) && (props.controller.state.newRowCount === undefined || props.controller.state.newRowCount < 1);
  const handleSave = () => {
    props.controller.save((customerServiceLevelId) => {
      // after saving - navigate to same page with new id in address bar
      history.replace('/customerservicelevel/' + customerServiceLevelId);
    });
  }

  const handleClose = () => {
    history.goBack();
  }

  return (
    <div>
      <div className='edit-buttons float-right mr-1 mt-2'>
        <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving || disableSave}>Save</Button>
        <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const CustomerServiceLevelPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class CustomerServiceLevelPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const data = store.get('edit-customer-service-level');
    const customerServiceLevelId = this.props.match.params.customerServiceLevelId;
    const prototypeServiceLevelId = data && data.prototypeServiceLevelId;
    const customerId = data && data.customerId;
    const customerName = data && data.customerName;
    this.props.controller.load(customerServiceLevelId, prototypeServiceLevelId, customerId, customerName);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new CustomerServiceLevelController()
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const name = controller.state.customerServiceLevelName;
    const title = (controller.adding ? 'Create ' : 'Edit ') + 'Customer Service Level' + (name ? (' — ' + name) : '');

    return (
      <Page {...this.props} pageTitle='InteServ · Customer Service Levels' id='edit-cust-service-level-page'>
        <CustomerServiceLevelPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <EditForm controller={controller} binder={binder} />
              <Buttons controller={controller} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default CustomerServiceLevelPage;
