import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';


class SerialReadingHeaderRowController extends BaseController {
  constructor(parentController, serialHeader) {
    super('serial-reading-header-'+  SerialReadingHeaderRowController.key++, {
      serialId         : serialHeader ? serialHeader.serialId        : '',
      jobSOWId         : serialHeader ? serialHeader.jobSOWId        : '',
      serial           : serialHeader ? serialHeader.serial          : '',
      groupName        : serialHeader ? serialHeader.groupName       : '',
      groupNumber      : serialHeader ? serialHeader.groupNumber     : '',
      description      : serialHeader ? serialHeader.description     : '',
      downloadToScope  : serialHeader ? serialHeader.downloadToScope : false,
      collapsed        : serialHeader ? serialHeader.collapsed       : true,
      assetStatus      : serialHeader ? serialHeader.assetStatus     : ''
    });
    this.parentController = parentController;
  }

  static key = 1;


  toggleSerialWithoutReadingSelection = () => {
    this.state.downloadToScope = !this.state.downloadToScope;
    this.parentController.commit();
    this.commit();
  }

  downloadToScopeChanged = () => {
    const parameters={
      jobId : this.parentController.state.selectedJob.id,
      jobSOWId : this.state.jobSOWId,
      downloadToScope : this.state.downloadToScope
    }
    co(this, function* () {
      try {
        const result = yield ajax.get('gauge/markAssetToDownloadBySow', parameters);
        this.state.downloadToScope = result;
        this.commit();
      }
      catch (err) {
        notifications.action('error').post({msg: err.message, title: 'Error marking Serials to download'});
      }
    });
    this.parentController.commit();
  }
}
export default SerialReadingHeaderRowController;
