import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Router, useHistory, useLocation } from 'react-router-dom';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import GroupBadge from '../../components/GroupBadge';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import TransferAssetsToJobController from '../controllers/TransferAssetsToJobController';
import Authentication from '../services/authentication';
import Utils from '../utility/Utils';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import JobSummary from '../views/JobSummary';

const Asset = (props) => {

    const toggleSelection = (asset) => {
        props.controller.toggleSelection(asset);
    }

    const selectAndTransfer = (asset) => {
        props.controller.selectAndTransfer(asset);
    }

    const controller = props.controller;
    const asset = props.asset;
    const className = 'list-group-item asset';
    const assetStatus = asset.assetStatus;
    let rejectReason = asset.rejectReason;
    let statusColourClass = 'asset-status status' + asset.assetStatus; // e.g.: statusScrapped
    if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
        statusColourClass = 'asset-status statusWeldRepair'
    if (asset.assetStatus === 'OS/Machining Repair') 
        statusColourClass = 'asset-status statusOSRepair'
    if (controller.isAssetScrapped(asset)) {
        if (asset.assetStatus != 'Passed' && asset.assetStatus != 'Failed Inspection')
            rejectReason = <span> &ndash; {rejectReason}</span>;
    }

    return (
        <List.Item className={className + ' p-2'} active={asset.selected} onClick={() => { toggleSelection(asset); }} onDoubleClick={() => { selectAndTransfer(asset); }} >
            <Badge variant='info'> {asset.groupNumber} </Badge>&nbsp;&nbsp;
          <span className='field-data'>{asset.primarySerialNumber}</span>
            <span>{asset.description}</span>
            <span id='asset-status' className={statusColourClass}> {assetStatus}</span>
            {assetStatus != 'Passed' ? <span className={statusColourClass}>{rejectReason}</span> : null}
        </List.Item>
    );
}

const Buttons = (props) => {
    let history = useHistory();

    const handleSave = () => {
        props.controller.save(() => {
            history.goBack();
        });
    }

    const handleClose = () => {
        history.goBack();
    }

    return (
        <div className='col-12'>
            <div className='d-flex justify-content-end'>
                <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button>
                <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
            </div>
        </div>
    );
}

const TransferButtons = (props) => {

    const select = (evt) => {
        evt.preventDefault();
        props.controller.moveToSelected();
    }

    const unselect = (evt) => {
        evt.preventDefault();
        props.controller.removeFromSelected();
    }

    let selectDisabled = false;
    let unselectDisabled = false;

    return (
        <div id='transfer-buttons' className='mt-5 col-md-2'>
            <div className='row'>
                <div className='col-md-12'>
                    <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
                    <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect}>Unselect</Button>
                </div>
            </div>
        </div>
    );
}


const AssetsToTransfer = (props) => {
    const selectNone = (evt) => {
        evt.preventDefault();
        props.controller.selectNoSelectedAssets();
    }

    const selectAll = (evt) => {
        evt.preventDefault();
        props.controller.selectAllSelectedAssets();
    }

    const controller = props.controller;
    const assetsToTransfer = controller.state.selectedAssets || [];
    const selectedLabel = Utils.pluralize('selected asset', 's', assetsToTransfer.length);

    return (
        <div className='col-md-5'>
            <div className='col-md-12 mb-2'>
                <div className='row'>
                    <div className='d-flex justify-content-start'>
                        <Button icon='fa-square' variant='success' size='sm' className='mr-1' onClick={selectAll}>Select All</Button>
                        <Button icon='far fa-square' variant='danger' size='sm' className='mr-1' onClick={selectNone}>Select None</Button>
                        <div><Badge variant='info'>{selectedLabel}</Badge></div>
                    </div>
                </div>
            </div>
            <Jumbotron className='col-md-12'>
                <div className='row'>
                    <List className='list-group asset-list'>
                        {
                            assetsToTransfer.map(asset => {
                                return <Asset key={asset.jobSOWId} controller={controller} asset={asset} />;
                            })
                        }
                    </List>
                </div>
            </Jumbotron>
        </div>
    );
}

const AllAssets = (props) => {

    useEffect(() => {
        props.controller.state.multiSelectGroups = '';
        props.controller.commit();
    }, []);

    const selectNone = (evt) => {
        evt.preventDefault();
        props.controller.selectNoAssets();
    }

    const selectAll = (evt) => {
        evt.preventDefault();
        props.controller.selectAllAssets();
    }

    const selectScrapped = (evt) => {
        evt.preventDefault();
        props.controller.selectScrapped();
    }
    
    const controller = props.controller;
    const binder = props.binder;
    let assets = controller.state.assets || [];
    assets.sort(function (a, b) {
        // first, sort by group number
        if (a.groupNumber !== b.groupNumber) {
            return (a.groupNumber - b.groupNumber);
        }

        // then by serial number
        return a.primarySerialNumber.localeCompare(b.primarySerialNumber);
    });

    const selectedAssets = controller.state.selectedAssets || [];
    const availableLabel = assets.length + ' available asset' + ((assets.length === 1) ? '' : 's');
    const assetListContents = assets.length
        ? assets.map(asset => {
            return <Asset key={asset.jobSOWId} controller={controller} asset={asset} />;
        })
        : selectedAssets.length // don't display message if all they've done is transfer all assets to selected list
            ? null
            : <li className='list-group-item'>No assets are available to transfer from this job</li>;

    return (
        <div className='col-md-5'>
            <div className='col-md-12 mb-2'>
                <div className='row'>
                    <div className='d-flex justify-content-start'>
                        <Button icon='fa-square' variant='success' size='sm' className='mr-1' onClick={selectAll}>Select All</Button>
                        <Button icon='far fa-square' variant='danger' size='sm' className='mr-1' onClick={selectNone}>Select None</Button>
                        <Button icon='far fa-thumbs-down' variant='info' size='sm' className='m-1' onClick={selectScrapped}>Select Scrapped</Button>
                       <div> <Badge variant='info'>{availableLabel}</Badge></div>
                    </div>
                </div>
            </div>
            <Jumbotron className='col-md-12'>
                <div className='row'>
                    <List className='list-group asset-list'>
                        {assetListContents}
                    </List>
                </div>
            </Jumbotron>
        </div>
    );
}

const TransferAssetsPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} icon='fa-exchange-alt' />

class TransferAssetsToJobPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        const jobId = this.props.match.params.jobId;
        if (!jobId) {
            this.props.history.goBack();
        }
        this.props.controller.load(jobId);
    }

    static defaultProps = {
        controller: new TransferAssetsToJobController()
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
    }

    handleGroupClick = (groupNumber) => {
        const controller = this.props.controller;
        controller.toggleGroupSelection(groupNumber);
    }

    renderGroups = (fnOnClick, groups) => {
        if (!groups) {
            return 'No Groups';
        }
        return groups.map(group => {
            const variant = (group.selected) ? 'danger' : 'secondary';
            const groupDisplay = group.value.groupName ? group.value.groupName : group.value.groupNumber;
            return <GroupBadge key={group.value.groupNumber} variant={variant} className={'mr-1 mb-1'} onClick={fnOnClick.bind(this, group.value.groupNumber)} dataToggle="tooltip" dataPlacement="top" title={group.value.groupName}> {groupDisplay}</GroupBadge>;
        });
    }

    clearGroupSelection = () => {
        this.props.controller.deselectAllGroups();
    }

    render = () => {
        const controller = this.props.controller;
        const job = controller.state.job;
        const binder = new Binder(this);
        const labelText = 'Select the assets you wish to transfer';
        const customerName = job && job.customerName;
        const facilityName = job && job.facilityName;
        const jobNumber = job && job.jobNumber;
        const newJobLabel = <span>Active Jobs for <span className='font-weight-bold'>{customerName}</span> at <span className='bold-label'>{facilityName}</span></span>;
        const header = 'Transferring Assets for Job ' + jobNumber;
        const selectedAssets = controller.state.selectedAssets || [];
        const canRemoveFromContainer = selectedAssets.reduce((acc, curr) => {
            return acc || (curr.containerId !== Utils.emptyGuid);
        }, false);

        const removeFromContainer = canRemoveFromContainer
            ? <div className='col-sm-4'>
                <Form.CheckBox label='Remove from Container' bind={binder.bind('removeFromContainer')} />
            </div>
            : null;

        const groups = binder.bind('groups')() || [];
        let distinctGroup = [];
        let distinctGroupWithNames = [];
        groups.forEach(group => {
            if (distinctGroup.indexOf(group.value.groupNumber) < 0) {
                const name = group.value.groupName ? (group.value.groupNumber).toString() + ' - ' + group.value.groupName : group.value.groupNumber.toString();
                distinctGroup.push(group.value.groupNumber);
                distinctGroupWithNames.push({ id: group.value.groupNumber, name: name });
            }
        });

        const groupView = distinctGroup.length <= 10
            ? <TransitionGroup component='div'>
                {this.renderGroups(this.handleGroupClick, groups)}
            </TransitionGroup>
            : <Form.MultiSelect id='group-multiselect' className='groupNumber-select' placeholder='Click here to select Groups' bind={binder.bind('multiSelectGroups')} search={Utils.createSearch(distinctGroupWithNames)} style={{ height: 'auto' }} />;

        var formatResult = item => {
            var result = `${item.result?.jobNumber}`;
            if(item.result.jobDescription && item.result.jobDescription.length != 0
                && item.result.jobDescription != ' '){
                result+= ` - ${item.result?.jobDescription}`;
            }
            return <span>{result}</span>;
        };
        return (
            <Page {...this.props} pageTitle='InteServ · Transfer Assets' id='transfer-assets-page'>
                <TransferAssetsPageHeader pageTitle={header} />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-12'>
                                <div className='d-flex justify-content-start'>
                                    <Form.Label name='Groups' prependLabelIcon='fa-filter' />
                                    <GroupBadge variant='danger' className='ml-2' pill={false} onClick={this.clearGroupSelection} >Clear Group Selection</GroupBadge>
                                </div>
                        </div>
                        <div className='col-md-12'>
                            {groupView}
                        </div>
                        <div className='col-md-12'>
                            <h5>{labelText}</h5>
                            <div className='row'>
                                <AllAssets controller={controller} binder={binder} />
                                <TransferButtons controller={controller} />
                                <AssetsToTransfer controller={controller} />
                            </div>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <Form.AutoComplete formatResult={formatResult} label={newJobLabel} id='transfer-target-job' className='required' bind={binder.bind('newJob')} search={controller.activeJobSearch()} />
                                </div>
                                <div className='col-md-5 pl-5'>
                                    <div className='row'>
                                        <div style={{width: '100px'}}><Form.AutoComplete className='pr-2' label='Group #' dataToggle="tooltip" dataPlacement="top" title="Leave blank to auto-assign" bind={binder.bind('groupNumber')} search={controller.groupNumberSearch()} minimumResultsForSearch={-1} /></div>
                                        <div className='pl-3'><Form.Input label='Group Name' bind={binder.bind('groupName')} maxLength="10" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'><Form.Input label='Requesting Customer' hint='(optional)' bind={binder.bind('requestingCustomer')} /></div>
                                {removeFromContainer}
                            </div>
                        </div>
                        <Buttons controller={controller} />
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default TransferAssetsToJobPage;
