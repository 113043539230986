import React, { useMemo } from 'react';
import Badge from '../../components/Badge';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import Table from '../../components/Table';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Roles from '../utility/Roles';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';

const PerformedTestRow = (props) => {
  const wasScrappedOnThisServiceLevelTest = (data, scrapDetails) => {
    return (data && scrapDetails && (data.jobSOWSlTestId === scrapDetails.jobSOWSlTestId));
  }
    const data              = props.data;
    const scrapDetails      = props.scrapDetails;
    const scrappedIndicator = wasScrappedOnThisServiceLevelTest(data, scrapDetails)
                              ? <ActionIcon icon = 'fa-circle' className='statusScrapped' title='Asset was scrapped on this service level test' />
                              : null;
    const controller        = props.controller;
    return (
      <Table.Row>       
        <Table.Data>{data.testName}</Table.Data>
        <Table.Data>{scrappedIndicator} {data.testResult}</Table.Data>
        <Table.Data>{data.technician}</Table.Data>
        <Table.Data>{DateTimeFormatter.formatDate(data.testDate)}</Table.Data>
      </Table.Row>
    );
}

const ThirdPartyRow = (props) => {
    const data = props.data;

    return (
      <Table.Row>
        <Table.Data colSpan={2} />
        <Table.Data>{data.thirdPartyJobNumber}</Table.Data>
        <Table.Data colSpan={2}>{data.certificate}</Table.Data>
      </Table.Row>
    );
}

const TimeTrackingRow = (props) => {

    const data = props.data;

    return (
      <Table.Row>
        <Table.Data><span className='ml-4'>{data.testName}</span></Table.Data>
        <Table.Data>{data.technician && data.technician.name}</Table.Data>
        <Table.Data className='text-right'>{DateTimeFormatter.formatDuration(data.calculatedDuration) || '0m'}</Table.Data>
        <Table.Data colSpan={2}>{DateTimeFormatter.formatDate(data.stopTime)}</Table.Data>
      </Table.Row>
    );
}

const WallReadingRow = (props) => {
    const data  = props.data;
    const value = data.readingValue === null
                  ? <span className='field-label font-italic' style={{fontSize: '12px'}}>no reading</span>
                  : data.readingValue;

    return (
      <Table.Row>
        <Table.Data className='text-right'><span className='ml-3'>{data.readingId}</span></Table.Data>
        <Table.Data>{data.readingType}</Table.Data>
        <Table.Data>{data.newDimension}</Table.Data>
        <Table.Data>{data.minimumDimension}</Table.Data>
        <Table.Data>{value}</Table.Data>
      </Table.Row>
    );
}

const TorqueReadingRow = (props) => {

    const data    = props.data;
    const reading = data.actualReading === null
                  ? <span className='field-label font-italic' style={{fontSize: '12px'}}>no reading</span>
                  : data.actualReading;

    return (
      <Table.Row>
        <Table.Data className='text-right'><span className='ml-3'>{data.readingId}</span></Table.Data>
        <Table.Data>{data.readingType}</Table.Data>
        <Table.Data>{data.torqueLocation}</Table.Data>
        <Table.Data>{data.torqueRange}</Table.Data>
        <Table.Data colSpan={2}>{reading}</Table.Data>
      </Table.Row>
    );
}

const DimensionValueRow = (props) => {
    const data  = props.data;
    const dimension = data.actualDimension === null
                  ? <span className='field-label font-italic' style={{fontSize: '12px'}}>no dimension</span>
                  : data.actualDimension;

    return (
      <Table.Row>
        <Table.Data className='text-right'><span className='ml-3'>{data.dimensionId}</span></Table.Data>
        <Table.Data>{data.readingType}</Table.Data>
        <Table.Data>{data.minimumDimension}</Table.Data>
        <Table.Data colSpan={2}>{dimension}</Table.Data>
      </Table.Row>
    );
}

const ServiceHistoryTable = (props) => {

  let history = useHistory();

  const canNavigateToInspection = (data) => {
    if (data.isComplete && LoginService.isInRole(Roles.Technician)) {
      return false;
    }
    return (LoginService.isTechnician && (data.facilityId === LoginService.loginInfo.facilityId));
  }

  const getJobHeader = (job) => {
    const jobValue  =<span>{job.jobNumber} &ndash; {job.facilityName}</span>;;
    const jobControl = canNavigateToInspection(job)
                      ? <Form.Link to={'/inspection/' + job.jobId} value={jobValue} />
                      : jobValue;
    const count      = (job.performedServiceLevels.length === 25)
                      ? 'last 25'
                      : job.performedServiceLevels.length;

    return (
        <Table.Row className='service-history-job-header'>
        <Table.Data colSpan={3}>{jobControl}</Table.Data>
        <Table.Data>{DateTimeFormatter.formatDate(job.jobDate)}</Table.Data>
        <Table.Data><Badge variant='info' className='float-right'>{count}</Badge></Table.Data>
      </Table.Row>
    );
  }

  const handleToggleCollapsed = (performedServiceLevel) => {
    props.handleToggleCollapsed(performedServiceLevel);
  }

  const hasChildren = (performedServiceLevel) => {
    return (performedServiceLevel.performedTests.length || performedServiceLevel.timeTrackingResults.length || performedServiceLevel.wallReadings.length || (performedServiceLevel.thirdParty !== null));
  }

  const wasScrappedOnThisServiceLevel = (data, scrapDetails) => {
    return (data && scrapDetails && (data.jobSOWServiceLevelId === scrapDetails.jobSOWSlId));
  }

  const getPerformedServiceLevelHeader = (performedServiceLevel, scrapDetails) => {
    let actionButton = null;
    if (hasChildren(performedServiceLevel)) {
      actionButton = performedServiceLevel.collapsed
                      ? <ActionIcon icon='fa-plus-square'  className='btn-expand-collapse' action={() => handleToggleCollapsed(performedServiceLevel)} />
                      : <ActionIcon icon='fa-minus-square' className='btn-expand-collapse' action={() => handleToggleCollapsed(performedServiceLevel)} />;
    }
    else {
      actionButton = <ActionIcon className='ml-2'icon='' action={() => {}} />;
    }

    const scrappedIndicator = wasScrappedOnThisServiceLevel(performedServiceLevel, scrapDetails)
                          ? <ActionIcon icon='fa-circle' className='statusScrapped' title='Asset was scrapped on this service level' />
                          : null;

    return (
      <Table.Row className='service-history-performed-service-level-header'>
        <Table.Data colSpan={5}><span className='font-italic ml-2' style={{ fontWeight: 'normal', fontSize: '12px'}}>{actionButton} Service Level &ndash;</span> {performedServiceLevel.serviceLevelName} {scrappedIndicator}{performedServiceLevel.serviceOrderNumber?(' - '+performedServiceLevel.serviceOrderNumber):''} {'  -  '+performedServiceLevel.status}</Table.Data>
      </Table.Row>
    );
  }

  const getThirdPartyHeader = () => {
    return (
      <Table.Row className='service-history-performed-test-header'>
        <Table.Data colSpan={2}><span style={{marginLeft: '30px'}}>Third Party</span></Table.Data>
        <Table.Data>Third Party Job Number</Table.Data>
        <Table.Data colSpan={2}>Certificate</Table.Data>
      </Table.Row>
    );
  }

  const getPerformedTestHeader = () => {
    return (     
      <Table.Row className='service-history-performed-test-header'>       
        <Table.Data>Test Name</Table.Data>
        <Table.Data>Status</Table.Data>
        <Table.Data>Technician</Table.Data>
        <Table.Data>Test Date</Table.Data>
      </Table.Row>
    );
  }

  const getTimeTrackingHeader = (timeTrackingResults) => {
    const totalTime = timeTrackingResults.reduce((acc, curr) => { return (acc + curr.calculatedDuration); }, 0);
    return (
      <Table.Row className='service-history-time-tracking-header'>
        <Table.Data colSpan={2}><span className='ml-3'>Time Tracking</span></Table.Data>
        <Table.Data className='text-right'>{DateTimeFormatter.formatDuration(totalTime) || '0m'}</Table.Data>
        <Table.Data colSpan={2} />
      </Table.Row>
    );
  }

  const getWallReadingHeader = () => {
    return (
      <Table.Row className='service-history-wall-reading-header'>
        <Table.Data><span className='ml-3'>Wall Readings</span></Table.Data>
        <Table.Data>Type</Table.Data>
        <Table.Data>New Dimension</Table.Data>
        <Table.Data>Minimum Dimension</Table.Data>
        <Table.Data>Measured Value</Table.Data>
      </Table.Row>
    );
  }

  const getTorqueReadingHeader = () => {
    return (
      <Table.Row className='service-history-torque-reading-header'>
        <Table.Data><span className='ml-3'>Torque Readings</span></Table.Data>
        <Table.Data>Type</Table.Data>
        <Table.Data>Torque Location</Table.Data>
        <Table.Data>Minimum Range</Table.Data>
        <Table.Data colSpan={2}>Actual Reading</Table.Data>
      </Table.Row>
    );
  }

  const getDimensionValueHeader = () => {
    return (
      <Table.Row className='service-history-dimension-value-header'>
        <Table.Data><span className='ml-3'>Dimensions</span></Table.Data>
        <Table.Data>Type</Table.Data>
        <Table.Data>Minimum Dimension</Table.Data>
        <Table.Data colSpan={2}>Actual Dimension</Table.Data>
      </Table.Row>
    );
  }

  const addWallReadings = (rows, performedServiceLevel) => {
    const wallReadings = performedServiceLevel.wallReadings || [];
    if (wallReadings.length) {
      rows.push(getWallReadingHeader());
    }

    wallReadings.forEach((wallReading, i) => {
      var wallReadingRow = <WallReadingRow key={'wr-' + i} data={wallReading} />;
      rows.push(wallReadingRow);
    });
  }

  const addTorqueReadings = (rows, performedServiceLevel) => {
    const torqueReadings = performedServiceLevel.torqueReadings || [];
    if (torqueReadings.length) {
      rows.push(getTorqueReadingHeader());
    }

    torqueReadings.forEach((torqueReading, i) => {
      const torqueReadingRow = <TorqueReadingRow key={'tr-' + i} data={torqueReading} />;
      rows.push(torqueReadingRow);
    });
  }

  const addDimensionValues = (rows, performedServiceLevel) => {
    const dimensionValues = performedServiceLevel.dimensionValues || [];
    if (dimensionValues.length) {
      rows.push(getDimensionValueHeader());
    }

    dimensionValues.forEach((dimensionValue, i) => {
      const dimensionValueRow = <DimensionValueRow key={'dv-' + i} data={dimensionValue} />;
      rows.push(dimensionValueRow);
    });
  }

  const addTimeTrackingResults = (rows, performedServiceLevel) => {
    const timeTrackingResults = performedServiceLevel.timeTrackingResults || [];
    if (timeTrackingResults.length) {
      rows.push(getTimeTrackingHeader(timeTrackingResults));
    }

    timeTrackingResults.forEach((timeTrackingResult, i) => {
      const timeTrackingRow = <TimeTrackingRow key={'ttr-' + i} data={timeTrackingResult} />;
      rows.push(timeTrackingRow);
    });
  }

  const addPerformedTests = (rows, performedServiceLevel, scrapDetails) => {
    const performedTests = performedServiceLevel.performedTests || [];
    if (performedTests.length) {
      rows.push(getPerformedTestHeader());
    }

    performedTests.forEach((performedTest, i) => {
      const performedTestRow = <PerformedTestRow key={'pt-' + i} data={performedTest} scrapDetails={scrapDetails} />;
      rows.push(performedTestRow);
    });
  }

  const addThirdParty = (rows, performedServiceLevel) => {
    const thirdParty = performedServiceLevel.thirdParty;
    if (thirdParty !== null) {
      rows.push(getThirdPartyHeader());
      const thirdPartyRow = <ThirdPartyRow key={'tp-' + thirdParty} data={thirdParty} />;
      rows.push(thirdPartyRow);
    }
  }

  const addPerformedServiceLevels = (rows, job, scrapDetails) => {
    const performedServiceLevels = job.performedServiceLevels || [];
    performedServiceLevels.forEach(performedServiceLevel => {
      rows.push(getPerformedServiceLevelHeader(performedServiceLevel, scrapDetails));
      if (!performedServiceLevel.collapsed) {
        addThirdParty(rows, performedServiceLevel);
        addPerformedTests(rows, performedServiceLevel, scrapDetails);
        addTimeTrackingResults(rows, performedServiceLevel);
        addWallReadings(rows, performedServiceLevel);
        addTorqueReadings(rows, performedServiceLevel);
        addDimensionValues(rows, performedServiceLevel);
      }
    });
  }

  const addJobRows = (rows, history, scrapDetails) => {
    history.forEach(job => {
      rows.push(getJobHeader(job));
      addPerformedServiceLevels(rows, job, scrapDetails);
    });
  }
    const data      = props.data || [];
    const results      = [];
    const scrapDetails = props.scrapDetails;

    addJobRows(results, data, scrapDetails);


    const   handleExportAssetUtilizationClick = (e) => { 
  // const { controller } = props.controller;
      if (props.isLoading) {
        e.preventDefault();
      }
      else {
        props.controller.getServiceHistoryByteData();
      }
    }

    const colCount         = 5;
    const loadingMessage   = props.isLoading && <Table.Row><Table.Data colSpan={colCount} className='loading'>Loading service history for asset</Table.Data></Table.Row>;
    const enableExport           =  props.displayExport;
    const noResultsMessage = !loadingMessage && !results.length && <Table.Row><Table.Data colSpan={colCount} className='no-search-results'>No results for the current asset</Table.Data></Table.Row>;
    const downloadServiceHistoryButton=enableExport && <Button id='asset-utilization-export' disabled={navigator.userAgent.match(/iPad/i) != null || (!loadingMessage && !results.length)} variant='primary' size='sm' className='badge btn export-btn' icon='fa-file-excel' onClick={handleExportAssetUtilizationClick}>Export to Excel</Button>
    return (
      <div className='row mb-2'>
        <div className='col-sm-12'>
          <Form.Header className='alert-warning p-6'>Service History {downloadServiceHistoryButton}</Form.Header>
          <div id='service-history' className='search-results'>
            <Table>
              <Table.Body>
                {results}
                {loadingMessage}
                {noResultsMessage}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    );
}

export default ServiceHistoryTable;
