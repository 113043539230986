import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import ProfitCostCenterRowController    from '../controllers/ProfitCostCenterRowController';
import ReceivingCostCenterRowController from '../controllers/ReceivingCostCenterRowController';

class EditFacilityController extends BaseController {
  constructor() {
    super('edit-facility', EditFacilityController.getInitialState(''));

    this.adding     = false;
    this._validator = new Validator();
    this._validator.add('facilityName', 'Facility Name', [{required: true}]);
    this._validator.add('plantCode',    'Plant Code',    [{maxLength: {params: 4}}]);
    this._validator.add('locationCode', 'Location Code', [{maxLength: {params: 2}}]);
    this._validator.add('division',     'Division',      [{maxLength: {params: 2}}]);
    this._validator.add('salesOffice',  'Sales Office',  [{maxLength: {params: 4}}]);
  }

  load = (facilityId) => {
    if (facilityId === Utils.emptyGuid) {
      this.adding = true;
      this.state  = EditFacilityController.getInitialState(facilityId);
      this.commit();
    }
    else {
      this.adding = false;
      this.state  = EditFacilityController.getInitialState(facilityId);
        ajax.get('lookup/getFacility/', { facilityId: facilityId }).then(results=>{
          this.merge(results);
          this.commit();
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // FacilityDto
    }
  }

  merge = (facility) => {
    if (!facility) {
      return;
    }
    this.state        = Object.assign({}, facility);
    this.state.profitCostCenters = [];
    this.state.receivingCostCenters = [];
    this.state.errors = {};
    facility.profitCostCenters.forEach(element => {this.addNewProfitCostCenterRow(element)}); 
    facility.receivingCostCenters.forEach(data => {this.addNewReceivingCostCenterRow(data)})   
  }

  static getInitialState = (facilityId) => {
    return {
      facilityId           : facilityId,
      facilityName         : '',
      address1             : '',
      address2             : '',
      address3             : '',
      city                 : '',
      state                : '',
      postalCode           : '',
      region               : '',
      country              : '',
      phone                : '',
      fax                  : '',
      plantCode            : '',
      locationCode         : '',
      division             : '',
      salesOffice          : '',
      useSAP               : false,
      archived             : false,
      profitCostCenters    : [],
      receivingCostCenters : [],
      errors               : {},
      useTTS               : false,
      isSaving             : false,
      enableDMS            : false,
      mandateCostCenter    : false
    };
  }

  search = (searchType) => {
    if (!searchType) {
      return null;
    }
    return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), { searchTerm: searchTerm })
    .then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  });
  }

  validateProfitCostCenters = (state) => {
    // if (!state.profitCostCenters.length) {
    //   state.errors.profitCostCenters = 'At least one row must be specified';
    //   return;
    // }
    // else
    // {
    //   delete state.errors.profitCostCenters;
    //   this.commit();
    // }
    state.profitCostCenters.forEach(profitCostCenter => profitCostCenter.validate());
    state.receivingCostCenters.forEach(receivingCostCenter => receivingCostCenter.validate());
  }

  canSave = () => {
    this.validateProfitCostCenters(this.state);
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    const profitCostCentersErrors = this.state.profitCostCenters.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0); 
    const receivingCostCentersErrors = this.state.receivingCostCenters.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0); 
    if (!errors.length && !profitCostCentersErrors && !receivingCostCentersErrors) {
      return true;
    }
    this.commit();
    return false;
  }

  save = (cb) => {
    if (!this.canSave()) {
      return;
    }
    const s          = this.state;
    const facilityId = s.facilityId;
    this.state.profitCostCenters.forEach(profitCostCenter => 
      {
        profitCostCenter.state.jobTypeId = profitCostCenter.state.jobType.id
        profitCostCenter.state.jobTypeName = profitCostCenter.state.jobType.name
        profitCostCenter.state.accountingIndicatorId = profitCostCenter.state.accountingIndicator.id
        profitCostCenter.state.accountingIndicatorName = profitCostCenter.state.accountingIndicator.name
        this.commit();
      });
    const parameters = {
      facilityId          : facilityId,
      facilityName        : s.facilityName,
      address1            : s.address1,
      address2            : s.address2,
      address3            : s.address3,
      city                : s.city,
      state               : s.state,
      postalCode          : s.postalCode,
      regionId            : s.region  && s.region.id,
      countryId           : s.country && s.country.id,
      phone               : s.phone,
      fax                 : s.fax,
      plantCode           : s.plantCode,
      locationCode        : s.locationCode,
      division            : s.division,
      salesOffice         : s.salesOffice,
      archived            : s.archived,
      profitCostCenters   : s.profitCostCenters.map(tr => tr.state),
      receivingCostCenters: s.receivingCostCenters.map(row => row.state),
      useTTS              : s.useTTS,
      enableDMS           : s.enableDMS,
      mandateCostCenter   : s.mandateCostCenter 
    };
        this.state.isSaving=true;
        this.commit();
        ajax.post('facility/save', parameters).then(()=>{
          this.state.isSaving=false;
          this.commit();
          if (this.adding) {
            notification.action('success').post('Saved new Facility');
          }
          else {
            notification.action('success').post('Saved changes for ' + s.facilityName);
          }
          const postData = this.adding ? null : facilityId;
          messages.channel('edit-facility').action('saved').post(postData);
          cb();
        }).catch(err => {
          this.state.isSaving=false;
          this.commit();
          notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // in: FacilityDto, out: void
        // use in FacilitieController to re-select edited form
        // if it's a new form, it will be the 1st entry in the list
  }

  addNewProfitCostCenterRow = (profitCostCenter) => {
    this.state.profitCostCenters.push(new ProfitCostCenterRowController(this, profitCostCenter));
    this.commit();
  }

  addNewReceivingCostCenterRow = (receivingCostCenter) => {
    this.state.receivingCostCenters.push(new ReceivingCostCenterRowController(this, receivingCostCenter));
    this.commit();
  }

  removeProfitCostCenterRow = (row) => {
    const idx = this.state.profitCostCenters.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.profitCostCenters.splice(idx, 1);
    this.commit();
  }

  removeReceivingCostCenterRow = (row) => {
    const idx = this.state.receivingCostCenters.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.receivingCostCenters.splice(idx, 1);
    this.commit();
  }
}

export default EditFacilityController;