import React from 'react';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import Table from '../../components/Table';
import Controller from '../../mixins/Controller';

class SerialReadingRow extends React.Component{
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    this.binder = new Binder(this);
}

componentDidMount = () => {
    this.initializeMixin();
}

  getColorCode(){
    return this.props.controller.getClassName();
  }

  render() {
    const state           = this.props.controller.state;
    const binder          = this.binder;
    var   colorCode       = this.getColorCode();
    //var   fldReadingValue = <Form.NumberInput className={'reading-value ' + colorCode} bind={binder.bind('readingValue')} />
    var   approve         = <Form.CheckBox className={'approve-check'} bind={binder.bind('approve')} />;
    return (
      <Table.Row>
        <Table.Data>{state.readingId}</Table.Data>
        <Table.Data>{state.minimumDimension}</Table.Data>
        <Table.Data>{state.newDimension}</Table.Data>
        <Table.Data>{state.Previous_Reading}</Table.Data>
        <Table.Data className={'col-readingvalue'+colorCode}><span className={colorCode}>{state.readingValue}</span></Table.Data>
        <Table.Data>{state.scopeSerial}</Table.Data>
        <Table.Data>{approve}</Table.Data>
      </Table.Row>
    );
  }
}

export default SerialReadingRow;