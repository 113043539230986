import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Button from '../../components/Button';
import SignaturePad from '../../components/SignaturePad';

class OverrideDialog extends React.Component {

    handleClearSignature = () => {
        this.props.controller.clearSignature();
    }

    handleUpdateSignature = (signature) => {
        this.props.controller.updateSignature(signature);
    }

    handleClose = (result) => (result.Cancel) ? true : this.props.controller.saveOverride();

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const ClockInTitle = 'Quote Override';
        const errors = controller.state.errors;
        return (
            <Dialog {...this.props} allowKeyDownEvent={true} modal={true} width={370} height={500} header={ClockInTitle} id='clockOut-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='scrap-asset-right-div'>
                        <form role='form'>
                            <div className='col-sm-12'>
                                <Form.TextArea id='reason' className='required' label='Reason' bind={binder.bind('reason')} error={errors.reason} maxLength='150' />
                            </div>
                            <div className='col-sm-12'>
                                <Form.Input label='Approved By' placeholder='please enter your name' className='required' bind={binder.bind('approverName')} error={errors.approverName} maxLength='50' />
                            </div>
                            <div className='col-sm-12'>
                                <SignaturePad className='required' id='signature-pad' label='Approver Signature' backgroundColor='#F000' onUpdateSignature={this.handleUpdateSignature} bind={binder.bind('approverSignature')} error={errors.approverSignature} width={325} height={150} />
                            </div>

                            <div className='col-sm-12'>
                                <Button variant='light' size='md' style={{ width: '120px', marginTop: '5px', marginBottom: '5px' }} onClick={this.handleClearSignature}>Clear</Button>
                            </div>
                        </form>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCloseButtons saving={controller.saving} />;
            </Dialog>
        );
    }
}
export default OverrideDialog;