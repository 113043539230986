import _ from 'immutable';
import $ from 'jquery';
import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notifications from '../../services/Notification';
import ConfirmReapplyServiceLevelsController from '../controllers/ConfirmReapplyServiceLevelsController';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import ConfirmReapplyServiceLevelsDialog from '../pages/ConfirmReapplyServiceLevelsDialog';

class JobProgressAssetsController extends BaseController {
  constructor() {
    super('job-progress-assets', {
      job: '',
      facilityId: '',
      assets: [],
      groups: [],
      familyCodes: [],
      familyCode: '',
      isLockInspInProgress: false,
      multiSelectGroups: '',
      isButtonView: true,
    });

    messages.channel('login').action('loggedin').subscribe(loginInfo => {
      if (loginInfo) {
        this.loadFamilyCodes();
      }
    });

    messages.channel('edit-job-asset-details').action('saved').subscribe(() => {
      this.loadJobAssets(this.state.job.id);
    });

    messages.channel('assign-service-levels').action('saved').subscribe(serviceLevelInfo => {
      this.cacheAssets(serviceLevelInfo.assets);
      this.loadJobAssets(serviceLevelInfo.jobId);
    });

    messages.channel('lock-for-inspection').action('updated').subscribe(result => {
      if (result.jobSOWId) {
        this.updateAsset(result, 'lock-for-inspection');
      }
      else if (result.jobId && result.message && result.message.action) {
        notifications.action(result.message.action).post(result.message);
        this.loadJobAssets(result.jobId);
      }
    });

    messages.channel('reapply-service-levels').action('updated').subscribe(result => {
      if (result.asset) {
        this.replaceAsset(result.asset);
      }
      else if (this.state.job.id === result.jobId) {
        this.loadJobAssets(result.jobId);

        if (result.message && result.message.action) {
          notifications.action(result.message.action).post(result.message);
        }
      }
    });

    messages.channel('change-facility').action('changed').subscribe(facility => {
      var state = this.state;
      state.job = '';
      state.facilityId = facility.id;
      state.assets = [];
      this.commit();
    });
  }

  familyCodeChanged = () => {
    this.deselectAllGroups();
    this.commit();
  }

  multiSelectGroupsChanged = () => {
    if (this.state.multiSelectGroups) {
      var selectedGroups = this.state.multiSelectGroups.map(group => group.id);
      this.state.groups.forEach(group => {
        if (selectedGroups.includes(group.value.groupNumber)) {
          group.selected = true;
        }
        else {
          group.selected = false;
        }
      });
    }
    else {
      this.state.groups.forEach(group => {
        group.selected = false;
      });
    }
    this.deselectAssetsInUnselectedGroups();
    this.commit();
  }

  canEditAssetDescription = (asset) => {
    return (
      this.doesJobStateAllowEditAssetDescription(this.state.job) &&
      (this.getSelectedCount() === 1) &&                                                // only 1 asset selected
      asset &&                                                                          // we have an asset
      asset.serviceLevels &&                                                            // and it has service levels
      (asset.serviceLevels.filter(serviceLevel => serviceLevel.performed).length === 0) // and none have been selected yet
    );
  }

  doesJobStateAllowEditAssetDescription = (job) => {
    let statusValue = 0;
    if (job.assetCount === 0) {
      // SAP && non-SAP
    }
    else if (job.isClosed) {
      // SAP && non-SAP
      statusValue += 90;
    }
    else if (job.isInvoiced) {
      // SAP only
      statusValue += 80;
    }
    else if (job.canInvoice) {
      // SAP only
      statusValue += 70;
    }
    else if (job.isCompleting) {
      // SAP only
      statusValue += 60;
    }
    else if (job.canComplete) {
      // SAP && non-SAP
      statusValue += 50;
    }
    else if (job.isInspecting) {
      // SAP && non-SAP
      statusValue += 40;
    }
    else if (job.canLockForInspection) {
      // SAP && non-SAP
      statusValue += 30;
    }
    else if (job.awaitingLockForInspection) {
      // SAP && non-SAP
      statusValue += 20;
    }
    else if (job.isCreated) {
      statusValue += 10;
    }
    else {
      statusValue += 100;
    }
    return (statusValue <= 30);
  }

  canReapplyServiceLevels = () => {
    const job = this.state.job;
    //var canReapply = (job && !job.isWaiting);
    //if (!canReapply) {
    // return false;
    //}
    const selectedAssets = this.state.assets.filter(asset => asset.selected);
    if (!selectedAssets.length) {
      return false;
    }
    const assetsCanReapplyServiceLevels = this.state.assets.filter(asset => asset.selected).reduce((acc, curr) => {
      return (acc && curr.canReapplyServiceLevels); // curr.isScrapped);
    }, true);

    //return assetsCanReapplyServiceLevels;

    const tecoAssets = selectedAssets.filter(asset => asset.sapStatus == 'TECO');
    const result = assetsCanReapplyServiceLevels && !tecoAssets.length > 0;

    // if the assets chosen have different description SLs and/or BOMs, disable Edit Service Levels button
    if (result && selectedAssets.length > 1) {
      const descriptions = selectedAssets.map(asset => asset.customerDescriptionId);
      const isDiffDescription = descriptions.some((val, i, arr) => val != arr[0]);
      var serLls = [];
      var boms = [];
      selectedAssets.forEach(asset => {
        var sls = asset.serviceLevels.filter(serviceLevel => serviceLevel.performed)[0];
        if (sls) {
          serLls.push(sls.serviceLevelId);
          var bom = sls.billsOfMaterial.filter(x => x.selected)[0];
          if (bom) {
            boms.push(bom.bomId);
          }
        }
      });
      const isDiffServiceLevels = serLls.some((val, i, arr) => val != arr[0]);
      const isDiffBOMs = boms.some((val, i, arr) => val != arr[0]);
      return result && !(isDiffDescription || isDiffServiceLevels || isDiffBOMs);
    }

    return result;
  }

  reapplyServiceLevels = () => {
    const jobId = this.state.job.id;
    const jobNumber = this.state.job.jobNumber;
    const filteredAssets = this.state.assets.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.jobSOWId === value.jobSOWId && t.assetId === value.assetId
      ))
    );
    const assetCount = filteredAssets.length;
    const selectedAssets = this.getSelectedAssets();
    this.state.job.isWaiting = true;

    co(this, function* () {
      try {
        const controller = new ConfirmReapplyServiceLevelsController(jobId, jobNumber, assetCount, selectedAssets);
        const result = yield Dialog.showDialogWaitForResult(<ConfirmReapplyServiceLevelsDialog controller={controller} header='Confirm Reapply Service Levels' />);

        if (result.Save) {
          controller.save();
        }
      }
      catch (e) {
        this.state.job.isWaiting = false;
        throw e;
      }
    });
  }

  getVisibleAssets = () => {
    return this.state.assets.filter(asset => {
      if (this.state.familyCode) {
        if (asset.familyCodeId === this.state.familyCode.id) {
          return asset;
        }
      }
      else {
        const selectedGroups = this.state.groups.filter(group => group.selected);
        if (!selectedGroups.length) {
          return asset;
        }

        for (let i = 0; i < selectedGroups.length; ++i) {
          const group = selectedGroups[i];
          if (asset.groupNumber === group.value.groupNumber) {
            return asset;
          }
        }
      }
    });
  }

  getShippableAssets = () => {
    //const selectedAssets       = this.state.assets.filter(asset => asset.selected);
    const job = this.state.job;
    return this.state.assets.filter(asset => {
      return asset.canShip && job.sapStatus != 'QUST'
    });
  }

  getReceivableAssets = (selectedAssets) => {
    const assets = this.state.assets;
    let denyAssetsList = [];
    let AssetsList = [];
    selectedAssets.forEach(asset => {
      let denyAsset = assets.filter(denyAsset => denyAsset.assetId == asset.assetId && denyAsset.sapStatus != AppConstants.AssetSAPStatus.TECO);
      if (denyAsset.length > 0) {
        denyAssetsList.push(denyAsset.assetId)
      }
      if (!AssetsList.includes(asset.assetId)) {
        AssetsList.push(asset.assetId)
      }
    });

    const rassets = selectedAssets.filter(asset => {
      return asset.canShip && asset.assetStatus != 'Scrapped';
    });
    if (selectedAssets.length == rassets.length && denyAssetsList.length == 0 && AssetsList.length == selectedAssets.length) {
      return true;
    }
    else {
      return false;
    }
  }

  getTransferrableAssets = () => {
    //var selectedAssets       = this.state.assets.filter(asset => asset.selected);
    const job = this.state.job;
    const jobSapStatus = ['SORL', 'QUOR'];
    const assetSapStatus = ['REL', 'PCNF'];
    var assets = (job.useSAP)?this.state.assets.filter(asset => {
      return asset.canTransfer && jobSapStatus.indexOf(job.sapStatus) > -1 && assetSapStatus.indexOf(asset.sapStatus) > -1
    }):this.state.assets.filter(asset => {      
      return asset.selected && asset.canTransfer && job.sapStatus!='QUCR' && job.sapStatus!='QUST'});  
    return [...new Map(assets.map(item => [item["assetId"] + '-' + item["jobSOWId"], item])).values()]
  }

  getRemovableAssets = () => {
    const job = this.state.job;
    //if (!job || job.isComplete || job.awaitingLockForInspection) {
    if (!job || job.isComplete) {
      return [];
    }
    var assets = this.state.assets.filter(asset => {

      const isFinalInspectonComplete = ((asset.sapStatus === AppConstants.AssetSAPStatus.TECO) || (asset.lastTestResult && asset.lastTestResult.status === 'Final Inspection Passed'));
      const isScrapped = (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped);
      const isWeldrepaired = (asset.eAssetStatus === AppConstants.eAssetStatus.WeldRepair);
      const isOSRepaired = (asset.eAssetStatus === AppConstants.eAssetStatus.OSRepair);
      //return (asset.selected && !asset.hasShipped && !asset.isWaiting && !isScrapped && !isWeldrepaired && !isOSRepaired && !isFinalInspectonComplete);
      return (asset.selected && job.sapStatus != 'QUCR' && job.sapStatus != 'QUST' && !asset.hasShipped && !isScrapped && !isWeldrepaired && !isOSRepaired && !isFinalInspectonComplete);
    });

    return [...new Map(assets.map(item => [item["assetId"] + '-' + item["jobSOWId"], item])).values()]

  }

  getLockableAssets = () => {
    const x = this.state.assets.filter(asset => asset.selected && asset.canLockForInspection);
    return [...new Map(x.map(item => [item["assetId"] + '-' + item["jobSOWId"], item])).values()]
  }

  isContainCommonSLs = (selectedAssets) =>{
    
    var ServicelevelIds=[];
    //selected assets must have at least one common service level
    var serviceLevels =   selectedAssets.forEach(asset => {
      var sls = asset.serviceLevels?.map(sl=>{
      
        ServicelevelIds.push(sl.serviceLevelId);
      });

    });
    
    const commonSLCount = Array.from(
      ServicelevelIds.reduce((r, c) => r.set(c, (r.get(c) || 0) + 1), new Map()), 
      (([key, count]) => ({ key, count }))
    )
    const commonSls=commonSLCount.filter(x=>x.count == selectedAssets.length).length;
    if(commonSls>=1){
      return true;
    }
    return false;

  }

  canEditServiceLevels = () => {
    const job = this.state.job;
    //if (!job || job.isWaiting || job.isComplete) {
    if (!job || job.isComplete) {
      return false;
    }

    const selectedAssets = this.state.assets.filter(asset => asset.selected);

    if (!selectedAssets.length) {
      return false;
    }
    
    if(selectedAssets.length>1 && !this.isContainCommonSLs(selectedAssets)){
        return false;
    }

    const canEditAllAssetServiceLevels = selectedAssets.every(asset => this.canEditServiceLevel(asset));
    if (!canEditAllAssetServiceLevels) {
      return false;
    }

    // return true if at least one asset can be edited
    //return this.state.assets.some(asset => asset.selected && this.canEditServiceLevel(asset));

    var result = this.state.assets.some(asset => asset.selected && this.canEditServiceLevel(asset) && asset.sapStatus != 'TECO');

    // if the assets chosen have different description SLs and/or BOMs, disable Edit Service Levels button
    if (result && selectedAssets.length > 1) {
      const descriptions = selectedAssets.map(asset => asset.customerDescriptionId);
      const isDiffDescription = descriptions.some((val, i, arr) => val != arr[0]);
      var serLls = [];
      var assetsWithNoSL=[];
      var boms = [];
      selectedAssets.forEach(asset => {
        var sls = asset.serviceLevels.filter(serviceLevel => serviceLevel.performed)[0];
        if (sls) {
          serLls.push(sls.serviceLevelId);
          var bom = sls.billsOfMaterial.filter(x => x.selected)[0];
          if (bom) {
            boms.push(bom.bomId);
          }
        }
        else{
          assetsWithNoSL.push(asset.assetId);
        }
      });
  
      const isValidSLCount= serLls.length=== selectedAssets.length? true: serLls.length===0? true: false; //bug- 201778
       const isDiffServiceLevels = serLls.some((val, i, arr) => val != arr[0]);
      const isDiffBOMs = boms.some((val, i, arr) => val != arr[0]);
      // return result && !(isDiffDescription || isDiffServiceLevels || isDiffBOMs);
      return result && !(isDiffServiceLevels || isDiffBOMs ) && isValidSLCount;
    }

    return result;
  }

  canEditServiceLevel = (asset) => {
    return (asset && asset.canEditServiceLevel);
  }

  hasAssetShipped = (asset) => {
    return (asset && asset.hasShipped);
  }

  toggleSelection = (asset) => {
    // asset.selected = !asset.selected;
    // if (asset.selected) {
    //   this.loadServiceLevels(asset);
    // }
    this.state.assets.filter(x => x.assetId === asset.assetId && x.jobSOWId == asset.jobSOWId).forEach(x => { x.selected = !x.selected });
    this.commit();
  }

  loadServiceLevels = (params) => {
    if (!params || !params.length) {
      return;
    }

    const assets = params.length
      ? params
      : [params];

    const assetIds = assets.map(asset => asset.jobSOWId);
    co(this, function* () {
      try {
        this.isLoading = true;
        var assetServiceLevels = yield ajax.post('lookup/getServiceLevels', { jobId: this.state.job.id, assetIds: assetIds }); // in: ; out: IList<AssetServiceLevelDto>
        this.isLoading = false;

        assetServiceLevels.forEach(serviceLevel => {
          var filteredAsset = this.state.assets.filter(asset => asset.jobSOWId === serviceLevel.jobSOWId);
          if (filteredAsset.length) {
            var thisAsset = filteredAsset[0];
            thisAsset.serviceLevels = serviceLevel.serviceLevels;
          }
        });
        this.commit();
      }
      catch (err) {
        notifications.action('error').post({ msg: err.message, title: 'Error loading asset service levels' });
      }
    });
  }

  deselectAllGroups = () => {
    this.state.multiSelectGroups = '';
    $('#group-multiselect').select2('data', []);
    this.state.groups.forEach(group => { group.selected = false; });
    this.deselectAssetsInUnselectedGroups();
    this.commit();
  }

  deselectAssetsInUnselectedGroups = () => {
    const unselectedGroups = this.state.groups.filter(group => !group.selected);
    if (unselectedGroups.length === this.state.groups.length) {
      // all groups are unselected - no need to deselect assets
      return;
    }

    this.state.assets.forEach(asset => {
      for (let i = 0; i < unselectedGroups.length; ++i) {
        let group = unselectedGroups[i];
        if (asset.groupNumber === group.value) {
          asset.selected = false;
        }
      }
    });
  }

  toggleGroupSelection = (clickedGroup) => {
    this.state.familyCode = '';
    var selectedgroup = this.state.multiSelectGroups === '' ? [] : this.state.multiSelectGroups;
    if (selectedgroup === null) {
      selectedgroup = [];
    }
    this.state.groups.forEach(group => {
      if (group.value.groupNumber === parseInt(clickedGroup, 10)) {
        group.selected = !group.selected;
        if (group.selected) {
          selectedgroup.push({ name: group.value.groupName ? `${group.value.groupNumber} - ${group.value.groupName}` : group.value.groupNumber, id: group.value.groupNumber });
        } else {
          //remove group from Array;
          var removedGroup = selectedgroup.filter(x => x.id === group.value.groupNumber);
          const idx = selectedgroup.indexOf(removedGroup[0]);
          if (idx === -1) {
            return;
          }
          selectedgroup.splice(idx, 1);
        }
      }
    });
    this.state.multiSelectGroups = selectedgroup;
    this.deselectAssetsInUnselectedGroups();
    this.commit();
  }

  selectAll = () => {
    this.getVisibleAssets().forEach(asset => asset.selected = true);
    this.commit();
  }

  selectNone = () => {
    this.getVisibleAssets().forEach(asset => asset.selected = false);
    this.commit();
  }

  selectScrapped = () => {
    this.selectNone();
    this.getVisibleAssets()
      .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped)
      .forEach(asset => asset.selected = true);
  }

  cancelLoadAssets = () => {
    this.cancelAssetLoading = true;
  }

  pushFailedAssets = () => {
    const jobId = this.state.job.id;
    co(this, function* () {
      try {
        yield ajax.get('jobprogress/pushFailedAssets', { jobId: jobId });
        notifications.action('info').post('Request for failed assets service order creation is completed');
      }
      catch (ex) {
        notifications.action('error').post({ msg: ex.message, title: 'Error creating service orders' });
      }
    });
  }

  initializeGroups = () => {
    let groups = [];

    this.state.assets.filter(asset => {
      if (groups.indexOf(asset.groupNumber) === -1) {
        groups.push({ groupNumber: asset.groupNumber, groupName: asset.groupName });
      }
    });

    groups.sort(function (a, b) {
      return (a.groupNumber - b.groupNumber);
    });

    this.state.groups = groups.map(group => {
      return {
        value: group,
        selected: false
      };
    });
  }

  isFiltered = (asset) => {
    const familyCode = this.state.familyCode;
    return (!familyCode || (familyCode.id === asset.familyCodeId));
  }

  replaceAsset = (asset) => {
    if (!asset) {
      return;
    }

    const existingAsset = this.state.assets.find(a => a.jobSOWId === asset.jobSOWId);
    if (!existingAsset) {
      return;
    }

    const idx = this.state.assets.indexOf(existingAsset);
    this.state.assets.splice(idx, 1);
    this.state.assets.push(asset);
    this.commit();
  }

  updateAsset = (result, channel) => {
    if (!result || !result.jobSOWId) {
      return;
    }

    let asset = this.state.assets.find(a => a.jobSOWId === result.jobSOWId);
    if (!asset) {
      return;
    }

    if (result.sapErrorCode !== undefined) {
      asset.sapErrorCode = result.sapErrorCode;
    }
    if (result.sapErrorMessage !== undefined) {
      asset.sapErrorMessage = result.sapErrorMessage;
    }
    if (result.serviceOrderNumber !== undefined) {
      asset.serviceOrderNumber = result.serviceOrderNumber;
    }
    if (result.sapStatus !== undefined) {
      asset.sapStatus = result.sapStatus;
    }
    if (result.reason !== undefined) {
      asset.waitingForReason = result.reason;

      switch (channel) {
        case 'reapply-service-levels':
          asset.isWaitingForReapplyServiceLevels = (result.reason === AppConstants.AssetWaitingReason.ReapplyServiceLevels);
          break;

        case 'lock-for-inspection':
          asset.isWaitingForLockForInspection = (result.reason === AppConstants.AssetWaitingReason.LockForInspection);
          break;

        default:
          break;
      }
    }
    this.commit();
  }

  updateServiceOrder = (result) => {
    if (!result || !result.jobSOWId || !result.serviceOrderNumber) {
      return;
    }

    let asset = this.state.assets.find(a => a.jobSOWId === result.jobSOWId);
    if (!asset) {
      return;
    }
    asset.serviceOrderNumber = result.serviceOrderNumber;
    asset.sapStatus = result.sapStatus;

    this.commit();
  }

  loadJobAssets = (jobId) => {
    if (this.isLoading) {
      return;
    }

    this.state.job = '';
    this.state.assets = [];
    this.state.groups = [];
    this.state.facilityId = LoginService.loginInfo.facilityId;

    if (!jobId) {
      this.commit();
      return;
    }

    co(this, function* () {
      try {
        this.isLoading = true;
        this.cancelAssetLoading = false;
        this.state.job = yield ajax.get('lookup/getJob', { jobId: jobId }); // JobLookupDto
        this.commit();

        // yield ajax.get('jobprogress/updateJobAssetErrors', { jobId: jobId });

        this.state.assets = [];
        //var assetChunkSize = 25;
        //for (var i = 0; i <= this.state.job.assetCount / assetChunkSize; ++i) {
        //  if (this.cancelAssetLoading) {
        //   break;
        //  }
        //  else {
        //   var assets = yield ajax.get('lookup/getJobProgressAssets2', { jobId: jobId, skip: i * assetChunkSize, take: assetChunkSize }); // IList<JobProgressAssetDto>
        //   this.state.assets.push(...assets);
        //  this.commit(); 
        //}
        //}       
        var assets = yield ajax.get('lookup/getJobProgressAssets', { jobId: jobId }); // IList<JobProgressAssetDto>
        this.state.assets.push(...assets);
        this.commit();
        this.initializeGroups();
        // this.loadServiceLevels(this.state.assets);
      }
      catch (err) {
        notifications.action('error').post({ msg: err.message, title: 'Error loading job progress assets' });
      }
      finally {
        this.isLoading = false;
        this.commit();
      }
    });
  }

  // getSelectedAsset = () => {
  //   if (this.getSelectedCount() !== 1) {
  //     return '';
  //   }
  //   return this.state.assets.filter(asset => asset.selected)[0];
  // }

  getSelectedCount = () => {
    // return this.state.assets.reduce((acc, curr) => {
    //   return curr.selected ? acc + 1 : acc;
    // }, 0);

    return new Set(this.state.assets.filter(x => x.selected === true)?.map(x => x.assetId + '' + x.jobSOWId)).size;
  }

  getSelectedAssets = () => {
    const selectedAssets = this.state.assets.filter(asset => asset.selected);
    const assets = [...new Map(selectedAssets.map(item => [item["assetId"] + '-' + item["jobSOWId"], item])).values()];
    return assets || [];
  }

  getSelectedAsset = () => {
    const selectedAssets = this.getSelectedAssets();
    return (selectedAssets.length === 1)
      ? selectedAssets[0]
      : '';
  }

  getFailedCount = () => {
    // return this.state.assets.reduce((acc, curr) => {      
    //   return curr.sapErrorMessage!="" ? acc + 1 : acc;
    // }, 0);
    return [... new Set(this.state.assets.filter(x => x.sapErrorMessage != "").map(x => x.assetId + '' + x.jobSOWId))].size;
  }

  cacheAssets = (assets) => {
    this.state.cachedAssets = assets;
  }

  clearAssetCache = () => {
    this.state.cachedAssets = [];
  }

  selectAssetsInCache = () => {
    let assetIds = this.state.cachedAssets.map(asset => asset.jobSOWId);
    if (!assetIds || !assetIds.length) {
      return;
    }

    const assets = this.state.assets;
    assets.forEach(asset => { asset.selected = false; });
    assetIds.forEach(jobSOWId => {
      assets.forEach(asset => {
        if (asset.jobSOWId === jobSOWId) {
          asset.selected = true;
          this.commit();
        }
      });
    });
  }

  loadFamilyCodes = () => {
    co(this, function* () {
      const results = yield ajax.get('lookup/searchLookupList/Family%20Code', { searchTerm: '' }); // IList<LookupDto>
      this.state.familyCodes = results;
    });
  }

  familyCodeSearch = () => {
    const self = this;
    return function* () {
      const assetFamilyCodeIds = self.state.assets.map(asset => asset.familyCodeId);
      const list = new _.List(assetFamilyCodeIds);
      const results = self.state.familyCodes.filter(familyCode => list.contains(familyCode.id));
      return results;
    };
  }

  getBillOfMaterial = (serviceLevel) => {
    if (!serviceLevel || !serviceLevel.billsOfMaterial || !serviceLevel.billsOfMaterial.length) {
      return '';
    }
    const boms = serviceLevel.billsOfMaterial.filter(bom => bom.selected);
    if (!boms.length) {
      return 'No Bill of Material selected';
    }
    return boms[0];
  }

  canRemoveAssets = (assets) => {
    const job = this.state.job;
    if (job.isComplete) {
      return false;
    }
    if (!assets.length) {
      return false;
    }
    if (assets.filter(asset => !asset.selected || asset.hasShipped).length) {
      return false;
    }
    return true;
  }

  removeAssets = () => {
    const selectedAssets = this.getSelectedAssets();
    if (!this.canRemoveAssets(selectedAssets)) {
      return;
    }

    const assetCount = selectedAssets.length;
    const jobNumber = this.state.job.jobNumber;
    const confirmMessage = <div className='h5'>{'Please confirm you wish to remove ' + Utils.pluralize('asset', 's', assetCount) + ' from job ' + jobNumber}</div>;
    const message = 'Note: This will remove all test results and time tracking data for the ' + Utils.pluralize('asset', 's', assetCount, false);
    const warningMessage = <span className='font-weight-bold text-danger h6 mt-3'>{message}</span>;

    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={250} header='Confirm Asset Removal'>
          {confirmMessage}
          {warningMessage}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }
      this.state.isLockInspInProgress = true;
      this.commit();
      const parameters = {
        jobId: this.state.job.id,
        assetIds: selectedAssets.map(asset => asset.jobSOWId)
      };

      yield ajax.post('jobprogress/removeAssets', parameters);
      this.state.assets = [];
      this.commit();
      notifications.action('info').post('Removed ' + Utils.pluralize('asset', 's', selectedAssets.length) + ' from job ' + jobNumber);
      //messages.channel('edit-job-asset-details').action('saved').post();
      //this.loadJobAssets(this.state.job.id);      
      this.state.isLockInspInProgress = false;
      this.commit();
    });
  }
  handleReceiveAssetAgain = (selectedAssets) => {
    const assetCount = selectedAssets.length;
    const jobNumber = this.state.job.jobNumber;
    const confirmMessage = <div><h4>{'Please confirm you wish to re add ' + Utils.pluralize('asset', 's', assetCount) + ' in to job ' + jobNumber}</h4></div>;

    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={625} height={300} header='Confirm Asset Receive Again'>
          {confirmMessage}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }
      this.state.isLockInspInProgress = true;
      this.commit();
      const parameters = {
        jobId: this.state.job.id,
        assets: selectedAssets.map(asset => {
          return {
            assetId: asset.assetId,
            customerId: this.state.job.customerId,
            currentLocationId: asset.assetLocationId,
            primarySerialNumber: asset.primarySerialNumber,
            eAssetStatus: asset.eAssetStatus,
            equipmentNumber: asset.equipmentNumber,
            jobSOWId: asset.jobSOWId
          };
        })
      };
      try {
        yield ajax.post('receiving/receiveAssetsAgain', parameters);
        this.state.assets = [];
        this.commit();
        notifications.action('info').post('Received ' + Utils.pluralize('asset', 's', selectedAssets.length) + ' in to job ' + jobNumber);
        this.state.isLockInspInProgress = false;
        this.commit();
      }
      catch (err) {
        notifications.action('error').post({ msg: err.message, title: 'Assign Assets to Job Error' });
      }
      finally {
        this.state.isLockInspInProgress = false;
        this.commit();
      }
    });
  }
  lockForInspection = (assets) => {
    if (!assets || !assets.length || !assets.every(a => a.canLockForInspection)) {
      notifications.action('warning').post('Unable to lock for inspection');
      return;
    }

    const message = 'Please confirm you wish to Lock the ' + (assets.length > 1 ? assets.length + ' assets' : 'selected asset') + ' for Inspection';

    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Lock for Inspection'>
          {message}
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        this.state.isLockInspInProgress = true;
        this.commit();
        const job = this.state.job;
        const parameters = {
          jobId: job.id,
          jobNumber: job.jobNumber,
          assetIds: assets.map(a => a.jobSOWId)
        };

        yield ajax.post('jobprogress/lockAssetsForInspection', parameters); // in: LockJobAssetsForInspectionDto
        this.state.isLockInspInProgress = false;
        this.commit();
      }
    });
  }
  setGroupView = (viewoption) => {
    this.state.isButtonView = viewoption;
    this.commit();
  }
}

export default JobProgressAssetsController;
