import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
import Utils from '../utility/Utils';

class ShippingTicketController extends BaseController {
    constructor() {
        super('shipping-tickets', {
            searchText: '',
            jobNumber: '',
            customer: '',
            container: '',
            includeShippedTickets: false,
            includeZeroAssetTickets: false,
            selectedTicket: '',
            facility: '',
            customers: [],
            searchResults: [] 
        });

        const self = this;
        this.flash = emitter();

        messages.channel('login').action('loggedin').subscribe(data => {
            if (!data) {
                self.state.customer = '';
                self.clearResults();
            }
        });
        messages.channel('complete-job').action('saved').subscribe(() => {
            this.state.customer = '';
            this.state.customers = [];
            this.state.searchResults = [];
        });
        messages.channel('change-facility').action('changed').subscribe(() => {
            this.state.customer = '';
            this.state.customers = [];
            this.state.searchResults = [];
        });
    }

    customerChanged() {
        this.clearResults();
    }

    facilitySearch() {
        return function* (searchTerm) {
            var results = yield ajax.get('lookup/searchFacilitiesForUser', {
                searchTerm: searchTerm,
                sapFacilitiesOnly: true
            }); // IList<LookupDto>
            return results;
        };
    }

    clearResults = () => {
        this.state.searchResults = [];
        this.state.selectedTicket = '';
    }

    clearSearchText = () => {
        this.state.searchText = '';
        this.commit();
    }

    search = () => {
        this.clearResults();
        const isSAPRep = LoginService.isSAPRep;
        const isAdministrator = LoginService.isAdministrator;
        const facilityId = (isSAPRep && !isAdministrator) ? (this.state.facility && this.state.facility.id) || ((LoginService.loginInfo && LoginService.loginInfo.facilityId) || '')
            : (LoginService.loginInfo && LoginService.loginInfo.facilityId) || '';
        const ticketNumber = this.state.searchText;
        const jobNumber = this.state.jobNumber;
        const customerId = (this.state.customer && this.state.customer.id); // can be null

        if (ticketNumber && !Utils.isNumeric(ticketNumber)) {
            notification.action('warning').post('If specified, Shipping Ticket Number must be a number', 'Not Valid');
            return;
        }

        const parameters = {
            facilityId: facilityId,
            ticketNumber: ticketNumber,
            jobNumber: jobNumber,
            customerId: customerId,
            container: this.state.container,
            includeShippedTickets: this.state.includeShippedTickets,
            includeZeroAssetTickets: this.state.includeZeroAssetTickets
        };

        this.state.searchResults = [];
        this.state.isLoading = true;
        this.commit();
        ajax.get('lookup/searchShippingTickets', parameters).then(results => {
            this.state.isLoading = false;
            this.state.searchResults = results;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    deleteTicket = () => {
        if (!this.state.selectedTicket) {
            return;
        }

        const ticket = this.state.selectedTicket;
        const ticketNumber = ticket.ticketNumber;
        const message = <div><h5>{`Please confirm you wish to delete shipping ticket #${ticketNumber}`}</h5></div>;

        co(this, function* () {
            var result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog width={600} height={300} header='Confirm Shipping Ticket Deletion'>
                    {message}
                </Dialog.OKCancelDialog>
            );

            if (!result.OK) {
                return;
            }

            yield ajax.post('shipping/delete', ticket.shippingTicketId); // Guid
            notification.action('success').post(`Deleted shipping ticket #${ticketNumber}`, 'Removed Assets from Container');
            this.search();
        });
    }

    customersSearch = () => {
        if (!LoginService.loginInfo) {
            return;
        }
        var facilityId = (this.state.facility && this.state.facility.id) || LoginService.loginInfo.facilityId;
        return (searchTerm) => ajax.get('lookup/searchCustomersWithShippingTicketsAtFacility', {
            searchTerm: searchTerm,
            facilityId: facilityId
        }).then(results => results).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    };

    isSelected = (ticket) => {
        if (!this.state.selectedTicket) {
            return false;
        }
        return (ticket.shippingTicketId === this.state.selectedTicket.shippingTicketId);
    }

    loadDetails = (ticket) => {
        this.state.selectedTicket = ticket;
        this.commit();
    }
}

export default ShippingTicketController;
