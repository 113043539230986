const eAssetStatus = {
  Unknown: 0,
  Pending: 1,
  Scrapped: 2,
  Passed: 3,
  FailedInspection: 4,
  WeldRepair: 19,
  OSRepair: 21
};

const AssetStatus = [
  { id: 0, name: 'Unknown' },
  { id: 1, name: 'Pending' },
  { id: 2, name: 'Scrapped' },
  { id: 3, name: 'Passed' },
  { id: 4, name: 'Failed Inspection' },
  { id: 19, name: 'Weld Repair' },
  { id: 21, name: 'OS/Machining Repair' }
];

const ActiveAssetStatus = [
  { id: 0, name: 'Unknown' },
  { id: 1, name: 'Pending' },
  { id: 3, name: 'Passed' },
  { id: 19, name: 'Weld Repair' },
  { id: 21, name: 'OS/Machining Repair' }
];
const NormalActiveAssetStatus = [
  { id: 0, name: 'Unknown' },
  { id: 1, name: 'Pending' },
  { id: 3, name: 'Passed' }
];
const OSActiveAssetStatus = [
  { id: 3, name: 'Passed' },
  { id: 21, name: 'OS/Machining Repair' }
];
const eDropoffDestination = {
  IdleAssets: 1,
  AssignToJob: 2
};

const eInspectionTestResult = {
  Failed: 0,
  Passed: 1,
  NotPerformed: 2,
  TemporarilyFailed: 3,
  FailedInspection: 4,
  OSRepair: 21,
  WeldRepair: 19,
  Unteco: 5
};

const eSearchType = {
  MfgPartNumber: 1,
  Properties: 2
};

const eSerialNumberType = {
  Primary: 1,
  Secondary: 2
};

const JobType = [
  { id: 1, name: 'Asset Management' },
  { id: 2, name: 'Service' },           // default
  { id: 3, name: 'OffSite' },
  { id: 4, name: 'Rental' },
  { id: 5, name: 'MMR' }
];

const AccountingIndicators = [
  { id: 0, name: 'Billable' },          // default
  { id: 1, name: 'FMC Rental Unit Service' },
  { id: 2, name: 'Good Will Service' },
  { id: 3, name: 'Warranty Service' }
];

const Organizations = [
  { id: 1, name: 'FMC Fluid Control' },          // default
  { id: 2, name: 'Universal Well Services' },
  { id: 3, name: 'Topco Oilsite Products' },
  { id: 4, name: 'IAL Engineering Services' }
];

const AdhocOperationUnitsOfMeasure = {
  Minutes: 'Minutes',
  Dollars: 'Dollars',
  Quantity: 'Quantity',
  Hours: 'Hours'
};


const FailedHydrostaticTest = '7083F636-8BF1-414E-8888-17CD0854F749'; // Reject Reason: Failed Hydrostatic Test - requires rebuild & retest

const NoteTypes = {
  Job: 1,
  ManufacturerDescription: 2,
  CustomerDescription: 3,
  Asset: 4,
  ReleaseNotes: 5,
  ManufactureDescriptionRequest: 6,
  AWO : 9,
  Reportgeneration : 10
};

const ReadingType = {
  Manufacturer: 'Manufacturer',
  Customer: 'Customer'
};

const JobSAPStatus = [
  { id: 0, name: 'QUNR' },
  { id: 1, name: 'QUNC' },
  { id: 2, name: 'SOCR' },
  { id: 3, name: 'QUCR' },
  { id: 4, name: 'QUAG' },
  { id: 5, name: 'QUAC' },
  { id: 6, name: 'SORL' },
  { id: 7, name: 'NOCO' },
  { id: 8, name: 'RPIG' },
  { id: 9, name: 'RPOR' },
  { id: 10, name: 'POAC' },
  { id: 11, name: 'SHIP' },
  { id: 12, name: 'NOBL' },
  { id: 13, name: 'QUST' },
  { id: 14, name: 'QUOR' }
];

const AssetSAPStatus = {
  CRTD: 'CRTD', // created
  REL: 'REL',  // released
  PCNF: 'PCNF', // time confirmation
  TECO: 'TECO', // technically completed
  CLSD: 'CLSD', // closed
  LKD: 'LKD'
};

const SerializedAssetSearchTab = {
  Simple: 'simple',
  Customer: 'customer',
  Advanced: 'advanced',
  QRCode: 'qr',
  AssetManagement: 'assetManagement',
  MassTransfer: 'transfer'
};

const MessageSeverity = {
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
  Error: 'error'
};

const PasswordExpirationWarningDays = 14;

const JobWaitingReason = {
  NotWaiting: 0,
  LockForInspection: 1,
  Invoicing: 2,
  Completion: 3,
  ReapplyServiceLevels: 4
};

const AssetWaitingReason = {
  NotWaiting: 0,
  LockForInspection: 1,
  ReapplyServiceLevels: 2
};

const SapProfiles = [
  { id: 1, name: 'EWHP' },
  { id: 2, name: 'EWHP1' }
];

const ReportFormat = [
  { id: 1, name: 'PDF' },
  { id: 2, name: 'Excel' }
];

const ReportList = [
  { id: 1, name: 'Asset Certification' },
  { id: 2, name: 'Vendor Certificates' }
];

const RecordStatus = {
  Added: 'A',
  Modified: 'M',
  Deleted: 'D',
  Unchanged: 'UC',
};

const PerDiem = {
  TravelPerDiem: 1,
  HalfDayFieldPerDiem: 2,
  NightShift: 3
};

const Period = [
  { id: 1, name: 'AM' },
  { id: 2, name: 'PM' }
];
const ServiceLevelGUID = '1F6C68CC-79B5-4C47-9BBC-364B81537662';

const AdhocTransactionTypes = {
  Actual: 'actual',
  Estimated: 'estimated'
};

const UnitOfMeasure = [
  { id: 0, name: 'Minutes' },
  { id: 3, name: 'Hours' },
  { id: 1, name: 'Dollars' },
  { id: 2, name: 'Quantity' }
];
const BackDatingNote = 'Note: When backdating please stay in the current pay period';

const SOStatus = [
  { id: 0, name: 'CRTD' },
  { id: 1, name: 'REL' },
  { id: 2, name: 'TECO' }
];

const imgFileTypes = ['jpg', 'jpeg', 'tiff', 'png', 'bmp'];

const readingDifferenceRange = 0.001;
const readingBelowMinStatusText = 'Failed (Low Reading)';
const readingMissingStatusText = 'Missing Reading';

const FunctionalLocationIndicator = {
  z4: 'Z4',
  z9: 'Z9'
};

const EqTypes = [
  { id: 1, name: 'Allow' },
  { id: 2, name: 'Restrict' }
];

const CoCReportAPIURL = 'https://coc.apps.technipfmc.com/api/report/downloadReport';

const JobSAPStatusText =  { 
  QUNR : 'QUNR',
  QUNC : 'QUNC',
  SOCR : 'SOCR',
  QUCR  : 'QUCR',
  QUAG  : 'QUAG',
  QUAC  : 'QUAC',
  SORL : 'SORL',
  NOCO : 'NOCO',
  RPIG  : 'RPIG',
  RPOR  : 'RPOR',
  POAC : 'POAC',
  SHIP : 'SHIP',
  NOBL  : 'NOBL',
  QUST  : 'QUST',
  QUOR  : 'QUOR',  
}
const AllowLockSAPStatus = [JobSAPStatusText.QUNR,JobSAPStatusText.QUNC,JobSAPStatusText.SORL,JobSAPStatusText.SOCR];

const AppConstants = {
  eAssetStatus: eAssetStatus,
  eDropoffDestination: eDropoffDestination,
  eInspectionTestResult: eInspectionTestResult,
  eSearchType: eSearchType,
  eSerialNumberType: eSerialNumberType,
  JobType: JobType,
  AdhocOperationUnitsOfMeasure: AdhocOperationUnitsOfMeasure,
  AccountingIndicators: AccountingIndicators,
  FailedHydrostaticTest: FailedHydrostaticTest,
  NoteTypes: NoteTypes,
  ReadingType: ReadingType,
  JobSAPStatus: JobSAPStatus,
  AssetSAPStatus: AssetSAPStatus,
  AssetStatus: AssetStatus,
  ActiveAssetStatus: ActiveAssetStatus,
  NormalActiveAssetStatus: NormalActiveAssetStatus,
  OSActiveAssetStatus: OSActiveAssetStatus,
  SerializedAssetSearchTab: SerializedAssetSearchTab,
  MessageSeverity: MessageSeverity,
  Organizations: Organizations,
  PasswordExpirationWarningDays: PasswordExpirationWarningDays,
  JobWaitingReason: JobWaitingReason,
  AssetWaitingReason: AssetWaitingReason,
  SapProfiles: SapProfiles,
  BackDatingNote: BackDatingNote,
  ReportFormat: ReportFormat,
  ReportList: ReportList,
  RecordStatus: RecordStatus,
  Period: Period,
  PerDiem: PerDiem,
  UnitOfMeasure: UnitOfMeasure,
  ServiceLevelGUID: ServiceLevelGUID,
  AdhocTransactionTypes: AdhocTransactionTypes,
  SOStatus: SOStatus,
  imgFileTypes: imgFileTypes,
  readingDifferenceRange: readingDifferenceRange,
  readingBelowMinStatusText: readingBelowMinStatusText,
  readingMissingStatusText: readingMissingStatusText,
  FunctionalLocationIndicator: FunctionalLocationIndicator,
  EqTypes: EqTypes,
  CoCReportAPIURL: CoCReportAPIURL,
  JobSAPStatusText : JobSAPStatusText,
  AllowLockSAPStatus : AllowLockSAPStatus
};

export default AppConstants;  