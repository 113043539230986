import React from 'react';
import Binder from '../../lib/Binder';
import Dialog from '../../components/Dialog';
import Controller from '../../mixins/Controller';
import Table from '../../components/Table';
import ThicknessReadingRow from '../pages/ThicknessReadingRow';

const ThicknessReadingTable = (props) => {

    const handleAddNewRow = () => {
        props.controller.addNewRow();
    }

    // renderRowErrors() {
    //   var rowErrors = this.props.controller.state.errors && this.props.controller.state.errors.dimensionValues;
    //   if (rowErrors) {
    //     return <div className='help-block'>{rowErrors}</div>;
    //   }
    // },

    const controller = props.controller;
    const isViewMode = controller.state.isViewMode;
    const thicknessReadings = controller.state.thicknessReadings.map(row => <ThicknessReadingRow key={row.key} controller={row} enableReadingType={true} />);

    return (
        <div id='thicknessReading-div' className='mt-1' style={{ height: 315, overflowY: 'auto' }} >
            <Table id='thicknessReading-table' className='table table-condensed table-hover table-full-width' style={{ marginBottom: '0' }}>
                <Table.Header>
                    <Table.Row>
                        <Table.Head className='col-readingType'>Reading Type</Table.Head>
                        <Table.Head className='col-actualDimension'>New Dimension</Table.Head>
                        <Table.Head className='col-minimumDimension'>Min Dimension</Table.Head>
                        <Table.Head className='col-readingValue'>Reading Value</Table.Head>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {thicknessReadings}
                </Table.Body>
            </Table>
            {/* {this.renderRowErrors()} */}
        </div>
    );
}

class ThicknessReadingsDialog extends React.Component {
    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => (result.Cancel || result.OK) ? true : this.props.controller.saveThicknessReadings();

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const isViewMode = controller.state.isViewMode;
        const actionButtons = isViewMode ? <Dialog.OKButtons /> : <Dialog.SaveCancelButtons />
        const ThicknessReadingsTitle = 'Thickness Reading';
        const errors = controller.state.errors;

        return (
            <Dialog {...this.props} modal={true} width={600} height={480} header={ThicknessReadingsTitle} id='thicknessReadings-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='thicknessReadings-dlg-body' className='col-12'>
                        <form role='form'>
                            <ThicknessReadingTable controller={controller} />
                        </form>
                    </div>
                </Dialog.Body>
                {actionButtons}
            </Dialog>
        );
    }
}


export default ThicknessReadingsDialog;