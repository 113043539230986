
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class AssemblyOperationNotesController extends BaseController {
  constructor(awoId, testName, awoSowId, notesId, notes) {
    super('inspection-test-notes', {
      notesId           : notesId ? notesId : Utils.emptyGuid,
      awoId             : awoId,
      testName          : testName,
      awoSowId          : awoSowId,
      notes             : notes ? notes : '' ,
      errors            : {},
    });

    this.saving = false;
    this._validator = new Validator();
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    let errors = Object.keys(this.state.errors);    
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  save = () => {
      if (!this.canSave()) {
        return;
      }
      const s          = this.state;
      const parameters = {
        notesId           : s.notesId,
        awoId             : s.awoId,
        testName          : s.testName,
        awoSowId           : s.awoSowId,
        notes             : s.notes
      };
      this.saving=true;
      this.commit();

      return ajax.post('awo/operations/addNote', parameters).then(results=>{
        this.saving=false;
        this.commit();
        notification.action('success').post('Notes saved successfully');
      //messages.channel('reload-test-results').action('saved').post();
      return true;
      }).catch(err => {
        this.saving=false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
      });
    }
}

export default AssemblyOperationNotesController;