const makeInterpolator = (function() {
    const rc = {
      '\n': '\\n', '\"': '\\\"'
    };
  
    return function makeInterpolator(str) {
      const s = 'return "' + (
          str
          .replace(/["\n\r]/g, function($0) {
            return rc[$0];
          })
          .replace(/\{([\s\S]+?)\}/g, '" + o.$1 + "')
        ) + '";';
      const f = new Function('o', s);
      return f;
    };
  }());

  
  String.prototype.interpolate = function(obj) {
    let cache = String.prototype.interpolate.__cache;
    if (!cache)
      cache = String.prototype.interpolate.__cache = {};
  
    let fn = cache[this];
    if (!fn) {
       fn = makeInterpolator(this);
       cache[this] = fn;
    }
  
    return fn(obj);
  }