import React from 'react';
import BaseController from '../../lib/BaseController';
import store from 'store';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import AWOGroupRowController from './AwoGroupRowController';
import AssemblyWorkOrderRowController from './AssemblyWorkOrderRowController';
import { thresholdFreedmanDiaconis, tickStep } from 'd3';
import { config } from 'react-transition-group';
import notifications from '../../services/Notification';
import { forEach } from 'lodash';

class CreateNewAWOController extends BaseController {
  constructor() {
    super('create-new-asset', CreateNewAWOController.getInitialState());
    this._validator = new Validator();
    this._validator.add('awoCustomer', 'AWO Customer ', [{ required: true }]);
    this._validator.add('owner', 'Owner', [{ required: true }]);
    this._validator.add('serviceLine', 'Service Line', [{ required: true }]);
    this._validator.add('equipmentRequiredDate', 'Equipment Required Date', [{ required: true }]);
    this._validator.add('system', 'System', [{ required: true }]);
    this._validator.add('awoNumber', 'AWO Number', [{ required: true }]);
    this._validator.add('awoDescription', 'AWO Description', [{ required: true }]);
    this._validator.add('customerLocation', 'Customer Location', [{ required: true }]);
    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state = CreateNewAWOController.getInitialState();
      }
    });

    messages.channel('create-new-asset').action('updated').subscribe(data => {
      if (SAPService.isSAPFacility() && data.assetId) {
        var loginInfo = store.get('InteServLoginInfo');
        if (loginInfo.userId = data.userId) {
          messages.channel('receiving').action('add-to-list').post([data.assetId]);
        }
      }
    });
  }

  static getInitialState = () => {
    return {
      awoCustomer: '',
      awoNumber: '',
      cwp: '',
      length: '',
      style: '',
      addedAWOSystemConfigurationRows: [],
      errors: {},
      equipmentNumber: '',
      isSaving: false,
      serviceLine: '',
      owner: '',
      equipmentRequiredDate: '',
      containerDescription: '',
      functionalLocation: '',
      pad: '',
      wellName: '',
      wellColor: '',
      system: '',
      awoDescription: '',
      addedAssemblyWorkOrderRows: [],
      minutes: '',
      parentChildList: [],
      file: '',
      uploadedFile: '',
      hasFile: false,
      previewUrl: '',
      savedOwner: '',
      customerLocation: '',
      order: '',
    };
  }

  load() {
    co(this, function* () {
      this.state = CreateNewAWOController.getInitialState();
      this.commit();
    });
  }

  createSystemConfigurationRow = (group, positionPackage, equipmentDescription, dependentEquipment, ischild, order) => {
    return {
      group: group,
      positionPackage: positionPackage,
      equipmentDescription: equipmentDescription,
      dependentEquipment: dependentEquipment,
      //dependentChild: dependentChild,
      isChild: ischild,
      order: order,
    };
  }

  createAssemblyWorkOrderRow = (awoDescription, awoCustomer, pad, wellName, system, cwp, equipmentRequiredDate, awoGroupRows, fileName, file, serviceLine, containerDescription, wellColor, awoNumber, functionalLocation) => {
    return {
      awoDescription: awoDescription,
      awoCustomer: awoCustomer,
      pad: pad,
      wellName: wellName,
      system: system,
      cwp: cwp,
      equipmentRequiredDate: equipmentRequiredDate,
      awoGroupRows: awoGroupRows,
      fileName: fileName,
      file: file,
      serviceLine: serviceLine,
      containerDescription: containerDescription,
      wellColor: wellColor,
      awoNumber: awoNumber,
      functionalLocation: functionalLocation
    };
  }

  getDefaultSystemConfigRow = () => {
    const defaultRowData = this.createSystemConfigurationRow('', '', '', '', null, '');
    this.state.addedAWOSystemConfigurationRows.push(new AWOGroupRowController(this, defaultRowData));
  }

  getAWOParentChildList = () => {
    this.state.parentChildList.push({ id: 0, name: 'Parent' });
    this.state.parentChildList.push({ id: 1, name: 'Child' });
    this.commit();
  }

  handleAddNewRow = () => {
    const defaultRowData = this.createSystemConfigurationRow('', '', '', '', null, '');
    this.state.addedAWOSystemConfigurationRows.push(new AWOGroupRowController(this, defaultRowData));
    this.commit();
  }

  validateExistingRows = () => {
    var isvalid = true;
    const rows = this.state.addedAWOSystemConfigurationRows;
    if (rows.length > 0) {
      rows.map(row => {
        const errors = row.validate();
        const errorList = Object.keys(errors);
        if (errorList.length > 0) {
          errorList.map(errorKey => {
            notification.action('error').post('System configuration data is required.');
          });
          isvalid = false;
          return isvalid;
        }
      });
    }

    // validate row order

    const configRows = rows.sort((a, b) => {
      return (parseInt(b.state.order) - parseInt(a.state.order));
    })
    let order = configRows.length;
    configRows.forEach(row => {
      if (row.state.order !== order.toString()) {
        isvalid = false;
        notification.action('error').post('Please assign increasing values for Order. Every configuration should have a unique value starting from 1 (one)');
      }
      order = order - 1;
    });

    return isvalid;
  }

  mergeState = (state) => {
    this.state.customer = state.customer;
    this.state.cwp = state.cwp;
    this.state.equipmentNumber = state.equipmentNumber;
    this.state.addedAWOSystemConfigurationRows = state.addedAWOSystemConfigurationRows;
  }


  ownerChanged = () => {
    if (this.state.addedAssemblyWorkOrderRows.length > 0) {
      this.state.owner = this.state.savedOwner;
      notification.action('error').post('Assembly Work Order can only be created for one Owner at a time.');
      this.commit();
      return true;
    }
    this.state.savedOwner = this.state.owner;
    this.state.containerDescription = '';
    this.state.functionalLocation = '';
    this.commit();
  }



  functionalLocationChanged = () => {
    this.state.containerDescription = '';
    this.commit();
  }

  customerSearch = () => {
    return function* (searchTerm) {
      const results = yield ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }



  cwpSearch = () => {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchLookupList/CWP', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }


  validateAWOs(state) {
    if (SAPService.isSAPFacility() === true && !state.minutes.length) {
      notification.action('error').post('Minutes are required');
      return false;
    }
    if (SAPService.isSAPFacility() === true && state.minutes && state.minutes === '0') {
      notification.action('error').post('Minutes should be greater than zero');
      return false;
    }
    return true;
  }

  canCreateAWOs = () => {
    this.state.errors = {};
    if (!this.validateAWOs(this.state)) {
      return false;
    }

    this.commit();
    return true;
  }

  createAWOs = () => {
    if (this.state.addedAssemblyWorkOrderRows.length === 0) {
      return false;
    }
    if (!this.canCreateAWOs()) {
      return;
    }
    var loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
      var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (this.state.minutes > availableTime) {
        notification.action('error').post("Minutes should not be more than Available Production Time");
        return false;
      }
    }
    //var awoNumbers = '';
    const emptyGuid = Utils.emptyGuid;
    const facilityId = LoginService.loginInfo ? LoginService.loginInfo.facilityId : '';
    co(this, function* () {
      const awoHeaderData = this.state.addedAssemblyWorkOrderRows.map(row => {

        const awoSystemConfigurationRows = row.state.awoGroupRows.map(configRow => {
          return {
            group_GUID: configRow.state.group?.id,
            position_Package_GUID: configRow.state.positionPackage?.id,
            equipment_Desc_GUID: configRow.state.equipmentDescription?.id,
            dependent_Equipment_GUID: configRow.state.dependentEquipment?.id,
            isEquipment_Depedent: false,
            isChild: configRow.state.isChild ? configRow.state.isChild.id === 1 ? true : false : false,
            order: configRow.state.order
          }
        });
        //awoNumbers = awoNumbers + ' ' + row.state.awoNumber + ',';

        return {
          awoCustomer: row.state.awoCustomer.id,
          owner_GUID: this.state.owner.id,
          customerlocationId: this.state.customerLocation ? this.state.customerLocation.id : Utils.emptyGuid,
          awoDescription: row.state.awoDescription,     // retrieved just customerLocation from user - repurpose as currentLocation
          serviceLine_GUID: row.state.serviceLine ? row.state.serviceLine.id : '',
          cwp_GUID: row.state.cwp ? row.state.cwp.id : Utils.emptyGuid,
          equipmentRequiredDate: row.state.equipmentRequiredDate,
          functionalLocation: row.state.functionalLocation ? row.state.functionalLocation.name : '',
          minutes: this.state.minutes,
          pad_WorkSite_Rig: row.state.pad,
          well_Name: row.state.wellName,
          well_Color_GUID: row.state.wellColor ? row.state.wellColor.id : emptyGuid,
          facility_GUID: facilityId,
          well_Color_System: row.state.system.id ? row.state.system.id : emptyGuid,
          Container_GUID: row.state.containerDescription ? row.state.containerDescription.id : emptyGuid,
          awoSystemConfiguration: awoSystemConfigurationRows,
          file: row.state.file,
          awoNumber: row.state.awoNumber
        }
      });

      const parameters = {
        assemblyWorkOrders: awoHeaderData,
      };
      try {
        this.state.isSaving = true;
        this.commit();
        const createdAWOIds = yield ajax.post('awoprogess/createAWOs', parameters); // in: CreateAssetDto, out: ReceivedAssetDto
        this.state.isSaving = false;
        this.state = CreateNewAWOController.getInitialState();
        this.commit();
        if (!SAPService.isSAPFacility()) {
          notification.action('success').post(Utils.pluralize('AWO', 's', createdAWOIds.length) + `${createdAWOIds.length > 1 ? " have" : " has"}` + ' been successfully created.');
        }
        else {
          if (createdAWOIds != undefined && createdAWOIds.length > 0) {
            const aWONumberList = createdAWOIds.map(x => {
              return x.awoNumber;
            })
            notification.action('success').post(aWONumberList.toString() + `${aWONumberList.length > 1 ? " were" : " was"}` + ' successfully created.');
            //notification.action('success').post(aWONumberList.toString() + ' were successfully created.');
          }
        }
        //awoNumbers = awoNumbers.substring(0, awoNumbers.length - 1);
        //  notification.action('success').post('Saved New Assembly Work order with AWO Number(s):' + awoNumbers);
      }
      catch (err) {
        this.state.isSaving = false;
        this.commit();
        notification.action('error').post(err.message);
      }
    });
  }

  isAWODescriptionUnique = (awoNumber) => {
    const parameters = {
      awoNumber: awoNumber
    };
    return ajax.get('lookup/isAWONumberUnique', parameters).then(isUnique => isUnique)
      .catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });

  }

  canAddToList = () => {
    var canProceed = true;
    if (SAPService.isSAPFacility()) {
      this._validator.remove('awoNumber');
    }
    if (!SAPService.isSAPFacility()) {
      this._validator.remove('customerLocation');
    }
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    this.commit();

    if (errors.length > 0) {
      return false;
    }


    // validate System Configuration rows
    if (!this.validateExistingRows()) {
      return false;
    }

    if (this.state.awoDescription === undefined || this.state.awoDescription.trim().length === 0) {
      notification.action('error').post({ msg: 'AWO Description is required', title: 'Error' });
      canProceed = false;
      return canProceed;
    }

    if (!SAPService.isSAPFacility() && (this.state.awoNumber === undefined || this.state.awoNumber.trim().length === 0)) {
      notification.action('error').post({ msg: 'AWO Number is required', title: 'Error' });
      canProceed = false;
      return canProceed;
    }
    var canProceed = this.validateRowsToAddToList();
    return canProceed;
  }

  validateRowsToAddToList = () => {
    var isValid = true;
    // validate AWODescription
    if (!SAPService.isSAPFacility()) {
      var awoList = this.state.addedAssemblyWorkOrderRows.filter(x => x.state.awoNumber === this.state.awoNumber);
      if (awoList && awoList.length > 0) {
        notification.action('error').post({ msg: 'AWO Number already exists.', title: 'Error' });
        return false;
      }
    }
    // all groups must have a parent row
    var groupIdList = [...new Set(this.state.addedAWOSystemConfigurationRows.map(x => x.state.group.name))];
    groupIdList.map(grpName => {
      const rowsInGroup = (this.state.addedAWOSystemConfigurationRows.filter(x => x.state.group.name === grpName && x.state.isChild?.id === 0))
      if (rowsInGroup === undefined || rowsInGroup.length === 0) {
        notification.action('error').post({ msg: 'Group- ' + grpName + ' does not have a parent row', title: 'Error' });
        isValid = false;
      }
      if (rowsInGroup.length > 1) {
        notification.action('error').post({ msg: 'Group- ' + grpName + '  have more than one parent row', title: 'Error' });
        isValid = false;
      }
    });
    return isValid;
  }


  addToList = () => {
    if (!this.canAddToList()) {
      return false;
    }
    let uniqueAWODescription;

    this.isAWODescriptionUnique(this.state.awoNumber).then(UniqueAWoDescriptionResult => {
      uniqueAWODescription = UniqueAWoDescriptionResult;
      if (!uniqueAWODescription?.isUnique) {
        notification.action('error').post('AWO Number already exists for Customer - ' + uniqueAWODescription?.awoCustomerName);
        return false;
      } else {
        const configRows = this.state.addedAWOSystemConfigurationRows;
        const asemblyWorkOrderRow = this.createAssemblyWorkOrderRow(this.state.awoDescription, this.state.awoCustomer, this.state.pad, this.state.wellName, this.state.system, this.state.cwp, this.state.equipmentRequiredDate, configRows, '', this.state.uploadedFile, this.state.serviceLine, this.state.containerDescription, this.state.wellColor, this.state.awoNumber, this.state.functionalLocation)
        const assemblyWorkOrderRows = this.state.addedAssemblyWorkOrderRows;
        assemblyWorkOrderRows.push(new AssemblyWorkOrderRowController(this, asemblyWorkOrderRow));
        this.state.addedAssemblyWorkOrderRows = assemblyWorkOrderRows;
        this.commit();
        this.reCreateConfigRows(configRows);
      }
    });
  }

  reCreateConfigRows = (configRows) => {
    const rows = configRows;
    const newRows = [];
    rows.map(row => {
      const rowData = this.createSystemConfigurationRow(row.state.group, row.state.positionPackage, row.state.equipmentDescription, row.state.dependentEquipment, row.state.isChild, row.state.order);
      newRows.push(new AWOGroupRowController(this, rowData));
      this.state.addedAWOSystemConfigurationRows = newRows;
    })
    this.commit();
  }

  removeFromList = (item) => {
    co(this, function* () {
      var equipmentDescriptionName = item.state.equipmentDescription.name === undefined ? '' : item.state.equipmentDescription.name;
      var rowsInGroup = '';
      var bodyText = 'Please confirm you wish to remove ' + equipmentDescriptionName + '. Dependent Equipment will also be deleted.';

      if (item.state.isChild && item.state.isChild?.id === 0) {
        rowsInGroup = (this.state.addedAWOSystemConfigurationRows.filter(x => x.state.group.name === item.state.group.name && x.state.isChild.id === 1))
        if (rowsInGroup.length > 0) {
          bodyText = "This row is designated as parent and" + ' has child(ren). Please confirm you wish to remove Parent and Child(ren).';
        }
      }

      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Equipment'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      let idx = this.state.addedAWOSystemConfigurationRows.indexOf(item);
      if (idx === -1) {
        return;
      }
      this.state.addedAWOSystemConfigurationRows.splice(idx, 1);
      if (rowsInGroup.length > 0) {
        this.removeChildRows(rowsInGroup);
      }
      this.commit();
    });
  }

  removeChildRows = (rowsInGroup) => {
    var configRows = this.state.addedAWOSystemConfigurationRows;
    var finalRows = configRows.filter(v => {
      return !rowsInGroup.includes(v);
    });
    this.state.addedAWOSystemConfigurationRows = finalRows;
    this.commit();
  }

  removeFromConfigList = (item) => {
    co(this, function* () {
      var bodyText = 'Please confirm you wish to remove AWO from list. AWO will not be created.';

      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove AWO'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      var configRows = this.state.addedAssemblyWorkOrderRows;
      let idx = configRows.indexOf(item);
      if (idx === -1) {
        return;
      }
      configRows.splice(idx, 1);
      this.state.addedAssemblyWorkOrderRows = configRows;
      this.commit();
    });
  }

  isInList = (primarySerialNumber) => {
    return this.state.addedSerialNumberRows.filter(row => row.state.primarySerialNumber === primarySerialNumber).length;
  }

  clearList = () => {
    co(this, function* () {
      const bodyText = 'Please confirm you wish to clear the System Configuration data. All rows will be removed and data will have to be recreated. ';
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Clear System Configuration'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      this.state.addedAWOSystemConfigurationRows = [];
      // this.state.hasFile = false;
      // this.state.uploadedFile = '';
      // this.state.file = '';
      this.commit();
    });
  }

  isRowSelected = (row) => {
    return (this.state.selectedSerialNumberRow === row);
  }

  bornOnDateChanged = () => {
    this.commit();
  }

  primarySerialNumberChanged = () => {
    var primarySerialNumber = this.state.primarySerialNumber.replace(/^\s+|\s+$/g, '');
    this.state.primarySerialNumber = primarySerialNumber;
    document.getElementById('create-asset-primary-serial-number').value = primarySerialNumber;
    this.commit();
  }

  customerChanged = () => {
    this.state.matchingAssets = [];
    this.state.selectedMatchingAsset = '';
    this.commit();
  }

  uploadFile = (res) => {
    this.state.file = '';
    this.state.uploadedFile = '';
    this.state.hasFile = false;
    this.commit();
    if (res && (res.file.size / 1048576).toFixed(1) < 25) {
      let name = '';
      if (navigator.userAgent.match(/iPad/i) != null) {
        //name = res.file.name.split('.')[0] + '_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' }) + '.' + res.file.name.split('.')[1].toLowerCase();
        name = res.file.name;
      }
      else {
        name = res.file.name;
      }
      const newFile = {
        fileType: res.file.name.split('.')[1] || '',
        fileName: name,
        fileData: res.imageUrl
      };
      this.state.file = res;
      this.state.uploadedFile = newFile;
      this.state.hasFile = true;
      this.setPreviewImageLink(URL.createObjectURL(res.file));
      this.commit();
    }
    else {
      notification.action('error').post({ msg: 'File size is larger that 25 MB', title: 'File Error' });
    }

  }

  removeUploadedFile = () => {

    co(this, function* () {
      var bodyText = 'Please confirm you wish to remove this diagram- the file will be deleted.';

      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Diagram'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      this.state.file = '';
      this.state.uploadedFile = '';
      this.state.hasFile = false;
      this.commit();
    });
  }

  setPreviewImageLink = (url) => {
    this.state.previewUrl = url;
  }

  customerLocationSearch = () => {
    const self = this;
    return function* (searchTerm) {
      const customerId = (self.state.owner && self.state.owner.id);
      if (!customerId) {
        notification.action('warning').post('Please select a Owner so you can choose a Customer Location.');
        return null;
      }
      const facilityId = LoginService.loginInfo.facilityId;
      const results = yield ajax.get('lookup/searchCustomerLocations', { facilityId: facilityId, customerId: customerId, searchTerm: searchTerm }); // IList<CustomerLocationLookupDto>
      return results;
    };
  }

  getSelectedParentGroups = () => {
    var s = this.state;
    var groups = s.addedAWOSystemConfigurationRows?.filter(y => y.state.group?.name !== undefined && y.state.isChild?.id === 0).map(x => x.state.group?.name);
    return groups;
  }

}

export default CreateNewAWOController;
