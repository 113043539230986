import React, { useMemo, useEffect } from 'react';
import ActionIcon from '../../components/ActionIcon';
import DropoffController from '../controllers/DropoffController';
import Dialog from '../../components/Dialog';
import Jumbotron from '../../components/Jumbotron';
import Form from '../../components/Form';
import TopPagination from '../components/TopPagination';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Dropdown from '../../components/Dropdown';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Controller from '../../mixins/Controller';
import PickJobDialog from '../dialogs/PickJobDialog';
import PickJobController from '../controllers/PickJobController';
import DropOffTimeDialog from '../dialogs/DropOffTimeDialog';
import DropOffTimeController from '../controllers/DropOffTimeController';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import Search from '../components/Search';
import Authentication from '../services/authentication';

const getReceivingTargetDate = (createdDate) => {
    if (!createdDate) {
        return '';
    }
    const targetDate = DateTimeFormatter.getMoment(createdDate).add(48, 'h');
    return targetDate;
}

const getReceivingTargetClassName = (dropoff) => {
    const now = DateTimeFormatter.now();
    const receivingTargetDate = getReceivingTargetDate(dropoff.createdDate);
    let receivingTargetDateClassName;
    if (now > receivingTargetDate) {
        receivingTargetDateClassName = 'late-error';
    }
    else {
        now.add(6, 'h');
        if (now > receivingTargetDate) {
            receivingTargetDateClassName = 'late-warning';
        }
    }
    return receivingTargetDateClassName;
}

const getCompletedClassName = (dropoff) => {
    let completedClassName;
    const completedDate = DateTimeFormatter.getMoment(dropoff.completedDate);
    const receivingTargetDate = getReceivingTargetDate(dropoff.createdDate);

    if (completedDate > receivingTargetDate) {
        completedClassName = 'late-error';
    }

    if (dropoff.isComplete && (completedDate < receivingTargetDate)) {
        completedClassName = 'early';
    }
    return completedClassName;
}

const SearchHeader = (props) => {
    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.searchDropoffs();
    }
    const handleMouseSearch = () => {
        props.controller.searchDropoffs();
    }
    const clearSearch = () => {
        props.controller.clearSearch();
    }

    const binder = props.binder;
    const controller = props.controller;
    const placeholder = 'number';
    const isSAPRep = LoginService.isSAPRep;
    const isAdministrator = LoginService.isAdministrator;
    const formatFacilityResult = (item) => {
        const spanSAP = item.result.useSAP
            ? <span className='user-facility-sap pull-right select2-tag'> SAP</span>
            : null;
        if (item.result.useSAP) {
            return <span>{item.text} {spanSAP}</span>;
        }
        return <span>{item.text}</span>;

    };
    return (
        <div {...props} id='search-criteria'>
            <div className='row'>
                <div className='col-md-6'>
                    <Search type='number' className='mb-2' id='searchText' inputClassName='search-input  text-uppercase' label='Enter search text' placeholder='number' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
                </div>
                <div className='col-md-6'>
                    <div id='customer-search'>
                        <Form.AutoComplete bind={binder.bind('customer')} label='Customers' prependLabelIcon='fa-filter' placeholder='Select a customer to filter your results' search={controller.customersSearch()} />
                    </div>
                </div>
            </div>
            <div className='row'>
                {
                    (isSAPRep && !isAdministrator) &&
                    <div className='col-md-6'>

                        <div id='facility-search'>
                            <Form.AutoComplete formatResult={formatFacilityResult} formatSelection={formatFacilityResult} placeholder='Select a facility to filter your results' label='Facilities' prependLabelIcon='fa-filter' bind={binder.bind('facility')} search={controller.facilitySearch()} />
                        </div>

                    </div>
                }
                <div className='col-md-6'>
                    <div className='radio-search drop-off'>
                        <Form.Label name='Drop-Off status' />
                        <Form.RadioInput bind={binder.bind('searchActive')} label='Active' />
                        <Form.RadioInput bind={binder.bind('searchCompleted')} label='Completed' />
                    </div>
                </div>
            </div>
        </div>
    );
}

const Results = (props) => {
    const isSAPRep = LoginService.isSAPRep;
    const isAdministrator = LoginService.isAdministrator;
    const controller = props.controller;
    const data = controller.state.searchResults;
    const matchesCount = controller.state.searchResults.length;
    const matchesLabel = Utils.pluralize('match', 'es', matchesCount);
    const handleRowClick = (data) => {
        props.controller.loadDropoffDetails(data);
    }

    const handlePageChange = () => {
        TopPagination.Toggle();
    }

    useEffect(() => {
        handlePageChange();
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'isComplete',
                Cell: cellInfo => cellInfo.cell.value
                    ? <span className='dropoff-complete status-column' title='Drop-Off is complete – no further changes can be made'><i className='fa fa-ban' /></span>
                    : <span className='dropoff-completion-candidate status-column' title="Click 'Complete' to remove this entry from the list"><i className='fa fa-check' /></span>
            },
            {
                Header: 'Number',
                accessor: 'dropoffNumber',
                Cell: (cellInfo) => <Form.Link to={'/dropoff/' + cellInfo.row.original.dropoffId} value={cellInfo.cell.value} />
            },
            {
                Header: 'Customer',
                accessor: 'customerName',
            },
            {
                Header: 'Facility',
                accessor: 'facilityName',
                isVisible: isSAPRep && !isAdministrator
            },
            {
                Header: 'Created Date',
                accessor: 'createdDate',
                Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
            },
            {
                Header: controller.state.searchCompleted ? 'Completed Date' : 'Receiving Target Date',
                accessor: controller.state.searchCompleted ? 'completedDate' : 'receivingTargetDate',
                Cell: cellInfo => {
                    const receivingTargetDate = getReceivingTargetDate(cellInfo.row.original.createdDate);
                    const receivingTargetDateClassName = getReceivingTargetClassName(cellInfo.row.original);
                    const completedDate = cellInfo.row.original.completedDate;
                    const completedDateClassName = getCompletedClassName(cellInfo.row.original);
                    return controller.state.searchCompleted
                        ? <span className={completedDateClassName}>{DateTimeFormatter.formatDateTime(completedDate)}</span>
                        : <span className={receivingTargetDateClassName}>{DateTimeFormatter.formatDateTime(receivingTargetDate)}</span>;
                }
            },
            {
                Header: 'Requested Date',
                accessor: 'requestedDate',
                Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
            },
            {
                Header: 'Service Goal',
                accessor: 'destinationType',
                Cell: cellInfo => <span>{(cellInfo.cell.value === AppConstants.eDropoffDestination.IdleAssets) ? 'Stored until further notice' : 'Serviced'}</span>
            },
            {
                Header: 'Application',
                accessor: 'application'
            },
            {
                Header: 'Equipment Category',
                accessor: 'equipmentCategory',
            },
        ],
        [controller.state.searchCompleted]
    )
    return (
        <div className='col-md-12'>
            <div className='d-flex justify-content-between'>
                <TopPagination />
                <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
            </div>
            <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText='No Drop-Off forms meet the search criteria defined above' enableDefaultRowSelect={true} onPageChange={handlePageChange} />
        </div>
    );
}

class Details extends React.Component {

    render = () => {
        const controller = this.props.controller;
        const selectedForm = controller.state.selectedForm;
        let destination = '';
        if (selectedForm.destinationType === AppConstants.eDropoffDestination.AssignToJob) {
            destination = 'Serviced';
        } else if (selectedForm.destinationType === AppConstants.eDropoffDestination.IdleAssets) {
            destination = 'Stored until further notice';
        }
        const receivingTargetDate = getReceivingTargetDate(selectedForm.createdDate);
        const completedDateClassName = getCompletedClassName(selectedForm);
        const jobId = selectedForm.jobId;
        let jobElement = <Form.StaticData label='Job Number' value={selectedForm.jobNumber} />;
        if (LoginService.isCoordinator) {
            jobElement = <Form.Link to={'/jobprogressassets/' + jobId} label='Job Number' value={selectedForm.jobNumber} />;
        }
        let customerLocationElement = <Form.StaticData label='Customer Location' value={selectedForm.customerLocationName} />;
        if (LoginService.isAdministrator) {
            customerLocationElement =
                <Form.Link label='Current Location' to={'/customerlocations/' + selectedForm.customerLocationId} value={selectedForm.customerLocationName} />;
        }
        return (
            <Jumbotron id='dropoff-details' className='form-group col-sm-12'>
                <div className='row'>
                    <div className='col-sm-12'><Form.StaticData label='Customer' value={selectedForm.customerName} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>{customerLocationElement}</div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'><Form.StaticData label='Application' value={selectedForm.application} />
                    </div>
                    <div className='col-sm-6'><Form.StaticData label='Equipment Category'
                        value={selectedForm.equipmentCategory} /></div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'><Form.StaticData label='Equipment Details' value={selectedForm.equipmentDetails} /></div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'><Form.StaticData label='Service Goal' value={destination} /></div>
                    <div className='col-sm-6'>{jobElement}</div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'><Form.StaticData label='Created Date' value={DateTimeFormatter.formatDateTime(selectedForm.createdDate)} /></div>
                    <div className='col-sm-6'><Form.StaticData label='Receiving Target Date' value={DateTimeFormatter.formatDateTime(receivingTargetDate)} /></div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'><Form.StaticData label='Requested Date' value={DateTimeFormatter.formatDate(selectedForm.requestedDate)} /></div>
                    <div className={'col-sm-6 ' + completedDateClassName}><Form.StaticData label='Completed Date' value={DateTimeFormatter.formatDateTime(selectedForm.completedDate)} /></div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'><Form.StaticData label='Delivered By' value={selectedForm.deliveredByName} /></div>
                    <div className='col-sm-6'><Form.StaticData label='Service Interval' value={selectedForm.serviceInterval} /></div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'><Form.StaticData label='Service' value={selectedForm.service} /></div>
                    <div className='col-sm-6'><Form.StaticData label='Customer PO Number' value={selectedForm.customerPO} /></div>
                </div>
            </Jumbotron>
        );
    }
}

const Buttons = (props) => {

    const handleAddTime = (form, canaddTime) => {
        Dialog.showDialog(<DropOffTimeDialog controller={new DropOffTimeController(form.dropoffId, form.jobId, form.isComplete, canaddTime)} />);
    }

    const handleAttachToJob = () => {
        co(function* () {
            const dialogController = new PickJobController(props.controller.state.selectedForm);
            yield Dialog.showDialogWaitForResult(<PickJobDialog controller={dialogController} header='Attach Drop-Off to Job' />);
        });
    }

    const handleDetachFromJob = () => {
        props.controller.detachFromJob();
    }

    const handleComplete = (dropoffForm) => {
        props.controller.complete(dropoffForm, true);
    }

    const controller = props.controller;
    const form = controller.state.selectedForm;
    const canEdit = form && !form.isComplete;
    const editText = canEdit ? 'Edit' : 'View';
    const canConvert = canEdit && controller.canConvertToJob();
    const canAttach = canEdit && controller.canAttachToJob();
    const canDetach = canEdit && controller.canDetachFromJob();
    const canaddTime = form.sapStatus == 'NOCO' || form.sapStatus == 'RPIG' || form.sapStatus == 'RPOR' || form.sapStatus == 'SHIP' || form.sapStatus == 'NOBL';
    const addTime = (SAPService.isSAPFacility() && SAPService.useTTS() && LoginService.loginInfo.isClockedIn && !LoginService.loginInfo.isClockedOut && form)
        ? <Dropdown.MenuItem icon='fa fa-clock fa-flip-horizontal' onClick={() => { handleAddTime(form, canaddTime); }} name='Add Drop-Off Time ...' />
        : null;
    const queryData = {
        customer: { id: form.customerId, name: form.customerName },
        customerLocation: { id: form.customerLocationId, name: form.customerLocationName },
        customerPO: form.customerPO,
        equipmentDetails: form.equipmentDetails,
        dropoffNumber: form.dropoffNumber,
        requestedDate: form.requestedDate,
        createdDate: form.createdDate,
        dropoffId: form.dropoffId
    };

    return (
        <div className='col mb-1'>
            <div className='row'>
                <Button to={'/dropoff/' + form.dropoffId} disabled={!form} icon='fas fa-pencil-alt' variant='primary' size='sm' className='action-button m-1'> {editText}... </Button>
                <Button to={'/dropoff/' + Utils.emptyGuid} icon='fa-plus-circle' variant='success' size='sm' className='action-button m-1'>Create New...</Button>
                <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Drop-Off Actions'>
                    {!LoginService.isOnlyTechnician && <Dropdown.MenuItem icon='fa-cog' to={{ pathname: '/job/' + Utils.emptyGuid, dropoff: queryData }} disabled={!canConvert} name='Convert to Job...' />}
                    <Dropdown.MenuItem icon='fa-link' disabled={!canAttach} onClick={handleAttachToJob} name='Attach to Job...' />
                    {addTime}
                    <Dropdown.MenuItem icon='fa-unlink' disabled={!canDetach} onClick={handleDetachFromJob} name='Remove Attachment to Job ...' />
                    <Dropdown.MenuItem icon='fa-check' disabled={!canEdit} onClick={() => { handleComplete(form); }} name='Complete ...' />
                </Dropdown.Button>
            </div>
        </div>
    );
}

const DropoffPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa-truck fa-flip-horizontal' />

class DropoffPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
    }

    static defaultProps = {
        controller: new DropoffController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        return (
            <Page {...this.props} pageTitle='InteServ · Drop-Off' id='dropoff-page'>
                <DropoffPageHeader pageTitle={'Equipment Drop-Off'} />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <SearchHeader controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <Results controller={controller} binder={binder} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Buttons controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <Details controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );

    }
}

export default DropoffPage;
