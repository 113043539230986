import BaseController from '../../lib/BaseController';
import messages from '../../services/Messages';
import notification from '../../services/Notification';

class ReceivedAssetDailogController extends BaseController {
    constructor(parentController, selectedassets) {
        super('receive-asset-details', {
            selectedassets: selectedassets,
            parentController: parentController,
            finalassets: selectedassets.map(x=>x.assetId),
            allassetIds : selectedassets.map(x=>x.assetId)

        });
    }
    addremoveassets = (assetId) => {
        var assetIds = [...this.state.finalassets];
        if (assetIds.indexOf(assetId) > -1) {
            assetIds.splice(assetIds.indexOf(assetId), 1);
        }
        else{
            assetIds.push(assetId);
        }       
        this.state.finalassets = assetIds;
        this.commit();
    }
    save = ()=>{
        var assetIds = [...this.state.finalassets];
        if(assetIds.length>0)
            {
                messages.channel('receiving').action('add-to-list').post(assetIds);
                return true;
            }
        else{
            notification.action('error').post({ msg: "Please select Asset(s) to receive", title: 'Error' });
            return false;
        }
        
    }
    selectAllAssets=()=>{
        const allassets  = [...this.state.allassetIds];
        this.state.finalassets = allassets;
        this.commit();
        return false;
    }
    selectNoneAsset=()=>{
        this.state.finalassets = [];
        this.commit();
        return false;
    }
}
export default ReceivedAssetDailogController;