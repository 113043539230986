import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';

class ConfirmReapplyAssemblyLevelsDialog extends React.Component{
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
}

componentDidMount = () => {
  this.initializeMixin();
}

  render = () => {
     const binder             = new Binder(this);
    const controller         = this.props.controller;
    const groupCount         = controller.state.groups.length;
    const selectedGroupCount = controller.state.selectedGroups.length;
    const displayMessageOnly = ((selectedGroupCount === 0) || (selectedGroupCount === groupCount));
    const message            = displayMessageOnly
                                ? 'Please confirm you wish to reapply the assembly levels for the ' + (groupCount === 1 ? 'group' : groupCount + ' groups')
                                : 'Please make a selection from the following and confirm you wish to reapply service levels for the ' + (selectedGroupCount === 1 ? '' : selectedGroupCount + ' ') + 'selected group' + (selectedGroupCount === 1 ? '' : 's') ;
    const warning            = 'Warning: any selected assembly levels will be reset and you will have to reapply them';

    let options = null;
    if (!displayMessageOnly) {
      const option1 = (groupCount === 2 ? 'Both' : 'All ' + groupCount) + ' groups on the AWO';
      const option2 = 'Only the ' + (selectedGroupCount === 1 ? 'selected group' : selectedGroupCount + ' selected groups');
      options = (
        <div className='col-sm-12 text-center mx-2'>
          <Form.RadioInput bind={binder.bind('reapplyAll')} label={option1}/>
          <Form.RadioInput bind={binder.bind('reapplySelectedOnly')} label={option2}/>
        </div>
      );
    }

    return (
      <Dialog {...this.props} header={this.props.header} width={750} height={350} id='confirm-reapply-service-levels-dlg'>
        <Dialog.Body>
            <h5 className='col-sm-12 text-center' >{message}</h5>
            {options}
            <div className='col-sm-12 text-center font-weight-bold text-danger mt-3'>{warning}</div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons  saving={this.props.controller.saving}/>
      </Dialog>
    );
  }
}

export default ConfirmReapplyAssemblyLevelsDialog;
