import BaseController from '../../lib/BaseController';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
import ajax from '../../services/Ajax';

class QuotesDetailController extends BaseController {
  constructor() {
    super('quotedetail', QuotesDetailController.getInitialState());

    this.flash = emitter();
  }

  static getInitialState = () => {
    return {
      quotes: [],
      notes: [],
      requestedDate: '',
      quoteNotes: []
    };
  }

  flashNote = (id) => {
    this.flash.emit(id);
  }

  getTitle = (jobNumber) => {
    return 'Quote Detail — ' + jobNumber;
  }

  mergeQuotesAndNotes = () => {
    this.state.quoteNotes = this.state.quotes.concat(this.state.notes);
    this.state.quoteNotes.sort(this.quoteNotesSort);
    this.commit();
  }

  quoteNotesSort = (a,b) => {
    const dateA = new Date(a.dateAdded).getTime(); 
    const dateB = new Date(b.dateAdded).getTime(); 
    return dateA < dateB ? 1 : -1; 
  }

  load = (jobId) => {
    if (jobId) {
      co(this, function* () {
        const quotenotes = yield ajax.get('lookup/getJobQuotes/', { jobId: jobId }); // ReleaseNotesDto
        this.state.quotes = quotenotes.quotes;
        this.state.notes = quotenotes.notes;
        //this.state.quoteText = quotes.note;
        this.state.quotes.forEach(item =>{
          item.Type = 0
        });
        this.state.notes.forEach(item=> {
          item.Type = 1
        });
        this.commit();
        this.mergeQuotesAndNotes();
      });
    }
  }
}

export default QuotesDetailController;
