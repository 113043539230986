import BaseController from '../../lib/BaseController';

class CustomerFileNameRowController extends BaseController {
    constructor(parentController, file) {
      super('customer-file-name-row-controller-' + CustomerFileNameRowController.key++, {
        file: file ? file : {},
        description : file ? file.description : ''
      });
      this.parentController = parentController; 
    }

    componentDidMount(){
      this.state.description = '';
    }

    static key = 1;
  
    canRemoveRow = () => {
      return true;
    }
  
    removeRow = () => {
      if (!this.canRemoveRow()) {
        return;
      }
      if(this.state.file){
        if(this.state.file.recordStatus === 'A'){
          this.parentController.deleteFile(this);
        }
        else{
          this.state.file.recordStatus = 'D';
          this.commit();
          this.parentController.commit();  
          this.parentController.save(()=>{});     
        }      
      }
    }
}
    
  export default CustomerFileNameRowController;
  