import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';

class ResetPasswordController extends BaseController {
  constructor(user) {
    super('reset-password', {
      user     : user,
      password : '',
      error    : '',
      isLoading: false
    });
  }

  save = () => {    
    const password = this.state.password;
    if (!password.length) {
      this.state.error = 'A password is required';
      this.commit();
      document.getElementById('password').focus();
      return false;
    }

    const user       = this.state.user;
    const parameters = {
      userId      : user.userId,
      loginName   : user.loginName,
      newPassword : password
    };

    this.state.isLoading = true;
    this.commit();

    return ajax.post('user/resetPassword', parameters).then(() => {
      this.state.isLoading = false;
      this.commit();
      notification.action('success').post('Changed password for user <b>' + user.loginName + '</b>');
      messages.channel('edit-user').action('password-reset').post(user);     
      return true;
    })
    .catch(err => {
      this.state.isLoading = false;
      this.commit();
      notification.action('error').post(err.message);
      return false;
    });
  }
}

export default ResetPasswordController;