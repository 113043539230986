import React from 'react';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import GroupBadge from '../../components/GroupBadge';
import ActionIcon from '../../components/ActionIcon';
// var Search = require('../components/Search.jsx');
import Roles from '../utility/Roles';
import List from '../../components/List';

const User = (props) => {
  const getRoleClass = (user) => {
    if (!user.loginEnabled) {
      return ' login-disabled';
    }
    if (user.roleName === Roles.Administrator) {
      return ' user-administrator';
    }
    if (user.roleName === Roles.SuperUser) {
      return ' user-super-user';
    }
    if (user.roleName === Roles.DataClerk) {
      return ' user-data-clerk';
    }
    if (user.roleName === Roles.Coordinator) {
      return ' user-coordinator';
    }
    if (user.roleName === Roles.SAPRep) {
      return ' user-sap-rep';
    }
    if (user.roleName === Roles.Technician) {
      return ' user-technician';
    }
    return '';
  }

  const toggleSelection = (user) => {
    props.controller.toggleSelection(user);
  }
  const user = props.user;
  let className = 'user list-group-item';
  const spanDisabled = user.loginEnabled
    ? null
    : <span><i className='fa fa-ban archived' />&nbsp;&nbsp;</span>;

  const spanRole = <span className={'rolename' + getRoleClass(user)}>{user.roleName}</span>;

  return (
    <List.Item className={className + ' p-2'} active={user.selected} onClick={() => { toggleSelection(user); }} >{spanDisabled}{user.name} {spanRole}</List.Item>
  );
}

const AllUsers = (props) => {
  const selectNone = () => {
    props.controller.selectNoUsers();
  }

  const selectAll = () => {
    props.controller.selectAllUsers();
  }
  const controller = props.controller;
  const binder = props.binder;
  const allUsers = controller.state.allUsers || [];
  let badgeText = '0 selected users'; // default
  const selectedCount = allUsers.reduce((acc, curr) => {
    return (acc + curr.selected ? 1 : 0);
  }, 0);
  if (allUsers.length) {
    badgeText = selectedCount + ' / ' + allUsers.length + ' selected users';
  }
  const noResults = allUsers.length
    ? null
    : <li className='list-group-item no-search-results'>No users meet the search criteria defined above</li>;

  return (
    <div className='col-md-5'>
      <div className='col-md-12 mb-1'>
        <div className='row'>
          {/* <Search
            clearSearchFn = {controller.clearSearch}
            searchFn      = {controller.searchAllUsers}
            controller    = {controller}
            binder        = {binder}
            autoFocus     = 'true'
            searchId      = 'searchUser'
            placeholder   = 'search users' /> */}
          <Form.Input id='searchUser' label='Enter search text' prependLabelIcon='fa-search' className='search-input' placeholder='search users' autoFocus='true' bind={binder.bind('searchUser')} />
          <ActionIcon icon='fa-times-circle' className='fa-2x clearable' action={controller.clearSearch} />
          <div><Button icon='fa-search' variant='primary' size='sm' className='btn-search-icon' onClick={controller.searchAllUsers} />
          </div>
        </div>
      </div>
      <div id='form-buttons' className='row'>
        <div className='col-md-12'>
          <div className='d-flex justify-content-start'>
            <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
            <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
            <Badge variant='info' className='m-1'>{badgeText}</Badge>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row all-facility-list'>
          <List id='list-group user-list' style={{paddingLeft: 'unset'}}>
            {noResults}
            {
              allUsers.map(user => {
                return <User key={user.id} controller={controller} user={user} />;
              })
            }
          </List>
        </div>
      </div>
    </div>
  );
}

const FacilityUsers = (props) => {
  const selectNone = () => {
    props.controller.selectNoFacilityUsers();
  }
  const selectAll = () => {
    props.controller.selectAllFacilityUsers();
  }
  const controller = props.controller;
  const facilityUsers = controller.state.facilityUsers;
  const selectedLabel = facilityUsers.length + ' selected ' + ((facilityUsers.length === 1) ? 'user' : 'users');

  return (
    <div className='col-md-5'>
      <div id='form-buttons' className='row mb-1'>
        <div className='col-md-12'>
          <div className='d-flex justify-content-start'>
            <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
            <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
            <Badge variant='info' className='m-1'>{selectedLabel}</Badge>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row facility-list'>
          <List id='list-group selected-user-list' style={{paddingLeft: 'unset'}}>
            {
              facilityUsers.map(user => {
                return <User key={user.id} controller={controller} user={user} userFacility={true} />;
              })
            }
          </List>
        </div>
      </div>
    </div>
  );
}

const TransferButtons = (props) => {
  const select = () => {
    props.controller.moveToSelected();
  }

  const unselect = () => {
    props.controller.removeFromSelected();
  }

  const selectDisabled = false;
  const unselectDisabled = false;

  return (
    <div id='transfer-buttons' className='mt-5 col-md-2 mt-5'>
      <div className='row'>
        <div className='col-md-12'>
          <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
          <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect}>Unselect</Button>
        </div>
      </div>
    </div>
  );
}

class SelectFacilityUsersDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const labelText = 'Select the users which should have access to {facilityName}'.interpolate(controller.state.facility);

    return (
      <Dialog {...this.props} header={this.props.header} width={720} height={650} id='change-facility-user-access-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <div className='form-group col-md-12'>
              <h5>{labelText}</h5>
              <div className='row'>
                <AllUsers controller={controller} binder={binder} />
                <TransferButtons controller={controller} />
                <FacilityUsers controller={controller} />
              </div>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons />
      </Dialog>
    );
  }
}

export default SelectFacilityUsersDialog;
