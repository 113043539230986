import React, { useState, useEffect, useMemo } from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Grid from '../../components/Grid';
import Form from '../../components/Form';
import Table from '../../components/Table';
import Utils from '../utility/Utils';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import TimeConfirmationController from '../controllers/TimeConfirmationController';
import LoginService from '../services/LoginService';
import Searchers from '../utility/Searchers';

const AdditionalList = (props) => {

    const GetWorkingTime = () => {
        const LoginInfo = LoginService.loginInfo;
        const totalTime = DateTimeFormatter.getDuration(LoginInfo.clockedInTime, LoginInfo.isBreakOut ? LoginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()));
        const workingTime = parseFloat(totalTime - (LoginInfo.totalBreakTime + (LoginInfo.totalProductiveTime || 0))).toFixed(1);
        return workingTime;
    }

    const [state, setState] = useState({ workingTime: GetWorkingTime() });

    const updateTimer = () => {
        setState({ workingTime: GetWorkingTime() });
    }

    useEffect(() => {
        const interval = setInterval(() => updateTimer(), 100);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const controller = props.controller;
    const loginInfo = LoginService.loginInfo;
    const ClockedInTime = loginInfo.clockedInTime;
    const data = controller.state.additionalTimeData.map(row => row);
    const columns = useMemo(
        () => [
            {
                Header: 'Work Type',
                accessor: 'Type',
                Cell: cellInfo => <span>{((cellInfo.cell.value === 'PerDiem') ? '' : cellInfo.cell.value)}</span>
            },
            {
                Header: 'Time',
                accessor: 'TotalTime',
                Cell: cellInfo => {
                    let hours = 0;
                    let minutes = 0;
                    let final = '';
                    let TotalPaid = 0;
                    let GrandTotal = 0;
                    const overHeadHours = state.workingTime;

                    if (cellInfo.row.original.Type === 'Overhead:') {
                        const workingTimeInHrs = Utils.convertMinsToHrsMins(overHeadHours);
                        final = (workingTimeInHrs + ' / ' + overHeadHours + ' min');
                    }
                    else if (cellInfo.row.original.Type === 'Paid Time:') {
                        TotalPaid = Number(overHeadHours) + (Number(cellInfo.cell.value) ? Number(cellInfo.cell.value) : 0);
                        hours = TotalPaid ? Utils.convertMinsToHrsMins(TotalPaid) : 0;
                        minutes = TotalPaid ? TotalPaid : 0;
                        final = (hours + ' / ' + minutes.toFixed(1) + ' min');
                    }
                    else if (cellInfo.row.original.Type === 'Total Time:') {
                        GrandTotal = Number(overHeadHours) + (Number(cellInfo.cell.value) ? Number(cellInfo.cell.value) : 0);
                        hours = GrandTotal ? Utils.convertMinsToHrsMins(GrandTotal) : 0;
                        minutes = GrandTotal ? GrandTotal : 0;
                        final = (hours + ' / ' + minutes.toFixed(1) + ' min');
                    }
                    else if (cellInfo.row.original.Type !== 'PerDiem') {
                        hours = cellInfo.cell.value === null ? '' : Utils.convertMinsToHrsMins(cellInfo.cell.value);
                        minutes = (cellInfo.cell.value === null ? '' : cellInfo.cell.value);
                        final = cellInfo.cell.value === null ? '' : (hours + ' / ' + minutes + ' min');
                    }

                    return <span>{final}</span>;
                }
            },
        ],
        [state.workingTime]
    )

    return (
        <div id='additionalValue-div' className style={{ overflowY: 'auto' }} >
            <Form.StaticData label='Clocked In @' value={ClockedInTime} />
            <Grid id='additionalValue-table' columns={columns} data={data} showPagination={false} disableSortBy={true} noDataText='No Records Found' />
        </div>
    );

}
const PerDiem = (props) => {
    const { controller, binder } = props;
    const errors = controller.state.errors;
    const base = <Form.AutoComplete label='Base' bind={binder.bind('base')} search={Searchers.sapFacilitySearch()} />
    const costCenterProps = {
        id: 'costCenter',
        className: controller.state.base ? 'required' : '',
        error: controller.state.base ? errors.costCenter : '',
        bind: binder.bind('costCenter'),
        label: 'Cost Center',
        search: Searchers.searchCostCenterWithFacilityId(controller.state.base ? controller.state.base.id : '')
    }
    const costCenter = <Form.AutoComplete {...costCenterProps} />
    const disabled = controller.state.isPerDiemSelected;
    return (
        <div id='additionalValue-div'>
            <Table id='additionalValue-table' bordered={false} hover={false} striped={false} responsive={false}>
                <Table.Header>
                    <Table.Row>
                        <Table.Head colSpan='2'>Per Diem</Table.Head>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row className='perDiem-row'>
                        <Table.Data colSpan='2'>
                            <div className='row'>
                                <div className='col-4'>
                                    <div id='col-perDiem-travel'><Form.CheckBox label='Travel Per Diem' bind={binder.bind('travelPerDiem')} enabled={!disabled} disabled={disabled} /></div>
                                </div>
                                <div className='col-5'>
                                    <div id='col-perDiem-half-day'><Form.CheckBox label='Half Day Field Per Diem' bind={binder.bind('halfDayFieldPerDiem')} enabled={!disabled} disabled={disabled} /> </div>
                                </div>
                                <div className='col-3'>
                                    <div id='col-perDiem-nigth-shift'><Form.CheckBox label='Night Shift' bind={binder.bind('nightShift')} enabled={!disabled} disabled={disabled} /></div>
                                </div>
                            </div>
                        </Table.Data>
                    </Table.Row>
                    <Table.Row className='perDiem-row'>
                        <Table.Data colSpan='2'>
                            <div className='row'>
                                <div className='col-6'> {base}       </div>
                                <div className='col-6'> {costCenter} </div>
                            </div>
                        </Table.Data>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );

}

class TimeConfirmationDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        popup: 'popup',
        controller: new TimeConfirmationController()
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.props.controller.load(LoginService.loginInfo.userId);
    }

    handleClose = (result) => {
        if (result.Cancel) {
            return true;
        }
        
        if(this.props.controller.validateSave()== true){
        return this.props.controller.saveManHours().then((result) => {
            const loginInfo = LoginService.loginInfo;
            this.props.controller.load(loginInfo.userId);
            return result; 
        });
        }
    }

    render = () => {

        var binder = new Binder(this);
        var controller = this.props.controller;
        var ClockInTitle = 'Time Confirmation to CATS';

        return (
            <Dialog {...this.props} modal={true} width={560} height={570} header={ClockInTitle} id='timeconf-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='timeconf-dlg-body'>
                        <div className='row'><div className='col'> <AdditionalList controller={controller} /></div></div>
                        <div className='row'><div className='col'> <PerDiem controller={controller} binder={binder} /></div></div>
                    </div>
                </Dialog.Body>
                <Dialog.Buttons>
                    <Dialog.Button variant='primary' size='sm' className='btn' icon='fa-upload' disabled={controller.isInProgress} result={{ Save: true }}>Push to CATS</Dialog.Button>
                    <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' disabled={controller.isInProgress} result={{ Cancel: true }}>Close</Dialog.Button>
                </Dialog.Buttons>
            </Dialog>
        );
    }
}

export default TimeConfirmationDialog;