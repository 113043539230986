import $ from 'jquery';
import React from 'react';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Dialog from '../../components/Dialog';
import co from '../../lib/Co';

class ManufacturerDescriptionController extends BaseController {
  constructor() {
    super('man-desc', ManufacturerDescriptionController.getInitialState(''));

    const self = this;
    messages.channel('edit-man-desc').action('saved').subscribe(manDesc => {
      const manDescs = self.state.searchResults.filter(result => result.manDescId === manDesc.manDescId);
      if ((manDescs === null) || !manDescs.length) {
        return;
      }

      const md = manDescs[0];
      ajax.get('lookup/getManufacturerDescription/', { manDescId: manDesc.manDescId }).then(updatedManDesc=>{
        $.extend(md, updatedManDesc);
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });  // in: Guid, out: ManufacturerDescriptionDto
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state.familyCode     = '';
        this.state.manufacturer   = '';
        this.state.nominalSize    = '';
        this.state.cwp            = '';
        this.state.degree         = '';
        this.state.id             = '';
        this.state.od             = '';
        this.state.length         = '';
        this.state.style          = '';
        this.state.service        = '';
        this.state.mfgItemNo      = '';
        this.state.altItemNo      = '';
        this.state.searchText     = '';
        this.state.selectedResult = '';
        this.state.searchResults  = [];
        this.state.updateWallReadings=false;
      }
    });
  }

  static getInitialState = () => {
    return {
      manDescId      : '',
      familyCode     : '',
      manufacturer   : '',
      nominalSize    : '',
      cwp            : '',
      degree         : '',
      id             : '',
      od             : '',
      length         : '',
      style          : '',
      service        : '',
      typeApproval   : '',
      tempRange      : '',
      loadRating     : '',
      mfgItemNo      : '',
      altItemNo      : '',
      description    : '',
      searchText     : '',
      hasDiagram     : false,
      customerCount  : 0,
      assetCount     : 0,
      searchResults  : [],
      selectedResult : '',
      updateWallReadings: false,
    };
  }

  selectItem = (item) => {
    this.state.selectedResult = item;
    this.commit();
  }

  isSelected = (item) => {
    return (this.state.selectedResult === item);
  }

  getSelection = () => {
    return this.state.selectedResult; // searchResults.filter(r => r.selected);
  }

  setSearchResults = (results) => {
    this.state.searchResults  = results;
    if (results.length > 0) {
      this.state.selectedResult = results[0];
    }
    this.commit();
  }

  delete = (item) => {
    const message = <div>
                    <h5>Please confirm you wish to delete this Manufacturer Description</h5>
                    <h5>All associated data be deleted (e.g. Customer Descriptions)</h5>
                  </div>;

    co(this, function*() {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={300} header='Confirm Delete Manufacturer Description'>
          {message}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }
      const manDescId = item.manDescId;
      yield ajax.post('manufacturerDescription/delete', manDescId); // in: Guid, out: void

      // notify user
      let msg       = 'Manufacturer Description';
      const mfgItemNo = item.mfgItemNo && item.mfgItemNo.name;
      if (mfgItemNo) {
        msg += ' with Mfg Part # ' + mfgItemNo;
      }
      msg += ' has been deleted';
      notification.action('success').post(msg);

      // remove from current search results
      const idx = this.state.searchResults.indexOf(item);
      if (idx === -1) {
        return;
      }

      this.state.searchResults.splice(idx, 1);
      this.commit();
    });
  }
}

export default ManufacturerDescriptionController;
