import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import LoginService from '../services/LoginService';

class ChangePasswordController extends BaseController {
    constructor(loginInfo, message, loggingIn) {
        super('change-password', {
            loginInfo: loginInfo,
            oldPassword: '',
            newPassword: '',
            errors: {},
            message: message,
            loggingIn: loggingIn
        });

        this._validator = new Validator();
        this._validator.add('oldPassword', 'Current Password', [{ required: true }]);
        this._validator.add('newPassword', 'New Password', [{ required: true }]);
    }

    validate = () => {
        this._validator.validateAll(this.state);
        var errors = Object.keys(this.state.errors);
        if (!errors.length) {
          return true;
        }
        this.commit();
        return false;
      }
    
    save = () => {
        if (!this.validate()) {
            return false;
        }

        const loginInfo = this.state.loginInfo;
        const parameters = {
            userId: loginInfo.userId,
            loginName: loginInfo.loginName,
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword
        };

        return ajax.post('user/changePassword', parameters).then(() => {
            if (this.state.loggingIn) {
                notification.action('success').post('You have successfully changed your password, <b>' + loginInfo.loginName + '</b> -- please login using your new credentials');
            }
            else {
                notification.action('success').post('You have successfully changed your password, <b>' + loginInfo.loginName + '</b>');
            }
            return true;
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
            return false;
        });
    }
}

export default ChangePasswordController;