import kefir from 'kefir';

const emitter = () =>{
    let emitter;
    let stream = kefir.stream((_emitter)=>{
        emitter = _emitter;
        return (() => emitter = null);
    });
    stream.emit = (x) => {
        if(emitter){
            emitter.emit(x);
        }
    };
    return stream;
}

export default emitter;