import React from 'react';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import CustomerDescriptionWallReadingRowController from './CustomerDescriptionWallReadingRowController';
import CustDescTorqueReadingRowController from './CustDescTorqueReadingRowController';
import CustDescDimensionRowController from './CustDescDimensionRowController';
import EndConfigurationRowController from './EndConfigurationRowController';
import PressureTestRowController from './PressureTestRowController';
import SelectedCustomerServiceLevelRowController from './SelectedCustomerServiceLevelRowController';
import Dialog from '../../components/Dialog';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import PSMDetailsController from '../controllers/PSMDetailsController';
import PSMDetailsDialog from '../dialogs/PSMDetailsDialog';
import TechnicalDocRowController from './TechnicalDocRowController';

class EditCustomerDescriptionController extends BaseController {
  constructor() {
    super('edit-cust-desc', EditCustomerDescriptionController.getInitialState(''));

    this.adding = false;

    messages.channel('edit-cust-desc').action('saved').subscribe(custDesc => {
      this.load(custDesc.custDescId);
    });
  }

  load = (custDescId, manDescId, customer) => {
    if (custDescId === Utils.emptyGuid) {
      this.adding = true;
      this.state = EditCustomerDescriptionController.getInitialState(custDescId);
      if (manDescId) {
        ajax.get('lookup/getManufacturerDescription/', { manDescId: manDescId }).then(manDesc => {
          this.mergeManufacturerDescription(manDesc);
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });     // ManufacturerDescriptionDto
      }
      this.mergeCustomer(customer);
      this.commit();
    }
    else {
      this.adding = false;
      ajax.get('lookup/getCustomerDescription/', { custDescId: custDescId }).then(results => {
        this.merge(results);
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });     // CustomerDescriptionDto
      this.commit();
    }
  }

  mergeManufacturerDescription = (md) => {
    const s = this.state;
    s.manDescId = md.manDescId;
    s.familyCode = md.familyCode;
    s.manufacturer = md.manufacturer;
    s.nominalSize = md.nominalSize;
    s.cwp = md.cwp;
    s.degree = md.degree;
    s.id = md.id;
    s.od = md.od;
    s.length = md.length;
    s.style = md.style;
    s.service = md.service;
    s.mfgItemNo = md.mfgItemNo;
    s.description = md.description;
    this.initializeEndConfigurations(md.endConfigurations);
    // s.wallReadings      = md.wallReadings;
    // s.torqueReadings    = md.torqueReadings;
    // s.dimensions        = md.dimensions;
    this.initializeWallReadings(md.wallReadings);
    this.initializeTorqueReadings(md.torqueReadings);
    this.initializeDimensions(md.dimensions);
  }

  mergeCustomer = (customer) => {
    if (customer) {
      this.state.customer = customer;
    }
  }

  initializePressureTestSettings = (pressureTestSettings) => {
    this.state.pressureTestSettings = [];
    pressureTestSettings.forEach(pressureTestSetting => this.addNewPressureTestRow(pressureTestSetting));
  }

  initializeEndConfigurations = (endConfigurations) => {
    this.state.endConfigurations = [];
    endConfigurations.forEach(endConfiguration => this.addNewEndConfigurationRow(endConfiguration));
  }

  initializeWallReadings = (wallReadings) => {
    this.state.wallReadings = [];
    wallReadings.forEach(wallReading => this.addNewWallReadingRow(wallReading));
  }

  initializeTorqueReadings = (torqueReadings) => {
    this.state.torqueReadings = [];
    torqueReadings.forEach(torqueReading => this.addNewTorqueReadingRow(torqueReading));
  }

  initializeDimensions = (dimensions) => {
    this.state.dimensions = [];
    dimensions.forEach(dimension => this.addNewDimensionRow(dimension));
  }

  initializeSelectedServiceLevels = (selectedServiceLevels) => {
    this.state.selectedServiceLevels = [];
    selectedServiceLevels.forEach(selectedServiceLevel => this.state.selectedServiceLevels.push(new SelectedCustomerServiceLevelRowController(this, selectedServiceLevel)));
    this.commit();
  }

  merge = (custDesc) => {
    if (!custDesc) {
      return;
    }

    this.state = Object.assign({}, custDesc, { errors: {} });
    this.state.endConfigurations = [];
    this.state.wallReadings = [];
    this.state.torqueReadings = [];
    this.state.dimensions = [];
    this.state.selectedServiceLevels = [];
    this.state.pressureTestSettings = [];
    this.initializeEndConfigurations(custDesc.endConfigurations);
    this.initializeWallReadings(custDesc.wallReadings);
    this.initializeTorqueReadings(custDesc.torqueReadings);
    this.initializeDimensions(custDesc.dimensions);
    this.initializeSelectedServiceLevels(custDesc.selectedServiceLevels);
    this.initializePressureTestSettings(custDesc.pressureTestSettings);
  }

  static getInitialState = (custDescId) => {
    return {
      custDescId: custDescId,
      manDescId: '',
      customer: '',
      familyCode: '',
      manufacturer: '',
      nominalSize: '',
      cwp: '',
      degree: '',
      id: '',
      od: '',
      length: '',
      style: '',
      service: '',
      typeApproval: '',
      tempRange: '',
      loadRating: '',
      mfgItemNo: '',
      altItemNo: '',
      description: '',
      hasDiagram: false,
      custCWP: 0,
      custTestPressure: 0,
      assetCount: 0,
      wallReadings: [],
      torqueReadings: [],
      dimensions: [],
      endConfigurations: [],
      selectedServiceLevels: [],
      pressureTestSettings: [],
      errors: {},
      isSaving: false,
      updatePressureTests: false
    };
  }

  customerSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  mfgItemSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Mfg%20Item%20%23', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  search = (searchType) => {
    if (!searchType) {
      return null;
    }

    return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  removeDiagram = () => {
    if (!this.state.hasDiagram) {
      notification.action('warning').post('No diagram – nothing to remove');
      return;
    }
    const message = 'Please confirm you wish to remove this diagram – the file will be deleted';
    const custDescId = this.state.custDescId;

    co(this, function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={300} header='Confirm Remove Diagram'>
          {message}
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        yield ajax.post('customerDescription/removeDiagram', custDescId); // in: Guid, out: void
        messages.channel('edit-cust-desc').action('saved').post({ custDescId: custDescId });
      }
    });
  }

  uploadDiagram = (res, file) => {
    const parameters = {
      id: this.state.custDescId,
      fileName: file.fileName,
      fileType: file.fileType,
      imgUrl: file.url
    };

    ajax.post('customerDescription/uploadDiagram', parameters).then(() => {
      messages.channel('edit-cust-desc').action('saved').post({ custDescId: this.state.custDescId });
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });     // in: UploadFileDto, out: void
  }

  addNewPressureTestRow = (setting) => {
    this.state.pressureTestSettings.push(new PressureTestRowController(this, 'cd', setting));
    this.commit();
  }

  addNewEndConfigurationRow = (config) => {
    this.state.endConfigurations.push(new EndConfigurationRowController(this, config, this.state.manDescId));
  }

  addNewWallReadingRow = (reading) => {
    this.state.wallReadings.push(new CustomerDescriptionWallReadingRowController(this, reading, this.state.custDescId));
    this.commit();
  }

  addNewTorqueReadingRow = (reading) => {
    this.state.torqueReadings.push(new CustDescTorqueReadingRowController(this, reading, this.state.custDescId));
    this.commit();
  }

  addNewDimensionRow = (dimension) => {
    this.state.dimensions.push(new CustDescDimensionRowController(this, dimension, this.state.custDescId));
    this.commit();
  }
  removePressureTestSettingRow = (row) => {
    const idx = this.state.pressureTestSettings.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.pressureTestSettings.splice(idx, 1);
    this.commit();
  }

  removeWallReadingRow = (row) => {
    const idx = this.state.wallReadings.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.wallReadings.splice(idx, 1);
    this.commit();
  }

  removeTorqueReadingRow = (row) => {
    const idx = this.state.torqueReadings.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.torqueReadings.splice(idx, 1);
    this.commit();
  }

  removeDimensionRow = (row) => {
    const idx = this.state.dimensions.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.dimensions.splice(idx, 1);
    this.commit();
  }

  validatePressureTestSettingRow = (setting) => {
    // var customerSelected         = setting.customerSelected;
    // var customerMinimumDimension = setting.customerMinimumDimension;
    // if (!customerSelected || customerMinimumDimension) {
    //   delete setting.errors.customerMinimumDimension;
    // }
    // else {
    //   setting.errors.customerMinimumDimension = 'A value for Customer Minimum must be specified';
    // }
  }

  validateManufacturerDescriptionWallReadingRow = (reading) => {
    const customerSelected = reading.customerSelected;
    const customerMinimumDimension = reading.customerMinimumDimension;
    if (!customerSelected || customerMinimumDimension) {
      delete reading.errors.customerMinimumDimension;
    }
    else {
      reading.errors.customerMinimumDimension = 'A value for Customer Minimum must be specified';
    }
  }

  validateManufacturerDescriptionTorqueReadingRow = (reading) => {
    const customerSelected = reading.customerSelected;
    const customerTorqueRange = reading.customerTorqueRange;
    if (!customerSelected || customerTorqueRange) {
      delete reading.errors.customerTorqueRange;
    }
    else {
      reading.errors.customerTorqueRange = 'Customer Torque Range	must be specified';
    }
  }

  validateManufacturerDescriptionDimensionRow = (dimension) => {
    const customerSelected = dimension.customerSelected;
    const customerMinimumDimension = dimension.customerMinimumDimension;
    if (!customerSelected || customerMinimumDimension) {
      delete dimension.errors.customerMinimumDimension;
    }
    else {
      dimension.errors.customerMinimumDimension = 'A value for Customer Minimum must be specified';
    }
  }

  validateCustomerDescriptionWallReadingRow = (reading) => {
    const newDimension = reading.newDimension;
    const customerMinimumDimension = reading.customerMinimumDimension;

    if (newDimension) {
      delete reading.errors.newDimension;
    }
    else {
      reading.errors.newDimension = 'A value for New Dimension must be specified';
    }

    if (customerMinimumDimension) {
      delete reading.errors.customerMinimumDimension;
    }
    else {
      reading.errors.customerMinimumDimension = 'A value for Customer Minimum must be specified';
    }
  }

  validateCustomerDescriptionTorqueReadingRow = (reading) => {
    const customerTorqueRange = reading.customerTorqueRange;
    const torqueLocation = reading.torqueLocation;
    if (torqueLocation) {
      delete reading.errors.torqueLocation;
    }
    else {
      reading.errors.torqueLocation = 'Torque Location must be specified';
    }

    if (customerTorqueRange) {
      delete reading.errors.customerTorqueRange;
    }
    else {
      reading.errors.customerTorqueRange = 'Customer Torque Range	must be specified';
    }
  }

  validateCustomerDescriptionDimensionRow = (dimension) => {
    const customerMinimumDimension = dimension.customerMinimumDimension;

    if (customerMinimumDimension) {
      delete dimension.errors.customerMinimumDimension;
    }
    else {
      dimension.errors.customerMinimumDimension = 'A value for Customer Minimum must be specified';
    }
  }

  validatePressureTestSettingRows = (settings) => {
    settings.forEach(row => row.validate());
  }

  validateWallReadingRows = (wallReadings) => {
    wallReadings.forEach(row => {
      const reading = row.state;
      const readingId = reading.readingId;
      const readingType = reading.readingType;

      if (readingId) {
        delete row.state.errors.readingId;
      }
      else {
        reading.errors.readingId = 'ID is required';
      }

      switch (readingType) {
        case AppConstants.ReadingType.Manufacturer:
          this.validateManufacturerDescriptionWallReadingRow(reading);
          break;

        case AppConstants.ReadingType.Customer:
          this.validateCustomerDescriptionWallReadingRow(reading);
          break;

        default:
          break;
      }
    });
  }

  validateTorqueReadingRows = (torqueReadings) => {
    torqueReadings.forEach(row => {
      const reading = row.state;
      const readingId = reading.readingId;
      const readingType = reading.readingType;

      if (readingId) {
        delete row.state.errors.readingId;
      }
      else {
        reading.errors.readingId = 'ID is required';
      }

      switch (readingType) {
        case AppConstants.ReadingType.Manufacturer:
          this.validateManufacturerDescriptionTorqueReadingRow(reading);
          break;

        case AppConstants.ReadingType.Customer:
          this.validateCustomerDescriptionTorqueReadingRow(reading);
          break;

        default:
          break;
      }
    });
  }

  validateDimensionRows = (dimensions) => {
    dimensions.forEach(row => {
      const dimension = row.state;
      const dimensionId = dimension.dimensionId;
      const readingType = dimension.readingType;

      if (dimensionId) {
        delete row.state.errors.dimensionId;
      }
      else {
        dimension.errors.dimensionId = 'ID is required';
      }

      switch (readingType) {
        case AppConstants.ReadingType.Manufacturer:
          this.validateManufacturerDescriptionDimensionRow(dimension);
          break;

        case AppConstants.ReadingType.Customer:
          this.validateCustomerDescriptionDimensionRow(dimension);
          break;

        default:
          break;
      }
    });
  }

  canSave = () => {
    this.validateWallReadingRows(this.state.wallReadings);
    this.validateTorqueReadingRows(this.state.torqueReadings);
    this.validateDimensionRows(this.state.dimensions);
    const wallReadingRowErrors = this.state.wallReadings.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    const torqueReadingRowErrors = this.state.torqueReadings.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    const dimensionRowErrors = this.state.dimensions.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    this.validatePressureTestSettingRows(this.state.pressureTestSettings);
    const pressureTestSettingRowErrors = this.state.pressureTestSettings.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!wallReadingRowErrors && !torqueReadingRowErrors && !dimensionRowErrors && !pressureTestSettingRowErrors) {
      this.commit();
      return true;
    }
    this.commit();
    return false;
  }

  toggleupdatePressureTestSection = () => {
    this.state.updatePressureTests = !this.state.updatePressureTests;
    this.commit();
  }

  save = (cb) => {
    if (!this.canSave()) {
      return;
    }

    const s = this.state;
    const parameters = {
      custDescId: s.custDescId,
      manDescId: s.manDescId,
      customerId: s.customer.id,
      notes: s.notes,
      updatePressureTests: s.updatePressureTests,
      selectedServiceLevels: s.selectedServiceLevels
        .filter(sl => sl.state.isDirty)
        .map(sl => sl.state)
    };

    if (!this.adding) {
      parameters.endConfigurations = s.endConfigurations.map(ec => ec.state);
      parameters.wallReadings = s.wallReadings.map(wr => wr.state);
      parameters.torqueReadings = s.torqueReadings.map(tr => {
        const reading = {
          custDescId: tr.state.custDescId,
          manDescReadingId: tr.state.manDescReadingId,
          custDescManReadingId: tr.state.custDescManReadingId,
          readingId: tr.state.readingId,
          readingType: tr.state.readingType,
          torqueLocationId: tr.state.torqueLocation.id,
          torqueRangeId: tr.state.torqueRange.id,
          customerTorqueRangeId: tr.state.customerTorqueRange.id,
          customerSelected: tr.state.customerSelected
        }
        return reading;
      });
 
      parameters.dimensions = s.dimensions.map(tr => {
        const reading = {
          custDescId: tr.state.custDescId,
          custDescManDimensionId: tr.state.custDescManDimensionId,
          customerMinimumDimension: tr.state.customerMinimumDimension,
          customerSelected: tr.state.customerSelected,
          dimensionId: tr.state.dimensionId,
          newDimension : tr.state.newDimension,
          readingType : tr.state.readingType,
          manDescDimensionId: tr.state.manDescDimensionId,
          minimumDimension: tr.state.minimumDimension,
          dimensionLocationId: tr.state.dimensionLocation?tr.state.dimensionLocation.id: Utils.emptyGuid,
          }
        return reading;
      });
      parameters.pressureTestSettings = s.pressureTestSettings.map(pts => pts.state);
      parameters.psmNumber = s.psmNumber;
      parameters.psmLink = s.psmLink;
      parameters.serviceLevelId=s.serviceLevelId && s.serviceLevelId.id;
      parameters.technicalDocuments = s.technicalDocuments;
      }

    this.state.isSaving = true;
    this.commit();
    ajax.post('customerDescription/save', parameters).then(custDescId => {
      this.state.isSaving = false;
      this.commit();
      if (this.adding) {
        notification.action('success').post('Saved new Customer Description');
        if (cb) {
          cb(custDescId);
        }
      }
      else {
        this.state.isSaving = false;
        this.commit();
        notification.action('success').post('Saved changes to Customer Description');
      }

      // use in CustomerDescriptionController to re-select edited form
      // if it's a new form, it will be the 1st entry in the list
      // messages.channel('edit-cust-desc').action('saved').post(custDescId);
      messages.channel('edit-cust-desc').action('saved').post({ custDescId: custDescId });
    }).catch(err => {
      this.state.isSaving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });     // in: UpdateCustomerDescriptionDto, out: Guid
  }

  showPSMDetails = () => {
    const psmLink = this.state.psmLink;
    const psmNumber = this.state.psmNumber;
    const serviceLevelId = this.state.serviceLevelId;
    const customerId = this.state.customer.id;
    const displayClearButton = this.state.psmLink !== null && this.state.psmLink?.length > 0;
    co(this, function* () {
      Dialog.showDialog(<PSMDetailsDialog controller={new PSMDetailsController(this, customerId, psmLink, psmNumber, serviceLevelId, displayClearButton)} header='Edit PSM Details' />);
    });
  }

  savePSMDetails = (psmLink, psmNumber, serviceLevelId) => {
    this.state.psmLink = psmLink.trim();
    this.state.psmNumber = psmNumber.trim();
    this.state.serviceLevelId = serviceLevelId;
    this.commit();
  }
  showTechnicalDoc = () => {
    const technicalDocuments = this.getTechnicalDocumentRows();
    const custDescId = this.state.custDescId;
    const psmLink = this.state.psmLink;
    const psmNumber = this.state.psmNumber;
    const serviceLevelId = this.state.serviceLevelId;
    const customerId = this.state.customer.id;
    const displayClearButton = this.state.psmLink !== null && this.state.psmLink?.length > 0;
    co(this, function* () {
      Dialog.showDialog(<PSMDetailsDialog controller={new PSMDetailsController(this,customerId,psmLink,psmNumber,serviceLevelId,displayClearButton,true,false,custDescId,technicalDocuments)} header = "Add/Edit Technical Documents" />);
    });
  }
  getTechnicalDocumentRows = ()=>{
    const rows  = [];
    const technicalDocuments = this.state.technicalDocuments;
    if(technicalDocuments && technicalDocuments.length>0)
      {
        technicalDocuments.forEach((x)=>{rows.push(new TechnicalDocRowController(x.Document_GUID , x.Cust_Desc_GUID, x.Document_Name,x.Document_Link))});
      }
      return rows;
  }
  addTechnicalDocuments = (technicalDocs)=>{ 
    const rows = [];
        if(technicalDocs && technicalDocs.length>0){
          technicalDocs.forEach((x)=>{rows.push({Document_GUID:x.state.Document_GUID ,Cust_Desc_GUID: x.state.Cust_Desc_GUID, Document_Name: x.state.Document_Name,Document_Link :x.state.Document_Link})});
          
        }
        this.state.technicalDocuments = rows;
        this.commit();      
  }
}
export default EditCustomerDescriptionController;