import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class CompleteAWODialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
       

    }
    componentDidMount = () => {
        this.initializeMixin();
    }


    handleClose = (result) => (result.Close) ? true : this.props.controller.save();

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const errors = controller.state.errors;
        const title = 'Confirm AWO Completion';
        const spanStyle = { whiteSpace: 'pre-wrap' , fontWeight: 'bold' };
        const Awonumber = this.props.awonumber;
        return (
            <Dialog className='completion-date-dialog' {...this.props} height={350} width={550} modal={true} header={title} onClose={this.handleClose}>
                <Dialog.Body>
                    <div className='col-md-12'>
                        <div className='row p-3'>  
                            <span style={spanStyle}>
                            Once an AWO is marked complete, no other transactions can be created.
                            </span>
                        </div>
                        <div className='row p-3'>  
                            <span style={spanStyle}>
                            Please confirm you wish to complete AWO: [#{Awonumber}]
                            </span>
                        </div>
                        <div className='row p-2 text-center' style={spanStyle}> 
                        <div class="d-flex justify-content-center align-items-center" style={{width:"100%"}}>
                        <span style={spanStyle}>Completion Date: </span>                       
                            
                        </div> 
                        </div>
                        <div className='row'>
                        <div class="d-flex justify-content-center align-items-center" style={{width:"100%"}}>
                        <Form.Date label=''  className='required' bind={binder.bind('completiondate')}  options={{ startDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today())}} />
                        </div>
                        </div>
                        {/* <div className='row'>                            
                            <div className='col-1'><Form.CheckBox label='' bind={binder.bind('printawo')}></Form.CheckBox></div>
                            <div className='col-4'><span style={spanStyle}>Print AWO</span></div>
                            <div className='col-2'></div>
                            <div className='col-1'><Form.CheckBox label='' bind={binder.bind('printawoqr')}></Form.CheckBox></div>
                            <div className='col-4'><span style={spanStyle}>Print AWO QR Code</span></div>                            
                        </div> */}
                    </div>
                </Dialog.Body>
                <Dialog.SaveCloseButtons saving={this.props.saving} />;
            </Dialog>
        )
    }
}

export default CompleteAWODialog;