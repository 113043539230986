
import React, { useMemo } from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import LoginActivityController from '../controllers/LoginActivityController';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Grid from '../../components/Grid';

const Results = (props) => {
   
    const getRowProps = (row) => {
        const entry = row.original;
        const loginAge   = DateTimeFormatter.today().diff(DateTimeFormatter.getMoment(entry.loginDate), 'days');
        const isToday    = (loginAge === 0);
        const isOld      = (loginAge > 14);
        const className  = isToday
                          ? 'text-success table-success'
                          : isOld
                              ? 'text-danger table-danger'
                              : '';
        const rowProps = { className: className};
        return rowProps;
      }

    const columns = useMemo(
        () => [
            {
                Header: 'Logged In',
                accessor: 'loginDate',
                
                Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
            },
            {
                Header: 'Logged Out',
                accessor: 'logoutDate',
                Cell: cellInfo => <span>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
            },
            {
                Header: 'Last Logged In',
                accessor: 'lastLoggedIn',
                Cell: cellInfo => <span>{DateTimeFormatter.fromNowTime(cellInfo.row.original.loginDate)}</span>
            },
            {
                Header: 'IP Address',
                accessor: 'clientIPAddress'
            },
            {
                Header: 'Server',
                accessor: 'serverName'
            }
        ],
        []
    )
    return (
        <Grid id='results-table' columns={columns} data={props.data} showPagination={false} noDataText='No Records Found' disableSortBy={true} getRowProps={getRowProps}/>  
    );
}

class LoginActivityDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new LoginActivityController()
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.props.controller.getLoginActivity();
    }

    handleClose = () =>  true;

    render() {
        const controller = this.props.controller;
        const data = [...controller.state.loginActivity];
        return (
            <Dialog {...this.props} modal={true} header={this.props.header} width={750} height={600} id='login-activity-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div>
                        <Results data={data}/>
                    </div>
                </Dialog.Body>
                <Dialog.Buttons>
                    <Dialog.CloseButton />
                </Dialog.Buttons>
            </Dialog>
        );
    }
}

export default LoginActivityDialog;