import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants  from '../utility/AppConstants';

class ManDescDimensionRowController extends BaseController {
  constructor(parentController, dimension, manDescId) {
    super('man-desc-dimension-row-controller-' + ManDescDimensionRowController.key++, {
      manDescId               : manDescId,
      manDescDimensionId      : dimension ? dimension.manDescDimensionId : Utils.emptyGuid,
      dimensionId             : dimension ? dimension.dimensionId              : '',
      readingType             : dimension ? dimension.readingType              : AppConstants.ReadingType.Manufacturer,
      minimumDimension        : dimension ? dimension.minimumDimension         : '',
      errors                  : {},
      newDimension            : dimension ? dimension.newDimension         : '',
      dimensionLocation       : dimension && dimension.dimensionLocationId != Utils.emptyGuid ? {id: dimension.dimensionLocationId, name: dimension.dimensionLocationName}  : '',
    });
   
    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('dimensionId',      'ID',               [{required: true}]);
    this._validator.add('newDimension', 'New Dimension',    [{required: true}]);
    this._validator.add('minimumDimension', 'Minimum Dimension',    [{required: true}]);
  }

  static key = 1;

  canRemoveRow = () => {
    return true;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeDimensionRow(this);
  }
}

export default ManDescDimensionRowController;
  