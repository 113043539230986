import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Validator from '../../lib/Validator';
import store from 'store';
import SAPService from '../services/SAPService';
import Utils from '../utility/Utils';

class RecordInspectionTimeController extends BaseController {
  constructor(parentController,awoId, awoSOWId, testName, selectedGroups) {
    super('assembly-time', {
      parentController:parentController,
      awoId: awoId,
      awoSOWId: awoSOWId,
      testName: testName,
      selectedGroups: selectedGroups,
      assemblyMinutes: 0,
      assemblyDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      technician: '',
      errors: {},
      notesId: Utils.emptyGuid,
      notes: ''
    });
    this.getAssemblyNotes();
    this.saving = false;
    this._validator = new Validator();
    this._validator.add('assemblyMinutes', 'Assembly Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: this.state.assemblyMinutes } }]);
  }

  validate = (assemblyMinutes) => {
    try {
      const MAX_VALUE = (60 * 12); // 12 hrs per day is plenty
      const value = parseFloat(assemblyMinutes);

      if (isNaN(value)) {
        notification.action('warning').post({ msg: 'That is not a number – please re-enter.', title: 'No Value Warning' });
        return false;
      }
      else if (value > MAX_VALUE) {
        notification.action('warning').post({ msg: 'Did you really work over 12 hours on this task? That value is too high to be accepted – please re-enter.', title: 'Limit Warning' });
        return false;
      }
      else if (value < 0) {
        notification.action('warning').post({ msg: 'The number must be greater than 0 (zero) – please re-enter.', title: 'Negative Value Warning' });
        return false;
      }
      else if (value === 0) {
        notification.action('warning').post({ msg: 'That is not a valid value – it evaluates to 0 (zero) – please re-enter.', title: 'Zero Value Warning' });
        return false;
      }
      return true;
    }
    catch (err) {
      notification.action('error').post({ msg: 'The value for Inspection Minutes is not valid – please re-enter', title: 'Conversion Error' });
      return false;
    }
  }

  getAssemblyNotes = () => {
    const s = this.state;
    if (s.awoSOWId.length > 1) {
      return false;
    }
    const param = {
      awoId: s.awoId,
      awoSOWId: s.awoSowId,
      testName: s.testName,
    }
    ajax.post('lookup/awo/getAssemblyOperationTestNotes', param).then(result => {
      if (result) {
        this.state.notesId = result.notesId || Utils.emptyGuid
        this.state.notes = result.notes || '';
        this.commit();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  canSave = () => {
    const self = this;
    this._validator.validateAll(self.state);

    const errors = Object.keys(self.state.errors);
    if (!errors.length) {
      return true;
    }

    self.commit();
    return false;
  }

  save() {
    if (!this.canSave()) {
      return;
    }
    //this.saving = true;
    const assemblyMinutes = this.state.assemblyMinutes;
    if (!this.validate(assemblyMinutes)) {
      return false;
    }
    const loginInfo = store.get('InteServLoginInfo');
     if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
      if (parseFloat(assemblyMinutes).toFixed(1) <= 0) {
        notification.action('error').post("After rounding to 1 decimal, time will become 0. Inspection Time should be greater than 0");
        return false;
      }
      var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));

      if (assemblyMinutes > availableTime) {
        notification.action('error').post("Time should not be more than Available Production Time");
        return false;
      }
    }
    if (this.saving)
    return;

    this.saving = true;
    this.commit();
    var s = this.state;
    var parameters = {
      awoId: s.awoId,
      awoSOWId: s.awoSOWId,
      testName: s.testName,
      assemblyMinutes: parseFloat(assemblyMinutes),
      assemblyDate: s.assemblyDate,
      technician: s.technician,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      notesId: s.notesId,
      notes: s.notes || null
    };

    return ajax.post('awo/operations/recordInspectionTime', parameters).then(prodTime => {
      this.saving = false;
      s.parentController.refreshConfigData();
      this.commit();
      //messages.channel('productiveTime').action('update').post(prodTime);
      //messages.channel('reload-test-results').action('saved').post();
      return true;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default RecordInspectionTimeController;
