import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
class RejectReasonController extends BaseController {
  constructor() {
    super('rejectreason', {
      filterText         : '',
      selectedFamilyCode : '',
      sourceItems        : [],
      selectedItems      : [],
      isSaving           : false
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.clearResults();
        this.state.selectedFamilyCode = '';
      }
    });
  }

  filter = () => {
    this.commit();
  }

  selectedFamilyCodeChanged = () => {
    this.loadSelectedRejectReasons();
  }

  loadSelectedRejectReasons = () => {
    this.clearResults();
    var familyCodeId = this.state.selectedFamilyCode && this.state.selectedFamilyCode.id;
    if (!familyCodeId) {
      this.commit();
      return;
    }

    co(this, function*() {
      var parameters = {
        familyCodeId : familyCodeId
      };

      var results              = yield ajax.get('lookup/searchRejectReasonLookupList', parameters); // IList<LookupDto>
      this.state.selectedItems = results;
      this.commit();
    });
  }

  loadAvailableRejectReasons = () => {
    co(this, function*() {
      this.state.sourceItems = [];
      var results            = yield ajax.get('lookup/getAvailableRejectReasons'); // IList<LookupDto>
      this.state.sourceItems = results;
      this.commit();
    });
  }

  clearResults = () => {
    this.state.selectedItems = [];
  }

  clearFilter = () => {
    this.state.filterText = '';   
    this.commit();
    document.getElementById('filterText').focus();
  }

  selectNoSourceItems = () => {
    this.state.sourceItems.forEach(item => {
      item.selected = false;
    });
    this.commit();
  }

  selectAllSourceItems = () => {
    this.state.sourceItems.forEach(item => {
      item.selected = true;
    });
    this.commit();
  }

  selectNoSelectedItems = () => {
    this.state.selectedItems.forEach(item => {
      item.selected = false;
    });
    this.commit();
  }

  selectAllSelectedItems = () => {
    this.state.selectedItems.forEach(item => {
      item.selected = true;
    });
    this.commit();
  }

  toggleSelection = (item) => {
    item.selected = !item.selected;
    this.commit();
  }

  moveToSelected = () => {
    if (!this.canTransfer()) {
      return;
    }

    var state = this.state;
    state.sourceItems.forEach(item => {
      if (item.selected) {
        item.selected = false;

        var found = false;
        for (var i in state.selectedItems) {
          if (state.selectedItems[i].id === item.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          state.selectedItems.push({
            id   : item.id,
            name : item.name
          });
        }
      }
    });

    // sort selected list
    this.state.selectedItems.sort(function(a, b) {
      return (a.name.localeCompare(b.name));
    });
    this.commit();
  }

  removeFromSelected = () => {
    if (!this.canTransfer()) {
      return;
    }

    var i = this.state.selectedItems.length;
    while (i--) {
      var item = this.state.selectedItems[i];
      if (item.selected) {
        this.state.selectedItems.splice(i, 1);
      }
    }
    this.commit();
  }

  selectAndTransfer = (isSource, item) => {
    if (!this.canTransfer()) {
      return;
    }

    item.selected = true;
    if (isSource) {
      this.moveToSelected();
    }
    else {
      this.removeFromSelected();
    }
  }

  canTransfer = ()  => {
    return (LoginService.isAdministrator && !!this.state.selectedFamilyCode);
  }

  save = () => {
    var parameters = {
      familyCode    : this.state.selectedFamilyCode,
      rejectReasons : this.state.selectedItems
    };

    co(this, function*() {
      try{
        this.state.isSaving=true;
      this.commit();
      yield ajax.post('list/saveRejectReasons', parameters); // in: RejectReasonMapDto
      notifications.action('success').post('Saved changes to reject reasons for family code ' + this.state.selectedFamilyCode.name);
      }
      catch(err){
        notifications.action('error').post({msg: err.message, title: 'Error saving the Reject reason'});
      } 
      finally {
        this.state.isSaving=false;
        this.commit();
      } 
    });
  }
}

export default RejectReasonController;
