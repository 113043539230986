import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Validator from '../../lib/Validator';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import AppConstants from '../utility/AppConstants';

class TimeConfirmationController extends BaseController {
    constructor() {
        super('timeconf', {
            workDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
            additionalTimeData: [],
            base: '',
            costCenter: '',
            travelPerDiem: '',
            halfDayFieldPerDiem: '',
            nightShift: '',
            isPerDiemSelected: false,
            isNightShiftSelected: false,
            errors: {}
        });
        this._validator = new Validator();
        this.isInProgress = false;
    }

    load = () => {
        ajax.post('manhours/getTotalTime', { TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()) }).then((results) => {
            this.state.additionalTimeData = results;
            var selPerd = 0;
            var resP = this.state.additionalTimeData.filter(function (event) {
                return event.Type === 'PerDiem';
            });
            selPerd = resP[0].TotalTime;
            if (selPerd == 1) {
                this.state.isPerDiemSelected = true;
                this.state.travelPerDiem = true;
            }
            else if (selPerd == 2) {
                this.state.isPerDiemSelected = true;
                this.state.halfDayFieldPerDiem = true;
            }
            else if (selPerd == 3) {
                this.state.isPerDiemSelected = true;
                this.state.nightShift = true;
            }

            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }


    validateSave=()=>{
        this._validator.validateAll(this.state);
        var errors = Object.keys(this.state.errors);
        this.commit();
        if (!this.state.base) {
            if (errors.length) {
                this.removeErrorWithValue(errors, 'costCenter');
            }
        }
        if (errors.length) {
            return false;
        }
        return true;
    }


    saveManHours = () => {
        const s = this.state;
        var paidHours = 0;
        var res = this.state.additionalTimeData.filter(function (event) {
            return event.Type == "Paid Time:";
        });
        paidHours = res[0].TotalTime;

        var addPaidHours = 0;
        var resP = this.state.additionalTimeData.filter(function (event) {
            return event.Type == "Additional Production:";
        });
        addPaidHours = resP[0].TotalTime;

        var TotPaidHours = (Number(paidHours) - Number(addPaidHours));

        var loginInfo = LoginService.loginInfo;
        var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
        var Tot = (Number(paidHours) + Number(availableTime));
        if (Number(Tot) == 0) {
            notification.action('error').post("No Time available to push to CATS.");
            return false;
        }

        var params = {
            workDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
            userId: loginInfo.userId,
            totalManHours: TotPaidHours,
            totalOverHead: availableTime,
            perDiem: s.travelPerDiem ? AppConstants.PerDiem.TravelPerDiem : s.halfDayFieldPerDiem ? AppConstants.PerDiem.HalfDayFieldPerDiem : s.nightShift ? AppConstants.PerDiem.NightShift : 0,
            nightShift: s.nightShift ? AppConstants.PerDiem.TravelPerDiem : 0,
            costCenter: s.costCenter ? s.costCenter.id : ''
        };
        this.isInProgress = true;
        this.commit();
        return ajax.post('timeconfirmation/pushtimetocats', params).then(() => {
            notification.action('success').post('Time pushed to CATS and Clocked Out successfully');
            messages.channel('clockInProcess_Cli').action('start').post();
            this.isInProgress = false;
            this.commit();
            return true;
        }).catch(err => {
            this.isInProgress = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
            return false;
        });
    }

    baseChanged = () => {
        this.state.costCenter = '';
        this.commit();
    }

    travelPerDiemChanged = () => {
        const s = this.state;
        this.state.halfDayFieldPerDiem = false;
        this.state.nightShift = false;
        this.commit();
    }

    halfDayFieldPerDiemChanged = () => {
        const s = this.state;
        this.state.travelPerDiem = false;
        this.state.nightShift = false;
        this.commit();
    }

    removeErrorWithValue = (errors, value) => {
        if (errors.indexOf(value) !== -1) {
            errors.splice(errors.indexOf(value), 1);
        }
    }

    removeErrorWithKey = (errors, key) => {
        delete errors[key];
    }

    nightShiftChanged = () => {
        const s = this.state;
        this.state.travelPerDiem = false;
        this.state.halfDayFieldPerDiem = false;
        this.commit();
    }

    baseChanged = () => {
        if (this.state.base) {
            this._validator.add('costCenter', 'CostCenter', [{ required: true }]);
            this.removeErrorWithKey(this.state.errors, 'costCenter');
            document.getElementById('s2id_costCenter').classList.add('required');
        }
        else {
            const errors = Object.keys(this.state.errors);
            document.getElementById('s2id_costCenter').classList.remove('required');
            if (errors.length) {
                this.removeErrorWithValue(errors, 'costCenter');
                this.state.errors = errors;
            }
        }
        this.state.costCenter = '';
        this.commit();
    }
}

export default TimeConfirmationController;