import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';

class LoginResetPasswordController extends BaseController {
  constructor() {
    super('login-reset-password', {
      loginname: '',
      error: '',
      isLoading: false
    });
  }

  save = () => {
    this.state.isLoading = true;
    this.commit();
    const LoginName = this.state.loginname;
    if (!LoginName.length) {
      this.state.error = 'Loginname is required';
      this.commit();
      document.getElementById('loginname').focus();
      this.state.isLoading = false;
      this.commit();
      return false;
    }

    const parameters = {
      userId: '',
      loginName: LoginName,
      newPassword: ''
    };

    return ajax.post('security/resetPassword', parameters).then(() => {
      notification.action('success').post('Password has been sent to your email');
      this.state.isLoading = false;
      this.commit();
      return true;
    })
    .catch(err => {
      this.state.isLoading = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }

  loginnameChanged = () => {
    if (this.state.loginname) {
      this.state.error = '';
      this.commit();
    }
  }
}

export default LoginResetPasswordController;