import React from 'react';
import Form from './Form';
import GroupBadge from './GroupBadge';
import PropTypes from 'prop-types';

class Keypad extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            value: 0
        };
    }

    static defaultProps = {
        minValue: 0,
        maxValue: 999,
        enabled: true,
        allowDecimal: false,
        autoFocus: false
    }


    adjustValue = (newValue) => {
        var value = newValue;
        if (value < this.props.minValue) {
            value = this.props.minValue;
        }
        if (value > this.props.maxValue) {
            value = this.props.maxValue;
        }

        if (this.props.bind) {
            this.props.bind(value);
        }

        this.setState({
            value: value
        });
    }

    handleNumberClick = (newValue) => {
        if (this.props.enabled) {
            const value = this.state.value.toString().includes('.') ? ((this.state.value) + newValue) : ((this.state.value * 10) + newValue);
            this.adjustValue(value);
        }
    }


    handleDotClick = () => {
        if (this.props.enabled) {
            var value = this.state.value;
            if (!value.toString().includes('.')) {
                value = ((this.state.value) + '.');
            }
            this.adjustValue(value);
        }
    }

    handleClearClick = () => {
        if (this.props.enabled) {
            this.adjustValue(0);
        }
    }

    handleDeleteClick = () => {
        if (this.props.enabled) {
            var value = Math.floor(this.state.value / 10);
            this.adjustValue(value);
        }
    }

    render = () => {
        var classNumberBadge = 'keypad-number';
        var classClear = 'fas fa-eraser keypad-clear';
        var classDelete = 'fas fa-arrow-circle-left keypad-delete';

        const keypadNumberStyle = {
            borderRadius: 30,
            fontSize: 20,
            margin: 5,
            padding: 12,
            minHeight: 45,
            minWidth: 45,
            cursor: this.props.enabled ? 'pointer' : 'not-allowed'
        }

        if (!this.props.enabled) {
            classNumberBadge += ' disabled';
            classClear += ' disabled';
            classDelete += ' disabled';
        }

        return (
            <div className={this.props.className}>
                {this.props.autoFocus ? <Form.StealFocusElement /> : null}
                <div style={{ display: 'inline-block', minHeight: 165, minWidth: 225 }}>
                    <div>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(1)}>1</GroupBadge>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(2)}>2</GroupBadge>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(3)}>3</GroupBadge>
                    </div>
                    <div>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(4)}>4</GroupBadge>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(5)}>5</GroupBadge>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(6)}>6</GroupBadge>
                    </div>
                    <div>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(7)}>7</GroupBadge>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(8)}>8</GroupBadge>
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(9)}>9</GroupBadge>
                    </div>
                    <div>
                        {
                            this.props.allowDecimal
                                ? <GroupBadge variant='danger' className={classNumberBadge} style={keypadNumberStyle} onClick={this.handleDotClick}>.</GroupBadge>
                                : <GroupBadge variant='danger' className={classClear} style={keypadNumberStyle} onClick={this.handleClearClick}></GroupBadge>
                        }
                        <GroupBadge variant='primary' className={classNumberBadge} style={keypadNumberStyle} onClick={() => this.handleNumberClick(0)}>0</GroupBadge>
                        {
                            this.props.allowDecimal
                                ? <GroupBadge variant='danger' className={classClear} style={keypadNumberStyle} onClick={this.handleClearClick}></GroupBadge>
                                : <GroupBadge variant='danger' className={classDelete} style={keypadNumberStyle} onClick={this.handleDeleteClick}></GroupBadge>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

GroupBadge.propTypes = {
    className: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    enabled: PropTypes.bool,
    allowDecimal: PropTypes.bool,
    autoFocus: PropTypes.bool
}


export default Keypad;