import React from 'react';
import qr from 'qr-image';
import Utils from '../utility/Utils';
import PropTypes from 'prop-types';

const qrImageSticker = {
    width: '3.74in',
    height: '1.60in',
    display: 'flex',
    display: 'inline-flex'
}

const qrImageContainer = {
    width: '1.55in',
    height: '1.59in',
    float: 'left',
    display: 'grid',
    //background-color: #008E0
}

const qrDetailContainer = {
    width: '2.19in',
    height: '1.59in',
    textAlign: 'left',
    float: 'right',
    display: 'grid'
    //background-color: #00008E
}

const equipmentNo = {
    fontSize: '18px'
}

const qrDetailContent = {
    maxWidth: '2.19in',
    // color: '#585858',
    fontWeight: 'bolder',
    fontFamily: 'Arial, Helvetica, sans-serif',
    textShadow: 'none',
    fontSize: '12px',
    wordBreak: 'break-all',
    marginTop: '20px',
    marginLeft: '-10px'
}

const qrImage = {
    margin: 'auto',
    height: '135px',
    width: '135px'
}

const technipfmcLogoQr = {
    height: '5mm',
    width: '128px',
    marginBottom: '1px'
}

const qrDetailText = {
    margin: 'unset',
    lineHeight: 1.42,
}

const QRSticker = (defaultProps) => {

    const props = {
        qrValue: {
            assetId: Utils.emptyGuid,
            serialNumber: '',
            partNumber: '',
            familyCode: '',
            nominalSize: '',
            length: '',
            cwp: ''
        },
        ...defaultProps
    }

    const generateQRCodes = (serialNumber) => {
        const qrSerialValues = serialNumber;
        const server = {
            ssl: process.env.REACT_APP_API_SSL,
            domain: process.env.REACT_APP_API_HOST_NAME,
            port: process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : '',
            vdir: process.env.REACT_APP_API_VDIR ? '/' + process.env.REACT_APP_API_VDIR : '',
            cors: process.env.REACT_APP_API_CORS === "true" ? true : false,
        }
        if (server.domain === 'localhost') {
            server.port = '3001';
        }
        const url = '{ssl}://{domain}:{port}{vdir}/'.interpolate(server);
        const generatedUrl = url + '#/scannedqrasset';
        const pngArray = qr.imageSync(generatedUrl + '/' + qrSerialValues, { type: 'png' });
        const generatedQRCode = btoa(String.fromCharCode.apply(null, pngArray));
        return generatedQRCode;
    }

    const validateValue = (value) => {
        value = (value == null || value == '' || value == 'N/A' || value.toLowerCase() == 'null')
            ? 'N/A'
            : value;
        return value;
    }

    const formLine = (firstValue, secondValue, isSizeAndLength) => {
        let line;
        if (firstValue == 'N/A' && secondValue == 'N/A') {
            return '';
        }
        else if (firstValue != 'N/A' && secondValue == 'N/A') {
            line = firstValue;
        }
        else if (firstValue == 'N/A' && secondValue != 'N/A') {
            line = secondValue;
        }
        else if (firstValue != 'N/A' && secondValue != 'N/A' && isSizeAndLength) {
            line = firstValue + ' X ' + secondValue
        }
        else {
            line = firstValue + ', ' + secondValue
        }
        return line;
    }

    const assetId = props.qrValue.assetId;
    const equipmentNumber = props.qrValue.equipmentNumber;
    const serialNumber = props.qrValue.primarySerialNumber;
    const partNumber = props.qrValue.mfgPartNumber;
    const familyCode = props.qrValue.familyCode;
    const nominalSize = props.qrValue.nominalSize
        ? validateValue(props.qrValue.nominalSize)
        : validateValue(props.qrValue.size);
    const length = validateValue(props.qrValue.length);
    //const id = validateValue(props.qrValue.id);
    const style = validateValue(props.qrValue.style);
    const cwp = validateValue(props.qrValue.cwp);
    let sizeAndLength = formLine(nominalSize, length, true);
    sizeAndLength = sizeAndLength ? sizeAndLength : 'N/A';
    const descriptionRow = formLine(familyCode, sizeAndLength, false);
    // const cwpRow = formLine(id, cwp, false);
    const cwpRow = formLine(style, cwp, false);
    const qrCode = generateQRCodes(assetId);
    const partNumberRow = (!partNumber.includes('N/A') && partNumber != null && partNumber.toLowerCase() != 'null' && partNumber != '' && partNumber != '')
        ? <span>PN: {partNumber}<br /></span>
        : null;
    const equipmentNoRow = equipmentNumber
        ? <span style={equipmentNo}>EQ: {equipmentNumber}<br /></span>
        : null;
    const assetNo =  props.qrValue.assetNumber ? props.qrValue.assetNumber : '';
    const assetNumber = <span>AN: {assetNo}<br /></span>
    return (
        <div style={qrImageSticker}>
            <div style={qrImageContainer} className='qr-image-container'>
                <img style={qrImage} className='qr-image' src={'data:image/png;base64,' + qrCode} />
            </div>
            <div style={qrDetailContainer} className='qr-detail-container'>
                <div style={qrDetailContent} className='qr-detail-content'>
                    <img style={technipfmcLogoQr} className='technipfmc-logo-qr' src='./assets/images/logo-black.png' />
                    <span className='qr-detail-text'>
                        <p style={qrDetailText}>
                            {equipmentNoRow}
                            SN: {serialNumber}
                            <br />
                            {partNumberRow}
                            {assetNumber}
                            {descriptionRow}
                            <br />
                            {cwpRow}
                        </p>
                    </span>
                </div>
            </div>
        </div>
    );
}

QRSticker.propTypes = {
    qrValue: PropTypes.object
}

export default QRSticker;