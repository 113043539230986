import React from 'react';
import Table from '../../components/Table';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import AppConstants from '../utility/AppConstants';

class EstimatedAdhocTransactionRow extends React.Component {
    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
        this.binder = new Binder(this);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleRemoveRow = () => {
        const source = this.props.source;
        this.props.controller.removeRow(source);
    }

    handleOnKeyPress = (evt) => {
        if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
            evt.stopPropagation();
        }
    }

    handleKeyUp = (evt) => {
        try {
            const value = parseInt(evt.target.value, 10);
            if (value < 0) {
                evt.target.value = 0;
            }
            if (value > 100000) {
                evt.target.value = 100000;
            }
        }
        catch (err) {
            evt.target.value = 0;
        }
    }

    renderDollarsRow = () => {
        const controller = this.props.controller;
        const parentController = controller.parentController;
        const binder = this.binder;
        const errors = controller.state.errors;
        const disabled = controller.state.unitOfMeasure === '' || !this.props.canEdit;
        const trashIcon = controller.state.recordStatus == AppConstants.RecordStatus.Added
            ? <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle' action={this.handleRemoveRow} /></Table.Data>
            : <Table.Data className='col-action'></Table.Data>;
        const operations = controller.state.recordStatus == AppConstants.RecordStatus.Added
            ? <Form.AutoComplete id='ad-hoc-operation' readOnly={false} bind={binder.bind('operation')} error={errors.operation} search={parentController.estimatedOperationSearch()} />
            : <Form.StaticData value={controller.state.operation.name} />

        return (
            <Table.Row className='estimated-adhoc-transactions-trow'>
                {trashIcon}
                <Table.Data className='col-operation'>
                    {operations}
                </Table.Data>

                <Table.Data colSpan={3} className='col-dollars'>
                    <Form.Input id='ad-hoc-dollars' type='number' readOnly={disabled} disabled={disabled} bind={binder.bind('value')} error={errors.value} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
                </Table.Data>

                <Table.Data className='col-unit-of-measure'>
                    {this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity ? <Form.StaticData value='Quantity' /> : <Form.StaticData value='Dollars' />}
                </Table.Data>
                {/* <Table.Data className='col-technician'></Table.Data> */}
                {/* <Table.Data className='col-date'></Table.Data>
              <Table.Data className='col-notes'><Form.Input bind={binder.bind('notes')} /></Table.Data> */}
            </Table.Row>
        );
    }

    renderTimeRow = () => {
        const controller = this.props.controller;
        const parentController = controller.parentController;
        const binder = this.binder;
        const errors = controller.state.errors;
        const disabled = controller.state.unitOfMeasure === '' || !this.props.canEdit;
        const timeRow = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours ? null : <Form.Input type='number' id='ad-hoc-min' min='0' max='60' readOnly={disabled} disabled={disabled} bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />;
        const minutesLabel = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours ? null : <Form.StaticData value='Minutes' />
        const trashIcon = controller.state.recordStatus == AppConstants.RecordStatus.Added
            ? <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle' action={this.handleRemoveRow} /></Table.Data>
            : <Table.Data className='col-action'></Table.Data>;
        const operations = controller.state.recordStatus == AppConstants.RecordStatus.Added
            ? <Form.AutoComplete id='ad-hoc-operation' readOnly={false} bind={binder.bind('operation')} placeholder='select one of the operations' error={errors.operation} search={parentController.estimatedOperationSearch()} />
            : <Form.StaticData value={controller.state.operation.name} />
        return (
            <Table.Row className='estimated-adhoc-transactions-trow'>
                {trashIcon}
                <Table.Data className='col-operation'>
                    {operations}
                </Table.Data>

                <Table.Data className='col-time'>
                    <Form.Input type='number' id='ad-hoc-hrs' min='0' readOnly={disabled} disabled={disabled} bind={binder.bind('hours')} error={errors.hours} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
                </Table.Data>
                <Table.Data className='col-unit-of-measure'><Form.StaticData value='Hours' /></Table.Data>

                <Table.Data className='col-time'>
                    {timeRow}
                </Table.Data>
                <Table.Data className='col-unit-of-measure'>{minutesLabel}</Table.Data>

                {/* <Table.Data className='col-technician'>
              <Form.StaticData value={controller.state.technician} />
              </Table.Data> */}
                {/* <Table.Data className='col-date' style={{minWidth:100}}>
              <Form.StaticData value={controller.state.date} />
              </Table.Data>
              <Table.Data className='col-notes'><Form.Input bind={binder.bind('notes')} /></Table.Data> */}
            </Table.Row>
        );
    }

    render = () => {
        if (this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) {
            return this.renderDollarsRow();
        }
        return this.renderTimeRow();
    }
}

export default EstimatedAdhocTransactionRow;