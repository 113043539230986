import store from 'store';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectAssetsForContainerController extends BaseController {
  constructor(customer, container) {
    super('select-container-assets', {
      customer   : customer,
      container  : container,
      assets     : [],
      searchTerm : ''
    });
  }

  assetSearch = () => {
      this.state.assets = [];
      const parameters = {
        customerId  : this.state.customer.id,
        searchTerm  : this.state.searchTerm
      };

      ajax.get('lookup/searchCustomerAssetsForContainer', parameters).then(results=>{
        this.state.assets = results;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // IList<ContainerAssetDto>
  }

  getSelectedAssets = () => {
    const assets = this.state.assets || [];
    return assets.filter(a => a.selected);
  }

  getCurrentFacility = () => {
    const facility = store.get('currentFacility');
    return facility;
  }

  clearSearchTerm = () => {
    this.state.searchTerm = '';
    this.commit();
  }

  selectAll = () => {
    this.state.assets.forEach(a => {
      if (((a.jobNumber && a.jobComplete == 0) || a.container)) {
       return;
      }
      a.selected = true
    });
    this.commit();
  }

  selectNone = () => {
    this.state.assets.forEach(a => a.selected = false);
    this.commit();
  }

  toggleSelection = (asset) => {
    if(((asset.jobNumber && asset.jobComplete == 0) || asset.container)) return;

    asset.selected = !asset.selected;
    this.commit();
  }

  addAssets = () => {
    const parameters = {
      customerId  : this.state.customer.id,
      containerId : this.state.container.containerId,
      assetIds    : this.getSelectedAssets().map(a => a.assetId) // just the Ids
    };

    ajax.post('container/addAssets', parameters).then(()=>{
      messages.channel('edit-container').action('saved').post();
    this.state.assets = [];
    this.commit();
    this.assetSearch();
    notification.action('success').post('Added ' + Utils.pluralize('asset', 's', parameters.assetIds.length) + ' to ' + this.state.container.description);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: AddAssetsToContainerDto, out: void
  }
}

export default SelectAssetsForContainerController;
