
import React, { useEffect } from 'react';
import lodash from 'lodash';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import Pagination from './Pagination';
import Table from './Table';
import Form from './Form';
import Button from './Button';
import Binder from '../lib/Binder';
import Controller from '../mixins/Controller';


const style = {
    margin: "5px"
}

const getAccessorId = (id) => {
    let name = id;
    if (!name) {
        return name;
    }

    const index = id.indexOf('.');
    if (index !== -1) {
        const list = id.split('.')
        name = list[list.length - 1];
    }
    else {
        name = id;
    }

    return name;
}

class EditableRow extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    componentDidUpdate = () => {
        if (!lodash.isEqual(this.props.controller.state, this.state)) {
            this.setState(this.props.controller.state);
        }
    }

    render() {
        const row = this.props.row;
        const binder = new Binder(this);
        return (
            <Table.Row {...this.props}>
                {row.cells.map(cell => {
                    const className = 'col-' + getAccessorId(cell.column.id);
                    const cellProps = this.props.getCellProps ? { ...this.props.getCellProps(cell), ...cell.getCellProps() } : cell.getCellProps();
                    const classNames = cellProps.className ? className + ' ' + cellProps.className : className;

                    return (
                        <Table.Data {...cellProps} className={classNames} >
                            {cell.render(cell.column.Cell(cell, binder))}
                        </Table.Data>
                    );
                })}
            </Table.Row>
        );
    }
}

const Row = (props) => {
    const row = props.row;
    const rowName = 'row-' + row.id;
    const defaultRowProps = { canSelect: true, id: rowName };
    let rowProps = props.getRowProps ? { ...props.getRowProps(row), ...row.getRowProps() } : row.getRowProps();
    rowProps = { ...defaultRowProps, ...rowProps };
    const isSelected = rowProps.isSelected === undefined ? rowProps.canSelect ? row.isSelected : false : rowProps.isSelected;

    const handleRowClick = (e) => {
        if (!rowProps.onClick) {
            if (props.onRowClick && rowProps.canSelect) {
                if (!props.allowMultiSelect) {
                    props.toggleAllRowsSelected(false);
                }
                row.toggleRowSelected();
            }
        }
        else {
            rowProps.onClick(row.original);
        }
    }

    return (
        props.editable
            ? <EditableRow {...rowProps} key={rowName} row={row} controller={row.original} getCellProps={props.getCellProps} />
            : <Table.Row {...rowProps} isSelected={isSelected} key={rowName} onClick={handleRowClick}>
                {row.cells.map(cell => {
                    const className = 'col-' + getAccessorId(cell.column.id);
                    const cellProps = props.getCellProps ? { ...props.getCellProps(cell), ...cell.getCellProps() } : cell.getCellProps();
                    const classNames = cellProps.className ? className + ' ' + cellProps.className : className;
                    return <Table.Data {...cellProps} className={classNames} >{cell.render('Cell')}</Table.Data>
                })}
            </Table.Row>
    )
}

const Grid = (defaultProps) => {

    const props = {
        hover: true,
        small: true,
        dark: false,
        striped: true,
        bordered: true,
        borderless: false,
        responsive: true,
        showHeaders: false,
        showAllPages: false,
        noDataText: 'No data',
        addNewRowText: 'Add new row',
        allowMultiSelect: false,
        showPagination: true,
        editable: false,
        canAddNewRow: true,
        enableDefaultRowSelect: false,
        pageSizes: [{ Id: 10, Name: '10' }, { Id: 25, Name: '25' }, { Id: 50, Name: '50' }, { Id: 100, Name: '100' },
        { Id: 10000, Name: 'All' }],
        selectedRowIds: {},
        isAllRowsSelected: false,
        canAddDefaultValueButton: false,
        disableAddDefaultValueButton: false,
        addDefaultValueText: 'Add Default',
        ...defaultProps
    };

    const {
        getTableProps,
        getTableBodyProps,
        visibleColumns,
        headerGroups,
        footerGroups,
        prepareRow,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        toggleAllRowsSelected,
        toggleRowSelected,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            initialState: {
                pageIndex: 0,
                pageSize: props.pageSize || (props.pageSizes[0].Id || 10),
                hiddenColumns: props.columns.filter(c => c.isVisible === false).map(c => c.accessor),
                selectedRowIds: props.onRowClick && props.enableDefaultRowSelect ? { 0: true, ...props.selectedRowIds } : props.selectedRowIds
            },
            ...props,
        },
        useSortBy,
        usePagination,
        useRowSelect,
    )

    const pagination = (currentPage, totalPageCount) => {
        if (totalPageCount === 0) {
            return [1];
        }
        if (props.showAllPages) {
            return Array.from(new Array(totalPageCount), (value, index) => index + 1);
        }
        let delta = 2,
            left = currentPage - delta,
            right = currentPage + delta + 1,
            result = [];

        result = Array.from({ length: totalPageCount }, (v, k) => k + 1)
            .filter(i => i && i >= left && i < right);

        if (result.length > 1) {
            if (result[0] > 1) {
                if (result[0] > 2) {
                    result.unshift('...')
                }
                result.unshift(1)
            }

            if (result[result.length - 1] < totalPageCount) {
                if (result[result.length - 1] !== totalPageCount - 1) {
                    result.push('...')
                }
                result.push(totalPageCount)
            }
        }
        return result;
    }

    const handlePageIndexClick = (event) => {
        const target = event.target;
        const totalPageCount = props.pageCount || pageCount;
        const name = target.localName === 'span' ? target.parentNode.getAttribute('name') : target.getAttribute('name');
        switch (name) {
            case 'backward':
                gotoPage(0);
                break;
            case 'previous':
                previousPage();
                break;
            case 'next':
                nextPage();
                break;
            case 'forward':
                gotoPage(totalPageCount - 1);
                break;
            case '...':
                break;

            default:
                let newPageIndex = parseInt(name) - 1;
                gotoPage(newPageIndex);
                break;
        }
    };

    const handlePageSizeChange = (event) => {
        if (event.added && event.added.result) {
            setPageSize(event.added.result.Id);
        }
    };

    const pagesArray = [];
    let previousClasses = 'page-item';
    let nextClasses = 'page-item';
    const totalPages = props.pageCount || pageCount;
    const curentPageIndex = pageIndex;
    const pagesList = pagination(curentPageIndex, totalPages);
    previousClasses = !canPreviousPage ? previousClasses + ' disabled' : previousClasses;
    nextClasses = !canNextPage ? nextClasses + ' disabled' : nextClasses;
    pagesList.forEach((page, key) => {
        const item = page === '...'
            ? <Pagination.Ellipsis disabled={true} />
            : <Pagination.Item key={key} name={page} active={(parseInt(page) - 1) == curentPageIndex} onClick={handlePageIndexClick}>{page}</Pagination.Item>
        pagesArray.push(item);
    });

    const noRows = <Table.Data colSpan={visibleColumns.length}>{props.noDataText}</Table.Data>
    const showPageSize = !props.pageSize;
    const pageSizeField = showPageSize
        ? <span><Form.AutoComplete className='page-size-dropdown' style={{ minWidth: '6.25rem' }} minimumResultsForSearch={-1} allowClear={false} data={props.pageSizes} onChange={handlePageSizeChange} defaultValue={props.pageSizes[0] || ''} /></span>
        : null;

    const paginationContainerClasses = showPageSize ? 'justify-content-between' : 'justify-content-end';

    let editableFooter = props.editable && visibleColumns.length && (props.canAddNewRow || props.canAddDefaultValueButton)
        ? <Table.Row>
            <Table.Data colSpan={visibleColumns.length} className='table-add-new-row col-action'>
                <div>
                    {props.canAddNewRow && <Button onClick={props.onAddNewRow} icon='fa fa-plus' variant='success' size='sm'>{props.addNewRowText}</Button>}

                    {props.canAddDefaultValueButton && <Button onClick={props.onAddDefaultValue} icon='fa fa-plus' variant='success' disabled={props.disableAddDefaultValueButton} size='sm' style={style} >{props.addDefaultValueText}</Button>}
                </div>
            </Table.Data>
        </Table.Row>
        : null;

    const tableProps = {
        id: props.id,
        hover: props.hover,
        small: props.small,
        dark: props.dark,
        striped: props.striped,
        bordered: props.bordered,
        borderless: props.borderless,
        responsive: props.responsive,
        caption: props.caption
    }

    useEffect(() => {
        if (props.onRowClick && props.data.length) {
            if (props.allowMultiSelect) {
                const selectedRow = selectedFlatRows.map(r => r.original);
                props.onRowClick(selectedRow);
            }

            // else if(props.selectedGridRow != 0 && selectedFlatRows.length == 0) {  
            //     var result = props.data[props.selectedGridRow];
            //     props.onRowClick(result);
            // }
            
            else {
                const selectedRows = selectedFlatRows.length ? selectedFlatRows[0].original : '';
                props.onRowClick(selectedRows);
            }
        }
    }, [selectedFlatRows]);

    useEffect(() => {
        switch (props.selectionType) {
            case 'all':
                toggleAllRowsSelected(true);
                break;
            case 'none':
                toggleAllRowsSelected(false);
                break;
            case 'some':
                break;
            default:
                break;
        }
    }, [props.selectionType]);

    useEffect(() => {
        if (props.onPageChange) {
            props.onPageChange(pageIndex);
        }
    }, [pageIndex]);

    useEffect(() => {
        if (props.onPageSizeChange) {
            props.onPageChange(pageSize);
        }
    }, [pageSize]);

    return (
        <div>
            <Table {...tableProps} {...getTableProps()}>
                <Table.Header>
                    {
                        headerGroups.map(headerGroup => {
                            return (
                                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                        const className = 'col-' + getAccessorId(column.id);
                                        const columnHeaderProps = column.canSort && !props.editable ? { ...column.getHeaderProps(column.getSortByToggleProps()) } : { ...column.getHeaderProps() };
                                        return (
                                            <Table.Head {...columnHeaderProps} title='' className={className}>
                                                {column.render('Header')}{' '}<span className={column.isSorted ? column.isSortedDesc ? 'fas fa-sort-amount-up-alt' : 'fas fa-sort-amount-down-alt' : column.canSort && !props.editable ? 'fas fa-sort' : ''} />
                                            </Table.Head>
                                        )
                                    })}
                                </Table.Row>
                            )
                        })
                    }
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {
                        props.showPagination && !props.editable
                            ? page.length
                                ? page.map((row, i) => {
                                    prepareRow(row)
                                    // if(!props.allowMultiSelect){row.isSelected = props.selectedGridRow == i;}
                                    return <Row row={row} getRowProps={props.getRowProps} onRowClick={props.onRowClick} allowMultiSelect={props.allowMultiSelect} getCellProps={props.getCellProps} toggleAllRowsSelected={toggleAllRowsSelected} selectedFlatRows={selectedFlatRows} selectedRowIds={selectedRowIds} />
                                })
                                : noRows
                            : rows.length
                                ? rows.map((row, i) => {
                                    prepareRow(row)
                                    // if(!props.allowMultiSelect){row.isSelected = props.selectedGridRow == i;}
                                    return <Row row={row} getRowProps={props.getRowProps} onRowClick={props.onRowClick} allowMultiSelect={props.allowMultiSelect} getCellProps={props.getCellProps} toggleAllRowsSelected={toggleAllRowsSelected} selectedFlatRows={selectedFlatRows} editable={props.editable} selectedRowIds={selectedRowIds} />
                                })
                                : noRows
                    }
                </Table.Body>
                <Table.Footer>
                    {editableFooter
                    /* {footerGroups.map(group => (
                        <Table.Row {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <Table.Data {...column.getFooterProps()}>{column.render('Footer')}</Table.Data>
                            ))}
                        </Table.Row>
                    ))} */}
                </Table.Footer>
            </Table>
            {
                props.showPagination && !props.editable
                    ? <div className={'mt-2 d-flex ' + paginationContainerClasses}>
                        {pageSizeField}
                        {
                            <Pagination className='mr-2 ml-2 mb-2 flex-wrap grid-pagination'>
                                <Pagination.First id='backward' name='backward' disabled={!canPreviousPage} onClick={handlePageIndexClick} />
                                <Pagination.Prev id='previous' name='previous' disabled={!canPreviousPage} onClick={handlePageIndexClick} />
                                {pagesArray}
                                <Pagination.Next id='next' name='next' disabled={!canNextPage} onClick={handlePageIndexClick} />
                                <Pagination.Last id='forward' name='forward' disabled={!canNextPage} onClick={handlePageIndexClick} />
                            </Pagination>
                        }
                    </div>
                    : null
            }
        </div>
    )
}

export default Grid;