import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import notifications from '../../services/Notification';
import $ from 'jquery';
class AssetsAlreadyInJobRowController extends BaseController {

  constructor(asset, parentContoller) {    
    const key = AssetsAlreadyInJobRowController.__key++;
    super('assets-already-in-job-row' + key, {
      assetId             : asset ? asset.assetId : Utils.emptyGuid,
      primarySerialNumber : asset ? asset.primarySerialNumber : '',
      description         : asset ? asset.description : '',
      isSelected          : asset ? true : false
    });
    this.assetIdparentContoller = parentContoller;
  }

  isSelectedChanged = () => {
    const pState = this.parentContoller.state;
    if(pState.assets.filter(asset => asset.state.isSelected).length < 1)
    {
      $('.assign-assets-to-job-child-dialog').find('.btn-primary').prop('disabled', true);
      notifications.action('warning').post({ msg: 'It is mandatory select at least one asset' });
    }
    else{
      $('.assign-assets-to-job-child-dialog').find('.btn-primary').prop('disabled', false);
    }    
  }

}

AssetsAlreadyInJobRowController.__key = 1;

export default AssetsAlreadyInJobRowController;