import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import TechnicalDocRowController from './TechnicalDocRowController';
import notification from '../../services/Notification';

class PSMDetailsController extends BaseController {
    constructor(parentController, customerId, psmLink, psmNumber, serviceLevelId, displayClearButton, isTechnicalDoc = false, isReadOnly = false, custDescId = null, technicalDocuments = null) {
      
        super('psm-details', {
            psmLink: psmLink,
            psmNumber: psmNumber,
            serviceLevelId: serviceLevelId.id === Utils.emptyGuid ? null : serviceLevelId,
            disablePSM: psmNumber ? true : false,
            displayClearButton: displayClearButton,
            customerId: customerId,
            parentController: parentController,
            errors: {},
            isTechnicalDoc: isTechnicalDoc,
            isReadOnly: isReadOnly,
            custDescId: custDescId,
            technicalDocuments: technicalDocuments
        });
        this.saving = false;
    }

    clear() {
        this.state.displayClearButton = false;
        this.state.psmLink = '';
        this.commit();
    }

    save = () => {
        const state = this.state;
        var validPSMNumber = state?.psmNumber?.replace(/\s+/g, '').length === state?.psmNumber?.length ? true : false;
        if (!validPSMNumber) {
            this.state.errors.psmNumber = 'Please enter valid PSM number';
            this.commit();
            return false;
        }
        this.state.parentController.savePSMDetails(state.psmLink, state.psmNumber, state.serviceLevelId);
        return true;
    }
    addNewLink = () => {
        var linkrows = [...this.state.technicalDocuments];
        linkrows.unshift(new TechnicalDocRowController(Utils.emptyGuid, this.state.custDescId, '', ''));
        this.state.technicalDocuments = linkrows;
        this.commit();
        return false;
    }
    removeLink = (index) => {
        var linkrows = [...this.state.technicalDocuments];
        linkrows.splice(index, 1);
        this.state.technicalDocuments = linkrows;
        this.commit();
    }
    saveLink = () => {
        var linkrows = [...this.state.technicalDocuments];

        var emptyName = linkrows.every(x => x.state.Document_Name && x.state.Document_Link);
        if (!emptyName) {
            notification.action('error').post({ msg: 'Please enter Document Name and Link', title: 'Error' });
            return false;
        }
        const duplicates = linkrows.filter((item, index) => linkrows.some((elem, idx) => elem.state.Document_Name.toLowerCase() === item.state.Document_Name.toLowerCase() && idx !== index));
        if (duplicates.length > 0) {
            notification.action('error').post({ msg: 'Please enter unique Document Names', title: 'Error' });
            return false;
        }
        this.state.parentController.addTechnicalDocuments(this.state.technicalDocuments);
        return true;

    }
}

export default PSMDetailsController;