import BaseController from '../../lib/BaseController';

class AdditionalTimeRowController extends BaseController {
    constructor(parentController, additionalTime) {
      super('additionalTime-row-controller-' + AdditionalTimeRowController.key++, {
        type              : additionalTime ? additionalTime.Type              : '',
        value             : additionalTime ? additionalTime.TotalTime         : '',
        base              : additionalTime ? additionalTime.baseName          : '',
        costCenter        : additionalTime ? additionalTime.costCenterName    : '',     
        errors            : {}
      });
  
      this.parentController = parentController;
    }  

    static key = 1;  
  }

  export default AdditionalTimeRowController; 