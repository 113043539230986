import BaseController from '../../lib/BaseController';
import ThicknessReadingRowController from './ThicknessReadingRowController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';

class ThicknessReadingsController extends BaseController {
  constructor(asset, isViewMode) {
    super('thicknessReadings', {
      errors: {},
      thicknessReadings: [],
      asset: asset,
      isViewMode: isViewMode
    });
    this.saving = false;
    this.loadThicknessReading();
  }

  // validatedimensionValues(state) {
  //   if (!state.dimensionValues.length) {
  //     state.errors.dimensionValues = 'At least one row must be specified';
  //     return;
  //   }
  //   state.dimensionValues.forEach(row => row.validate());
  // }

  // canSave() {    
  //   this.validatedimensionValues(this.state);    
  //   var errors    = Object.keys(this.state.errors);
  //   var rowErrors = this.state.dimensionValues.reduce((acc, curr) => {
  //     return acc + Object.keys(curr.state.errors).length;
  //   }, 0);  

  //   if (!errors.length && !rowErrors) {
  //     return true;
  //   }

  //   this.commit();
  //   return false;
  // }

  addNewThicknessReadingRow = (thicknessReading) => {
    this.state.thicknessReadings.push(new ThicknessReadingRowController(this, thicknessReading));
    this.commit();
  }

  loadThicknessReading = () => {
    const asset = this.state.asset;
    ajax.get('lookup/getThicknessReadings/', { jobSOWId: asset.jobSOWId }).then(thicknessReadings => {
      if (thicknessReadings != null) {
        thicknessReadings.forEach(thicknessReading => this.addNewThicknessReadingRow(thicknessReading));
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }

  saveThicknessReadings = () => {
    // if(!this.canSave()){         
    //   return false;
    // }
    const parameters = {
      jobSOWId: this.state.asset.jobSOWId,
      thicknessReadingList: this.state.thicknessReadings.map(thicknessReading => thicknessReading.state)
    };
    this.saving=true;
    this.commit();
    return ajax.post('inspection/saveThicknessReadings', parameters).then(response => {
      this.saving=false;
      this.commit();
      notification.action('success').post('Saved the Thickness reading');
      return response;
    }).catch(err => {
      this.saving=false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default ThicknessReadingsController;