
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class ReceivingTicketDetailController extends BaseController {
    constructor() {
        super('receiving-ticket', ReceivingTicketDetailController.getInitialState(''));

        messages.channel('login').action('loggedin').subscribe(data => {
            if (!data) {
                this.clearData();
            }
        });

        messages.channel('receiving-ticket').action('saved').subscribe(data => {
            if (data && data.receivingTicketId) {
                this.loadTicket(data.receivingTicketId);
            }
        });

        messages.channel('change-facility').action('changed').subscribe(() => {
            this.clearData();
            this.commit();
        });
    }

    static getInitialState = () => {
        return {
            receivingTicketId: '',
            ticketNumber: '',
            facilityId: '',
            receivedDate: '',
            receivingID: '',
            shipToLocationId: '',
            container: '',
            shipToLocationName: '',
            notes: '',
            systemNotes: '',
            jobId: '',
            jobNumber: '',
            customerId: '',
            customerName: '',
            customerPoNumber: '',
            customerJobNumber: '',
            receivedByName: '',
            assetCount: 0,
            customerLocation: '',
            receivingFacility: '',
            assets: [],
            errors: {},
            editingNote: false,
            cacheNotes: ''
        };
    }

    merge = (ticket) => {
        if (!ticket) {
            return;
        }

        this.state.receivingTicketId = ticket.receivingTicketId;
        this.state.ticketNumber = ticket.ticketNumber;
        this.state.facilityId = ticket.facilityId;
        this.state.receivedDate = ticket.receivedDate;
        this.state.receivingID = ticket.receivingID;
        this.state.shipToLocationId = ticket.shipToLocationId;
        this.state.container = ticket.container;
        this.state.shipToLocationName = ticket.shipToLocationName;
        this.state.notes = ticket.notes;
        this.state.systemNotes = ticket.systemNotes;
        this.state.datePODUploaded = ticket.datePODUploaded;
        this.state.jobId = ticket.jobId;
        this.state.jobNumber = ticket.jobNumber;
        this.state.customerId = ticket.customerId;
        this.state.customerName = ticket.customerName;
        this.state.customerPoNumber = ticket.customerPoNumber;
        this.state.customerJobNumber = ticket.customerJobNumber;
        this.state.receivedByName = ticket.receivedByName;
        this.state.assetCount = ticket.assetCount;
        this.state.customerLocation = ticket.customerLocation;
        this.state.receivingFacility = ticket.receivingFacility;
        this.state.assets = ticket.assets;
        this.state.errors = {};
        this.state.cacheNotes = '';
    }

    clearData = () => {
        this.state = ReceivingTicketDetailController.getInitialState();
    }

    loadTicket = (ticketId) => {
        if (ticketId) {
            this.clearData();
            this.commit();
            this.loading = true;
            ajax.get('lookup/getReceivingTicket/', { ticketId: ticketId }).then(result => {
                this.loading = false;

                if (result !== null) {
                    this.merge(result);
                    this.commit();
                }
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
        }
    }



    editNote = () => {
        this.state.editingNote = true;
        this.state.cacheNotes = this.state.notes;
        this.commit();
    }

    cancelSaveNotes = () => {
        this.state.editingNote = false;
        this.state.notes = this.state.cacheNotes;
        this.state.cacheNotes = '';
        this.commit();
    }

    filterElements = (node) => {
        return (!Utils.contains(node.className, 'no-print') && !Utils.contains(node.className, 'no-sap-upload'));
    }


    saveNotes = () => {
        const ticket = this.state;
        if (ticket.notes === ticket.cacheNotes) {
            this.cancelSaveNotes();
            return;
        }
        const receivingTicketId = ticket.receivingTicketId;
        const ticketNumber = ticket.ticketNumber;
        const customerName = ticket.customerName;
        const note = ticket.notes;
        const parameters = {
            receivingTicketId: receivingTicketId,
            note: note
        };

        ajax.post('receiving/saveNotes', parameters).then(() => {
            messages.channel('receiving-ticket').action('saved').post({ receivingTicketId: receivingTicketId });
            notification.action('success').post(`Saved note for ${customerName} on receiving ticket ${ticketNumber}`);
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }
}

export default ReceivingTicketDetailController;