import store from 'store';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import LoginService from '../services/LoginService';
import notification from '../../services/Notification';

class FacilitiesController extends BaseController {
    constructor() {
        super('facility', {
            searchActive: true,
            searchCompleted: false,
            searchEnabledUsersOnly: true,
            searchText: '',
            searchLocationText: '',
            searchResults: [],
            selectedFacility: '',
            allCustomerLocations: [],
            filteredCustomerLocations: [],
            allFacilityUsers: [],
            filteredFacilityUsers: []
        });

        messages.channel('edit-facility').action('saved').subscribe(facilityId => {
            this.searchFacilities(facilityId);
            this.loadCurrentFacility();
        });

        messages.channel('edit-customer-location').action('saved').subscribe(() => {
            this.loadCustomerLocations(this.state.selectedFacility);
        });

        messages.channel('select-facility-customer-locations').action('saved').subscribe(() => {
            this.loadCustomerLocations(this.state.selectedFacility);
        });

        messages.channel('select-facility-user-access').action('saved').subscribe(() => {
            this.loadFacilityUsers(this.state.selectedFacility);
        });

        messages.channel('edit-user').action('saved').subscribe(() => {
            this.loadFacilityUsers(this.state.selectedFacility);
            store.remove('edit-user');
        });

        messages.channel('login').action('loggedin').subscribe(data => {
            if (data) {
                this.loadCurrentFacility();
            }
            else {
                this.clearResults();
            }
        });
    }

    searchEnabledUsersOnlyChanged = () => {
        this.loadFacilityUsers(this.state.selectedFacility);
    }

    selectFacility = (facilityId) => {
        this.state.searchResults.forEach(facility => {
            if (facility.facilityId === facilityId) {
                this.loadDetails(facility);
                this.loadCustomerLocations(facility);
                this.loadFacilityUsers(facility);
            }
        });
    }

    isSelected = (facility) => {
        if (!this.state.selectedFacility) {
            return false;
        }
        return (facility.facilityId === this.state.selectedFacility.facilityId);
    }

    clearResults = () => {
        this.state.searchResults = [];
        this.state.selectedFacility = '';
        this.state.allCustomerLocations = [];
        this.state.filteredCustomerLocations = [];
        this.state.allFacilityUsers = [];
        this.state.filteredFacilityUsers = [];
    }

    clearSearch = () => {
        this.state.searchText='';
        this.clearLocationSearch();
        this.clearUserSearch();
    }

    loadCurrentFacility = () => {
        if (!LoginService.loginInfo) {
            return;
        }

        const facilityId = LoginService.loginInfo.facilityId;
        const parameters = {
            facilityId: facilityId
        };
        ajax.get('lookup/getFacility', parameters).then(results => {
            store.set('currentFacility', results);
            let loginDetail = store.get('InteServLoginInfo');
            if (results)
                loginDetail.enableDMS = results.enableDMS;
            else
                loginDetail.enableDMS = false;
            store.set('InteServLoginInfo', loginDetail);
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // FacilityDto 
    }

    loadDetails = (facility) => {
        this.state.selectedFacility = facility;
        this.loadCustomerLocations(facility);
        this.loadFacilityUsers(facility);
        this.commit();
    }

    // Customer Locations
    // ----------------------------------------------

    loadCustomerLocations = (facility) => {
        if (!facility || !facility.facilityId) {
            return;
        }
        const parameters = {
            facilityId: facility.facilityId
        };

        this.state.allCustomerLocations = [];
        this.state.isLoadingLocations = true;
        this.commit();
        ajax.get('lookup/getAllCustomerLocationsForFacility', parameters).then(results => {
            this.state.isLoadingLocations = false;
            this.state.allCustomerLocations = results;
            this.filterCustomerLocations();
            this.commit();
        }).catch(err => {
            this.state.isLoadingLocations = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // IList<FacilityCustomerLocationDto>
    }

    filterCustomerLocations = () => {
        if (!this.state.allCustomerLocations.length) {
            this.state.filteredCustomerLocations = [];
            this.commit();
            return;
        }

        const filterText = (this.state.searchLocationText || '').toLowerCase();
        if (filterText.trim() === '') {
            this.state.filteredCustomerLocations = this.state.allCustomerLocations.map(cl => cl);
            this.commit();
            return;
        }

        this.state.filteredCustomerLocations = this.state.allCustomerLocations.filter(cl => cl.name.toLowerCase().indexOf(filterText) !== -1);
        this.commit();
    }

    clearLocationSearch = () => {
        document.getElementById('searchLocationText').focus();
        this.state.searchLocationText = '';
        this.commit();
    }

    // Facility Users
    // ----------------------------------------------

    loadFacilityUsers = (facility) => {
        if (!facility || !facility.facilityId) {
            return;
        }
        const parameters = {
            facilityId: facility.facilityId,
            searchTerm: '',
            enabledUsersOnly: this.state.searchEnabledUsersOnly,
            displayAccount: true
        };

        this.state.allFacilityUsers = [];
        this.state.isLoadingUsers = true;
        ajax.get('lookup/getAllUsersForFacility', parameters).then(results => {
            this.state.isLoadingUsers = false;
            this.state.allFacilityUsers = results;
            this.filterFacilityUsers();
            this.commit();
        }).catch(err => {
            this.state.isLoadingUsers = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // IList<UserLookupDto>
    }

    filterFacilityUsers = () => {
        if (!this.state.allFacilityUsers.length) {
            this.state.filteredFacilityUsers = [];
            this.commit();
            return;
        }

        const filterText = (this.state.searchUserText || '').toLowerCase();
        if (filterText.trim() === '') {
            this.state.filteredFacilityUsers = this.state.allFacilityUsers.map(u => u);
            this.commit();
            return;
        }

        this.state.filteredFacilityUsers = this.state.allFacilityUsers.filter(u => u.name.toLowerCase().indexOf(filterText) !== -1);
        this.commit();
    }

    clearUserSearch = () => {
        document.getElementById('searchUserText').focus();
        this.state.searchUserText = '';
        this.commit();
    }

    removeUserAccess = (user) => {
        const parameters = {
            facilityId: this.state.selectedFacility.facilityId,
            userId: user.id
        };

        ajax.post('facility/removeUserAccess', parameters).then(() => {
            this.loadFacilityUsers(this.state.selectedFacility);
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    searchFacilities = (selectedFacilityId) => {
        this.state.results = [];
        const searchText = this.state.searchText;
        const parameters = {
            searchTerm: searchText
        };
        this.state.searchResults = [];
        this.state.isLoading = true;
        ajax.get('lookup/searchFacilities', parameters).then(results => {
            this.state.isLoading = false;
            this.state.searchResults = results;
            if (selectedFacilityId) {
                // facility to select was specified
                this.selectFacility(selectedFacilityId);
            }
            else if (results.length > 0) {
                // auto-select the 1st facility - this will be the newest one based on ordering
                this.state.selectedFacility = results[0];
                this.selectFacility(this.state.selectedFacility.facilityId);
            }
            else {
                this.state.selectedFacility = '';
            }
            this.commit();
        }).catch(err => {
            this.state.isLoading = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // IList<FacilityLookupDto>
    }
}

export default FacilitiesController;
