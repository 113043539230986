import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';

class InspectionAttachmentDialogController extends BaseController {
  constructor(assets, parentContoller, testName) {
    super('inspection-attachment', InspectionAttachmentDialogController.getInitialState(assets));
    this.parentContoller = parentContoller;
    this.adding = false;
    this.applications = [];
    this.testName = testName;
  }

  static getInitialState = (assets) => {
    let initialState = {
      assets: assets || [],
      files: [],
      existingImages: [],
      loadStatus: false
    };
    initialState.assets.map(asset => {
      if (asset.file) {
        asset.file = [];
      }
    });
    return initialState;
  }

  load = () => {
    let parameters = [];
    this.state.loadStatus = true;
    this.commit();
    this.state.assets.forEach(item => {
      var InspectionAttachmentRequestDto = { jobId: item.assetId, jobSOWId: item.jobSOWId };
      parameters.push(InspectionAttachmentRequestDto)
    });
    co(this, function* () {
      try {
        var result = yield ajax.post('lookup/getExistingInspectionAttachments', parameters); // UpdateJobDto
        this.state.existingImages = result;
        this.state.loadStatus = false;
        this.commit();
      }
      catch (err) {
        notification.action('error').post({ msg: err.message, title: 'Error loading existing attachment' });
      }
    });
  }

  save = () => {
    const s = this.state;
    const files = s.assets.filter(asset => asset.file && asset.file.length);
    let parameters = [];
    if (!this.adding) {
      files.map(attach => {
        parameters.push({
          jobId: attach.assetId,
          files: attach.file,
          jobSOWId: attach.jobSOWId,
          testName: this.testName
        });
      });
    }
    this.saving = true;
    this.commit();
    ajax.post('inspection/saveFailInspectionattachment', parameters).then(() => {
      this.saving = false;
      this.commit();
    }).catch(err => {
      if (this.adding) {
        notification.action('error').post({ msg: err.message, title: 'Error saving attachment' });
      }
      else {
        notification.action('error').post({ msg: err.message, title: 'Error saving attachment' });
      }
      this.saving = false;
      this.commit();
    });
  }

  uploadFile = (assetId, res) => {
    var duplicateIdentifed = false;
    var duplicateOfAlreadyUploaded = false;
    this.state.assets.forEach(asset => {
      if (!duplicateIdentifed && asset.assetId == assetId && asset.file && asset.file.find(f => f.fileName === res.file.name)) {
        duplicateIdentifed = true;
      }
    });
    this.state.existingImages.forEach(image => {
      if (image.jobId == assetId) {
        if (image.files.find(f => f.fileName === res.file.name)) {
          duplicateOfAlreadyUploaded = true;
        }
      }
    })
    if (duplicateIdentifed || duplicateOfAlreadyUploaded) {
      var message = duplicateOfAlreadyUploaded ? 'A file has been already uploaded with the same name'
        : 'File Name already exists for this serial in the current upload';
      notification.action('error').post({ msg: message, title: 'Duplicate File' });
      return;
    }
    if (res && (res.file.size / 1048576).toFixed(1) < 25 && AppConstants.imgFileTypes.includes(res.file.name.split('.')[1].toLowerCase())) {
      var name = '';
      if (navigator.userAgent.match(/iPad/i) != null) {
        name = res.file.name.split('.')[0] + '_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' }) + '.' + res.file.name.split('.')[1].toLowerCase();
      }
      else {
        name = res.file.name;
      }
      var newFile = {
        id: Utils.newGuid(),
        fileName: name,
        size: res.file.size,
        altText: '',
        caption: '',
        file: res.file,
        fileType: res.file.name.split('.')[1].toLowerCase() || '',
        MimeType: res.file.type,
        imgUrl: res.imageUrl,
        recordStatus: 'A'
      };
      this.addNewFile(newFile, assetId);
      this.parentContoller.commit();
    }
    else {
      notification.action('error').post({ msg: 'File size is larger that 25 MB or not in image format', title: 'File Error' });
    }
  }

  addNewFile = (file, assetId) => {
    this.state.assets.map(asset => {
      if (asset.assetId == assetId) {
        if (asset.file) {
          asset.file.push(file);
        }
        else {
          asset.file = new Array(file);
        }
      }
    });
    this.commit();
    this.parentContoller.commit();
  }

  deleteFile = (assetId, file) => {
    var idx = this.state.assets.map(asset => {
      if (asset.assetId == assetId) {
        return asset.file.indexOf(file);
      }
    });
    if (idx === -1) {
      return;
    }
    this.state.assets.map(asset => {
      if (asset.assetId == assetId) {
        return asset.file.splice(idx, 1);
      }
    });
    this.commit();
    this.parentContoller.commit();
  }
}

export default InspectionAttachmentDialogController;
