import React, {useMemo} from 'react';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';

const EndConfigurationsTable = (props) => {
  const controller = props.controller;
  const canEdit = props.enabled;  

  const renderRowErrors = () => {
    var rowErrors = controller.state.errors && controller.state.errors.endConfigurations;
    if (rowErrors) {
      return <Form.FieldError>{rowErrors}</Form.FieldError>;
    }
  }

  const totalConfigurationCount = controller.state.endConfigurations.reduce((acc, curr) => acc + curr.state.quantity, 0);

  const handleAddNewRow = () => {
    props.controller.addNewEndConfigurationRow();
  }

  const handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  const handleKeyUp = (evt) => {
    try {
      const value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }

      if (value > 200) {
        evt.target.value = 200;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }
  
  const divClassName = controller.state.errors.endConfigurations ? ' has-error' : '';

  const data = [...controller.state.endConfigurations];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        isVisible: canEdit,     
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return canEdit
            ? <ActionIcon  icon='fa-trash' className='remove-handle' action={controller.removeRow} />
            : null;
        }
      },
      {
        Header: 'ID',
        accessor: 'state.configurationId',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.Input bind={binder.bind('configurationId')} error={errors.configurationId}  className='required id-field' placeholder='#' />
            : cellInfo.cell.value;
        }
      },
      {
        Header: 'End Connection Size',
        accessor: 'state.endConnectionSize',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete readOnly={false} bind={binder.bind('endConnectionSize')} error={errors.endConnectionSize} className='required' search={parentController.search('end connection size')} />
            : cellInfo.cell.value.name;
        }
      },
      {
        Header: 'End Configuration',
        accessor: 'state.endConfiguration',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete readOnly={false} bind={binder.bind('endConfiguration')}  error={errors.endConfiguration}  className='required' search={parentController.search('end configuration')} />
            : cellInfo.cell.value.name;
        }
      },
      {
        Header: 'End Connection',
        accessor: 'state.endConnection',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return canEdit
            ? <Form.AutoComplete readOnly={false} bind={binder.bind('endConnection')}     error={errors.endConnection}     className='required' search={parentController.search('end connection')} />
            : cellInfo.cell.value.name;
        }
      },
    ],
    [canEdit]);
    return (
      <div className={divClassName}>
        <Grid id='end-config-results-table' columns={columns} data={data} editable={canEdit} canAddNewRow={false} onAddNewRow={handleAddNewRow} showPagination={false} noDataText='No end configurations exist yet'/>
        {renderRowErrors()}
      </div>
  );
}

export default EndConfigurationsTable;