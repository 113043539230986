import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectFacilityUsersController extends BaseController {
  constructor(facility, facilityUsers) {
    super('change-facility-users', {
      facility      : facility,
      facilityUsers : Utils.clone(facilityUsers),
      allUsers      : [],
      searchUser    : ''
    });
  }

  selectNoUsers = () => {
    this.state.allUsers.forEach(user => {
      user.selected = false;
    });
    this.commit();
  }

  selectNoFacilityUsers = () => {
    this.state.facilityUsers.forEach(user => {
      user.selected = false;
    });
    this.commit();
  }

  selectAllUsers = () => {
    this.state.allUsers.forEach(user => {
      user.selected = true;
    });
    this.commit();
  }

  selectAllFacilityUsers = () => {
    this.state.facilityUsers.forEach(user => {
      user.selected = true;
    });
    this.commit();
  }

  toggleSelection = (user) => {
    user.selected = !user.selected;
    this.commit();
  }

  clearSearch = () => {
    this.state.searchUser = '';
    this.state.allUsers   = [];
    document.getElementById('searchUser').focus();
    this.commit();
  }

  searchAllUsers = () => {
      this.state.allUsers  = [];
      var searchText       = this.state.searchUser;
      ajax.get('lookup/searchFacilityUsers', { searchTerm: searchText }).then(results=>{
        this.state.allUsers  = results;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // IList<UserLookupDto>
  }

  moveToSelected = () => {
    this.state.allUsers.forEach(user => {
      if (user.selected) {
        user.selected = false;

        let found = false;
        for (let i in this.state.facilityUsers) {
          if (this.state.facilityUsers[i].id === user.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.state.facilityUsers.push(Utils.clone(user));
        }
      }
    });
    // sort selected list
    this.state.facilityUsers.sort(function(a, b) {
      return (a.name.localeCompare(b.name));
    });
    this.commit();
  }

  removeFromSelected = () => {
    let i = this.state.facilityUsers.length;
    while (i--) {
      const user = this.state.facilityUsers[i];
      if (user.selected) {
        this.state.facilityUsers.splice(i, 1);
      }
    }
    this.commit();
  }

  save = () => {
      const ids        = this.state.facilityUsers.map(user => { return user.id; });   // use only the ids
      const facility   = this.state.facility;
      const parameters = {
        facilityId : facility.facilityId,
        userIds    : ids
      };

      ajax.post('facility/saveUserAccess', parameters).then(()=>{
        messages.channel('select-facility-user-access').action('saved').post(facility.facilityId);
        return true;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // in: UpdateFacilityUserAccessDto, out: void
    }
}

export default SelectFacilityUsersController;
