import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import SAPService from '../services/SAPService';
import LoginService from '../services/LoginService';
import EditAssetController from '../controllers/EditAssetController';
import AppConstants from '../utility/AppConstants';
import authentication from '../services/authentication';

const EditForm = (props) => {
    const handleSpaceKey = (evt) => {
        if (evt.which == 32) {
            evt.preventDefault();
        }
    }

    const binder = props.binder;
    const controller = props.controller;
    const asset = controller.state || {};
    const customer = asset.customer;
    const enableCustomerLocation =LoginService.isCoordinator || LoginService.isCustomer;
    const isSAPFacility = SAPService.isSAPFacility();
    const description = asset.description && asset.description.name;
    const enableEditSerialNumbers = controller.canSaveWithRfidTag();
    const rfidSaveWarning = controller.hasRfidTag()
        ? controller.canSaveWithRfidTag()
            ? 'This asset has an RFID tag associated with it. As an Administrator, you are permitted to save changes but should be aware of the dangers of doing so.'
            : 'This asset has an RFID tag associated with it. Changes to the serial numbers are not permitted.'
        : '';
    const elementRfidWarning = controller.hasRfidTag()
        ? <div className='col-sm-12 sap-error' style={{ marginTop: '5px' }}>
            <div className='col-sm-12'>{rfidSaveWarning}</div>
        </div>
        : '';
    const elementPrimarySerialNumber = (asset.lockSerialNumber || !enableEditSerialNumbers)
        ? <Form.StaticData label='Primary Serial #' value={asset.primarySerialNumber} />
        : <Form.Input label='Primary Serial #' maxLength='18' bind={binder.bind('primarySerialNumber')} className='required uppercase' error={asset.errors.primarySerialNumber}/>;
    const elementSecondarySerialNumber = (asset.lockSerialNumber || !enableEditSerialNumbers)
        ? <Form.StaticData label='Secondary Serial #' value={asset.secondarySerialNumber} />
        : <Form.Input label='Secondary Serial #' bind={binder.bind('secondarySerialNumber')} className='uppercase' />;
    let equipmentNumberValue = asset.equipmentNumber;
    let equipmentClass = '';
    if (controller.canRetrieveEquipmentNumber()) {
        equipmentNumberValue = 'Click \'Save\' to request an Equipment # from SAP';
        equipmentClass = 'sap-error';
    }

    let statusColourClass = 'status' + asset.status; // e.g. statusScrapped
    if (asset.status === 'Weld Repair' || asset.status === 'Failed Inspection')
        statusColourClass = 'asset-status statusWeldRepair'
    if (asset.status === 'OS/Machining Repair') 
        statusColourClass = 'asset-status statusOSRepair'
    let assetStatus = asset.status;
    if (asset.rejectReason) {
        if (assetStatus != 'Passed')
            assetStatus += ' (' + asset.rejectReason + ')';
    }

    const ctlAssetStatus = (asset.eAssetStatus !== AppConstants.eAssetStatus.Scrapped)
        && (LoginService.isAdministrator || LoginService.isCoordinator)
        ? <Form.AutoComplete label='Asset Status' bind={binder.bind('assetStatus')} search={controller.assetActiveStatusSearch()} />
        : <Form.StaticData label='Asset Status' className={statusColourClass} value={assetStatus} />;

    // var rejectReasonEnabled = (asset.assetStatus && (asset.assetStatus.id === AppConstants.eAssetStatus.Scrapped));
    const valueStyle = { border: 'solid 2px goldenrod' };
    const formatResult = item => {
        const spanFMCLocation = item.result.fmcLocation
            ? <span className='fmc-location float-right select2-tag'>&nbsp;&nbsp;TechnipFMC</span>
            : null;

        const iconArchived = item.result.archived
            ? <i className='fa fa-archive archived float-right select2-tag' />
            : null;

        return <span>{item.text} {iconArchived} {spanFMCLocation}</span>;
    };

    const formatContainerResult = item => {
        let functionalLocation = null;
        if (item.result.functionalLocation) {
            functionalLocation = <span className='user-facility-sap pull-right select2-tag' style={{ fontSize: '1.2rem', color: '#094502' }}> {item.result.functionalLocation}</span>;
        }
        return <span>{item.text} {functionalLocation}</span>;
    }

    const fieldEquipmentNumber = isSAPFacility
        ? <Form.StaticData label='Equipment #' className={equipmentClass} value={equipmentNumberValue} />
        : <Form.NumberInput label='Equipment #' bind={binder.bind('equipmentNumber')} maxLength='8' error={asset.errors.equipmentNumber} />;
    const container = asset.jobNumber
        ? <div className='col-md-4'><Form.StaticData label='Container' formatResult={formatContainerResult} formatSelection={formatContainerResult} value={asset.container.name} /> </div>
        : <div className='col-md-4'><Form.AutoComplete label='Container' formatResult={formatContainerResult} formatSelection={formatContainerResult} bind={binder.bind('container')} search={controller.containerSearch()} /></div>
    return (
        <div>
            <div className='col-md-12 row' style={{ marginTop: '20px' }}>
                <div className='col-md-6'><Form.StaticData label='Customer' value={customer && customer.name} /></div>
                <div className='col-md-6'><Form.StaticData label='Mfg Part #' value={asset.mfgPartNumber} /></div>
                <div className='col-md-6'><Form.StaticData label='Description' value={description} /></div>
                <div className='col-md-6'><Form.StaticData label='In Service Date' value={asset.inServiceDate} /></div>
            </div>

            <div className='col-md-12 row'>
                <div className='col-md-4' onPaste={(evt) => { evt.preventDefault() }}>{elementPrimarySerialNumber}</div>
                <div className='col-md-4'>{elementSecondarySerialNumber}</div>
                <div className='col-md-4' onPaste={(evt) => { evt.preventDefault() }}>{fieldEquipmentNumber}</div>
            </div>

            <div className='col-md-12 row'>
                <div className='col-md-4'><Form.Input label='Batch Number' maxLength='250' bind={binder.bind('batchNumber')} /></div>
                <div className='col-md-4'><Form.Date label='Born On Date' bind={binder.bind('bornOnDate')} className='required' error={asset.errors.bornOnDate} /></div>
                <div className='col-md-4'>{ctlAssetStatus}</div>
            </div>

            <div className='col-md-12 row'>
                {container}
                <div className='col-md-4'><Form.AutoComplete label='Current Location' className='required' bind={binder.bind('currentLocation')} search={controller.customerLocationSearch()} valueStyle={valueStyle} enabled={customer} formatResult={formatResult} formatSelection={formatResult} error={asset.errors.currentLocation} /></div>
                 <div className='col-sm-6 col-lg-4'><Form.AutoComplete label='Customer Location' className='required' bind={binder.bind('customerLocation')}  search={controller.customerLocationSearch()} valueStyle={valueStyle} enabled={enableCustomerLocation} formatResult={formatResult} formatSelection={formatResult} error={asset.errors.customerLocation}/></div>
            </div>

            <div className='col-md-12 row'>
                <div className='col-md-12'>
                    <Form.TextArea rows={5} label='Notes' bind={binder.bind('notes')} />
                </div>
            </div>
            {/* <div className='col-sm-12'>
                <div className='col-sm-6 col-lg-4'><Form.Input label='RFID1' bind={binder.bind('rfidTagId')} maxLength="24" /></div>
                <div className='col-sm-6 col-lg-4'><Form.Input label='RFID2' bind={binder.bind('rfidTagId2')} maxLength="24"/></div>          
              </div> */}
        </div>
    );
}

const Buttons = (props) => {
    let history = useHistory();

    const handleSave = () => {
        props.controller.save(() => {
            history.goBack();
        })
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <div className='col-12'>
            <div className='mb-1 d-flex justify-content-end'>
                <Button icon='fa-save' id='btn-save' className='m-1 action-button' variant='primary' size='sm' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button>
                <Button icon='fa-times' id='btn-cancel' className='m-1 action-button' variant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    );
}

const EditAssetPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class EditAssetPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
        const assetId = this.props.match.params.assetId;
        const prevLocation=this.props.match.params.prevLocation;
        this.props.controller.load(assetId,prevLocation);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    static defaultProps = {
        controller: new EditAssetController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const title = 'Edit Asset — ' + controller.state.primarySerialNumber;
        return (
            <Page {...this.props} pageTitle='InteServ · Edit Asset' id='edit-asset-page'>
                <EditAssetPageHeader pageTitle={title} />
                <Page.Content>
                <div className='content'>
                    <EditForm controller={controller} binder={binder} />
                    <Buttons controller={controller} />
                </div>
                </Page.Content>
            </Page>
        )
    }
}

export default EditAssetPage;