import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectFacilityCustomerLocationsController extends BaseController {
  constructor(facility, facilityCustomerLocations) {
    super('change-facility-users', {
      facility: facility,
      facilityCustomerLocations: Utils.clone(facilityCustomerLocations),
      customerLocations: [],
      customer: '',
      facilityCustomerLocationFilterText: '',
      customerLocationFilterText: ''
    });
  }

  customerSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  customerChanged = () => {
    this.loadCustomerLocations(this.state.customer);
  }

  selectNoCustomerLocations = () => {
    this.state.customerLocations.forEach(location => {
      location.selected = false;
    });
    this.commit();
  }

  selectNoFacilityCustomerLocations = () => {
    this.state.facilityCustomerLocations.forEach(location => {
      location.selected = false;
    });
    this.commit();
  }

  selectAllFilteredCustomerLocations = () => {
    this.applyFilter(this.state.customerLocations, this.state.customerLocationFilterText).forEach(location => {
      location.selected = true;
    });
    this.commit();
  }

  selectAllFilteredFacilityCustomerLocations = () => {
    this.applyFilter(this.state.facilityCustomerLocations, this.state.facilityCustomerLocationFilterText).forEach(location => {
      location.selected = true;
    });
    this.commit();
  }

  toggleSelection = (location) => {
    location.selected = !location.selected;
    this.commit();
  }

  loadCustomerLocations = (customer) => {
    if (!customer || !customer.id) {
      return;
    }
    const parameters = {
      customerId: customer.id
    };
    this.state.customerLocations = [];
    this.commit();
    ajax.get('lookup/getAllCustomerLocationsForCustomer', parameters).then(results => {
      this.state.customerLocations = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<CustomerCustomerLocationDto>
  }

  applyFilter = (locations, filterText) => {
    return locations.filter(cl => {
      if (!filterText) {
        return true;
      }
      return Utils.contains(cl.name, filterText) ||
        Utils.contains(cl.customerName, filterText) ||
        Utils.contains(cl.region, filterText) ||
        Utils.contains(cl.country, filterText);
    });
  }

  moveToSelected = () => {
    this.state.customerLocations.forEach(location => {
      if (location.selected) {
        location.selected = false;

        let found = false;
        for (let i in this.state.facilityCustomerLocations) {
          if (this.state.facilityCustomerLocations[i].id === location.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.state.facilityCustomerLocations.push(Utils.clone(location));
        }
      }
    });
    // sort selected list
    this.state.facilityCustomerLocations.sort(function (a, b) {
      return (a.name.localeCompare(b.name));
    });
    this.commit();
  }

  removeFromSelected = () => {
    let i = this.state.facilityCustomerLocations.length;
    while (i--) {
      const location = this.state.facilityCustomerLocations[i];
      if (location.selected) {
        this.state.facilityCustomerLocations.splice(i, 1);
      }
    }
    this.commit();
  }

  clearFacilityCustomerLocationFilter = () => {
    this.state.facilityCustomerLocationFilterText = '';
    this.commit();
  }

  filterFacilityCustomerLocations = () => {
    this.selectNoFacilityCustomerLocations();
    this.commit();
  }

  clearCustomerLocationFilter = () => {
    this.state.customerLocationFilterText = '';
    this.commit();
  }

  filterCustomerLocations = () => {
    this.selectNoCustomerLocations();
    this.commit();
  }

  save() {
    const customerLocationIds = this.state.facilityCustomerLocations.map(location => { return location.id; });   // use only the ids
    const facility = this.state.facility;
    const parameters = {
      facilityId: facility.facilityId,
      customerLocationIds: customerLocationIds
    };

    return ajax.post('facility/saveCustomerLocations', parameters).then(() => {
      messages.channel('select-facility-customer-locations').action('saved').post(facility.facilityId);
      notification.action('success').post('Saved customer locations for {facilityName}'.interpolate(facility));
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });// in: UpdateFacilityCustomerLocationsDto, out: void
  }
}

export default SelectFacilityCustomerLocationsController;
