import React, { useMemo } from 'react';
import LoginService from '../services/LoginService';
import Roles from '../utility/Roles';
import Grid from '../../components/GridWithWidth';

const ContainerHistoyTable = (props) => {

  const canNavigateToInspection = (data) => {
    if (data.isComplete && LoginService.isInRole(Roles.Technician)) {
      return false;
    }
    return (LoginService.isTechnician && (data.facilityId === LoginService.loginInfo.facilityId));
  }

  const canNavigateToShippingTicketDetails = () => {
    return LoginService.isTechnician && !LoginService.isCustomer;
  }

  const data = props.value || [];

  const getColumnWidth = (accessor, headerText) => {
    const rows = data
    const maxWidth = 400
    const magicSpacing = 9
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    )
    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  const getClassName = (isDeleted) => {
    if (isDeleted) {
      return 'deleted-container';
    } return '';
  }

  const loadingMessage = props.isLoading && 'Loading Container History for asset';
  const noResultsMessage = !loadingMessage && !data.length && 'No results for the current asset';

  const columns = useMemo(
    () => [

      {
        Header: 'Location',
        accessor: 'facilityLocation',
        minWidth: '100px',
        disableSortBy: true,
        Cell: cellInfo => {
          const style = getClassName(cellInfo.row.original.isDeleted);
          return <div className={style}>{cellInfo.cell.value}</div>
        }
      },
      {
        Header: 'Functional Location',
        accessor: 'functionalLocation',
        minWidth: '110px',
        disableSortBy: true,
        Cell: cellInfo => {
          const style = getClassName(cellInfo.row.original.isDeleted);
          return <div className={style}> {cellInfo.row.original.functionalLocation}</div>
        }
      },
      {
        Header: 'Container Description',
        accessor: 'containerDescription',
        minWidth: getColumnWidth('UType', 'Type'),
        disableSortBy: true,
        Cell: cellInfo => {
          const style = getClassName(cellInfo.row.original.isDeleted);
          return <div className={style}>{cellInfo.row.original.containerDescription}</div>
        }
      },
      {
        Header: 'Date Added',
        accessor: 'dateAdded',
        minWidth: '110px',
        disableSortBy: true,
        Cell: cellInfo => {
          const style = getClassName(cellInfo.row.original.isDeleted);
          return <div className={style}>{cellInfo.row.original.dateAdded}</div>
        }
      },
      {
        Header: 'Date Removed',
        accessor: 'dateRemoved',
        minWidth: '110px',
        disableSortBy: true,
        Cell: cellInfo => {
          const style = getClassName(cellInfo.row.original.isDeleted);
          return <div className={style}>{cellInfo.row.original.dateRemoved}</div>
        }
      },
      {
        Header: 'Days',
        accessor: 'days',
        minWidth: getColumnWidth('daysInUse', 'Days'),
        disableSortBy: true,
        Cell: cellInfo => {
          const style = getClassName(cellInfo.row.original.isDeleted);
          return <div className={style}>{cellInfo.row.original.days}</div>
        }
      }
    ],
    []
  )
  return (
    <div id='container-history-table'>
      <Grid id='results-table' responsive={false} showPagination={false} columns={columns} data={data} noDataText={loadingMessage || noResultsMessage} />
    </div>
  );
}

export default ContainerHistoyTable;
