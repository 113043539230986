const Rights = {
    aptUser: '644E1B2A-77AC-4086-B9F1-AFD755456760',
    allowAPTOverride: '8D32CE4F-A8E5-4FFE-8F4D-E65E20A3E833',
    TecoUnTeco: '8D32DE8F-A8E5-5FFE-4F8D-E65E60A3E369',
    AssetCertificateApprover: '04F8753B-162F-4E53-9C4E-4C7CA3023806',
};

const hasSecurityToken = (loginInfo, securityToken) => {
    return (loginInfo.securityTokenIds.includes(securityToken));
}

const SecurityRights = {
    Rights: Rights,
    hasSecurityToken: hasSecurityToken
}

export default SecurityRights;
