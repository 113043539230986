import React, { useMemo } from 'react';
import Badge from '../../components/Badge';
import ActionIcon from '../../components/ActionIcon';
import Search from '../components/Search';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import SearchOptions from '../views/SearchOptions';

const ManDescTable = (props) => {

    const controller = props.controller;
    const data = props.items || [];
    const loadingMessage = controller.state.isLoading && 'Loading manufacturer description details';
    const noResultsMessage = !loadingMessage && !data.length && 'No manufacturer description available';
    const matchesLabel = props.badgeText;

    const handleRowClick = (item) => {
        if (item) {
            props.controller.selectManufacturerDescription(item);
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'action',
                disableSortBy: true,
                Cell: (cellInfo) => {
                    return <ActionIcon icon='fa-trash' className='remove-handle' size='1x' action={() => controller.removeManDesc(cellInfo.row.original)} />
                }
            },
            {
                Header: 'Mfg Part #',
                accessor: 'mfgItemNo',
                Cell: (cellInfo) => <Form.Link to={'/manufacturerdescription/' + cellInfo.row.original.manDescId} value={cellInfo.cell.value} />
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Service Levels',
                accessor: 'serviceLevelCount',
                Cell: cellInfo => <span className='text-center'>{cellInfo.cell.value}</span>
            },
        ],
        []
    )
    return (
        <div>
            <div className='d-flex justify-content-end'>
                <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
            </div>
            <div className='man-desc-table'>
                <Grid id='result-table' columns={columns} data={data} onRowClick={handleRowClick} showPagination={false} noDataText={loadingMessage || noResultsMessage} />
            </div>
        </div>
    );
}

const CustomerServiceLevelTable = (props) => {

    const controller = props.controller;
    const data = controller.state.customerServiceLevels || [];
    const noResultsMessage = !data.length && 'No customer service levels for the selected Manufacturer Description';
    const matchesLabel = data.length;

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'action',
                disableSortBy: true,
                Cell: (cellInfo) => {
                    return <ActionIcon icon='fa-trash' className='remove-handle' size='1x' action={() => controller.removeServiceLevel(controller)} />
                }
            },
            {
                Header: 'Customer',
                accessor: 'customerName',
            },
            {
                Header: 'Service Level',
                accessor: 'customerServiceLevelName',
                Cell: (cellInfo) => {
                    const isSAPServiceLevel = cellInfo.row.original.isSAPServiceLevel ? <span className='font-weight-bold text-danger'>&nbsp;SAP</span> : null;
                    return <span><Form.Link to={'/customerservicelevel/' + cellInfo.row.original.customerServiceLevelId} value={cellInfo.cell.value} />{isSAPServiceLevel}</span>
                }
            },
        ],
        []
    )
    return (
        <div>
            <div className='d-flex justify-content-end'>
                <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
            </div>
            <div className='cust-service-lvl-table'>
                <Grid id='result-table' columns={columns} data={data} showPagination={false} noDataText={noResultsMessage} />
            </div>
        </div>
    );
}

const FilterOptions = (props) => {

    const controller = props.controller;
    const binder = props.binder;

    return (
        <div className='row'>
            <div className='col-sm-6'>
                <Search className='mb-2' id='manDescFilterText' icon='fa fa-filter' prependLabelIcon='fa fa-filter' inputClassName='search-input text-uppercase' label='Enter filter text' placeholder='description' autoFocus='true' bind={binder.bind('manDescFilterText')} clear={controller.clearManDescFilterText} search={controller.applyManDescFilter}></Search>
            </div>
            <div className='col-md-6'>
                <SearchOptions
                    title='Filter Options'
                    binder={binder}
                    startsWithEnabled={false}
                    endsWithEnabled={false}
                    exactMatchEnabled={false}
                    showAllResultsEnabled={false}
                />
            </div>
        </div>
    );
}

const SelectedManDescTable = (props) => {

    return (
        <div className='row'>
            <div className='col-12'>
                <FilterOptions {...props} />
            </div>
            <div className='col-sm-12 mb-2' style={{ borderBottom: 'solid 3px lightgray' }} />
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-6'>
                        <ManDescTable              {...props} />
                    </div>
                    <div className='col-md-6'>
                        <CustomerServiceLevelTable {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectedManDescTable;
