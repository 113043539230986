import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import store from 'store';
import ActionIcon from '../../components/ActionIcon';
import Dialog from '../../components/Dialog';
import Jumbotron from '../../components/Jumbotron';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Table from '../../components/Table';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import CustomerController from '../controllers/CustomerController';
import PickPrototypeServiceLevelsController from '../controllers/PickPrototypeServiceLevelsController';
import PickPrototypeServiceLevelsDialog from '../dialogs/PickPrototypeServiceLevelsDialog';
import Roles from '../utility/Roles';
import GroupBadge from '../../components/GroupBadge';
import Search from '../components/Search';
import LoginService from '../services/LoginService';

const SearchHeader = (props) => {
    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.searchCustomers();
    }

    const handleMouseSearch = () => {
        props.controller.searchCustomers();
    }

    const clearSearch = () => {
        props.controller.clearServiceTextSearch();
    }
    const binder = props.binder;

    return (
        <div id='search-criteria' className='col-md-12'>
            <div class='row'>
                <div className='search-container col-md-8' onKeyDown={handleKeyboardSearch}>
                    <Search className='mb-2' id='searchText' inputClassName='search-input' label='Enter search text' placeholder='customer name' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
                </div>
            </div>
        </div>
    );
}

const ResultsList = (props) => {

    let history = useHistory();

    let jobAgeClasses = {};
    jobAgeClasses['never'] = 'age-never';
    jobAgeClasses['> 10 years'] = 'age-10yr';
    jobAgeClasses['> 5 years'] = 'age-5yr';
    jobAgeClasses['> 3 years'] = 'age-3yr';
    jobAgeClasses['> 1 year'] = 'age-1yr';
    jobAgeClasses['> 6 months'] = 'age-6mos';
    jobAgeClasses['> 3 months'] = 'age-3mos';
    jobAgeClasses['1 – 3 months'] = 'age-recent';
    jobAgeClasses['within the last month'] = 'age-this-month';
    jobAgeClasses['future?'] = 'age-future';

    const editCustomer = (evt, customer) => {
        history.push('/customers/' + customer.customerId);
    }

    const getJobAgeClass = (jobAge) => {
        return jobAgeClasses[jobAge];
    }

    const getLastJobAge = (jobDate) => {
        var date = DateTimeFormatter.formatDate(jobDate);
        if (!date) {
            return 'never';
        }

        var now = DateTimeFormatter.now();
        var durationDays = ((now - DateTimeFormatter.getMoment(jobDate)) / 1000 / 3600 / 24); // subtracting 2 dates yields number of mSec
        // > 10 years
        if ((durationDays / 365) > 10) {
            return '> 10 years';
        }
        // > 5 years
        if ((durationDays / 365) > 5) {
            return '> 5 years';
        }
        // > 3 years
        if ((durationDays / 365) > 3) {
            return '> 3 years';
        }
        // > 1 year
        if ((durationDays / 365) > 1) {
            return '> 1 year';
        }
        // > 6 months
        if ((durationDays / 365) > 0.5) {
            return '> 6 months';
        }
        // > 3 months
        if ((durationDays / 365) > 0.25) {
            return '> 3 months';
        }
        // 1 - 3 months
        if (durationDays > 30) {
            return '1 - 3 months';
        }
        // within the last month
        if (durationDays > 0) {
            return 'within the last month';
        }
        return 'future?';
    }

    const handleRowClick = (data) => {
        props.controller.loadCustomerDetails(data);
    }
    const controller = props.controller;
    const data = controller.state.searchResults || [];
    const loadingMessage = controller.state.isLoading && 'Loading customers that meet the search criteria defined above';
    const noResultsMessage = !loadingMessage && !data.length && 'No customers meet the search criteria defined above';
    const displayMessage = noResultsMessage || loadingMessage;
    const matchesCount = controller.state.searchResults.length;
    const matchesLabel = Utils.pluralize('match', 'es', matchesCount);
    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'archived',
                Cell: cellInfo => cellInfo.cell.value
                    ? <span className='status-column archived'><i className='fa fa-archive' title='Customer is archived' /></span>
                    : <span />
            },
            {
                Header: 'Customer',
                accessor: 'customerName',
                Cell: cellInfo => {
                    let rowClassName = '';
                    const isPrototypeCustomer = cellInfo.row.original && cellInfo.cell.value === 'Prototype Customer Service Levels';
                    if (isPrototypeCustomer) {
                        rowClassName += ' archived';
                    }
                    return <span className={rowClassName}>{cellInfo.cell.value}</span>
                }
            },
            {
                Header: 'Last Job Date',
                accessor: 'lastJobDate',
                Cell: cellInfo => {
                    let rowClassName = '';
                    const isPrototypeCustomer = cellInfo.row.original && cellInfo.row.original.customerName === 'Prototype Customer Service Levels';
                    if (isPrototypeCustomer) {
                        rowClassName += ' archived';
                    }
                    return <span className={rowClassName}>{DateTimeFormatter.formatDateTime(cellInfo.cell.value)}</span>
                }
            },
            {
                Header: 'Last Job Age',
                accessor: '',
                Cell: (cellInfo) => {
                    const lastJobAge = getLastJobAge(cellInfo.row.original.lastJobDate);
                    const jobAgeClass = getJobAgeClass(lastJobAge);
                    return <div className={jobAgeClass}>{lastJobAge}</div>
                }
            }
        ],
        []
    )
    return (
        <div className='col-md-12'>
            <div className='d-flex justify-content-end'>
                <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
            </div>
            <div className='customer-search-result'>
                <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText={displayMessage} enableDefaultRowSelect={true} />
            </div>
        </div>
    );
}

const CustomerServiceLevelRow = (props) => {

    let history = useHistory();

    const handleEdit = (customer, customerServiceLevel) => {
        const data = {
            customerServiceLevelId: customerServiceLevel.customerServiceLevelId,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-customer-service-level', data);
        history.push('/customerservicelevel/' + customerServiceLevel.customerServiceLevelId);
    }
    const customer = props.customer;
    const serviceLevel = props.serviceLevel;
    let statusClassName = 'status-column';
    if (!serviceLevel.active) {
        statusClassName += ' archived';
    }
    const isSAPServiceLevel = serviceLevel.isSAPServiceLevel ? <span className='sap-service-level'>&nbsp;SAP</span> : null;
    const activeIcon = serviceLevel.active ? null : <i className='fa fa-archive' title='Service Level is inactive' />;
    const hierarchyBadge = serviceLevel.hierarchyLevel > 0 ? <Badge variant='info' title='Hierarchy Level'>{serviceLevel.hierarchyLevel}</Badge> : null;
    const testCountBadge = <Badge title='Number of tests' variant='info' className='pull-right'>{serviceLevel.testCount}</Badge>;

    return (
        <Table.Row>
            <Table.Data className={statusClassName}>{activeIcon}</Table.Data>
            <Table.Data className='status-column'>{hierarchyBadge}</Table.Data>
            <Table.Data><span className='d-inline-flex'><Form.Link onClick={() => { handleEdit(customer, serviceLevel); }} value={serviceLevel.customerServiceLevelName} />{isSAPServiceLevel}</span></Table.Data>
            <Table.Data>{testCountBadge}</Table.Data>
        </Table.Row>
    );
}

const CustomerServiceLevels = (props) => {

    let history = useHistory();

    const handleAddUsingPrototype = (customer) => {
        co(function* () {
            const dialogController = new PickPrototypeServiceLevelsController(customer);
            yield Dialog.showDialogWaitForResult(<PickPrototypeServiceLevelsDialog controller={dialogController} header='Pick Prototype Service Levels' />);
        });
    }

    const handleAdd = (customer) => {
        const data = {
            customerServiceLevelId: Utils.emptyGuid,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-customer-service-level', data);
        history.push('/customerservicelevel/' + Utils.emptyGuid);
    }

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.filterCustomerServiceLevels();
    }

    const handleMouseSearch = () => {
        props.controller.filterCustomerServiceLevels();
    }

    const clearSearch = () => {
        props.controller.clearServiceLevelSearch();
    }
    const binder = props.binder;
    const controller = props.controller;
    const customer = controller.state.selectedCustomer;
    const isPrototypeCustomer = customer && customer.customerName === 'Prototype Customer Service Levels';
    const serviceLevels = controller.state.filteredServiceLevels.map(serviceLevel => <CustomerServiceLevelRow key={serviceLevel.customerServiceLevelId} customer={customer} serviceLevel={serviceLevel} />);
    const count = serviceLevels.length;
    const canAdd = customer;
    const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data colSpan='4' className='loading'>Loading ...</Table.Data></Table.Row>;
    let noResultsMessage = null;
    const isSuperUser = LoginService.isInRole(Roles.SuperUser);
    if (!loadingMessage && !count) {
        if (controller.state.serviceLevelText) {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='4' className='no-search-results'>No service levels match the search criteria</Table.Data>
            </Table.Row>;
        }
        else {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='4' className='no-search-results'>No service levels for this customer</Table.Data>
            </Table.Row>;
        }
    }

    return (
        <div className='col-md-12'>
            <div className='no-gutters'>
                <div className='col-md-12' onKeyDown={handleKeyboardSearch}>
                    <span >
                        <Form.Input id='searchServiceLevelText' className='search-input' placeholder='service level name' bind={binder.bind('searchServiceLevelText')} />
                        <ActionIcon icon='fa-times-circle' className='clearable' action={clearSearch} />
                        <Button icon='fa-filter' variant='primary' size='sm' className='btn-search-icon' onClick={handleMouseSearch} />
                    </span>
                </div>
                <div className='form-group col-md-12 table-container'>
                    <Table id='locations-table' responsive='false'>
                        <Table.Header>
                            <Table.Row>
                                <Table.Head class='bg-primary' colSpan={4}>
                                    <div className='d-flex justify-content-between'>
                                        <div><i className='fa fa-certificate' />&nbsp;Service Levels&nbsp;</div>
                                        <GroupBadge icon='fa-plus-circle' variant='success' pill={false} disabled={(!canAdd || isSuperUser)} className='' onClick={() => { handleAdd(customer); }}>New ...</GroupBadge>
                                        &nbsp;&nbsp;
                                        {!isPrototypeCustomer && <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={!canAdd} className='' onClick={() => { handleAddUsingPrototype(customer); }}>Pick Prototype ...</GroupBadge>}
                                        <div><Badge variant='info' title='Number of Customer Service Levels' className='pull-right'>{count}</Badge></div>
                                    </div>
                                </Table.Head>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {serviceLevels}
                            {loadingMessage}
                            {noResultsMessage}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    );
}



const CustomerAssemblyLevelRow = (props) =>{
    let history = useHistory();

    const handleEdit = (customer, assemblyLevel) => {
        const data = {
            customerAssemblyLevelId:assemblyLevel.customerAssemblyLevelId ,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-customer-assembly-level', data);        
       history.push('/customerAssemblylevel/' + data.customerAssemblyLevelId);
    }
    const customer = props.customer;
    const assemblyLevel = props.assemblyLevel;
    let statusClassName = 'status-column';
    if (!assemblyLevel.active) {
        statusClassName += ' archived';
    }
    const isSAPAssemblyLevel = assemblyLevel.isSAPAssemblyLevel ? <span className='sap-service-level'>&nbsp;SAP</span> : null;
    const activeIcon = assemblyLevel.active ? null : <i className='fa fa-archive' title='Assembly Level is inactive' />;
   
    return (
        <Table.Row>
            <Table.Data className={statusClassName}>{activeIcon}</Table.Data>
            <Table.Data><span className='d-inline-flex'><Form.Link onClick={() => { handleEdit(customer, assemblyLevel); }} value={assemblyLevel.customerAssemblyLevelName} />{isSAPAssemblyLevel}</span></Table.Data>
            <Table.Data></Table.Data>
        </Table.Row>
    );
}
const CustomerAssemblyLevels = (props) => {

    let history = useHistory();

       const handleAddAssemblyLevel = (customer) => {
        const data = {
            customerAssemblyLevelId: Utils.emptyGuid,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-customer-assembly-level', data);
      
       history.push('/customerAssemblylevel/' + data.customerAssemblyLevelId);
    }

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.filterCustomerAssemblyLevels();
    }

    const handleMouseSearch = () => {
        props.controller.filterCustomerAssemblyLevels();
    }

    const clearSearch = () => {
        props.controller.clearAssemblyLevelSearch();
    }
    const binder = props.binder;
    const controller = props.controller;
    
    const customer = controller.state.selectedCustomer;
    const isPrototypeCustomer = customer && customer.customerName === 'Prototype Customer Service Levels';
    const assemblyLevels = controller.state.filteredassemblylevels.map(assemblyLevels => <CustomerAssemblyLevelRow key={assemblyLevels.customerAssemblyLevelId} customer={customer} assemblyLevel={assemblyLevels} />);
    const count = assemblyLevels.length;
    
    const canAdd = customer;
    const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data colSpan='4' className='loading'>Loading ...</Table.Data></Table.Row>;
    let noResultsMessage = null;
    const isSuperUser = LoginService.isInRole(Roles.SuperUser);
    if (!loadingMessage && !count) {
        if (controller.state.searchAssemblyLevelText) {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='4' className='no-search-results'>No assembly levels match the search criteria</Table.Data>
            </Table.Row>;
        }
        else {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='4' className='no-search-results'>No assembly levels for this customer</Table.Data>
            </Table.Row>;
        }
    }

    return (
        <div className='col-md-12'>
            <div className='no-gutters'>
                <div className='col-md-12' onKeyDown={handleKeyboardSearch}>
                    <span >
                        <Form.Input id='searchAssemblyLevelText' className='search-input' placeholder='assembly level name' bind={binder.bind('searchAssemblyLevelText')} />
                        <ActionIcon icon='fa-times-circle' className='clearable' action={clearSearch} />
                        <Button icon='fa-filter' variant='primary' size='sm' className='btn-search-icon' onClick={handleMouseSearch} />
                    </span>
                </div>
                <div className='form-group col-md-12 table-container'>
                    <Table id='locations-table' responsive='false'>
                        <Table.Header>
                            <Table.Row>
                                <Table.Head class='bg-primary' colSpan={4}>
                                    <div className='d-flex justify-content-between'>
                                        <div><i className='fa fa-certificate' />&nbsp;Assembly Levels&nbsp;</div>
                                        <GroupBadge icon='fa-plus-circle' variant='success' pill={false} disabled={(!canAdd || isSuperUser)} className='' onClick={()=>{handleAddAssemblyLevel(customer)}}>New ...</GroupBadge>
                                        &nbsp;&nbsp;
                                        {!isPrototypeCustomer && <GroupBadge  variant='success' pill={false} icon='fa-plus-circle' disabled={true} className='' onClick={() => { }}>Pick Prototype ...</GroupBadge>}
                                        <div><Badge variant='info' title='Number of Customer Assembly Levels' className='pull-right'>{count}</Badge></div>
                                    </div>
                                </Table.Head>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {assemblyLevels}
                            {loadingMessage}
                            {noResultsMessage}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    );
}
const CustomerLocationRow = (props) => {

    let history = useHistory();

    const handleEdit = (customer, location) => {
        const data = {
            customerLocationId: location.id,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-customer-location', data);
        history.push('/customerlocations/' + location.id);
    }
    const customer = props.customer;
    const location = props.location;
    let statusClassName = 'status-column';
    if (location.archived) {
        statusClassName += ' archived';
    }
    const spanFMCLocation = location.fmcLocation
        ? <span className='fmc-location'>&nbsp;&nbsp;TechnipFMC</span>
        : null;

    const statusCell = (location.archived)
        ? <Table.Data className={statusClassName}><i className='fa fa-archive' title='Location is archived' /></Table.Data>
        : <Table.Data className='status-column' />;

    const locationValue = <span>{location.name}{spanFMCLocation}</span>
    return (
        <Table.Row>
            {statusCell}
            <Table.Data><Form.Link onClick={() => { handleEdit(customer, location); }} value={locationValue} /></Table.Data>
        </Table.Row>
    );
}

const CustomerLocations = (props) => {

    let history = useHistory();

    const handleAdd = (customer) => {
        const data = {
            customerLocationId: Utils.emptyGuid,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-customer-location', data);
        history.push('/customerlocations/' + Utils.emptyGuid);
    }

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.filterCustomerLocations();
    }

    const handleMouseSearch = () => {
        props.controller.filterCustomerLocations();
    }

    const clearSearch = () => {
        props.controller.clearLocationSearch();
    }
    const binder = props.binder;
    const controller = props.controller;
    const customer = controller.state.selectedCustomer;
    const locations = controller.state.filteredCustomerLocations.map(location => <CustomerLocationRow key={location.id} customer={customer} location={location} />);
    const count = locations.length;
    const colSpan = 3;
    const canAdd = customer;
    const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data colSpan='4' className='loading'>Loading ...</Table.Data></Table.Row>;
    let noResultsMessage = null;
    if (!loadingMessage && !count) {
        if (controller.state.searchLocationText) {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan={colSpan} className='no-search-results'>No locations match the search criteria</Table.Data>
            </Table.Row>;
        }
        else {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan={colSpan} className='no-search-results'>No locations for this customer</Table.Data>
            </Table.Row>;
        }
    }

    return (
        <div className='col-md-12' >
            <div className='no-gutters'>
                <div className='col-md-12' onKeyDown={handleKeyboardSearch}>
                    <span>
                        <Form.Input style={{ fontSize: '1.5rem', height: '32px', marginBottom: '-10px' }} id='searchLocationText' className='search-input' placeholder='location name' bind={binder.bind('searchLocationText')} />
                        <ActionIcon icon='fa-times-circle' className='clearable' action={clearSearch} />
                        <Button icon='fa-filter' variant='primary' size='sm' className='btn-search-icon' onClick={handleMouseSearch} />
                    </span>
                </div>
                <div className='form-group col-md-12 table-container'>
                    <Table id='locations-table'>
                        <Table.Header>
                            <Table.Row>
                                <Table.Head colSpan={2}>
                                    <div className='d-flex justify-content-between'>
                                        <div><i className='fa fa-globe' /> &nbsp;&nbsp;Locations&nbsp;&nbsp;</div>
                                        <div><GroupBadge icon='fa-plus-circle' variant='success' pill={false} disabled={!canAdd} className='' onClick={() => { handleAdd(customer); }}>New ...</GroupBadge></div>
                                        <div><Badge variant='info' title='Number of Customer Locations' className='pull-right'>{count}</Badge></div>
                                    </div>
                                </Table.Head>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {locations}
                            {loadingMessage}
                            {noResultsMessage}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    );
};

const CustomerUserRow = (props) => {

    let history = useHistory();

    const handleEdit = (customer, user) => {
        const data = {
            userId: user.id,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-user', data);
        history.push('/users/' + user.id);
    }

    const customer = props.customer;
    const user = props.user;
    let statusClassName = 'status-column';
    if (!user.loginEnabled) {
        statusClassName += ' archived';
    }

    const statusCell = (user.loginEnabled)
        ? <Table.Data className='status-column' />
        : <Table.Data className={statusClassName}><i className='fa fa-ban' title="User's login is disabled" /></Table.Data>;

    const roleClassName = Roles.getRoleClassName(user.roleName);

    return (
        <Table.Row>
            {statusCell}
            <Table.Data> <Form.Link onClick={() => { handleEdit(customer, user); }} value={user.name} /></Table.Data>
            <Table.Data>{user.loginName}</Table.Data>
            <Table.Data><span className={roleClassName}>{user.roleName}</span></Table.Data>
        </Table.Row>
    );
}

const CustomerUsers = (props) => {

    let history = useHistory();

    const handleAdd = (customer) => {
        const data = {
            userId: Utils.emptyGuid,
            customerId: customer.customerId,
            customerName: customer.customerName
        };
        store.set('edit-user', data);
        history.push('/users/' + Utils.emptyGuid);
    }

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.filterCustomerUsers();
    }

    const handleMouseSearch = () => {
        props.controller.filterCustomerUsers();
    }

    const clearSearch = () => {
        props.controller.clearUserSearch();
    }

    const binder = props.binder;
    const controller = props.controller;
    const customer = controller.state.selectedCustomer;
    const canAdd = customer;
    const users = controller.state.filteredCustomerUsers.map(user => <CustomerUserRow key={user.id} customer={customer} user={user} />);
    const count = users.length;
    const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data colSpan='4' className='loading'>Loading ...</Table.Data></Table.Row>;
    let noResultsMessage = null;
    if (!loadingMessage && !count) {
        if (controller.state.searchUserText) {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='2' className=''>No users match the search criteria</Table.Data>
            </Table.Row>;
        }
        else {
            noResultsMessage = <Table.Row>
                <Table.Data colSpan='2' className=''>No users for this customer</Table.Data>
            </Table.Row>;
        }
    }

    var styleCheckBox = {
        height: '16px',
        width: '16px',
        marginLeft: '8px !important',
        verticalAlign: 'text-bottom'
    };

    return (
        <div className='col-md-12'>
            <div className='no-gutters'>
                <div className='col-md-12' onKeyDown={handleKeyboardSearch}>
                    <span>
                        <Form.Input style={{ fontSize: '1.5rem', height: '32px', marginBottom: '-10px' }} id='searchUserText' className='search-input' placeholder='user name' bind={binder.bind('searchUserText')} />
                        <ActionIcon icon='fa-times-circle' className='clearable' action={clearSearch} />
                        <Button icon='fa-filter' variant='primary' size='sm' className='btn-search-icon' onClick={handleMouseSearch} />
                    </span>
                </div>
                <div className='form-group col-md-12 table-container'>
                    <Table id='users-table' >
                        <Table.Header>
                            <Table.Row>
                                <Table.Head colSpan={4} className='sorting' role='columnheader'>
                                    <div className='d-flex justify-content-between'>
                                        <div><i className='fa fa-users' />&nbsp;&nbsp;Users&nbsp;&nbsp;</div>
                                        <div><GroupBadge icon='fa-plus-circle' variant='success' pill={false} disabled={!canAdd} className='' onClick={() => { handleAdd(customer); }}>New ...</GroupBadge></div>
                                        <div className='d-inline-flex show-all-result'><Form.CheckBox className='mr-2' style={styleCheckBox} enabled={canAdd} disabled={!canAdd} bind={binder.bind('searchEnabledUsersOnly')} /><Form.Label name='Enabled only' /></div>
                                        <div><Badge variant='info' title='Number of Customer Users' className='count-badge pull-right'>{count}</Badge></div>
                                    </div>
                                </Table.Head>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {users}
                            {loadingMessage}
                            {noResultsMessage}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    );
}

const Details = (props) => {
    const binder = props.binder;
    const controller = props.controller;
    const selectedCustomer = controller.state.selectedCustomer;
    const isPrototypeCustomer = selectedCustomer && selectedCustomer.customerName === 'Prototype Customer Service Levels';

    return (
        <Jumbotron {...props} id='customer-details' className='form-group col-md-12'>
            <div className='row'>
                <div className='col-md-12'><Form.StaticData label='Customer' value={selectedCustomer.customerName} /></div>
                <div className='col-md-6' ><Form.StaticData label='Is Revenue Generating' value={Utils.formatBool(selectedCustomer.isRevenueGenerating)} /></div>
                <div className='col-md-12'><Form.StaticData label='Notes' value={selectedCustomer.notes} /></div>
                <CustomerServiceLevels controller={controller} binder={binder} />
                <CustomerAssemblyLevels controller={controller} binder={binder} />
                {!isPrototypeCustomer && <CustomerLocations controller={controller} binder={binder} />}
                {!isPrototypeCustomer && <CustomerUsers controller={controller} binder={binder} />}
            </div>
        </Jumbotron>
    );
}

const Buttons = (props) => {

    const handleComplete = () => {
        props.controller.complete();
    }

    const controller = props.controller;
    const customer = controller.state.selectedCustomer;
    const isSuperUser = LoginService.isInRole(Roles.SuperUser);
    const canEdit = customer;
    const isTechnipFMCDrillingCompletionsCustomer = customer && customer.customerName === 'TechnipFMC Drilling & Completions';

    return (
        <div id='customer-buttons'>
            <Button icon='fas fa-pencil-alt' disabled={!canEdit} to={'/customers/' + customer.customerId} variant='primary' size='sm' className='action-button m-1'>Edit ...</Button>
            <Button icon='fa-plus-circle' to={'/customers/' + Utils.emptyGuid} variant='success' size='sm' className='action-button m-1' disabled={isSuperUser}>Create New ...</Button>
            <Button icon='fa-cog' disabled={!isTechnipFMCDrillingCompletionsCustomer} to={'/configurecustomer/' + customer.customerId} variant='primary' size='sm' className='action-button m-1'>Configure ...</Button>
        </div>
    );
}

const CustomerPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-briefcase' />

class CustomerPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();
    }

    static defaultProps = {
        controller: new CustomerController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Page {...this.props} pageTitle='InteServ · Customers' id='customer-page'>
                <CustomerPageHeader pageTitle={'Customers'} />
                <Page.Content>
                    <div className='row'>
                        <div {...this.props} className='col-md-8'>
                            <div className='row'>
                                <SearchHeader controller={controller} binder={binder} />
                                <ResultsList controller={controller} binder={binder} />
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Buttons controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <Details controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
};

export default CustomerPage;
