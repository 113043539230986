import $ from 'jquery';
import React from 'react';
import Router from 'react-router-dom';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Shortcut from '../../components/Shortcut';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Table from '../../components/Table'
import Utils from '../utility/Utils';
import QuotesDetailController from '../controllers/QuotesDetailController';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Quote = (props) => {
    const quote = props.quote;
    const userName = quote && quote.Name;
    const roleName = quote && quote.RoleName;
    const baseDateTime = props.baseDateTime;
    const dateAdded = quote.dateAdded;
    const quoteDateTime = DateTimeFormatter.formatFullDateTime(dateAdded) + ' UTC';
    let quoteAge;
    if (baseDateTime === dateAdded) {
        quoteAge = '';
    }
    else {
        const elapsedSeconds = DateTimeFormatter.getMoment(dateAdded).diff(DateTimeFormatter.getMoment(baseDateTime), 'seconds');
        if (elapsedSeconds === 0) {
            quoteAge = '';
        }
        else if (elapsedSeconds < 60) {
            quoteAge = 'a few seconds later';
        }
        else {
            quoteAge = DateTimeFormatter.formatDuration(elapsedSeconds / 60, false) + ' later';
        }
    }

    // var isLoggedInUser = LoginService.isLoggedInUser(quote.user && quote.user.id);
    const classUserName = 'note-user user-other';
    const classRoleName = 'user-role user-' + roleName.toLowerCase();
    const classDate = 'note-date';
    const classText = 'quote-text note-other-user';
    const text = <div className={classText}>
        <table>
            <Table.Row>
                <Table.Data>
                    Quotation No:
                              </Table.Data>
                <Table.Data>
                    &nbsp;&nbsp;<span className='quote-value'>{quote.QuotationNo}</span>
                </Table.Data>
            </Table.Row>
            <Table.Row>
                <Table.Data>
                    Total Weight:
                              </Table.Data>
                <Table.Data>
                    &nbsp;&nbsp;<span className='quote-value'>{quote.NetWeight}</span><span className='quote-unit'>{quote.WeightUnit}</span>
                </Table.Data>
            </Table.Row>
            <Table.Row>
                <Table.Data>
                    Net Value (Material):
                              </Table.Data>
                <Table.Data>
                    &nbsp;&nbsp;<span className='quote-value'>{quote.MaterialNet}</span><span className='quote-unit'>{quote.Currency}</span>
                </Table.Data>
            </Table.Row>
            <Table.Row>
                <Table.Data>
                    Net Value (Service):
                              </Table.Data>
                <Table.Data>
                    &nbsp;&nbsp;<span className='quote-value'>{quote.ServiceNet}</span><span className='quote-unit'>{quote.Currency}</span>
                </Table.Data>
            </Table.Row>
            <Table.Row>
                <Table.Data>
                    Tax (Material):
                              </Table.Data>
                <Table.Data>
                    &nbsp;&nbsp;<span className='quote-value'>{quote.MaterialTax}</span><span className='quote-unit'>{quote.Currency}</span>
                </Table.Data>
            </Table.Row>
            <Table.Row>
                <Table.Data>
                    Tax (Service):
                              </Table.Data>
                <Table.Data>
                    &nbsp;&nbsp;<span className='quote-value'>{quote.ServiceTax}</span><span className='quote-unit'>{quote.Currency}</span>
                </Table.Data>
            </Table.Row>
            <Table.Row>
                <Table.Data>
                    Total Value:
                              </Table.Data>
                <Table.Data>
                    &nbsp;&nbsp;<span className='quote-value'>{quote.TotalVal}</span><span className='quote-unit'>{quote.Currency}</span>
                </Table.Data>
            </Table.Row>
        </table>
    </div>
    return (
        <div className='row col-md-12 note-element'>
            <div className='col-md-2' style={{ cursor: 'pointer' }} title={DateTimeFormatter.formatFullDateTime(dateAdded) + ' UTC'}>
                <div className={classUserName}>{userName}</div>
                <div className={classRoleName}>{roleName}</div>
                <div className={classDate}>{quoteDateTime}</div>
                <div className={classDate}>{quoteAge}</div>
            </div>
            <div className='col-md-10'>{text}</div>
        </div>
    );
}

class Quotes extends React.Component {
    componenDidMount = () => {
        const stream = this.props.controller.flash.delay(0);
        stream.onValue(this.flash);
        this.dispose = () => stream.offValue(this.flash);
    }

    flash = (noteId) => {
        const notes = $('#all-notes').find('#' + noteId + ' .quote-text');
        notes.velocity({ backgroundColor: '#FFD1B2' }, { duration: 600 })
            .velocity('reverse');
    }

    render = () => {
        const data = this.props.quotes || [];
        const baseDateTime = data.length && data[0].dateAdded;
        const noQuotes = (data.length === 0) ? <div className='col-sm-12 help-block'>No Quotes have been created yet</div> : '';
        const quotes = data.map((quote, id) => quote.Type == 0
            ? <CSSTransition><Quote key={id} quote={quote} baseDateTime={baseDateTime} /></CSSTransition>
            : <CSSTransition><Note key={quote.noteId} note={quote} baseDateTime={baseDateTime} /></CSSTransition>);
        const displayQuotes = data.length === 0
            ? noQuotes
            : quotes

        return (
            <div className='row'>
                <TransitionGroup component='div' transitionName='fade' transitionEnterTimeout={300} transitionLeaveTimeout={200} id='all-notes' className='col-sm-12'>
                    {displayQuotes}
                </TransitionGroup>
            </div>
        );
    }
}

const Note = (props) => {
    const note = props.note;
    const user = note.user;
    const userName = user && user.name;
    const roleName = note && note.roleName;
    const baseDateTime = props.baseDateTime;
    const dateAdded = note.dateAdded;
    const noteDateTime = DateTimeFormatter.formatFullDateTime(dateAdded) + ' UTC';
    let noteAge;
    if (baseDateTime === dateAdded) {
        noteAge = '';
    }
    else {
        const elapsedSeconds = DateTimeFormatter.getMoment(dateAdded).diff(DateTimeFormatter.getMoment(baseDateTime), 'seconds');
        if (elapsedSeconds === 0) {
            noteAge = '';
        }
        else if (elapsedSeconds < 60) {
            noteAge = 'a few seconds later';
        }
        else {
            noteAge = DateTimeFormatter.formatDuration(elapsedSeconds / 60, false) + ' later';
        }
    }

    const isLoggedInUser = LoginService.isLoggedInUser(note.user && note.user.id);
    const classUserName = isLoggedInUser
        ? 'note-user user-current'
        : 'note-user user-other';
    const classRoleName = 'user-role user-' + roleName.toLowerCase();
    const classDate = 'note-date';
    const noQuoteCreated = note.text.includes('Quotation didn\'t created for Job');
    const classText = noQuoteCreated ? 'note-text quotes-not-created' : isLoggedInUser
        ? 'note-text note-current-user'
        : 'note-text note-other-user';
    const text = <div className={classText}>{Utils.makeParagraphElements(note.text)}</div>;

    return (
        <div className='row col-md-12 note-element'>
            <div className='col-md-2' style={{ cursor: 'pointer' }} title={DateTimeFormatter.formatFullDateTime(dateAdded) + ' UTC'}>
                <div className={classUserName}>{userName}</div>
                <div className={classRoleName}>{roleName}</div>
                <div className={classDate}>{noteDateTime}</div>
                <div className={classDate}>{noteAge}</div>
            </div>
            <div className='col-md-10'>{text}</div>
        </div>
    );
}

class Notes extends React.Component {
    componentDidMount = () => {
        const stream = this.props.controller.flash.delay(0);
        stream.onValue(this.flash);
        this.dispose = () => stream.offValue(this.flash);
    }

    flash = (noteId) => {
        const notes = $('#all-notes').find('#' + noteId + ' .note-text');
        notes.velocity({ backgroundColor: '#FFD1B2' }, { duration: 600 })
            .velocity('reverse');
    }

    render() {
        const data = this.props.notes || [];
        const baseDateTime = data.length && data[0].dateAdded;
        const noNotes = (data.length === 0) ? <div className='col-sm-12 help-block'>No Override notes have been created yet</div> : null;
        const notes = data.map(note => <Note key={note.noteId} note={note} baseDateTime={baseDateTime} />);

        return (
            <div className='row'>
                <TransitionGroup component='div' transitionName='fade' transitionEnterTimeout={300} transitionLeaveTimeout={200} id='all-notes' className='col-sm-12'>
                    {noNotes}
                    {notes}
                </TransitionGroup>
            </div>
        );
    }
}

const QuotesPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class QuotesDetailPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        const jobId = this.props.match.params.jobId;
        if (!jobId) {
            this.props.history.goBack();
        }
        this.props.controller.load(jobId);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandJobNotes();
    }

    static defaultProps = {
        controller: new QuotesDetailController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const jobNumber = this.props.match.params.jobNumber;
        const title = controller.getTitle(jobNumber);
        const quoteNotes = controller.state.quoteNotes;
        const notes = controller.state.notes;
        return (
            <Page {...this.props} pageTitle='InteServ · Quote Detail' id='quote-page'>
                <QuotesPageHeader pageTitle={title} />
                <Page.Content>
                    <div className='row'>
                        <div id='quotes-page' className='notes-page-content col-12'>
                            <Quotes controller={controller} binder={binder} quotes={quoteNotes} />
                            {/* <Notes controller={controller} binder={binder} notes={notes} /> */}
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default QuotesDetailPage;