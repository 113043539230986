import $ from 'jquery';
import React from 'react';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Dialog from '../../components/Dialog';
import co from '../../lib/Co';
import Utils from '../utility/Utils';

class CustomerDescriptionController extends BaseController {
  constructor() {
    super('cust-desc', CustomerDescriptionController.getInitialState(''));

    var self = this;
    messages.channel('edit-cust-desc').action('saved').subscribe(custDesc => {
      var custDescs = self.state.searchResults.filter(result => result.custDescId === custDesc.custDescId);
      if ((custDescs === null) || !custDescs.length) {
        return;
      }

      const md = custDescs[0];
        ajax.get('lookup/getCustomerDescription/', { custDescId: custDesc.custDescId }).then(updatedCustDesc=>{
          $.extend(md, updatedCustDesc);
          this.commit();
        }).then(result => result).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // in: Guid, out: CustomerDescriptionDto
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state.customer       = '';
        this.state.familyCode     = '';
        this.state.manufacturer   = '';
        this.state.nominalSize    = '';
        this.state.cwp            = '';
        this.state.degree         = '';
        this.state.id             = '';
        this.state.od             = '';
        this.state.length         = '';
        this.state.style          = '';
        this.state.service        = '';
        this.state.mfgItemNo      = '';
        this.state.searchText     = '';
        this.state.selectedResult = '';
        this.state.searchResults  = [];
      }
    });
  }

  customerChanged = () => {
    this.state.searchResults = [];
    this.state.totalCount    = 0;

    if (!this.state.customer || !this.state.customer.id) {
      return;
    }
    this.loadCount(this.state.customer.id);
  }

  loadCount = (customerId) => {
      ajax.get('lookup/getCustomerDescriptionCount', {customerId : customerId}).then(result=>{
        this.state.totalCount = result;
        this.commit();
      }).then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // int
  }

  static getInitialState = () => {
    return {
      custDescId       : '',
      manDescId        : '',
      customer         : '',
      familyCode       : '',
      manufacturer     : '',
      nominalSize      : '',
      cwp              : '',
      degree           : '',
      id               : '',
      od               : '',
      length           : '',
      style            : '',
      service          : '',
      typeApproval     : '',
      tempRange        : '',
      loadRating       : '',
      mfgItemNo        : '',
      description      : '',
      hasDiagram       : false,
      custCWP          : 0,
      custTestPressure : 0,
      assetCount       : 0,
      searchText       : '',
      searchResults    : [],
      selectedResult   : ''
    };
  }

  customerSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  mfgItemSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Mfg%20Item%20%23', { searchTerm: searchTerm })
    .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  search = (searchType) => {
    if (!searchType) {
      return null;
    }

    return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), { searchTerm: searchTerm })
    .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  selectItem = (item) => {
    this.state.selectedResult = item;
    this.commit();
  }

  setMfgItemNoDisplayValue = (result) => {
    const mfgItemNo = result.mfgItemNo && result.mfgItemNo.name;
    result.mfgItemNoDisplayValue = (!mfgItemNo || mfgItemNo.startsWith('N/A')) ? '' : mfgItemNo;
  }

  clearSearch = () => {
    $('#searchText').val('');
    $('#searchText').focus();
  }

  clearSearchCriteria = () => {
    this.clearSearch();
    this.state.customer     = '';
    this.state.familyCode   = '';
    this.state.manufacturer = '';
    this.state.nominalSize  = '';
    this.state.cwp          = '';
    this.state.degree       = '';
    this.state.id           = '';
    this.state.od           = '';
    this.state.length       = '';
    this.state.style        = '';
    this.state.service      = '';
    this.state.mfgItemNo    = '';
    this.state.totalCount   = 0;
    this.commit();
  }

  handleSearch = () => {
    const customerId = this.state.customer && this.state.customer.id;
    if (!customerId) {
      notification.action('warning').post('Please select a Customer first', 'Customer not specified');
      return;
    }
      const parameters = {
        customerId      : customerId,
        familyCodeId    : this.state.familyCode    ? this.state.familyCode.id    : Utils.emptyGuid,
        lengthId        : this.state.length        ? this.state.length.id        : Utils.emptyGuid,
        cwpId           : this.state.cwp           ? this.state.cwp.id           : Utils.emptyGuid,
        styleId         : this.state.style         ? this.state.style.id         : Utils.emptyGuid,
        nominalSizeId   : this.state.nominalSize   ? this.state.nominalSize.id   : Utils.emptyGuid,
        manufacturerId  : this.state.manufacturer  ? this.state.manufacturer.id  : Utils.emptyGuid,
        idId            : this.state.id            ? this.state.id.id            : Utils.emptyGuid,
        odId            : this.state.od            ? this.state.od.id            : Utils.emptyGuid,
        degreeId        : this.state.degree        ? this.state.degree.id        : Utils.emptyGuid,
        serviceId       : this.state.service       ? this.state.service.id       : Utils.emptyGuid,
        mfgItemNo       : this.state.mfgItemNo     ? this.state.mfgItemNo.name   : '',
        searchTerm      : this.state.searchText
      };

      this.state.searchResults  = [];
      this.state.isLoading      = true;
      ajax.get('lookup/searchCustomerDescriptions', parameters).then(results=>{
        this.state.isLoading      = false;
        this.state.searchResults  = results;
        this.state.selectedResult = (results.length > 0) ? results[0] : '';
        results.forEach(this.setMfgItemNoDisplayValue);
        this.commit();
      }).then(result => result).catch(err => {
        this.state.isLoading      = false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<CustomerDescriptionDto>
  }

  // handleSearch2 = () => {
  //   const customerId = this.state.customer && this.state.customer.id;
  //   if (!customerId) {
  //     notification.action('warning').post('Please select a Customer first', 'Customer not specified');
  //     return;
  //   }

  //     const parameters = {
  //       customerId : customerId,
  //       searchTerm : this.state.searchText
  //     };

  //     this.state.isLoading = true;
  //     ajax.get('lookup/getCustomerDescriptions2', parameters).then(results=>{
  //     this.state.isLoading = false;
  //     this.state.searchResults = results;
  //     if (results.length > 0) {
  //       this.state.selectedResult = results[0];
  //     }
  //     results.forEach(this.setMfgItemNoDisplayValue);
  //     this.commit(); // IList<CustomerDescriptionDto>
  //     
  //   });
  // }

  delete = (item) => {
    const message = <div>
                    <h4>Please confirm you wish to delete this Customer Description</h4>
                    <h4>All associated data be deleted (e.g. Customer Service Levels)</h4>
                  </div>;

    co(this, function*() {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={300} header='Confirm Delete Customer Description'>
          {message}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }
      const custDescId = item.custDescId;
      yield ajax.post('customerDescription/delete', custDescId); // in: Guid, out: void

      // notify user
      let msg       = 'Customer Description';
      const mfgItemNo = item.mfgItemNoDisplayValue;
      if (mfgItemNo) {
        msg += ' with Mfg Part # ' + mfgItemNo;
      }
      const customerName = item.customer.name;
      if (customerName) {
        msg += ' for customer ' + customerName;
      }
      msg += ' has been deleted';
      notification.action('success').post(msg);

      // remove from current search results
      const idx = this.state.searchResults.indexOf(item);
      if (idx === -1) {
        return;
      }

      this.state.searchResults.splice(idx, 1);
      this.commit();
    });
  }
}
export default CustomerDescriptionController;
