const throttle = (quietMillis, fn, immediate) => {
    var timeout;
    var previousArgs;

    return function () {
        var context = this;
        var args = arguments;

        var later = function () {
            timeout = null;
            if (!immediate || args !== previousArgs) {
                fn.apply(context, args);
                previousArgs = args;
            }
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, quietMillis);
        if (callNow) {
            fn.apply(context, args);
            previousArgs = args;
        }
    };
}

const contains = (string, substring) => {
    if (!string) {
        return false;
    }
    if (!substring) {
        return true;
    }
    return string.toLowerCase().indexOf(substring.toLowerCase()) !== -1;
}

export default {
    throttle:  throttle,
    contains: contains
}