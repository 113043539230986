import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import ComponentUpdateRowController from './ComponentUpdateRowController';

class ComponentUpdateController extends BaseController {
  constructor(asset,notetype = 1,entityId = null) {
    super('fix-plant', {
      asset: asset,
      componentvalues: [],
      notetype : notetype,
      entityId : entityId,
      usersToNotify : [],
      noteText : '',
      isEmailScreenOpted : false,
    });
    this.getComponents();
    this.flage = true;
    this.saving = false;
    this.notesText = '';
  }

  addNewRow = () => {
    const comp = this.state.componentvalues;
    let x = [];
    comp.forEach(item => {
      x.push(item.state.Posnr);
    });
    let PosMax = Math.max(...x);
    PosMax = ((PosMax == Number.POSITIVE_INFINITY) || (PosMax == Number.NEGATIVE_INFINITY)) ? 0 : PosMax;
    PosMax = Number(PosMax) + 10;
    let result = [{
      Orderid: this.state.asset[0].serviceOrderNumber,
      Operation: '0010',
      Posnr: Utils.pad(PosMax, 4),
      Matnr: '',
      Matxt: '',
      Menge: '',
      Enmng: '0',
      Werks: this.state.asset[0].plant
    }];
    result.forEach(item => {
      this.state.componentvalues.push(new ComponentUpdateRowController(this, item, 1));
      this.commit();
    });
  }
  removeRow = (comp) => {
    const idx = this.state.componentvalues.indexOf(comp);
    if (idx === -1) {
      return;
    }
    this.state.componentvalues.splice(idx, 1);
    let posMax = this.state.componentvalues.filter(item => { return item.state.flag == 0 })
    let x = [];
    posMax.forEach(item => {
      x.push(item.state.Posnr);
    });
    let posMaxValue = Math.max(...x);
    posMaxValue = ((posMaxValue == Number.POSITIVE_INFINITY) || (posMaxValue == Number.NEGATIVE_INFINITY)) ? 0 : posMaxValue;
    this.state.componentvalues.filter(item => { return item.state.flag == 1 }).forEach(r => {
      posMaxValue = Number(posMaxValue) + 10;
      r.state.Posnr = Utils.pad(posMaxValue, 4);
    });
    this.commit();
  }
  getComponents = () => {
    const param = {
      serviceorderNumber: this.state.asset[0].serviceOrderNumber
    }
    ajax.get('lookup/getComponent', param).then(result => {
      if (result.length != 0) {
        result.forEach(item => {
          this.state.componentvalues.push(new ComponentUpdateRowController(this, item, 0));
          this.commit();
        });
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  validateComponents = (state) => {
    state.componentvalues.forEach(row => row.validate());
  }

  canSave = () => {
    const s = this.state;
    this.validateComponents(s);
    const componentsErrors = s.componentvalues.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!componentsErrors) {
      return true;
    }

    this.commit();
    return false;
  }

  save = () => {
    const s = this.state;
    if (!this.canSave()) {
      return;
    }
    this.saving = true;
    this.flage = true;
    this.commit();
    let x = [];
    s.componentvalues.forEach(item => {
      if(item.state.XLOEK !== 'X'){
        x.push(item.state.Matnr);
      }
    });

    const duplicates = Utils.find_duplicate_in_array(x);
    if (duplicates.length > 0) {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: 'Components for Part#' + duplicates.join(',') + ' already added.Please remove the duplicate row(s)', title: '' });
      return;
    }
    s.componentvalues.forEach(component => {
      const state = component.state;
      if (Number(state.Menge) < Number(state.Enmng)) {
        this.flage = false;
      }
    });

    if (!this.flage) {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: 'Please reverse issued component for one or more component', title: '' });
      return;
    }
    ajax.post('inspection/updateComponent', s.componentvalues.map(item => item.state)).then(result => {
      if (result && result.length != 0) {
        this.state.componentvalues = [];
        // result.forEach(item =>{
        //   this.state.componentvalues.push(new ComponentUpdateRowController(this,item,0));
        //   this.commit();
        // });
        this.getComponents();
        this.saving = false;
        this.commit();
        notification.action('success').post('Component updated successfully');
        // return true;
      }
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });

  }
  emailPartsList=()=>{
    
    if(this.state.usersToNotify && this.state.usersToNotify.length > 0)
    {
      const parameters = {
        entityId: this.state.entityId,
        noteType: this.state.notetype ,
        text: this.notesText,
        usersToNotify: this.state.usersToNotify,
        serviceOrderNumber: this.state.asset[0].serviceOrderNumber
      };
      ajax.post('inspection/emailpartslist', parameters).then(() => {
        this.state.isEmailScreenOpted = false;
        this.state.usersToNotify = [];
        this.state.noteText = '';
        this.commit();
        notification.action('success').post({ msg: "Parts List emailed to selected participants", title: 'Success' });
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }); 
    }
    else{
      notification.action('error').post({ msg: "Please select participants to Email Parts list", title: 'Error' });
    }
  }
  handleNotesChange = (text, usersToNotify, displayText) => {
    this.state.usersToNotify = usersToNotify;
    this.state.noteText = text;
    this.notesText = displayText;
    this.commit();
  }
  showEmailScreen = ()=>{   
    this.state.isEmailScreenOpted = true;
    this.state.usersToNotify = [];
    this.state.noteText = '';
    this.commit();
  }
  
}

export default ComponentUpdateController;