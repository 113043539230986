import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';

class EditAssetDescriptionController extends BaseController {
  constructor(customer, asset) {
    super('create-new-asset', EditAssetDescriptionController.getInitialState(customer, asset));
  }

  static getInitialState = () => {
    return {
      asset: '',
      customer: '',
      mfgPartNumber: '',
      familyCode: '',
      manufacturer: '',
      nominalSize: '',
      cwp: '',
      length: '',
      style: '',
      matchingAssets: [],
      selectedMatchingAsset: '',
      errors: {},
      objectType: '',
      totalAssets : 0,
      inventoryNumber : '',
      equipmentNumber : ''
    };
  }

  mfgPartNumberChanged = (data) => {
    this.handleSearch(AppConstants.eSearchType.MfgPartNumber);
  }

  mfgItemSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Mfg%20Item%20%23', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  familyCodeSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Family%20Code', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  manufacturerSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Manufacturer', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  nominalSizeSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Nominal%20Size', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  cwpSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/CWP', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  lengthSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Length', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }
  objectTypeSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Object%20Type', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }
  styleSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Style', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  selectAsset = (asset) => {
    this.state.selectedMatchingAsset = asset;
    this.commit();
  }

  handleSearch = (searchType) => {
    const parameters = {
      customerId: this.state.customer.id,
      customerName: this.state.customer.name
    };

    switch (searchType) {
      case AppConstants.eSearchType.MfgPartNumber:
        var mfgPartNumber = this.state.mfgPartNumber ? this.state.mfgPartNumber.name : 'no-mfg-item';
        parameters.mfgPartNumber = mfgPartNumber;
        break;

      case AppConstants.eSearchType.Properties:
        this.state.mfgPartNumber = '';
        parameters.familyCodeId = this.state.familyCode ? this.state.familyCode.id : Utils.emptyGuid;
        parameters.manufacturerId = this.state.manufacturer ? this.state.manufacturer.id : Utils.emptyGuid;
        parameters.nominalSizeId = this.state.nominalSize ? this.state.nominalSize.id : Utils.emptyGuid;
        parameters.cwpId = this.state.cwp ? this.state.cwp.id : Utils.emptyGuid;
        parameters.lengthId = this.state.length ? this.state.length.id : Utils.emptyGuid;
        parameters.styleId = this.state.style ? this.state.style.id : Utils.emptyGuid;
        break;

      default:
        break;
    }
    ajax.get('lookup/getAssetcount', parameters).then(results => { 
       this.state.totalAssets = results; 
       this.commit();  
    });

        
    ajax.get('lookup/getAssets', parameters).then(results => {
      this.state.matchingAssets = results;
      this.state.selectedMatchingAsset = (results.length === 1) ? results[0] : '';
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      this.state.matchingAssets = [];
      this.state.selectedMatchingAsset = '';
      this.commit();
     
    });   // IList<NewAssetLookupDto>
    
  }

  load = (assetId) => {
    this.state = EditAssetDescriptionController.getInitialState();   
    ajax.get('lookup/getEditDescriptionAsset/', { assetId: assetId }).then(asset => {
      this.state.asset = asset;
      this.state.customer = { id: asset.customerId, name: asset.customerName };
      this.state.inventoryNumber = asset.inventoryNumber;
      this.state.equipmentNumber = asset.equipmentNumber;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });  // out: EditDescriptionAssetDto
  }

  hasRfidTag = () => {
    const asset = this.state.asset;
    const hasRfidTag = asset && (asset.rfidTagId || asset.rfidTagId2);
    return hasRfidTag;
  }

  canSaveWithRfidTag = () => {
    if (!this.hasRfidTag()) {
      return true;
    }
    return LoginService.isAdministrator;
  }

  canSave = () => {
    if ((this.state.selectedMatchingAsset !== '' && this.state.selectedMatchingAsset.mfgPartNumber !== '') || this.state.objectType || this.state.inventoryNumber!=='') {
      return true;
    }

    if (this.state.selectedMatchingAsset == '' && !this.state.objectType) {
      notification.action('warning').post('You must select or enter one of the entries in the Matching Descriptions list/Object Type/Inventory number.');
    }
    if (this.state.selectedMatchingAsset.mfgPartNumber === '') {
      notification.action('warning').post('No Mfg Part # for the selected item.');
    }
    // else {
    //   notification.action('warning').post('You must select one of the entries in the Matching Descriptions list/Object Type.');
    // }
    this.commit();
    return false;
  }

  save = (cb) => {
    if (!this.canSave()) {
      return false;
    }
    const assetId = this.state.asset.assetId;
    const parameters = {
      assetId: assetId,
      newCustomerDescriptionId: this.state.selectedMatchingAsset.id,
      objectType: this.state.objectType.name,
      inventoryNumber : this.state.inventoryNumber
    };
    ajax.post('jobprogress/editAssetDescription', parameters).then(() => {
      if (this.state.selectedMatchingAsset.description)
      {
        notification.action('success').post('Modified description of asset ' + this.state.asset.primarySerialNumber + ' to ' + this.state.selectedMatchingAsset.description);
      } 
      else if (this.state.objectType.name)
      { 
        notification.action('success').post('Modified ObjectType of asset ' + this.state.asset.primarySerialNumber + ' to ' + this.state.objectType.name);
      }
      else if(this.state.inventoryNumber)
      {
        notification.action('success').post('Modified Inventory number of asset ' + this.state.asset.primarySerialNumber + ' to ' + this.state.inventoryNumber);
      }
      cb(assetId);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });  // in: UpdateAssetDescriptionDto
  }
  
}

export default EditAssetDescriptionController;