import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';

class CustomerServiceLevelBOMRowController extends BaseController {
    constructor(parentController, bom) {
        super('customer-service-level-bom-row-controller-' + CustomerServiceLevelBOMRowController.key++, {
            customerServiceLevelBOMId: bom ? bom.customerServiceLevelBOMId : Utils.emptyGuid,
            parentServiceLevelId     : bom ? bom.parentServiceLevelId : '',
            bom                      : bom ? bom.bom : '',
            errors                   : {}
        });

        this.parentController = parentController;
    }

    static key = 1;

    removeRow = () => {
        this.parentController.removeBOMRow(this);
    }
}

export default CustomerServiceLevelBOMRowController;