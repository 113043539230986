import React from 'react';
import Dialog from '../../components/Dialog';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Validator from '../../lib/Validator';
import notification from '../../services/Notification';
import Utils from '../utility/Utils';
import co from '../../lib/Co';

class ConfigureCustomerController extends BaseController {
    constructor() {
        super('config-cust', ConfigureCustomerController.getInitialState(''));

        this._validator = new Validator();
        this._validator.add('familyCode', 'Family Code', [{ required: true }]);
        this._validator.add('eqType', 'Rule', [{ required: true }]);
    }

    static getInitialState = (customerId) => {
        return {
            customerId: customerId,
            familyCode: '',
            nominalSize: '',
            length: '',
            style: '',
            manufacturer: '',
            cwp: '',
            service: '',
            degree: '',
            id: '',
            od: '',
            mfgItemNo: '',
            endConfiguration: '',
            errors: {},
            isSaving: false,
            eqType: ''
        };
    }

    load = (customerId) => {
        this.state.customerId = customerId;
        ajax.get('lookup/getCustomerConfigurations/', { customerId: customerId }).then(results => {
            this.merge(results);
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // Create API to GET Customer id based configs
    }

    merge = (configCustomer) => {
        if (!configCustomer) {
            return;
        }
        this.state.configurationsData = configCustomer;
        this.state.errors = {};
    }

    mfgItemSearch = () => {
        return (searchTerm) => ajax.get('lookup/searchLookupList/Mfg%20Item%20%23', { searchTerm: searchTerm })
            .then(result => result).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
    }

    search = (searchType) => {
        if (!searchType) {
            return null;
        }

        return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), { searchTerm: searchTerm })
            .then(result => result).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
    }

    clear = () => {
        this.state.familyCode = '';
        this.state.nominalSize = '';
        this.state.length = '';
        this.state.style = '';
        this.state.manufacturer = '';
        this.state.cwp = '';
        this.state.service = '';
        this.state.degree = '';
        this.state.id = '';
        this.state.od = '';
        this.state.mfgItemNo = '';
        this.state.endConfiguration = '';
        this.state.errors = {};
        this.state.isSaving = false;
        this.state.eqType = '';
        this.commit();
    }

    canSave = () => {
        this._validator.validateAll(this.state);
        var errors = Object.keys(this.state.errors);
        if (!errors.length) {
            return true;
        }
        this.commit();
        return false;
    }

    save = () => {
        if (!this.canSave()) {
            return;
        }

        const parameters = {
            customerId: this.state.customerId,
            familyCodeId: this.state.familyCode ? this.state.familyCode.id : Utils.emptyGuid,
            nominalSizeId: this.state.nominalSize ? this.state.nominalSize.id : Utils.emptyGuid,
            lengthId: this.state.length ? this.state.length.id : Utils.emptyGuid,
            styleId: this.state.style ? this.state.style.id : Utils.emptyGuid,
            manufacturerId: this.state.manufacturer ? this.state.manufacturer.id : Utils.emptyGuid,
            cwpId: this.state.cwp ? this.state.cwp.id : Utils.emptyGuid,
            serviceId: this.state.service ? this.state.service.id : Utils.emptyGuid,
            degreeId: this.state.degree ? this.state.degree.id : Utils.emptyGuid,
            idId: this.state.id ? this.state.id.id : Utils.emptyGuid,
            odId: this.state.od ? this.state.od.id : Utils.emptyGuid,
            mfgItemNo: this.state.mfgItemNo ? this.state.mfgItemNo.name : '',
            endConfigurationId: this.state.endConfiguration ? this.state.endConfiguration.id : Utils.emptyGuid,
            eqType: this.state.eqType ? this.state.eqType.id : Utils.emptyGuid,
        };
        this.state.isSaving = true;
        this.commit();
        ajax.post('customer/saveConfiguration', parameters).then(() => {
            this.state.isSaving = false;
            this.commit();
            notification.action('success').post('Saved new Configuration');
            this.load(this.state.customerId);
            this.clear();
        }).catch(err => {
            this.state.isSaving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    removeRow = (configurationId) => {
        const message = <span>
            <h6>Please confirm you wish to delete this Customer Configration</h6>
        </span>;

        co(this, function* () {
            var result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog width={500} height={200} header='Confirm Delete Customer Configration'>
                    {message}
                </Dialog.OKCancelDialog>
            );
            if (!result.OK) {
                return;
            }
            yield ajax.get('customer/deleteConfiguration', { customerConfigurationId: configurationId });
            notification.action('success').post("Customer Configration deleted successfully");
            this.commit();
            this.load(this.state.customerId);
        });
    }
}

export default ConfigureCustomerController;
