import React from 'react';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import GroupBadge from '../../components/GroupBadge';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import Search from '../components/Search';

const CustomerLocation = (props) => {
  const toggleSelection = (customerLocation) => {
    props.controller.toggleSelection(customerLocation);
  }
  const customerLocation = props.customerLocation;
  let className = 'customer-location list-group-item';

  const spanArchived = customerLocation.archived
    ? <span><i className='fa fa-archive archived' />&nbsp;&nbsp;</span>
    : null;
  const spanFMCLocation = customerLocation.fmcLocation
    ? <span className='fmc-location'>TechnipFMC</span>
    : null;
  const regionCountry = customerLocation.region
    ? <span className='region-country'>{customerLocation.region}, {customerLocation.country}</span>
    : <span className='region-country'>{customerLocation.country}</span>;

  return (
    <List.Item className={className + ' p-2'} active={customerLocation.selected} onClick={() => { toggleSelection(customerLocation); }} >
      <div>{spanArchived}<span className='customer-location-name font-weight-bold'>{customerLocation.name} </span> {regionCountry} {spanFMCLocation}</div>
      <div><span>{customerLocation.customerName}</span></div>
    </List.Item>
  );
}

const CustomerLocations = (props) => {
  const selectNone = () => {
    props.controller.selectNoCustomerLocations();
  }
  const selectAll = () => {
    props.controller.selectAllFilteredCustomerLocations();
  }
  const binder = props.binder;
  const controller = props.controller;
  const filterText = controller.state.customerLocationFilterText;
  const customerLocations = controller.applyFilter(controller.state.customerLocations, filterText);
  const count = customerLocations.length;
  let badgeText = filterText ? 'No matches' : ''; // default
  if (count) {
    badgeText = count + ' / ' + controller.state.customerLocations.length + ((count === 1) ? ' match' : ' matches');
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <Search className='mb-2' id='searchText' inputClassName='search-input' label='Enter search text' placeholder='available locations' autoFocus='true' bind={binder.bind('customerLocationFilterText')} clear={controller.clearCustomerLocationFilter} search={controller.filterCustomerLocations}></Search>
      </div>
      <div id='form-buttons' className='col-md-12'>
        <div className='d-flex justify-content-start'>
          <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
          <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
          <Badge variant='info' className='m-1'>{badgeText}</Badge>
        </div>
      </div>
      <div className='col-md-12'>
        <Jumbotron className='customer-list'>
          <List id='list-group customer-location-list'>
            {
              customerLocations.map(customerLocation => {
                return <CustomerLocation key={customerLocation.id} controller={controller} customerLocation={customerLocation} />;
              })
            }
          </List>
        </Jumbotron>
      </div>
    </div>
  );
}

const FacilityCustomerLocations = (props) => {
  const selectNone = () => {
    props.controller.selectNoFacilityCustomerLocations();
  }
  const selectAll = () => {
    props.controller.selectAllFilteredFacilityCustomerLocations();
  }
  const binder = props.binder;
  const controller = props.controller;
  const filterText = controller.state.facilityCustomerLocationFilterText;
  const facilityCustomerLocations = controller.applyFilter(controller.state.facilityCustomerLocations, filterText);
  const count = facilityCustomerLocations.length;
  let badgeText = filterText ? 'No matches' : ''; // default
  if (count) {
    badgeText = count + ' / ' + controller.state.facilityCustomerLocations.length + ((count === 1) ? ' match' : ' matches');
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <Search className='mb-2' id='searchText' inputClassName='search-input' label='Enter search text' placeholder='available locations' autoFocus='true' bind={binder.bind('facilityCustomerLocationFilterText')} clear={controller.clearFacilityCustomerLocationFilter} search={controller.filterFacilityCustomerLocations}></Search>
      </div>
      <div id='form-buttons' className='col-md-12'>
        <div className='d-flex justify-content-start'>
          <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
          <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
          <Badge variant='info' className='m-1'>{badgeText}</Badge>
        </div>
      </div>
      <div className='col-md-12'>
        <Jumbotron className='customer-list'>
          <List id='list-group selected-customer-location-list'>
            {
              facilityCustomerLocations.map(customerLocation => {
                return <CustomerLocation key={customerLocation.id} controller={controller} customerLocation={customerLocation} />;
              })
            }
          </List>
        </Jumbotron>
      </div>
    </div>
  );
}

const TransferButtons = (props) => {
  const select = () => {
    props.controller.moveToSelected();
  }
  const unselect = () => {
    props.controller.removeFromSelected();
  }
  const selectDisabled = false;
  const unselectDisabled = false;

  return (
    <div id='transfer-buttons' className='row mt-5'>
      <div className='col-md-12'>
        <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
        <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect} >Unselect</Button>
      </div>
    </div>
  );
}

class SelectFacilityCustomerLocationsDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Close ? true : this.props.controller.save();

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const labelText = 'Select the locations which should have access to {facilityName}'.interpolate(controller.state.facility);

    return (
      <Dialog {...this.props} header={this.props.header} width={750} height={650} id='change-facility-customer-locations-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <div className='form-group col-md-12'>
              <div className='text-center'>
                <h5>{labelText}</h5>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='col-6'><Form.AutoComplete label='Customer' className='required' bind={binder.bind('customer')} search={controller.customerSearch()} /></div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='align-items-center row'>
                <div className='col-md-5'><CustomerLocations controller={controller} binder={binder} /></div>
                <div className='col-md-2'><TransferButtons controller={controller} /></div>
                <div className='col-md-5'><FacilityCustomerLocations controller={controller} binder={binder} /></div>
              </div>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCloseButtons />
      </Dialog>
    );
  }
}

export default SelectFacilityCustomerLocationsDialog;
