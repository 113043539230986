import React from 'react';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import Controller from '../../mixins/Controller';
import ActionIcon from '../../components/ActionIcon';
import AppConstants  from '../utility/AppConstants';
import Table from '../../components/Table';

class SerialReadingHeaderRow extends React.Component{
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.binder = new Binder(this);
  }

  toggleSelection = () => {
    this.props.controller.toggleSerialWithoutReadingSelection();
  }

  handleToggleCollapsed = (calHeader) => {
    this.props.handleToggleCollapsed(calHeader);
  }

  render = () => {
    const binder          = new Binder(this);;
    let   className       = 'asset';
    const data            = this.props.controller.state; 
    const rowClass        = this.props.showCollapse ? 'service-history-performed-service-level-header' : '';
    const actionButton    = this.props.showCollapse
                            ? data.collapsed
                            ? <Table.Data><ActionIcon icon='fa-plus-square'  className='btn-expand-collapse text-info' action={() => this.handleToggleCollapsed(data)} /></Table.Data>
                            : <Table.Data><ActionIcon icon='fa-minus-square' className='btn-expand-collapse text-info' action={() => this.handleToggleCollapsed(data)} /></Table.Data>
                            : null;
    const groupDisplay    = data.groupName? data.groupName : data.groupNumber;
    const downloadtext    = data.serial.length>16?<Form.StaticData className='statusScrapped' value='  Serial Length>16. Download is not possible' />:null;
    const fldSerial       = <Form.StaticData value={data.serial} />
    const fldGroupDisplay = <Form.StaticData value={groupDisplay} />
    const fldDescription  = <Form.StaticData value={data.description} />
    const isScrapped      = data.assetStatus == 'Scrapped' || data.serial.length>16 ;
    const statusClass     = (data.assetStatus == AppConstants.readingBelowMinStatusText  || data.assetStatus == AppConstants.readingMissingStatusText || data.assetStatus =='Scrapped') ? 'statusScrapped' : '';
    const assetStatus     = <Form.StaticData className={statusClass} value={data.assetStatus} />;
    const downloadToScope = <Form.CheckBox className={'approve-check'} enabled={!isScrapped} disabled={isScrapped} bind={binder.bind('downloadToScope')} />;

    return(
      <Table.Row className={rowClass}>
        <Table.Data>{downloadToScope}</Table.Data>
        {actionButton}
        <Table.Data className={className}>{fldSerial}{downloadtext}</Table.Data>
        <Table.Data className={className}>{fldGroupDisplay}</Table.Data>
        <Table.Data className={className} colSpan={3}>{fldDescription}</Table.Data>
        <Table.Data className={className}>{assetStatus}</Table.Data>
      </Table.Row>
    )
  }
}

export default SerialReadingHeaderRow;