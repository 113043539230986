import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import DropZone from '../../components/DropZone';
import Controller from '../../mixins/Controller';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import FileNameRow from '../pages/FileNameRow';
import AwoAttachmentController from '../controllers/AwoAttachmentController';
import Table from '../../components/Table';
import Form from '../../components/Form';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import ActionIcon from '../../components/ActionIcon';
import Dialog from '../../components/Dialog';
import co from '../../lib/Co';
const FileListRow=({uploadedby,filename,uploadedon,controller,docId,awoId})=>{
const FileLink = controller.state.filelink;

 const handleDeleteAttachment = () => {        
    co(this, function* () {
        const result = yield Dialog.showDialogWaitForResult(            
            <Dialog.OKCancelDialog className='delete-confirm-dialog' header='Confirm Remove'>
                {'Are you sure you want to remove the file'}
            </Dialog.OKCancelDialog>
        );

        if (result.OK) {
            controller.removeattachment(docId,awoId);
        }
    });
}   
return <Table.Row>
    <Table.Data className='col-action'>
    <ActionIcon icon='fa-trash' onClick={handleDeleteAttachment}  className='remove-handle'/>
    </Table.Data>
    <Table.Data className='col-fileName'>
    <Form.Link value={filename} href={`${FileLink}${docId}`}  />
    </Table.Data>
    <Table.Data className='col-testName'>{uploadedby}</Table.Data>
    <Table.Data className='col-uploadedon'>{uploadedon}</Table.Data>
</Table.Row>
}
const DocumentUpload = (props) => {

    const awoId = props.awoId;
    const handleFileUpload = (res) => {
        props.controller.uploadFile(res,awoId);
    }

    const binder = props.binder;
    const controller = props.controller;
    const files = controller.state.files || [];
    const msg = files.length === 0 ? <Table.Row>
        <Table.Data></Table.Data>
        <Table.Data colSpan={3}>No Files Found</Table.Data>
    </Table.Row> : null;
    const rows = files.map(fc => {       
        return <FileListRow awoId={awoId} controller={props.controller} docId={fc.Document_GUID} uploadedby={fc.UploadedBy} uploadedon={DateTimeFormatter.formatDateTime(fc.UploadedOn)} filename={fc.FileName}/>
    });
    const upload = <DropZone className='float-right' onDrop={handleFileUpload} multiple={false} disabled={controller.adding | controller.state.isSaveInProgress} style={{ borderStyle: 'none', padding: 'unset' }} width={200} height={50} >
        <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
            <Button type='file' className='' variant='success' size='sm' icon='fa-upload' disabled={controller.adding | controller.state.isSaveInProgress} >Upload</Button>
        </DropZone>;

    return (
            <div>
                <div className='row mb-2'>
                    <div className='col-6'><Form.Label name='Documents (Max. file size allowed is 25 MB)' /> </div>
                    <div className='col-6'>{upload}</div>
                </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Table id='documents'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Head className='bg-primary table-dark col-action' />
                                        <Table.Head className='bg-primary table-dark col-fileName'>Filename</Table.Head>                                        
                                        <Table.Head className='bg-primary table-dark col-testName'>Uploaded By</Table.Head>
                                        <Table.Head className='bg-primary table-dark col-dateTime'>Uploaded On</Table.Head>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {rows}
                                    {msg}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
    );
}


const Buttons = (props) => {

    let history = useHistory();

    const handleSave = () => {
        props.controller.save(() => {
            history.goBack();
        });
    }

    const handleClose = () => {
        history.goBack();
    }

    const controller = props.controller;
    return (
        <div className='col-md-12'>
            <div className='edit-buttons pull-right mr-2'>
                <Button id='btn-save' variant='primary' size='sm' className='action-button' disabled={controller.state.isSaveInProgress} onClick={handleSave}>Save</Button>
                <Button id='btn-cancel' variant='secodary' size='sm' className='action-button' onClick={handleClose}>Close</Button>
            </div>
        </div>
    );
}

const AwoAttachmentPageHeader = (props) => <PageHeader title={props.pageTitle} showBackNavigation='true'/>

class AwoAttachmentPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        Object.assign(this, Controller);
        const awoId = this.props.match.params.awoId; 
        this.awoId = awoId;      
        this.props.controller.load(awoId);
        this.props.controller.getAWODetails(awoId);
    }

    static defaultProps = {
        controller: new AwoAttachmentController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    render = () => {
        const binder = this.props.binder;
        const controller = this.props.controller;
        const buttons = controller.state.files.length !== 0        
            ? <Buttons controller={controller} />
            : null;
            const Header = controller.state.awoDetails ? <AwoAttachmentPageHeader pageTitle={`AWO Attachments : ${controller.state.awoDetails.awoNumber}`} /> :
        <AwoAttachmentPageHeader pageTitle={`AWO Attachments`}/>;
        return (
            <Page {...this.props} pageTitle='InteServ · AWO Attachments' id='asset-attachments'>
                {Header}
                <Page.Content>
                <div className='row'>
                {/* <div className='col-12 mb-1'><span className='h5'>AWO Number: {controller.state.Awonumber}</span></div> */}
                    <div className='col-12 job-attachments-content'>
                        <DocumentUpload awoId={this.awoId} controller={controller} binder={binder} />
                    </div>
                    {/* {buttons} */}
                </div>
                </Page.Content>
            </Page>
        );
    }
}

export default AwoAttachmentPage;