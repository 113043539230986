import React from 'react';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import SearchController from '../controllers/SearchController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import AppConstants from '../utility/AppConstants';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Dialog from '../../components/Dialog';
import store from 'store';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';
import Table from '../../components/Table';
import InspectionAttachmentDialog from '../pages/InspectionAttachmentDialog';
import InspectionAttachmentDialogController from '../controllers/InspectionAttachmentDialogController';

class InspectionFailedController extends BaseController {
  constructor(jobId, testName, assets, isWeldRepair, isfailInspection, customerName) {
    super('scrap-asset', {
      jobId: jobId,
      testName: testName,
      assets: assets,
      rejectReason: '',
      approverName: '',
      approverSignature: '',
      vendor: '',
      recordZeroTime: false,
      inspectionMinutes: 0,
      inspectionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      technician: '',
      errors: {},
      isWeldRepair: isWeldRepair,
      guageSerPrim: '',
      notesId: Utils.emptyGuid,
      notes: '',
      files: [],
      isfailInspection: isfailInspection,
      scrap: false,
      failInspection: false,
      canShowRejectCodeDD: false,
      rejectCode: '',
      rejectCodeName:'',
      customerName:customerName,
    });

    this.saving = false;
    this._searchController = new SearchController();
    this._validator = new Validator();
    this._validator.add('approverName', 'Approved By', [{ required: true }]);
    this._validator.add('approverSignature', 'Approver Signature', [{ required: true }]);
    this._validator.add('inspectionDate', 'Inspection Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: this.state.inspectionDate } }]);
    this._validator.add('rejectReason', 'Reject Reason', [{ required: true }]);
    this.getInspectionNotes();
    if(customerName==='Halliburton'){
        this.getRejectCodeForHalliburton();
    }else{
        this.getRejectCode()
      }
    
  }

  get searchController() { return this._searchController; }

  vendorChanged = (data) => {
    this.state.recordZeroTime = false;
    if (data) {
      this.state.technician = '';
      this.commit();
    }
  }

  technicianChanged = (data) => {
    if (data) {
      this.state.vendor = '';
      this.commit();
    }
  }

  recordZeroTimeChanged = () => {
    if (this.state.recordZeroTime) {
      this.state.inspectionMinutes = 0;
      this.commit();
    }
  }

  rejectReasonSearch = () => {
    var familyCodeId = this.state.assets[0].familyCodeId;
    return (searchTerm) => ajax.get('lookup/searchRejectReasonLookupList', { familyCodeId: familyCodeId, searchTerm: searchTerm })
      .then(results => results)
      .catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  guageSerPrimData = () => {
    return (searchTerm) => ajax.get('lookup/getserialprimarygauge', { searchTerm: searchTerm })
      .then(results => results)
      .catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  clearSignature = () => {
    this.state.approverSignature = '';
    this.commit();
  }

  updateSignature = (signature) => {
    this.state.approverSignature = signature;
    this.commit();
  }
  uploadFile = (res) => {
    if (this.state.files.find(f => f.state.file.fileName === res.file.name)) {
      return;
    }
    if (res && (res.file.size / 1048576).toFixed(1) < 25) {
      var newFile = {
        id: Utils.newGuid(),
        fileName: res.file.name,
        size: res.file.size,
        altText: '',
        caption: '',
        file: res.file,
        fileType: res.file.name.split('.')[1].toLowerCase() || '',
        MimeType: res.file.type,
        imgUrl: res.imageUrl,
        recordStatus: 'A'
      };
      this.addNewFile(newFile);
      this.uploadSave(() => { });
    }
    else {
      notification.action('error').post({ msg: 'File size is larger that 25 MB', title: 'File Size Error' });
    }
  }
  addNewFile = (file) => {
    this.state.files.push(file);
    this.commit();
  }

  uploadSave = (cb) => {
    var s = this.state;
    var jobId = s.assets[0].assetId;
    let parameters = {};
    if (!this.adding) {
      parameters.jobId = jobId;
      parameters.files = s.files.map(f => f);
    }
    this.saving = true;
    this.commit();
    ajax.post('inspection/saveassetattachment', parameters).then(result => {
      if (this.adding) {
        notification.action('success').post('Saved attachment with Asset Number ' + this.state.jobNumber);
      }
      else {
        notification.action('success').post('Saved attachment for Asset with Asset Number ' + this.state.jobNumber);
      }
      cb();
      this.saving = false;
      this.commit();
      // this.load(this.state.jobId,this.state.jobNumber)
    }).catch(err => {
      if (this.adding) {
        notification.action('error').post({ msg: err.message, title: 'Error saving attachment' });
      }
      else {
        notification.action('error').post({ msg: err.message, title: 'Error saving attachment' });
      }
      this.saving = false;
      this.commit();
    });
  }

  canSave = () => {
    if (this.state.isfailInspection && (!this.state.scrap && !this.state.failInspection)) {
      notification.action('error').post({ msg: 'Please check either Scrap or Fail Inspection', title: 'Scrap Asset Error' });
      return false;
    }

    this._validator.validateAll(this.state);
    let errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }

  getSuccessMessage = (rejectReasonId, count) => {
    var type = this.state.scrap ? 'Scrapped' : 'Failed'
    return (rejectReasonId.toUpperCase() === AppConstants.FailedHydrostaticTest)
      ? 'Temporarily failed ' + Utils.pluralize('asset', 's', count)
      : (this.state.isWeldRepair ? 'Weld repair ' + Utils.pluralize('asset', 's', count) : type + Utils.pluralize('asset', 's', count));
  }

  handleSaveWithConfirmationDialog = (errorMessage, cb) => {
  }

  getInspectionNotes = () => {
    var self = this;
    var s = self.state;
    var param = {
      jobId: s.jobId,
      testName: s.testName,
      assetId: s.assets[0].jobSOWId,
    }
    ajax.post('lookup/getInspectionTestNotes', param).then(result => {
      if (result) {
        self.state.notesId = result.notesId || Utils.emptyGuid
        self.state.notes = result.notes || '';
        self.commit();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  getRejectCode = () => {
    var self = this;
    var s = self.state;
    const assetId=s.assets[0].assetId;
    ajax.get('lookup/getMultiRejectLaborCodes', {assetId:assetId }).then(result => {
      if (result) {
        self.state.canShowRejectCodeDD = result>0? true: false;
        if(self.state.canShowRejectCodeDD){
          this._validator.add('rejectCode', 'Reject Code', [{ required: true }]);
        }
        self.commit();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  getRejectCodeForHalliburton = () => {
    var self = this;
    var s = self.state;
    const testName=s.testName;
     ajax.get('lookup/getRejectCodeByTestName', {testName:testName}).then(result => {
      if (result) {
        self.state.rejectCode = result.id;
        self.state.rejectCodeName=result.name;
        self.commit();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  getShowDetailFlag = () => {
    if (this.state.isfailInspection) {
      var flag = this.state.scrap || this.state.failInspection ? true : false;
      return flag;
    }
    return true;
  }

  getErrorMessage = (message) => {
    message = message.toString();
    if (Utils.contains(message, 'Please enter')) {
      let messages = message.split('|');
      message = '<p>' + messages.join('</p><p>') + '</p>';
    }
    return message;
  }

  * save() {
    const self = this;
    this.state.errors = {};
    if (!this.canSave()) {
      return false;
    }
    var loginInfo = store.get('InteServLoginInfo');
    var s = this.state;
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
      // if(parseFloat(s.inspectionMinutes).toFixed(1)<=0)
      // {
      //   notification.action('error').post("After rounding to 1 decimal, time will become 0. Inspection Time should be greater than 0");
      //   return false;
      // }   
      var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));

      if (s.inspectionMinutes > availableTime) {
        notification.action('error').post("Inspection Time should not be more than Available Production Time");
        return false;
      }
    }

    var rejectReasonId = s.rejectReason ? s.rejectReason.id : '';
   
    var parameters = {
      jobId: s.jobId,
      rejectReasonId: s.rejectReason ? s.rejectReason.id : '',
      rejectReasonName: s.rejectReason ? s.rejectReason.name : '',
      testName: s.testName,
      selectedAssets: s.assets,
      approvedByName: s.approverName,
      approvedBySignature: s.approverSignature,
      inspectionMinutes: s.inspectionMinutes,
      inspectionDate: s.inspectionDate,
      technician: s.technician,
      vendorId: s.vendor && s.vendor.id,
      recordZeroTime: s.recordZeroTime,
      isWeldRepair: s.isWeldRepair,
      guageSerPrimId: s.guageSerPrim.id,
      notesId: s.notesId,
      notes: s.notes || null,
      isScraped: s.isfailInspection ? s.scrap : true,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      enableDMS: loginInfo.enableDMS,
      rejectCode: s.customerName==='Halliburton'?s.rejectCode:s.rejectCode.id
    };

    try {
      let controller = new InspectionAttachmentDialogController(s.assets, this, s.testName);
      var testMode = s.isWeldRepair ? 'Weld Repair' : s.scrap ? 'Scrap Test' : 'Fail Test';
      var attachmentDailog = <Dialog className='inspection-attachment-dialog' width={600} height={600} header={testMode + ' attachments'} popup='attachmentPopup'>
        <InspectionAttachmentDialog controller={controller} />
        <Dialog.SaveCloseButtons popup={'attachmentPopup'} />
      </Dialog>
      let result = yield Dialog.showDialogWaitForResult(attachmentDailog, 'attachmentPopup');

      if (!result.Save) {
        return false;
      }
      else {
        var assetsWithFile = controller.state.assets.filter(asset => (asset.file && asset.file.length));
        if (assetsWithFile.length) {
          var fileAttachment = [];
          assetsWithFile.map(attach => {
            fileAttachment.push({
              jobId: attach.assetId,
              files: attach.file,
              jobSOWId: attach.jobSOWId,
              testName: s.testName
            });
          });
          parameters.attachment = fileAttachment;
        }
        
        var results1 = yield ajax.post('inspection/componentTest', parameters);
        if (results1.length) {
          var comRows = results1.map(com => {
            var Resdiv = '';
            if (com.Enmng != "0.000" && com.Enmng == com.Menge)
              Resdiv = <Table.Row className="comIssued"><Table.Data>{com.Orderid}</Table.Data><Table.Data>{com.Matnr}</Table.Data><Table.Data>{com.Menge}</Table.Data><Table.Data>{com.Enmng}</Table.Data><Table.Data>{com.Werks}</Table.Data></Table.Row>
            else
              Resdiv = <Table.Row className="comNotIssued"><Table.Data>{com.Orderid}</Table.Data><Table.Data>{com.Matnr}</Table.Data><Table.Data>{com.Menge}</Table.Data><Table.Data>{com.Enmng}</Table.Data><Table.Data>{com.Werks}</Table.Data></Table.Row>
            return Resdiv;
          });
          //var componetList= results1.componetMessages.map(element => <li>{element}</li>);
          var result2 = yield Dialog.showDialogWaitForResult(
            <Dialog id="comp" className='comp-update-dialog' width={450} height={450} model={true} header='Components Issued' popup='childPopup'>
              <Dialog.Body>
                <div className='w2uicen'>
                  <div>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.Head>
                            SERVICE ORDER
                          </Table.Head>
                          <Table.Head>
                            PART NUMBER
                          </Table.Head>
                          <Table.Head>
                            PLANNED
                          </Table.Head>
                          <Table.Head>
                            ISSUED
                          </Table.Head>
                          <Table.Head>
                            PLANT
                          </Table.Head>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {comRows}
                      </Table.Body>
                    </Table>
                  </div>
                  <br />
                  <div>Do you want to continue?</div>
                </div>
              </Dialog.Body>
              <Dialog.OKCancelButtons popup={'childPopup'} />
              {/* <Dialog.Buttons>
              {Dialog.OKButton()}
              {<button className='btn btn-default' onClick={Dialog.handleResult({ Close: true })} >Cancel</button>}
            </Dialog.Buttons> */}
            </Dialog>
            , 'childPopup');
          if (!result2.OK) {
            return false;
          }
          else {
            try {
              var results = yield ajax.post('inspection/failTest', parameters);
              var msg;
              //messages.channel('productiveTime').action('update').post(results.prodTime);
              if (results.successMessages.length) {
                msg = self.getSuccessMessage(rejectReasonId, s.assets.length);
                messages.channel('scrap-asset').action('saved').post();
                notification.action('info').post({ msg: msg, title: 'Action Complete' });
                notification.action('success').post('<p>' + results.successMessages.join('</p><p>') + '</p>');
                return true;
              }
              if (results.errorMessages.length) {
                notification.action('error').post('<p>' + results.errorMessages.join('</p><p>') + '</p>');
                return false;
              }
            }
            catch (err) {
              notification.action('error').post({ msg: err.message, title: 'Scrap Asset Error' });
            }
          }
        }
        else {
          try {
            var results = yield ajax.post('inspection/failTest', parameters); // in: UpdateTestDto, out: InspectionResultDto
            var msg;
            //messages.channel('productiveTime').action('update').post(results.prodTime);
            if (results.successMessages.length) {
              msg = self.getSuccessMessage(rejectReasonId, s.assets.length);
              messages.channel('scrap-asset').action('saved').post();
              notification.action('info').post({ msg: msg, title: 'Action Complete' });
              notification.action('success').post('<p>' + results.successMessages.join('</p><p>') + '</p>');
              return true;
            }
            if (results.errorMessages.length) {
              notification.action('error').post('<p>' + results.errorMessages.join('</p><p>') + '</p>');
              return false;
            }
          }
          catch (err) {
            const errorMessage = self.getErrorMessage(err.message);
            notification.action('error').post({ msg: errorMessage, title: 'Scrap Asset Error' });
          }
        }
      }
    }
    catch (err) {
      const errorMessage = self.getErrorMessage(err.message);
      notification.action('error').post({ msg: errorMessage, title: 'Scrap Asset Error' });
    }
  }

  scrapChanged = () => {
    if (this.state.scrap && this.state.failInspection) {
      this.state.failInspection = !this.state.scrap;
      this.commit();
    }
    if (this.state.scrap) {
      this._validator.add('rejectReason', 'Reject Reason', [{ required: true }]);
    }
    this.commit();
  }

  failInspectionChanged = () => {
    if (this.state.failInspection && this.state.scrap) {
      this.state.scrap = !this.state.failInspection;
      this.commit();
    }

    if (this.state.failInspection && this.state.isfailInspection) {
      this.state.rejectReason = '';
      this._validator.remove('rejectReason');
    }
    this.commit();
  }
}
export default InspectionFailedController;