import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';

class ManualPushToCATSController extends BaseController {
    constructor() {
        super('manualpush', {
            workDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()),
            pushedHours: 0,
            remainingHours: 0,
            totalHours: 0
        });

        this.saving = false;
    }

    load = (userId) => {
        var params = {
            userId: userId,
            wDate: this.state.workDate
        };
        ajax.get('timeconfirmation/getTime', params).then((results) => {
            this.state.workDate = this.state.workDate;
            this.state.pushedHours = results.pushedHours;
            this.state.remainingHours = results.remainingHours;
            this.state.totalHours = results.totalHours;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    saveManualHours = () => {
        if (this.state.remainingHours == 0) {
            notification.action('error').post("Time cannot pushed to CATS because remaining time value is 0.");
            return false;
        }

        const loginInfo = LoginService.loginInfo;
        const params = {
            workDate: this.state.workDate,
            userId: loginInfo.userId,
            totalManHours: this.state.remainingHours
        };

        this.saving = true;
        this.commit();
        return ajax.post('timeconfirmation/manualpushtocats', params).then(() => {
            this.saving = false;
            this.commit();
            const loginInfo = LoginService.loginInfo;
            this.load(loginInfo.userId);
            return true;
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
            this.saving = false;
            this.commit();
            return false;
        });
    }

}

export default ManualPushToCATSController;