import React from 'react';
import BaseController from '../../lib/BaseController';
import store, { set } from 'store';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import AWOGroupRowController from './AwoGroupRowController';
import AssemblyWorkOrderRowController from './AssemblyWorkOrderRowController';

const contollerUrl = `${process.env.REACT_APP_API_SSL}://${process.env.REACT_APP_API_HOST_NAME}${process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : ''}/${process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : ''}${process.env.REACT_APP_API}/lookup/awo/getawodocument/`;
class EditAWOController extends BaseController {
  constructor() {
    super('create-new-asset', EditAWOController.getInitialState());

    this._validator = new Validator();
    this._validator.add('equipmentRequiredDate', 'Equipment Required Date', [{ required: true }]);
    this._validator.add('serviceLine', 'Service Line', [{ required: true }]);
    this._validator.add('system', 'System', [{ required: true }]);
    this._validator.add('awoDescription', 'AWO Description', [{ required: true }]);
    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state = EditAWOController.getInitialState();
      }
    });

    messages.channel('create-new-asset').action('updated').subscribe(data => {
      if (SAPService.isSAPFacility() && data.assetId) {
        var loginInfo = store.get('InteServLoginInfo');
        if (loginInfo.userId = data.userId) {
          messages.channel('receiving').action('add-to-list').post([data.assetId]);
        }
      }
    });
  }

  static getInitialState = () => {
    return {
      awoCustomer: '',
      cwp: '',
      length: '',
      style: '',
      addedAWOSystemConfigurationRows: [],
      errors: {},
      equipmentNumber: '',
      isSaving: false,
      serviceLine: '',
      owner: '',
      equipmentRequiredDate: '',
      containerDescription: '',
      functionalLocation: '',
      pad: '',
      wellName: '',
      wellColor: '',
      system: '',
      awoDescription: '',
      addedAssemblyWorkOrderRows: [],
      minutes: '',
      parentChildList: [],
      file: '',
      uploadedFile: '',
      hasFile: false,
      awoId: '',
      awoHeaderId: '',
      fileName: '',
      fileDownloadUrl: '',
      awoNumber: '',
      deletedConfigIds: [],
      customerLocation : '',
      equipmentRequiredDate2 : ''
    };
  }

  createSystemConfigurationRow = (group, positionPackage, equipmentDescription, dependentEquipment, dependentChild, ischild, system_GUID, serial_GUID = null, serial_dependent_GUID , isAssemblyStarted = false , order) => {
    return {
      group: group,
      positionPackage: positionPackage,
      equipmentDescription: equipmentDescription,
      dependentEquipment: dependentEquipment,
      dependentChild: dependentChild,
      isChild: ischild,
      system_GUID: system_GUID,
      serial_GUID: serial_GUID,
      serial_dependent_GUID: serial_dependent_GUID,
      isAssemblyStarted : isAssemblyStarted,
      order : order
    };
  }

  createAssemblyWorkOrderRow = (awoDescription, awoCustomer, pad, wellName, system, cwp, equipmentRequiredDate, awoGroupRows, fileName, file) => {
    return {
      awoDescription: awoDescription,
      awoCustomer: awoCustomer,
      pad: pad,
      wellName: wellName,
      system: system,
      cwp: cwp,
      equipmentRequiredDate: equipmentRequiredDate,
      awoGroupRows: awoGroupRows,
      fileName: fileName,
      file: file
    };
  }

  getDefaultSystemConfigRow = () => {
    const defaultRowData = this.createSystemConfigurationRow('', '', '', '', 0, null, null,'');
    this.state.addedAWOSystemConfigurationRows.push(new AWOGroupRowController(this, defaultRowData));

  }

  getAWOParentChildList = () => {
    this.state.parentChildList.push({ id: 0, name: 'Parent' });
    this.state.parentChildList.push({ id: 1, name: 'Child' });
    this.commit();
  }

  handleAddNewRow = () => {
    const defaultRowData = this.createSystemConfigurationRow('', '', '', '', 0, null, null,'');
    this.state.addedAWOSystemConfigurationRows.push(new AWOGroupRowController(this, defaultRowData));
    this.commit();
  }

  mergeState = (state) => {
    this.state.customer = state.customer;
    this.state.cwp = state.cwp;
    this.state.equipmentNumber = state.equipmentNumber;
    this.state.addedAWOSystemConfigurationRows = state.addedAWOSystemConfigurationRows;
  }


  ownerChanged = () => {
    this.state.containerDescription = '';
    this.state.functionalLocation = '';
    this.commit();
  }

  functionalLocationChanged = () => {
    this.state.containerDescription = '';
    this.commit();
  }

  customerSearch = () => {
    return function* (searchTerm) {
      const results = yield ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }



  cwpSearch = () => {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchLookupList/CWP', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }



  load = (awoId) => {
    ajax.get('lookup/awo/getawobyid', { awoId: awoId }).then(awodetails => {
      const { well_Name: wellName, pad_WorkSite_Rig: pad, owner, cwp, customer,
        serviceLine, functionallocationdto: functionalLocation, container: containerDescription,
        well_Color_Systemdto: system, well_Color: wellColor, equipmentRequiredDate, awoDescription,
        awoSystemConfiguration: systemcofiguration, awoId, awoHeaderId, fileName, awoNumber, customer_Location : customerLocation
      } = awodetails;
      this.state.awoDescription = awoDescription;
      this.state.awoCustomer = customer;
      this.state.owner = owner;
      this.state.serviceLine = serviceLine;
      this.state.cwp = cwp;
      this.state.functionalLocation = functionalLocation;
      this.state.containerDescription = containerDescription;
      this.state.equipmentRequiredDate = DateTimeFormatter.formatDate(equipmentRequiredDate);
      this.state.pad = pad;
      this.state.wellName = wellName;
      this.state.wellColor = wellColor;
      this.state.system = system;
      this.state.awoId = awoId;
      this.state.awoHeaderId = awoHeaderId;
      this.state.fileName = fileName;
      this.state.fileDownloadUrl = `${contollerUrl}${awoId}`;
      this.state.awoNumber = awoNumber;
      this.state.customerLocation = customerLocation;
      this.state.equipmentRequiredDate2 = DateTimeFormatter.formatDate(equipmentRequiredDate);
      if (systemcofiguration) {

        var configs = systemcofiguration.map(x => {
          const { group: group, position_Package: positionPackage, equipment_Desc: equipmentDescription, dependent_Equipment: dependentEquipment, isChild, isEquipment_Depedent: dependentChild, system_GUID, serial_GUID, serial_dependent_GUID , isAssemblyStarted, order } = x;

          return new AWOGroupRowController(this, this.createSystemConfigurationRow(group, positionPackage, equipmentDescription, dependentEquipment, dependentChild, this.state.parentChildList.find(x => x.id === (isChild ? 1 : 0)), system_GUID, serial_GUID, serial_dependent_GUID , isAssemblyStarted,order))
        });

        this.state.addedAWOSystemConfigurationRows = configs;

      }


      this.commit();

    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }



  validateAWOs(state) {
    if (!state.minutes.length || state.minutes === 0) {
      notification.action('error').post('Minutes are required');
      return false;
    }
    return true;
  }

  canCreateAWOs = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
    /*
    if(this.state.equipmentRequiredDate !==  this.state.equipmentRequiredDate2)
    {
      var currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      var eqDate = new Date(this.state.equipmentRequiredDate);
     
      if(currentDate > eqDate)
      {    
        notification.action('error').post({ msg: "Equipment Required Date should greater than or equal to the today's date", title: 'Equipment Required Date Incorrect!' });   
        return false;
      }
    }
    */
    const errors = Object.keys(this.state.errors);
    if (!errors.length && this.validateExistingRows()) {

      return true;
    }
    
    this.commit();
    return false;
  }

  createAWOs = () => {   
    if (!this.canCreateAWOs()) {
      return;
    }
    co(this, function* () {
      const facilityId = LoginService.loginInfo ? LoginService.loginInfo.facilityId : '';
      const awoSystemConfigurationRows = this.state.addedAWOSystemConfigurationRows.map(configRow => {
        return {
          group_GUID: configRow.state.group?.id,
          position_Package_GUID: configRow.state.positionPackage?.id,
          equipment_Desc_GUID: configRow.state.equipmentDescription?.id,
          dependent_Equipment_GUID: configRow.state.dependentEquipment?.id,
          isEquipment_Depedent: configRow.state.dependentChild === 0 ? false : configRow.state.dependentChild,
          isChild: configRow.state.isChild ? configRow.state.isChild.id === 1 ? true : false : false,
          system_GUID: configRow.state?.system_GUID,
          order : configRow.state?.order
        }
      });
      const awodata = {
        awoCustomer: this.state.awoCustomer.id,
        owner_GUID: this.state.owner.id,
        awoDescription: this.state.awoDescription,
        serviceLine_GUID: this.state.serviceLine.id,
        cwp_GUID: this.state.cwp ? this.state.cwp.id : Utils.emptyGuid,
        equipmentRequiredDate: this.state.equipmentRequiredDate,
        functionalLocation: this.state.functionalLocation ? this.state.functionalLocation.name : '',
        pad_WorkSite_Rig: this.state.pad,
        well_Name: this.state.wellName,
        well_Color_GUID: this.state.wellColor ? this.state.wellColor.id : Utils.emptyGuid,
        facility_GUID: facilityId,
        well_Color_System: this.state.system ? this.state.system.id : Utils.emptyGuid,
        container_GUID: this.state.containerDescription ? this.state.containerDescription.id : Utils.emptyGuid,
        awoSystemConfiguration: awoSystemConfigurationRows,
        file: this.state.uploadedFile,
        awoId: this.state.awoId,
        awoHeaderId: this.state.awoHeaderId
      }



      try {
        this.state.isSaving = true;
        this.commit();
        if (this.state.deletedConfigIds.length > 0) {
          const r = yield ajax.post('awoprogess/deletesystemconfig', this.state.deletedConfigIds);
          if (r) {
            this.state.deletedConfigIds = [];
            this.commit();
          }
        }

        const results = yield ajax.post('awoprogess/editAWO', awodata);
        this.state.isSaving = false;
        this.commit();
        //if (!SAPService.isSAPFacility()) {
        // messages.channel('receiving').action('add-to-list').post(createdAssetIds);
        //}
        // notification.action('success').post(Utils.pluralize('asset', 's', ) + ' created successfully');
        if (results) {
          this.load(this.state.awoId);
          notification.action('success').post(`Updated AWO Number: ${this.state.awoNumber} successfully`);
        }
        else {
          notification.action('error').post('Unable to update AWO');
        }

      }
      catch (err) {
        this.state.isSaving = false;
        this.commit();
        notification.action('error').post(err.message);
      }
    });


  }

  isAWODescriptionUnique = (awoNumber) => {
    const parameters = {
      awoNumber: awoNumber
    };
    return ajax.get('lookup/isAWODescriptionUnique', parameters).then(isUnique => isUnique)
      .catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });

  }
  removeFromList = (item) => {
    co(this, function* () {
      var equipmentDescriptionName = item.state.equipmentDescription.name === undefined ? '' : item.state.equipmentDescription.name;
      var rowsInGroup = '';
      var bodyText = 'Please confirm you wish to remove ' + equipmentDescriptionName + '. Dependent Equipment will also be deleted.';

      if (item.state.isChild && item.state.isChild?.id === 0) {
        rowsInGroup = (this.state.addedAWOSystemConfigurationRows.filter(x => x.state.group.name === item.state.group.name && x.state.isChild.id === 1))
        if (rowsInGroup.length > 0) {
          bodyText = "This row is designated as parent and" + ' has child(ren). Please confirm you wish to remove Parent and Child(ren).';
        }
      }

      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Equipment'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      let idx = this.state.addedAWOSystemConfigurationRows.indexOf(item);
      if (idx === -1) {
        return;
      }
      this.state.addedAWOSystemConfigurationRows.splice(idx, 1);
      if (rowsInGroup.length > 0) {
        this.removeChildRows(rowsInGroup);
      }
      this.commit();
    });
  }

  removeFromConfigList = (item) => {
    var configRows = this.state.addedAWOSystemConfigurationRows;
    let idx = configRows.indexOf(item);
    if (idx === -1) {
      return;
    }
    configRows.splice(idx, 1);
    this.state.addedAWOSystemConfigurationRows = configRows;
    this.commit();
  }

  isInList = (primarySerialNumber) => {
    return this.state.addedSerialNumberRows.filter(row => row.state.primarySerialNumber === primarySerialNumber).length;
  }

  clearList = () => {
    co(this, function* () {
      var bodyText = 'Please confirm you wish to clear the System Configuration data. All rows will be removed and data will have to be recreated.';
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Clear System Configuration'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      ajax.get('awoprogess/deletesystemconfigbyawo', { awoId: this.state.awoId }).then(result => {
        if (result) {
          this.state.addedAWOSystemConfigurationRows = [];
          notification.action('success').post('System configuration deleted successfully');
          this.commit();
        }
      });
    });
  }

  isRowSelected = (row) => {
    return (this.state.selectedSerialNumberRow === row);
  }

  bornOnDateChanged = () => {
    this.commit();
  }

  primarySerialNumberChanged = () => {
    var primarySerialNumber = this.state.primarySerialNumber.replace(/^\s+|\s+$/g, '');
    this.state.primarySerialNumber = primarySerialNumber;
    document.getElementById('create-asset-primary-serial-number').value = primarySerialNumber;
    this.commit();
  }

  customerChanged = () => {
    this.state.matchingAssets = [];
    this.state.selectedMatchingAsset = '';
    this.commit();
  }

  uploadFile = (res) => {
    if (this.state.fileName) {
      this.state.file = '';
      this.state.uploadedFile = '';
      this.state.hasFile = false;
      this.state.fileName = '';
      this.commit();
    }
    if (res && (res.file.size / 1048576).toFixed(1) < 25) {
      let name = '';
      if (navigator.userAgent.match(/iPad/i) != null) {
        //name = res.file.name.split('.')[0] + '_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' }) + '.' + res.file.name.split('.')[1].toLowerCase();
        name = res.file.name;
      }
      else {
        name = res.file.name;
      }
      const newFile = {
        fileType: res.file.name.split('.')[1] || '',
        fileName: name,
        fileData: res.imageUrl
      };
      this.state.file = res;
      this.state.uploadedFile = newFile;
      this.state.hasFile = true;

      this.commit();
    }
    else {
      notification.action('error').post({ msg: 'File size is larger that 25 MB', title: 'File Error' });
    }

  }

  removeUploadedFile = () => {
    co(this, function* () {
      var bodyText = 'Please confirm you wish to remove this diagram- the file will be deleted.';

      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Diagram'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      if (this.state.fileName) {
        ajax.get('awoprogess/removedocument', { awoId: this.state.awoId }).then(result => {
          if (result) {
            notification.action('success').post('File removed successfully');
            this.state.fileName = '';
            this.state.hasFile = false;
            this.state.uploadedFile = '';
            this.commit();
          }
          else {
            notification.action('error').post('Unable to remove file');
          }
        });
      }
      else {
        this.state.file = '';
        this.state.uploadedFile = '';
        this.state.hasFile = false;
        this.commit();
      }
    });
  }
  removeSystemconfiguration(system_GUID) {
    co(this, function* () {
      const item = this.state.addedAWOSystemConfigurationRows.find(x => x.state.system_GUID === system_GUID);
      var arr = [];
      var rowsInGroup = '';
      var equipmentDescriptionName = item.state.equipmentDescription.name === undefined ? '' : item.state.equipmentDescription.name;
      var bodyText = 'Please confirm you wish to remove ' + equipmentDescriptionName + '. Dependent Equipment will also be deleted.';
      if (item.state.isChild.id === 0) {
        rowsInGroup = (this.state.addedAWOSystemConfigurationRows.filter(x => x.state.group.name === item.state.group.name && x.state.isChild.id === 1))
        if (rowsInGroup.length > 0) {
          if (rowsInGroup.filter(x => x.state.serial_GUID != Utils.emptyGuid || x.state.serial_dependent_GUID != Utils.emptyGuid).length > 0) {
            notification.action('error').post('The designated child configuration has assets assigned. Please unassign asset in order to delete it');
            return;
          }
          bodyText = "This row is designated as parent and" + ' has child(ren). Please confirm you wish to remove Parent and Child(ren).';
          arr = [...rowsInGroup.map(x => x.state.system_GUID)];
        }
      }
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Equipment'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      arr.push(system_GUID);
      let deletedConfigIds = this.state.deletedConfigIds;
      deletedConfigIds.push(...arr);
      this.state.deletedConfigIds = deletedConfigIds;
      this.commit();
      if (item) {
        this.removeFromConfigList(item);
        if (rowsInGroup.length > 0) {
          this.removeChildRows(rowsInGroup);
        }

        notification.action('success').post('System configuration deleted successfully');
      }
    });
  }
  close = () => {
    co(this, function* () {
      var bodyText = 'Please confirm you wish to close the Edit Assembly Work Order page. All data will be lost if not previously saved.';

      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Close Edit Assembly Work Order'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      window.history.back();
    });
  }
  validateExistingRows = () => {
    var isvalid = true;
    const rows = this.state.addedAWOSystemConfigurationRows;
    if (rows.length > 0) {
      rows.forEach(row => {
        const errors = row.validate();
        const errorList = Object.keys(errors);
        if (errorList.length > 0) {
          notification.action('error').post(Object.values(errors).join(' and '))
          isvalid = false;
        }
      });
      if (!isvalid) {
        return isvalid;
      }
    }
    var groupIdList = [...new Set(this.state.addedAWOSystemConfigurationRows.map(x => x.state.group.name))];
    groupIdList.map(grpName => {
      const rowsInGroup = (this.state.addedAWOSystemConfigurationRows.filter(x => x.state.group.name === grpName && x.state.isChild.id === 0))
      if (rowsInGroup === undefined || rowsInGroup.length === 0) {
        notification.action('error').post({ msg: 'Group- ' + grpName + ' does not have a parent row', title: 'Error' });
        isvalid = false;
      }
    });
      
    const configRows = rows.sort((a, b) => {
        return (b.state.order.toString() - a.state.order.toString());
          })
    var orderLookup =  Array.from({length:configRows.length},(v,k)=>k+1)
    var actualorder = new Set(this.state.addedAWOSystemConfigurationRows.map(x => parseInt(x.state.order)))
    if(orderLookup.length != actualorder.size)
    {
      isvalid = false;
      notification.action('error').post('No Duplicate order number allowed. Please assign increasing values for Order. Every configuration should have a unique value starting from 1 (one)');      
    }
    if(configRows.filter(x=> !orderLookup.includes(parseInt(x.state.order))).length > 0)
    {
      isvalid = false;
      notification.action('error').post('Please assign increasing values for Order. Every configuration should have a unique value starting from 1 (one)');
    }
      
    return isvalid;
  }
  removeChildRows = (rowsInGroup) => {
    var configRows = this.state.addedAWOSystemConfigurationRows;
    var finalRows = configRows.filter(v => {
      return !rowsInGroup.includes(v);
    });
    this.state.addedAWOSystemConfigurationRows = finalRows;
    this.commit();
  }
  getgroupAssemblyDetails = (groupId, rowcontroller) => {
    var hasMultipleParent = this.state.addedAWOSystemConfigurationRows.filter(x => x.state?.isChild?.id === 0 && x.state?.group?.id === groupId).length > 1 ? true : false;
    if (hasMultipleParent) {
      notification.action("error").post(`Group - ${rowcontroller.state.group.name} has more than one Parent`);
      rowcontroller.resetGroup();
      return;
    }
    ajax.get('lookup/awo/getassemblydetailsforgroupbyawo', { awoId: this.state.awoId, groupId: groupId }).then(result => {
      if (result) {
        const { IsAssemblyApplied, IsOperationStarted } = result;
        if (!IsOperationStarted && IsAssemblyApplied) {
          notification.action("warning").post(`Assembly Level is assigned to Group ${rowcontroller.state.group.name} , you will need to Reapply Assembly Level from the Assembly Module before proceeding with Assembly on that group.`)
          return;
        }
        if (IsOperationStarted) {
          notification.action("error").post(`Operations have been started for Group ${rowcontroller.state.group.name} , no configuration can be added for the group`);
          rowcontroller.resetGroup();
        }
      }
      else {
        notification.action('error').post('Unable to remove file');
      }
    })
  }
  isDuplicateParent = (groupId, rowcontroller) => {
    let configs = this.state.addedAWOSystemConfigurationRows;
    let group_parent_config = configs.filter(x => x.state.isChild.id === 0 && x.state.group.id === groupId);
    if (group_parent_config && group_parent_config.length > 1) {
      rowcontroller.resetParent();
      notification.action('error').post('The group already has parent configuration');
    }

  }

  getSelectedParentGroups = () => {
    var s = this.state;
    var groups = s.addedAWOSystemConfigurationRows?.filter(y => y.state.group?.name !== undefined && y.state.isChild?.id === 0).map(x => x.state.group?.name);
    return groups;
  }
  getStartedAssembly = () => {
    var s = this.state;
    var groups = s.addedAWOSystemConfigurationRows?.filter(y => y.state?.isAssemblyStarted === true && y.state.isChild?.id === 0).map(x => x.state.group?.name);
    return groups;
  }
  customerLocationSearch = () => {
    const self = this;
    return function* (searchTerm) {
      const customerId = (self.state.owner && self.state.owner.id);
      if (!customerId) {
        notification.action('warning').post('Please select a Owner so you can choose a Customer Location.');
        return null;
      }
      const facilityId = LoginService.loginInfo.facilityId;
      const results = yield ajax.get('lookup/searchCustomerLocations', { facilityId: facilityId, customerId: customerId, searchTerm: searchTerm }); // IList<CustomerLocationLookupDto>
      return results;
    };
  }
}

export default EditAWOController;
