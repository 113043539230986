import React from 'react';
import BaseController from '../../lib/BaseController';
import store from 'store';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import SerialNumberRowController from '../controllers/SerialNumberRowController';
import TfmcAssetsSerialNumberRowController from '../controllers/TfmcAssetsSerialNumberRowController';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';

class CreateNewAssetController extends BaseController {
  constructor() {
    super('create-new-asset', CreateNewAssetController.getInitialState());

    this._validator = new Validator();
    this._validator.add('customerLocation', 'Customer Location', [{ required: true }]);
    this._validator.add('inServiceDate', 'In Service Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: DateTimeFormatter.today() } }]);
    
    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state = CreateNewAssetController.getInitialState();
        this.load();
      }
    });

    messages.channel('create-new-asset').action('updated').subscribe(data => {
      if (SAPService.isSAPFacility() && data.assetId) {
        var loginInfo = store.get('InteServLoginInfo');
        if (loginInfo.userId = data.userId) {
          messages.channel('receiving').action('add-to-list').post([data.assetId]);
        }
      }
    });
    }

  static getInitialState = () => {
    return {
      allCustomer: '',
      customer: '',
      mfgPartNumber: '',
      familyCode: '',
      manufacturer: '',
      nominalSize: '',
      cwp: '',
      length: '',
      style: '',
      primarySerialNumber: '',
      secondarySerialNumber: '',
      batchNumber: '',
      bornOnDate: '',
      inServiceDate: '',
      customerLocation: '',
      numberToCreate: 1,
      matchingAssets: [],
      selectedMatchingAsset: '',
      addedSerialNumberRows: [],
      selectedSerialNumberRow: '',
      errors: {},
      equipmentNumber: '',
      isSaving: false.assetId,
      isFmcCustomer: false,
      isNonFMCCustomer: false,
      intenalCustomers: [],
      showAllCustomer: true,
      category: '',
      maintPlant: '',
      //companyCode: 'EWHP',
      objectType: '',
      acquisitionValue: '',
      currency: '',
      manufacturer: '',
      inventoryNumber: '',
      mfgCountry: '',
      assetNumber: '',
      costCenter: '',
    };
  }
 

  // pull list of internal customers
  load() {

    co(this, function* () {
      this.state=CreateNewAssetController.getInitialState();
      var results = yield ajax.get('lookup/getInternalCustomerList/', null); // ReceivedAssetDto
      if (results !== null) {
        this.state.intenalCustomers = results;
        this.commit();
      }
    });


  }

  mergeState = (state) => {
    this.state.customer = state.customer;
    this.state.mfgPartNumber = state.mfgPartNumber;
    this.state.familyCode = state.familyCode;
    this.state.manufacturer = state.manufacturer;
    this.state.nominalSize = state.nominalSize;
    this.state.cwp = state.cwp;
    this.state.length = state.length;
    this.state.style = state.style;
    this.state.primarySerialNumber = state.primarySerialNumber;
    this.state.secondarySerialNumber = state.secondarySerialNumber;
    this.state.batchNumber = state.batchNumber;
    this.state.bornOnDate = state.bornOnDate;
    this.state.inServiceDate = state.inServiceDate;
    this.state.customerLocation = state.customerLocation;
    this.state.numberToCreate = state.numberToCreate;
    this.state.matchingAssets = [];
    this.state.selectedMatchingAsset = '';
    this.state.addedSerialNumberRows = [];
    this.state.selectedSerialNumberRow = '';
    this.state.errors = {};
    this.state.equipmentNumber = state.equipmentNumber;
  }

  mfgPartNumberChanged = () => {
    if (this.state.isNonFMCCustomer) {
      this.handleSearch(AppConstants.eSearchType.MfgPartNumber);
    } else {
      this.handleMfgPartNumberSearch();
    }
  }

  customerSearch = () => {
    return function* (searchTerm) {
      const results = yield ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  tfmcCustomerSearch = () => {
    return function* (searchTerm) {
      const results = yield ajax.get('lookup/searchTFMCCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  nonTfmcCustomerSearch = () => {
    return function* (searchTerm) {
      const results = yield ajax.get('lookup/searchNonTFMCCustomerList', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  mfgItemSearch = () => {
    const self = this;
    return function* (searchTerm) {
      // Mfg. Item #
      if (!self.state.customer) {
        notification.action('warning').post('You must specify a customer prior to looking up assets by Mfg Part #');
        return null;
      }

      const results = yield ajax.get('lookup/searchLookupList/Mfg%20Item%20%23', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  familyCodeSearch = () => {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchLookupList/Family%20Code', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  manufacturerSearch = () => {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchLookupList/Manufacturer', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  nominalSizeSearch = () => {
    return function* (searchTerm) {
      // Nominal Size
      var results = yield ajax.get('lookup/searchLookupList/Nominal%20Size', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  cwpSearch = () => {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchLookupList/CWP', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  lengthSearch = () => {
    return function* (searchTerm) {
      const results = yield ajax.get('lookup/searchLookupList/Length', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  styleSearch = () => {
    return function* (searchTerm) {
      const results = yield ajax.get('lookup/searchLookupList/Style', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  customerLocationSearch = () => {
    var self = this;
    return function* (searchTerm) {
      const customerId = (self.state.selectedMatchingAsset && self.state.selectedMatchingAsset.customerId);
      if (customerId === '') {
        if (self.state.matchingAssets.length === 0) {
          notification.action('warning').post('An entry in the Matching Descriptions list is required. Perform a search and select one of the entries so you can choose a Customer Location.');
        }
        else {
          notification.action('warning').post('Select one of the entries in the Matching Descriptions list first so you can choose a Customer Location.');
        }
        return null;
      }
      const facilityId = LoginService.loginInfo ? LoginService.loginInfo.facilityId : '';
      const results = yield ajax.get('lookup/searchCustomerLocations', { facilityId: facilityId, customerId: customerId, searchTerm: searchTerm }); // IList<CustomerLocationLookupDto>
      return results;
    };
  }


  customerLocationSearchForTFMCAssets = () => {
    var self = this;
    return function* (searchTerm) {
      const customerId = self.state.customer.id;
      const facilityId = LoginService.loginInfo ? LoginService.loginInfo.facilityId : '';
      const results = yield ajax.get('lookup/searchCustomerLocations', { facilityId: facilityId, customerId: customerId, searchTerm: searchTerm }); // IList<CustomerLocationLookupDto>
      return results;
    };
  }

  //load = (searchTerm) => {
  // this.mergeState(this.getInitialState());
  // if (searchTerm !== '_') {
  //   this.state.primarySerialNumber = searchTerm;
  // }
  // this.commit();
  //}

  selectAsset = (asset) => {
    if (this.state.selectedMatchingAsset.customerId !== asset.customerId) {
      this.state.customerLocation = '';
    }
    this.state.selectedMatchingAsset = asset;
    this.commit();
  }

  selectAddedSerialNumberRow = (item) => {
    if (item && !this.isInList(item.state.primarySerialNumber)) {
      return;
    }
    this.state.selectedSerialNumberRow = item;
    this.commit();
  }

  handleSearch = (searchType) => {
    if (!this.state.customer) {
      notification.action('warning').post('You must specify a customer prior to looking up assets');
      return null;
    }

    const parameters = {
      customerId: this.state.customer.id,
      customerName: this.state.customer.name
    };

    switch (searchType) {
      case AppConstants.eSearchType.MfgPartNumber:
        const mfgPartNumber = this.state.mfgPartNumber ? this.state.mfgPartNumber.name : 'no-mfg-item';
        parameters.mfgPartNumber = mfgPartNumber;
        break;

      case AppConstants.eSearchType.Properties:
        this.state.mfgPartNumber = '';
        parameters.familyCodeId = this.state.familyCode ? this.state.familyCode.id : Utils.emptyGuid;
        parameters.manufacturerId = this.state.manufacturer ? this.state.manufacturer.id : Utils.emptyGuid;
        parameters.nominalSizeId = this.state.nominalSize ? this.state.nominalSize.id : Utils.emptyGuid;
        parameters.cwpId = this.state.cwp ? this.state.cwp.id : Utils.emptyGuid;
        parameters.lengthId = this.state.length ? this.state.length.id : Utils.emptyGuid;
        parameters.styleId = this.state.style ? this.state.style.id : Utils.emptyGuid;
        break;

      default:
        break;
    }

    co(this, function* () {
      try {
        const results = yield ajax.get('lookup/getAssets', parameters); // IList<NewAssetLookupDto>
        this.state.matchingAssets = results;
        this.state.selectedMatchingAsset = (results.length === 1) ? results[0] : '';
        if (this.state.isNonFMCCustomer) {
          this.state.customerLocation = '';
        }
      }
      catch (err) {
        notification.action('error').post(err.message);
        this.state.matchingAssets = [];
        this.state.selectedMatchingAsset = '';
      }
      this.commit();
    });
  }

  handleMfgPartNumberSearch = () => {
    if (this.state.mfgPartNumber === null) {
      this.state.manufacturer=""; // reset manufacturer field
      return;
    }
    if (!this.state.customer) {
      notification.action('warning').post('You must specify a customer prior to looking up assets');
      return null;
    }

    const parameters = {
      customerId: this.state.customer.id,
      customerName: this.state.customer.name
    };

    const mfgPartNumber = this.state.mfgPartNumber ? this.state.mfgPartNumber.name : 'no-mfg-item';
    parameters.mfgPartNumber = mfgPartNumber;

    co(this, function* () {
      try {
        const results = yield ajax.get('lookup/getAssets', parameters); // IList<NewAssetLookupDto>
        this.state.matchingAssets = results;
        this.state.selectedMatchingAsset = (results.length === 1) ? results[0] : '';
        if (this.state.isNonFMCCustomer) {
          this.state.customerLocation = '';
        }
        if (results.length > 1) {
          notification.action('warning').post('Selected Mfg Part # has more than one description.');
        }
        this.state.manufacturer=this.state.selectedMatchingAsset?.manufacturerName;
      }
      catch (err) {
        notification.action('error').post(err.message);
        this.state.matchingAssets = [];
        this.state.selectedMatchingAsset = '';
      }
      this.commit();
    });
  }

  validateRows(state) {
    if (!state.addedSerialNumberRows.length) {
      state.errors.addedSerialNumberRows = 'At least one row must be specified';
      return;
    }
    state.addedSerialNumberRows.forEach(row => row.validate());
  }

  canCreateAssets = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
    this.validateRows(this.state);

    const errors = Object.keys(this.state.errors);
    const rowErrors = this.state.addedSerialNumberRows.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!errors.length && !rowErrors) {
      return true;
    }

    this.commit();
    return false;
  }

  createAssets = () => {
    if (!this.canCreateAssets()) {
      return;
    }

    const notUniqueCount = this.state.addedSerialNumberRows.filter(snr => !snr.state.isPrimaryUnique).length;

    co(this, function* () {
      if (notUniqueCount) {
        const message1 = (notUniqueCount === 1)
          ? 'There is a proposed serial number that is already in use and will not be created'
          : 'There are ' + notUniqueCount + ' proposed serial numbers that are already in use and will not be created';

        const result = yield Dialog.showDialogWaitForResult(
          <Dialog.OKCancelDialog width={700} height={325} header='Confirm Asset Creation'>
            <div>
              <h4 style={{ marginTop: '-20px' }}>{message1}</h4>
              <h5 style={{ marginBottom: '10px' }}>Please confirm you wish to continue</h5>
            </div>
          </Dialog.OKCancelDialog>
        );
        if (!result.OK) {
          return;
        }
      }

      const inServiceDate = this.state.inServiceDate;
      const customerLocationId = this.state.customerLocation.id;
      const customerDescriptionId = this.state.selectedMatchingAsset.id;
      const assetData = this.state.addedSerialNumberRows.map(row => {
        return {
          primarySerialNumber: row.state.primarySerialNumber,
          secondarySerialNumber: row.state.secondarySerialNumber,
          equipmentNumber: row.state.equipmentNumber,
          batchNumber: row.state.batchNumber,
          bornOnDate: row.state.bornOnDate
        };
      });
      const newAssets = {
        inServiceDate: inServiceDate,
        customerLocationId: customerLocationId,
        currentLocationId: customerLocationId,     // retrieved just customerLocation from user - repurpose as currentLocation
        customerDescriptionId: customerDescriptionId,
        assets: assetData
      };

      try {
        this.state.isSaving = true;
        this.commit();
        const createdAssetIds = yield ajax.post('receiving/createAsset', newAssets); // in: CreateAssetDto, out: ReceivedAssetDto
        this.state.isSaving = false;
        this.commit();
        //if (!SAPService.isSAPFacility()) {
        messages.channel('receiving').action('add-to-list').post(createdAssetIds);
        //}

        this.prepareForNextAsset();
        notification.action('success').post(Utils.pluralize('asset', 's', createdAssetIds.length) + ' created successfully');
        //notification.action('info').post('Added ' + Utils.pluralize('asset', 's', createdAssetIds.length) + ' to Received Assets list');
        if (notUniqueCount > 0) {
          notification.action('warning').post(notUniqueCount + ' with a serial number already in use ' + (notUniqueCount === 1 ? 'was' : 'were') + ' not');
        }
      }
      catch (err) {
        this.state.isSaving = false;
        this.commit();
        notification.action('error').post(err.message);
      }
    });
  }


  createTfmcAssets = () => {
    if (!this.canCreateTfmcAssets()) {
      return;
    }

    const notUniqueCount = this.state.addedSerialNumberRows.filter(snr => !snr.state.isPrimaryUnique).length;

    co(this, function* () {
      if (notUniqueCount) {
        const message1 = (notUniqueCount === 1)
          ? 'There is a proposed serial number that is already in use and will not be created'
          : 'There are ' + notUniqueCount + ' proposed serial numbers that are already in use and will not be created';

        const result = yield Dialog.showDialogWaitForResult(
          <Dialog.OKCancelDialog width={700} height={325} header='Confirm Asset Creation'>
            <div>
              <h4 style={{ marginTop: '-20px' }}>{message1}</h4>
              <h5 style={{ marginBottom: '10px' }}>Please confirm you wish to continue</h5>
            </div>
          </Dialog.OKCancelDialog>
        );
        if (!result.OK) {
          return;
        }
      }
      const customerDescriptionId = this.state.selectedMatchingAsset.id;
      if(customerDescriptionId=== undefined){
        notification.action('error').post("Selected MFG Part # is not available for customer- "+this.state.customer.name);
        return; 
      }

      const inServiceDate = this.state.inServiceDate;
      const customerLocationId = this.state.customerLocation.id;
      const category = this.state.category;
      const maintPlant = this.state.maintPlant;
      // const companyCode = this.state.companyCode;
      const customer_GUID = this.state.customer.id;
      const assetData = this.state.addedSerialNumberRows.map(row => {
        return {
          primarySerialNumber: row.state.primarySerialNumber,
          objectTypeId: row.state.objectType.id,
          objectType: row.state.objectType.name,
          bornOnDate: row.state.bornOnDate,
          inventoryNumber: row.state.inventoryNumber,
          acquisitionValue: row.state.acquisitionValue,
          currency: row.state.currency.name,
          manufacturer: row.state.manufacturer,
          mfgCountry: row.state.mfgCountry.name,
          assetNumber: row.state.assetNumber,
          costCenter: row.state.costCenter.name,
          costCenterId: row.state.costCenter.id,
          customerDescriptionId: row.state.customerDescriptionId,
          mfgPartNumber: row.state.mfgPartNumber.name
        };
      });
      const newAssets = {
        isInternalCustomer: this.state.isFmcCustomer,
        inServiceDate: inServiceDate,
        customerLocationId: customerLocationId,
        currentLocationId: customerLocationId, 
        category: category,
        maintPlant: maintPlant,
        //companyCode: companyCode,
        customerDescriptionId: customerDescriptionId,
        Customer_GUID: customer_GUID,
        assets: assetData
      };

      try {
        this.state.isSaving = true;
        this.commit();
        const createdAssetIds = yield ajax.post('receiving/createAsset', newAssets); // in: CreateAssetDto, out: ReceivedAssetDto
        this.state.isSaving = false;
        this.commit();
        //if (!SAPService.isSAPFacility()) {
        messages.channel('receiving').action('add-to-list').post(createdAssetIds);
        //}

        this.prepareForNextTFMCAsset();
        notification.action('success').post(Utils.pluralize('asset', 's', createdAssetIds.length) + ' created successfully');
        //notification.action('info').post('Added ' + Utils.pluralize('asset', 's', createdAssetIds.length) + ' to Received Assets list');
        if (notUniqueCount > 0) {
          notification.action('warning').post(notUniqueCount + ' with a serial number already in use ' + (notUniqueCount === 1 ? 'was' : 'were') + ' not');
        }
      }
      catch (err) {
        this.state.isSaving = false;
        this.commit();
        notification.action('error').post(err.message);
      }
    });
  }


  canCreateTfmcAssets = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
    this.validateRows(this.state);

    const errors = Object.keys(this.state.errors);
    const rowErrors = this.state.addedSerialNumberRows.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!errors.length && !rowErrors) {
      return true;
    }

    this.commit();
    return false;
  }


  prepareForNextTFMCAsset = () => {
    this.state.primarySerialNumber = '';
    this.state.secondarySerialNumber = '';
    this.state.bornOnDate = '';
    this.state.addedSerialNumberRows = [];
    this.state.mfgCountry = '';
    this.state.costCenter = '';
    this.state.mfgPartNumber = '';
    this.state.manufacturer = '';
    this.state.objectType = '';
    this.state.inventoryNumber = '';
    this.state.acquisitionValue = '';
    this.state.assetNumber = '';
    this.state.currency='';
    this.selectAddedSerialNumberRow('');
    this.commit();
  }
  prepareForNextAsset = () => {
    this.state.primarySerialNumber = '';
    this.state.secondarySerialNumber = '';
    this.state.bornOnDate = '';
    this.state.addedSerialNumberRows = [];
    this.state.inServiceDate = '';
    this.state.customerLocation = '';
    this.selectAddedSerialNumberRow('');
    this.commit();
  }
  createAddedSerialNumber = (primarySerialNumber, isPrimaryUnique, customerName, currentLocationName, secondarySerialNumber, isSecondaryUnique, batchNumber, bornOnDate) => {
    return {
      primarySerialNumber: primarySerialNumber,
      customerName: customerName,
      currentLocationName: currentLocationName,
      secondarySerialNumber: secondarySerialNumber,
      equipmentNumber: '',
      batchNumber: batchNumber,
      bornOnDate: bornOnDate,
      isPrimaryUnique: isPrimaryUnique,
      isSecondaryUnique: isSecondaryUnique
    };
  }

  createAddedTFMCSerialNumber = (primarySerialNumber, isPrimaryUnique, mfgPartNumber, objectType, acquisitionValue, currency, manufacturer, mfgCountry, inventoryNumber, assetNumber, costCenter, bornOnDate, customerDescriptionId) => {
    return {
      primarySerialNumber: primarySerialNumber,
      mfgPartNumber: mfgPartNumber,
      objectType: objectType,
      acquisitionValue: acquisitionValue,
      currency: currency,
      manufacturer: manufacturer,
      mfgCountry: mfgCountry,
      inventoryNumber: inventoryNumber,
      bornOnDate: bornOnDate,
      assetNumber: assetNumber,
      costCenter: costCenter,
      isPrimaryUnique: isPrimaryUnique,
      customerDescriptionId: customerDescriptionId
    };
  }

  getReferenceSerialNumberRow = () => {
    let referenceSerialNumberRow = this.state.selectedSerialNumberRow;
    if (!referenceSerialNumberRow) {
      referenceSerialNumberRow = this.state.addedSerialNumberRows[this.state.addedSerialNumberRows.length - 1];
      if (!referenceSerialNumberRow) {
        return undefined;
      }
    }
    return referenceSerialNumberRow;
  }

  canAddToList = () => {
    let canAddToList = true;
    if (!this.state.primarySerialNumber) {
      notification.action('warning').post('Primary Serial # is required');
      canAddToList = false;
    }
    if (!this.state.bornOnDate) {
      notification.action('warning').post('Born On Date is required');
      canAddToList = false;
    }
    return canAddToList;
  }

  canAddToTfmcAssetList = () => {
    let canAddToList = true;
    if (!this.state.primarySerialNumber) {
      notification.action('warning').post('Primary Serial # is required');
      canAddToList = false;
    }
    if (!this.state.bornOnDate) {
      notification.action('warning').post('Born On Date is required');
      canAddToList = false;
    }
   // if (!this.state.assetNumber) {
   //   notification.action('warning').post('Asset Number is required');
   //   canAddToList = false;
   // }
    if (this.state.assetNumber && this.state.assetNumber.length < 7) {
      notification.action('warning').post('Asset Number must contain 7 digits');
      canAddToList = false;
    }
    if (this.state.assetNumber && this.state.assetNumber.length > 7) {
      notification.action('warning').post('Asset Number must contain 7 digits');
      canAddToList = false;
    }
    if (this.state.costCenter === null || this.state.costCenter === '') {
      notification.action('warning').post('Cost Center is required');
      canAddToList = false;
    }
    if (this.state.mfgPartNumber === null || this.state.mfgPartNumber === '') {
      notification.action('warning').post('Mfg Part # is required');
      canAddToList = false;
    }
    if (!this.state.acquisitionValue) {
      notification.action('warning').post('Acquisition value is required');
      canAddToList = false;
    }
    if (this.state.currency === null || this.state.currency === '') {
      notification.action('warning').post('Currency is required');
      canAddToList = false;
    }

    return canAddToList;
  }

  addToTfmcAssetList = () => {
    if (!this.canAddToTfmcAssetList()) {
      return;
    }

    // check for uniqueness of primary serial number and, optionally, secondary serial number
    const primarySerialNumber = this.state.primarySerialNumber.toUpperCase();
    this.isSerialNumberUnique(primarySerialNumber, AppConstants.eSerialNumberType.Primary).then(primarySerialNumberUnique => {
      const customerDescriptionId = this.state.selectedMatchingAsset.id;

      const alreadyInList = this.isInList(primarySerialNumber);
      if (alreadyInList) {
        notification.action('error').post('Duplicate Primary Serial #');
      }
      if (!alreadyInList && (this.state.addedSerialNumberRows.length < 50)) {
        const serialNumberRowData = this.createAddedTFMCSerialNumber(this.state.primarySerialNumber.toUpperCase(), primarySerialNumberUnique.isUnique, this.state.mfgPartNumber, this.state.objectType, this.state.acquisitionValue, this.state.currency, this.state.manufacturer, this.state.mfgCountry, this.state.inventoryNumber, this.state.assetNumber, this.state.costCenter, this.state.bornOnDate, customerDescriptionId);
        this.state.addedSerialNumberRows.push(new TfmcAssetsSerialNumberRowController(this, serialNumberRowData));
      }
      this.selectAddedSerialNumberRow('');
      this.commit();
    });

  }


  isSerialNumberUnique = (serialNumber, serialNumberType) => {
    const parameters = {
      serialNumber: serialNumber,
      serialNumberType: serialNumberType
    };
    return ajax.get('lookup/isSerialNumberUnique', parameters).then(isUnique => isUnique)
      .catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  addToList = () => {
    if (!this.canAddToList()) {
      return;
    }

    // check for uniqueness of primary serial number and, optionally, secondary serial number
    const primarySerialNumber = this.state.primarySerialNumber.toUpperCase();
    this.isSerialNumberUnique(primarySerialNumber, AppConstants.eSerialNumberType.Primary).then(primarySerialNumberUnique => {
      let isSecondaryUnique = true;
      const secondarySerialNumber = this.state.secondarySerialNumber.toUpperCase();
      if (secondarySerialNumber) {
        this.isSerialNumberUnique(secondarySerialNumber, AppConstants.eSerialNumberType.Secondary).then(secondarySerialNumberUnique => {
          isSecondaryUnique = secondarySerialNumberUnique.isUnique;
        });
      }
      const alreadyInList = this.isInList(primarySerialNumber);
      if (!alreadyInList && (this.state.addedSerialNumberRows.length < 50)) {
        const serialNumberRowData = this.createAddedSerialNumber(this.state.primarySerialNumber.toUpperCase(), primarySerialNumberUnique.isUnique, primarySerialNumberUnique.customerName, primarySerialNumberUnique.currentLocationName, this.state.secondarySerialNumber.toUpperCase(), isSecondaryUnique, this.state.batchNumber, this.state.bornOnDate);
        this.state.addedSerialNumberRows.push(new SerialNumberRowController(this, serialNumberRowData));
      }
      this.selectAddedSerialNumberRow('');
      this.commit();
    });

  }

  removeFromList = (item) => {
    let idx = this.state.addedSerialNumberRows.indexOf(item);
    if (idx === -1) {
      return;
    }
    this.state.addedSerialNumberRows.splice(idx, 1);
    this.selectAddedSerialNumberRow('');
    this.commit();
  }

  isInList = (primarySerialNumber) => {
    return this.state.addedSerialNumberRows.filter(row => row.state.primarySerialNumber === primarySerialNumber).length;
  }

  addMultiple = () => {
    const referenceSerialNumberRow = this.getReferenceSerialNumberRow();
    if (!referenceSerialNumberRow) {
      return;
    }

    const referenceBornOnDate = referenceSerialNumberRow.state.bornOnDate;

    const parameters = {
      basePrimarySerialNumber: referenceSerialNumberRow.state.primarySerialNumber,
      secondarySerialNumber: referenceSerialNumberRow.state.secondarySerialNumber,
      batchNumber: referenceSerialNumberRow.state.batchNumber,
      numberToGenerate: this.state.numberToCreate
    };

    ajax.get('lookup/getSequentialSerialNumbers', parameters).then(results => {
      if (!results || !results.length) {
        return;
      }

      results.forEach(result => {
        const primarySerialNumber = result.primarySerialNumber;
        const customerName = result.customerName;
        const currentLocationName = result.currentLocationName;
        const secondarySerialNumber = result.secondarySerialNumber;
        const isPrimaryUnique = result.isPrimaryUnique;
        const isSecondaryUnique = result.isSecondaryUnique;
        const batchNumber = result.batchNumber;
        const alreadyInList = this.isInList(primarySerialNumber);
        if (!alreadyInList && (this.state.addedSerialNumberRows.length < 50)) {
          var serialNumberRowData = this.createAddedSerialNumber(primarySerialNumber, isPrimaryUnique, customerName, currentLocationName, secondarySerialNumber, isSecondaryUnique, batchNumber, referenceBornOnDate);
          this.state.addedSerialNumberRows.push(new SerialNumberRowController(this, serialNumberRowData));
        }
      });
      this.selectAddedSerialNumberRow('');
      this.commit();
    })
  }

  clearList = () => {
    this.state.addedSerialNumberRows = [];
    this.state.numberToCreate = 1;
    this.commit();
  }

  isRowSelected = (row) => {
    return (this.state.selectedSerialNumberRow === row);
  }

  bornOnDateChanged = () => {
    this.commit();
  }

  primarySerialNumberChanged = () => {
    var primarySerialNumber = this.state.primarySerialNumber.replace(/^\s+|\s+$/g, '');
    this.state.primarySerialNumber = primarySerialNumber;
    document.getElementById('create-asset-primary-serial-number').value = primarySerialNumber;
    this.commit();
  }

  // customerChanged = () => {
  //   this.state.matchingAssets = [];
  //   this.state.selectedMatchingAsset = '';
  //   this.commit();
  // }

  allCustomerChanged = () => {
    // this.handleSearch(AppConstants.eSearchType.MfgPartNumber);
    var selectedCustomer = this.state.allCustomer;
    if (selectedCustomer === null) {
      this.state.isInternalCustomer = true;
      this.state.isNonFMCCustomer = false;
      this.state.showAllCustomer = true;
      this.commit();
    } else {
      var isInternalCustomer = Utils.arrayContains(this.state.intenalCustomers.map(c => c.id), selectedCustomer.id)
      if (isInternalCustomer) {
        this.state.isFmcCustomer = true;
        this.state.isNonFMCCustomer = false;
        this.state.showAllCustomer = false;
        this.state.category = 'R'

        const facility = store.get('currentFacility');
        this.state.maintPlant = facility.plantCode;
      } else {
        this.state.isFmcCustomer = false;
        this.state.isNonFMCCustomer = true;
        this.state.showAllCustomer = false;
      }
      this.state.customer = this.state.allCustomer;
      this.commit();
    }
  }

  customerChanged = () => {
    const isSAPFacility = SAPService.isSAPFacility();
    const selectedCustomer = this.state.customer;
    this.state = CreateNewAssetController.getInitialState();
    if (isSAPFacility) {
      this.load();
      this.state.showAllCustomer = true;
      this.state.isFmcCustomer = false;
      this.state.isNonFMCCustomer = false;
    } else {
      this.state.customer = selectedCustomer;
    }
    this.commit();
  }

  objectTypeSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Object%20Type', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  currencySearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Currency', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  mfgCountrySearch = () => {
    return (searchTerm) => ajax.get('lookup/searchLookupList/Mfg%20Country', { searchTerm: searchTerm })
      .then(result => result).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

}

export default CreateNewAssetController;
