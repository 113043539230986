import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectCustomerLocationFacilitiesController extends BaseController {
  constructor(customerLocation) {
    super('change-customer-location-facilities', {
      customerLocation: customerLocation,
      searchText: '',
      searchText1: ''
    });

    this.loadFacilities();
    this.loadCustomerLocationFacilities(customerLocation.id);
  }

  loadFacilities = () => {
    ajax.get('lookup/getAllFacilities').then(results => {
      this.state.allFacilities = results;
      this.commit();
    }).then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<FacilityLookupDto>
  }

  loadCustomerLocationFacilities = (customerLocationId) => {
    const parameters = {
      customerLocationId: customerLocationId
    };
    ajax.get('lookup/getCustomerLocationFacilities', parameters).then(results => {
      this.state.customerLocationFacilities = results;
      this.commit();
    }).then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<FacilityLookupDto>
  }

  selectNoFacilities = () => {
    this.state.allFacilities.forEach(facility => {
      facility.selected = false;
    });
    this.commit();
  }

  selectNoCustomerLocationFacilities = () => {
    this.state.customerLocationFacilities.forEach(facility => {
      facility.selected = false;
    });
    this.commit();
  }

  clearSearch = () => {
    this.state.searchText = '';
    this.commit();
  }

  clearSearch1 = () => {
    this.state.searchText1 = '';
    this.commit();
  }

  selectAllFacilities = () => {
    this.state.allFacilities.forEach(facility => {
      facility.selected = true;
    });
    this.commit();
  }

  selectAllCustomerLocationFacilities = () => {
    this.state.customerLocationFacilities.forEach(facility => {
      facility.selected = true;
    });
    this.commit();
  }

  toggleSelection = (facility) => {
    facility.selected = !facility.selected;
    this.commit();
  }

  moveToSelected = () => {
    this.state.allFacilities.forEach(facility => {
      if (facility.selected) {
        facility.selected = false;

        let found = false;
        for (let i in this.state.customerLocationFacilities) {
          if (this.state.customerLocationFacilities[i].id === facility.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.state.customerLocationFacilities.push(Utils.clone(facility));
        }
      }
    });

    // sort selected list
    this.state.customerLocationFacilities.sort(function (a, b) {
      return (a.name.localeCompare(b.name));
    });
    this.commit();
  }

  removeFromSelected = () => {
    let i = this.state.customerLocationFacilities.length;
    while (i--) {
      var facility = this.state.customerLocationFacilities[i];
      if (facility.selected) {
        this.state.customerLocationFacilities.splice(i, 1);
      }
    }
    this.commit();
  }

  searchAllFacilities = () => {
    this.state.results = [];
    const searchText = this.state.searchText;
    const parameters = {
      searchTerm: searchText
    };
    this.state.allFacilities = [];
    this.state.isLoading = true;
    ajax.get('lookup/getAllFacilitiesBySearch', parameters).then(results => {
      this.state.isLoading = false;
      this.state.allFacilities = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });    // IList<FacilityLookupDto>
  }

  searchCustomerFacilities = () => {
    this.state.results = [];
    const searchText = this.state.searchText1;
    const parameters = {
      customerLocationId: this.state.customerLocation.id,
      searchTerm: searchText
    };

    this.state.customerLocationFacilities = [];
    this.state.isLoading = true;
    ajax.get('lookup/getCustomerLocationFacilitiesBySearch', parameters).then(results => {
      this.state.isLoading = false;
      this.state.customerLocationFacilities = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });    // IList<FacilityLookupDto>
  }

  save = () => {
    const selectedfacilityIds = this.state.customerLocationFacilities.map(facility => { return facility.id; });   // use only the ids
    const customerLocation = this.state.customerLocation;
    const parameters = {
      customerLocationId: customerLocation.id,
      selectedfacilityIds: selectedfacilityIds
    };

    return ajax.post('customerLocation/changeFacilities', parameters).then(() => {
      messages.channel('edit-customer-location').action('saved').post(customerLocation);
      notification.action('success').post('Saved facilities for {name}'.interpolate(customerLocation));
      return true;
    }).then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });  // in: UpdateCustomerLocationFacilitiesDto, out: void
  }
}

export default SelectCustomerLocationFacilitiesController;
