import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import ActionIcon from '../../components/ActionIcon';
import Table from '../../components/Table';

class ComponentUpdateRow extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleRemoveRow = () => {
    this.props.controller.removeRow();
  }
  render = () => {
    const controller = this.props.controller;
    const asset = this.props.asset;
    const binder = new Binder(this);;
    const errors = controller.state.errors;
    const Orderid = controller.state.Orderid;
    const Matnr = controller.state.Matnr;
    const Matxt = controller.state.Matxt;
    const Posnr = controller.state.Posnr;
    const Werks = controller.state.Werks;
    const Enmng = controller.state.Enmng;
    const Menge = controller.state.Menge;
    const Operation = controller.state.Operation;
    const fldSONum = <Form.StaticData value={Orderid} />
    const fldPartNum = controller.state.flag == 1 && asset && controller.state.XLOEK != 'X' && asset.sapStatus != 'TECO' ? <Form.Input bind={binder.bind('Matnr')} className='required' error={errors.Matnr} placeholder='Enter a value' /> : <Form.StaticData value={Matnr} />
    const fldPosnr = controller.state.flag == 1 && asset && controller.state.XLOEK != 'X' && asset.sapStatus != 'TECO' ? <Form.Input bind={binder.bind('Posnr')} className='required' error={errors.Posnr} placeholder='Enter a value' /> : <Form.StaticData value={Posnr} />
    const fldMENGE = asset && controller.state.XLOEK != 'X' && asset.sapStatus != 'TECO' ? <Form.NumberInput bind={binder.bind('Menge')} className='required' error={errors.Menge} placeholder='Enter a value' /> : <Form.StaticData value={Menge} />
    const fldENMNG = <Form.StaticData value={Enmng} />
    const fldMatxt = <Form.StaticData value={Matxt} />
    const fldPlant = <Form.StaticData value={Werks} />
    const fldoper = <Form.StaticData value={Operation} />
    const tdAction = controller.state.flag == 1 && asset && controller.state.XLOEK != 'X' && asset.sapStatus != 'TECO'
      ? <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle' action={this.handleRemoveRow} /></Table.Data>
      : <Table.Data className='col-action'></Table.Data>;
    const clsName = controller.state.XLOEK != 'X' ? '' : 'comNotIssued'

    return (
      <Table.Row className={clsName}>
        {tdAction}
        <Table.Data className='col-location'>{fldSONum}</Table.Data>
        <Table.Data className='col-location'>{fldoper}</Table.Data>
        {/* <Table.Data className='col-actualReading'>{fldPosnr}</Table.Data> */}
        <Table.Data className='col-actualReading'>{fldPartNum}</Table.Data>

        <Table.Data className='col-actualReading reserved' >{fldMENGE}</Table.Data>
        <Table.Data className='col-actualReading' >{fldENMNG}</Table.Data>
        <Table.Data className='col-location' >{fldPlant}</Table.Data>
        <Table.Data className='col-actualReading'>{fldMatxt}</Table.Data>
      </Table.Row>
    );
  }
}

export default ComponentUpdateRow;
