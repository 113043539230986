import React from 'react';
import {Tab as RBTab, TabContainer as RBTabContainer, TabContent as RBTabContent, TabPane as RBTabPane} from 'react-bootstrap';

const Tab = (defaultProps) => {

  const props = {
    ...defaultProps
  }
  
  return <RBTab {...props}>{props.children}</RBTab>;
}

Tab.Container = (defaultProps) => {
  const props = {
    ...defaultProps
  }
  return <RBTabContainer {...props}>{props.children}</RBTabContainer>;
}

Tab.Content = (defaultProps) => {
  const props = {
    ...defaultProps
  }
  return <RBTabContent {...props}>{props.children}</RBTabContent>;
}

Tab.Pane = (defaultProps) => {
  const props = {
    ...defaultProps
  }
  return <RBTabPane {...props}>{props.children}</RBTabPane>;
}

export default Tab;