
import React, { useMemo } from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import GaugeManagementController from '../controllers/GaugeManagementController';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Utils from '../utility/Utils';

class CropImageDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            crop: {
                unit: '%',
                width: 40,
                height: 40,
            },
            croppedImage: '',
            croppedImageUrl: '',
            pageLoad:true
        }
        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new GaugeManagementController(),
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = async (evt) => 
    {
        if(evt.Cancel)
        {
            await this.makeClientCrop(this.state.crop,true);
            return this.props.controller.SaveCroppedImage(this.state.croppedImage, this.state.croppedImageUrl);
        }
        else if (this.state.croppedImage == '' || this.state.croppedImageUrl == '')
        {
            await this.makeClientCrop(this.state.crop,null);
            return this.props.controller.SaveCroppedImage(this.state.croppedImage, this.state.croppedImageUrl);
        }
        else
        return this.props.controller.SaveCroppedImage(this.state.croppedImage, this.state.croppedImageUrl);
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        if(!this.state.pageLoad)
        this.makeClientCrop(crop,null);
        this.setState({pageLoad : false});
    };

    onCropChange = (crop, percentCrop) => {
        //upadte crop parameters
        this.setState({ crop });
    };


    async makeClientCrop(crop,isFullCrop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
                isFullCrop
            );
            this.state.croppedImageUrl = croppedImageUrl;
        }
    }

    getCroppedImg(image, crop, fileName,isFullCrop) {
        const canvas = document.createElement('canvas');
        // let pixelRatio = window.devicePixelRatio;
        // let ratio=1;
        // if (navigator.userAgent.match(/iPad/i) != null) {
        //     pixelRatio = 1; 
        //     ratio=0.3;
        // }
        let pixelRatio = 1;
        let ratio=1;
        let flag = false;
        let counter = 0;
        if(isFullCrop)
        {
            crop.width =image.width;
            crop.height =image.height;
        }
        while(!flag)
        {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

       
        if(ratio<=0.1)
        {
        ratio=0.1;
        flag = true;
        }
        
        canvas.width = crop.width * pixelRatio * scaleX* ratio;
        canvas.height = crop.height * pixelRatio * scaleY*ratio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX * ratio,
            crop.height * scaleY * ratio
        );
        var imgData = canvas.toDataURL();
        var imgFileSize = imgData.length;
        
       
        if((imgFileSize / (1024*1024)).toFixed(2) < 1.5)
        flag = true;

        counter++;
        ratio= 1-(0.1*counter);
        }
        
        this.state.croppedImage = imgData;

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    resolve(window.URL.createObjectURL(blob));
                },
                'image/jpeg',
                1
            );
        });
    }


    render() {
        const controller = this.props.controller;
        const crop = this.state.crop;
        const src = this.props.controller.state.previewUrl
        return (
            <Dialog {...this.props} modal={true} header={this.props.header} width={600} height={500} onClose={this.handleClose}>
                <Dialog.Body>
                    <div>
                        <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    </div>
                </Dialog.Body>
                <Dialog.Buttons>
                    <Dialog.SaveCancelButtons />
                </Dialog.Buttons>
            </Dialog>
        );
    }
}

export default CropImageDialog;