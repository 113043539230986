import React from 'react';
import store from 'store';
import $ from 'jquery';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import CompleteJobController from '../controllers/CompleteJobController';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
import Utils from '../utility/Utils';
import CompleteJobDialog from '../dialogs/CompleteJobDialog';
import OverrideDialog from '../dialogs/OverrideDialog';
import OverrideController from '../controllers/OverrideController';

class JobProgressController extends BaseController {
  constructor() {
    super('job-progress-assets', {
      awo         : '',
      facilityId  : '',
      assets      : [],
      groups      : [],
      familyCodes : [],
      familyCode  : '',
      isLockInspInProgress        : false,
      multiSelectGroups : ''
    });

    messages.channel('login').action('loggedin').subscribe(loginInfo => {
      if (loginInfo) {
      //  this.loadFamilyCodes();
      }
    });

    messages.channel('change-facility').action('changed').subscribe(facility => {
      var state        = this.state;
      state.awo        = '';
      state.facilityId = facility.id;
      state.assets     = [];
      this.commit();
    });
   
  }
  getSelectedCount=()=>{
      return 1;
  }

  getSelectedAssets = () => {
    const selectedAssets = this.state.assets.filter(asset => asset.selected);
   const assets = [...new Map(selectedAssets.map(item =>[item["assetId"]-item["jobSOWId"], item])).values()];      
    return assets || [];
  }

  
  getSelectedAsset = () => {
    const selectedAssets = this.getSelectedAssets();    
    return (selectedAssets.length === 1)
      ? selectedAssets[0]
      : '';
  }

  getVisibleAssets = () => {
    return this.state.assets.filter(asset => {
      if (this.state.familyCode) {
        if (asset.familyCodeId === this.state.familyCode.id) {
          return asset;
        }
      }
      else {
        const selectedGroups = this.state.groups.filter(group => group.selected);
        if (!selectedGroups.length) {
          return asset;
        }

        for (let i = 0; i < selectedGroups.length; ++i) {
          const group = selectedGroups[i];
          if (asset.groupNumber === group.value.groupNumber) {
            return asset;
          }
        }
      }
    });
  }


  loadAssemblyAssets = (awoId) => {
     //-for layout puposeonly
    
    if (this.isLoading) {
      return;
    }

    this.state.job        = '';
    this.state.assets     = [];
    this.state.groups     = [];
    this.state.facilityId = LoginService.loginInfo.facilityId;


    co(this, function*() {
      try {
        this.isLoading          = true;
        this.cancelAssetLoading = false;
        this.state.awo          = yield ajax.get('lookup/awo/getAssemblyWorkOrder'); // JobLookupDto
        this.commit();
        this.state.assets  = [];
        var assets = yield ajax.get('lookup/awo/getAssemblyProgressAssets'); // IList<JobProgressAssetDto>
        this.state.assets.push(...assets);       
        this.commit();
        this.initializeGroups();
        // this.loadServiceLevels(this.state.assets);
      }
      catch (err) {
        
        //notifications.action('error').post({msg: err.message, title: 'Error loading job progress assets'});
      }
      finally {
        this.isLoading = false;
        this.commit();
      }
    });
  }

  
  initializeGroups = () => {
    let groups = [];

    this.state.assets.filter(asset => {
      if (groups.indexOf(asset.groupNumber) === -1) {
        groups.push({groupNumber: asset.groupNumber, groupName: asset.groupName});
      }
    });

    groups.sort(function(a, b) {
      return (a.groupNumber - b.groupNumber);
    });
    
    this.state.groups = groups.map(group => {
      return {
        value    : group,
        selected : false
      };
    });
  }

}
export default JobProgressController;