import React, { useMemo } from 'react';
import { Router, useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import AppConstants from '../utility/AppConstants';
import Controller from '../../mixins/Controller';
import SAPService from '../services/SAPService';
import Authentication from '../services/authentication';
import Grid from '../../components/Grid';
import Form from '../../components/Form';
import EditAWOController from '../controllers/EditAWOController';
import Table from '../../components/Table';
import DropZone from '../../components/DropZone';
import GroupBadge from '../../components/GroupBadge';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import notifications from '../../services/Notification';
import Utils from '../utility/Utils';
const AddAWO = (props) => {
  const controller = props.controller;
  const binder = props.binder;

  const handleAddToList = () => {
    props.controller.addToList();
  }

  const handleAddNewRow = () => {
    props.controller.handleAddNewRow();
  }

  const getCellProps = (cell) => {
    let style = {};
    if (cell && cell.column.id === 'state.group') {
      style = {
        width: '100px'
      };
    }
    const rowProps = { style: style };
    return rowProps;

  }
  if (controller.state.parentChildList.length === 0) {
    controller.getAWOParentChildList();
  }

  if (controller.state.addedAWOSystemConfigurationRows.length === 0) {
    controller.getDefaultSystemConfigRow();
  }
  const divClassName = controller.state.errors.addedAWOSystemConfigurationRows ? ' has-error' : '';
  let data = [...controller.state.addedAWOSystemConfigurationRows];
  let assemblyWorkOrderData = [...controller.state.addedAssemblyWorkOrderRows];
  const parentcontroller = controller;
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          const system_GUID = controller.state.system_GUID;
          const {serial_GUID , serial_dependent_GUID} = controller.state;          
          return system_GUID ? (serial_GUID === Utils.emptyGuid && serial_dependent_GUID === Utils.emptyGuid)?  <ActionIcon icon='fa-trash' className='remove-handle' onClick={()=>{parentcontroller.removeSystemconfiguration(system_GUID)}} /> : <></> : <ActionIcon icon='fa-trash' className='remove-handle' action={controller.remove} />
        }
      },
      {
        Header: 'Parent / Child',
        accessor: 'state.isChild',
        Cell: (cellInfo, binder) => {
            const controller = cellInfo.row.original;
          const {serial_GUID , serial_dependent_GUID} = controller.state;
          const enable = (!serial_GUID && !serial_dependent_GUID) || (serial_GUID === Utils.emptyGuid && serial_dependent_GUID === Utils.emptyGuid) 
          return <span><Form.AutoComplete label='' enabled={enable} className='required' bind={binder.bind('isChild')} search={Searchers.awoParentChildSearch()} minimumResultsForSearch={-1} /></span>
        }
      },
      {
        Header: 'Group',
        accessor: 'state.group',
        width: 100,
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          var ischild= cellInfo.row.original.state?.isChild?.id;
          const {serial_GUID , serial_dependent_GUID} = controller.state;          
          const enable = (!serial_GUID && !serial_dependent_GUID) || (serial_GUID === Utils.emptyGuid && serial_dependent_GUID === Utils.emptyGuid)
          return <span><Form.AutoComplete enabled={enable} label='' className='required' bind={binder.bind('group')} search={()=>controller.awoGroupSearch(cellInfo.row.original.state)} /></span>
        }
      },
      {
        Header: 'Position / Package',
        accessor: 'state.positionPackage',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const {serial_GUID , serial_dependent_GUID} = controller.state;
          const enable = (!serial_GUID && !serial_dependent_GUID) || (serial_GUID === Utils.emptyGuid && serial_dependent_GUID === Utils.emptyGuid)
          return <span><Form.AutoComplete enabled={enable} label='' className='required' bind={binder.bind('positionPackage')} search={Searchers.awoPackageSearch()} /></span>

        }
      },
      {
        Header: 'Equipment Description',
        accessor: 'state.equipmentDescription',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const {serial_GUID , serial_dependent_GUID} = controller.state;
          const enable = (!serial_GUID && !serial_dependent_GUID) || (serial_GUID === Utils.emptyGuid && serial_dependent_GUID === Utils.emptyGuid)
          return <span><Form.AutoComplete enabled={enable} label='' className='required' bind={binder.bind('equipmentDescription')} search={Searchers.awoEquipmentDescriptionSearch()} /></span>
        }
      },
      {
        Header: 'Dependent Equipment Description',
        accessor: 'state.dependentEquipment',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const {serial_GUID , serial_dependent_GUID} = controller.state;
          const enable = (!serial_GUID && !serial_dependent_GUID) || (serial_GUID === Utils.emptyGuid && serial_dependent_GUID === Utils.emptyGuid)
          return <div style={{ width: "100%" }}>
            <div className='row'>
              <div className='col-12'><Form.AutoComplete enabled={enable} label='' style={{ width: '100%' }} className='required' bind={binder.bind('dependentEquipment')} search={Searchers.awoDependentEquipmentDescriptionSearch()} /></div>
             
            </div>
          </div>
        }
      },
      {
        Header: 'Order',
        accessor: 'state.order',
        Cell: (cellInfo, binder) => {
          return <div style={{ width: "100%" }}>
            <div className='row'>
              <div className='col-12'><Form.Input label='' className='required' type='number' min='1' bind={binder.bind('order')} /></div>
            </div>
          </div>
        }
      }

    ],
    [data]);
    const disableRemove = controller.state.hasFile || controller.state.fileName
    ? false
    : true;
    const handleUploadDiagram = (res) => {
    var fileType = (res.file.name.split('.')[1] || '').toLowerCase();
    if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'pdf') {
      props.controller.uploadFile(res);
    }
    else {
      notifications.action('error').post('Uploaded file is not of acceptable format .Please upload either image file or pdf file');
    }
  }
  const uploadedFile = disableRemove === false ? controller.state.uploadedFile : '';
  const fileType = uploadedFile?.fileType?.toLowerCase();
  const isImageFile = fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? true : false;
  const handleRemoveDiagram = () => {
    props.controller.removeUploadedFile();
  }
 
    const OpenInNewTab = () => {      
      if(controller.state.file.file){
      const fileURL =URL.createObjectURL(controller.state.file.file) ;
      const fileName = controller.state.uploadedFile?.fileName;
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.download = fileName;
      tempLink.click();
      tempLink.remove();
      return true;
      }
    }
    const uploadedfileextn = (controller.state.fileName?.split('.')[1] || '')?.toLowerCase();
    const isImageFileuploaded = uploadedfileextn === 'jpg' || uploadedfileextn === 'jpeg' || uploadedfileextn === 'png' ? true : false;

    const imageField = disableRemove === true ?
    <img className='img-responsive center-block' alt={''} src={'./assets/images/asset_diagrams/NoDiagramAvailable.png?1682673439650'} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '267px', maxWidth: '267px' }} />
    : isImageFile === true ? <a><img alt={''} src={uploadedFile.fileData} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '267px', maxWidth: '267px' }} onClick={() => OpenInNewTab()} /></a>
      : <a href={uploadedfileextn ? controller.state.fileDownloadUrl : null} target="#"><img className='img-responsive center-block' alt={''} src={isImageFileuploaded? controller.state.fileDownloadUrl :'./assets/images/asset_diagrams/PDF.png?1682673439650'} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '267px', maxWidth: '267px' }} onClick={!uploadedfileextn ? ()=>{OpenInNewTab();} : null} /></a>
      const handleClearList = () => {
        props.controller.clearList();
      }
   const uploadtext = controller.state.fileName || controller.state.uploadedFile ? "Replace" : "Upload" ;
  return (
    <>
      <div className='col-12'>
        <div><Form.Header className='alert-warning'>System Configuration - <span style={{ fontWeight: 'normal' }}>Add a new row for each asset</span></Form.Header></div>
        <div className='row'>
        <div className='col-xl-10 col-lg-12 mb-2 div-awo-system-configuration assembly-grid' >
            <div className='row'>
              <div className='col-12'>
                <div  className='mb-2'>
                  <Grid id='results-table' getCellProps={getCellProps} columns={columns} data={data} editable={true} canAddNewRow={false} showPagination={false} noDataText='' />
                </div>
              </div>
              <div className='col-12'>
                <Button icon='fa-plus' variant='success' size='sm' className='m-1' onClick={handleAddNewRow}>Add New Row</Button>
                {/* <Button icon='fa-times' variant='info' size='sm' className='m-1 float-right' onClick={handleClearList}>Clear</Button> */}
                {/* <Button icon='fa fa-check-circle' variant='success' size='sm' className='m-1 float-right' onClick={handleAddToList}>Add to List</Button> */}
              </div>
            </div>
          </div>
          <div className='col-xl-2 col-lg-12 div-awo-system-configuration assembly-image'>
            <div className='row'>
              <div className='col-12 text-center'>
                <div className='justify-content-center'>
                  {imageField}
                  {/* <img className='img-responsive center-block' alt={''} src={'./assets/images/asset_diagrams/NoDiagramAvailable.png?1682673439650'} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '300px', maxWidth: '300px' }} /> */}
                </div>
              </div>
              <div className='col-12 text-center'>
                <div className='d-flex justify-content-center'>
                  {/* <Form.Header className='alert-warning' style={{ width: "267px" }}>
                    <DropZone onDrop={handleUploadDiagram} multiple={false} disabled={false} style={{ borderStyle: 'none', padding: 'unset', display: 'unset' }} width={170} height={23}>
                      <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
                      <GroupBadge className='m-1' type='file' variant='success' pill={false} icon='fa-upload' disabled={false}>{uploadtext}</GroupBadge>
                    </DropZone>
                    <GroupBadge className='m-1' variant='danger' icon='fa-trash' pill={false} onClick={handleRemoveDiagram} disabled={disableRemove}>Remove</GroupBadge>
                  </Form.Header> */}
                    <Form.Header className='alert-warning row' style={{ width: "267px" }}>                    
                    <div className='col-1g-12 col-xl-12'>
                    <DropZone  onDrop={handleUploadDiagram} multiple={false} disabled={false} style={{ borderStyle: 'none', padding: 'unset', display: 'unset' }} width={267} height={23}>
                    <div className='row'>
                    <div className='col-xl-12 col-lg-12'>
                    <Form.FieldHint style={{width:"100%"}} hint='Drag &amp; Drop &uarr;' />
                    </div>
                    <div className='col-xl-12 col-lg-12'>
                    <GroupBadge style={{width:"100%"}} className='m-1' type='file' variant='success' pill={false} icon='fa-upload' disabled={false}>{uploadtext}</GroupBadge>
                    </div>                    
                    </div>
                    </DropZone>
                    </div>                   
                   <div className='col-xl-12 col-lg-12'>
                        <GroupBadge style={{width:"100%"}} className='m-1' variant='danger' icon='fa-trash' pill={false} onClick={handleRemoveDiagram} disabled={disableRemove}>Remove</GroupBadge>
                    </div>
                  </Form.Header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const EditForm = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  const errors = controller.state.errors;
  const facilityId = LoginService.loginInfo.facilityId;
  const ownerId = controller.state.owner?.id;
  const functionLocation = controller.state.functionalLocation?.name;
  const isSAPFacility = SAPService.isSAPFacility();
  const rowWidth = isSAPFacility === true ? "col-md-4" : "col-md-3";
  let currentYear = new Date();
  return (
    <div className='col-12'>
      <div>
        <Form.Header className='alert-warning'>Customer</Form.Header>
      </div>
      {
        isSAPFacility ?
        <div id='customer' className='row'>
    <div className={'col-md-3'}><Form.AutoComplete enabled={false} label='AWO Customer' className='required' bind={binder.bind('awoCustomer')} error={errors.awoCustomer} search={Searchers.awocustomerSearch()} /></div>
    <div className={'col-md-3'}><Form.AutoComplete enabled={false} label='Owner' className='required' bind={binder.bind('owner')} error={errors.owner} search={Searchers.customerSearchByFacility(facilityId)} /></div>
    <div className={'col-md-3'}><Form.AutoComplete enabled={false} label='Customer Location' id='customer-location' className='required' error={errors.customerLocation} bind={binder.bind('customerLocation')}  search={controller.customerLocationSearch()} /></div>
    <div className={'col-md-3'}><Form.AutoComplete className='required' label='Service Line' bind={binder.bind('serviceLine')} error={errors.serviceLine} search={Searchers.awoServiceLineSearch()} /></div>
    <div className='col-md-12'><Form.Input label='AWO Description' className='required' bind={binder.bind('awoDescription')} error={errors.awoDescription} maxlength='40' /></div>
    <div className='col-md-2'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={Searchers.awoCWPSearch()} /></div>
    <div className='col-md-3'><Form.AutoComplete label='Functional Location' bind={binder.bind('functionalLocation')} search={Searchers.getCustomerFunctionalLocationList(ownerId)} /></div>
    <div className='col-md-3'><Form.AutoComplete label='Container Description' bind={binder.bind('containerDescription')} search={Searchers.getCustomerContainerListByFunctionalLocation(ownerId, functionLocation)} /></div>
    <div className='col-md-4'><Form.Date label='Equipment Required Date' placeholder='dd-mon-yyyy' className='required' bind={binder.bind('equipmentRequiredDate')} error={errors.equipmentRequiredDate} /></div>
      </div>      
      :
      <div id='customer' className='row'>
        <div className={rowWidth}><Form.Input disabled={true} label='AWO Number' className='required' bind={binder.bind('awoNumber')} maxlength='40' /></div>
        <div className={rowWidth}><Form.AutoComplete label='AWO Customer' enabled={false} className='required' bind={binder.bind('awoCustomer')} search={Searchers.awocustomerSearch()} /></div>
        <div className={rowWidth}><Form.AutoComplete enabled={false} label='Owner' className='required' disabled={true} bind={binder.bind('owner')} search={Searchers.customerSearchByFacility(facilityId)} /></div>
        <div className={rowWidth}><Form.AutoComplete error={errors.serviceLine} className='required' label='Service Line' bind={binder.bind('serviceLine')} search={Searchers.awoServiceLineSearch()} /></div>
        <div className='col-md-12'><Form.Input  label='AWO Description' className='required' bind={binder.bind('awoDescription')} maxlength='40' /></div>
        <div className='col-md-2'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={Searchers.awoCWPSearch()} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Functional Location' bind={binder.bind('functionalLocation')} search={Searchers.getCustomerFunctionalLocationList(ownerId)} /></div>
        <div className='col-md-3'><Form.AutoComplete   label='Container Description' bind={binder.bind('containerDescription')} search={Searchers.getCustomerContainerListByFunctionalLocation(ownerId, functionLocation)} /></div>
        <div className='col-md-4'><Form.Date label='Equipment Required Date' placeholder='dd-mon-yyyy' className='required' bind={binder.bind('equipmentRequiredDate')} error={errors.requiredDate} /></div>
      </div>
  }
      <div>
        <Form.Header className='alert-warning'>Worksite Information</Form.Header>
      </div>
      <div id='customer' className='row'>
        <div className='col-md-4'><Form.Input label='Pad / Worksite / Rig' bind={binder.bind('pad')} maxlength='40' /></div>
        <div className='col-md-4'><Form.Input label='Well Name' maxlength='40' bind={binder.bind('wellName')} /></div>
        <div className='col-md-4'><Form.AutoComplete label='Well Color' bind={binder.bind('wellColor')} search={Searchers.awoWellColorSearch()} /></div>
        <div className='col-md-4'><Form.AutoComplete error={errors.system} className='required' label='System' bind={binder.bind('system')} search={Searchers.awoSystemSearch()} /></div>
      </div>
    </div>
  );

}

const Buttons = (props) => {
  let history = useHistory();

  const handleCreate = () => {
    props.controller.createAWOs();
  }

  const handleClose = () => {
      
   props.controller.close();
  
  }

  const validateNumberToCreate = (evt) => {
    const value = parseInt(evt.target.value, 10);
    if (value < 1) {
      value = 1;
    }
    if (value > 50) {
      value = 50;
    }
    evt.target.value = value;
  }



 
  const binder = props.binder;

  return (

    <div className='col-md-12 mt-2' >
      <div className='d-flex justify-content-end m-2'>
        <Button icon='fa-save' variant='primary' size='sm' className='mr-1 action-button' onClick={handleCreate} disabled={props.controller.state.isSaving}>Save</Button>
        <Button icon='fa-times' variant='secondary' size='sm' className='mr-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const CreateAssetPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class EditAwoPage extends React.Component {


  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const awoId = this.props.match.params.awoId;
    this.props.controller.load(awoId);

  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new EditAWOController()
  }

  render = () => {
    const isSAPFacility = SAPService.isSAPFacility();
    const controller = this.props.controller;
    const s = controller.state;
    const binder = new Binder(this);
    const title =  `Edit Assembly Work Order ${isSAPFacility ? ` - ${s.awoNumber}` : ''}`;

    return (
      <Page {...this.props} pageTitle='InteServ · Edit AWO' id='create-asset-page'>
        <CreateAssetPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row content'>
            <div className='col-12'>
              <EditForm controller={controller} binder={binder} />
              <AddAWO controller={controller} binder={binder} />
              <Buttons controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default EditAwoPage;