import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import ClockInController from '../controllers/ClockInController';
import LoginService from '../services/LoginService';
import LateTimeEntryDialog from './LateTimeEntryDialog';
import LateTimeEntryController from '../controllers/LateTimeEntryController';

class ClockInDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        popup: 'popup',
        controller: new ClockInController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (evt) =>  { 
        if (evt.ClockIn) {
            return this.props.controller.clockInNow(LoginService.loginInfo.userId);
        }
        else if(evt.LateTime)
        {
            Dialog.showDialog(<LateTimeEntryDialog controller={new LateTimeEntryController()} />);
        }
        else if(evt.Exit)
        {
            return true;
        }
    }

    render = () => {
        return (
            <Dialog {...this.props} width={300} height={250} header={'Clock in'} id='clockIn-dlg' onClose={this.handleClose} >
                <Dialog.Body>
                    <div className='d-flex justify-content-center'>
                        <Button variant='light' className='clockin' disabled={this.props.controller.isClockinProgress} onClick={Dialog.handleResult({popup: this.props.popup, ClockIn: true})} title='clock in'>
                            <img className='clockin-img' src='./assets/images/clockin.png' />
                            <span className='clockin-img-labels'>
                                <span className='clockin-img-text'>Clock in</span>
                            </span>
                        </Button>
                        <Button variant='light' className='clockin' disabled={this.props.controller.isClockinProgress} onClick={Dialog.handleResult({popup: this.props.popup, LateTime: true})} title='Forgot clock in'>
                            <img className='clockin-img' src='./assets/images/forget-clockin.png' />
                            <span className='clockin-img-labels'>
                                <span className='clockin-img-text'>Forgot clock in</span>
                            </span>
                        </Button>
                    </div>
                </Dialog.Body>
                <Dialog.Buttons>
                    <Dialog.Button variant='warning' size='sm' saving={this.props.controller.isClockinProgress} result={{Exit: true }} >
                        <img className='continue-without-clockin-img' src='./assets/images/without-clockin.png' />
                        <span className='continue-without-clockin-txt'>Continue without Clock-in</span>
                    </Dialog.Button>
                </Dialog.Buttons>
            </Dialog>
        );
    }
}

export default ClockInDialog;