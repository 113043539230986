import React, { useMemo } from 'react';
import Router, { useHistory } from 'react-router-dom';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Table from '../../components/Table';
import Page from '../../components/Page';
import Form from '../../components/Form';
import PageHeader from '../components/PageHeader';
import Jumbotron from '../../components/Jumbotron';
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import Grid from '../../components/Grid';
import LoginService from '../services/LoginService';
import AdhocTransactionRow from '../pages/AdhocTransactionRow'
import AdhocTransactionsController from '../controllers/AdhocTransactionsController';
import EstimatedAdhocTransactionRow from '../pages/EstimatedAdhocTransactionRow';
import ActionIcon from '../../components/ActionIcon';

const Buttons = (props) => {
    let history = useHistory();

    const handleSave = () => {
        props.controller.save();
    }

    const handleclose = () => {
        history.goBack();
    }

    const controller = props.controller;
    const isSaveInProgress = controller.state.isSaveInProgress;
    const hide = props.canAddEstimated;
    return (
        <div className='col-md-12'>
            <div className='d-flex justify-content-end mb-1'>
                {hide ? <Button icon='fa-save' id='btn-save' variant='primary' size='sm' onClick={handleSave} disabled={isSaveInProgress}>Save</Button> : null}
                {/* <Button id='btn-cancel' variant='secondary' size='sm' onClick={this.handleClose}>Close</Button>  */}
            </div>
        </div>
    );
}

const ButtonsForEstimated = (props) => {
    let history = useHistory();

    const handleSave = () => {
        props.controller.saveEstimated();
    }

    const handleClose = () => {
        history.goBack();
    }

    const controller = props.controller;
    const isSaveInProgress = controller.state.isSaveInProgress;
    const hide = props.canAddEstimated;
    return (
        <div className='col-12'>
            <div className='d-flex justify-content-end'>
                {hide ? <Button icon='fa-save' id='btn-save' variant='primary' size='sm' onClick={handleSave} disabled={isSaveInProgress}>Save Estimated</Button> : null}
                {/* <Button id='btn-cancel' variant='secondary' size='sm' onClick={handleClose}>Close</Button> */}
            </div>
        </div>
    );
}

const RowsTable = (props) => {
    const controller = props.controller;
    const canEdit = !controller.state.isComplete;

    const renderRowErrors = () => {
        var rowErrors = controller.state.errors && controller.state.errors.rows;
        if (rowErrors) {
            return <Form.FieldError>{rowErrors}</Form.FieldError>;
        }
    }

    const handleAddNewRow = () => {
        props.controller.addNewRow();
    }

    const handleOnKeyPress = (evt) => {
        if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
            evt.stopPropagation();
        }
    }

    const handleKeyUp = (evt) => {
        try {
            const value = parseInt(evt.target.value, 10);
            if (value < 0) {
                evt.target.value = 0;
            }

            if (value > 100000) {
                evt.target.value = 100000;
            }
        }
        catch (err) {
            evt.target.value = 0;
        }
    }

    const divClassName = controller.state.errors.rows ? ' has-error' : '';

    const data = [...controller.state.rows];

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'action',
                isVisible: canEdit,
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    return <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
                }
            },
            {
                Header: 'Operation',
                accessor: 'state.operation',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const parentController = controller.parentController;
                    const errors = controller.state.errors;
                    return <Form.AutoComplete id='ad-hoc-operation' readOnly={false} bind={binder.bind('operation')} error={errors.operation} search={parentController.operationSearch()} />;
                }
            },
            {
                Header: 'Value',
                accessor: 'state.value',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const parentController = controller.parentController;
                    const errors = controller.state.errors;
                    const disabled = controller.state.unitOfMeasure === ''
                    const isDollarRow = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity;
                    const unitType = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity ? <Form.StaticData value='Quantity' /> : <Form.StaticData value='Dollars' />
                    const minsView = !isDollarRow && controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours ? null : <Form.Input type='number' id='ad-hoc-min' min='0' max='60' readOnly={disabled} disabled={disabled} bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
                    const minsUnit = minsView && <Form.StaticData value='Minutes' />;
                    return isDollarRow
                        ? <div className='d-inline-flex align-items-center'>
                            <Form.Input id='ad-hoc-dollars' type='number' readOnly={disabled} disabled={disabled} bind={binder.bind('value')} error={errors.value} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
                            <span className='ml-r mr-2'>{unitType}</span>
                        </div>
                        : <div className='d-inline-flex align-items-center'> 
                            <Form.Input type='number' id='ad-hoc-hrs' min='0' max='24' readOnly={disabled} disabled={disabled} bind={binder.bind('hours')} error={errors.hours} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
                            <Form.StaticData className='mr-2' value='Hours' />
                            {minsView} {minsUnit}
                        </div>;
                }
            },
            {
                Header: 'Technician',
                accessor: 'state.technician',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const parentController = controller.parentController;
                    const errors = controller.state.errors;
                    const isDollarRow = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity;
                    return isDollarRow
                        ? <span></span>
                        : <span className='font-weight-bold'>{LoginService.loginInfo.fullName}</span>; //cellInfo.cell.value;
                }
            },
            {
                Header: 'Date',
                accessor: 'state.date',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const parentController = controller.parentController;
                    const errors = controller.state.errors;
                    const isDollarRow = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity;
                    return isDollarRow
                        ? <span></span>
                        : <span className='font-weight-bold'>{DateTimeFormatter.formatDate(DateTimeFormatter.today())}</span>; //cellInfo.cell.value.name;
                }
            },
            {
                Header: 'Notes',
                accessor: 'state.notes',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const parentController = controller.parentController;
                    const errors = controller.state.errors;
                    return <Form.Input bind={binder.bind('notes')} />
                }
            },
        ],
        []);
    return (
        <div className={divClassName}>
            <Grid id='adhoc-results-table' columns={columns} data={data} editable={canEdit} addNewRowText='Click to add a new transaction' onAddNewRow={handleAddNewRow} showPagination={false} />
            {renderRowErrors()}
        </div>
    );

}

// const RowsTable = (props) => {
//     const handleAddNewRow = () => {
//         props.controller.addNewRow();
//     }
//     const renderRowErrors = () => {
//         const rowErrors = props.controller.state.errors && props.controller.state.errors.rows;
//         if (rowErrors) {
//             return <div className='help-desk'>{rowErrors}</div>
//         }
//     }
//     const controller = props.controller;
//     const canEdit = !controller.state.isComplete;
//     const rows = controller.state.rows.map(row => <AdhocTransactionRow key={row.key} controller={row} canEdit={canEdit} />);
//     // const rows = '';
//     const addRow = canEdit
//         ? <Table.Row>
//             <Table.Data colSpan='10'> <Button icon='fa fa-plus-square'  variant='success' size='sm' onClick={handleAddNewRow}>Click to add a new transaction</Button></Table.Data>
//         </Table.Row>
//         : null;
//     const backDatingNote = <div className='backdating-note-message'>{AppConstants.BackDatingNote}</div>;

//     let divClassName = 'form-group col-sm-12';
//     if (controller.state.errors.rows) {
//         divClassName += ' has-error';
//     }
//     return (
//         <div id='search-results' className={divClassName} style={{ marginTop: '10px' }}>
//             <h5>Actual Adhoc Transactions</h5>
//             <Table id='results-table'>
//                 <Table.Header>
//                     <Table.Row>
//                         <Table.Head className='col-action' />
//                         <Table.Head className='col-operation'>Operation</Table.Head>
//                         <Table.Head colSpan={4}>Value</Table.Head>
//                         <Table.Head className='col-technician'>Technician</Table.Head>
//                         <Table.Head className='col-date' >Date</Table.Head>
//                         <Table.Head className='col-notes'>Notes</Table.Head>
//                     </Table.Row>
//                 </Table.Header>
//                 <Table.Body>
//                     {rows}
//                 </Table.Body>
//                 <Table.Footer>
//                     {addRow}
//                 </Table.Footer>
//             </Table>
//             {renderRowErrors()}
//             {/* {backDatingNote} */}
//             <div className='text-success font-weight-bold'>Note: Please enter the actual cost only.  The system will automatically apply the markup for the customer in SAP.</div>
//         </div>
//     );
// }

const EstimatedRowsTable = (props) => {
    const controller = props.controller;
    const canEdit = props.canAddEstimated;
    const source = AppConstants.AdhocTransactionTypes.Estimated;

    const renderRowErrors = () => {
        var rowErrors = controller.state.errors && controller.state.errors.rows;
        if (rowErrors) {
            return <Form.FieldError>{rowErrors}</Form.FieldError>;
        }
    }

    const handleAddNewRow = () => {
        props.controller.addNewEstimatedRow();
    }

    const handleOnKeyPress = (evt) => {
        if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
            evt.stopPropagation();
        }
    }

    const handleKeyUp = (evt) => {
        try {
            const value = parseInt(evt.target.value, 10);
            if (value < 0) {
                evt.target.value = 0;
            }

            if (value > 100000) {
                evt.target.value = 100000;
            }
        }
        catch (err) {
            evt.target.value = 0;
        }
    }

    const divClassName = controller.state.errors.rows ? ' has-error' : '';

    const estimatedTrans = Utils.filterDeletedRecords(controller.state.estimatedTransaction);

    const data = [...estimatedTrans];

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'action',
                isVisible: canEdit,
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    const recordAdded = controller.state.recordStatus == AppConstants.RecordStatus.Added;
                    return recordAdded 
                           ? <ActionIcon icon='fa-trash' className='remove-handle' action={() => controller.removeRow(source)} />
                           : <span></span>;
                }
            },
            {
                Header: 'Operation',
                accessor: 'state.operation',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const parentController = controller.parentController;
                    const errors = controller.state.errors;
                    const recordAdded = controller.state.recordStatus == AppConstants.RecordStatus.Added;
                    return recordAdded 
                           ? <Form.AutoComplete id='ad-hoc-operation' readOnly={false} bind={binder.bind('operation')} error={errors.operation} search={parentController.operationSearch()} />
                           : <Form.StaticData value={controller.state.operation.name} />;
                }
            },
            {
                Header: 'Value',
                accessor: 'state.value',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const parentController = controller.parentController;
                    const errors = controller.state.errors;
                    const disabled = controller.state.unitOfMeasure === ''
                    const isDollarRow = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity;
                    const unitType = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity ? <Form.StaticData value='Quantity' /> : <Form.StaticData value='Dollars' />
                    const minsView = !isDollarRow && controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours ? null : <Form.Input type='number' id='ad-hoc-min' min='0' max='60' readOnly={disabled} disabled={disabled} bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
                    const minsUnit = minsView && <Form.StaticData value='Minutes' />;
                    return isDollarRow
                        ? <div className='d-inline-flex align-items-center'>
                            <Form.Input id='ad-hoc-dollars' className='mr-3' type='number' readOnly={!canEdit} disabled={!canEdit} bind={binder.bind('value')} error={errors.value} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
                            {unitType}
                        </div>
                        : <div className='d-inline-flex align-items-center'>
                            <Form.Input className='mr-1' type='number' id='ad-hoc-hrs' min='0' max='24' readOnly={!canEdit} disabled={!canEdit} bind={binder.bind('hours')} error={errors.hours} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
                            <Form.StaticData className='mr-2' value='Hours' />
                            {minsView} {minsUnit}
                        </div>;
                }
            },
        ],
        [canEdit]);
    return (
        <div className='col-md-12'>
            <div className='mb-2'>
                <span className='h5'>Estimated Adhoc Transactions</span>
            </div>
            <div className={divClassName}>
                <Grid id='estimated-results-table' columns={columns} data={data} editable={true} addNewRowText='Click to add a new transaction' onAddNewRow={handleAddNewRow} showPagination={false} />
                {renderRowErrors()}
            </div>
        </div>
    );

}

// const EstimatedRowsTable = (props) => {
//     const handleAddNewRow = () => {
//         props.controller.addNewEstimatedRow();
//     }

//     const renderRowErrors = () => {
//         const rowErrors = props.controller.state.errors && props.controller.state.errors.rows;
//         if (rowErrors) {
//             return <div className='help-block'>{rowErrors}</div>;
//         }
//     }

//     const controller = props.controller;
//     const canEdit = props.canAddEstimated;
//     const source = AppConstants.AdhocTransactionTypes.Estimated;
//     const addRow = canEdit
//         ? <Table.Row>
//             <Table.Data colSpan='10'> <Button icon='fa fa-plus-square' variant='success' size='sm' onClick={handleAddNewRow}>Click to add a new transaction</Button></Table.Data>
//         </Table.Row>
//         : null;
//     const estimatedTrans = Utils.filterDeletedRecords(controller.state.estimatedTransaction);
//     const rows = estimatedTrans.map((row, index) => {
//         return <EstimatedAdhocTransactionRow key={index} controller={row} canEdit={canEdit} source={source} />
//     });
//     const backDatingNote = <div className='backdating-note-message'>{AppConstants.BackDatingNote}</div>;

//     let divClassName = 'form-group col-md-12';
//     if (controller.state.errors.rows) {
//         divClassName += ' has-error';
//     }

//     return (
//         <div id='estimated-search-results' className={divClassName} style={{ marginTop: '10px' }}>
//             <h5>Estimated Adhoc Transactions</h5>
//             <Table id='results-table'>
//                 <Table.Header>
//                     <Table.Row>
//                         <Table.Head className='col-action' />
//                         <Table.Head className='col-operation'>Operation</Table.Head>
//                         <Table.Head colSpan={4}>Value</Table.Head>
//                         {/* <Table.Head className='col-technician'>Technician</Table.Head> */}
//                         {/* <Table.Head className='col-date' >Date</Table.Head>
//                   <Table.Head className='col-notes'>Notes</Table.Head> */}
//                     </Table.Row>
//                 </Table.Header>
//                 <Table.Body>
//                     {rows}
//                 </Table.Body>
//                 <Table.Footer>
//                     {addRow}
//                 </Table.Footer>
//             </Table>
//             {renderRowErrors()}
//             {/* {backDatingNote} */}
//         </div>
//     );
// }

const ResultRow = (props) => {
    const transaction = props.transaction;
    const value = (transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) ? Utils.formatDollars(transaction.value, (transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars ? 1 : 2)) : transaction.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours ? Utils.formatHours(transaction.value) : DateTimeFormatter.formatDuration(transaction.value);
    return (
        <Table.Row>
            <Table.Data>{transaction.operationDescription}</Table.Data>
            <Table.Data>{value}</Table.Data>
            <Table.Data>{transaction.technician}</Table.Data>
            <Table.Data style={{ minWidth: 90 }}>{DateTimeFormatter.formatDate(transaction.date)}</Table.Data>
            <Table.Data>{transaction.notes}</Table.Data>
        </Table.Row>
    );
}

class ResultsList extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    getData = () => {
        return this.props.controller.state.transactions || [];
    }

    render = () => {
        const controller = this.props.controller;
        const job = controller.state.job;
        let transactions = controller.state.transactions;
        let noTransactionsMessage = null; // default
        if (!transactions.length) {
            noTransactionsMessage = job.isClosed
                ? <Table.Row><Table.Data colSpan={5}>No adhoc transactions were created for this job</Table.Data></Table.Row>
                : <Table.Row><Table.Data colSpan={5}>No adhoc transactions have been created for this job yet</Table.Data></Table.Row>;
        }

        transactions = transactions.map(transaction => <ResultRow key={transaction.adhocTransactionId} transaction={transaction} />);

        return (
            <div id='saved-search-results' className='form-group col-md-12 table-container'>
                <Table id='results-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head className='col-operation' sortKey='operationDescription' getData={this.getData} sorter={this.sortData}>Operation</Table.Head>
                            <Table.Head className='col-value-header'>Value</Table.Head>
                            <Table.Head className='col-technician'>Technician</Table.Head>
                            <Table.Head className='col-date'>Date</Table.Head>
                            <Table.Head className='col-notes'>Notes</Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {transactions}
                        {noTransactionsMessage}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const AdhocTransactionPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} icon='fa fa-star' />

class AdhocTransactionsPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    static defaultProps = {
        controller: new AdhocTransactionsController()
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandAdhoc();
        var jobId = this.props.match.params.jobId;
        if (!jobId) {
            this.goBack();
        }

        this.props.controller.load(jobId);
    }

    canAddActAdhocTransactions = (job, adhocServiceOrder) => {
        return (job.canAddAdhocTransactions && (adhocServiceOrder.sapStatus != AppConstants.AssetSAPStatus.TECO) && (adhocServiceOrder.sapStatus == AppConstants.AssetSAPStatus.REL));
    }

    canAddEstAdhocTransactions = (job, adhocServiceOrder) => {
        return (adhocServiceOrder.sapStatus != AppConstants.AssetSAPStatus.TECO && ((job.sapStatus == 'QUST') ? false : true));
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render = () => {
        const controller = this.props.controller;
        const job = controller.state.job || {};
        const adhocServiceOrder = controller.state.adhocServiceOrder || {};
        const binder = new Binder(this);
        const customerName = job.customerName;
        const customerLocation = job.customerLocation;
        const jobNumber = job.jobNumber;
        let rowsTable = null;
        let divErrorMessage = null;
        const canAddActTransaction = this.canAddActAdhocTransactions(job, adhocServiceOrder) && adhocServiceOrder.serviceOrderNumber;
        const canAddEstimatedTransaction = this.canAddEstAdhocTransactions(job, adhocServiceOrder) && adhocServiceOrder.serviceOrderNumber;

        if (!this.canAddActAdhocTransactions(job, adhocServiceOrder)) {
            divErrorMessage = <div className='col-12 text-danger font-weight-bold'>Job is not in a state that allows adhoc transactions to be created</div>;
        }
        else if (!adhocServiceOrder.serviceOrderNumber) {
            divErrorMessage = <div className='col-12 text-danger font-weight-bold'>Job does not have a service order number</div>;
        }
        else {
            rowsTable = <RowsTable controller={controller} binder={binder} />;
        }

        return (
            <Page {...this.props} pageTitle='InteServ · Adhoc Transactions' id='adhoc-transactions-page'>
                <AdhocTransactionPageHeader pageTitle='Adhoc Transactions' />
                <Page.Content>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='col-md-12 font-weight-bold' style={{ fontSize: '24px' }}>{jobNumber} &ndash; {customerName} &ndash; {customerLocation}</div>
                                    <div className='col-md-12 font-italic' style={{ fontSize: '14px' }}>{'SAP Status: ' + (adhocServiceOrder.sapStatus || 'not set')}</div>
                                    <div className='col-md-12 font-italic' style={{ fontSize: '14px' }}>{'SAP SO #: ' + (adhocServiceOrder.serviceOrderNumber || 'not set')}</div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='col-12'>
                                        <div className='d-flex justify-content-end'>
                                            <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' onClick={this.handleClose}>Close</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div {...this.props} className='col-md-12'>
                            {divErrorMessage}
                            {rowsTable}
                            <ResultsList controller={controller} />
                            <Buttons controller={controller} canAddEstimated={canAddActTransaction} />
                        </div>

                        <div className='col-md-12' id='estimated-transaction-detail'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Jumbotron>
                                        <div className='row'>
                                            <EstimatedRowsTable controller={controller} binder={binder} canAddEstimated={canAddEstimatedTransaction} />
                                            <ButtonsForEstimated controller={controller} canAddEstimated={canAddEstimatedTransaction} />
                                        </div>
                                    </Jumbotron>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default AdhocTransactionsPage;