import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import BreakInController from '../controllers/BreakInController';
import LoginService from '../services/LoginService';

class BreakInDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        popup: 'popup',
        controller: new BreakInController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (evt) =>  { 
        if (evt.BreakIn) {
            return this.props.controller.breakInNow(LoginService.loginInfo.userId);
        }
        else if(evt.Exit)
        {
            return true;
        }
    }

    render = () => {
        return (
            <Dialog {...this.props} width={300} height={250} header={'Ready to Work'} id='clockIn-dlg' onClose={this.handleClose} >
                <Dialog.Body>
                    <div className='d-flex justify-content-center'>
                        <Button variant='light' className='clockin' disabled={this.props.controller.isInProgress} onClick={Dialog.handleResult({ popup: this.props.popup, BreakIn: true })} title='Ready to Work'>
                            <img className='clockin-img' src='./assets/images/clockin.png' />
                            <span className='clockin-img-labels'>
                                <span className='clockin-img-text'>Ready to Work</span>
                            </span>
                        </Button>
                    </div>
                </Dialog.Body>
                <Dialog.Buttons>
                    <Dialog.Button variant='warning' size='sm' saving={this.props.controller.isInProgress} result={{Exit: true }} >
                        <img className='continue-without-clockin-img' src='./assets/images/without-clockin.png' />
                        <span className='continue-without-clockin-txt'>Continue with Unpaid Break </span>
                    </Dialog.Button>
                </Dialog.Buttons>
            </Dialog>
        );
    }
}

export default BreakInDialog;