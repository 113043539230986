import React, { useMemo, useEffect } from 'react';
import store from 'store';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import CustomerAssemblyLevelController from '../controllers/CustomerAssemblyLevelController';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Badge from '../../components/Badge';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';

const TestRowsTable = (props) => {
  const controller = props.controller;

  const renderRowErrors = () => {
    var rowErrors = controller.state.errors && controller.state.errors.tests;
    if (rowErrors) {
      return <Form.FieldError>{rowErrors}</Form.FieldError>;
    }
  }

  const handleAddNewRow = () => {
    controller.addNewTestRow();
  }

  const handleOperationCodeKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  const validateOperationOrder = (evt) => {
    let minValue = 1;
    const maxValue = controller.state.tests.length; // bit of a code smell
    const value = parseInt(evt.target.value, 10);
    if (isNaN(value)) {
      evt.target.value = minValue;
    }
    else if (value < minValue) {
      evt.target.value = minValue;
    }
    else if (value > maxValue) {
      evt.target.value = maxValue;
    }
  }
  const handleAutoCodeTests = () => {
    controller.autoCodeTests();
  }

  const divClassName = controller.state.errors.rows ? ' has-error' : '';
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const data = [...controller.state.tests];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return isSuperUser ? <span></span> : <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
        }
      },
      {
        Header: 'Assembly Level Operations',
        accessor: 'state.assemblylevelOperation',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const parentController = controller.parentController;
          const errors = controller.state.errors;
          return <Form.AutoComplete readOnly={isSuperUser} bind={binder.bind('assemblylevelOperation')} error={errors.assemblylevelOperation} className='required' placeholder='select assembly level operation' search={parentController.search('Assembly level test')} />
        }
      },
      {
        Header: 'Operation Names',
        accessor: 'state.operationName',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('operationName')} readOnly={true} error={errors.operationName} />
        }
      },
      {
        Header: 'Codes',
        accessor: 'state.operationCode',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('operationCode')} readOnly={isSuperUser} maxlength='4' error={errors.operationCode} onKeyPress={handleOperationCodeKeyPress} />
        }
      },
      {
        Header: 'Order',
        accessor: 'state.operationOrder',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input type='number' bind={binder.bind('operationOrder')} readOnly={isSuperUser} error={errors.operationOrder} onInput={validateOperationOrder} />
        }
      },
    ],
    []);
  return (
    <div className={divClassName}>
      <div className='d-flex justify-content-end'>
        <div className='mt-2 mr-2'><Badge variant='info'>{data.length}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} addNewRowText='Click to add a new operation' editable={true} onAddNewRow={handleAddNewRow} canAddNewRow={!isSuperUser} showPagination={false} />
      <div className='float-right'>
        <Button icon='fas fa-sort-numeric-down' variant='warning' size='sm' className='m-1' disabled={!data.length ||  isSuperUser} onClick={handleAutoCodeTests}>Auto-code Operations</Button>
      </div>
      {renderRowErrors()}
    </div>
  );
}


const EditForm = (props) => {

  const handleDuplicate = () => {
    props.controller.duplicate();
  }

  const getData = () => {
    return props.controller.state.assignedLaborCodes || [];
  }

  const binder = props.binder;
  const controller = props.controller;
  const errors = controller.state.errors;
  const customer = controller.state.customer;
  const isSAPAssemblyLevel = controller.state.isSAPAssemblyLevel;
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  // for use in 'Change Service Level' AutoComplete
  const sapMarker = isSAPAssemblyLevel ? <span className='user-facility-sap'> SAP</span> : null;
  const sapLabel = <span>SAP Assembly Level {sapMarker}</span>;
  const formatResult = item => {
    var spanSAP = item.result.isSAPServiceLevel ? <span className='sap-service-level pull-right select2-tag'> SAP</span> : null;
    if (item.result.isSAPServiceLevel) {
      return <span>{item.text} {spanSAP}</span>;
    }
    return <span>{item.text}</span>;
  };  
  return (
    <div>
      <div className='row'>
        <div className='col-md-12 h3'>{customer && customer.name}</div>
        <div className='col-md-4'><Form.AutoComplete label='Assembly Level' bind={binder.bind('assemblyLevel')} placeholder='select assembly level' readOnly={isSuperUser} search={controller.search('Assembly Level')} className='required' error={errors.assemblyLevel} /></div>
        <div className='col-md-4'><Form.Input label='Assembly Level Name' bind={binder.bind('assemblyLevelName')} maxlength='50' className='required' readOnly={isSuperUser} error={errors.assemblyLevelName} /></div>
        {
          isSAPAssemblyLevel ? 
              <div className='col-md-4'><Form.AutoComplete label='Assembly Labor Code' bind={binder.bind('laborCode')} placeholder='select labor code' readOnly={isSuperUser} search={controller.searchlaborCode()} className={'required'} error={errors.laborCode} /></div>
              :
              <></>
        }
              </div>
      <div className='row'>
        <div className='col-md-3'><Form.CheckBox disabled={isSuperUser} label='Active' bind={binder.bind('active')} /></div>
        <div className='col-md-3'><Form.CheckBox disabled={isSuperUser} label={sapLabel} bind={binder.bind('isSAPAssemblyLevel')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <Form.Header className='alert-warning col-md-12'>Operations</Form.Header>
          <TestRowsTable controller={controller} binder={binder} />
        </div>
      </div>
    </div>
  );
}

const Buttons = (props) => {

  let history = useHistory();
  const disableSave = LoginService.isInRole(Roles.SuperUser) && (props.controller.state.newRowCount === undefined || props.controller.state.newRowCount < 1);
  const handleSave = () => {
    props.controller.save((customerServiceLevelId) => {
      // after saving - navigate to same page with new id in address bar
      var id = props.controller.state.customerAssemblyLevelId;
      const data = {
        customerAssemblyLevelId: id,
        customerId: props.controller.state.customer.customerId,
        customerName: props.controller.state.customer.customerName
    };
    store.set('edit-customer-assembly-level', data);
    props.controller.load(data.customerAssemblyLevelId, data.prototypeAssemblyLevelId, data.customerId, data.customerName) 
      history.replace('/customerAssemblylevel/' + id);
    });
  }

  const handleClose = () => {
    history.goBack();
  }

  return (
    <div>
      <div className='edit-buttons float-right mr-1 mt-2'>
        <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving || disableSave}>Save</Button>
        <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const CustomerAssemblyLevelPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class CustomerAssemblyLevelPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const data = store.get('edit-customer-assembly-level');
    const customerAssemblyLevelId = data && data.customerAssemblyLevelId;
    const prototypeAssemblyLevelId = data && data.prototypeAssemblyLevelId;
    const customerId = data && data.customerId;
    const customerName = data && data.customerName;
    this.props.controller.load(customerAssemblyLevelId, prototypeAssemblyLevelId, customerId, customerName);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new CustomerAssemblyLevelController()
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const name = controller.state.customerAssemblyLevelName;
    const title = controller.state.customerAssemblyLevelId !== Utils.emptyGuid ? 'Edit Assembly Level' + (name ? (' — ' + name) : '') : 'Create Assembly Level' + (name ? (' — ' + name) : '');

    return (
      <Page {...this.props} pageTitle='InteServ · Assembly Levels' id='edit-cust-assembly-level-page'>
        <CustomerAssemblyLevelPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <EditForm controller={controller} binder={binder} />
              <Buttons controller={controller} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default CustomerAssemblyLevelPage;
