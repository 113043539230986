import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Validator from '../../lib/Validator';
import SAPService from '../services/SAPService';
import store from 'store';
import messages from '../../services/Messages';
import LoginService from '../services/LoginService';
import AssetsAlreadyInJobDialogBody from '../dialogs/AssetsAlreadyInJobDialogBody';
import AssetsAlreadyInJobController from '../controllers/AssetsAlreadyInJobController';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
import Table from '../../components/Table';

class AssignAssetsToJobController extends BaseController {
  constructor(customerId, facilityId, assets) {
    super('assign-assets-to-job', {
      customerId: customerId,
      facilityId: facilityId,
      assets: assets,
      receivingLocation: '',
      existingJob: '',
      newJob: '',
      selectedJob: '',
      customerJobNumber: '',
      customerPO: '',
      groupNumber: '',
      groupName: '',
      jobDescription: '',
      jobNotes: '',
      requiredDate: '',
      removeFromContainer: false,
      errors: {},
      costCenter: '',
      hours: '0',
      minutes: '0',
      TransactionDate: '',
      saving: false
    });
    this.saving = false;
    this.receivedAsstes = assets;
    this.selectedAssets = [];
    this._validator = new Validator();

    const assignedToContainer = assets.reduce((acc, curr) => {
      return acc || (curr.container?.id !== Utils.emptyGuid);
    }, false);
    this.state.removeFromContainer = assignedToContainer;
    this._validator.add('receivingLocation', 'Receiving Location', [{ required: true }]);
  }

  // common to both new and existing jobs
  jobChanged = () => {
    this.state.customerJobNumber = this.state.selectedJob && this.state.selectedJob.customerJobNumber;
    this.state.customerPO = this.state.selectedJob && this.state.selectedJob.customerPO;
    this.state.jobDescription = this.state.selectedJob && this.state.selectedJob.jobDescription;
    this.state.jobNotes = this.state.selectedJob && this.state.selectedJob.jobNotes;
    this.state.requiredDate = this.state.selectedJob && this.state.selectedJob.requiredDate ? DateTimeFormatter.formatDropdownDate(this.state.selectedJob.requiredDate) : null;
  }

  existingJobChanged = () => {
    this.state.newJob = '';
    this.state.selectedJob = this.state.existingJob;
    this.jobChanged();
    if (this.state.selectedJob) {
      this.validateAssetWithJob();
    }
    else {
      if (this.state.assets.length != this.receivedAsstes.length) {
        this.state.assets = this.receivedAsstes;
        this.commit();
      }
    }
  }

  newJobChanged = () => {
    this.state.existingJob = '';
    this.state.selectedJob = this.state.newJob;
    this.jobChanged();
  }

  groupNumberChanged = () => {
    this.state.groupName = this.state.groupNumber ? this.state.groupNumber.desc : '';
    this.commit();
  }

  existingJobSearch = () => {
    const customerId = this.state.customerId;
    const facilityId = this.state.facilityId;

    return function* (searchTerm) {
      const parameters = {
        facilityId: facilityId,
        customerId: customerId,
        searchTerm: searchTerm
      };
      const results = yield ajax.get('lookup/searchExistingJobs', parameters); // IList<JobSearchResultDto>
      return results;
    };
  }

  newJobSearch = () => {
    const customerId = this.state.customerId;
    const facilityId = this.state.facilityId;

    return function* (searchTerm) {
      const parameters = {
        customerId: customerId,
        facilityId: facilityId,
        searchTerm: searchTerm
      };
      const results = yield ajax.get('lookup/searchNewJobs', parameters);
      return results;
    };
  }

  validateAssetWithJob = () => {
    const state = this.state;
    co(this, function* () {
      try {
        const parameters = {
          jobId: state.selectedJob.id,
          assets: state.assets.map(asset => {
            return {
              assetId: asset.assetId
            };
          })
        };
        const results = yield ajax.post('receiving/validateAssetWithJob', parameters);
        if (results && results.length) {
          const assetList = results.map(asset => {
            const item = state.assets.find(obj => obj.assetId === asset.assetId);
            if (item) {
              asset.primarySerialNumber = item.primarySerialNumber;
              asset.description = item.description ? item.description.name : '';
            }
            return asset;
          });
          const assetsAlreadyAvailableDialog =
            <Dialog className='assign-assets-to-job-child-dialog' header={'Assets Already Attached'} height={400} width={460} popup='childPopup'>
              <AssetsAlreadyInJobDialogBody controller={new AssetsAlreadyInJobController(results, this)} />
              <Dialog.OKCancelButtons popup={'childPopup'} />
            </Dialog>
          const result = yield Dialog.showDialogWaitForResult(assetsAlreadyAvailableDialog, 'childPopup');

          if (!result.OK) {
            if (LoginService.isCoordinator) {
              this.state.existingJob = '';
              this.existingJobChanged();
              this.commit();
            }
          }
          else {
            if (LoginService.isCoordinator) {
              messages.channel('assets-already-in-job').action('save').post();
              if (this.selectedAssets.length) {
                this.state.assets = this.selectedAssets.map(asset => state.assets.find(obj => obj.assetId === asset.assetId));
                this.commit();
              }
            }
          }

          if (!LoginService.isAdministrator && !LoginService.isCoordinator && (LoginService.isSAPRep || LoginService.isTechnician)) {
            this.state.existingJob = '';
            this.existingJobChanged();
            this.commit();
          }
        }
        else {
          if (this.state.assets.length != this.receivedAsstes.length) {
            this.state.assets = this.receivedAsstes;
            this.commit();
          }
        }
      }
      catch (err) {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }
    });
  }

  receivingLocationSearch = () => {
    const self = this;
    return function* (searchTerm) {
      const parameters = {
        facilityId: self.state.facilityId,
        customerId: self.state.customerId,
        searchTerm: searchTerm
      };
      const results = yield ajax.get('lookup/searchCustomerLocationsForFacility', parameters); // IList<CustomerLocationLookupDto>
      return results;
    };
  }

  groupNumberSearch = () => {
    const jobId = this.state.existingJob && this.state.existingJob.id;
    return function* (searchTerm) {
      const parameters = { jobId: jobId };
      // var results = yield ajax.get('lookup/searchJobGroupNumbers', parameters); // IList<LookupDto>
      const results = yield ajax.get('lookup/searchJobGroupNamesAndNumbers', parameters);
      return results;
    };
  }

  canAssignToJob = () => {
    let success = true;

    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (errors.length > 0) {
      success = false;
    }

    if ((this.state.selectedJob === this.state.newJob) && !this.state.receivingLocation) {
      notification.action('warning').post('You must select a Receiving Location');
      success = false;
    }

    if (this.state.assets.length === 0) {
      notification.action('warning').post('You must select at least one asset');
      success = false;
    }

    if (!this.state.selectedJob) {
      notification.action('warning').post('You must select either a New or an Existing job');
      success = false;
    }

    if (!success) {
      this.commit();
    }
    return success;
  }

  showAssignCompleteDialog = (result) => {
    co(this, function* () {
      const jobNumber = result.jobNumber;
      const groupNumber = result.groupNumber;
      const groupName = result.groupName;
      const assetCount = result.assetCount;
      const receivingTicketNumber = result.receivingTicketNumber;
      const jobDescription = result.jobDescription;

      const response = yield Dialog.showDialogWaitForResult(
        <Dialog.OKDialog width={600} height={300} header='Assets Assigned to Job' popup='childPopup'>
          <Table borderless={true} id='assign-assets-complete-dialog'>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Job #</Table.Data><Table.Data className='value text-left'>{jobNumber}</Table.Data></Table.Row>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group #</Table.Data><Table.Data className='value text-left'>{groupNumber}</Table.Data></Table.Row>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Group Name</Table.Data><Table.Data className='value text-left'>{groupName}</Table.Data></Table.Row>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}># of Assets Assigned</Table.Data><Table.Data className='value text-left'>{assetCount}</Table.Data></Table.Row>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Receiving Ticket #</Table.Data><Table.Data className='value text-left'>{receivingTicketNumber}</Table.Data></Table.Row>
            <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Job Description</Table.Data><Table.Data className='value text-left job-description'><div>{jobDescription}</div></Table.Data></Table.Row>
          </Table>
        </Dialog.OKDialog>, 'childPopup'
      );
      if (response.OK) {

        const closeParentDialog = Dialog.handleResult({ popup: 'popup', Cancel: true });  // (document.getElementsByClassName('w2ui-msg-buttons'));
        closeParentDialog(new Event('click'));
      }
    });
  }

  save = () => {
    if (!this.canAssignToJob()) {
      return false;
    }
    const containerAssets = this.state.assets.filter(asset => asset.container && asset.container.id != Utils.emptyGuid);
    const state = this.state;
    const hours = parseFloat(isNaN(state.hours) ? 0 : state.hours);
    const minutes = parseFloat(isNaN(state.minutes) ? 0 : state.minutes);
    const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
    const loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      // if(hours>23)
      // {
      //   notification.action('error').post("Hours cannot be more than 23");
      //   return false;
      // }
      // if(minutes>60)
      // {
      //   notification.action('error').post("Minutes cannot be more than 60");
      //   return false;
      // }
      if (value <= 0) {
        notification.action('error').post("Receiving Time should be greater than 0");
        return false;
      }
      if (parseFloat(value).toFixed(1) <= 0) {
        notification.action('error').post("After rounding to 1 decimal, time will become 0. Receiving Time should be greater than 0");
        return false;
      }
      var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (value > availableTime) {
        notification.action('error').post("Receiving Time should not be more than Available Production Time");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
    }
    const parameters = {
      customerId: state.customerId,
      facilityId: state.facilityId,
      jobId: state.selectedJob.id,
      receivingLocationId: state.receivingLocation.id,
      customerJobNumber: state.customerJobNumber,
      customerPO: state.customerPO,
      jobDescription: state.jobDescription,
      jobNotes: state.jobNotes,
      removeFromContainer: state.removeFromContainer,
      groupNumber: state.groupNumber && state.groupNumber.name,
      groupName: state.groupName,
      value: value,
      TransactionDate: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
      costCenter: state.costCenter.id,
      assets: state.assets.map(asset => {
        return {
          assetId: asset.assetId,
          customerId: asset.customer.id,
          currentLocationId: asset.currentLocation.id,
          primarySerialNumber: asset.primarySerialNumber,
          eAssetStatus: asset.eAssetStatus,
          equipmentNumber: asset.equipmentNumber,
          plantCode: asset.plantCode
        };
      })
    };
    this.saving = true;
    this.commit()
    ajax.post('receiving/assignAssetsToJob', parameters).then(results => {
      this.channel.action('saved').post();
      const assetText = ' asset' + ((results.assetCount !== 1) ? 's' : '');
      const msg = 'Assigned ' + results.assetCount + assetText + ' to job ' + results.jobNumber + ' in group number ' + results.groupNumber;
      notification.action('info').post({ msg: msg, title: 'Assign Assets to Job Complete', position: 'toast-bottom-left' });
      //messages.channel('productiveTime').action('update').post(results.prodTime); 
      if (containerAssets.length > 0) {
        messages.channel('load-container-assets').action('reload').post();
      }
      this.saving = false;
      this.commit();
      this.showAssignCompleteDialog(results);
      return true;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }

  assignSelectedAssets = (assets) => {
    this.selectedAssets = assets;
  }
}

export default AssignAssetsToJobController;
