import React from 'react';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import Dropdown from '../../components/Dropdown';
import Dialog from '../../components/Dialog';
import ActionIcon from '../../components/ActionIcon';
import ChangeFacilityDialog from '../dialogs/ChangeFacilityDialog';
import ChangeFacilityController from '../controllers/ChangeFacilityController';
import ChangePasswordDialog from '../dialogs/ChangePasswordDialog';
import ChangePasswordController from '../controllers/ChangePasswordController';
import LateTimeEntryDialog from '../dialogs/LateTimeEntryDialog';
import LateTimeEntryController from '../controllers/LateTimeEntryController';
import AdditionalTimeDialog from '../dialogs/AdditionalTimeDialog';
import AdditionalTimeController from '../controllers/AdditionalTimeController';
import TimeConfirmationDialog from '../dialogs/TimeConfirmationDialog';
import TimeConfirmationController from '../controllers/TimeConfirmationController';
import ManualPushToCATSDialog from '../dialogs/ManualPushToCATSDialog';
import ManualPushToCATSController from '../controllers/ManualPushToCATSController';
import styled from 'styled-components';
import { ManHours } from './PageHeader';

const UserProfileStyles = styled.div`
  cursor: pointer;
  .dropdown-menu{
      z-index: 1501;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .user-profile-text{
    display: inline-block;
  }  

  .user-fullname{
    width: 100%;
    text-align: right;
    padding: 0 10px 0 0;
    font-size: 0.9rem;
  }  

  .user-facility{
    width: 100%;
    text-align: right;
    padding: 0 10px 0 0;
    font-size: 0.825rem;
  }  

  .user-facility-sap{
    font-size: 0.675rem;
    font-weight: bold;
    color: red;
  }
`;

const UserProfile = (defaultProps) => {

    const props = {
        linkId: 'userProfileMenuLink',
        ...defaultProps
    }

    const handleLogout = (evt) => {
        evt.preventDefault();
        LoginService.logout();
    }

    const handleChangeFacility = (evt) => {
        evt.preventDefault();
        Dialog.showDialog(<ChangeFacilityDialog controller={new ChangeFacilityController()} />);
    }

    const handleChangePassword = (evt) => {
        evt.preventDefault();
        Dialog.showDialog(<ChangePasswordDialog controller={new ChangePasswordController(loginInfo)} />);
    }

    const handleManualTimeConfirmation = (evt) => {
        evt.preventDefault();
        Dialog.showDialog(<ManualPushToCATSDialog controller={new ManualPushToCATSController()} />);
    }

    const handleLateTimeEntry = (evt) => {
        evt.preventDefault();
        Dialog.showDialog(<LateTimeEntryDialog controller={new LateTimeEntryController()} />);
    }

    const handleAdditionalTimeEntry = (evt) => {
        evt.preventDefault();
        Dialog.showDialog(<AdditionalTimeDialog controller={new AdditionalTimeController()} />);
    }

    const handleBreakInClick = (evt) => {
        evt.preventDefault();
        LoginService.BreakIn();
    }

    const handleBreakOutClick = (evt) => {
        evt.preventDefault();
        LoginService.BreakOut();
    }

    const handleClockOutClick = (evt) => {
        evt.preventDefault();
        Dialog.showDialog(<TimeConfirmationDialog controller={new TimeConfirmationController()} />);
    }

    const loginInfo = LoginService.loginInfo;
    const fullName = loginInfo ? loginInfo.fullName : '';
    const contextName = loginInfo ? loginInfo.isCustomer ? loginInfo.customerName : loginInfo.facilityName : '';
    const useSAP = SAPService.isSAPFacility();
    const useTTS = SAPService.useTTS();
    let spanSAP = null;
    let changeFacility = null;

    const timeConfirmation = useSAP && !useTTS
        ? <Dropdown.MenuItem onClick={handleManualTimeConfirmation} icon='fa-clock' name='Time Confirmation to CATS' />
        : null;

    const latetimeEntry = (useSAP && useTTS && !loginInfo.isBreakOut)
        ? <Dropdown.MenuItem onClick={handleLateTimeEntry} icon='fa-clock' name='Late Time Entry' />
        : null;

    const additionaltimeEntry = (useSAP && useTTS && loginInfo.isClockedIn && !loginInfo.isClockedOut && !loginInfo.isBreakOut)
        ? <Dropdown.MenuItem onClick={handleAdditionalTimeEntry} icon='fa-clock' name='Additional Production Time' />
        : null;

    const clockOut = (useSAP && useTTS && loginInfo.isClockedIn && !loginInfo.isClockedOut && !loginInfo.isBreakOut)
        ? <Dropdown.MenuItem onClick={handleClockOutClick} icon='fa-clock' name='Clock Out' />
        : null;

    const breakInbreakOut = (useSAP && useTTS && loginInfo.isClockedIn && !loginInfo.isClockedOut && loginInfo.isBreakOut)
        ? <Dropdown.MenuItem onClick={handleBreakInClick} icon='fa-clock' name='Ready to work' />
        : ((useSAP && useTTS && loginInfo.isClockedIn && !loginInfo.isClockedOut)
            ? <Dropdown.MenuItem onClick={handleBreakOutClick} icon='fa-clock' name='Unpaid Break' />
            : null);

    if (loginInfo && !loginInfo.isCustomer) {
        spanSAP = useSAP ? <span className='user-facility-sap'> SAP</span> : null;
        changeFacility = <Dropdown.MenuItem onClick={handleChangeFacility} icon='fa-exchange-alt' name='Change Current Facility' />;
    }

    return (
        <Dropdown as={UserProfileStyles}>
            <Dropdown.Toggle as='a' id={props.linkId}>
                <div className='user-profile-text d-none d-lg-inline-block'>
                    <div className='user-fullname'>{fullName}</div>
                    <div className='user-facility'> <span className='user-facility-name'>{contextName}</span>{spanSAP}</div>
                </div>
                <ActionIcon icon='fa-user-circle' style={{ fontSize: '2.5rem' }} size='' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header className='user-profie-menu-item d-block d-lg-none' title={fullName} />
                <Dropdown.Header className='user-profie-menu-item d-block d-lg-none'><span className='user-facility-name'>{contextName}</span>{spanSAP}</Dropdown.Header>
                <div className='m-2 d-block d-md-none' ><ManHours /></div>
                <Dropdown.Divider className='d-md-block d-lg-none' />
                {changeFacility}
                <Dropdown.MenuItem onClick={handleChangePassword} icon='fa-key' name='Change Password' />
                <Dropdown.Divider />
                {timeConfirmation}
                {latetimeEntry}
                {additionaltimeEntry}
                {breakInbreakOut}
                {clockOut}
                <Dropdown.Divider />
                <Dropdown.MenuItem to='/scannedqrasset' icon='fa fa-qrcode' name='QR Scanned Assets' />
                {loginInfo && !loginInfo.isCustomer ? <Dropdown.MenuItem to='/releasenotes' icon='fa-file-alt fa-flip-horizontal' name='Release Notes' /> : null}
                {loginInfo && !loginInfo.isCustomer ? <Dropdown.Divider /> : null}
                <Dropdown.MenuItem onClick={handleLogout} icon='fa-sign-out-alt fa-flip-horizontal' name='Log Out' />
                <Dropdown.Divider />
                <Dropdown.MenuItem className='disabled'>{'Size of Local storage: ' + JSON.stringify(localStorage).length}</Dropdown.MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default UserProfile;