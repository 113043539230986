import store from 'store';
import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notifications from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';

class EditAssetController extends BaseController {
  constructor(plantCode) {
    super('edit-asset', {assetId               : '',
    isTPRS                : false,
    isHalliburton         : false,
    primarySerialNumber   : '',
    secondarySerialNumber : '',
    batchNumber           : '',
    equipmentNumber       : '',
    eAssetStatus          : '',
    bornOnDate            : '',
    container             : '',
    plantCode             : '',
    currentLocation       : '',
    inServiceDate         : '',
    notes                 : '',
    rfidTagId             : '',
    rfidTagId2            : '',
    rfidUserData          : '',
    errors                : {},
    originalRfidTagId     : '',
    originalRfidTagId2    : '',
    lockSerialNumber      : false,
    isFracCustomer        : false,
    writeEnable           : true,      
    isSaving              : false,
    jobNumber             : '',
    customerLocation      : ''});
    this.state.plantCode = plantCode;

    this._validator = new Validator();
    this._validator.add('primarySerialNumber', 'Primary Serial #', [{required: true}]);
    this._validator.add('bornOnDate',          'Born On Date'    , [{required: true}]);
    this._validator.add('currentLocation',          'Current Location'    , [{required: true}]);
    this._validator.add('customerLocation',          'Customer Location'    , [{required: true}]);
    //customerLocation
    // if(!SAPService.isSAPFacility()){
    //   this._validator.add('equipmentNumber',   'Equipment #', [{minLength: {params: 8}}]);
    // }

    messages.channel('edit-asset-container').action('saved').subscribe(assetId => {
      if (assetId === this.state.assetId) {
       this.load(assetId);
      }
    });
  }

  getInitialState(plantCode) {
    return {
      assetId               : '',
      isTPRS                : false,
      isHalliburton         : false,
      primarySerialNumber   : '',
      secondarySerialNumber : '',
      batchNumber           : '',
      equipmentNumber       : '',
      eAssetStatus          : '',
      bornOnDate            : '',
      container             : '',
      plantCode             : plantCode,
      currentLocation       : '',
      inServiceDate         : '',
      notes                 : '',
      rfidTagId             : '',
      rfidTagId2            : '',
      rfidUserData          : '',
      errors                : {},
      originalRfidTagId     : '',
      originalRfidTagId2    : '',
      lockSerialNumber      : false,
      isFracCustomer        : false,
      writeEnable           : true,      
      isSaving              : false,
      jobNumber             : '',
      customerLocation      : '',
      prevLocation          : '',
    };
  }

  clearRfidTag1() {
    this.state.rfidTagId = '';
    this.commit();
  }

  clearRfidTag2() {
    this.state.rfidTagId2 = '';
    this.commit();
  }

  hasRfidTag() {
    var asset      = this.state;
    var hasRfidTag = asset && (asset.rfidTagId || asset.rfidTagId2);
    return hasRfidTag;
  }

  canSaveWithRfidTag() {
    if (!this.hasRfidTag()) {
      return true;
    }
    return LoginService.isAdministrator;
  }

  canRetrieveEquipmentNumber() {
    return (!this.state.equipmentNumber && (this.state.eAssetStatus !== AppConstants.eAssetStatus.Scrapped) && SAPService.isSAPFacility());
  }

  canSave() {
    this._validator.validateAll(this.state);
    var errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  shouldProvideRfidUserData() {
    return (!this.state.isHalliburton && !this.state.isTPRS);
  }

  load(assetId,prevLocation) {
    if(prevLocation){
      this.state.prevLocation=prevLocation;
    }
    co(this, function* () {
      var results = yield ajax.get('lookup/getAsset/', { assetId: assetId }); // ReceivedAssetDto
      if (results !== null) {
        if (LoginService.isCustomer && LoginService.loginInfo.customerName != results.customer.name) {
          this.state.writeEnable = false;
          this.mergeState(this.getInitialState());
          this.commit();
          notifications.action('error').post({ title: 'InteServ Security Violation', msg: 'You do not have the appropriate security rights to view this Information' });
        }
        else{
          this.mergeState(this.getInitialState());
          this.mergeState(results);
          this.commit();
        }
      }
    });
  }

  has2RfidTags() {
    return this.state.isHalliburton;
  }

  isTPRS() {
    return this.state.isTPRS;
  }

  canWriteRfidTag() {
    var tag1HasValue = !!this.state.rfidTagId;
    var tag2HasValue = !!this.state.rfidTagId2;
    var canWriteTag1 = !tag1HasValue;
    var canWriteTag2 = this.has2RfidTags() ? !tag2HasValue : false;

    return canWriteTag1 || canWriteTag2;
  }

  mergeState(state) {
    this.state.assetId               = state.assetId;
    this.state.isHalliburton         = state.isHalliburton;
    this.state.isTPRS                = state.isTPRS;
    this.state.customer              = state.customer;
    this.state.description           = state.description;
    this.state.mfgPartNumber         = state.mfgPartNumber;
    this.state.primarySerialNumber   = state.primarySerialNumber;
    this.state.secondarySerialNumber = state.secondarySerialNumber;
    this.state.batchNumber           = state.batchNumber;
    this.state.equipmentNumber       = state.equipmentNumber;
    this.state.eAssetStatus          = state.eAssetStatus;
    this.state.status                = state.status;
    this.state.assetStatus           = { id: state.eAssetStatus, name: state.status };
    this.state.rejectReason          = state.rejectReason;
    this.state.rejectReasonId        = state.rejectReasonId;
    this.state.bornOnDate            = DateTimeFormatter.formatDropdownDate(state.bornOnDate);
    this.state.inServiceDate         = DateTimeFormatter.formatDropdownDate(state.inServiceDate);
    this.state.container             = state.container?.id !== Utils.emptyGuid ? state.container : "";
    this.state.currentLocation       = state.currentLocation;
    this.state.notes                 = state.notes;
    this.state.rfidTagId             = state.rfidTagId;
    this.state.rfidTagId2            = state.rfidTagId2;
    this.state.rfidUserData          = state.rfidUserData;
    this.state.errors                = {};
    this.state.originalRfidTagId     = state.rfidTagId;
    this.state.originalRfidTagId2    = state.rfidTagId2;
    this.state.lockSerialNumber      = state.lockSerialNumber;
    this.state.isFracCustomer        = state.isFracCustomer;
    this.state.jobNumber             = state.jobNumber;
    this.state.customerLocation      = state.customerLocation;
  }

  save(cb) {
    if (!this.canSave()) {
      return false;
    }

    var asset = this.state;
    var facility = store.get('currentFacility');
    var parameters = {
      assetId               : asset.assetId,
      plantCode             : facility.plantCode,
      primarySerialNumber   : asset.primarySerialNumber,
      secondarySerialNumber : asset.secondarySerialNumber,
      batchNumber           : asset.batchNumber,
      equipmentNumber       : asset.equipmentNumber,
      bornOnDate            : asset.bornOnDate,
      containerId           : asset.container && asset.container.id,
      currentLocationId     : asset.currentLocation && asset.currentLocation.id,
      inServiceDate         : asset.inServiceDate, // not updated - only used for validation
      eAssetStatus          : asset.assetStatus.id,
      notes                 : asset.notes,
      rejectReasonId        : asset.rejectReasonId,
      customerLocation      : asset.customerLocation && asset.customerLocation.id
    };

    co(this, function*() {
      try {
        this.state.isSaving=true;
        this.commit();
          var results = yield ajax.post('receiving/updateAsset', parameters); // in: UpdateAssetDto; out: ReceivedAssetDto
          try 
          {
            // var parameters1 = {
            //     assetId             : asset.assetId,
            //     primarySerialNumber : asset.primarySerialNumber,
            //     rfidTagId           : asset.rfidTagId,
            //     rfidTagId2          : asset.rfidTagId2,
            //     forceSave           : false
            //   };
            // var results1 = yield ajax.post('receiving/updateAssetRfid', parameters1); // in: UpdateAssetRfidDto; out: ReceivedAssetDto
            this.state.isSaving=false;
            this.commit();
            notifications.action('success').post({msg:'Saved changes for asset ' + results.primarySerialNumber});
            messages.channel('edit-asset').action('saved').post(results);
            cb();
          }
          catch (err) 
          {
            this.state.isSaving=false;
            this.commit();
              if (err.message.indexOf('already associated') !== -1) {
                this.handleSaveWithConfirmationDialog(err.message, cb);
              }
              else {
                notifications.action('error').post(err);
              }
          }       
      }
      catch (err) {
        this.state.isSaving=false;
        this.commit();
        notifications.action('error').post(err.message);
      }
    });
  }
  savePlannedDate(asset, plannedStartDate){    
    var parameters = {
      asset : asset,
      plannedStartDate : plannedStartDate
    };

    co(this, function*(){
      try{
        var results = yield ajax.post('receiving/updateAssetStartDate', parameters);
        if (results){
          notifications.action('success').post('Saved Cycle Count date for the asset');
          messages.channel('edit-asset').action('updated').post(asset);
        }     
      }
      catch(err){
        notifications.action('error').post(err);
      }
    });    
  }
  saveRfid(forceSave, cb) {
    var asset      = this.state;
    var parameters = {
      assetId             : asset.assetId,
      primarySerialNumber : asset.primarySerialNumber,
      rfidTagId           : asset.rfidTagId,
      rfidTagId2          : asset.rfidTagId2,
      forceSave           : forceSave
    };

    co(this, function*() {
      try {
        var results = yield ajax.post('receiving/updateAssetRfid', parameters); // in: UpdateAssetRfidDto; out: ReceivedAssetDto

        // Update the calling page
        notifications.action('success').post('Saved changes for asset' + results.primarySerialNumber);
        messages.channel('edit-asset').action('saved').post(results);
        cb();
      }
      catch (err) {
        if (err.message.indexOf('already associated') !== -1) {
          this.handleSaveWithConfirmationDialog(err.message, cb);
        }
        else {
          notifications.action('error').post(err);
        }
      }
    });
  }

  handleSaveWithConfirmationDialog(errorMessage, cb) {
    co(this, function*() {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={300} header='Tag already associated'>
          <div>{errorMessage}</div>
          <br />
          <div>Please confirm you wish to overwrite</div>
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }
      this.saveRfid(true, cb);
    });
  }

  containerSearch() {
    var self = this;
    return function* (searchTerm) {
      var parameters = {
        customerId : self.state.customer ? self.state.customer.id : Utils.emptyGuid,
        searchTerm : searchTerm
      };
      var results = yield ajax.get('lookup/searchCustomerContainers', parameters); // IList<LookupDto>
      return results;
    };
  }

  customerLocationSearch() {
    var self = this;
    return function* (searchTerm) {
      var parameters = {
        facilityId : LoginService.loginInfo.facilityId,
        customerId : self.state.customer ? self.state.customer.id : Utils.emptyGuid,
        searchTerm : searchTerm
      };
      var results    = yield ajax.get('lookup/searchNonTechnipCustomerLocations', parameters); // IList<CustomerLocationLookupDto>
      return results;
    };
  }

  assetActiveStatusSearch() {
    var self = this;
      return function* () {
        var statusList = AppConstants.ActiveAssetStatus;
        if(!self.state.isFracCustomer)
        {
          statusList = statusList.filter(item =>{ return item.id != 19 && item.id!=21})
        }
        else
        {
          if(self.state.eAssetStatus === 21)
          {
            statusList = statusList.filter(item =>{ return item.id === 21 || item.id === 3})
          }
          if(self.state.eAssetStatus === 19)
          {
            statusList = statusList.filter(item =>{ return item.id === 19 || item.id === 3})
          }
        }
        return statusList;
      };    
  }

}

export default EditAssetController;