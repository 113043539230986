import React from 'react';
import {useHistory} from 'react-router-dom';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Badge from '../../components/Badge';
import Search from '../components/Search';
import messages from '../../services/Messages';
import GroupBadge from '../../components/GroupBadge';
import Jumbotron from '../../components/Jumbotron';
import List from '../../components/List';

const AssetRow = (props) => {

  let history =  useHistory();

  const loadAssetDetails = (jobId, evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    history.push('/jobprogressassets/' + jobId);
    messages.channel('dialog').action('__close__').post({ Close: true });
    // window.location.hash = "#/jobprogressassets/" + jobId;
    // window.location.reload();
  }
  const asset = props.asset;
  const controller = props.controller;
  let className = 'asset';

  const selectable = ((asset.jobNumber && asset.jobComplete == 0) || asset.container); 
  const facility = controller.getCurrentFacility();
  const jobNumber = asset.jobNumber && asset.jobComplete == 0
    ? facility.facilityId == asset.facilityId
      ? <span><a className='asset-container'
        onClick={loadAssetDetails.bind(this, asset.jobId)}
      >{asset.jobNumber} </a> </span> : asset.jobNumber
    : null
  className += selectable ? ' non-selectable-row' : '';
  let statusColourClass = 'mr-1 status' + asset.assetStatus; // e.g.: statusScrapped
  if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
    statusColourClass = 'asset-status statusWeldRepair'
    if (asset.assetStatus === 'OS/Machining Repair') 
      statusColourClass = 'asset-status statusOSRepair'
  return (
    <List.Item className={className + ' p-2'} active={asset.selected} onClick={() => { props.controller.toggleSelection(asset); }} style={{ cursor: selectable ? 'not-allowed' : 'pointer' }}>
      <div className>
          <span className='font-weight-bold mr-1'>{asset.primarySerialNumber}</span>
          <span className={statusColourClass}>{asset.assetStatus}</span>
          <span className='mr-1'>{asset.description}</span>
          <span className='asset-container mr-1'>{(asset.container && '«On Container»' + asset.container) || '« no container »'}</span>
          <span className='asset-container mr-1'>{asset.jobNumber && asset.jobComplete == 0 && '  «On Job»'}
            {jobNumber}
          </span>
      </div>
    </List.Item>
  );
}

class SelectAssetsForContainerDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.addAssets();


  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const context = this.props.context;
    const container = controller.state.container;
    const customer = controller.state.customer;
    const assets = controller.state.assets;
    const selectedAssets = controller.getSelectedAssets();
    const assetCount = assets.length;
    let badgeText = controller.state.searchTerm ? 'No results' : ''; // default
    if (assetCount) {
      badgeText = (selectedAssets.length + ' / ' + assetCount + ' selected');
    }
    const header = <span>Please select the assets you wish to add to container <span
      className='font-weight-bold'>{container.description}</span> for <span
        className='font-weight-bold'>{customer.name}</span></span>;

    return (
      <Dialog {...this.props} header='Select Assets for Container' width={600} height={585}
        id='select-container-assets-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div>
            <div className='row'>
            <div className='col-12 text-center mb-2 h6'>
                {header}
              </div>
              <div className='col-12'>
                <Search className='mb-2' id='searchTerm' inputClassName='search-input text-uppercase' placeholder='primary serial number' autoFocus='true' bind={binder.bind('searchTerm')} clear={controller.clearSearchTerm} search={controller.assetSearch}></Search>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={this.props.controller.selectAll}>Select All</GroupBadge>
                <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={this.props.controller.selectNone}>Select None</GroupBadge>
                <Badge className='m-1' variant='info'>{badgeText}</Badge>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <Jumbotron className='assets-list'>
                  <List id='asset-list' className='row'>
                    {
                      assets.map(asset => {
                        return <AssetRow key={asset.assetId} controller={controller} asset={asset} context={context} />;
                      })
                    }
                  </List>
                </Jumbotron>
              </div>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons />
      </Dialog>
    );
  }
}

export default SelectAssetsForContainerDialog;