import React from 'react';
import ReactDOM from 'react-dom';
import store from 'store';
import $ from 'jquery';
import ActionIcon from '../../components/ActionIcon';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import notifications from '../../services/Notification';
import AssignAssetsToJobController from '../controllers/AssignAssetsToJobController';
import ReceivingController from '../controllers/ReceivingController.js';
import TransferToIdleAssetsController from '../controllers/TransferToIdleAssetsController';
import authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import AssignAssetsToJobDialog from '../dialogs/AssignAssetsToJobDialog';
import TransferToIdleAssetsDialog from '../dialogs/TransferToIdleAssetsDialog';
import ManufacturerWallReadings from './ManufacturerWallReadings';
import QRSticker from '../components/QRSticker';
import QRScanner from '../components/QRScanner';
import EditAssetController from '../controllers/EditAssetController';
import AppConstants from '../utility/AppConstants';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import Search from '../components/Search';
import AssignAssetsToAssemblyWorkOrderController from '../controllers/AssignAssetsToAssemblyWorkOrderController';
import AssignAssetsToAssemblyWorkOrderDialog from '../dialogs/AssignAssetsToAssemblyWorkOrderDialog';

const LookupAsset = (props) => {

  const binder = props.binder;
  const controller = props.controller;
  const asset = props.asset;
  const searchText = props.controller.state.searchText || '_';
  const canEdit = !!asset; // disabled   = controller.canProcess(asset) ? '' : 'true';
  const currentlocation = 'receiving';
  const handleQRInvalid = () => {
    notifications.action('warning').post({ msg: 'Invalid QR Code!', title: 'QR Code' });
    controller.clearSearchResults();
  }

  const qrScanner = controller.state.qrCanvasVisibility
    ? <QRScanner handleScan={controller.findAssetWithQRCode} handleQRInvalid={handleQRInvalid} />
    : '';

  return (
    <div className='col-md-6'>
      <SearchBox className='form-group col-md-12' asset={asset} controller={controller} binder={binder} />
      <div className='col-12'> {qrScanner} </div>
      <AssetDescription className='form-group col-md-12' asset={asset} controller={controller} />
      <div className='col-md-12'>
        <Button icon='fa-edit' to={'/asset/' + asset.assetId + '/' + currentlocation} id='edit-asset' variant='info' size='md' className='btn-block' disabled={!canEdit}>Edit Asset ...</Button>
        <Button icon='fa-plus-circle' to={'/createasset/' + searchText} id='create-asset' variant='success' size='md' className='btn-block' >Create New Asset ...</Button>
      </div>
    </div>
  );
}

const SearchBox = (props) => {

  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.findAsset();
  }

  const handleInput = (evt) => {
    props.controller.handleRfidSearch(evt.target.value);
  }

  const handleMouseSearch = () => {
    props.controller.findAsset();
  }

  const handleReceive = () => {
    props.controller.receiveAsset(props.asset);
  }

  const clearSearch = () => {
    props.controller.clearSearchText();
  }

  const handleQrVisiblity = () => {
    props.controller.toggleQrVisiblity();
  }

  const asset = props.asset;
  const canProcess = props.controller.canProcess(asset) ? '' : 'true';
  const binder = props.binder;
  const qrShowButton = props.controller.state.qrCanvasVisibility
    ? <Button icon='fa-qrcode' variant='info' size='md' className='col-sm-12' onClick={handleQrVisiblity}>Hide QR Scanner</Button>
    : <Button icon='fa-qrcode' variant='info' size='md' className='col-sm-12' onClick={handleQrVisiblity}>Show QR Scanner</Button>

  return (
    <div>
      <div className='search-container' onInput={handleInput} onKeyDown={handleKeyboardSearch}>
        <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' prependLabelIcon='fa-rss' placeholder='search criteria' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
      </div>
      <Button icon='fa-download' id='receive-asset' variant='warning' size='md' className='col-sm-12' disabled={canProcess} onClick={handleReceive}>Receive Asset</Button>
      {qrShowButton}
    </div>
  );
}

class AssetDescription extends React.Component {

  handleGetChartData = () => {
    this.props.controller.getChart();
  }

  handleRfid = () => {
    const asset = this.props.asset;
    store.set(asset.assetId, asset);
    store.set('callingRoute', this.props.location.pathname);
    this.props.history.push('editrfid/' + asset.assetId);
  }

  nextInspection = (asset) => {
    if (!asset || (asset.status === 'Scrapped')) {
      return null;
    }

    const nextInspectionDate = DateTimeFormatter.formatDate(asset.nextInspectionDate);
    if (asset && !nextInspectionDate) {
      return 'No History';
    }

    return `${asset.nextInspectionServiceLevel} ${nextInspectionDate}`;
  }


  getAssetStatus = (asset) => {
    let assetStatus = asset.status;
    if (asset.rejectReason && asset.status != 'Passed' && asset.status != 'Failed Inspection') {
      assetStatus += ' (' + asset.rejectReason + ')';
    }
    return assetStatus;
  }

  getScrapDetails = (asset) => {
    let scrapDetails = '';
    if (asset.scrapDetails) {
      const sh = asset.scrapDetails;
      scrapDetails = ` Failed ${sh.serviceLevelName} :: ${sh.testName} on job ${sh.jobNumber} at ${sh.facilityName} on ${DateTimeFormatter.formatDate(sh.scrapDate)} by ${sh.technician}`;
    }
    return scrapDetails;
  }

  handleCycleCountUpdate = () => {
    //Dialog.showDialog(<CycleCountDialog controller = {new CycleCountController()}  assets={[this.props.asset]} source={'receiving'}/>);
    const aController = new EditAssetController(this.props.asset.plantCode);
    if (this.props.asset.equipmentNumber == '') {
      notifications.action('error').post('selected asset does not contain equipment number.');
      return;
    }
    else if (this.props.asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped) {
      notifications.action('error').post('selected asset is scrapped.');
      return;
    }
    const isSAP = SAPService.isSAPFacility();
    aController.savePlannedDate(this.props.asset, DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()));
  }

  handleGenerateQRCodes = () => {    
    const qrSerialValues = {
      assetId: this.props.asset.assetId,
      primarySerialNumber: this.props.asset.primarySerialNumber,
      equipmentNumber: this.props.asset.equipmentNumber,
      mfgPartNumber: this.props.asset.mfgPartNumber,
      familyCode: this.props.asset.familyCode.name,
      nominalSize: this.props.asset.nominalSize.name,
      length: this.props.asset.length.name,
      cwp: this.props.asset.cwp.name,
      id: this.props.asset.id.name,
      style: this.props.asset.style.name,
      assetNumber : this.props.asset.assetNumber
    };
    const generatedQRCodes = <div>{<QRSticker qrValue={qrSerialValues} />}</div>;
    var newWindow = window.open('', '_blank');
    const title = 'QR code';
    newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" type="text/scss" href="src/scss/application.scss"> </head><body>');
    ReactDOM.render(generatedQRCodes, newWindow.document.body);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    $(newWindow).on('load', function () {
      newWindow.print();
    });
  }

  handleChangeCustomer = (asset) => {
    if (asset) {
      this.props.controller.changeCustomer(asset);
    }
  }

  handleAttachFiles = () => {
    const asset = this.props.controller.state.currentAsset;
    this.props.history.push('/inspectionattachments/' + asset.assetId + '/' + asset.primarySerialNumber);
  }

  render = () => {
    const asset = this.props.asset;
    const bornOnDate = asset && DateTimeFormatter.formatDate(asset.bornOnDate);
    const inServiceDate = asset && DateTimeFormatter.formatDate(asset.inServiceDate);
    const completionDate = asset && DateTimeFormatter.formatDate(asset.completionDate);
    let classNextInspection = null;
    let inspectionIcon = null;
    const daysUntilNextInspection = DateTimeFormatter.getMoment(asset.nextInspectionDate).diff(DateTimeFormatter.today(), 'days');

    if (daysUntilNextInspection <= 0) {
      classNextInspection = 'late-error';
      inspectionIcon = asset && 'late-error fa-circle';
    }
    else if (daysUntilNextInspection < 30) {
      classNextInspection = 'late-error';
    }
    else if (daysUntilNextInspection < 90) {
      classNextInspection = 'late-warning';
      inspectionIcon = 'late-warning fa-circle';
    }
    else if (daysUntilNextInspection > 90) {
      classNextInspection = 'due-date';
    }
    else if (daysUntilNextInspection > 30) {
      classNextInspection = 'due-warning';
    }
    else if (daysUntilNextInspection > 0) {
      classNextInspection = 'late-error';
    }

    const nextInspection = this.nextInspection(asset);
    if (!nextInspection || (nextInspection === 'No History')) {
      inspectionIcon = null;
    }

    const container = asset.container && asset.container.name;
    const notReceivableClass = asset.isReceivable
      ? 'hidden'
      : 'field-highlight';
    let statusColourClass = 'status' + asset.status; // e.g. statusScrapped
    if (asset.status === 'Weld Repair' || asset.status === 'Failed Inspection')
      statusColourClass = 'asset-status statusWeldRepair'
    if (asset.status === 'OS/Machining Repair')
      statusColourClass = 'asset-status statusOSRepair'
    const assetStatus = this.getAssetStatus(asset);
    const userStatus = asset.userStatus;
    const scrapDetails = this.getScrapDetails(asset);
    const customerLocationId = asset && asset.customerLocation ? asset.customerLocation.id : '';
    const currentLocationId = asset && asset.currentLocation ? asset.currentLocation.id : '';
    const canGetChartData = (asset && asset.hasChart)
      ? 'true'
      : '';
    const hasRfidTag = asset
      ? asset.rfidTagId || asset.rfidTagId2
        ? 'Yes'
        : 'No'
      : '';
    const isTPRS = asset
      ? asset.isTPRS
        ? 'Yes'
        : 'No'
      : '';
    const plannedStartDate = SAPService.isSAPFacility() && asset ? DateTimeFormatter.formatDate(asset.plannedStartDate) != '01-Jan-1900'
      ? <div><Form.StaticData label='Cycle Count Date' value={DateTimeFormatter.formatDate(asset.plannedStartDate)} /><Button icon='fa-redo-alt' variant='primary' size='sm' className='btn-text-wrap' onClick={this.handleCycleCountUpdate}>Cycle Count</Button></div>
      : <Button icon='fa-redo-alt' variant='primary' size='sm' className='btn-text-wrap' onClick={this.handleCycleCountUpdate}>Cycle Count</Button>
      : '';
    const notReceivable = asset.reasonNotReceivable
      ? <Form.StaticData label='Reason asset is not receivable' value={asset.reasonNotReceivable} className={notReceivableClass} />
      : null;
    const sapError = (SAPService.isSAPFacility() && (asset.sapErrorCode || asset.sapErrorMessage))
      ? <Form.StaticData label='SAP Error' value={asset.sapErrorCode + ' ' + asset.sapErrorMessage} className='field-highlight' />
      : null;
    const descriptionControl = LoginService.isSuperUser
      ? <Form.Link to={'/customerdescription/' + asset.customerDescriptionId} label='Description' value={asset.description && asset.description.name} />
      : <Form.StaticData label='Description' value={asset.description && asset.description.name} />;
    const customerLocationControl = LoginService.isSuperUser
      ? <Form.Link to={'/customerlocations/' + customerLocationId} label='Customer Location' value={asset.customerLocation && asset.customerLocation.name} />
      : <Form.StaticData label='Customer Location' value={asset.customerLocation && asset.customerLocation.name} />;
    const currentLocationControl = LoginService.isSuperUser
      ? <Form.Link to={'/customerlocations/' + currentLocationId} label='Current Location' value={asset.currentLocation && asset.currentLocation.name} />
      : <Form.StaticData label='Current Location' value={asset.currentLocation && asset.currentLocation.name} />;
    const equipmentNumber = asset.equipmentNumber ? asset.equipmentNumber : '-';
    const nextInspectionDate = DateTimeFormatter.formatDate(asset.nextInspectionDate);
    const displayNextInspection = (asset && nextInspectionDate) && this.props.controller.isGreenTagLocation();
    // <ServiceHistory controller={this.props.controller} />

    return (
      <Jumbotron className='my-1' id='asset-description'>

        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Primary Serial Number' value={<Form.Link to={'/assetdetail/' + asset.assetId} value={asset.primarySerialNumber} />} /></div>
          <div className='col-md-6'><Form.StaticData label='Secondary Serial Number' value={asset.secondarySerialNumber} /></div>
        </div>

        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Equipment Number' value={asset.equipmentNumber} /></div>
          <div className='col-md-6'><Form.StaticData label='Batch Number' style={{ wordBreak: 'break-word' }} value={asset.batchNumber} /></div>
        </div>
        {descriptionControl}
        {notReceivable}
        {sapError}
        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Customer' value={asset.customer && asset.customer.name} /></div>
          <div className='col-md-6'>{customerLocationControl}</div>
        </div>
        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Company Code' value={asset.companyCode} /></div>
          <div className='col-sm-6'><Form.StaticData label='MaintPlant' value={asset.plantCode} /></div>
        </div>
        <div className='row'>
          <div className='col-sm-6'><Form.StaticData label='User Status' value={userStatus} className={statusColourClass} /></div>
          <div className='col-md-6'><Form.StaticData label='Status' value={assetStatus} className={statusColourClass} /></div>
        </div>
        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Container' value={container} /></div>
          <div className='col-md-6'>{currentLocationControl}</div>
        </div>
        {scrapDetails &&
          <div className='row'>
            <div className='col-md-12'><Form.StaticData label='Scrap Details' value={scrapDetails} className='statusScrapped' /></div>
          </div>
        }
        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Born On Date' value={bornOnDate} /></div>
          <div className='col-md-6'><Form.StaticData label='In Service Date' value={inServiceDate} /></div>
        </div>

        <div className='row'>
          {displayNextInspection && <div className='col-md-6'><Form.StaticData label='Next Inspection' value={nextInspection} className={classNextInspection} icon={inspectionIcon} /></div>}
          {completionDate != '01-Jan-1900' && assetStatus == 'OS/Machining Repair' && <div className='col-sm-6'><Form.StaticData label='Scheduled Completion Date' value={completionDate} className={statusColourClass} /> </div>}
        </div>
        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Asset Number' value={asset.assetNumber} /></div>
          <div className='col-md-6'><Form.StaticData label='Inventory Number' value={asset.inventoryNumber} /></div>
        </div>
        <div className='row'>
          <div className='col-md-6'><Form.StaticData label='Object Type' value={asset.objectType} /></div>
          {/* <div className='col-md-6'><Form.StaticData label='Planning Plant'  value={asset.planningPlant} /></div> */}
          <div className='col-md-6'>
            {plannedStartDate}
          </div>
        </div>
        <Form.StaticData label='Notes' value={asset.notes} className='field-notes' />
        <Button icon='fa-chart-line' variant='info' size='sm' className='btn-text-wrap mt-1 mr-1' disabled={!canGetChartData} onClick={this.handleGetChartData}>Chart ...</Button>
        {/* <Button icon='fa-rss'    variant='info' size='sm'    className='' disabled={!asset}           style={{marginTop: '5px', marginRight: '5px;'}} onClick={this.handleRfid}>RFID ...</Button> */}
        <Button icon='fa-qrcode' variant='primary' size='sm' className='btn-text-wrap mt-1 mr-1' disabled={!asset} onClick={this.handleGenerateQRCodes.bind(this)}>Print QR</Button>
        <Button icon='fa-briefcase' variant='primary' size='sm' className='btn-text-wrap mt-1 mr-1' disabled={!asset || (!LoginService.isSuperUser && asset.customer && (asset.customer.name != 'TechnipFMC Fluid Control' && asset.customer.name != 'TechnipFMC In Transit'))} onClick={() => { this.handleChangeCustomer(asset); }}>Change Customer</Button>
        <Button to={'/inspectionattachments/' + asset.assetId + '/' + asset.primarySerialNumber + '/' + equipmentNumber} icon='fa-plus-circle' variant='success' size='sm' className='btn-text-wrap mt-1 mr-1' disabled={!asset}>Attach Files ...</Button>

        <div className='row form-group' style={{ marginTop: '25px' }}>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Family Code' value={asset && asset.familyCode.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Length' value={asset && asset.length.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='CWP' value={asset && asset.cwp.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Style' value={asset && asset.style.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Nominal Size' value={asset && asset.nominalSize.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Manufacturer' value={asset && asset.manufacturer.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='ID' value={asset && asset.id.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='OD' value={asset && asset.od.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Degree' value={asset && asset.degree.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Service' value={asset && asset.service.name} /></div>
          <div className='col-lg-3 col-md-4 col-sm-6'><Form.StaticData label='Mfg Part #' value={asset && asset.mfgPartNumber} /></div>
        </div>

        {/* <Form.Header className='col-sm-12' style={{ margin: '0', padding: '6px' }}>Manufacturer Wall Readings</Form.Header> */}
        <h6>Manufacturer Wall Readings</h6>
        <div className='row'><ManufacturerWallReadings wallReadings={asset && asset.wallReadings} /></div>
      </Jumbotron>
    );
  }
}

const ReceivedAssets = (props) => {

  const handleAssignToJob = () => {
    const assets = props.assets;
    const customerId = props.controller.state.currentCustomerId;
    const facilityId = LoginService.loginInfo && LoginService.loginInfo.facilityId;

    Dialog.showDialog(<AssignAssetsToJobDialog controller={new AssignAssetsToJobController(customerId, facilityId, assets)} />);
  }

  const handleAssignToAssemblyWorkOrder = () => {
    const assets = props.assets;
    const customerId = props.controller.state.currentCustomerId;
    const facilityId = LoginService.loginInfo && LoginService.loginInfo.facilityId;

    Dialog.showDialog(<AssignAssetsToAssemblyWorkOrderDialog controller={new AssignAssetsToAssemblyWorkOrderController(customerId, facilityId, assets)} />);
  }

  const handleTransferToIdleAssets = () => {
    const assets = props.assets;
    const length = assets.length;
    const header = 'Transferring ' + Utils.pluralize('Asset', 's', length);
    const customerId = props.controller.state.currentCustomerId;
    const facilityId = LoginService.loginInfo && LoginService.loginInfo.facilityId;
    const boneyards = props.controller.state.boneyards;
   
    Dialog.showDialog(<TransferToIdleAssetsDialog controller={new TransferToIdleAssetsController(customerId, facilityId, assets, boneyards)} header={header} />);
  }

  const handleShipAssets = () => {
    const controller = props.controller;
    messages.channel('shipReceivableAssets').action('shipAssets').post(controller.state.receivedAssets);
    //store.set('shipreceivableassets', controller.state.receivedAssets);  
  }

  const assets = props.assets;
  const controller = props.controller;
  const canAssignToJob = controller.canAssignToJob() ? '' : 'true';
  const canTransferToIdleAssets = controller.canTransferToIdleAssets() ? '' : 'true';
  const canShipAssets = controller.canShipAssets() ? '' : 'true';
  const isSAPFacility=  SAPService.isSAPFacility();
  const binder = props.binder;
  const style = { paddingRight: '0' };
  const queryData = {
    customerId: controller.state.currentCustomerId,
    customerName: controller.state.currentCustomerName,
    facilityId: LoginService.loginInfo && LoginService.loginInfo.facilityId,
    facilityName: LoginService.loginInfo && LoginService.loginInfo.facilityName,
    assets: controller.state.receivedAssets
  };

  return (
    <div id='received-assets' className='col-md-6'>
      <ReceivedAssetsHeader controller={controller} assets={assets} binder={binder} />
      <ReceivedAssetsList controller={controller} assets={assets} />

      <div className='col-md-12 row' style={style}>

      {/* {isSAPFacility &&  <div className='col-sm-12' style={style}> 
        <Button icon='fa-arrow-circle-right' id='assign-to-job' variant='success' size='md' className='btn-block btn-min-width' disabled={canAssignToJob} onClick={handleAssignToJob}>Assign to Job ...</Button>
        </div>
      } */}
      {<div className='col-sm-12 col-md-6' style={style}> 
        <Button icon='fa-arrow-circle-right' id='assign-to-job' variant='success' size='md' className='btn-block btn-min-width' disabled={canAssignToJob} onClick={handleAssignToJob}>Assign to Job ...</Button>
        </div>
      }
      {<div className='col-sm-12 col-md-6' style={style}>
        <Button icon='fa-arrow-circle-right' id='assign-to-awo' variant='success' size='md' className='btn-block btn-min-width' disabled={canAssignToJob} onClick={handleAssignToAssemblyWorkOrder}>Assign to Assembly Work Order ...</Button>
       </div>
       }
        </div>
      <div className='col-md-12 row' style={style}>
        <div className='col-sm-12 col-md-6' style={style}>             
         <Button icon='fa-align-justify' id='transfer-to-boneyard' variant='danger' size='md' className='btn-block btn-min-width' disabled={canTransferToIdleAssets} onClick={handleTransferToIdleAssets}>Idle Assets/Program Iron ...</Button>
         </div>
        <div className='col-sm-12 col-md-6' style={style}>
          <Button icon='fa-truck' to={{ pathname: '/shipreceivedassets', queryData: queryData }} id='ship-assets' variant='success' size='md' className='btn-block btn-min-width' disabled={canShipAssets} onClick={handleShipAssets}>Receive/Ship to ...</Button>
        </div>
      </div>
    </div>
  );
}

const ReceivedAsset = (props) => {
  const handleRemoveAsset = () => {
    co(function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Asset'>
          {'Please confirm you wish to remove asset with serial number ' + props.asset.primarySerialNumber + ' from the list'}
        </Dialog.OKCancelDialog>
      );

      if (result.OK) {
        props.controller.removeReceivedAsset(props.asset);
      }
    });
  }

  const asset = props.asset;

  return (
    <List.Item {...props} className='form-group asset'>
      <div className='row'>
        <div className='col-md-1'>
          <ActionIcon icon='fa-times-circle fa-2x text-danger' action={handleRemoveAsset} />
        </div>
        <div className='form-group col-md-11'>
          <span className='field-data font-weight-bold'>{asset.primarySerialNumber}</span>
          <div className='field-label'>{asset.description.name}</div>
        </div>
      </div>
    </List.Item>
  );
}
const ReceivedAssetsHeader = (props) => {
  const handleRemoveAll = () => {
    co(function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove All'>
          {'Please confirm you wish to remove all assets from this list'}
        </Dialog.OKCancelDialog>
      );

      if (result.OK) {
        props.controller.clearResults();
      }
    });
  }
  const count = props.assets.length;
  const label = Utils.pluralize('asset', 's', count);
  const isRemoveAllDisabled = (count === 0);
  const customer = props.controller.state.currentCustomerName;

  return (
    <div {...props} className='form-group'>
      <span className='h4'>{customer}</span>
      <div className='h5 mt-1'>Received Assets</div>
      <div className='form-group mt-1' id='btn-asset-count'>
        <Button icon='fa-times-circle' disabled={isRemoveAllDisabled} variant='danger' size='sm' className='m-1' onClick={handleRemoveAll}>Remove all</Button>
        <Badge variant='info' className='m-1'>{label}</Badge>
      </div>
    </div>
  );
}

const ReceivedAssetsList = (props) => {
  const controller = props.controller;
  const assets = props.assets;

  return (
    <Jumbotron className='received-asset-list mb-2' id='asset-list'>
      <List {...props} className='form-group'>

        {assets.map(asset => {
          return <ReceivedAsset key={asset.assetId} controller={controller} asset={asset} />;
        })}

      </List>
    </Jumbotron>
  );
}

const ReceivingPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-download' />

class ReceivingPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, authentication);
  }
  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
    const assetId = this.props.match.params.assetId || '';
    this.props.controller.load(assetId);

    messages.channel('change-facility').action('changed').subscribe(() => {
      const location = this.props.location;
      if (location.pathname !== '/') {
        return;
      }
      this.props.controller.getBoneyards();
    });
  }
  static defaultProps = {
    controller: new ReceivingController()
  }
  render = () => {
    const controller = this.props.controller;
    const state = controller.state;
    const binder = new Binder(this);

    return (
      <Page {...this.props} pageTitle='InteServ · Receiving' id='receiving-page'>
        <ReceivingPageHeader pageTitle={'Receive Assets'} />
        <Page.Content>
          <div className='row'>
            <LookupAsset controller={controller} binder={binder} asset={state.currentAsset} />
            <ReceivedAssets controller={controller} binder={binder} assets={state.receivedAssets} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default ReceivingPage;
