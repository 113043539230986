
import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Shortcut from '../../components/Shortcut';
import LoginResetPasswordController from '../controllers/LoginResetPasswordController';

class LoginResetPasswordDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new LoginResetPasswordController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (evt) =>  evt.Cancel ? true : this.props.controller.save();

    handleSubmit = (evt) => {
        evt.preventDefault();
        this.props.controller.save();
    }

    render() {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Dialog {...this.props} modal={true} header={this.props.header} width={400} height={230} id='reset-password-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <Shortcut shortcutKey='enter' preventDefault action={this.handleSubmit} />
                    <form role='form'>
                        <Form.Input id='loginname' label='Enter Loginname:' className='required' bind={binder.bind('loginname')} error={controller.state.error}/>
                    </form>
                </Dialog.Body>
                <Dialog.SaveCancelButtons saving={controller.state.isLoading} />
            </Dialog>
        );
    }
}

export default LoginResetPasswordDialog;