import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuItem = (propsParam) => {
    const props = {
        canShow: true,
        ...propsParam
    }

    const menuItemStyle = {
        padding: '0.625rem',
        minHeight:'2.5rem',
        ...props.style,
    }

    const title = props.isExpanded ? <span className={'nav-menu-item-text font-weight-bold'} style={{fontSize: '0.800rem'}}>{props.title}</span> : null

    const className =  props.isExpanded ? 'justify-content-between': 'justify-content-center';

    return (
        props.canShow
            ? <NavLink className={'nav-menu-item nav-item nav-link d-flex align-items-center ' + className} activeClassName='active' style={menuItemStyle} to={props.to}>
                {title}
                <span className={props.icon + ' nav-menu-item-icon'} style={{fontSize: '0.950rem'}} title={props.title} />
            </NavLink>
            : null
    );
}

const ToggleButton = (propsParam) => {

    const props = {
        openIcon: 'fas fa-bars',
        closeIcon: 'fas fa-times',
        ...propsParam
    }

    const toggleButtonStyle = {
        fontSize: '1.2rem',
        height: '3.75rem',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        ...props.style,
    }

    const className =  props.isExpanded ? 'justify-content-between': 'justify-content-center';

    return (
        <div className={'nav-menu-toggle-button sticky-top d-flex align-items-center ' + className} style={toggleButtonStyle} onClick={() => { props.setIsExpanded(!props.isExpanded) }}>
            <span className={'m-3 ' + (props.isExpanded ? props.closeIcon : props.openIcon)} />
        </div>
    );
}


const SideNavMenuStyles = styled.div`
    .side-nav-menu{
        background-color: #E8E8E8 !important;
        min-height: 100vh;
        padding: 0;
        align-items: unset;
        z-index: 1501;
        width: ${props => props.isExpanded ? '15.625rem' : '2.5rem'}
    }
    .navbar-nav{
        width: inherit;
    }
    .menu-content{
        width:  calc(100vw - ${props => props.isExpanded ? '15.625rem' : '2.5rem'})
    }
`;

const SideNavMenu = (defaultProps) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const props = {
        toggleOpenIcon: 'fas fa-bars',
        toggleCloseIcon: 'fas fa-times',
        showToggle: true,
        ...defaultProps
    }

    const links = props.links ? props.links : [];
    let className = 'side-nav-menu navbar navbar-light';
    className += isExpanded ? ' expanded' : ''; 
    const routeLinks = links.map((link, index) => <MenuItem {...link} key={index} isExpanded={isExpanded} style={props.menuItemStyle}/>);

    return (
        <SideNavMenuStyles className='d-flex' isExpanded={isExpanded}>
            <div style={props.style} className={className}>
                <div className='navbar-nav'>
                    {props.showToggle ? <ToggleButton setIsExpanded={setIsExpanded} isExpanded={isExpanded} openIcon={props.toggleOpenIcon} closeIcon={props.toggleCloseIcon} style={props.toggleButtonStyle}/> : null}
                    {routeLinks}
                </div>
            </div>
            <div className='menu-content'>
                {props.children}
            </div>
        </SideNavMenuStyles>

    );
}

SideNavMenu.propTypes = {
    links: PropTypes.array
}

export default SideNavMenu;