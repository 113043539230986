import React from 'react';
import { Pagination as RBPagination } from 'react-bootstrap';

const Pagination = (props) => {
    return(<RBPagination {...props} style={{marginBottom: '0.5rem', ...props.style}}/>);
}

Pagination.Item = (props) => {
    return(<RBPagination.Item {...props}/>);
}

Pagination.First = (props) => {
    return(<RBPagination.First {...props}/>);
}

Pagination.Prev  = (props) => {
    return(<RBPagination.Prev  {...props}/>);
}

Pagination.Next  = (props) => {
    return(<RBPagination.Next  {...props}/>);
}

Pagination.Last = (props) => {
    return(<RBPagination.Last {...props}/>);
}

Pagination.Ellipsis  = (props) => {
    return(<RBPagination.Ellipsis {...props}/>);
}

export default Pagination;