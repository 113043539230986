
import React from 'react';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import EndConfigurationRowController from './EndConfigurationRowController';
import ManufacturerDescriptionWallReadingRowController from './ManufacturerDescriptionWallReadingRowController';
import ManDescTorqueReadingRowController from './ManDescTorqueReadingRowController';
import ManDescDimensionRowController from './ManDescDimensionRowController'
import Dialog from '../../components/Dialog';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';

class EditManufacturerDescriptionController extends BaseController {
    constructor() {
        super('edit-man-desc', EditManufacturerDescriptionController.getInitialState(''));

        this.adding = false;
        this._validator = new Validator();
        this._validator.add('familyCode', 'Family Code', [{ required: true }]);
        this._validator.add('length', 'Length', [{ required: true }]);
        this._validator.add('cwp', 'CWP', [{ required: true }]);
        this._validator.add('style', 'Style', [{ required: true }]);
        this._validator.add('nominalSize', 'Nominal Size', [{ required: true }]);
        this._validator.add('manufacturer', 'Manufacturer', [{ required: true }]);
        this._validator.add('id', 'ID', [{ required: true }]);
        this._validator.add('od', 'OD', [{ required: true }]);
        this._validator.add('degree', 'Degree', [{ required: true }]);
        this._validator.add('service', 'Service', [{ required: true }]);

        messages.channel('edit-man-desc').action('saved').subscribe(manDesc => {
            this.load(manDesc.manDescId);
        });
    }

    toggleupdateWallReadingsSection = () => {
        this.state.updateWallReadings = !this.state.updateWallReadings;
        this.commit();
    }

    familyCodeChanged = () => {
        this.checkUniqueness();
    }

    lengthChanged = () => {
        this.checkUniqueness();
    }

    cwpChanged = () => {
        this.checkUniqueness();
    }

    styleChanged = () => {
        this.checkUniqueness();
    }

    nominalSizeChanged = () => {
        this.checkUniqueness();
    }

    manufacturerChanged = () => {
        this.checkUniqueness();
    }

    idChanged = () => {
        this.checkUniqueness();
    }

    odChanged = () => {
        this.checkUniqueness();
    }

    degreeChanged = () => {
        this.checkUniqueness();
    }

    serviceChanged = () => {
        this.checkUniqueness();
    }

    mfgItemNoChanged = () => {
        this.checkUniqueness();
    }

    hasNoId = (prop) => {
        return (!prop || (prop.id === Utils.emptyGuid));
    }

    canCheckUniqueness = () => {
        const s = this.state;
        if (this.hasNoId(s.familyCode)) {
            return false;
        }
        if (this.hasNoId(s.manufacturer)) {
            return false;
        }
        if (this.hasNoId(s.nominalSize)) {
            return false;
        }
        if (this.hasNoId(s.cwp)) {
            return false;
        }
        if (this.hasNoId(s.degree)) {
            return false;
        }
        if (this.hasNoId(s.id)) {
            return false;
        }
        if (this.hasNoId(s.od)) {
            return false;
        }
        if (this.hasNoId(s.length)) {
            return false;
        }
        if (this.hasNoId(s.style)) {
            return false;
        }
        if (this.hasNoId(s.service)) {
            return false;
        }
        return true;
    }

    checkUniqueness = () => {
        if (!this.canCheckUniqueness()) {
            return;
        }

        const s = this.state;
        const parameters = {
            manDescId: s.manDescId,
            familyCodeId: s.familyCode.id,
            manufacturerId: s.manufacturer.id,
            nominalSizeId: s.nominalSize.id,
            cwpId: s.cwp.id,
            degreeId: s.degree.id,
            idId: s.id.id,
            odId: s.od.id,
            lengthId: s.length.id,
            styleId: s.style.id,
            serviceId: s.service.id,
            mfgItemNo: s.mfgItemNo
        };
        ajax.get('lookup/checkManufacturerDescriptionUniqueness/', parameters).then(isUniqueMessage => {
            if (isUniqueMessage) {
                notification.action('warning').post({ msg: isUniqueMessage, title: 'Uniqueness Check Failure' });
            }
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });  // in: Guid, out: string
    }

    load = (manDescId) => {
        if (manDescId === Utils.emptyGuid) {
            this.adding = true;
            this.state = EditManufacturerDescriptionController.getInitialState(manDescId);
            this.commit();
        }
        else {
            this.adding = false;
            ajax.get('lookup/getManufacturerDescription/', { manDescId: manDescId }).then(results => {
                if (results === null) {
                    notification.action('warning').post({ msg: 'Unable to load Manufacturer Description &ndash; the one specified does not existing &ndash; creating a new one', title: 'Manufacturer Description Not Found' });
                    this.load(Utils.emptyGuid);
                }
                else {
                    this.merge(results);
                    this.commit();
                }
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });  // in: Guid, out: ManufacturerDescriptionDto
        }
    }

    merge = (manDesc) => {
        if (!manDesc) {
            return;
        }

        this.state = Object.assign({}, manDesc);
        this.state.mfgItemNo = manDesc.mfgItemNo && manDesc.mfgItemNo.name;
        this.state.endConfigurations = [];
        this.state.wallReadings = [];
        this.state.torqueReadings = [];
        this.state.dimensions = [];
        this.state.errors = {};

        manDesc.endConfigurations.forEach(config => this.addNewEndConfigurationRow(config));
        manDesc.wallReadings.forEach(reading => this.addNewWallReadingRow(reading));
        manDesc.torqueReadings.forEach(reading => this.addNewTorqueReadingRow(reading));
        manDesc.dimensions.forEach(dimension => this.addNewDimensionRow(dimension));
    }

    static getInitialState = (manDescId) => {
        return {
            manDescId: manDescId,
            familyCode: '',
            manufacturer: '',
            nominalSize: '',
            cwp: '',
            degree: '',
            id: '',
            od: '',
            length: '',
            style: '',
            service: '',
            // typeApproval      : '',
            // tempRange         : '',
            // loadRating        : '',
            mfgItemNo: '',
            altItemNo: '',
            description: '',
            hasDiagram: false,
            customerCount: 0,
            assetCount: 0,
            endConfigurations: [],
            wallReadings: [],
            torqueReadings: [],
            dimensions: [],
            laborCodes: [],
            notes: '',
            verified: {},
            updatedBy: {},
            errors: {},
            isSaving: false,
            isFMCPart: false,
            customer: '',
            updateWallReadings: false,
            isUTScope: false,
           };
    }

    clearCustomerValue = () => {
        this.state.customer = '';
        this.commit();
    }

    customerSearch = () => {
        return (searchTerm) => ajax.get('lookup/searchCustomerList', { searchTerm: searchTerm })
            .then(result => result).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
    }

    isCustomerAlreadyPresent = (customer) => {
        const filteredList = this.state.customerDescriptions.filter(cd => cd.customer.id === customer.id);
        return filteredList.length;
    }

    search = (searchType) => {
        if (!searchType) {
            return null;
        }
        return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), { searchTerm: searchTerm })
            .then(result => result).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
    }

    verify = () => {
        if (this.adding) {
            notification.action('warning').post({ msg: 'Unable to verify new Manufacturer Descriptions &ndash; please save first before verifying', title: 'Verification Error' });
            return;
        }

        const message = <div>
            <h5 style={{ lineHeight: '1.4' }}>Please confirm you wish to complete verification of this Manufacturer Description</h5>
            <h6>Ensure the verification protocol has been met</h6>
        </div>;

        co(this, function* () {
            var result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog width={500} height={300} header='Confirm Verification'>
                    {message}
                </Dialog.OKCancelDialog>
            );
            if (!result.OK) {
                return;
            }

            const manDescId = this.state.manDescId;
            const parameters = {
                manDescId: manDescId
            };

            try {
                yield ajax.post('manufacturerDescription/verify', parameters); // in: VerifyManufacturerDescriptionDto, out: void
                notification.action('success').post('Verified Manufacturer Description');
                messages.channel('edit-man-desc').action('saved').post({ manDescId: manDescId });
            }
            catch (err) {
                notification.action('error').post({ msg: err.message, title: 'Verification Error' });
            }
        });
    }

    removeDiagram = () => {
        if (!this.state.hasDiagram) {
            notification.action('warning').post('No diagram – nothing to remove');
            return;
        }

        const message = 'Please confirm you wish to remove this diagram – the file will be deleted';
        const manDescId = this.state.manDescId;

        co(this, function* () {
            const result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog width={500} height={300} header='Confirm Remove Diagram'>
                    {message}
                </Dialog.OKCancelDialog>
            );
            if (!result.OK) {
                return;
            }

            yield ajax.post('manufacturerDescription/removeDiagram', manDescId); // in: Guid, out: void
            messages.channel('edit-man-desc').action('saved').post({ manDescId: manDescId });
        });
    }

    uploadDiagram = (res, file) => {
        const parameters = {
            id: this.state.manDescId,
            fileName: file.fileName,
            fileType: file.fileType,
            imgUrl: file.url
        };

        ajax.post('manufacturerDescription/uploadDiagram', parameters).then(() => {
            messages.channel('edit-man-desc').action('saved').post({ manDescId: this.state.manDescId });
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });  // in: UploadFileDto, out: void
    }

    addNewEndConfigurationRow = (config) => {
        this.state.endConfigurations.push(new EndConfigurationRowController(this, config, this.state.manDescId));
        this.commit();
    }

    removeEndConfigurationRow = (row) => {
        const idx = this.state.endConfigurations.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.endConfigurations.splice(idx, 1);
        this.commit();
    }

    addNewWallReadingRow = (reading) => {
        this.state.wallReadings.push(new ManufacturerDescriptionWallReadingRowController(this, reading, this.state.manDescId));
        this.commit();
    }

    addNewTorqueReadingRow = (reading) => {
        this.state.torqueReadings.push(new ManDescTorqueReadingRowController(this, reading, this.state.manDescId));
        this.commit();
    }

    addNewDimensionRow = (dimension) => {
        this.state.dimensions.push(new ManDescDimensionRowController(this, dimension, this.state.manDescId));
        this.commit();
    }

    removeWallReadingRow = (row) => {
        const idx = this.state.wallReadings.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.wallReadings.splice(idx, 1);
        this.commit();
    }

    removeTorqueReadingRow = (row) => {
        const idx = this.state.torqueReadings.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.torqueReadings.splice(idx, 1);
        this.commit();
    }

    removeDimensionRow = (row) => {
        const idx = this.state.dimensions.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.dimensions.splice(idx, 1);
        this.commit();
    }

    validateEndConfigurationRows = (state) => {
        state.endConfigurations.forEach(row => {
            const configurationId = row.state.configurationId;
            const endConnectionSize = row.state.endConnectionSize;
            const endConfiguration = row.state.endConfiguration;
            const endConnection = row.state.endConnection;

            if (configurationId) {
                delete row.state.errors.configurationId;
            }
            else {
                row.state.errors.configurationId = 'ID is required';
            }

            if (endConnectionSize) {
                delete row.state.errors.endConnectionSize;
            }
            else {
                row.state.errors.endConnectionSize = 'A value for End Connection Size must be selected';
            }

            if (endConfiguration) {
                delete row.state.errors.endConfiguration;
            }
            else {
                row.state.errors.endConfiguration = 'A value for End Configuration must be selected';
            }

            if (endConnection) {
                delete row.state.errors.endConnection;
            }
            else {
                row.state.errors.endConnection = 'A value for End Connection must be selected';
            }
        });
    }

    validateWallReadingRows = (state) => {
        state.wallReadings.forEach(row => {
            const reading = row.state;
            const readingId = reading.readingId;
            const newDimension = reading.newDimension;
            const minimumDimension = reading.minimumDimension;

            if (readingId) {
                delete reading.errors.readingId;
            }
            else {
                reading.errors.readingId = 'ID is required';
            }

            if (newDimension) {
                delete reading.errors.newDimension;
            }
            else {
                reading.errors.newDimension = 'A value for New Dimension must be specified';
            }

            if (minimumDimension) {
                delete reading.errors.minimumDimension;
            }
            else {
                reading.errors.minimumDimension = 'A value for Minimum Dimension must be specified';
            }
        });
    }

    validateTorqueReadingsRows = (state) => {
        state.torqueReadings.forEach(row => {
            const reading = row.state;
            const readingId = reading.readingId;
            const torqueLocation = reading.torqueLocation;
            const torqueRange = reading.torqueRange;
            if (readingId) {
                delete reading.errors.readingId;
            }
            else {
                reading.errors.readingId = 'ID is required';
            }

            if (torqueLocation) {
                delete reading.errors.torqueLocation;
            }
            else {
                reading.errors.torqueLocation = 'Location must be specified';
            }

            if (torqueRange) {
                delete reading.errors.torqueRange;
            }
            else {
                reading.errors.torqueRange = 'Range must be specified';
            }

        });
    }

    validateDimensionsRows = (state) => {
        state.dimensions.forEach(row => {
            const reading = row.state;
            const dimensionId = reading.dimensionId;
            const minimumDimension = reading.minimumDimension;

            if (dimensionId) {
                delete reading.errors.dimensionId;
            }
            else {
                reading.errors.dimensionId = 'ID is required';
            }

            if (minimumDimension) {
                delete reading.errors.minimumDimension;
            }
            else {
                reading.errors.minimumDimension = 'A value for Minimum Dimension must be specified';
            }
        });
    }

    canSave = () => {
        this._validator.validateAll(this.state);
        this.validateEndConfigurationRows(this.state);
        this.validateWallReadingRows(this.state);
        this.validateTorqueReadingsRows(this.state);
        this.validateDimensionsRows(this.state);

        const errors = Object.keys(this.state.errors);
        const endConfigurationRowErrors = this.state.endConfigurations.reduce((acc, curr) => {
            return acc + Object.keys(curr.state.errors).length;
        }, 0);
        const wallReadingRowErrors = this.state.wallReadings.reduce((acc, curr) => {
            return acc + Object.keys(curr.state.errors).length;
        }, 0);

        const torqueReadingRowErrors = this.state.torqueReadings.reduce((acc, curr) => {
            return acc + Object.keys(curr.state.errors).length;
        }, 0);

        const dimensionRowErrors = this.state.dimensions.reduce((acc, curr) => {
            return acc + Object.keys(curr.state.errors).length;
        }, 0);

        if (!errors.length && !endConfigurationRowErrors && !wallReadingRowErrors && !torqueReadingRowErrors && !dimensionRowErrors) {
            return true;
        }

        this.commit();
        return false;
    }

    save = (cb) => {
        if (!this.canSave()) {
            return;
        }

        const s = this.state;
        const parameters = {
            manDescId: s.manDescId,
            familyCodeId: s.familyCode.id,
            manufacturerId: s.manufacturer.id,
            nominalSizeId: s.nominalSize.id,
            cwpId: s.cwp.id,
            degreeId: s.degree.id,
            idId: s.id.id,
            odId: s.od.id,
            lengthId: s.length.id,
            styleId: s.style.id,
            serviceId: s.service.id,
            mfgItemNo: s.mfgItemNo || null,
            altItemNo: s.altItemNo || null,
            notes: s.notes,
            isFMCPart: s.isFMCPart,
            updateWallReadings: s.updateWallReadings,
            isUTScope: s.isUTScope,
         };

        if (!this.adding) {
            parameters.endConfigurations = s.endConfigurations.map(ec => ec.state);
            parameters.wallReadings = s.wallReadings.map(wr => wr.state);
            parameters.torqueReadings = s.torqueReadings.map(tr => {
                const reading = {
                    manDescId: tr.state.manDescId,
                    manDescTorqueReadingId: tr.state.manDescTorqueReadingId,
                    readingId: tr.state.readingId,
                    torqueLocationId: tr.state.torqueLocation.id,
                    torqueRangeId: tr.state.torqueRange.id,
                    readingType: tr.state.readingType,
                }
                return reading;
            });
            parameters.dimensions = s.dimensions.map(tr => {
                const reading = {
                    manDescId: tr.state.manDescId,
                    manDescDimensionId: tr.state.manDescDimensionId,
                    minimumDimension: tr.state.minimumDimension,
                    dimensionId: tr.state.dimensionId,
                    dimensionLocationId: tr.state.dimensionLocation ? tr.state.dimensionLocation.id : Utils.emptyGuid,
                    newDimension: tr.state.newDimension,
                    readingType: tr.state.readingType
                }
                return reading;
            });
        }
        this.state.isSaving = true;
        this.commit();
        ajax.post('manufacturerDescription/save', parameters).then(manDescId => {
            this.state.isSaving = false;
            this.commit();
            if (this.adding) {
                notification.action('success').post('Saved new Manufacturer Description');
                if (cb) {
                    cb(manDescId);
                }
            }
            else {
                notification.action('success').post('Saved changes to Manufacturer Description');
            }
            // use in ManufacturerDescriptionController to re-select edited form
            // if it's a new form, it will be the 1st entry in the list
            messages.channel('edit-man-desc').action('saved').post({ manDescId: manDescId });
        }).catch(err => {
            this.state.isSaving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });  // in: UpdateManufacturerDescriptionDto, out: Guid
    }
}

export default EditManufacturerDescriptionController;