import React from 'react';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import SearchController from '../controllers/SearchController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Dialog from '../../components/Dialog';
import store from 'store';
import SAPService from '../services/SAPService';


class AssemblyOperationPassedController extends BaseController {
  constructor(parentController, awoId, testName, configs, selectedGroups,notesId,notes) {
    super('pass-test', {
      parentController: parentController,
      awoId: awoId,
      testName: testName,
      configs: configs,
      operationMinutes: 0,
      selectedGroups: selectedGroups,
      operationDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      notes: notes,
      notesId:notesId,
      vendor: '',
      errors: {},
      completionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),

    });

    this.saving = false;
    this._searchController = new SearchController();
    this._validator = new Validator();
    //this.getInspectionNotes();
    this.loadValidators();
  }
  loadValidators = () => {
    this._validator.add('operationDate', 'Operation Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: DateTimeFormatter.today() } }]);

  }
  get searchController() { return this._searchController; }


  getOperationsNotes = () => {
    var self = this;
    var s = self.state;
    var param = {
      jobId: s.jobId,
      testName: s.testName,
      assetId: s.awoSOWId[0].jobSOWId,
    }
    //ajax.post('lookup/getInspectionTestNotes', param).then(result => {
    //  if (result) {
    //    self.state.notesId = result.notesId || Utils.emptyGuid
    //    self.state.notes = result.notes || '';
    //    self.commit();
    //  }
    //}).catch(err => {
    //  notification.action('error').post({ msg: err.message, title: 'Error' });
    //});
  }

  canSave = () => {

    var self = this;
    this._validator.validateAll(self.state);
    let errors = Object.keys(self.state.errors);
    if (errors.length) {
      return false;
    }

    self.commit();
    return true;
  }

  * save() {
    if (!this.canSave()) {
      return;
    }
    var s = this.state;
    var loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
      var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (s.operationMinutes > availableTime) {
        notification.action('error').post("Operation Time should not be more than Available Production Time");
        return false;
      }
    }
    var self = this;
    if (self.saving)
      return;

    self.saving = true;
    self.commit();

    var awoSOWIds = s.configs.map(x => x.awoSOWId);
    var parameters = {
      awoId: s.awoId,
      testName: s.testName,
      awoSOWId: awoSOWIds,
      operationMinutes: s.operationMinutes,
      vendorId: s.vendor?.id,
      notesId: s.notesId,
      notes: s.notes || null,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      completionDate: s.completionDate,
    };
    
    try {
      var results = yield ajax.post('awo/operations/passTest', parameters); // in: UpdateTestDto, out: InspectionResultDto
      this.saving = false;
      this.commit();
      s.parentController.refreshConfigData();
      const selectGroups = String(s.selectedGroups);
      if (s.selectedGroups.length > 1) {

        notification.action('success').post('<p> ' + s.testName + ' for groups ' + selectGroups + ' has been set to Passed ' + '</p>');
        return true;
      } else {
        notification.action('success').post('<p>' + s.testName + ' for group ' + selectGroups + ' has been set to Passed' + '</p>');
        return true;
      }

      //  if (results.errorMessages.length) {
      //    notification.action('error').post('<p>' + results.errorMessages.join('</p><p>') + '</p>');
      //    return false;
      // }
    }
    catch (err) {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: this.getErrorMessage(err.message), title: 'Pass Operation Error' });
      return false;
    }

  }

  getErrorMessage = (message) => {
    if (Utils.contains(message, 'Please enter')) {
      let messages = message.split('|');
      message = '<p>' + messages.join('</p><p>') + '</p>';
    }
    return message;
  }
}

export default AssemblyOperationPassedController;