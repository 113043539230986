import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';

class InspectionGaugesRowController extends BaseController {
  constructor(parentController, row, gaugeIds) {
    super('inspection-gauges-row-controller-' + InspectionGaugesRowController.key++, {
      gaugeRowGUID: row ? row.gaugeRowGUID : Utils.emptyGuid,
      serialNumber: row ? row.serialNumber : '',
      psn: row ? row.psn : parentController.state.primrySerNumber?.name,
      recordStatus: row ? row.recordStatus : AppConstants.RecordStatus.Added,
      description: row ? row.description : '',
      calibrationDate: row ? row.calibrationDate : '',
      expiryDate: row ? row.expiryDate : '',
      errors: {},
      jobStatus: row ? row.jobStatus : false,
      assetStatus: row ? row.assetStatus : '',
    });

    this.parentController = parentController;
    this._validator = new Validator();
    this._validator.add('serialNumber', 'Gauge Serial No', [{ required: true }]);
  }

  static key = 1;

  removeRow = () => {
    const recordStatus = this.state.recordStatus;
    if (recordStatus === AppConstants.RecordStatus.Added) {
      this.parentController.removeRow(this);
    }
    else {
      this.state.recordStatus = AppConstants.RecordStatus.Deleted;
      this.commit();
      this.parentController.updateDeletedRowStatus(this.state.serialNumber);
      this.parentController.commit();
    }
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

  updateRecordStatus = () => {
    this.state.recordStatus = this.state.recordStatus === AppConstants.RecordStatus.Unchanged ? AppConstants.RecordStatus.Modified : this.state.recordStatus;
    this.commit();
  }

  serialNumberChanged = () => {
    this.parentController.isLoading = true;
    this.commit();
    this.updateRecordStatus();
    const serialNumber = this.state.serialNumber;
    this.state.description = serialNumber ? serialNumber.description : '';
    this.state.calibrationDate = serialNumber ? DateTimeFormatter.formatDate(serialNumber.gaugeExpiryDate) : '';
    this.state.expiryDate = serialNumber ? DateTimeFormatter.formatDate(serialNumber.expiryDate) : '';
    
    if (serialNumber !== null && serialNumber !== undefined) {
      if (serialNumber && serialNumber?.gaugeExpiryDate == null) {
        this.state.calibrationDate = serialNumber ? DateTimeFormatter.formatDate(serialNumber.calibrationDate) : '';
      }
      else if (DateTimeFormatter.getMoment(serialNumber.gaugeExpiryDate).diff(DateTimeFormatter.today(), 'days') >= 0) {
        this.state.calibrationDate = serialNumber ? DateTimeFormatter.formatDate(serialNumber.gaugeExpiryDate) : '';
      }
      else if (DateTimeFormatter.getMoment(serialNumber.gaugeExpiryDate).diff(DateTimeFormatter.today(), 'days') < 0
        && DateTimeFormatter.getMoment(serialNumber.calibrationDate).diff(DateTimeFormatter.today(), 'days') < 0) {
        this.state.calibrationDate = serialNumber ? DateTimeFormatter.formatDate(serialNumber.gaugeExpiryDate) : '';
      }
      else if (DateTimeFormatter.getMoment(serialNumber.gaugeExpiryDate).diff(DateTimeFormatter.today(), 'days') < 0
        && DateTimeFormatter.getMoment(serialNumber.calibrationDate).diff(DateTimeFormatter.today(), 'days') >= 0) {
        this.state.calibrationDate = serialNumber ? DateTimeFormatter.formatDate(serialNumber.calibrationDate) : '';
      }
    }
    this.parentController.getAddedRowsGaugeId();
    this.parentController.isLoading = false;
    this.commit();
  }

}

export default InspectionGaugesRowController;