import React from 'react';
import Button from '../../components/Button';
import DropZone from '../../components/DropZone';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Table from '../../components/Table';

class AssetIdRows extends React.Component {
  handleFileUpload = (assetId, res) => {
    this.props.controller.uploadFile(assetId, res);
  }

  handleRemoveFile = (assetId, file) => {
    this.props.controller.deleteFile(assetId, file);
  }

  render = () => {
    const controller = this.props.controller;
    const asset = this.props.asset;
    let attachedImages = [];
    let loadStatus = controller.state.loadStatus;
    const file = asset.file ? asset.file.fileName : null;
    const upload = <span className='pull-right' style={{ marginTop: '-1px' }}>
      <DropZone onDrop={this.handleFileUpload.bind(this, asset.assetId)} multiple={false} style={{ borderStyle: 'none', padding: 'unset' }} width={200} height={40} >
        <span className='field-hint'>Drag &amp; Drop &rarr;</span>
        &nbsp;
        <Button type='file' variant='success' size='sm' className='' icon='fa-upload' disabled={loadStatus} >Upload</Button>
      </DropZone>
    </span>
    if (this.props.asset.file) {
      this.props.asset.file.map((image, index) => {
        attachedImages.push(<div key={index} id='divattimgs'>
          <a><img className='inspection-image-thumbnail' src={image.imgUrl} alt='image' /> <div className='hover-img'><span><img src={image.imgUrl} alt='image' height='100' /></span></div></a>
          <span className='fa fa-times-circle inspection-image-remove' onClick={this.handleRemoveFile.bind(this, asset.assetId, image)} />
        </div>)
      });
    }

    const className = '';

    return (
      <Table.Row>
        <Table.Data className={className}>{asset.primarySerialNumber}</Table.Data>
        <Table.Data className={className}>{upload}</Table.Data>
        <Table.Data className={className}>{attachedImages.map(att => att)}</Table.Data>
      </Table.Row>
    );
  }
}
class DocumnetUpload extends React.Component {

  handleFileUpload = (res) => {
    this.props.controller.uploadFile(res);
  }

  // componentWillUnmount(){
  //   this.props.controller.state.assets = [];
  //   this.props.controller.commit();
  // },

  render = () => {
    var controller = this.props.controller;
    var assetId = controller.state.assets.map((row, index) => <AssetIdRows key={index} controller={controller} asset={row} />);

    return (
      <div className='row'>
        <div className='col-12 documents-upload'>
          <span className='field-label'>Documents (Max. file size allowed is 25 MB)</span>
          {/* {upload} */}
        </div>
        <div className='col-12'>
          <Table id='result-items-table' >
            <Table.Header>
              <Table.Row>
                <Table.Head>Serial Numbers</Table.Head>
                <Table.Head>Upload</Table.Head>
                <Table.Head>Attached Files</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {assetId}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

class InspectionAttachmentDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.props.controller.load();
  }

  render = () => {
    const controller = this.props.controller;
    const assets = controller.state.assets;
    // var popupId             = this.props.popupId;
    return (
      <Dialog.Body>
        <div className='row'>
          <div className='col-md-12'>
            <DocumnetUpload controller={controller} />
          </div>
        </div>
      </Dialog.Body>
    );
  }
}

export default InspectionAttachmentDialog;
// {
//     DocumnetUpload : DocumnetUpload,
//     InspectionAttachmentDialog: InspectionAttachmentDialog
//   };