import React from 'react';
import ReactLoaderSpinner from 'react-loader-spinner'

const Loader = (defaultProps) => {

    const props = {
        radius: 15,
        type: 'Bars',
        height: 80,
        width: 80,
        allowBackgroundOperation: false,
        ...defaultProps
    }

    const wrapperStyle =  { top: '50%', left:'50%', position: 'fixed', marginLeft: -(props.width/2), marginTop: -(props.height)/2, zIndex: 2e9}
    const backgroundBlurStyle =  {  display: props.loaded ? 'none' : 'block', position: 'fixed', height: '100%', width: '100%', backgroundColor: '#000000', opacity: '.5', zIndex: (2e9-1) }
    const backgroundBlur = <div className='loader-dimmer'style={backgroundBlurStyle} />

    return (
        <div className='loader-container'>
            <div className='loader-wrapper'style={wrapperStyle}><ReactLoaderSpinner {...props} visible={!props.loaded} /></div>
            {props.allowBackgroundOperation ? null : backgroundBlur}
        </div>
    );
}

export default Loader;