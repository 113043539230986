import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';

class AssetNotesController {
  constructor(parent) {
    this.parent = parent;
    this.asset  = '';
    this.notes  = [];
  }

  getNotes = () => {
    return this.notes;
  }

  getTitle = () => {
    const asset = this.asset;
    return 'Asset Notes — ' + (asset && asset.primarySerialNumber);
  }

  load = (assetId) => {
    ajax.get('lookup/getAsset/', { assetId: assetId }).then(asset=>{
      this.asset = asset;
      this.parent.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  }); // out: ReceivedAssetDto
  }

  loadNotes = (assetId, flashNewNote) => {
    ajax.get('lookup/getAssetNotes/', { assetId: assetId }).then(results=>{
      this.notes  = results;
      this.parent.commit();
      if (flashNewNote) {
        var newNote = results[results.length - 1];
        if(newNote.noteId!='undefined')
          this.parent.flashNote(newNote.noteId);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  });// out: IList<NoteDto>
  }

  canAddNotes = () => {
    return ((LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep) || (LoginService.isTechnician));
  }

  addNote = (text, usersToNotify) => {
    const assetId    = this.asset.assetId;
    const parameters = {
      entityId : assetId,
      noteType : AppConstants.NoteTypes.Asset,
      text     : text,
      usersToNotify : usersToNotify
    };
    this.parent.state.saveInProgress = true;
    this.parent.commit();
    ajax.post('receiving/addNote', parameters).then(()=>{
      //this.loadNotes(assetId, true);
      this.parent.clearNoteText();
      this.loadNotes(assetId, true);
      this.parent.state.saveInProgress = false;
      this.parent.commit();      
    }).catch(err => {
      this.parent.state.saveInProgress = false;
      this.parent.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
  }); // in: AddNoteDto, out: void
  }
}

export default AssetNotesController;