import React, { useMemo, useEffect } from 'react';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import ActionIcon from '../../components/ActionIcon';
import notification from '../../services/Notification';
import Page from '../../components/Page';
import LoginService from '../services/LoginService';
import Dialog from '../../components/Dialog';
import SerialUTReadingTable from './SerialUTReadingTable';
import SerialWithoutReadingTable from './SerialWithoutReadingTable';
import SerialReadingHeaderRow from './SerialReadingHeaderRow';
import TopPagination from '../components/TopPagination';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import Grid from '../../components/Grid';
import Dropdown from '../../components/Dropdown';
import Search from '../components/Search';
import Authentication from '../services/authentication';
import GaugeManagementController from '../controllers/GaugeManagementController';


const Results = (props) => {
  
  const binder = props.binder;
  const s = props.controller.state;
  const data = s?.facilityGaugesList ? s.facilityGaugesList : [];
  const expiredCount = s.facilityGaugeViewData.ExpiredGaugeCount;
  const dueSoonCount = s.facilityGaugeViewData.FastDueGaugeCount;
  const dueCount = s.facilityGaugeViewData.DueGaugeCount;
  const count = data?.length;
  
  
  const handleExportToExcelClick = (e) => {
    if (props.controller.state.isLoading) {
      e.preventDefault();
    }
    else {
      props.controller.getByteData('excel');
    }
  }

  const handleExportToPDFClick = (e) => {
    if (props.controller.state.isLoading) {
      e.preventDefault();
    }
    else {
      props.controller.getByteData('pdf');
    }
  }
  const exportToExcel = <Dropdown.MenuItem icon='fa-file-excel' variant='primary' size='sm' className=' m-1' disabled={count == 0 || count === undefined} onClick={handleExportToExcelClick} > Excel  </Dropdown.MenuItem>;
  const exportToPDF = <Dropdown.MenuItem icon='fa-light fa-file-pdf' variant='primary' size='sm' className=' m-1' disabled={count == 0 || count === undefined} onClick={handleExportToPDFClick} > PDF  </Dropdown.MenuItem>;

  const handleRedirect = (serialGuid) => {
    props.controller.toggelDetailViewWithSerialGuid(serialGuid);
  }

  const handlePageChange = () => {
    TopPagination.Toggle();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.searchGauges();
  }

  const handleMouseSearch = () => {
    props.controller.searchGauges();
  }

  const clearSearch = () => {
    props.controller.clearFacilitySearchText();
  }

  //const activeJobsLabel = Utils.pluralize('match', 'es', count);
  //const totalAssetCount = props.controller.state.searchResults.reduce((acc, curr) => { return acc + curr.assetCount; }, 0);
  //const totalAssetCountLabel = Utils.pluralize('total asset', 's', totalAssetCount);
  const columns = useMemo(
    () => [
      {
        Header: 'Primary Serial Number',
        accessor: 'state.primarySerialNumber',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => {
          return cellInfo.value ? <div className="link-label"><Form.Link href={null} value={cellInfo.value} onClick={() => handleRedirect(cellInfo.row.original.state.serial_GUID)} /></div> : <span></span>
        }
      },
      {
        Header: 'Description',
        accessor: 'state.description',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => <span>{cellInfo.cell.value}</span>
      },
      {
        Header: '  Exp. Date ',
        accessor: 'state.expiryDate',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => {
          const expDate = DateTimeFormatter.formatDate(cellInfo.cell.value);
          const daysUntilNextInspection = DateTimeFormatter.getMoment(cellInfo.cell.value).diff(DateTimeFormatter.today(), 'days');
          let classExpDate = '';
          if (daysUntilNextInspection <= 0) {
            classExpDate = 'late-error';
          }
          else if (daysUntilNextInspection > 0 && daysUntilNextInspection <= 30) {
            classExpDate = 'late-warning'; // orange font color
          }
          else if ( daysUntilNextInspection >30) {
            classExpDate = 'due-date'; // green font color
          }
          //else if (daysUntilNextInspection > 90) {
          //  classExpDate = 'due-date';
          //}
          //else if (daysUntilNextInspection > 30) {
          //  classExpDate = 'due-warning';
          //}
          //else if (daysUntilNextInspection > 0) {
          //  classExpDate = 'late-error';
          //}
          return <span className={classExpDate}>{expDate}</span>
        }
      },
      {
        Header: 'Calibration Frequency',
        accessor: 'state.calibrationFrequency',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Customer Location',
        accessor: 'state.customerLocation',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => {
          return <span>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Current Location',
        accessor: 'state.currentLocation',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Secondary Serial Number',
        accessor: 'state.secondarySerialNumber',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => {
          return <span>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Status',
        accessor: 'state.status',
        width: 250,
        disableSortBy: false,
        Cell: cellInfo => {
          const requiredDateClassName = cellInfo.cell.value === 'Scrapped' ? ' state-scrap' : ' state-pass';
          return <span className={requiredDateClassName}>{cellInfo.cell.value}</span>
        }
      },
    ],
    []
  )
  return (
    <div className='col-md-12'>
      <div className='row right-margin d-flex'>
        <div className='col-2 facility-gauge-header-div bck-blue'>
          <Badge variant='info' className='mr-1'> { Utils.pluralize('match', 'es', count)}</Badge>
        </div>
        <div className=' col facility-gauge-header-div bck-red'>
          <span className='text-color'>{'Due in Past Count = ' + expiredCount}</span>
        </div>
        <div className='col facility-gauge-header-div bck-orange'>
          <span>{'Due Within Next 30 days Count = ' + dueSoonCount}</span>
        </div>
        <div className='col facility-gauge-header-div bck-green '>
          <span className='text-color'>{'Due After 30 days Count = ' + dueCount}</span>
        </div>
        <div className='col-md-2 facility-gauge-button-div'>
          <Dropdown.Button variant={'primary'} size={'sm'} className='m-1 export-button' title='Export' disabled={count == 0 || count === undefined}>
            {exportToExcel}
            {exportToPDF}
          </Dropdown.Button>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <TopPagination />
        <div className='search-container col-md-6' onKeyDown={handleKeyboardSearch}>
        <Search className='mb-2' id='searchFacilityGaugeText' inputClassName='search-input text-uppercase' label='Enter search criteria here' placeholder='serial number, description, exp. date, calibration frequency, location' autoFocus='true' bind={binder.bind('searchFacilityGaugeText')} clear={clearSearch} search={handleMouseSearch}></Search>
        </div>
      </div>
      {data !== undefined && <Grid id='facility-gauge-table' columns={columns} data={data} noDataText='No gauges meet the search criteria' enableDefaultRowSelect={true} onPageChange={handlePageChange} />}
    </div>
  );
}

class FacilityGaugesPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
   }

  static defaultProps = {
    controller: new GaugeManagementController()
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;

    return (
      <div>
        <div className='row'>
          <Results controller={controller} binder={binder} />
        </div>
      </div>
    );
  }
}

export default FacilityGaugesPage;

