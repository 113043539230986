import Helper from '../lib/Helper';
import toastr from 'toastr';
import messages from './Messages';
const Notification = messages.channel('ui-messages');
const errorToIgnore = ['ResizeObserver loop limit exceeded'];
const positionClass = 'toast-bottom-right';

String.prototype.replaceAll = function (search, replace) {
    // if replace is not sent, return original string otherwise it will
    // replace search string with 'undefined'.
    if (replace === undefined) {
        return this.toString();
    }
    return this.replace(new RegExp('[' + search + ']', 'g'), replace);
};

toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    preventOpenDuplicates: true,
    positionClass: positionClass,
    timeOut: 8000,
    showMethod: 'slideDown',
    hideMethod: 'slideUp'
};

const SetPositionClass = () => {
    const location = window.location.href?.split('/')?.slice(-1)[0];
    return (location == 'receiving') ? 'toast-bottom-left' : positionClass;
}

const funcError = Helper.throttle(100, err => {
    var SERVER_DOWN_MSG = 'Origin is not allowed';
    var SERVER_NOT_AVAILABLE = 'Server is Unavailable';
    var SECURITY_RIGHTS = 'security rights';

    toastr.options.positionClass = SetPositionClass();
    if (typeof err === 'object') {
        var msg = err.msg || err.message;
        if (err.position) {
            toastr.options.positionClass = err.position;
        }
        if (err.timeOut) {
            toastr.options.timeOut = err.timeOut;
        }
        if (Helper.contains(msg, SERVER_DOWN_MSG)) {
            toastr.error(SERVER_NOT_AVAILABLE, err.title);
        }
        else {
            toastr.error(msg.replaceAll('\n', '<br />'), err.title);
        }
    }
    else {
        if (!err) {
            return;
        }
        else if (Helper.contains(err, SERVER_DOWN_MSG)) {
            toastr.error(SERVER_NOT_AVAILABLE, 'Server down');
        }
        else if (Helper.contains(err, SECURITY_RIGHTS)) {
            toastr.error(err, 'Security Violation');
        }
        else {
            toastr.error(err.replaceAll('\n', '<br />'));
        }
    }
}, false);
Notification.action('error').subscribe(funcError);

const funcSuccess = Helper.throttle(100, msg => {
    toastr.options.positionClass = SetPositionClass();
    if (typeof msg === 'object') {
        if (msg.position) {
            toastr.options.positionClass = msg.position;
        }
        if (msg.timeOut) {
            toastr.options.timeOut = msg.timeOut;
        }
        toastr.success(msg.msg, msg.title);
    }
    else {
        toastr.success(msg);
    }
}, false);
Notification.action('success').subscribe(funcSuccess);

Notification.action('warning').subscribe(msg => {
    toastr.options.positionClass = SetPositionClass();
     if (typeof msg === 'object') {
        if (msg.position) {
            toastr.options.positionClass = msg.position;
        }
        if (msg.timeOut) {
            toastr.options.timeOut = msg.timeOut;
        }
        if (msg.msg) {
            if (msg.msg.message) {
                toastr.warning(msg.msg.message.replaceAll('\n', '<br />'), msg.title);
            }
            else {
                toastr.warning(msg.msg.replaceAll('\n', '<br />'), msg.title);
            }
        }
    }
    else {
        toastr.warning(msg.replaceAll('\n', '<br />'));
    }
});

Notification.action('info').subscribe(msg => {
    toastr.options.positionClass = SetPositionClass();
        if (typeof msg === 'object') {
        if (msg.timeOut) {
            toastr.options.timeOut = msg.timeOut;
        }
        if (msg.position) {
            toastr.options.positionClass = msg.position;
        }
        if (msg.title) {
            toastr.info(msg.msg.replaceAll('\n', '<br />'), msg.title);
        } else {
            toastr.info(msg.msg.replaceAll('\n', '<br />'));
        }
    }
    else {
        toastr.info(msg.replaceAll('\n', '<br />'));
    }
});

window.onerror = err => {
    if (!errorToIgnore.includes(err)) {
        Notification.action('error').post(err.message || err);
    }
};

export default Notification;