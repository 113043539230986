import React, { useMemo } from 'react';
import store from 'store';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import SelectServiceLevelController from '../controllers/SelectServiceLevelController';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import LoginService from '../services/LoginService';
import Form from '../../components/Form';
import Table from '../../components/Table';
import SAPService from '../services/SAPService';
import ServiceLevelHelper from '../services/ServiceLevelHelper';

const Buttons = (props) => {

  let history = useHistory();

  const handleSave = () => {
    props.controller.save(() => {
      history.goBack();
    });
  }

  const handleClose = () => {
    history.goBack();
  }
  return (
    <div className='col-md-12'>
      <div className='edit-buttons float-right m-2'>
        <Button id='btn-save' icon='fa-save' variant='primary' size='sm' className='m-1 action-button' disabled={props.controller.state.isSaveInProgress} onClick={handleSave}>Save</Button>
        <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const NoConflictRow = (props) => {
  return (
    <Table.Row>
      <Table.Data>Conflicting cells?</Table.Data>
      <Table.Data />
      <Table.Data colSpan={props.length} className='no-conflict-cell'>no conflicts exists for the selected service levels</Table.Data>
    </Table.Row>
  );
}

const ConflictCell = (props) => {
  return <Table.Data className='conflict-cell'><i className='fa fa-ban fa-lg' /></Table.Data>;
}

const NoConflictCell = () => {
  return <Table.Data className='conflict-cell' />;
}

const ServiceLevelHeaderRow = (props) => {
  const tests = props.tests;
  if (!tests.length) {
    return (
      <Table.Row>
        <Table.Head className='service-level-test'>No tests have been defined for the selected asset(s)</Table.Head>
      </Table.Row>
    );
  }
  return (
    <Table.Row>
      <Table.Head /><Table.Head />
      {
        tests.map(test => { return <Table.Head key={test} className='service-level-test'>{test}</Table.Head>; })
      }
    </Table.Row>
  );
}

const BillOfMaterial = (props) => {
  if (!props.isSAPFacility) {
    return null;
  }
  const binder = props.binder;
  const controller = props.controller;
  const serviceLevel = controller.state.selectedServiceLevel;
  const billsOfMaterial = serviceLevel.billsOfMaterial;
  let ctl = <i>No service level selected</i>;
  const hasIndeterminateServiceLevels = controller.state.indeterminateServiceLevels.length;
  if (serviceLevel) {
    // default
    ctl = <b>No Bill of Material available for this service level</b>;
  }
  if (billsOfMaterial && billsOfMaterial.length) {
    ctl = <Form.AutoComplete data={controller.bomSearch(billsOfMaterial)} placeholder='Please select a Bill of Material' bind={binder.bind('selectedBillOfMaterial')} enabled={!hasIndeterminateServiceLevels} />;
  }
  return (
    <div className='row'>
      <div className='col-md-12'>
        <Form.Header className='alert-warning'>Bill of Material</Form.Header>
        {ctl}
      </div>
    </div>
  );
}

const ServiceLevelPageHeader = (props) => <PageHeader title={props.pageTitle} icon='far fa-check-square' />

class SelectServiceLevelPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.load();
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new SelectServiceLevelController()
  }

  handleRadioClick = (serviceLevel) => {
    this.props.controller.selectServiceLevel(serviceLevel);
  }

  getConflictCells = (serviceLevels, testsInConflict, tests) => {
    if (!serviceLevels.length) {
      return null;
    }

    if (testsInConflict === 0) {
      return <NoConflictRow length={tests.length} />;
    }

    const conflictCells = tests.map(test => {
      return ServiceLevelHelper.hasTestsInConflict(serviceLevels, test)
        ? <ConflictCell />
        : <NoConflictCell />;
    });

    return (
      <Table.Row>
        <Table.Data>Conflicting cells?</Table.Data>
        <Table.Data />
        {conflictCells}
      </Table.Row>
    );
  }

  getHeader = (tests) => {
    return <ServiceLevelHeaderRow tests={tests} />;
  }

  getExtrasHeader = (tests) => {
    if (!tests.length) {
      return (
        <Table.Row>
          <Table.Head className='service-level-header'>No tests have been defined for the selected asset(s)</Table.Head>
        </Table.Row>
      );
    }

    return (
      <Table.Row>
        <Table.Head className='service-level-header' />
        {
          tests.map(test => {
            return <Table.Head>{test}</Table.Head>;
          })
        }
      </Table.Row>
    );
  }

  handleNavigateToCustomerServiceLevel = (serviceLevel) => {
    const data = {
      customerServiceLevelId: serviceLevel.serviceLevelId
    };
    store.set('edit-customer-service-level', data);
    this.props.history.push('/customerservicelevel/' + serviceLevel.serviceLevelId);
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const count = controller.state.assets.length;
    let selectedAssetsLabel = (count === 1)
      ? 'Service Levels for 1 selected asset'
      : 'Service Levels in common for ' + count + ' selected assets';
    selectedAssetsLabel += ' on Job Number ' + controller.state.jobNumber;
    selectedAssetsLabel += ' for ' + controller.state.customerName;
    const serviceLevels = controller.state.serviceLevels;
    const tests = controller.state.tests;
    const appliesClass = <i className='fa fa-circle fa-2x' />;
    const doesNotApplyClass = <i className='fa fa-times' />;
    let restOfRow = function (serviceLevel) {
      return tests.map(test => {
        return (
          <Table.Data key={serviceLevel.serviceLevelId + '-' + test} className='service-level-test'>{ServiceLevelHelper.doesTestApplyToServiceLevel(serviceLevel, test) ? appliesClass : doesNotApplyClass}</Table.Data>
        );
      });
    };
    let maxHierarchyLevel = -1;
    let hasRequiredServiceLevel = false;
    let serviceLevelsWithNoTests = 0;
    const isSAPFacility = SAPService.isSAPFacility();
    var serviceLevelRows = serviceLevels.map(serviceLevel => {
      if (maxHierarchyLevel === -1) {
        maxHierarchyLevel = serviceLevel.hierarchyLevel; // assumes ordering by hierarcharchy level
      }
      let padding = (serviceLevel.hierarchyLevel === 0)
        ? '5px;'
        : ((5 + ((- serviceLevel.hierarchyLevel) * 20)) + 'px;'); // indent the hierarchy
      let checkboxClass = 'checkbox' + ((serviceLevel.performed && serviceLevel.indeterminate) ? ' indeterminate' : '');

      let backgroundColour = '';
      let serviceLevelHasNoTests = false;

      if (!serviceLevel.tests.length) {
        backgroundColour = '#c83a2a';
        serviceLevelHasNoTests = true;
        serviceLevelsWithNoTests++;
      }
      else if (serviceLevel.required) {
        backgroundColour = '#1871B9';
        hasRequiredServiceLevel = true;
      }
      let serviceLevelsWithNoOpc = 0;
      let isSAPServiceLevel = serviceLevel.isSAPServiceLevel ? <span className='sap-error' style={{ fontSize: '0.8rem' }}>&nbsp;&nbsp;SAP</span> : null;
      let soperCode = serviceLevel.tests.filter(sop => sop.operationCode == "");
      if (soperCode.length) {
        serviceLevelHasNoTests = true;
        serviceLevelsWithNoOpc++;
      }
      let opsMessage = serviceLevelsWithNoOpc > 0 ? <span className='sap-error' style={{ fontSize: '0.8rem' }}>&nbsp;&nbsp;One or more tests doesnot contain Operation Codes</span> : null;
      return (
        <Table.Row key={serviceLevel.serviceLevelId}>
          {
            LoginService.isAdministrator
              ? <Table.Data className='row-header' style={{ paddingLeft: padding, backgroundColor: backgroundColour }}>
                <a className='service-level-select-link' onClick={() => { this.handleNavigateToCustomerServiceLevel(serviceLevel); }}>
                  {serviceLevel.serviceLevelName}
                </a>
                {isSAPServiceLevel}{opsMessage}
              </Table.Data>
              : <Table.Data className='row-header' style={{ paddingLeft: padding, backgroundColor: backgroundColour }}>
                {serviceLevel.serviceLevelName}{isSAPServiceLevel}{opsMessage}
              </Table.Data>
          }
          <Table.Data className={!!serviceLevel.required ? 'required-test service-level-test' : 'service-level-test'}>
            <div>
              <input type='radio' className={checkboxClass} style={{ marginRight: '7px', height: '22px', width: '22px' }} checked={serviceLevel.performed} enabled={!serviceLevelHasNoTests} disabled={serviceLevelHasNoTests} onChange={() => { this.handleRadioClick(serviceLevel); }}></input>
            </div>
          </Table.Data>
          {restOfRow(serviceLevel)}
        </Table.Row>
      );
    });
    const testsInConflict = isSAPFacility
      ? 0
      : tests.reduce((acc, curr) => {
        let inConflict = ServiceLevelHelper.hasTestsInConflict(serviceLevels, curr);
        return acc + (inConflict ? 1 : 0);
      }, 0);
    const conflictMessage = (testsInConflict > 0)
      ? <Table.Row>
        <Table.Data /><Table.Data /><Table.Data colSpan={tests.length} className='conflict-message'>Saving these changes will prevent Inspection Tests from being performed for the selected assets</Table.Data>
      </Table.Row>
      : null;
    const requiredMessage = hasRequiredServiceLevel
      ? <span style={{ color: 'white', backgroundColor: '#1871B9', padding: '5px 10px' }}>Required service level</span>
      : null;
    let noServiceLevelTestsMessage = null;
    if (serviceLevelsWithNoTests === 1) {
      noServiceLevelTestsMessage = <span style={{ color: 'white', backgroundColor: '#c83a2a', padding: '5px 10px' }}>This service level does not have any tests – contact your InteServ Administrator</span>;
    }
    else if (serviceLevelsWithNoTests > 1) {
      noServiceLevelTestsMessage = <span style={{ color: 'white', backgroundColor: '#c83a2a', padding: '5px 10px' }}>These service levels do not have any tests – contact your InteServ Administrator</span>;
    }
    // var noServiceLevelOpsMessage = null;
    // if (serviceLevelsWithNoOpc > 0) {
    //   noServiceLevelOpsMessage = <span style={{color: 'white', backgroundColor: '#c83a2a', padding: '5px 10px'}}>These service levels do not have some/any operation codes on tests – contact your InteServ Administrator</span>;
    // }
    const LoggedInuser = LoginService.loginInfo.fullName;
    const fldHours = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} /> : null;
    const fldMinutes = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} /> : null;
    const fldTechnician = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.StaticData label='Record Receiving Time for User' value={LoggedInuser} /> : null;
    return (
      <Page {...this.props} pageTitle='InteServ · Select Service Levels' id='service-level-page'>
        <ServiceLevelPageHeader pageTitle={'Select Service Levels'} />
        <Page.Content>
          <div className='row'>
            <div id='search-results' className='col-md-12'>
              <div>
                <Form.Header className='alert-warning col-md-12'>{selectedAssetsLabel}</Form.Header>
              </div>
              <div>
                <div className=''>
                  <Table id='results-table'>
                    <Table.Header>
                      {this.getHeader(tests)}
                    </Table.Header>
                    <Table.Body>
                      {serviceLevelRows}
                      {isSAPFacility ? null : this.getConflictCells(serviceLevels, testsInConflict, tests)}
                      {conflictMessage}
                    </Table.Body>
                  </Table>
                </div>
                <div className='mt-3'>
                  {requiredMessage} {noServiceLevelTestsMessage}
                  {/* {noServiceLevelOpsMessage} */}
                </div>
              </div>
            </div>

            <div className='col-md-12 mt-2'> <BillOfMaterial controller={controller} binder={binder} isSAPFacility={isSAPFacility} /></div>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-4'>{fldTechnician}</div>
                {/* <div className='col-sm-6 col-md-3 col-lg-1'>{fldHours}</div> */}
                <div className='col-md-3'>{fldMinutes}</div>
                <div className='col-md-5'></div>
              </div>
            </div>
            <Buttons controller={controller} binder={binder} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default SelectServiceLevelPage;
