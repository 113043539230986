import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';

class TfmcAssetsSerialNumberRowController extends BaseController {
  constructor(parentController, obj) {
    super('tfmcAsset-serial-number-row-controller-' + TfmcAssetsSerialNumberRowController.key++, {
      primarySerialNumber:     obj.primarySerialNumber,
      mfgPartNumber:          obj ? obj.mfgPartNumber         : '',
      isPrimaryUnique:        obj.isPrimaryUnique,
      objectType:             obj? obj.objectType             : '',
      acquisitionValue:       obj? obj.acquisitionValue       : '',
      currency:               obj? obj.currency               :'',
      manufacturer:           obj?obj.manufacturer            : '',
      mfgCountry:             obj?obj.mfgCountry              :'',
      inventoryNumber:        obj? obj.inventoryNumber        :'',
      bornOnDate:             obj.bornOnDate,
      assetNumber:            obj? obj.assetNumber            :'',
      costCenter:             obj? obj.costCenter             :'',
      customerDescriptionId:  obj? obj.customerDescriptionId  :'',
      errors: {}
    });

    this.parentController = parentController;
    this._validator = new Validator();
    this._validator.add('primarySerialNumber', 'Primary Serial #', [{ required: true }]);
    this._validator.add('bornOnDate', 'Born On Date', [{ required: true }]);
  }

  static key = 1;

  primarySerialNumberChanged = () => {
    co(this, function* () {
    var uniqueSerialNumber = yield this.parentController.isSerialNumberUnique(this.state.primarySerialNumber, AppConstants.eSerialNumberType.Primary);
     // this.state.customerName = uniqueSerialNumber.customerName;
     // this.state.currentLocationName = uniqueSerialNumber.currentLocationName;
     this.state.isPrimaryUnique = uniqueSerialNumber.isUnique;
      this.commit();
      this.parentController.commit();
    });
  }

  

  remove = () => {
    this.parentController.removeFromList(this);
  }

  select = () => {
    this.parentController.selectAddedSerialNumberRow(this);
  }

  isSelected = () => {
    return this.parentController.isRowSelected(this);
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }
}

export default TfmcAssetsSerialNumberRowController;
