import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import List from '../../components/List';
import Form from '../../components/Form';

const ServiceLevelSelector = (props) => {

  const handleCheckBoxClick = (serviceLevel) => {
    props.controller.toggleServiceLevelSelection(serviceLevel);
  }
  const serviceLevel = props.item;
  const spanSAP = serviceLevel.isSAPServiceLevel
    ? <span className='sap-error'>SAP</span>
    : null;

  return (
    <List.Item className='list-group-item'>
      <div className='col-sm-12'>
        {/* <Form.CheckBox label='Remove from Container' checked={serviceLevel.selected} onChange={() => { handleCheckBoxClick(serviceLevel); }} /> */}
        <input type='checkbox' checked={serviceLevel.selected} onChange={() => { handleCheckBoxClick(serviceLevel); }}/>
        <span className='ml-2 font-weight-bold'>{serviceLevel.name}</span>&nbsp;{spanSAP}
      </div>
    </List.Item>
  );
}

class PickPrototypeServiceLevelsDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render = () => {
    const controller = this.props.controller;
    const customerName = controller.state.customer.customerName;
    const message = 'Select the prototype service levels to create for ' + customerName;
    const style = { marginTop: '15px', marginBottom: '10px' };
    const serviceLevels = controller.state.serviceLevels.map(sl => <ServiceLevelSelector key={sl.id} item={sl} controller={controller} />);

    return (
      <Dialog {...this.props} header={this.props.header} width={750} height={530} id='pick-prototype-service-levels-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <h5 className='col-sm-12 text-center' style={style} >{message}</h5>
          <div className='col-sm-12' style={{ marginTop: '10px', overflowY: 'scroll', height: '335px' }}>
            <List className='list-group' style={{ padding: '0' }}>
              {serviceLevels}
            </List>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={controller.saving} />
      </Dialog>
    );
  }
}

export default PickPrototypeServiceLevelsDialog;
