import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Table from '../../components/Table';

class InspectionGaugesRow extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleRemoveRow = () => {
        this.props.controller.removeRow();
    }

    getSerialNumberDetails = () => {
        var serialNumberDetails = Searchers.serialNumbersSearch();
    }

    getClassName = () => {
        const { controller } = this.props;
        let className = '';
        const warningDate = DateTimeFormatter.now().add('days', 30).format('YYYYMMDD');
        if ((DateTimeFormatter.getMoment(controller.state.expiryDate).format('YYYYMMDD') < DateTimeFormatter.getMoment(warningDate).format('YYYYMMDD')) &&
            (DateTimeFormatter.getMoment(controller.state.expiryDate).format('YYYYMMDD') > DateTimeFormatter.now().format('YYYYMMDD'))) {
            className = 'about-to-expire';
        }
        else if (DateTimeFormatter.getMoment(controller.state.expiryDate).format('YYYYMMDD') <= DateTimeFormatter.now().format('YYYYMMDD')) {
            className = 'expired';
        }
        else {
            className = 'not-expired';
        }
        return className;
    }

    render = () => {
        const { controller } = this.props;
        const binder = new Binder(this);
        const { errors } = controller.state;
        const selectedSerialNumbers = controller.state.selectedSerialNumbers;
        const readMode = controller.state.jobStatus || controller.state.assetStatus == AppConstants.AssetSAPStatus.TECO;
        const tdAction = !readMode
            ? <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle' action={this.handleRemoveRow} /></Table.Data>
            : <Table.Data className='col-action'></Table.Data>;
        const fieldSerialNumber = !readMode
            ? <Form.AutoComplete className='required' bind={binder.bind('serialNumber')} search={Searchers.serialNumbersSearch(LoginService.loginInfo.facilityId, selectedSerialNumbers)} error={errors.serialNumber} />
            : <Form.StaticData value={controller.state.serialNumber.name} />
        let className = this.getClassName();
        return (
            <Table.Row>
                {tdAction}
                <Table.Data className='gauge-serial-number'>{fieldSerialNumber}</Table.Data>
                <Table.Data className='gauge-serial-number-description'><Form.StaticData value={controller.state.description} /></Table.Data>
                <Table.Data className={'calibration-date ' + className}><Form.StaticData value={controller.state.calibrationDate} /></Table.Data>
            </Table.Row>
        );
    }
}

export default InspectionGaugesRow;
