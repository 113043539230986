
import React, { useMemo } from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import UserSecurityRightsController from '../controllers/UserSecurityRightsController';
import List from '../../components/List';

const SecurityRightCheckbox = (props) => {

    const handleCheckBoxClick = (securityRight) => {
        props.controller.toggleSecurityRightSelection(securityRight);
    }
    
    var securityRight = props.item;
    const checkboxStyle = {
        height: 24,
        width: 24,
        backgroundColor: '#F1F1F1'
      }
    return (
        <List.Item key={props.key}>
             <div className='d-inline-flex'><input type='checkbox' style={checkboxStyle} className='mr-2' checked={securityRight.selected} onChange={() => handleCheckBoxClick(securityRight)}/><Form.Label name={securityRight.name} style={{marginTop: 3}}/></div>
        </List.Item>
    );
}

class UserSecurityRightsDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new UserSecurityRightsController()
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.props.controller.getSecurityRights();
    }

    handleClose = (evt) =>  evt.Cancel ? true : this.props.controller.save();

    render() {
        const controller = this.props.controller;
        const securityRights = controller.state.securityRights.map(sr => <SecurityRightCheckbox key={sr.securityTokenId} item={sr} controller={controller} /> );

        return (
            <Dialog {...this.props} modal={true} header={this.props.header} width={600} height={500} onClose={this.handleClose}>
                <Dialog.Body>
                    <div>
                        <List>
                            {securityRights}
                        </List>
                    </div>
                </Dialog.Body>
                <Dialog.Buttons>
                    <Dialog.SaveCancelButtons />
                </Dialog.Buttons>
            </Dialog>
        );
    }
}

export default UserSecurityRightsDialog;