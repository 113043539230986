import _   from 'immutable';
import BaseController from '../../lib/BaseController';

class JobSowGroupRowController extends BaseController {    
    constructor(parentController, group) {       
        super('jobsowgroup-row-controller-'+ JobSowGroupRowController.key++, {
            groupNumber: group.name,
            groupName: group.desc,
            jobId: group.jobId,
            recordStatus : 'UC'
        });
        this.parentController = parentController;       
    }
    static key = 1;

    groupNameChanged = () => {
        this.state.recordStatus = 'M';
        this.commit();
    } 
}

export default JobSowGroupRowController;
