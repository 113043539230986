import React from 'react';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import Shortcut from '../../components/Shortcut';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import ChangePasswordController from '../controllers/ChangePasswordController';

class ChangePasswordDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        popup: 'popup',
        controller: new ChangePasswordController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) =>  result.Cancel ? true : this.props.controller.save();

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const loginInfo = controller.state.loginInfo;
        const errors = controller.state.errors;
        const header = loginInfo.fullName;
        const ctlTitle = <div className='mb-2' style={{fontSize: '1.1rem', fontWeight: 500}}>Changing password for <span style={{ fontWeight: 'bold', fontSize: 'smaller', color: 'steelblue' }}>{loginInfo.loginName}</span></div>;
        const instructions = 'Password must be a minimum of 8 and maximum of 15 characters, contain at least 1 uppercase character, 1 lowercase character, 1 number, and 1 special character ?!@#$%^&*';
        const ctlInstructions = <div className='mb-4'>{instructions}</div>;
        const ctlMessage = controller.state.message
            ? <div className='mb-2 err-msg'>{controller.state.message}</div>
            : <div className='mb-2' />;

        return (
            <Dialog {...this.props} header={header} width={400} height={400} id='change-password-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div className='row'>
                        <div className='col'>
                            <div className='row'>
                                <div className='col'>
                                    <Shortcut shortcutKey='enter' preventDefault action={Dialog.handleResult({ popup: this.props.popup, Save: true })} />
                                    {ctlTitle}
                                    {ctlMessage}
                                    {ctlInstructions}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <Form.Input type='password' id='oldPassword' label='Current Password' className='form-control required' bind={binder.bind('oldPassword')} autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' error={errors.oldPassword} focus={true} />
                                    <Form.Input type='password' id='newPassword' label='New Password' className='form-control required' bind={binder.bind('newPassword')} autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' error={errors.newPassword} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons />
            </Dialog>
        );
    }
}

export default ChangePasswordDialog;