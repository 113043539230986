import BaseController from '../../lib/BaseController';
import co from '../../lib/Co';
import $ from 'jquery';
import emitter from '../utility/Emitter';
import AppConstants from '../utility/AppConstants';
import JobNotesController from './JobNotesController';
import AssetNotesController from './AssetNotesController';
import CustomerDescriptionNotesController from './CustomerDescriptionNotesController';
import ManufacturerDescriptionNotesController from './ManufacturerDescriptionNotesController';
import AwoNotesController from './AwoNotesController';

class NotesController extends BaseController {
  constructor() {
    super('notes', {
      noteType: 0,
      noteText: '',
      subController: '',
      requestedDate: '',
      rnId: '',
      saveInProgress: false,
      usersToNotify: []
    });

    this.notesText = '';
    this.flash = emitter();
  }

  initializeController = (noteType) => {
    switch (noteType) {
      case AppConstants.NoteTypes.Job:
        this.state.subController = new JobNotesController(this);
        break;

      case AppConstants.NoteTypes.ManufacturerDescription:
        this.state.subController = new ManufacturerDescriptionNotesController(this);
        break;

      case AppConstants.NoteTypes.CustomerDescription:
        this.state.subController = new CustomerDescriptionNotesController(this);
        break;

      case AppConstants.NoteTypes.Asset:
        this.state.subController = new AssetNotesController(this);
        break;

        case AppConstants.NoteTypes.AWO:
          this.state.subController = new AwoNotesController(this);
          break;
    }
  }

  flashNote = (id) => {
    this.flash.emit(id);
  }

  getNotes = () => {
    return this.state.subController.getNotes();
  }

  getTitle = () => {
    return this.state.subController.getTitle();
  }

  load = (id) => {
    const controller = this.state.subController;
    controller.load(id);
    controller.loadNotes(id);
    this.commit();
  }

  clearNoteText = () => {
    this.state.noteText = '';
    this.commit();
  }

  canAddNotes = () => {
    return this.state.subController.canAddNotes();
  }

  canSave = () => {
    return (this.state.noteText);
  }

  addNote = () => {
    if (!this.canSave()) {
      return;
    }
    const text = this.notesText;
    const usersToNotify = this.state.usersToNotify;
    this.state.subController.addNote(text, usersToNotify);
    this.state.noteText = '';
    this.commit();
  }

  handleNotesChange = (text, usersToNotify, displayText) => {
    this.state.usersToNotify = usersToNotify;
    this.state.noteText = text;
    this.notesText = displayText;
    this.commit();
  }
}

export default NotesController;
