import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import Table from '../../components/Table';

class ShipReceivedAssetsController extends BaseController {
    constructor() {
        super('ship-received-assets', {
            assets: [],
            removeFromContainer: false
        });

        this.clear();
        this._validator = new Validator();
        this._validator.add('receivingLocation', 'Receiving Location', [{ required: true }]);
        this._validator.add('shippedBySignature', 'Your signature', [{ required: true }]);
        if (SAPService.IsCostCenterMandatory() && this.state.customerName === 'TechnipFMC Drilling & Completions') {
            this._validator.add('costCenter', 'Cost Center', [{ required: true }]);
        }
        this.shippedAssets = '';
        messages.channel('shipReceivableAssets').action('shipAssets').subscribe((shippedAssets) => {
            this.shippedAssets = shippedAssets;
        });

    }

    load = (queryData) => {
        this.clear();
        if (!queryData) {
            this.commit();
            return;
        }
        this.state = Object.assign(this.state, queryData);
        this.state.assets = this.shippedAssets;//store.get('shipreceivableassets')
        this.state.IsSaveInProgress = false;
        const assignedToContainer = this.state.assets.reduce((acc, curr) => {
            return acc || (curr.container?.id !== Utils.emptyGuid);
        }, false);
        this.state.removeFromContainer = assignedToContainer;
        this.commit();
        this.commit();
    }

    clear = () => {
        this.state = {
            assets: [],
            facilityId: '',
            facilityName: '',
            customerId: '',
            customerName: '',
            receivingLocation: '',
            customerShipLocation: '',
            customerContainer: '',
            carrier: '',
            trackingNumber: '',
            weight: '',
            notes: '',
            shippedBySignature: '',
            removeFromContainer: false,
            IsSaveInProgress: false,
            errors: {},
            costCenter: '',
            activeJobsOfADNOC: '',
        };
    }

    receivingLocationSearch = () => {
        const facilityId = this.state.facilityId;
        const customerId = this.state.customerId;
        return (searchTerm) => ajax.get('lookup/searchCustomerLocationsForFacility', {
            searchTerm: searchTerm,
            customerId: customerId,
            facilityId: facilityId
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    getActiveJobsOfADNOC = () => {
        const facilityId = this.state.facilityId;
        const customerId = this.state.customerId;
        return (searchTerm) => ajax.get('lookup/searchExistingJobsForFacility', {
            searchTerm: searchTerm,
            customerId: customerId,
            facilityId: facilityId
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    customerShipLocationChanged = () => {
        this.state.customerContainer = '';
    }

    customerContainerSearch = () => {
        if (!this.state.customerShipLocation) {
            return null;
        }
        return (searchTerm) => ajax.get('lookup/searchCustomerContainersForLocation', {
            customerLocationId: this.state.customerShipLocation.id,
            searchTerm: searchTerm
        })
            .then(results => results); // IList<LookupDto>
    }

    customerShipLocationSearch = () => {
        return (searchTerm) => ajax.get('lookup/searchCustomerLocations', {
            facilityId: this.state.facilityId,
            customerId: this.state.customerId,
            searchTerm: searchTerm
        }) // IList<CustomerLocationLookupDto>
            .then(results => results);
    }

    customerShipLocationSearchShipping = () => {
        return (searchTerm) => ajax.get('lookup/searchCustomerLocations', {
            facilityId: this.state.facilityId,
            customerId: this.state.customerId,
            searchTerm: searchTerm
        })
            .then(results => results) // IList<CustomerLocationLookupDto>
    }

    clearSignature = () => {
        this.state.shippedBySignature = '';
        this.commit();
    }

    updateSignature = (signature) => {
        this.state.shippedBySignature = signature;
        this.commit();
    }

    isAssetScrapped = (asset) => {
        return (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped);
    }

    canShipAssets = () => {
        let success = true;

        this._validator.validateAll(this.state);
        const errors = Object.keys(this.state.errors);
        if (errors.length > 0) {
            success = false;
        }

        if (!this.state.receivingLocation) {
            notification.action('warning').post('You must select a Receiving Location');
            success = false;
        }

        if (SAPService.isSAPFacility() && this.state.customerName === 'TechnipFMC Drilling & Completions'
            && SAPService.IsCostCenterMandatory() && !this.state.costCenter) {
            notification.action('warning').post('You must select a Cost Center');
            success = false;
        }


        if (!this.state.customerShipLocation) {
            notification.action('warning').post('You must select a Ship Location');
            success = false;
        }

        if (SAPService.isSAPFacility() && this.state.customerShipLocation && this.state.customerShipLocation.error) {
            notification.action('warning').post('An invalid ship location has been selected - ' + this.state.customerShipLocation.error);
            success = false;
        }

        if (!this.state.shippedBySignature) {
            notification.action('warning').post('Your signature is required');
            success = false;
        }

        if (!success) {
            this.commit();
        }

        return success;
    }

    showShipReceiveAssetsCompleteDialog = (result) => {
        var customer = result.customer;
        var shipLocation = result.shipLocation;
        var receivingTicketNumber = result.receivingTicketNumber;
        var shippingTicketNumber = result.shippingTicketNumber;
        var assetCount = result.assetCount;
        co(this, function* () {
            yield Dialog.showDialogWaitForResult(
                <Dialog.OKDialog width={700} height={300} header='Assets Received and Shipped'>
                    <Table borderless={true} id='assign-assets-complete-dialog'>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Customer</Table.Data><Table.Data className='value text-left'>{customer}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Ship Location</Table.Data><Table.Data className='value text-left'><div>{shipLocation}</div></Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Receiving Ticket #</Table.Data><Table.Data className='value text-left'>{receivingTicketNumber}</Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}>Shipping Ticket #</Table.Data><Table.Data className='value text-left'><div>{shippingTicketNumber}</div></Table.Data></Table.Row>
                        <Table.Row><Table.Data className='label text-left font-weight-bold' style={{ width: '200px' }}># of Assets Transferred</Table.Data><Table.Data className='value text-left'>{assetCount}</Table.Data></Table.Row>
                    </Table>
                </Dialog.OKDialog>
            );
        });
    }

    customerContainerChanged = () => {
        this.state.removeFromContainer = false;
        this.commit();
    }

    save = (cb) => {
        if (!this.canShipAssets()) {
            return;
        }

        const containerAssets = this.state.assets.filter(asset => asset.container && asset.container.id != Utils.emptyGuid);

        co(this, function* () {
            try {
                const state = this.state;
                this.state.IsSaveInProgress = true;
                this.commit();
                const parameters = {
                    facilityId: state.facilityId,
                    customerId: state.customerId,
                    receivingLocationId: state.receivingLocation && this.state.receivingLocation.id,
                    customerLocationId: state.customerShipLocation.id,
                    containerId: state.customerContainer && this.state.customerContainer.id,
                    carrier: state.carrier,
                    trackingNumber: state.trackingNumber,
                    weight: state.weight,
                    notes: state.notes,
                    shippedByName: LoginService.loginInfo.fullName,
                    shippedBySignature: state.shippedBySignature,
                    costCenterId: state.costCenter && state.costCenter.id,
                    removeFromContainer: state.removeFromContainer,
                    adnocActiveJobId: state.activeJobsOfADNOC && state.activeJobsOfADNOC.id,
                    assets: state.assets.map(asset => {
                        return {
                            assetId: asset.assetId,
                            customerId: asset.customer.id,
                            currentLocationId: asset.currentLocation.id,
                            primarySerialNumber: asset.primarySerialNumber,
                            eAssetStatus: asset.eAssetStatus,
                            equipmentNumber: asset.equipmentNumber,
                            plantCode: asset.plantCode,
                            jobSOWId: state.activeJobsOfADNOC && state.activeJobsOfADNOC.id,
                        };
                    })
                };

                const results = yield ajax.post('receiving/shipReceivedAssets', parameters); // in: ShipAssetsDto, out: void
                const msg = 'Shipped ' + parameters.assets.length + ' asset' + (parameters.assets.length === 1 ? '' : 's') + ' to ' + this.state.customerShipLocation.name;
                notification.action('info').post({ msg: msg, title: 'Assets Shipped', position: 'toast-bottom-left' });
                this.showShipReceiveAssetsCompleteDialog(results);
                if (containerAssets.length > 0) {
                    messages.channel('load-container-assets').action('reload').post();
                }
                this.channel.action('saved').post();
                this.state.IsSaveInProgress = false;
                this.commit();
                cb();
            }
            catch (err) {
                this.state.IsSaveInProgress = false;
                this.commit();
                notification.action('error').post(err.message);
            }
        });
    }
}

export default ShipReceivedAssetsController;
