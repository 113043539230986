import store from 'store';
import BaseController from '../../lib/BaseController';
import ShippingTimeRowController  from './ShippingTimeRowController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService                   from '../services/LoginService';


class ShippingTimeController extends BaseController {
  constructor(shippingId,canaddTime) {
    super('shippingTime',{
      errors          : {},
      shippingTimeData   : [], 
      shippingId       : shippingId,
      hours:0,
      minutes:'0',
      technician:'',
      canaddTime:canaddTime   
    });
    this.saving=false;
    this.loadShippingTime();
  }
  
  // validateshippingTime(state) {
  //   if (!state.shippingTime.length) {
  //     state.errors.shippingTime = 'At least one row must be specified';
  //     return;
  //   }
  //   state.shippingTime.forEach(row => row.validate());
  // }

  // canSave() {    
  //   this.validateshippingTime(this.state);    
  //   var errors    = Object.keys(this.state.errors);
  //   var rowErrors = this.state.shippingTime.reduce((acc, curr) => {
  //     return acc + Object.keys(curr.state.errors).length;
  //   }, 0);  
    
  //   if (!errors.length && !rowErrors) {
  //     return true;
  //   }

  //   this.commit();
  //   return false;
  // }

  // addNewShippingTimeRow = (shippingValue) => {
  //   this.state.shippingTimeData.push(new ShippingTimeRowController(this, shippingValue));
  //   this.commit();
  // }  

  loadShippingTime = () => {
    ajax.get('lookup/getshippingTime/', { shippingId: this.state.shippingId }).then(usershippingTime=>{
      this.state.shippingTimeData = usershippingTime;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  });  
  }

  saveShippingValues = () => {       
      // if(!this.canSave()){         
      //   return false;
      // }
      // if(!flag){
        const loginInfo = store.get('InteServLoginInfo');
        const hours   = parseFloat(isNaN(this.state.hours) ? 0 : this.state.hours);
        const minutes = parseFloat(isNaN(this.state.minutes) ? 0 : this.state.minutes);
        const value   = ((60 * (isNaN(hours)?0:hours)) + (isNaN(minutes)?0:minutes));  
        // if(hours>23)
        // {
        //   notification.action('error').post("Hours cannot be more than 23");
        //   return false;
        // }
        // if(minutes>60)
        // {
        //   notification.action('error').post("Minutes cannot be more than 60");
        //   return false;
        // }
        if(value<=0)
        {
            notification.action('error').post("Shipping Time should be greater than 0");
            return false;
        }
        if(parseFloat(value).toFixed(1)<=0)
        {
          notification.action('error').post("After rounding to 1 decimal, time will become 0. Shipping Time should be greater than 0");
          return false;
        } 
        if(loginInfo.isBreakOut)
        {
          notification.action('error').post("Currently on Unpaid break");
          return false;
        }   
        const availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
        if(value>availableTime)
        {
            notification.action('error').post("Shipping Time should not be more than Available Production Time");
            return false;
        }     
        this.saving = true;
        this.commit(); 
        const parameters = {
          shippingId         : this.state.shippingId,
          TechnicianId: LoginService.loginInfo.userId,
          TransactionDate : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
          Value: value
        };
        ajax.post('shipping/saveTime', parameters).then(response=>{
          notification.action('success').post('Saved the shipping Time');        
          this.state.hours=0;
          this.state.minutes=0;
          this.state.shippingTimeData=[];
          //messages.channel('productiveTime').action('update').post(response);  
          this.saving=false;
          this.commit();
          this.loadShippingTime();
          return true;
        }).catch(err => {
          this.saving = false;
          this.commit(); 
          notification.action('error').post({ msg: err.message, title: 'Error' });
      }); 
      // }
      // else{
      //   return false;
      // }      
  } 
}

export default ShippingTimeController;