import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';

class ClockInController extends BaseController {
    constructor() {
        super();

        this.isClockinProgress = false;
    }

    clockInNow = (userId) => {
        this.isClockinProgress = true;
        this.commit();
        const clockInTime = DateTimeFormatter.formatLocalTime(DateTimeFormatter.now());
        return ajax.post('manHours/clockInNow', { userId: userId, today: clockInTime }).then((result) => {
            this.isClockinProgress = false;
            this.commit();
            return result;

        }).catch(err => {
            this.isClockinProgress = false;
            this.commit();
            if (!Utils.contains(err.message, 'A unique value for Clock In Time is required')) {
                notification.action('error').post({ msg: err.message, title: 'Error' });
                if (Utils.contains(err.message, 'User already clocked in')) {
                    return true;
                }
                return false;
            }
        });
    }
}

export default ClockInController;