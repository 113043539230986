import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';

class FacilityGaugeRowController extends BaseController {
  constructor(parentController, facilityGaugeData) {
    super('facility-gauge-row-controller-' + FacilityGaugeRowController.key++, {
        serial_GUID                : facilityGaugeData ? facilityGaugeData.Serial_GUID          :  Utils.emptyGuid,
        primarySerialNumber        : facilityGaugeData ? facilityGaugeData.PrimarySerialNumber: '',
        description                : facilityGaugeData ? facilityGaugeData.Description : '',
        expiryDate                 : facilityGaugeData ? facilityGaugeData.ExpiryDate : '',
        calibrationFrequency       : facilityGaugeData ? facilityGaugeData.CalibrationFrequency : '',
        customerLocation           : facilityGaugeData ? facilityGaugeData.CustomerLocation : '',
        currentLocation            : facilityGaugeData ? facilityGaugeData.CurrentLocation: '',
        secondarySerialNumber      : facilityGaugeData ? facilityGaugeData.SecondarySerialNumber : '',
        status                     : facilityGaugeData ? facilityGaugeData.Status : '',
        selected                   : facilityGaugeData ? facilityGaugeData.selected : false,
        errors                     : {}
    });
    this.parentController = parentController;
   
  }

  static key = 1;
  selectedChanged = () => {
    this.parentController.commit();
    this.commit();
  }
}
    
export default FacilityGaugeRowController;
  