import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';

class ShippingTimeRowController extends BaseController {
  constructor(parentController, shippingTime) {
    this.key = ShippingTimeRowController.__key++;
    super('shippingTime-row-controller-' + this.key, {
      technician              : shippingTime ? shippingTime.Technician          : '',
      tvalue                   : shippingTime ? shippingTime.Value          : '',
      transactionDate                 : shippingTime ? shippingTime.TransactionDate           : '',
      errors                  : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('TransactionDate', 'TransactionDate', [{required: true}]);
    this._validator.add('Technician', 'Technician', [{required: true}]);
    this._validator.add('Value', 'Value', [{required: true}]);
  }  

}

ShippingTimeRowController.__key = 1;

export default ShippingTimeRowController;