import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Searchers  from'../utility/Searchers';
class ChangeAssetOwnershipDialog extends React.Component{

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }  

  componentDidMount = () =>{
    this.initializeMixin();
  }

  handleClose = (result) =>  result.Cancel ? true : this.props.controller.changeOwnership(this.props.parentController ? this.props.parentController : '');

  render() {
    var hideCustomerLocation = this.props.hideCustomerLocation;
    var binder            = new Binder(this);
    var controller        = this.props.controller;
    var asset             = controller.state.asset;
    var assets            = controller.state.assets;
    var errors            = controller.state.errors;
    var canSelectLocation = !!controller.state.newCustomer;
    var title = controller.state.routedFrom == 'AssetDetail'
                ? `Please select the new customer and location to transfer asset${Array.isArray(assets) ? 's' : ' ' + asset.primarySerialNumber}`
                : `Please select the new customer to transfer asset${Array.isArray(assets) ? 's' : ' ' + asset.primarySerialNumber}`;
    return (
      <Dialog {...this.props} header='Change Assets Ownership' width={600} height={345} id='change-asset-ownership-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div className='col-sm-12' style={{padding: '10px', fontSize: '1.1rem !important'}}>
              <h5 className='text-center'>{title}</h5>
              <div className='col-sm-12'><Form.AutoComplete label='Customer' id='customer'          className='required' bind={binder.bind('newCustomer')} error={errors.newCustomer} search={Searchers.customerSearch()} /></div>
              <div className='col-sm-12'>
                  {!hideCustomerLocation && <Form.AutoComplete label='Location' id='customer-location'
                                     className='required' bind={binder.bind('newLocation')}
                                     error={errors.newLocation} search={controller.locationSearch()}
                                     enabled={canSelectLocation} />}
              </div>
            </div>
          </form>
        </Dialog.Body>
         <Dialog.Buttons>
         <Dialog.SaveCancelButtons saving = {controller.saving} />
        </Dialog.Buttons>
      </Dialog>
    );
  }
}

export default ChangeAssetOwnershipDialog;
