import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import SAPService from '../services/SAPService';
import Searchers from '../utility/Searchers';

class AssignAssetsToAssemblyWorkOrderDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  isExistingJob = () => {
    return (this.props.controller.state.existingJob); // return false if New Job or not specified yet
  }
  handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  handleKeyUp = (evt) => {
    try {
      var value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }
      if (value > 100000) {
        evt.target.value = 100000;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const canRemoveFromContainer = controller.state.assets.reduce((acc, curr) => {
      return acc || (curr.containerId !== Utils.emptyGuid);
    }, false);

    const isSAPFacility = SAPService.isSAPFacility();
    const LoggedInuser = LoginService.loginInfo.fullName;
    //const fldHours = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} /> : null;
    const fldMinutes = SAPService.isSAPFacility() && SAPService.useTTS()
      ? <div>
        <label className='minutes-label mr-1'>Minutes <i className='fa fa-exclamation-triangle text-warning' /></label>
        <Form.Input type='number' min='0' bind={binder.bind('minutes')} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
      </div>
      : null;

    const fldTechnician = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.StaticData label='Record Receiving Time for User' value={LoggedInuser} /> : null;
    const length = controller.state.assets.length;
    const header = 'Assigning ' + Utils.pluralize('Asset', 's', length) + ' to Assembly Work Order';
    const ownerId = controller.state.customerId
    const facilityId = controller.state.facilityId;
    const functionLocation = controller.state.functionalLocation?.name;
    const disableRemoveFromContainer= controller.state.diableRemoveFromContainer;
    const removeFromContainer= disableRemoveFromContainer===true? <Form.CheckBox label='Remove From Container' bind={binder.bind('removeFromContainer')} disabled={true} />
    :<Form.CheckBox label='Remove From Container' bind={binder.bind('removeFromContainer')} enabled={true} disabled={false}/>;
    
    return (
      <Dialog {...this.props} allowKeyDownEvent={true} header={header}
        width={750} height={500} id='assign-assets-to-job-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='assign-assets-to-job-dlg-content'>
            <div id='choose-jobs' className='row'>
              <div className='col-lg-12'>
                <Form.AutoComplete label='Existing Assembly Work Order' bind={binder.bind('existingAWO')} search={controller.existingAWOSearch()} />
                <Form.AutoComplete label='New Assembly Work Order' bind={binder.bind('newAWO')} search={controller.newAWOSearch()} />
              </div>
            </div>

            <div className='row form-group'>

              <div className='col-md-6'>
                <Form.AutoComplete label='Receiving Location' id='receivingLocation' className='required' error={controller.state.errors.receivingLocation} bind={binder.bind('receivingLocation')} search={controller.receivingLocationSearch()} />
              </div>
            </div>
            {!isSAPFacility &&
              <div className='row form-group'>
                <div className='col-md-6'>
                  <Form.AutoComplete label='Container Description' bind={binder.bind('containerDescription')} search={Searchers.getCustomerContainerListByFunctionalLocation(ownerId, '')} />
                </div>
              </div>}
            {isSAPFacility &&
              <div className='row form-group'>
                <div className='col-md-6'>
                  <Form.AutoComplete label='Functional Location' bind={binder.bind('functionalLocation')} search={Searchers.getCustomerFunctionalLocationList(ownerId)} />
                </div>
              </div>
            }
            {isSAPFacility && <div className='row form-group'>
              <div className='col-md-6'>
                <Form.AutoComplete label='Container Description' bind={binder.bind('containerDescription')} search={Searchers.getCustomerContainerListByFunctionalLocation(ownerId, functionLocation)} />
              </div>
            </div>
            }
            <div className='row form-group'>
              <div className='col-md-6'>
              {removeFromContainer}
              </div>
            </div>
            {/*
            <div className='row form-group'>
              <div className='col-md-6'>{fldTechnician}</div>
              <div className='col-md-2'>{fldMinutes}</div>
            </div>
            */
            }

          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={controller.saving} />
      </Dialog>
    );
  }
}

export default AssignAssetsToAssemblyWorkOrderDialog;