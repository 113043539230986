import React,{ useRef } from 'react';
import styled from 'styled-components';
import Form from '../../components/Form';
import Button from '../../components/Button';
import ActionIcon from '../../components/ActionIcon';

const SearchStyles = styled.div`
    .form-group{
        margin-bottom: unset;
    }
    .search-btn {
        max-width: 2.5rem;
    }
    .clearable{
        position: absolute;
        left: -1.75rem;
        top: 0.4375rem ;
        font-size: 1.5rem;
        color: #AAA;
    }
    .form-control{
        padding-right: 1.875rem;
    }
`;

const Search =  (propsParam) => {
    const props = {
        icon : 'fa-search',
        prependLabelIcon: 'fa-search',
        className: '',
        inputClassName: '',
        buttonClassName: '',
        autoFocus: false,
        showSearchButton: true,
        search: () => {},
        clear: () => {},
        enableSearch:()=>{},
        ...propsParam
    }

    const container = useRef(null);

    const handleKeyboardSearch = (e) => {
       if (e.which === 13) {
            e.preventDefault();
            if(!props.disable){
               props.search();
            }
        }else{
                props.enableSearch(e.target.value);
        }
    }

    const handleMouseSearch = () => {
        props.search();
    }

    const handleClear = () => {
        const div = container.current.children[0];
        if (div && div.children[0]) {
            div.children[0].focus();
        }
        props.clear();
    }
    
    const label = props.label ? <Form.Label prependLabelIcon={props.prependLabelIcon} appendLabelIcon={props.appendLabelIcon} name={props.label} hint={props.hint}/> : null;
    const formInput =  props.type === 'number' ?
    <Form.NumberInput type='number' id={props.id} className={props.inputClassName} min='1' placeholder={props.placeholder}  readOnly={false} bind={props.bind} onKeyUp={handleKeyboardSearch} /> :
    <Form.Input type={props.type} id={props.id} className={props.inputClassName} placeholder={props.placeholder} bind={props.bind} onKeyUp={handleKeyboardSearch} />;
    const searchButton = props.showSearchButton ? <Button icon={props.icon} variant='primary' onClick={handleMouseSearch}   disabled ={props.disable} className={'search-btn ' + props.buttonClassName} /> : null;

    return (
        <SearchStyles className={props.className}>
            {label}
            {props.children}
            <div className='row no-gutters'>
                <div className='col' ref={container}>{formInput}</div>
                <div className='col-auto'><ActionIcon className='clearable' icon='fa-times-circle' size='2x' action={handleClear} /></div>
                <div className='col-auto'>{searchButton}</div>
            </div>
        </SearchStyles>
    );
}

export default Search;