import BaseController from '../../lib/BaseController';
import SAPService from '../services/SAPService';
import Utils from '../utility/Utils';

const isSAPFacility = SAPService.isSAPFacility();
class SystemConfifRowController extends BaseController {
    constructor(parentController, obj) {       
        super('system-config-row-controller-' + SystemConfifRowController.key++, {
            
            group_name: obj.group_name,
            groupNumber: obj.groupNumber,
            equipment_Desc_name: obj.equipment_Desc_name,
            dependent_Equipment_name: obj.dependent_Equipment_name,
            position_Package_name: obj.position_Package_name,
            equipment_number: obj.equipment_number,
            system_GUID: obj.system_GUID,
            asset_Dto: obj.asset_Dto,
            serial_primary: obj.serial_primary,
            awoId: obj.aowId,
            systemconfigId: obj.systemconfigId,
            dependent_Equipment_GUID: obj.dependent_Equipment_GUID,
            test_status: obj.task_status,
            date_updated: obj.date_updated,
            asset_dependent_Dto: obj.asset_dependent_Dto,
            dependent_equipment_number: obj.dependent_equipment_number,
            serial_dependent_primary: obj.serial_dependent_primary,
            isChild: obj.isChild,
            awo_SOW_GUID: obj.awo_SOW_GUID,
            customerAssemblyLevel_GUID: obj.customerAssemblyLevel_GUID,
            assemblyLevel: obj.assemblyLevel,
            isAssemblyStarted: obj.isAssemblyStarted,
            updatedDate: obj.updatedDate,
            updatedBy: obj.updatedBy,
            testName: obj.testName,
            testResult: obj.testResult,
            isShipped : obj.isShipped,
            serviceOrderNumber : obj.serviceOrderNumber,
            sapStatus : obj.sapStatus,
            sapErrorMessage : obj.sapErrorMessage,
            sapErrorCode : obj.sapErrorCode,
            isWaitingForReapplyServiceLevels : obj.isWaitingForReapplyServiceLevels,
            isWaitingForLockForInspection : obj.isWaitingForLockForInspection,
            isWaiting : obj.isWaiting,
            plant:obj.plant,
            serial_primary : obj.serial_primary,
            order : obj.order,
            dependent_serial_name : obj.dependent_serial_name
        });
        this.parentController = parentController;       
    }
    static key = 1;
    asset_DtoChanged = () => {
        this.parentController.assginSerialnumberToConfiguration(this.state.system_GUID, this.state.asset_Dto?.id, this);
    }
    clearassetSelection = () => {
        this.state.asset_Dto = '';
        this.state.serial_primary = '';
        this.state.equipment_number = '';
        this.commit();
    }
    assignEquipment = (equipment_number) => {
        this.state.awo_SOW_GUID= Utils.emptyGuid;
        if(this.state.asset_Dto){
            this.state.awo_SOW_GUID= this.state.asset_Dto.id;
        }
        this.commit();
    }
    asset_dependent_DtoChanged = () => {
        this.parentController.assginDependentSerialnumberToConfiguration(this.state.system_GUID, this.state.asset_dependent_Dto?.id, this);
    }
    assignDependentEquipment = (equipment_number) => {
        this.state.dependent_equipment_number = equipment_number;
        this.commit();
    }
    setEquipmentNumber=(equipment_number)=>
    {
        if(isSAPFacility)
        {
            this.state.serial_primary = equipment_number;
        }
        else{
            this.state.equipment_number = equipment_number;
        }
        if(this.state.asset_Dto){
            this.state.awo_SOW_GUID= this.state.asset_Dto.id;
        }
       
    }

}
export default SystemConfifRowController;
