import React, { useMemo } from 'react';
import Utils from '../utility/Utils';
import $ from 'jquery'
import ActionIcon from '../../components/ActionIcon';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import GaugeManagementController from '../controllers/GaugeManagementController'
import Authentication from '../services/authentication';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import Search from '../components/Search';
import AppConstants from '../utility/AppConstants';
import DropZone from '../../components/DropZone';
import DailyCalibrationHistory from './DailyCalibrationHistoryTable';
import Jumbotron from '../../components/Jumbotron';
import Searchers from '../utility/Searchers';
import Dialog from '../../components/Dialog';
import CropImageDialog from '../dialogs/CropImageDialog';
import co from '../../lib/Co';
import ReactImageZoom from 'react-image-zoom';
import PrintShareGaugeController from '../controllers/PrintShareGaugeController';
import SendEmailDialog from '../dialogs/SendEmailDialog';
import ServiceHistoryTable from './ServiceHistoryTable';
import { bind } from 'lodash';
import UTScopePage from './UTScopePage';
import FacilityGaugesPage from './FacilityGaugesPage';

const LookupAsset = (props) => {

  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.findAsset();
  }

  const handleMouseSearch = () => {
    props.controller.findAsset();
  }

  const clearSearch = () => {
    $('#searchText').val('');
    $('#searchText').focus();
    // props.controller.clearSearch();
  }

  const handleDailyVerification = () => {
    props.controller.enableDailyVerification();
  }

  const handleYearlyCalibration = () => {
    props.controller.enableYearlyCalibration();
  }

  const binder = props.binder;
  const controller = props.controller;
  const asset = props.asset;
  const isUTScope = asset.isUTScope;
  const canEdit = !!asset;
  const yearlyCalibrationButton = <div className='col-md-6'> <Button icon='fa-th' id='yearly' variant='success' size='sm' className='m-1 btn-block' onClick={handleYearlyCalibration} disabled={!canEdit}>Yearly Calibration</Button> </div>
  const updateCalibrationButton = <div className='col-md-12'> <Button icon='fa-th' id='yearly' variant='success' size='sm' className='m-1 btn-block' onClick={handleYearlyCalibration} disabled={!canEdit}>Update/View Calibration</Button> </div>
  const showDailyVrificationButton = isUTScope === true || props.asset === '';

  return (
    <div className='row asset-detail'>
      <div className='col-md-12'>
        <Search className='mb-2' id='searchText' prependLabelIcon='fa-rss' inputClassName='search-input text-uppercase' label='Enter Gauge Serial Number' placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
        {isUTScope === true ? <UtsScopeAssetDescription className='form-group' asset={asset} controller={controller} />
          : <OthersAssetDescription className='form-group' asset={asset} controller={controller} />
        }
        <div className='row m-1'>
          {showDailyVrificationButton && <div className='col-md-6'> <Button icon='far fa-calendar' id='daily' variant='info' size='sm' className='m-1 btn-block' onClick={handleDailyVerification} disabled={!canEdit}>Daily Verification</Button> </div>}
          {isUTScope != false ? yearlyCalibrationButton : updateCalibrationButton}
        </div>
      </div>
    </div>
  );
}

const UtsScopeAssetDescription = (props) => {

  const handleScrapAsset = () => {
    props.controller.scrapGaugeAsset();
  }
  const asset = props.asset;
  const canEdit = !!asset;
  const descriptionControl = <Form.StaticData label='Description' value={asset.description && asset.description.name} />;
  const customerLocationControl = <Form.StaticData label='Customer Location' value={asset.customerLocation && asset.customerLocation.name} />;
  const lastVerificationDateValue = DateTimeFormatter.formatDropdownDate(asset.lastCalibrationDate) == DateTimeFormatter.minDate ? '' : DateTimeFormatter.formatDateTime(asset.lastCalibrationDate);
  const lastVerificationDate = <Form.StaticData label='Last Verification Date' value={lastVerificationDateValue} />;
  const expired = asset && asset.expired ? <span>Last verification has been expired. Kindly re-verify</span> : null;
  let statusColourClass = ' status' + asset.assetStatus;
  if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
    statusColourClass = 'asset-status statusWeldRepair'
  if (asset.assetStatus === 'OS/Machining Repair') {
    statusColourClass = 'asset-status statusOSRepair'
  }
  const assetStatus = <Form.StaticData className={statusColourClass} label='Asset Status' value={asset.assetStatus} />;
  const scrapDisable = !asset || asset.eAssetStatus == AppConstants.eAssetStatus.Scrapped || !props.controller.state.dailyVerificationData.length;
  const assetScrappedDate = <div className='col-md-12'> <Form.StaticData label='Asset Scrapped Date' value={asset.eAssetStatus == AppConstants.eAssetStatus.Scrapped ? asset.scrappedDate : null} /></div>;
  return (
    <Jumbotron id='asset-description'>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Primary Serial Number' value={asset.primarySerialNumber} /></div>
        {LoginService.isAdministrator && <div className='col-md-6 scrap-button'><Button icon='fa-times' id='scrap-asset' variant='danger' size='sm' className='m-1' onClick={handleScrapAsset} disabled={scrapDisable}>Scrap Asset</Button></div>}
      </div>
      {descriptionControl}
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Customer' value={asset.customer && asset.customer.name} /></div>
        <div className='col-md-12'>{customerLocationControl}</div>
        <div className='col-md-12 row'>
          <div className='col-md-12'>{assetStatus}</div>
          {assetScrappedDate}
          <div className='col-md-12'>{lastVerificationDate}</div>
        </div>
        <div className='col-md-12 expired-text'>{expired}</div>
      </div>
    </Jumbotron>
  );
}


const OthersAssetDescription = (props) => {

  const handleScrapAsset = () => {
    props.controller.scrapOtherGaugeAsset();
  }
  const asset = props.asset;
  const canEdit = !!asset;
  const descriptionControl = <Form.StaticData label='Description' value={asset.description && asset.description.name} />;
  const customerLocationControl = <Form.StaticData label='Customer Location' value={asset.customerLocation && asset.customerLocation.name} />;
  const lastVerificationDateValue = DateTimeFormatter.formatDropdownDate(asset.lastCalibrationDate) == DateTimeFormatter.minDate ? '' : DateTimeFormatter.formatDateTime(asset.lastCalibrationDate);
  const currentLocationControl = <Form.StaticData label='Current Location' value={asset.currentLocation && asset.currentLocation.name} />;
  //const lastVerificationDate = <Form.StaticData label='Last Verification Date' value={lastVerificationDateValue} />;
  const expires = <Form.StaticData label='Expiration Date' value={props.asset.expiryDate} />;
  let statusColourClass = ' status' + asset.assetStatus;
  if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
    statusColourClass = 'asset-status statusWeldRepair'
  if (asset.assetStatus === 'OS/Machining Repair') {
    statusColourClass = 'asset-status statusOSRepair'
  }
  const assetStatus = <Form.StaticData className={statusColourClass} label='Asset Status' value={asset.assetStatus} />;
  const scrapDisable = !asset || asset.eAssetStatus == AppConstants.eAssetStatus.Scrapped;
  const imgdata = asset.image && asset.image.length > 0 ? asset.image : asset.altImg
  const imageProps = {
    width: 250,
    height: 250,
    zoomWidth: 300,
    img: imgdata,
    zoomPosition: 'original',
  };
  const ZoomableImage = imgdata && imgdata.length > 0 ? <div className="zoomable-image"><ReactImageZoom  {...imageProps} /></div> : '';
  const assetScrappedDate = <div> <Form.StaticData label='Asset Scrapped Date' value={asset.eAssetStatus == AppConstants.eAssetStatus.Scrapped ? asset.scrappedDate : null} /></div>;
  return (
    <Jumbotron id='asset-description'>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Primary Serial Number' value={asset.primarySerialNumber} /></div>
        {LoginService.isAdministrator && <div className='col-md-6 scrap-button'><Button icon='fa-times' id='scrap-asset' variant='danger' size='sm' className='m-1' onClick={handleScrapAsset} disabled={scrapDisable}>Scrap Asset</Button></div>}
      </div>
      {descriptionControl}
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Customer' value={asset.customer && asset.customer.name} /></div>
        <div className='col-md-12 row'>
          <div className='col-md-5'>
            <div>{customerLocationControl}</div>
            <div>{currentLocationControl}</div>
            <div>{assetStatus}</div>
            {assetScrappedDate}
            <div className='expired-text'>{expires}</div>
          </div>
          <div className='col-sm-7'>
            {ZoomableImage}
          </div>
        </div>
      </div>
    </Jumbotron>
  );
}

const DailyVerificationDataTable = (props) => {

  const controller = props.controller;
  const data = [...controller.state.dailyVerificationData] || [];
  const dailyDataExist = controller.state.dailyVerificationData.length;
  const loadingMessage = !dailyDataExist && controller.state.searchInProgress && 'searching calibration details for the selected serial';
  const noResultsMessage = !dailyDataExist && !controller.state.searchInProgress && 'No Data available for the selected serial Number';
  const isEditable = controller.state.allowManualOverride && dailyDataExist && data.filter(x => x.EnableManualOverride === true).length > 0;

  const columns = useMemo(
    () => [
      {
        Header: 'Reading Id',
        accessor: 'state.ReadingID'
      },
      {
        Header: 'Minimum Dimension',
        accessor: 'state.MinimumDimension'
      },
      {
        Header: 'Reading Value',
        accessor: 'state.ReadingValue',
        Cell: (cellInfo, binder) => {
          const minRange = cellInfo.row.original.state.NewDimension - AppConstants.readingDifferenceRange;
          const maxRange = cellInfo.row.original.state.NewDimension + AppConstants.readingDifferenceRange;
          const colorCode = cellInfo.cell && cellInfo.cell.value < minRange || cellInfo.cell && cellInfo.cell.value > maxRange ? 'text-danger' : '';
          return cellInfo.row.original.EnableManualOverride === true ? <Form.Input bind={binder.bind('ReadingValue')} type='number' className={colorCode} placeholder='' /> : <span className={colorCode}>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Scope Serial',
        accessor: 'state.ScopeSerial',
        Cell: (cellInfo, binder) => {
          return cellInfo.row.original.EnableManualOverride === true ? <Form.Input bind={binder.bind('ScopeSerial')} placeholder='' /> : <span>{cellInfo.value}</span>
        }
      },
    ],
    []
  )
  return (
    <div id='daily-verification-results'>
      <Grid id='results-table' showPagination={false} columns={columns} editable={isEditable} canAddNewRow={false} data={data} noDataText={loadingMessage || noResultsMessage} />
    </div>
  );
}

const DailyVerification = (props) => {

  const handleSaveReading = () => {
    props.controller.saveUpdatedDailyVerificationData();
  }

  const handleManualOverride = () => {
    props.controller.handleManualOverride();
  }


  const handleDownload = () => {
    const websocket = new WebSocket("ws://localhost:8068");
    var dailyReadings = props.controller.getDailyVerificationData();
    const param = {
      SerialNumber: props.controller.state.currentAsset.primarySerialNumber,
      CustomerName: props.controller.state.currentAsset.customer.name,
      Type: "Download",
      Readings: dailyReadings,
      Technician: LoginService.loginInfo.fullName
    };
    websocket.onopen = function (evt) {
      props.controller.state.IsInProgress = true;
      props.controller.commit();
      websocket.send(JSON.stringify(param))
    };
    websocket.onmessage = function (evt) {
      props.controller.state.IsInProgress = false;
      props.controller.commit();
      const responsedata = JSON.parse(evt.data);
      if (responsedata.ResponseType == "S") {
        notification.action('success').post(responsedata.ResponseMessage);
      }
      else if (responsedata.ResponseType == "E") {
        notification.action('error').post(responsedata.ResponseMessage);
      }
    };
    websocket.onerror = function (evt) {
      notification.action('error').post(evt.data);
    };
  }

  const handleGetReading = () => {
    const websocket = new WebSocket("ws://localhost:8068");
    var dailyReadings = props.controller.getDailyVerificationData();
    const param = {
      SerialNumber: props.controller.state.currentAsset.primarySerialNumber,
      Type: "Retrieve",
      Readings: dailyReadings,
      Technician: LoginService.loginInfo.fullName
    };
    websocket.onopen = function (evt) {
      props.controller.state.IsInProgress = true;
      props.controller.commit();
      websocket.send(JSON.stringify(param))
    };
    websocket.onmessage = function (evt) {
      props.controller.state.IsInProgress = false;
      props.controller.commit();
      const responsedata = JSON.parse(evt.data);

      if (responsedata.ResponseType == "S") {

        notification.action('success').post(responsedata.ResponseMessage);
        props.controller.updateDailyVerificationData(responsedata.Readings, responsedata.ScopeSerial);
        //props.controller.findAsset();
      }
      else if (responsedata.ResponseType == "E") {
        notification.action('error').post(responsedata.ResponseMessage);
      }


      //props.controller.state.dailyVerificationData=JSON.parse(evt.data);
      //props.controller.commit();
    };
    websocket.onerror = function (evt) {
      notification.action('error').post(evt.data);
    };
  }

  const getReadingsNotInRange = () => {
    return props.controller.getReadingsNotInRange();
  }

  const binder = props.binder;
  const controller = props.controller;
  const readingNotInRange = getReadingsNotInRange() > 0;
  const isSaveInProgress = controller.state.IsInProgress;
  const serialNumber = controller.state.currentAsset.primarySerialNumber;
  const dailyDataExist = controller.state.dailyVerificationData.length;
  const allowManualOverride = controller.state.allowManualOverride;

  return (
    <div id='daily-verification' className='col-md-12 mt-2'>
      {/* <Form.Header className='headingClass'>
          Daily Calibration
        </Form.Header> */}
      <div className='row'>
        <div className='serial-number col-md-4'><Form.StaticData label='Serial Number' value={serialNumber} /> </div>
        <div className='verification-date col-md-3'><Form.StaticData label='Verification Date' value={DateTimeFormatter.formatDate(DateTimeFormatter.today())} /> </div>
        <div className='serial-number col-md-5'>
          <div className='mt-2'><Button icon='far fa-bookmark' id='save-reading' variant='success' size='sm' onClick={handleSaveReading} disabled={readingNotInRange}>Approve</Button>
            {allowManualOverride && <Button variant='warning' className='m-1 manual-override' id='manual-override' size='sm' onClick={handleManualOverride}>Manual Override</Button>}
          </div>
        </div>
      </div>
      <DailyVerificationDataTable controller={controller} binder={binder} />
      <div className='col-sm-12 row'>
        <div className='col-md-6'><Button icon='fa-download' id='downloadtodevice' variant='success' size='sm' className='btn-block' onClick={handleDownload} disabled={isSaveInProgress || !dailyDataExist}>Download to Device</Button></div>
        <div className='col-md-6'><Button icon='fa-upload' id='getreading' variant='primary' size='sm' className='btn-block' onClick={handleGetReading} disabled={isSaveInProgress || !dailyDataExist}>Get Reading</Button></div>
      </div>
    </div>
  );
}

const YearlyCalibration = (props) => {

  const handleFileUpload = (res) => {
    props.controller.uploadFile(res);
  }
  const handleSave = () => {
    if (DateTimeFormatter.getMoment(props.controller.state.expirationDate).diff(DateTimeFormatter.today(), 'days') < 0) {
      co(function* () {
        const result = yield Dialog.showDialogWaitForResult(
          <Dialog.OKCancelDialog width={500} height={200} header='Confirm Expiration Date'>
            {'Date should not be less than current date or it should not be less than date in the history'}
          </Dialog.OKCancelDialog>
        );
        if (result.OK) {
          props.controller.handleYearlyCalibrationSave();
        }
      });
    } else {
      props.controller.handleYearlyCalibrationSave();
    }
  }
  const handleRemoveFile = () => {
    props.controller.removeYearlyCalibrationFile();
  }

  let nextYear = new Date();
  let pastYear = new Date();
  let nYear = nextYear.getFullYear() + 1;
  nextYear.setFullYear(nYear);
  nYear = pastYear.getFullYear() - 1;
  pastYear.setFullYear(nYear);
  const binder = props.binder;
  const controller = props.controller;
  const serialNumber = controller.state.currentAsset.primarySerialNumber;
  const fileName = controller.state.certificateImage ? controller.state.certificateImage.fileName : '';
  const deleteFile = fileName
    ? <ActionIcon icon='fa-trash' className='remove-handle' action={handleRemoveFile} />
    : '';
  return (
    <div className='row mt-2'>
      <div id='yearly-calibration' className='col-md-12'>
        <div className='serial-number'><Form.StaticData label='Serial Number' value={serialNumber} /> </div>
        <div className='row'>
          <div className='col-md-12'>
            <Form.Date className='expiration-date' label='Expiration Date' bind={binder.bind('expirationDate')} options={{ startDate: DateTimeFormatter.formatDropdownDate(pastYear), endDate: DateTimeFormatter.formatDate(nextYear) }} />
            <Form.Input className='certificate-number' label='Certificate Number' bind={binder.bind('certificateNumber')} />
            <span className='certificate-image'>Certificate image
              <br />
              <DropZone onDrop={handleFileUpload} multiple={false} width={170} height={10} style={{ borderStyle: 'none', padding: 'unset' }}>
                <Button type='file' variant='success' size='sm' className='' icon='fa-upload'  >Attach</Button>
              </DropZone>
            </span> <br /><br />
            {deleteFile}&nbsp;&nbsp;
            <span>{fileName}</span>
          </div>
        </div>
        <div className='col-sm-4 float-right'><Button icon='fa-save' className='btn-block' variant='success' size='sm' disabled={controller.state.saveInProgress} onClick={handleSave}>Save</Button></div>
      </div>
    </div>
  );
}



const UpdateCalibration = (props) => {

  const handleFileUpload = (res) => {
    props.controller.uploadFile(res);
    const fileType = props.controller.state.certificateImage.fileType;
    if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
      Dialog.showDialog(<CropImageDialog controller={props.controller} header={props.controller.state.certificateImage.fileName} />);
    }
  }
  const handleSave = () => {
    if (DateTimeFormatter.getMoment(props.controller.state.expirationDate).diff(DateTimeFormatter.today(), 'days') < 0) {
      co(function* () {
        const result = yield Dialog.showDialogWaitForResult(
          <Dialog.OKCancelDialog width={500} height={200} header='Confirm Expiration Date'>
            {'Date should not be less than current date or it should not be less than date in the history'}
          </Dialog.OKCancelDialog>
        );
        if (result.OK) {
          props.controller.handleYearlyCalibrationSave();
        }
      });
    } else {
      props.controller.handleYearlyCalibrationSave();
    }
  }

  const handleCancel = () => {
    props.controller.CancelUpdate();

  }
  const handleRemoveFile = () => {
    props.controller.removeUpdateYearlyCalibrationFile();
  }

  const handleCertificateCheckBoxClick = () => {
    props.controller.handleCertificateCheckBoxClick();
  }
  const handleStickerCheckBoxClick = () => {
    props.controller.handleStickerCheckBoxClick();
  }
  const styleAcknowledgementCheckBox = {
    height: '20px',
    width: '16px'
  }  
  const styleButton = {
    'min-width': 'fit-content'
  };


  let nextYear = new Date();
  let pastYear = new Date();
  let nYear = nextYear.getFullYear() + 3;
  nextYear.setFullYear(nYear);
  nYear = pastYear.getFullYear() - 1;
  pastYear.setFullYear(nYear);
  const binder = props.binder;
  const controller = props.controller;
  const serialNumber = controller.state.currentAsset.primarySerialNumber;
  const errors = controller.state.errors;
  const IsUTscope = controller.state.currentAsset.isUTScope;
  const fileName = controller.state.certificateImage ? controller.state.certificateImage.fileName : '';
  const deleteFile = fileName
    ? <ActionIcon icon='fa-trash' className='remove-handle' action={handleRemoveFile} />
    : '';
  const acknowledgementMessage = 'I, ' + LoginService.loginInfo.fullName + ', acknowledge the photo that is attached is a valid calibration sticker for this serialized gauge selected.';
  const validationMessage = controller.state.showValidation ? "Please select either Sticker  or Certificate" : "";
  const isSticker = controller.state.isSticker;
  const disbaleSave = isSticker && (controller.state.acknowledgement === false || (controller.state.certificateImage.fileName === undefined) || (controller.state.certificateImage.fileName === null) || (controller.state.certificateImage.fileName === ''));
  const preview = controller.state.previewUrl.length > 0 ? <a className="preview-link" href={controller.state.previewUrl} target="_blank">&nbsp;File Preview</a> : '';
  const chooseCalibration = controller.state.calibrationId ?
    <div className='serial-number'><Form.StaticData label='Calibration' value={controller.state.calibration} /> </div>
    : <Form.AutoComplete label='Choose Calibration' bind={binder.bind('listItem_GUID')} className='required' error={errors.listItem_GUID} id='guage-calibration' allowClear={false} search={Searchers.calibrationSearch()} minimumResultsForSearch={-1} />
  const displayCancel = controller.state.calibrationId ? true : false;

  return (
    <div className='row mt-2'>
      <div id='yearly-calibration' className='col-md-12'>
        <div className='serial-number'><Form.StaticData label='Serial Number' value={serialNumber} /> </div>
        <div className='row'>
          <div className='col-md-12'>
            {chooseCalibration}

            <div className='row'>
              <div className='col-md-3'>
                <span className='ml-2 d-block checkbox-label'>Sticker</span>&nbsp;
                <input type='checkbox' className='sticker-checkbox' checked={controller.state.isSticker} onChange={() => { handleStickerCheckBoxClick(); }} />
              </div>
              <div className='col-md-3'>
                <span className='ml-2 d-block checkbox-label'>Certificate</span>&nbsp;
                <input type='checkbox' className='sticker-checkbox' checked={controller.state.isCertificate} onChange={() => { handleCertificateCheckBoxClick(); }} />
              </div>
              <div className='col-md-6'>
                <span className='ml-2 d-block validation-message'>{validationMessage}</span>
              </div>
            </div>

            <Form.Date className='expiration-date required' label='Expiration Date' bind={binder.bind('expirationDate')} error={errors.expirationDate} options={{ startDate: DateTimeFormatter.formatDropdownDate(pastYear), endDate: DateTimeFormatter.formatDate(nextYear) }} />
            <Form.Input className='certificate-number' label='Vendor Name' bind={binder.bind('vendorName')} />
            <Form.Input className='certificate-number' label='Certificate Number' bind={binder.bind('certificateNumber')} />
            <div className="row">
              <div className='col-md-3'><span className='certificate-image'>
                <DropZone onDrop={handleFileUpload} multiple={false} width={170} height={10} style={{ borderStyle: 'none', padding: 'unset' }}>
                  <Button type='file' variant='success' size='sm' className='' icon='fa-upload'  >Attach File</Button>
                </DropZone>
              </span>
              </div>

              <div className='col-md-4'>{preview}</div>
            </div>
            <br /><br />
            <div className='delete-file'>
              {deleteFile} &nbsp;&nbsp;
              <span>{fileName}</span>
            </div>
          </div>
          {isSticker &&
            <div className='col-md-12 mt-4'>
              <div className='d-inline-flex acknowledgment-message'><Form.CheckBox className='mr-2 mt-1' style={styleAcknowledgementCheckBox} bind={binder.bind('acknowledgement')} /><Form.Label name={acknowledgementMessage} /></div>
            </div>
          }
        </div>
         <div>
        {displayCancel && <div className='col-sm-3 float-right'><Button icon='fa-times' className='btn-block' variant='secondary' size='sm' disabled={false} onClick={handleCancel} style={styleButton}>Cancel</Button></div>}
        <div className='col-sm-3 float-right'><Button icon='fa-save' className='btn-block' variant='success' size='sm' disabled={controller.state.saveInProgress || disbaleSave} onClick={handleSave} style={styleButton}>Save</Button></div>
        </div>
      </div>
    </div >
  );
}


const YearlyCalibrationHistory = (props) => {
  const controller = props.controller;
  const serialNumber = controller.state.currentAsset.primarySerialNumber;
  const data = [...controller.state.yearlyCalibrationHistory] || [];
  const disablePrint = data.filter(x => x.state.selected === true)?.length === 0 ||
    data.filter(x => x.state.selected === true)?.length > 1 ||
    data.filter(x => x.state.selected === true && (x.state.imgUrl == null || x.state.imgUrl == ''))?.length > 0;
  var disableShare = data.filter(x => x.state.selected === true)?.length === 0;
  if (data.filter(x => x.state.selected === true)?.length > 0) {
    disableShare = data.filter(x => x.state.selected === true).every(x => x.state.imgUrl == null || x.state.imgUrl == '');
  }
  const noDataMessage = !controller.state.yearlyCalibrationHistory.length && !controller.state.searchInProgress && 'No Data available for the selected serial Number';
  const searchingMessage = !controller.state.yearlyCalibrationHistory.length && controller.state.searchInProgress && 'searching calibration details for the selected serial Number';
  const isUtScope = controller.state.currentAsset.isUTScope;
  const serviceHistory = controller.state.assetServiceHistory && controller.state.assetServiceHistory.serviceHistory;
  const scrapDetails = controller.state.assetServiceHistory && controller.state.assetServiceHistory.scrapDetails;
  const canDeleteGaugeHistory = controller.state.currentAsset.canDeleteGaugeHistory && (LoginService.isAdministrator || LoginService.isSuperUser)

  const handleEdit = (calibrationId) => {
    props.controller.toggleEdityearlyVerificationVisiblity(calibrationId);
  }
  const handleRemove = (calibrationId) => {
    props.controller.deleteGaugeHistoryRecord(calibrationId);
  }

  const handleToggleCollapsed = (performedServiceLevel) => {
    props.controller.toggleCollapsed(performedServiceLevel);
  }

  const handleShare = () => {
    const calibrationIds = data.filter(x => x.state.selected === true).map(function (obj) { return obj.state.calibrationId; });
    Dialog.showDialog(<SendEmailDialog controller={new PrintShareGaugeController(calibrationIds, controller.state.currentAsset.assetId)} header='Send Gauge details in E-Mail' />);
  }

  const handlePrint = () => {
    const calibrationIds = data.filter(x => x.state.selected === true).map(function (obj) { return obj.state.calibrationId; });
    props.controller.handlePrint(calibrationIds);
  }

  const shareButton = <Button icon='fa-share' variant='primary' size='sm' className='my-3 mr-2' onClick={handleShare} disabled={disableShare}>Share</Button>;
  const printButton = <Button icon='fa-print' variant='primary' size='sm' className='my-3' onClick={handlePrint} disabled={disablePrint}>Print</Button>
  const assetScrapped = controller.state.currentAsset.eAssetStatus == AppConstants.eAssetStatus.Scrapped;
  const uTScopeHistorycolumns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'state.selected',
        Cell: (cellInfo, binder) => {
          return <Form.CheckBox className='csl-checkbox' bind={binder.bind('selected')} />
        }
      },
      {
        Header: 'Certificate Number',
        accessor: 'state.certificateNumber',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
      {
        Header: 'Expiration Date',
        accessor: 'state.expirationDate',
        Cell: (cellInfo) => {
          const expirationDate = cellInfo.value ? DateTimeFormatter.formatDate(cellInfo.value) : '';
          return <span>{expirationDate}</span>
        }
      },
      {
        Header: 'Certificate',
        accessor: 'state.fileName',
        Cell: (cellInfo) => {

          if (cellInfo.row.original.state.type == 'PDF' ||
            cellInfo.row.original.state.type == 'Image') {
            //function to open images and document in new tab
            const handleRedirect = () => {
              const imgUrl = cellInfo.row.original.state.imgUrl;
              if (imgUrl.includes("image")) {
                var image = new Image();
                image.src = cellInfo.row.original.state.imgUrl;
                var w = window.open("");
                w.document.write(image.outerHTML);
              }
              else if (imgUrl.includes("pdf")) {
                let b64String = imgUrl;
                let b64File = Utils.contains(b64String, ';base64,') ? b64String.split(';base64,')[1] : b64String;
                let applicationType = 'application/pdf';
                let file = Utils.convertBase64toBlob(b64File, applicationType);
                let fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
              }
            }
            return cellInfo.value ? <div className="link-label">{cellInfo.row.original.state.isSticker ? " Sticker: " : "Certificate: "}<Form.Link href={cellInfo.row.original.state.imgUrl} value={cellInfo.value} onClick={handleRedirect} /></div> : <span></span>
          }
          else {
            return cellInfo.value ? <div className="link-label">{cellInfo.row.original.state.isSticker ? " Sticker: " : "Certificate: "}<Form.Link value={cellInfo.value} href={cellInfo.row.original.state.imgUrl} download={cellInfo.value} /></div> : <span></span>
          }
        }
      },
      {
        Header: 'Created By',
        accessor: 'state.createdBy',
        Cell: cellInfo => <span>{cellInfo.value}</span>
      },
    ],
    []
  )

  const manageHistorycolumns = useMemo(
    () => [
      {
        Header: 'Share/Print',
        accessor: 'state.selected',
        Cell: (cellInfo, binder) => {
          return <Form.CheckBox className='csl-checkbox' bind={binder.bind('selected')} />
        }
      },
      {
        Header: 'Certificate Number',
        accessor: 'state.certificateNumber',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
      {
        Header: 'Expiration Date',
        accessor: 'state.expirationDate',
        Cell: (cellInfo) => {
          const expirationDate = cellInfo.value ? DateTimeFormatter.formatDate(cellInfo.value) : '';
          return <span>{expirationDate}</span>
        }
      },
      {
        Header: 'Files',
        accessor: 'state.fileName',
        Cell: (cellInfo) => {

          if (cellInfo.row.original.state.type == 'PDF' ||
            cellInfo.row.original.state.type == 'Image') {
            //function to open images and document in new tab
            const handleRedirect = () => {
              const imgUrl = cellInfo.row.original.state.imgUrl;
              if (imgUrl.includes("image")) {
                var image = new Image();
                image.src = cellInfo.row.original.state.imgUrl;
                var w = window.open("");
                w.document.write(image.outerHTML);
              }
              else if (imgUrl.includes("pdf")) {
                let b64String = imgUrl;
                let b64File = Utils.contains(b64String, ';base64,') ? b64String.split(';base64,')[1] : b64String;
                let applicationType = 'application/pdf';
                let file = Utils.convertBase64toBlob(b64File, applicationType);
                let fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
              }
            }
            return cellInfo.value ? <div className="link-label">{cellInfo.row.original.state.isSticker ? " Sticker: " : "Certificate: "}<Form.Link href={null} value={cellInfo.value} onClick={handleRedirect} /></div> : <span></span>
          }
          else {
            return cellInfo.value ? <div className="link-label">{cellInfo.row.original.state.isSticker ? " Sticker: " : "Certificate: "}<Form.Link value={cellInfo.value} href={cellInfo.row.original.state.imgUrl} download={cellInfo.value} /></div> : <span></span>
          }
        }
      },
      {
        Header: 'Created By',
        accessor: 'state.createdBy',
        Cell: (cellInfo) => {
          return <span>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Edit',
        accessor: 'state.calibrationId',
        disableSortBy: true,
        Cell: (cellInfo) => {
          return assetScrapped ? <span></span> : <ActionIcon icon='fa-pencil-alt' className='icon-edit text-info m-1' action={() => handleEdit(cellInfo.value)} />
        }
      },
      {
        Header: 'Delete',
        accessor: 'default',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const calibrationId = cellInfo.row.original.state.calibrationId;
          return assetScrapped ? <span></span> : <ActionIcon icon='fa-trash' className='remove-handle' action={() => handleRemove(calibrationId)} />
        }
      }
    ],
    []
  )

  const historycolumns = useMemo(
    () => [
      {
        Header: 'Share/Print',
        accessor: 'state.selected',
        Cell: (cellInfo, binder) => {
          return <Form.CheckBox className='csl-checkbox' bind={binder.bind('selected')} />
        }
      },
      {
        Header: 'Certificate Number',
        accessor: 'state.certificateNumber',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
      {
        Header: 'Expiration Date',
        accessor: 'state.expirationDate',
        Cell: (cellInfo) => {
          const expirationDate = cellInfo.value ? DateTimeFormatter.formatDate(cellInfo.value) : '';
          return <span>{expirationDate}</span>
        }
      },
      {
        Header: 'Files',
        accessor: 'state.fileName',
        Cell: (cellInfo) => {

          if (cellInfo.row.original.state.type == 'PDF' ||
            cellInfo.row.original.state.type == 'Image') {
            //function to open images and document in new tab
            const handleRedirect = () => {
              const imgUrl = cellInfo.row.original.state.imgUrl;
              if (imgUrl.includes("image")) {
                var image = new Image();
                image.src = cellInfo.row.original.state.imgUrl;
                var w = window.open("");
                w.document.write(image.outerHTML);
              }
              else if (imgUrl.includes("pdf")) {
                let b64String = imgUrl;
                let b64File = Utils.contains(b64String, ';base64,') ? b64String.split(';base64,')[1] : b64String;
                let applicationType = 'application/pdf';
                let file = Utils.convertBase64toBlob(b64File, applicationType);
                let fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
                return true;
              }
            }
            return cellInfo.value ? <div className="link-label">{cellInfo.row.original.state.isSticker ? " Sticker: " : "Certificate: "}<Form.Link href={null} value={cellInfo.value} onClick={handleRedirect} /></div> : <span></span>
          }
          else {
            return cellInfo.value ? <div className="link-label">{cellInfo.row.original.state.isSticker ? " Sticker: " : "Certificate: "}<Form.Link value={cellInfo.value} href={cellInfo.row.original.state.imgUrl} download={cellInfo.value} /></div> : <span></span>
          }
        }
      },
      {
        Header: 'Created By',
        accessor: 'state.createdBy',
        Cell: (cellInfo) => {
          return <span>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Edit',
        accessor: 'state.calibrationId',
        disableSortBy: true,
        Cell: (cellInfo) => {
          return assetScrapped ? <span></span> : <ActionIcon icon='fa-pencil-alt' className='icon-edit text-info m-1' action={() => handleEdit(cellInfo.value)} />
        }
      }
    ],
    []
  )

  return (
    <div className='row'>
      <div id='yearly-calibration-history' className='col-md-12'>
        <div className='row'>
          <div className='col-md-6 serial-number'><Form.StaticData label='Serial Number' value={serialNumber} /> </div>
          <div className='col-md-6'>
            {shareButton}
            {printButton}
          </div>
        </div>
        {/* <div className='yearly-history-table'> */}
        <div id='yearly-cal-results' className='col-md-12'>
          <Grid id='yearly-results-table' showPagination={false} enableDefaultRowSelect={true} canAddNewRow={false} editable={true} columns={isUtScope === true ? uTScopeHistorycolumns : canDeleteGaugeHistory ? manageHistorycolumns : historycolumns} data={data} noDataText={searchingMessage || noDataMessage} />
        </div>

        <ServiceHistoryTable data={serviceHistory} scrapDetails={scrapDetails} isLoading={controller.state.IsInProgress} handleToggleCollapsed={handleToggleCollapsed} controller={controller} displayExport={false} />

        {/* </div> */}
      </div>
    </div>
  );
}

class DailyVerificationDetails extends React.Component {

  componentDidMount = () => {
    const assetScrapped = this.props.controller.state.currentAsset.eAssetStatus == AppConstants.eAssetStatus.Scrapped;
    const tab = assetScrapped ? 'daily-verification-history-tab' : 'daily-verification-tab';
    // $('.nav-tabs a[href="#'+ tab +'"]').tab('show');
  }

  handleToggleCollapsed = (calHeader) => {
    this.props.controller.toggleCollapsed(calHeader);
  }

  render = () => {
    const controller = this.props.controller;
    const binder = this.props.binder;
    const hide = controller.state.currentAsset.eAssetStatus;
    const dailyVerificationTitle = 'Daily Verification';
    const historyTitle = 'History';
    const assetScrapped = controller.state.currentAsset.eAssetStatus == AppConstants.eAssetStatus.Scrapped;

    return (<Jumbotron className='col-md-12'>
      <Tabs onSelect={(k) => controller.tabShow(k)}>
        {!assetScrapped && <Tab eventKey={1} title={dailyVerificationTitle}>
          <Tab.Pane>
            <DailyVerification controller={controller} binder={binder} />
          </Tab.Pane>
        </Tab>}
        <Tab eventKey={2} title={historyTitle}>
          <Tab.Pane>
            <DailyCalibrationHistory data={controller.state} handleToggleCollapsed={this.handleToggleCollapsed} binder={binder} />
          </Tab.Pane>
        </Tab>
      </Tabs>
    </Jumbotron>
    );
  }
}

class YearlyCalibrationDetails extends React.Component {
  componentDidMount = () => {
    const assetScrapped = this.props.controller.state.currentAsset.eAssetStatus == AppConstants.eAssetStatus.Scrapped;
    const tab = assetScrapped ? 'yearly-calibration-history-tab' : 'yearly-calibration-tab';
  }

  render = () => {
    const controller = this.props.controller;
    const binder = this.props.binder;
    const IsUTscope = controller.state.currentAsset.isUTScope;
    const yearlyVerificationTitle = IsUTscope === true ? 'Yearly Calibration' : 'Update Calibration';
    const historyTitle = 'History';
    const assetScrapped = controller.state.currentAsset.eAssetStatus == AppConstants.eAssetStatus.Scrapped;
    return (
      <Jumbotron className='col-md-12'>
        <Tabs activeKey={controller.state.yearCalActiveKey} onSelect={(k) => controller.tabShow(k)}>
          {!assetScrapped &&
            <Tab id='yearly-calibration-tab' eventKey={3} title={yearlyVerificationTitle}>
              <Tab.Pane>
                {IsUTscope ? <YearlyCalibration controller={controller} binder={binder} /> : <UpdateCalibration controller={controller} binder={binder} />}
              </Tab.Pane>
            </Tab>}
          <Tab id='yearly-calibration-history-tab' eventKey={4} title={historyTitle}>
            <Tab.Pane>
              <YearlyCalibrationHistory controller={controller} binder={binder} />
            </Tab.Pane>
          </Tab>
        </Tabs>
      </Jumbotron>
    );
  }
}

const ReceivedAssets = (props) => {

  const controller = props.controller;
  const binder = props.binder;
  const calibrationVisibility = controller.state.calibrationVisibility;
  let calibrationPage = null;
  switch (calibrationVisibility) {
    case 'DV':
      calibrationPage = <DailyVerificationDetails controller={controller} binder={binder} />
      break;
    case 'YC':
      calibrationPage = <YearlyCalibrationDetails controller={controller} binder={binder} />
      break;
    default:
      calibrationPage = null;
  }
  return (
    <div id='received-assets' className='row'>
      {calibrationPage}
    </div>
  );
}

const DetailGaugeView = (props) => {

  const controller = props.controller;
  const binder = props.binder;
  const state = controller.state;
  const calibrationVisibility = controller.state.calibrationVisibility && controller.state.calibrationVisibility.length > 0;

  return (
    <div className='row bck-white'>
      <div className='col-md-6' style={{ position: 'relative', zIndex: 1 }}>
        <Jumbotron>
          <LookupAsset controller={controller} binder={binder} asset={state.currentAsset} />
        </Jumbotron>
      </div>
      <div className='col-md-6'>
        {calibrationVisibility &&
          <Jumbotron>
            <ReceivedAssets controller={controller} binder={binder} />
          </Jumbotron>
        }
      </div>
    </div>
  );
}

const GaugeManagementPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-tachometer-alt' />

class GaugeManagementPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new GaugeManagementController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const DetailGaugeViewTitle = 'Detail View';
    const facilityGaugeListTitle = 'Facility Gauge List';
    return (
      <Page pageTitle='InteServ · Gauge Management' id='gaugemanagement-page'>
        <GaugeManagementPageHeader pageTitle={'Gauge Management'} />
        <Page.Content>
          <div className="row">
            <Jumbotron className='col-md-12 bck-white'>
              <Tabs activeKey={controller.state.activeKey} onSelect={(k) => controller.mainTabShow(k)}>
                <Tab id='yearly-calibration-tab' eventKey={1} title={DetailGaugeViewTitle}>
                  <Tab.Pane>
                    <DetailGaugeView controller={controller} binder={binder} />
                  </Tab.Pane>
                </Tab>
                <Tab id='facility-gauge-tab' eventKey={2} title={facilityGaugeListTitle}>
                  <Tab.Pane>
                    {controller.state.facilityGaugeVisibility && <FacilityGaugesPage controller={controller} binder={binder} />}
                  </Tab.Pane>
                </Tab>
              </Tabs>
            </Jumbotron>
          </div>

        </Page.Content>
      </Page>
    );
  }
}

export default GaugeManagementPage;
