
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class ServiceLevelLinkController extends BaseController {
    constructor() {
        super('service-level-link', ServiceLevelLinkController.getInitialState())

        messages.channel('login').action('loggedin').subscribe(data => {
            if (!data) {
                this.state.searchManDesc = '';
                this.state.searchLaborCode = '';
                this.state.manDescSearchResults = [];
                this.state.laborCodeSearchResults = [];
                this.state.distinctCustomers = [];
                this.state.assignedLaborCodes = [];
                this.state.customerServiceLevels = [];
                this.state.selectedCustomerForSearch = '';
                this.state.selectedServiceLevelForSearch = '';
                this.state.customerServiceLevelFilter = '';
                this.state.serviceLevelsForSearch = [];
            }
        });
    }

    static getInitialState = () => {
        return {
            searchManDesc: '',
            searchLaborCode: '',
            searchAssignedLaborCode: '',
            manDescSearchResults: [],
            laborCodeSearchResults: [],
            distinctCustomers: [],
            assignedLaborCodes: [],
            customerServiceLevels: [],
            searchStyleEnabled: true,
            searchContains: true,
            searchDoesNotContain: false,
            searchStartsWith: false,
            searchEndsWith: false,
            searchExactMatch: false,
            showAllResults: false,
            selectedCustomerForSearch: '',
            selectedCustomerForManDescSearch: '',
            selectedServiceLevelForSearch: '',
            serviceLevelsForSearch: [],
            customerServiceLevelFilter: '',
            errors: {}
        }
    }

    selectedCustomerForManDescSearchChanged = () => {
        this.state.manDescSearchResults = [];
        this.commit();
        document.getElementById('searchManDesc').focus();
    }

    searchAssignedLaborCodeChanged = (data) => {
        this.state.searchStyleEnabled = !Utils.contains(data.new, ',');
    }

    selectAllManufacturerDescriptions = (newState) => {
        this.state.manDescSearchResults.forEach(md => md.selected = newState);
        if (this.state.manDescSearchResults.length) {
            this.searchCustomers(this.state.manDescSearchResults[0]);
        }
        this.commit();
    }

    clearSearchOptions = () => {
        this.state.searchContains = false;
        this.state.searchDoesNotContain = false;
        this.state.searchStartsWith = false;
        this.state.searchEndsWith = false;
        this.state.searchExactMatch = false;
    }

    searchContainsChanged = () => {
        this.clearSearchOptions();
        this.state.searchContains = true;
    }

    searchDoesNotContainChanged = () => {
        this.clearSearchOptions();
        this.state.searchDoesNotContain = true;
    }

    searchStartsWithChanged = () => {
        this.clearSearchOptions();
        this.state.searchStartsWith = true;
    }

    searchEndsWithChanged = () => {
        this.clearSearchOptions();
        this.state.searchEndsWith = true;
    }

    searchExactMatchChanged = () => {
        this.clearSearchOptions();
        this.state.searchExactMatch = true;
    }

    selectedServiceLevelForSearchChanged = () => {
        document.getElementById('searchAssignedLaborCode').focus();
        this.state.assignedLaborCodes = [];
        this.commit();
    }

    selectedCustomerForSearchChanged = () => {
        this.state.selectedServiceLevelForSearch = '';
        this.state.assignedLaborCodes = [];
        if (this.state.selectedCustomerForSearch) {
            this.loadCustomerServiceLevels(this.state.selectedCustomerForSearch.id, 'serviceLevelsForSearch');
        }
        else {
            this.state.serviceLevelsForSearch = [];
            this.commit();
        }
    }

    canApply = () => {
        return ((this.getSelectedManDesc().length > 0) && (this.getSelectedCustomerServiceLevels().length > 0));
    }

    getSelectedManDesc = () => {
        return this.state.manDescSearchResults.filter(result => result.selected);
    }

    getSelectedLaborCodes = () => {
        return this.state.laborCodeSearchResults.filter(result => result.selected);
    }

    getSelectedCustomerServiceLevels = () => {
        return this.state.customerServiceLevels.filter(result => result.selected);
    }

    toggleSelection = (result, searchCustomers) => {
        result.selected = !result.selected;
        if (searchCustomers) {
            this.searchCustomers(result);
        }
        this.commit();
    }

    clearCustomerServiceLevelFilter = () => {
        this.state.customerServiceLevelFilter = '';
        this.commit();
    }

    filterCustomerServiceLevels = () => {
        this.commit();
    }

    customerServiceLevelSearch = () => {
        var self = this;

        return (searchTerm) => {
            var parameters = {
                customerId: self.state.selectedCustomerForSearch.id,
                searchTerm: searchTerm
            };
            return ajax.get('lookup/getAllServiceLevelsForCustomer', parameters).then((results) => {
                var serviceLevels = results.map(r => {
                    return {
                        id: r.customerServiceLevelId,
                        name: r.customerServiceLevelName,
                        isSAPServiceLevel: r.isSAPServiceLevel
                    };
                });

                return serviceLevels;
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
            
        };
    }

    undimAllManDesc = () => {
        this.state.manDescSearchResults.forEach(manDesc => {
            manDesc.dimmed = false;
        });
    }

    dimSelectedManDescsWithoutCustomer = (customer) => {
        this.state.manDescSearchResults.forEach(manDesc => {
            if (!manDesc.selected || !manDesc.customers || !manDesc.customers.length) {
                manDesc.dimmed = false;
                return;
            }
            manDesc.dimmed = !Utils.arrayContains(manDesc.customers.map(c => c.customer.id), customer.id);
        });
    }

    selectCustomer = (customer) => {
        this.dimSelectedManDescsWithoutCustomer(customer);
        this.state.distinctCustomers.forEach(c => c.selected = false);
        var selectedCustomer = this.state.distinctCustomers.filter(c => c.id === customer.id);
        if (selectedCustomer.length) {
            selectedCustomer[0].selected = true;
            this.loadCustomerServiceLevels(selectedCustomer[0].id, 'customerServiceLevels');
        }
        else {
            this.state.customerServiceLevels = [];
            this.undimAllManDesc();
        }
        this.commit();
    }

    loadCustomerServiceLevels = (customerId, statePropertyToUpdate) => {
        var parameters = {
            customerId: customerId,
            searchTerm: ''
        };

        this.state.isLoading = true;
        ajax.get('lookup/getAllServiceLevelsForCustomer', parameters).then((results) => {
            this.state.isLoading = false;
            this.state[statePropertyToUpdate] = results;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    populateCustomers = () => {
        var selectedCustomer = this.state.distinctCustomers.filter(c => c.selected);
        var allCustomers = [];
        this.state.manDescSearchResults
            .filter(manDesc => manDesc.selected && manDesc.customers && manDesc.customers.length)
            .forEach(manDesc => {
                allCustomers = allCustomers.concat(manDesc.customers.map(c => c.customer));
            });

        this.state.distinctCustomers = [];
        for (var i = 0; i < allCustomers.length; ++i) {
            var customer = allCustomers[i];
            if (!Utils.arrayContains(this.state.distinctCustomers.map(c => c.id), customer.id)) {
                this.state.distinctCustomers.push(customer);
            }
        }
        this.state.distinctCustomers.sort((a, b) => a.name.localeCompare(b.name));
        if (selectedCustomer.length) {
            this.selectCustomer(selectedCustomer[0]);
        }
        this.commit();
    }

    searchCustomers = (manDesc) => {
        if (!manDesc || (manDesc.customers && manDesc.customers.length)) {
            this.populateCustomers();
            return;
        }

        var parameters = {
            manDescId: manDesc.manDescId
        };

        this.state.isLoading = true;
        ajax.get('lookup/getCustomerDescriptionsForManufacturerDescriptions', parameters).then((results) => {
            this.state.isLoading = false;
            manDesc.customers = results;
            this.populateCustomers();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    clearManDescSearch = () => {
        this.state.searchManDesc = '';
        this.commit();
    }

    clearLaborCodeSearch = () => {
        this.state.searchLaborCode = '';
        this.commit();
    }

    clearsearchAssignedLaborCodeSearch = () => {
        this.state.searchAssignedLaborCode = '';
        this.commit();
    }

    searchManDesc = () => {
        var parameters = {
            searchTerm: this.state.searchManDesc,
            customerId: this.state.selectedCustomerForManDescSearch && this.state.selectedCustomerForManDescSearch.id,
            maxResults: 500
        };

        this.state.isLoading = true;
        ajax.get('lookup/searchManufacturerDescriptions2', parameters).then((results) => {
            this.state.isLoading = false;
            this.state.manDescSearchResults = results;
            this.searchCustomers();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    searchLaborCode = () => {
        var parameters = {
            searchTerm: this.state.searchLaborCode,
            maxResults: 50
        };

        this.state.isLoading = true;
        ajax.get('lookup/searchLaborCode', parameters).then((results) => {
            this.state.isLoading = false;
            this.state.laborCodeSearchResults = results;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    canSearchAssignedLaborCodes = () => {
        return this.state.selectedServiceLevelForSearch; // has value
    }

    getSearchStyle = () => {
        if (this.state.searchContains) {
            return 0;
        }
        if (this.state.searchDoesNotContain) {
            return 1;
        }
        if (this.state.searchStartsWith) {
            return 2;
        }
        if (this.state.searchEndsWith) {
            return 3;
        }
        if (this.state.searchExactMatch) {
            return 4;
        }
    }

    searchAssignedLaborCodes = () => {
        if (!this.canSearchAssignedLaborCodes()) {
            notification.action('warning').post('You must select a Customer Service Level');
            return;
        }

        var parameters = {
            customerServiceLevelId: this.state.selectedServiceLevelForSearch.id,
            searchTerm: this.state.searchAssignedLaborCode,
            searchStyle: this.getSearchStyle(),
            showAllResults: this.state.showAllResults
        };

        this.state.assignedLaborCodes = [];
        this.state.isLoadingAssignedLaborCodes = true;
        this.commit();
        ajax.get('lookup/searchAssignedLaborCodes', parameters).then((searchResults) => {
            this.state.isLoadingAssignedLaborCodes = false;
            this.state.assignedLaborCodes = searchResults.filteredResults;
            this.state.unfilteredAssignedLaborCodesCount = searchResults.unfilteredResultsCount;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    removeAssignedLaborCode = (item) => {
        var laborCode = item.laborCodeName;
        var mfgItemNo = item.mfgItemNo;
        var customerServiceLevel = item.customerServiceLevelName;

        this.state.isLoading = true;
        ajax.post('serviceLevel/deleteAssignedLaborCode', item.assignedLaborCodeId).then(() => {
            this.state.isLoading = false;
            notification.action('success').post('Remove assigned labor code ' + laborCode + ' for ' + mfgItemNo + ' on ' + customerServiceLevel);
            this.searchAssignedLaborCodes();
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    applyAssignedLaborCodes = () => {
        var parameters = {
            manufacturerDescriptionIds: this.getSelectedManDesc().filter(md => !md.dimmed).map(md => md.manDescId),
            laborCodeIds: this.getSelectedLaborCodes().map(lc => lc.laborCodeId),
            customerServiceLevelIds: this.getSelectedCustomerServiceLevels().map(csl => csl.customerServiceLevelId)
        };

        this.state.isLoading = true;
        ajax.post('serviceLevel/createAssignedLaborCodes', parameters).then(() => {
            this.state.isLoading = false;
            notification.action('success').post('Successfully created assigned labor codes');
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }
}

export default ServiceLevelLinkController;