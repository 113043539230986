import store from 'store';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';

class CustomerController extends BaseController {
    constructor() {
        super('customer', {
            searchActive: true,
            searchCompleted: false,
            searchEnabledUsersOnly: true,
            searchText: '',
            searchResults: [],
            selectedCustomer: '',
            allCustomerLocations: [],
            filteredCustomerLocations: [],
            allCustomerUsers: [],
            filteredCustomerUsers: [],
            allServiceLevels: [],
            filteredServiceLevels: [],
            allassemblylevels : [],
            filteredassemblylevels : []
        });

        messages.channel('edit-customer').action('saved').subscribe(customerId => {
            this.searchCustomers(customerId);
        });

        messages.channel('edit-customer-service-level').action('saved').subscribe(() => {
            this.loadCustomerServiceLevels(this.state.selectedCustomer);
            store.remove('edit-customer-service-level');
        });

        messages.channel('edit-customer-location').action('saved').subscribe(() => {
            this.loadCustomerLocations(this.state.selectedCustomer);
            store.remove('edit-customer-location');
        });

        messages.channel('edit-user').action('saved').subscribe(() => {
            this.loadCustomerUsers(this.state.selectedCustomer);
            store.remove('edit-user');
        });

        messages.channel('login').action('loggedin').subscribe(data => {
            if (!data) {
                this.clearResults();
            }
        });
    }

    searchEnabledUsersOnlyChanged = () => {
        this.loadCustomerUsers(this.state.selectedCustomer);
    }

    searchCustomers = (selectedCustomerId) => {
        this.state.searchResults = [];
        var searchText = this.state.searchText;
        var parameters = {
            searchTerm: searchText
        };

        this.state.selectedCustomer = '';
        this.state.searchResults = [];
        this.state.allCustomerLocations = [];
        this.state.filteredCustomerLocations = [];
        this.state.allCustomerUsers = [];
        this.state.filteredCustomerUsers = [];
        this.state.allServiceLevels = [];
        this.state.filteredServiceLevels = [];
        this.state.filteredassemblylevels = [];
        this.state.allassemblylevels = [];
        this.state.isLoading = true;
        ajax.get('lookup/searchCustomers', parameters).then(results => {
            this.state.isLoading = false;
            this.state.searchResults = results;

            if (selectedCustomerId) {
                // customer to select was specified
                this.selectCustomer(selectedCustomerId);
            }
            else if (results.length > 0) {
                // auto-select the 1st customer - this will be the newest one based on ordering
                this.state.selectedCustomer = results[0];
                this.selectCustomer(results[0].customerId);
            }
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    selectCustomer = (customerId) => {
        this.state.searchResults.forEach(customer => {
            if (customer.customerId === customerId) {
                this.loadCustomerDetails(customer);
                this.loadCustomerLocations(customer);
                this.loadCustomerUsers(customer);
                this.loadCustomerServiceLevels(customer);
            }
        });
    }

    isSelected = (customer) => {
        if (!this.state.selectedCustomer) {
            return false;
        }

        return (customer.customerId === this.state.selectedCustomer.customerId);
    }

    clearResults = () => {
        this.state.selectedCustomer = '';
        this.state.searchResults = [];
        this.state.allCustomerLocations = [];
        this.state.filteredCustomerLocations = [];
        this.state.allCustomerUsers = [];
        this.state.filteredCustomerUsers = [];
        this.state.allServiceLevels = [];
        this.state.filteredServiceLevels = [];
        this.state.allServiceLevels = [];
        this.state.filteredassemblylevels = [];
        this.state.allassemblylevels = [];
    }

    clearSearch = () => {
        this.clearLocationSearch();
        this.clearUserSearch();
        this.clearServiceLevelSearch();
        this.clearServiceTextSearch();
    }

    // Customer Details
    // ----------------------------------------------

    loadCustomerDetails = (customer) => {
        this.state.selectedCustomer = customer;
        this.loadCustomerLocations(customer);
        this.loadCustomerUsers(customer);
        this.loadCustomerServiceLevels(customer);
        this.loadCustomerAssemblyLevels(customer);
        this.commit();
    }

    // Customer Service Levels
    // ----------------------------------------------

    loadCustomerServiceLevels = (customer) => {
        if (!customer || !customer.customerId) {
            return;
        }
        var parameters = {
            customerId: customer.customerId
        };

        ajax.get('lookup/getAllServiceLevelsForCustomer', parameters).then(results => {
            this.state.allServiceLevels = results;
            this.filterCustomerServiceLevels();
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // IList<CustomerServiceLevelLookupDto>
    }

    filterCustomerServiceLevels = () => {
        if (!this.state.allServiceLevels.length) {
            this.state.filteredServiceLevels = [];
            this.commit();
            return;
        }

        var filterText = (this.state.searchServiceLevelText || '').toLowerCase();
        if (filterText.trim() === '') {
            this.state.filteredServiceLevels = this.state.allServiceLevels.map(cl => cl);
            this.commit();
            return;
        }

        this.state.filteredServiceLevels = this.state.allServiceLevels.filter(cl => cl.customerServiceLevelName.toLowerCase().indexOf(filterText) !== -1);
        this.commit();
    }

    clearServiceLevelSearch = () => {
        document.getElementById('searchServiceLevelText').focus();
        this.state.searchServiceLevelText = '';
        this.commit();
    }
    clearAssemblyLevelSearch = () => {
        document.getElementById('searchAssemblyLevelText').focus();
        this.state.searchAssemblyLevelText = '';
        this.commit();
    }

    clearServiceTextSearch = () => {
        document.getElementById('searchText').focus();
        this.state.searchText = '';
        this.commit();
    }

    // Customer Locations
    // ----------------------------------------------

    loadCustomerLocations = (customer) => {
        if (!customer || !customer.customerId) {
            return;
        }
        var parameters = {
            customerId: customer.customerId
        };

        ajax.get('lookup/getAllCustomerLocationsForCustomer', parameters).then(results => {
            this.state.allCustomerLocations = results;
            this.filterCustomerLocations();
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // IList<LookupDto>
    }

    filterCustomerLocations = () => {
        if (!this.state.allCustomerLocations.length) {
            this.state.filteredCustomerLocations = [];
            this.commit();
            return;
        }

        var filterText = (this.state.searchLocationText || '').toLowerCase();
        if (filterText.trim() === '') {
            this.state.filteredCustomerLocations = this.state.allCustomerLocations.map(cl => cl);
            this.commit();
            return;
        }

        this.state.filteredCustomerLocations = this.state.allCustomerLocations.filter(cl => cl.name.toLowerCase().indexOf(filterText) !== -1);
        this.commit();
    }

    clearLocationSearch = () => {
        document.getElementById('searchLocationText').focus();
        this.state.searchLocationText = '';
        this.commit();
    }

    // Customer Users
    // ----------------------------------------------

    clearUserSearch = () => {
        document.getElementById('searchUserText').focus();
        this.state.searchUserText = '';
        this.commit();
    }

    loadCustomerUsers = (customer) => {
        if (!customer || !customer.customerId) {
            return;
        }

        var parameters = {
            customerId: customer.customerId,
            enabledUsersOnly: this.state.searchEnabledUsersOnly
        };

        ajax.get('lookup/getAllUsersForCustomer', parameters).then(results => {
            this.state.allCustomerUsers = results;
            this.filterCustomerUsers();
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // IList<UserLookupDto>
    }

    filterCustomerUsers = () => {
        if (!this.state.allCustomerUsers.length) {
            this.state.filteredCustomerUsers = [];
            this.commit();
            return;
        }

        var filterText = (this.state.searchUserText || '').toLowerCase();
        if (filterText.trim() === '') {
            this.state.filteredCustomerUsers = this.state.allCustomerUsers.map(u => u);
            this.commit();
            return;
        }

        this.state.filteredCustomerUsers = this.state.allCustomerUsers.filter(u => u.name.toLowerCase().indexOf(filterText) !== -1);
        this.commit();
    }
    loadCustomerAssemblyLevels = (customer) => {
        if (!customer || !customer.customerId) {
            return;
        }
        var parameters = {
            customerId: customer.customerId
        };

        ajax.get('lookup/awo/getassemblylevelsbycustomerId', parameters).then(results => {
            this.state.allassemblylevels = results;
            this.filterCustomerAssemblyLevels();
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }
    filterCustomerAssemblyLevels = () => {
        if (!this.state.allassemblylevels.length) {
            this.state.filteredassemblylevels = [];
            this.commit();
            return;
        }

        var filterText = (this.state.searchAssemblyLevelText || '').toLowerCase();
        if (filterText.trim() === '') {
            this.state.filteredassemblylevels = this.state.allassemblylevels.map(cl => cl);
            this.commit();
            return;
        }

        this.state.filteredassemblylevels = this.state.allassemblylevels.filter(cl => cl.customerAssemblyLevelName.toLowerCase().indexOf(filterText) !== -1);
        this.commit();
    }
}

export default CustomerController;
