import React from 'react';
import Dialog from '../../components/Dialog';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Validator from '../../lib/Validator';
import notification from '../../services/Notification';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
import messages from '../../services/Messages';
import store from 'store';
import SAPService from '../services/SAPService';
import AppConstants  from '../utility/AppConstants';
import MoveContainerController                         from '../controllers/MoveContainerController';
import SelectAssetsForContainerController              from '../controllers/SelectAssetsForContainerController';
import SelectAuthorizedLocationsForContainerController from '../controllers/SelectAuthorizedLocationsForContainerController';
import TransferAssetsToContainerController             from '../controllers/TransferAssetsToContainerController';
import ShipAssetsToContainerDialogController           from '../controllers/ShipAssetsToContainerDialogController';
import MoveContainerDialog                             from '../dialogs/MoveContainerDialog';
import SelectAssetsForContainerDialog                  from '../dialogs/SelectAssetsForContainerDialog';
import SelectAuthorizedLocationsForContainerDialog     from '../dialogs/SelectAuthorizedLocationsForContainerDialog';
import TransferAssetsToContainerDialog                 from '../dialogs/TransferAssetsToContainerDialog';
import ShipAssetsToContainerDialog                     from '../dialogs/ShipAssetsToContainerDialog';

class ContainerController extends BaseController {
  constructor() {
    super('container', {
      customer            : '',
      selectedContainer   : '',
      containerId         : '',
      description         : '',
      functionalLocation  : '',
      containers          : [],
      containerList       : [],
      containerMatchCount : 0,
      totalContainerCount : 0,
      totalAssetCount     : 0,
      errors              : {},
      containerSearchText : '',
      assetFilterText     : '',
      filteredAssets      : [],
      showAllResults      : false,
      activeTab           : 2,
      plantCode           : '',
      indicator           : '',
      isFunLoc            : false,
      currentPlantCode    : '',
      canEditButton       : false,
      canNewButton        : false,
      canEditDesc         : false,
      useHierarchyData    : true
    });
    
    messages.channel('edit-container').action('saved').subscribe(() => {
      this.customerChanged();
      let element = document.getElementById('fldDescription');
      element && element.focus();
    });

    messages.channel('load-container-assets').action('reload').subscribe(()=>{
      if(this.state.selectedContainer){
        this.selectContainer(this.state.selectedContainer);
      }
    });

    messages.channel('load-plant-Code').action('reload').subscribe(()=>{
        this.loadPlantCode();
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state.customer            = '';
        this.state.containers          = [];
        this.state.containerSearchText = '';
        this.state.showAllResults      = false;
        this.clearSelectedContainer();
      }
    });    
  }

  setActiveTab = (tabNumber) => {
    this.state.activeTab = tabNumber;
    this.commit();
  }

  customerChanged = (data) => {
    var selectedContainer = data ? null : this.state.selectedContainer;
    this.state.errors     = {};
    this.clearSelectedContainer();
    this.containerSearch(selectedContainer);
    let element = document.getElementById('containerSearchText');
    element && element.focus();
  }

  clearSelectedContainer = () => {
    this.state.selectedContainer   = '';
    this.state.filteredAssets      = [];
    this.state.containerId         = '';
    this.state.description         = '';
    this.state.functionalLocation         = '';
    this.state.plantCode = this.state.currentPlantCode;
    this.state.containerMatchCount = 0;
    this.state.isFunLoc = false;
    this.state.errors={};
    this.state.canEditButton = false;
    this.state.canNewButton = false;
    this.state.canEditDesc = false;
    this.commit();
  }

  cancel = () => {
    this.state.canNewButton = true;
    this.state.canEditDesc = false; 
    if(this.state.description == '' && this.state.functionalLocation == ''){
      this.selectContainer(this.state.containers[0]);
    }
    this.commit();
  }

  handleNew = () => {
    this.state.canNewButton = true;
    this.state.canEditDesc = true;
    this.commit();
  }

  handleEdit = () => {
    this.state.canNewButton = false;
    this.state.canEditButton = true;
    this.state.canEditDesc = true;
    this.commit();
  }

  canSave = () => {
    if (!this.state.customer) {
      return false;
    }
    if (!this.state.selectedContainer) {
      return false;
    }
    if (!this.state.selectedContainer.containerId) {
      return false;
    }
    if (!this.state.selectedContainer.hasAccess) {
      return false;
    }
    return true;
  }

  clearContainerSearchText = () => {
    this.state.containerSearchText = '';
    document.getElementById('containerSearchText').focus();
    this.commit();
  }

  clearAssetFilterText = () => {
    this.state.assetFilterText = '';
    document.getElementById('assetFilterText').focus();
    this.commit();
  }

  filterAssets = () => {
    const container = this.state.selectedContainer;
    if (!container || !container.assets || !container.assets.length) {
      return;
    }

    if (!this.state.assetFilterText) {
      this.state.filteredAssets = container.assets;
      this.commit();
      return;
    }

    container.assets.forEach(a => a.selected = false); // deselect all assets prior to filtering
    this.state.filteredAssets = container.assets;
    this.state.filteredAssets = this.state.filteredAssets.filter(a => {
      const terms        = this.state.assetFilterText.split(',');
      let includeAsset = true;
      for (let i = 0; i < terms.length; ++i) {
        let includeForThisTerm = false;
        let term = terms[i];
        if (Utils.contains(a.primarySerialNumber, term)) {
          includeForThisTerm = true;
        }
        else if (Utils.contains(a.assetStatus, term)) {
          includeForThisTerm = true;
        }
        else if (Utils.contains(a.description, term)) {
          includeForThisTerm = true;
        }
        else if (Utils.contains(a.equipmentNumber, term)) {
          includeForThisTerm = true;
        }
        else if (Utils.contains(a.jobNumber, term)) {
          includeForThisTerm = true;
        }
        includeAsset &= includeForThisTerm;
      }
      return includeAsset;
    });
    this.commit();
  }

  customerSearch = () => {
    return (searchTerm) => ajax.get('lookup/searchCustomerList', {searchTerm: searchTerm})
    .then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  });
  }

  getContainerHyrarchy = (container) => {
    if (container.parentContainerId === Utils.emptyGuid & container.childContainerCount > 0
      && this.state.containers.filter(x => x.containerId === container.containerId).length > 0) {
      return this.state.containers.filter(x => x.containerId === container.containerId)[0];
    }
    else if (container.parentContainerId != Utils.emptyGuid & container.childContainerCount > 0) {
      return this.getChildContainer(container);
    }
    return container;
  }

  getChildContainer(container) {
    const childContainers = this.state.containerList.filter(x => x.parentContainerId === container.containerId);
    childContainers.forEach(item => {
      if (item.childContainerCount > 0) {
        return this.getChildContainer(item);
      }
    })
    if (!container.containers) {
      container.containers = [];       // if the child collection doesn't exist yet, add it ...
    }
    container.containers = childContainers;
    return container;
  }

  selectContainer = (container) => {
    if(!container){
      return;
    }
    delete container.assets;
    delete container.locations;
    this.state.selectedContainer = this.state.useHierarchyData ? container : this.getContainerHyrarchy(container);
    this.state.containerId       = container.containerId;
    this.state.description       = container.description;
    this.state.functionalLocation= container.functionalLocation;
    this.state.plantCode=(container.plantCode?container.plantCode:this.state.currentPlantCode)
    this.state.isFunLoc=  container.functionalLocation ?true:false;
    this.state.filteredAssets    = [];
    container.assets             = [];
    container.locations          = [];
    this.state.errors={};
    this.state.canEditButton = true;
    this.state.canNewButton = true;
    this.commit();

    if (container.hasAccess) {
      this.loadAssets(container);
    }
    this.loadLocations(container);
    this.loadIndicatorValue();
  }

  toggleSelection = (items) => {
    //if(item.hasShipped || item.jobNumber) return;
    this.state.filteredAssets.forEach(row => {
      const asset = items.find(item => row.assetId === item.assetId);
      if(asset){
        row.selected = true;
      }
      else{
        row.selected = false;
      }
    });
    this.commit();
  }

  toggleLocationSelection = (location) => {
    //if(item.hasShipped || item.jobNumber) return;
      this.state.selectedContainer.locations.forEach(row => {
        if(row?.containerLocationId == location?.containerLocationId){
          row.selected = true;
        }
        else{
          row.selected = false;
        }
      });
    this.commit();
  }


  selectAllAssets = () => {
      if (!this.state.filteredAssets) {
        return;
    }
    this.state.filteredAssets.forEach(a =>a.selected = true);
      this.commit();
  }

  deselectAllAssets = () => {
      if (!this.state.filteredAssets) {
          return;
      }
      this.state.filteredAssets.forEach(a =>a.selected = false);
      this.commit();
  }

  getSelectedAssets = () => {
    if (!this.state.filteredAssets) {
      return [];
    }
    return this.state.filteredAssets.filter(a => a.selected);
  }
  
  deselectAllLocation = () => {
    
  }

  getAssetsToProcess = () => {
    let selectedAssetIds = [];
    let selectedAssetIdswitAWONumber=[];
    if(!this.getSelectedAssets().length){
      return false;
    }
    selectedAssetIds = this.getSelectedAssets().filter(a=>a.jobNumber)
    selectedAssetIdswitAWONumber = this.getSelectedAssets().filter(a=>a.awoNumber);
    if(!selectedAssetIds.length && !selectedAssetIdswitAWONumber.length){
      return true;
    }
    return false;
  }
  removeSelectedAssets = () => {
    const selectedAssetIds = this.getSelectedAssets().map(a => a.assetId);
    const assetCount       = selectedAssetIds.length;
    if (!assetCount) {
      return;
    }

    const container = this.state.description;
    const message   = <div><h6>{'Please confirm you wish to remove ' + Utils.pluralize('asset', 's', assetCount) + ' from the ' + container + ' container'}</h6></div>;

    co(this, function*() {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={150} header='Confirm Asset Removal'>
          {message}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }

      const parameters = {
        containerId : this.state.containerId,
        assetIds    : selectedAssetIds
      };

      yield ajax.post('container/removeAssets', parameters); // IList<RemoveContainerAssetsDto>
      notification.action('success').post('Removed ' + Utils.pluralize('asset', 's', assetCount) + ' from ' + container, 'Removed Assets from Container');
      messages.channel('edit-container').action('saved').post();
    });
  }

  loadAssets = (container) => {
      const parameters = {
        containerId : container.containerId
      };
      this.state.isLoading       = true;
      this.state.canEditDesc = false;
      ajax.get('lookup/getContainerAssets', parameters).then(results=>{
        this.state.isLoading       = false;
        container.assets           = results;
        this.state.filteredAssets  = results;
        this.state.totalAssetCount = results.length;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<ContainerAssetDto>
  }

  loadLocations = (container) => {
    co(this, function*() {
      const parameters = {
        containerId : container.containerId
      };
      this.state.isLoadingLocations = true;
      var results                   = yield ajax.get('lookup/getContainerLocations', parameters); // IList<ContainerLocationDto>
      this.state.isLoadingLocations = false;
      container.locations           = results;
      this.commit();
    });
  }

  getSelectedLocations = () => {
    if (!this.state.selectedContainer || !this.state.selectedContainer.locations) {
      return [];
    }
    return this.state.selectedContainer.locations.filter(l => l.selected);
  }

  removeSelectedLocations = () => {
    const selectedIds = this.getSelectedLocations().map(l => l.containerLocationId);
    const count       = selectedIds.length;
    if (!count) {
      return;
    }

    const container = this.state.description;
    const message   = <div><h6>{'Please confirm you wish to remove ' + Utils.pluralize('location', 's', count) + ' from the ' + container + ' container'}</h6></div>;

    co(this, function*() {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={150} header='Confirm Location Removal'>
          {message}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }

      const parameters = {
        containerId          : this.state.containerId,
        containerLocationIds : selectedIds
      };

      yield ajax.post('container/removeLocations', parameters); // IList<RemoveContainerLocationsDto>
      notification.action('success').post('Removed ' + Utils.pluralize('location', 's', count) + ' from ' + container, 'Removed Locations from Container');
      messages.channel('edit-container').action('saved').post();
    });
  }

  findNode = (nodes, id) => {
    // recursively find node
    for (let i = 0; i < nodes.length; ++i) {
      let node = nodes[i];
      if (node.containerId === id) {
        return node;
      }
      if (node.containers) {
        let childNode = this.findNode(node.containers, id);
        if (childNode !== null) {
          return childNode;
        }
      }
    }
    return null;
  }

  buildContainerHierarchy = (containers) => {
    let hierarchy = [];
    let cloned    = Utils.clone(containers);
    let level     = 0;
  try{
      // Add all children
      while (cloned.length) {
        for (let j = cloned.length - 1; j >= 0; --j) {
          let container = cloned[j];
          if (container.level !== level) {
            continue;
          }

          if (container.parentContainerId === Utils.emptyGuid) {
            hierarchy.push(container);  // ... and populate it
            cloned.splice(j, 1);
          }
          else {
            let parentContainer = this.findNode(hierarchy, container.parentContainerId); // find the parent
            if (parentContainer !== null) {
              if (!parentContainer.containers) {
                parentContainer.containers = [];       // if the child collection doesn't exist yet, add it ...
              }
              parentContainer.containers.push(container);  // ... and populate it
              cloned.splice(j, 1);
            }
            else
            {
              cloned.splice(j, 1);
            }
          }
        }
        level++;
      }
      return hierarchy;
    }
    catch(ex)
    {
      console.log(ex);
    }
  }

  containerSearch = (selectedContainer) => {
    this.state.containers          = [];
    this.state.filteredAssets      = [];
    this.state.totalContainerCount = 0;
    this.state.totalAssetCount     = 0;
    if (!this.state.customer) {
      return;
    }

    co(this, function*() {
      const parameters = {
        customerId     : this.state.customer.id,
        searchTerm     : this.state.containerSearchText,
        showAllResults : this.state.showAllResults
      };
      this.state.isLoading           = true;
      var results                    = yield ajax.get('lookup/getCustomerContainers', parameters); // IList<CustomerContainerDto>
      this.state.totalContainerCount = yield ajax.get('lookup/getCustomerContainerCount', { customerId : this.state.customer.id }); // int
      this.state.isLoading           = false;
      this.state.containerMatchCount = results.length;
      results.sort((a, b) => b.description.localeCompare(a.description));
      this.state.containerList = results;
      this.state.containers = this.buildContainerHierarchy(results);
      this.state.useHierarchyData = true;

      if (results.length > 0) {
        if (selectedContainer) {
          var matchingContainers = results.filter(c => c.containerId === selectedContainer.containerId);
          if (matchingContainers.length) {
            this.selectContainer(matchingContainers[0]);
          }
        }
        else {
          this.selectContainer(this.state.containers[0]);
        }
      }
      else { this.state.canNewButton = true; }
      this.commit();
    });
  }

  moveContainer = () => {
    const selectedContainer = this.state.selectedContainer;
    if (!selectedContainer || !selectedContainer.hasAccess) {
      return;
    }
    const customer = this.state.customer;

    co(this, function*() {
      yield Dialog.showDialogWaitForResult(<MoveContainerDialog controller={new MoveContainerController(customer, selectedContainer)} header='Move Container' />);
    });
  }
  

  canSaveContainer = () => {
    this.state.errors={};
    this.commit();
    if (!this.state.customer) {
      notification.action('error').post('A customer must be selected in order to create a new container', 'No customer selected');
      return false;
    }
    this._validator = new Validator();
    this._validator.add('description', 'Container Description', [{required: true}]);   
    if(this.state.functionalLocation)
    {
      const test1 = new RegExp(/^[A-Z0-9]{4}-[0-9]{5}-[A-Z0-9]{1}-[A-Z0-9]{5}$/).test(this.state.functionalLocation);
      const test2 = new RegExp(/^[0-9]{2}-[0-9]{3}-[0-9]{5}$/).test(this.state.functionalLocation)
      if(!test1 && !test2){
        this.state.errors.functionalLocation = 'Functional Location is invalid'
        this.commit();
      }
    } 
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    this.commit();
    if (errors.length) {
      return false;
    }   
    this.commit();    
    return true;
  }

  saveContainer = () => {
            if (!this.canSaveContainer()) {
              return;
            }    
              const parameters = {
                customerId         : this.state.customer.id,
                containerId        : this.state.containerId,
                description        : this.state.description,
                functionalLocation : this.state.functionalLocation,
                plantCode          : this.state.plantCode,
                indicator          : this.state.indicator,
                category           : 'S'
              };
              ajax.post('container/save', parameters).then(result=>{
                notification.action('success').post(result, 'Saved Container');
                messages.channel('edit-container').action('saved').post();
              }).catch(err => {
                notification.action('error').post({msg: this.getErrorMessage(err.message), title: 'Container Save Error'});
                return false;
            }); // IN: EditCustomerContainerDto, OUT: void
  }

  getErrorMessage = (message) => {
    if (Utils.contains(message, 'Please enter')) {
      const messages = message.split('|');
      message = '<p>' + messages.join('</p><p>') + '</p>';
    }
    return message;
  }

  transferAssets = () => {
    const customer         = this.state.customer;
    const sourceContainer  = this.state.selectedContainer;
    const selectedAssetIds = this.getSelectedAssets().map(a => a.assetId);
    const assetCount       = selectedAssetIds.length;
    if (!assetCount) {
      return;
    }
    Dialog.showDialog(<TransferAssetsToContainerDialog controller={new TransferAssetsToContainerController(customer, sourceContainer, selectedAssetIds)} header='Transfer Assets to Container' />);
  }

  shipAssets = () => {
    const customer         = this.state.customer;
    const sourceContainer  = this.state.selectedContainer;
    const selectedAssetIds = this.getSelectedAssets().map(a => a.assetId);
    const assetCount       = selectedAssetIds.length;
    const selectedAssets   = this.getSelectedAssets();
    if (!assetCount) {
      return;
    }
    Dialog.showDialog(<ShipAssetsToContainerDialog controller={new ShipAssetsToContainerDialogController(
        customer, selectedAssetIds, this.state.containerId,selectedAssets)} header='Ship Assets to Container' />);
  }

  addAssetsToContainer = () => {
    const customer  = this.state.customer;
    const container = this.state.selectedContainer;

    Dialog.showDialog(<SelectAssetsForContainerDialog controller={new SelectAssetsForContainerController(customer, container)} header='Add Assets to Container' />);
  }

  addLocationsToContainer = () => {
    const customer  = this.state.customer;
    const container = this.state.selectedContainer;

    Dialog.showDialog(<SelectAuthorizedLocationsForContainerDialog controller={new SelectAuthorizedLocationsForContainerController(customer, container)} header='Add Authorized Locations to Container' />);
  }

  delete = (container) => {
    const message = <div>
                      <h5>Please confirm you wish to delete this Container/Functional Location</h5>
                  </div>;

    co(this, function*() {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={400} height={200} header='Delete Container/Functional Location'>
          {message}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }

      ajax.post('container/removeFunctionalLocation/' + container.containerId).then(()=>
      {
          notification.action('success').post('Functional location ' + container.functionalLocation + ' has been deleted' );
          this.containerSearch(this.state.selectedContainer);
      })
      .catch(err => {
          notification.action('error').post({msg: this.getErrorMessage(err.message), title: 'Delete container Error'});
          return false;
      }); 

      this.commit();
    });
  }

  validFunctionalLocation = () => {
    if(this.state.functionalLocation)
    {
      const test1 = new RegExp(/^[A-Z0-9]{4}-[0-9]{5}-[A-Z0-9]{1}-[A-Z0-9]{5}$/).test(this.state.functionalLocation);
      const test2 = new RegExp(/^[0-9]{2}-[0-9]{3}-[0-9]{5}$/).test(this.state.functionalLocation)
      return test1 || test2;
    }

    return false;
  }

  functionalLocationChanged = () =>{
    this.loadIndicatorValue();
  }

  loadPlantCode = () => { 
    const isSAPFacility = SAPService.isSAPFacility();
    if (isSAPFacility) {
      const facilityId = store.get('currentFacility').facilityId;
      this.state.currentPlantCode = '';
      this.commit();
        ajax.get('lookup/getPlantCodeForFacility', { facilityId: facilityId }).then(plantCode=>{
            this.state.currentPlantCode = plantCode;
            // this.state.plantCode=(this.state.plantCode?this.state.plantCode:plantCode);
            this.state.plantCode=plantCode;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }
  }

  loadIndicatorValue = () => {
    const isSAPFacility = SAPService.isSAPFacility();
    this.state.indicator = '';
    if (this.state.functionalLocation && isSAPFacility) {
      const test1 = new RegExp(/^[A-Z0-9]{4}-[0-9]{5}-[A-Z0-9]{1}-[A-Z0-9]{5}$/).test(this.state.functionalLocation);
      const test2 = new RegExp(/^[0-9]{2}-[0-9]{3}-[0-9]{5}$/).test(this.state.functionalLocation)
      if (test1 || test2) {
        this.state.indicator = test1 ? AppConstants.FunctionalLocationIndicator.z4 : AppConstants.FunctionalLocationIndicator.z9;
      }
    }
    this.commit();
  }
}

export default ContainerController;
