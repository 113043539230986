import React from 'react';
import jsQR from 'jsqr';
import { isChrome, isIOS } from 'react-device-detect';
import notifications from '../../services/Notification';
import Button from '../../components/Button';

class QRScanner extends React.Component {

    constructor(props) {
        super(props);
        this.isChromeIOS = isChrome && isIOS;
        this.isQRScanned = false;
        this.videoAvailable = true;
    }

    static defaultProps = {
        handleScan: () => { },
        handleQRInvalid: () => { }
    }


    initializeVideoInstance = () => {
        this.video = document.createElement('video');
    }

    getVideoInstance = () => {
        return this.video;
    }

    componentDidMount = () => {
        const isChromeIOS = this.isChromeIOS;
        if (!isChromeIOS) {
            this.initializeVideoInstance();
            const video = this.getVideoInstance();
            this.stream = navigator
                .mediaDevices
                .getUserMedia({
                    video: {
                        width: { ideal: 4096 },
                        height: { ideal: 2160 },
                        facingMode: 'environment'
                    }
                })
                .then((stream) => {
                    video.srcObject = stream;
                    video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
                    video.play();
                    this.animationFrame = window.requestAnimationFrame(this.tick);
                }).catch((err) => {
                });
        }
    }

    componentWillUnmount = () => {
        const isChromeIOS = this.isChromeIOS;
        if (!isChromeIOS) {
            window.cancelAnimationFrame(this.animationFrame);
            this.stopStreamedVideo(this.getVideoInstance());
        }
    }

    stopStreamedVideo = (video) => {
        if (video && video.srcObject) {
            const tracks = video.srcObject.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            video.srcObject = null;
        }
    }

    drawLine = (begin, end, color) => {
        const canvas = document.getElementById('qr-canvas').getContext('2d');
        canvas.beginPath();
        canvas.moveTo(begin.x, begin.y);
        canvas.lineTo(end.x, end.y);
        canvas.lineWidth = 4;
        canvas.strokeStyle = color;
        canvas.stroke();
    }

    tick = () => {
        const video = this.getVideoInstance();

        if (video) {
            var canvasElement = document.getElementById('qr-canvas');
            if (canvasElement) {
                let canvas = canvasElement.getContext('2d');
                let loadingMessage = document.getElementById('loadingMessage');
                loadingMessage.innerText = '⌛ Loading video...';
                if (video.readyState === video.HAVE_ENOUGH_DATA) {
                    loadingMessage.hidden = true;
                    canvas.drawImage(
                        video,
                        (video.videoWidth - canvasElement.width) / 2,
                        (video.videoHeight - canvasElement.height) / 2,
                        canvasElement.width,
                        canvasElement.height,
                        0,
                        0,
                        canvasElement.width,
                        canvasElement.height);
                    var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
                    var code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' });
                    if (code) {
                        this.processQRCode(code);
                        return;
                    }
                }
            }
            this.animationFrame = window.requestAnimationFrame(this.tick);
        }
    }

    processQRCode = (code) => {
        this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58');
        this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58');
        this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58');
        this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58');
        this.props.handleScan(code.data);
    }

    handleRescanClick = () => {
        this.props.handleScan('');
        window.requestAnimationFrame(this.tick);
    }

    getQRImage = (e) => {
        var file = e.target.files[0];
        if (file) {
            var canvasElement = document.getElementById('qr-canvas');
            var context = canvasElement.getContext('2d');
            var img = new Image();
            img.onload = () => {
                this.isQRScanned = true;
                context.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);
                var imageData = context.getImageData(0, 0, canvasElement.width, canvasElement.height);
                var code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' });
                if (code && code.data) {
                    this.processQRCode(code);
                    return;
                }
                else {
                    context.fillStyle = 'black';
                    context.fillRect(0, 0, canvasElement.width, canvasElement.height);
                    this.props.handleQRInvalid();
                }
            };
            img.src = URL.createObjectURL(file);
        }
        e.target.value = null;
    }

    render = () => {
        const canvasWidth = 320;
        const canvasHeight = 320;
        const qrScannerLabel = this.isQRScanned ? 'Rescan' : 'QR Scan';
        const qrScannerIcon = this.isQRScanned ? 'fa fa-refresh icon' : 'fa fa-qrcode icon';
        const scanner = !this.isChromeIOS
            ? <div className='qrscanner col-md-12'>
                <div className='text-center'>
                    <canvas id='qr-canvas' width={canvasWidth} height={canvasHeight} />
                    <div id='loadingMessage' className='qrscanner-message'>🎥 Unable to access video stream (please make sure you have a webcam enabled)</div>
                </div>
                <div className='text-center'>
                    <Button icon='fa-sync-alt' variant='primary' size='sm' onClick={this.handleRescanClick}>Rescan</Button>
                </div>
            </div>
            : <div className='col-md-12 qrscanner'>
                <div className='row'>
                    <canvas id='qr-canvas' width={canvasWidth} height={canvasHeight} />
                </div>
                <div className='row'>
                    <label id='qr-scan-ios-btn' className='btn btn-primary'>
                        <i className={qrScannerIcon} /> {qrScannerLabel} <input id='qrInput' type='file' accept='image/*' capture class='qr-scan-file-input' hidden onChange={this.getQRImage} />
                    </label>
                </div>
            </div>

        return (
            <div>
                {scanner}
            </div>
        );
    }
}

export default QRScanner;