import React from 'react';

const Disposable = (WrappedComponent) => {
  let disposable = [];
  return (props) => {
    function using() {
      Array.prototype.slice.call(arguments, 0).forEach((d) => {
        if (!d.dispose) {
          return;
        }

        delete d.isDisposed;
        disposable.push(d);
      });
    }

    const terminate = () => {
      for (var i = disposable.length - 1; i >= 0; i--) {
        var objToDispose = disposable[i];
        if (objToDispose && objToDispose.dispose && !objToDispose.isDisposed) {
          objToDispose.isDisposed = true;
          objToDispose.dispose();
        }
      }
      disposable = [];
    }

    return <WrappedComponent {...props} using={using} terminate={terminate} />;
  }
    
}

export default Disposable;