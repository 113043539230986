import React, { useMemo, useEffect } from 'react';
import Form from '../../components/Form';
import ActionIcon from '../../components/ActionIcon';
import Button from '../../components/Button';
import SearchController from '../controllers/SearchController';
import Search from '../components/Search';

class ManufacturerDescriptionLookup extends React.Component {

  //   constructor(props, context) {
  //     super(props, context);

  //     Object.assign(this, Controller);
  // }

  // componentDidMount = () => {
  //     this.initializeMixin();          
  // }

  static defaultProps = {
    controller: new SearchController()
  }

  handleKeyboardSearch = (evt) => {
    if (evt.which === 13) {
      this.handleSearch(evt);
    }
  }

  handleSearch = (evt) => {
    evt.preventDefault();
    this.props.controller.handleManDescSearch(this.props.searchResultsController.state, this.props.searchResultsController);
  }

  clearSearch = () => {
    this.props.controller.clearSearch();
  }

  clearSearchCriteria = () => {
    this.props.controller.clearManDescSearchCriteria(this.props.searchResultsController.state, this.props.searchResultsController);
  }

  selectRow = (item) => {
    this.props.controller.selectItem(item);
  }

  render = () => {
    const binder = this.props.binder;
    const controller = this.props.controller;
    const searchLabel = <div><span>Enter search text</span><span className='field-hint font-italic' style={{ fontSize: '0.799rem', fontWeight: '400'}}>&nbsp;&nbsp;Use commas to separate multiple criteria</span></div>

    return (
      <div id='search-criteria'>
        <div className='row'>
          <div className='search-container col-md-8'>
            <div className='' onKeyDown={this.handleKeyboardSearch} >
            <Search className='mb-2' id='searchText' inputClassName='search-input' label={searchLabel} placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} clear={this.clearSearch} showSearchButton={false}></Search>
              {/* <h5><i className='fa fa-search' />&nbsp;&nbsp;Enter search text&nbsp;&nbsp;</h5><span className='field-hint font-italic'>Use commas to separate multiple criteria</span> 
              <Form.Input id='searchText' label={searchLabel} prependLabelIcon='fa-search' className='search-input' placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} />
              <ActionIcon icon='fa-times-circle' className='fa-2x clearable' action={this.clearSearch} />*/}
            </div>
          </div>
          <div className='col-md-4 form-group mt-3'>
            <div>&nbsp;</div>
            <span style={{ cursor: 'pointer' }}><a className='simple-link' onClick={this.clearSearchCriteria}>Clear search criteria</a></span>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-3'><Form.AutoComplete label='Family Code' bind={binder.bind('familyCode')} search={controller.search('family code')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='Nominal Size' bind={binder.bind('nominalSize')} search={controller.search('nominal size')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='Length' bind={binder.bind('length')} search={controller.search('length')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='Style' bind={binder.bind('style')} search={controller.search('style')} /></div>
        </div>

        <div className='row'>
          <div className='col-md-3'><Form.AutoComplete label='Manufacturer' bind={binder.bind('manufacturer')} search={controller.search('manufacturer')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={controller.search('cwp')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='Service' bind={binder.bind('service')} search={controller.search('service')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='Degree' bind={binder.bind('degree')} search={controller.search('degree')} /></div>
        </div>

        <div className='row'>
          <div className='col-md-3'><Form.AutoComplete label='ID' bind={binder.bind('id')} search={controller.search('id')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='OD' bind={binder.bind('od')} search={controller.search('od')} /></div>
          <div className='col-md-3'><Form.AutoComplete label='Mfg Part #' bind={binder.bind('mfgItemNo')} search={controller.mfgItemSearch()} /></div>
          <div className='col-md-3 mt-4'>
            <Button icon='fa-search' variant='info' size='sm' className='btn-block' onClick={this.handleSearch}>Search</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ManufacturerDescriptionLookup;
