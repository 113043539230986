import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import moment from 'moment';
import Validator from '../../lib/Validator';

class VendorReportsController extends BaseController {
  constructor() {
    super('reports', {
      serialNo: '',
      reportFormat: AppConstants.ReportFormat[0],
      reportList: AppConstants.ReportList[0],
      reportName: 'Reports/assetcertification',
      selectedCustomerName: '',
      selectedLocation: '',
      selectedItems: [],
      startDate: '',
      endDate: '',
      errors: {},
      certs: []
    });

    // this._validator = new Validator();
    // this._validator.add('selectedCustomerName', 'Customer Name', [{ required: true }]);
    // this._validator.add('serialNo', 'Serial', [{ required: true }]);
  }

  clear = () => {
    this.state.serialNo = '';
    this.state.selectedCustomerName = '';
    this.state.selectedLocation = '';
    this.state.startDate = '';
    this.state.endDate = '';
    this.state.errors = {};
    this.state.certs = [];
    this.commit();
  }

  validate = () => {
    if (this.state.startDate) {
      const startDate = moment(this.state.startDate);
      this._validator.add('endDate', 'End date must be greater than Start date.', [{ isGreaterThanEqualsDate: { params: startDate } }]);
    }
    if (this.state.endDate) {
      const endDate = moment(this.state.endDate);
      this._validator.add('startDate', 'Start date must be lesser than End date.', [{ isLessThanEqualsDate: { params: endDate } }]);
    }
    this._validator.validateAll(this.state);
    var errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }


  viewFile = (certId) => {
    if (certId) {
      this.state.cert = {};
      this.loading = true;
      ajax.get('customercert/getcertificate/', { certId: certId }).then(results => {
        this.loading = false;
        if (results !== null) {
          let fType = results.files[0].fileType;
          let fName = results.files[0].fileName;
          let b64String = results.files[0].imgUrl;
          let b64File = b64String.split(';base64,')[1];
          var blob;
          if (b64File) {
            blob = Utils.convertBase64toBlob(b64File, fType);
          }
          else {
            blob = Utils.convertBase64toBlob(b64String, fType);
          }
          var blobUrl = URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = blobUrl;
          a.download = fName;
          a.click();
          URL.revokeObjectURL(blobUrl);
        }
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });
    }
  }

  getReport = () => {
    this._validator = new Validator();
    this._validator.add('selectedCustomerName', 'Customer Name', [{ required: true }]);
    this._validator.add('serialNo', 'Serial No', [{ required: true }]);
    this._validator.add('selectedLocation', 'Current Location', [{ required: true }]);
    this._validator.add('startDate', 'Start Date', [{ required: true }]);
    this._validator.add('endDate', 'End Date', [{ required: true }]);

    if (!this.validate()) {
      return false;
    }
    ajax.get('lookup/getVendorCertReport',
      {
        serialPrime: this.state.serialNo,
        customerId: this.state.selectedCustomerName ? this.state.selectedCustomerName.id : '',
        locationId: this.state.selectedLocation ? this.state.selectedLocation.id : '',
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }).then(results => {
        if (results !== null) {
          this.state.certs = results;
          this.commit();
        }
        return true;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  viewCertFile = (certId) => {
    if (certId) {
      this.state.cert = {};
      this.state.loading = true;
      ajax.get('customercert/getcertificate/', { certId: certId }).then(results => {
        this.state.loading = false;
        if (results !== null) {
          let fType = results.files[0].fileType;
          let fName = results.files[0].fileName;
          let b64String = results.files[0].imgUrl;
          let b64File = Utils.contains(b64String, ';base64,') ? b64String.split(';base64,')[1] : b64String;
          let applicationType = 'plain/text';
          switch (fType) {
            case 'txt':
              applicationType = 'plain/text'; break;
            case 'pdf':
            case '.pdf':
              applicationType = 'application/pdf'; break;
            case 'gif':
              applicationType = 'image/gif'; break;
            case 'jpg':
            case 'jpeg':
            case 'png':
              applicationType = 'image/jpg'; break;
          }
          if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/Chrome/i) != null) {
            const blob = Utils.convertBase64toBlob(b64File, applicationType);
            const fileName = fName;
            const fileURL = URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.download = fileName;
            tempLink.click();
            tempLink.remove();
          } else {
            let file = Utils.convertBase64toBlob(b64File, applicationType);
            let fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });
    }
  }

  selectedCustomerNameChanged = () => {
    this.state.selectedLocation = '';
    this.commit();
  }
}

export default VendorReportsController;