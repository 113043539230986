import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Utils from '../utility/Utils';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Table from '../../components/Table';

class DropOffTimeRow extends React.Component{
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        this.binder = new Binder(this);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    
   render = () => {
    const controller         = this.props.controller;
    const transDate=DateTimeFormatter.formatDate(controller.state.transactionDate)  
    const hours   = Utils.convertMinsToHrsMins(controller.state.tvalue);
    const minutes = parseFloat(controller.state.tvalue).toFixed(1); 
    const final=hours + ' / ' + minutes + ' min';
    const fldTechnician = <span>{controller.state.technician} </span>
    const fldValue = <span>{final} </span>
    const fldDate = <span>{transDate} </span>
    const fldJob = <span>{controller.state.tjobNumber} </span>
    return (
        <Table.Row>
          <Table.Data className='col-location'>{fldJob}</Table.Data>
          <Table.Data className='col-location'>{fldDate}</Table.Data>
          <Table.Data className='col-torqueRange'>{fldTechnician}</Table.Data>
          <Table.Data className='col-actualReading' >{fldValue}</Table.Data>         
        </Table.Row>
    );
  }
}

export default DropOffTimeRow;