import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class ComponentUpdateRowController extends BaseController {
  constructor(parentController, componentvalues, Flag) {
    super('componentvalue-row-controller-' + ComponentUpdateRowController.key++, {
      Orderid: componentvalues ? componentvalues.Orderid : '',
      Operation: componentvalues ? componentvalues.Operation : '',
      Posnr: componentvalues ? Utils.pad(componentvalues.Posnr, 4) : '',
      Matnr: componentvalues ? componentvalues.Matnr : '',
      Matxt: componentvalues ? componentvalues.Matxt : '',
      Menge: componentvalues ? Number(componentvalues.Menge) || '0' : '0',
      Enmng: componentvalues ? Number(componentvalues.Enmng) : '',
      Werks: componentvalues ? componentvalues.Werks : '',
      errors: {},
      flag: Flag,
      XLOEK: componentvalues ? componentvalues.XLOEK : '',
    });

    this.parentController = parentController;
    this._validator = new Validator();
    this._validator.add('Matnr', 'Part Number', [{ required: true }]);
    this._validator.add('Posnr', 'Position', [{ required: true }]);
    this._validator.add('Menge', 'Reserved', [{ required: true }]);
  }

  static key = 1;


  removeRow = () => {
    this.parentController.removeRow(this);
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

}

export default ComponentUpdateRowController;