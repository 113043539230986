import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';

class EditFieldAssetController extends BaseController {
  constructor() {
    super('edit-field-asset', EditFieldAssetController.getInitialState(''));

    this._validator = new Validator();
    this._validator.add('primarySerialNumber', 'Primary Serial #', [{ required: true }]);
    this._validator.add('bornOnDate', 'Born On Date', [{ required: true }]);
  }

  static getInitialState = () => {
    return {
      assetId: '',
      isTPRS: false,
      isHalliburton: false,
      primarySerialNumber: '',
      secondarySerialNumber: '',
      batchNumber: '',
      equipmentNumber: '',
      eAssetStatus: '',
      bornOnDate: '',
      inServiceDate: '',
      notes: '',
      removeFromContainer: '',
      errors: {},
      customFields: [],
      isSaving: false,
      currentLocationId: '',
      containerId: ''
    };
  }

  getCustomFields = () => {
    const customer = this.state.customer;
    if (!customer || !customer.id) {
      return null;
    }

    const customerId = customer.id.toUpperCase();
    if (customerId === Utils.emptyGuid) {
      return null;
    }

    // if (customerId === '5CF7A2BD-D6E6-4261-8B57-37CDE13DE831') { // Schlumberger Well Services
    //   return [
    //     { type: 'autocomplete', className : 'required', label : 'Zone',                   name : 'zone',              search : 'SchlumbergerZone' },
    //     { type: 'autocomplete', className : '',         label : 'Volume (Sand)',          name : 'volume',            search : 'volume'           },
    //     { type: 'input',        className : '',         label : 'Volume (Sand) Quantity', name : 'volumeQuantity',    search : ''                 },
    //     { type: 'autocomplete', className : '',         label : 'Acid Type',              name : 'acidType',          search : 'acidType'         },
    //     { type: 'input',        className : '',         label : 'Acid Concentration',     name : 'acidConcentration', search : ''                 },
    //     { type: 'autocomplete', className : 'required', label : 'Pressure',               name : 'pressure',          search : 'cwp'              },
    //     { type: 'checkbox',     className : '',         label : 'Biocide Present',        name : 'biocidePresent',    search : ''                 },
    //     { type: 'checkbox',     className : '',         label : 'Active',                 name : 'status',            search : ''                 }
    //   ];
    // }

    // if (customerId === 'E103516A-7EC2-46D1-B2D5-71F65129CDFA') { // Halliburton
    //   return [
    //     { type: 'input',        className : 'required', label : 'Halliburton Field #1',     name : 'field1', search : ''      },
    //     { type: 'autocomplete', className : '',         label : 'Halliburton Style Lookup', name : 'style',  search : 'style' },
    //     { type: 'checkbox',     className : '',         label : 'Active',                   name : 'status', search : ''      }
    //   ];
    // }

    return null;
  }

  searchLookupList = (searchType) => {
    return (searchTerm) => ajax.get('lookup/searchLookupList' + searchType, {
      searchTerm: searchTerm
    }).then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  load = (assetId) => {
    ajax.get('lookup/getAsset/', { assetId: assetId }).then(results => {
      // var customFields = yield ajax.get('lookup/getCustomFields', { assetId: assetId }); // or include results in previous call?
      //this.mergeState(this.getInitialState());
      this.mergeState(results);
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // ReceivedAssetDto
  }

  has2RfidTags = () => {
    return this.state.isHalliburton;
  }

  isTPRS = () => {
    return this.state.isTPRS;
  }
  canRetrieveEquipmentNumber = () => {
    return (!this.state.equipmentNumber && (this.state.eAssetStatus !== AppConstants.eAssetStatus.Scrapped) && SAPService.isSAPFacility());
  }
  mergeState = (state) => {
    this.state.assetId = state.assetId;
    this.state.isHalliburton = state.isHalliburton;
    this.state.isTPRS = state.isTPRS;
    this.state.customer = state.customer;
    this.state.description = state.description;
    this.state.mfgPartNumber = state.mfgPartNumber;
    this.state.primarySerialNumber = state.primarySerialNumber;
    this.state.secondarySerialNumber = state.secondarySerialNumber;
    this.state.batchNumber = state.batchNumber;
    this.state.equipmentNumber = state.equipmentNumber;
    this.state.eAssetStatus = state.eAssetStatus;
    this.state.status = state.status;
    this.state.assetStatus = { id: state.eAssetStatus, name: state.status };
    this.state.rejectReason = state.rejectReason;
    this.state.bornOnDate = LoginService.isCustomer ? state.bornOnDate : DateTimeFormatter.formatDropdownDate(state.bornOnDate);
    this.state.inServiceDate = state.inServiceDate;
    this.state.notes = state.notes;
    this.state.removeFromContainer = false;
    this.state.errors = {};
    this.state.customFields = [];
    this.state.currentLocationId = state.currentLocation.id;
    this.state.containerId = state.container.id;
  }

  save = (cb) => {
    if (!this.canSave()) {
      return false;
    }

    const asset = this.state;
    const parameters = {
      assetId: asset.assetId,
      plantCode: asset.plantCode,
      primarySerialNumber: asset.primarySerialNumber,
      secondarySerialNumber: asset.secondarySerialNumber,
      batchNumber: asset.batchNumber,
      equipmentNumber: asset.equipmentNumber,
      bornOnDate: asset.bornOnDate,
      inServiceDate: asset.inServiceDate, // not updated - only used for validation
      notes: asset.notes,
      currentLocationId: asset.currentLocationId,
      containerId: asset.containerId,
      eAssetStatus: asset.eAssetStatus
    };

    this.state.isSaving = true;
    this.commit();
    ajax.post('receiving/updateAssetIronManagement', parameters).then(results => {
      this.state.isSaving = false;
      this.commit();
      notification.action('success').post('Saved changes for asset ' + results.primarySerialNumber);
      messages.channel('edit-asset').action('saved').post(results);
      cb();
    }).catch(err => {
      this.state.isSaving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in/out: ReceivedAssetDto
  }
}

export default EditFieldAssetController;