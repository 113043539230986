import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class ReceivingTimeRow extends React.Component{
    componentWillMount() {
        this.binder = new Binder(this);
      }        
    
      render() {
        var controller         = this.props.controller;
        var transDate=DateTimeFormatter.formatDate(controller.state.transactionDate)   
    
        var hours   = Utils.convertMinsToHrsMins(controller.state.tvalue);
        var minutes = parseFloat(controller.state.tvalue).toFixed(1);
     
        var final=hours + ' / ' + minutes + ' min';
        var fldTechnician = <span>{controller.state.technician} </span>
        var fldValue = <span>{final} </span>
        var fldDate = <span>{transDate} </span>
        return (
            <tr>
              <td className='col-location'>{fldDate}</td>
              <td className='col-torqueRange'>{fldTechnician}</td>
              <td className='col-actualReading' >{fldValue}</td>
            </tr>
        );
      }
}
export default ReceivingTimeRow;
