import React from 'react';
import Table from '../../components/Table';

const WallReadingRow = (props) => {
  const item = props.item;

  return (
    <Table.Row>
      <Table.Data>{item.readingId}</Table.Data>
      <Table.Data>{item.newDimension}</Table.Data>
      <Table.Data>{item.minimumDimension}</Table.Data>
    </Table.Row>
  );
}

const ManufacturerWallReadings = (props) => {

  var data = props.wallReadings || [];
  var rows = data.map(wr => <WallReadingRow key={wr.manDescReadingId} item={wr} />);
  var addRow = null;
  var divClassName = 'col-md-12';

  return (
    <div id='wall-readings' className={divClassName} style={{ marginTop: '10px' }}>
      <Table id='results-table'>
        <Table.Header>
          <Table.Row>
            <Table.Head>ID</Table.Head>
            <Table.Head>New Dimension</Table.Head>
            <Table.Head>Minimum Dimension</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
        <Table.Footer>
          {addRow}
        </Table.Footer>
      </Table>
    </div>
  );
}

export default ManufacturerWallReadings;
