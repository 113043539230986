import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import SAPService from '../services/SAPService';

class AssignAssetsToJobDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  isExistingJob = () => {
    return (this.props.controller.state.existingJob); // return false if New Job or not specified yet
  }
  handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  handleKeyUp = (evt) => {
    try {
      var value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }
      if (value > 100000) {
        evt.target.value = 100000;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const canRemoveFromContainer = controller.state.assets.reduce((acc, curr) => {
      return acc || (curr.containerId !== Utils.emptyGuid);
    }, false);

    const removeFromContainer = canRemoveFromContainer
      ? <div className='col-md-6'>
        <Form.CheckBox label='Remove from Container' bind={binder.bind('removeFromContainer')} />
      </div>
      : null;
    const groupSelection = this.isExistingJob()
      ? <div className="col-md-6">
        <div className='row'>
          <div className='col-md-4'>
            <Form.AutoComplete label='Group #' dataToggle="tooltip" dataPlacement="top" title="Leave blank to auto-assign" bind={binder.bind('groupNumber')} search={controller.groupNumberSearch()} minimumResultsForSearch={-1} />
          </div>
          <div className='col-md-8'>
            <Form.Input label='Group Name' bind={binder.bind('groupName')} maxLength="30" />
          </div>
        </div>
      </div>
      : <div className='col-md-6'>
        <Form.Input label='Group Name' bind={binder.bind('groupName')} maxLength="30" />
      </div>;
    //var receivingCostCenter = <div className='col-lg-12'><Form.AutoComplete label='Cost Center' dataToggle="tooltip" dataPlacement="top" bind={binder.bind('costCenter')} search={Searchers.searchReceivngCostCenter(controller.state.facilityId)} minimumResultsForSearch={-1}/></div>
    //var height              = (canRemoveFromContainer || groupSelection) ? 640 : 575;
    const verificationMessage = 'Upon receiving these items, ' + LoginService.loginInfo.fullName + ' acknowledges verification has taken place and all information is correct';
    const LoggedInuser = LoginService.loginInfo.fullName;
    const fldHours = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} /> : null;
    const fldMinutes = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} /> : null;
    const fldTechnician = SAPService.isSAPFacility() && SAPService.useTTS() ? <Form.StaticData label='Record Receiving Time for User' value={LoggedInuser} /> : null;
    const length = controller.state.assets.length;
    const header = 'Assigning ' + Utils.pluralize('Asset', 's', length) + ' to Job';

    return (
      <Dialog {...this.props} allowKeyDownEvent={true} header={header}
        width={750} height={500} id='assign-assets-to-job-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='assign-assets-to-job-dlg-content'>
            <div id='choose-jobs' className='row'>
              {/* <div className='form-group'> */}
              <div className='col-lg-12'>
                <Form.AutoComplete label='Existing Jobs' bind={binder.bind('existingJob')} search={controller.existingJobSearch()} />
                <Form.AutoComplete label='New Jobs' bind={binder.bind('newJob')} search={controller.newJobSearch()} />
              </div>
            </div>
            {/* </div> */}

            <div id='job-details' className='row'>
              <div className='col-md-6'>
                <Form.Input label='Customer Job #' bind={binder.bind('customerJobNumber')} />
              </div>

              <div className='col-md-6'>
                <Form.Input label='Customer PO' bind={binder.bind('customerPO')} />
              </div>
            </div>

            <div className='row form-group'>
              <div className='col-md-6'>
                <Form.StaticData label='Customer Requested Date' value={binder.bind('requiredDate')()} />
              </div>

              <div className='col-md-6'>
                <Form.AutoComplete label='Receiving Location' id='receivingLocation' className='required' error={controller.state.errors.receivingLocation} bind={binder.bind('receivingLocation')} search={controller.receivingLocationSearch()} />
              </div>
            </div>

            {/* <div className='row'>
                  {receivingCostCenter}
                </div> */}

            {(canRemoveFromContainer || groupSelection) &&
              <div className='row'>
                <div className='col-md-12 form-group'>
                  <div className='row'>
                    {removeFromContainer}
                    {groupSelection}
                  </div>
                </div>
              </div>
            }
            <div className='row form-group'>
              <div className='col-md-6'>{fldTechnician}</div>
              {/* <div className='col-lg-2'>{fldHours}</div> */}
              <div className='col-md-2'>{fldMinutes}</div>
            </div>

            <div className='row form-group'>
              <div className='col-md-6'>
                <Form.TextArea rows={5} label='Job Description' bind={binder.bind('jobDescription')} />
              </div>

              <div className='col-md-6'>
                <Form.TextArea rows={5} label='Job Notes' bind={binder.bind('jobNotes')} />
              </div>
            </div>

            <div>
              <span className='h6 verification-message'>{verificationMessage}</span>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={controller.saving} />
      </Dialog>
    );
  }
}

export default AssignAssetsToJobDialog;