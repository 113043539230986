import React from 'react';
import Form from '../../components/Form';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import SAPService from '../services/SAPService';

const AssetManagementTab = (props) => {

  var binder = props.binder;
  var controller = props.controller;
  var customer = binder.bind('customer')();
  var customerLocation = binder.bind('customerLocation')();
  var valueStyle = { border: 'solid 2px goldenrod' };
  var customerHint = LoginService.isCustomer
    ? ''
    : 'requires customer selection';
  var formatResult = item => {
    var spanFMCLocation = item.result.fmcLocation
      ? <span className='fmc-location pull-right select2-tag'>&nbsp;&nbsp;TechnipFMC</span>
      : null;
    var iconArchived = item.result.archived
      ? <i className='fa fa-archive archived pull-right select2-tag' />
      : null;
    return <span>{item.text} {iconArchived} {spanFMCLocation}</span>;
  };
  const defaultGUID = Utils.emptyGuid;
  const ctlCustomer = LoginService.isCustomer
    ? <div className='row'><div className='col'><Form.StaticData label='Customer' value={customer && customer.name} /></div></div>
    : <div className='row'><div className='col'><Form.AutoComplete label='Customer' bind={binder.bind('customer')} search={Searchers.customerSearch()} valueStyle={valueStyle} error={controller.state.errors.customer} /></div></div>;

  const handleOnKeyPress = (evt) => {
    var invalidChars = /[^0-9\n]/gi
    if (invalidChars.test(evt.target.value)) {
      evt.target.value = evt.target.value.replace(invalidChars, "");
    }
  }

  const jobNumberPropsBase = {
    label: 'Job Numbers',
    bind: binder.bind('jobNumbers'),
    hint: customerHint,
    id: 'jobNumbers'
  }

  const jobNumberProps = {
    className: 'jobNumber-select',
    search: Searchers.reportsJobNumberSearch({ customerId: customer ? customer.id : defaultGUID, facilityId: LoginService.loginInfo ? LoginService.loginInfo.facilityId : defaultGUID, locationId: customerLocation ? customerLocation.id : defaultGUID }),
    valueStyle: valueStyle,
    formatResult: formatResult,
    formatSelection: formatResult,
    multiple: true,
    ...jobNumberPropsBase
  };
  const awoNumberPropsBase = {
    label: 'AWO Numbers',
    bind: binder.bind('awoNumbers'),
    hint: customerHint,
    id: 'awoNumbers'
  }
  const awoNumberProps = {
    className: 'awoNumber-select',
    search: Searchers.searchAwoByOwner(customer ? customer.id : defaultGUID, LoginService.loginInfo ? LoginService.loginInfo.facilityId : defaultGUID),
    valueStyle: valueStyle,
    formatResult: formatResult,
    formatSelection: formatResult,
    multiple: true,
    ...awoNumberPropsBase

  };
  return (
    <div className='col mb-2 pt-2' style={{ backgroundColor: 'aliceBlue', borderRadius: '10px', border: 'solid 1px lightgrey' }}>
      {ctlCustomer}
      <div className='row'><div className='col'><Form.Input label='Maint Plant' bind={binder.bind('plantCode')} hint='allows partial matches' /></div></div>
      <div className='row'><div className='col multiselect-input'><Form.TextArea onInput={evt => handleOnKeyPress(evt)} className='multivalue-text' label='Equipment Number' valueStyle={valueStyle} bind={binder.bind('equipmentNumber')} hint='allows multiple values' /></div></div>
      <div className='row'><div className='col multiselect-input' onInput={props.handleInput}><Form.TextArea className='multivalue-text' label='Primary Serial Number' valueStyle={valueStyle} bind={binder.bind('primarySerialNumber')} hint='allows multiple values' prependLabelIcon='fa-rss' /></div></div>
      <div className='row'><div className='col multiselect-input'><Form.MultiSelect className='input-select' label='User Status' valueStyle={valueStyle} formatResult={formatResult} formatSelection={formatResult} bind={binder.bind('equipmentsStatus')} hint='allows multiple selection' search={Searchers.equipmentStatusSearch()} /></div></div>
      <div className='row'><div className='col multiselect-input'><Form.MultiSelect className='input-select' label='Material Number' bind={binder.bind('mfgItemNos')} valueStyle={valueStyle} formatResult={formatResult} formatSelection={formatResult} hint='allows multiple selection' search={Searchers.searchAssetManagementMfgParts()} /></div></div>
      {props.page == 'Asset Mgmt' &&
        <div className='row'><div className='col' onKeyDown={props.handleSearch}><Form.Input label='Description' valueStyle={valueStyle} bind={binder.bind('manufacturerDescriptionTokens')} hint='specify portions of manufacturer description to filter results' placeholder='use commas to separate multiple search criteria' /></div></div>
      }
      <div className='row'><div className='col'><Form.AutoComplete label='Current Location' bind={binder.bind('currentLocation')} search={Searchers.customerLocationSearch(customer ? customer.id : Utils.emptyGuid)} hint={customerHint} valueStyle={valueStyle} enabled={customer} formatResult={formatResult} formatSelection={formatResult} /></div></div>
      <div className='row'><div className='col' onKeyDown={props.handleSearch}><Form.Input bind={binder.bind('container')} hint='allows partial matches' valueStyle={valueStyle} label='Container' /></div></div>
      <div className='row'><div className='col'><Form.Input label='Functional Location' bind={binder.bind('functionalLocation')} valueStyle={valueStyle} formatResult={formatResult} formatSelection={formatResult} hint='allows partial matches' /></div></div>
      <div className='row'><div className='col job-number-muliselect-input'>{customer ? <Form.AutoComplete {...jobNumberProps} /> : <Form.Input {...jobNumberPropsBase} disabled={true} />}</div></div>
      <div className='row'><div className='col job-number-muliselect-input'>{customer ? <Form.AutoComplete {...awoNumberProps} /> : <Form.Input {...awoNumberPropsBase} disabled={true} />}</div></div>
      
      <div className='row'><div className='col multiselect-input'><Form.MultiSelect className='input-select' label='Object Type' valueStyle={valueStyle} formatResult={formatResult} formatSelection={formatResult} bind={binder.bind('objectType')} search={Searchers.objectTypeSearch()} hint='allows multiple selection' /></div></div>
      {props.page == 'Asset Mgmt' &&
        <div>
          <div className='row'>
            <div className='col'><Form.AutoComplete label='Family Code' bind={binder.bind('familyCode')} search={Searchers.listSearch('family code')} /></div>
          </div>
          <div className='row'>
            <div className='col-md-6'><Form.AutoComplete label='Nominal Size' bind={binder.bind('nominalSize')} search={Searchers.listSearch('nominal size')} /></div>
          </div>
        </div>
      }
      <div className='row'><div className='col'><Form.Input label='Cost Center' bind={binder.bind('costCenter')} hint='allows partial matches' /></div></div>
      <div className='row'><div className='col multiselect-input'><Form.TextArea className='multivalue-text' label='Asset Number' bind={binder.bind('assetNumber')} hint='allows multiple values' /></div></div>
      <div className='row'><div className='col'><Form.NumberInput bind={binder.bind('shippingTicket')} hint='whole number only' label='Shipping Ticket #' /></div></div>
      <div className='row'>
        <div className='col-md-6'><Form.Date className='reduced-text-area' label='Cycle Range From' bind={binder.bind('cycleFromDate')} error={controller.state.errors.cycleFromDate} options={{ endDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()) }} /></div>
        <div className='col-md-6'><Form.Date className='reduced-text-area' label='Cycle Range To' bind={binder.bind('cycleToDate')} error={controller.state.errors.cycleToDate} options={{ endDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()) }} /></div>
      </div>
    </div>
  );
}

export default AssetManagementTab;
