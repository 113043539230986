import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';


class EditThirdPartyJobNumberDialog extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render = () => {
    const binder = new Binder(this);
    return (
      <Dialog {...this.props} header={this.props.header} width={500} height={220} id='edit-third-party-job-number-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div className='col-sm-12' style={{ marginTop: '20px' }}>
              <Form.Input focus={true} label='3rd Party Job Number' bind={binder.bind('thirdPartyJobNumber')} />
            </div>
          </form>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
      </Dialog>
    );
  }
}

export default EditThirdPartyJobNumberDialog;
