import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import SAPService from '../services/SAPService';
import LoginService from '../services/LoginService';

class ShippingTicketDetailController extends BaseController {
    constructor() {
        super('shipping-ticket', ShippingTicketDetailController.getInitialState(''));

        this._validator = new Validator();
        this._validator.add('signedByCustomerName', 'Customer Name', [{ required: true }]);
        this._validator.add('signedByCustomerSignature', 'Customer Signature', [{ required: true }]);

        messages.channel('login').action('loggedin').subscribe(data => {
            if (!data) {
                this.clearData();
            }
        });

        messages.channel('shipping-ticket').action('saved').subscribe(data => {
            if (data && data.shippingTicketId) {
                this.loadTicket(data.shippingTicketId);
            }
        });

        messages.channel('change-facility').action('changed').subscribe(() => {
            this.clearData();
            this.commit();
        });
    }

    static getInitialState = () => {
        return {
            shippingTicketId: '',
            ticketNumber: '',
            facilityId: '',
            shippedDate: '',
            shippingID: '',
            shipToLocationId: '',
            container: '',
            shipToLocationName: '',
            notes: '',
            systemNotes: '',
            jobId: '',
            jobNumber: '',
            customerId: '',
            customerName: '',
            customerPoNumber: '',
            customerJobNumber: '',
            shippedByName: '',
            signedByTechnicianName: '',
            signedByTechnicianSignature: '',
            signedByTechnicianTimestamp: '',
            signedByCustomerName: '',
            signedByCustomerSignature: '',
            signedByCustomerTimestamp: '',
            assetCount: 0,
            customerLocation: '',
            shippingFacility: '',
            assets: [],
            errors: {},
            editingNote: false,
            cacheNotes: '',
            isGeneratingReport: false,
            uploadInProgress: false,
            deliveryTicket: '',
            isADNOCCustomer: false,
            updatedAssetNotes: [],
        };
    }

    merge = (ticket) => {
        if (!ticket) {
            return;
        }

        this.state.shippingTicketId = ticket.shippingTicketId;
        this.state.ticketNumber = ticket.ticketNumber;
        this.state.facilityId = ticket.facilityId;
        this.state.shippedDate = ticket.shippedDate;
        this.state.shippingID = ticket.shippingID;
        this.state.shipToLocationId = ticket.shipToLocationId;
        this.state.container = ticket.container;
        this.state.shipToLocationName = ticket.shipToLocationName;
        this.state.notes = ticket.notes;
        this.state.systemNotes = ticket.systemNotes;
        this.state.datePODUploaded = ticket.datePODUploaded;
        this.state.jobId = ticket.jobId;
        this.state.jobNumber = ticket.jobNumber;
        this.state.customerId = ticket.customerId;
        this.state.customerName = ticket.customerName;
        this.state.customerPoNumber = ticket.customerPoNumber;
        this.state.customerJobNumber = ticket.customerJobNumber;
        this.state.shippedByName = ticket.shippedByName;
        this.state.signedByTechnicianName = ticket.signedByTechnicianName;
        this.state.signedByTechnicianSignature = ticket.signedByTechnicianSignature;
        this.state.signedByTechnicianTimestamp = ticket.signedByTechnicianTimestamp;
        this.state.signedByCustomerName = ticket.signedByCustomerName;
        this.state.signedByCustomerSignature = ticket.signedByCustomerSignature;
        this.state.signedByCustomerTimestamp = ticket.signedByCustomerTimestamp;
        this.state.assetCount = ticket.assetCount;
        this.state.customerLocation = ticket.customerLocation;
        this.state.shippingFacility = ticket.shippingFacility;
        this.state.assets = ticket.assets;
        this.state.errors = {};
        this.state.cacheNotes = '';
        this.state.deliveryTicket = ticket.deliveryTicket;
        this.state.isADNOCCustomer = ticket.isADNOCCustomer;
        this.state.jobDescription = ticket.jobDescription;
        if (this.state.assets.length > 0) {
            this.updateState();
        }
        this.commit();
    }

    clearData = () => {
        this.state = ShippingTicketDetailController.getInitialState();
    }

    updateSignature = (signature) => {
        this.state.signedByCustomerSignature = signature;
        this.commit();
    }

    clearCustomerSignature = () => {
        this.state.signedByCustomerSignature = '';
        this.commit();
    }

    loadTicket = (ticketId) => {
        if (ticketId) {
            this.clearData();
            this.commit();
            this.loading = true;
            ajax.get('lookup/getShippingTicket/', { ticketId: ticketId }).then(result => {
                this.loading = false;
                if (result !== null) {
                    this.merge(result);
                    this.commit();
                }
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
        }
    }


    canSave = () => {
        this._validator.validateAll(this.state);
        const errors = Object.keys(this.state.errors);
        if (!errors.length) {
            return true;
        }

        this.commit();
        return false;
    }

    save = () => {
          if (!this.canSave()) {
            return false;
        }
        const ticket = this.state;
        const shippingTicketId = ticket.shippingTicketId;
        const ticketNumber = ticket.ticketNumber;
        const customerName = ticket.signedByCustomerName;
        if (this.state.isADNOCCustomer === true && this.hasNotesBeenUpdated() === true) {
            //update asset Notes 
            const parameters = {
                shippingTicketId: shippingTicketId,
                name: '',
                //signature: ticket.signedByCustomerSignature,
                assets: this.state.updatedAssetNotes
            };

            ajax.post('shipping/saveShippingTicketNotes', parameters).then(() => {
                messages.channel('shipping-ticket').action('saved').post({ shippingTicketId: shippingTicketId });
                if (this.hasSignatureBeenCaptured()) {
                    notification.action('success').post(`Saved notes for ${customerName} on shipping ticket ${ticketNumber}`);
                }
                return true;
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
            this.state.updatedAssetNotes = [];
        }

        if (!this.hasSignatureBeenCaptured()) {
            const parameters = {
                shippingTicketId: shippingTicketId,
                name: customerName,
                signature: ticket.signedByCustomerSignature
            };
            ajax.post('shipping/saveCustomerSignature', parameters).then(() => {
                messages.channel('shipping-ticket').action('saved').post({ shippingTicketId: shippingTicketId });
                notification.action('success').post(`Saved signature for ${customerName} on shipping ticket ${ticketNumber}`);
                return true;
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });

        }



        //   if (!this.hasSignatureBeenCaptured() && this.hasNotesBeenUpdated() === true) { 
        //     notification.action('success').post(`Saved shipping ticket ${ticketNumber}for ${customerName}`);

        //}


    }

    editNote = () => {
        this.state.editingNote = true;
        this.state.cacheNotes = this.state.notes;
        this.commit();
    }

    cancelSaveNotes = () => {
        this.state.editingNote = false;
        this.state.notes = this.state.cacheNotes;
        this.state.cacheNotes = '';
        this.commit();
    }

    filterElements = (node) => {
        return (!Utils.contains(node.className, 'no-print') && !Utils.contains(node.className, 'no-sap-upload'));
    }

    hasPODBeenUploaded = () => {
        return !DateTimeFormatter.isMinDateTime(this.state.datePODUploaded);
    }

    hasSignatureBeenCaptured = () => {
        return !DateTimeFormatter.isMinDateTime(this.state.signedByCustomerTimestamp);
    }

    hasNotesBeenUpdated = () => {
        let isUpdated = false;
        //return !DateTimeFormatter.isMinDateTime(this.state.signedByCustomerTimestamp);
        if (this.state.isADNOCCustomer != true || this.state.assets.length < 1) {
            return false;
        }

        this.state.assets.map(asset => {
            const assetId = asset.assetId;
            const updatedNotes = this.state['note_' + assetId];
            if (asset.notes != updatedNotes) {
                isUpdated = true;
                asset.notes = updatedNotes;
                this.state.updatedAssetNotes.push({ shipping_Asset_GUID: asset.shipping_Asset_GUID, notes: updatedNotes, assetId: asset.assetId });
                this.commit();
            }
        });
        return isUpdated;
    }


    hasSignatureBeenCapturedISR = () => {
        return LoginService.isOnlySAPRep ? true : !DateTimeFormatter.isMinDateTime(this.state.signedByCustomerTimestamp);
    }

    canUploadPOD = () => {
        return !this.state.editingNote &&
            // TODO: uncomment code when SAP POD Upload service is available
            SAPService.isSAPFacility() &&
            this.state.jobNumber && // has to be associated with an FMC job

            // TODO: uncomment code when SAP POD Upload service is available
            !this.hasPODBeenUploaded() && // cannot have been uploaded already
            this.hasSignatureBeenCaptured();  // customer has to have signed for ticket
    }
    canDownloadPOD = () => {
        return !this.state.editingNote && this.hasSignatureBeenCapturedISR();  // customer has to have signed for ticket
    }

    canDisableSave = () => {
        if (this.state.isADNOCCustomer == true) {
            return false;
        }
        return this.hasSignatureBeenCaptured();  // customer has to have signed for ticket
    }

    uploadPOD = () => {
        if (!this.canUploadPOD()) {
            return;
        }
        const ticket = this.state;
        const shippingTicketId = ticket.shippingTicketId;
        const ticketNumber = ticket.ticketNumber;
        const customerName = ticket.customerName;
        const jobId = ticket.jobId;

        const parameters = {
            shippingTicketId: shippingTicketId,
            podImage: ''
        };
        this.state.uploadInProgress = true;
        this.commit();

        ajax.post('shipping/uploadPOD', parameters).then(pdfData => {
            messages.channel('shipping-ticket').action('saved').post({ shippingTicketId: shippingTicketId });
            notification.action('success').post(`Uploaded POD for ${customerName} on shipping ticket ${ticketNumber}`);
            this.state.uploadInProgress = false;
            this.commit();
            // var file = new Blob([pdfData], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            // if (jobId) {
            //   var postData = { jobId: jobId };
            //   messages.channel('edit-job').action('saved').post(postData);
            // } 
            return true;
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    createPOD = () => {
        if (!this.canDownloadPOD()) {
            return;
        }
        const ticket = this.state;
        const shippingTicketId = ticket.shippingTicketId;
        const ticketNumber = ticket.ticketNumber;
        const customerName = ticket.customerName;
        const jobId = ticket.jobId;

        const parameters = {
            shippingTicketId: shippingTicketId,
            podImage: ''
        };

        this.state.isGeneratingReport = true;
        this.commit();
        if (ticket.isADNOCCustomer === true) {
            ajax.post('shipping/DownloadPOD_ADNOC', parameters).then(pdfData => {
                this.state.isGeneratingReport = false;
                this.commit();
                if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/CriOS/i) != null) {
                    let file = Utils.convertBase64toBlob(pdfData, 'application/pdf');
                    let fileURL = window.URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else {
                    pdfData = 'data:application/pdf;base64,' + pdfData;
                    var tempLink = document.createElement('a');
                    tempLink.href = pdfData;
                    tempLink.setAttribute('download', ticketNumber + '.pdf');
                    tempLink.click();
                    tempLink.remove();
                }
                return true;
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
        }
        else {
            ajax.post('shipping/downloadPOD', parameters).then(pdfData => {
                this.state.isGeneratingReport = false;
                this.commit();
                if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/CriOS/i) != null) {
                    let file = Utils.convertBase64toBlob(pdfData, 'application/pdf');
                    let fileURL = window.URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else {
                    pdfData = 'data:application/pdf;base64,' + pdfData;
                    var tempLink = document.createElement('a');
                    tempLink.href = pdfData;
                    tempLink.setAttribute('download', ticketNumber + '.pdf');
                    tempLink.click();
                    tempLink.remove();
                }
                return true;
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
        }
    }
    // var ticket                = this.state;
    // var oldBodyWidth          = document.body.style.width;
    // document.body.style.width = '935px';

    // domtoimage.toPng(document.body, { filter: this.filterElements, width: 1400 })
    //   .then(data => {
    //     var img                   = new Image();
    //     img.width                 = 1200;
    //     img.src                   = data;
    //     document.body.style.width = oldBodyWidth;

    //     var pdfOptions = {
    //       filename     : ticket.ticketNumber + '.pdf',
    //       generateFile : true,
    //       margin       : 0,
    //       image        : { type : 'jpeg', quality         : 1.0 },
    //       html2canvas  : { dpi  : 192   , letterRendering : true },
    //       jsPDF        : { unit : 'pt'  , format          : 'letter', orientation : 'portrait' },
    //       ticket       : ticket
    //       //callback     : pdfData => this.uploadPOD(ticket, pdfData)
    //     };
    //     html2pdf(img, pdfOptions);
    //   })
    //   .catch(() => {
    //     notification.action('error').post(`Error creating PDF for shipping ticket ${ticket.ticketNumber}. Note: there are known issues when trying to create PDF from iPads.`);
    //   });


    saveNotes = () => {
        const ticket = this.state;
        if (ticket.notes === ticket.cacheNotes) {
            this.cancelSaveNotes();
            return;
        }


        const shippingTicketId = ticket.shippingTicketId;
        const ticketNumber = ticket.ticketNumber;
        const customerName = ticket.customerName;
        const note = ticket.notes;
        const parameters = {
            shippingTicketId: shippingTicketId,
            note: note
        };

        ajax.post('shipping/saveNotes', parameters).then(() => {
            messages.channel('shipping-ticket').action('saved').post({ shippingTicketId: shippingTicketId });
            notification.action('success').post(`Saved note for ${customerName} on shipping ticket ${ticketNumber}`);
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: ShippingCustomerSignatureDto, out: void

    }

    updateState = () => {
        this.state.assets.map(x => {
            var s = this.state;
            this.state = { ...s, ["note_" + (x.assetId)]: x.notes }
        })
        this.commit();
    }
}

export default ShippingTicketDetailController;