export const SortType = {
    unknown: 'unknown',
    ASC: 'ASC',
    DESC: 'DESC'
};

export const sorter = (fnData = () => { }, sortKey, sortDir) => {
    var data = fnData();
    if (!data || !sortKey) {
        return null;
    }

    // get current sort direction and then toggle it
    sortDir = sortDir || SortType.ASC;
    sortDir = (sortDir === SortType.ASC) ? SortType.DESC : SortType.ASC;

    data.sort((a, b) => {
        var sortVal = 0;
        var sortKeys;
        if (typeof (sortKey) === 'function') {
            sortKeys = sortKey(a, b).split(',');
        }
        else {
            sortKeys = sortKey.split(',');
        }

        for (var keyIndex = 0; keyIndex < sortKeys.length; ++keyIndex) {
            sortVal = sort(a, b, sortDir, sortKeys[keyIndex]);
            if (sortVal !== 0) {
                // if using a secondary sort, don't reverse the sort
                if ((sortDir === SortType.DESC) && (keyIndex === 0)) {
                    sortVal = -sortVal;
                }
                break;
            }
        }
        return sortVal;
    });
    // return the new Sort Direction so the caller can persist it
    return sortDir;
}

const sort = (a, b, sortDir, sortKey) => {
    var sortVal = 0;

    var aVal = getVal(a, sortKey);
    if (aVal === null) {
        return -1;
    }

    var bVal = getVal(b, sortKey);
    if (bVal === null) {
        return 1;
    }

    if ((typeof aVal === 'string') && (typeof bVal === 'string')) {
        sortVal = aVal.localeCompare(bVal);
    }
    else {
        if (aVal > bVal) {
            sortVal = 1;
        }

        if (aVal < bVal) {
            sortVal = -1;
        }
    }
    return sortVal;
}

const getVal = (obj, key) => {
    if (!obj) {
        return '';
    }

    if (key.indexOf('.') === -1) {
        return obj[key];
    }

    var parts = key.split('.');
    for (var i = 0; i < parts.length; ++i) {
        if (obj) {
            obj = obj[parts[i]];
        }
    }
    return obj;
}

export default {
    SortType: SortType
}