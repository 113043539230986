import React, {useMemo} from 'react';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Searchers from '../utility/Searchers';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';

const PressureTestSettingsTable = (props) => {
  const controller = props.controller;
  const editable     = props.editable;
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);

  const handleAddNewRow = () => {
    props.controller.addNewPressureTestRow();
  }
  let data =[];
  if(props.source && props.source === 'Manual'){
    data = props ? [...props.pressureTestSettings] : [];
  }
  else{
     data = controller ? [...controller.state.pressureTestSettings] : [];
  }

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',  
        isVisible : editable, 
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return (controller.canRemoveRow() && !isSuperUser)
                 ? <ActionIcon  icon='fa-trash' className='remove-handle' action={controller.removeRow} />
                 : <span></span>;
        }
      },
      {
        Header: 'Step ID',
        accessor: 'state.stepId',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return editable
                 ? <Form.NumberInput bind={binder.bind('stepId')} readOnly={isSuperUser} className='required col-step-id' error={errors.stepId} placeholder='#' />
                 : <span>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Duration (0-29 → minutes, >= 30 → seconds)',
        accessor: 'state.duration',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return editable
                 ? <Form.NumberInput className='required col-duration' readOnly={isSuperUser} bind={binder.bind('duration')} error={errors.duration} />
                 : <span>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Hold Pressure (PSI)',
        accessor: 'state.holdPressure',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return editable
                 ? <Form.NumberInput allowDecimal={true} className='required col-hold-pressure' readOnly={isSuperUser} bind={binder.bind('holdPressure')} error={errors.holdPressure} />
                 : <span>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Allowable Drop (PSI)',
        accessor: 'state.allowableDrop',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return editable
                 ? <Form.NumberInput allowPercent={true} allowDecimal={true} className='required col-allowable-drop' readOnly={isSuperUser} bind={binder.bind('allowableDrop')} error={errors.allowableDrop} />
                 : <span>{cellInfo.value}</span>
        }
      },
      {
        Header: '%',
        accessor: 'state.allowableDropPercent',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
      {
        Header: 'Max Allowable Pressure',
        accessor: 'state.maxAllowablePressure',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return editable
                 ? <Form.NumberInput allowPercent={true} allowDecimal={true} className='required col-max-allowable-pressure' readOnly={isSuperUser} bind={binder.bind('maxAllowablePressure')} error={errors.maxAllowablePressure} />
                 : <span>{cellInfo.value}</span>
        }
      },
      {
        Header: '%',
        accessor: 'state.maxAllowablePressurePercent',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
      {
        Header: 'Test Mode',
        accessor: 'state.testMode',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return editable
                 ? <Form.AutoComplete className='required col-test-mode' readOnly={isSuperUser} bind={binder.bind('testMode')} error={errors.testMode} allowClear={false} search={Searchers.testModeSearch()} />
                 : <span>{cellInfo.value.name}</span>;
        }
      },
    ],
    [editable]);
    // const caption = <span style={{color: '#000000'}}><b>Dimensions: </b>{totalWallReadingCount}</span>
    return (
      <div id='pressure-test-results'>
        <Grid id='pressure-results-table' columns={columns} responsive={false} data={data} editable={editable} canAddNewRow={false}  onAddNewRow={handleAddNewRow} showPagination={false} noDataText='There are no overriding settings for this asset on this job'/>
      </div>
  );
}

export default PressureTestSettingsTable;