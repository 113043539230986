import React from 'react';

const SortColumn = (props) => {
    var label    = props.children;
    var sorter   = props.sorter;
    var fnData   = props.getData;
    var sortKey  = props.sortKey;
    var sortable = !!sorter;
    var sortDir  = props.sortDir;
    var sortIcon = sortable? sortDir==='ASC'?<i className='fas fa-sort-amount-down-alt' />
                             :sortDir==='DESC'?<i className='fas fa-sort-amount-up-alt' />
                                               : <i className='fa fa-sort' />
                    : null;


    return (<th className={props.className} style={{cursor: 'pointer'}} onClick={sortable ? sorter.bind(null, fnData, sortKey) : null} >{label} {sortIcon}</th>);
};

export default SortColumn;