import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import moment from 'moment';

class CompleteJobController extends BaseController {
  constructor(job) {
    super('complete-job', {
      job: job,
      returnDate: DateTimeFormatter.formatDate(moment().startOf('day')),
      errors: {}
    });
    this.saving = false;
    this._validator = new Validator();
    this._validator.add('returnDate', 'Return Date must be less than or equal to today.', [{ isLessThanEqualsDate: { params: DateTimeFormatter.today() } }]);
    //this._validator.add('returnDate', 'Return Date must be greater than or equal to Receive Date and less than or equal to today.', [{isLessGreaterThanEqualsDate: { params: [receiveDate , DateTimeFormatter.today()] }}]);
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    var errors = Object.keys(this.state.errors);
    this.commit();
    if (errors.length) {
      return false;
    }
    var receiveDate = moment(this.state.job.receiveDate);
    this._validator.add('returnDate', 'Return Date must be greater than or equal to Receive Date.', [{ isGreaterThanEqualsDate: { params: receiveDate } }]);
    this.commit();
    this._validator.validateAll(this.state);
    var errors = Object.keys(this.state.errors);
    this.commit();
    if (errors.length) {
      return false;
    }
    this.commit();
    return true;
  }

  save = () => {
    if (!this.canSave()) {
      return false;
    }

    const job = this.state.job;
    const parameters = {
      jobId: job.id,
      jobNumber: job.jobNumber,
      returnDate: DateTimeFormatter.formatDate(this.state.returnDate)
    };
    this.saving = true;
    return ajax.post('jobprogress/complete', parameters).then(() => {
      this.saving = false;
      this.commit();
      notification.action('success').post('Completed job: ' + job.jobNumber + ' – ' + job.jobDescription);
      messages.channel('complete-job').action('saved').post();
      return true;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default CompleteJobController;
