import React, {useMemo} from 'react';
import Grid from '../../components/Grid';
import LoginService from '../services/LoginService';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';

const CustomerDescriptionWallReadingsTable = (props) => {
  const controller = props.controller;
  const canEdit   = LoginService.isSuperUser;

  const totalWallReadingCount = controller.state.wallReadings.reduce((acc, curr) => acc + curr.state.quantity, 0);

  const handleAddNewRow = () => {
    props.controller.addNewWallReadingRow();
  }
  
  const divClassName = controller.state.errors.wallReadings ? ' has-error' : '';

  const data = [...controller.state.wallReadings];

  const getRowProps = (row) => {
    const rowProps = {};
    if (row.original.state.ReadingType == AppConstants.ReadingType.Customer) {
      rowProps.className = 'cust-desc-background';
    }
    else {
      if(row.original.state.customerSelected){
        rowProps.className = 'man-desc-background';
      }
      else{
        rowProps.className = 'man-desc-background unused-manufacturer-reading';
      }
    }
    return rowProps;
  }
  const fldClassName ='required';

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action', 
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return canEdit && controller.canRemoveRow() 
                ? <ActionIcon  icon='fa-trash' className='remove-handle' action={controller.removeRow} />
                : <span></span>;
        }
      },
      {
        Header: 'Use',
        accessor: 'state.customerSelected',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Manufacturer)
                 ? canEdit ? <Form.CheckBox bind={binder.bind('customerSelected')} /> 
                 : <span>{Utils.formatBool(cellInfo.value)}</span>
                 : <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'ID',
        accessor: 'state.readingId',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Customer && canEdit)
                  ? <Form.Input bind={binder.bind('readingId')} className='required id-field' error={errors.readingId} placeholder='#' />
                  : <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'New Dimension',
        accessor: 'state.newDimension',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return cellInfo.row.original.state.readingType == AppConstants.ReadingType.Customer && canEdit
          ? <Form.Input bind={binder.bind('newDimension')} className={fldClassName} error={errors.newDimension} placeholder='Enter a value' />
          : <span>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Minimum Dimension',
        accessor: 'state.minimumDimension',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
      {
        Header: 'Customer Minimum',
        accessor: 'state.customerMinimumDimension',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return  (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Customer || (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Manufacturer && cellInfo.row.original.state.customerSelected))
          ? canEdit ? <Form.Input bind={binder.bind('customerMinimumDimension')} className={'required'} error={errors.customerMinimumDimension} placeholder='Enter a value' />
          : <span>{cellInfo.value}</span>
          : <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'Type',
        accessor: 'state.readingType',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
    ],
    [data]);
  
    return (
      <div className={divClassName}>
        <Grid id='wall-read-results-table' columns={columns} data={data} editable={canEdit} canAddNewRow={false} getRowProps={getRowProps} onAddNewRow={handleAddNewRow} showPagination={false} noDataText='No wall readings exist yet'/>
      </div>
  );
}

export default CustomerDescriptionWallReadingsTable;