import React from 'react';
import store from 'store';
import $ from 'jquery';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import CompleteJobController from '../controllers/CompleteJobController';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
import Utils from '../utility/Utils';
import CompleteJobDialog from '../dialogs/CompleteJobDialog';
import OverrideDialog from '../dialogs/OverrideDialog';
import OverrideController from '../controllers/OverrideController';

class JobProgressController extends BaseController {
  constructor() {
    super('job-progress', {
      customer: '',
      selectedJob: '',
      searchActive: true,
      searchCompleted: false,
      revenueGeneratingCustomersOnly: true,
      includeNewJobs: false,
      currentPage: 1,
      customers: [],
      searchResults: [],
      revenueGeneratingCustomers: [],
      allCustomers: [],
      userJobNotifications: []
    });

    const self = this;
    this.flash = emitter();

    window.setTimeout(() => {
      this.searchJobs();
    }, 0);

    messages.channel('change-facility').action('changed').subscribe(() => {
      this.state.customer = '';
      this.state.searchResults = [];
      this.state.customers = [];
      this.state.selectedJob = '';
      this.clearCache();
    });

    messages.channel('complete-job').action('saved').subscribe(() => {
      this.searchJobs();
    });

    messages.channel('edit-job').action('saved').subscribe(() => {
      this.searchJobs();
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        self.state.customer = '';
        self.clearResults();
        self.clearCache();
      }
    });
    messages.channel('quote-status').action('updated').subscribe(data => {
      if (data.message && data.message.action) {
        notification.action(data.message.action).post(data.message);
      }
    });
    messages.channel('job-status').action('updated').subscribe(data => {
      const jobs = self.state.searchResults.filter(result => result.id === data.jobId);
      if ((jobs === null) || !jobs.length) {
        return;
      }

      if (data.message && data.message.action) {
        notification.action(data.message.action).post(data.message);
      }

      const job = jobs[0];
      co(self, function* () {
        const updatedJob = yield ajax.get('lookup/getJob', { jobId: job.id }); // JobLookupDto
        $.extend(job, updatedJob);
        this.commit();
        this.flash.emit(job.id);
      });
    });
  }

  customerChanged = () => {
    this.clearResults();
    document.getElementById('searchText').focus();
    this.state.currentPage = 1;
    this.commit();
    this.searchJobs();
  }

  clearResults = () => {
    this.state.searchResults = [];
    this.state.selectedJob = '';
  }

  clearCache = () => {
    this.state.revenueGeneratingCustomers = [];
    this.state.allCustomers = [];
  }

  clearSearchText = () => {
    this.state.searchText = '';
    this.commit();
  }

  searchActiveChanged = () => {
    this.state.searchCompleted = false;
    this.clearResults();
    document.getElementById('searchText').focus();
    this.state.currentPage = 1;
    this.commit();
    this.searchJobs();
  }

  searchCompletedChanged = () => {
    this.state.searchActive = false;
    this.clearResults();
    this.clearCache();
    document.getElementById('searchText').focus();
    this.state.currentPage = 1;
    this.commit();
    this.searchJobs();
  }

  revenueGeneratingCustomersOnlyChanged = () => {
    this.state.customer = '';
    this.clearResults();
    this.state.currentPage = 1;
    this.commit();
    this.searchJobs();
  }

  includeNewJobsChanged = () => {
    this.clearResults();
    this.clearCache();
    this.state.currentPage = 1;
    this.commit();
    this.searchJobs();
  }

  searchJobs = () => {
    this.clearResults();
    const facilityId = (LoginService.loginInfo && LoginService.loginInfo.facilityId);
    const customerId = (this.state.customer && this.state.customer.id);
    const searchText = this.state.searchText;
    if (!facilityId) {
      return;
    }
    const parameters = {
      facilityId: facilityId,
      customerId: customerId,
      searchTerm: searchText,
      searchActive: this.state.searchActive,
      revenueGeneratingCustomersOnly: this.state.revenueGeneratingCustomersOnly,
      includeNewJobs: this.state.includeNewJobs
    };

    this.state.searchResults = [];
    this.state.isLoading = true;
    this.commit();
    ajax.get('lookup/searchExistingJobsForFacility', parameters).then(results => {
      this.state.isLoading = false;
      if (results.length > 0) {
        this.loadJobDetails(results[0]);
      }
      this.state.searchResults = results;
      this.commit();
    }
    ).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  customerSearch = () => {
    const self = this;
    return function* () {
      if (!LoginService.loginInfo) {
        return;
      }

      let revenueGeneratingCustomersOnly = 0; // self.state.revenueGeneratingCustomersOnly ? 1 : 0;
      const facilityId = LoginService.loginInfo.facilityId;
      const includeNewJobs = self.state.includeNewJobs ? 1 : 0;
      const searchActive = self.state.searchActive ? 1 : 0;
      const parameters = {
        facilityId: facilityId,
        searchTerm: '',
        revenueGeneratingCustomersOnly: 0,
        includeNewJobs: includeNewJobs,
        searchActive: searchActive
      };

      const results = yield ajax.get('lookup/searchCustomersWithJobsAtFacility', parameters); // IList<LookupDto>
      self.state.allCustomers = results;
      return results;
    };
  }
  
  loadCustomers = () => {
    if (!LoginService.loginInfo) {
      return;
    }

    let revenueGeneratingCustomersOnly = 0; // this.state.revenueGeneratingCustomersOnly ? 1 : 0;

    //check for cached values
    switch (revenueGeneratingCustomersOnly) {
      case 0:
        if (this.state.allCustomers.length) {
          this.state.customers = this.state.allCustomers;
          this.commit();
          return;
        }
        break;
      case 1:
        if (this.state.revenueGeneratingCustomers.length) {
          this.state.customers = this.state.revenueGeneratingCustomers;
          this.commit();
          return;
        }
        break;

      default:
        break;
    }

    const facilityId = LoginService.loginInfo.facilityId;
    const includeNewJobs = this.state.includeNewJobs ? 1 : 0;
    const searchActive = this.state.searchActive ? 1 : 0;
    const parameters = {
      facilityId: facilityId,
      searchTerm: '',
      revenueGeneratingCustomersOnly: 0,
      includeNewJobs: includeNewJobs,
      searchActive: searchActive
    };
      ajax.get('lookup/searchCustomersWithJobsAtFacility', parameters).then(results => {
        this.state.customers = results;
      switch (revenueGeneratingCustomersOnly) {
        case 0:
          this.state.allCustomers = results;
          break;

        case 1:
          this.state.revenueGeneratingCustomers = results;
          break;

        default:
          break;
      }
      this.commit();
      return results;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
  }

  isSelected = (job) => {
    if (!this.state.selectedJob) {
      return false;
    }
    return (job.id === this.state.selectedJob.id);
  }

  loadJobDetails = (job) => {
    this.state.selectedJob = job;
    const jobId = (job && job.id);
    if (!jobId) {
      this.commit();
      return;
    }
      if (job.dropoffForms === null) {
        ajax.get('lookup/getDropoffForms', { jobId: jobId }).then(dropoffForms => {
          job.dropoffForms = dropoffForms;
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }

      if (job.shippingTickets === null) {
        ajax.get('lookup/getShippingTickets', { jobId: jobId }).then(shippingTickets => {
          job.shippingTickets = shippingTickets;
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }

      this.commit();
  }

  canCreateNewJob = () => {
    const facility = store.get('currentFacility');
    if (!facility) {
      return false;
    }
    return (!facility.useSAP || (facility.plantCode !== ''));
  }
  
  canEstimateQuote = (job) => {
    const facility = store.get('currentFacility');
    if (!facility) {
      return false;
    }
    if (!facility.useSAP) {
      return false;
    }
    switch (job.sapStatus) {
      case 'NOCO':
      case 'QUNC':
      case 'QUNR':
      case 'RPIG':
      case 'RPOR':
      case 'POAC':
      case 'SHIP':
      case 'NOBL':
      case 'QUST':
      case '':
        return false;

      default: // SORL, SOCR, QUCR, QUAC, QUAG
        return true;
    }
  }
  
  canOverrideJob = (job) => {
    const facility = store.get('currentFacility');
    if (!facility) {
      return false;
    }
    if (!facility.useSAP) {
      return false;
    }
    switch (job.sapStatus) {
      case 'SOCR':
      case 'QUCR':
      case 'QUST':
        return true;

      default: // QUNC,QUNR, QUAC, QUAG,SORL, NOCO, RPIG, RPOR, POAC, SHIP, NOBL
        return false;
    }
  }
  
  canGetReadyForInvoicing = () => {
    return false;
  }

  canPerformAdhocTransactions = (job) => {
    if (!job) {
      return false;
    }

    switch (job.sapStatus) {
      case 'QUNC':
      case 'QUNR':
      // case 'SOCR':
      // case 'QUCR':
      // case 'QUAC':
      // case 'QUAG':
      case '':
        return false;

      default: // SORL, NOCO, RPIG, RPOR, POAC, SHIP, NOBL
        return true;
    }
  }

  canAddAdhocTransactions = (job) => {
    return (job && job.canAddAdhocTransactions);
  }

  allShippingTicketsUploaded = (job) => {
    return job && job.shippingTickets && job.shippingTickets.every(ticket => this.isShippingTicketUploaded(ticket));
  }

  isShippingTicketUploaded = (ticket) => {
    return ticket && ticket.isPODUploaded;
  }

  lockForInspection = (job) => {
    if (!job.canLockForInspection) {
      notification.action('warning').post('Unable to lock job for inspection');
      return;
    }

    const boldStyle = { fontWeight: 'bold' };
    const spanStyle = { whiteSpace: 'pre-wrap' };
    const divStyle = { marginTop: '10px' };
    const message1 = <span style={spanStyle}>Please confirm you have finished receiving all assets for job <span style={boldStyle}>{job.jobNumber}</span></span>;
    const message2 = <span style={spanStyle}><span style={boldStyle}>NOTE:</span> It is still possible to add additional assets later &ndash; you are just making it possible to begin inspection of the assets added so far</span>;

    co(this, function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={550} height={300} header='Confirm Lock Assets'>
          <div>{message1}</div>
          <div style={divStyle}>{message2}</div>
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        var parameters = {
          jobId: job.id,
          jobNumber: job.jobNumber
        };

        yield ajax.post('jobprogress/lockJobForInspection', parameters); // in: JobDto
      }
    });
  }

  tryInvoice = (job) => {
    if (!job.canInvoice) {
      notification.action('warning').post('Unable to invoice job');
      return;
    }

    // TODO: uncomment code when SAP POD Upload service is available
    if (!this.allShippingTicketsUploaded(job)) {
      notification.action('warning').post('All shipping tickets have not been uploaded to SAP yet - please take care of that prior to selecting POD Paperwork Complete');
      return;
    }

    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog className='confirm-invoice-request' width={500} height={300} header='Confirm Invoice Request'>
          <h6 className='highlight-text'>All customer POD documentation must be complete at this point so invoicing can occur.</h6>
          <h6>Please confirm you wish to invoice job:</h6>
          <h5>{job.jobNumber}</h5>
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        const parameters = {
          jobId: job.id,
          jobNumber: job.jobNumber
        };

        yield ajax.post('jobprogress/invoice', parameters); // in: JobDto
      }
    });
  }

  complete = (job) => {
    if (!job.canComplete) {
      return;
    }

    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <CompleteJobDialog controller={new CompleteJobController(job)} header='Confirm Job Completion' />
      );
      if (result.Save) {
        this.searchJobs();
      }
    });
  }
  EstimateQuote = (job) => {
    try {
      co(this, function* () {
        const parameters = {
          jobId: job.id,
          jobNumber: job.jobNumber
        };
        yield ajax.post('jobprogress/requestQuote', parameters);
      });
      //notification.action('success').post('Request Quote completed successfully');
    }
    catch (err) {
      notification.action('error').post(err.message);
    }
  }
  Override = (job) => {
    // try { 
    //   co(this, function*() {     
    //     var parameters = {
    //       jobId     : job.id,
    //       jobNumber : job.jobNumber
    //     };
    //     yield ajax.post('jobprogress/override', parameters);        
    //   });
    //   //notification.action('success').post('Override completed successfully');
    // }
    // catch (err) {
    //   notification.action('error').post(err.message);
    // }
    try {
      co(this, function* () {
        const result = yield Dialog.showDialogWaitForResult(
          <OverrideDialog controller={new OverrideController(job)} header='Quote Override' />
        );
      });
    }
    catch (err) {
      notification.action('error').post(err.message);
    }
  }
  delete = (job) => {
    if (!job.canDelete) {
      return;
    }

    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog className='confirm-invoice-request' width={500} height={300} header='Confirm Delete Request'>
          <h6>Please confirm you wish to delete job:</h6>
          <h5>{job.jobNumber}</h5>
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        const parameters = {
          jobId: job.id,
          jobNumber: job.jobNumber
        };
        yield ajax.post('jobprogress/delete', parameters);
        this.searchJobs();
      }
    });
  }
}

export default JobProgressController;
