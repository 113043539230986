import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Page from '../../components/Page';
import Jumbotron from '../../components/Jumbotron';
import PageHeader from '../components/PageHeader';
import ReceivingTicketController from '../controllers/ReceivingTicketController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import ReceivingTimeController from '../controllers/ReceivingTimeController';
import ReceivingTimeDialog from '../dialogs/ReceivingTimeDialog';
import Dialog from '../../components/Dialog';
import Table from '../../components/Table';
import Grid from '../../components/Grid';
import Search from '../components/Search';

const SearchHeader = (props) => {

  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.search();
  }

  const handleMouseSearch = () => {
    props.controller.search();
  }

  const clearSearch = () => {
    props.controller.clearSearchText();
  }

  const binder = props.binder;
  const controller = props.controller;
  const customerClassName = binder.bind('customer')()
    ? 'err-msg'
    : null;

  return (
    <div {...props} id='search-criteria'>
      <div className='row'>
        <div className='search-container col-md-6' onKeyDown={handleKeyboardSearch}>
          <Search type='number' className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Receiving Ticket Number' placeholder='exact match required' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
        </div>
        <div id='customer-search' className='col-md-6'>
          <Form.AutoComplete bind={binder.bind('customer')} label='Customers' prependLabelIcon='fa fa-filter' placeholder='Select a customer to filter your results' data={controller.state.customers} minimumResultsForSearch={-1} />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-6' onKeyDown={handleKeyboardSearch}>
            { SAPService.isSAPFacility() ?
              <div><Form.Input type='number' className='search-input uppercase' placeholder='allows partial matches' prependLabelIcon='fa fa-filter' label='Job Number' bind={binder.bind('jobNumber')} /></div> : 
              <Form.Input className='search-input uppercase' placeholder='allows partial matches' prependLabelIcon='fa fa-filter' label='Job Number' bind={binder.bind('jobNumber')} />
            } </div>
            <div className='col-md-6'>
              <Form.CheckBox label='Include Tickets Without Assets' prependLabelIcon='fa fa-filter' bind={binder.bind('includeZeroAssetTickets')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ResultRow = (props) => {

  let history = useHistory();

  const selectRow = (ticket) => {
    props.controller.loadDetails(ticket);
  }

  const navigateToTicketDetails = (evt, ticket) => {
    props.controller.state.selectedTicket = ticket;
    history.push('receivingticketdetail', { ticketId: ticket.receivingTicketId });
  }

  const controller = props.controller;
  const ticket = props.ticket;
  let className = '';
  if (controller.isSelected(ticket)) {
    className = 'selected';
  }

  const ticketLink = (ticket.assetCount > 0)
    ? <Form.Link to={'/receivingtickets/' + ticket.receivingTicketId} value={ticket.ticketNumber} />
    : ticket.ticketNumber;

  return (
    <Table.Row id={ticket.receivingTicketId} onClick={() => { selectRow(ticket); }} isSelected={controller.isSelected(ticket)} ticket={ticket}>
      <Table.Data className={className}>{ticketLink}</Table.Data>
      <Table.Data className={className}>{DateTimeFormatter.formatDate(ticket.receivedDate)}</Table.Data>
      <Table.Data className={className}>{ticket.jobNumber}</Table.Data>
      <Table.Data className={className}>{ticket.assetCount}</Table.Data>
      <Table.Data className={className}>{ticket.customerName}</Table.Data>
      <Table.Data className={className}>{ticket.receiveToLocationName}</Table.Data>
    </Table.Row>
  );
}

const Results = (props) => {


  const handleRowClick = (ticket) => {
    props.controller.loadDetails(ticket);
  }

  const getData = () => {
    return props.controller.state.searchResults || [];
  }

  const data = getData();

  const headers = useMemo(
    () => [
      {
        Header: 'Ticket Number',
        accessor: 'ticketNumber',
        Cell: cellInfo => cellInfo.row.original.assetCount > 0
          ? <Form.Link to={'/receivingtickets/' + cellInfo.row.original.receivingTicketId} value={cellInfo.cell.value} />
          : cellInfo.cell.value
      },
      {
        Header: 'Received Date',
        accessor: 'receivedDate',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
      },
      {
        Header: 'Job Number',
        accessor: 'jobNumber'
      },
      {
        Header: 'Asset Count',
        accessor: 'assetCount'
      },
      {
        Header: 'Customer',
        accessor: 'customerName'
      },
      {
        Header: 'Receiving Location',
        accessor: 'receiveToLocationName'
      },
    ],
    []
  )
  const columns = headers;
  return (
    <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText='No receiving tickets meet the search criteria defined above' />
  );
}


class ResultsList extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount = () => {
    var stream = this.props.controller.flash.delay(0);
    stream.onValue(this.flash);
    this.dispose = () => stream.offValue(this.flash);

  }

  componentWillUnmount = () => {
    this.dispose();
  }

  getData = () => {
    return this.props.controller.state.searchResults || [];
  }

  render = () => {
    const controller = this.props.controller;
    let tickets = this.getData();
    const colSpanCount = 7;
    const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data colSpan={colSpanCount} className='loading'>Loading receiving tickets that match the search criteria defined above</Table.Data></Table.Row>;
    const noResultsMessage = !loadingMessage && !tickets.length && <Table.Row><Table.Data colSpan={colSpanCount} className='no-search-results'>No receiving tickets meet the search criteria defined above</Table.Data></Table.Row>;
    const count = controller.state.searchResults.length;
    const activeLabel = Utils.pluralize('match', 'es', count);
    const totalAssetCount = controller.state.searchResults.reduce((acc, curr) => { return acc + curr.assetCount; }, 0);
    const totalAssetCountLabel = Utils.pluralize('total asset', 's', totalAssetCount);

    tickets = tickets.map((ticket, index) => <ResultRow key={index + 1} controller={controller} ticket={ticket} />);

    return (
      <div id='search-results' className='form-group table-container'>
        <div className='d-flex justify-content-end mb-1'>
          <Badge variant='info' className='mr-1'> {activeLabel}</Badge>
          <Badge variant='info' className='mr-1'> {totalAssetCountLabel}</Badge>
        </div>

        <Table id='results-table' responsive={false}>
          <Table.Header>
            <Table.Row>
              <Table.Head sortKey='ticketNumber' getData={this.getData} sorter={this.sortData}>Ticket Number</Table.Head>
              <Table.Head sortKey='receivedDate,ticketNumber' getData={this.getData} sorter={this.sortData}>Received Date</Table.Head>
              <Table.Head sortKey='jobNumber,ticketNumber' getData={this.getData} sorter={this.sortData}>Job Number</Table.Head>
              <Table.Head sortKey='assetCount,ticketNumber' getData={this.getData} sorter={this.sortData}>Asset Count</Table.Head>
              <Table.Head sortKey='customerName,ticketNumber' getData={this.getData} sorter={this.sortData}>Customer</Table.Head>
              <Table.Head sortKey='receiveToLocationName,ticketNumber' getData={this.getData} sorter={this.sortData}>Receiving Location</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tickets}
            {loadingMessage}
            {noResultsMessage}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const Details = (props) => {

  // var binder               = this.props.binder;
  const controller = props.controller;
  const ticket = controller.state.selectedTicket;
  let receivingLocationElement = <Form.StaticData label='Receiving Location' value={ticket.receiveToLocationName} />;
  if (LoginService.isAdministrator) {
    receivingLocationElement = <Form.Link label='Receiving Location' to={'/customerlocations/' + ticket.receiveToLocationId} value={ticket.receiveToLocationName} />;
  }
  let jobElement = <Form.StaticData label='Job Number' value={ticket.jobNumber} />;
  if (LoginService.isCoordinator) {
    jobElement = <Form.Link label='Job Number' value={ticket.jobNumber} to={'/jobprogressassets/' + ticket.jobId} />;
  }

  return (
    <Jumbotron {...props} id='receiving-ticket-description' className='form-group col-sm-12'>
      <div className='row'>
        <div className='col-sm-12'><Form.StaticData label='Ticket Number' value={ticket.ticketNumber} /></div>
      </div>
      <div className='row'>
        <div className='col-sm-12'><Form.StaticData label='Customer' value={ticket.customerName} /></div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>{receivingLocationElement}</div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>{jobElement}</div>
      </div>
      <div className='row'>
        <div className='col-sm-6'><Form.StaticData label='Receiving Date' value={DateTimeFormatter.formatDate(ticket.receivedDate)} /></div>
        <div className='col-sm-6'><Form.StaticData label='Received By' value={ticket.receivedByName} /></div>
      </div>
      <div className='row'>
        <div className='col-sm-6'><Form.StaticData label='Asset Count' value={ticket.assetCount} /></div>
      </div>
      <div className='row'>
        <div className='col-sm-12'><Form.StaticData label='Notes' value={ticket.notes} /></div>
      </div>
    </Jumbotron>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleTicketDetails = () => {
    const ticket = props.controller.state.selectedTicket;
    if (!ticket) {
      return;
    }
    history.push('receivingticketdetail', { ticketId: ticket.receivingTicketId });
  }

  const handleDeleteTicket = () => {
    props.controller.deleteTicket();
  }
  const handleAddTime = (ticket, canaddTime) => {
    Dialog.showDialog(<ReceivingTimeDialog controller={new ReceivingTimeController(ticket.receivingTicketId, canaddTime)} />);
  }

  const controller = props.controller;
  const ticket = controller.state.selectedTicket;
  const canDelete = ticket && !ticket.assetCount;
  const canaddTime = ticket.sapStatus == 'NOCO' || ticket.sapStatus == 'RPIG' || ticket.sapStatus == 'RPOR' || ticket.sapStatus == 'SHIP' || ticket.sapStatus == 'NOBL';
  const addTime = (SAPService.isSAPFacility() && SAPService.useTTS() && LoginService.loginInfo.isClockedIn && !LoginService.loginInfo.isClockedOut) ?
    <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Actions'>
      <Dropdown.MenuItem icon='fa fa-clock fa-flip-horizontal' disabled={!ticket} onClick={() => handleAddTime(ticket, canaddTime)}>Add Receiving Time ...</Dropdown.MenuItem>
    </Dropdown.Button> : null;
  return (
    <div className='col mb-1'>
      <div className='row'>
        <Button icon='fa-pencil-alt' disabled={!ticket || (ticket.assetCount === 0)} to={'/receivingtickets/' + ticket.receivingTicketId} variant='primary' size='sm' className='action-button m-1 btn-text-wrap'>Ticket details ...</Button>
        {addTime}
      </div>
    </div>
  );
}

const ReceivingTicketPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-ticket-alt fa-flip-both' />

class ReceivingTicketPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }
  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
    this.props.controller.loadCustomers();
  }
  static defaultProps = {
    controller: new ReceivingTicketController()
  }
  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const count = controller.state.searchResults.length;
    const activeLabel = Utils.pluralize('match', 'es', count);
    const totalAssetCount = controller.state.searchResults.reduce((acc, curr) => { return acc + curr.assetCount; }, 0);
    const totalAssetCountLabel = Utils.pluralize('total asset', 's', totalAssetCount);

    return (
      <Page {...this.props} pageTitle='InteServ · Receiving Tickets' id='receiving-tickets-page'>
        <ReceivingTicketPageHeader pageTitle={'Receiving Tickets'} />
        <Page.Content>
          <div className='row'>
            <div {...this.props} className='col-md-8'>
              <div className='row'>
                <div className='col-md-12'>
                  <SearchHeader controller={controller} binder={binder} />
                </div>
                <div className='col-md-12'>
                  <div className='d-flex justify-content-end mb-1'>
                    <Badge variant='info' className='mr-1'> {activeLabel}</Badge>
                    <Badge variant='info' className='mr-1'> {totalAssetCountLabel}</Badge>
                  </div>
                  {/* <ResultsList controller={controller} binder={binder} /> */}
                  <div style={{ height: '20rem', overflowY: 'auto' }}>
                    <Results controller={controller} binder={binder} />
                  </div>
                </div>
              </div>
            </div>

            <div {...this.props} className='col-md-4'>
              <div className='row'>
                <div className='col-md-12'>
                  <Buttons controller={controller} />
                </div>
                <div className='col-md-12'>
                  <Details controller={controller} binder={binder} />
                </div>
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}


export default ReceivingTicketPage;