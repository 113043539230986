import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import DropZone from '../../components/DropZone';
import Table from '../../components/Table';
import Binder from '../../lib/Binder';
import ActionIcon from '../../components/ActionIcon';
import co from '../../lib/Co';
import Dialog from '../../components/Dialog';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import CustomerDocumentUploadController from '../controllers/CustomerDocumentUploadController';

const Buttons = (props) => {

    let history = useHistory();

    const handleSave = () => {
        props.controller.saveDescription(() => {
            history.goBack();
        }, props.uploadType);
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <div className='col-sm-12'>
            <div>
                <div className='edit-buttons d-flex justify-content-end mr-2'>
                    { props.screenType == 'fromEditCustomerPage' && <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button> }
                    <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleCancel}>Cancel</Button>
                </div>
            </div>
        </div>
    );
}

const CustomerDocumentUploadPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class CustomerDocumentUploadPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        this.id = this.props.match.params.customerId;
        this.uploadType = this.props.match.params.uploadType;
        this.fromScreen = this.props.match.params.fromPage;
        this.props.controller.load(this.id, this.uploadType);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
    }

    static defaultProps = {
        controller: new CustomerDocumentUploadController()
    }

    render = () => {
        const controller = this.props.controller;
        const customerName = controller.state.customerName;
        const title = 'Upload Documents for Customer ' + customerName;

        const handleFileUpload = (res) => {
            controller.uploadFile(res, this.uploadType);
        }

        const files = controller.state.files || [];
        const msg = files.length == 0 ? <Table.Row>
                        <Table.Data></Table.Data>
                        <Table.Data colSpan={7}>No Files Found</Table.Data>
                    </Table.Row> : null;
        
        const rows = files.map(fc => {
            if (fc.state.file.recordStatus !== 'D') {
                return <CustomerFileNameRow key={fc.key} controller={fc} screenType ={this.fromScreen}/>
            }
        });

        const upload = this.fromScreen == 'fromEditCustomerPage' ? <DropZone className='float-right' onDrop={handleFileUpload} multiple={false} disabled={controller.adding | controller.state.isSaveInProgress} style={{ borderStyle: 'none', padding: 'unset' }} width={200} height={50} >
            <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
                <Button type='file' variant='success' size='sm' className='' icon='fa-upload' disabled={controller.adding | controller.state.isSaveInProgress} >Upload</Button>
        </DropZone> : null;
        
        return (
            <Page {...this.props} pageTitle='InteServ · Upload Customer Documents' id='upload-customerdocument-page'>
                <CustomerDocumentUploadPageHeader pageTitle={title} />
                <Page.Content>
                    <div>
                        <div className='row mb-2'>
                            <div className='col-6'><Form.Label name='Documents (Max. file size allowed is 25 MB)' /> </div>
                            <div className='col-6'>{upload}</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12' >
                                <Table id='documents'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Head className='bg-primary table-dark col-action' />
                                            <Table.Head className='bg-primary table-dark col-fileName'>Filename</Table.Head>
                                            <Table.Head className='bg-primary table-dark col-description'>Description</Table.Head>
                                            <Table.Head className='bg-primary table-dark col-testName'>Uploaded By</Table.Head>
                                            <Table.Head className='bg-primary table-dark col-dateTime'>Uploaded On</Table.Head>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {rows}
                                        {msg}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <Buttons controller={controller} uploadType={this.props.match.params.uploadType} screenType={this.props.match.params.fromPage} />
                </Page.Content>
            </Page>
        );
    }
}

class CustomerFileNameRow extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        Object.assign(this, Controller);
    }
    componentDidMount = () => {
        this.initializeMixin();
    }

    handleDeleteOrderNumber = () => {
        co(this, function* () {
            const result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog className='delete-confirm-dialog' header='Confirm Remove'>
                    {'Are you sure you want to remove the file'}
                </Dialog.OKCancelDialog>
            );

            if (result.OK) {
                this.props.controller.removeRow();
            }
        });
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const removeButton = this.props.screenType == 'fromEditCustomerPage' ? 
            <ActionIcon icon='fa-trash' className='remove-handle' action={this.handleDeleteOrderNumber} /> : null;
        const fldFileName = controller.state.file 
            ? <Form.Link value={controller.state.file.fileName} href={controller.state.file.imgUrl} download={controller.state.file.fileName} />
            : null;
        const description = <Table.Data className={'col-description' + this.props.key + controller.state?.file?.isCustomerStandard}>
                                <Form.Input disabled={this.props.screenType != 'fromEditCustomerPage'} bind={binder.bind('description')} placeholder='Description' />
                            </Table.Data>

        return (
            <Table.Row>
                <Table.Data className='col-action'>{removeButton}</Table.Data>
                <Table.Data className='col-fileName'>{fldFileName}</Table.Data>
                {description}
                <Table.Data className='col-testName'>{controller.state.file.uploadedBy}</Table.Data>
                <Table.Data className='col-uploadedon'>{DateTimeFormatter.formatDateTime(controller.state.file.uploadedOn)}</Table.Data>
            </Table.Row>
        )
    }
}

export default CustomerDocumentUploadPage;