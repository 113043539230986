import React from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import co from '../../lib/Co';
import DropOffTimeRow from '../pages/DropOffTimeRow';
import LoginService from '../services/LoginService';
import Table from '../../components/Table';

class RecordTimeTable extends React.Component {
  handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  handleKeyUp = (evt) => {
    try {
      const value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }
      if (value > 100000) {
        evt.target.value = 100000;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }

  render = () => {
    const controller = this.props.controller;
    const errors = controller.state.errors;
    const binder = this.props.binder;
    const LoggedInuser = LoginService.loginInfo.fullName;
    const fldHours = <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} error={errors.hours} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
    const fldMinutes = <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
    const fldTechnician = <Form.StaticData label='User' value={LoggedInuser} />

    return (
      <div id='dropOffValue-div' >
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Head className='col-minimumReading' colSpan={3}>Record Drop-Off Time</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Data>{fldTechnician}</Table.Data>
              {/* <Table.Data>{fldHours}</Table.Data> */}
              <Table.Data>{fldMinutes}</Table.Data>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

class DropOffTable extends React.Component {

  render = () => {
    const controller = this.props.controller;
    const dropOffValues = (controller.state.dropOffTimeData.length == 0 ? <Table.Row><Table.Data colSpan={4}>No Records Found</Table.Data></Table.Row> : controller.state.dropOffTimeData.map(row => <DropOffTimeRow key={row.key} controller={row} />));

    return (
      <div id='dropOffValue-div'>
        <Table id='dropOffValue-table'>
          <Table.Header>
            <Table.Row>
              <Table.Head className='col-minimumReading'>Job Number</Table.Head>
              <Table.Head className='col-minimumReading'>Transaction Date</Table.Head>
              <Table.Head className='col-minimumReading'>Technician</Table.Head>
              <Table.Head className='col-actualReading'>Time</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dropOffValues}
          </Table.Body>
        </Table>
      </div>
    );
  }
}


class DropOffTimeDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.savedropOffValues();

  render() {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const dropOffValuesTitle = 'Drop-Off Time';
    const canaddtime = controller.state.isComplete && controller.state.canaddTime;
    const actionButtons = canaddtime ? <Dialog.OKButtons /> : <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
    return (
      <Dialog {...this.props} modal={true} width={510} height={450} header={dropOffValuesTitle} id='dropOffTime-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <div className='col-12'>
              {!canaddtime && <RecordTimeTable controller={controller} binder={binder} />}
            </div>
            <div className='col-12'>
              <DropOffTable controller={controller} />
            </div>
          </div>
        </Dialog.Body>
        {actionButtons}
      </Dialog>
    );
  }
}

export default DropOffTimeDialog;