import store from 'store';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import Dialog from '../../components/Dialog';
import PageHeader from '../components/PageHeader';
import CustomerLocationController from '../controllers/CustomerLocationController';
import SelectCustomerLocationFacilitiesController from '../controllers/SelectCustomerLocationFacilitiesController';
import Utils from '../utility/Utils';
import Searchers from '../utility/Searchers';
import co from '../../lib/Co';
import SelectCustomerLocationFacilitiesDialog from '../dialogs/SelectCustomerLocationFacilitiesDialog';

const EditForm = (props) => {
  const binder = props.binder;
  const controller = props.controller;
  const customerName = controller.state.customer && controller.state.customer.name;

  return (
    <div className='col-md-12'>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Customer' className='customer-name' value={customerName} /></div>
        <div className='col-md-6'><Form.Input label='Location Name' bind={binder.bind('locationName')} className='required' /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.Input label='Address 1' bind={binder.bind('address1')} /></div>
        <div className='col-md-6'><Form.Input label='Address 2' bind={binder.bind('address2')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.Input label='City' bind={binder.bind('city')} /></div>
        <div className='col-md-6'><Form.Input label='State' bind={binder.bind('state')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.Input label='Postal Code' bind={binder.bind('postalCode')} /></div>
        <div className='col-md-6'><Form.AutoComplete label='Shale' bind={binder.bind('shale')} search={controller.search('shale')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.AutoComplete label='Region' bind={binder.bind('region')} search={controller.search('region')} /></div>
        <div className='col-md-6'><Form.AutoComplete label='Country' bind={binder.bind('country')} search={controller.search('country')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-2'><Form.Input label='Sold To' bind={binder.bind('soldTo')} /></div>
        <div className='col-md-2'><Form.Input label='Ship To' bind={binder.bind('shipTo')} /></div>
        <div className='col-md-2'><Form.Input label='Cost Center' bind={binder.bind('costCenter')} /></div>
        <div className='col-md-2'><Form.CheckBox label='TechnipFMC Location' bind={binder.bind('fmcLocation')} /></div>
        <div className='col-md-2'><Form.CheckBox label='Rental' bind={binder.bind('rental')} /></div>
        <div className='col-md-2'><Form.CheckBox label='Archived' bind={binder.bind('archived')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.AutoComplete label='User Status' bind={binder.bind('userStatus')} search={Searchers.equipmentStatusSearch()} /></div>
      </div>
    </div>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleSave = () => {
    props.controller.save(customerLocationId => {
      // after saving - navigate to same page with new id in address bar
      history.replace('/customerlocations/' + customerLocationId);
      props.controller.load(customerLocationId);
    });
  }

  const handleClose = () => {
    history.goBack();
  }

  const handleSelectFacilities = () => {
    const customerLocation = {
      id: props.controller.state.customerLocationId,
      name: props.controller.state.locationName
    };

    co(function* () {
      yield Dialog.showDialogWaitForResult(
        <SelectCustomerLocationFacilitiesDialog controller={new SelectCustomerLocationFacilitiesController(customerLocation)} header='Select Customer Location Facilities' />
      );
    });
  }

  const controller = props.controller;
  const canSave = (controller.state.customer && controller.state.customer.id && (controller.state.customer.id !== Utils.emptyGuid));
  const isSaving = controller.state.isSaving;
  const message = canSave ? '' : <div className='col-md-8 late-error'>No customer specified &ndash; You will be unable to save this customer location &ndash; Please Close and try again.</div>;
  const canSelectFacilities = !controller.adding;

  return (
    <div className='col-md-12'>
      <div>
        <div className='row edit-buttons'>
          {message}
        </div>
        <div className='edit-buttons mt-2'>
          <Button icon='fa-building' variant='info' size='sm' disabled={!canSelectFacilities} onClick={handleSelectFacilities} >Select Facilities ...</Button>
        </div>
        <div className='edit-buttons float-right mt-2' >
          <Button id='btn-save' icon='fa-save' variant='primary' size='sm' className='m-1 action-button' disabled={!canSave || isSaving} onClick={handleSave}>Save</Button>
          <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
        </div>
      </div>
    </div>
  );
}

const CustomerLocationPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class CustomerLocationPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    const customerLocationId = this.props.match.params.customerLocationId;
    const data = store.get('edit-customer-location');
    const customerId = data && data.customerId;
    const customerName = data && data.customerName;
    this.props.controller.load(customerLocationId, customerId, customerName);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  static defaultProps = {
    controller: new CustomerLocationController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const location = controller.state.locationName;
    const title = (controller.adding ? 'Create ' : 'Edit ') + 'Location' + (location ? (' — ' + location) : '');

    return (
      <Page {...this.props} pageTitle='InteServ · Edit Customer Location' id='edit-customer-location-page'>
        <CustomerLocationPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <EditForm controller={controller} binder={binder} />
            <Buttons controller={controller} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default CustomerLocationPage;
