import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button as RBButton } from 'react-bootstrap';

const ButtonIcons = (props) => {

  let icon = null;

  let prependIconName;
  if (props.icon) {
    if (props.icon.includes('fab')) {
      prependIconName = 'fab ';
    }
    else if (props.icon.includes('far')) {
      prependIconName = 'far ';
    }
    else if (props.icon.includes('fas')) {
      prependIconName = '';
    }
    else {
      prependIconName = 'fas ';
    }
  }

  if (props.icon && props.stackIcon) {
    icon = <span className='fa-stack fa-lg'>
            <i className={'fas fa-stack-2x ' + props.stackClassName + ' ' + props.stackIcon} style={{ marginLeft: -10, marginRight: 5 }} />
            <i className={'fas fa-stack-1x ' + props.icon} style={{ marginLeft: -10, marginRight: 5 }} />
          </span>;
  }
  else if (props.icon) {
    icon = <i className={prependIconName + props.icon} style={{marginRight: 5}} />;
  }

  return (icon);
}

const Button = (propsParam) => {
  let props = {...propsParam};
  if (props.to && !props.disabled) {
    props.as = Link;
  }

  return <RBButton {...props} >{<ButtonIcons icon={props.icon} stackIcon={props.stackIcon} stackClassName={props.stackClassName}/>}{props.children}</RBButton>;
}

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
  stackIcon: PropTypes.string,
  stackClassName:  PropTypes.string,
  to: PropTypes.string,
}

export default Button;