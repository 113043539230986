import React from 'react';
import $ from 'jquery'
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import emitter from '../utility/Emitter';

class MonitorInterfaceController extends BaseController {
  constructor() {
    super('monitor-interface', {
      searchText: '',
      notesSearchText: '',
      mdErrorsSearchText: '',
      searchResults: [],
      notesSearchResults: [],
      mdErrorsSearchResults: [],
      isRetryInProgress: false,
      jobNumbers: '',
      fromDate: '',
      toDate: '',
      errors: {},
      equipmentUpdateErrorSearch: '',
      equipmentUpdateSearchResults: [],
      manuDescRequestSearchText: '',
      manuDescRequestSearchResult: [],
      custDescRequestSearchText: '',
      custDescRequestSearchResult: [],
      modifiedTransJobNumbers:[],
      modifiedTransSerialNumbers:'',
      modifiedTransSearchText: '',
      tabValue: "1"
    });

    const self = this;
    this.flash = emitter();
    this._validator = new Validator();

    this._validator.add('toDate', 'To Date must be greater than From date', [{ isGreaterThanEqualsDate: { params: this.state.fromDate } }]);
    //this._validator.add('fromDate'   , 'From Date must be lesser than To date'                 , [{isLessThanEqualsDate: { params: this.state.toDate }}]);


    messages.channel('error-status').action('updated').subscribe(data => {
      const jobs = self.state.searchResults.filter(result => result.id === data.jobId);
      if ((jobs === null) || !jobs.length) {
        return;
      }

      if (data.message && data.message.action) {
        notification.action(data.message.action).post(data.message);
      }

      let job = jobs[0];
      co(self, function* () {
        try {
          const updatedJob = yield ajax.get('lookup/getJob', { jobId: job.id }); // JobLookupDto
          $.extend(job, updatedJob);
          this.commit();
          this.flash.emit(job.id);
        }
        catch (err) {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        }
      });
    });
  }

  clearSearchText = () => {
    this.state.searchText = '';
    this.commit();
    this.searchJobs();
  }
  updateTabValue = (e) => {    
    this.state.tabValue = (e!==undefined)?e:'1';  
    this.tabShow(e);
    this.commit();
  }

  clearAWOSearchText = () => {
    this.state.searchText = '';
    this.commit();
    this.searchAWOs();
  }

  searchJobs = (retryJobs) => {
    const s = this.state;
    this.state.searchResults = [];
    this.state.isLoading = true;
    this.commit();
    ajax.get('jobprogress/getFailedTransactions', { searchTerm: s.searchText }).then(jobs => {
      this.state.isLoading = false;
      jobs.map(obj => {
        obj.isSelected = false;
        obj.isRetryFailed = false;
        return obj;
      })
      if (retryJobs) {
        jobs.forEach((result) => {
          const retryJob = retryJobs.filter(job => { return job.JobNumber == result.JobNumber && job.Serial == result.Serial });
          if (retryJob.length) {
            result.isRetryFailed = true;
          }
        });
        if (jobs.filter(Retry => Retry.isRetryFailed == true).length) {
          notification.action('error').post('Retry process failed for the highlighted rows in red');
        }
        else {
          notification.action('success').post('Retry process completed successfully');
        }
      }
      this.state.searchResults = jobs;
      this.commit();
    }).catch(err => {
      this.state.isLoading = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }


  
  searchAWOs = (retryAWOs) => {
    const s = this.state;
    this.state.searchResults = [];
    this.state.isLoading = true;
    this.commit();
    ajax.get('awoprogess/getFailedAWOTransactions', { searchTerm: s.searchText }).then(jobs => {
      this.state.isLoading = false;
      jobs.map(obj => {
        obj.isSelected = false;
        obj.isRetryFailed = false;
        return obj;
      })
      if (retryAWOs) {
        jobs.forEach((result) => {
          const retryJob = retryAWOs.filter(awo => { return awo.AWONumber == result.AWONumber && awo.Serial == result.Serial });
          if (retryJob.length) {
            result.isRetryFailed = true;
          }
        });
        if (jobs.filter(Retry => Retry.isRetryFailed == true).length) {
          notification.action('error').post('Retry process failed for the highlighted rows in red');
        }
        else {
          notification.action('success').post('Retry process completed successfully');
        }
      }
      this.state.searchResults = jobs;
      this.commit();
    }).catch(err => {
      this.state.isLoading = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }

  retryMessage = (job) => {
    this.state.isRetryInProgress = true;
    this.commit();
    ajax.post('jobprogress/processFailedTransactions', job).then(result => {
      if (result) {
        this.searchJobs(job);
        this.state.isRetryInProgress = false;
        this.commit();
      }
    }).catch(err => {
      this.state.isRetryInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }

  export = () => {
    const s = this.state;
    ajax.get('jobprogress/exportFailedTransactionsAsExcel', { searchTerm: s.searchText }).then(pdfData => {
      const blob = Utils.convertBase64toBlob(pdfData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      const fileName = 'Monitor Transaction.xlsx';
      const fileURL = URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.download = fileName;
      if (navigator.userAgent.match(/iPad/i) != null) {
        window.open(fileURL);
      }
      else {
        tempLink.click();
      }
      tempLink.remove();
      notification.action('success').post('Exported successfully');
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
  exportMonitorTransactions = () => {
    const s = this.state;    
  var parameters = {
    jobNumberIds: s.modifiedTransJobNumbers ? s.modifiedTransJobNumbers.map(number => number.name) : '',
    serialNumbers: s.modifiedTransSerialNumbers ? s.modifiedTransSerialNumbers : ''
  }
    ajax.post('jobprogress/exportModifiedTransactionsAsExcel', parameters).then(pdfData => {
      const blob = Utils.convertBase64toBlob(pdfData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      const fileName = 'Modified Transactions.xlsx';
      const fileURL = URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.download = fileName;
      if (navigator.userAgent.match(/iPad/i) != null) {
        window.open(fileURL);
      }
      else {
        tempLink.click();
      }
      tempLink.remove();
      notification.action('success').post('Exported successfully');
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
  selectJobRow = (job) => {
    let results = this.state.searchResults;
    const resultFilter = results.filter(Api => Api.isSelected == true);
    const resultFilterApi = resultFilter.length ? resultFilter[0].API : '';
    const jobFilterApi = job.length ? job[0].API : '';
    if (jobFilterApi == resultFilterApi || resultFilterApi == '') {
      results.forEach((result) => {
        const asset = job.find(item => result.JobNumber === item.JobNumber && result.API === item.API && result.Serial === item.Serial && result.ServiceOrderNumber === item.ServiceOrderNumber);
        if (asset) {
          result.isSelected = true;
        }
        else {
          result.isSelected = false;
        }
      });
      this.state.searchResults = results;
    }
    else {
      notification.action('warning').post('Please select the transaction with similar API type');
    }

    this.commit();
  }


  
  selectAWORow = (awo) => {
    let results = this.state.searchResults;
    const resultFilter = results.filter(Api => Api.isSelected == true);
    const resultFilterApi = resultFilter.length ? resultFilter[0].API : '';
    const jobFilterApi = awo.length ? awo[0].API : '';
    if (jobFilterApi == resultFilterApi || resultFilterApi == '') {
      results.forEach((result) => {
        const asset = awo.find(item => result.AWONumber === item.AWONumber && result.API === item.API && result.Serial === item.Serial && result.ServiceOrderNumber === item.ServiceOrderNumber);
        if (asset) {
          result.isSelected = true;
        }
        else {
          result.isSelected = false;
        }
      });
      this.state.searchResults = results;
    }
    else {
      notification.action('warning').post('Please select the transaction with similar API type');
    }

    this.commit();
  }

  selectManDescRequestRow = (job) => {
    let results = this.state.manuDescRequestSearchResult;
    results.forEach((result) => {
      const asset = job.find(item => result.MfgPart === item.MfgPart && result.ManDescRequestGuid === item.ManDescRequestGuid && result.ManDescGUID === item.ManDescGUID);
      if (asset) {
        result.isSelected = true;
      }
      else {
        result.isSelected = false;
      }
    });
    this.state.manuDescRequestSearchResult = results;
    this.commit();
  }

  selectCustDescRequestRow = (job) => {
    let results = this.state.custDescRequestSearchResult;
    results.forEach((result) => {
      const asset = job.find(item => result.CustomerDescription === item.CustomerDescription && result.CustDescRequestGuid === item.CustDescRequestGuid && result.AddedByGUID === item.AddedByGUID);
      if (asset) {
        result.isSelected = true;
      }
      else {
        result.isSelected = false;
      }
    });
    this.state.custDescRequestSearchResult = results;
    this.commit();
  }

  selectNone = () => {
    this.state.searchResults.forEach((row) => { row.isSelected = false });
    this.commit();
  }

  clearSearchCriteria = () => {
    this.state.toDate = '';
    this.state.fromDate = '';
    this.state.jobNumbers = [];
    // $('#jobNumber-multiSelect').select2('data', []);
    this.state.notesSearchResults = [];
    this.commit();
  }
  clearModifiedTransSearchCriteria = () => {
    this.state.modifiedTransJobNumbers = [];
    this.state.modifiedTransSerialNumbers = '';
    this.state.modifiedTransactionResults = [];
    this.commit();
  }
  searchTransactionalNotes = () => {
    if (!this.state.notesSearchText) {
      notification.action('error').post({ msg: 'Please enter some search text', title: 'Input Required' });
      return false;
    }
    const s = this.state;
    ajax.get('lookup/searchTransactionalNotes', { searchTerm: s.notesSearchText }).then(notes => {
      this.state.notesSearchResults = notes || [];
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  searchEquipmentUpdateErrors = () => {
    const s = this.state;
    ajax.get('lookup/searchEquipmentUpdateError', { searchTerm: s.equipmentUpdateErrorSearch }).then(errors => {
      this.state.equipmentUpdateSearchResults = errors || [];
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  canSearchNotesWithJob = () => {
    const self = this;
    this._validator.validateAll(self.state);
    const errors = Object.keys(self.state.errors);
    if (!errors.length) {
      return true;
    }
    self.commit();
    return false;
  }
  searchModifiedTransactions = () => { console.log('logged');
  if (!this.state.modifiedTransSearchText){
    notification.action('error').post({ msg: 'Please enter search term', title: 'Input Required' });
    return false;
  }

  const s = this.state;
  ajax.get('lookup/searchModifiedTransactions', { searchTerm: s.modifiedTransSearchText }).then(result => {
    this.state.modifiedTransactionResults = result || [];
    this.commit();
  }).catch(err => {
    notification.action('error').post({ msg: err.message, title: 'Error' });
  });
}

searchModifiedTransactionsWithJobAndSerial = () => {
  if (!this.state.modifiedTransJobNumbers && !this.state.modifiedTransSerialNumbers) {
    notification.action('error').post({ msg: 'Please select Job Number or Serial Numbers', title: 'Input Required' });
    return false;
  }
  const s = this.state;
  var parameters = {
    jobNumberIds: s.modifiedTransJobNumbers ? s.modifiedTransJobNumbers.map(number => number.name) : '',
    serialNumbers: s.modifiedTransSerialNumbers ? s.modifiedTransSerialNumbers : ''
  }
  ajax.post('lookup/searchModifiedTransactionsWithJobAndSerial', parameters).then(result => {
    this.state.modifiedTransactionResults = result || [];
    this.commit();
  }).catch(err => {
    notification.action('error').post({ msg: err.message, title: 'Error' });
  });
}

loadData = (data) => {
  this.state.modifiedTransJobNumbers = [];
  this.state.modifiedTransSerialNumbers = '';
  if(data)
  {
    const { jobNo,  selectedAssets} = data;
    this.state.modifiedTransJobNumbers.push({ "name" : jobNo });
    this.state.modifiedTransSerialNumbers = selectedAssets;
    this.searchModifiedTransactionsWithJobAndSerial();
  }
}
  searchTransactionalNotesWithJob = () => {
    if (!this.state.jobNumbers && !this.state.fromDate && !this.state.toDate) {
      notification.action('error').post({ msg: 'Please select Job Number or Date range', title: 'Input Required' });
      return false;
    }
    const fromDate = new Date(this.state.fromDate);
    const toDate = new Date(this.state.toDate);
    if ((!this.state.toDate && this.state.fromDate) || (this.state.toDate && !this.state.fromDate)) {
      notification.action('error').post({ msg: 'Both From date and To date are required', title: 'Date range error' });
      return false;
    }
    if (this.state.toDate && this.state.fromDate && !(toDate >= fromDate)) {
      notification.action('error').post({ msg: 'To Date should be greater than or equal to From Date', title: 'Date range error' });
      return false;
    }

    // if (!this.canSearchNotesWithJob()) {
    //   return;
    // }
    const s = this.state;
    var parameters = {
      jobNumberIds: s.jobNumbers ? s.jobNumbers.map(number => number.id) : '',
      fromDate: s.fromDate,
      toDate: s.toDate
    }
    ajax.post('lookup/searchTransactionalNotesWithJob', parameters).then(notes => {
      this.state.notesSearchResults = notes || [];
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  searchMdErrors = () => {
    const s = this.state;
    ajax.get('lookup/searchMasterDataErrors', { searchTerm: s.mdErrorsSearchText }).then(errors => {
      this.state.mdErrorsSearchResults = errors || [];
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  searchManuDescRequest = () => {
    const s = this.state;
    ajax.get('lookup/searchManuDescRequest', { searchTerm: s.manuDescRequestSearchText }).then(requests => {
      this.state.isLoading = false;
      requests.map(obj => {
        obj.isSelected = false;
        return obj;
      })
      this.state.manuDescRequestSearchResult = requests || [];
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  searchCustDescRequest = () => {
    const s = this.state;
    ajax.get('lookup/searchCustDescRequest', { searchTerm: s.custDescRequestSearchText }).then(requests => {
      this.state.isLoading = false;
      requests.map(obj => {
        obj.isSelected = false;
        return obj;
      })
      this.state.custDescRequestSearchResult = requests || [];
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  clearNotesSearchText = () => {
    this.state.modifiedTransSearchText = '';
    this.state.notesSearchText = '';
    this.state.notesSearchResults = [];
    this.commit();
  }
  clearModifiedTransSearchText= () => {
    this.state.modifiedTransSearchTesearcxt = '';
    this.state.modifiedTransactionResults = [];
    this.commit();
  }

  clearManuDescSearchText = () => {
    this.state.manuDescRequestSearchText = '';
    this.state.manuDescRequestSearchResult = [];
    this.commit();
    this.searchManuDescRequest();
  }

  clearCustuDescSearchText = () => {
    this.state.custDescRequestSearchText = '';
    this.state.custDescRequestSearchResult = [];
    this.commit();
    this.searchCustDescRequest();
  }

  clearMdErrorsSearchText = () => {
    this.state.mdErrorsSearchText = '';
    this.state.mdErrorsSearchResults = [];
    this.commit();
    this.searchMdErrors();
  }

  clearEquipmentUpdateErrorSearch = () => {
    this.state.equipmentUpdateErrorSearch = '';
    this.state.equipmentUpdateSearchResults = [];
    this.commit();
  }

  DeleteManDescRequest = (manDescRequestGuid) => {
    ajax.post('manufacturerDescription/deleteRequest', manDescRequestGuid).then(request => { this.searchManuDescRequest(); })
  };

  DeleteCustDescRequest = (manDescRequestGuid) => {
    ajax.post('customerDescription/DeleteRequest', manDescRequestGuid).then(request => { this.searchCustDescRequest(); })
  };

  tabShow(tabId) {
    if (tabId === '1') {
      this.clearSearchText();
    }
    else if (tabId === '3') {
      this.searchMdErrors();
    }
    else if (tabId === '4') {
      this.searchEquipmentUpdateErrors();
    }
    else if (tabId === '5') {
      this.searchModifiedTransactions();
    }
    else if (tabId === '6') {
      this.searchManuDescRequest();
    }
    else if (tabId === '7') {
      this.searchCustDescRequest();
    }
    else if (tabId === '8') {
      this.clearAWOSearchText();
    }
  }

  
  retryAWOTransaction = (job) => {
    this.state.isRetryInProgress = true;
    this.commit();
    ajax.post('awoprogess/processFailedAWOTransactions', job).then(result => {
      if (result) {
        this.searchAWOs(job);
        this.state.isRetryInProgress = false;
        this.commit();
      }
    }).catch(err => {
      this.state.isRetryInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
  redirectToAssembly = (awoId,cb)=>{
    const data = {awoId: awoId};   
      messages.channel('apply-assembly-levels').action('assign-data').post(data);
      cb();
  }
  exportAWOs = () => {
    const s = this.state;
    ajax.get('awoprogess/exportFailedAWOTransactionsAsExcel', { searchTerm: s.searchText }).then(pdfData => {
      const blob = Utils.convertBase64toBlob(pdfData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      const fileName = 'Failed AWO Transaction.xlsx';
      const fileURL = URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.download = fileName;
      if (navigator.userAgent.match(/iPad/i) != null) {
        window.open(fileURL);
      }
      else {
        tempLink.click();
      }
      tempLink.remove();
      notification.action('success').post('Exported successfully');
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default MonitorInterfaceController;
