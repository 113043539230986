import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import GroupBadge from '../../components/GroupBadge';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';

const Facility = (props) => {
    const selectCurrentFacility = (facility) => {
        if (!props.userFacility) {
            facility.selected = true;
            props.controller.moveToSelected();
            return;
        }
        props.controller.selectCurrentFacility(facility);
    }

    const toggleSelection = (facility) => {
        const isCurrentFacility = props.userFacility && (props.controller.state.currentFacility === facility);
        if (!isCurrentFacility) {
            props.controller.toggleSelection(facility);
        }
    }

    const facility = props.facility;
    const controller = props.controller;
    const isCurrentFacility = props.userFacility && controller.state.currentFacility && (controller.state.currentFacility.id === facility.id);
    const className = 'facility' + (isCurrentFacility ? ' current' : '');
    const spanSAP = facility.useSAP ? <span className='user-facility-sap'>SAP</span> : null;
    const spanArchived = facility.archived
        ? <span><i className='fa fa-archive archived' />&nbsp;&nbsp;</span>
        : null;

    return (
        <List.Item className={className + ' p-2'} active={facility.selected} onClick={() => { toggleSelection(facility); }} onDoubleClick={() => { selectCurrentFacility(facility); }}  style={{ cursor: isCurrentFacility ? 'not-allowed' : 'pointer' }} variant={isCurrentFacility ? 'danger' : '' }>{spanArchived} {facility.name} {spanSAP}</List.Item>
    );
}



const UserFacilities = (props) => {
    const selectNone = () => {
        props.controller.selectNoUserFacilities();
    }

    const selectAll = () => {
        props.controller.selectAllUserFacilities();
    }

    const controller = props.controller;
    const userFacilities = controller.state.userFacilities || [];
    const selectedLabel = userFacilities.length + ' selected ' + ((userFacilities.length === 1) ? 'facility' : 'facilities');

    return (
        <div>
            <div className='row'>
                <div className='col'>
                    <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
                    <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
                    <Badge className='m-1' variant='info'>{selectedLabel}</Badge>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Jumbotron className='facilities-list'>
                        <List id='selected-facility-list'>
                            {
                                userFacilities.map(facility => {
                                    return <Facility controller={controller} facility={facility} userFacility={true} />;
                                })
                            }
                        </List>
                    </Jumbotron>
                </div>
            </div>            
        </div>
    );
}

const TransferButtons = (props) => {

    const select = () => {
        props.controller.moveToSelected();
    }

    const unselect = () => {
        props.controller.removeFromSelected();
    }

    const selectDisabled = false;

    const unselectDisabled = false;

    return (
        <div className='row'>
            <div className='col'>
                <div>
                    <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
                    <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect} >Unselect</Button>
                </div>
            </div>
        </div>
    );
}

const AllFacilities = (props) => {
    const selectNone = () => {
        props.controller.selectNoFacilities();
    }

    const selectAll = () => {
        props.controller.selectAllFacilities();
    }

    const controller = props.controller;
    const allFacilities = controller.state.allFacilities || [];
    const availableLabel = allFacilities.length + ' available ' + ((allFacilities.length === 1) ? 'facility' : 'facilities');

    return (
        <div>
            <div className='row'>
                <div className='col'>
                    <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
                    <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
                    <Badge className='m-1' variant='info'>{availableLabel}</Badge>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Jumbotron className='facilities-list'>
                        <List id='facility-list'>
                            {
                                allFacilities.map(facility => {
                                    return <Facility controller={controller} facility={facility} />;
                                })
                            }
                        </List>
                    </Jumbotron>
                </div>
            </div>            
        </div>
    );
}

class SelectUserFacilitiesDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel ? true : this.props.controller.save();

    render = () => {
        const controller = this.props.controller;
        const labelText = 'Select the facilities to which {firstName} {lastName} should have access:'.interpolate(controller.state.user);

        return (
            <Dialog {...this.props} header={this.props.header} width={750} height={600} id='change-user-facilities-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div className='row'>
                        <div className='col'>
                            <div className='row'><div className='col h6'>{labelText}</div></div>
                            <div className='row align-items-center'>
                                <div className='col-md-5'><AllFacilities controller={controller} /></div>
                                <div className='col-md-2'><TransferButtons controller={controller} /></div>
                                <div className='col-md-5'><UserFacilities controller={controller} /></div>
                            </div>
                        </div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons />
            </Dialog>
        );
    }
}

export default SelectUserFacilitiesDialog;