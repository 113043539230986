import BaseController from '../../lib/BaseController';

class AssetChartController extends BaseController {
  constructor(assetChart) {
    super('asset-chart', {
      assetChart : assetChart
    });
  }
}

export default AssetChartController;
