import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import Form from '../../components/Form';
import ActionIcon from '../../components/ActionIcon';
import NotificationBell from '../components/NotificationBell';
import UserProfile from '../components/UserProfile';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import styled from 'styled-components';
import AppConstants from '../utility/AppConstants';

const ManHoursStyles = styled.div`
.field-label{
    font-size: 0.750rem !important;
    margin-bottom: unset;
  }
  .field-data{
    font-size: 0.750rem;
  }
  .form-group{
    margin-bottom: unset;
  }
`;

export const ManHours = () => {

  const GetAvailableTime = () => {
    const totalBreakTime = LoginService.loginInfo ? LoginService.loginInfo.totalBreakTime : 0;
    const totalProductiveTime = LoginService.loginInfo ? LoginService.loginInfo.totalProductiveTime : 0;
    const totalTime = DateTimeFormatter.getDuration(LoginService.loginInfo && LoginService.loginInfo.clockedInTime, LoginService.loginInfo && LoginService.loginInfo.isBreakOut ? LoginService.loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()));
    const availableTime = parseFloat(totalTime - (totalBreakTime + totalProductiveTime)).toFixed(1);
    return availableTime;
  }

  const GetWorkingTime = () => {
    const totalBreakTime = LoginService.loginInfo ? LoginService.loginInfo.totalBreakTime : 0;
    const totalTime = DateTimeFormatter.getDuration(LoginService.loginInfo && LoginService.loginInfo.clockedInTime, LoginService.loginInfo && LoginService.loginInfo.isBreakOut ? LoginService.loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()));
    const workingTime = totalTime - totalBreakTime;
    return workingTime;
  }

  const [state, setState] = useState({ availableTime: GetAvailableTime(), workingTime: GetWorkingTime() });

  const updateTimer = () => {
    const loginInfo = LoginService.loginInfo;
    const totalTime = DateTimeFormatter.getDuration(loginInfo && loginInfo.clockedInTime, DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()));
    if (totalTime <= 960) {
      setState({ availableTime: GetAvailableTime(), workingTime: GetWorkingTime() });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => updateTimer(), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const loginInfo = LoginService.loginInfo;
  const ClockedInTime = loginInfo && loginInfo.clockedInTime;
  const availableTimeInMin = state.availableTime;
  const availableTimeInHrs = Utils.convertMinsToHrsMins(availableTimeInMin);
  const workingTimeInMin = state.workingTime;
  const workingTimeInHrs = Utils.convertMinsToHrsMinsHeader(workingTimeInMin);
  const dollerIcon = (loginInfo && loginInfo.isClockedIn && !loginInfo.isClockedOut)
    ? !LoginService.loginInfo.isBreakOut
      ? <span className='fa-stack fa-sm'>
        <span className='fas fa-circle fa-stack-2x page-header-coin' style={{ color: '#FFCD00' }} />
        <span className='fas fas fa-dollar-sign fa-stack-1x page-header-doller' style={{ color: '#907300' }} />
        <span className='far fa-circle fa-stack-2x page-header-coin' style={{ color: '#DCAF00' }} />
      </span>
      : <span className='fa-stack fa-sm'>
        <span className='fas fa-circle fa-stack-2x page-header-coin' style={{ color: '#FFCD00' }} />
        <span className='fas fas fa-dollar-sign fa-stack-1x page-header-doller' style={{ color: '#907300' }} />
        <span className='fas fa-ban fa-stack-2x text-danger' />
      </span>
    : null

  const clockinInfo = (loginInfo && loginInfo.isClockedIn && !LoginService.loginInfo.isClockedOut)
    ? <Form.StaticData label='Clocked In @' value={ClockedInTime} />
    : ((loginInfo && loginInfo.isClockedIn && LoginService.loginInfo.isClockedOut) ? <Form.StaticData value='Clocked out for the day' /> : <Form.StaticData value='Not clocked in' />);

  const availableTimeClock = (loginInfo && loginInfo.isClockedIn && !LoginService.loginInfo.isClockedOut)
    ? <Form.StaticData label='Total Paid/Available Time' value={workingTimeInHrs + '(hh:mm)' + '/' + availableTimeInMin + ' min'} />
    : null;
  return (
    <ManHoursStyles className='d-flex'>
      <span className='mr-2 mt-1 man-hours-doller'>{dollerIcon}</span>
      <span className='mr-2 man-hours-clockinInfo' style={{ fontSize: '0.850rem' }} >{clockinInfo}</span>
      <span className='mr-2 man-hours-availableTimeClock' style={{ fontSize: '0.850rem' }}>{availableTimeClock}</span>
    </ManHoursStyles>
  );
}

const NavbarBrandStyle = styled.span`
  .back-navigation{
    cursor: pointer; 
    color: #249F93;
    font-size: 2rem !important; 
    margin-right: 0.2rem;
  }

  .page-icon{
    cursor: pointer; 
    font-size: 1.3rem !important;
  }

  .page-title{
    cursor: pointer;
    font-size: 1.2rem;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) { 
    .page-title-container{
      max-width: calc(43vw);
    }  
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {  
    .page-title-container{
      max-width: calc(60vw);
    }  
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {  
    .page-title-container{
      max-width: calc(20vw);
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) and (max-width: 1199.98px) {  
    .page-title-container{
      max-width: calc(19vw);
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    .page-title-container{
      max-width: calc(32vw);
    }
   }
`;

const PageHeader = (defaultProps) => {
  const history = useHistory();

  const props = {
    showBackNavigation: false,
    isAdvanced: true,
    ...defaultProps
  };

  const handleBack = () => {
    history.goBack();
  };

  const manHours = SAPService.isSAPFacility() && SAPService.useTTS() ? <ManHours /> : null;
  let ctlPasswordWarning = null;
  const loginInfo = LoginService.loginInfo;
  if (loginInfo && loginInfo.daysUntilPasswordExpires < AppConstants.PasswordExpirationWarningDays) {
    const message = LoginService.passwordExpirationMessage(loginInfo.daysUntilPasswordExpires);
    ctlPasswordWarning = <ActionIcon className='text-danger' icon='fas fa-key pulse' style={{ fontSize: '1.5rem' }} size='' title={message} onClick={() => LoginService.showPasswordExpirationWarning(loginInfo.daysUntilPasswordExpires)} />
  }

  return (
    <Page.Header {...props}>
      <NavbarBrandStyle className='navbar-brand d-flex'>
        {props.showBackNavigation ? <ActionIcon icon='fa-arrow-alt-circle-left' className='back-navigation' action={handleBack} size='' /> : null}
        <span className='page-title-container d-inline-block text-truncate'>
          <ActionIcon className='page-icon' icon={props.icon} size='' />
          <span className='page-title' title={props.title}> {props.title} </span>
        </span>
      </NavbarBrandStyle>
      {props.isAdvanced ? <div className='d-none d-md-block'>{manHours}</div> : null}
      {props.isAdvanced ? <NotificationBell /> : null}
      {props.isAdvanced ? ctlPasswordWarning : null}
      {props.isAdvanced ? <UserProfile /> : null}
    </Page.Header>
  );
}

export default PageHeader;