import React, { useState, useEffect } from 'react';
import './scss/App.scss';
import AppRouter from '../src/app/AppRouter';
import Loader from './components/Loader';
import Disposable from './mixins/Disposable';
import messages from './services/Messages';

const App = Disposable((props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let counter = 0;
    props.using(messages.channel('loader').action('update').subscribe(flag => {
      if (flag) {
        counter++;
      }
      else {
        if(counter > 0){
          counter--;
        }
      }
      setLoading(counter);
    }));
    return () => {
      props.terminate();
      counter = 0;
    };
  }, []);

  return (
    <div className='App'>
      <Loader loaded={!loading} allowBackgroundOperation={true}/>
      <AppRouter />
      <div id='popup' />
      <div id='childPopup' />
    </div>
  );
});

export default App;