import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import SAPService from '../services/SAPService';
import Table from '../../components/Table';

class SerialNumberRow extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClickAddedSerialNumber = () => {
        this.props.controller.select();
    }

    handleRemoveFromList = () => {
        this.props.controller.remove();
    }

    handleSpaceKey = (evt) => {
        if (evt.which == 32) {
            evt.preventDefault();
        }
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const errors = controller.state.errors;
        const className = controller.isSelected()
            ? 'selected'
            : '';
        const isSAPFacility = SAPService.isSAPFacility();
        let style = {};
        if (!controller.state.isPrimaryUnique) {
            style.primary = {
                backgroundColor: '#F55C5C'
            };

            style.primaryCustomer = {
                color: '#A94442',
                fontSize: '0.9rem !important',
                fontWeight: 'normal !important'
            };
        }
        if (!controller.state.isSecondaryUnique) {
            style.secondary = {
                backgroundColor: 'yellow'
            };
        }
        const EqupNum = <Table.Data className={className} style={style.secondary}><Form.NumberInput bind={binder.bind('equipmentNumber')} maxLength="8" readOnly={false} /></Table.Data>;
        return (
            <Table.Row onClick={this.handleClickAddedSerialNumber}>
                <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle' action={this.handleRemoveFromList} /></Table.Data>
                <Table.Data className={className} style={style.primary} onKeyDown={this.handleSpaceKey}><Form.Input bind={binder.bind('primarySerialNumber')} readOnly={false} error={errors.primarySerialNumber} /></Table.Data>
                <Table.Data className={className} style={style.primaryCustomer}><Form.StaticData value={controller.state.customerName} /></Table.Data>
                <Table.Data className={className} style={style.primaryCustomer}><Form.StaticData value={controller.state.currentLocationName} /></Table.Data>
                <Table.Data className={className} style={style.secondary}><Form.Input bind={binder.bind('secondarySerialNumber')} readOnly={false} /></Table.Data>
                {EqupNum}
                <Table.Data className={className} ><Form.Input bind={binder.bind('batchNumber')} maxLength='250' readOnly={false} /></Table.Data>
                <Table.Data className={className}><Form.Date bind={binder.bind('bornOnDate')} error={errors.bornOnDate} /></Table.Data>
            </Table.Row>
        );
    }
}

export default SerialNumberRow;