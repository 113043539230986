import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import FileNameRowController from '../controllers/FileNameRowController';

class JobAttachmentController extends BaseController {
    constructor() {
        super('job-attachment', JobAttachmentController.getInitialState());

        this.adding = false;
        this.applications = [];
    }

    static getInitialState = () => {
        const initialState = {
            files: [],
            filelink :  `${process.env.REACT_APP_API_SSL}://${process.env.REACT_APP_API_HOST_NAME}${process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : ''}/${process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : ''}${process.env.REACT_APP_API}/lookup/getJobAttachmentById/`,
        };
        return initialState;
    }

    load = (jobId, jobNumber) => {
        if (jobId === Utils.emptyGuid) {
            this.adding = true;
            this.state = JobAttachmentController.getInitialState(jobId);
            this.commit();
        }
        else {
            this.adding = false;
            this.state.files = [];
            this.state.jobNumber = jobNumber;
            this.commit();
            co(this, function* () {
                var results = yield ajax.get('lookup/getjobattachments', { jobId: jobId }); // UpdateJobDto
                results.jobNumber = jobNumber;
                this.state.files = [];
                this.commit();
                this.mergeJob(results);
                this.commit();
            });
        }
    }

    mergeJob = (job) => {
        this.state.jobId = job.jobId;
        this.state.jobNumber = job.jobNumber;
        job.files.forEach(file => {
            this.addNewFile(file);
        });
        this.commit();
    }

    save = (cb) => {
        const s = this.state;
        const jobId = s.jobId;
        const parameters = {};
        if (!this.adding) {
            parameters.jobId = jobId;
            parameters.files = s.files.map(f => f.state.file);
        }
        this.state.isSaveInProgress = true;
        this.commit();
        co(this, function* () {
            try {
                yield ajax.post('jobprogress/saveattachment', parameters); // in: UpdateJobDto, out: void
                const saveType = parameters.files.filter(file => file.recordStatus != 'UC');
                saveType.map(file => {
                    const text = file.recordStatus == 'A' ? 'Saved ' + file.fileName + ' attachment for ' : 'Removed ' + file.fileName + ' attachment from ';
                    notifications.action('success').post(text + 'Job with Job Number ' + this.state.serialNumber);
                })

                cb();
                this.state.isSaveInProgress = false;
                this.commit();
                this.load(this.state.jobId, this.state.jobNumber);
            }
            catch (err) {
                notifications.action('error').post({ msg: err.message, title: 'Error saving attachment' });
                this.state.isSaveInProgress = false;
                this.commit();
            }
        });
    }

    uploadFile = (res) => {
        if (this.state.files.find(f => f.state.file.fileName === res.file.name)) {
            notifications.action('error').post({ msg: 'File name already exists', title: 'File Error' });
            return;
        }
        if (res && (res.file.size / 1048576).toFixed(1) < 25) {
            let name = '';
            if (navigator.userAgent.match(/iPad/i) != null) {
                name = res.file.name.split('.')[0] + '_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' }) + '.' + res.file.name.split('.')[1].toLowerCase();
            }
            else {
                name = res.file.name;
            }
            const newFile = {
                id: Utils.newGuid(),
                fileName: name,
                size: res.file.size,
                altText: '',
                caption: '',
                file: res.file,
                fileType: res.file.name.split('.')[1].toLowerCase() || '',
                MimeType: res.file.type,
                imgUrl: res.imageUrl,
                recordStatus: 'A'
            };
            this.addNewFile(newFile);
            this.save(() => { });
        }
        else {
            notifications.action('error').post({ msg: 'File size is larger than 25 MB', title: 'File Size Error' });
        }
    }

    addNewFile = (file) => {
        this.state.files.push(new FileNameRowController(this, file));
        this.commit();
    }

    deleteFile = (file) => {
        const idx = this.state.files.indexOf(file);
        if (idx === -1) {
            return;
        }
        this.state.files.splice(idx, 1);
        this.commit();
    }
    getFile=(file)=>{
        co(this, function* () {
            var results = yield ajax.get('lookup/getJobAttachmentById', { fileId: file.state.file.id });
        const fileName = results.files[0].fileName;
          const tempLink = document.createElement('a');
          tempLink.href = results.files[0].imgUrl;
          tempLink.download = fileName;
          tempLink.click();
          tempLink.remove();
    });
    }
}

export default JobAttachmentController;
