import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Dialog from '../../components/Dialog';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LateTimeEntryController from '../controllers/LateTimeEntryController';
import AppConstants from '../utility/AppConstants';

class LateTimeEntryDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new LateTimeEntryController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => {
        if (result.Cancel) {
            return true;
        }
        return this.props.controller.saveLateEntry();
    }

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const ClockInTitle = 'Late Time Entry';
        const errors = controller.state.errors;
        
        return (
            <Dialog {...this.props} allowKeyDownEvent={true} modal={true} width={480} height={380} header={ClockInTitle} id='lateentry-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='latetime-dialog'>
                        <form role='form'>
                            <div className='row'>
                                <div className='col-12'><Form.Date label='Shift Start Date:' className='required' bind={binder.bind('shiftStartDate')} error={errors.shiftStartDate} autofocus='false' defaultValue={DateTimeFormatter.formatDate(DateTimeFormatter.today())} options={{ startDate: DateTimeFormatter.formatDate(DateTimeFormatter.yesterday()), endDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),  }} /></div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'><Form.NumberInput label='Hours' type='number' bind={binder.bind('hours')} min='0' max='12' maxLength='2' /></div>
                                <div className='col-sm-4'><Form.NumberInput label='Minutes' type='number' bind={binder.bind('minutes')} min='0' max='59' /></div>
                                <div className='col-sm-4'><Form.AutoComplete label='Period' bind={binder.bind('period')} search={controller.periodSearch()} allowClear={false} /></div>
                            </div>
                            <div className='row'>
                                <div className='col-12'> <Form.TextArea id='reason' className='required' label='Reason' bind={binder.bind('reason')} error={errors.reason} maxLength='132' /></div>
                            </div>                            
                        </form>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons saving={controller.saving} />
            </Dialog>
        );
    }
}

export default LateTimeEntryDialog;