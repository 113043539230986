import React, { useMemo, useEffect } from 'react';
import ActionIcon from '../../components/ActionIcon';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import PageHeader from '../components/PageHeader';
import CustomerDescriptionController from '../controllers/CustomerDescriptionController';
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Search from '../components/Search';
import Jumbotron from '../../components/Jumbotron';

const SearchHeader = (props) => {

  const handleSearch = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    props.controller.handleSearch();
  }

  const handleKeyboardSearch = (evt) => {
    evt.stopPropagation();
    if (evt.which === 13) {
      props.controller.handleSearch();
    }
  }

  const clearSearch = () => {
    props.controller.clearSearchCriteria();
  }

  const binder = props.binder;
  const controller = props.controller;
  const searchLabel = <div><span>Enter search text</span><span className='field-hint font-italic' style={{ fontSize: '0.799rem', fontWeight: '400'}}>&nbsp;&nbsp;Use commas to separate multiple criteria</span></div>

  return (
    <div id='search-criteria'>
      <div className='row'>
        <div className='col-md-8'><Form.AutoComplete label='Customer' className='required' bind={binder.bind('customer')} search={controller.customerSearch()} /></div>
      </div>
      <div className='row'>
        <div className='search-container col-md-8'>
          <div className='' onKeyDown={handleKeyboardSearch} >
          <Search className='mb-2' id='searchText' inputClassName='search-input' label={searchLabel} placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} showSearchButton={false}></Search>
            {/* <h5><i className='fa fa-search' />&nbsp;&nbsp;Enter search text&nbsp;&nbsp;</h5><span className='field-hint font-italic'>Use commas to separate multiple criteria</span> 
            <Form.Input id='searchText' label={searchLabel} prependLabelIcon='fa-search' className='search-input' placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} />
            <ActionIcon icon='fa-times-circle' className='fa-2x clearable' action={clearSearch} />*/}
          </div>
        </div>
        <div className='col-md-4 form-group mt-3'>
          <div>&nbsp;</div>
          <span style={{ cursor: 'pointer' }}><a className='simple-link' onClick={clearSearch}>Clear search criteria</a></span>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3'><Form.AutoComplete label='Family Code' bind={binder.bind('familyCode')} search={controller.search('family code')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Nominal Size' bind={binder.bind('nominalSize')} search={controller.search('nominal size')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Length' bind={binder.bind('length')} search={controller.search('length')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Style' bind={binder.bind('style')} search={controller.search('style')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.AutoComplete label='Manufacturer' bind={binder.bind('manufacturer')} search={controller.search('manufacturer')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={controller.search('cwp')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Service' bind={binder.bind('service')} search={controller.search('service')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Degree' bind={binder.bind('degree')} search={controller.search('degree')} /></div>
      </div>
      <div className='row align-items-center'>
        <div className='col-md-3'><Form.AutoComplete label='ID' bind={binder.bind('id')} search={controller.search('id')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='OD' bind={binder.bind('od')} search={controller.search('od')} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Mfg Part #' bind={binder.bind('mfgItemNo')} search={controller.mfgItemSearch()} /></div>
        <div className='col-md-3'><Button icon='fa-search' variant='info' size='sm' className='btn-block' onClick={handleSearch}>Search</Button></div>
      </div>
    </div >
  );
}

const ResultsList = (props) => {

  const handleRowClick = (item) =>{
    props.controller.selectItem(item);
  }

  const handleDelete = (item) => {
    props.controller.delete(item);
  }

  const controller = props.controller;
  const data = controller.state.searchResults;
  const loadingMessage = 'Loading customer descriptions that meet the search criteria defined above'
  const noResultsMessage = 'No customer descriptions meet the search criteria defined above';
  const displayMessage = controller.state.isLoading
    ? loadingMessage
    : noResultsMessage;
  const matchesCount = (controller.state.searchResults || []).length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'custDescId',
        Cell: cellInfo => <Form.Link to={'/customerdescription/' + cellInfo.cell.value} value='Edit' />
      },
      {
        Header: 'Mfg Part #',
        accessor: 'mfgItemNoDisplayValue',
        Cell: (cellInfo) => <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Has Diagram',
        accessor: 'hasDiagram',
        Cell: cellInfo => <span>{Utils.formatBool(cellInfo.cell.value)}</span>
      },
      {
        Header: 'Asset Count',
        accessor: 'assetCount',
      },
      {
        Header: '',
        accessor: 'default',
        Cell: cellInfo => !cellInfo.row.original.assetCount
          ? <ActionIcon icon='fa-trash' className='remove-handle' title='Delete manufacturer description' action={() => handleDelete(cellInfo.row.original)} />
          : null
      },
    ],
    []
  )
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText={displayMessage} enableDefaultRowSelect={true} />
    </div>
  );
}

const Details = (props) => {

  const controller = props.controller;
  const item = controller.state.selectedResult;

  return (
    <Jumbotron {...props} className='form-group no-gutter'>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Customer' value={item.customer && item.customer.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Mfg Item #' value={item.mfgItemNoDisplayValue} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Description' value={item.description} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Family Code' value={item.familyCode && item.familyCode.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Length' value={item && item.length && item.length.name} /></div>
        <div className='col-md-6'><Form.StaticData label='CWP' value={item.cwp && item.cwp.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Style' value={item.style && item.style.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Nominal Size' value={item.nominalSize && item.nominalSize.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Manufacturer' value={item.manufacturer && item.manufacturer.name} /></div>
        <div className='col-md-6'><Form.StaticData label='ID' value={item.id && item.id.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='OD' value={item.od && item.od.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Degree' value={item.degree && item.degree.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Service' value={item.service && item.service.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Type Approval' value={item.typeApproval && item.typeApproval.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Temp Range' value={item.tempRange && item.tempRange.name} /></div>
        <div className='col-md-6'><Form.StaticData label='Load Rating' value={item.loadRating && item.loadRating.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Customer CWP' value={item && item.custCWP} /></div>
        <div className='col-md-6'><Form.StaticData label='Customer Test Pressure' value={item && item.custTestPressure} /></div>
      </div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Asset Count' value={item.assetCount} /></div>
        <div className='col-md-6'><Form.StaticData label='Has Diagram' value={Utils.formatBool(item.hasDiagram)} /></div>
      </div>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Notes' value={item.notes} /></div>
      </div>
    </Jumbotron>
  );
}

const Buttons = (props) => {

  const controller = props.controller;
  const item = controller.state.selectedResult;
  const canEdit = !!item;

  return (
    <div className='buttons mb-2'>
      <Button to={'/customerdescription/' + item.manDescId} icon='fa-pencil-alt' disabled={!canEdit} variant='primary' size='sm' className='btn-action action-button m-1'>Edit ...</Button>
    </div>
  );
}

const CustomerDescriptionPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-th' />

class CustomerDescriptionPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new CustomerDescriptionController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const title = 'Customer Description';

    return (
      <Page {...this.props} pageTitle='InteServ · Customer Description' id='cust-desc-page'>
        <CustomerDescriptionPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div {...this.props} className='col-md-8'>
              <div className='row'>
                <div className='col-md-12'>
                  <SearchHeader controller={controller} binder={binder} />
                </div>
                <div className='col-md-12'>
                  <ResultsList controller={controller} binder={binder} />
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='row'>
                <div className='col-md-12'>
                  <Buttons controller={controller} binder={binder} />
                </div>
                <div className='col-md-12'>
                  <Details controller={controller} binder={binder} />
                </div>
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
};

export default CustomerDescriptionPage;
