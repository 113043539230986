import store from 'store';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import DropOffTimeRowController from './DropOffTimeRowController';
import LoginService from '../services/LoginService';

class DropOffTimeController extends BaseController {
    constructor(dropoffId,jobId,isComplete,canaddTime) {
      super('dropOffTime',{
        errors          : {},
        dropOffTimeData   : [], 
        dropoffId       : dropoffId,
        hours:'0',
        minutes:'0',
        technician:'',
        jobId   : jobId,
        jobNumber:'',
        isComplete: isComplete,
        canaddTime:canaddTime
      });
      this.loadDropOffTime();
    }
    
    // validatedropOffTime(state) {
    //   if (!state.dropOffTime.length) {
    //     state.errors.dropOffTime = 'At least one row must be specified';
    //     return;
    //   }
    //   state.dropOffTime.forEach(row => row.validate());
    // }
  
    // canSave() {    
    //   this.validatedropOffTime(this.state);    
    //   var errors    = Object.keys(this.state.errors);
    //   var rowErrors = this.state.dropOffTime.reduce((acc, curr) => {
    //     return acc + Object.keys(curr.state.errors).length;
    //   }, 0);  
      
    //   if (!errors.length && !rowErrors) {
    //     return true;
    //   }
  
    //   this.commit();
    //   return false;
    // }
  
    addNewdropOffTimeRow = (dropOffValue) => {
      this.state.dropOffTimeData.push(new DropOffTimeRowController(this, dropOffValue));
      this.commit();
    }  
  
    loadDropOffTime = () =>{  
          ajax.get('lookup/getdropOffTime/', { dropoffId: this.state.dropoffId }).then(userdropOffTime=>{
            if (userdropOffTime.length>0) {          
              userdropOffTime.forEach(dropOffValue => this.addNewdropOffTimeRow(dropOffValue));
             }
          }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }
  
    savedropOffValues = () => {       
          const hours   = parseFloat(isNaN(this.state.hours) ? 0 : this.state.hours);
          const minutes = parseFloat(isNaN(this.state.minutes) ? 0 : this.state.minutes);
          const value   = ((60 * (isNaN(hours)?0:hours)) + (isNaN(minutes)?0:minutes));       
          if(value<=0)
          {          
              notification.action('error').post("Drop-Off Time should be greater than 0");
              return false;
          }
          if(parseFloat(value).toFixed(1)<=0)
          {
            notification.action('error').post("After rounding to 1 decimal, time will become 0. Drop-Off Time should be greater than 0");
            return false;
          } 
          // if(hours>23)
          // {
          //   notification.action('error').post("Hours cannot be more than 23");
          //   return false;
          // }
          // if(minutes>60)
          // {
          //   notification.action('error').post("Minutes cannot be more than 60");
          //   return false;
          // }
          const loginInfo = store.get('InteServLoginInfo');
          const availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
          if(value>availableTime)
          {
              notification.action('error').post("Drop-Off Time should not be more than Available Production Time");
              return false;
          } 
          if(loginInfo.isBreakOut)
          {
            notification.action('error').post("Currently on Unpaid break");
            return false;
          }   
          const parameters = {
            dropoffId         : this.state.dropoffId,
            TechnicianId: LoginService.loginInfo.userId,        
            TransactionDate : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()),
            Value: value,
            jobId : this.state.jobId
          };
          this.saving=true;
          this.commit();
          return ajax.post('dropOff/saveTime', parameters).then(()=>{
            notification.action('success').post('Saved the Drop-Off Time');   
            this.saving=false;     
          this.state.hours=0;
          this.state.minutes=0;
          this.state.dropOffTimeData=[];
          //messages.channel('productiveTime').action('update').post(response);     
          this.commit();
          this.loadDropOffTime();        
          return false;     
          }).catch(err => {
            this.saving=false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
            return false;
        });
    }  
  }

  export default DropOffTimeController;
  
  