import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Router, useHistory, useLocation } from 'react-router-dom';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import GroupBadge from '../../components/GroupBadge';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import ShipAssetsController from '../controllers/ShipAssetsController';
import ShippingDetails from '../pages/ShippingDetails';
import Authentication from '../services/authentication';
import Utils from '../utility/Utils';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import ShipAwoAssetsController from '../controllers/ShipAwoAssetsController';

const Asset = (props) => {

    const toggleSelection = (asset) => {
        props.controller.toggleSelection(asset);
    }

    const selectAndTransfer = (asset) => {
        props.controller.selectAndTransfer(asset);
    }

    const controller = props.controller;
    const asset = props.asset;
    const className = 'list-group-item asset';
    const assetStatus = asset.assetStatus;
    let rejectReason = asset.rejectReason;
    let statusColourClass = 'asset-status status' + asset.assetStatus; // e.g.: statusScrapped
    if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
        statusColourClass = 'asset-status statusWeldRepair'
    if (asset.assetStatus === 'OS/Machining Repair') 
        statusColourClass = 'asset-status statusOSRepair'
    if (controller.isAssetScrapped(asset)) {
        if (asset.assetStatus != 'Passed' && asset.assetStatus != 'Failed Inspection')
            rejectReason = <span> &ndash; {rejectReason}</span>;
    }

    return (
        <List.Item className={className + ' p-2'} active={asset.selected} onClick={() => { toggleSelection(asset); }} onDoubleClick={() => { selectAndTransfer(asset); }} >
            <Badge variant='info'> {asset.group_name} </Badge>&nbsp;&nbsp;
          <span className='field-data'>{asset.primarySerialNumber}</span>
            <span>{asset.description}</span>
            <span id='asset-status' className={statusColourClass}> {assetStatus}</span>
            {assetStatus != 'Passed' ? <span className={statusColourClass}>{rejectReason}</span> : null}
        </List.Item>
    );
}

const Buttons = (props) => {
    let history = useHistory();

    const handleSave = () => {
        props.controller.save(() => {
            history.goBack();
        });
    }

    const handleClose = () => {
        history.goBack();
    }

    return (
        <div className='col-12'>
            <div className='d-flex justify-content-end'>
                <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button>
                <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
            </div>
        </div>
    );
}

const TransferButtons = (props) => {

    const select = (evt) => {
        evt.preventDefault();
        props.controller.moveToSelected();
    }

    const unselect = (evt) => {
        evt.preventDefault();
        props.controller.removeFromSelected();
    }

    let selectDisabled = false;
    let unselectDisabled = false;

    return (
        <div id='transfer-buttons' className='mt-5 col-md-2'>
            <div className='row'>
                <div className='col-md-12'>
                    <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
                    <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect}>Unselect</Button>
                </div>
            </div>
        </div>
    );
}


const AssetsToShip = (props) => {
    const selectNone = (evt) => {
        evt.preventDefault();
        props.controller.selectNoSelectedAssets();
    }

    const selectAll = (evt) => {
        evt.preventDefault();
        props.controller.selectAllSelectedAssets();
    }

    const controller = props.controller;
    const assetsToShip = controller.state.selectedAssets || [];
    const selectedLabel = Utils.pluralize('selected asset', 's', assetsToShip.length);

    return (
        <div className='col-md-5'>
            <div className='col-md-12 mb-2'>
                <div className='row'>
                    <div className='d-flex justify-content-start'>
                        <Button icon='fa-square' variant='success' size='sm' className='mr-1' onClick={selectAll}>Select All</Button>
                        <Button icon='far fa-square' variant='danger' size='sm'  className='mr-1' onClick={selectNone}>Select None</Button>
                        <div><Badge variant='info'>{selectedLabel}</Badge></div>
                    </div>
                </div>
            </div>
            <Jumbotron className='col-md-12'>
                <div className='row'>
                    <List className='list-group asset-list'>
                        {
                            assetsToShip.map(asset => {
                                return <Asset key={asset.jobSOWId} controller={controller} asset={asset} />;
                            })
                        }
                    </List>
                </div>
            </Jumbotron>
        </div>
    );
}

const AllAssets = (props) => {

    useEffect(() => {
        props.controller.state.multiSelectGroups = '';
        props.controller.commit();
    }, []);

    const selectNone = (evt) => {
        evt.preventDefault();
        props.controller.selectNoAssets();
    }

    const selectAll = (evt) => {
        evt.preventDefault();
        props.controller.selectAllAssets();
    }

    const selectScrapped = (evt) => {
        evt.preventDefault();
        props.controller.selectScrapped();
    }

    const controller = props.controller;
    const binder = props.binder;
    let assets = controller.state.assets || [];
    assets.sort(function (a, b) {
        // first, sort by group number
        if (a.groupNumber !== b.groupNumber) {
            return (a.groupNumber - b.groupNumber);
        }

        // then by serial number
        return a.primarySerialNumber.localeCompare(b.primarySerialNumber);
    });

    const selectedAssets = controller.state.selectedAssets || [];
    const availableLabel = assets.length + ' available asset' + ((assets.length === 1) ? '' : 's');
    const assetListContents = assets.length
        ? assets.map(asset => {
            return <Asset key={asset.jobSOWId} controller={controller} asset={asset} />;
        })
        : selectedAssets.length // don't display message if all they've done is transfer all assets to selected list
            ? null
            : <li className='list-group-item'>No assets are available to transfer from this AWO</li>;

    return (
        <div className='col-md-5'>
            <div className='col-md-12 mb-2'>
                <div className='row'>
                    <div className='d-flex justify-content-start'>
                        <Button icon='fa-square' variant='success' size='sm' className='mr-1' onClick={selectAll}>Select All</Button>
                        <Button icon='far fa-square' variant='danger' size='sm' className='mr-1' onClick={selectNone}>Select None</Button>
                        {/* <Button icon='far fa-thumbs-down' variant='info' size='sm' className='m-1' onClick={selectScrapped}>Select Scrapped</Button> */}
                        <div><Badge variant='info'>{availableLabel}</Badge></div>
                    </div>
                </div>
            </div>
            <Jumbotron className='col-md-12'>
                <div className='row'>
                    <List className='list-group asset-list'>
                        {assetListContents}
                    </List>
                </div>
            </Jumbotron>
        </div>
    );
}

const ShipAssetsPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} icon='fa-truck fa-flip-horizontal' />

class ShipAwoAssetsPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        const awoId = this.props.match.params.awoId;
        if (!awoId) {
            this.props.history.goBack();
        }
        this.props.controller.load(awoId);
    }

    static defaultProps = {
        controller: new ShipAwoAssetsController()
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
    }

    handleGroupClick = (groupNumber) => {
        const controller = this.props.controller;
        controller.toggleGroupSelection(groupNumber);
    }

    renderGroups = (fnOnClick, groups) => {
        if (!groups) {
            return 'No Groups';
        }
        return groups.map(group => {
            const variant = this.isgroupSelected(group) ? 'danger' : 'secondary';
            const groupDisplay = group;
            return <GroupBadge key={groupDisplay} variant={variant} className={'mr-1 mb-1'} onClick={fnOnClick.bind(this, groupDisplay)} dataToggle="tooltip" dataPlacement="top" title={groupDisplay}> {groupDisplay}</GroupBadge>;
        });
    }
    isgroupSelected=(group)=>{
        const selectedgroups = this.props.controller.state.selectedtedgroups;
        return selectedgroups.indexOf(group) >-1;
    }
    clearGroupSelection = () => {
        this.props.controller.deselectAllGroups();
    }

    handleClearSignature = () => {
        this.props.controller.clearSignature();
    }

    handleUpdateSignature = (signature) => {
        this.props.controller.updateSignature(signature);
    }

    render = () => {
        const controller = this.props.controller;
        const data = controller.state;
        const binder = new Binder(this);
        const labelText = 'Select the assets you wish to ship';
        const awoNumber = data.awoNumber;
        const customerName = data.customerName;
        const customerLocation = data.customerLocation;
        const selectedAssets = controller.state.selectedAssets || [];
        const canRemoveFromContainer = selectedAssets.reduce((acc, curr) => {
            return acc || (curr.containerId !== Utils.emptyGuid);
        }, false);
        const removeFromContainerDisable = data.customerContainer ? true : false;
        const removeFromContainer = canRemoveFromContainer
            ? <div className='col-lg-6'>
                <Form.CheckBox label='Remove from Container' bind={binder.bind('removeFromContainer')} disabled={removeFromContainerDisable} enabled={!removeFromContainerDisable} />
            </div>
            : null;

        var groups = binder.bind('groups')() || [];
        var distinctGroup = [];
        var distinctGroupWithNames = [];
        groups.forEach(group => {
            if (distinctGroup.indexOf(group) < 0) {
                var name = group;
                distinctGroup.push(group);
                distinctGroupWithNames.push({ id: group, name: name });
            }
        });
        var groupView =  <TransitionGroup component='div' transitionName='grow-shrink' transitionEnterTimeout={300} transitionLeaveTimeout={200} id='group-badges'>
                {this.renderGroups(this.handleGroupClick, groups)}
            </TransitionGroup>
        return (
            <Page {...this.props} pageTitle='InteServ · Transfer Assets' id='ship-assets-page'>
                <ShipAssetsPageHeader pageTitle='Ship Assets' />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-12'>
                        <div className='h5' >{awoNumber} &mdash; {customerName}</div>
                        <div className='h6 text-center'>{labelText}</div>
                        </div>
                        <div className='col-md-12'>
                            <div className='d-flex justify-content-start'>
                                <Form.Label name='Groups' prependLabelIcon='fa-filter' />
                                <GroupBadge variant='danger' className='ml-2' pill={false} onClick={this.clearGroupSelection} >Clear Group Selection</GroupBadge>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            {groupView}
                        </div>
                        <div className='col-md-12 my-2'>
                            <div className='row'>
                                <AllAssets controller={controller} binder={binder} />
                                <TransferButtons controller={controller} />
                                <AssetsToShip controller={controller} />
                            </div>
                        </div>
                        {removeFromContainer}
                        <div className='col-md-12'>
                                <ShippingDetails controller={controller} binder={binder} clearSignature={this.handleClearSignature} updateSignature={this.handleUpdateSignature} parentPage='shipawoassetspage'/>
                        </div>
                        <Buttons controller={controller} />
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default ShipAwoAssetsPage;
