import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class PrintShareGaugeController extends BaseController {
    constructor(calibrationIds,serialId) {
        super('send-email', {
            calibrationIds: calibrationIds || [],
            emailId: '',
            serialId: serialId,
            errors: {}
        });
        this.saving = false;
    }


    verifyEmailId = () => {
        const validEmail = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/).test(this.state.emailId);
        let proceed = true;
        if (!validEmail) {
            this.state.errors.emailId = 'Please enter valid E-mail address';
            this.commit();
            proceed = false;
        }
        return proceed;
    }

    save = () => {
        const calibrationIds = this.state.calibrationIds
        if (this.verifyEmailId()) {
            const parameters = {
                calibrationIds: calibrationIds,
                serialId: this.state.serialId,
                emailId: this.state.emailId,
            };
            this.saving = true;
            this.commit();
            return ajax.post('gauge/emailGaugeDocsPdf', parameters).then(results => {
                this.saving = false;
                this.commit();
                notification.action('success').post('Successfully sent');
                return true;
            }).catch(err => {
                this.saving = false;
                this.commit();
                notification.action('error').post({ msg: err.message, title: 'Error' });
                return false;
            });
        }
    }
}

export default PrintShareGaugeController;