import React, { useMemo } from 'react';
import Binder from '../../lib/Binder';
import Dialog from '../../components/Dialog';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import Button from '../../components/Button';
import TorqueValueRow from '../pages/TorqueValueRow';
import Table from '../../components/Table';
import Controller from '../../mixins/Controller';
import Grid from '../../components/Grid';

const TorqueValueTable = (props) => {
    const controller = props.controller;

    const renderRowErrors = () => {
        var rowErrors = controller.state.errors && controller.state.errors.torqueValues;
        if (rowErrors) {
            return <Form.FieldError>{rowErrors}</Form.FieldError>;
        }
    }

    const handleAddNewRow = () => {
        props.controller.addNewRow();
    }

    const divClassName = controller.state.errors.torqueValues ? ' has-error' : '';

    const data = [...controller.state.torqueValues];

    const isViewMode = controller.state.isViewMode;

    const canAddtorqueValues = false;

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'action',
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    const canDeleteRow = controller.state.minimumReadingMappedId == Utils.emptyGuid && !isViewMode;
                    return canDeleteRow
                        ? <ActionIcon icon='fa-trash' className='remove-handle' action={() => controller.removeRow()} />
                        : <span></span>;
                }
            },
            {
                Header: 'Location',
                accessor: 'state.location',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <Form.StaticData value={binder.bind('location')()} />
                }
            },
            {
                Header: 'Torque Range',
                accessor: 'state.torqueRange',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <Form.StaticData value={binder.bind('torqueRange')()} />
                }
            },
            {
                Header: 'Not Applicable',
                accessor: 'state.isNotApplicable',
                Cell: (cellInfo, binder) => <Form.CheckBox bind={binder.bind('isNotApplicable')} />
            },
            {
                Header: 'Actual Reading',
                accessor: 'state.actualReading',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const errors = controller.state.errors;
                    let actualReadingProps = {
                        id: controller.key + 'actualReading',
                        bind: binder.bind('actualReading'),
                        placeholder: 'Enter a value',
                    }
                    if (!controller.state.isNotApplicable) {
                        actualReadingProps.error = errors.actualReading;
                        actualReadingProps.className = 'required';
                    }
                    return controller.state.isNotApplicable
                        ? <span>0</span>
                        : <Form.Input {...actualReadingProps} />
                }
            },
        ],
        []);
    return (
        <div className={divClassName}>
            <Grid id='torque-value-table' columns={columns} data={data} editable={!isViewMode} canAddNewRow={canAddtorqueValues} addNewRowText='Click to add a new row' onAddNewRow={handleAddNewRow} showPagination={false} />
            {renderRowErrors()}
        </div>
    );

}

// class TorqueValueTable extends React.Component {
//     handleAddNewRow = () => {
//         this.props.controller.addNewRow();
//     }

//     renderRowErrors = () => {
//         var rowErrors = this.props.controller.state.errors && this.props.controller.state.errors.torqueValues;
//         if (rowErrors) {
//             return <div className='help-block'>{rowErrors}</div>;
//         }
//     }

//     render = () => {
//         const controller = this.props.controller;
//         const isViewMode = controller.state.isViewMode;
//         const torqueValues = controller.state.torqueValues.map(row => <TorqueValueRow key={row.key} controller={row} />);
//         const canAddtorqueValues = !isViewMode; // controller.state.torqueValues.length + 1 <= 10 ? true : false;
//         const addNewRow = canAddtorqueValues
//             ? <Table.Row>
//                 <Table.Data className='table-add-new-row col-action'><Button onClick={this.handleAddNewRow} icon='fa fa-plus-square' variant='primary' size='sm' className='' style={{ color: '#5cb85c' }} /></Table.Data>
//                 <Table.Data colSpan='2' className='table-add-new-row'>Click to add a new row</Table.Data>
//             </Table.Row>
//             : null;

//         let divClassName = 'form-group col-md-12';
//         if (controller.state.errors.torqueValues) {
//             divClassName += ' has-error';
//         }

//         return (
//             <div id='torqueValue-div' className={divClassName} style={{ height: 315, overflowY: 'auto', marginTop: '15' }} >
//                 <Table id='torqueValue-table'>
//                     <Table.Header>
//                         <Table.Row>
//                             <Table.Head className='col-action' />
//                             <Table.Head className='col-minimumReading'>Location</Table.Head>
//                             <Table.Head className='col-minimumReading'>Torque Range</Table.Head>
//                             <Table.Head className='col-notApplicable'>Not Applicable</Table.Head>
//                             <Table.Head className='col-actualReading'>Actual Reading</Table.Head>
//                         </Table.Row>
//                     </Table.Header>
//                     <Table.Body>
//                         {torqueValues}
//                     </Table.Body>
//                 </Table>
//                 {this.renderRowErrors()}
//             </div>
//         );
//     }
// }


class TorqueValuesDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => (result.Cancel || result.OK) ? true : this.props.controller.saveTorqueValues();


    render() {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const isViewMode = controller.state.isViewMode;
        const actionButtons = isViewMode ? <Dialog.OKButtons /> : <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
        const TorqueValuesTitle = 'Torque values';
        const errors = controller.state.errors;

        return (
            <Dialog {...this.props} modal={true} width={650} height={450} header={TorqueValuesTitle} id='torqueValues-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='torqueValues-dlg-body'>
                        <form role='form'>
                            <TorqueValueTable controller={controller} />
                        </form>
                    </div>
                </Dialog.Body>
                {actionButtons}
            </Dialog>
        );
    }
}


export default TorqueValuesDialog;