import React, { useMemo } from 'react';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';
import Searchers from '../utility/Searchers';

const CustDescDimensionsTable = (props) => {
  const controller = props.controller;

  const totalReadingCount = controller.state.dimensions.reduce((acc, curr) => acc + curr.state.quantity, 0);

  const handleAddNewRow = () => {
    props.controller.addNewDimensionRow();
  }

  const getRowProps = (row) => {
    const rowProps = {};
    if (row.original.ReadingType == AppConstants.ReadingType.Customer) {
      rowProps.className = 'cust-desc-background';
    }
    else {
      if (row.original.customerSelected) {
        rowProps.className = 'man-desc-background';
      }
      else {
        rowProps.className = 'man-desc-background unused-manufacturer-reading';
      }
    }
    return rowProps;
  }

  let divClassName = 'col-md-12';
  divClassName = controller.state.errors.dimensions ? ' has-error' : '';

  const data = [...controller.state.dimensions];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return controller.canRemoveRow()
            ? <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
            : <span></span>;
        }
      },
      {
        Header: 'Use',
        accessor: 'state.customerSelected',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return cellInfo.row.original.state.readingType == AppConstants.ReadingType.Manufacturer
            ? LoginService.isSuperUser ? <Form.CheckBox bind={binder.bind('customerSelected')} />
              : <span>{Utils.formatBool(cellInfo.value)}</span>
            : cellInfo.value;
        }
      },
      {
        Header: 'ID',
        accessor: 'state.dimensionId',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return cellInfo.row.original.state.readingType == AppConstants.ReadingType.Customer && LoginService.isSuperUser
            ? <Form.Input bind={binder.bind('dimensionId')} className='required id-field' error={errors.dimensionId} placeholder='#' />
            : <span>{cellInfo.value}</span>
        }
      },
      {
        Header: 'Dimensional Location',
        accessor: 'state.dimensionLocation',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Customer || (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Manufacturer && cellInfo.row.original.state.customerSelected))
            ? LoginService.isSuperUser ? <Form.AutoComplete bind={binder.bind('dimensionLocation')} className='' error={errors.torquelocation} search={Searchers.searchDimensionLocation()} minimumResultsForSearch={-1} />
              : <span>{cellInfo.value.name}</span>
            : <span>{cellInfo.value.name}</span>;
        }
      },
      {
        Header: 'New Dimensions',
        accessor: 'state.newDimension',
        Cell: (cellInfo) => {
          return cellInfo.row.original.state.readingType == AppConstants.ReadingType.Manufacturer
            ? <span>{cellInfo.value}</span>
            : <span></span>;
        }
      },
      {
        Header: 'Minimum Dimensions',
        accessor: 'state.minimumDimension',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
      {
        Header: 'Customer Minimum',
        accessor: 'state.customerMinimumDimension',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Customer || (cellInfo.row.original.state.readingType == AppConstants.ReadingType.Manufacturer && cellInfo.row.original.state.customerSelected))
            ? LoginService.isSuperUser ? <Form.Input bind={binder.bind('customerMinimumDimension')} className='required' error={errors.customerMinimumDimension} placeholder='Enter a value' />
              : <span>{cellInfo.value}</span>
            : <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'Type',
        accessor: 'state.readingType',
        Cell: (cellInfo) => <span>{cellInfo.value}</span>
      },
    ],
    []);

  return (
    <div className={divClassName}>
      <Grid id='dimension-results-table' columns={columns} data={data} editable={true} canAddNewRow={false} getRowProps={getRowProps} onAddNewRow={handleAddNewRow} showPagination={false} noDataText='No dimensions readings exist yet' />
    </div>
  );
}

export default CustDescDimensionsTable;