import moment from 'moment';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants from '../utility/AppConstants';
import DropoffRowController from './DropoffRowController';
import EditDropoffController from './EditDropoffController';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';


class EditJobController extends BaseController {
    constructor() {
        super('edit-job', EditJobController.getInitialState(''));

        this.adding = false;
        this.applications = [];
        this._validator = new Validator();
    }

    loadValidators = () => {
        // common validators
        this._validator.add('receiveDate', 'Receive Date', [{ required: true }]);
        this._validator.add('customerLocation', 'Customer Location', [{ required: true }]);
        this._validator.add('jobDescription', 'Description', [{ required: true }]);
        this._validator.add('jobType', 'Job Type', [{ required: true }]);
        this._validator.add('accountingIndicator', 'Accounting Indicator', [{ required: true }]);
        this._validator.add('requiredDate', 'Requested Completion Date', [{ required: true }]);

        // validators during initial job creation only
        if (this.adding) {
            this._validator.add('customer', 'Customer', [{ required: true }]);
            this._validator.add('jobDate', 'Job Date must be greater than or equal to today', [{ isGreaterThanEqualsDate: { params: DateTimeFormatter.today() } }]);
        }
    }

    EnableRecDate = (job) => {
        let statusValue = false;
        if (job.assetCount === 0) {
            statusValue = true;
        }
        else if (job.isClosed) {
            statusValue = false;
        }
        else if (job.isInvoiced) {
            statusValue = false;
        }
        else if (job.canInvoice) {
            statusValue = false;
        }
        else if (job.isCompleting) {
            statusValue = false;
        }
        else if (job.canComplete) {
            statusValue = true;
        }
        else if (job.isWaitingToRetry) {
            statusValue = true;
        }
        else if (job.isInspecting) {
            statusValue = true;
        }
        else if (job.canLockForInspection) {
            statusValue = true;
        }
        else if (job.isWaiting) {
            statusValue = true;
        }
        else if (job.isCreated) {
            statusValue = true;
        }
        else {
            statusValue = true;
        }
        return statusValue;
    }

    load = (jobId, data) => {
        if (jobId === Utils.emptyGuid) {
            this.adding = true;
            this.state = EditJobController.getInitialState(jobId, data);
            this.loadValidators();
            this.commit();
        }
        else {
            this.adding = false;
                ajax.get('lookup/getJob/', { jobId: jobId }).then(results=>{
                    this.mergeJob(results);
                    this.loadValidators();
                    this.commit();
                }).catch(err => {
                    notification.action('error').post({ msg: err.message, title: 'Error' });
                }); // UpdateJobDto
        }
    }

    getStatus = (job) => {
        let statusValue = false;
        if (job.assetCount === 0) {
            statusValue = true;
        }
        else if (job.isClosed) {
            statusValue = false;
        }
        else if (job.isInvoiced) {
            statusValue = false;
        }
        else if (job.canInvoice) {
            statusValue = false;
        }
        else if (job.isCompleting) {
            statusValue = false;
        }
        else if (job.canComplete) {
            statusValue = false;
        }
        else if (job.isWaitingToRetry) {
            statusValue = false;
        }
        else if (job.isInspecting) {
            statusValue = false;
        }
        else if (job.canLockForInspection) {
            statusValue = true;
        }
        else if (job.isWaiting) {
            statusValue = false;
        }
        else if (job.isCreated) {
            statusValue = true;
        }
        else {
            statusValue = true;
        }
        return statusValue;
    }

    static getInitialState = (jobId, dropoff) => {
        return {
            id: jobId,
            jobNumber: '',
            jobType: '', // (AppConstants.JobType[1]),
            accountingIndicator: (AppConstants.AccountingIndicators[0]),
            customer: (dropoff && dropoff.customer) || '',
            customerLocation: (dropoff && dropoff.customerLocation) || '',
            customerPO: (dropoff && dropoff.customerPO) || '',
            customerJobNumber: '',
            jobDescription: (dropoff && dropoff.equipmentDetails) || '',
            notes: '',
            deliveryTicket: (dropoff && dropoff.dropoffNumber) || '',
            isComplete: false,
            jobDate: DateTimeFormatter.formatDropdownDate(moment().startOf('day')),
            requiredDate: (dropoff && DateTimeFormatter.formatDropdownDate(dropoff.requestedDate)) || '',
            receiveDate: (dropoff && DateTimeFormatter.formatDropdownDate(dropoff.createdDate)) || '',
            errors: {},
            profitcostcenterId: (dropoff && dropoff.profitcostcenterId) || '',
            customerStandard: (dropoff && dropoff.customerStandard) || '',
            shortText: (dropoff && dropoff.shortText) || '',
            JobDetails: {},
            isSaveInProgress: false,
            dropoffId: (dropoff && dropoff.dropoffId) || '',
            wbsnumber:''
        };
    }

    mergeJob = (job) => {
        this.state.id = job.id;
        this.state.jobNumber = job.jobNumber;
        this.state.jobType = { id: job.eJobType, name: job.jobType };
        this.state.accountingIndicator = { id: job.eAccountingIndicator, name: job.accountingIndicator };
        this.state.customer = { id: job.customerId, name: job.customerName };
        this.state.customerLocation = { id: job.customerLocationId, name: job.customerLocation };
        this.state.customerPO = job.customerPO;
        this.state.customerJobNumber = job.customerJobNumber;
        this.state.jobDescription = job.jobDescription;
        this.state.notes = job.jobNotes;
        this.state.deliveryTicket = job.deliveryTicket;
        this.state.isComplete = job.isComplete;
        this.state.jobDate = DateTimeFormatter.formatDropdownDate(job.jobDate);
        this.state.requiredDate = DateTimeFormatter.formatDropdownDate(job.requiredDate);
        this.state.receiveDate = DateTimeFormatter.formatDropdownDate(job.receiveDate);
        this.state.errors = {};
        this.state.profitcostcenterId = job.profitcostcenterId === Utils.emptyGuid ? '' : { id: job.profitcostcenterId, name: job.shortText };
        this.state.customerStandard = job.customerStandard;
        this.state.shortText = job.profitcostcenterId === Utils.emptyGuid ? '' : job.shortText;
        this.state.JobDetails = job;
        this.state.wbsnumber=job.wbsnumber?job.wbsnumber:'';
    }

    customerChanged = () => {
        this.state.customerLocation = '';
        this.commit();
    }

    profitcostSearch = () => {
        const self = this;
        return function* () {
            const accountingIndicatorId = (self.state.accountingIndicator && self.state.accountingIndicator.id);
            const jobTypeId = (self.state.jobType && self.state.jobType.id);
            const param = {
                accountingIndicatorId: (accountingIndicatorId === "" || accountingIndicatorId === null) ? -1 : accountingIndicatorId,
                jobTypeId: (jobTypeId === "" || jobTypeId === null) ? 0 : jobTypeId,
                facilityId: LoginService.loginInfo.facilityId
            }
            const facilityId = LoginService.loginInfo.facilityId;
            const results = yield ajax.get('lookup/getProfitCost', param);
            return results;
        };
    }

    customerSearch = () => {
        const facilityId = LoginService.loginInfo.facilityId;
        return (searchTerm) => ajax.get('lookup/searchCustomersForFacility', { facilityId: facilityId, searchTerm: searchTerm })
        .then(result => result).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }


    customerLocationSearch = () => {
        const self = this;
        return function* (searchTerm) {
            const customerId = (self.state.customer && self.state.customer.id);
            if (!customerId) {
                notification.action('warning').post('Please select a customer so you can choose a Customer Location.');
                return null;
            }
            const facilityId = LoginService.loginInfo.facilityId;
            const results = yield ajax.get('lookup/searchCustomerLocations', { facilityId: facilityId, customerId: customerId, searchTerm: searchTerm }); // IList<CustomerLocationLookupDto>
            return results;
        };
    }

    useSAP = () => {
        this._validator.remove('jobNumber');
        this._validator.remove('profitcostcenterId');

        const useSAP = SAPService.isSAPFacility();
        if (!useSAP) {
            this._validator.add('jobNumber', 'Job Number', [{ required: true }]);
        }
        else {
            if (this.getStatus(this.state.JobDetails)) {
                this._validator.add('profitcostcenterId', 'Profit/Cost Center', [{ required: true }]);
            }
        }
        return useSAP;
    }

    canSave = () => {
        this._validator.validateAll(this.state);
        this.commit();
        let errors = Object.keys(this.state.errors);
        if (errors.length) {
            return false;
        }
        if (this.adding) {
            this._validator.add('requiredDate', 'Requested Completion Date must be greater than or equal to today', [{ isGreaterThanEqualsDate: { params: DateTimeFormatter.today() } }]);
        }
        else {
            const receiveDate = moment(this.state.receiveDate);
            this._validator.add('requiredDate', 'Requested Completion Date must be greater than or equal to Receive Date', [{ isGreaterThanEqualsDate: { params: receiveDate } }]);
        }
        this.commit();
        this._validator.validateAll(this.state);
        errors = Object.keys(this.state.errors);
        this.commit();
        if (errors.length) {
            return false;
        }
        this.commit();
        return true;
    }

    save = (cb) => {
        if (!this.canSave()) {
            return;
        }

        const s = this.state;
        const jobId = s.id;
        const parameters = {
            jobId: jobId,
            jobNumber: s.jobNumber,
            jobType: s.jobType.id,
            accountingIndicator: s.accountingIndicator.id,
            facility: {
                id: LoginService.loginInfo.facilityId,
                name: LoginService.loginInfo.facilityName
            },
            customer: s.customer,
            customerLocation: s.customerLocation,
            customerPO: s.customerPO,
            customerJobNumber: s.customerJobNumber,
            description: s.jobDescription,
            notes: s.notes,
            deliveryTicket: s.deliveryTicket,
            jobDate: s.jobDate,
            requestedDate: s.requiredDate,
            receiveDate: s.receiveDate,
            profitcostcenterId: s.profitcostcenterId ? s.profitcostcenterId.id : null,
            customerStandard: s.customerStandard,
            WBSNumber: s.wbsnumber,
        };
        this.state.isSaveInProgress = true;
        this.commit();
        co(this, function* () {
            try {
                var jobNumber = yield ajax.post('jobprogress/save', parameters); // in: UpdateJobDto, out: void
                if (this.adding) {
                    notification.action('success').post('Saved new Job with Job Number ' + jobNumber);
                }
                else {
                    notification.action('success').post('Saved changes for Job with Job Number ' + jobNumber);
                }

                // use in JobController to re-select edited form
                // if it's a new form, it will be the 1st entry in the list
                var postData = this.adding
                    ? { dropoffNumber: s.deliveryTicket }
                    : { jobId: jobId };
                messages.channel('edit-job').action('saved').post(postData);
                if (s.dropoffId != '')
                    messages.channel('edit-dropoff').action('saved').post(s.dropoffId);
                cb();
                this.state.isSaveInProgress = false;
                this.commit();
            }
            catch (err) {
                if (this.adding) {
                    notification.action('error').post({ msg: err.message, title: 'Error creating new job' });
                }
                else {
                    notification.action('error').post({ msg: err.message, title: 'Error saving job' });
                }
                this.state.isSaveInProgress = false;
                this.commit();
            }
        });
    }

    jobTypeChanged = () => {
        this.state.profitcostcenterId = '';
    }

    accountingIndicatorChanged = () => {
        this.state.profitcostcenterId = '';
    }
}

export default EditJobController;