import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class BreakInController extends BaseController {
    constructor() {
        super();

        this.isInProgress = false;
    }

    breakInNow = (userId) => {
        this.isInProgress = true;
        this.commit();
        return ajax.post('manHours/breakInNow', { userId: userId, today: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()) }).then(() => {
            this.isInProgress = false;
            this.commit();
            return true;
        }).catch(err => {
            this.isInProgress = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
            return false;
        });
    }
}

export default BreakInController;