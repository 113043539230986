import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';

class CustomerDescriptionNotesController {
  constructor(parent) {
    this.parent  = parent;
    this.custDesc = '';
    this.notes   = [];
  }

  getNotes = () => {
    return this.notes;
  }

  getTitle = () => {
    const custDesc = this.custDesc;
    return 'Customer Description Notes — ' + (custDesc && custDesc.customer && custDesc.customer.name) + ' – ' + (custDesc && custDesc.mfgItemNo && custDesc.mfgItemNo.name);
  }

  load = (custDescId) => {
    ajax.get('lookup/getCustomerDescription/', { custDescId: custDescId }).then(custDesc=>{
      this.custDesc = custDesc;
      this.parent.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  }); // out: CustomerDescriptionDto
  }

  loadNotes = (custDescId, flashNewNote) => {
    ajax.get('lookup/getCustomerDescriptionNotes/', { custDescId: custDescId }).then(results=>{
      this.notes  = results;
      this.parent.commit();
  
      if (flashNewNote) {
        var newNote = results[results.length - 1];
        if(newNote.noteId!='undefined')
          this.parent.flashNote(newNote.noteId);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  }); // out: IList<NoteDto>
  }

  canAddNotes = () => {
    return ((LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep) || (LoginService.isTechnician));
  }

  addNote = (text,usersToNotify) => {
    const custDescId  = this.custDesc.custDescId;
    const parameters = {
      entityId : custDescId,
      noteType : AppConstants.NoteTypes.CustomerDescription,
      text     : text,
      usersToNotify: usersToNotify
    };
    this.parent.state.saveInProgress = true;
    this.parent.commit();
        ajax.post('customerDescription/addNote', parameters).then(()=>{
          this.loadNotes(custDescId, true);
          this.parent.clearNoteText();
          this.loadNotes(custDescId, true);
          this.parent.state.saveInProgress = false;
          this.parent.commit();
        }).catch(err => {
          this.parent.state.saveInProgress = false;
          this.parent.commit();
          notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: AddNoteDto, out: void
  }
}

export default CustomerDescriptionNotesController;