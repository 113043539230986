import React from 'react';
import store from 'store';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import EditUserController from '../controllers/EditUserController';
import SearchController from '../controllers/SearchController';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Searchers from '../utility/Searchers';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import LoginService from '../services/LoginService';
import ButtonGroup from '../../components/ButtonGroup';
import Dialog from '../../components/Dialog';
import ResetPasswordDialog from '../dialogs/ResetPasswordDialog';
import ResetPasswordController from '../controllers/ResetPasswordController';
import LoginActivityDialog from '../dialogs/LoginActivityDialog';
import LoginActivityController from '../controllers/LoginActivityController';
import SelectUserFacilitiesDialog from '../dialogs/SelectUserFacilitiesDialog';
import SelectUserFacilitiesController from '../controllers/SelectUserFacilitiesController';
import SelectUserCustomerLocationsDialog from '../dialogs/SelectUserCustomerLocationsDialog';
import SelectUserCustomerLocationsController from '../controllers/SelectUserCustomerLocationsController';
import UserSecurityRightsDialog from '../dialogs/UserSecurityRightsDialog';
import UserSecurityRightsController from '../controllers/UserSecurityRightsController';
import DropZone from '../../components/DropZone';
import GroupBadge from '../../components/GroupBadge';
import CropImageDialog from '../dialogs/CropImageDialog';

const EditForm = (props) => {

    const getLastLogin = (user) => {
        let lastLogin = DateTimeFormatter.formatDate(user.lastLogin);
        if (!lastLogin) {
            lastLogin = 'never';
        }
        else {
            lastLogin = DateTimeFormatter.fromNowTime(user.lastLogin);
        }
        return lastLogin;
    }

    const handleOnKeyPress = (evt) => {
        if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
            evt.preventDefault();
        }
    }
    const handleUploadDiagram = (res) => {
        const newFile = {
            id: Utils.newGuid(),
            fileName: res.file.name,
            size: res.file.size,
            altText: '',
            caption: '',
            file: res.file,
            fileType: res.file.name.split('.')[1] || '',
            url: res.imageUrl
        };
        if(!props.controller.validateUploadedFile(newFile.fileType)){
            return false;
        }
        props.controller.uploadSignature(res, newFile);
        Dialog.showDialog(<CropImageDialog controller={props.controller} header={props.controller.state.signatureImage.fileName} />);
    }
    const handleRemoveDiagram = () => {
        props.controller.removeDiagram();
    }


    const binder = props.binder;
    const controller = props.controller;
    const errors = controller.state.errors;
    const item = controller.state;
    const searchController = new SearchController();
    const displayAccountDisabled = false;
    const lastLogin = getLastLogin(controller.state);
    const lastLoginClassName = (lastLogin === 'never' ? 'never-logged-in' : '');
    const customer = controller.state.customer;
    const customerName = customer && customer.name;
    const currentFacility = controller.state.customerAccount
        ? <div className='col-lg-4 col-md-6'><Form.StaticData label='' value='' /></div>
        : <div className='col-lg-4 col-md-6'><Form.AutoComplete label='Current Facility' bind={binder.bind('currentFacility')} search={controller.facilitySearch()} minimumResultsForSearch={-1} /></div>;
    const fldEmployeeId = !controller.state.customerAccount
        ? <div className='col-lg-4 col-md-6'><Form.Input label='Employee ID' bind={binder.bind('employeeID')} maxLength='8' onKeyPress={handleOnKeyPress} /></div>
        : null;
    const fldSapProfile = !controller.state.customerAccount
        ? <div className='col-lg-2 col-md-4'><Form.AutoComplete label='SAP Profile' bind={binder.bind('sapProfile')} id='sap-profile' search={Searchers.sapProfileSearch()} minimumResultsForSearch={-1} /></div>
        : null;
    const fldTimeZone = !controller.state.customerAccount
        ? <div className='col-lg-4 col-md-4'><Form.AutoComplete label='Time Zone(UTC)' bind={binder.bind('timeZone')} id='time-zone' search={Searchers.timeZoneSearch()} /></div>
        : null;
    const uploadReplaceDiagramText = item.hasDiagram
        ? 'Replace'
        : 'Upload';

    const dropzone = <div className='d-flex' style={{ maxWidth: 246 }}>
        {item.hasDiagram ? '' : < DropZone onDrop={handleUploadDiagram} multiple={false} disabled={controller.adding} style={{ borderStyle: 'none', padding: 'unset' }} width={180} height={23} >
            <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
            <GroupBadge type='file' variant='success' pill={false} icon='fa-upload' disabled={controller.adding}>{uploadReplaceDiagramText}</GroupBadge>
        </DropZone>
        }
        <div className='remove-button'>
            {item.hasDiagram ? <GroupBadge variant='danger' icon='fa-trash' pill={false} onClick={handleRemoveDiagram} disabled={controller.adding || !item.hasDiagram}>Remove</GroupBadge>
                : ''}
        </div>
    </div >

    const diagramImage = (!controller.adding)
        ? <img alt={item.imgAlt} src={item.imgUrl} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', height: '70px', width: '242px' }}  />
        : null;

    let datePasswordLastChanged = DateTimeFormatter.formatDate(controller.state.passwordChangeDate);
    if (!datePasswordLastChanged) {
        datePasswordLastChanged = 'unknown';
    }

    let passwordExpiredClassName = null;
    let passwordExpiredIcon = null;
    let daysUntilPasswordExpires = controller.state.daysUntilPasswordExpires;
    if ((daysUntilPasswordExpires === 0) && (datePasswordLastChanged === 'unknown')) {
        daysUntilPasswordExpires = '';
    }
    else if (daysUntilPasswordExpires === 0) {
        daysUntilPasswordExpires = 'expires today';
        passwordExpiredClassName = 'late-error';
        passwordExpiredIcon = 'late-error fa-key';
    }
    else if (daysUntilPasswordExpires < 0) {
        daysUntilPasswordExpires = 'expired ' + Utils.pluralize('day', 's', -daysUntilPasswordExpires) + ' ago';
        passwordExpiredClassName = 'late-error';
        passwordExpiredIcon = 'late-error fa-key';
    }
    else if (daysUntilPasswordExpires < AppConstants.PasswordExpirationWarningDays) {
        daysUntilPasswordExpires = LoginService.passwordExpirationMessage(daysUntilPasswordExpires);
        passwordExpiredClassName = 'late-warning';
        passwordExpiredIcon = 'late-warning fa-key';
    }

    return (
        <div className='col-sm-12'>
            {customerName ? <div className='row'><div className='col-md-12 pl-2'><Form.Header>{customerName}</Form.Header></div></div> : null}
            <div className='row'>
                <div className='col-lg-4 col-md-6'><Form.Input id='login-name' label='Login Name' bind={binder.bind('loginName')} className='required' error={errors.loginName} /></div>
                <div className='col-lg-4 col-md-6'><Form.Input label='PIN' bind={binder.bind('pin')} hint='10 chars max' /></div>
                <div className='col-lg-4 col-md-6'><Form.AutoComplete label='Role' bind={binder.bind('role')} id='user-role' className='required' error={errors.role} allowClear={false} search={Searchers.roleSearch()} minimumResultsForSearch={-1} /></div>
                <div className='col-lg-4 col-md-6'><Form.Input label='Email' bind={binder.bind('email')} /></div>
                <div className='col-lg-4 col-md-6'><Form.Input label='First Name' bind={binder.bind('firstName')} /></div>
                <div className='col-lg-4 col-md-6'><Form.Input label='Last Name' bind={binder.bind('lastName')} /></div>
                <div className='col-lg-2 col-md-3'><Form.CheckBox label='Login Enabled' bind={binder.bind('loginEnabled')} /></div>
                <div className='col-lg-2 col-md-3'><Form.CheckBox label='Display Account' bind={binder.bind('displayAccount')} enabled={!displayAccountDisabled} disabled={displayAccountDisabled} /></div>
                <div className='col-lg-2 col-md-3'><Form.AutoComplete label='Organization' bind={binder.bind('organization')} id='user-organization' className='required' error={errors.organization} allowClear={false} search={Searchers.organizationSearch()} /></div>
                <div className='col-lg-2 col-md-3'><Form.AutoComplete label='Culture' bind={binder.bind('culture')} id='user-culture' className='required' error={errors.culture} allowClear={false} search={searchController.search('culture')} minimumResultsForSearch={-1} /></div>
                {currentFacility}
                <div className='col-lg-2 col-md-3'><Form.StaticData label='Last Login Date' value={lastLogin} className={lastLoginClassName} /></div>
                <div className='col-lg-2 col-md-3'><Form.StaticData label='Date password last changed' value={datePasswordLastChanged} className={passwordExpiredClassName} /></div>
                <div className='col-lg-4 col-md-6'><Form.StaticData label='Days until password expires' value={daysUntilPasswordExpires} className={passwordExpiredClassName} icon={passwordExpiredIcon} /></div>
                {fldEmployeeId}
                {fldSapProfile}
                <div className='col-lg-2 col-md-3'><Form.Input label='Cost Center' bind={binder.bind('costCenter')} /></div>
                {fldTimeZone}
               
            </div>
            <div className='row'>
                <div className='col-lg-2 div-signature-preview'>  {diagramImage} </div>
                <div className='col-lg-8 col-md-4'>  {dropzone} </div>
            </div>
        </div>
    );
}

const Buttons = (props) => {

    let history = useHistory();

    const handleResetPassword = () => {
        const state = props.controller.state;
        const header = 'Reset Password';
        const user = {
            userId: state.userId,
            loginName: state.loginName,
            firstName: state.firstName,
            lastName: state.lastName
        };
        Dialog.showDialog(<ResetPasswordDialog controller={new ResetPasswordController(user)} header={header} />);
    }

    const handleLoginActivity = () => {
        const state = props.controller.state;
        const user = {
            userId: state.userId,
            firstName: state.firstName,
            lastName: state.lastName
        };
        const header = 'Login activity for {firstName} {lastName}'.interpolate(user);
        Dialog.showDialog(<LoginActivityDialog controller={new LoginActivityController(user)} header={header} />);
    }

    const handleSelectFacilities = () => {
        const state = props.controller.state;
        const user = state;
        const userFacilities = state.userFacilities;
        const allFacilities = state.allFacilities;
        const currentFacility = user.currentFacility;

        Dialog.showDialog(<SelectUserFacilitiesDialog controller={new SelectUserFacilitiesController(user, userFacilities, allFacilities, currentFacility)} header='Select User Facilities' />);
    }

    const handleSelectCustomerLocations = () => {
        const state = props.controller.state;
        const user = {
            userId: state.userId,
            firstName: state.firstName,
            lastName: state.lastName
        };
        const userCustomerLocations = state.userCustomerLocations;
        const allCustomerLocations = state.allCustomerLocations;

        Dialog.showDialog(<SelectUserCustomerLocationsDialog controller={new SelectUserCustomerLocationsController(user, userCustomerLocations, allCustomerLocations)} header='Select User Customer Locations' />);
    }

    const handleSecurityRights = () => {
        const user = props.controller.state;
        const header = `Security Rights for ${user.name}`;
        Dialog.showDialog(<UserSecurityRightsDialog controller={new UserSecurityRightsController(user)} header={header} />);
    }

    const handleSave = () => {
        props.controller.save(userId => {
            if (props.controller.adding) {
                // after saving a new user - stay on same page with new id in address bar
                history.push('edituser', { userId: userId });
                // reload
                const customer = props.controller.state.customer;
                props.controller.load(userId, customer && customer.id, customer && customer.name);
            }
            else {
                history.goBack();
            }
        });
    }

    const handleCancel = () => {
        history.goBack();
    }

    var controller = props.controller;
    var isButtonDisabled = controller.adding;
    var btnSelectCustomerLocations = controller.state.customerAccount
        ? <Button icon='fa-globe' variant='info' onClick={handleSelectCustomerLocations} disabled={isButtonDisabled} >Customer Locations ...</Button>
        : null;
    var btnSelectFacilities = controller.state.customerAccount
        ? null
        : <Button icon='fa-building' variant='info' onClick={handleSelectFacilities} disabled={isButtonDisabled} >Facilities ...</Button>;
    var btnSecurityRights = controller.state.customerAccount
        ? null
        : <Button icon='fa-unlock' variant='danger' onClick={handleSecurityRights} disabled={isButtonDisabled} >Security Rights ...</Button>;

    return (
        <div className='col-sm-12'>
            <ButtonGroup size='sm' className='m-1 float-left'>
                <Button icon='fa-lock' variant='info' onClick={handleResetPassword} disabled={isButtonDisabled} >Reset Password ...</Button>
                <Button icon='fa-list' variant='outline-secondary' onClick={handleLoginActivity} disabled={isButtonDisabled} >Login Activity ...</Button>
                {btnSelectCustomerLocations}
                {btnSelectFacilities}
                {btnSecurityRights}
            </ButtonGroup>
            <div className='float-right'>
                <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button>
                <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    );
}

const EditUserPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class EditUserPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        this.load();
    }

    load = () => {
        const data = store.get('edit-user');
        const userId = this.props.match.params.userId;
        const customerId = data && data.customerId;
        const customerName = data && data.customerName;
        this.props.controller.load(userId, customerId, customerName);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.location !== this.props.location) {
            this.load();
        }
    }
    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();
    }

    static defaultProps = {
        controller: new EditUserController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const loginName = controller.state.loginName;
        const isCustomerUser = controller.state.customerAccount;
        const title = (controller.adding ? 'Create ' : 'Edit ') + (isCustomerUser ? 'Customer ' : 'FMC ') + 'User' + (loginName ? (' — ' + loginName) : '');

        return (
            <Page {...this.props} pageTitle='InteServ · Edit User' id='edit-user-page'>
                <EditUserPageHeader pageTitle={title} />
                <Page.Content>
                    <div className='row'>
                        <EditForm controller={controller} binder={binder} />
                        <Buttons controller={controller} />
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default EditUserPage;