import Channel from './Channel';

let __channels = {};

const Message = {
  channel(channelName) {
    let channel = __channels[channelName];
    if (!channel) {
      channel = __channels[channelName] = new Channel();
      let actions = {};
      channel.action = function(actionName) {
        let action = actions[actionName];
        if (!action) {
          action       = channel.where(message => message.action === actionName).map(m => m.data);
          let original = action.post;
          action.post  = function(data) {
            original.call(action, {channel: channelName, action: actionName, data: data});
          };
          actions[actionName] = action;
        }
        return action;
      };
    }
    return channel;
  }
};

export default Message;