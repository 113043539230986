import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';


class OverrideController extends BaseController {
  constructor(job) {
    super('colckOut',{  
      jobId:   job.id,
      jobNumber:job.jobNumber,
      reason : '',
      approverName      : '',
      approverSignature : '',
      errors: {}
    });
    this._validator = new Validator();
    this._validator.add('reason', 'Reason', [{required: true}]); 
    this._validator.add('approverName'      , 'Approved By'        , [{required: true}]);
    this._validator.add('approverSignature' , 'Approver Signature' , [{required: true}]); 
  }    
  clearSignature = () => {
    this.state.approverSignature = '';
    this.commit();
  }
  updateSignature = (signature) => {
    this.state.approverSignature = signature;
    this.commit();
  }
  saveOverride = () => {       
         
        // if(this.state.remainingHours==0){  
        //   notifications.action('error').post("Time cannot pushed to CATS because remaining time value is 0.");       
        //   return false;
        // }               

        const s = this.state;
        this._validator.validateAll(this.state); 
        const errors = Object.keys(s.errors)
        if(errors.length){
          return false;
        }  
        this.saving = true;
        this.commit();    

        const params = {
          jobId     : s.jobId,
          jobNumber : s.jobNumber,
          reason    : s.reason,
          approverName: s.approverName,
          approverSignature: s.approverSignature
        };       
        return ajax.post('jobprogress/override', params).then(response=>{
          this.saving = false;
          this.commit();
          return response;
        }).catch(err => {
          this.saving = false;
          this.commit();
          notification.action('error').post({ msg: err.message, title: 'Error' });
          return false;
        });
  }
  
  // hoursChanged(){
  //  const s = this.state;
  //  if(s.hours > 12 ){
  //   this.state.hours = 0;
  //   this.commit();
  //  }
  // }

  // minutesChanged(){
  //   const s = this.state;
  //   if(s.minutes > 59 ){
  //    this.state.minutes = 0;
  //    this.commit();
  //   }
  //  }
}

export default OverrideController;