import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import ShippingTicketDetailController from '../controllers/ShippingTicketDetailController';
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import AppConstants from '../utility/AppConstants';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import SignaturePad from '../../components/SignaturePad';

const ShippingTicketHeader = (props) => {
    const ticket = props.ticket;
    var isAdnocCustomer = ticket.isADNOCCustomer;
    const shippingId = <div className='col-md-6'> <Form.StaticData label='Shipping ID' value={ticket.shippingID} /></div>
    const ticketNumber = <div className='col-md-6'> <Form.StaticData label='Delivery Ticket' value={ticket.deliveryTicket} /></div>
    //ticket.Shipping ID
    return (
        <div className='col-md-12'>
            {isAdnocCustomer !== true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Shipping Ticket #' value={ticket.ticketNumber} /></div>
                    <div className='col-md-6'><Form.StaticData label='FMC Job Number' value={ticket.jobNumber} /></div>
                </div>
            }
            {isAdnocCustomer !== true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Customer P.O.' value={ticket.customerPoNumber} /></div>
                    <div className='col-md-6'><Form.StaticData label='Container' value={ticket.container} /></div>
                </div>
            }
            {isAdnocCustomer !== true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Shipping Date' value={DateTimeFormatter.formatDate(ticket.shippedDate)} /></div>
                    {shippingId}
                </div>
            }
            {isAdnocCustomer !== true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Customer Job Number' value={ticket.customerJobNumber} /></div>
                    <div className='col-md-6'><Form.StaticData label='Shipping User' value={ticket.shippedByName} /></div>
                </div>
            }
            {isAdnocCustomer === true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Shipping Ticket #' value={ticket.ticketNumber} /></div>
                    <div className='col-md-6'><Form.StaticData label='Shipping Date' value={DateTimeFormatter.formatDate(ticket.shippedDate)} /></div>
                </div>
            }
            {isAdnocCustomer === true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='FMC Job Number' value={ticket.jobNumber} /></div>
                    {ticketNumber}
                   </div>
            }
            {isAdnocCustomer === true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Customer P.O.' value={ticket.customerPoNumber} /></div>
                    <div className='col-md-6'><Form.StaticData label='Container' value={ticket.container} /></div>
                </div>
            }
            {isAdnocCustomer === true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Customer Job Number' value={ticket.customerJobNumber} /></div>
                    <div className='col-md-6'><Form.StaticData label='Shipping User' value={ticket.shippedByName} /></div>
                </div>
            }
            {isAdnocCustomer === true &&
                <div className='row'>
                    <div className='col-md-6'><Form.StaticData label='Job Description' value={ticket.jobDescription} /></div>
                </div>
            }
        </div>
    );
}

const ShippingTicketLocations = (props) => {
    const getToAddressElements = (customerLocation) => {
        let elements = [];
        if (customerLocation) {
            elements.push(customerLocation.locationName);
            elements.push(customerLocation.address1);
            elements.push(customerLocation.address2);
            elements.push(customerLocation.cityState);
        }
        return elements;
    }

    const getFromFacilityAddressElements = (facility) => {
        let elements = [];
        if (facility) {
            elements.push(facility.address1);
            elements.push(facility.address2);
            elements.push(facility.address3);
            elements.push(facility.cityState);
            if (facility.phone) {
                elements.push('Phone: ' + facility.phone);
            }
        }
        return elements;
    }
    const ticket = props.ticket;
    const border = 'solid 1px #CCC';
    const toAddress = getToAddressElements(ticket.customerLocation)
        .filter(elem => elem !== '')
        .map(elem => <Form.StaticData value={elem} />);
    const fromAddress = getFromFacilityAddressElements(ticket.shippingFacility)
        .filter(elem => elem !== '')
        .map(elem => <Form.StaticData value={elem} />);

    return (
        <div className='col-md-12' style={{ marginLeft: '15px', marginRight: '15px', border: border }}>
            <div className='row'>
                <div className='col-6' style={{ padding: '10px', borderRight: border }}>
                    <Form.StaticData label='To' style={{ marginBottom: 0 }} value={ticket.customerName} />
                    {toAddress}
                </div>

                <div className='col-6' style={{ padding: '10px' }}>
                    <Form.StaticData label='From' style={{ marginBottom: 0 }} />
                    {fromAddress}
                </div>
            </div>
        </div>
    );
}

const ShippingTicketNotes = (props) => {
    const handleEdit = () => {
        props.controller.editNote();
    }

    const handleSave = () => {
        props.controller.saveNotes();
    }

    const handleCancel = () => {
        props.controller.cancelSaveNotes();
    }

    const binder = props.binder;
    const ticket = props.ticket;
    const buttons = props.editing
        ? <div className='no-print'>
            <Button icon='fa-save' variant='success' size='sm' className='mr-1 action-button' onClick={handleSave}>Save</Button>
            <Button icon='fa-times' variant='secondary' size='sm' className='mr-1 action-button' onClick={handleCancel}>Cancel</Button>
        </div>
        : <Button icon='fa-pencil-alt' variant='secondary' size='sm' className='no-print mr-1 mb-1' onClick={handleEdit}>Edit</Button>;
    const ctlNote = props.editing
        ? <Form.TextArea rows={3} label='Notes' bind={binder.bind('notes')} />
        : <div className='row col-sm-12'><Form.StaticData label='Notes' value={ticket.notes} /></div>;
    const ctlSystemNote = ticket.systemNotes
        ? <div className='col-sm-12 row no-print'>
            <Form.StaticData label='System Notes' value={Utils.makeParagraphElements(ticket.systemNotes, { margin: '0' })} className='shipping-ticket-system-note font-italic' />
        </div>
        : null;

    return (
        <div className='col-md-12'>
            {ctlNote}
            {buttons}
            {ctlSystemNote}
        </div>
    );
}

const ShippingTicketAssetHeader = (props) => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.Head>Item</Table.Head>
                <Table.Head>Primary SN</Table.Head>
                <Table.Head>Secondary SN</Table.Head>
                <Table.Head>Equipment Number</Table.Head>
                <Table.Head>Description</Table.Head>
                <Table.Head>Asset Status</Table.Head>
                <Table.Head>Customer Status</Table.Head>
            </Table.Row>
        </Table.Header>
    );
}

const ShippingTicketAssetHeaderForADNOC = (props) => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.Head>Item</Table.Head>
                <Table.Head>Primary SN</Table.Head>
                <Table.Head>ADNOC SAP No.</Table.Head>
                {//<Table.Head>Equipment Number</Table.Head>
                }<Table.Head>Description</Table.Head>
                <Table.Head>Notes</Table.Head>
            </Table.Row>
        </Table.Header>
    );
}

const AssetRow = (props) => {
    const asset = props.asset;
    const item = props.item;
    let statusColourClass = 'status' + asset.assetStatus; // e.g.: statusScrapped
    if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
        statusColourClass = 'asset-status statusWeldRepair'
    if (asset.assetStatus === 'OS/Machining Repair')
        statusColourClass = 'asset-status statusOSRepair'
    const alignmentStyle = { textAlign: 'center' };

    return (
        <Table.Row>
            <Table.Data style={alignmentStyle}>{item}</Table.Data>
            <Table.Data>{asset.primarySerialNumber}</Table.Data>
            <Table.Data>{asset.secondarySerialNumber}</Table.Data>
            <Table.Data>{asset.equipmentNumber}</Table.Data>
            <Table.Data className='col-wrap'>{asset.description}</Table.Data>
            <Table.Data style={alignmentStyle} className={statusColourClass}>{asset.assetStatus}</Table.Data>
            <Table.Data style={alignmentStyle}>NA</Table.Data>
        </Table.Row>
    );
}



const AssetRowForADNOC = (props) => {
    const asset = props.asset;
    const binder = props.binder;
    const item = props.item;
    let statusColourClass = 'status' + asset.assetStatus; // e.g.: statusScrapped
    if (asset.assetStatus === 'Weld Repair' || asset.assetStatus === 'Failed Inspection')
        statusColourClass = 'asset-status statusWeldRepair'
    if (asset.assetStatus === 'OS/Machining Repair')
        statusColourClass = 'asset-status statusOSRepair'
    const alignmentStyle = { textAlign: 'center' };


    const notes = <Form.Input placeholder='' bind={binder.bind('note_' + (asset.assetId))} maxLength='100' />

    return (
        <Table.Row>
            <Table.Data style={alignmentStyle}>{item}</Table.Data>
            <Table.Data>{asset.primarySerialNumber}</Table.Data>
            <Table.Data>{asset.sapStatus}</Table.Data>
           {// <Table.Data>{asset.equipmentNumber}</Table.Data>
           }<Table.Data className='col-wrap'>{asset.description}</Table.Data>
            <Table.Data className='col-wrap'>{notes}</Table.Data>
            {
                //<Table.Data style={alignmentStyle} className={statusColourClass}>{asset.assetStatus}</Table.Data>
                //<Table.Data style={alignmentStyle}>NA</Table.Data>
            }
        </Table.Row>
    );
}

const TotalAssetsCount = (props) => {
    const ticket = props.ticket;
    const count = ticket && ticket.assets && ticket.assets.length;

    return <Table.Row><Table.Data colSpan={7} className='field-data font-weight-bold'>Total Assets Shipped: {count}</Table.Data></Table.Row>;
}

const SignaturePlaceholders = (props) => {

    const handleClearCustomerSignature = () => {
        props.controller.clearCustomerSignature();
    }

    const handleUpdateCustomerSignature = (signature) => {
        props.controller.updateSignature(signature);
    }

    const customerCanSignTicket = (ticket) => {
        return DateTimeFormatter.isMinDateTime(ticket.signedByCustomerTimestamp);
    }

    const binder = props.binder;
    const controller = props.controller;
    const errors = controller.state.errors;
    const ticket = controller.state;
    const showTechnicianSignature = !!ticket.signedByTechnicianSignature;
    const header = ticket.isADNOCCustomer === true ? 'Representative' : 'Technician';
    
    const signedByTechnicianSignature = showTechnicianSignature
        ? <SignaturePad className='required' canvasId='technician-signature' signature={ticket.signedByTechnicianSignature} disabled={true} width={156} height={75} showBorder={false} />
        : '_____________________________________';
    const signedByTechnicianName = ticket.signedByTechnicianName || '_____________________________________';
    const signedByTechnicianDate = showTechnicianSignature
        ? DateTimeFormatter.formatDate(ticket.signedByTechnicianTimestamp)
        : '______________';
    // const signedByTechnicianTime = showTechnicianSignature
    //     ? DateTimeFormatter.formatTime(ticket.signedByTechnicianTimestamp)
    //     : '______________';
    const signedByTechnicianTime = '______________';
    const customerCanSign = customerCanSignTicket(ticket);
    const signedByCustomerSignature = !customerCanSign
        ? <SignaturePad canvasId='customer-signature' className='required' label='Customer Signature' signature={ticket.signedByCustomerSignature} disabled={true} width={156} height={75} showBorder={false} />
        : <div>
            <SignaturePad canvasId='customer-signature' className='required bg-white' label='Customer Signature' width={400} height={200} backgroundColor='#FDD' onUpdateSignature={handleUpdateCustomerSignature} signature={ticket.signedByCustomerSignature} bind={binder.bind('signedByCustomerSignature')} error={errors.signedByCustomerSignature} disabled={false} showClear={true} />
        </div>;
    const signedByCustomerName = customerCanSign
        ? <Form.Input className='search-input' placeholder='Please enter your name' bind={binder.bind('signedByCustomerName')} error={errors.signedByCustomerName} />
        : <Form.StaticData value={ticket.signedByCustomerName} />;
    const signedByCustomerDate = customerCanSign
        ? '______________'
        : DateTimeFormatter.formatDate(ticket.signedByCustomerTimestamp);
    // const signedByCustomerTime = customerCanSign
    //     ? '______________'
    //     : DateTimeFormatter.formatTime(ticket.signedByCustomerTimestamp);
    const signedByCustomerTime = '______________'
    return (
        <Table.Row><Table.Data colSpan={7}>
            <Table hover={false}>
                <Table.Body>
                    <Table.Row><Table.Data className='no-bottom-border' colSpan={6}>Serial Number Verification performed by TechnipFMC {header}</Table.Data></Table.Row>
                    <Table.Row>
                        <Table.Data className='small-fixed no-bottom-border'>Sign:</Table.Data>
                        <Table.Data className='no-bottom-border'>{signedByTechnicianSignature}</Table.Data>
                        <Table.Data className='small-fixed no-bottom-border'>Date:</Table.Data>
                        <Table.Data className='no-bottom-border'>{signedByTechnicianDate}</Table.Data>
                        <Table.Data className='small-fixed no-bottom-border'>Time:</Table.Data>
                        <Table.Data className='no-bottom-border'>{signedByTechnicianTime}</Table.Data>
                    </Table.Row>
                    <Table.Row>
                        <Table.Data className='small-fixed'>Print:</Table.Data>
                        <Table.Data>{signedByTechnicianName}</Table.Data>
                        <Table.Data colSpan={4} />
                    </Table.Row>

                    <Table.Row><Table.Data className='no-bottom-border' colSpan={6}>Received in good order by</Table.Data></Table.Row>
                    <Table.Row>
                        <Table.Data className='small-fixed no-bottom-border' />
                        <Table.Data className='no-bottom-border'>{signedByCustomerSignature}</Table.Data>
                        <Table.Data className='small-fixed no-bottom-border'>Date:</Table.Data>
                        <Table.Data className='no-bottom-border'>{signedByCustomerDate}</Table.Data>
                        <Table.Data className='small-fixed no-bottom-border'>Time:</Table.Data>
                        <Table.Data className='no-bottom-border'>{signedByCustomerTime}</Table.Data>
                    </Table.Row>
                    <Table.Row>
                        <Table.Data className='small-fixed'>Print:</Table.Data>
                        <Table.Data>{signedByCustomerName}</Table.Data>
                        <Table.Data colSpan={4} />
                    </Table.Row>
                </Table.Body>
            </Table>
        </Table.Data>
        </Table.Row>
    );
}
const LegalNotice = (props) => {
    return (
        <div className='form-group col-12 table-container mt-1' style={{ borderTop: 'solid 1px #CCC' }}>
            <div className='row'>
                <Table hover={false}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Data className='no-border field-data' style={{ width: '66%' }}>Powered by TechnipFMC</Table.Data>
                            <Table.Data className='no-border field-label'>Copyright &copy; 2005-2017</Table.Data>
                        </Table.Row>
                        {/* <Table.Row>
                        <Table.Data className='no-border' style={{ width: '66%' }} />
                    </Table.Row> */}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

const Buttons = (props) => {

    let history = useHistory();

    const handleDownload = () => {
        props.controller.createPOD();
    }

    const handleUpload = () => {
        props.controller.uploadPOD();
    }
    const handleSave = () => {
        props.controller.save();
    }

    const handleClose = () => {
        history.goBack();
    }
    const editing = props.controller.state.editingNote;
    const displayPODButton = SAPService.isSAPFacility() && LoginService.isCoordinator;
    const canUploadPOD = props.controller.canUploadPOD();
    const canDownloadPOD = props.controller.canDownloadPOD();
    const canDisableSave = props.controller.canDisableSave();
    const uploadInProgress = props.controller.state.uploadInProgress;
    const ticket = props.controller.state;
    const podUploadDate = displayPODButton && props.controller.hasPODBeenUploaded() && <span className='shipping-ticket-system-note mr-1'> Uploaded: {DateTimeFormatter.formatDateTime(ticket.datePODUploaded)}</span>;
    const podButton = <span className='edit-buttons'>
        <Button id='btn-upload' icon='fa-download' variant='primary' size='sm' className='mr-1' onClick={handleDownload} disabled={!canDownloadPOD || props.controller.state.isGeneratingReport}>Download POD</Button>
    </span>;
    const podUpButton = displayPODButton &&
        <span className='edit-buttons'>
            <Button id='btn-upload' icon='fa-upload' variant='primary' size='sm' className='mr-1' onClick={handleUpload} disabled={!canUploadPOD || uploadInProgress} >Upload POD to SAP</Button>
            {podUploadDate}
        </span>;

    return (
        <div className='row'>
            <div className='col-12 no-print mb-1'>
                <div className='d-flex justify-content-between'>
                    <div>
                        {podButton}{podUpButton}
                    </div>
                    <span className='' id='save-button'>
                        <Button icon='fa-save' id='btn-save' variant='success' size='sm' className='mr-1 action-button' onClick={handleSave} disabled={canDisableSave} >Save</Button>
                        <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='mr-1 action-button' onClick={handleClose} disabled={editing} >Close</Button>
                    </span>
                </div>
            </div>
        </div>
    );
}

const ShippingTicketDetailPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-ticket-alt fa-flip-both' showBackNavigation={true} />

class ShippingTicketDetailPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        const ticketId = this.props.match.params.ticketId;
        this.props.controller.loadTicket(ticketId);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
    }

    static defaultProps = {
        controller: new ShippingTicketDetailController()
    }

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const ticket = controller.state;
        const isAdnocCustomer = ticket.isADNOCCustomer === true ? true : false;
        const title = 'Shipping Ticket — ' + ticket.ticketNumber;
        const assets = (ticket && ticket.assets) || [];
        let item = 1;
        let assetItemNo = 1;
        const passedAssets = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Passed)
            .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
        const scrappedAssets = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped)
            .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
        const unknownAssets = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Unknown)
            .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
        const pendingAssets = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Pending)
            .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
        const failedInspAssets = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.FailedInspection)
            .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
        const osRepairAssets = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.OSRepair)
            .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);
        const weldRepairAssets = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.WeldRepair)
            .map(asset => <AssetRow key={asset.assetId} item={item++} asset={asset} />);

        // Asset row consts for ADNOC customer
        const passedAssetsForADNOC = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Passed)
            .map(asset => <AssetRowForADNOC key={asset.assetId} item={assetItemNo++} asset={asset} binder={binder} />);

        const scrappedAssetsForADNOC = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped)
            .map(asset => <AssetRowForADNOC key={asset.assetId} item={assetItemNo++} asset={asset} binder={binder} />);
        const unknownAssetsForADNOC = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Unknown)
            .map(asset => <AssetRowForADNOC key={asset.assetId} item={assetItemNo++} asset={asset} binder={binder} />);
        const pendingAssetsForADNOC = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Pending)
            .map(asset => <AssetRowForADNOC key={asset.assetId} item={assetItemNo++} asset={asset} binder={binder} />);
        const failedInspAssetsForADNOC = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.FailedInspection)
            .map(asset => <AssetRowForADNOC key={asset.assetId} item={assetItemNo++} asset={asset} binder={binder} />);
        const osRepairAssetsForADNOC = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.OSRepair)
            .map(asset => <AssetRowForADNOC key={asset.assetId} item={assetItemNo++} asset={asset} binder={binder} />);
        const weldRepairAssetsForADNOC = assets
            .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.WeldRepair)
            .map(asset => <AssetRowForADNOC key={asset.assetId} item={assetItemNo++} asset={asset} binder={binder} />);

        const passedAssetsHeader = passedAssets.length
            ? <Table.Row><Table.Data colSpan={7} className='field-data font-weight-bold statusPassed'>Passed = {passedAssets.length}</Table.Data></Table.Row>
            : null;

        const scrappedAssetsHeader = scrappedAssets.length
            ? <Table.Row><Table.Data colSpan={7} className='field-data font-weight-bold statusScrapped'>Scrapped = {scrappedAssets.length}</Table.Data></Table.Row>
            : null;
        const failedInspHeader = failedInspAssets.length
            ? <Table.Row><Table.Data colSpan={7} className='field-data font-weight-bold statusScrapped'>Failed Inspection = {failedInspAssets.length}</Table.Data></Table.Row>
            : null;
        const osRepairHeader = osRepairAssets.length
            ? <Table.Row><Table.Data colSpan={7} className='field-data font-weight-bold statusPassed'>OS/Machining Repair = {osRepairAssets.length}</Table.Data></Table.Row>
            : null;
        const weldRepairHeader = weldRepairAssets.length
            ? <Table.Row><Table.Data colSpan={7} className='field-data font-weight-bold statusScrapped'>Weld Repair = {weldRepairAssets.length}</Table.Data></Table.Row>
            : null;
        const unknownAssetsHeader = unknownAssets.length
            ? <Table.Row><Table.Data colSpan={7} className='field-data font-weight-bold statusUnknown'>Unknown = {unknownAssets.length}</Table.Data></Table.Row>
            : null;

        const pendingAssetsHeader = pendingAssets.length
            ? <Table.Row><Table.Data colSpan={7} className='field-data statusPending'>Pending = {pendingAssets.length}</Table.Data></Table.Row>
            : null;

        return (
            <Page pageTitle='InteServ · Shipping Ticket Detail' id='shipping-ticket-detail-page'>
                <ShippingTicketDetailPageHeader pageTitle={title} />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row'>
                                <ShippingTicketHeader ticket={ticket} />
                                <ShippingTicketLocations ticket={ticket} />
                                <ShippingTicketNotes ticket={ticket} controller={controller} binder={binder} editing={controller.state.editingNote} className='no-pod-upload' />
                            </div>

                            <div className='row'>
                                <div id='search-results' className='form-group col-12 table-container'>
                                    {!isAdnocCustomer && <Table id='results-table' hover={false} responsive={false}>
                                        <ShippingTicketAssetHeader />
                                        <Table.Body>
                                            {passedAssetsHeader}
                                            {passedAssets}
                                            {scrappedAssetsHeader}
                                            {scrappedAssets}
                                            {unknownAssetsHeader}
                                            {unknownAssets}
                                            {pendingAssetsHeader}
                                            {pendingAssets}
                                            {failedInspHeader}
                                            {failedInspAssets}
                                            {osRepairHeader}
                                            {osRepairAssets}
                                            {weldRepairHeader}
                                            {weldRepairAssets}
                                            <TotalAssetsCount ticket={ticket} />
                                            <SignaturePlaceholders controller={controller} binder={binder} />
                                        </Table.Body>
                                    </Table>
                                    }

                                    {isAdnocCustomer && <Table id='results-table' hover={false} responsive={false}>
                                        <ShippingTicketAssetHeaderForADNOC />
                                        <Table.Body>
                                            {passedAssetsHeader}
                                            {passedAssetsForADNOC}
                                            {scrappedAssetsHeader}
                                            {scrappedAssetsForADNOC}
                                            {unknownAssetsHeader}
                                            {unknownAssetsForADNOC}
                                            {pendingAssetsHeader}
                                            {pendingAssetsForADNOC}
                                            {failedInspHeader}
                                            {failedInspAssetsForADNOC}
                                            {osRepairHeader}
                                            {osRepairAssetsForADNOC}
                                            {weldRepairHeader}
                                            {weldRepairAssetsForADNOC}
                                            <TotalAssetsCount ticket={ticket} />
                                            <SignaturePlaceholders controller={controller} binder={binder} />
                                        </Table.Body>
                                    </Table>
                                    }
                                </div>
                            </div>

                            <Buttons controller={controller} />
                            <LegalNotice />

                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default ShippingTicketDetailPage;