import React from 'react';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import SearchController from '../controllers/SearchController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Dialog from '../../components/Dialog';
import store from 'store';
import SAPService from '../services/SAPService';
import Table from '../../components/Table';
import InspectionAttachmentDialog from '../pages/InspectionAttachmentDialog';
import InspectionAttachmentDialogController from '../controllers/InspectionAttachmentDialogController';

class InspectionPassedController extends BaseController {
  constructor(jobId, testName, assets, isOSRepair) {
    super('pass-test', {
      jobId: jobId,
      testName: testName,
      assets: assets,
      inspectionMinutes: 0,
      inspectionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      technician: '',
      vendor: '',
      recordZeroTime: false,
      isOSRepair: isOSRepair,
      notesId: Utils.emptyGuid,
      notes: '',
      guageSerPrim: '',
      errors: {},
      completionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      rejectReason: ''
    });

    this.saving = false;
    this._searchController = new SearchController();
    this._validator = new Validator();
    this.getInspectionNotes();
    this.loadValidators();
  }
  loadValidators = () => {
    this._validator.add('inspectionDate', 'Inspection Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: DateTimeFormatter.today() } }]);
    if (this.state.isOSRepair && SAPService.isSAPFacility()) {
      this._validator.add('rejectReason', 'Reject Reason', [{ required: true }]);
      this._validator.add('completionDate', 'Schedule Completion Date', [{ required: true }]);
    }
  }
  get searchController() { return this._searchController; }

  vendorChanged = (data) => {
    this.state.recordZeroTime = false;
    if (data) {
      this.state.technician = '';
      this.commit();
    }
  }

  technicianChanged = (data) => {
    if (data) {
      this.state.vendor = '';
      this.commit();
    }
  }

  recordZeroTimeChanged = () => {
    if (this.state.recordZeroTime) {
      this.state.inspectionMinutes = 0;
    }
  }

  getInspectionNotes = () => {
    var self = this;
    var s = self.state;
    var param = {
      jobId: s.jobId,
      testName: s.testName,
      assetId: s.assets[0].jobSOWId,
    }
    ajax.post('lookup/getInspectionTestNotes', param).then(result => {
      if (result) {
        self.state.notesId = result.notesId || Utils.emptyGuid
        self.state.notes = result.notes || '';
        self.commit();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  canSave = () => {
    if (this.state.isOSRepair && SAPService.isSAPFacility()) {
      this._validator.add('completionDate', 'Schedule Completion Date', [{ required: true }]);
    }
    var self = this;
    this._validator.validateAll(self.state);
    let errors = Object.keys(self.state.errors);
    if (errors.length) {
      return false;
    }
    var validCompletionDate = !isNaN(Date.parse(this.state.completionDate));
    if (!validCompletionDate) {
      notification.action('error').post("Completion date not in correct format");
      return false;
    }
    if (this.state.isOSRepair && SAPService.isSAPFacility()) {
      this._validator.add('completionDate', 'Completion Date must be greater than or equal to today', [{ isGreaterThanEqualsDate: { params: DateTimeFormatter.formatDate(DateTimeFormatter.today()) } }]);
      this.commit();
      this._validator.validateAll(this.state);
      errors = Object.keys(this.state.errors);
      this.commit();
      if (errors.length) {
        return false;
      }
    }

    self.commit();
    return true;
  }

  * save() {
    if (!this.canSave()) {
      return;
    }
    var s = this.state;
    var loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
      // if(parseFloat(s.inspectionMinutes).toFixed(1)<=0)
      // {
      //   notification.action('error').post("After rounding to 1 decimal, time will become 0. Inspection Time should be greater than 0");
      //   return false;
      // }    
      var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (s.inspectionMinutes > availableTime) {
        notification.action('error').post("Inspection Time should not be more than Available Production Time");
        return false;
      }
    }
    var self = this;
    if (self.saving)
      return;

    self.saving = true;
    self.commit();


    var parameters = {
      jobId: s.jobId,
      testName: s.testName,
      selectedAssets: s.assets,
      inspectionMinutes: s.inspectionMinutes,
      inspectionDate: s.inspectionDate,
      technician: s.technician,
      vendorId: s.vendor && s.vendor.id,
      recordZeroTime: s.recordZeroTime,
      isOSRepair: s.isOSRepair,
      notesId: s.notesId,
      guageSerPrimId: s.guageSerPrim.id,
      notes: s.notes || null,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      completionDate: s.completionDate,
      rejectReasonId: s.rejectReason ? s.rejectReason.id : '',
      rejectReasonName: s.rejectReason ? s.rejectReason.name : '',
      enableDMS: loginInfo.enableDMS
    };
    if (this.state.isOSRepair) {
      var controller = new InspectionAttachmentDialogController(this.state.assets, this, this.state.testName);
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog className='inspection-attachment-dialog' width={600} height={700} model={true} header='OS/Machining Repair attachments' popup='attachmentPopup'>
          <InspectionAttachmentDialog controller={controller} />
          <Dialog.SaveCloseButtons popup={'attachmentPopup'} />
        </Dialog>
        , 'attachmentPopup');

      if (!result.Save) {
        self.saving = false;
        self.commit();
        return;
      }
      else {
        var assetsWithFile = controller.state.assets.filter(asset => (asset.file && asset.file.length));
        if (assetsWithFile.length) {
          var fileAttachment = [];
          assetsWithFile.map(attach => {
            return fileAttachment.push({
              jobId: attach.assetId,
              files: attach.file,
              jobSOWId: attach.jobSOWId,
              testName: this.state.testName
            });
          });
          parameters.attachment = fileAttachment;
        }
      }
    }
    if (s.testName == 'Final Inspection') {
      var results1 = yield ajax.post('inspection/componentTest', parameters);
      if (results1.length) {
        var comRows = results1.map(com => {
          var Resdiv = '';
          if (com.Enmng !== '0.000' && com.Enmng === com.Menge)
            Resdiv = <Table.Row className="comIssued"><Table.Data>{com.Orderid}</Table.Data><Table.Data>{com.Matnr}</Table.Data><Table.Data>{com.Menge}</Table.Data><Table.Data>{com.Enmng}</Table.Data><Table.Data>{com.Werks}</Table.Data></Table.Row>
          else
            Resdiv = <Table.Row className="comNotIssued"><Table.Data>{com.Orderid}</Table.Data><Table.Data>{com.Matnr}</Table.Data><Table.Data>{com.Menge}</Table.Data><Table.Data>{com.Enmng}</Table.Data><Table.Data>{com.Werks}</Table.Data></Table.Row>
          return Resdiv;
        });
        //const componetList= results1.componetMessages.map(element => <li>{element}</li>);
        var result2 = yield Dialog.showDialogWaitForResult(
          <Dialog id='comp' className='comp-update-dialog' width={450} height={450} model={true} header='Components Issued' popup='childPopup'>
            <Dialog.Body>
              <div className='w2uicen'>
                <div>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Head>
                          SERVICE ORDER
                        </Table.Head>
                        <Table.Head>
                          PART NUMBER
                        </Table.Head>
                        <Table.Head>
                          PLANNED
                        </Table.Head>
                        <Table.Head>
                          ISSUED
                         </Table.Head>
                        <Table.Head>
                          PLANT
                        </Table.Head>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {comRows}
                    </Table.Body>
                  </Table>
                </div>
                <br />
                <div>Do you want to continue?</div>
              </div>
            </Dialog.Body>
            <Dialog.OKCancelButtons popup={'childPopup'} />
          </Dialog>
          , 'childPopup');
        if (!result2.OK) {
          self.saving = false;
          self.commit();
          return false;
        }
        else {
          try {
            var results = yield ajax.post('inspection/passTest', parameters); // in: UpdateTestDto, out: InspectionResultDto
            var assetIds = s.assets.map(a => a.assetId);
            messages.channel('inspection').action('saved').post({ jobId: s.jobId, assetIds: assetIds });
            //messages.channel('productiveTime').action('update').post(results.prodTime);    
            this.saving = false;
            this.commit();
            if (results.successMessages.length) {
              notification.action('success').post('<p>' + results.successMessages.join('</p><p>') + '</p>');
              return true;
            }
            if (results.errorMessages.length) {
              notification.action('error').post('<p>' + results.errorMessages.join('</p><p>') + '</p>');
              return false;
            }
          }
          catch (err) {
            this.saving = false;
            this.commit();
            notification.action('error').post({ msg: this.getErrorMessage(err.message), title: 'Pass Test Error' });
            return false;
          }
        }
      }
      else {
        try {
          var results = yield ajax.post('inspection/passTest', parameters); // in: UpdateTestDto, out: InspectionResultDto
          var assetIds = s.assets.map(a => a.assetId);
          messages.channel('inspection').action('saved').post({ jobId: s.jobId, assetIds: assetIds });
          //messages.channel('productiveTime').action('update').post(results.prodTime);    
          this.saving = false;
          this.commit();
          if (results.successMessages.length) {
            notification.action('success').post('<p>' + results.successMessages.join('</p><p>') + '</p>');
            return true;
          }
          if (results.errorMessages.length) {
            notification.action('error').post('<p>' + results.errorMessages.join('</p><p>') + '</p>');
            return false;
          }
        }
        catch (err) {
          this.saving = false;
          this.commit();
          notification.action('error').post({ msg: this.getErrorMessage(err.message), title: 'Pass Test Error' });
          return false;
        }
      }
    }
    else {
      try {
        var results = yield ajax.post('inspection/passTest', parameters); // in: UpdateTestDto, out: InspectionResultDto
        var assetIds = s.assets.map(a => a.assetId);
        messages.channel('inspection').action('saved').post({ jobId: s.jobId, assetIds: assetIds });
        //messages.channel('productiveTime').action('update').post(results.prodTime);    
        this.saving = false;
        this.commit();
        if (results.successMessages.length) {
          notification.action('success').post('<p>' + results.successMessages.join('</p><p>') + '</p>');
          return true;
        }
        if (results.errorMessages.length) {
          notification.action('error').post('<p>' + results.errorMessages.join('</p><p>') + '</p>');
          return false;
        }
      }
      catch (err) {
        this.saving = false;
        this.commit();
        notification.action('error').post({ msg: this.getErrorMessage(err.message), title: 'Pass Test Error' });
        return false;
      }
    }
  }
  getErrorMessage = (message) => {
    if (Utils.contains(message, 'Please enter')) {
      let messages = message.split('|');
      message = '<p>' + messages.join('</p><p>') + '</p>';
    }
    return message;
  }
}

export default InspectionPassedController;