import React, { useMemo, useEffect } from 'react';
import store from 'store';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Router, { useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Badge from '../../components/Badge';
import Grid from '../../components/Grid';
import BigGrid from '../../components/BigGrid';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dropdown from '../../components/Dropdown';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Searchers from '../utility/Searchers';
import SerializedAssetSearchController from '../controllers/SerializedAssetSearchController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import Roles from '../utility/Roles';
import Utils from '../utility/Utils';
import QRSticker from '../components/QRSticker';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Dialog from '../../components/Dialog';
import CompletionDateDialog from '../dialogs/CompletionDateDialog';
import CompletionDateController from '../controllers/CompletionDateController';
import AppConstants from '../utility/AppConstants.js';
import notifications from '../../services/Notification';
import SAPService from '../services/SAPService';
import AssetManagementTabPanel from '../pages/AssetManagementTab';
import Table from '../../components/Table';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import { json } from 'd3';

const CommonSearchParameters = (props) => {
  const binder = props.binder;
  const valueStyle = { border: 'solid 2px goldenrod' };

  return (
    <div className='col mb-2 pt-2' style={{ backgroundColor: '#dcf5de', borderRadius: '10px', border: 'solid 1px lightgrey' }}>
      <div className='row'><div className='col' onKeyDown={props.handleSearch} onInput={props.handleInput}><Form.Input label='Primary Serial Number' valueStyle={valueStyle} bind={binder.bind('primarySerialNumber')} hint='allows partial matches' prependLabelIcon='fa-rss' /></div></div>
      <div className='row'><div className='col' onKeyDown={props.handleSearch}><Form.Input label='Secondary Serial Number' valueStyle={valueStyle} bind={binder.bind('secondarySerialNumber')} hint='allows partial matches' /></div></div>
      <div className='row'><div className='col' onKeyDown={props.handleSearch}><Form.NumberInput type='number' label='Equipment Number' maxLength='8' valueStyle={valueStyle} bind={binder.bind('equipmentNumber')} hint='allows partial matches' /></div></div>
      <div className='row'><div className='col' onKeyDown={props.handleSearch}><Form.Input label='Description' valueStyle={valueStyle} bind={binder.bind('manufacturerDescriptionTokens')} hint='specify portions of manufacturer description to filter results' placeholder='use commas to separate multiple search criteria' /></div></div>
    </div>
  );
}

const CustomerSearchParameters = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  const jobNumbers = binder.bind('jobNumbers')();
  const customer = binder.bind('customer')();
  const customerLocation = binder.bind('customerLocation')();
  const valueStyle = { border: 'solid 2px goldenrod' };
  const defaultGUID = Utils.emptyGuid;
  const style = controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.MassTransfer ? 'required' : '';
  // for use in Customer / Current Location combo boxes
  const formatResult = item => {
    const spanFMCLocation = item.result.fmcLocation
      ? <span className='fmc-location float-right select2-tag'>&nbsp;&nbsp;TechnipFMC</span>
      : null;

    const iconArchived = item.result.archived
      ? <i className='fa fa-archive archived pull-right select2-tag' />
      : null;

    return <span>{item.text} {iconArchived} {spanFMCLocation}</span>;
  };

  const ctlCustomer = LoginService.isCustomer
    ? <div className='row'><div className='col'><Form.StaticData label='Customer' value={customer && customer.name} /></div></div>
    : <div className='row'><div className='col'><Form.AutoComplete className={style} label='Customer' bind={binder.bind('customer')} search={Searchers.customerSearch()} valueStyle={valueStyle} error={controller.state.errors.customer} /></div></div>;

  const customerHint = LoginService.isCustomer
    ? ''
    : 'requires customer selection';
  const jobNumberPropsBase = {
    label: 'Job Numbers',
    bind: binder.bind('jobNumbers'),
    hint: customerHint,
    id: 'jobNumbers'
  }

  const jobNumberProps = Object.assign(
    {
      className: 'jobNumber-select',
      search: Searchers.reportsJobNumberSearch({ customerId: customer ? customer.id : defaultGUID, facilityId: LoginService.loginInfo ? LoginService.loginInfo.facilityId : defaultGUID, locationId: customerLocation ? customerLocation.id : defaultGUID }),
      valueStyle: valueStyle,
      formatResult: formatResult,
      formatSelection: formatResult,
      multiple: true
    }
    , jobNumberPropsBase)

  return (
    <div className='col mb-2 pt-2' style={{ backgroundColor: 'rgba(230,136,24,.21)', borderRadius: '10px', border: 'solid 1px lightgrey' }}>
      {ctlCustomer}
      <div className='row'><div className='col'><Form.AutoComplete label='Customer Location' bind={binder.bind('customerLocation')} search={Searchers.customerLocationSearch(customer ? customer.id : Utils.emptyGuid)} hint={customerHint} valueStyle={valueStyle} enabled={customer} formatResult={formatResult} formatSelection={formatResult} /></div></div>
      <div className='row'><div className='col'><Form.AutoComplete label='Current Location' bind={binder.bind('currentLocation')} search={Searchers.customerLocationSearch(customer ? customer.id : Utils.emptyGuid)} hint={customerHint} valueStyle={valueStyle} enabled={customer} formatResult={formatResult} formatSelection={formatResult} /></div></div>
      <div className='row'><div className='col job-number-muliselect-input'>{customer ? <Form.AutoComplete {...jobNumberProps} /> : <Form.Input {...jobNumberPropsBase} disabled={true} />}</div></div>
      <div className='row'><div className='col' onKeyDown={props.handleSearch}><Form.Input bind={binder.bind('container')} hint='allows partial matches' valueStyle={valueStyle} label='Container' /></div></div>
      <div className='row'>
        <div className='col-md-6'><Form.AutoComplete label='Group Number/Name' bind={binder.bind('groupNumber')} enabled={jobNumbers && jobNumbers.length == 1} search={Searchers.groupNumberSearch(jobNumbers ? jobNumbers[0].id : Utils.emptyGuid)} /></div>
        <div className='col-md-6'><Form.AutoComplete label='SO Status' bind={binder.bind('soStatus')} enabled={jobNumbers} data={AppConstants.SOStatus} /></div>
      </div>
    </div>
  );
}

const SimpleSearchTabPanel = (props) => {
  return (
    <CommonSearchParameters handleSearch={props.handleSearch} handleInput={props.handleInput} binder={props.binder} />
  );
}

const CustomerSearchTabPanel = (props) => {
  return (
    <CustomerSearchParameters controller={props.controller} handleSearch={props.handleSearch} binder={props.binder} />
  );
}

const Transfer = (props) => {
  const binder = props.binder;
  return (
    <div>
      <CustomerSearchParameters controller={props.controller} handleSearch={props.handleSearch} binder={binder} />
      <CommonSearchParameters handleSearch={props.handleSearch} binder={binder} handleInput={props.handleInput} />
    </div>
  );
}

const AdvancedSearchTabPanel = (props) => {
  const binder = props.binder;
  const controller = props.controller;
  const searchOrder = props.controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.MassTransfer
    ? <Transfer {...props} />
    : <div>
      <CommonSearchParameters handleSearch={props.handleSearch} binder={binder} handleInput={props.handleInput} />
      <CustomerSearchParameters controller={props.controller} handleSearch={props.handleSearch} binder={binder} />
    </div>;

  return (
    <div>
      {searchOrder}
      <div className='col mb-2 pt-2' style={{ backgroundColor: 'aliceBlue', borderRadius: '10px', border: 'solid 1px lightgrey' }}>
        <div className='row'><div className='col' onKeyDown={props.handleSearch} onInput={props.handleInput}><Form.Input label='Batch Number' maxLength='250' bind={binder.bind('batchNumber')} hint='allows partial matches' /></div></div>
        <div className='row'><div className='col'><Form.Input label='Customer Job' bind={binder.bind('customerJob')} hint='allows partial matches' /></div></div>
        <div className='row'><div className='col'><Form.AutoComplete label='Family Code' bind={binder.bind('familyCode')} search={Searchers.listSearch('family code')} /></div></div>

        <div className='row'>
          <div className='col-md-6'><Form.Input label='SO' bind={binder.bind('so')} hint='allows partial matches' /></div>
          <div className='col-md-6'><Form.Input label='PO#' bind={binder.bind('po')} hint='allows partial matches' /></div>
        </div>

        <div className='row'><div className='col'><Form.AutoComplete label='User Status' bind={binder.bind('equipmentStatus')} search={Searchers.equipmentStatusSearch()} /></div></div>

        <div className='row'>
          <div className='col-md-6'><Form.AutoComplete label='Nominal Size' bind={binder.bind('nominalSize')} search={Searchers.listSearch('nominal size')} /></div>
          <div className='col-md-6'><Form.AutoComplete label='Length' bind={binder.bind('length')} search={Searchers.listSearch('length')} /></div>
        </div>

        <div className='row'><div className='col'><Form.AutoComplete label='Style' bind={binder.bind('style')} search={Searchers.listSearch('style')} /></div></div>
        <div className='row'><div className='col'><Form.AutoComplete label='Manufacturer' bind={binder.bind('manufacturer')} search={Searchers.listSearch('manufacturer')} /></div></div>

        <div className='row'>
          <div className='col-md-6'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={Searchers.listSearch('cwp')} /></div>
          <div className='col-md-6'><Form.AutoComplete label='Degree' bind={binder.bind('degree')} search={Searchers.listSearch('degree')} /></div>
        </div>

        <div className='row'><div className='col'><Form.AutoComplete label='Service' bind={binder.bind('service')} search={Searchers.listSearch('service')} /></div></div>

        <div className='row'>
          <div className='col-md-6'><Form.AutoComplete label='ID' bind={binder.bind('id')} search={Searchers.listSearch('id')} /></div>
          <div className='col-md-6'><Form.AutoComplete label='OD' bind={binder.bind('od')} search={Searchers.listSearch('od')} /></div>
        </div>

        <div className='row'>
          <div className='col-md-6'><Form.AutoComplete label='Mfg Part #' bind={binder.bind('mfgItemNo')} search={Searchers.mfgItemSearch()} /></div>
          <div className='col-md-6'><Form.AutoComplete label='Asset Status' bind={binder.bind('status')} data={AppConstants.AssetStatus} /></div>
        </div>

        <div className='row'><div className='col-12'><Form.NumberInput bind={binder.bind('shippingTicket')} hint='whole number only' label='Shipping Ticket #' /></div></div>

        <div className='row'>
          <div className='col-md-6'><Form.Date className='reduced-text-area' label='Cycle Range From' bind={binder.bind('cycleFromDate')} error={controller.state.errors.cycleFromDate} options={{ end: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()) }} /></div>
          <div className='col-md-6'><Form.Date className='reduced-text-area' label='Cycle Range To' bind={binder.bind('cycleToDate')} error={controller.state.errors.cycleToDate} options={{ end: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()) }} /></div>
        </div>
      </div>
    </div>
  );
}

const ErrorsTabPanel = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  const transferErrors = controller.state.transferErrors || [];
  const errorListContents = transferErrors.length
    ? transferErrors.map((error, index) => <li className='asset p-1' key={index}>{error}</li>) :
    <li className='p-1'>No Errors, all assets transferred successfully</li>;
  return (
    <div>
      <div className='advanced-search-criteria row'>
        <div style={{
          backgroundColor: 'aliceBlue',
          padding: '7px 7px 1px 7px',
          borderRadius: '10px',
          border: 'solid 1px lightgrey',
          marginBottom: '2px'
        }}>

          <div className='row'>
            <div className='col-md-12'>
              <List className='asset-list error-list'>
                {errorListContents}
              </List>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ResultsList = React.memo((props) => {

  let history = useHistory();

  const handleEdit = (assetId) => {
    if (LoginService.isCustomer) {
      history.push('/editfieldasset/' + assetId);
    }
    else {
      history.push('/asset/' + assetId);
    }
  }

  const handleReassign = (assetId) => {
    history.push('/jobprogress/' + assetId);
  }

  const navigateToReceiving = () => {
    history.push('/receiving');
  }

  const handleAttachFiles = (item) => {
    const equipmentNumber = (item.equipmentNumber != '' ? item.equipmentNumber : '-');
    history.push('/inspectionattachments/' + item.assetId + '/' + item.primarySerialNumber + '/' + equipmentNumber);
  }

  const getData = () => {
    return props.controller.state.searchResults;
  }

  const controller = props.controller;
  const data = useMemo(() => getData(), [props.controller.state.searchResults]);
  const matchesCount = (data || []).length;
  const selectedData = data.filter(d => d.selected);
  const selectionType = useMemo(() => {
    let type = 'none';
    if (data.length !== 0 && selectedData.length !== 0) {
      if (data.length === selectedData.length) {
        type = 'all';
      }
      else if (selectedData.length > 0) {
        type = 'some';
      }
      else {
        type = 'none';
      }
    }
    return type;

  }, [data.length, selectedData.length]);

  const handleRowClick = (data) => {
    if (data && data.length) {
      props.controller.toggleSelection(data);
    }
    else {
      props.controller.selectNoAssets();
    }
  }

  var noDataText = controller.state.isLoading ? 'Loading assets that meet the search criteria defined' : 'No assets meet the search criteria defined';

  const columns = useMemo(
    () => [
      {
        Header: () => {
          const totalCount = controller.state.totalResultsCount;
          const matchesLabel = (matchesCount === totalCount)
            ? Utils.pluralize('match', 'es', matchesCount)
            : matchesCount + ' / ' + totalCount + ' matches';
          return <Badge variant='info'>{matchesLabel}</Badge>;
        },
        accessor: 'assetId',
        width: 150,
        disableSortBy: true,
        Cell: cellInfo => {
          const btnEditAsset = LoginService.isTechnician
            ? <ActionIcon icon='fa-pencil-alt' className='icon-edit text-info m-1' action={() => handleEdit(cellInfo.cell.value)} />
            : null;
          const btnReassign = LoginService.isCoordinator ?
            <>
              {
                cellInfo.row.original.jobNumber && cellInfo.row.original.jobNumber.length > 0 ?
                  <ActionIcon style={{ opacity: 0.6 }} icon='fa-retweet' className='icon-reassign text-warning m-1' />
                  :
                  <ActionIcon icon='fa-retweet' className='icon-reassign text-warning m-1' action={() => handleReassign(cellInfo.cell.value)} />
              }
            </>
            : null;
          const imageCount = <Badge variant='warning' className='image-badge s-image-badge m-1' onClick={() => handleAttachFiles(cellInfo.row.original)} dataToggle="tooltip" dataPlacement="top" title="Click here to navigate to attachments page">{cellInfo.row.original.imgCount}</Badge>;
          const iconOnReceivingList = cellInfo.row.original.onReceivedAssetsList
            ? <ActionIcon icon='fa-download' className='icon-receiving text-primary m-1' action={() => navigateToReceiving()} />
            : null;
          const iconCannotReceive = cellInfo.row.original.cannotReceive
            ? <ActionIcon icon='fa-warning' className='icon-warning text-danger m-1' title={cellInfo.row.original.reasonCannotReceive} />
            : null;
          return <span style={{ cursor: 'pointer' }}>
            {btnEditAsset}
            {btnReassign}
            {imageCount}
            {iconOnReceivingList}
            {iconCannotReceive}
          </span>
        }
      },
      {
        Header: 'Primary Serial Number',
        accessor: 'primarySerialNumber',
        width: 190,
        Cell: (cellInfo) => <Form.Link to={'/assetdetail/' + cellInfo.row.original.assetId} value={cellInfo.cell.value} />
      }, 
      {
        Header: 'Equipment Number',
        accessor: 'equipmentNumber',
        width: 170,
      },
      {
        Header: 'User Status',
        accessor: 'userStatus',
        width: 200,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 160,
        Cell: (cellInfo) => {
          const statusClass = (cellInfo.cell.value == 'OS/Machining Repair' ? 'OSRepair' : ((cellInfo.cell.value == 'Weld Repair' || cellInfo.cell.value == 'Failed Inspection') ? 'WeldRepair' : cellInfo.cell.value));
          return <span className={'font-weight-bold status' + statusClass}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 800,
        Cell: (cellInfo) => LoginService.isSuperUser ? <Form.Link to={'/customerdescription/' + cellInfo.row.original.custDescId} value={cellInfo.cell.value} /> : <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Mfg Part #',
        width: 120,
        accessor: 'mfgPartNumber',
        Cell: (cellInfo) => <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Customer',
        width: 250,
        accessor: 'customer',
        Cell: (cellInfo) => <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Current Location',
        accessor: 'currentLocation',
        width: 250,
      },
      {
        Header: 'Container',
        accessor: 'container',
        width: 200,
      },
      {
        Header: 'Job Number',
        accessor: 'jobNumber',
        width: 120,
      },
      {
        Header: 'Born On Date',
        accessor: 'bornOnDate',
        width: 160,
        Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
      },
     /* {
        Header: 'Cycle Submit Date',
        accessor: 'plannedStartDate',
        width: 180,
        Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value) != '01-Jan-1900' ? DateTimeFormatter.formatDate(cellInfo.cell.value) : ''}</span>
      }*/
    ],
    [matchesCount]
  )
  return (
    <div className='result-table-container'>
      <BigGrid columns={columns} data={data} noDataText={noDataText} selectionType={selectionType} onRowClick={handleRowClick} allowMultiSelect={true} />
    </div>
  );
});

const AssetManagementResults = (props) => {

  let history = useHistory();

  const handleEdit = (assetId) => {
    if (LoginService.isCustomer) {
      history.push('/editfieldasset/' + assetId);
    }
    else {
      history.push('/asset/' + assetId);
    }
  }

  const handleReassign = (assetId) => {
    history.push('/jobprogress/' + assetId);
  }

  const handleJobSearch = (assetId) => {
    history.push('/jobprogressassets/' + assetId);
  }

  const navigateToReceiving = () => {
    history.push('/receiving');
  }

  const handleAttachFiles = (item) => {
    const equipmentNumber = (item.equipmentNumber != '' ? item.equipmentNumber : '-');
    history.push('/inspectionattachments/' + item.assetId + '/' + item.primarySerialNumber + '/' + equipmentNumber);
  }

  const controller = props.controller;
  const loginDetail = store.get('InteServLoginInfo');
  const data = useMemo(() => props.controller.state.assetMgmtSearchResults, [props.controller.state.assetMgmtSearchResults]);
  const matchesCount = (data || []).length;
  const selectedData = data.filter(d => d.selected);
  const selectionType = useMemo(() => {
    let type = 'none';
    if (data.length !== 0 && selectedData.length !== 0) {
      if (data.length === selectedData.length) {
        type = 'all';
      }
      else if (selectedData.length > 0) {
        type = 'some';
      }
      else {
        type = 'none';
      }
    }
    return type;

  }, [data.length, selectedData.length]);

  const handleRowClick = (data) => {
    if (data && data.length) {
      props.controller.toggleSelection(data);
    }
    else {
      props.controller.unselectAllAssetManagementResults();
    }
  }

  document.addEventListener('copy', function (e) {
    var text = window.getSelection().toString().replace(/[\n\r]+/g, '');
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
  });
  const redirectToassembly = () => {
    history.push('/assembly');
  }
  const noDataText = controller.state.isLoading ? 'Loading assets that meet the search criteria defined' : 'No assets meet the search criteria defined';
  const columns = useMemo(
    () => [
      {
        Header: () => {
          const totalCount = controller.state.totalResultsCount;
          const matchesLabel = (matchesCount === totalCount)
            ? Utils.pluralize('match', 'es', matchesCount)
            : matchesCount + ' / ' + totalCount + ' matches';
          return <Badge variant='info'>{matchesLabel}</Badge>;
        },
        accessor: 'assetId',
        disableSortBy: true,
        width: 150,
        Cell: cellInfo => {
          const btnEditAsset = LoginService.isTechnician
            ? <ActionIcon icon='fa-pencil-alt' className='icon-edit text-info m-1' action={() => handleEdit(cellInfo.cell.value)} />
            : null;
          const btnReassign = LoginService.isCoordinator ?
            <>
              {
                cellInfo.row.original.jobNumber && cellInfo.row.original.jobNumber.length > 0 ?
                  <ActionIcon style={{ opacity: 0.6 }} icon='fa-retweet' className='icon-reassign text-warning m-1' /> :
                  <ActionIcon icon='fa-retweet' className='icon-reassign text-warning m-1' action={() => handleReassign(cellInfo.cell.value)} />
              }
            </>
            : null;
          const imageCount = <Badge className='m-1' variant='warning' onClick={() => handleAttachFiles(cellInfo.row.original)} dataToggle="tooltip" dataPlacement="top" title="Click here to navigate to attachments page">{cellInfo.row.original.imgCount}</Badge>;
          const iconOnReceivingList = cellInfo.row.original.onReceivedAssetsList
            ? <ActionIcon icon='fas fa-download' className='icon-receiving text-primary m-1' action={() => navigateToReceiving()} />
            : null;
          const iconCannotReceive = cellInfo.row.original.cannotReceive
            ? <ActionIcon icon='fas fa-exclamation-triangle' className='icon-warning text-danger m-1' title={cellInfo.row.original.reasonCannotReceive} />
            : null;
          return <span style={{ cursor: 'pointer' }}>
             {!LoginService.isCustomer && btnEditAsset}
             {!LoginService.isCustomer && btnReassign}
            {imageCount}
            {iconOnReceivingList}
            {iconCannotReceive}
          </span>
        }
      },
      {
        Header: 'Primary Serial Number',
        accessor: 'primarySerialNumber',
        width: 180,
        Cell: (cellInfo) => <Form.Link to={'/assetdetail/' + cellInfo.row.original.assetId} value={cellInfo.cell.value} />
      },
      {
        Header: 'Secondary Serial Number',
        accessor: 'secondarySerialNumber',
        width: 210,
        Cell: (cellInfo) => {
          return <span>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Equipment Number',
        accessor: 'equipmentNumber',
        width: 160,
      }, 
      {
        Header: 'Status',
        accessor: 'status',
        width: 160,
        Cell: (cellInfo) => {
          const statusClass = (cellInfo.cell.value == 'OS/Machining Repair' ? 'OSRepair' : ((cellInfo.cell.value == 'Weld Repair' || cellInfo.cell.value == 'Failed Inspection') ? 'WeldRepair' : cellInfo.cell.value));
          return <span className={'font-weight-bold status' + statusClass}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'User Status',
        accessor: 'userStatus',
        width: 160,
      },
      {
        Header: 'Object Type',
        accessor: 'objectType',
        width: 110,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 800,
      },
      {
        Header: 'Mfg Part',
        width: 120,
        accessor: 'mfgPartNumber',
        Cell: (cellInfo) => !LoginService.isCustomer && (cellInfo.row.original.manufacturerName === 'FMC')
          ? <Form.Link href={'http://ste1web.net.fmcti.com/cgi-bin/search/part-url.cgi?part=' + cellInfo.row.original.mfgPartNumber} value={cellInfo.cell.value} target='_blank' />
          : <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Customer',
        width: 250,
        accessor: 'customer',
        Cell: (cellInfo) => <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Current Location',
        accessor: 'currentLocation',
        width: 250,
      },
      {
        Header: 'Functional Location',
        accessor: 'functionalLocation',
        width: 180,
      },
      {
        Header: 'Container',
        accessor: 'container',
        width: 200,
      },
      {
        Header: 'Maint Plant',
        width: 110,
        accessor: 'plantCode'
      },
      {
        Header: 'Cost Center',
        accessor: 'costCenter',
        width: 110,
      },
      {
        Header: 'Asset Number',
        accessor: 'assetNumber',
        width: 130,
      },
      {
        Header: 'Acquisition Value',
        accessor: 'acquisitionvalue',
        width: 180,
        Cell: cellInfo => cellInfo.cell.value ? parseFloat(cellInfo.cell.value).toFixed(2) : <></>
      },
      {
        Header: 'Currency',
        accessor: 'acquisitioncurrency',
        width: 90,
      },
      {
        Header: 'Start-Up Date',
        accessor: 'plannedStartDate',
        width: 120,
        Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value) != '01-Jan-1900' ? DateTimeFormatter.formatDate(cellInfo.cell.value) : ''}</span>
      },
      {
        Header: 'Job Number',
        accessor: 'jobNumber',
        width: 120,
        Cell: (cellInfo) =>
          cellInfo.row.original.jobFacilityId == loginDetail.facilityId
            ? <Form.Link to={'/jobprogressassets/' + cellInfo.row.original.jobId} value={cellInfo.cell.value} />
            : <span>{cellInfo.cell.value}</span>
      },
      {
        Header: 'Assembly Work Order',
        accessor: 'awonumber',
        width: 180,
        Cell: (cellInfo) =>
          cellInfo.row.original.awofacilityId == loginDetail.facilityId
            ? <Form.Link onClick={() => { props.controller.redirectToAssembly(cellInfo.row.original.awoId, redirectToassembly); }} value={cellInfo.row.original.awonumber} />
            :
            <span>{cellInfo.row.original.awonumber}</span>
      },
      
    ],
    [matchesCount]
  )

  return (
    <div className='result-table-container asset-management-results-container'>
      <BigGrid columns={columns} data={data} onRowClick={handleRowClick} selectionType={selectionType} allowMultiSelect={true} noDataText={noDataText} />
    </div>
  );
}

const SearchButton = (props) => {

  return (
    <div className='search-buttons mt-1'>
      <Button icon='fa-search' variant='info' size='sm' className='btn-block' onClick={props.handleSearch}>Search</Button>
      <div className='d-flex justify-content-end'><Form.Link onClick={props.clearSearch} value='Clear search criteria' /></div>
    </div>
  );
}

const AllAssets = (props) => {

  const selectNone = (evt) => {
    evt.preventDefault();
    props.controller.selectNoAssets();
  }

  const selectAll = (evt) => {
    evt.preventDefault();
    props.controller.selectAllAssets();
  }

  const controller = props.controller;
  const groups = controller.state.groups;
  const assets = controller.state.assets || [];
  const canSelectAll = assets.length;
  const canSelectNone = controller.state.assets.filter(x => x.selected).length;
  const availableLabel = Utils.pluralize('available asset', 's', assets.length);
  const loadingMessage = <List.Item className='p-2'>Loading assets that meet the search criteria defined</List.Item>;
  const assetListContents = assets.length
    ? assets.map(asset => <Asset key={asset.assetId} controller={controller} asset={asset} />)
    : controller.state.isLoading ? loadingMessage : <List.Item className='p-2'>No assets meet the search criteria defined</List.Item>;

  return (
    <div className='col-md-5'>
      <div className='row'>
        <div className='col mb-2'>
          <Button icon='fa-square' variant='success' size='sm' className='mr-1 mb-1' disabled={!canSelectAll} onClick={selectAll}>Select All</Button>
          <Button icon='far fa-square' variant='danger' size='sm' className='mr-1 mb-1' disabled={!canSelectNone} onClick={selectNone}>Select None</Button>
          <div className='d-inline-block'><Badge variant='info'>{availableLabel}</Badge></div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <Jumbotron className='all-assets-list'>
            <List className='asset-list'>
              {assetListContents}
            </List>
          </Jumbotron>
        </div>
      </div>
    </div>
  );
}

const AssetsToPrintQR = (props) => {
  const selectNone = (evt) => {
    evt.preventDefault();
    props.controller.selectNoSelectedAssets();
  }

  const selectAll = (evt) => {
    evt.preventDefault();
    props.controller.selectAllSelectedAssets();
  }
  const controller = props.controller;
  const assetsToPrintQR = controller.state.selectedAssets || [];
  const selectedLabel = Utils.pluralize('selected asset', 's', assetsToPrintQR.length);
  const canSelectAll = assetsToPrintQR.length;
  const canSelectNone = controller.state.selectedAssets.filter(x => x.selected).length;

  return (
    <div className='col-md-5'>
      <div className='row'>
        <div className='col mb-2'>
          <Button icon='fa-square' variant='success' size='sm' className='mr-1 mb-1' disabled={!canSelectAll} onClick={selectAll}>Select All</Button>
          <Button icon='far fa-square' variant='danger' size='sm' className='mr-1 mb-1' disabled={!canSelectNone} onClick={selectNone}>Select None</Button>
          <div className='d-inline-flex'><Badge variant='info'>{selectedLabel}</Badge></div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <Jumbotron className='selected-assets-list'>
            <List className='list-group selected-asset-list'>
              {
                assetsToPrintQR.map(asset => {
                  return <Asset key={asset.assetId} controller={controller} asset={asset} />;
                })
              }
            </List>
          </Jumbotron>
        </div>
      </div>
    </div>
  );
}

const TransferButtons = (props) => {
  const select = (evt) => {
    evt.preventDefault();
    props.controller.moveToSelected();
  }

  const unselect = (evt) => {
    evt.preventDefault();
    props.controller.removeFromSelected();
  }

  const selectDisabled = false;
  const unselectDisabled = false;

  return (
    <div id='transfer-buttons' className='mt-5 col-md-2'>
      <div className='row'>
        <div className='col-md-12'>
          <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select}>Select</Button>
          <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect}>Unselect</Button>
        </div>
      </div>
    </div>
  );
}

const Asset = (props) => {

  const toggleSelection = (asset) => {
    props.controller.toggleQRAssetSelection(asset);
  }

  const selectAndTransfer = (asset) => {
    props.controller.selectAndTransfer(asset);
  }

  const asset = props.asset;
  const assetStatus = asset.status;
  let statusColourClass = ''; // e.g.: statusScrapped
  if (asset.status === 'Weld Repair' || asset.status === 'Failed Inspection' || asset.status === 'Failed' || asset.status === 'Scrapped')
    statusColourClass = 'text-danger';
  else if (asset.status === 'Passed' || asset.status === 'OS/Machining Repair')
    statusColourClass = 'text-success';
  else if (asset.status === 'Pending')
    statusColourClass = 'text-warning';
  else
    statusColourClass = 'statusUnknown';
  return (
    <List.Item className='asset asset p-2' onClick={() => { toggleSelection(asset); }} onDoubleClick={() => { selectAndTransfer(asset); }} active={asset.selected} style={{ cursor: 'pointer' }}>
      <span className='field-data font-weight-bold'>{asset.primarySerialNumber}</span>
      <span>{asset.equipmentNumber}</span>
      <br />
      <span>{asset.description}</span>
      <span id='asset-status' className={statusColourClass}> {assetStatus}</span>
    </List.Item>
  );
}

const SerializedAssetSearchPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-search' />

class SerializedAssetSearchPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSerializedAssetSearch();
  }

  static defaultProps = {
    controller: new SerializedAssetSearchController()
  }

  setActiveTab = (index) => {
    this.props.controller.setActiveTab(index);
  }

  handleInput = (evt) => {
    this.props.controller.handleRfidSearch(evt.target.value);
  }

  handleSearch = (evt) => {
    evt.stopPropagation();
    if (evt.which === 13) {
      this.props.controller.handleSearch();
    }
  }

  handleMouseSearch = () => {
    this.props.controller.handleSearch();
  }

  clearSearch = () => {
    this.props.controller.clearSearch();
  }

  handleCreateNewTransfer = () => {
    this.props.controller.createNewTransfer();
  }

  handleReceiveAssets = () => {
    this.props.controller.receiveAssets();
  }

  handleAssignToContainer = () => {
    this.props.controller.assignToContainer();
  }

  handleClearSelection = () => {
    this.props.controller.clearSelection();
  }

  handleSelectAll = () => {
    this.props.controller.selectAll();
  }

  handleCycleCount = () => {
    var selectedAssets = this.props.controller.getSelectedAssets();
    var assetsWithEquipmentNumber = selectedAssets.filter(asset => asset.equipmentNumber && !Utils.contains(asset.status, 'Scrapped'));
    if (selectedAssets.length != assetsWithEquipmentNumber.length) {
      notifications.action('error').post('One or more selected asset(s) are scrapped/does not contain equipment number');
      return;
    }
    if (assetsWithEquipmentNumber.length) {
      this.props.controller.updateCycleCount(assetsWithEquipmentNumber, DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()));
    }
  }

  handleCompletionDate = () => {
    var selectedAssets = this.props.controller.getSelectedAssets();
    var assets = selectedAssets.filter(asset => Utils.contains(asset.status, 'OS/Machining Repair'));
    if (selectedAssets.length != assets.length) {
      notifications.action('error').post('One or more selected asset(s) status is not OS/Machining Repair');
      return;
    }
    assets = selectedAssets.filter(asset => asset.equipmentNumber != '');
    if (selectedAssets.length != assets.length) {
      notifications.action('error').post('One or more selected asset(s) does not contain equipment number');
      return;
    }
    assets = selectedAssets.filter(asset => asset.serviceOrderNumber != '');
    if (selectedAssets.length != assets.length) {
      notifications.action('error').post('One or more selected asset(s) does not contain service order number');
      return;
    }
    Dialog.showDialog(<CompletionDateDialog controller={new CompletionDateController()} assets={assets} />);
  }

  handleGenerateQRCodes = () => {
    const controller = this.props.controller;
    const searchResults = controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.QRCode
      ? controller.state.selectedAssets
      : controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement
        ? controller.state.assetMgmtSearchResults
        : controller.state.searchResults;
    const filterSearchResults = searchResults.filter(result => result.selected == true);
    const qrSerialValues = filterSearchResults.length ? filterSearchResults : searchResults;
    const generatedQRCodes = <div>{qrSerialValues.map(row => <QRSticker qrValue={row} />)}</div>;
    var newWindow = window.open('', '_blank');
    const title = 'QR code'
    newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="css/application.css"> </head><body>');
    ReactDOM.render(generatedQRCodes, newWindow.document.body);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    $(newWindow).on('load', function () {
      newWindow.print();
    });
  }

  handleExportClick = (e) => {
    const { controller } = this.props;
    if (controller.state.isLoading) {
      e.preventDefault();
    }
    else {
      this.props.controller.getByteData();
    }
  }

  handleExportToExcelClick = (e) => {
    const { controller } = this.props;
    if (controller.state.isLoading) {
      e.preventDefault();
    }
    else {
      this.props.controller.getByteData(e.currentTarget.textContent.trim());
    }
  }

  toggleSearchCriteriaVisibility = () => {
    this.props.controller.toggleSearchCriteriaVisibility();
  }

  onTabSelect = (key, event) => {
    this.props.controller.tabShow(key);
  }

  render = () => {    
    const controller = this.props.controller;
    const binder = new Binder(this);
    const title = 'Serialized Asset Search';
    const labelText = 'Select the assets you wish to print QR';
    const labelTransText = 'Select the assets you wish to Transfer';
    const activeTab = controller.state.activeTab;
    const activeTabName = controller.state.activeTabName;
    const searchResultsCount = controller.state.searchResults.length;
    const assetMgmtSrcResultCount = controller.state.assetMgmtSearchResults.length;
    const canGenerateQRCode = searchResultsCount > 0 || controller.state.selectedAssets.length || (assetMgmtSrcResultCount && activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement);
    const canGenerateExcel = (searchResultsCount > 0 && activeTabName != AppConstants.SerializedAssetSearchTab.AssetManagement) || (assetMgmtSrcResultCount > 0 && activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement);
    const selectedAssetCount = controller.getSelectedAssets().length;
    const canReceive = ((selectedAssetCount > 0) && controller.state.customer);
    const canAssigntoContainer = ((selectedAssetCount > 0) && controller.state.customer);
    const canUpdateCycleCount = selectedAssetCount > 0;
    const transferEnable = controller.state.selectedAssets.length;
    const canSelectAll = (searchResultsCount > 0 && activeTabName != AppConstants.SerializedAssetSearchTab.AssetManagement) || (assetMgmtSrcResultCount > 0 && activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement);
    const createAssetButton = LoginService.isTechnician && !LoginService.isCustomer
      ? <Button icon='fa-plus-circle' variant='success' size='sm' className='m-1' to={'/createasset/' + '_'} onClick={this.handleCreateNewAsset}>Create New Asset ...</Button>
      : null;
    const createTransferButton = <Button icon='fa-plus-circle' variant='success' size='sm' className='m-1'
      onClick={this.handleCreateNewTransfer} disabled={!transferEnable}>Create New Transfer ...</Button>;
    const receiveButton = LoginService.isTechnician && !LoginService.isCustomer && !LoginService.isInRole(Roles.SAPRep)
      ? <Dropdown.MenuItem icon='fa-download' disabled={!canReceive} onClick={this.handleReceiveAssets}>{'Receive ' + Utils.pluralize('Asset', 's', selectedAssetCount, false) + '...'}</Dropdown.MenuItem>
      : null;
    const assignToContainerButton = LoginService.isTechnician && !LoginService.isCustomer && !LoginService.isInRole(Roles.SAPRep)
      ? <Dropdown.MenuItem icon='fab fa-dropbox' disabled={!canAssigntoContainer} onClick={this.handleAssignToContainer}>Assign to Container...</Dropdown.MenuItem>
      : null;
    const completionDate = SAPService.isSAPFacility()
      ? <Dropdown.MenuItem icon='fa-calendar-alt' disabled={!(selectedAssetCount > 0)} onClick={this.handleCompletionDate}>Completion date</Dropdown.MenuItem>
      : null;

    const clearSelectionButton =  LoginService.isInRole(Roles.SAPRep) || (activeTab == '4')
      ? null
      : <Button icon='far fa-square' variant='danger' size='sm' className='m-1' disabled={!selectedAssetCount} onClick={this.handleClearSelection}>Select None</Button>;

    const selectAllButton =  LoginService.isInRole(Roles.SAPRep) || (activeTab == '4')
      ? null
      : <Button icon='far fa-square' variant='success' size='sm' className='m-1' disabled={!canSelectAll} onClick={this.handleSelectAll}>Select All</Button>;

    const generateQRCodes = !LoginService.isInRole(Roles.SAPRep)  ? <Dropdown.MenuItem icon='fa-qrcode' variant='primary' size='sm' className='m-1' disabled={!canGenerateQRCode} onClick={this.handleGenerateQRCodes} > Print QR </Dropdown.MenuItem> : null;

    const exportToExcel = controller.state.activeTabName != AppConstants.SerializedAssetSearchTab.QRCode ?
      (
        <>
          <Dropdown.MenuItem icon='fa-file-excel' variant='primary' size='sm' className=' m-1' disabled={!selectedAssetCount} onClick={this.handleExportToExcelClick} > Export Selected </Dropdown.MenuItem>
          <Dropdown.MenuItem icon='fa-file-excel' variant='primary' size='sm' className=' m-1' disabled={!canGenerateExcel} onClick={this.handleExportToExcelClick} > Export All </Dropdown.MenuItem>
        </>
      ) : null;
    const exportToExcelButton = controller.state.activeTabName != AppConstants.SerializedAssetSearchTab.QRCode && LoginService.isInRole(Roles.SAPRep) ? <Button icon='fa-file-excel' variant='primary' size='sm' className=' m-1' disabled={!canGenerateExcel} onClick={this.handleExportClick} > Export To Excel </Button> : null;

    const selectionBadge = LoginService.isCustomer || LoginService.isInRole(Roles.SAPRep)
      ? null
      : <div className='d-inline-block'><Badge variant='info'>{selectedAssetCount} selected</Badge></div>;
    const showSearchCriteria = controller.state.showSearchCriteria;
    const visibilityIcon = showSearchCriteria ? 'fa-eye-slash' : 'fa-eye';
    const resultsClassName = showSearchCriteria ? 'col-md-8' : 'col-md-12';
    const buttonLabel = showSearchCriteria ? 'Hide Search Criteria' : 'Show Search Criteria';
    const toggleVisibilityButton = <Button icon={visibilityIcon} variant='primary' size='sm' className='mb-1' onClick={this.toggleSearchCriteriaVisibility}>{buttonLabel}</Button>;
    const showDropdown = (receiveButton || assignToContainerButton);
    const resultsList = controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.QRCode ?
      <div id='print-qr-transfer'>
        <h5>{labelText}</h5>
        <div className='row'>
          <AllAssets controller={controller} />
          <TransferButtons controller={controller} />
          <AssetsToPrintQR controller={controller} />
        </div>
      </div>
      : controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.MassTransfer ? <div id='print-qr-transfer'>
        <h5>{labelTransText}</h5>
        <div className='row'>
          <AllAssets controller={controller} />
          <TransferButtons controller={controller} />
          <AssetsToPrintQR controller={controller} />
        </div>
      </div>
        : controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.AssetManagement
          // ? <AssetManagementResultsList controller={controller} binder={binder} />
          ? <AssetManagementResults controller={controller} binder={binder} />
          : <ResultsList controller={controller} binder={binder} />;
    const ownershipChangeError = controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.MassTransfer && controller.state.transferErrors.length ? <ErrorsTabPanel title='Errors' {...this.props} binder={binder} /> : null;
    const massTransferTab = controller.state.activeTabName == AppConstants.SerializedAssetSearchTab.MassTransfer ? true : false;

    return (
      <Page pageTitle='InteServ · Serialized Asset Search' id='asset-search-page'>
        <SerializedAssetSearchPageHeader pageTitle={'Serialized Asset Search'} />
        <Page.Content>
          <div className='row'>
            <div className='col-12 mb-1'>
              {toggleVisibilityButton}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='row'>
                {
                  showSearchCriteria &&
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='col' >
                        <Tabs defaultActiveKey={controller.state.activeTabName} id='uncontrolled-tab-example' onSelect={this.onTabSelect}>
                          <Tab eventKey='simple' title='Simple'>
                            <Tab.Pane><SimpleSearchTabPanel controller={controller} binder={binder} /></Tab.Pane>
                          </Tab>
                          <Tab eventKey='customer' title='Customer'>
                            <Tab.Pane><CustomerSearchTabPanel controller={controller} binder={binder} /></Tab.Pane>
                          </Tab>
                          <Tab eventKey='advanced' title='Advanced'>
                            <Tab.Pane><AdvancedSearchTabPanel controller={controller} binder={binder} /></Tab.Pane>
                          </Tab>
                          {
                            !LoginService.isInRole(Roles.SAPRep) && !LoginService.isCustomer
                              ? <Tab eventKey='qr' title='QR Code'>
                                <Tab.Pane><AssetManagementTabPanel controller={controller} binder={binder} /></Tab.Pane>
                              </Tab>
                              : null
                          }
                          {
                              ((LoginService.isCustomer && !LoginService.isInRole(Roles.SAPRep)) || !LoginService.isCustomer) ? 
                              <Tab eventKey='assetManagement' title='Asset Mgmt'>
                                <Tab.Pane><AssetManagementTabPanel page={'Asset Mgmt'} controller={controller} binder={binder} /></Tab.Pane>
                              </Tab>
                              : null
                          }
                          {/* <Tab eventKey='transfer' title='Transfer'>
                                <Tab.Pane><AssetManagementTabPanel controller={controller} binder={binder} /></Tab.Pane>
                              </Tab> */}
                        </Tabs>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col'><SearchButton handleSearch={this.handleMouseSearch} clearSearch={this.clearSearch} /></div>
                    </div>
                  </div>
                }
                <div className={resultsClassName}>
                  <div className='row'>
                    <div className='col'>
                      <div className='row'>
                        <div className='col'>
                          {!massTransferTab && createAssetButton}
                          {massTransferTab && createTransferButton}
                          {showDropdown && !massTransferTab &&
                            <div className='d-inline-block'>
                              <Dropdown.Button variant={'warning'} size={'sm'} className='m-1' title='Actions'>
                                {receiveButton}
                                {assignToContainerButton}
                                {completionDate}
                                {generateQRCodes}
                                {exportToExcel}

                              </Dropdown.Button>
                            </div>
                          }
                          {
                            LoginService.isCustomer &&
                            <div className='d-inline-block'>
                              <Dropdown.Button variant={'warning'} size={'sm'} className='m-1' title='Actions'>
                              {exportToExcel}
                              </Dropdown.Button>
                            </div>
                          }
                          {!massTransferTab && selectAllButton}
                          {!massTransferTab && clearSelectionButton}
                          {!massTransferTab && selectionBadge}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          {resultsList}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          {ownershipChangeError}
                        </div>
                      </div>
                      <div className='d-none'><div id='qr-codes'></div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Page.Content>
      </Page >
    );
  }
}

export default SerializedAssetSearchPage;
