import BaseController from '../../lib/BaseController';

class FilenameRowController extends BaseController {
    constructor(parentController, file) {
      super('filename-row-'+ FilenameRowController.key++, {
        file                  : file ? file   : ''
      });
  
      this.parentController = parentController;
    }

    static key = 1;
  
    canRemoveRow = () => {
      return true;
    }
  
    removeRow = () => {
      if (!this.canRemoveRow()) {
        return;
      }
      if(this.state.file){
        if(this.state.file.recordStatus === 'A'){
          this.parentController.deleteFile(this);
        }
        else{
          this.state.file.recordStatus = 'D';
          this.commit();
          this.parentController.commit();  
          this.parentController.save(()=>{});     
        }      
      }
    }
    getFile = () => {
      this.parentController.getFile(this);
    }
  }
    
  export default FilenameRowController;
  