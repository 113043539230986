import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';

class EditThirdPartyJobNumberController extends BaseController {
  constructor(jobId, thirdPartyJobNumber) {
    super('edit-third-party-job-number', {
      jobId               : jobId,
      thirdPartyJobNumber : thirdPartyJobNumber
    });
  }

  save = () => {
    const s = this.state;
    const parameters = {
      jobId               : s.jobId,
      thirdPartyJobNumber : s.thirdPartyJobNumber
    };

    return ajax.post('inspection/saveThirdPartyJobNumber', parameters).then(() => {
      messages.channel('edit-3rd-party-job-number').action('saved').post(parameters);
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default EditThirdPartyJobNumberController;
