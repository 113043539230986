import React from 'react';
import { useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Jumbotron from '../../components/Jumbotron';
import ScannedQRAssetsController from '../controllers/ScannedQRAssetsController';
import LoginService from '../services/LoginService';
import List from '../../components/List';
import Utils from '../utility/Utils';
import Dialog from '../../components/Dialog';
import co from '../../lib/Co';
import notification from '../../services/Notification';
import PrintCertificateOptionController from '../controllers/PrintCertificateOptionController';
import PrintCertificateOptionDialog from '../dialogs/PrintCertificateOptionDialog';
import SendEmailController from '../controllers/SendEmailController';
import SendEmailDialog from '../dialogs/SendEmailDialog';
import QRScanner from '../components/QRScanner';

const ScannedAssets = (props) => {

    const controller = props.controller;
    const binder = props.binder;
    const assetList = controller.state.assetList;
    const assetremoved = controller.state.displayMessage
        ? <div className='row'>
            <div className='col-md-12'>
                <span className='text-danger'>Note: Assets belongs to other customers are removed from the list</span>
            </div>
        </div>
        : null;
    return (
        <div className='col-md-12'>
            <div id='scanned-assets-list' className=''>
                <ReceivedAssetsList controller={controller} assets={assetList} />
            </div>
            {assetremoved}
        </div>
    );
}

const LookupAsset = (props) => {

    const handleEmail = () => {
        const assetsList = props.controller.state.assetList;
        Dialog.showDialog(<SendEmailDialog controller={new SendEmailController(assetsList)} header='Send assets in E-Mail' />);
    }

    const handleReceiveAssets = () => {
        props.controller.handleReceiveAssets();
    }

    const handleLogin = () => {
        LoginService.autoLogin();
    }

    const handlePrintAssetCertificate = () => {
        const assetsList = props.controller.state.assetList;
        Dialog.showDialog(<PrintCertificateOptionDialog controller={new PrintCertificateOptionController(assetsList)} header='Generate Asset Certification' />);
    }

    const handleQrVisiblity = () => {
        props.controller.toggleQrVisiblity();
    }

    const controller = props.controller;
    const enableOptions = controller.state.assetList.length ? false : true;
    const loginInfoAvailable = LoginService.loginInfo ? true : false;

    const handleQRInvalid = () => {
        notification.action('warning').post({ msg: 'Invalid QR Code!', title: 'QR Code' });
    }

    const qrScanner = controller.state.qrCanvasVisibility
        ? <QRScanner handleScan={controller.findAssetWithQRCode} handleQRInvalid={handleQRInvalid} />
        : '';

    const qrShowButton = props.controller.state.qrCanvasVisibility
        ? <Button icon='fa-qrcode' variant='info' size='sm' className='col-sm-12' onClick={handleQrVisiblity}>Hide QR Scanner</Button>
        : <Button icon='fa-qrcode' variant='info' size='sm' className='col-sm-12' onClick={handleQrVisiblity}>Show QR Scanner</Button>
    return (
        <div className='col-sm-12'>
            <div className='row'><div className='col-12'>{qrShowButton} </div></div>
            <div className='row'><div className='col-12'>{qrScanner}</div></div>
            <div className='row'>
                <div id='scanned-assets-operations' className='col-12'>
                    <div className='assets-content'>
                        <Jumbotron className='col-md-12 mt-5'>
                            <div className='row'>
                                <div className='col-md-12'><Button icon='fa-arrow-circle-right' id='email-asset-list' variant='success' size='sm' className='m-1' disabled={enableOptions || !loginInfoAvailable} onClick={handleEmail}>Email</Button></div>
                            </div>
                            {!LoginService.isCustomer && <div className='row'>
                                <div className='col-md-12'><Button icon='fa-download' id='qr-receive-assets' variant='success' size='sm' className='m-1' disabled={enableOptions || !loginInfoAvailable} onClick={handleReceiveAssets}>Receive Assets</Button></div>
                            </div>}
                            <div className='row'>
                                <div className='col-md-12'><Button icon='fa-truck fa-flip-horizontal' id='print-asset-certificate' variant='success' size='sm' className='m-1' disabled={enableOptions || !loginInfoAvailable} onClick={handlePrintAssetCertificate}>Print Asset Certificate Report</Button></div>
                            </div>
                            {!loginInfoAvailable && <div className='row'><div className='col-md-12'>
                                <Form.StaticData value='Currently not logged in to Inteserv.' /></div>
                                <div className='col-md-12'><Button icon='fa fa-sign-in' id='scanned-qr-login' variant='success' size='sm' className='m-1' onClick={handleLogin}>Click here</Button>
                                    <span>to login.</span>
                                </div></div>}
                        </Jumbotron>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ReceivedAsset = (props) => {

    let history = useHistory();

    const navigateToReceiving = () => {
        history.push('/receiving');
    }

    const handleRemoveAsset = () => {
        co(function* () {
            const result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Asset'>
                    {'Please confirm you wish to remove asset with serial number ' + props.asset.primarySerialNumber + ' from the list'}
                </Dialog.OKCancelDialog>
            );

            if (result.OK) {
                props.controller.removeScannedAsset(props.asset);
            }
        });
    }
    const asset = props.asset;
    const iconOnReceivingList = !asset.cannotReceive && asset.onReceivedAssetsList
        ? <ActionIcon icon='fa-download' className='icon-receiving mt-1 ml-1' action={() => navigateToReceiving()} />
        : null;
    const iconCannotReceive = asset.cannotReceive
        ? <ActionIcon icon='fas fa-exclamation-triangle mt-1 ml-1' className='text-danger' dataToggle="tooltip" dataPlacement="top" title={asset.reasonCannotReceive} />
        : null;
    const cannotReceiveReason = asset.reasonCannotReceive
        ? <div className='row'>
            <div className='col-md-12'>
                <span className='text-danger'>{asset.reasonCannotReceive}</span>
            </div>
        </div>
        : null;
    const iconDiv = iconCannotReceive || iconOnReceivingList ? 'col-md-2' : 'col-md-1';
    const descDiv = iconCannotReceive || iconOnReceivingList ? 'col-md-9 icon-list' : 'col-md-10';

    return (
        <List.Item {...props} className='asset'>
            <div className='row'>
                <div className={iconDiv}>
                    <div className='row'>
                        <ActionIcon icon='fa-times-circle alert-danger remove-icon mt-1' action={() => handleRemoveAsset()} />
                        {/* <span className='field-data'>{asset}</span> */}
                        {iconOnReceivingList}
                        {iconCannotReceive}
                    </div>
                </div>
                <div className={descDiv}>
                        <span className='font-weight-bold'>{asset.name}</span>
                        <div className='field-label'>{asset.description}</div>
                </div>
                {cannotReceiveReason}
            </div>
        </List.Item>
    );
}

const ReceivedAssetsList = (props) => {
    const handleRemoveAll = () => {
        co(function* () {
            const result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog width={500} height={300} header='Confirm Remove All'>
                    {'Please confirm you wish to remove all assets from this list'}
                </Dialog.OKCancelDialog>
            );

            if (result.OK) {
                props.controller.clearResults();
            }
        });
    }

    const controller = props.controller;
    const assets = props.assets;
    const count = props.assets.length;
    const label = Utils.pluralize('asset', 's', count);
    const isRemoveAllDisabled = (count === 0);
    return (
        <div>
            <div className='row'>
                <div className='h5 title'>Scanned Assets</div>
            </div>
            <div className='row form-group align-items-center d-flex justify-content-start' id='btn-asset-count'>
                <div><Button icon='fa-times-circle' variant='danger' size='sm' disabled={isRemoveAllDisabled} className='m-1' onClick={handleRemoveAll}>Remove all</Button></div>
                <div><Badge className='m-1' variant='info'>{label}</Badge></div>
            </div>

            <div className='row'>
                <Jumbotron className='col-md-12 asset-list'>
                    <List {...props}  className='form-group'>
                        {assets.map(asset => {
                            return <ReceivedAsset key={asset.assetId} controller={controller} asset={asset} />;
                        })}

                    </List>
                </Jumbotron>
            </div>
        </div>
    );
}

const ScannedQRAssetsPageHeader = (props) => <PageHeader title={props.pageTitle} isAdvanced={props.isAdvanced} icon='fa fa-qrcode' />

class ScannedQRAssetsPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        this.props.controller.loadFromStore();
        this.props.controller.loadAsset(this.props.match.params.assetId);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    static defaultProps = {
        controller: new ScannedQRAssetsController()
    }

    render = () => {
        const controller = this.props.controller;
        const state = controller.state;
        const binder = new Binder(this);
        const isAdvancedPageHeader = LoginService.loginInfo ? true : false;

        return (
            <Page pageTitle='InteServ · ScannedQRAssets' id='scanned-qr-assets-page'>
                <ScannedQRAssetsPageHeader pageTitle={'QR Scanned Assets'} isAdvanced={isAdvancedPageHeader} />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-6'>
                            <ScannedAssets controller={controller} binder={binder} />
                        </div>
                        <div className='col-md-6'>
                            <LookupAsset controller={controller} binder={binder} />
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default ScannedQRAssetsPage;
