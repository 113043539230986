import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import Utils from '../utility/Utils';

class UserSecurityRightsController extends BaseController {
  constructor(user) {
    super('user-security-rights', {
      user           : user,
      securityRights : []
    });
  }

  toggleSecurityRightSelection = (securityRight) => {
    securityRight.selected = !securityRight.selected;
    this.commit();
  }

  save = () => {
    let name = this.state.user.name;
    if (name === ' ') {
      name = this.state.user.loginName;
    }

    const parameters = {
      userId: this.state.user.userId,
      securityTokenIds: this.state.securityRights.filter(sr => sr.selected).map(sr => sr.securityTokenId)
    };

    return ajax.post('user/saveSecurityRights', parameters).then(() => {
      notification.action('info').post({ msg: 'Saved security rights for ' + name });
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }

  getSecurityRights = () => {
    const userId = this.state.user.userId;
    ajax.get('lookup/getUserSecurityRights', { userId: userId }).then((securityRights) => {
      securityRights.forEach(r => r.selected = r.userId !== Utils.emptyGuid);
      this.state.securityRights = [...securityRights];
      this.commit();

    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }
}

export default UserSecurityRightsController;