import store from 'store';
import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
class SAPJobProgressController extends BaseController {
  constructor() {
    super('job-progress', {
      facility: '',
      customer: '',
      selectedJob: '',
      sapStatus: '',
      searchActive: true,
      searchCompleted: false,
      includeNewJobs: false,
      customers: [],
      searchResults: [],
      currentPage: 1
    });

    this.flash = emitter();

    messages.channel('complete-job').action('saved').subscribe(() => {
      this.searchJobs();
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.state.facility = '';
        this.state.customer = '';
        this.state.sapStatus = '';
        this.clearResults();
        this.clearCache();
      }
    });

    messages.channel('change-facility').action('changed').subscribe(() => {
      this.state.customer = '';
      this.state.searchResults = [];
      this.state.sapStatus = '';
      this.clearResults();
      this.clearCache();
    });
    messages.channel('quote-status').action('updated').subscribe(data => {
      if (data.message && data.message.action) {
        notifications.action(data.message.action).post(data.message);
      }
    });
    messages.channel('job-status').action('updated').subscribe(data => {
      const jobs = this.state.searchResults.filter(result => result.id === data.jobId);
      if ((jobs === null) || !jobs.length) {
        return;
      }

      if (data.message && data.message.action) {
        notifications.action(data.message.action).post(data.message);
      }

      let job = jobs[0];
      co(this, function* () {
        const updatedJob = yield ajax.get('lookup/getJob', { jobId: job.id }); // JobLookupDto
        job = { ...job, ...updatedJob }
        this.commit();
        this.flash.emit(job.id);
      });
    });
  }

  facilityChanged = () => {
    this.clearResults();
    this.state.currentPage = 1;
    this.commit();
  }

  customerChanged = () => {
    this.clearResults();
    document.getElementById('searchText').focus();
    this.state.currentPage = 1;
    this.commit();
  }

  clearResults = () => {
    this.state.searchResults = [];
    this.state.selectedJob = '';
  }

  clearSearchText = () => {
    this.state.searchText = '';
    this.commit();
}

  clearCache = () => {
    this.state.customers = [];
  }

  searchActiveChanged = () => {
    this.state.searchCompleted = false;
    this.clearResults();
    document.getElementById('searchText').focus();
    this.state.currentPage = 1;
    this.commit();
  }

  searchCompletedChanged = () => {
    this.state.searchActive = false;
    this.clearResults();
    this.clearCache();
    document.getElementById('searchText').focus();
    this.state.currentPage = 1;
    this.commit();
  }

  includeNewJobsChanged = () => {
    this.clearResults();
    this.clearCache();
    this.state.currentPage = 1;
    this.commit();
  }

  sapStatusChanged = () => {
    this.clearResults();
    this.clearCache();
    this.state.currentPage = 1;
    this.commit();
  }

  searchJobs = () => {
    co(this, function* () {
      this.clearResults();
      const facilityId = (this.state.facility && this.state.facility.id) || '';
      const customerId = (this.state.customer && this.state.customer.id); // can be null
      const searchText = this.state.searchText;
      const sapStatus = this.state.sapStatus && this.state.sapStatus.name;

      const parameters = {
        facilityId: facilityId,
        customerId: customerId,
        searchTerm: searchText,
        searchActive: this.state.searchActive,
        includeNewJobs: this.state.includeNewJobs,
        revenueGeneratingCustomersOnly: false,
        sapFacilitiesOnly: true,
        sapStatus: sapStatus
      };

      this.state.searchResults = [];
      this.state.isLoading = true;
      const jobs = yield ajax.get('lookup/searchExistingJobsForFacility', parameters); // IList<JobLookupDto>
      this.state.isLoading = false;

      // auto-select the 1st job
      if (jobs.length > 0) {
        this.loadJobDetails(jobs[0]);
      }

      this.state.searchResults = jobs;
      this.commit();
    });
  }

  facilitySearch = () => {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchFacilitiesForUser', { searchTerm: searchTerm, sapFacilitiesOnly: true }); // IList<LookupDto>
      return results;
    };
  }


  customersSearch = (searchTerm) => {
    if (!LoginService.loginInfo) {
      return;
    }
    const facilityId = (this.state.facility && this.state.facility.id) || LoginService.loginInfo.facilityId;
    const includeNewJobs = this.state.includeNewJobs ? 1 : 0;
    const searchActive = this.state.searchActive ? 1 : 0;
    return (searchTerm) => ajax.get('lookup/searchCustomersWithJobsAtFacility', {
      facilityId: facilityId,
      searchTerm: searchTerm,
      revenueGeneratingCustomersOnly: 0,
      includeNewJobs: includeNewJobs,
      searchActive: searchActive
    }).then(result => result);
  }

  isSelected = (job) => {
    if (!this.state.selectedJob) {
      return false;
    }
    return (job.id === this.state.selectedJob.id);
  }

  loadJobDetails = (job) => {
    this.state.selectedJob = job;
    const jobId = (job && job.id);
    if (!jobId) {
      this.commit();
      return;
    }

    co(this, function* () {
      if (job.dropoffForms === null) {
        const dropoffForms = yield ajax.get('lookup/getDropoffForms', { jobId: jobId });
        job.dropoffForms = dropoffForms;
      }

      if (job.shippingTickets === null) {
        const shippingTickets = yield ajax.get('lookup/getShippingTickets', { jobId: jobId });
        job.shippingTickets = shippingTickets;
      }

      this.commit();
    });
  }

  canCreateNewJob = () => {
    const facility = store.get('currentFacility');
    if (!facility) {
      return false;
    }
    return (!facility.useSAP || (facility.plantCode !== ''));
  }
  canEstimateQuote = (job) => {
    const facility = store.get('currentFacility');
    if (!facility) {
      return false;
    }
    if (!facility.useSAP) {
      return false;
    }

    let result;
    switch (job.sapStatus) {
      case 'QUNC':
      case 'QUNR':
      case 'NOCO':
      case 'RPIG':
      case 'RPOR':
      case 'POAC':
      case 'SHIP':
      case 'NOBL':
      case 'QUST':
      case '':
        result = false;
        break;

      default: // SORL, NOCO, SOCR, QUCR, QUAC, QUAG
        result =  true;
    }

    return result;
  }
  canOverrideJob = (job) => {
    const facility = store.get('currentFacility');
    if (!facility) {
      return false;
    }
    if (!facility.useSAP) {
      return false;
    }
    
    let result;
    switch (job.sapStatus) {
      case 'SOCR':
      case 'QUCR':
      case 'QUST':
        result = true;
        break;

      default: // QUNC,QUNR, QUAC, QUAG,SORL, NOCO, RPIG, RPOR, POAC, SHIP, NOBL
        result = false;
    }

    return result;
  }
  canGetReadyForInvoicing = () => {
    return false;
  }

  canPerformAdhocTransactions = (job) => {
    if (!job) {
      return false;
    }

    let result;
    switch (job.sapStatus) {
      case 'QUNC':
      case 'QUNR':
      // case 'SOCR':
      // case 'QUCR':
      // case 'QUAC':
      // case 'QUAG':
      case '':
        result = false;
        break;

      default: // SORL, NOCO, RPIG, RPOR, POAC, SHIP, NOBL
        result = true;
    }

    return result;
  }
  EstimateQuote = (job) => {
    try {
      co(this, function* () {
        const parameters = {
          jobId: job.id,
          jobNumber: job.jobNumber
        };
        yield ajax.post('jobprogress/requestQuote', parameters);
      });
      //notifications.action('success').post('Request Quote completed successfully');
    }
    catch (err) {
      notifications.action('error').post(err.message);
    }
  }
  Override = (job) => {
    try {
      co(this, function* () {
        const parameters = {
          jobId: job.id,
          jobNumber: job.jobNumber
        };
        yield ajax.post('jobprogress/override', parameters);
      });
      notifications.action('success').post('Override completed successfully');
    }
    catch (err) {
      notifications.action('error').post(err.message);
    }
  }
  canAddAdhocTransactions = (job) => {
    return (job && job.canAddAdhocTransactions);
  }
}

export default SAPJobProgressController;
