import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectAuthorizedLocationsForContainerController extends BaseController {
  constructor(customer, container) {
    super('select-container-locations', {
      customer   : customer,
      container  : container,
      locations  : [],
      searchTerm : ''
    });
  }

  locationSearch = () => {
      this.state.locations = [];
      const parameters = {
        customerId  : this.state.customer.id,
        searchTerm  : this.state.searchTerm
      };

      ajax.get('lookup/searchCustomerLocationsForCustomer', parameters).then(results=>{
        this.state.locations = results || [];
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });// IList<CustomerLocationDto>
  }

  getSelectedLocations = () => {
    const locations = this.state.locations || [];
    return locations.filter(l => l.selected);
  }

  clearSearchTerm = () => {
    this.state.searchTerm = '';
    this.commit();
  }

  selectAll = () => {
    this.state.locations.forEach(l => l.selected = true);
    this.commit();
  }

  selectNone = () => {
    this.state.locations.forEach(l => l.selected = false);
    this.commit();
  }

  toggleSelection = (location) => {
    location.selected = !location.selected;
    this.commit();
  }

  addLocations = () => {
    const parameters = {
      customerId          : this.state.customer.id,
      containerId         : this.state.container.containerId,
      customerLocationIds : this.getSelectedLocations().map(l => l.customerLocationId) // just the Ids
    };

    return ajax.post('container/addLocations', parameters).then(()=>{
      messages.channel('edit-container').action('saved').post();
      notification.action('success').post('Added ' + Utils.pluralize('authorized location', 's', parameters.customerLocationIds.length) + ' to ' + this.state.container.description);
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
  }); // in: AddLocationsToContainerDto, out: void
  }
}

export default SelectAuthorizedLocationsForContainerController;
