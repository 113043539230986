import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import $ from 'jquery';

class SelectLaborCodesController extends BaseController {
  constructor(manDesc, selectedItems) {
    super('select-labor-codes', {
      manDesc: manDesc,
      selectedItems: Utils.clone(selectedItems),
      sourceItems: [],
      searchText: ''
    });
  }

  search = () => {
    // TODO: not sure why this is not binding when clicking on the search button (SelectLaborCodesDialog.jsx) without tabbing out of the input control
    //       it may be related to being on dialog rather than a page (?)
    ajax.get('lookup/searchLaborCode', { searchTerm: this.state.searchText }).then(results => {
      this.state.sourceItems = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });  // IList<LaborCodeDto>
  }

  clearSearch = () => {
    this.state.searchText = '';
    this.commit();
  }

  selectNoSourceItems = () => {
    this.state.sourceItems.forEach(item => {
      item.selected = false;
    });
    this.commit();
  }

  selectAllSourceItems = () => {
    this.state.sourceItems.forEach(item => {
      item.selected = true;
    });
    this.commit();
  }

  selectNoSelectedItems = () => {
    this.state.selectedItems.forEach(item => {
      item.selected = false;
    });
    this.commit();
  }

  selectAllSelectedItems = () => {
    this.state.selectedItems.forEach(item => {
      item.selected = true;
    });
    this.commit();
  }

  toggleSelection = (item) => {
    item.selected = !item.selected;
    this.commit();
  }

  moveToSelected = () => {
    const state = this.state;
    state.sourceItems.forEach(item => {
      if (item.selected) {
        item.selected = false;

        let found = false;
        for (let i in state.selectedItems) {
          if (state.selectedItems[i].laborCodeId === item.laborCodeId) {
            found = true;
            break;
          }
        }
        if (!found) {
          state.selectedItems.push({
            manDescLaborCodeId: Utils.emptyGuid,
            laborCodeId: item.laborCodeId,
            laborCodeName: item.laborCodeName,
            description: item.description
          });
        }
      }
    });

    // sort selected list
    this.state.selectedItems.sort(function (a, b) {
      return (a.laborCodeName.localeCompare(b.laborCodeName));
    });
    this.commit();
  }

  removeFromSelected = () => {
    let i = this.state.selectedItems.length;
    while (i--) {
      var item = this.state.selectedItems[i];
      if (item.selected) {
        this.state.selectedItems.splice(i, 1);
      }
    }
    this.commit();
  }

  selectAndTransfer = (isSource, item) => {
    item.selected = true;
    if (isSource) {
      this.moveToSelected();
    }
    else {
      this.removeFromSelected();
    }
  }

  save() {

    const manDesc = this.state.manDesc;
    const parameters = {
      manDescId: manDesc.manDescId,
      laborCodes: this.state.selectedItems
    };

    return ajax.post('manufacturerDescription/saveLaborCodes', parameters).then(() => {
      messages.channel('edit-man-desc').action('saved').post(manDesc);
      notification.action('success').post('Saved changes to labor code selection');
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: UpdateLaborCodesDto, out: void
  }
}

export default SelectLaborCodesController;
