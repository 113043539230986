import React from 'react';
import Form from '../../components/Form';
import ActionIcon from '../../components/ActionIcon';
import co from '../../lib/Co';
import Dialog from '../../components/Dialog';
import Table from '../../components/Table';

const CertificateRow = (props) => {

    const openFile = () => {
        props.controller.viewCertFile(props.data.certificateId);
    }

    const deleteFile = () => {
        co(function* () {
            const result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog className='delete-confirm-dialog' header='Confirm Remove'>
                    {'Are you sure you want to remove this file?'}
                </Dialog.OKCancelDialog>
            );

            if (result.OK) {
                props.controller.deleteCertFile(props.data.certificateId);
            }
        });
    }

    const data = props.data;
    const fileNameLink = <Form.Link onClick={() => { openFile(); }} disabled={!data.laborCodeId} value={data.fileName} />
    const fileDeleteAction = <ActionIcon icon='fa-trash' className='remove-handle' action={deleteFile} />

    if (props.showSerialPrimar && props.flgShowSerialPrim) {
        fileDeleteAction = data.SerialPrimary
    }

    return (
        <Table.Row>
            <Table.Data>{fileNameLink}</Table.Data>
            <Table.Data>{data.dateAdded}</Table.Data>
            {
                props.showSerialPrimar ?
                    (props.flgShowSerialPrim ?
                        <Table.Data className='col-action'>
                            {fileDeleteAction}
                        </Table.Data>
                        : null)
                    :
                    <Table.Data className='col-action'>
                        {fileDeleteAction}
                    </Table.Data>
            }
        </Table.Row>
    );
}

const CustomerCertificateTable = (props) => {

    const getAssetCerts = (certs, controller, showSerialPrimar, flgShowSerialPrim) => {
        let results = [];

        for (let i = 0; i < certs.length; i++) {
            let cert = certs[i];
            results.push(<CertificateRow key={cert.certificateId} data={cert} controller={controller} flgShowSerialPrim={flgShowSerialPrim} showSerialPrimar={showSerialPrimar} />);
        }
        return results;
    }
    const certs = props.data || [];
    let flgShowSerialPrim = false;
    if (certs.length > 0) {
        flgShowSerialPrim = certs[0].SerialPrimary ? true : false;
    }
    const results = getAssetCerts(certs, props.controller, props.showSerialPrimar, flgShowSerialPrim);
    const colCount = 3;
    const loadingMessage = props.isLoading && <Table.Row><Table.Data colSpan={colCount} className='loading'>Loading asset certificates ...</Table.Data></Table.Row>;
    const noResultsMessage = !loadingMessage && !results.length && <Table.Row><Table.Data colSpan={colCount} className='no-search-results'>No results for the current asset</Table.Data></Table.Row>;

    return (
        <div className="row">
            <div className="col-sm-12">
                <Form.Header className="alert-warning p-3">
                    Asset Certificates
                    </Form.Header>
                <div id="asset-certs" className="search-results">
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.Head>File Name</Table.Head>
                                <Table.Head>Service Date</Table.Head>
                                {props.showSerialPrimar ? (flgShowSerialPrim ? <Table.Head>Serial</Table.Head> : null) : <Table.Head></Table.Head>}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {results}
                            {loadingMessage}
                            {noResultsMessage}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default CustomerCertificateTable;
