import BaseController from '../../lib/BaseController';
import messages from '../../services/Messages';
import AssetsAlreadyInJobRowController from '../controllers/AssetsAlreadyInJobRowController';

class AssetsAlreadyInJobController extends BaseController {
  constructor(assets, parentContoller) {
    super('assets-already-in-job', {assets: []});
    this.parentContoller = parentContoller;
    this.load(assets);
    messages.channel('assets-already-in-job').action('save').subscribe(() => {
      this.getSelectedAsstes();
    });
  }  

  load(assets){
    this.state.assets = assets.map(asset => new AssetsAlreadyInJobRowController(asset, this));    
    this.commit();    
  }

  getInitialState() {
    return{ 
      assets: []
    } 
  }

  getSelectedAsstes(){
    const asstes = this.state.assets.filter(asset => asset.state.isSelected).map(asset => asset.state);
    this.parentContoller.assignSelectedAssets(asstes);
  }
}

export default AssetsAlreadyInJobController;