import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import $ from 'jquery';

class SearchController extends BaseController {
  constructor() {
    super('search-controller', {
    });
  }

  customerSearch() {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchCustomerList', {searchTerm: searchTerm}); // IList<LookupDto>
      return results;
    };
  }

  mfgItemSearch() {
    return function* (searchTerm) {
      // Mfg. Item #
      var results = yield ajax.get('lookup/searchLookupList/Mfg%20Item%20%23', { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  search(searchType) {
    if (!searchType) {
      return null;
    }

    return function* (searchTerm) {
      var results = yield ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), { searchTerm: searchTerm }); // IList<LookupDto>
      return results;
    };
  }

  clearSearch() {
    $('#searchText').val('');
    $('#searchText').focus();
  }

  clearManDescSearchCriteria(state, controller) {
    this.clearSearch();
    state.familyCode   = '';
    state.manufacturer = '';
    state.nominalSize  = '';
    state.cwp          = '';
    state.degree       = '';
    state.id           = '';
    state.od           = '';
    state.length       = '';
    state.style        = '';
    state.service      = '';
    state.mfgItemNo    = '';
    controller.commit();
  }

  handleManDescSearch(state, controller) {
    co(this, function*() {
      var parameters = {
        familyCodeId    : state.familyCode    ? state.familyCode.id   : Utils.emptyGuid,
        lengthId        : state.length        ? state.length.id       : Utils.emptyGuid,
        cwpId           : state.cwp           ? state.cwp.id          : Utils.emptyGuid,
        styleId         : state.style         ? state.style.id        : Utils.emptyGuid,
        nominalSizeId   : state.nominalSize   ? state.nominalSize.id  : Utils.emptyGuid,
        manufacturerId  : state.manufacturer  ? state.manufacturer.id : Utils.emptyGuid,
        idId            : state.id            ? state.id.id           : Utils.emptyGuid,
        odId            : state.od            ? state.od.id           : Utils.emptyGuid,
        degreeId        : state.degree        ? state.degree.id       : Utils.emptyGuid,
        serviceId       : state.service       ? state.service.id      : Utils.emptyGuid,
        mfgItemNo       : state.mfgItemNo     ? state.mfgItemNo.name  : '',
        searchTerm      : state.searchText
      };

      state.isLoadingManufacturerDescriptions = true;
      var results                             = yield ajax.get('lookup/searchManufacturerDescriptions', parameters); // IList<ManufacturerDescriptionDto>
      state.isLoadingManufacturerDescriptions = false;
      controller.setSearchResults(results);
    });
  }

  vendorSearch() {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/getVendors', { searchTerm: searchTerm }); // IList<Vendor>
      return results;
    };
  }

  guageSerPrimData() {
    return function* (searchTerm) {
      var results = yield ajax.get('lookup/getserialprimarygauge', { searchTerm: searchTerm }); 
      return results;
    };
  }

  // handleManDescSearch2(state, resultsController) {
  //   co(this, function*() {
  //     var parameters = {
  //       searchTerm : state.searchText
  //     };

  //     state.isLoadingManufacturerDescriptions = true;
  //     var results                             = yield ajax.get('lookup/searchManufacturerDescriptions2', parameters); // IList<ManufacturerDescriptionDto>
  //     state.isLoadingManufacturerDescriptions = false;
  //     resultsController.setSearchResults(results);
  //   });
  // }
}

export default SearchController;
