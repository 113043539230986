import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Utils from '../utility/Utils';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import ActionIcon from '../../components/ActionIcon';
import EditFacilityController from '../controllers/EditFacilityController';
import ProfitCostCenterTable from '../pages/ProfitCostCenterTable';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';

const ReceivingCostCenterTable = (props) => {
  const controller = props.controller;

  const handleAddNewRow = () => {
    controller.addNewReceivingCostCenterRow();
  }

  const renderRowErrors = () => {
    const rowErrors = controller.state.errors && controller.state.errors.profitCostCenters;
    if (rowErrors) {
      return props.controller.state.profitCostCenters.length == 0
        ? <div className='help-block'>{rowErrors}</div>
        : null;
    }
  }

  const receivingCostCenters = Utils.filterDeletedRecords(controller.state.receivingCostCenters) || [];
  const data = [...controller.state.receivingCostCenters];
  // const rows = receivingCostCenters.map(center => <ReceivingCostCenterRow key={center.key} controller={center} />);
  const canEdit = receivingCostCenters.length + 1 <= 100 ? true : false;
  const divClassName = controller.state.errors.receivingCostCenters ? ' has-error' : '';
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return isSuperUser ? <span></span> : <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
        }
      },
      {
        Header: 'Cost Center',
        accessor: 'state.costCenter',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('costCenter')} readOnly={isSuperUser} className='required' error={errors.costCenter} maxLength="250" />
        }
      },
      {
        Header: 'Short Text',
        accessor: 'state.shortText',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('shortText')} readOnly={isSuperUser} className='required' error={errors.shortText} maxLength="250" />;
        }
      },
      {
        Header: 'Long Text',
        accessor: 'state.longText',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('longText')} readOnly={isSuperUser} className='required' error={errors.longText} maxLength="500" />;
        }
      },
    ],
    []);
  return (
    <div className={divClassName}>
      <div className='mb-2'><span className='h5'>Receiving Cost Center</span></div>
      <Grid id='receiving-cost-results-table' columns={columns} data={data} editable={canEdit} canAddNewRow={!isSuperUser} onAddNewRow={handleAddNewRow} showPagination={false} />
      {renderRowErrors()}
    </div>
  );
}

const EditForm = (props) => {
  const binder = props.binder;
  const controller = props.controller;
  const facility = controller.state;
  const errors = controller.state.errors;
  const useSAP = controller.state.useSAP;
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const usetts = useSAP ? <div className='col-md-1'><Form.CheckBox label='Use TTS' disabled={isSuperUser} bind={binder.bind('useTTS')} /></div> : null;

  return (
    <div className='col-md-12'>
      <div className='row'>
        <div className='col-md-4'><Form.Input label='Facility Name' readOnly={isSuperUser} bind={binder.bind('facilityName')} error={errors.facilityName} className='required' /></div>
        <div className='col-md-4'><Form.Input label='Address 1' readOnly={isSuperUser} bind={binder.bind('address1')} /></div>
        <div className='col-md-4'><Form.Input label='Address 2' readOnly={isSuperUser} bind={binder.bind('address2')} /></div>
        <div className='col-md-4'><Form.Input label='Address 3' readOnly={isSuperUser} bind={binder.bind('address3')} /></div>
        <div className='col-md-4'><Form.Input label='City' readOnly={isSuperUser} bind={binder.bind('city')} /></div>
        <div className='col-md-4'><Form.Input label='State' readOnly={isSuperUser} bind={binder.bind('state')} /></div>
        <div className='col-md-4'><Form.Input label='Postal Code' readOnly={isSuperUser} bind={binder.bind('postalCode')} /></div>
        <div className='col-md-4'><Form.AutoComplete label='Region' readOnly={isSuperUser} bind={binder.bind('region')} search={controller.search('region')} /></div>
        <div className='col-md-4'><Form.AutoComplete label='Country' readOnly={isSuperUser} bind={binder.bind('country')} search={controller.search('country')} /></div>
        <div className='col-md-4'><Form.Input label='Phone' readOnly={isSuperUser} bind={binder.bind('phone')} /></div>
        <div className='col-md-4'><Form.Input label='Fax' readOnly={isSuperUser} bind={binder.bind('fax')} /></div>
        <div className='col-md-2'><Form.Input label='Plant Code' readOnly={isSuperUser} bind={binder.bind('plantCode')} hint='4 chars max' error={errors.plantCode} /></div>
        <div className='col-md-2'><Form.Input label='Location Code' readOnly={isSuperUser} bind={binder.bind('locationCode')} hint='2 chars max' error={errors.locationCode} /></div>
        <div className='col-md-2'><Form.Input label='Division' readOnly={isSuperUser} bind={binder.bind('division')} hint='2 chars max' error={errors.division} /></div>
        <div className='col-md-2'><Form.Input label='Sales Office' readOnly={isSuperUser} bind={binder.bind('salesOffice')} hint='4 chars max' error={errors.salesOffice} /></div>
        <div className='col-md-1'><Form.StaticData label='Use SAP' readOnly={isSuperUser} value={Utils.formatBool(facility.useSAP)} /></div>
        <div className='col-md-2'><Form.CheckBox label='Archived' disabled={isSuperUser} bind={binder.bind('archived')} /></div>
        {usetts}
        <div className='col-md-2'><Form.CheckBox label='Gauge Management' disabled={isSuperUser} bind={binder.bind('enableDMS')} /></div>
        <div className='col-md-2'><Form.CheckBox label='Cost Center Mandatory' disabled={isSuperUser} bind={binder.bind('mandateCostCenter')} /></div>
      </div>
    </div>
  );
}

const Buttons = (props) => {
  let history = useHistory();

  const handleSave = () => {
    props.controller.save(() => {
      history.goBack();
    });
  }
  const handleCancel = () => {
    history.goBack();
  }
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  return (
    <div className='col-sm-12'>
      <div className='mt-2'>
        <div className='edit-buttons d-flex justify-content-end'>
          <Button icon='fa-save' variant='primary' size='sm' id='btn-save' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving || isSuperUser}>Save</Button>
          <Button icon='fa-times' variant='secondary' size='sm' id='btn-cancel' className='m-1 action-button' onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    </div>
  );
}

const ProfitCostCenter = (props) => {
  return (
    <div className='col-md-12'>
      <div className='mt-2'>

      </div>
    </div>
  );
}

const EditFacilitiesPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class EditFacilityPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const id = this.props.match.params.facilityId;
    this.props.controller.load(id);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new EditFacilityController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const facilityName = controller.state.facilityName;
    const isSuperUser = LoginService.isInRole(Roles.SuperUser);
    const title = (controller.adding ? 'Create ' : isSuperUser ? 'View ' : 'Edit ') + 'Facility' + (facilityName ? (' — ' + facilityName) : '');
    const useSAP = controller.state.useSAP;
    const profitCostCenterGrid = useSAP
      ? <ProfitCostCenterTable controller={controller} binder={binder} />
      : null;
    const receivingCostCenterGrid = useSAP
      ? <ReceivingCostCenterTable controller={controller} binder={binder} />
      : null;

    return (
      <Page {...this.props} pageTitle='InteServ · Edit Facility' id='edit-facility-page'>
        <EditFacilitiesPageHeader pageTitle={title} />
        <Page.Content>
          <div className>
            <EditForm controller={controller} binder={binder} />
            {profitCostCenterGrid}
            {receivingCostCenterGrid}
            <Buttons controller={controller} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}


export default EditFacilityPage;
