import React from 'react';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Dialog from '../../components/Dialog';
import ManualPushToCATSController from '../controllers/ManualPushToCATSController';
import LoginService from '../services/LoginService';


const TimeComponent = (props) => {
    const controller = props.controller;
    const PushedHours = controller.state.pushedHours;
    const RemainingHours = controller.state.remainingHours;
    const TotalHours = controller.state.totalHours;
    const workDate = controller.state.workDate;
    const workDateInput = <Form.StaticData label='Date:' value={workDate} />;
    const PushedHoursInput = <Form.StaticData label='Time already pushed to CATS(hours):' value={PushedHours} />;
    const RemainingHoursInput = <Form.StaticData label='Remaining Time(hours):' value={RemainingHours} />;
    const TotalHoursInput = <Form.StaticData label='Total Time(hours):' value={TotalHours} />;

    return (
        <div>
            {workDateInput}
            {PushedHoursInput}
            {RemainingHoursInput}
            {TotalHoursInput}
        </div>
    );
}

class ManualPushToCATSDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new ManualPushToCATSController()
    }

    componentDidMount = () => {
        this.initializeMixin();
        const loginInfo = LoginService.loginInfo;
        this.props.controller.load(loginInfo.userId);
    }

    handleClose = (result) => {
        if (result.Cancel) {
            return true;
        }
        return this.props.controller.saveManualHours();
    }

    render = () => {
        const controller = this.props.controller;

        return (
            <Dialog {...this.props} modal={true} width={430} height={350} header={'Time Confirmation to CATS'} id='manualpush-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div className='row'><div className='col'><TimeComponent controller={controller} /></div></div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons saving={controller.saving} />
            </Dialog>
        );
    }
}

export default ManualPushToCATSDialog;