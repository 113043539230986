import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import ThicknessReadingRowController from './ThicknessReadingRowController';
import messages from '../../services/Messages';

class UpdateBelowMinimumReadingsController extends BaseController {
  constructor(asset, readingDetails) {
    super('belowMinimumReadings',{
      asset               : asset,
      errors              : {},
      readings            : [],
    });
    this.updateReadingValue(readingDetails);
  }

  updateReadingValue = (readingDetails) => {
    if (readingDetails && readingDetails.length) {
      readingDetails.forEach(row => {
        this.state.readings.push(new ThicknessReadingRowController(this, row));
      });
    }
  }

  deleteAndUnscrap = () =>{
    this.state.readings.map(row => row.state.readingValue = null);
    this.commit();
    this.saveAndUnscrap();
  }

  saveAndUnscrap = () =>{
    const self = this;

        this.state.readings.map(row => row.state.scopeSerial = 'Manual');
        const parameters = {
          assetId: self.state.asset.assetId,
          jobSOWId: self.state.asset.jobSOWId,
          readingsList: self.state.readings.map(reading => reading.state)
        };
        return ajax.post('gauge/updateMinimumReadings', parameters).then(response=>{
          notification.action('success').post('Saved the Thickness reading');
          messages.channel('edit-asset').action('updated').post(self.state.asset);
          messages.channel('dialog').action('__close__').post(true);
          return response;
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
      }); 
  }
}

export default UpdateBelowMinimumReadingsController;