import React from 'react';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Utils from '../utility/Utils';
import GroupBadge from '../../components/GroupBadge';
import Search from '../components/Search';
import List from '../../components/List';

const Item = (props) => {
  const toggleSelection = (item) => {
    props.controller.toggleSelection(item);
  }
  const selectAndTransfer = (item) => {
    props.controller.selectAndTransfer(!!props.isSource, item);
  }

  const item = props.item;
  const className = 'item';

  return (
    <List.Item className={className + ' p-2'} active={item.selected} onClick={() => { toggleSelection(item); }} onDoubleClick={() => { selectAndTransfer(item); }}>
      <span className='laborCode'>{item.laborCodeName}</span>
      <span className='description'>{item.description || item.laborCodeDescription}</span>
    </List.Item>
  );
}

const SourceItems = (props) => {

  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.search();
  }

  const handleMouseSearch = () => {
    props.controller.search();
  }

  const clearSearch = () => {
    props.controller.clearSearch();
  }

  // const selectNone = () => {
  //   props.controller.selectNoSourceItems();
  // }
  // const selectAll = () => {
  //   props.controller.selectAllSourceItems();
  // }

  const binder = props.binder;
  const controller = props.controller;
  const sourceItems = controller.state.sourceItems;
  const availableLabel = Utils.pluralize('match', 'es', sourceItems.length);

  return (
    <div className='col-md-5'>
      <div className='row'>
        <div className='col-md-12'>
          <Search id='searchlaborcodes' className='labor-codes mb-2' label='Enter search text' onKeyDown={handleKeyboardSearch} placeholder='search text' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}>
            <Badge className='m-1' variant='info'>{availableLabel}</Badge>
          </Search>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row'>
          <List id='source-list' style={{ paddingLeft: 'unset' }}>
            {
              sourceItems.map(item => {
                return <Item controller={controller} item={item} isSource={props.isSource} />;
              })
            }
          </List>
        </div>
      </div>
    </div >
  );
}

const SelectedItems = (props) => {
  const selectNone = () => {
    props.controller.selectNoSelectedItems();
  }
  const selectAll = () => {
    props.controller.selectAllSelectedItems();
  }

  const controller = props.controller;
  const selectedItems = controller.state.selectedItems;
  const selectedLabel = selectedItems.length + ' selected';

  return (
    <div className='col-md-5'>
      <div id='form-buttons' className='col-md-12'>
        <div className='row'>
          <div className='d-flex justify-content-start'>
            <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
            <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
            <Badge variant='info' className='m-1'>{selectedLabel}</Badge>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row customer-list'>
          <List id='selected-list' style={{ paddingLeft: 'unset' }}>
            {
              selectedItems.map(item => {
                return <Item controller={controller} item={item} useritem={true} />;
              })
            }
          </List>
        </div>
      </div>
    </div>
  );
}

const TransferButtons = (props) => {
  const select = () => {
    props.controller.moveToSelected();
  }
  const unselect = () => {
    props.controller.removeFromSelected();
  }
  const selectDisabled = false;
  const unselectDisabled = false;

  return (
    <div id='transfer-buttons' className='col-md-2 mt-5'>
      <div className='row'>
        <div className='col-md-12'>
          <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
          <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect} >Unselect</Button>
        </div>
      </div>
    </div>
  );
}

class SelectLaborCodesDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Close ? true : this.props.controller.save();

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const labelText = 'Select the Labor Codes you wish to associate with the Manufacturer Description';

    return (
      <Dialog {...this.props} header={this.props.header} width={750} height={650} id='select-labor-code-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <div className='form-group col-md-12'>
              <div className='text-center'>
                <h5>{labelText}</h5>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='row'>
                <SourceItems controller={controller} binder={binder} isSource={true} />
                <TransferButtons controller={controller} />
                <SelectedItems controller={controller} />
              </div>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCloseButtons />
      </Dialog>
    );
  }
}

export default SelectLaborCodesDialog;
