import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
class EditAssetCertificateDialog extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render = () => {
    const binder = new Binder(this);

    return (
      <Dialog {...this.props} header={this.props.header} width={500} height={210} id='edit-asset-certificate-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div className='col-md-12'>
              <Form.Input focus={true} label='Certificate' bind={binder.bind('certificate')} />
            </div>
          </form>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
      </Dialog>
    );
  }
}

export default EditAssetCertificateDialog;
