import BaseController from '../../lib/BaseController';
import LoginService from '../services/LoginService';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification'
import Utils from '../utility/Utils';

class LoginController extends BaseController {
    constructor() {
        super('login', {
            username: '',
            password: '',
            errorMessage: '',
            badgeSwipe: ''
        });
    }

    loginFromBadgeReader = () => {
        var badgeSwipe = this.state.badgeSwipe;
        if (!badgeSwipe) {
            return;
        }

        LoginService.login(badgeSwipe);
    }

    loginFromKeyboard = () => {
        var username = this.state.username;
        var password = this.state.password;

        if (!username || !password) {
            return;
        }

        LoginService.login(username, password);
    }

    getCoCCertificate = (serialGUID) => {

        ajax.post('security/getSerialNumberForCoc', serialGUID).then((serial)=>{
            if (serial) {
                ajax.getExternal({ serialNumbers: serial }).then((response) => {
                    if (response && response.File) {
                        const pdfData = response.File;
                        const blob = Utils.convertBase64toBlob(pdfData, 'application/pdf');
                        const fileName = 'Certificate' + '.pdf';
                        const fileURL = URL.createObjectURL(blob);
    
                        if (navigator.userAgent.match(/iPad/i) != null) {
                            window.open(fileURL);
                        }
    
                        else {
                            const tempLink = document.createElement('a');
                            tempLink.href = fileURL;
                            tempLink.download = fileName;
                            tempLink.click();
                            tempLink.remove();
                        }
                    }
                    else {
                        notification.action('error').post({ msg: 'Invalid Serial to download certificate', title: 'Error' });
                    }
                })
                .catch(err => {
                    notification.action('error').post({ msg: err.message, title: 'Error' });
                });               
            }
            else {
                notification.action('error').post({ msg: 'Invalid Serial to download certificate', title: 'Error' });
            }
        }) 
        .catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });   
    }
}

export default LoginController;