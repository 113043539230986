import React from 'react';
import ReactDOM from 'react-dom';
import co from '../lib/Co';
import $ from 'jquery'; 
import select2 from 'select2';

class Shortcut extends React.Component{

  static getShortcuts = (key) => {
      return Shortcut.__shortcuts[key];
    }

  handleFocus = (evt) => {
      var element = $(ReactDOM.findDOMNode(this));
      if (element.length === 0) {
        return;
      }
    
      if (element.is('button:visible,.select2-focusser:visible,input:not(.select2-input,.select2-offscreen):visible,textarea:visible,select:visible')) {
        element.focus();
        return;
      }
    
      var fields = element.find('button:visible,.select2-focusser:visible,input:not(.select2-input,.select2-offscreen):visible,textarea:visible,select:visible');
      if (fields.length === 0) {
        return;
      }
    
      fields.focus();
      evt.stopPropagation();
      evt.preventDefault();
  }

  isGenerator = (obj) => {
    return (obj && (typeof obj.next === 'function') && (typeof obj.throw === 'function'));
  }
  
  handleShortcut = (evt) => {
    var shortcutKey = String(evt.key).toLowerCase();
    if (shortcutKey === 'unidentified') {
      shortcutKey = String.fromCharCode(evt.keyCode).toLowerCase();
    }
  
    if (evt.shiftKey) {
      shortcutKey = 'shift+' + shortcutKey;
    }
    if (evt.ctrlKey) {
      shortcutKey = 'ctrl+' + shortcutKey;
    }
  
    var shortcuts = Shortcut.getShortcuts(shortcutKey);
    if (!shortcuts || (shortcuts.length === 0)) {
      return;
    }
  
    var match;
    var target = $(evt.target);
  
    if (shortcuts.length === 1) {
      match = shortcuts[0];
    }
    else {
      for (var i = shortcuts.length - 1; i >= 0; i--) {
        var self    = shortcuts[i];
        var isChild = $(ReactDOM.findDOMNode(self._owner)).find(target);
  
        if (isChild.length > 0) {
          match = self;
          break;
        }
      }
    }
  
    if (!match) {
      if (shortcuts[0].props.preventDefault !== undefined) {
        evt.preventDefault();
        evt.stopPropagation();
      }
  
      return;
    }
  
    var action = (match.props.action || this.handleFocus);
    if (action) {
      var active;
  
      if (action !== this.handleFocus) {
        active = $(document.activeElement);
        active.blur();
      }
  
      setTimeout(() => {
        var fn = action.call(match, evt);
        if (this.isGenerator(fn)) {
          co(function*() {
            yield* fn;
            active.focus();
          })();
          return;
        }
  
        if (active) {
          active.focus();
        }
      }, 0);
  
      if (match.props.preventDefault !== undefined) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
  }

  addShortcut = () => {
    if (!this.props.shortcutKey) {
      return;
    }

    if (!Shortcut.__shortcuts) {
      $(document).on('keydown', this.handleShortcut);
      Shortcut.__shortcuts = {};
    }

    if (!Shortcut.__shortcuts[this.props.shortcutKey]) {
      Shortcut.__shortcuts[this.props.shortcutKey] = [];
    }

    Shortcut.__shortcuts[this.props.shortcutKey].push(this);
  }

  removeShortcut = () => {
    var shortcuts = Shortcut.getShortcuts(this.props.shortcutKey);
    if (!shortcuts) {
      return;
    }

    shortcuts.splice(shortcuts.indexOf(this), 1);
    if (shortcuts.length === 0) {
      delete Shortcut.__shortcuts[this.props.shortcutKey];
    }
  }

  componentDidMount = () => {
    this.addShortcut();
  }

  componentWillUnmount = () =>{
    this.removeShortcut();
  }

  render = () => {
    return (this.props.children) ? this.props.children : null;
  }
}

export default Shortcut;
