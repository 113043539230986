import React, { useMemo, useEffect } from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import BroadcastMessagesController from '../controllers/BroadcastMessagesController';


const BroadcastPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-bullhorn' />

class BroadcastMessagesPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();
    }

    static defaultProps = {
        controller: new BroadcastMessagesController()
    }

    handleSend = () => {
        this.props.controller.sendMessage();
    }

    handleClear = () => {
        this.props.controller.clear();
    }

    render = () => {
        const binder = new Binder(this);
        return (
            <Page {...this.props} pageTitle='InteServ · Broadcast Message' id='broadcast-page'>
                <BroadcastPageHeader pageTitle={'Broadcast Messages'} />
                <Page.Content>
                    <div className='row'>
                        {/* <form> */}
                        <div className='col-sm-12'><Form.Input label='Title' className='required' bind={binder.bind('title')} /></div>
                        <div className='col-sm-12'><Form.TextArea label='Message' className='required' bind={binder.bind('message')} rows={5} /></div>

                        <div className='col-sm-12'>
                            <span className='field-label' style={{ display: 'block' }}>Severity</span>
                            <Form.RadioInput bind={binder.bind('severityInfo')} label='Info'/>
                            <Form.RadioInput bind={binder.bind('severitySuccess')} label='Success'/>
                            <Form.RadioInput bind={binder.bind('severityWarning')} label='Warning'/>
                            <Form.RadioInput bind={binder.bind('severityError')} label='Error'/>
                        </div>

                        <div className='col-sm-12 mt-2'>
                            <div className='d-flex justify-content-between'>
                                <Button variant='info' size='sm' icon='fa-times' className='btn-action action-button' onClick={this.handleClear}>Clear</Button>
                                <div>
                                    <Button variant='success' size='sm' icon='fa-bullhorn' className='btn-action action-button' onClick={this.handleSend}>Send</Button>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default BroadcastMessagesPage;
