import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Table from '../../components/Table';

const CalibrationReadingRow = (props) => {

  const data = props.data;
  const ReadingValue = isNaN(parseFloat(data.ReadingValue)) ? '' : parseFloat(data.ReadingValue).toFixed(3)
  return (
    <Table.Row>
      <Table.Data>{data.ReadingID}</Table.Data>
      {/* <Table.Data>{data.Previous_Reading}</Table.Data> */}
      <Table.Data>{data.MinimumDimension}</Table.Data>
      <Table.Data>{ReadingValue}</Table.Data>
      <Table.Data>{data.ScopeSerial}</Table.Data>
    </Table.Row>
  );
}

const DailyCalibrationHistoryTable = (props) => {

  const handleToggleCollapsed = (calHeader) => {
    props.handleToggleCollapsed(calHeader);
  }

  // const hasChildren = (performedServiceLevel) => {
  //   return (performedServiceLevel.performedTests.length || performedServiceLevel.timeTrackingResults.length || performedServiceLevel.wallReadings.length || (performedServiceLevel.thirdParty !== null));
  // }

  const getTableHeader = () => {
    return (
      <Table.Row className='service-history-job-header'>
        <Table.Data />
        <Table.Data colSpan={1}>Calibration Date</Table.Data>
        <Table.Data colSpan={2}>Performed by</Table.Data>
      </Table.Row>
    );
  }


  const getDailyCalibrationHeader = (calHeader) => {
    let actionButton = null;
    actionButton = calHeader.collapsed
      ? <ActionIcon icon='fa-plus-square' className='btn-expand-collapse' action={() => handleToggleCollapsed(calHeader)} />
      : <ActionIcon icon='fa-minus-square' className='btn-expand-collapse' action={() => handleToggleCollapsed(calHeader)} />;
    return (
      <Table.Row className='service-history-performed-service-level-header'>
        <Table.Data>{actionButton}</Table.Data>
        <Table.Data colSpan={1}>
          <span style={{ marginLeft: '15px', fontStyle: 'normal', fontWeight: 'bold', fontSize: '12px', color: 'black' }} className='field-label'>
            {DateTimeFormatter.formatDateTime(calHeader.dateAdded)}</span></Table.Data>
        <Table.Data colSpan={2}>
          <span style={{ marginLeft: '15px', fontStyle: 'normal', fontWeight: 'bold', fontSize: '12px', color: 'black' }} className='field-label'>
            {calHeader.updatedBy}</span></Table.Data>
      </Table.Row>
    );
  }

  const getCalibrationReadingHeader = () => {
    return (
      <Table.Row className='service-history-performed-test-header'>
        <Table.Data><span style={{ marginLeft: '30px' }}>Reading Id</span></Table.Data>
        {/* <Table.Data>Previous Reading</Table.Data> */}
        <Table.Data>Minimum Dimension</Table.Data>
        <Table.Data>Reading Value</Table.Data>
        <Table.Data>Scope Serial</Table.Data>
      </Table.Row>
    );
  }

  const addCalibrationReading = (rows, calHeader, calDetail) => {
    if (calDetail !== null) {
      rows.push(getCalibrationReadingHeader());
      var calibrationReadingRow = calDetail.map(row => {
        if (row.DailyCalId == calHeader.DailyCalId) {
          return <CalibrationReadingRow key={row.key} data={row} />;
        }
      });
      rows.push(calibrationReadingRow);
    }
  }

  const addDailyCalibrationRows = (rows, calHeader, calDetail) => {
    rows.push(getDailyCalibrationHeader(calHeader));
    if (!calHeader.collapsed) {
      addCalibrationReading(rows, calHeader, calDetail);
    }
  }

  const addCalibrationRows = (rows, dataHeader, dataRow) => {
    rows.push(getTableHeader());
    dataHeader.forEach(job => {
      addDailyCalibrationRows(rows, job, dataRow);
    });
  }

  const dataRow = props.data.dailyCalibrationHistory || [];
  const dataHeader = props.data.dailyCalHistoryHeader || [];
  let results = [];

  addCalibrationRows(results, dataHeader, dataRow);

  const colCount = 4;
  const loadingMessage = props.data.searchInProgress && <Table.Row><Table.Data colSpan={colCount} className='loading'>Loading Daily calibration details</Table.Data></Table.Row>;
  const noResultsMessage = !loadingMessage && !results.length && <Table.Row><Table.Data colSpan={colCount} className='no-search-results'>No results for the current asset</Table.Data></Table.Row>;

  return (
    <div id='daily-calibration-history' className='row mt-2'>
      <div className='col-md-12'>
        {/* <Form.Header className='bg-warning' style={{margin: '0', padding: '6px'}}>Daily Calibration History</Form.Header> */}
        {/* <Form.Header className='headingClass'>
          Daily Calibration History
        </Form.Header> */}
        <div id='service-history' className='search-results'>
          <Table>
            <Table.Body>
              {results}
              {loadingMessage}
              {noResultsMessage}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default DailyCalibrationHistoryTable;
