import React from 'react';
import Table from '../../components/Table';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import AppConstants from '../utility/AppConstants'

class AdhocTransactionRow extends React.Component {
    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
        this.binder = new Binder(this);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleRemoveRow = () => {
        this.props.controller.removeRow();
    }

    handleOnKeyPress = (evt) => {
        if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
            evt.stopPropagation();
        }
    }

    handleKeyUp = (evt) => {
        try {
            const value = parseInt(evt.target.value, 10);
            if (value < 0) {
                evt.target.value = 0;
            }
            if (value > 100000) {
                evt.target.value = 100000;
            }
        }
        catch (err) {
            evt.target.value = 0;
        }
    }

    renderDollarsRow = () => {
        const controller = this.props.controller;
        const parentController = controller.parentController;
        const binder = this.binder;
        const errors = controller.state.errors;
        const disabled = controller.state.unitOfMeasure === '';

        return (
            <Table.Row>
                <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle'  action={this.handleRemoveRow} /></Table.Data>
                <Table.Data className='col-operation'>
                    <Form.AutoComplete id='ad-hoc-operation' readOnly={false} bind={binder.bind('operation')} error={errors.operation} search={parentController.operationSearch()} />
                </Table.Data>

                <Table.Data colSpan={3} className='col-dollars'>
                    <Form.Input id='ad-hoc-dollars' type='number' readOnly={disabled} disabled={disabled} bind={binder.bind('value')} error={errors.value} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
                </Table.Data>

                <Table.Data className='col-unit-of-measure'>
                    {this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity ? <Form.StaticData value='Quantity' /> : <Form.StaticData value='Dollars' />}
                </Table.Data>
                <Table.Data className='col-technician'></Table.Data>
                <Table.Data className='col-date'></Table.Data>
                <Table.Data className='col-notes'><Form.Input bind={binder.bind('notes')} /></Table.Data>
            </Table.Row>
        )
    }

    renderTimeRow = () => {
        const controller = this.props.controller;
        const parentController = controller.parentController;
        const binder = this.binder;
        const errors = controller.state.errors;
        const disabled = controller.state.unitOfMeasure === '';
        const timerow = controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours ? null : <Form.Input type='number' id='ad-hoc-min' min='0' max='60' readOnly={disabled} disabled={disabled} bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />;

        return (
            <Table.Row>
                <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle' action={this.handleRemoveRow} /></Table.Data>
                <Table.Data className='col-operation'>
                    <Form.AutoComplete id='ad-hoc-operation' readOnly={false} bind={binder.bind('operation')} placeholder='select one of the operations' error={errors.operation} search={parentController.operationSearch()} />
                </Table.Data>

                <Table.Data className='col-time'>
                    <Form.Input type='number' id='ad-hoc-hrs' min='0' max='24' readOnly={disabled} disabled={disabled} bind={binder.bind('hours')} error={errors.hours} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
                </Table.Data>
                <Table.Data className='col-unit-of-measure'><Form.StaticData value='Hours' /></Table.Data>

                <Table.Data className='col-time'>
                    {timerow}
                </Table.Data>
                <Table.Data className='col-unit-of-measure'>
                    {this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity ? <Form.StaticData value='Quantity' /> : (this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Hours ? null : <Form.StaticData value='Minutes' />)}
                </Table.Data>

                <Table.Data className='col-technician'>
                    {/* <Form.AutoComplete bind={binder.bind('technician')} disabled='true' className='required' placeholder='select a technician' error={errors.technician} search={Searchers.activeUsersForSapFacilityWithEmployeeId(facilityId)} /> */}
                    <Form.StaticData value={controller.state.technician} />
                </Table.Data>
                <Table.Data className='col-date' style={{ minWidth: 100 }}>
                    {/* <Form.Input type='date' bind={binder.bind('date')} className='required'error={errors.date} /> */}
                    <Form.StaticData value={controller.state.date} />
                </Table.Data>
                <Table.Data className='col-notes'><Form.Input bind={binder.bind('notes')} /></Table.Data>
            </Table.Row>
        );
    }

    render = () => {
        if (this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || this.props.controller.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) {
            return this.renderDollarsRow();
        }
        return this.renderTimeRow();
    }
}

export default AdhocTransactionRow;