import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';

class ConfirmReapplyServiceLevelDialog extends React.Component{
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
}

componentDidMount = () => {
  this.initializeMixin();
}

  // handleClose: function*(result) {
    // if (result.Cancel) {
    //   return true;
    // }

    // this.setState({saving: true});
    // yield this.props.controller.save();
    // this.setState({saving: false});
    // return result;
  // },

  render = () => {
    const binder             = new Binder(this);
    const controller         = this.props.controller;
    const assetCount         = controller.state.assetCount;
    const selectedAssetCount = controller.state.selectedAssets.length;
    const displayMessageOnly = ((selectedAssetCount === 0) || (selectedAssetCount === assetCount));
    const message            = displayMessageOnly
                                ? 'Please confirm you wish to reapply the service levels for the ' + (assetCount === 1 ? 'asset' : assetCount + ' assets') + ' on the job'
                                : 'Please make a selection from the following and confirm you wish to reapply service levels for the ' + (selectedAssetCount === 1 ? '' : selectedAssetCount + ' ') + 'selected asset' + (selectedAssetCount === 1 ? '' : 's') + ' on the job';
    const warning            = 'Warning: any selected service levels will be reset and you will have to re-select them';

    let options = null;
    if (!displayMessageOnly) {
      const option1 = (assetCount === 2 ? 'Both' : 'All ' + assetCount) + ' assets on the job';
      const option2 = 'Just the ' + (selectedAssetCount === 1 ? 'selected asset' : selectedAssetCount + ' selected assets');
      options = (
        <div className='col-sm-12 text-center mx-2'>
          <Form.RadioInput bind={binder.bind('reapplyAll')} label={option1}/>
          <Form.RadioInput bind={binder.bind('reapplySelectedOnly')} label={option2}/>
        </div>
      );
    }

    return (
      <Dialog {...this.props} header={this.props.header} width={750} height={250} id='confirm-reapply-service-levels-dlg'>
        <Dialog.Body>
            <h5 className='col-sm-12 text-center' >{message}</h5>
            {options}
            <div className='col-sm-12 text-center font-weight-bold text-danger mt-3'>{warning}</div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons  saving={this.props.controller.saving}/>
      </Dialog>
    );
  }
}

export default ConfirmReapplyServiceLevelDialog;
