import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Button from '../../components/Button';
import SAPService from '../services/SAPService';
import Grid from '../../components/Grid';
import GroupBadge from '../../components/GroupBadge';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import InspectionTestIcons from '../utility/InspectionTestIcons';
import store from 'store';
import ActionIcon from '../../components/ActionIcon';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import Badge from '../../components/Badge';

const AssemblyTab = (props) => {
  const controller = props.controller;
  let history = useHistory();

  // const getStatusElement = (showSAPStatus, awo) => {
  //     return true;
  // }

  const handleRowClick = (awo) => {
    props.controller.clearActiveTests();
    if (awo && awo.length) {
      props.controller.toggleSelection(awo);
    }
    else {
      props.controller.selectNone();
    }
  }

  const showSAPStatus = SAPService.isSAPFacility();
  const selectedAssemblyGroups = props.controller.state.selectedAssemblyGroups;
  const assemblyData = selectedAssemblyGroups.length > 0 ? props.controller.state.filteredconfigurations : props.controller.state.configurations;


  const handlePageChange = () => {
    //TopPagination.Toggle();
  }
  const selectNone = (evt) => {
    evt.preventDefault();
    props.controller.selectNone();
  }

  const selectAll = (evt) => {
    evt.preventDefault();
    props.controller.selectAll();
  }

  const AssemblyButtons = (props) => {
    let history = useHistory();

    const getActiveTest = (testName) => {
      let tests = props.controller.state.assemblyTests.concat(controller.state.extraTests);
      let test = tests.filter(t => t.name === testName)[0];
      return test;
    }

    const getActiveTestName = () => {
      const testName = props.controller.state.activeTestName;
      return testName;
    }

    const passTest = () => {
      const test = getActiveTest(getActiveTestName());
      if (test && !test.enabled) {
        return;
      }
      props.controller.passTest(test);
    }

    const recordTime = () => {
      const test = getActiveTest(getActiveTestName());
      if (test && !test.enabled) {
        return;
      }
      props.controller.recordOperationsTime(test);
    }

    const notPerformedTest = () => {
      const test = getActiveTest(getActiveTestName());
      if (test && !test.enabled) {
        return;
      }
      props.controller.notPerformedTest(test);
    }

    const InspectionButton = (props) => {

      let history = useHistory();

      const handleClick = (test) => {
        if (!test.enabled) {
          return;
        }
        props.controller.setActiveTest(test.name);
      }

      const handleAddReworkTime = (test) => {
        props.controller.addReworkTime(test);
      }

      const getStampBandData = () => {
        props.controller.getStampBandData();
      }

      const handleAddThicknessReading = () => {
        props.controller.addThicknessReadings();
      }


      const handleAddDimensionValues = () => {
        props.controller.addDimensionValues();
      }

      const displayScrapSignature = (scrapSignature) => {
        props.controller.displayScrapSignature(scrapSignature);
      }

      const navigateToPressureTests = () => {
        const controller = props.controller;
        const queryData = {
          jobId: controller.state.selectedJob.id,
          customerId: controller.state.selectedJob.customerId,
          familyCodeId: controller.getCommonFamilyCodeId(),
          cwpId: controller.getCommonCWPId(),
          canEdit: controller.canEditPressureTests()
        };
        //  history.push({
        //    pathname: '/manualpressuretests/',
        //    state: { params: queryData }
        //  });

      }

      const handleNotesClick = (test) => {
        props.controller.addTestNotes(test);
      }

      const getActiveTestName = () => {
        const testName = props.controller.state.activeTestName;
        return testName;
      }

      const controller = props.controller;
      const test = props.test;
      const selectedConfigs = controller.state.configs.configs;
      const activeTestName = controller.state.activeTestName;
      let buttonClassName = 'test-btn btn-md';
      let smallTextClassName = '';

      let isNA = false;
      let passArray = [], failArray = [];
      const loginDetail = store.get('InteServLoginInfo');
      const dmsEnabledFacility = loginDetail ? loginDetail.enableDMS : false;
      const isSAPFacility = SAPService.isSAPFacility();

      if (test.enabled && (test.name === activeTestName)) {
        buttonClassName += ' active test-btn-selected';
        smallTextClassName += 'test';
      }
      else {
        buttonClassName += ' btn-default';
      }

      if (test.enabled) {
        buttonClassName += ' ' + (test.className ? test.className : 'extrabutton');
      }
      else {
        buttonClassName += ' disabled-button';
        smallTextClassName += ' disabled-button';
      }

      var matchingTests = controller.getTestResults(selectedConfigs, test.name);
      var testNotperformed = controller.isGroupTestNotPerformed(matchingTests);
      var timeTrackingResults = controller.getTimeTrackingResults(selectedConfigs, test.name);
      var testUpdatedDate = controller.getCommonTestDate(timeTrackingResults);
      var testPerformedBy = controller.getCommonTechnician(timeTrackingResults);
      var testOperationCode = controller.getOperationCode(matchingTests);
      var statusIcon = controller.getStatusAssetsIcon(matchingTests);
      var statusText = controller.getStatusText(matchingTests);
      var lastTestResult = controller.getLastTestResultName();
      var testDuration = controller.getTestDuration(test.name);
      // var anyAssetsScrapped = selectedAssets.some(asset => controller.isAssetScrapped(asset));
      var canAddReworkTime = controller.canAddReworkTime(statusIcon, testUpdatedDate);

      //var istecountecoDisable   = (controller.state.istecountecoDisable && controller.userHasTecoRights());   
      var btnReworkTime = props.showReworkTime && <GroupBadge variant='secondary' className='m-1' pill={false} disabled={!canAddReworkTime} onClick={() => handleAddReworkTime(test)}>Add Rework Time</GroupBadge>;
      const notesButton = selectedConfigs.length === 1
        ? <GroupBadge variant='warning' className='m-1' pill={false} onClick={() => handleNotesClick(test)}>Notes</GroupBadge>
        : null;
      var isFracCustomer = controller.state.selectedJob ? controller.state.selectedJob.isFracCustomer : '';


      // tried to get results using time tracking results, but failed to get any
      // fall back to using test results
      if (!testUpdatedDate) {
        testUpdatedDate = controller.getCommonTestDate(matchingTests);
      }

      // tried to get results using time tracking results, but failed to get any
      // fall back to using test results
      if (!testPerformedBy) {
        testPerformedBy = controller.getCommonTechnician(matchingTests);
      }

      if (lastTestResult === test.name) {
        smallTextClassName += ' most-recent';
      }

      const isMultiLineLabel = test.name === 'Thickness/Dimensional Measurements' ? 'multi-line-label' : '';
      var divClassName = test.enabled ? 'col-lg-12 inspection-test-button' : 'invisible';

      return (
        <div className={divClassName} key={test.name}>
          <span className='test'>
            <Button variant='light' className={buttonClassName} onClick={() => { handleClick(test); }}>
              {/* {statusText} */} <span className={isMultiLineLabel + ' test-name font-weight-bold'}>{test.name}</span>{statusIcon}
              <span className={smallTextClassName + ' font-weight-bold test-performed-by mb-2'}>{testPerformedBy}</span>
              <span className={smallTextClassName + ' font-weight-bold test-date-updated'}>{testUpdatedDate}</span>
              <div className={smallTextClassName + ' font-weight-bold test-operation-code'}>{testOperationCode}</div>
              <div className={smallTextClassName + ' font-weight-bold test-duration'}>{testDuration}</div>
            </Button>
          </span>
          {btnReworkTime}

          {notesButton}
          {
            //(test.name === 'Stamp/Band') && (selectedAssets.length === 1) &&
            //  <div><GroupBadge variant='info' pill={false} onClick={getStampBandData}>View Stamp/Band</GroupBadge></div>
          }
          {
            //(test.name === 'Thickness (UT)') && (selectedAssets.length === 1) && dmsEnabledFacility &&
            //<div><GroupBadge variant='info' pill={false} onClick={handleAddThicknessReading}>Record Reading</GroupBadge></div>
          }
          {
            //canOverridePressureTests &&
            //<div><GroupBadge variant='warning' pill={false} onClick={navigateToPressureTests}>Override Pressure Tests</GroupBadge></div>
          }
          {
            //(test.name === 'Final Inspection') && hasScrappedAssetSignature &&
            //<div><GroupBadge variant='danger' onClick={() => displayScrapSignature(selectedAssets[0].scrapSignature)}>View Scrap Details</GroupBadge></div>
          }
          {/* {(test.name.includes('Final Inspection')) && (selectedAssets.length>0) &&
                      {tecoUntecoButton}
                    } */}
          {
            //(test.name.includes('Torque')) && (selectedAssets.length === 1) &&
            //addTorqueValues
          }
          {//(test.name.includes('Dimensional')) && (selectedAssets.length === 1) &&
            // addDimensionValues
          }
        </div>
      );
    }

    //end Inspection Button
    const renderInspectionButtons = () => {
      const controller = props.controller;
      const assemblyTests = controller.state.assemblyTests.filter(test => test.enabled);
      const extraTests = controller.state.extraTests;
      let inspectionButtons = assemblyTests
        .filter(test => test.name !== 'Final Inspection')
        .concat(extraTests)
        .concat(assemblyTests.filter(test => test.name === 'Final Inspection'));

      inspectionButtons
        .forEach(inspectionButton => {
          inspectionButton.operationOrder = parseInt(controller.getOperationOrder(inspectionButton.name), 10) || 0;
        });

      const pressureTestButtons = inspectionButtons.filter(button => ((button.name === 'Final Inspection') || (button.name === 'final inspection')));
      const pressureTestButtonOperationOrder = pressureTestButtons.length
        ? pressureTestButtons.reduce((acc, curr) => {
          return (curr.operationOrder > acc) ? curr.operationOrder : acc;
        }, 0)
        : 0;

      inspectionButtons = inspectionButtons
        .sort((a, b) => {
          return (a.operationOrder - b.operationOrder);
        })
        .map(test => <InspectionButton key={test.name} controller={controller} test={test} showReworkTime={pressureTestButtonOperationOrder >= test.operationOrder} />);

      return (
        <div className='row'>
          {inspectionButtons}
        </div>
      );
    }
    ///end renderInspectionButtons
    const handleNotesClick = () => {
      const awoId = controller.state.assemblyWorkOrderSearch.id
      if (!awoId || awoId === Utils.emptyGuid) {
        return;
      }
      history.push('/notes/' + AppConstants.NoteTypes.AWO + '/' + awoId);
    }

    const handleAttachments = () => {
      const awoId = controller.state.assemblyWorkOrderSearch.id
      if (!awoId || awoId === Utils.emptyGuid) {
        return;
      }
      history.push('/awoattachments/' + awoId);
    }

    const FixPlant = () => {
      const selectedAsset = props.controller.getSelectedAssets();
      if (selectedAsset.length == 0) {
        return;
      }
      props.controller.fixPlant(selectedAsset);
    }
    var compClassName = 'btn-test action-button  m-1';
    var recordTimeClassName = 'action-button btn-test m-1';
    var passClassName = 'btn-test action-button m-1';
    var notPerformedClassName = 'action-button btn-test m-1';
    var selectedConfigs = controller.state.configs.configs;
    var displayMessage = controller.state.selectedassetgroups?.length > 0;
    var test = getActiveTest(controller.state.activeTestName);
    var matchingTests = controller.getTestResults(selectedConfigs, test?.name);
    var IsTestPassed = controller.IsAssetTestAlreadyPassed(matchingTests, selectedConfigs);
    var isMarkedNotPerformed = controller.IsAssetTestAlreadyMarkedNotperformed(matchingTests, selectedConfigs);
    var attachFilesDisabled = (controller.state.configs.configs === undefined || controller.state.configs.configs.length == 0 || controller.state.disableButtons) ? true : false;//(selectedAssets.length == 0 || selectedAssets.length > 1);
    var notesDisabled = (controller.state.configs.configs === undefined || controller.state.configs.configs.length == 0 || controller.state.disableButtons) ? true : false;// (selectedAssets.length == 0 || selectedAssets.length > 1);
    var passDisabled = controller.isPreviousTestComplete(test) === false || controller.state.configs.configs === undefined || controller.state.configs.configs.length == 0 || controller.state.activeTestName === undefined || controller.state.activeTestName === '' || IsTestPassed === true || controller.state.disableButtons === true ? true : false;
    var recordTimeDisabled = controller.state.configs.configs === undefined || controller.state.configs.configs.length == 0 || controller.state.activeTestName === undefined || controller.state.activeTestName === '' || IsTestPassed === true || controller.state.disableButtons === true || controller.isPreviousTestComplete(test) === false? true : false;
    var notPerformedDisabled = controller.isPreviousTestComplete(test) === false || controller.state.configs.configs === undefined || controller.state.configs.configs.length == 0 || controller.state.activeTestName === undefined || controller.state.activeTestName === '' || IsTestPassed === true || isMarkedNotPerformed === true || controller.state.activeTestName.toLowerCase() === 'final inspection' || controller.state.disableButtons === true ? true : false;
    var partsListDisabled = controller.isMultipleGroupsSelected() || controller.state.configs.configs === undefined || controller.state.configs.configs.length == 0 || controller.state.activeTestName === undefined || controller.state.activeTestName === '';

    //disable message
    var divDisabledMessage = '';
    if (props.controller.state.disabledMessage !== '' || props.controller.state.disabledMessage !== undefined) {
      divDisabledMessage = <div className='fail-disabled-message'>{props.controller.state.disabledMessage}</div>;
    }

    //   if (!controller.state.selectedJob) {
    //       divDisabledMessage = <div className='fail-disabled-message'>No AWo config is selected</div>;
    //   }
    //   else if (controller.state.selectedJob && controller.state.selectedJob.isComplete) {
    //       divDisabledMessage = <div className='fail-waiting-message'><i className='fas fa-clock' />&nbsp;Job is complete</div>;
    //   }
    //   else if (jobIsWaiting) {
    //       divDisabledMessage = <div className='fail-waiting-message'><i className='fas fa-clock' />&nbsp;Job is waiting</div>;
    //   }
    //   else if (assetsWithoutServiceOrderNumbers) {
    //       var preamble = (selectedAssets.length === 1) ? 'The selected asset ' : 'At least one selected asset ';
    //       var suffix = (selectedAssets.length === 1) ? 'it ' : 'they ';
    //       divDisabledMessage = <div className='fail-disabled-message'>{preamble + 'does not have a Service Order Number - ' + suffix + ' must be locked for inspection'}</div>;
    //   }
    //   else if (!anyAssetsScrapped && !anyAssetsShipped && operationOrderNotDefined) {
    //       divDisabledMessage = <div className='fail-disabled-message'>Unable to record activity - the order of operations for the tests have not been defined for the service level</div>;
    //   }
    //   else if (anyAssetsWaitingLockForInspection) {
    //       preamble = (selectedAssets.length === 1) ? 'The selected asset ' : 'At least one selected asset ';
    //       divDisabledMessage = <div className='fail-disabled-message'>{preamble + 'is waiting for a lock for inspection request to complete'}</div>;
    //   }
    //   else if (anyAssetsThatCannotBeInspected) {
    //       preamble = (selectedAssets.length === 1) ? 'The selected asset ' : 'At least one selected asset ';
    //       divDisabledMessage = <div className='fail-disabled-message'>{preamble + 'is not in the correct state to allow inspection to begin'}</div>;
    //   }
    //   else if (noFailReasonMessage) {
    //       divDisabledMessage = <div className='fail-disabled-message'>{noFailReasonMessage}</div>;
    //   }
    //   else if (selectedAssets.length && IsTestPassed) {
    //       var msg = (selectedAssets.length === 1)
    //           ? 'Selected asset is already passed'
    //           : 'At least one selected asset is already passed';
    //       divDisabledMessage = <div className='fail-disabled-message'>{'Pass button is disabled because: ' + msg}</div>;
    //   }
    //   else if (selectedAssets.length && activeTest && !isPreviousTestComplete) {
    //       var msg = (selectedAssets.length === 1)
    //           ? 'the selected asset'
    //           : 'at least one of the selected assets';
    //       divDisabledMessage = <div className='fail-disabled-message'>
    //           <div>{'The previous test has not been completed yet for ' + msg}</div>
    //           <div>You must Pass or mark it as Not Performed before recording activity for this test</div>
    //       </div>;
    //   }


    return (
      <div className='row' id='assembly-test-buttons' >
        <div className='col-md-12'>
          <Button icon='fa-clock' variant='secondary' size='sm' className={recordTimeClassName} disabled={recordTimeDisabled} onClick={recordTime}>Record Time</Button>
          <Button icon='far fa-thumbs-up' variant='success' size='sm' className={passClassName} disabled={passDisabled} onClick={passTest}>Pass</Button>
          <Button icon='fa-times' variant='primary' size='sm' className={notPerformedClassName} disabled={notPerformedDisabled} onClick={notPerformedTest}>Not Performed</Button>
          {SAPService.isSAPFacility() === true && <Button variant='info' size='sm' className={compClassName} disabled={partsListDisabled} onClick={FixPlant}>Parts List</Button>
          }

          <Button icon='fa-comments' disabled={notesDisabled} variant='info' size='sm' className='btn-test action-button m-1' onClick={() => handleNotesClick()}>Notes</Button>
          <Button icon='fa-plus-circle' disabled={attachFilesDisabled} variant='success' size='sm' className='action-button btn-test m-1' onClick={() => handleAttachments()}>Attach Files</Button>
        </div>
        <div className='col-md-12'>
          {displayMessage && divDisabledMessage}
        </div>
        {<div className='col-md-12'>{controller.state.selectedassetgroups?.length > 0 && renderInspectionButtons()}</div>
        }
      </div>
    );
  }
  useEffect(() => {
    handlePageChange();
  }, [assemblyData]);

  const getRowProps = (row) => {
    const awo = row.original;
    const rowProps = { id: awo.id };
    if (awo.sapStatus === 'QUOR') {
      rowProps.style = { backgroundColor: '#FFA07A' };
    }
    const selectedGroups = props.controller.getSelectedGroups();
    const selectedGroupNumbers = [...selectedGroups.map(group => group.value.groupNumber)];
    if (!selectedGroupNumbers.includes(row.original.groupNumber) && selectedGroupNumbers.length) {
      rowProps.className = 'd-none';
    }
    if (row.original.selected) {
      rowProps.isSelected = true;
    }
    else {
      rowProps.isSelected = false;
    }

    if (!awo.state.isChild) {
      rowProps.className = 'font-weight-bold';
    }
    return rowProps;
  }

  const groups = [...new Set(props.controller.state.configurations.map(x => x.state.group_name))].sort();


  const handleAssemblyGroupClick = (group) => {
    props.controller.setAssemblygroup(group);
    return false;
  }

  const isAssemblyGroupSelected = (group) => {
    let selectedassemblygroups = props.controller.state.selectedAssemblyGroups;
    return selectedassemblygroups.includes(group);
  }
  const clearAssemblyGroupSelection = () => {
    props.controller.resetAssemblyGroupSelection();
  }
  const getClassName = (asset) => {
    let className = 'asset';
    const assetStatus = asset.assetStatus;
    if (!controller.isAssetSelectable(asset)) {
      className += ' disabled';
    }
    let statusColourClass = ' status' + assetStatus; // e.g.: statusScrapped
    if (assetStatus === 'Weld Repair' || assetStatus === 'Failed Inspection') {
      statusColourClass = 'asset-status statusWeldRepair'
    }
    if (assetStatus === 'OS/Machining Repair') {
      statusColourClass = 'asset-status statusOSRepair'
    }
    if (statusColourClass === ' statusScrapped' || statusColourClass === ' statusWeldRepair') {
      className += statusColourClass;
    }
    return className;
  }
  const assemblyNonSapColumns = useMemo(
    () => [
      {
        Header: "",
        accessor: "statusicon",
        Cell: cellInfo => {
          if (!cellInfo.row.original.state.isChild) {
            if (!cellInfo.row.original.state.serviceOrderNumber) {
              return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='far fa-circle' /></span>;
            }

            else if (cellInfo.row.original.state.isWaitingForReapplyServiceLevels) {
              return <span className='status-column asset-active' title='Waiting for Reapply Assembly Levels to complete'><i className='fas fa-clock asset-waiting-reapply-service-levels' /></span>;
            }
            else if (cellInfo.row.original.state.isWaitingForLockForInspection) {
              return <span className='status-column asset-active' title='Waiting for Lock for Assembly request to complete'><i className='fas fa-clock asset-waiting-lock-for-inspection' /></span>;
            }
            else {
              switch (cellInfo.row.original.state.sapStatus) {
                case AppConstants.AssetSAPStatus.CRTD:
                  return <span className='status-column asset-active' title=''><i className='far fa-clock' /></span>;

                case AppConstants.AssetSAPStatus.REL:
                case AppConstants.AssetSAPStatus.PCNF:
                  return <span className='status-column asset-active' title='Assembly can be performed'><i className='fas fa-cogs' /></span>;

                case AppConstants.AssetSAPStatus.TECO:
                case AppConstants.AssetSAPStatus.CLSD:
                case AppConstants.AssetSAPStatus.LKD:
                  return <span className='status-column locked' title='No longer active on this AWO'><i className='fas fa-lock' /></span>;

                default:
                  return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='fas fa-circle' /></span>;
              }
            }
          }
          else {
            return <></>
          }

        },
        isVisible: showSAPStatus
      },
      {
        Header: 'Group',
        accessor: 'state.group_name',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          const isshipped = controller.state.isShipped;
          return <><span> {controller.state.group_name} </span>{isshipped ? <Badge className='text-danger' variant='info'> <strong>S</strong></Badge> : <></>}</>;
        }
      },
      {
        Header: 'Equipment Number',
        accessor: 'state.equipment_number',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.equipment_number}</span>
        }
      },
      {
        Header: 'Primary Serial Number',
        accessor: 'state.serial_primary',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.serial_primary}</span>
        }
      },
      {
        Header: 'Equipment Description',
        accessor: 'state.equipment_Desc_name',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.equipment_Desc_name}</span>
        }
      },
      {
        Header: 'Dependent Equipment Description',
        accessor: 'state.dependent_Equipment_name',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.dependent_Equipment_name}</span>
        }
      },
      {
        Header: 'Dependent Serial Number',
        accessor: 'state.serial_dependent_primary',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.serial_dependent_primary}</span>
        }
      },
      {
        Header: 'Position',
        accessor: 'state.position_Package_name',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.position_Package_name}</span>
        }
      },
      {
        Header: 'Test Status',
        accessor: 'state.task_status',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          const className = 'asset';
          const cellValue = controller.state.test_status;
          const statusIcon = cellValue === 'Not Started' ? InspectionTestIcons.NO_ICON : InspectionTestIcons.PASSED;
          const testStatus = cellValue != 'Not Started'
            ? <span>{controller.state.testName} {controller.state.testResult}<div>{controller.state.updatedBy} {DateTimeFormatter.formatDate(controller.state.updatedDate)}</div></span>
            : <span>{controller.state.test_status}</span>;
          //const statusIcon = <i className='far fa-thumbs-up test-status-icon text-success' />;
          const display = <span className={className}> {statusIcon}<span className='test'>{testStatus}</span></span>;
          return display;
        }
      },
      {
        Header: 'Assembly Level',
        accessor: 'state.assemblyLevel',
        disableSortBy: true,
        Cell: cellInfo => {
          const value = cellInfo.value;
          if (value) {
            return <span className=''>{value}</span>
          }
          return <span className='assembly_details'>No Assembly Level Selected</span>
        }
      },
      {
        Header: 'Order',
        accessor: 'state.order',
        disableSortBy: true,
        Cell: cellInfo => {
          const value = cellInfo.value === 0 ? '' : cellInfo.value;
          return <span className=''>{value}</span>

        }
      }
    ],
    [showSAPStatus]
  )



  const assemblySapColumns = useMemo(
    () => [
      {
        Header: "",
        accessor: "statusicon",
        disableSortBy: true,
        Cell: cellInfo => {
          if (!cellInfo.row.original.state.isChild) {
            if (!cellInfo.row.original.state.serviceOrderNumber) {
              return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='far fa-circle' /></span>;
            }

            else if (cellInfo.row.original.state.isWaitingForReapplyServiceLevels) {
              return <span className='status-column asset-active' title='Waiting for Reapply Assembly Levels to complete'><i className='fas fa-clock asset-waiting-reapply-service-levels' /></span>;
            }
            else if (cellInfo.row.original.state.isWaitingForLockForInspection) {
              return <span className='status-column asset-active' title='Waiting for Lock for Assembly request to complete'><i className='fas fa-clock asset-waiting-lock-for-inspection' /></span>;
            }
            else {
              switch (cellInfo.row.original.state.sapStatus) {
                case AppConstants.AssetSAPStatus.CRTD:
                  return <span className='status-column asset-active' title=''><i className='far fa-clock' /></span>;

                case AppConstants.AssetSAPStatus.REL:
                case AppConstants.AssetSAPStatus.PCNF:
                  return <span className='status-column asset-active' title='Assembly can be performed'><i className='fas fa-cogs' /></span>;

                case AppConstants.AssetSAPStatus.TECO:
                case AppConstants.AssetSAPStatus.CLSD:
                case AppConstants.AssetSAPStatus.LKD:
                  return <span className='status-column locked' title='No longer active on this AWO'><i className='fas fa-lock' /></span>;

                default:
                  return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='fas fa-circle' /></span>;
              }
            }
          }
          else {
            return <></>
          }

        },
        isVisible: showSAPStatus
      },
      {
        Header: 'Group',
        accessor: 'state.group_name',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          const isshipped = controller.state.isShipped;
          return <><span> {controller.state.group_name} </span>{isshipped ? <Badge className='text-danger' variant='info'> <strong>S</strong></Badge> : <></>}</>;
        }
      },
      {
        Header: 'Equipment Number',
        accessor: 'state.equipment_number',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          const description = controller.state.equipment_Desc_name;
          const serialNumber = controller.state.serial_primary;
          const SAPStatus = controller.state.sapStatus;
          const ServiceOrder = controller.state.serviceOrderNumber;
          const showParentdetails = controller.state.isChild;
          return <span>
            <div><b>EQ: </b>{controller.state.equipment_number}</div>
            <div><b>SN: </b>{serialNumber}</div>
            {showParentdetails === false && <div><b>SAP Status: </b> {SAPStatus}</div>}
            {showParentdetails === false && <div><b>SAP SO: </b>{ServiceOrder}</div>}
          </span>
        }
      },
      {
        Header: 'Primary Serial Number',
        accessor: 'state.serial_primary',
        disableSortBy: true,
        isVisible: false,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.serial_primary}</span>
        }
      },
      {
        Header: 'Equipment Description',
        accessor: 'state.equipment_Desc_name',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.equipment_Desc_name}</span>
        }
      },
      {
        Header: 'Dependent Equipment Description',
        accessor: 'state.dependent_Equipment_name',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.dependent_Equipment_name}</span>
        }
      },
      {
        Header: 'Dependent Equipment Number',
        accessor: 'state.dependent_serial_name',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.dependent_serial_name}</span>
        }
      },
      {
        Header: 'Position',
        accessor: 'state.position_Package_name',
        disableSortBy: true,
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return <span>{controller.state.position_Package_name}</span>
        }
      },
      {
        Header: 'Test Status',
        accessor: 'state.task_status',
        disableSortBy: true,
        Cell: cellInfo => {
          const controller = cellInfo.row.original;
          const className = 'asset';
          const cellValue = controller.state.test_status;
          const statusIcon = cellValue === 'Not Started' ? InspectionTestIcons.NO_ICON : InspectionTestIcons.PASSED;
          const testStatus = cellValue != 'Not Started'
            ? <span>{controller.state.testName} {controller.state.testResult}<div>{controller.state.updatedBy} {DateTimeFormatter.formatDate(controller.state.updatedDate)}</div></span>
            : <span>{controller.state.test_status}</span>;
          //const statusIcon = <i className='far fa-thumbs-up test-status-icon text-success' />;
          const display = <span className={className}> {statusIcon}<span className='test'>{testStatus}</span></span>;
          return display;
        }
      },
      {
        Header: 'Assembly Level',
        accessor: 'state.assemblyLevel',
        disableSortBy: true,
        Cell: cellInfo => {
          const value = cellInfo.value;
          if (value) {
            return <span className=''>{value}</span>
          }
          return <span className='assembly_details'>No Assembly Level Selected</span>
        }
      },
      {
        Header: 'Order',
        accessor: 'state.order',
        disableSortBy: true,
        Cell: cellInfo => {
          const value = cellInfo.value === 0 ? '' : cellInfo.value;
          return <span className=''>{value}</span>
        }
      }
    ],
    [showSAPStatus]
  )
  return (
    <>
      <div className='row'>
        <div className='col-md-12 m-2'>
          <div className='row'>
            <div className='col-md-8'>
              <div>
                <div className='row ml-1 mr-1'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div id='groups' className='form-group col-12'>
                        <div className='row'>
                          <div className=''><Form.Label name='Groups' prependLabelIcon='fa-filter' /> </div>
                          <div className='ml-3'><GroupBadge onClick={clearAssemblyGroupSelection} variant='danger' pill={false} >Clear Group Selection</GroupBadge></div>
                        </div>
                        <div className='row'>
                          <div className='col-8'>
                            {groups.map(x =>
                              <GroupBadge key={x} variant={!isAssemblyGroupSelected(x) ? 'secondary' : 'danger'} onClick={() => { handleAssemblyGroupClick(x) }} className={'mr-1'} dataToggle="tooltip" dataPlacement="top" title={x}> {x}</GroupBadge>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='' id='inspection-buttons' >
                      <div className='row'>
                        <Button icon='fa-square' variant='success' size='sm' className='m-1' onClick={selectAll}>Select All</Button>
                        <Button icon='far fa-square' variant='danger' size='sm' className='m-1' onClick={selectNone}>Select None</Button>
                      </div>
                    </div>
                    <div className='row'>
                      <div style={{ width: "100%" }}>
                        <Grid showPagination={false} id='results-table' columns={showSAPStatus === true ? assemblySapColumns : assemblyNonSapColumns} data={assemblyData} onRowClick={handleRowClick} allowMultiSelect={true} noDataText='No Assembly Work Orders meet the search criteria defined above' enableDefaultRowSelect={false} getRowProps={getRowProps} onPageChange={handlePageChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='row'>
                <div className='col-md-12'><AssemblyButtons controller={controller} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AssemblyTab;