import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notifications from '../../services/Notification';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import LoginService from '../services/LoginService';
import { isNull } from 'lodash';
import messages from '../../services/Messages';

class LibertyReportPageController extends BaseController {
    constructor() {
        super('liberty-report-page', {
            jobs: [],
            jobCompletionDate: '', //DateTimeFormatter.formatDropdownDate(moment().startOf('day'))
            primarySerialNumber: '',
            searchText : null
        });
        messages.channel('report-generation').action('saved').subscribe(() => {
            this.loadJobs();
          });
      
    }

    loadJobs = (jobnumber = null) => {      
        co(this, function*() {
          this.state.jobs = [];
          var results     = yield ajax.get('lookup/getofflinerequests' , {searchTerm : this.state.searchText ? this.state.searchText : null } );          
          this.state.jobs = results;
          this.commit();
        });
    }

    serialNoSearch = () => {
        co(this, function*() {
          this.state.jobs = [];
          var results     = yield ajax.get('liberty/jobsBySerialNumber', { serialNumber : this.state.primarySerialNumber });
          this.state.jobs = results;
          this.commit();
        });
    }

    clearSearchText = () => {
        this.state.primarySerialNumber = '';
        this.commit();
    }
    clearSearchJob = () => {
        co(this, function*() {
            this.state.searchText = '';
            this.state.jobs = [];
            var results     = yield ajax.get('lookup/getofflinerequests' , {searchTerm : this.state.searchText ? this.state.searchText : null } );            
            this.state.jobs = results;
            this.commit();
          });
       
      }

    handler = (FileName) => {        
        const s = this.state;
        const parameters = {
            jobCompletionDate: s.jobCompletionDate
        };
        co(this, function* () {
            try {
                const results = yield ajax.get('liberty/zipexists', { id : FileName });
                this.commit();
                
                if (results) {
                    const url = `${process.env.REACT_APP_API_SSL}://${process.env.REACT_APP_API_HOST_NAME}${process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : ''}/${process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : ''}${process.env.REACT_APP_API}/liberty/offlinezipdownload?id=${encodeURIComponent(FileName)}`
                    const tempLink = document.createElement('a');
                    tempLink.href = url;
                    tempLink.click();
                    tempLink.remove();
                    
                }
                else {
                    notifications.action('error').post({ msg: 'No Records found', title: 'Error in Report download' });
                }
            }
            catch (err) {
                notifications.action('error').post({ msg: err.message, title: 'Error in downloading offline zip reports' });
            }
        });
    }

    generate = () => {
        const s = this.state;
        const parameters = {
            jobCompletionDate: s.jobCompletionDate
        };
        co(this, function* () {
            try {
                const results = yield ajax.get('liberty/reports', { jobCompletionDate : s.jobCompletionDate });
                this.commit();
                if (results == '') {
                    notifications.action('error').post({ msg: 'No Records found', title: 'Error in Report download' });
                }
                else {
                    const tempLink = document.createElement('a');
                    let file = Utils.convertBase64toBlob(results, 'application/octet-stream');
                    let fileURL = window.URL.createObjectURL(file);
                    tempLink.href = fileURL;
                    tempLink.download = (s.jobCompletionDate + '.zip');
                    tempLink.click();
                    tempLink.remove();
                }
            }
            catch (err) {
                notifications.action('error').post({ msg: err.message, title: 'Error loading Liberty reports' });
            }
        });
    }
    
}

export default LibertyReportPageController;