import React, { useState, useEffect } from 'react';
import '../app/scss/application.scss';
import axios from 'axios';
import store from 'store';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Disposable from '../mixins/Disposable'
import SideNavMenu from '../components/SideNavMenu';
import messages from '../services/Messages';
import notification from '../services/Notification';
import LoginService from '../app/services/LoginService';
import LoginPage from './pages/LoginPage';
import NoSuchRoutePage from './pages/NoSuchRoutePage';
import DropoffPage from './pages/DropoffPage';
import EditDropoffPage from './pages/EditDropoffPage';
import EditJobPage from './pages/EditJobPage';
import FieldAssetManagementPage from './pages/FieldAssetManagementPage';
import ReceivingPage from './pages/ReceivingPage';
import RejectReasonPage from './pages/RejectReasonPage';
import SAPJobProgressPage from './pages/SAPJobProgressPage';
import JobProgressPage from './pages/JobProgressPage';
import InspectionPage from './pages/InspectionPage';
import SerializedAssetSearchPage from './pages/SerializedAssetSearchPage';
import SerializedAssetDetailPage from './pages/SerializedAssetDetailPage';
import ReceivingTicketPage from './pages/ReceivingTicketPage';
import ShippingTicketPage from './pages/ShippingTicketPage';
import EditAssetPage from './pages/EditAssetPage';
import CreateNewAssetPage from './pages/CreateNewAssetPage';
import ShipReceivedAssetsPage from './pages/ShipReceivedAssetsPage';
import JobProgressAssetsPage from './pages/JobProgressAssetsPage';
import InspectionAttachmentPage from './pages/InspectionAttachmentPage';
import JobAttachmentPage from './pages/JobAttachmentPage';
import NotesPage from './pages/NotesPage';
import QuotesDetailPage from './pages/QuotesDetailPage';
import AdhocTransactionsPage from './pages/AdhocTransactionsPage';
import TransferAssetsToJobPage from './pages/TransferAssetsToJobPage';
import ShipAssetsPage from './pages/ShipAssetsPage';
import ReceivingTicketDetailPage from './pages/ReceivingTicketDetailPage';
import ShippingTicketDetailPage from './pages/ShippingTicketDetailPage';
import UsersPage from './pages/UsersPage';
import CustomerPage from './pages/CustomerPage';
import FacilitiesPage from './pages/FacilitiesPage';
import EditUserPage from './pages/EditUserPage';
import EditCustomerPage from './pages/EditCustomerPage';
import CustomerDocumentUploadPage from './pages/CustomerDocumentUploadPage';
import EditFacilityPage from './pages/EditFacilityPage';
import ConfigureCustomerPage from './pages/ConfigureCustomerPage';
import ServiceLevelLinkPage from './pages/ServiceLevelLinkPage';
import BroadcastMessagesPage from './pages/BroadcastMessagesPage';
import ManufacturerDescriptionPage from './pages/ManufacturerDescriptionPage';
import EditManufacturerDescriptionPage from './pages/EditManufacturerDescriptionPage';
import CustomerDescriptionPage from './pages/CustomerDescriptionPage';
import EditCustomerDescriptionPage from './pages/EditCustomerDescriptionPage';
import ListPage from './pages/ListPage';
import BillOfMaterialPage from './pages/BillOfMaterialPage';
import MonitorInterfacePage from './pages/MonitorInterfacePage';
import LaborCodePage from './pages/LaborCodePage';
import ContainerPage from './pages/ContainerPage';
import EditLaborCodePage from './pages/EditLaborCodePage';
import GaugeManagementPage from './pages/GaugeManagementPage';
import AdminQueryPage from './pages/AdminQueryPage';
import ReportsPage from './pages/ReportsPage';
import AddReleaseNotesPage from './pages/AddReleaseNotesPage';
import ReleaseNotesPage from './pages/ReleaseNotesPage';
import ManualPressureTestPage from './pages/ManualPressureTestPage';
import CustomerServiceLevelPage from './pages/CustomerServiceLevelPage';
import CustomerLocationPage from './pages/CustomerLocationPage';
import EditAssetDescriptionPage from './pages/EditAssetDescriptionPage';
import EditFieldAssetPage from './pages/EditFieldAssetPage';
import ScannedQRAssetsPage from './pages/ScannedQRAssetsPage';
import SelectServiceLevelPage from './pages/SelectServiceLevelPage';
import LibertyReportPage from './pages/LibertyReportPage';
import ScrollToTop from '../ScrollToTop';
import AssemblyWorkOrderPage from './pages/AssemblyWorkOrderPage';
import CreateNewAWOPage from './pages/CreateNewAWOPage';
import SelectAssemblyLevelPage from './pages/SelectAssemblyLevelPage';
import AssemblyPage from './pages/AssemblyPage';
import AwoAttachmentPage from './pages/AwoAttachmentPage';
import AssemblyProgressPage from './pages/AssemblyProgressPage'
import EditAwoPage from './pages/EditAwoPage';
import DuplicateAwoPage from './pages/DuplicateAwoPage';
import CustomerAssemblyLevelPage from './pages/CustomerAssemblyLevelPage';
import ShipAwoAssetsPage from './pages/ShipAwoAssetsPage';
import SAPService from '../../src/app/services/SAPService';

//over riding ajax server configuration for edc development environment
if (process.env.REACT_APP_ENV_NAME === 'edcDevelopment') {
    axios.interceptors.request.use(function (config) {
        const location = window.location;
        if (location && location.protocol === 'https:') {
            config.url = config.url.replace('http:', 'https:');
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
}

const AppRouter = Disposable((props) => {
    LoginService.updateLoginInfoFormLocalStore();
    const [loginInfo, setLoginInfo] = useState(LoginService.loginInfo);
    const isAdministrator = LoginService.isAdministrator;
    const isCoordinator = LoginService.isCoordinator;
    const isTechnician = LoginService.isTechnician;
    const isTechnicianWithoutDataClerk = LoginService.isTechnicianWithoutDataClerk;
    const isCustomer = LoginService.isCustomer;
    const isSAPRep = LoginService.isSAPRep;
    const isSuperUser = LoginService.isSuperUser;
    const loginDetail = store.get('InteServLoginInfo');
    const dmsEnabledFacility = loginDetail ? loginDetail.enableDMS : false;
    const defaultRoute = isCustomer ? '/fieldasset' : '/dropoff';
    const sapFacility = SAPService.isSAPFacility();
    // const isLibertycustomer = LoginService.isLibertycustomer;

    useEffect(() => {
        LoginService.autoLogin()
        props.using(
            messages.channel('login').action('loggedin').subscribe(loginInfo => { setLoginInfo(loginInfo) }),
            messages.channel('loginService').action('update').subscribe(() => { setLoginInfo(LoginService.loginInfo) }),
            messages.channel('broadcast-messages').action('send').subscribe(data => {
                if (data) {
                    const message = data.message;
                    if (!message) {
                        return;
                    }
                    notification.action(message.action).post(message);
                }
            })
        );
        return () => {
            props.terminate();
        };
    }, []);

    const links = [
        { to: '/dropoff', icon: 'fas fa-truck fa-flip-horizontal', title: 'Equipment Drop-Off', canShow: ((isSAPRep || isTechnician) && !isCustomer) },
        { to: '/fieldasset', icon: 'fa fa-bullseye', title: 'Iron Management Portal', canShow: (isAdministrator || isCustomer || isSuperUser) },
        { to: '/receiving', icon: 'fa fa-download', title: 'Receive Assets', canShow: isTechnician && !isCustomer },
        { to: '/rejectreason', icon: 'fa fa-thumbs-down', title: 'Reject Reason Mapping', canShow: isTechnician && !isCustomer },
        { to: '/sapjobprogress', icon: 'fa fa-comments', title: 'SAP Job Progress', canShow: isSAPRep && !isCustomer },
        { to: '/gaugemanagement', icon: 'fa fa-tachometer-alt', title: 'Gauge Management', canShow: !isCustomer && dmsEnabledFacility },
        { to: '/jobprogress', icon: 'fa fa-cog', title: 'Job Progress', canShow: ((isCoordinator || isTechnician) && !isCustomer) },
        { to: '/inspection', icon: 'fa fa-cogs', title: 'Inspection', canShow: isTechnician && !isCustomer },
        { to: '/assemblyworkorder', icon: 'fa fa-sharp fa-solid fa-cubes', title: 'Assembly Work Order', canShow: !isCustomer },
        { to: '/assembly', icon: 'fa fa-puzzle-piece', title: 'Assembly' ,canShow: !isCustomer },
        { to: '/containers', icon: 'fab fa-dropbox', title: 'Containers', canShow: isTechnicianWithoutDataClerk && !isCustomer },
        { to: '/assetsearch', icon: 'fa fa-search', title: 'Serialized Asset Search', canShow: (isTechnician || isCustomer || isSAPRep) },
        { to: '/receivingtickets', icon: 'fa fa-ticket-alt fa-flip-both', title: 'Receiving Tickets', canShow: (isTechnician && !isCustomer) },
        { to: '/shippingtickets', icon: 'fa fa-truck', title: 'Shipping Tickets', canShow: ((isSAPRep || isTechnician) && !isCustomer) },
        { to: '/customers', icon: 'fa fa-briefcase', title: 'Customers', canShow: (isAdministrator || isSuperUser) },
        { to: '/facilities', icon: 'far fa-building', title: 'Facilities', canShow: (isAdministrator || isSuperUser) },
        { to: '/users', icon: 'fas fa-users', title: 'User', canShow: (isAdministrator || isSuperUser) },
        { to: '/lists', icon: 'fa fa-th-list', title: 'List Management', canShow: isAdministrator },
        { to: '/manufacturerdescription', icon: 'fa fa-th-large', title: 'Manufacturer Description Management', canShow: (isAdministrator || isSuperUser) },
        { to: '/customerdescription', icon: 'fa fa-th', title: 'Customer Description Management', canShow: (isAdministrator || isSuperUser) },
        { to: '/laborcodes', icon: 'fa fa-anchor', title: 'Labor Code Management', canShow: (isAdministrator || isSuperUser) },
        { to: '/servicelevellinks', icon: 'fa fa-link fa-flip-horizontal', title: 'Service Level Links', canShow: (isAdministrator || isSuperUser) },
        { to: '/messages', icon: 'fa fa-bullhorn', title: 'Broadcast Messages', canShow: (isAdministrator || isSuperUser) },
        { to: '/billsofmaterial', icon: 'fas fa-sort-numeric-down', title: 'BOM Management', canShow: (isAdministrator || isSuperUser) },
        { to: '/adminqueries', icon: 'fa fa-bomb', title: 'Admin Queries', canShow: (isAdministrator || isSuperUser) },
        { to: '/reports', icon: 'far fa-chart-bar', title: 'Report' },
        { to: '/offlinezipstatus', icon: 'fas fa-file-download', title: 'My Report Requests' },
        { to: '/transactions', icon: 'fa fa-binoculars', title: 'Monitor Transactions', canShow: !isCustomer },

    ];

    return (
        loginInfo
            ? <Router>
                <ScrollToTop />
                <SideNavMenu links={links} showToggle={false}>
                    <Switch>
                        <Route exact path='/' ><Redirect to={defaultRoute} /></Route>
                        <Route exact path='/dropoff' component={DropoffPage} />
                        <Route path='/dropoff/:dropoffId' component={EditDropoffPage} />
                        <Route exact path='/fieldasset' component={FieldAssetManagementPage} />
                        <Route path='/fieldasset/:assetId' component={FieldAssetManagementPage} />
                        <Route path='/editfieldasset/:assetId' component={EditFieldAssetPage} />
                        <Route exact path='/receiving' component={ReceivingPage} />
                        <Route path='/receiving/:assetId' component={ReceivingPage} />
                        <Route path='/rejectreason' component={RejectReasonPage} />
                        <Route path='/releasenotes' component={ReleaseNotesPage} />
                        <Route exact path='/addreleasenotes' component={AddReleaseNotesPage} />
                        <Route path='/addreleasenotes/:rnId' component={AddReleaseNotesPage} />
                        <Route path='/createasset/:searchText' component={CreateNewAssetPage} />
                        <Route path='/createawo/:searchText' component={CreateNewAWOPage} />
                        <Route exact path='/asset/:assetId' component={EditAssetPage} />
                        <Route path='/asset/:assetId/:prevLocation' component={EditAssetPage} />
                        <Route exact path='/inspection' component={InspectionPage} />
                        <Route path='/inspection/:jobId' component={InspectionPage} />
                        <Route exact path='/jobprogress' component={JobProgressPage} />
                        <Route path='/jobprogress/:assetId' component={EditAssetDescriptionPage} />
                        <Route path='/job/:jobId' component={EditJobPage} />
                        <Route exact path='/jobprogressassets/:jobId' component={JobProgressAssetsPage} />
                        <Route path='/jobprogressassets/:jobId/:serial' component={JobProgressAssetsPage} />
                        <Route path='/shipassets/:jobId' component={ShipAssetsPage} />
                        <Route path='/adhoctransactions/:jobId' component={AdhocTransactionsPage} />
                        <Route path='/transferassets/:jobId' component={TransferAssetsToJobPage} />
                        <Route path='/selectservicelevels/:jobId' component={SelectServiceLevelPage} />
                        <Route path='/sapjobprogress' component={SAPJobProgressPage} />
                        <Route exact path='/users' component={UsersPage} />
                        <Route path='/users/:userId' component={EditUserPage} />
                        <Route exact path='/customers' component={CustomerPage} />
                        <Route exact path='/customers/:customerId' component={EditCustomerPage} />
                        <Route path='/customers/documents/:customerId/:uploadType/:fromPage' component={CustomerDocumentUploadPage} />
                        <Route path='/configurecustomer/:customerId' component={ConfigureCustomerPage} />
                        <Route path='/customerlocations/:customerLocationId' component={CustomerLocationPage} />
                        <Route path='/customerservicelevel/:customerServiceLevelId' component={CustomerServiceLevelPage} />
                        <Route exact path='/facilities' component={FacilitiesPage} />
                        <Route path='/facilities/:facilityId' component={EditFacilityPage} />
                        <Route exact path='/manufacturerdescription' component={ManufacturerDescriptionPage} />
                        <Route path='/manufacturerdescription/:manDescId' component={EditManufacturerDescriptionPage} />
                        <Route exact path='/customerdescription' component={CustomerDescriptionPage} />
                        <Route path='/customerdescription/:custDescId' component={EditCustomerDescriptionPage} />
                        <Route path='/lists' component={ListPage} />
                        <Route exact path='/laborcodes' component={LaborCodePage} />
                        <Route exact path='/offlinezipstatus' component={LibertyReportPage} />
                        <Route path='/laborcodes/:laborCodeId' component={EditLaborCodePage} />
                        <Route path='/servicelevellinks' component={ServiceLevelLinkPage} />
                        <Route path='/billsofmaterial' component={BillOfMaterialPage} />
                        <Route path='/notes/:notetype/:id' component={NotesPage} />
                        <Route exact path='/adminqueries' component={AdminQueryPage} />
                        <Route exact path='/containers' component={ContainerPage} />
                        <Route exact path='/assetsearch' component={SerializedAssetSearchPage} />
                        <Route path='/assetdetail/:assetId' component={SerializedAssetDetailPage} />
                        <Route path='/messages' component={BroadcastMessagesPage} />
                        <Route exact path='/shippingtickets' component={ShippingTicketPage} />
                        <Route path='/shippingtickets/:ticketId' component={ShippingTicketDetailPage} />
                        <Route path='/shipreceivedassets' component={ShipReceivedAssetsPage} />
                        <Route path='/manualpressuretests' component={ManualPressureTestPage} />
                        <Route path='/reports' component={ReportsPage} />
                        <Route path='/jobattachments/:jobId/:jobNumber' component={JobAttachmentPage} />
                        <Route exact path='/receivingtickets' component={ReceivingTicketPage} />
                        <Route path='/receivingtickets/:ticketId' component={ReceivingTicketDetailPage} />
                        <Route path='/transactions' component={MonitorInterfacePage} />
                        <Route path='/quotesdetail/:jobNumber/:jobId' component={QuotesDetailPage} />
                        <Route path='/inspectionattachments/:assetId/:assetSerialNumber/:equipmentNumber' component={InspectionAttachmentPage} />
                        <Route path='/gaugemanagement' component={GaugeManagementPage} />
                        <Route exact path='/scannedqrasset' component={ScannedQRAssetsPage} />
                        <Route path='/scannedqrasset/:assetId' component={ScannedQRAssetsPage} />
                        <Route exact path='/assemblyworkorder' component={AssemblyWorkOrderPage} />
                        <Route path='/selectAssemblyLevels/:awoId' component={SelectAssemblyLevelPage} />
                        <Route exact path='/assembly' component={AssemblyPage} />
                        <Route path='/awoattachments/:awoId' component={AwoAttachmentPage} />
                        <Route exact path='/assemblyProgress/:assemblyId' component={AssemblyProgressPage} />
                        <Route path='/assemblyProgress/:assemblyId/:serial' component={AssemblyProgressPage} />
                        <Route path='/editawo/:awoId' component={EditAwoPage} />
                        <Route path='/duplicateawo/:awoId' component={DuplicateAwoPage} />
                        <Route path='/customerAssemblylevel/:customerAssemblyLevelId' component={CustomerAssemblyLevelPage} />
                        <Route path='/shipsawoassets/:awoId' component={ShipAwoAssetsPage}/>
                        <Route path='/assembly/:awoId' component={AssemblyPage} />
                        <Route component={NoSuchRoutePage} />
                    </Switch>
                </SideNavMenu>
            </Router>
            : <LoginPage />
    );
});

export default AppRouter;
