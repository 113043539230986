import React from 'react';
import $, { trim } from 'jquery';
import moment from 'moment';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';
import Dialog from '../../components/Dialog';
import GaugeAssetScrapDialog from '../dialogs/GaugeAssetsScrapDialog';
import GaugeAssetScrapController from '../controllers/GaugeAssetScrapController';
import Validator from '../../lib/Validator';
import { result } from 'lodash';
import { tickStep } from 'd3';
// import DailyVerificationRowController from '../controllers/DailyVerificationRowController';
import GaugeManagementDailyVerificationRowController from '../controllers/GaugeManagementDailyVerificationRowController';
import GaugeManagementYearlyCalibrationRowController from '../controllers/GaugeManagementYearlyCalibrationRowController';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
import FacilityGaugeRowController from './FacilityGaugeRowController';


class GaugeManagementController extends BaseController {
  constructor() {
    super('gauge-management-controller', {
      currentAsset: '',
      searchText: '',
      expirationDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      certificateNumber: '',
      certificateImage: {},
      dailyVerificationData: [],
      calibrationVisibility: '',
      yearlyCalibrationHistory: [],
      dailyCalibrationHistory: [],
      dailyCalHistoryHeader: [],
      IsInProgress: false,
      saveInProgress: false,
      searchInProgress: false,
      yearCalActiveKey: 3,
      listItem_GUID: '',
      isCertificate: false,
      isSticker: false,
      vendorName: '',
      expiryDate: '',
      acknowledgement: false,
      previewUrl: '',
      calibration: '',
      calibrationId: '',
      showValidation: false,
      errors: {},
      allowManualOverride: false,
      restrictedExtensions: ['.avi', '.wmv', '.flv', '.mpg', '.mp4', '.3gp', '.mp3', '.gif', '.exe'],
      assetServiceHistory: '',
      detailViewVisibility: true,
      facilityGaugeVisibility: false,
      serialGuid: null,
      activeKey: 1,
      facilityGaugeViewData: [],
      facilityGaugesList: [],
      searchFacilityGaugeText:'',
      enteredSearchText:'',
    });

    this._validator = new Validator();
    this._validator.add('listItem_GUID', 'Choose Calibration', [{ required: true }]);
    this._validator.add('expirationDate', 'Expiration Date', [{ required: true }]);
    messages.channel('gauges').action('updated').subscribe((data) => {
      this.updateAssetStatus(data);
    });
  }

  updateAssetStatus = (data) => {
    if (this.state.currentAsset.assetId == data.assetId) {
      // this.state.currentAsset.assetStatus = 'Scrapped';
      // this.state.currentAsset.eAssetStatus = 2;
      // this.state.calibrationVisibility = '';
      this.findAsset(null, false);
      //this.commit();
    }
  }

  clearResults = () => {
    this.state.currentAsset = '';
    this.commit();
  }

  load = (assetId) => {
    this.loadAsset(assetId);
  }

  loadAsset = (assetId) => {
    this.loadFromStore();
    if (assetId) {
      ajax.get('lookup/getAsset/', { assetId: assetId }).then(result => {
        this.state.serviceHistory = [];
        if (result !== null) {
          this.state.currentAsset = result;
          this.state.searchText = result.primarySerialNumber;
          this.commit();
        }
      }); // ReceivedAssetDto
    }
  }

  uploadFile = (res) => {
    if (this.state.currentAsset.isUTScope !== true) {
      const fileType = res.file.name.split('.')[1].toLowerCase() || '';
      if (this.state.restrictedExtensions.filter(x => x === "." + fileType).length > 0) {
        notification.action('error').post({ msg: 'Invalid file type. .' + fileType + ' is an unrecognized file type to upload', title: 'File Error' });
        return false;
      }
    }

    if (res && (res.file.size / 1048576).toFixed(1) < 10) {
      this.state.certificateImage = {
        fileName: res.file.name,
        size: res.file.size,
        altText: '',
        caption: '',
        file: res.file,
        fileType: res.file.name.split('.')[1].toLowerCase() || '',
        MimeType: res.file.type,
        imgUrl: res.imageUrl,
        type: res.type,
        recordStatus: 'A'
      };
      this.setPreviewImageLink(URL.createObjectURL(res.file));
      this.commit();
    }
    else {
      notification.action('error').post({ msg: 'Please upload file less than 10MB size', title: 'File Error' });
    }
  }

  setPreviewImageLink = (url) => {
    this.state.previewUrl = url;
  }

  SaveCroppedImage = (res, croppedImageLink) => {
    this.state.certificateImage.imgUrl = res;
    this.setPreviewImageLink(croppedImageLink);
    this.commit();
    return true;
  }

  findAsset = (callback, showmessage = true) => {
    const searchText = this.state.searchText;
    if (!searchText) {
      return;
    }

    this.state.currentAsset = '';
    this.state.serviceHistory = [];
    this.state.yearlyCalibrationHistory = [];
    this.state.calibrationVisibility = '';
    this.state.searchText = searchText;
    ajax.get('lookup/searchAssetForGauge', { serialNumber: searchText }).then(result => {
      this.state.currentAsset = result;
      this.state.searchText = result.primarySerialNumber;
      if (showmessage) {
        notification.action('info').post({ msg: 'Found asset with serial number ' + result.primarySerialNumber + ': ' + result.description.name, title: 'Asset Found' });
      }
      this.commit();
      if (callback) {
        callback();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // ReceivedAssetDto
  }

  clearSearchResults = () => {
    this.clearResults();
    this.state.currentAsset = '';
    this.state.searchText = '';
    this.commit();
  }
  canProcess = (asset) => {
    return (asset && asset.isReceivable);
  }
  toggleQrVisiblity = () => {
    this.state.qrCanvasVisibility = !this.state.qrCanvasVisibility;
    this.commit();
  }
  enableDailyVerification = () => {
    this.state.calibrationVisibility = 'DV';
    this.tabShow('1');
    this.commit();
  }



  toggleDailyVerificationVisiblity = () => {
    const s = this.state;
    this.state.searchInProgress = true;
    this.commit();
    ajax.get('lookup/getDailyCalibration', { serialPrimary: this.state.currentAsset.primarySerialNumber, serialId: this.state.currentAsset.assetId, facilityId: LoginService.loginInfo.facilityId }).then(result => {
      this.state.searchInProgress = false;
      s.dailyVerificationData = [];
      result.forEach(data => {
        s.dailyVerificationData.push(new GaugeManagementDailyVerificationRowController(this, data));
      });
      this.manualOverrideSecuritySettings();
      this.commit();
    }).catch(err => {
      this.state.searchInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error retriving Daily Calibration' });
    });
  }

  toggleEdityearlyVerificationVisiblity = (calibrationId) => {
    const s = this.state;
    this.state.yearCalActiveKey = 3;
    this.state.calibrationVisibility = 'YC';
    this.state.searchInProgress = true;
    this.commit();

    ajax.get('lookup/GetYearlyCalibrationById', { calibrationId: calibrationId }).then(result => {
      this.state.searchInProgress = false;

      this.state.certificateImage = {
        fileName: result.fileName,
        imgUrl: result.imgUrl,
        type: result.type,
        recordStatus: 'A'
      };
      this.state.calibrationId = result.calibrationId;
      this.state.listItem_GUID = result.calibrationId;
      this.state.expirationDate = DateTimeFormatter.formatDropdownDate(result.expirationDate);
      this.state.certificateNumber = result.certificateNumber;
      this.state.isSticker = result.isSticker;
      this.state.isCertificate = result.isCertificate;
      this.state.vendorName = result.vendorName;
      this.state.acknowledgement = result.acknowledgement;
      this.state.calibration = result.calibration;
      this.commit();
    }).catch(err => {
      this.state.searchInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error retriving Daily Calibration' });
    });
  }

  manualOverrideSecuritySettings = () => {
    //get Manual override security setting for logged-in user
    ajax.get('lookup/getManualOverrideSetting', null).then(result => {
      this.state.searchInProgress = false;
      this.state.allowManualOverride = result;
      this.commit();
    }).catch(err => {
      this.state.searchInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error retriving daily verification' });
    });
    this.commit();
  }

  updateDailyVerificationData = (dmsGoData, scopeSerial) => {
    if (dmsGoData.length > 0) {
      dmsGoData.forEach(data => {
        this.state.dailyVerificationData.forEach(dailyData => {
          if (data.ReadingID == dailyData.state.ReadingID && data.Serial == dailyData.state.Serial) {
            dailyData.state.ReadingValue = data.Current_Reading;
            dailyData.state.RecordStatus = AppConstants.RecordStatus.Unchanged;
            if (scopeSerial == '')
              dailyData.state.ScopeSerial = this.state.currentAsset.primarySerialNumber;
            else
              dailyData.state.ScopeSerial = scopeSerial;
          }
        })
      });
      this.commit();
      // this.saveUpdatedDailyVerificationData();
    }
  }

  handleManualOverride = () => {
    this.state.dailyVerificationData.map(function (data) { return data.EnableManualOverride = true });
    this.commit();
  }

  saveUpdatedDailyVerificationData = () => {
    const updateCalibrationDate = this.getReadingsNotInRange() == 0;
    const dailyVerificationData = [];
    this.state.dailyVerificationData.forEach(data => {
      dailyVerificationData.push(data.state);
    });
    const parameters = {
      updateCalibrationDate: updateCalibrationDate,
      dailyVerificationData: dailyVerificationData
    }
    ajax.post('gauge/saveDailyCalibration', parameters).then(result => {
      if (result && updateCalibrationDate) {
        var websocket = new WebSocket("ws://localhost:8068");
        websocket.onopen = function (evt) {
          var param = {
            Type: "Delete",
          };
          websocket.send(JSON.stringify(param))
        };
        websocket.onmessage = function (evt) {
          var responsedata = JSON.parse(evt.data);
          if (responsedata.ResponseType == "S") {
            notification.action('success').post(responsedata.ResponseMessage);
          }
          else if (responsedata.ResponseType == "E") {
            notification.action('error').post(responsedata.ResponseMessage);
          }
        };
        websocket.onerror = function (evt) {
          notification.action('error').post(evt.data);
        };
        this.state.currentAsset.lastCalibrationDate = moment(result).utc;
        this.state.currentAsset.expired = false;
        this.commit();
      }
      this.toggleDailyVerificationVisiblity();
      notification.action('success').post('Daily Calibration updated successfully');
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Daily verification update error' });
    });
  }

  toggleYearlyCalibrationHistoryVisiblity = () => {
    this.state.yearCalActiveKey = 4;
    this.state.searchInProgress = true;
    this.state.showValidation = false;
    this.clearYearlyCalibration();
    this.state.yearlyCalibrationHistory = [];
    this.commit();

    ajax.get('lookup/getYearlyCalibrationHistory', { serialId: this.state.currentAsset.assetId, isUTScope: this.state.currentAsset.isUTScope }).then(calibrationHistory => {
      if (calibrationHistory) {
        calibrationHistory.forEach(data => {
          this.state.yearlyCalibrationHistory.push(new GaugeManagementYearlyCalibrationRowController(this, data));
        });
        //  this.state.yearlyCalibrationHistory = calibrationHistory;
      }
      this.commit();
    }).catch(err => {
      this.state.searchInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error retriving yearly calibration history' });
    });

    //ServiceHistory
    ajax.get('lookup/getAssetServceHistory', { serialId: this.state.currentAsset.assetId }).then(serviceHistory => {
      this.state.searchInProgress = false;
      if (serviceHistory) {

        this.state.assetServiceHistory = serviceHistory;
        this.collapseAllServiceLevels();
        //  this.state.yearlyCalibrationHistory = calibrationHistory;
      }
      this.commit();
    }).catch(err => {
      this.state.searchInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error retriving yearly calibration history' });
    });
  }

  collapseAllServiceLevels() {
    this.state.assetServiceHistory.serviceHistory.forEach(sh => sh.performedServiceLevels.forEach(sl => sl.collapsed = true));
  }

  toggleDailyCalibrationHistoryVisiblity = () => {
    this.state.searchInProgress = true;
    this.commit();
    ajax.get('lookup/getDailyCalibrationHistory', { serialId: this.state.currentAsset.assetId }).then(dailyHistory => {
      this.state.searchInProgress = false;
      if (dailyHistory) {
        this.state.dailyCalibrationHistory = dailyHistory;
        var checkArray = [];
        this.state.dailyCalHistoryHeader = [];
        this.state.dailyCalibrationHistory.forEach(row => {
          if (!checkArray.includes(row.DailyCalId)) {
            checkArray.push(row.DailyCalId);
            this.state.dailyCalHistoryHeader.push({ DailyCalId: row.DailyCalId, updatedBy: row.updatedBy, dateAdded: row.CalibrationDate, collapsed: true });
          }
        });
      }
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error retriving yearly calibration history' });
      return false;
    });
  }

  toggleCollapsed = (calHeader) => {
    calHeader.collapsed = !calHeader.collapsed;
    this.commit();
  }

  clearYearlyCalibration = () => {
    this.state.calibrationId = '';
    this.state.certificateImage = '';
    this.state.expirationDate = '';
    this.state.certificateNumber = '';
    this.state.isSticker = false;
    this.state.isCertificate = false;
    this.state.vendorName = '';
    this.state.acknowledgement = false;
    this.state.calibration = '';
    this.state.previewUrl = '';
    this.commit();
  }

  enableYearlyCalibration = () => {
    this.state.yearCalActiveKey = 3;
    this.state.listItem_GUID = '';
    this.state.calibrationVisibility = 'YC';

    this.clearYearlyCalibration()
    //get most recent Expiry date from yearly Calibration History
    this.state.searchInProgress = true;
    this.commit();
    if (this.state.currentAsset.isUTScope === false && this.state.currentAsset.eAssetStatus === AppConstants.eAssetStatus.Scrapped) {
      this.toggleYearlyCalibrationHistoryVisiblity();
    } else {
      this.getYearlyCalibrationExpiryDate();
    }
  }

  getYearlyCalibrationExpiryDate = () => {
    ajax.get('lookup/getYearlyCalibrationExpiryDate', { serialId: this.state.currentAsset.assetId }).then(result => {
      this.state.searchInProgress = false;
      this.state.expirationDate = DateTimeFormatter.formatDropdownDate(result);
      this.commit();
    }).catch(err => {
      this.state.searchInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error retriving Daily Calibration' });
    });
  }

  removeYearlyCalibrationFile = () => {
    this.state.certificateImage = {};
    this.commit();
  }

  removeUpdateYearlyCalibrationFile = () => {
    this.state.certificateImage = '';
    this.state.previewUrl = '';
    this.commit();
  }

  handleYearlyCalibrationSave = () => {
    if (!this.canSave()) {
      return false;
    }
    const parameters = {
      serialId: this.state.currentAsset.assetId,
      certificateNumber: this.state.certificateNumber,
      expirationDate: this.state.expirationDate,
      certificateImgURL: this.state.certificateImage,
      isCertificate: this.state.isCertificate,
      isSticker: this.state.isSticker,
      vendorName: this.state.vendorName,
      listItem_GUID: this.state.listItem_GUID.id,
      acknowledgement: this.state.acknowledgement,
      calibrationId: this.state.calibrationId,
      isUTScope: this.state.currentAsset.isUTScope
    }

    this.state.saveInProgress = true;
    this.commit();
    ajax.post('gauge/saveYearlyCalibration', parameters).then(() => {
      notification.action('success').post('Calibration saved successfully');
      this.state.certificateNumber = '';
      this.state.certificateImage = {};
      this.state.expirationDate = DateTimeFormatter.formatDate(DateTimeFormatter.today());
      this.tabShow('4');
      this.state.saveInProgress = false;
      this.commit();
    }).catch(err => {
      this.state.saveInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error Yearly Calibration' });
    });
  }

  CancelUpdate = () => {
    this.tabShow('4');
    this.commit();
  }

  canSave = () => {
    if (this.state.currentAsset.isUTScope === true) {
      return true;
    }
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (this.state.isSticker === false && this.state.isCertificate === false) {
      this.state.showValidation = true;
      this.commit();
      return false;
    }
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  tabShow = (tabKey) => {
    switch (tabKey) {
      case '1':
        this.toggleDailyVerificationVisiblity();
        break;
      case '2':
        this.toggleDailyCalibrationHistoryVisiblity();
        break;
      case '3':
        this.state.yearCalActiveKey = 3;
        this.getYearlyCalibrationExpiryDate();
        this.commit();
        break;
      case '4':
        this.toggleYearlyCalibrationHistoryVisiblity();
        break;
      default:
    }
    this.commit();
  }

  scrapGaugeAsset = () => {
    const asset = this.state.currentAsset;
    const dailyCalId = this.state.dailyVerificationData[0].DailyCalId;
    const header = 'Scrap Gauge Asset';
    Dialog.showDialog(<GaugeAssetScrapDialog controller={new GaugeAssetScrapController(asset, dailyCalId)} header={header} />);
  }

  scrapOtherGaugeAsset = () => {
    const message = <div>
      <h6>Please confirm you wish to scrap this asset</h6>
    </div>;
    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={400} height={200} header='Scrap Asset'>
          {message}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      const asset = this.state.currentAsset;
      const parameters = {
        assetId: asset.assetId,
      };
      return ajax.post('gauge/ScrapOtherGaugeAsset', parameters).then(result => {
        if (result) {
          messages.channel('gauges').action('updated').post({ assetId: asset.assetId });
          notification.action('success').post(`Asset scrapped successfully on ${DateTimeFormatter.formatDate(DateTimeFormatter.today())}`);
        }
        return true;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
      });
    })
  }

  getReadingsNotInRange = () => {
    let readingNotInRange = [];
    const readingRangeDiff = AppConstants.readingDifferenceRange;
    const lastReadingId = this.state.dailyVerificationData.length;
    this.state.dailyVerificationData.forEach(row => {
      if (row.state.ReadingID == lastReadingId) {
        if (row.state.ReadingValue && (row.state.ReadingValue < row.state.NewDimension - readingRangeDiff || row.state.ReadingValue > row.state.NewDimension + readingRangeDiff)) {
          readingNotInRange.push(row.state.ReadingID);
        }
      }
      else {
        if (row.state.ReadingValue < row.state.NewDimension - readingRangeDiff || row.state.ReadingValue > row.state.NewDimension + readingRangeDiff) {
          readingNotInRange.push(row.state.ReadingID);
        }
      }
    });
    return readingNotInRange.length;
  }


  handleCertificateCheckBoxClick = () => {
    if (this.state.isCertificate === false && this.state.isSticker === true) {
      this.state.isSticker = false;
      this.state.acknowledgement = false;
    }
    this.state.isCertificate = !this.state.isCertificate;
    this.commit();
  }

  handleStickerCheckBoxClick = () => {
    if (this.state.isCertificate === true && this.state.isSticker === false) {
      this.state.isCertificate = false;
    }
    this.state.isSticker = !this.state.isSticker;
    this.commit();
  }

  handlePrint = (calibrationIds) => {
    const parameters = {
      calibrationIds: calibrationIds,
      serialId: this.state.currentAsset.assetId,
    };
    this.saving = true;
    this.commit();
    return ajax.post('gauge/GenerateGaugeDocsPdf', parameters).then(results => {
      var docType = null;
      if (results.includes('data:application/pdf')) { docType = 'application/pdf'; }
      else if (results.includes('data:image/png')) { docType = 'image/png'; }
      else if (results.includes('data:image/jpeg')) { docType = 'image/jpeg'; }
      else if (results.includes('data:image/jpg')) { docType = 'image/jpg'; }
      else if (results.includes('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document')) { docType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; }
      else if (results.includes('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) { docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
      var imageUrl = results.split(/base64,/)[1];
      this.saving = false;
      this.commit();
      if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/Chrome/i) != null) {
        const blob = Utils.convertBase64toBlob(imageUrl, docType);
        const fileName = this.state.currentAsset.serialPrimary + '.pdf';
        const fileURL = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.download = fileName;
        tempLink.click();
        tempLink.remove();
      } else {
        let file = Utils.convertBase64toBlob(imageUrl, docType);
        let fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
      }
      return true
        ;
    }).catch(err => {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });

  }

  toggleCollapsed(performedServiceLevel) {
    performedServiceLevel.collapsed = !performedServiceLevel.collapsed;
    this.commit();
  }

  deleteGaugeHistoryRecord = (calibrationId) => {
    const message = <div>
      <h4>Please confirm you wish to delete this Gauge History Record</h4>
      <h4>All associated data be deleted.</h4>
    </div>;

    co(this, function* () {
      const result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={300} header='Confirm Delete Customer Description'>
          {message}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }
      yield ajax.post('gauge/deleteGaugeHistoryRecord', calibrationId); // in: Guid, out: void
      this.commit();
      this.toggleYearlyCalibrationHistoryVisiblity();
    });
  }


  getDailyVerificationData = () => {
    let readingsArray = [];
    this.state.dailyVerificationData.forEach(dailyData => {
      readingsArray.push({
        DailyCalId: dailyData.state.DailyCalId,
        Serial: dailyData.state.Serial,
        Description: dailyData.state.Description,
        Container: dailyData.state.Container,
        MinimumDimension: dailyData.state.MinimumDimension,
        ReadingID: dailyData.state.ReadingID,
        Previous_Reading: dailyData.state.Previous_Reading,
        Current_Reading: dailyData.state.Current_Reading,
        NewDimension: dailyData.state.NewDimension,
        ReadingType: dailyData.state.ReadingType,
        ReadingValue: dailyData.state.ReadingValue,
        ScopeSerial: dailyData.state.ScopeSerial,
        RecordStatus: dailyData.state.RecordStatus
      });
    });
    return readingsArray;
  }
  clearDataForDetailView = () => {
    this.state.facilityGaugeVisibility = false;
    this.state.currentAsset = '';
    this.state.serviceHistory = [];
    this.state.yearlyCalibrationHistory = [];
    this.state.calibrationVisibility = '';
    this.state.facilityGaugesList = [];
    this.state.searchText = '';
    this.state.searchFacilityGaugeText='';
    this.state.enteredSearchText='';
    this.commit();
  }

  toggleDetailView = (serialGuid) => {
    this.clearDataForDetailView();
    this.state.detailViewVisibility = true;
    this.state.activeKey = 1;
    this.commit();
  }

  toggelDetailViewWithSerialGuid = (serialGuid) => {

    if (!serialGuid) {
      return;
    }
    this.clearDataForDetailView();
    this.state.currentAsset = '';
    this.state.serviceHistory = [];
    this.state.yearlyCalibrationHistory = [];
    this.state.calibrationVisibility = '';

    ajax.get('lookup/searchAssetForFacilityGauge', { serialGuid: serialGuid }).then(result => {
      this.state.currentAsset = result;
      this.state.searchText = result.primarySerialNumber;
      this.state.activeKey = 1;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  toggleFacilityGaugeListView = () => {
    this.state.facilityGaugeVisibility = true;
    this.state.detailViewVisibility = false;
    this.state.activeKey = 2;
    this.getFacilityGauges();
    this.commit();
  }

  mainTabShow = (tabKey) => {
    switch (tabKey) {
      case '1':
        this.toggleDetailView(this.state.serialGuid);
        break;
      case '2':
        this.toggleFacilityGaugeListView();
        break;
      default:
    }
    this.commit();
  }

  getFacilityGauges = () => {
    const s = this.state;
    this.state.searchInProgress = true;
    s.facilityGaugeViewData = [];
    s.facilityGaugesList = [];
    const searchtext= this.state.enteredSearchText;
    this.commit();
    ajax.get('lookup/getfacilityGauges', { facilityId: LoginService.loginInfo.facilityId , searchText: searchtext}).then(result => {
      this.state.searchInProgress = false;
      s.facilityGaugeViewData = result;
      const list = result.FacilityGaugeList;
      s.facilityGaugesList = [];
      if (list) {
        list.forEach(data => {
          s.facilityGaugesList.push(new FacilityGaugeRowController(this, data));
        });
        //  this.state.yearlyCalibrationHistory = calibrationHistory;
      }
      this.commit();
    }).catch(err => {
      this.state.searchInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error retriving Facility Gauge List' });
    });
  }

  getByteData = (exportType) => {
    var parameters = {
      facilityGuid: LoginService.loginInfo.facilityId,
      searchText: this.state.enteredSearchText
    };
    if (exportType === 'excel') {
      ajax.post('lookup/exportFacilityGaugesAsExcel', parameters).then(excelData => {
        this.export(exportType, excelData);
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    } else {
      ajax.post('lookup/exportFacilityGaugesAsPDF', parameters).then(excelData => {
        this.export(exportType, excelData);
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
  }

  export = (exportType, excelData) => {
    co(this, function* () {
      try {
        const s = this.state;
        if (exportType === 'excel') {
          const blob = Utils.convertBase64toBlob(excelData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          const fileName = 'GaugesList.xlsx';
          const fileURL = URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.href = fileURL;
          tempLink.download = fileName;
          //   if (navigator.userAgent.match(/iPad/i) != null) {
          //     window.open(fileURL);
          //   }
          //   else {
          tempLink.click();
          //  }
          tempLink.remove();
        }else {
          const blob = Utils.convertBase64toBlob(excelData, 'application/pdf');
          const fileName = 'GaugesList.pdf';
          const fileURL = URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.href = fileURL;
          tempLink.download = fileName;
          //   if (navigator.userAgent.match(/iPad/i) != null) {
          //     window.open(fileURL);
          //   }
          //   else {
          tempLink.click();
          //  }
          tempLink.remove();
        }
        notification.action('success').post('Exported successfully');
      }
      catch (err) {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }
    });
  }

  clearFacilitySearchText = () => {
    this.state.searchFacilityGaugeText = '';
    this.state.enteredSearchText = '';
    this.commit();
  }

  searchGauges = () => {
   const facilityId = (LoginService.loginInfo && LoginService.loginInfo.facilityId);
   const searchText = this.state.searchFacilityGaugeText;
   this.state.enteredSearchText=searchText;
   this.commit();
    if (!facilityId) {
      return;
    }
    this.getFacilityGauges();
  }
}



export default GaugeManagementController;
