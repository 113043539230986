import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';

class PickPrototypeServiceLevelsController extends BaseController {
  constructor(customer) {
    super('pick-prototype-service-levels', {
      customer      : customer,
      serviceLevels : []
    });
    this.loadPrototypeServiceLevels();
  }

  loadPrototypeServiceLevels = () => {
      ajax.get('lookup/getPrototypeServiceLevels', {searchTerm: ''}).then(results=>{
        this.state.serviceLevels = results;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<ServiceLevelLookupDto>
  }

  toggleServiceLevelSelection = (serviceLevel) => {
    serviceLevel.selected = !serviceLevel.selected;
    this.commit();
  }

  save = () => {
      const parameters = {
        customerId               : this.state.customer.customerId,
        customerName             : this.state.customer.customerName,
        prototypeServiceLevelIds : this.state.serviceLevels.filter(sl => sl.selected).map(sl => sl.id)
      };

      if (!parameters.prototypeServiceLevelIds.length) {
        return true;
      }

      return ajax.post('serviceLevel/createFromPrototypes', parameters).then(()=>{
        notification.action('info').post({msg: 'Created service levels for ' + parameters.customerName});
        messages.channel('edit-customer-service-level').action('saved').post();
        return true;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // ReapplyServiceLevelsDto
  }
}

export default PickPrototypeServiceLevelsController;