import React from 'react';
import Form from '../../components/Form';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import SignaturePad from '../../components/SignaturePad';
import Searchers from '../utility/Searchers';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Utils from '../utility/Utils';

const shippingDetails = (props) => {
  
  const handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  const handleKeyUp = (evt) => {
    try {
      const value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }
      if (value > 100000) {
        evt.target.value = 100000;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }

  const parentPage=props?.parentPage
  const binder = props.binder;
  const controller = props.controller;
  const data = controller.state;
  const customerShipLocation = data.customerShipLocation;
  const className = props.receiveOrShip ? '' : 'required';
  const customerId = data.customerId;
  const hideAWONumber = window.location.href.toLocaleLowerCase().includes('containers');  
  const isAWOSelected = ( props.canTransfertoAWO && data?.awoId && data?.awoId?.id != Utils.emptyGuid);
  const shipLocationLabel = <span>{'Ship To'}</span>;
  /*<span className='bold-label'>{customerName}</span>
      at <span className='bold-label'>{facilityName}</span>*/
  const shipContainerLabel = !!customerShipLocation
    ? <span>Ship Containers for <span className='bold-label'>
      {customerShipLocation.name}</span></span>
    : 'Ship Container';
  const LoggedInuser = LoginService.loginInfo.fullName;
  // const fldHours = (SAPService.isSAPFacility() && SAPService.useTTS() && data.jobId) ? <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} /> : null;
  const fldMinutes = (SAPService.isSAPFacility() && SAPService.useTTS() && (data.jobId || data.awoId )) ? <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} /> : null;
  const fldTechnician = (SAPService.isSAPFacility() && SAPService.useTTS() && (data.jobId || data.awoId )) ? <Form.StaticData label='Record Shipping Time for User' value={LoggedInuser} /> : null;
  const formatResult = item => {
    let spanError = null;
    if (SAPService.isSAPFacility() && item.result.error) {
      spanError = <span className='user-facility-sap float-right select2-tag'> {item.result.error}</span>;
    }
    return <span>{item.text} {spanError}</span>;
  };
  const formatContainerResult = item => {
    let functionalLocation = null;
    if (item.result.functionalLocation) {
      functionalLocation = <span className='user-facility-sap float-right select2-tag' style={{ fontSize: '0.8rem', color: '#094502' }}> {item.result.functionalLocation}</span>;
    }
    return <span>{item.text} {functionalLocation}</span>;
  }
  const facilityGUID = LoginService?.loginInfo?.facilityId;
  const mustdisplayADNOCDropdown = !['shipawoassetspage', 'shipassetspage'].includes(parentPage);
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='row'>
            {
              !isAWOSelected ?
                <div className='col-md-12'>
                 <Form.AutoComplete formatResult={formatResult} formatSelection={formatResult}
                  label={shipLocationLabel} id='customer-ship-location'
                  className={className} bind={binder.bind('customerShipLocation')}
                  search={SAPService.isSAPFacility() ? controller.customerShipLocationSearchShipping()
                    : controller.customerShipLocationSearch()} />  </div>: 
                    <></> 
            }
            { 
              !isAWOSelected ?
              <div className='col-md-12'>
                <Form.AutoComplete formatResult={formatContainerResult} formatSelection={formatContainerResult} label={shipContainerLabel} id='customer-ship-container' bind={binder.bind('customerContainer')} search={controller.customerContainerSearch()} />
              </div>
              :<></>
              }
              {
                props.canTransfertoAWO ? 
                <>
                <div className='col-md-12'>
                <Form.AutoComplete bind={binder.bind('awoId')} label='Assembly Work Order' search={Searchers.assemblyWorkOrderSearch(facilityGUID,customerId)} />
              </div>
              
                {
                isAWOSelected ?
                <>
                <div className='col-md-12'>
                <Form.AutoComplete 
                 label='Receiving Location' id='customer-ship-location'
                 className={className} bind={binder.bind('customerShipLocation')}
                 search={controller.receivingLocationSearch()} />  </div> 
                 <div className='col-md-12'>
                <Form.AutoComplete formatResult={formatContainerResult} formatSelection={formatContainerResult} label={'Container'} id='customer-ship-container' bind={binder.bind('customerContainer')} search={controller.customerContainerSearch()} />
                </div>
                 </>
                 : <></>
                }
                 </>
              
              :
              <></>
              }
              <div className='col-md-12'><Form.Input label='Carrier' bind={binder.bind('carrier')} /></div>
              <div className='col-md-12'><Form.Input label='Tracking Number' bind={binder.bind('trackingNumber')} /></div>
              <div className='col-md-12'><Form.Input label='Weight' bind={binder.bind('weight')} /></div>
              <div className='col-md-12'>
                {controller.state.customerName === 'ADNOC' && mustdisplayADNOCDropdown  &&
                    <Form.AutoComplete label='Shipping Ticket Job Details' id='sTJobDetails' bind={binder.bind('activeJobsOfADNOC')} search={controller.getActiveJobsOfADNOC()} />
                }
            </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='col-md-12'>
              <div className='col-md-6'>{fldTechnician}</div>
              {/* <div className='col-lg-3'>{fldHours}</div> */}
              <div className='col-md-3'>{fldMinutes}</div>
            </div>
            <div className='col-md-12'>
              <Form.TextArea rows={12} label='Notes' bind={binder.bind('notes')} />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <div className='col-md-12'>
            {(props.required) ?
              <SignaturePad id='signature-pad' className='required' label='Sign your name' width={520} height={200}
                onUpdateSignature={props.updateSignature} signature={data.shippedBySignature}
                bind={binder.bind('shippedBySignature')}
                error={props.errors} showClear={true} />
              : <SignaturePad id='signature-pad' className='required' label='Sign your name' width={520} height={200}
                onUpdateSignature={props.updateSignature} signature={data.shippedBySignature}
                bind={binder.bind('shippedBySignature')}
                showClear={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default shippingDetails;
