import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants  from '../utility/AppConstants';

class ManufacturerDescriptionWallReadingRowController extends BaseController {
  constructor(parentController, wallReading, manDescId) {
    super('man-desc-wall-reading-row-controller-' + ManufacturerDescriptionWallReadingRowController.key++, {
      manDescId        : manDescId,
      manDescReadingId : wallReading ? wallReading.manDescReadingId : Utils.emptyGuid,
      readingId        : wallReading ? wallReading.readingId        : '',
      readingType      : wallReading ? wallReading.readingType      : AppConstants.ReadingType.Manufacturer,
      newDimension     : wallReading ? wallReading.newDimension     : '',
      minimumDimension : wallReading ? wallReading.minimumDimension : '',
      errors           : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('readingId',        'ID',                [{required: true}]);
    this._validator.add('newDimension',     'New Dimension',     [{required: true}]);
    this._validator.add('minimumDimension', 'Minimum Dimension', [{required: true}]);
  }

  static key = 1;

  canRemoveRow = () => {
    return true;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeWallReadingRow(this);
  }
}
    
export default ManufacturerDescriptionWallReadingRowController;
  