import React, { useMemo } from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Table from '../../components/Table';
import Binder from '../../lib/Binder';
import ReadingRow from '../pages/ThicknessReadingRow';

const ReadingsTable = (props) => {
    const controller = props.controller;
    const readings   = controller.state.readings.map(row => <ReadingRow key={row.key} controller={row} enableReadingType={false}/>);
    return (
        <div id='belowMinimumReading-div' style={{ height: 315, overflowY: 'auto', marginTop: '15' }} >
            <Table id='belowMinimumReading-table'>
                <Table.Header>
                    <Table.Row>
                        <Table.Head className='col-actualDimension'>New Dimension</Table.Head>
                        <Table.Head className='col-minimumDimension'>Min Dimension</Table.Head>
                        <Table.Head className='col-readingValue'>Reading Value</Table.Head>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {readings}
                </Table.Body>
            </Table>
        </div>
    );
}

class UpdateBelowMinimumReadingsDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleSaveAndUnscrap = () => {
        return this.props.controller.saveAndUnscrap();
    }

    handleDeleteAndUnscrap = () => {
        this.props.controller.deleteAndUnscrap();
    }

    handleClose = (evt) =>  { 
        if (evt.Update) {
            return this.props.controller.saveAndUnscrap();
        }
        else if(evt.Delete)
        {
            return this.props.controller.deleteAndUnscrap();
        }
        else {
            return true;
        }
    }

    render() {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const ThicknessReadingsTitle = 'Update UT Readings';
        const errors = controller.state.errors;

        return (
            <Dialog {...this.props} modal={true} width={600} height={480} header={ThicknessReadingsTitle} id='thicknessReadings-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='belowMinimumReadings-dlg-body' className='col-sm-12'>
                        <form role='form'>
                            <ReadingsTable controller={controller} />
                        </form>
                    </div>
                </Dialog.Body>
                <Dialog.Buttons>
                    <Dialog.Button variant='success' size='sm' className='btn' icon='fa-check' onClick={this.handleSaveAndUnscrap} result={{ Update: true }}>Update and Unscrap</Dialog.Button>
                    <Dialog.Button variant='danger' size='sm' className='btn' icon='fas fa-eraser' onClick={this.handleDeleteAndUnscrap} result={{ Delete: true }}>Delete and Unscrap</Dialog.Button>
                    <Dialog.Button variant='secondary' size='sm' className='btn' icon='fa-times' result={{ Cancel: true }}>Close</Dialog.Button>
                </Dialog.Buttons>
            </Dialog>
        );
    }
}

export default UpdateBelowMinimumReadingsDialog;