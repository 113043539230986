import React from 'react';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import Controller from '../../mixins/Controller';
import Table from '../../components/Table';

class ThicknessReadingRow extends React.Component{

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  render = () => {
    const controller           = this.props.controller;
    const binder               = new Binder(this);
    const parentController     = controller.parentController;
    const errors               = controller.state.errors;
    const isViewMode           = parentController.state.isViewMode;
    const readingType          = controller.state.readingType == 1 ? 'Manufacturer' : 'Customer';
    const fldReadingType       = this.props.enableReadingType ? <Table.Data className='col-readingType'><Form.StaticData  value={readingType}/></Table.Data> : null ;
    const fldMinimumDimension  = <Form.StaticData  value={controller.state.minimumDimension}/>
    const fldNewDimension      = <Form.StaticData  value={controller.state.newDimension}/>
    const fldReadingValue   = <Form.Input bind={binder.bind('readingValue')}  className='required' error={errors.actualDimension} placeholder='Enter a value' />
    return (
        <Table.Row>
          {fldReadingType}
          <Table.Data className='col-actualDimension' >{fldNewDimension}</Table.Data>
          <Table.Data className='col-minimumDimension'>{fldMinimumDimension}</Table.Data>
          <Table.Data className='col-readingValue' >{fldReadingValue}</Table.Data>
        </Table.Row>
    );
  }
}

export default ThicknessReadingRow;
