import store from 'store';

class SAPService {

  isSAPFacility = () => {
    const facility = store.get('currentFacility');
    return (facility && facility.useSAP);
  }

  IsCostCenterMandatory = () => {
    const facility = store.get('currentFacility');
    return (facility && facility.mandateCostCenter);
  }
  
  useTTS = () => {
    const facility = store.get('currentFacility');
    return (facility && facility.useTTS);
  }
}

export default new SAPService();
