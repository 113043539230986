import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Validator from '../../lib/Validator';
import store from 'store';
import SAPService from '../services/SAPService';
import Utils from '../utility/Utils';
import co from '../../lib/Co';
class AddReworkTimeController extends BaseController {
  constructor(jobId, testName, assets) {
    super('addrework-time', {
      jobId: jobId,
      testName: testName,
      assets: assets,
      inspectionMinutes: 0,
      inspectionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      technician: '',
      errors: {},
      notesId: Utils.emptyGuid,
      notes: ''
    });
    this._validator = new Validator();
    this.getInspectionNotes();
    this._validator.add('inspectionDate', 'Inspection Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: this.state.inspectionDate } }]);
  }

  validate = (inspectionMinutes) => {
    try {
      const MAX_VALUE = (60 * 12); // 12 hrs per day is plenty
      var value = parseFloat(inspectionMinutes);

      if (isNaN(value)) {
        notification.action('warning').post({ msg: 'That is not a number – please re-enter.', title: 'No Value Warning' });
        return false;
      }
      else if (value > MAX_VALUE) {
        notification.action('warning').post({ msg: 'Did you really work over 12 hours on this task? That value is too high to be accepted – please re-enter.', title: 'Limit Warning' });
        return false;
      }
      else if (value < 0) {
        notification.action('warning').post({ msg: 'The number must be greater than 0 (zero) – please re-enter.', title: 'Negative Value Warning' });
        return false;
      }
      else if (value === 0) {
        notification.action('warning').post({ msg: 'That is not a valid value – it evaluates to 0 (zero) – please re-enter.', title: 'Zero Value Warning' });
        return false;
      }
      return true;
    }
    catch (err) {
      notification.action('error').post({ msg: 'The value for Inspection Minutes is not valid – please re-enter', title: 'Conversion Error' });
      return false;
    }
  }

  addTime = (value) => {
    this.state.inspectionMinutes += value;
    if (this.state.inspectionMinutes < 0) {
      this.state.inspectionMinutes = 0;
    }
    this.commit();
  }
  getInspectionNotes = () => {
    const self = this;
    co(function* () {
      try {
        const s = self.state;
        const param = {
          jobId: s.jobId,
          testName: s.testName,
          assetId: s.assets[0].jobSOWId,
        }
        const result = yield ajax.post('lookup/getInspectionTestNotes', param);
        if (result) {
          self.state.notesId = result.notesId || Utils.emptyGuid
          self.state.notes = result.notes || '';
          self.commit();
        }
      }
      catch (err) {
        notification.action('error').post({ msg: err.message, title: 'Error in loading notes' });
      }
    });
  }
  canSave = () => {
    this._validator.validateAll(this.state);
    var errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }

  save = () => {
    if (!this.canSave()) {
      return false;
    }
    var inspectionMinutes = this.state.inspectionMinutes;
    if (!this.validate(inspectionMinutes)) {
      return false;
    }
    var loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
      if (parseFloat(inspectionMinutes).toFixed(1) <= 0) {
        notification.action('error').post("After rounding to 1 decimal, time will become 0. Inspection Time should be greater than 0");
        return false;
      }
      var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (inspectionMinutes > availableTime) {
        notification.action('error').post("Time should not be more than Available Production Time");
        return false;
      }
    }
    const s = this.state;
    const parameters = {
      jobId: s.jobId,
      testName: s.testName,
      assets: s.assets.map(asset => asset.jobSOWId),
      inspectionMinutes: parseFloat(inspectionMinutes),
      inspectionDate: s.inspectionDate,
      technician: s.technician,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      notesId: s.notesId,
      notes: s.notes || null
    };

    return ajax.post('inspection/addReworkTime', parameters).then(prodTime => {
      messages.channel('reload-test-results').action('saved').post();
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });

  }
}

export default AddReworkTimeController;
