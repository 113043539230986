import React from 'react';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import ActionIcon from '../../components/ActionIcon';
import notification from '../../services/Notification';
import Page from '../../components/Page';
import LoginService from '../services/LoginService';
import Table from '../../components/Table';
import Dialog from '../../components/Dialog';
import SerialUTReadingTable from './SerialUTReadingTable';
import SerialWithoutReadingTable from './SerialWithoutReadingTable';
import SerialReadingHeaderRow from './SerialReadingHeaderRow';
import Searchers from '../utility/Searchers';

const SerialDownloadRow = (props) => {

  const handleRemoveDownload = (data) => {
    props.controller.removeFromDownload(data);
  }

  const className = 'asset'
  const data = props.data;
  const groupDisplay = data.groupName ? data.groupName : data.groupNumber;
  const fldSerial = <Form.StaticData value={data.serial} />
  const fldGroupDisplay = <Form.StaticData value={groupDisplay} />
  const fldDescription = <Form.StaticData value={data.description} />
  const removeIcon = <a data-toggle="tooltip" title="Remove from download!"><ActionIcon icon='fa-trash' className='remove-handle' action={() => handleRemoveDownload(data)} /></a>
  return (
    <Table.Row>
      <Table.Data>{removeIcon}</Table.Data>
      <Table.Data className={className}>{fldSerial}</Table.Data>
      <Table.Data className={className}>{fldGroupDisplay}</Table.Data>
      <Table.Data className={className}>{fldDescription}</Table.Data>
    </Table.Row>
  )
}

const SerialWithoutReading = (props) => {
  const markAssetToDownload = () => {
    props.controller.markAssetToDownload();
  }

  const controller = props.controller;
  const serialWithoutReadings = props.controller.state.serialWithoutReadingsHeader;
  const serialNumbers = serialWithoutReadings.map(serial => <SerialReadingHeaderRow key={serial.key} showCollapse={false} controller={serial} />);
  const serialToDownload = controller.state.serialWithoutReadingsHeader.filter(row => row.state.downloadToScope).length > 0 || controller.state.serialWithReadingsHeader.filter(row => row.state.downloadToScope).length > 0;
  const isSaveInProgress = controller.state.isSaveInProgress;
  return (
    <div id='serial-without-reading-table' className='search-results'>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Head />
            <Table.Head className='col-serialNumber'>Serial Number</Table.Head>
            <Table.Head className='col-group-name'>Group Name/Number</Table.Head>
            <Table.Head className='col-description'>Description</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {serialNumbers}
        </Table.Body>
      </Table>
      <div className='col-sm-12 ut-scope-buttons'>
        <div className='float-right'><Button icon='fa-download' id='mark-download' variant='success' size='sm' className='' onClick={markAssetToDownload} disabled={isSaveInProgress || !serialToDownload}>Mark Download</Button></div>
      </div>
    </div>
  )
}


const SerialMarkedDownload = (props) => {

  const controller = props.controller;
  const assetsMarkedDownload = props.controller.state.assetsMarkedDownloadHeader;
  const serialNumbers = assetsMarkedDownload.map(serial => <SerialDownloadRow controller={controller} data={serial} />)
  return (
    <div id='serial-without-reading-table' className='search-results'>
      <Table className='table table-striped table-bordered table-condensed table-hover table-full-width dataTable'>
        <Table.Header>
          <Table.Row>
            <Table.Head></Table.Head>
            <Table.Head className='col-serialnumber'>Serial Number</Table.Head>
            <Table.Head className='col-group-name'>Group Name/Number</Table.Head>
            <Table.Head className='col-description'>Description</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {serialNumbers}
        </Table.Body>
      </Table>
    </div>
  )
}

class UTScopePage extends React.Component {

  handleToggleCollapsed = (serialHeader) => {
    this.props.controller.toggleCollapsed(serialHeader);
  }

  handleClose = (result) => {
    if (result.Cancel) {
      return true;
    }
    const self = this;
    if (self.props.controller.state.scopeSerial) {
      var controller = this.props.controller;
      var jobNumber = controller.state.selectedJob.jobNumber;
      var selectedSerial = controller.state.scopeSerial.name;
      var websocket = new WebSocket("ws://localhost:8068");
      var param = {
        JobNumber: jobNumber,
        Job_GUID: this.props.controller.state.selectedJob.id,
        CustomerName: this.props.controller.state.selectedJob.customerName,
        Type: "Download",
        Technician: LoginService.loginInfo.fullName,
        SerialNumber: selectedSerial
      };
      websocket.onopen = function (evt) {
        self.props.controller.state.IsInProgress = true;
        self.props.controller.commit();
        websocket.send(JSON.stringify(param));
      };
      websocket.onmessage = function (evt) {
        self.props.controller.state.IsInProgress = false;
        self.props.controller.commit();
        var responsedata = JSON.parse(evt.data);
        if (responsedata.ResponseType == "S") {
          notification.action('success').post(responsedata.ResponseMessage);
        }
        else if (responsedata.ResponseType == "E") {
          notification.action('error').post(responsedata.ResponseMessage);
        }
      };
      websocket.onerror = function (evt) {
        notification.action('error').post(evt.data);
      };
      return true;
    }
    else {
      notification.action('error').post('Scope Serial is required');
      return false;
    }
  }

  handleDownload = () => {
    this.props.controller.state.errors = '';
    this.props.controller.state.scopeSerial = '';
    this.props.controller.commit();
    const binder = this.props.binder;
    const errors = this.props.controller.state.errors;
    Dialog.showDialog(
      <Dialog width={600} height={200} header='Select Scope Serial' id='select-scope-serial-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='text-left'>
            <div className='col-sm-12 mt-2' style={{ height: '120px' }}>
              <Form.AutoComplete label='Scope Serial' className='required' bind={binder.bind('scopeSerial')} error={errors.scopeSerial} search={Searchers.searchFacilityScopeSerials(LoginService.loginInfo.facilityId)} />
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons />
      </Dialog>
    );
  }

  handleGetReading = () => {
    const self = this;
    const controller = this.props.controller;
    const jobNumber = controller.state.selectedJob.jobNumber;
    const selectedSerial = controller.state.assetsMarkedDownload;
    const websocket = new WebSocket("ws://localhost:8068");
    const param = {
      JobNumber: jobNumber,
      Job_GUID: this.props.controller.state.selectedJob.id,
      Type: "Retrieve",
      Technician: LoginService.loginInfo.fullName,
      UserGUID: LoginService.loginInfo.userId
    };
    websocket.onopen = function (evt) {
      self.props.controller.state.IsInProgress = true;
      self.props.controller.commit();
      websocket.send(JSON.stringify(param))
    };
    websocket.onmessage = function (evt) {
      self.props.controller.state.IsInProgress = false;
      self.props.controller.commit();
      const responsedata = JSON.parse(evt.data);
      if (responsedata.ResponseType == "S") {
        // notification.action('success').post(responsedata.ResponseMessage);
        self.props.controller.getReadingsConfirmation(responsedata);
      }
      else if (responsedata.ResponseType == "E") {
        notification.action('error').post(responsedata.ResponseMessage);
        self.props.controller.getReadingDetailsForSerial();
      }
    };
    websocket.onerror = function (evt) {
      notification.action('error').post(evt.data);
    };
  }

  selectAllAssets = () => {
    this.props.controller.selectAllAssets();
  }

  deselectAllAssets = () => {
    this.props.controller.deselectAllAssets();
  }

  render = () => {
    const binder = new Binder(this);
    const jobId = this.props.jobId;
    const controller = this.props.controller;
    const dmsConnectionInProgress = controller.state.IsInProgress
    const serialToDownload = controller.state.serialWithoutReadingsHeader.filter(row => row.state.downloadToScope).length > 0 || controller.state.serialWithReadingsHeader.filter(row => row.state.downloadToScope).length > 0;
    const isSaveInProgress = controller.state.isSaveInProgress;
    const withoutReadingBadge = <Badge variant='info'>{controller.state.serialWithoutReadingsHeader.length}</Badge>
    const withReadingBadge = <Badge variant='info'>{controller.state.serialWithReadingsHeader.length}</Badge>
    const assetsMarkedDownload = <Badge variant='info'>{controller.state.assetsMarkedDownloadHeader.length}</Badge>

    return (
      <div id='ut-scope-pg-detail'>
        <div id='serial-with-reading'>
          <Form.Header className='heading-class'>
            Assets with Readings {withReadingBadge}
          </Form.Header>
          <SerialUTReadingTable controller={controller} serialWithReading={true} handleToggleCollapsed={this.handleToggleCollapsed} />
        </div>
        <div id='serial-without-reading'>
          <Form.Header className='heading-class'>
            Assets without Readings {withoutReadingBadge}
          </Form.Header>
          {/* <SerialWithoutReading controller={controller} /> */}
          <SerialWithoutReadingTable controller={controller} serialWithReading={false} handleToggleCollapsed={this.handleToggleCollapsed} />
        </div>
        {/* <div id='serial-without-reading'>
              <Form.Header className='heading-class'>
                Upload/Download Assets from/to DMS/DMS2 {assetsMarkedDownload}
              </Form.Header>
              <SerialMarkedDownload controller={controller} />
            </div> */}
        <div className='col-md-12 ut-scope-buttons'>
          <div className='row'>
            <div className='col-md-7'>
              <span className='text-danger font-weight-bold'>Warning: Check that the Scope or SD Card is connected and active. Reconnect if inactive.</span>
            </div>
            <div className='col-md-5 d-flex justify-content-end'>
              <div className='dms-button'><Button icon='fa-download' id='downloadtodevice' variant='success' size='sm' className='m-1' onClick={this.handleDownload} disabled={isSaveInProgress || !serialToDownload || dmsConnectionInProgress}>Download to Device</Button></div>
              <div className='dms-button'><Button icon='fa-upload' id='getreading' variant='primary' size='sm' className='m-1' onClick={this.handleGetReading} disabled={isSaveInProgress || dmsConnectionInProgress}>Get Reading</Button></div>
            </div>
          </div>
          {/* <div className='col-sm-3'><Button icon='fa-square'        variant='success' size='sm' className='' onClick={this.selectAllAssets}>Select All</Button></div>  
              <div className='col-sm-3'><Button icon='fa-square-o'      variant='danger' size='sm'  className=''  onClick={this.deselectAllAssets}>Select None</Button>             </div> */}
        </div>
      </div>
    );
  }
}

export default UTScopePage;

