import React, {useMemo} from 'react';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Badge from '../../components/Badge';
import Utils from '../utility/Utils';


const SelectedLaborCodeTable = (props) => {

  const controller = props.controller;
  const data = [...props.items];

  const columns = useMemo(
      () => [
          {
              Header: 'Labor Code',
              accessor: 'laborCodeName',
              Cell: cellInfo => { 
                return cellInfo.row.original.laborCodeId
                ? <Form.Link to={'/laborcodes/' + cellInfo.row.original.laborCodeId} value={cellInfo.cell.value} />
                : cellInfo.cell.value;
              }
          },
          {
              Header: 'Is Reject Labor Code',
              accessor: 'isRejectLaborCode',
              Cell: cellInfo => <span>{Utils.formatBool(cellInfo.cell.value)}</span>
          },
          {
              Header: <span>Description <Badge className='float-right mr-1 mt-1' variant='secondary'>{data && data.length}</Badge></span>,
              accessor: 'laborCodeDescription',
          },
      ],
      [data]
  )
  return (
      <div id='selected-labor-codes'>
          <Grid id='results-table' responsive={false} showPagination={false} columns={columns} data={data}/>
      </div>     
  );
}

export default SelectedLaborCodeTable;