import React from 'react';
import { Link } from 'react-router-dom';
import {
    DropdownButton as RBDropdownButton,
    SplitButton as RBSplitButton,
    Dropdown as RBDropdown
} from 'react-bootstrap';

const Dropdown = (propsPrams) => {
    const props = {
        ...propsPrams
    };

    return (
        <RBDropdown {...props}>
            {props.children}
        </RBDropdown>
    );
}

Dropdown.Button = (propsPrams) => {
    const props = {
        variant: 'primary',
        ...propsPrams
    };

    return (
        <RBDropdownButton {...props}>
            {props.children}
        </RBDropdownButton>
    );
}

Dropdown.SplitButton = (propsPrams) => {
    const props = {
        ...propsPrams
    };

    return (
        <RBSplitButton {...props}>
            {props.children}
        </RBSplitButton>
    );
}

Dropdown.Toggle = (propsPrams) => {
    const props = {
        ...propsPrams
    };

    return (
        <RBDropdown.Toggle {...props}>
            {props.children}
        </RBDropdown.Toggle>
    );
}

Dropdown.Menu = (propsPrams) => {
    const props = {
        ...propsPrams
    };

    return (
        <RBDropdown.Menu {...props}>
            {props.children}
        </RBDropdown.Menu>
    );
}

Dropdown.MenuItem = (propsParam) => {
    let props = { ...propsParam };
    let prependIconName;

    if (props.to && !props.disabled) {
        props.as = Link;
    }

    if (props.icon) {
        if (props.icon.includes('fab')) {
            prependIconName = 'fab ';
        }
        else if (props.icon.includes('far')) {
            prependIconName = 'far ';
        }
        else if (props.icon.includes('fas')) {
            prependIconName = '';
        }
        else {
            prependIconName = 'fas ';
        }
    }
    const icon = props.icon ? <span className={prependIconName + props.icon + ' mr-2'} ></span> : null;
    return <RBDropdown.Item {...props}>{icon}{props.name}{props.children}</RBDropdown.Item>;
}

Dropdown.Header = (props) => {
    let prependIconName;
    if (props.icon) {
        if (props.icon.includes('fab')) {
            prependIconName = 'fab ';
        }
        else if (props.icon.includes('far')) {
            prependIconName = 'far ';
        }
        else if (props.icon.includes('fas')) {
            prependIconName = '';
        }
        else {
            prependIconName = 'fas ';
        }
    }
    const icon = props.icon ? <span className={prependIconName + props.icon + ' mr-2'} /> : null;
    return <RBDropdown.Header {...props}>{icon}{props.title}{props.children}</RBDropdown.Header>;
}

Dropdown.Divider = (props) => <RBDropdown.Divider {...props}></RBDropdown.Divider>;

export default Dropdown;