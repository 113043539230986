import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';

class DropoffRowController extends BaseController {
    constructor(parentController, dropoff) {
      super('dropoff-row-controller-' + DropoffRowController.key++, {
        dropoffRowId   : dropoff ? dropoff.dropoffRowId   : '',
        quantity       : dropoff ? dropoff.quantity       : 1,
        nominalSize    : dropoff ? dropoff.nominalSize    : '',
        familyCode     : dropoff ? dropoff.familyCode     : '',
        manufacturer   : dropoff ? dropoff.manufacturer   : '',
        style          : dropoff ? dropoff.style          : '',
        endConnection  : dropoff ? dropoff.endConnection  : '',
        length         : dropoff ? dropoff.length         : '',
        errors         : {}
      });
  
      this.parentController = parentController;
      this._validator       = new Validator();
      this._validator.add('nominalSize' ,'Nominal Size' ,[{required: true}]);
      this._validator.add('familyCode'  ,'Family Code'  ,[{required: true}]);
    }

    static key = 1;
  
    quantityChanged = (data) => {
      try {
        if (data.new) {
          this.state.quantity = parseInt(data.new, 10);
        }
        else {
          this.state.quantity = 0;
        }
      }
      catch (err) {
        this.state.quantity = 0;
      }
      this.parentController.commit();
    }
  
    removeRow = () => {
      this.parentController.removeRow(this);
    }
  
    validate = () => {
      this.state.errors = {};
      this._validator.validateAll(this.state);
  
      const quantity = this.state.quantity;
      if (quantity) {
        delete this.state.errors.quantity;
      }
      else {
        this.state.errors.quantity = 'Quantity must be greater than 0';
      }
    }
  }
  
  export default DropoffRowController;
