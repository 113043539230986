import React, {useMemo} from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import AdditionalTimeController from '../controllers/AdditionalTimeController';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import Searchers from '../utility/Searchers';
import Grid from '../../components/Grid';
import Table from '../../components/Table';

const AddProdList = (props) => {
  var controller = props.controller;
  const data =  controller.state.addProdTimeData.map(row => row);

  const columns = useMemo(
    () => [
      {
        Header: 'Additional Time',
        accessor: 'Value',
        Cell: cellInfo => {
          const hours = cellInfo.cell.value === null ? '' : Utils.convertMinsToHrsMins(cellInfo.cell.value);
          const minutes = (cellInfo.cell.value === null ? '' : parseFloat(cellInfo.cell.value).toFixed(1));
          const final = cellInfo.cell.value === null ? '' : (hours + ' / ' + minutes + ' min');
          return <span>{final}</span>
        }
      },
      {
        Header: 'Base',
        accessor: 'baseName',
      },
      {
        Header: 'Cost Center',
        accessor: 'costCenterName',
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      }
    ],
    []
  )

  return (
    <div id='additionalValue-div' >
      <Grid id='results-table' columns={columns} data={data} showPagination={false} noDataText='No Records Found' disableSortBy={true}/>
    </div>
  );
}      

const RecordTimeTable = (props) => {

  const handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  const handleKeyUp = (evt) => {
    try {
      var value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }
      if (value > 100000) {
        evt.target.value = 100000;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }

  const controller = props.controller;
  const errors = controller.state.errors;
  const binder = props.binder;
  const loginInfo = LoginService.loginInfo;
  const LoggedInuser = loginInfo.fullName;

  const fldMinutes = <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
  const fldTechnician = <Form.StaticData label='User' value={LoggedInuser} />
  const fldBase = <Form.AutoComplete label='Base' bind={binder.bind('base')} search={Searchers.sapFacilitySearch()} />
  const costCenterProps = {
    id: 'costCenter',
    className: controller.state.base ? 'required' : '',
    error: controller.state.base ? errors.costCenter : '',
    bind: binder.bind('costCenter'),
    label: 'Cost Center',
    search: Searchers.searchCostCenterWithFacilityId(controller.state.base ? controller.state.base.id : '')
  }
  var fldCostCenter = <Form.AutoComplete {...costCenterProps} />
  return (
    <div id='additionalValue-div-top' >
      <Table id='additionalValue-table' bordered={false} hover={false} striped={false}>
        <Table.Header>
          <Table.Row>
            <Table.Head colSpan='4' className='col-additionalTime'>Record Additional Time</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row className='additionalTime-row'>
            <Table.Data className='col-additionalTime-user'>{fldTechnician}</Table.Data>
            <Table.Data className='col-additionalTime-min'>{fldMinutes}</Table.Data>
            <Table.Data className='col-additionalTime-base'>{fldBase}</Table.Data>
            <Table.Data className='col-additionalTime-cost-center'>{fldCostCenter}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data colSpan='4'>
              <Form.TextArea id='reason' className='required' label='Reason' bind={binder.bind('reason')} error={errors.reason} maxLength='132' />
            </Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}    

const AdditionalTable = (props) => {
  const controller = props.controller;
  const data = controller.state.additionalTimeData.map(row => row.state);
  const columns = useMemo(
    () => [
      {
        Header: 'Work Type',
        accessor: 'type',
      },
      {
        Header: 'Time',
        accessor: 'value',        
        Cell: cellInfo => {
          const value = cellInfo.cell.value;
          const hours = value === null ? '' : Utils.convertMinsToHrsMins(value);
          const minutes = (value === null ? '' : parseFloat(value).toFixed(1));
          const final = value === null ? '' : (hours + ' / ' + minutes + ' min');
          return <span>{final}</span>
        }
      },
    ],
    []
  )

  return (
    <div id='additionalValue-div-bottom'>
      <Grid id='additionalValue-table' columns={columns} data={data} showPagination={false} disableSortBy={true} noDataText='No Records Found'/>
    </div>
  );
}

class AdditionalTimeDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new AdditionalTimeController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) =>  (result.Cancel || result.OK) ? true : this.props.controller.saveAdditionalValues();

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const additionalValuesTitle = 'Additional Time';       

        return (
          <Dialog {...this.props} modal={true} width={650} height={540} style={{ overflowY: 'auto', marginTop: '15' }} header={additionalValuesTitle} id='additional-time-dlg' onClose={this.handleClose}>
            <Dialog.Body>
              <div id='additionalValues-dlg-body'>
                <div className='row'><div className='col'><AddProdList controller={controller} /></div></div>
                <div className='row'><div className='col'><RecordTimeTable controller={controller} binder={binder} /></div></div>
                <div className='row'><div className='col'><AdditionalTable controller={controller} /></div></div>
              </div>
            </Dialog.Body>
            <Dialog.SaveCancelButtons saving={this.props.controller.saving} />;
          </Dialog>
        );
    }
}

export default AdditionalTimeDialog;