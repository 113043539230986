import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants  from '../utility/AppConstants';

class CustDescTorqueReadingRowController extends BaseController {
  constructor(parentController, torqueReading, custDescId) {
    super('cust-desc-torque-reading-row-controller-' + CustDescTorqueReadingRowController.key++, {
      custDescId               : custDescId,
      manDescReadingId         : torqueReading ? torqueReading.manDescReadingId         : Utils.emptyGuid,
      custDescManReadingId     : torqueReading ? torqueReading.custDescManReadingId     : Utils.emptyGuid,
      readingId                : torqueReading ? torqueReading.readingId                : '',
      readingType              : torqueReading ? torqueReading.readingType              : AppConstants.ReadingType.Customer,
      torqueLocation           : torqueReading ? {id: torqueReading.torqueLocationId, name: torqueReading.torqueLocationName}  : '',
      torqueRange              : torqueReading ? {id: torqueReading.torqueRangeId, name: torqueReading.torqueRangeName}         : '',
      customerTorqueRange      : torqueReading ? {id: torqueReading.customerTorqueRangeId, name: torqueReading.customerTorqueRangeName} : '',
      customerSelected         : torqueReading ? torqueReading.readingType === AppConstants.ReadingType.Manufacturer ? torqueReading.customerSelected  : true : true,
      errors                   : {}
    });
    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('readingId',        'ID',                          [{required: true}]);
    this._validator.add('torqueLocation', 'Torque Location',               [{required: true}]);
    this._validator.add('torqueRange', 'Torque Range',                     [{required: true}]);
    this._validator.add('customerTorqueRange', 'Customer Torque Range',    [{required: true}]);
  }

  static key = 1;

  customerSelectedChanged = () => {
    if (!this.state.customerSelected) {
      this.state.customerMinimumReading = 0;
    }
    this.commit();
    this.parentController.commit();
  }


  canRemoveRow = () => {
    return this.state.readingType === AppConstants.ReadingType.Customer;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeTorqueReadingRow(this);
  }

}
    
export default CustDescTorqueReadingRowController;
  