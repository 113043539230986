import React from 'react';
import store from 'store';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import LoginService from '../services/LoginService';

class DropoffController extends BaseController {
    constructor() {
        super('dropoff', {
            searchActive: true,
            searchCompleted: false,
            searchText: '',
            searchResults: [],
            selectedForm: '',
            customers: [],
            customer: '',
            facility: ''
        });

        messages.channel('change-facility').action('changed').subscribe(() => {
            this.state.selectedForm = '';
            this.state.searchResults = [];
            this.customers = [];
        });

        messages.channel('edit-dropoff').action('saved').subscribe(dropoffId => {
            this.searchDropoffs(dropoffId);
        });

        messages.channel('complete-dropoff').action('saved').subscribe(() => {
            this.searchDropoffs();
        });

        messages.channel('complete-job').action('saved').subscribe(() => {
            this.searchDropoffs();
        });

        messages.channel('edit-job').action('saved').subscribe(data => {
            if (!data.dropoffNumber) {
                return;
            }

            var form = this.state.searchResults.filter(result => result.dropoffNumber === data.dropoffNumber);
            if (!form || !form.length) {
                return;
            }

            this.complete(form[0], false); // don't ask for confirmation
        });

        messages.channel('login').action('loggedin').subscribe(data => {
            if (!data) {
                this.state.customer = '';
                this.clearResults();
            }
        });
    }

    selectForm = (dropoffId) => {
        this.state.searchResults.forEach(form => {
            if (form.dropoffId === dropoffId) {
                this.loadDropoffDetails(form);
            }
        });
    }

    searchActiveChanged = () => {
        this.state.searchCompleted = false;
        this.clearResults();
        document.getElementById('searchText').focus();
    }

    searchCompletedChanged = () => {
        this.state.searchActive = false;
        this.clearResults();
        document.getElementById('searchText').focus();
    }

    isSelected = (form) => {
        if (!this.state.selectedForm) {
            return false;
        }
        return (form.dropoffId === this.state.selectedForm.dropoffId);
    }

    clearResults = () => {
        this.state.searchResults = [];
        this.state.selectedForm = '';
    }

    clearSearch = () => {
        this.state.searchText = '';
        this.commit();
    }

    customerChanged = () => {
        this.clearResults();
        document.getElementById('searchText').focus();
        this.searchDropoffs();
    }

    canConvertToJob = () => {
        const facility = store.get('currentFacility');
        if (!facility) {
            return false;
        }
        return (!facility.useSAP || (facility.plantCode !== '') && this.canAttachToJob());
    }

    canAttachToJob = () => {
        const form = this.state.selectedForm;
        return (form && !form.isComplete && (form.jobId === Utils.emptyGuid));
    }

    canDetachFromJob = () => {
        const form = this.state.selectedForm;
        return (form && !form.isComplete && (form.jobId !== Utils.emptyGuid));
    }

    detachFromJob = () => {
        const form = this.state.selectedForm;
        const dropoffId = form.dropoffId;
        const dropoffNumber = form.dropoffNumber;
        const jobNumber = form.jobNumber;

        co(this, function* () {
            const message = 'Please confirm you wish to detach Drop-Off Form #' + dropoffNumber + ' from Job #' + jobNumber;
            const header = <h5 className='col-12 center my-2'>{message}</h5>;
            const result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog width={600} height={250} header='Confirm Asset Removal'>
                    <div className='row'>
                        {header}
                    </div>
                </Dialog.OKCancelDialog>
            );

            if (!result.OK) {
                return;
            }

            const parameters = {
                dropoffId: dropoffId
            };

            yield ajax.post('dropoff/detachFromJob', parameters); // in: DetachDropoffFromJobDto, out: void
            notification.action('success').post('Detached Drop-Off Form #' + dropoffNumber + ' from Job #' + jobNumber);
            messages.channel('edit-dropoff').action('saved').post(dropoffId);
        });
    }

    customersSearch = () => {
        if (!LoginService.loginInfo) {
            return;
        }
        const facilityId = (this.state.facility && this.state.facility.id) || LoginService.loginInfo.facilityId;
        return (searchTerm) => ajax.get('lookup/searchCustomersWithDropoffsAtFacility', {
            searchTerm: searchTerm,
            facilityId: facilityId
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    loadDropoffDetails = (form) => {
        this.state.selectedForm = form;
        this.commit();
    }

    facilitySearch = () => {
        return function* (searchTerm) {
            const results = yield ajax.get('lookup/searchFacilitiesForUser', {
                searchTerm: searchTerm,
                sapFacilitiesOnly: true
            });
            return results;
        };
    }

    searchDropoffs = (selectedFormDropoffId) => {
        const isSAPRep = LoginService.isSAPRep;
        const isAdministrator = LoginService.isAdministrator;
        const defaultFacility = (LoginService.loginInfo && LoginService.loginInfo.facilityId) || '';
        co(this, function* () {
            this.state.results = [];
            const facilityId = (isSAPRep && !isAdministrator) ? (this.state.facility && this.state.facility.id) || defaultFacility
                : defaultFacility;
            // var facilityId     = (LoginService.loginInfo && LoginService.loginInfo.facilityId);
            const customerId = (this.state.customer && this.state.customer.id); // can be null
            const searchActive = this.state.searchActive ? 1 : 0;
            const searchText = this.state.searchText;

            /*if (!facilityId) {
              return;
            }*/

            const parameters = {
                facilityId: facilityId,
                customerId: customerId,
                searchActive: searchActive,
                searchTerm: searchText
            };

            const results = yield ajax.get('lookup/searchDropoffsForFacility', parameters); // IList<DropoffLookupDto>
            this.state.searchResults = results;

            if (selectedFormDropoffId) {
                // form to select was specified
                this.selectForm(selectedFormDropoffId);
            } else if (results.length > 0) {
                const createdDate = this.state.selectedForm.createdDate;
                DateTimeFormatter.getMoment(createdDate);
            } else {
                this.state.selectedForm = '';
            }
            this.commit();
        });
    }

    complete = (form, requestConfirmation) => {
        // var form = this.state.selectedForm;
        if (form.isComplete) {
            return;
        }

        const dropoffNumber = form.dropoffNumber;
        co(this, function* () {
            if (requestConfirmation) {
                const result = yield Dialog.showDialogWaitForResult(
                    <Dialog.OKCancelDialog width={500} height={250} header='Confirm Drop-Off Form Completion'>
                        <h5>{'Please confirm you wish to complete Drop-Off Form #' + dropoffNumber}</h5>
                    </Dialog.OKCancelDialog>
                );

                if (!result.OK) {
                    return;
                }
            }

            try {
                const parameters = {
                    dropoffId: form.dropoffId
                };

                yield ajax.post('dropoff/complete', parameters); // in: Guid, out: void
                notification.action('success').post('Completed Drop-Off Form #' + dropoffNumber);
                messages.channel('complete-dropoff').action('saved').post();
            } catch (err) {
                notification.action('error').post(err.message);
            }
        });
    }
}

export default DropoffController;
