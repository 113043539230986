import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';

class EditLaborCodeController extends BaseController {
    constructor() {
        super('edit-labor-code', {
            laborCodeId: '',
            laborCodeName: '',
            description: '',
            isRejectLaborCode: false,
            multiRejectCapable: false,
            errors: {},
            searchContains: true,
            searchDoesNotContain: false,
            manDescFilterText: '',
            manufacturerDescriptions: [],
            customerServiceLevels: []
        });

        this.adding = false;
        this._validator = new Validator();
        this._validator.add('laborCodeName', 'Labor Code Name', [{ required: true }]);
        this._validator.add('description', 'Description', [{ required: true }]);

        messages.channel('edit-labor-code').action('saved').subscribe(laborCode => {
            if (!laborCode || laborCode && laborCode.laborCodeId !== this.state.laborCodeId) {
                return;
            }
            this.load(laborCode.laborCodeId);
        });
    }

    initializeState = (laborCodeId) => {
        this.state.laborCodeId = laborCodeId;
        this.state.laborCodeName = '';
        this.state.description = '';
        this.state.isRejectLaborCode = false;
        this.state.multiRejectCapable=false;
        this.state.manufacturerDescriptions = [];
        this.state.customerServiceLevels = [];
        this.state.errors = {};
    }

    clearSearchOptions = () => {
        this.state.searchContains = false;
        this.state.searchDoesNotContain = false;
    }

    searchContainsChanged = () => {
        this.clearSearchOptions();
        this.state.searchContains = true;
    }

    searchDoesNotContainChanged = () => {
        this.clearSearchOptions();
        this.state.searchDoesNotContain = true;
    }

    filterItems = (items) => {
        if (!items || !items.length) {
            return [];
        }

        // TODO: Add tokenized filtering here - use following code as an example
        // var terms = this.state.laborCodeFilterText.split(',');
        // for (var i = 0; i < terms.length; ++i) {
        //   var term = terms[i];
        //   if (Utils.contains(lc.mfgItemNo, term)) {
        //     return true;
        //   }
        //   if (Utils.contains(lc.manDescDescription, term)) {
        //     return true;
        //   }
        //   if (Utils.contains(lc.laborCodeName, term)) {
        //     return true;
        //   }
        //   if (Utils.contains(lc.laborCodeDescription, term)) {
        //     return true;
        //   }
        // }
        // return false;

        const filterText = this.state.manDescFilterText;
        if (!filterText) {
            return items || [];
        }

        if (this.state.searchContains) {
            return items.filter(md => Utils.contains(md.description, filterText));
        }

        if (this.state.searchDoesNotContain) {
            return items.filter(md => !Utils.contains(md.description, filterText));
        }
    }

    getSelectedManDesc = () => {
        const selected = this.state.manufacturerDescriptions.filter(md => md.selected);
        if (selected.length) {
            return selected[0];
        }
        return '';
    }

    load = (laborCodeId) => {
        if (laborCodeId === Utils.emptyGuid) {
            this.adding = true;
            this.initializeState(laborCodeId);
            this.commit();
        }
        else {
            var selectedManufacturerDescription = this.getSelectedManDesc();
            this.adding = false;
            ajax.get('lookup/getLaborCode/', { laborCodeId: laborCodeId }).then(results => {
                this.merge(results);
                if (selectedManufacturerDescription) {
                    // reselect
                    var manDescLaborCodeId = selectedManufacturerDescription.manDescLaborCodeId;
                    var selectedManufacturerDescriptions = this.state.manufacturerDescriptions.filter(md => md.manDescLaborCodeId === manDescLaborCodeId);
                    if (selectedManufacturerDescriptions.length) {
                        this.selectManufacturerDescription(selectedManufacturerDescriptions[0]);
                    }
                }
                this.commit();
            }).catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            }); // LaborCodeDto
        }
    }

    merge = (laborCode) => {
        if (!laborCode) {
            return;
        }
        Object.assign(this.state, laborCode);
        this.state.customerServiceLevels = [];
        this.state.errors = {};
    }

    canSave = () => {
        this._validator.validateAll(this.state);

        var errors = Object.keys(this.state.errors);
        if (!errors.length) {
            return true;
        }
        this.commit();
        return false;
    }

    clearManDescFilterText = () => {
        this.state.manDescFilterText = '';
        this.commit();
    }

    applyManDescFilter = () => {
        // doesn't need a body - just needs to be here for the <Search /> component on EditLaborCodepage.jsx
    }

    removeManDesc = (item) => {
        ajax.post('laborCode/deleteManDescLaborCode', item).then(() => {
            notification.action('success').post('Saved changes for ' + this.state.laborCodeName);

            // use in FacilityController to re-select edited form
            // if it's a new form, it will be the 1st entry in the list
            messages.channel('edit-labor-code').action('saved').post(item);
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: LaborCodeManufacturerDescriptionDto, out: void
    }

    removeServiceLevel = (item) => {
        ajax.post('laborCode/deleteAssignedLaborCode', item).then(() => {
            notification.action('success').post('Saved changes for ' + this.state.laborCodeName);
            messages.channel('edit-labor-code').action('saved').post(item);
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: AssignedLaborCodeDto, out: void
    }

    selectManufacturerDescription = (item) => {
        this.state.manufacturerDescriptions.forEach(md => md.selected = false);
        item.selected = true;
        this.loadCustomerServiceLevels(item.manDescLaborCodeId);
        this.commit();
    }

    loadCustomerServiceLevels = (manDescLaborCodeId) => {
        ajax.get('lookup/getAssignedServiceLevels/', { manDescLaborCodeId: manDescLaborCodeId }).then(results => {
            this.state.customerServiceLevels = results;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: Guid, out: IList<AssignedLaborCodeDto>
    }

    save = (cb) => {
        if (!this.canSave()) {
            return;
        }

        const s = this.state;
        const laborCodeId = s.laborCodeId;
        const parameters = {
            laborCodeId: laborCodeId,
            laborCodeName: s.laborCodeName,
            description: s.description,
            isRejectLaborCode: s.isRejectLaborCode,
            multiRejectCapable: s.multiRejectCapable
        };
        ajax.post('laborCode/save', parameters).then(() => {
            if (this.adding) {
                notification.action('success').post('Saved new Labor Code');
            }
            else {
                notification.action('success').post('Saved changes for ' + s.laborCodeName);
            }

            // use in FacilitieController to re-select edited form
            // if it's a new form, it will be the 1st entry in the list
            var postData = this.adding ? null : laborCodeId;
            messages.channel('edit-labor-code').action('saved').post(postData);
            cb();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: LaborCodeDto, out: void
    }
}

export default EditLaborCodeController;