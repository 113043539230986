
import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import ResetPasswordController from '../controllers/ResetPasswordController';

class ResetPasswordDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    static defaultProps = {
        controller: new ResetPasswordController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (evt) =>  evt.Cancel ? true : this.props.controller.save();

    handleSubmit = (evt) => {
        evt.preventDefault();
        this.props.controller.save();
    }

    render() {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Dialog {...this.props} modal={true} header={this.props.header} width={400} height={280} id='reset-password-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div>
                        <div className='m-2 text-center'><span>Enter the new password for </span><span className='font-weight-bold'>{controller.state.user.firstName} {controller.state.user.lastName}</span></div>
                        <div className='m-2 text-center font-italic'>NOTE: password will be visible</div>
                        <div className='m-2 text-center font-weight-bold text-danger'>{controller.state.error}</div>
                        <div className='m-2'><Form.Input id='password' focus={true} bind={binder.bind('password')} className='required' /></div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons saving={controller.state.isLoading} />
            </Dialog>
        );
    }
}

export default ResetPasswordDialog;