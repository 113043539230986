import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import LaborCodeController from '../controllers/LaborCodeController';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Badge from '../../components/Badge';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Utils from '../utility/Utils';
import Authentication from '../services/authentication';
import Search from '../components/Search';
import Grid from '../../components/Grid';
import Jumbotron from '../../components/Jumbotron';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';


const ResultsList = (props) => {
    const controller = props.controller;
    const data = controller.state.searchResults;
    const loadingMessage = controller.state.isLoading && 'Loading labor codes that meet the search criteria defined above';
    const noResultsMessage = !loadingMessage && !data.length && 'No labor codes meet the search criteria defined above';
    const matchesCount = controller.state.searchResults.length;
    const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

    const handleRowClick = (laborCode) => {
        props.controller.select(laborCode);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Labor Code',
                accessor: 'laborCodeName',
            },
            {
                Header: 'Reject Labor Code',
                accessor: 'isRejectLaborCode',
                Cell: cellInfo => <span>{Utils.formatBool(cellInfo.cell.value)}</span>
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Man Desc Count',
                accessor: 'manufacturerDescriptionCount',
            },
        ],
        []
    )
    return (
        <div>
            <div className='d-flex justify-content-end'>
                <div className='mt-2 mr-2 mb-1'><Badge variant='info'>{matchesLabel}</Badge></div>
            </div>
            <div className='labor-code-table' >
                <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText={loadingMessage || noResultsMessage} enableDefaultRowSelect={true} />
            </div>
        </div>
    );
}

const Details = (props) => {

    const controller = props.controller;
    const laborCode = controller.state.selectedItem;

    return (
        <Jumbotron {...props} id='labor-code-details' className='form-group col-sm-12'>
            <div className='row'>
                <div className='col-sm-12'><Form.StaticData label='Labor Code' value={laborCode.laborCodeName} /></div>
                <div className='col-sm-12'><Form.StaticData label='Is Reject Labor Code' value={Utils.formatBool(laborCode.isRejectLaborCode)} /></div>
                <div className='col-sm-12'><Form.StaticData label='Description' value={laborCode.description} /></div>
            </div>
        </Jumbotron>
    );
}

const Buttons = (props) => {

    let history = useHistory();

    const handleEdit = (item) => {
        history.push('/laborcodes/' + item.laborCodeId);
    }

    const handleCreate = () => {
        history.push('/laborcodes/' + Utils.emptyGuid);
    }

    const controller = props.controller;
    const selectedItem = controller.state.selectedItem;
    const isSuperUser = LoginService.isInRole(Roles.SuperUser)
    const canEdit = selectedItem ;
   
    return (
        <div id='labor-code-buttons'>
            <Button icon='fa-pencil-alt' disabled={!canEdit || isSuperUser } variant={'primary'} size={'sm'} className='action-button m-1' onClick={() => { handleEdit(selectedItem); }}>Edit ...</Button>
            <Button icon='fa-plus-circle' variant={'success'} size={'sm'} className='action-button m-1' onClick={handleCreate} disabled={isSuperUser}>Create New ...</Button>
        </div>
    );
}

const LaborCodePageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-anchor' />

class LaborCodePage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
    }

    static defaultProps = {
        controller: new LaborCodeController()
    }

    handleMouseSearch = () => {
        this.props.controller.search();
    }

    clearSearch = () => {
        this.props.controller.clearSearch();
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Page {...this.props} pageTitle='InteServ · Labor Codes' id='labor-code-page'>
                <LaborCodePageHeader pageTitle={'Labor Codes'} />
                <Page.Content>
                    <div className='row'>
                        <div {...this.props} className='col-sm-8 col-md-8 col-xs-12'>
                            <Search className='mb-2' id='searchText' inputClassName='search-input' label='Enter search text' placeholder='search text' autoFocus='true' bind={binder.bind('searchText')} clear={this.clearSearch} search={this.handleMouseSearch}></Search>
                            <ResultsList controller={controller} binder={binder} />
                        </div>

                        <div className='col-sm-4 col-md-4 col-xs-12'>
                            <Buttons controller={controller} binder={binder} />
                            <Details controller={controller} binder={binder} />
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
};

export default LaborCodePage;