import React from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import co from '../../lib/Co';

class SendEmailDialog extends React.Component{
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }  

    handleClose = (result) =>  result.Cancel ? true : this.props.controller.save();
    
    render() {
      var binder        = new Binder(this);
      var controller    = this.props.controller;
      var errors        = controller.state.errors;
    
        return (
          <Dialog {...this.props} header={this.props.header} width={450} height={220} id='send-email-dlg' onClose={this.handleClose}>
            <Dialog.Body>
            <div className='col-md-12 m-2'>
                <Form.Input bind={binder.bind('emailId')}  label='Email Address' error={errors.emailId}/>
            </div>
            </Dialog.Body>
            <Dialog.Buttons>
                    <Dialog.Button variant='primary' size='sm' className='btn' icon='fa-upload' result={{ Save: true }}>Send Email</Dialog.Button>
                    <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Cancel: true }}>Close</Dialog.Button>
                </Dialog.Buttons>
          </Dialog>
        );
    }
}

export default SendEmailDialog;