import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants  from '../utility/AppConstants';

class GaugeManagementDailyVerificationRowController extends BaseController {
  constructor(parentController, dailyVerificationData) {
    super('gauge-daily-verification-reading-row-controller-' + GaugeManagementDailyVerificationRowController.key++, {
      //manDescId        : manDescId,
      Container            : dailyVerificationData ? dailyVerificationData.Container          : '',
      Current_Reading      : dailyVerificationData ? dailyVerificationData.Current_Reading    : '',
      DailyCalId           : dailyVerificationData ? dailyVerificationData.DailyCalId         : Utils.emptyGuid,
      Description          : dailyVerificationData ? dailyVerificationData.Description        : '',
      MinimumDimension     : dailyVerificationData ? dailyVerificationData.MinimumDimension   : '',
      NewDimension         : dailyVerificationData ? dailyVerificationData.NewDimension       : '',
      Previous_Reading     : dailyVerificationData ? dailyVerificationData.Previous_Reading   : '',
      ReadingID            : dailyVerificationData ? dailyVerificationData.ReadingID          : '',
      ReadingType          : dailyVerificationData ? dailyVerificationData.ReadingType        : '',
      ReadingValue         : dailyVerificationData ? dailyVerificationData.ReadingValue       : '',
      RecordStatus         : dailyVerificationData ? dailyVerificationData.RecordStatus       : '',
      ScopeSerial          : dailyVerificationData ? dailyVerificationData.ScopeSerial        : '',
      Serial               : dailyVerificationData ? dailyVerificationData.Serial             : '',
      EnableManualOverride : dailyVerificationData ? dailyVerificationData.EnableManualOverride: false,
      errors               : {}
    });
    this.parentController = parentController;
   
  }

  static key = 1;

}
    
export default GaugeManagementDailyVerificationRowController;
  