import BaseController from '../../lib/BaseController';

class SelectedCustomerServiceLevelRowController extends BaseController {
  constructor(parentController, csl) {
    super('selectedCustomerServiceLevel-row-controller-' + SelectedCustomerServiceLevelRowController.key++, {
      customerDescriptionLevelId : csl ? csl.customerDescriptionLevelId : '',
      customerServiceLevel       : csl ? csl.customerServiceLevel       : {},
      requiresReading            : csl ? csl.requiresReading            : false,
      selected                   : csl ? csl.selected                   : false,
      originalRequiresReading    : csl ? csl.requiresReading            : false,
      originalSelected           : csl ? csl.selected                   : false,
      isDirty                    : false
    });

    this.parentController = parentController;
  }

  static key = 1;

  selectedChanged = () => {
    this.parentController.commit();
    this.commit();
  }

  requiresReadingChanged = () => {
    this.parentController.commit();
    this.commit();
  }

  isDirty = () => {
    this.state.isDirty = (
      (this.state.originalRequiresReading !== this.state.requiresReading) ||
      (this.state.originalSelected        !== this.state.selected)
    );
    return this.state.isDirty;
  }
}
    
export default SelectedCustomerServiceLevelRowController;
  