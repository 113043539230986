import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';

class GaugeManagementYearlyCalibrationRowController extends BaseController {
  constructor(parentController, yearlyCalibrationData) {
    super('gauge-yearly-calibration-reading-row-controller-' + GaugeManagementYearlyCalibrationRowController.key++, {
        calibrationId              : yearlyCalibrationData ? yearlyCalibrationData.calibrationId          :  Utils.emptyGuid,
        expirationDate             : yearlyCalibrationData ? yearlyCalibrationData.expirationDate: false,
        certificateNumber          : yearlyCalibrationData ? yearlyCalibrationData.certificateNumber : '',
        fileName                   : yearlyCalibrationData ? yearlyCalibrationData.fileName : '',
        imgUrl                     : yearlyCalibrationData ? yearlyCalibrationData.imgUrl : '',
        type                       : yearlyCalibrationData ? yearlyCalibrationData.type : '',
        createdBy                  : yearlyCalibrationData ? yearlyCalibrationData.createdBy: '',
        isCertificate              : yearlyCalibrationData ? yearlyCalibrationData.isCertificate : false,
        isSticker                  : yearlyCalibrationData ? yearlyCalibrationData.isSticker : false,
        selected                   : yearlyCalibrationData ? yearlyCalibrationData.selected : false,
        errors                     : {}
    });
    this.parentController = parentController;
   
  }

  static key = 1;
  selectedChanged = () => {
    this.parentController.commit();
    this.commit();
  }
}
    
export default GaugeManagementYearlyCalibrationRowController;
  