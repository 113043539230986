import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import CustomerFileNameRowController from '../controllers/CustomerFileNameRowController';
import co from '../../lib/Co';

class CustomerDocumentUploadController extends BaseController {
    constructor() {
        super('customer-document-upload', CustomerDocumentUploadController.getInitialState(''));
    
        this.adding = false;
        this.isSaveInProgress = false;
        this._validator = new Validator();
        this._validator.add('customerId', 'Customer Id', [{ required: true }]);
      }

    static getInitialState = (customerId) => {
        return {
            customerId: customerId,
            customerName: '',
            files: [],
            errors: {},
        };
    }

    canSave = () => {
        this._validator.validateAll(this.state);

        var errors = Object.keys(this.state.errors);
        if (!errors.length) {
        return true;
        }

        this.commit();
        return false;
    }

    addNewFile = (file) => {
        this.state.files.push(new CustomerFileNameRowController(this, file));
        this.commit();
    }

    mergeJob = (customer, uploadType) => {
        this.state.customerId = customer.customerId;
        customer.files.forEach(file => {
        if(uploadType == 'customerUpload' && file.IsCustomerStandard)
            { this.addNewFile(file); }
        else if(uploadType == 'tfmcUpload' && file.IsTfmcStandard)
            { this.addNewFile(file); }
        });
        this.commit();
    }

    load = (customerId, uploadType) => {
        if (customerId === Utils.emptyGuid) {
            this.adding = true;
            this.state = CustomerDocumentUploadController.getInitialState(customerId);
            this.commit();
        }
        else {
            this.adding = false;
        try
        {
            this.adding = false;
            this.state.files = [];
            this.commit();
            co(this, function* () {
                var results = yield ajax.get('lookup/getcustomerattachments', { customerId }); // UpdateJobDto
                this.state.files = [];
                this.commit();
                this.mergeJob(results, uploadType);
                this.commit();
            });
        }
        catch(err){
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }
        }
    }
    
    deleteFile = (file) => {
        const idx = this.state.files.indexOf(file);
        if (idx === -1) {
            return;
        }
        this.state.files.splice(idx, 1);
        this.commit();
    }

    save = (cb) => {
        if (!this.canSave()) {
            return;
        }
        var files = this.state.files.map(f => f.state.file);
        var filestoDelete = files.filter(file => file.recordStatus == 'D');
        if(filestoDelete.length){
            const uploadType = filestoDelete[0].IsCustomerStandard == true ? 'customerUpload' : 'tfmcUpload';
            this.saveDocument(() => { }, uploadType);
            return;
        }

        const s = this.state;
        const customerId = s.customerId;
        const parameters = {
            customerId: customerId,
            customerName: s.customerName,
            sapCustomerName: s.sapCustomerName,
            isRevenueGenerating: s.isRevenueGenerating,
            archived: s.archived,
            failInspection: s.failInspection,
            notes: s.notes,
            allowEquipmentStatusUpdate: s.allowEquipmentStatusUpdate,
            multiRejectCapable: s.multiRejectCapable
        };
        this.state.isSaving = true;
        this.commit();
        ajax.post('customer/save', parameters).then(() => {
        this.state.isSaving = false;
        this.commit();
        if (this.adding) {
            notification.action('success').post('Saved new Customer');
        }
        else {
            notification.action('success').post('Saved changes for ' + s.customerName);
        }

        // use in CustomerController to re-select edited form
        // if it's a new form, it will be the 1st entry in the list
        const postData = this.adding ? null : customerId;
        messages.channel('edit-customer').action('saved').post(postData);
        cb();
        }).catch(err => {
        this.state.isSaving = false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: UpdateCustomerDto, out: void
    }

    loadDocuments = (customerId, uploadType) => {
        if (customerId === Utils.emptyGuid) {
        this.adding = true;
        this.state = CustomerDocumentUploadController.getInitialState(customerId);
        this.commit();
        }
        else 
        {
            this.adding = false;
            this.state.files = [];
            this.state.description = '';
            this.commit();
            co(this, function* () {
                var results = yield ajax.get('lookup/getcustomerattachments', { customerId }); // UpdateJobDto
                this.state.files = [];
                this.commit();
                this.mergeJob(results, uploadType);
                this.commit();
            });
        }
    }

    saveDocument = (cb, uploadType) => {
        const s = this.state;
        const customerId = s.customerId;
        const parameters = {};
        parameters.customerId = customerId;
        if (!this.adding) {
            parameters.files = s.files.map(f => f.state.file);
        }
        this.state.isSaveInProgress = true;
        this.commit();
        co(this, function* () {
            try {
                yield ajax.post('customer/saveDocument', parameters); // in: UpdateJobDto, out: void

                cb();
                this.state.isSaveInProgress = false;
                this.commit();
                this.loadDocuments(customerId, uploadType);
            }
            catch (err) {
                notification.action('error').post({ msg: err.message, title: 'Error saving attachment' });
                this.state.isSaveInProgress = false;
                this.commit();
                this.loadDocuments(customerId,uploadType);
            }
        });
    }

    saveDescription = (cb, uploadType) => {
        const s = this.state;
        const customerId = s.customerId;
        const parameters = {};
        parameters.customerId = customerId;
        if (!this.adding) {
            parameters.descriptions = s.files.map(f => 
            { 
                return {
                documentId: f.state.file.id,
                documentDescription: f.state.description
                };
            });
        }
        this.state.isSaveInProgress = true;
        this.commit();
        co(this, function* () {
            try {
                yield ajax.post('customer/saveCustomerDescription', parameters); // in: UpdateJobDto, out: void
                notification.action('success').post('Description saved');

                cb();
                this.state.isSaveInProgress = false;
                this.commit();
                this.loadDocuments(customerId,uploadType);
            }
            catch (err) {
                notification.action('error').post({ msg: err.message, title: 'Error saving Description' });
                this.state.isSaveInProgress = false;
                this.commit();
            }
        });
    }
    

    uploadFile = (res, uploadType) => {
        if (this.state.files.find(f => f.state.file.fileName === res.file.name)) {
            notification.action('error').post({ msg: 'File name already exists', title: 'File Error' });
            return;
        }
        if (res && (res.file.size / 1048576).toFixed(1) < 25) {
            let name = '';
            if (navigator.userAgent.match(/iPad/i) != null) {
                name = res.file.name.split('.')[0] + '_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' }) + '.' + res.file.name.split('.')[1].toLowerCase();
            }
            else {
                name = res.file.name;
            }
            const newFile = {
                id: Utils.newGuid(),
                fileName: name,
                size: res.file.size,
                altText: '',
                caption: '',
                file: res.file,
                fileType: res.file.name.split('.')[1].toLowerCase() || '',
                MimeType: res.file.type,
                imgUrl: res.imageUrl,
                recordStatus: 'A',
                isCustomerStandard : uploadType == 'customerUpload' ? true : false,
                IsTfmcStandard : uploadType == 'tfmcUpload' ? true : false
            };
            this.addNewFile(newFile);
            this.saveDocument(() => { }, uploadType);
        }
        else {
            notification.action('error').post({ msg: 'File size is larger than 25 MB', title: 'File Size Error' });
        }
        }
        
        deleteFile = (file) => {
        const idx = this.state.files.indexOf(file);
        if (idx === -1) {
            return;
        }
        this.state.files.splice(idx, 1);
        this.commit();
        }
}

export default CustomerDocumentUploadController;