import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import buildControl from '../../components/FormBuilder';

class AdminQueryParametersDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => {
    if (result.Cancel) {
      return true;
    }
    const parameters = this.props.controller.state.query.parameters;
    parameters.forEach(parameter => parameter.value = this.props.controller.state[parameter.name]);
    return this.props.controller.loadReport();
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const parameters = controller.state.query.parameters;
    const controls = parameters.map((parameter, i) => {
      return <div key={i} className='col-sm-12'>
        {buildControl(controller, binder, parameter)}
      </div>;
    });
    const dialogHeight = (225 + (parameters.length * 75));

    return (
      <Dialog {...this.props} header={'Report Parameters'} width={500} height={dialogHeight} id='report-parameters-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <p className='h5 text-center mt-2'>Provide values for these parameters and click OK to load the report</p>
            <div className='col-sm-12 mt-2'>
              {controls}
            </div>
          </div>
        </Dialog.Body>
        <Dialog.OKCancelButtons />
      </Dialog>
    );
  }
}

export default AdminQueryParametersDialog;