import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Binder from '../../lib/Binder';
import GroupBadge from '../../components/GroupBadge';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';

const CustomerLocation = (props) => {
    const toggleSelection = (customerLocation) => {
        props.controller.toggleSelection(customerLocation);
    }

    const selectAndTransfer = (customerLocation) => {
        props.controller.selectAndTransfer(customerLocation);
    }

    const customerLocation = props.customerLocation;
    const className = 'customer-location ';

    return (
        <List.Item className={className + ' p-2'} active={customerLocation.selected} onClick={() => { toggleSelection(customerLocation); }} style={{ cursor: 'pointer' }} onDoubleClick={() => { selectAndTransfer(customerLocation); }} >{customerLocation.name}</List.Item>
    );
}


const UserCustomerLocations = (props) => {
    const selectNone = () => {
        props.controller.selectNoUserCustomerLocations();
    }

    const selectAll = () => {
        props.controller.selectAllUserCustomerLocations();
    }

    const controller = props.controller;
    const userCustomerLocations = controller.state.userCustomerLocations || [];
    const selectedLabel = userCustomerLocations.length + ' selected location' + ((userCustomerLocations.length === 1) ? '' : 's');

    return (
        <div>
            <div className='row'>
                <div className='col'>
                    <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
                    <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
                    <Badge className='m-1' variant='info'>{selectedLabel}</Badge>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Jumbotron className='locations-list'>
                        <List id='selected-location-list'>
                            {
                                userCustomerLocations.map(customerLocation => {
                                    return <CustomerLocation controller={controller} customerLocation={customerLocation} />;
                                })
                            }
                        </List>
                    </Jumbotron>
                </div>
            </div>
        </div>
    );
}

const TransferButtons = (props) => {

    const select = () => {
        props.controller.moveToSelected();
    }

    const unselect = () => {
        props.controller.removeFromSelected();
    }

    // const selectDisabled = !props.controller.state.allCustomerLocations.reduce((acc, curr) => {
    //   return acc.selected | curr.selected;
    // }, false);
    const selectDisabled = false;

    // const unselectDisabled = !props.controller.state.userCustomerLocations.reduce((acc, curr) => {
    //   return acc.selected | curr.selected;
    // }, false);
    const unselectDisabled = false;

    return (
        <div className='row'>
            <div className='col'>
                <div>
                    <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
                    <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect} >Unselect</Button>
                </div>
            </div>
        </div>
    );
}

const AllCustomerLocations = (props) => {
    const selectNone = () => {
        props.controller.selectNoCustomerLocations();
    }

    const selectAll = () => {
        props.controller.selectAllCustomerLocations();
    }

    const controller = props.controller;
    const allCustomerLocations = controller.getFilteredAllCustomerLocations();
    const availableLabel = allCustomerLocations.length + ' available location' + ((allCustomerLocations.length === 1) ? '' : 's');

    /*
            <div className='col-sm-12'>
            <div className='row col-sm-12'>
              <Search
                labelText='Customer Location filter'
                clearSearchFn={controller.clearFilterAllCustomerLocations}
                searchFn={controller.filterAllCustomerLocations}
                controller={controller}
                binder={binder}
                autoFocus='true'
                searchId='availableCustomerLocationsFilterTerm'
                placeholder='search customer locations'
              />
            </div>
            </div>
    */

    return (
        <div>
            <div className='row'>
                <div className='col'>
                    <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
                    <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
                    <Badge className='m-1' variant='info'>{availableLabel}</Badge>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Jumbotron className='locations-list'>
                        <List id='location-list'>
                            {
                                allCustomerLocations.map(customerLocation => {
                                    return <CustomerLocation key={customerLocation.id} controller={controller} customerLocation={customerLocation} />;
                                })
                            }
                        </List>
                    </Jumbotron>
                </div>
            </div>
        </div>
    );
}

class SelectUserCustomerLocationsDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel ? true : this.props.controller.save();

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const labelText = 'Select the customer locations to which {firstName} {lastName} should have access:'.interpolate(controller.state.user);

        return (
            <Dialog {...this.props} header={this.props.header} width={750} height={600} id='change-user-cust-location-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div className='row'>
                        <div className='col'>
                            <div className='row'><div className='col h6'>{labelText}</div></div>
                            <div className='row align-items-center'>
                                <div className='col-md-5'><AllCustomerLocations controller={controller} binder={binder} /></div>
                                <div className='col-md-2'><TransferButtons controller={controller} /></div>
                                <div className='col-md-5'><UserCustomerLocations controller={controller} binder={binder} /></div>
                            </div>
                        </div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons />
            </Dialog>
        );
    }
}

export default SelectUserCustomerLocationsDialog;