import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Search from '../components/Search';
import SortColumn from '../components/SortColumn';
import GroupBadge from '../../components/GroupBadge';
import ContainerController from '../controllers/ContainerController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import QRSticker from '../components/QRSticker';
import SelectAssetsForContainerDialog from '../dialogs/SelectAssetsForContainerDialog';
import SelectAssetsForContainerController from '../controllers/SelectAssetsForContainerController';
import Dialog from '../../components/Dialog';
import SAPService from '../services/SAPService';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import Table from '../../components/Table';
import Grid from '../../components/Grid';
import { SortType, sorter } from '../../lib/DataSorter';
import ActionIcon from '../../components/ActionIcon';

const ContainerList = (props) => {

    const [dir, setDir] = useState(SortType.unknown);
    const [key, setKey] = useState('');

    const dataSort = (fnData, sortKey) => {
        //props.controller.state.useHierarchyData = false;
       
        if (sortKey !== key) {
            setDir(SortType.ASC);
        }
    
        var newSortDir = sorter(fnData, sortKey, dir);
        if (newSortDir) {
            setKey(sortKey);
            setDir(newSortDir);
        }
    };

    const getSortDir = (columnKey) => {
        if (key == columnKey) {
            return dir
        }
        return SortType.unknown;
    }

    const getData = () => {
        return props.controller.state.containerList || [];
    }

    const getHierarchyData = () => {
        return props.controller.state.containers || [];
    }

    const handleDelete = (container) => {
        props.controller.delete(container);
    }

    const addContainerNode = (rows, container, indentLevel) => {
        const controller = props.controller;
        let className = container.hasAccess ? '' : 'no-container-access';
        className += (controller.state.selectedContainer.containerId === container.containerId) ? ' selected selected-container' : '';
        const padding = (5 + ((container.level * 20)) + 'px'); // indent the hierarchy
        const style = { paddingLeft: padding };
        let description = container.description; // default
        if (container.matchesSearch) {
            description = <span className={'text-danger font-weight-bold'}>{container.description}</span>;
        }

        const containerBadge = <Badge variant='info' className='container-count-badge' title='Number of containers'>{container.childContainerCount}</Badge>;
        rows.push(
            <Table.Row className={className} key={container.containerId} onClick={function () { controller.selectContainer(container); }}>

                <Table.Data className={className} style={{width:'30%'}}>{container.functionalLocation}</Table.Data>
                {/* <Table.Data className={className}>{container.description}</Table.Data> */}
                <Table.Data style={style} className={className}>{description}{containerBadge}</Table.Data>
                <Table.Data className={className} style={{width:'19%'}}>{container.locationCount}</Table.Data>
                <Table.Data className={className} style={{width:'15%'}}>{container.assetCount}</Table.Data>
                <Table.Data className={className} style={{width:'5%'}}>
                    { (container?.assetCount == 0 && container?.childContainerCount == 0 ) && <ActionIcon icon='fa-trash' className='remove-handle' title='Delete Container/Functional Location' action={() => handleDelete(container)} />}
                </Table.Data>
            </Table.Row> 
        );

        if (!container.containers || !container.containers.length) {
            return;
        }
        { controller.state.useHierarchyData && container.containers.forEach(child => addContainerNode(rows, child, indentLevel + 1)) }
    }

    const handleMoveContainer = () => {
        props.controller.moveContainer();
    }

    const colCount = 4;
    const controller = props.controller;
    const binder = props.binder;
    const data = controller.state.useHierarchyData ? getHierarchyData() : getData();
    let results = [];

    data.forEach(container => addContainerNode(results, container, 0));

    const customer = controller.state.customer;
    const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data colSpan={colCount} className='loading'>Loading customer containers</Table.Data></Table.Row>;
    const noResultsMessage = !loadingMessage && !!customer && !results.length && <Table.Row><Table.Data colSpan={colCount} className='no-search-results'>No containers exists for the selected customer</Table.Data></Table.Row>;
    let badgeText = controller.state.containerMatchCount; // Utils.pluralize('match', 'es', controller.state.containerMatchCount);
    if (controller.state.containers.length !== controller.state.totalContainerCount) {
        badgeText += ' / ' + controller.state.totalContainerCount;
    }
    const selectedContainer = controller.state.selectedContainer;
    const canMoveContainer = selectedContainer && selectedContainer.hasAccess;

    // cannot sort headers if a hierarchy is being displayed - only if all containers are at level 0 (i.e. no containers collection from any root nodes)
    const canSortHeaders = (getHierarchyData().length);
    var headers        =  canSortHeaders
                        ? <Table.Row>
                            <SortColumn className='bg-primary table-dark text-center' sortKey='functionalLocation' sortDir={getSortDir('functionalLocation')} getData={getHierarchyData} sorter={dataSort}>Functional Location</SortColumn>
                            <SortColumn className='bg-primary table-dark text-center' sortKey='description' sortDir={getSortDir('description')} getData={getHierarchyData} sorter={dataSort}>Container Description</SortColumn>
                            <SortColumn className='bg-primary table-dark text-center' sortKey='locationCount, description' sortDir={getSortDir('locationCount, description')} getData={getHierarchyData} sorter={dataSort}># Locations</SortColumn>
                            <SortColumn className='bg-primary table-dark text-center' sortKey='assetCount, description' sortDir={getSortDir('assetCount, description')} getData={getHierarchyData} sorter={dataSort}># Assets</SortColumn>
                            <SortColumn className='bg-primary table-dark text-center' sortKey='assetCount' sortDir={getSortDir('assetCount')} getData={getHierarchyData} sorter={false} sortable={false}>Delete</SortColumn>
                        </Table.Row>
                        : <Table.Row>
                            <Table.Head>Functional Location</Table.Head>
                            <Table.Head>Container Description</Table.Head>
                            <Table.Head># Locations</Table.Head>
                            <Table.Head># Assets</Table.Head>
                            <Table.Head></Table.Head>
                            </Table.Row>;

    return (
        <div className='row'>
            <div className='col-md-12'>
                <Search className='mb-2' id='containerSearchText' inputClassName='search-input' label='Containers' placeholder='description, functional location' autoFocus='true' bind={binder.bind('containerSearchText')} clear={controller.clearContainerSearchText} search={controller.containerSearch}>
                </Search>
            </div>
            <div className='col-md-12'>
                <div className='float-right row'>
                    <div><GroupBadge variant='warning' size='sm' className='m-1' pill={false} onClick={handleMoveContainer} disabled={!canMoveContainer}>Move...</GroupBadge></div>
                    <div><Badge className='m-1' variant='info'>{badgeText}</Badge></div>
                </div>
            </div>
            <div className='form-group col-md-12 container-search-results'>
                <Table id='container-results-table'>
                    <Table.Header>
                        {headers}
                    </Table.Header>
                    <Table.Body>
                        {results}
                        {loadingMessage}
                        {noResultsMessage}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

// const ContainerList = (props) => {

//     getData = () => {
//         return props.controller.state.containers || [];
//     }

//     addContainerNode = (rows, container, indentLevel) => {
//         const controller = props.controller;
//         var className = container.hasAccess ? '' : 'no-container-access';
//         className += (controller.state.selectedContainer.containerId === container.containerId) ? ' selected selected-container' : '';

//         var description = container.description; // default
//         if (container.matchesSearch) {
//             description = <span className={'search-term-match'}>{container.description}</span>;
//         }

//         var containerBadge = <Badge className='container-count-badge' title='Number of containers'>{container.childContainerCount}</Badge>;

//         rows.push(
//             <tr className={className} key={container.containerId} onClick={function () { controller.selectContainer(container); }}>

//                 <td className={className}>{container.functionalLocation}</td>
//                 {/* <td className={className}>{container.description}</td> */}
//                 <td style={style} className={className}>{description}{containerBadge}</td>
//                 <td className={className}>{container.locationCount}</td>
//                 <td className={className}>{container.assetCount}</td>
//             </tr>
//         );

//         if (!container.containers || !container.containers.length) {
//             return;
//         }
//         container.containers.forEach(child => this.addContainerNode(rows, child, indentLevel + 1));
//     }

//     handleMoveContainer = () => {
//         props.controller.moveContainer();
//     }

//     const columns = useMemo(
//         () => [
//             {
//                 Header: 'Functional Location',
//                 accessor: 'functionalLocation',
//                 Cell: (cellInfo) => {
//                     const rowClass = cellInfo.row.original.hasAccess ? '' : 'no-container-access';
//                     return <span className={rowClass}>{cellInfo.cell.value}</span>;
//                 }
//             },
//             {
//                 Header: 'Description',
//                 accessor: 'description',
//                 Cell: (cellInfo) => {
//                     const rowClass = cellInfo.row.original.hasAccess ? '' : 'no-container-access';
//                     const containerBadge  = <Badge title='Number of containers'>{cellInfo.row.original.childContainerCount}</Badge>
//                 return <span className={rowClass}>{cellInfo.cell.value} {containerBadge}</span>;
//                 }
//             },
//             {
//                 Header: '# Locations',
//                 accessor: 'locationCount',
//                 Cell: (cellInfo) => {
//                     const rowClass = cellInfo.row.original.hasAccess ? '' : 'no-container-access';
//                     return <span className={rowClass}>{cellInfo.cell.value}</span>;
//                 }
//             },
//             {
//                 Header: '# Assets',
//                 accessor: 'assetCount',
//                 Cell: (cellInfo) => {
//                     const rowClass = cellInfo.row.original.hasAccess ? '' : 'no-container-access';
//                     return <span className={rowClass}>{cellInfo.cell.value}</span>;
//                 }
//             }
//         ],
//         []
//     )
//         const controller = props.controller;
//         const data = getData();
//         let   results = [];
//         const customer = controller.state.customer;
//         const loadingMessage = controller.state.isLoading && 'Loading customer containers';
//         const noResultsMessage = !loadingMessage && !!customer && !results.length && 'No containers exists for the selected customer';
//         let   badgeText = controller.state.containerMatchCount; // Utils.pluralize('match', 'es', controller.state.containerMatchCount);
//         if (controller.state.containers.length !== controller.state.totalContainerCount) {
//             badgeText += ' / ' + controller.state.totalContainerCount;
//         }
//         const selectedContainer = controller.state.selectedContainer;
//         const canMoveContainer = selectedContainer && selectedContainer.hasAccess;
//         // cannot sort headers if a hierarchy is being displayed - only if all containers are at level 0 (i.e. no containers collection from any root nodes)
//         // var canSortHeaders = (data.filter(container => (container.containers && container.containers.length > 0)).length === 0);

//         return (
//             <div>
//                 <div className='col-sm-12'>
//                     <span className='h4' style={{ marginBottom: '5px' }}><i className='fa fa-search' />&nbsp;&nbsp;Containers</span>
//                     <Badge className='pull-right' style={{ marginBottom: '5px', marginRight: '0' }}>{badgeText}</Badge>
//                     <Button style={{ marginLeft: '5px', marginTop: '-2px', width: '80px' }} variant='warning' size='sm' className='pull-right' onClick={this.handleMoveContainer} disabled={!canMoveContainer}>Move...</Button>
//                 </div>

//                 <Search
//                     className='row col-sm-12'
//                     clearSearchFn={controller.clearContainerSearchText}
//                     searchFn={controller.containerSearch}
//                     controller={controller}
//                     binder={this.props.binder}
//                     searchId='containerSearchText'
//                     placeholder='description, functional location'
//                 />

//                 <div className='form-group col-sm-12 search-results-containers'>
//                     <table id='results-table' className='table table-striped table-bordered table-condensed table-hover table-full-width dataTable'>
//                         <thead>
//                             {headers}
//                         </thead>
//                         <tbody>
//                             {results}
//                             {loadingMessage}
//                             {noResultsMessage}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         );
//     }
// }

const AssetTab = (props) => {

    const handleAddAssets = () => {
        //props.controller.addAssetsToContainer();
        const customer = props.controller.state.customer;
        const container = props.controller.state.selectedContainer;
        Dialog.showDialog(<SelectAssetsForContainerDialog controller={new SelectAssetsForContainerController(customer, container)} header='Add Assets to Container' context={props.context} />);
    }

    const handleRemoveAssets = () => {
        props.controller.removeSelectedAssets();
    }

    const handleTransferAssets = () => {
        props.controller.transferAssets();
    }

    const handleRowClick = (assets) => {
        if (assets && assets.length) {
            props.controller.toggleSelection(assets);
        }
        else if (props.controller.state.filteredAssets && !assets.length) {
            props.controller.deselectAllAssets();
        }
    }

    const selectAllAssets = () => {
        props.controller.selectAllAssets();
    }

    const deselectAllAssets = () => {
        props.controller.deselectAllAssets();
    }

    const handleShip = () => {
        props.controller.shipAssets();
    }

    const getData = () => {
        return props.controller.state.filteredAssets || [];
    }

    const handleGenerateQRCodes = () => {
        const searchResults = props.controller.state.filteredAssets;
        const filterSearchResults = searchResults.filter(result => result.selected == true);
        const qrSerialValues = filterSearchResults.length ? filterSearchResults : searchResults;
        const generatedQRCodes = <div>{qrSerialValues.map(row => <QRSticker qrValue={row} />)}</div>;
        var newWindow = window.open('', '_blank');
        const title = 'QR code'
        newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="css/application.css"> </head><body>');
        ReactDOM.render(generatedQRCodes, newWindow.document.body);
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        $(newWindow).on('load', function () {
            newWindow.print();
        });
    }
    const controller = props.controller;
    const binder = props.binder;
    const selectedContainer = controller.state.selectedContainer;
    const data = getData();
    const loadingMessage = controller.state.isLoading && 'Loading container assets';
    const noResultsMessage = !selectedContainer ? ''
        : selectedContainer.hasAccess
            ? 'No assets exist in the selected container'
            : 'If you require access to the assets in container ' + selectedContainer.description + ', contact your InteServ Administrator';
    const noResultsRow = !loadingMessage && !data.length && noResultsMessage;
    const canProcessAssets = controller.getAssetsToProcess();
    const canGenerateQR = controller.state.filteredAssets.length;
    const canAddAssets = controller.state.selectedContainer;
    let heading = 'Assets';
    if (selectedContainer && (selectedContainer.description !== undefined)) {
        heading += ' – ' + selectedContainer.description;
    }
    let badgeText = Utils.pluralize('asset', 's', data.length);
    if (data.length !== controller.state.totalAssetCount) {
        badgeText += ' / ' + controller.state.totalAssetCount;
    }
    const isAdministrator = LoginService.isAdministrator || LoginService.isCoordinator;
    const selectedData = data.filter(d => d.selected);
    const selectionType = useMemo(() => {
        let type = 'none';
        if (data.length !== 0 && selectedData.length !== 0) {
            if (data.length === selectedData.length) {
                type = 'all';
            }
            else if (selectedData.length > 0) {
                type = 'some';
            }
            else {
                type = 'none';
            }
        }
        return type;

    }, [data.length, selectedData.length]);
    const columns = useMemo(
        () => [
            {
                Header: 'Serial Number',
                accessor: 'primarySerialNumber',
                Cell: (cellInfo) => <Form.Link to={'/receiving/' + cellInfo.row.original.assetId} value={cellInfo.cell.value} />
            },
            {
                Header: 'Equipment Number',
                accessor: 'equipmentNumber',
                Cell: (cellInfo) => {
                    let statusColourClass = 'status' + cellInfo.row.original.assetStatus;
                    if (cellInfo.row.original.assetStatus === 'Weld Repair' || cellInfo.row.original.assetStatus === 'Failed Inspection')
                        statusColourClass = 'asset-status statusWeldRepair'
                    if (cellInfo.row.original.assetStatus === 'OS/Machining Repair') 
                        statusColourClass = 'asset-status statusOSRepair'
                    return <span className={statusColourClass}>{cellInfo.cell.value}</span>;
                }
            },
            {
                Header: 'Status',
                accessor: 'assetStatus',
                Cell: (cellInfo) => {
                    let statusColourClass = 'status' + cellInfo.cell.value;
                    if (cellInfo.cell.value === 'Weld Repair' || cellInfo.cell.value === 'Failed Inspection')
                        statusColourClass = 'asset-status statusWeldRepair'
                    if (cellInfo.cell.value === 'OS/Machining Repair') 
                        statusColourClass = 'asset-status statusOSRepair'
                    return <span className={statusColourClass}>{cellInfo.cell.value}</span>;
                }
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'User Status',
                accessor: 'userStatus',
            },{
                Header: 'AWO',
                accessor: 'awoNumber',
            },
            {
                Header: 'Job Number',
                accessor: 'jobNumber',
            },
            
        ],
        []
    )

    return (
        <div className='mt-2 row'>
            <div className='col-md-12'>
                {!LoginService.isCustomer ? <Button icon='fa-plus-square' variant='success' size='sm' className='m-1' onClick={handleAddAssets} disabled={!canAddAssets}>Add ...</Button> : null}
                {!LoginService.isCustomer ? <Button icon='fa-exchange-alt' variant='info' size='sm' className='m-1' onClick={handleTransferAssets} disabled={!canProcessAssets}>Transfer ...</Button> : null}
                {!LoginService.isCustomer ? <Button icon='fa-trash' variant='danger' size='sm' className='m-1' onClick={handleRemoveAssets} disabled={!canProcessAssets}>Remove ...</Button> : null}
                <Button icon='fa-qrcode' variant='primary' size='sm' className='m-1' onClick={handleGenerateQRCodes} disabled={!canGenerateQR}> Print QR </Button>
                {!LoginService.isCustomer ? <Button icon='fa-truck fa-flip-horizontal' variant='success' size='sm' className='m-1' disabled={!canProcessAssets} onClick={() => { handleShip(); }}> Receive/Ship to</Button> : null}
            </div>
            <div className='col-md-12 mt-1'>
                <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAllAssets}>Select All</GroupBadge>
                <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={deselectAllAssets}>Select None</GroupBadge>
            </div>
            <div className='col-md-12'>
                {isAdministrator &&
                    <div>
                        <Search className='mb-2' id='assetFilterText' inputClassName='search-input' icon='fa-filter' label={heading} placeholder='serial number, status, description, equipment number, job number' autoFocus='true' bind={binder.bind('assetFilterText')} clear={controller.clearAssetFilterText} search={controller.filterAssets} />
                    </div>
                }
            </div>
            <div className='col-md-12'>
                <Badge className='float-right m-1' variant='info'>{badgeText}</Badge>
            </div>
            <div className='col-md-12 form-group search-results-assets'>
                <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} allowMultiSelect={true} showPagination={false} selectionType={selectionType} noDataText={loadingMessage || noResultsRow} />
            </div>
        </div>
    );
}

const LocationTab = (props) => {

    const handleAddLocations = () => {
        props.controller.addLocationsToContainer();
    }

    const handleRemoveLocations = () => {
        props.controller.removeSelectedLocations();
    }

    const handleRowClick = (location) => {
        const container = props.controller.state.selectedContainer;
        if (container && container.locations) {
            props.controller.toggleLocationSelection(location);
        }
    }

    const getData = () => {
        const container = props.controller.state.selectedContainer;
        if (container && container.locations) {
            return container.locations || [];
        }
        return [];
    }

    const getRowProps = () => {
        const rowProps = {};
        if (!props.controller.state.selectedContainer.hasAccess) {
            rowProps.canSelect = false;
        }
        return rowProps;
    }

    const controller = props.controller;
    const selectedContainer = controller.state.selectedContainer;
    const data = getData();
    const isAdministrator = LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isTechnicianWithoutDataClerk;
    const loadingMessage = controller.state.isLoadingLocations
        ? 'Loading container locations'
        : !data.length ? 'No locations are accessible from the selected container' : '';
    const canProcessLocations = selectedContainer.hasAccess && controller.getSelectedLocations().length;
    const canAddLocations = selectedContainer.hasAccess && controller.state.selectedContainer;
    const badgeText = Utils.pluralize('location', 's', data.length);
    let heading = 'Locations';
    if (selectedContainer && (selectedContainer.description !== undefined)) {
        heading += ' – ' + selectedContainer.description;
    }
    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'archived',
                Cell: (cellInfo) => {
                    const archiveClassName = 'archived';
                    const statusCell = (cellInfo.cell.value)
                        ? <span className={'status-column ' + archiveClassName}><i className='fa fa-archive' title='Location is archived' /></span>
                        : <span className='status-column' />;
                    return statusCell;
                }
            },
            {
                Header: 'Name',
                accessor: 'locationName',
                Cell: (cellInfo) => {
                    let className = !selectedContainer.hasAccess ? ' no-container-access' : '';
                    const spanFMCLocation = cellInfo.row.original.fmcLocation
                        ? <span className='fmc-location'>&nbsp;&nbsp;TechnipFMC</span>
                        : null;
                    const location = isAdministrator
                        ? <span className={className + ' d-flex justify-content-start'}><Form.Link to={'/customerlocations/' + cellInfo.row.original.customerLocationId} value={cellInfo.cell.value} />{spanFMCLocation}</span>
                        : <span className={className}>{cellInfo.cell.value}</span>
                    return location;
                }
            },
        ],
        []
    )

    return (
        <div className='row'>
            {isAdministrator &&
                <div className='col-md-12'>
                    <Button icon='fa-plus-square' variant='success' size='sm' className='m-1' onClick={handleAddLocations} disabled={!canAddLocations}>Add ...</Button>
                    <Button icon='fa-trash' variant='danger' size='sm' className='m-1' onClick={handleRemoveLocations} disabled={!canProcessLocations}>Remove ...</Button>
                </div>
            }

            <div className='col-md-12 mt-2'>
                <div><span className='h5 m-1'>{heading}</span></div>
                <div className='float-right'><Badge variant='info' className='m-1'>{badgeText}</Badge></div>
            </div>

            <div className='col-12'>
                <Grid id='results-table' columns={columns} onRowClick={handleRowClick} showPagination={false} getRowProps={getRowProps} data={data} noDataText={loadingMessage} />
            </div>
        </div>
    );
}

const EditContainer = (props) => {

    const [editButtonHandled, setEditButtonHandled] = useState(false);
    const [newButtonHandled, setNewButtonHandled] = useState(false);

    const handleNew = () => {
        setNewButtonHandled(true);
        props.controller.clearSelectedContainer();
        props.controller.handleNew();
    }

    const handleEdit = () => {
        setEditButtonHandled(true);
        props.controller.handleEdit();
    }
    
    const handleCancel = () => {
        setEditButtonHandled(true);
        props.controller.cancel();
    }

    const handleSave = () => {
        props.controller.saveContainer();
    }

    const binder = props.binder;
    const errors = props.controller.state.errors;
    const controller = props.controller;
    const canSave = true; // props.controller.canSave();
    const borderStyle = { borderBottom: 'solid 2px lightgray', marginBottom: '12px' };
    const issapFacility = SAPService.isSAPFacility();

    // To enable Edit button, either function location(first 4digits) should match main plant code or func location should empty
    var isEditButtonDisabled = true;
    if((controller.state.functionalLocation == null || props.controller.validFunctionalLocation() || controller.state.functionalLocation == '') || 
        (controller.state.functionalLocation && controller.state.functionalLocation?.substring(0,4) == controller.state.plantCode))
    {
        isEditButtonDisabled = false;
    }

    const funLoc = props.controller.state.isFunLoc
        ? <div className='col-md-4 func-loc-static no-gutters' ><Form.StaticData style={{ width: '190px', marginRight: '5px' }} label='Functional Location' value={controller.state.functionalLocation} /></div>
        : <div className='col-md-4 func-loc mr-1 no-gutters' ><Form.Input label='Functional Location' id='fldfuncLocation' bind={binder.bind('functionalLocation')} error={errors.functionalLocation}
            enabled={(editButtonHandled || newButtonHandled) && controller.state.canEditDesc} placeholder={'xxxx-xxxxx-x-xxxxx (or) xx-xxx-xxxxx'} /></div>;
    return (
        <div>
            <div className='d-flex align-items-center justify-content-between no-gutters'>
                <div className="col-md-2">
                    <div className="mb-1"><Button disabled={!controller.state.canNewButton} icon='fa-plus-circle' variant='success' size='sm' className='' onClick={handleNew}>Create New</Button></div>
                    <div><Button disabled={isEditButtonDisabled ? true : !controller.state.canEditButton} icon='fa-plus-circle' variant='primary' size='sm' className='' onClick={handleEdit}>Edit</Button></div>
                </div>
                {funLoc}
                <div className='col-md-3 container-description'><Form.Input label='Container Description' id='fldDescription' bind={binder.bind('description')} error={errors.description} className='required' enabled={(editButtonHandled || newButtonHandled) && controller.state.canEditDesc} /></div>
                {issapFacility && <div className='col-md-2 form-group plantCode pl-1'><Form.StaticData label='Maint Plant' value={controller.state.plantCode} /></div>}
                <div>
                <div className='col-md-1 save-button'>
                    <Button disabled={!controller.state.canEditDesc} variant='primary' size='sm' className='' onClick={handleSave}>Save</Button>
                </div>
                { controller.state.canEditDesc &&
                        <div className="mb-1 mt-1">
                            <Button id='btn-cancel canEdit' icon='fa-times' variant='secondary' size='sm' className='action-button' onClick={handleCancel}>Cancel</Button>
                        </div>
                }</div>
            </div>
            <div className='col-sm-12 col-xs-12' style={borderStyle} />
        </div>
    );
}

const ContainerPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fab fa-dropbox' />

class ContainerPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        this.props.controller.loadPlantCode();
    }
    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnicianWithoutDataClerk();
    }

    static defaultProps = {
        controller: new ContainerController()
    }

    setActiveTab = (index) => {
        this.props.controller.setActiveTab(index);
    }

    customerSearch = () => {
        var results = [{ id: LoginService.loginInfo.customerId, name: LoginService.loginInfo.customerName }];
        return results;
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const isAdministrator = LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isTechnicianWithoutDataClerk;
        const activeTab = controller.state.activeTab;

        return (
            <Page {...this.props} pageTitle='InteServ · Containers' id='container-page'>
                <ContainerPageHeader pageTitle={'Containers'} />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className='row'>
                                <span className='col-12 h6'>Customer</span>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className={isAdministrator ? 'col-md-9' : 'col-md-12'}><Form.AutoComplete className='required' placeholder='select a customer' bind={binder.bind('customer')} search={LoginService.isCustomer
                                            ? this.customerSearch
                                            : controller.customerSearch()} /></div>
                                        {isAdministrator && <div className='col-md-3' style={{ marginTop: '-20px', padding: 'unset   ' }}><Form.CheckBox label='Show All Results' bind={binder.bind('showAllResults')} /></div>}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <ContainerList controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-7'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <EditContainer controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <Tabs defaultActiveKey={2} onSelect={(k) => this.setActiveTab(k)}>
                                        <Tab eventKey={1} title='Locations'>
                                            <Tab.Pane>
                                                <LocationTab title='Locations' {...this.props} />
                                            </Tab.Pane>
                                        </Tab>
                                        <Tab eventKey={2} title='Assets'>
                                            <Tab.Pane>
                                                <AssetTab title='Assets'    {...this.props} binder={binder} />
                                            </Tab.Pane>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default ContainerPage;
