import React from 'react';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Utils from '../utility/Utils';
import notification from '../../services/Notification';
import JobSowGroupRowController from './JobSowGroupRowController';
import messages from '../../services/Messages';
import co from '../../lib/Co';

class JobSowGroupController extends BaseController {
    constructor(jobId) {
        super('get-jobsow-groups', {
            jobId: jobId,
            groups: [],
            errors: {}
        });
        this.saving = false;
        this.searchJobSowGroups(this.state.jobId);
    }

    listJobSowGroupRow = (group) => {
        this.state.groups.push(new JobSowGroupRowController(this, group));
        this.commit();
    }


    searchJobSowGroups = (jobId) => {
        try {
            co(this, function* () {
                var results = yield ajax.get('lookup/searchJobGroupNamesAndNumbers', jobId);

                if (results.length > 0) {
                    results.forEach(group => this.listJobSowGroupRow(group));
                    this.commit();
                }
            });
        }
        catch (err) {
            notification.action('error').post(err.message);
        }
    }

    validateSave = () => {
        let duplicateMessages = [];
        let canSave = true;
        const group = this.state.groups.map(group => group.state);
        co(this, function* () {
            const modifiedRecords = group.filter(group => group.recordStatus == 'M');
            for (let i = 0; i < modifiedRecords.length; i++) {
                const modifiedName = modifiedRecords[i].groupName ? modifiedRecords[i].groupName : '';
                const message = group.filter(group => ((group.groupName && (group.groupName.toLowerCase() == modifiedName.toLowerCase()) && group.groupNumber != modifiedRecords[i].groupNumber)));
                if (message && message.length) {
                    duplicateMessages.push(modifiedName.toLowerCase());
                }
            }
            let result = [...new Set(duplicateMessages)];
            if (result && result.length) {
                const names = result.join(', ');
                notification.action('error').post('Group ' + Utils.pluralize('Name', 's', result.length, false) + ' ' + names + ' already exists.');
                canSave = false;
            }
        });
        return canSave;
    }

    saveGroupName = () => {
        if (!this.validateSave()) {
            return false;
        }
        const groups = this.state.groups;
        for (let i = 0; i < groups.length; i++) {
            const parameters = {
                jobId: groups[i].state.jobId,
                groupNumber: groups[i].state.groupNumber,
                groupName: groups[i].state.groupName
            };
            this.saving = true;
            ajax.post('jobprogress/updateGroupName', parameters).then(response => {
                notification.action('success').post('Saved changes to the Group Name');
                messages.channel('edit-job-asset-details').action('saved').post();
                this.saving = false;
                this.commit();
                return true;
            }).catch(err => {
                this.saving = false;
                this.commit();
                notification.action('error').post({ msg: err.message, title: 'Error' });
                return false;
            });
        }
        return true;
    }
}

export default JobSowGroupController;

