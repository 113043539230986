import store from 'store';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import ServiceLevelHelper from '../services/ServiceLevelHelper';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class SelectServiceLevelController extends BaseController {
  constructor() {
    super('select-service-levels', {
      serviceLevels: [],
      tests: [],
      assets: [],
      indeterminateServiceLevels: [],
      jobId: '',
      jobNumber: '',
      customerName: '',
      selectedServiceLevel: '',
      selectedBillOfMaterial: '',
      hours: '0',
      minutes: '0',
      isSaveInProgress: false
    });
    this.data = '';
    this.load();
    messages.channel('service-levels').action('assign-data').subscribe((data) => {
      this.data = data;
    });
  }

  selectedBillOfMaterialChanged = (bom) => {
    this.clearBOMSelection();
    bom.selected = true;
  }

  clearBOMSelection = () => {
    this.state.serviceLevels.forEach(sl => {
      sl.billsOfMaterial.forEach(bom => bom.selected = false);
    });
  }

  load = () => {
    this.clear();
    var data = this.data;//store.get('select-service-levels');
    if (!data) {
      this.commit();
      return;
    }

    this.state.jobId = data.jobId;
    this.state.jobNumber = data.jobNumber;
    this.state.customerName = data.customerName;
    this.state.assets = data.assets.map(asset => asset);
    this.state.serviceLevels = ServiceLevelHelper.initializeServiceLevels(this.state.assets);
    this.state.tests = ServiceLevelHelper.findDistinctTests(this.state.serviceLevels);
    this.state.indeterminateServiceLevels = ServiceLevelHelper.findSelectedNonIntersectingServiceLevels(this.state.assets);
    this.state.selectedServiceLevel = '';
    this.state.selectedBillOfMaterial = '';

    const selectedServiceLevels = this.state.serviceLevels.filter(sl => sl.performed);
    if (selectedServiceLevels.length > 0) {
      let selectedServiceLevel = selectedServiceLevels[0];
      this.state.selectedServiceLevel = selectedServiceLevel;

      let billsOfMaterial = [];
      selectedServiceLevels.forEach(sl => sl.billsOfMaterial.filter(bom => bom.selected).forEach(bom => billsOfMaterial.push(bom)));
      if (billsOfMaterial.length === 1) {
        let selectedBillOfMaterial = { id: billsOfMaterial[0].bomId, name: billsOfMaterial[0].description };
        this.state.selectedBillOfMaterial = selectedBillOfMaterial;
      }
    }
    this.state.hours = 0;
    this.state.minutes = '0';
    this.state.isSaveInProgress = false;
    this.commit();
  }

  clear = () => {
    this.state.jobId = '';
    this.state.assets = [];
    this.state.serviceLevels = [];
    this.state.tests = [];
    this.state.indeterminateServiceLevels = [];
  }

  selectServiceLevel = (serviceLevel) => {
    // SAP facility - radio button click
    this.state.serviceLevels.forEach(sl => {
      sl.performed = false;
      sl.indeterminate = false;
    });
    serviceLevel.performed = true;
    this.state.selectedServiceLevel = serviceLevel;
    this.state.indeterminateServiceLevels = [];
    this.state.selectedBillOfMaterial = '';
    this.commit();
  }

  toggleServiceLevelSelection = (serviceLevel) => {
    // non-SAP facility - check box click
    serviceLevel.indeterminate = false;
    serviceLevel.performed = !serviceLevel.performed;
    this.state.selectedServiceLevel = serviceLevel;
    this.commit();
  }

  bomSearch = (billsOfMaterial) => {
    return billsOfMaterial.map(bom => {
      return {
        id: bom.bomId,
        name: bom.description
      };
    });
  }

  canSave = () => {
    // subsequent checks are related to Bill of Material which is SAP-specific
    // if it's not an SAP facility, no need to enforce these rules
    if (!SAPService.isSAPFacility()) {
      return true;
    }

    const serviceLevel = this.state.selectedServiceLevel;
    if (!serviceLevel) {
      notification.action('error').post('You must select a Service Level');
      return false;
    }

    if (!serviceLevel.billsOfMaterial || !serviceLevel.billsOfMaterial.length) {
      // No bills of material for this service level - OK to save
      return true;
    }

    if (!this.state.selectedBillOfMaterial) {
      notification.action('error').post('You must select a Bill of Material');
      return false;
    }

    return true;
  }

  save = (cb) => {
    if (!this.canSave()) {
      return;
    }
    const state = this.state;
    const hours = parseFloat(isNaN(state.hours) ? 0 : state.hours);
    const minutes = parseFloat(isNaN(state.minutes) ? 0 : state.minutes);
    const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
    const loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (value <= 0) {
        notification.action('error').post("Receiving Time should be greater than 0");
        return false;
      }
      if (parseFloat(value).toFixed(1) <= 0) {
        notification.action('error').post("After rounding to 1 decimal, time will become 0. Receiving Time should be greater than 0");
        return false;
      }
      // if(hours>23)
      // {
      //   notification.action('error').post("Hours cannot be more than 23");
      //   return false;
      // }
      // if(minutes>60)
      // {
      //   notification.action('error').post("Minutes cannot be more than 60");
      //   return false;
      // }
      // if(value<this.state.assets.length)  
      // {
      //   notification.action('error').post("Minimum 1 minute should be recorded for each asset");
      //   return false;
      // }  
      const availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (value > availableTime) {
        notification.action('error').post("Receiving Time should not be more than Available Production Time");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
    }
    const addedServiceLevelIds = this.state.serviceLevels.filter(serviceLevel => serviceLevel.performed && !serviceLevel.indeterminate).map(serviceLevel => serviceLevel.serviceLevelId);
    const removedServiceLevelIds = this.state.serviceLevels.filter(serviceLevel => !serviceLevel.performed).map(serviceLevel => serviceLevel.serviceLevelId);

    if (!addedServiceLevelIds.length && !removedServiceLevelIds.length) {
      notification.action('info').post({ msg: 'No changes requested', title: 'Service Level Changes' });
      cb();
      return;
    }

    const jobId = this.state.jobId;
    const jobNumber = this.state.jobNumber;
    const assetIds = this.state.assets.map(asset => asset.jobSOWId);
    let   billOfMaterialId = this.state.selectedBillOfMaterial && this.state.selectedBillOfMaterial.id;
    if (!billOfMaterialId) {
      billOfMaterialId = Utils.emptyGuid;
    }
    this.state.isSaveInProgress = true;
    this.commit();

    const parameters = {
      jobId: jobId,
      jobNumber: jobNumber,
      assets: assetIds,
      addedServiceLevels: addedServiceLevelIds,
      removedServiceLevels: removedServiceLevelIds,
      selectedBillOfMaterialId: billOfMaterialId,
      value: value,
      TransactionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      allAssets: this.state.assets
    };

    ajax.post('jobprogress/assignServiceLevels', parameters).then(() => {
      const modifiedCount = (addedServiceLevelIds + removedServiceLevelIds);
      const msg = 'Modified service level' + (modifiedCount === 1 ? '' : 's') + ' for ' + assetIds.length + ' asset' + (assetIds.length === 1 ? '' : 's');
      notification.action('info').post({ msg: msg, title: 'Service Level Changes' });
      messages.channel('assign-service-levels').action('saved').post({ jobId: jobId, assets: this.state.assets });
      this.state.isSaveInProgress = false;
      this.commit();
      cb();
    }).catch(err => {
      this.state.isSaveInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });// in: AssignServicelevelsDto, out: void
  }
}

export default SelectServiceLevelController;
