import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectUserFacilitiesController extends BaseController {
  constructor(user, userFacilities, allFacilities, currentFacility) {
    super('change-user-facilities', {
      user: user,
      userFacilities: Utils.clone(userFacilities),
      allFacilities: allFacilities,
      currentFacility: currentFacility
    });
  }

  selectNoFacilities = () => {
    this.state.allFacilities.forEach(facility => {
      facility.selected = false;
    });
    this.commit();
  }

  selectNoUserFacilities = () => {
    this.state.userFacilities.forEach(facility => {
      if (this.state.currentFacility !== facility) {
        facility.selected = false;
      }
    });
    this.commit();
  }

  selectAllFacilities = () => {
    this.state.allFacilities.forEach(facility => {
      facility.selected = true;
    });
    this.commit();
  }

  selectAllUserFacilities = () => {
    this.state.userFacilities.forEach(facility => {
      facility.selected = true;
    });
    this.commit();
  }

  toggleSelection = (facility) => {
    facility.selected = !facility.selected;
    this.commit();
  }

  moveToSelected = () => {
    this.state.allFacilities.forEach(facility => {
      if (facility.selected) {
        facility.selected = false;

        let found = false;
        for (let i in this.state.userFacilities) {
          if (this.state.userFacilities[i].id === facility.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.state.userFacilities.push(Utils.clone(facility));
        }
      }
    });

    // sort selected list
    this.state.userFacilities.sort(function (a, b) {
      return (a.name.localeCompare(b.name));
    });
    this.commit();
  }

  removeFromSelected = () => {
    let i = this.state.userFacilities.length;
    while (i--) {
      let facility = this.state.userFacilities[i];
      if (facility.selected) {
        this.state.userFacilities.splice(i, 1);
      }
    }
    this.commit();
  }

  selectCurrentFacility = (facility) => {
    facility.selected = true;
    this.state.currentFacility = facility;
    this.commit();
  }

  save = () => {
    const selectedfacilityIds = this.state.userFacilities.map(facility => { return facility.id; });   // use only the ids
    const user = this.state.user;
    const parameters = {
      userId: user.userId,
      currentFacilityId: this.state.currentFacility && this.state.currentFacility.id,
      selectedfacilityIds: selectedfacilityIds
    };

    return ajax.post('user/changeUserFacilities', parameters).then(() => {
      messages.channel('edit-user-facilities').action('saved').post();
      messages.channel('edit-user').action('saved').post(user);
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    }); // in: ChangeUserFacilitiesDto, out: void
  }
}

export default SelectUserFacilitiesController;
