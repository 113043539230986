import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import LoginService from '../services/LoginService';
import Authentication from '../services/authentication';
import DropZone from '../../components/DropZone';
import PressureTestSettingsTable from './PressureTestSettingsTable';
import CustomerDescriptionWallReadingsTable from './CustomerDescriptionWallReadingsTable';
import CustDescTorqueReadingsTable from './CustDescTorqueReadingsTable';
import EndConfigurationsTable from './EndConfigurationsTable';
import CustDescDimensionsTable from './CustDescDimensionsTable';
import EditCustomerDescriptionController from '../controllers/EditCustomerDescriptionController';
import GroupBadge from '../../components/GroupBadge';
import Roles from '../utility/Roles';

const checkboxStyle = {
  height: 24,
  width: 24,
  backgroundColor: '#F1F1F1'
}
const SelectedServiceLevelTable = (props) => {

  const data = [...props.items];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return controller.isDirty()
            ? <i className='fas fa-edit text-danger' />
            : <span></span>;
        }
      },
      {
        Header: 'Service Level',
        accessor: 'state.customerServiceLevel',
        Cell: (cellInfo) => {
          const serviceLevel = cellInfo.row.original.state.customerServiceLevel;
          const sapServiceLevel = serviceLevel.isSAPServiceLevel
            ? <span className='sap-service-level mt-1 ml-1'>&nbsp;SAP</span>
            : null
          return LoginService.isSuperUser
            ? <span className='d-inline-flex'>
              <Form.Link to={'/customerservicelevel/' + cellInfo.value.id} value={cellInfo.value.name} />
              {sapServiceLevel}
            </span>
            : <span>cellInfo.value.name</span>;
        }
      },
      {
        Header: 'Requires Reading',
        accessor: 'state.requiresReading',
        Cell: (cellInfo, binder) => {
          const requiresReadingEnabled = cellInfo.row.original.state.selected;
          return LoginService.isSuperUser
            ? <Form.CheckBox className='csl-checkbox' bind={binder.bind('requiresReading')} enabled={requiresReadingEnabled} disabled={!requiresReadingEnabled} />
            : <span>{Utils.formatBool(cellInfo.value)}</span>;
        }
      },
      {
        Header: 'Selected',
        accessor: 'state.selected',
        Cell: (cellInfo, binder) => {
          return LoginService.isSuperUser
            ? <Form.CheckBox className='csl-checkbox' bind={binder.bind('selected')} />
            : <span>{Utils.formatBool(cellInfo.value)}</span>;
        }
      },
    ],
    []);
  return (
    <div id='service-level-results'>
      <Grid id='results-table' columns={columns} data={data} editable={true} canAddNewRow={false} showPagination={false} noDataText='No service level exist yet' />
    </div>
  );
}

const EditForm = (props) => {

  let history = useHistory();

  const showImageDialog = (item) => {
    if (!item.hasDiagram && !item.hasManDescDiagram) {
      return;
    }

    let height = 700;
    let width = 660;
    let style = {
      backgroundColor: 'white',
      marginBottom: '8px'
    };
    const imgs = $('img');
    if (imgs.length) {
      let img = imgs[0];
      if (img.width > img.height) {
        style.width = '640px';
        height = (640 * img.height / img.width) + 120;
      }
      else {
        style.height = '580px';
        width = (580 * img.width / img.height) + 20;
      }
    }

    co(function* () {
      yield Dialog.showDialogWaitForResult(
        <Dialog.OKDialog width={width} height={height} header='Image Viewer'>
          <div id='image-dialog'>
            <img src={item.imgUrl + '?' + DateTimeFormatter.now()} style={style} />
          </div>
        </Dialog.OKDialog>
      );
    });
  }

  const handleUploadDiagram = (res) => {
    const newFile = {
      id: Utils.newGuid(),
      fileName: res.file.name,
      size: res.file.size,
      altText: '',
      caption: '',
      file: res.file,
      fileType: res.file.name.split('.')[1] || '',
      url: res.imageUrl
    };
    props.controller.uploadDiagram(res, newFile);
  }

  const handleRemoveDiagram = () => {
    props.controller.removeDiagram();
  }

  const handleAddNotes = (custDesc) => {
    if (!custDesc) {
      return;
    }
    history.push('/notes/' + AppConstants.NoteTypes.CustomerDescription + '/' + custDesc.custDescId);
  }

  const binder = props.binder;
  const controller = props.controller;
  const item = controller.state;
  const endConfigurationCount = item.endConfigurations.length;
  const endConfigurationBadge = Utils.pluralize('End Configuration', 's', endConfigurationCount);
  const wallReadingCount = item.wallReadings.length;
  const wallReadingBadge = Utils.pluralize('Wall Reading', 's', wallReadingCount);
  const torqueReadingCount = item.torqueReadings.length;
  const torqueReadingBadge = Utils.pluralize('Torque Reading', 's', torqueReadingCount);
  const dimensionCount = item.dimensions.length;
  const dimensionBadge = Utils.pluralize('Dimension', 's', dimensionCount);
  const pressureTestCount = item.pressureTestSettings.length;
  const pressureTestBadge = Utils.pluralize('Pressure Test', 's', pressureTestCount);
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const uploadReplaceDiagramText = item.hasDiagram
    ? 'Replace'
    : 'Upload';
  let serviceLevelCount = item.selectedServiceLevels.length;
  let selectedServiceLevelCount = item.selectedServiceLevels.filter(ssl => ssl.state.selected).length;
  let serviceLevelsBadge;
  if (serviceLevelCount === selectedServiceLevelCount) {
    serviceLevelsBadge = serviceLevelCount;
  }
  else {
    serviceLevelsBadge = selectedServiceLevelCount + ' of ' + serviceLevelCount;
  }
  serviceLevelsBadge += ' selected';
  const manDescLink = controller.adding
    ? item.mfgItemNo.name
    : LoginService.isSuperUser
      ? <Form.Link to={'/manufacturerdescription/' + item.manDescId} value={item.mfgItemNo.name} />
      : <span>{item.mfgItemNo.name}</span>;

  const canShowNotes = !controller.adding && item;
  const noteCountBadge = item.noteCount
    ? <Badge className='note-badge ml-1' variant='info'>{item.noteCount}</Badge>
    : null;

  const lastUpdated = item && item.dateUpdated
    ? (item.updatedBy && item.updatedBy.name + ' on ' + DateTimeFormatter.formatDate(item.dateUpdated))
    : 'never';
  const isCustomerDiagram = (!controller.adding && item.hasDiagram);
  const pSMUploadLabelText = item.psmLink != null && item.psmLink?.length > 0 ? "Update" : "Upload";
  const TechnicalDocLabel = item.technicalDocuments && item.technicalDocuments.length > 0 ? "Update" : "Upload";
  const isManufacturerDiagram = (!isCustomerDiagram && !controller.adding && item.hasManDescDiagram);
  const diagramLabel = isCustomerDiagram
    ? <span><span style={{ color: '#117FCE' }}>Customer</span> <span>Diagram</span></span>
    : isManufacturerDiagram
      ? <span><span style={{ color: '#717C45' }}>Manufacturer</span> <span>Diagram</span></span>
      : 'Diagram';
  const diagramImage = (!controller.adding)
    ? <img alt={item.imgAlt} src={item.imgUrl + '?' + DateTimeFormatter.now()} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '300px', maxWidth: '395px' }} onClick={() => showImageDialog(item)} />
    : null;
  const handleCheckBoxClick = () => {
    props.controller.toggleupdatePressureTestSection();
  }

  const showGaugesPartNo = () => {
    props.controller.showPSMDetails();
  }
  const showTechnicalDoc = () =>{
    props.controller.showTechnicalDoc();
  }
  const psmControl = LoginService.isAdministrator ?
    <div className='col-md-3'> <span className='psmLabel'>PSM <br></br>
      <Button variant='success' size='sm' icon='fa-upload' className='btn-test upload-btn m-1' disabled={false} onClick={showGaugesPartNo}>{pSMUploadLabelText}</Button>
    </span> </div> : null;
const TechnicalDocControl = LoginService.isAdministrator || LoginService.isSuperUser ?
<div className='col-md-3'> <span className='psmLabel'>Technical Documents<br></br>
  <Button variant='success' size='sm' icon='fa-upload' className='btn-test upload-btn m-1' disabled={false} onClick={showTechnicalDoc}>{TechnicalDocLabel}</Button>
</span> </div> : null;
  return (
    <div className='col-sm-12'>
      <Form.Header className='alert-warning'>
        <div className='col-md-12'>
          <div className='d-flex justify-content-between row'>
            <div>
              <span>Description Information</span>
            </div>
            <div>
              <Badge variant='info' className=''>{item.assetCount} Assets</Badge>
            </div>
          </div>
        </div>
      </Form.Header>
      <div className='row'>
        <div className='col-md-12'><Form.StaticData label='Description' value={item.description} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.StaticData label='Last Updated' value={lastUpdated} /></div>
        <div className='col-md-3'><Form.StaticData label='Family Code' value={item.familyCode.name} /></div>
        <div className='col-md-3'><Form.StaticData label='Nominal Size' value={item.nominalSize.name} /></div>
        <div className='col-md-3'><Form.StaticData label='Length' value={item.length.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.StaticData label='Style' value={item.style.name} /></div>
        <div className='col-md-3'><Form.StaticData label='Manufacturer' value={item.manufacturer.name} /></div>
        <div className='col-md-3'><Form.StaticData label='CWP' value={item.cwp.name} /></div>
        <div className='col-md-3'><Form.StaticData label='Service' value={item.service.name} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.StaticData label='Degree' value={item.degree.name} /></div>
        <div className='col-md-3'><Form.StaticData label='ID' value={item.id.name} /></div>
        <div className='col-md-3'><Form.StaticData label='OD' value={item.od.name} /></div>
        <div className='col-md-3'><Form.StaticData label='Mfg Part #' value={manDescLink} /></div>
      </div>
      <div className='row'>
        <div className='col-md-3'><Form.StaticData label='Alt Part #' value={item.altItemNo} /></div>
        {psmControl}
        {TechnicalDocControl}
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span>End Configurations</span></div>
              <div><Badge variant='info'>{endConfigurationBadge}</Badge></div>
            </div>
          </Form.Header>
          <EndConfigurationsTable controller={controller} binder={binder} enabled={false} />
        </div>

        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Wall Readings</span></div>
              <div>
                {LoginService.isSuperUser && <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={controller.adding} className='mr-1' onClick={() => { controller.addNewWallReadingRow(); }}>Add ...</GroupBadge>}
                <Badge variant='info'>{wallReadingBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <CustomerDescriptionWallReadingsTable controller={controller} binder={binder} />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <Form.Header id='torque-header' className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Torque Readings</span></div>
              <div>
                <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={controller.adding} className='mr-1' onClick={() => { controller.addNewTorqueReadingRow(); }}>Add ...</GroupBadge>
                <Badge variant='info'>{torqueReadingBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <CustDescTorqueReadingsTable controller={controller} binder={binder} />
        </div>

        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span>Dimensions</span></div>
              <div>
                <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={controller.adding} className='mr-1' onClick={() => { controller.addNewDimensionRow(); }}>Add ...</GroupBadge>
                <Badge variant='info'>{dimensionBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <CustDescDimensionsTable controller={controller} binder={binder} />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <Form.Header className='alert-warning'>
            <div className='d-flex justify-content-between'>
              <div><span> Pressure Tests</span></div>
              {(isSuperUser || LoginService.isAdministrator) && <div className=' col-md-6 d-inline-flex'><input type='checkbox' style={checkboxStyle} checked={item.updatePressureTests} onChange={() => handleCheckBoxClick()} className='mr-2' /><Form.Label name='Update Pressure Tests' style={{ marginTop: 3 }} /></div>}
              <div>
                {LoginService.isSuperUser && <GroupBadge variant='success' pill={false} icon='fa-plus-circle' disabled={controller.adding || isSuperUser} className='mr-1' onClick={() => { controller.addNewPressureTestRow(); }}>Add ...</GroupBadge>}
                <Badge variant='info'>{pressureTestBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <PressureTestSettingsTable pressureTestSettings={item.pressureTestSettings} controller={controller} binder={binder} editable={LoginService.isAdministrator} />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <Form.Header className='alert-warning diagram-label col-md-12'>
            <div className='d-flex justify-content-between'>
              <div>{diagramLabel}</div>
              {LoginService.isSuperUser &&
                <div className='d-flex' style={{ maxWidth: 246 }}>
                  <DropZone onDrop={handleUploadDiagram} multiple={false} disabled={controller.adding} style={{ borderStyle: 'none', padding: 'unset' }} width={180} height={23} >
                    <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
                    <GroupBadge type='file' variant='success' pill={false} icon='fa-upload' disabled={controller.adding}>{uploadReplaceDiagramText}</GroupBadge>
                  </DropZone>
                  <div className='remove-button'>
                    <GroupBadge variant='danger' icon='fa-trash' pill={false} onClick={handleRemoveDiagram} disabled={controller.adding || !item.hasDiagram}>Remove</GroupBadge>
                  </div>
                </div>
              }
            </div>
          </Form.Header>
          <div className='col-sm-6'>
            <div>
              {diagramImage}
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span> Notes </span></div>
              <div>
                {LoginService.isSuperUser && <GroupBadge icon='fa-comments' disabled={!canShowNotes} variant='info' pill={false} className='' onClick={() => { handleAddNotes(item); }}>Notes ...</GroupBadge>}
                {noteCountBadge}
              </div>
            </div>
          </Form.Header>
          <div className='row'>
            <div className='col-md-6'>Customer Notes<Form.TextArea rows={9} bind={binder.bind('notes')} readOnly={!LoginService.isSuperUser} /></div>
            <div className='col-md-6'>Manufacturer Notes<Form.TextArea readOnly rows={9} bind={binder.bind('manufacturerNotes')} /></div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <Form.Header className='alert-warning col-md-12'>
            <div className='d-flex justify-content-between'>
              <div><span> Selected Service Levels </span></div>
              <div>
                <Badge variant='info'>{serviceLevelsBadge}</Badge>
              </div>
            </div>
          </Form.Header>
          <SelectedServiceLevelTable controller={controller} items={item.selectedServiceLevels} />
        </div>
      </div>
    </div>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleSave = () => {
    props.controller.save((custDescId) => {
      // after saving - navigate to same page with new id in address bar
      history.replace('/customerdescription/' + custDescId);
    });
  }

  const handleClose = () => {
    history.goBack();
  }
  return (
    <div className='col-md-12 mt-1'>
      <div className='d-flex justify-content-end'>
        {LoginService.isSuperUser && <Button icon='fa-save' variant='primary' size='sm' id='btn-save' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving}> Save</Button>}
        <Button icon='fa-times' variant='secondary' size='sm' id='btn-cancel' className='m-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const EditCustomerDescriptionPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class EditCustomerDescriptionPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const custDescId = this.props.match.params.custDescId;
    const query = this.props.location.queryData;
    const manDescId = query && query.manDescId;
    const customer = query && query.customer;
    this.props.controller.load(custDescId, manDescId, customer);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandSuperUser();
  }

  static defaultProps = {
    controller: new EditCustomerDescriptionController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const customerName = controller.state.customer && controller.state.customer.name;
    const title = (LoginService.isSuperUser ? (controller.adding ? 'Create ' : 'Edit ') : '') + 'Customer Description' + (customerName ? (' — ' + customerName) : '');

    return (
      <Page {...this.props} className='cust-desc-background' pageTitle='InteServ · Edit Customer Description' id='edit-cust-desc-page'>
        <EditCustomerDescriptionPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <EditForm controller={controller} binder={binder} />
            <Buttons controller={controller} binder={binder} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default EditCustomerDescriptionPage;