import React, { useMemo } from 'react';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import SignaturePad from '../../components/SignaturePad';
import Jumbotron from '../../components/Jumbotron';
import ShippingTicketController from '../controllers/ShippingTicketController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Dialog from '../../components/Dialog';
import ShippingTimeController from '../controllers/ShippingTimeController';
import ShippingTimeDialog from '../dialogs/ShippingTimeDialog';
import Grid from '../../components/Grid';
import Search from '../components/Search';

const SearchHeader = (props) => {

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }

        props.controller.search();
    }

    const handleMouseSearch = () => {
        props.controller.search();
    }

    const clearSearch = () => {
        props.controller.clearSearchText();
    }

    const binder = props.binder;
    const controller = props.controller;
    const formatFacilityResult = item => {
        const spanSAP = item.result.useSAP ?
            <span className='user-facility-sap pull-right select2-tag'> SAP</span> : null;
        if (item.result.useSAP) {
            return <span>{item.text} {spanSAP}</span>;
        }
        return <span>{item.text}</span>;
    };
    const isSAPRep = LoginService.isSAPRep;
    const isAdministrator = LoginService.isAdministrator;
    return (
        <div {...props} id='search-criteria'>
            <div className='row'>
                <div className='search-container col-md-6' onKeyDown={handleKeyboardSearch}>
                    <Search type='number' className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Shipping Ticket Number' placeholder='exact match required' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
                </div>

                <div id='customer-search' className='col-md-6'>
                    <Form.AutoComplete label='Customers' prependLabelIcon='fa fa-filter' bind={binder.bind('customer')}
                        placeholder='Select a customer to filter your results'
                        search={controller.customersSearch()} />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-4' onKeyDown={handleKeyboardSearch}>
                { SAPService.isSAPFacility() ?
                    <div><Form.Input type='number' label='Job Number' prependLabelIcon='fa fa-filter' className='search-input uppercase' placeholder='allows partial matches'
                        bind={binder.bind('jobNumber')} /></div> :
                        <Form.Input label='Job Number' prependLabelIcon='fa fa-filter' className='search-input uppercase' placeholder='allows partial matches'
                        bind={binder.bind('jobNumber')} />
                }
                </div>
                <div className='col-md-4' onKeyDown={handleKeyboardSearch}>
                    <Form.Input label='Container' prependLabelIcon='fa fa-filter' className='search-input' placeholder='allows partial matches'
                        bind={binder.bind('container')} />
                </div>
                <div className='col-md-4'>
                    <Form.CheckBox label='Include Shipped Tickets' prependLabelIcon='fa fa-filter' bind={binder.bind('includeShippedTickets')} />
                </div>

            </div>

            <div className='row'>
                <div className='col-md-4'>
                    <Form.CheckBox label='Include Tickets Without Assets' prependLabelIcon='fa fa-filter' bind={binder.bind('includeZeroAssetTickets')} />
                </div>

                {(isSAPRep && !isAdministrator) && 
                    <div className='col-md-4'>
                        <Form.AutoComplete label='Facility' prependLabelIcon='fa fa-filter' formatResult={formatFacilityResult}
                            formatSelection={formatFacilityResult}
                            bind={binder.bind('facility')}
                            placeholder='Select a facility to filter your results'
                            search={controller.facilitySearch()} />
                    </div>}

            </div>
        </div>
    );
}

const Results = (props) => {


    const handleRowClick = (ticket) => {
        props.controller.loadDetails(ticket);
    }

    const getData = () => {
        return props.controller.state.searchResults || [];
    }

    const data = getData();
    const isSAPRep = LoginService.isSAPRep;
    const isAdministrator = LoginService.isAdministrator;
    const showFacility = isSAPRep && !isAdministrator;

    const headers = useMemo(
        () => [
            {
                Header: 'Ticket Number',
                accessor: 'ticketNumber',
                Cell: cellInfo => cellInfo.row.original.assetCount > 0
                    ? <Form.Link to={'/shippingtickets/' + cellInfo.row.original.shippingTicketId} value={cellInfo.cell.value} />
                    : cellInfo.cell.value
            },
            {
                Header: 'Shipped Date',
                accessor: 'shippedDate',
                Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
            },
            {
                Header: 'Facility',
                accessor: 'facilityName',
                isVisible: showFacility
            },
            {
                Header: 'Job Number',
                accessor: 'jobNumber'
            },
            {
                Header: 'Status',
                accessor: 'shippingStatus'
            },
            {
                Header: 'Asset Count',
                accessor: 'assetCount'
            },
            {
                Header: 'Customer',
                accessor: 'customerName'
            },
            {
                Header: 'Shipping Location',
                accessor: 'shipToLocationName'
            },
        ],
        []
    )
    const columns = headers;
    return (
        <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} showPagination={true} noDataText='No shipping tickets meet the search criteria defined above' enableDefaultRowSelect={true} />
    );
}

const Details = (props) => {

    const controller = props.controller;
    const ticket = controller.state.selectedTicket;
    let shippingLocationElement = <Form.StaticData label='Shipping Location' value={ticket.shipToLocationName} />;
    if (LoginService.isAdministrator) {
        shippingLocationElement =
            <Form.Link to={'/customerlocations/' + ticket.shipToLocationId} label='Shipping Location' value={ticket.shipToLocationName} />;
    }
    let jobElement = <Form.StaticData label='Job Number' value={ticket.jobNumber} />;
    if (LoginService.isCoordinator) {
        jobElement = <Form.Link to={'/jobprogressassets/' + ticket.jobId} label='Job Number' value={ticket.jobNumber} />;
    }
    const signedByTechnicianSignatureElement = ticket.signedByTechnicianSignature ?
        <div className='col-sm-12'><SignaturePad className='required' canvasId='technician-signature' label='Shipped By Signature'
            signature={ticket.signedByTechnicianSignature} disabled={true}
            width={207} height={100} /></div> : null;
    const signedByCustomerSignatureElement = ticket.signedByCustomerSignature ?
        <div className='col-sm-12'><SignaturePad className='required' canvasId='customer-signature' label='Customer Signature'
            signature={ticket.signedByCustomerSignature} disabled={true}
            width={207} height={100} /></div> : null;
    const signedByCustomerNameElement = ticket.signedByCustomerName ?
        <div className='col-sm-12'><Form.StaticData label='Signed By Customer Name'
            value={ticket.signedByCustomerName} /></div> : null;
    const systemNotes = ticket.systemNotes || '';

    return (
        <Jumbotron {...props} id='shipping-ticket-description' className='form-group col-sm-12'>
            <div className='row'>
                <div className='col-sm-12'><Form.StaticData label='Ticket Number' value={ticket.ticketNumber} /></div>
            </div>
            <div className='row'>
                <div className='col-sm-12'><Form.StaticData label='Customer' value={ticket.customerName} /></div>
            </div>
            <div className='row'>
                <div className='col-sm-6'><Form.StaticData label='Container' value={ticket.container} /></div>
                <div className='col-sm-6'>{shippingLocationElement}</div>
            </div>
            <div className='row'>
                <div className='col-sm-12'>{jobElement}</div>
            </div>
            <div className='row'>
                <div className='col-sm-6'><Form.StaticData label='Shipping Date'
                    value={DateTimeFormatter.formatDate(ticket.shippedDate)} />
                </div>
                <div className='col-sm-6'><Form.StaticData label='Shipped By' value={ticket.shippedByName} />
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-6'><Form.StaticData label='Shipping Status'
                    value={ticket.shippingStatus} /></div>
                <div className='col-sm-6'><Form.StaticData label='Asset Count' value={ticket.assetCount} /></div>
            </div>
            <div className='row'>
                <div className='col-sm-12'><Form.StaticData label='Notes' value={ticket.notes} /></div>
            </div>
            <div className='row'>
                <div className='col-sm-12'><Form.StaticData label='System Notes'
                    value={Utils.makeParagraphElements(systemNotes, { margin: '0' })}
                    className='shipping-ticket-system-note' /></div>
            </div>
            <div className='row'>{signedByTechnicianSignatureElement}</div>
            <div className='row'>{signedByCustomerNameElement}</div>
            <div className='row'>{signedByCustomerSignatureElement}</div>
            {SAPService.isSAPFacility() && <div className='row'><div className='col-sm-12'><Form.StaticData label='POD Uploaded'
                value={DateTimeFormatter.formatDate(ticket.datePODUploaded)} />
            </div></div>}
        </Jumbotron>
    );
}

const Buttons = (props) => {

    const handleDeleteTicket = () => {
        props.controller.deleteTicket();
    }
    const handleAddTime = (ticket, canaddTime) => {
        Dialog.showDialog(<ShippingTimeDialog
            controller={new ShippingTimeController(ticket.shippingTicketId, canaddTime)} />);
    }

    const controller = props.controller;
    const ticket = controller.state.selectedTicket;
    const canDelete = ticket && !ticket.assetCount;
    const canaddTime = ticket.sapStatus == 'NOCO' || ticket.sapStatus == 'RPIG' || ticket.sapStatus == 'RPOR' || ticket.sapStatus == 'SHIP' || ticket.sapStatus == 'NOBL';
    const addTime = (SAPService.isSAPFacility() && SAPService.useTTS() && LoginService.loginInfo.isClockedIn && !LoginService.loginInfo.isClockedOut && ticket.jobNumber) ?
        <Dropdown.MenuItem icon='fa fa-clock fa-flip-horizontal' disabled={!ticket} onClick={() => {
            handleAddTime(ticket, canaddTime);
        }}>Add Shipping Time ...</Dropdown.MenuItem> : null;
    return (
        <div className='col'>
            <div className='row mb-1'>
                <Button icon='fa-pencil-alt' to={'/shippingtickets/' + ticket.shippingTicketId} disabled={!ticket || (ticket.assetCount === 0)}
                    variant='primary' size='sm' className='action-button m-1'>Ticket details...</Button>

                <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Actions'>
                    <Dropdown.MenuItem icon='fa-trash' className='remove-handle' size='2x' disabled={!canDelete} onClick={handleDeleteTicket}>Delete Ticket ...</Dropdown.MenuItem>
                    {addTime}
                </Dropdown.Button>
            </div>
        </div>
    );
}


const ShippingTicketPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-truck fa-flip-horizontal' />

class ShippingTicketPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }
    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
        //this.props.controller.loadCustomers();      
    }
    static defaultProps = {
        controller: new ShippingTicketController()
    }
    onPageChanged = (data) => {
        const pageLimit = data.pageLimit;
        const currentPage = data.currentPage;
        const offset = (currentPage - 1) * pageLimit;
        this.props.controller.state.pageLimit = pageLimit;
        this.props.controller.state.currentPage = currentPage;
        this.props.controller.state.offset = offset;
        this.props.controller.commit();
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const count = controller.state.searchResults.length;
        const activeLabel = Utils.pluralize('match', 'es', count);
        const totalAssetCount = controller.state.searchResults.reduce((acc, curr) => {
            return acc + curr.assetCount;
        }, 0);
        const totalAssetCountLabel = Utils.pluralize('total asset', 's', totalAssetCount);

        return (
            <Page {...this.props} pageTitle='InteServ · Shipping Tickets' id='shipping-tickets-page'>
                <ShippingTicketPageHeader pageTitle={'Shipping Tickets'} />
                <Page.Content>
                    <div className='row'>
                        <div {...this.props} className='col-md-8'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <SearchHeader controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12 result-head-top'>
                                    <div className='d-flex justify-content-end mb-1'>
                                        <Badge variant='info' className='mr-1'> {activeLabel}</Badge>
                                        <Badge variant='info' className='mr-1'> {totalAssetCountLabel}</Badge>
                                    </div>
                                    <Results controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>

                        <div {...this.props} className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Buttons controller={controller} />
                                </div>
                                <div className='col-md-12'>
                                    <Details controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}
export default ShippingTicketPage;
