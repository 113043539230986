import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Ajax from '../../services/Ajax';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import AppConstants from '../utility/AppConstants';

class AdhocTransactionRowController extends BaseController {
    constructor(parentController) {
      super('adhoc-transaction-row-controller-' + AdhocTransactionRowController.key++, {
        adhocTransactionId : '',
        operation          : '',
        value              : '0',
        unitOfMeasure      : '',
        notes              : '',
        errors             : {},
        hours              : '0',
        minutes            : '0',
        technician         : '',
        date               : ''
      });
  
      this.parentController = parentController;
      this._validator       = new Validator();
      // this._validator.add('operation', 'Operation', [{required: true}]);
      // this._validator.add('value'    , 'Value'    , [{required: true}]);   
    }
     
    static key= 1;
  
    operationChanged = () => {
      this.state.unitOfMeasure = this.state.operation ? this.state.operation.unitOfMeasure : '';
      this.state.value         = 0;
      this.state.hours         = 0;
      this.state.minutes       = 0;   
      this.commit();
      if (this.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Dollars || this.state.unitOfMeasure === AppConstants.AdhocOperationUnitsOfMeasure.Quantity) {  
          this.clearValues();
      }
      else
        this.setValues();
    }

    setValues = () =>
    {
      this.state.technician=LoginService.loginInfo.fullName;
      this.state.date=DateTimeFormatter.formatDate(DateTimeFormatter.today());
      this.commit();
    }

    clearValues = () =>
    {
      this.state.technician='';
      this.state.date='';
      this.commit();
    }

    removeRow = () => {
      this.parentController.removeRow(this);
    }
  }
  
  export default AdhocTransactionRowController;
  