import React from 'react';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Keypad from '../../components/Keypad';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import SAPService from '../services/SAPService';
import AppConstants from '../utility/AppConstants';
import Roles from '../utility/Roles';

class AssemblyOperationPassedDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
    //this.props.controller.getInspectionNotes();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  handleKeyPressOnDate = (evt) => {
    return false;
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const isSAPFacility = SAPService.isSAPFacility();
    const isOSRepair = controller.state.isOSRepair;
    const searchController = controller.searchController;
    const assetCount = controller.state.configs.length;
    const testName = controller.state.testName;
    const value = binder.bind('operationMinutes')();
    const errors = controller.state.errors;
    const title = <h5 className='col-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '5px' }}>Please confirm you wish to pass the <span className='font-weight-bold text-primary'>{testName}</span> operation for the {Utils.pluralize('selected group', 's', assetCount)}</h5>
    const msg = <h6>Last chance to record some time against {(assetCount === 1 ? 'this ' : 'these ')} {Utils.pluralize(' config', 's', assetCount, false)}</h6>;
    const facilityId = LoginService.loginInfo.facilityId;
    const notes = assetCount === 1
      ? <div className='col-md-12'><Form.TextArea id='notes' label='Notes' bind={binder.bind('notes')} maxLength='1000' /></div>
      : null;

    let formatResult = function (item) {
      let vendorBackgroundColor = '';
      if (item.result.type !== undefined) {
        switch (item.result.type.toLowerCase()) {
          case '3rd party':
            vendorBackgroundColor = 'success';
            break;

          case 'customer':
            vendorBackgroundColor = 'info';
            break;

          default:
            break;
        }
        if (vendorBackgroundColor !== '') {
          return <span>{item.text}<Badge variant={vendorBackgroundColor} className='float-right result-span'>{item.result.type}</Badge></span>;
        }
        return <span>{item.text}</span>;
      }
      return <span>{item.text}</span>;
    };


    return (
      <Dialog {...this.props} allowKeyDownEvent={true} className='pass-dialog' header={this.props.header} modal={true} width={625} height={550} id='pass-test-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form' id='pass-test-dlg-form'>
            <div className='row'>
              {title}
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-6' style={{ borderRight: 'solid 1px #D8D8D8' }}>
                    <div className='text-left'>{msg}</div>
                    <div className='text-center keypad-value my-3'>{value} min</div>
                    <Keypad className='text-center inspection-keypad my-3' bind={binder.bind('operationMinutes')} allowDecimal={true} minValue={0} maxValue={720} />
                  </div>
                  <div id='pass-dialog-right-div' className='col-md-6'>
                    <div className='col-md-12' style={{ marginTop: '20px' }}>
                      <Form.AutoComplete formatResult={formatResult} formatSelection={formatResult} label='3rd party Vendor / Customer' hint='optional' bind={binder.bind('vendor')} search={searchController.vendorSearch()} />
                    </div>
                    {notes}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
      </Dialog>
    );
  }
}

export default AssemblyOperationPassedDialog