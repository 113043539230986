import React, { useMemo } from 'react';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import Badge from '../../components/Badge';
import PageHeader from '../components/PageHeader';
import EditAssetDescriptionController from '../controllers/EditAssetDescriptionController';
import { useHistory } from 'react-router-dom';
import Authentication from '../services/authentication';
import SAPService from '../services/SAPService';
import Utils from '../utility/Utils';
import Grid from '../../components/Grid';
import AppConstants from '../utility/AppConstants';

const EditForm = (props) => {
  const handleSearchProperties = (evt) => {
    evt.preventDefault();
    props.controller.handleSearch(AppConstants.eSearchType.Properties);
  }

  const controller = props.controller;
  if (!controller.state.customer) {
    return null;
  }

  const binder = props.binder;

  let rfidSaveWarning = controller.hasRfidTag()
    ? controller.canSaveWithRfidTag()
      ? 'This asset has an RFID tag associated with it. As an Administrator, you are permitted to save changes but should be aware of the dangers of doing so.'
      : 'This asset has an RFID tag associated with it. Changes to the serial numbers are not permitted.'
    : '';
  let elementRfidWarning = controller.hasRfidTag()
    ? <div className='col-md-12 sap-error'>{rfidSaveWarning}</div>
    : '';
  return (
    <div>
      <div className='border-secondary  mb-2'>
        <div className='row'>
          <div className='col-md-3'>
            <Form.StaticData label='Customer' id='customer' value={controller.state.customer.name} />
          </div>
          <div className='col-md-3'>
            <Form.StaticData label='Serial Number' id='serialnum' value={controller.state.asset.primarySerialNumber} />
          </div>
          <div className='col-md-3'>
            <Form.StaticData label='Equipment Number' id='equipmentnum' value={controller.state.equipmentNumber} />
          </div>
          </div>
          <div className='row'>
          <div className='col-md-6'>
            <Form.AutoComplete label='Mfg Part #' bind={binder.bind('mfgPartNumber')} search={controller.mfgItemSearch()} />
          </div>
          <div className='col-md-3'>
            <Form.AutoComplete label='Family Code' bind={binder.bind('familyCode')} search={controller.familyCodeSearch()} />
          </div>
          <div className='col-md-3'>
            <Form.AutoComplete label='Length' bind={binder.bind('length')} search={controller.lengthSearch()} />
          </div>
        </div>
        <div className='row'>
        
          <div className='col-md-3'>
            <Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={controller.cwpSearch()} />
          </div>
          <div className='col-md-3'>
            <Form.AutoComplete label='Style' bind={binder.bind('style')} search={controller.styleSearch()} />
          </div>
          <div className='col-md-3'>
            <Form.AutoComplete label='Nominal Size' bind={binder.bind('nominalSize')} search={controller.nominalSizeSearch()} />
          </div>
          <div className='col-md-3'>
            <Form.AutoComplete label='Manufacturer' bind={binder.bind('manufacturer')} search={controller.manufacturerSearch()} />
          </div>
        </div>
        <div className='row'>          
          <div className='col-md-12'>          
            <Button icon='fa-search' variant='info' size='sm' className='m-1 action-button float-right' onClick={handleSearchProperties}>Search</Button>          
          </div>
        </div>
      </div>
      <div>       
        <div id='search-results'>
          <Results controller={controller} binder={binder} />
        </div>
          <div className='row'>
            <div className='col-md-3'><Form.AutoComplete label='Object Type' bind={binder.bind('objectType')} search={controller.objectTypeSearch()} /></div>
          </div>
          <div className='row'>
          <div className='col-md-3'><Form.Input id='create-asset-secondary-serial-number' label='Inventory Number' className='uppercase' bind={binder.bind('inventoryNumber')} /></div>
          </div>
          <div className='row'>
            <div className='col-md-3'>
            <span className='text-danger font-weight-bold'>
              Please note that if the Inventory Number field is left blank, the previous value will be reflected in SAP.
            </span>
            </div>
          </div>
      </div>
      {elementRfidWarning}
    </div>
  );
}

const Results = (props) => {
  const controller = props.controller;
  const data = controller.state.matchingAssets;
  const matchesCount = data.length;
  const totalCount = controller.state.totalAssets;
  const matchesLabel = (matchesCount === controller.state.totalAssets) || matchesCount === 0 ?  Utils.pluralize('match', 'es', matchesCount) :  matchesCount + ' / ' + totalCount + ' matches';
  const partNumberNotAvailable = (controller.state.selectedMatchingAsset && !controller.state.selectedMatchingAsset.mfgPartNumber)
                                ? <span className='text-danger font-weight-bold'>Warning: No Mfg Part # for the selected asset</span> 
                                : null;

  const handleRowClick = (asset) => {
    props.controller.selectAsset(asset);
  }

  const getRowProps = (row) => {
    const asset = row.original;
    let rowProps = { id: asset.id };
    if (!asset.mfgPartNumber && (asset.id === controller.state.selectedMatchingAsset.id)) {
      rowProps.className = 'bg-danger';
    }
    return rowProps;
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Mfg Part #',
        accessor: 'mfgPartNumber',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ],
    []
  )
  return (
    <div className='mb-3'>
      <div className='d-flex justify-content-between'>
        <div className='m-1'>
          <span className='font-weight-bold'>Matching Descriptions<Badge variant='info' className='ml-1'>{matchesLabel}</Badge></span>
        </div>
        <div>{partNumberNotAvailable}</div>
      </div>
      <div className={data && data.length >0 ? 'list-item-results' : ''}>
        <Grid id='results-table' columns={columns} data={data} showPagination={false} onRowClick={handleRowClick} showAllPages={true} getRowProps={getRowProps} noDataText='No items in this list' enableDefaultRowSelect={true} />
      </div>
    </div>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleSave = () => { 
      props.controller.save(() => {
      history.goBack();
    });
  }

  const handleCancel = () => {
    history.goBack();
  }
  const message = SAPService.isSAPFacility()
     ? <span className='text-danger font-weight-bold'>If you save this change, please submit a ticket to notify the InteServ help desk.</span>
   
    : null;

  return (
      <div className='edit-buttons mr-2'>
        {/* {message} */}
        <div className='float-right'>
          <Button id='btn-save' icon='fa-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave}>Save</Button>
          <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='m-1 action-button' onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
  );
}

const EditAssetDescriptionPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class EditAssetDescriptionPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const assetId = this.props.match.params.assetId;
    this.props.controller.load(assetId);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new EditAssetDescriptionController()
  }

  render = () => {
    const controller = this.props.controller;
    const asset = controller.state.asset;
    const binder = new Binder(this);
    let title = 'Reassign Asset Description';
    // if (asset) {
    //   title += ' — ' + controller.state.asset.primarySerialNumber;
    // }

    return (
      <Page {...this.props} pageTitle='InteServ · Reassign Asset Description' id='reassign-asset-description-page'>
        <EditAssetDescriptionPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <EditForm controller={controller} binder={binder} />
              <Buttons controller={controller} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default EditAssetDescriptionPage;
