import React from 'react';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import SerialReadingHeaderRow from './SerialReadingHeaderRow';
import Table from '../../components/Table';

const SerialReadingRow = (props) => {
  const data = props.data;
  const belowMinClass = data.readingValue < data.minimumDimension ? 'statusScrapped' : '';
  const scopeSerial = props.serialWithReading ? <Table.Data>{data.scopeSerial}</Table.Data> : null;
  const width = props.serialWithReading ? 1 : 2;
  const updatedBy = props.serialWithReading ? <Table.Data><span>{data.updatedBy + ' on ' + DateTimeFormatter.formatDate(data.dateUpdated)}</span></Table.Data> : null;

  return (
    <Table.Row>
      <Table.Data />
      <Table.Data>{data.readingId}</Table.Data>
      <Table.Data>{data.newDimension}</Table.Data>
      <Table.Data >{data.minimumDimension}</Table.Data>
      <Table.Data colSpan={width}>{data.Previous_Reading}</Table.Data>
      <Table.Data colSpan={width} className={belowMinClass}>{data.readingValue}</Table.Data>
      {scopeSerial}
      {updatedBy}
    </Table.Row>
  );
}

const SerialUTReadingTable = (props) => {

  const handleToggleCollapsed = (calHeader) => {
    props.handleToggleCollapsed(calHeader);
  }

  const getTableHeader = () => {
    const AssetStatus = <Table.Head colSpan={1}>Asset Status</Table.Head>;
    return (
      <Table.Row className='service-history-job-header'>
        <Table.Head />
        <Table.Head />
        <Table.Head colSpan={1}>Serial Number</Table.Head>
        <Table.Head colSpan={1}>Group Name/Number</Table.Head>
        <Table.Head colSpan={3}>Description</Table.Head>
        {AssetStatus}
      </Table.Row>
    );
  }

  const getSerialHeader = (calHeader) => {
    return (
      <SerialReadingHeaderRow key={calHeader.key} handleToggleCollapsed={handleToggleCollapsed} showCollapse={true} serialWithReading={props.serialWithReading} controller={calHeader} />
    );
  }

  const getSerialReadingHeader = () => {
    const scopeSerial = props.serialWithReading ? <Table.Data>Scope Serial</Table.Data> : null;
    const updatedBy = props.serialWithReading ? <Table.Data>Updated by</Table.Data> : null;
    const width = props.serialWithReading ? 1 : 2;

    return (
      <Table.Row className='service-history-performed-test-header'>
        <Table.Data />
        <Table.Data>Reading Id</Table.Data>
        <Table.Data>New Dimension</Table.Data>
        <Table.Data>Min Dimension</Table.Data>
        <Table.Data colSpan={width}>Previous Reading</Table.Data>
        <Table.Data colSpan={width}>Reading Value</Table.Data>
        {scopeSerial}
        {updatedBy}
      </Table.Row>
    );
  }

  const addSerialReading = (rows, calHeader, calDetail) => {
    if (calDetail !== null) {
      rows.push(getSerialReadingHeader());
      const serialReadingRow = calDetail.map(row => {
        if (row.serial == calHeader.serial && row.jobSOWId == calHeader.jobSOWId) {
          return <SerialReadingRow key={row.key} serialWithReading={props.serialWithReading} data={row} />;
        }
      });
      rows.push(serialReadingRow);
    }
  }

  const addSerialReadingRows = (rows, calHeader, calDetail, job) => {
    rows.push(getSerialHeader(job));
    if (!calHeader.collapsed) {
      addSerialReading(rows, calHeader, calDetail);
    }
  }

  const addSerialRows = (rows, dataHeader, dataRow) => {
    rows.push(getTableHeader());
    dataHeader.forEach(job => {
      addSerialReadingRows(rows, job.state, dataRow, job);
    });
  }

  const state = props.controller.state;
  const dataRow = props.serialWithReading ? state.serialWithReadings || [] : state.serialWithoutReadings;
  const dataHeader = props.serialWithReading ? state.serialWithReadingsHeader || [] : state.serialWithoutReadingsHeader;
  let results = [];
  addSerialRows(results, dataHeader, dataRow);
  const colCount = 4;
  const loadingMessage = state.searchInProgress && <Table.Row><Table.Data colSpan={colCount} className='loading'>Loading Serial UT readings</Table.Data></Table.Row>;
  const noResultsMessage = !loadingMessage && !results.length && <Table.Row><Table.Data colSpan={colCount} className='no-search-results'>No results for the current asset</Table.Data></Table.Row>;

  return (
    <div className='row'>
      <div className='serial-ut-reading col-sm-12'>
        <div id='serial-with-reading-table' className='search-results'>
          <Table className='table table-striped table-bordered table-condensed table-hover table-full-width dataTable'>
            <Table.Body>
              {results}
              {loadingMessage}
              {noResultsMessage}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default SerialUTReadingTable;
