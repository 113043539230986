import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants  from '../utility/AppConstants';

class ManDescTorqueReadingRowController extends BaseController {
  constructor(parentController, torqueReading, manDescId) {
    super('man-desc-torque-reading-row-controller-' + ManDescTorqueReadingRowController.key++, {
      manDescId               : manDescId,
      manDescTorqueReadingId  : torqueReading ? torqueReading.manDescTorqueReadingId : Utils.emptyGuid,
      readingId               : torqueReading ? torqueReading.readingId              : '',
      readingType             : torqueReading ? torqueReading.readingType            : AppConstants.ReadingType.Manufacturer,
      torqueLocation          : torqueReading ? {id: torqueReading.torqueLocationId, name: torqueReading.torqueLocationName}  : '',
      torqueRange             : torqueReading ? {id: torqueReading.torqueRangeId, name: torqueReading.torqueRangeName}  : '',
      errors                  : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('readingId',        'ID',               [{required: true}]);
    this._validator.add('Torque Location', 'torqueLocation',    [{required: true}]);
    this._validator.add('Torque Range', 'torqueRange',    [{required: true}]);
  }

  static key = 1;

  canRemoveRow = () => {
    return true;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeTorqueReadingRow(this);
  }
}
    
export default ManDescTorqueReadingRowController;
  