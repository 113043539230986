import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const List = (propsPrams) => {
    const props = {
        ...propsPrams
    };

    return (
        <ListGroup {...props}>
            {props.children}
        </ListGroup>
    );
}

List.Item = (propsPrams) => {
    const props = {
        ...propsPrams
    };

    if (props.to && !props.disabled) {
        props.as = Link;
    }

    return (
        <ListGroup.Item {...props}>
            {props.children}
        </ListGroup.Item >
    );
}

export default List;