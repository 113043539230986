import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import EditCustomerController from '../controllers/EditCustomerController';
import LoginService from '../services/LoginService';
import Roles from '../utility/Roles';

const EditForm = (props) => {

    const binder = props.binder;
    const controller = props.controller;
    const errors = controller.state.errors;
    const isPrototypeCustomer = controller.state.customerName === 'Prototype Customer Service Levels';
    const isSuperUser = LoginService.isInRole(Roles.SuperUser);

    return (
            <div className='col-sm-12'>
                 <div className='row'>
                <div className='col-sm-6'><Form.Input label='Customer Name' bind={binder.bind('customerName')} error={errors.customerName} className='required' disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className='col-sm-6'><Form.Input label='SAP Customer Name' bind={binder.bind('sapCustomerName')} disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className='col-sm-2'><Form.CheckBox label='Revenue Generating' bind={binder.bind('isRevenueGenerating')} enabled={!isPrototypeCustomer || !isSuperUser} disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className='col-sm-2'><Form.CheckBox label='Archived' bind={binder.bind('archived')} enabled={!isPrototypeCustomer || !isSuperUser} disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className='col-sm-3'><Form.CheckBox label='Multi-Reject Capable' bind={binder.bind('multiRejectCapable')} enabled={!isPrototypeCustomer || !isSuperUser} disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className='col-sm-2'><Form.CheckBox label='Gauge Management' bind={binder.bind('gaugeManagement')} enabled={!isPrototypeCustomer || !isSuperUser} disabled={isPrototypeCustomer} /></div>
                <div className='col-sm-2'><Form.CheckBox label='Fail Inspection' bind={binder.bind('failInspection')} enabled={!isPrototypeCustomer || !isSuperUser} disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className='col-sm-3 col-xs-4'><Form.CheckBox label='Allow Equipment Status Update' bind={binder.bind('allowEquipmentStatusUpdate')} enabled={!isPrototypeCustomer || !isSuperUser} disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className='col-sm-3 col-xs-4'><Form.CheckBox label='Expiration Date' bind={binder.bind('showExpirationDate')} enabled={!isPrototypeCustomer || !isSuperUser} disabled={isPrototypeCustomer || isSuperUser} /></div>
                <div className="m-2">
                    <Button id='btn-customerUpload' to={'/customers/documents/' + controller.state.customerId + '/' + 'customerUpload' + '/' + 'fromEditCustomerPage'} variant='primary' size='sm' className='m-1 action-button' >Customer Standard</Button>
                    <Button id='btn-tfmcUpload' to={'/customers/documents/' + controller.state.customerId + '/' + 'tfmcUpload' + '/' + 'fromEditCustomerPage'} style={{width:'auto'}} variant='primary' size='sm' className='m-1 action-button' >TFMC Standard</Button>
                </div>
                <div className='col-sm-12'><Form.TextArea label='Notes' bind={binder.bind('notes')} rows={3} /></div>
            </div>
        </div>
    );
}

const Buttons = (props) => {

    let history = useHistory();

    const handleSave = () => {
        props.controller.save(() => {
            history.goBack();
        });
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <div className='col-sm-12'>
            <div>
                <div className='edit-buttons d-flex justify-content-end mr-2'>
                    <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button>
                    <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleCancel}>Cancel</Button>
                </div>
            </div>
        </div>
    );
}

const EditCustomerPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

class EditCustomerPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        const id = this.props.match.params.customerId;
        this.props.controller.load(id);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();
    }

    static defaultProps = {
        controller: new EditCustomerController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const customerName = controller.state.customerName;
        const title = (controller.adding ? 'Create ' : 'Edit ') + 'Customer' + (customerName ? (' — ' + customerName) : '');

        return (
            <Page {...this.props} pageTitle='InteServ · Edit Customer' id='edit-customer-page'>
                <EditCustomerPageHeader pageTitle={title} />
                <Page.Content>
                    <div className='row'>
                        <EditForm controller={controller} binder={binder} />
                        <Buttons controller={controller} />
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default EditCustomerPage;