import  _     from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';
import $ from 'jquery';

class TorqueValueRowController extends BaseController {
  constructor(parentController, torqueValue) {
    super('torqueValue-row-controller-' + TorqueValueRowController.key++, {
      torqueValueId           : torqueValue ? torqueValue.torqueValueId           : Utils.emptyGuid,
      minimumReadingMappedId  : torqueValue ? torqueValue.minimumReadingMappedId  : Utils.emptyGuid,  
      location                : torqueValue ? torqueValue.location                : '',
      torqueRange             : torqueValue ? torqueValue.torqueRange             : '',
      isNotApplicable         : torqueValue ? torqueValue.isNotApplicable         : false,
      actualReading           : torqueValue ? torqueValue.actualReading           : '',
      errors                  : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this.load();
  }

  
static key = 1;

  load = () =>{
    if(!this.state.isNotApplicable){
      this._validator.add('actualReading', 'Actual Reading', [{required: true}]);
    }    
  }

  removeRow = () => {
    this.parentController.removeRow(this);
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

  removeErrorWithValue = (errors,value) =>{
    if(errors.indexOf(value) !== -1){
      errors.splice(errors.indexOf(value),1);
    }
  }

  removeErrorWithKey = (errors,key) =>{
    delete errors[key];
  }

  isNotApplicableChanged = () => {
    if (this.state.isNotApplicable) {
      const errors =  Object.keys(this.state.errors);;
      $('#'+this.key+'actualReading').removeClass('required');
      if(errors.length){
        this.removeErrorWithValue(errors, 'actualReading');
      }
    }
    else {
      this._validator.add('actualReading', 'Actual Reading', [{required: true}]);    
      this.removeErrorWithKey(this.state.errors, 'actualReading');
      $('#'+this.key+'actualReading').addClass('required');
    } 
    this.state.actualReading = '';
    this.commit();
   }
}


export default TorqueValueRowController;