import React, { useEffect, useMemo, useState } from 'react';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import PageHeader from '../components/PageHeader';
import ListController from '../controllers/ListController';
import Authentication from '../services/authentication';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import Search from '../components/Search';
import Searchers from '../utility/Searchers';


const ListItemSearchHeader = (props) => {
  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.filterItems();
  }

  const handleMouseSearch = () => {
    props.controller.filterItems();
  }

  const clearSearch = () => {
    props.controller.clearItemFilter();
  }

  const controller = props.controller;
  const binder = props.binder;
  const state = controller.state;

  return (
    <div className='search-criteria'>
      <div className='row'>
        <div className='col-sm-12 search-container' onKeyDown={handleKeyboardSearch}>
          <Search id='filterItemText' placeholder='filter text' label={state.selectedList.name} prependLabelIcon='fa-filter' bind={binder.bind('filterItemText')} clear={clearSearch} search={handleMouseSearch}>
            <div className='mb-1'>{state.selectedList.description}</div>
          </Search>
        </div>
      </div>
    </div>
  );
}

const ListItemResults = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  const getData = () => {
    return controller.getFilteredItems();
  }
  const data = getData();
  const matchesCount = data.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const handleRowClick = (item) => {
    const selectedItem = { ...props.controller.state.selectedItem};
    props.controller.selectItem(item);
    const id = document.getElementById('listItemName');
    if(id){
          id.focus();      
    }
  }

  useEffect(() => {
    var updatedItems = props.controller.getFilteredItems();
    if(updatedItems){
      props.controller.selectItem(updatedItems[0]);
    }
  }, [controller.state.activeOnly]);
  
  const getRowProps = (row) => {
    const item = row.original;
    const selectedItem = { ...props.controller.state.selectedItem};
    const rowProps = { isSelected: (item.listId && selectedItem.id && item.id === selectedItem.id), onClick: handleRowClick};
    return rowProps;
  }

  const columns = useMemo(
    () => [
      {
        Header: 'List Items',
        accessor: 'name',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: cellInfo => <span>{Utils.formatBool(cellInfo.cell.value)}</span>
      },
    ],
    []
  )
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div className='m-1'><Form.CheckBox label='Active Only' bind={binder.bind('activeOnly')} /></div>
        <div className='m-1'><Badge variant='info'>{matchesLabel}</Badge></div>
      </div>
      <div className='list-item-results'>
        <Grid id='results-table' columns={columns} data={data} showPagination={false} noDataText='No items in this list' enableDefaultRowSelect={true} getRowProps={getRowProps}/>
      </div>
    </div>
  );
}

const AdhocServiceLevelOperations = (props) => {
  const controller = props.controller;
  const getData = () => {
    return controller.getFilteredItems();
  }
  const data = getData();
  const matchesCount = data.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const handleRowClick = (data) => {
    props.controller.selectItem(data);
    const id = document.getElementById('listItemName');
    if (id) {
      id.focus();
    }
  }

  const getRowProps = (row) => {
    const item = row.original;  
    const selectedItem = { ...props.controller.state.selectedItem};
    const rowProps = { isSelected: (item.id && selectedItem.id && item.id === selectedItem.id), onClick: handleRowClick};
    return rowProps;
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Operation Name',
        accessor: 'name',
      },
      {
        Header: 'Operation Description',
        accessor: 'operationName',
      },
      {
        Header: 'Operation code',
        accessor: 'operationCode',
      },
      {
        Header: 'Unit of Measure',
        accessor: 'unitOfMeasure',
      },
    ],
    []
  )
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div className='m-1'><Badge variant='info'>{matchesLabel}</Badge></div>
      </div>
      <div className='adhoc-service-results'>
        <Grid id='results-table' columns={columns} data={data} showPagination={false} noDataText='No items in this list' enableDefaultRowSelect={true} getRowProps={getRowProps}/>
      </div>
    </div>
  );
}

const NotificationAlertResults = (props) => {
  const controller = props.controller;
  const getData = () => {
    return controller.getFilteredItems();
  }
  const data = getData();
  const matchesCount = data.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const handleRowClick = (data) => {
    props.controller.selectItem(data);
    const id = document.getElementById('listItemName');
    if(id){
          id.focus();      
    }
  }

  const getRowProps = (row) => {
    const item = row.original;  
    const selectedItem = { ...props.controller.state.selectedItem};
    const rowProps = { isSelected: (item.listId && selectedItem.id && item.id === selectedItem.id), onClick: handleRowClick};
    return rowProps;
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Notification Name',
        accessor: 'name',
      },
      {
        Header: 'Interval Days',
        accessor: 'notificationAfter',
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: cellInfo => <span>{Utils.formatBool(cellInfo.cell.value)}</span>
      },
    ],
    []
  )
  return (
    <div className='col-md-12'>
      <div className='d-flex justify-content-between'>
        <div className='m-2'><Badge variant='info'>{matchesLabel}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} showPagination={false} noDataText='No items in this list' enableDefaultRowSelect={true} getRowProps={getRowProps}/>
    </div>
  );
}

const ListResults = (props) => {

  const handleRowClick = (list) => {
    props.controller.selectList(list);
  };

  const controller = props.controller;
  const data = controller.state.searchResults || [];

  const columns = useMemo(
    () => [
      {
        Header: 'List Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
    ],
    []
  )
  return (
    <div id='list-type-results'>
      <Grid id='results-table' onRowClick={handleRowClick} showPagination={false} columns={columns} enableDefaultRowSelect={true} data={data} />
    </div>
  );
}

const ItemDetails = (props) => {

  const [editButtonHandled, setEditButtonHandled] = useState(false);
  const [newButtonHandled, setNewButtonHandled] = useState(false);

  // const handleNew = () => {
  //   setNewButtonHandled(true);
  //   props.controller.newItem();
  //   props.controller.handleNewOrEdit();
  //   const id = document.getElementById('listItemName');
  //   if(id){
  //         id.focus();      
  //   }
  // }

  const handleSave = () => {
    props.controller.saveItem();
  }

  const handleKeyDown = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.saveItem();
  }

  // const handleEdit = () => {
  //   setEditButtonHandled(true);
  //   props.controller.handleNewOrEdit();
  // }

  const handleNew = () => {
    setNewButtonHandled(true);
    props.controller.newItem();
    props.controller.handleNew();
    const id = document.getElementById('listItemName');
    if(id){
          id.focus();      
      }
  }

  const handleEdit = () => {
      setNewButtonHandled(false);
      setEditButtonHandled(true);
      props.controller.handleEdit();
  }

  const handleCancel = () => {
    setEditButtonHandled(false);
    props.controller.handleCancel();
  }

  const binder = props.binder;
  const isEnabled = props.controller.state.selectedItem;
  const isDescEnabled = (editButtonHandled || newButtonHandled) && props.controller.state.canEditDesc;
  const controller = props.controller;
  return (
    <div className='mb-2'>
      <div>
      <Button disabled={!controller.state.canNewButton} icon='fa-plus-circle' variant='success' size='sm' className='btn-action mr-1' onClick={handleNew}>Create New ...</Button>
      <Button disabled={!controller.state.canEditButton}  icon='fa-plus-circle' variant='primary' size='sm' className='' onClick={handleEdit}>Edit</Button>
      </div>
      <div className='mt-2' onKeyDown={handleKeyDown}>
        <Form.Input label='Name' className="required" enabled={isEnabled && isDescEnabled} bind={binder.bind('selectedItem.name')} id='listItemName' />
        <Form.CheckBox label='Active' enabled={isEnabled && isDescEnabled} disabled={!isEnabled || !isDescEnabled} bind={binder.bind('selectedItem.isActive')} />
      </div>
      <div className="float-right">
        <Button disabled={!controller.state.canEditDesc}  icon='fa-save' variant='primary' size='sm' className='btn-action action-button mr-1' enabled={isEnabled} onClick={handleSave}>Save</Button>
        { isDescEnabled &&
                <Button id='btn-cancel' icon='fa-times' variant='secondary' size='sm' className='action-button' onClick={handleCancel}>Cancel</Button>
        }
      </div>
    </div>
  );
}

const ServiceLevelOpsDetails = (props) => {
  const handleNew = () => {
    props.controller.newItem();
    const id = document.getElementById('listItemName');
    if(id){
          id.focus();      
    }
  }

  const handleSave = () => {
    props.controller.saveItem();
  }

  const handleKeyDown = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.saveItem();
  }

  const binder = props.binder;
  const controller = props.controller;
  const isEnabled = controller.state.operationId;
  const errors = controller.state.errors;

  return (
    <div className=''>
      <Button icon='fa-plus-circle' variant='success' size='sm' className='btn-action' onClick={handleNew}>Create New ...</Button>

      <div className='mt-2' onKeyDown={handleKeyDown}>
        <Form.Input label='Operation Name' className='required' error={errors.operationName} enabled={isEnabled} bind={binder.bind('operationName')} id='listItemName' />
        <Form.Input label='Operation Description' className='required' error={errors.operationDescription} enabled={isEnabled} bind={binder.bind('operationDescription')} />
        <Form.NumberInput label='Operation Code' className='required' error={errors.operationCode} enabled={isEnabled} bind={binder.bind('operationCode')} />
        <Form.AutoComplete label='Unit Of Measure' className='required' error={errors.unitOfMeasure} bind={binder.bind('unitOfMeasure')} search={Searchers.unitOfMeasureSearch()} allowClear={false} minimumResultsForSearch={-1} />
      </div>

        <Button icon='fa-save' variant='primary' size='sm' className='btn-action action-button float-right m-1' enabled={isEnabled} onClick={handleSave}>Save</Button>
    </div>
  );
}

const NotificationAlertDetails = (props) => {
  const handleNew = () => {
    props.controller.newItem();
    document.getElementById('listItemName').focus();
  }

  const handleSave = () => {
    props.controller.saveItem();
  }

  const handleKeyDown = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.saveItem();
  }
  const binder = props.binder;
  const isEnabled = props.controller.state.selectedItem;

  return (
    <div>
      {/* <div><Button icon='fa-plus-circle' variant='success' size='sm'  className='btn-action' onClick={handleNew}>Create New ...</Button></div> */}

      <div style={{ marginTop: '20px' }} onKeyDown={handleKeyDown}>
        <Form.NumberInput label='Interval Days' enabled={isEnabled} bind={binder.bind('selectedItem.notificationAfter')} id='listItemName' />
        <Form.CheckBox label='Active' enabled={isEnabled} bind={binder.bind('selectedItem.active')} />
      </div>

      <div className='float-right mr-1'>
        <Button icon='fa-save' variant='primary' size='sm' className='btn-action action-button' enabled={isEnabled} onClick={handleSave}>Save</Button>
      </div>
    </div>
  );
}

const ListPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-th-list' />

class ListPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.loadListsIfEmpty();
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandAdministrator();
  }

  static defaultProps = {
    controller: new ListController()
  }

  render = () => {
    const controller = this.props.controller;
    const state = controller.state;
    const binder = new Binder(this);
    const selectedList = controller.state.selectedList;
    const listTypeValue = selectedList && selectedList.listType;
    const showItemDetails = selectedList && selectedList.editEnabled;
    const warningClassName = (controller.state.filterItemText === '') ? '' : 'err-msg';
    const results = listTypeValue == '2'
      ? <AdhocServiceLevelOperations controller={controller} binder={binder} />
      : listTypeValue == '3'
        ? <NotificationAlertResults controller={controller} binder={binder} />
        : <ListItemResults controller={controller} binder={binder} />;
    const details = listTypeValue == '2'
      ? <div {...this.props} className='col-md-12 serviceOpsDivDetails'>
        <ServiceLevelOpsDetails controller={controller} binder={binder} /> </div>
      : listTypeValue == '3'
        ? <div {...this.props} className='col-md-6 divDetails'>
          <NotificationAlertDetails controller={controller} binder={binder} /> </div>
        : <div {...this.props} className='col-md-7 divDetails'>
          <ItemDetails controller={controller} binder={binder} /> </div>;
    const editDetails = showItemDetails
      ? details
      : <div className='col-md-4 col-sm-12 has-error'>
        <h5 style={{ marginTop: '25px' }} className='help-block'>List contents cannot be edited</h5>
      </div>
    const listClassName = listTypeValue == '2' ? 'col-md-12' : listTypeValue == '3' ? 'col-md-6' : 'col-md-5';

    return (
      <Page {...this.props} pageTitle='InteServ · Lists' id='lists-page'>
        <ListPageHeader pageTitle='List Management' />
        <Page.Content>
          <div className='row'>
            <div {...this.props} className='col-md-5 col-sm-12' style={{ marginTop: '10px' }}>
              <ListResults controller={controller} />
            </div>
            <div {...this.props} className='col-md-7 no-gutters' style={{ marginTop: '10px' }}>
              <div className='col-md-12 no-gutters'>
                <div className='row'>
                  <div className={listClassName}>
                    <ListItemSearchHeader controller={controller} binder={binder} />
                    {results}
                  </div>
                  {editDetails}
                </div>
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default ListPage;
