import BaseController from '../../lib/BaseController';

class AssetStampBandController extends BaseController {
  constructor(stampBandData) {
    super('stamp-band', {
      stampBandData : stampBandData
    });
  }
}

export default AssetStampBandController;
