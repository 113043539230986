import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Validator from '../../lib/Validator';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import CustomerAssemblyLevelTestRowController from './CustomerAssemblyLevelTestRowController';

class CustomerAssemblyLevelController extends BaseController {
    constructor() {
        super('customer-assembly-levels', CustomerAssemblyLevelController.getInitialState('', '', '', ''));
        this.adding = false;
        this.state.selectedServiceLevel = '';

        this._validator = new Validator();
        this._validator.add('assemblyLevel', 'Assembly Level', [{ required: true }]);
        this._validator.add('assemblyLevelName', 'Assembly Level Name', [{ required: true }]);
        this._validator.add('laborCode','Assembly Labor Code',[{required : true}]);
        messages.channel('edit-customer-assembly-level').action('saved').subscribe(customerAssemblyLevelId => {
            if (!customerAssemblyLevelId) {
                return;
            }
           
            this.load(customerAssemblyLevelId, null, this.state.customerId, this.state.customerName);
           
        });
    }

    selectedServiceLevelChanged = () => {
        const customerServiceLevelId = this.state.selectedServiceLevel.id;
        this.load(customerServiceLevelId, this.state.prototypeServiceLevelId, this.state.customer.id, this.state.customer.name);
    }

    assemblyLevelChanged = (data) => {
        const newValue = data && data.new && data.new.name;
        if (!newValue) {
            return;
        }
        this.state.assemblyLevelName = newValue;
    }

    load = (customerAssemblyLevelId, prototypeServiceLevelId, customerId, customerName) => {   
        this.state.errors = {};     
        if (customerAssemblyLevelId === Utils.emptyGuid) {
            this.adding = true;
            this.state = CustomerAssemblyLevelController.getInitialState(customerAssemblyLevelId, prototypeServiceLevelId, customerId, customerName);
            if (!prototypeServiceLevelId) {
                this.commit();
                return;
            }
        }
        else{            
            ajax.get("lookup/awo/getassemblylevelstestbyassemblylevel/",{assemblylevelId : customerAssemblyLevelId}).then(r=> 
                
                {
                    
                    this.adding = false;
                    this.state.assemblyLevel = r.assemblyLevel;
                    this.state.assemblyLevelName = r.customerAssemblyLevelName;
                    this.state.active = r.active;
                    this.state.isSAPAssemblyLevel = r.isSAPAssemblyLevel;
                    this.state.customer = {id: r.customer_Guid , name : r.customerName};
                    this.state.customerAssemblyLevelId = customerAssemblyLevelId;
                    this.state.laborCode = r.laborCode ? r.laborCode : '';                    
                   var tests =  r.tests.map(x=> new CustomerAssemblyLevelTestRowController(this,{ customerAssemblyLevelTestId : x.customerAssemblyLevelTestId 
                    , assemblylevelOperation : x.assemblylevelOperation , operationCode : x.operationCode , operationOrder : x.operationOrder
                    ,operationName : x.testName
                 }))            
                this.state.tests = tests;
                
                    this.commit();
                }

                );
        }

    }

    merge = (customerServiceLevel) => {
        if (!customerServiceLevel) {
            return;
        }
        this.state = Object.assign({}, customerServiceLevel); // , this.state.customer);
        this.state.tests = [];
        this.state.boms = [];
        this.state.errors = {};
        customerServiceLevel.tests.forEach(test => this.addNewTestRow(test));
        // customerServiceLevel.boms.forEach(bom => this.addNewBOMRow(bom));
    }

    static getInitialState = (customerAssemblyLevelId, prototypeServiceLevelId, customerId, customerName) => {
        return {
            customerAssemblyLevelId: customerAssemblyLevelId,
            assemblyLevelName: '',
            customer: { id: customerId, name: customerName },
            assemblyLevel: '',
            fmcServiceLevel: '',
            alternateName: '',
            intervalDays: 0,
            required: false,
            active: true,
            isSAPAssemblyLevel: false,
            nisd: false,
            laborCode: '',
            hierarchyLevel: 0,
            tests: [],
            errors: {},
            isSaving: false,
            newRowCount: 0,
            errors : {}
            
        };
    }

    search = (searchType) => {
        return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), {
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    bomSearch = () => {
        return (searchTerm) => ajax.get('lookup/searchBOM/', {
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    addNewTestRow = (test) => {
        if(this.state.tests && this.state.tests.length <50)
        {
        this.state.tests.push(new CustomerAssemblyLevelTestRowController(this, test));
        // this.renumberTests();
        this.commit();
        }
        else{
            notification.action('error').post({ msg: 'Only 50 tests are allowed for saving' , title: 'Warning' });
        }
    }

    removeTestRow = (row) => {
        const idx = this.state.tests.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.tests.splice(idx, 1);
        // this.renumberTests();
        this.commit();
    }



    removeBOMRow = (row) => {
        const idx = this.state.boms.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.boms.splice(idx, 1);
        this.state.newRowCount = this.state.newRowCount > 0 ? this.state.newRowCount - 1 : this.state.newRowCount;
        this.commit();
    }


    renumberTests = () => {
        this.state.tests.forEach((t, i) => {
            t.setOrder(i + 1);
        });
        this.state.tests.sort((a, b) => {
            return (a.state.operationOrder - b.state.operationOrder);
        });
        this.commit();
    }

    autoCodeTests = () => {
        this.state.tests.forEach(t => t.autoCode());
        this.commit();
    }

    validateTestRows = (state) => {
        state.tests.forEach(row => {
            const assemblylevelOperation = row.state.assemblylevelOperation;
            const operationName = row.state.operationName;
            const operationOrder = row.state.operationOrder;

            if (assemblylevelOperation) {
                delete row.state.errors.assemblylevelOperation;
            }
            else {
                row.state.errors.assemblylevelOperation = 'A value for Assembly Level Operation must be selected';
            }

            if (operationName) {
                delete row.state.errors.operationName;
            }
            else {
                row.state.errors.operationName = 'Operation Name must be specified';
            }

            if (operationOrder) {
                delete row.state.errors.operationOrder;
            }
            else {
                row.state.errors.operationOrder = 'A value for Operation Order must be selected';
            }
        });
    }

    /*duplicate = () => {
        const customerServiceLevel = Object.assign({}, this.state);
        customerServiceLevel.tests = this.state.tests.map(t => t.state);
        customerServiceLevel.tests.forEach(t => t.customerServiceLevelTestId = Utils.emptyGuid);
        customerServiceLevel.customerServiceLevelId = Utils.emptyGuid;
        this.merge(customerServiceLevel);
        this.commit();
        const message = 'Duplicate service level created from ' + this.state.customerServiceLevelName;
        notification.action('info').post({ msg: message, title: 'Customer Service Level Duplicated' });
    }*/

    customerServiceLevelSearch = () => {
        const self = this;
        const customerId = self.state.customer && self.state.customer.id;
        return (searchTerm) => ajax.get('lookup/getAllServiceLevelsForCustomer', {
            customerId: customerId,
            searchTerm: searchTerm
        }).then(result => {
            result.map(r => {
                return {
                    id: r.customerServiceLevelId,
                    name: r.customerServiceLevelName,
                    isSAPServiceLevel: r.isSAPServiceLevel
                }
            })
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    canSave = () => {
        this.state.errors = {};
        this._validator.validateAll(this.state);
        this.validateTestRows(this.state);
        const errors = Object.keys(this.state.errors);
        const testRowErrors = this.state.tests.reduce((acc, curr) => {
            return acc + Object.keys(curr.state.errors).length;
        }, 0);

        if (!errors.length && !testRowErrors) {
            return true;
        }
        this.commit();
        return false;
    }

    save = (cb) => {
        if (!this.canSave()) {
            return;
        }
       var testRows = this.state.tests.length;
        if (testRows === 0 || testRows === undefined) {
            notification.action('warning').post('There are no operations defined for the Assembly Level ');
            return false;
        }
        const s = this.state;
        let customerAssemblyLevelId = s.customerAssemblyLevelId;
        const assemblyLevelName = s.assemblyLevelName;
        const customerName = s.customer.name;
        const laborCode = s.laborCode;
        const testData = s.tests.map(test => {            
            return {
                assemblyLevelTest_GUID: test.state.assemblylevelOperation?.id,
                testName: test.state.operationName ? test.state.operationName : '',
                operationCode: test.state.operationCode ? test.state.operationCode : '',
                operationOrder: test.state.operationOrder ? test.state.operationOrder : '',
                customerAssemblyLevelTestId : test.state.customerAssemblyLevelTestId ? test.state.customerAssemblyLevelTestId : Utils.emptyGuid
            }
        });
       
        let parameters = {
            customerAssemblyLevelId : customerAssemblyLevelId ? customerAssemblyLevelId : Utils.emptyGuid,
            assemblyLevel_Guid: s.assemblyLevel ? s.assemblyLevel.id : Utils.emptyGuid,
            customerAssemblyLevelName: s.assemblyLevelName,
            customer_Guid: s.customer.id,
            required: s.required,
            active: s.active,
            isSAPAssemblyLevel: s.isSAPAssemblyLevel,
            tests: testData,
            laborCode_Guid : s.laborCode ? s.laborCode.id : Utils.emptyGuid
        }

        this.state.isSaving = true;
        this.commit();
        ajax.post('assemblylevel/create', parameters).then(customerAssemblyLevelId => {
            this.state.customerAssemblyLevelId=customerAssemblyLevelId;
            this.state.isSaving = false;
            this.commit();
            if (this.adding) {
                notification.action('info').post('Created Assembly Level for Customer ' + customerName);
                if (cb) {
                    cb(customerAssemblyLevelId);
                }
            }
            else {
                notification.action('success').post('Saved changes to Assembly Level ' + assemblyLevelName + ' for  '  + customerName);
            }
         
            messages.channel('edit-customer-Assembly-level').action('saved').post(customerAssemblyLevelId);
        }).catch(err => {
            this.state.isSaving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: CustomerServiceLevelDto, out: Guid
    }
    searchlaborCode = ()=>{
        return (searchTerm) => ajax.get('lookup/awo/searchlaborCode/', {
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }
    isSAPAssemblyLevelChanged=()=>{        
       if(this.state.isSAPAssemblyLevel)
       {
        this._validator.add('laborCode','Assembly Labor Code',[{required : true}]);    
       }
       else{
        this._validator.remove('laborCode');
        this.state.laborCode = null;
        this.commit();
       }
    }
}

export default CustomerAssemblyLevelController;