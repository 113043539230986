import React from 'react';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class MoveContainerController extends BaseController {
  constructor(customer, container) {
    super('move-container', {
      customer                  : customer,
      container                 : container,
      selectedParentContainer   : container.parentContainerId === Utils.emptyGuid ? '' : MoveContainerController.getNoParentContainer(),
      containers                : [],
      parentContainerSearchText : ''
    });

    if (this.state.selectedParentContainer !== '') {
      this.state.containers.push(this.state.selectedParentContainer);
    }
  }

  enableSearch = (value) => {
    this.state.parentContainerSearchText = value;
    if(value.trim().length==0){
      this.state.containers=[];
    }
    this.commit();
  }

  clearContainerSearchText = () => {
    this.state.parentContainerSearchText = '';
    this.state.containers=[];
    document.getElementById('parentContainerSearchText').focus();
    this.commit();
  }

  canSelectContainer = (potentialParentNode) => {
    // Can deselect current parent
    if (potentialParentNode.description === this.noParentContainerDescription) {
      return true;
    }
    // User must have access to parent
    if (!potentialParentNode.hasAccess) {
      return false;
    }
    // Cannot select itself as its own parent
    if (potentialParentNode.containerId === this.state.container.containerId) {
      return false;
    }
    // Cannot re-select its own parent
    if (potentialParentNode.containerId === this.state.container.parentContainerId) {
      return false;
    }
    // Parent cannot be one of its own children
    var childNode = this.findNode(this.state.container.containers, potentialParentNode.containerId);
    if (childNode !== null) {
      return false;
    }
    return true;
  }

  selectContainer = (container) => {
    if (!this.canSelectContainer(container)) {
      return;
    }
    this.state.selectedParentContainer = container;
    this.commit();
  }

  findNode = (nodes, id) => {
    // recursively find node
    if (!nodes || !nodes.length) {
      return null;
    }

    for (let i = 0; i < nodes.length; ++i) {
      let node = nodes[i];
      if (node.containerId === id) {
        return node;
      }
      if (node.containers) {
        let childNode = this.findNode(node.containers, id);
        if (childNode !== null) {
          return childNode;
        }
      }
    }
    return null;
  }

  buildContainerHierarchy = (containers) => {
    let hierarchy = [];
    let cloned    = Utils.clone(containers);
    let level     = 0;

    // Add all children
    while (cloned.length) {
      for (let j = cloned.length - 1; j >= 0; --j) {
        let container = cloned[j];
        if (container.level !== level) {
          continue;
        }

        if (container.parentContainerId === Utils.emptyGuid) {
          hierarchy.push(container);  // ... and populate it
          cloned.splice(j, 1);
        }
        else {
          let parentContainer = this.findNode(hierarchy, container.parentContainerId); // find the parent
          if (parentContainer !== null) {
            if (!parentContainer.containers) {
              parentContainer.containers = [];       // if the child collection doesn't exist yet, add it ...
            }
            parentContainer.containers.push(container);  // ... and populate it
            cloned.splice(j, 1);
          }
        }
      }
      level++;
    }
    return hierarchy;
  }

  noParentContainerDescription = () => {
    return '« Remove From Parent Container »';
  }

  static getNoParentContainer = () => {
    return {
      containerId : Utils.emptyGuid,
      description : '« Remove From Parent Container »',
      hasAccess   : true
    };
  }

  addRemoveParentContainerEntry = (hierarchy) => {
    if (this.state.container.parentContainerId === Utils.emptyGuid) {
      return hierarchy;
    }

    const noParentContainer = MoveContainerController.getNoParentContainer();

    hierarchy.unshift(noParentContainer);
    this.selectContainer(noParentContainer);
    return hierarchy;
  }

  containerSearch = () => {
    this.state.containers = [];
      const parameters = {
        customerId     : this.state.customer.id,
        searchTerm     : this.state.parentContainerSearchText,
        showAllResults : false
      };
      this.state.isLoading = true;
      ajax.get('lookup/getCustomerContainers', parameters).then(results=>{
        this.state.isLoading = false;
        results.sort((a, b) => b.description.localeCompare(a.description));
        var hierarchy         = this.buildContainerHierarchy(results);
        this.state.containers = this.addRemoveParentContainerEntry(hierarchy);
        this.commit();
      }).catch(err => {
        this.state.isLoading = false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<CustomerContainerDto>
  }

  canSave = () => {
    if (!this.state.selectedParentContainer) {
      return false;
    }
    return true;
  }

  save = () => {
    if (!this.canSave()) {
      return false;
    }

    const parameters = {
      containerId          : this.state.container.containerId,
      newParentContainerId : this.state.selectedParentContainer.containerId,
      customerId           : this.state.customer.id
    };

    return ajax.post('container/movecontainer', parameters).then(()=>{
      messages.channel('edit-container').action('saved').post();
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
  }); // IN: EditContainerParentDto, OUT: void
    // notifications.action('success').post('Saved container ' + this.state.description + ' for ' + this.state.customer.name, 'Saved Container');
  }
}
  
export default MoveContainerController;
  