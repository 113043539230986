import React from 'react';
import * as d3 from 'd3';
import Dialog from '../../components/Dialog';
import Controller from '../../mixins/Controller';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Form from '../../components/Form';

class AssetChartDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = () => {
    return true;
  }

  render = () => {
    const width = 650;
    const height = 320;
    const translate = 'translate(20, 40)';
    const parseDateValue = d3.timeParse('%Y-%m-%dT%H:%M:%S');
    let xScale = d3.scaleTime().range([0, width]);
    let yScale = d3.scaleLinear().range([height, 0]);
    const chart = this.props.controller.state.assetChart;
    const chartData = chart.data;
    let data = chartData.map(d => { return { testDate: d.testDate, dataPointTime: d.dataPointTime, dataPointPSI: d.dataPointPSI } });

    data.map(d => {
      d.testDate = parseDateValue(d.testDate);
      d.dataPointPSI = +d.dataPointPSI;
    });

    const maxValue = data.reduce((acc, curr) => {
      return curr.dataPointPSI > acc ? curr.dataPointPSI : acc;
    }, 0);

    data.forEach(d => {
      if (d > maxValue) {
        maxValue = d;
      }
    });


    xScale.domain(d3.extent(data, function (d) { return d.testDate; }));
    yScale.domain([0, d3.max(data, function (d) { return d.dataPointPSI; })]);

    const valueline = d3.line()
      .x(d => { return xScale(d.testDate); })
      .y(d => { return yScale(d.dataPointPSI); });
    const xAxis = d3.axisBottom(xScale).ticks(5);
    const yAxis = d3.axisLeft(yScale).ticks(5);

    return (
      <Dialog {...this.props} header={this.props.header + ' for ' + chart.primarySerialNumber} width={width + 100} height={height + 305} id='asset-chart-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div>
            <div className='col-md-12 row'>
              <div className='col-md-6'><Form.StaticData label='Customer' value={chart.customerName} /></div>
              <div className='col-md-6'><Form.StaticData label='Cust. Location' value={chart.customerLocation} /></div>
            </div>

            <div className='col-md-12 row'>
              <div className='col-md-6'><Form.StaticData label='Job Number' value={chart.jobNumber} /></div>
              <div className='col-md-6'><Form.StaticData label='Date Tested' value={DateTimeFormatter.formatDate(chart.dateAdded)} /></div>
            </div>

            <div className='col-md-12 row'>
              <div className='col-md-6'><Form.StaticData label='Serial #' value={chart.primarySerialNumber} /></div>
              <div className='col-md-6'><Form.StaticData label='Test Unit' value={chart.testUnit} /> </div>
            </div>

            <div className='row col-md-12'>
              <div className='col-md-6'><Form.StaticData label='Max Recorded' value={maxValue} /></div>
              <div className='col-md-6'><Form.StaticData label='Assm/Tester' value={chart.technician} /></div>
            </div>

            <div className='row col-md-12'>
              <div className='col-md-6'><Form.StaticData label='Assets Tested' value={chart.assetsTested} /></div>
              <div className='col-md-6'><Form.StaticData label='Printed' value={DateTimeFormatter.formatDate(DateTimeFormatter.today())} /></div>
            </div>

            <div className='row col-md-12'>
              <div className='col-md-12'><Form.StaticData label='Description' value={chart.description} /></div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <svg width={width + 50} height={height + 100}>
                  <g transform={translate}>
                    <g className='x axis' transform='translate(0,100)'>{xAxis}</g>
                    <g className='y axis'>{yAxis}</g>
                    <path d={valueline(data)} />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.OKButtons />
      </Dialog>
    );
  }
}

export default AssetChartDialog;
