import React, { useMemo } from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import ShippingTimeRow from '../pages/ShippingTimeRow';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Grid from '../../components/Grid';

const ShippingGrid = (props) => {

  const getData = () => {
    return props.controller.state.shippingTimeData || [];
  }

  const data = getData();

  const columns = useMemo(
    () => [
      {
        Header: 'Transaction Date',
        accessor: 'TransactionDate',
        Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
      },
      {
        Header: 'Technician',
        accessor: 'Technician'
      },
      {
        Header: 'Time',
        accessor: 'Value',
        Cell: (cellInfo) => {
          const hours = Utils.convertMinsToHrsMins(cellInfo.cell.value);
          const minutes = parseFloat(cellInfo.cell.value).toFixed(1);
          const final = hours + ' / ' + minutes + ' min';
          return <span> {final} </span>;
        }
      },
    ],
    []
  )
  return (
    <div className='row'>
      <div className='col-12'>
        <Grid id='results-table' columns={columns} showPagination={false} disableSortBy={true} data={data} noDataText='No records found' />
      </div>
    </div>
  );
}

class ShippingTable extends React.Component {

  render() {
    var controller = this.props.controller;
    var shippingValues = (controller.state.shippingTimeData.length == 0 ? <tr><td>No Records Found</td></tr> : controller.state.shippingTimeData.map(row => <ShippingTimeRow key={row.key} controller={row} />));


    var divClassName = 'form-group col-sm-12';

    return (
      <div id='shippingValue-div' className={divClassName} style={{ height: 315, overflowY: 'auto', marginTop: '15' }} >
        <table id='shippingValue-table' className='table table-condensed table-hover table-full-width' style={{ marginBottom: '0' }}>
          <thead>
            <tr>
              <th className='col-minimumReading'>Transaction Date</th>
              <th className='col-minimumReading'>Technician</th>
              <th className='col-actualReading'>Time</th>
            </tr>
          </thead>
          <tbody>
            {shippingValues}
          </tbody>
        </table>
      </div>
    );
  }
}
class RecordTimeTable extends React.Component {

  handleOnKeyPress = (evt) => {
    if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
      evt.stopPropagation();
    }
  }

  handleKeyUp = (evt) => {
    try {
      var value = parseInt(evt.target.value, 10);
      if (value < 0) {
        evt.target.value = 0;
      }
      if (value > 100000) {
        evt.target.value = 100000;
      }
    }
    catch (err) {
      evt.target.value = 0;
    }
  }

  render = () => {
    const controller = this.props.controller;
    const errors = controller.state.errors;
    const binder = this.props.binder;
    const LoggedInuser = LoginService.loginInfo.fullName;
    const fldHours = <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} error={errors.hours} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
    const fldMinutes = <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={this.handleOnKeyPress} onKeyUp={this.handleKeyUp} />
    const fldTechnician = <Form.StaticData label='User' value={LoggedInuser} />

    return (
      <div id='shippingValue-div' className='form-group row' >
        <div className='col-12'>
          <Table id='shippingValue-table'>
            <Table.Header>
              <Table.Row>
                <Table.Head className='col-minimumReading' colSpan='3'>Record Shipping Time</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Data>{fldTechnician}</Table.Data>
                {/* <Table.Data>{fldHours}</Table.Data> */}
                <Table.Data>{fldMinutes}</Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

class ShippingTimeDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => (result.Cancel || result.OK) ? true : this.props.controller.saveShippingValues();

  render = () => {
    var binder = new Binder(this);
    var controller = this.props.controller;
    var canaddtime = controller.state.canaddTime;
    var actionButtons = canaddtime ? <Dialog.OKButtons /> : <Dialog.SaveCancelButtons saving={this.props.controller.saving} />;
    var shippingValuesTitle = 'Shipping Time';

    return (
      <Dialog {...this.props} modal={true} width={480} height={450} header={shippingValuesTitle} id='shippingTime-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div id='shippingValues-dlg-body' className='row' style={{overflowY: 'auto' }}>
              <div className='col-12'>
                {!canaddtime && <RecordTimeTable controller={controller} binder={binder} />}
              </div>
              <div className='col-12'>
                {/* <ShippingTable controller={controller} /> */}
                <ShippingGrid controller={controller} />
              </div>
            </div>
          </form>
        </Dialog.Body>
        {actionButtons}
      </Dialog>
    );
  }
}

export default ShippingTimeDialog;