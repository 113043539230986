import React from 'react';
import ReactDOM from 'react-dom';
import { Link as RouterLink } from 'react-router-dom';
import $, { connection } from 'jquery';
import Cx from 'classnames';
import * as ColourLib from '../lib/ColourLib';
import AutoComplete from './AutoComplete';
import styled from 'styled-components'

const Form = (propsParam) => {

  const  props = {
    horizontal: true,
    ...propsParam
  }

  const  classes = Cx({
    'form-horizontal' : props.horizontal,
    'form-inline'     : props.inline
  });

  const className = props.className ? props.className.concat(' ' + classes) : classes;

  return <form {...props} ref='form' className={className} role='form'>{props.children}</form>;
}

const LabelIcon = (props) => <i className='label-icon' style={{ fontSize: '0.899rem',  color:'#5A5A5A', ...props.style}} className={'fas ' + props.icon} />;

const FieldHint = (props) => <span className='field-hint' style={{ fontSize: '0.7rem', fontStyle: 'italic', fontWeight: '500', color:'#414141'}}> {props.hint}</span>;

const FieldError = (props) => <span className='help-block d-block' style={{marginBottom: 10, fontSize: '0.899rem', fontWeight: '600', color:'#a94442', ...props.style}}> {props.error}</span>;

const Label = (props) => {
  const prependLabelIcon = props.prependLabelIcon ? <span><LabelIcon icon={props.prependLabelIcon} />&nbsp;</span> : null;
  const appendLabelIcon = props.appendLabelIcon ? <span>&nbsp;<LabelIcon icon={props.appendLabelIcon} /></span> : null;
  const hint = props.hint ? <FieldHint hint={props.hint}/> : null;
  const label = <span className='d-block'>{prependLabelIcon}<label className='field-label' style={{ fontSize: '0.899rem', fontWeight: '600', color:'#5A5A5A', ...props.style}} >{props.name}</label>{hint}{appendLabelIcon}</span>;
  return label;
};

class Span extends React.Component {

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.props.bind.changed();
  }

  render = () => {
    return (
      <div className='form-group'>
        { this.props.label ? <Label name={this.props.label} /> : null }
        <span className='control form-control'>{this.props.bind()}</span>
      </div>
    );
  }
}

const AutoCompleteStyle = styled.div`
.select2-container.required .select2-default {
  box-shadow: 0 0 0.25rem #d9534f !important;
  border: 1px solid !important;
  border-color: #d9534f !important;
}
`;

const MulitiSelectStyle = styled.div`
.select2-container-multi.required {
  box-shadow: ${props => !props.hasValue  ?  '0 0 0.25rem #d9534f !important' : ''};
  border: ${props => !props.hasValue  ?  '1px solid !important' : ''};
  border-color: ${props => !props.hasValue  ?  '#d9534f !important' : ''};
}
`;

const BaseAutoComplete = (propsParam) => {
  const props = {
    formGroup : true,
    hint      : '',
    ...propsParam
  }

  const label = props.label ? <Label prependLabelIcon={props.prependLabelIcon} appendLabelIcon={props.appendLabelIcon} hint={props.hint} name={props.label}/> : null;
  const error = props.error ? <FieldError error={props.error} /> : null;

  const formGroup = Cx({
    'form-group' : props.formGroup,
    'w-icon'     : props.icon,
    'has-error'  : !!props.error,
    'dark'       : !props.error
  });

  let className = props.className ? props.className.concat(' control form-control') : 'control form-control';

  const acStyle = {
    padding: 0,
    height: 'inherit',
    ...props.style
  }

  const items = props.bind ? props.bind() : props.defaultValue ? props.defaultValue : [];
  const controlLabel = props.error ? error : label;
  const control = <div><AutoComplete {...props} style={acStyle} className={className} /></div>

  const select = props.multiple
    ? <MulitiSelectStyle className={formGroup} hasValue={items ? true : false}>
      {controlLabel}
      {control}
    </MulitiSelectStyle>
    : <AutoCompleteStyle className={formGroup}>
      {controlLabel}
      {control}
    </AutoCompleteStyle>
  return (select);

}

const MultiSelect = (props) => <BaseAutoComplete {...props} multiple={true} style={{ minHeight: 70, height: 'auto', ...props.style }}/>;

const InputStyle = styled.div`
textarea.required,  
input.required {
    box-shadow: 0 0 0.25rem #d9534f !important;
    border-color: #d9534f !important;
  }
`;

class BlurInput extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = { value: this.props.bind() };
      }

    static defaultProps = {
        type: 'text',
        enabled: true,
        options: {}
      }

    componentDidMount = () => {
        var element = $(ReactDOM.findDOMNode(this.refs.input));
        if(this.props.type === 'date') {  
            const date = new Date();     
            const currentYear = date.getFullYear();  
            element.datepicker({...{ autoclose: true, todayHighlight: true, format: 'dd-M-yyyy', maxViewMode: 2, startDate:'1/1/' + (currentYear - 100), endDate:'31/12/' + (currentYear + 100)}, ...this.props.options})
            .on('changeDate', (evt) => {
              this.props.bind(evt.target.value);
          });       
        }
      }

      getType = () => {
        return this.props.type === 'date' ? 'text': this.props.type;
      }

      shouldComponentUpdate = (nextProps, nextState) => {
        return ((this.state.value !== nextState.value) || (this.props.enabled !== nextProps.enabled) || (this.props.readOnly !== nextProps.readOnly) || this.props.bind.changed());
      }

      componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props !== prevProps) {
          this.setState({ value: this.props.bind() });
        }
      }

      handleChange = (evt) => {
        this.setState({ value: evt.target.value });
      }

      handleBlur = (evt) => {
        if (!this.props.bind) {
          return false;
        }

        evt.preventDefault();
        if (this.props.type === 'checkbox') {
          this.props.bind(evt.target.checked);
        }
        else {
          this.props.bind(evt.target.value);
        }
      }

      handleKeyDown = (evt) => {
        if (evt.which === 13) {
          this.handleBlur(evt);
          return;
        }
      }

      render = () => {
        let className= this.props.className;
        if(this.state.value){
          className = className.replace('required','');
        }

        if (!this.props.enabled) {
          return (
            <input
              disabled
              ref='input'
              {...this.props}
              className = {className}
              type={this.getType()}
              value={this.state.value}
            />
          );
        }

        var valueStyle = null;
        if (!!this.state.value && this.props.valueStyle) {
          valueStyle = this.props.valueStyle;
        }

        return (
          <input
            ref          = 'input'
            {...this.props}
            className    = {className}
            enabled      = {this.props.enabled}
            placeholder  = {this.props.placeholder}
            bind         = {this.props.bind}
            type         = {this.getType()}
            value        = {this.state.value}
            onChange     = {this.handleChange}
            onKeyDown    = {this.handleKeyDown}
            onBlur       = {this.handleBlur}
            readOnly     = {this.props.readOnly}
            style        = {valueStyle}
            autoComplete = 'off'
          />
        );
      }
}

class Input extends React.Component {

    static defaultProps = {
        type         : 'text',
        formGroup    : true,
        className    : '',
        hint         : '',
        allowNumbers : true,
      }

      handleChange = (evt) => {
        if (!this.props.bind) {
          return false;
        }

        if (this.props.type === 'checkbox') {
          this.props.bind(evt.target.checked);
          return true;
        }

        if (this.props.type === 'radio') {
          this.props.bind(evt.target.checked);
          evt.stopPropagation();
          return true;
        }


        this.props.bind(evt.target.value);
        return true;
      }

      shouldComponentUpdate = (nextProps, nextState) => {
        var changed = (
          this.props.label    !== nextProps.label    ||
          this.props.error    !== nextProps.error    ||
          this.props.enabled  !== nextProps.enabled  ||
          this.props.readOnly !== nextProps.readOnly ||
          this.props.bind.changed()
        );
        return changed;
      }

      handleNameKeyPress = (evt) => {
        var k = evt.which || evt.charCode || evt.keyCode || 0;
        if((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)){
          return;
        }
        evt.preventDefault();
      }

      handleStringOnlyOnKeyPress = (evt) => {
        var k = evt.which || evt.charCode || evt.keyCode || 0;
        if((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 127 ){
          return;
        }
        evt.preventDefault();
      }

      handleAddressKeyPress = (evt) => {
        var k = evt.which || evt.charCode || evt.keyCode || 0;
        if((k >= 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 ||(k >= 44 && k <= 57)){
          return;
        }
        evt.preventDefault();
      }

      render = () => {
        const formGroup = Cx({
          'form-group'  : this.props.formGroup,
          'w-icon'      : this.props.icon,
          'has-error'   : !!this.props.error,
          'dark'        : !this.props.error
        });

        const style = this.props.display
                      ? {display: this.props.display}
                      : null;

        const className = this.props.className ? this.props.className.concat(' control form-control') : 'control form-control';
        let field = <BlurInput {...this.props} className={className} />;

        if (this.props.type === 'checkbox') {

          const checkboxStyle = {
            height: 24,
            width: 24,
            backgroundColor: '#F1F1F1',
            ...this.props.style
          }

          field = <input {...this.props} autoFocus='false' className={'ml-1 ' + this.props.className} style={checkboxStyle} type='checkbox' onChange={this.handleChange} checked={this.props.bind()} />;
        }

        if (this.props.type === 'radio') {

          const radioInputstyle = {
            height: 24,
            width: 24,
            outline: 0,
            verticalAlign: 'middle',
            ...this.props.style
          }

          return (
            <InputStyle className={formGroup + ' d-inline-flex'}>
              <input {...this.props} autoFocus='false' className={'ml-1 mt-1 ' + this.props.className} style={radioInputstyle} type='radio' checked={this.props.bind()} onChange={this.handleChange} enabled={this.props.enabled}/>
              {this.props.label ? <span className='ml-1 mt-1 mr-1'><Label prependLabelIcon={this.props.prependLabelIcon} appendLabelIcon={this.props.appendLabelIcon} hint={this.props.hint} name={this.props.label}/></span> : null}
            </InputStyle>
          );
        }

        if (this.props.type === 'name') {
          field = <BlurInput {...this.props} className={className} onKeyPress={this.handleNameKeyPress}/>;
        }

        if (this.props.type === 'address') {
          field = <BlurInput {...this.props} className={className} onKeyPress={this.handleAddressKeyPress}/>;
        }

        if (!this.props.allowNumbers) {
          field = <BlurInput {...this.props} className={className} onKeyPress={this.handleStringOnlyOnKeyPress}/>
        }

        const label = this.props.label ? <Label prependLabelIcon={this.props.prependLabelIcon} appendLabelIcon={this.props.appendLabelIcon} hint={this.props.hint} name={this.props.label}/> : null;
        const error = this.props.error ? <FieldError error={this.props.error} /> : null;
        
        return (
          <InputStyle className={formGroup} style={style}>
            {this.props.error ? error : label}
            {field}
          </InputStyle>
        );
      }
}

const DateInput = (props) => <Input {...props} type='date' />;

const CheckBox = (props) => <Input {...props} type='checkbox' />;

const RadioInput = (props) => <Input {...props} type='radio' />;

class SearchInput extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { value: this.props.bind() };
  }

  static defaultProps = {
    bind() { },
    enabled: true,
    options: {},
    type: 'search'
  }

  handleChange = (evt) => {
    this.props.bind(evt.target.value);
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.props.bind.changed();
  }

  render = () => {
    const className = this.props.className ? this.props.className.concat(' form-control'): 'form-control';
    const flag = !this.props.enabled
                    ? <input
                        className={className}
                        {...this.props}
                        disabled
                        ref='input'
                        type='search'
                        value={this.props.bind()}
                      />
                    : <input
                        className={className}
                        {...this.props}
                        ref='input'
                        type='text'
                        value={this.props.bind()}
                        onChange={this.handleChange}
                      />;

    return (flag);
  }
}

class NumberInput extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { value: this.props.bind() };
  }

  static defaultProps = {
    formGroup     : true,
    type          : 'text', // validation handled in handleKeyPress()
    allowPercent  : false,
    allowDecimal  : false,
    allowNegative : false
  }

  handleKeyPress = (evt) => {
    var key = String(evt.key).toLowerCase();
    if ('0123456789'.includes(key)) {
      // permitted - do nothing
      return;
    }
    if (key === 'del') {
      // permitted - do nothing
      return;
    }
    if (key === 'backspace') {
      // permitted - do nothing
      return;
    }
    if (this.props.allowPercent && (key === '%')) {
      // permitted - do nothing
      return;
    }
    if (this.props.allowDecimal && (key === '.')) {
      // permitted - do nothing
      return;
    }
    if (this.props.allowNegative && (key === '-')) {
      // permitted - do nothing
      return;
    }
    // not permitted - don't allow
    evt.preventDefault();
  }

  render = () => {
    var formGroup = Cx({
      'form-group'  : this.props.formGroup,
      'w-icon'      : this.props.icon,
      'has-error'   : !!this.props.error,
      'dark'        : !this.props.error
    });

    var style = this.props.display
              ? {display: this.props.display}
              : null;

    const className = this.props.className ? this.props.className.concat(' control form-control') : 'control form-control';
    const label = this.props.label ? <Label prependLabelIcon={this.props.prependLabelIcon} appendLabelIcon={this.props.appendLabelIcon} hint={this.props.hint} name={this.props.label}/> : null;
    const error = this.props.error ? <FieldError error={this.props.error} /> : null;
    
    return (
      <InputStyle className={formGroup} style={style}>
         {this.props.error ? error : label}
        <BlurInput {...this.props} onKeyPress={this.handleKeyPress} className={className} />
      </InputStyle>
    );
  }
}

const StaticData = (propsParam) => {
  const props = {
    label: '',
    value: '',
    formGroup: false,
    icon: '',
    hint: '',
    ...propsParam
  }

  const icone = props.icon ? <span><i className={'fas ' + props.icon} />&nbsp;</span> : null;
  const label = props.label ? <Label prependLabelIcon={props.prependLabelIcon} appendLabelIcon={props.appendLabelIcon} hint={props.hint} name={props.label}/> : null;

  var valueStyle = null;
  if (!!props.value && props.valueStyle) {
    valueStyle = props.valueStyle;
  }

  const className = props.className ? props.className.concat(' field-data') : 'field-data';

  return (
    <div {...props} className='form-group'>
      {label}
      <div className={className} style={{ fontWeight: 'bold', ...valueStyle }}>{icone}{props.value}</div>
    </div>
  );

}

class TextArea extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { value: this.props.bind() };
  }

  static defaultProps = {
    formGroup: true
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props !== prevProps) {
      this.setState({ value: this.props.bind() });
    }
  }

  handleChange = (evt) => {
    this.setState({ value: evt.target.value });
  }

  handleBlur = (evt) => {
    if (!this.props.bind) {
      return;
    }

    evt.preventDefault();
    this.props.bind(evt.target.value);
  }

  checkNumeric = (evt) => {
    if(this.props.type != 'number'){
      return;
    }
    var k = evt.which || evt.charCode || evt.keyCode || 0;
    if((k >= 48 && k <= 57) || k == 44 || k == 38 || k == 35){
      return;
    }
    evt.preventDefault();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    var changed = (
      this.state.value    !== nextState.value    ||
      this.props.label    !== nextProps.label    ||
      this.props.error    !== nextProps.error    ||
      this.props.enabled  !== nextProps.enabled  ||
      this.props.readOnly !== nextProps.readOnly ||
      this.props.bind.changed()
    );
    return changed;
  }

  render = () => {
    var formGroup = Cx({
      'form-group' : this.props.formGroup,
      'w-icon'     : this.props.icon,
      'has-error'  : !!this.props.error,
      'dark'       : !this.props.error
    });

    var style = this.props.display ? {display: this.props.display} : null;
    let className = this.props.className ? this.props.className.concat(' control form-control') : 'control form-control';
    const label = this.props.label ? <Label prependLabelIcon={this.props.prependLabelIcon} appendLabelIcon={this.props.appendLabelIcon} hint={this.props.hint} name={this.props.label}/> : null;
    const error = this.props.error ? <FieldError error={this.props.error} /> : null;

    if(this.state.value){
      className = className.replace('required','');
    }
    
    return (
      <InputStyle className={formGroup} style={style}>
        {this.props.error ? error : label}
        <textarea {...this.props} className={className} onBlur={this.handleBlur} onChange={this.handleChange} onKeyPress={this.checkNumeric} ref='input' value={this.state.value} />
      </InputStyle>
    );
  }
}

const Link = (propsParam) => {

  const props = {
    value: '',
    href: '',
    ...propsParam
  }

  const aStyle = {
    fontWeight: 'bold',
    ...props.style
  }

  const aProps = {
    ...props,
    href: props.href !== null ? props.href : '',
    className: props.className ? props.className.concat(' field-data') : 'field-data',
    style: aStyle,
    onClick: props.href
      ? props.onClick
      : (e) => {
        e.preventDefault();
        props.onClick(e)
      }
  }

  if (props.download) {
    aProps.download = props.download;
  }

  const label = props.label ? <Label prependLabelIcon={props.prependLabelIcon} appendLabelIcon={props.appendLabelIcon} name={props.label} /> : null;
  const icon = props.icon ? <i className={'fas ' + props.icon} style={{ marginRight: 5 }} /> : null;

  return (
    <div {...{ ...props, onClick: null }} className='form-group'>
      {label}
      <div>{props.to ? <RouterLink {...props} style={aStyle}>{icon}{props.value}</RouterLink> : <a {...aProps}>{icon}{props.value}</a>}</div>
    </div>
  );
}

class StealFocusElement extends React.Component {

  componentDidMount = () => {
    setTimeout(() => {
      $('.set-focus')[0].focus();
    }, 100);
  }

  render = () => {
    return <input className='set-focus' style={{width: 0, height: 0, padding: 0, border: 0}} />;
  }
}

const ColourSwatch = (propsParam) => {
  const props = {
    label           : 'Color',
    backgroundColor : 'White',
    formGroup       : false,
    ...propsParam
  }

  const label = props.label ? <Label name={props.label} /> : null;

  var backgroundColor = props.value ? props.backgroundColor : null;
  var color           = props.value ? ColourLib.calculateForeground(backgroundColor) : null;
  var style           = {
    padding         : 5,
    backgroundColor : backgroundColor,
    color           : color,
    border          : backgroundColor ? 'solid black 2px' : null,
    ...props.style
  };

  const className = props.className ? props.className.concat(' field-data') : 'field-data';

  return (
    <div {...props} className='form-group'>
      {label}
      <div className={className} style={style}>{props.value}</div>
    </div>
  );

}

const Header = (propsParam) => {

  const props = {
    color      : '#555',
    fontWeight : 'bold',
    size       : 6,
    className  : '',
    ...propsParam
  }

  props.className += (' h' + props.size);

  var style = {
    fontWeight: props.fontWeight,
    color: props.color,
    ...props.style
  }

  return (
    <div {...props} className={props.className + ' p-2'} style={style}>
      {props.children}
    </div>
  );

}

const Image = (props) => {
  const lable = props.label ? <Label style={{ marginLeft: 5 }} name={props.label} />: null
  var formGroup = Cx({
    'form-group': props.formGroup,
    'w-icon': props.icon,
    'has-error': !!props.error,
    'dark': !props.error
  });
  var imgAlt = props.imgAlt;
  var imgSrc = props.imgSrc;
  var style = props.style;
  var fnClick = props.onClick;

  return (
    <div className={formGroup}>
      {lable}
      <img alt={imgAlt} className={props.className} src={imgSrc} style={style} onClick={fnClick} />
    </div>
  );
}

Form.Label               = Label;
Form.FieldError          = FieldError;
Form.FieldHint           = FieldHint;
Form.Span                = Span;
Form.AutoComplete        = BaseAutoComplete;
Form.MultiSelect         = MultiSelect;
Form.BlurInput           = BlurInput;
Form.Input               = Input;
Form.Date                = DateInput;
Form.CheckBox            = CheckBox;
Form.RadioInput          = RadioInput;
Form.SearchInput         = SearchInput;
Form.NumberInput         = NumberInput;
Form.StaticData          = StaticData;
Form.TextArea            = TextArea;
Form.Link                = Link;
Form.StealFocusElement   = StealFocusElement;
Form.ColourSwatch        = ColourSwatch;
Form.Header              = Header;
Form.Image               = Image;

export default Form;