import React, { useMemo } from 'react';
import Binder from '../../lib/Binder';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form'
import ActionIcon from '../../components/ActionIcon';
import Utils from '../utility/Utils';
import DimensionValueRow from '../pages/DimensionValueRow';
import Grid from '../../components/Grid';
import Controller from '../../mixins/Controller';

const DimensionValueTable = (props) => {
    const controller = props.controller;

    const handleAddNewRow = () => {
        props.controller.addNewRow();
    }

    const renderRowErrors = () => {
        const rowErrors = props.controller.state.errors && props.controller.state.errors.dimensionValues;
        if (rowErrors) {
            return <Form.FieldError>{rowErrors}</Form.FieldError>;
        }
    }

    const divClassName = controller.state.errors.dimensionValues ? ' has-error' : '';
    const data = [...controller.state.dimensionValues];
    const isViewMode = controller.state.isViewMode;
    const canAddDimensionValues = false;

    const columns = useMemo(
        () => [
            
            {
                Header: '',
                accessor: 'action',
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    const canDeleteRow = controller.state.minimumDimensionMappedId == Utils.emptyGuid && canAddDimensionValues;
                    return canDeleteRow
                        ? <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
                        : <span></span>;
                }
            }, 
            {
                Header: 'ID',
                accessor: 'state.dimensionId',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const errors = controller.state.errors;
                    return <Form.StaticData value={binder.bind('dimensionId')()} />
                }
            },
            {
                Header: 'Dimensional Location',
                accessor: 'state.dimensionLocationName',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const errors = controller.state.errors;
                    return <Form.StaticData value={binder.bind('dimensionLocationName')()} />
                }
            }, 
            {
                Header: 'New Dimension',
                accessor: 'state.newDimension',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const errors = controller.state.errors;
                    return <Form.StaticData value={binder.bind('newDimension')()} />
                }
            },
            {
                Header: 'Minimum Dimension',
                accessor: 'state.minimumDimension',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const errors = controller.state.errors;
                    return <Form.StaticData value={binder.bind('minimumDimension')()} />
                }
            },
            {
                Header: 'Actual Dimension',
                accessor: 'state.actualDimension',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    const errors = controller.state.errors;
                    return <Form.Input bind={binder.bind('actualDimension')} className='required' error={errors.actualDimension} placeholder='Enter a value' />
                }
            },
        ],
        []);

    return (
        <div className={divClassName}>
            <Grid id='results-table' columns={columns} data={data} editable={true} canAddNewRow={canAddDimensionValues} onAddNewRow={handleAddNewRow} showPagination={false} noDataText='No dimensions exist yet' />
            {renderRowErrors()}
        </div>
    );
}


// const DimensionValueTable = (props) => {
//     const handleAddNewRow = () => {
//         props.controller.addNewRow();
//     }

//     const renderRowErrors = () => {
//         const rowErrors = props.controller.state.errors && props.controller.state.errors.dimensionValues;
//         if (rowErrors) {
//             return <div className='help-block'>{rowErrors}</div>;
//         }
//     }

//     const controller = props.controller;
//     const isViewMode = controller.state.isViewMode;
//     const dimensionValues = controller.state.dimensionValues.map(row => <DimensionValueRow key={row.key} controller={row} />);
//     const canAddDimensionValues = !isViewMode; // controller.state.dimensionValues.length + 1 <= 10 ? true : false;
//     const addNewRow = canAddDimensionValues
//         ? <Table.Row>
//             <Table.Data colSpan='10'> <Button icon='fa fa-plus-square' variant='success' size='sm' onClick={handleAddNewRow}>Click to add a new row</Button></Table.Data>
//         </Table.Row>
//         : null;

//     let divClassName = 'form-group col-12';
//     if (controller.state.errors.dimensionValues) {
//         divClassName += ' has-error';
//     }

//     return (
//         <div id='dimensionValue-div' className={divClassName} style={{ height: 315, overflowY: 'auto', marginTop: '15' }} >
//             <Table id='dimensionValue-table' className='table table-condensed table-hover table-full-width' style={{ marginBottom: '0' }}>
//                 <Table.Header>
//                     <Table.Row>
//                         <Table.Head className='col-action' />
//                         <Table.Head className='col-minimumDimension'>Minimum Dimension</Table.Head>
//                         <Table.Head className='col-actualDimension'>Actual Dimension</Table.Head>
//                     </Table.Row>
//                 </Table.Header>
//                 <Table.Body>
//                     {dimensionValues}
//                 </Table.Body>
//                 <Table.Footer>
//                     {addNewRow}
//                 </Table.Footer>
//             </Table>
//             {renderRowErrors()}
//         </div>
//     );
// }

class DimensionValuesDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => (result.Cancel || result.OK) ? true : this.props.controller.saveDimensionValues();

    render() {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const isViewMode = controller.state.isViewMode;
        const actionButtons = isViewMode ? <Dialog.OKButtons /> : <Dialog.SaveCancelButtons />
        const DimensionValuesTitle = 'Dimensional values';
        const errors = controller.state.errors;

        return (
            <Dialog {...this.props} modal={true} width={480} height={450} header={DimensionValuesTitle} id='dimensionValues-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div id='dimensionValues-dlg-body' className='col-12'>
                        <form role='form'>
                            <DimensionValueTable controller={controller} />
                        </form>
                    </div>
                </Dialog.Body>
                {actionButtons}
            </Dialog>
        );
    }
}

export default DimensionValuesDialog;