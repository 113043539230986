import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification'
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import Validator from '../../lib/Validator';

class ListController extends BaseController {
  constructor() {
    super('lists', {
      searchResults: [],
      listItems: [],
      selectedList: {},
      selectedItem: '',
      filterItemText: '',
      activeOnly: false,
      serviceLevelOperations: '',
      operationName: '',
      operationDescription: '',
      operationCode: '',
      unitOfMeasure: '',
      operationId: Utils.emptyGuid,
      errors: {},
      canEditDesc: false,
      canEditButton: false,
      canNewButton: false,
      unmodifiedSelectedItem: '',
    });
    this._validator = new Validator();
    this._validator.add('operationName', 'Name', [{ required: true }]);
    this._validator.add('operationDescription', 'Operation Description', [{ required: true }]);
    this._validator.add('operationCode', 'Operation Code', [{ required: true }]);
    this._validator.add('unitOfMeasure', 'Unit Of Measure', [{ required: true }]);
  }

  isListSelected = (list) => {
    if (!this.state.selectedList) {
      return false;
    }
    return (list.id === this.state.selectedList.id);
  }

  isItemSelected = (item) => {
    if (!this.state.selectedItem) {
      return false;
    }
    return (item.id === this.state.selectedItem.id);
  }

  selectList = (list) => {
    this.state.selectedList = list;
    this.clearItemFilter();
    this.loadListItems(list);
  }

  selectItem = (item) => {
    if (item) {
      this.state.operationName = item.name;
      this.state.operationDescription = item.operationName;
      this.state.operationCode = item.operationCode;
      this.state.operationId = item.id;
      this.state.unitOfMeasure = {
        id: item.eUnitOfMeasure,
        name: item.unitOfMeasure
      };
      this.state.selectedItem = item;
      this.state.canEditDesc = false;
      this.state.canNewButton = true;
      this.state.canEditButton = true;
      this.commit();
    }
  }

  clearItemFilter = () => {
    this.state.canEditDesc = false;
    this.state.filterItemText = '';
    this.commit();
  }

  filterItems = () => {
    var result = this.getFilteredItems();
    if (result.length == 0) {
      this.newItem();
      this.state.canEditButton = false;
      this.state.canNewButton = true;
    }
    else {
      this.selectItem(result[0]);
    }
    this.commit();
  }

  getFilteredItems = () => {
    const filterItemText = this.state.filterItemText.toLowerCase();
    const activeOnly = this.state.activeOnly;
    let items = [];
    if (this.state.listItems) {
      if (this.state.selectedList.listType === '2') {
        items = this.state.listItems
          .filter(item => activeOnly ? item.isActive : true)
          .filter(item => (filterItemText === '') || ((item.name || '').toLowerCase().indexOf(filterItemText) !== -1) || ((item.operationName || '').toLowerCase().indexOf(filterItemText) !== -1) || ((item.operationCode || '').toLowerCase().indexOf(filterItemText) !== -1) || ((item.unitOfMeasure || '').toLowerCase().indexOf(filterItemText) !== -1)); // item.name can be null
      }
      else {
        items = this.state.listItems
          .filter(item => activeOnly ? item.isActive : true)
          .filter(item => (filterItemText === '') || ((item.name || '').toLowerCase().indexOf(filterItemText) !== -1) || ((item.notificationName || '').toLowerCase().indexOf(filterItemText) !== -1)); // item.name can be null
      }
    }

    return items;
  }

  loadListsIfEmpty = () => {
    if (!this.state.searchResults || !this.state.searchResults.length) {
      this.loadLists();
    }
  }

  loadLists = () => {
    this.state.results = [];
    this.state.selectedItem = '';
    ajax.get('lookup/getLookupLists').then(results => {
      this.state.searchResults = results;
      this.commit();
    }); // IList<ListDto>
  }

  loadListItems = (list) => {
    const listId = list && list.id;
    let results = ''
    co(this, function* () {
      this.state.listItems = [];
      this.state.selectedItem = '';
      if (this.state.selectedList.listType === '2') {
        results = yield ajax.get('lookup/getAdhocServiceLevelOperations');
      }
      else if (this.state.selectedList.listType === '3') {
        results = yield ajax.get('lookup/getNotificationAlertItems');
      }
      else if (this.state.selectedList.listType) {
        results = yield ajax.get('lookup/getLookupListItems/', { listId: listId }); // IList<ListItemDto>
      }
      this.state.listItems = results;
      if (results && results.length > 0) {
        this.selectItem(results[0]);
      } else {
        this.newItem();
        this.state.canEditButton = false;
        this.state.canNewButton = true;
      }
      this.commit();
    });
  }

  handleNewOrEdit = () => {
    this.state.canEditDesc = true;
    this.commit();
  }

  handleNew = () => {
    this.state.canNewButton = true;
    this.state.canEditDesc = true;
    this.state.canEditButton = false;
    this.commit();
  }

  handleEdit = () => {
    this.state.canNewButton = false;
    this.state.canEditButton = true;
    this.state.canEditDesc = true;
    const item = this.state.selectedItem.name;
    this.state.unmodifiedSelectedItem = item;
    this.commit();
  }

  handleCancel = () => {
    this.state.canNewButton = true;
    this.state.canEditDesc = false;
    if (this.state.selectedItem?.name == '') {
      var result = this.getFilteredItems();
      this.selectItem(result[0]);
    }
   if(this.state.unmodifiedSelectedItem!=''){
      this.state.selectedItem.name = this.state.unmodifiedSelectedItem;
   }
    this.commit();
  }

  newItem = () => {
    if (this.state.selectedList.listType === '2') {
      this.state.operationName = '';
      this.state.operationDescription = '';
      this.state.operationCode = '';
      this.state.unitOfMeasure = '';
      this.state.operationId = Utils.emptyGuid;
      this.state.errors = {};
    }
    else {
      this.state.selectedItem = {
        id: Utils.emptyGuid,
        name: '',
        listId: this.state.selectedList.id,
        isActive: true
      };
    }
    this.commit();
  }

  saveItem = () => {
    let item = this.state.selectedItem;
    if (this.state.selectedList.listType === '2') {
      this._validator.validateAll(this.state);
      const errors = Object.keys(this.state.errors);
      if (errors.length) {
        this.commit();
        return false;
      }
    }
    else if (this.state.selectedList.listType === '1' && item !== '') {
      if (item.name === '') {
        notification.action('error').post('Name is required.')
        return false;
      }
    }
    else {
      if (item === '') {
        return;
      }
    }

    co(this, function* () {
      if (this.state.selectedList.listType === '2') {
        item = {
          id: this.state.operationId,
          name: this.state.operationName,
          operationName: this.state.operationDescription,
          operationCode: this.state.operationCode,
          adhocServiceLevelId: AppConstants.ServiceLevelGUID,
          eUnitOfMeasure: this.state.unitOfMeasure.id,
          unitOfMeasure: this.state.unitOfMeasure.name
        };
        yield ajax.post('list/saveServiceLevelOpsItem', item);
      }
      else if (this.state.selectedList.listType === '3') {
        yield ajax.post('list/saveNotificationAlertItem', item);
      }
      else {
        yield ajax.post('list/saveItem', item);
      }
      this.loadListItems(this.state.selectedList);

      const message = 'Saved change to item in list ' + this.state.selectedList.name + ': ' + item.name;
      notification.action('success').post(message);

      window.setTimeout(() => {
        this.selectItem(item);
      }, 50);
    });
  }
}

export default ListController;