import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import Utils from '../utility/Utils';

class TransferAssetsToContainerDialog extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel ? true : this.props.controller.transferAssets();

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const assetIds = controller.state.assetIds;
        const errors = controller.state.errors;

        return (
            <Dialog {...this.props} header='Transfer Assets to New Container' width={570} height={260}
                id='transfer-assets-dlg' onClose={this.handleClose}>
                <Dialog.Body>

                    <div className='col-sm-12 p-2'>
                        <h6 className='m-2 text-center'>
                            {'Please select the container to which the ' + Utils.pluralize('asset', 's', assetIds.length)
                                + ' should be transferred'}</h6>
                        <p className='mb-2 text-center'>Only containers associated with a location will appear in the search results</p>
                        <div className='col-sm-12'>
                            <Form.AutoComplete className='required'
                                readOnly={false} bind={binder.bind('targetContainer')}
                                error={errors.targetContainer} search={controller.containerSearch()} /></div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons />
            </Dialog>
        );
    }
}

export default TransferAssetsToContainerDialog;

