import BaseController from '../../lib/BaseController';
import TorqueValueRowController from './TorqueValueRowController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import co from '../../lib/Co';
import Utils from '../utility/Utils';

class TorqueValuesController extends BaseController {
  constructor(asset, isViewMode) {
    super('torqueValues', {
      errors: {},
      torqueValues: [],
      torqueValuesId: Utils.emptyGuid,
      asset: asset,
      isViewMode: isViewMode
    });
    this.saving = false;
    this.loadMinimumTorqueReading();
  }

  validatetorqueValues = (state) => {
    if (!state.torqueValues.length) {
      state.errors.torqueValues = 'At least one row must be specified';
      return;
    }
    state.torqueValues.forEach(row => {
      if (!row.state.isNotApplicable) {
        row.validate();
      }
    });
  }

  canSave = () => {
    this.validatetorqueValues(this.state);
    var errors = Object.keys(this.state.errors);

    var rowErrors = this.state.torqueValues.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!errors.length && !rowErrors) {
      return true;
    }

    this.commit();
    return false;
  }

  addNewRow = () => {
    this.state.torqueValues.push(new TorqueValueRowController(this));
    this.commit();
  }

  addNewTorqueReadingRow = (torqueValue) => {
    this.state.torqueValues.push(new TorqueValueRowController(this, torqueValue));
    this.commit();
  }

  removeRow = (torqueValues) => {
    var idx = this.state.torqueValues.indexOf(torqueValues);
    if (idx === -1) {
      return;
    }

    this.state.torqueValues.splice(idx, 1);
    this.commit();
  }

  loadMinimumTorqueReading = () =>{
      const asset = this.state.asset;
        ajax.get('lookup/getTorqueValues/', { assetId: asset.assetId, manDescId: asset.manufacturerDescriptionId }).then(assetTorqueValues=>{
          if (assetTorqueValues != null) {
            var torqueValues = assetTorqueValues.torqueValueList;
            this.state.torqueValuesId = assetTorqueValues.torqueValuesId;
            torqueValues.forEach(torqueValue => this.addNewTorqueReadingRow(torqueValue));
          }
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

  saveTorqueValues = () => {
    if (!this.canSave()) {
      return false;
    }

    this.saving=true;
    this.commit();
    const torqueValues = this.state.torqueValues.map(torqueValue => torqueValue.state)
    let flag = false;
    torqueValues.forEach(value => {
      if (!value.isNotApplicable) {
        const n = value.torqueRange ? value.torqueRange.split('-') : '';
        const minimumReading = n.length ? isNaN(Number(n[0])) ? 0 : Number(n[0]) : 0;
        const maximumReading = n.length ? isNaN(Number(n[1])) ? 0 : Number(n[1]) : 0;
        if ((Number(value.actualReading) < minimumReading || Number(value.actualReading) > maximumReading)) {
          const msg = 'The actual reading for the location ' + value.location + ' is not with in the torque range';
          notification.action('error').post(msg);
          flag = true;
        }
      }
    })
    if (!flag) {
      var parameters = {
        assetId: this.state.asset.assetId,
        torqueValuesId: this.state.torqueValuesId,
        torqueValueList: torqueValues,
        jobSOWId: this.state.asset.jobSOWId
      };
      return ajax.post('inspection/saveTorqueValues', parameters).then(response => {
        this.saving=false;
        this.commit();
        notification.action('success').post('Saved the torque reading');
        return response;
      }).catch(err => {
        this.saving=false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
      });
    }
    else {
      return false;
    }
  }
}

export default TorqueValuesController;