import React, {useMemo} from 'react';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Roles from '../utility/Roles';
import LoginService from '../services/LoginService';


const ManufacturerDescriptionWallReadingsTable = (props) => {
  const controller = props.controller;
  // const canEdit    = controller.canRemoveRow();
  const canEdit    = true;
  const isSuperUser = LoginService.isInRole(Roles.SuperUser);
  const totalWallReadingCount = controller.state.wallReadings.reduce((acc, curr) => acc + curr.state.quantity, 0);

  const handleAddNewRow = () => {
    props.controller.addNewWallReadingRow();
  }
  
  const divClassName = controller.state.errors.wallReadings ? ' has-error' : '';

  const data = [...controller.state.wallReadings];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',   
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return isSuperUser ?<span></span>:<ActionIcon  icon='fa-trash' className='remove-handle' action={controller.removeRow} />;
        }
      },
      {
        Header: 'ID',
        accessor: 'state.configurationId',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('readingId')}  className='required id-field' readOnly={isSuperUser} error={errors.readingId} placeholder='#' />
        }
      },
      {
        Header: 'New Dimension',
        accessor: 'state.newDimension',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('newDimension')} readOnly={isSuperUser} className='required' error={errors.newDimension} placeholder='Enter a value' />
        }
      },
      {
        Header: 'Minimum Dimension',
        accessor: 'state.minimumDimension',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return <Form.Input bind={binder.bind('minimumDimension')} readOnly={isSuperUser} className='required' error={errors.minimumDimension} placeholder='Enter a value' />
        }
      },
    ],
    []);
    return (
      <div className={divClassName}>
        <Grid id='results-table' columns={columns} data={data} editable={canEdit} canAddNewRow={false} onAddNewRow={handleAddNewRow} showPagination={false} noDataText='No wall readings exist yet'/>
      </div>
  );
}

export default ManufacturerDescriptionWallReadingsTable;