import React, { useMemo } from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import ReceivingTimeRow from '../pages/ReceivingTimeRow';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Grid from '../../components/Grid';

const ReceivingGrid = (props) => {

    const getData = () => {
        return props.controller.state.receivingTimeData || [];
    }

    const data = getData();

    const headers = useMemo(
        () => [
            {
                Header: 'Transaction Date',
                accessor: 'TransactionDate',
                Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
            },
            {
                Header: 'Technician',
                accessor: 'Technician'
            },
            {
                Header: 'Time',
                accessor: 'Value',
                Cell: (cellInfo) => {
                    const hours = Utils.convertMinsToHrsMins(cellInfo.cell.value);
                    const minutes = parseFloat(cellInfo.cell.value).toFixed(1);
                    const final = hours + ' / ' + minutes + ' min';
                    return <span> {final} </span>;
                }
            },
        ],
        []
    )
    const columns = headers;
    return (
        <div className='row'>
            <div className='col-12'>
                <Grid id='results-table' columns={columns} showPagination={false} disableSortBy={true} data={data} noDataText='No records found' />
            </div>
        </div>

    );
}



class ReceivingTable extends React.Component {
    render() {
        var controller = this.props.controller;
        var receivingValues = (controller.state.receivingTimeData.length == 0 ? <tr><td>No Records Found</td></tr> : controller.state.receivingTimeData.map(row => <ReceivingTimeRow key={row.key} controller={row} />));


        var divClassName = 'form-group col-12';

        return (
            <div id='receivingValue-div' className={divClassName} style={{ height: 315, overflowY: 'auto', marginTop: '15' }} >
                <table id='receivingValue-table'>
                    <thead>
                        <tr>
                            <th className='col-minimumReading'>Transaction Date</th>
                            <th className='col-minimumReading'>Technician</th>
                            <th className='col-actualReading'>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receivingValues}
                    </tbody>
                </table>
            </div>
        );
    }
}

const RecordTimeTable = (props) => {
    const handleOnKeyPress = (evt) => {
        if (!((evt.charCode >= 48) && (evt.charCode <= 57))) {
            evt.stopPropagation();
        }
    }

    const handleKeyUp = (evt) => {
        try {
            var value = parseInt(evt.target.value, 10);
            if (value < 0) {
                evt.target.value = 0;
            }
            if (value > 100000) {
                evt.target.value = 100000;
            }
        }
        catch (err) {
            evt.target.value = 0;
        }
    }

    const controller = props.controller;
    const errors = controller.state.errors;
    const binder = props.binder;
    const LoggedInuser = LoginService.loginInfo.fullName;

    let fldHours = <Form.NumberInput label='Hours' type='number' min='0' max='23' bind={binder.bind('hours')} error={errors.hours} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
    let fldMinutes = <Form.Input label='Minutes' type='number' min='0' bind={binder.bind('minutes')} error={errors.minutes} onKeyPress={handleOnKeyPress} onKeyUp={handleKeyUp} />
    let fldTechnician = <Form.StaticData label='User' value={LoggedInuser} />

    return (
        <div id='receivingValue-div' className='row'>
            <div className='col-12'>
                <Table id='receivingValue-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head className='col-minimumReading' colSpan='3'>Record Receiving Time</Table.Head>
                            {/* <Table.Head className='col-minimumReading'></Table.Head>
                            <Table.Head className='col-actualReading'></Table.Head> */}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Data>{fldTechnician}</Table.Data>
                            {/* <Table.Data>{fldHours}</Table.Data> */}
                            <Table.Data>{fldMinutes}</Table.Data>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

class ReceivingTimeDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => (result.Cancel || result.OK) ? true : this.props.controller.saveReceivingValues();

    render = () => {
        const binder = new Binder(this);
        const controller = this.props.controller;
        const canaddtime = controller.state.canaddTime;
        const actionButtons = canaddtime ? <Dialog.OKButtons /> : <Dialog.SaveCancelButtons saving={this.props.controller.saving} />;
        const receivingValuesTitle = 'Receiving Time';

        return (
            <Dialog {...this.props} modal={true} width={480} height={450} header={receivingValuesTitle} id='receivingTime-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <form role='form'>
                        <div id='receivingValues-dlg-body' className='row' style={{ overflowY: 'auto' }}>
                            <div className='col-12'>
                                {!canaddtime && <RecordTimeTable controller={controller} binder={binder} />}
                            </div>
                            <div className='col-12'>
                                {/* <ReceivingTable controller={controller} /> */}
                                <ReceivingGrid controller={controller} />
                            </div>
                        </div>
                    </form>
                </Dialog.Body>
                {actionButtons}
            </Dialog>
        );
    }
}

export default ReceivingTimeDialog;