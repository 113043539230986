import React from 'react';
import {Badge as RBBadge} from 'react-bootstrap';

const Badge = (defaultProps) => {

  const props = {
    pill: true,
    variant: 'secondary',
    ...defaultProps
  }
  
  return <RBBadge {...props}>{props.children}</RBBadge>;
}

export default Badge;