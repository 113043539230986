import React from 'react';

const InspectionTestIcons = {
  NO_ICON       : <i className='fas test-status-icon not-started' />,
  INDETERMINATE : <i className='fas fa-question test-status-icon not-started' />,
  FAILED        : <i className='far fa-thumbs-down test-status-icon failed' />,
  TEMP_FAILURE  : <i className='far fa-thumbs-down test-status-icon temp-failed' />,
  PASSED        : <i className='far fa-thumbs-up test-status-icon text-success' />,
  NOT_PERFORMED : <i className='fas fa-times test-status-icon not-performed' />,
  OS_REPAIR     : <i className='fas fa-wrench test-status-icon text-success' />,
  WELD_REPAIR   : <i className='fas fa-wrench test-status-icon text-danger' />
};

export default InspectionTestIcons;
