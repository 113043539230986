import React from 'react';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import SearchController from '../controllers/SearchController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import Dialog from '../../components/Dialog';
import store from 'store';
import SAPService from '../services/SAPService';

class AssemblyOperationNotPerformedController extends BaseController {
  constructor(parentController, awoId, awoSowId, url, action, testName, selectedConfigs, successMessage,selectedGroups, notesId, notes) {
    super('confirm-operation-results', {
      parentController: parentController,
      awoId: awoId,
      awoSowId: awoSowId,
      url: url,
      action: action,
      testName: testName,
      selectedConfigs: selectedConfigs,
      successMessage: successMessage,
      approverName: '',
      approverSignature: '',
      inspectionDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()),
      technician: '',
      errors: {},
      notesId: notesId,
      notes: notes,
      selectedGroups: selectedGroups
    });

    this._validator = new Validator();
    this._validator.add('approverName', 'Approved By', [{ required: true }]);
    this._validator.add('approverSignature', 'Approver Signature', [{ required: true }]);
    this._validator.add('inspectionDate', 'Inspection Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: this.state.inspectionDate } }]);
    //this.getInspectionNotes();
  }

  clearSignature = () => {
    this.state.approverSignature = '';
    this.commit();
  }

  updateSignature = (signature) => {
    this.state.approverSignature = signature;
    this.commit();
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }


  *save() {
    if (!this.canSave()) {
      return false;
    }

    const s = this.state;
    const selectedAssets = s.selected;
    var jobSOWIds = [];

    if (selectedAssets?.length > 0) {
      selectedAssets.forEach(asset => {
        if (!asset.gaugecountdto.length) {
          return;
        }
        jobSOWIds.push(asset.jobSOWId);
      })
    }

    const parameters = {
      awoId: s.awoId,
      testName: s.testName,
      awoSOWId: s.selectedConfigs,
      approvedByName: s.approverName,
      approvedBySignature: s.approverSignature,
      inspectionDate: s.inspectionDate,
      technician: s.technician,
      notesId: s.notesId,
      notes: s.notes,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today()),

    };
    try {
      var results = yield ajax.post('awo/operations/notPerformed', parameters); // in: UpdateTestDto, out: InspectionResultDto
      this.saving = false;
      this.commit();
      var test = s.parentController;
      this.state.parentController.refreshConfigData();
      const selectGroups = String(s.selectedGroups);
      if (s.selectedGroups.length > 1) {
  notification.action('success').post('<p> ' + s.testName + ' for groups ' + selectGroups + ' has been set to Not Performed ' + '</p>');
        return true;
      } else {
        notification.action('success').post('<p>' + s.testName + ' for group ' + selectGroups + ' has been set to Not Performed' + '</p>');
        return true;
      }
    }
    catch (err) {
      this.saving = false;
      this.commit();
      notification.action('error').post({ msg: this.getErrorMessage(err.message), title: 'Pass Operation Error' });
      return false;
    }
  }
}

export default AssemblyOperationNotPerformedController;
