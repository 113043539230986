import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Binder from '../../lib/Binder';
import GroupBadge from '../../components/GroupBadge';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import Search from '../components/Search';

const Facility = (props) => {

    const selectCurrentFacility = (facility) => {
        facility.selected = true;
        props.controller.moveToSelected();
    }

    const toggleSelection = (facility) => {
        const isCurrentFacility = props.userFacility && (props.controller.state.currentFacility === facility);
        if (!isCurrentFacility) {
            props.controller.toggleSelection(facility);
        }
    }

    const facility = props.facility;
    const className = 'facility';
    const spanSAP = facility.useSAP ? <span className='user-facility-sap'>SAP</span> : null;
    const spanArchived = facility.archived
        ? <span><i className='fa fa-archive archived' />&nbsp;&nbsp;</span>
        : null;

    return (
        <List.Item className={className + ' p-2'} active={facility.selected} onClick={() => { toggleSelection(facility); }} style={{ cursor: 'pointer' }}>{spanArchived} {facility.name} {spanSAP}</List.Item>
    );
}


const CustomerLocationFacilities = (props) => {
    const selectNone = () => {
        props.controller.selectNoCustomerLocationFacilities();
    }

    const selectAll = () => {
        props.controller.selectAllCustomerLocationFacilities();
    }

    const binder = props.binder;
    const controller = props.controller;
    const customerLocationFacilities = controller.state.customerLocationFacilities || [];
    const selectedLabel = customerLocationFacilities.length + ' selected ' + ((customerLocationFacilities.length === 1) ? 'facility' : 'facilities');

    return (
        <div className='row'>
            <div className='col-12'>
                <Search className='mb-2' id='searchText' inputClassName='search-input' label='Enter search text' placeholder='facility name' autoFocus='true' bind={binder.bind('searchText1')} clear={controller.clearSearch1} search={controller.searchCustomerFacilities}></Search>
            </div>
            <div className='col-12'>
                <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
                <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
                <Badge className='m-1' variant='info'>{selectedLabel}</Badge>
            </div>
            <div className='col-12 mt-1'>
                <Jumbotron className='facilities-list'>
                    <List id='selected-facility-list'>
                        {
                            customerLocationFacilities.map(facility => {
                                return <Facility key={facility.id} controller={controller} facility={facility} userFacility={true} />;
                            })
                        }
                    </List>
                </Jumbotron>
            </div>
        </div>
    );
}

const TransferButtons = (props) => {

    const select = () => {
        props.controller.moveToSelected();
    }

    const unselect = () => {
        props.controller.removeFromSelected();
    }
    const selectDisabled = false;
    const unselectDisabled = false;

    return (
        <div className='row'>
            <div className='col'>
                <div>
                    <Button icon='fa-arrow-right' disabled={selectDisabled} variant='success' size='sm' className='btn-block' onClick={select} >Select</Button>
                    <Button icon='fa-arrow-left' disabled={unselectDisabled} variant='danger' size='sm' className='btn-block' onClick={unselect} >Unselect</Button>
                </div>
            </div>
        </div>
    );
}

const AllFacilities = (props) => {
    const selectNone = () => {
        props.controller.selectNoFacilities();
    }

    const selectAll = () => {
        props.controller.selectAllFacilities();
    }
    
    const binder = props.binder;
    const controller = props.controller;
    const allFacilities = controller.state.allFacilities || [];
    const availableLabel = allFacilities.length + ' available ' + ((allFacilities.length === 1) ? 'facility' : 'facilities');

    return (
        <div className='row'>
            <div className='col-12'>
                <Search className='mb-2' id='searchText' inputClassName='search-input' label='Enter search text' placeholder='facility name' autoFocus='true' bind={binder.bind('searchText')} clear={controller.clearSearch} search={controller.searchAllFacilities}></Search>
            </div>
            <div className='col-12'>
                <GroupBadge icon='fa-square' variant='success' pill={false} className='m-1' onClick={selectAll}>Select All</GroupBadge>
                <GroupBadge icon='far fa-square' variant='danger' pill={false} className='m-1' onClick={selectNone}>Select None</GroupBadge>
                <Badge className='' variant='info'>{availableLabel}</Badge>
            </div>
            <div className='col-12 mt-1'>
                <Jumbotron className='facilities-list'>
                    <List id='facility-list'>
                        {
                            allFacilities.map(facility => {
                                return <Facility key={facility.id} controller={controller} facility={facility} />;
                            })
                        }
                    </List>
                </Jumbotron>
            </div>
        </div>
    );
}

class SelectCustomerLocationFacilitiesDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel ? true : this.props.controller.save();

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const labelText = 'Select the facilities to which {name} should have access:'.interpolate(controller.state.customerLocation);

        return (
            <Dialog {...this.props} header={this.props.header} width={750} height={600} id='change-customer-location-facilities-dlg' onClose={this.handleClose}>
                <Dialog.Body>
                    <div className='row'>
                        <div className='col'>
                            <div className='row'><div className='col h6'>{labelText}</div></div>
                            <div className='row align-items-center'>
                                <div className='col-md-5'><AllFacilities controller={controller} binder={binder}/></div>
                                <div className='col-md-2'><TransferButtons controller={controller} /></div>
                                <div className='col-md-5'><CustomerLocationFacilities controller={controller} binder={binder}/></div>
                            </div>
                        </div>
                    </div>
                </Dialog.Body>
                <Dialog.SaveCancelButtons />
            </Dialog>
        );
    }
}

export default SelectCustomerLocationFacilitiesDialog;