import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';

class InspectionNotPerformedController extends BaseController {
  constructor(jobId, url, action, testName, selectedAssets, successMessage) {
    super('confirm-inspection-results', {
      jobId: jobId,
      url: url,
      action: action,
      testName: testName,
      selectedAssets: selectedAssets,
      successMessage: successMessage,
      approverName: '',
      approverSignature: '',
      inspectionDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()),
      technician: '',
      errors: {},
      notesId: Utils.emptyGuid,
      notes: ''
    });

    this._validator = new Validator();
    this._validator.add('approverName', 'Approved By', [{ required: true }]);
    this._validator.add('approverSignature', 'Approver Signature', [{ required: true }]);
    this._validator.add('inspectionDate', 'Inspection Date must be less than or equal to today', [{ isLessThanEqualsDate: { params: this.state.inspectionDate } }]);
    this.getInspectionNotes();
  }

  clearSignature = () => {
    this.state.approverSignature = '';
    this.commit();
  }

  updateSignature = (signature) => {
    this.state.approverSignature = signature;
    this.commit();
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }

  getInspectionNotes = () => {
    const s = this.state;
    const param = {
      jobId: s.jobId,
      testName: s.testName,
      assetId: s.selectedAssets[0].jobSOWId,
    }
    ajax.post('lookup/getInspectionTestNotes', param).then(result => {
      if (result) {
        s.notesId = result.notesId || Utils.emptyGuid
        s.notes = result.notes || '';
        this.commit();
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });

  }

  save() {
    if (!this.canSave()) {
      return false;
    }
    const s = this.state;
    const selectedAssets = s.selectedAssets;
    var jobSOWIds =[];

    if (selectedAssets?.length > 0) {
      selectedAssets.forEach(asset => {
        if (!asset.gaugecountdto.length) {
          return;
        }
        jobSOWIds.push(asset.jobSOWId);
      })
    }

    const parameters = {
      jobId: s.jobId,
      testName: s.testName,
      selectedAssets: s.selectedAssets,
      approvedByName: s.approverName,
      approvedBySignature: s.approverSignature,
      inspectionDate: s.inspectionDate,
      technician: s.technician,
      notesId: s.notesId,
      notes: s.notes,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      jobSOWIds: jobSOWIds
    };
    return ajax.post('inspection/' + s.url, parameters).then(results => {
      messages.channel('inspection').action('saved').post({ jobId: s.jobId, assetIds: s.selectedAssets.map(a => a.assetId), results: results.job });
      if (results.successMessages.length) {
        notification.action('success').post('<p>' + results.successMessages.join('</p><p>') + '</p>');
      }
      if (results.errorMessages.length) {
        notification.action('error').post('<p>' + results.errorMessages.join('</p><p>') + '</p>');
      }
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default InspectionNotPerformedController;
