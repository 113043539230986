import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';

class SerialNumberRowController extends BaseController {
  constructor(parentController, obj) {
    super('serial-number-row-controller-' + SerialNumberRowController.key++, {
      primarySerialNumber: obj.primarySerialNumber,
      customerName: obj.customerName,
      currentLocationName: obj.currentLocationName,
      secondarySerialNumber: obj.secondarySerialNumber,
      equipmentNumber: obj ? obj.equipmentNumber : '',
      isPrimaryUnique: obj.isPrimaryUnique,
      isSecondaryUnique: obj.isSecondaryUnique,
      batchNumber: obj.batchNumber,
      bornOnDate: obj.bornOnDate,
      errors: {}
    });

    this.parentController = parentController;
    this._validator = new Validator();
    this._validator.add('primarySerialNumber', 'Primary Serial #', [{ required: true }]);
    this._validator.add('bornOnDate', 'Born On Date', [{ required: true }]);
  }

  static key = 1;

  primarySerialNumberChanged = () => {
    co(this, function* () {
      var uniqueSerialNumber = yield this.parentController.isSerialNumberUnique(this.state.primarySerialNumber, AppConstants.eSerialNumberType.Primary);
      this.state.customerName = uniqueSerialNumber.customerName;
      this.state.currentLocationName = uniqueSerialNumber.currentLocationName;
      this.state.isPrimaryUnique = uniqueSerialNumber.isUnique;
      this.commit();
    });
  }

  secondarySerialNumberChanged = () => {
    co(this, function* () {
      var uniqueSerialNumber = yield this.parentController.isSerialNumberUnique(this.state.secondarySerialNumber, AppConstants.eSerialNumberType.Secondary);
      this.state.isSecondaryUnique = uniqueSerialNumber.isUnique;
      this.commit();
    });
  }

  remove = () => {
    this.parentController.removeFromList(this);
  }

  select = () => {
    this.parentController.selectAddedSerialNumberRow(this);
  }

  isSelected = () => {
    return this.parentController.isRowSelected(this);
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }
}

export default SerialNumberRowController;
