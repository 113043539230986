import React from 'react';
import PropTypes from 'prop-types';

const ActionIcon = (propsParam) => {
  const props = {
    size: 'lg',
    ...propsParam
  };
  let className;

  if (props.icon) {
    if (props.icon.includes('fab')) {
      className = 'fab ';
    }
    else if (props.icon.includes('far')) {
      className = 'far ';
    }
    else if (props.icon.includes('fas')) {
      className = '';
    }
    else {
      className = 'fas ';
    }
    className += ' ' + props.icon;
  }
  
  if(props.size){
    className += ' fa-' + props.size;
  }

  const classNames = props.className ? props.className.concat(' ' + className) : className;

  return <i {...props} className={classNames} onClick={props.action || props.onClick} />;
}

ActionIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  action: PropTypes.func
}

export default ActionIcon;
