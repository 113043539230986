import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class DimensionValueRowController extends BaseController {
  constructor(parentController, dimension) {
    super('dimension-row-controller-' + DimensionValueRowController.key++, {
      dimensionId: dimension ? dimension.dimensionId : Utils.emptyGuid,
      minimumDimensionMappedId: dimension ? dimension.minimumDimensionMappedId : Utils.emptyGuid,
      minimumDimension: dimension ? dimension.minimumDimension : '',
      actualDimension: dimension ? dimension.actualDimension : '',
      dimensionLocationName : dimension ? dimension.dimensionLocationName :'',
      dimensionId: dimension? dimension.dimensionId :'',
      errors: {}
    });

    this.parentController = parentController;
    this._validator = new Validator();
    this._validator.add('actualDimension', 'Actual Dimension', [{ required: true }]);
  }

  static key = 1;

  removeRow = () => {
    this.parentController.removeRow(this);
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

}

export default DimensionValueRowController;