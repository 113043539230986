import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
import Utils from '../utility/Utils';
import $ from 'jquery';
class ReceivingTicketController extends BaseController {
  constructor() {
    super('receiving-tickets', {
      searchText              : '',
      jobNumber               : '',
      customer                : '',
      includeZeroAssetTickets : false,
      selectedTicket          : '',
      customers               : [],
      searchResults           : []
    });

    const self   = this;
    this.flash = emitter();

    window.setTimeout(() => {
      this.loadCustomers();
    }, 0);

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        self.state.customer = '';
        self.clearResults();
      }
    });
    messages.channel('complete-job').action('saved').subscribe(() => {
      this.state.customer      = '';
      this.state.customers     = [];
      this.state.searchResults = [];
      this.loadCustomers();
      $('#searchText').focus();
    });
    messages.channel('change-facility').action('changed').subscribe(() => {
      this.state.customer      = '';
      this.state.customers     = [];
      this.state.searchResults = [];
      this.loadCustomers();
    });
  }

  clearSearchText = () => {
    this.state.searchText = '';
    this.commit();
}

  customerChanged = () => {
    this.clearResults();
  }

  clearResults = () => {
    this.state.searchResults  = [];
    this.state.selectedTicket = '';
  }

  search = () => {
      this.clearResults();
      const facilityId   = (LoginService.loginInfo && LoginService.loginInfo.facilityId);
      const ticketNumber = this.state.searchText;
      const jobNumber    = this.state.jobNumber;
      const customerId   = (this.state.customer && this.state.customer.id); // can be null
      if (!facilityId) {
        return;
      }
      if (ticketNumber && !Utils.isNumeric(ticketNumber)) {
        notification.action('warning').post('If specified, Receiving Ticket Number must be a number', 'Not Valid');
        return;
      }
      const parameters = {
        facilityId             : facilityId,
        ticketNumber           : ticketNumber,
        jobNumber              : jobNumber,
        customerId             : customerId,
        includeZeroAssetTickets: this.state.includeZeroAssetTickets
      };

      this.state.searchResults = [];
      this.state.isLoading     = true;
      this.commit();
      ajax.get('lookup/searchReceivingTickets', parameters).then(results=>{
        this.state.isLoading     = false;

      // auto-select the 1st result
      if (results.length > 0) {
        this.state.selectedTicket = results[0];
      }

      this.state.searchResults = results;
      this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  deleteTicket = () => {
    if (!this.state.selectedTicket) {
      return;
    }

    const ticket       = this.state.selectedTicket;
    const ticketNumber = ticket.ticketNumber;
    const message   = <div><h5>{`Please confirm you wish to delete receiving ticket #${ticketNumber}`}</h5></div>;

    co(this, function*() {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={600} height={300} header='Confirm Receiving Ticket Deletion'>
          {message}
        </Dialog.OKCancelDialog>
      );

      if (!result.OK) {
        return;
      }

      yield ajax.post('receiving/delete', ticket.receivingTicketId); // Guid
      notification.action('success').post(`Deleted Receiving ticket #${ticketNumber}`, 'Removed Assets from Container');
      this.search();
    });
  }

  loadCustomers = () => {
    if (!LoginService.loginInfo) {
      return;
    }

    const facilityId = LoginService.loginInfo.facilityId;
    const parameters = {
      facilityId   : facilityId,
      searchTerm   : ''
    };

    ajax.get('lookup/searchCustomersWithReceivingTicketsAtFacility', parameters).then(results=>{
      this.state.customers = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
  });
  }

  isSelected = (ticket) => {
    if (!this.state.selectedTicket) {
      return false;
    }
    return (ticket.receivingTicketId === this.state.selectedTicket.receivingTicketId && ticket.jobNumber===this.state.selectedTicket.jobNumber);
  }

  loadDetails = (ticket) => {
    this.state.selectedTicket = ticket;
    this.commit();
  }
}

export default ReceivingTicketController;
