import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';

class ReceivingCostCenterRowController extends BaseController {
  constructor(parentController, receivingCostCenter) {
    super('receiving-cost-center-row-controller' + ReceivingCostCenterRowController.key++, {
      receivingCostCenterId  : receivingCostCenter ? receivingCostCenter.receivingCostCenterId                : Utils.emptyGuid,
      costCenter             : receivingCostCenter ? receivingCostCenter.costCenter                        : '',
      shortText              : receivingCostCenter ? receivingCostCenter.shortText                         : '',
      longText               : receivingCostCenter ? receivingCostCenter.longText                          : '',      
      canDelete              : receivingCostCenter ? receivingCostCenter.canDelete : true,  
      recordStatus           : receivingCostCenter ? receivingCostCenter.recordStatus : AppConstants.RecordStatus.Added,                                         
      errors                 : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('costCenter',         'Cost Center',             [{required: true}]);
    this._validator.add('shortText',          'Short Text',              [{required: true}]);
    this._validator.add('longText',           'Long Text',               [{required: true}]);
  }

  static key = 1;

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

  updateRecordStatus = () => {
    this.state.recordStatus = this.state.recordStatus === AppConstants.RecordStatus.Unchanged ? AppConstants.RecordStatus.Modified : this.state.recordStatus;
    this.commit();
  }

  costCenterChanged = () => {
    this.updateRecordStatus();
  }

  shortTextChanged = () => {
    this.updateRecordStatus();
  }

  longTextChanged = () => {
    this.updateRecordStatus();
  }

  removeRow = () => {
    const recordStatus = this.state.recordStatus;
    if(recordStatus === AppConstants.RecordStatus.Added){
      this.parentController.removeReceivingCostCenterRow(this);
    }
    else{
      this.state.recordStatus = AppConstants.RecordStatus.Deleted;
      this.commit();
      this.parentController.commit();   
    } 
  }
}
  
export default ReceivingCostCenterRowController;
