import _ from 'immutable';
import $ from 'jquery';
import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import store from 'store';
import SAPService from '../services/SAPService';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class ShipAwoAssetsController extends BaseController {
    constructor() {
        super('ship-assets', {
            assets: [],
            removeFromContainer: false,
            multiSelectGroups: [],
            groups: [],
            selectedtedgroups : []
        });
    }

    load = (awoId) => {
        this.clear();
        if (!awoId) {
            this.commit();
            return;
        }
        this.loadawo(awoId);
        this.loadShippableAssets(awoId);
    }

    loadawo = (awoId) => {
    this.getAWODetails(awoId);
    }

    loadShippableAssets = (awoId) => {
        ajax.get('lookup/awo/getassetsforshpping', { awoId: awoId }).then(assets => {
            this.state.assets = assets;
            this.state.groups = [... new Set(assets.map(x=>x.group_name))];
            this.state.removeFromContainer = false;
            this.commit();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    clear = () => {
        this.state.jobId = '';
        this.state.jobNumber = '';
        this.state.customerId = '';
        this.state.customerName = '';
        this.state.customerLocation = '';
        this.state.facilityName = '';
        this.state.selectedAssets = [];
        this.state.groups = [];
        this.state.assets = [];
        this.state.customerShipLocation = '';
        this.state.customerContainer = '';
        this.state.carrier = '';
        this.state.trackingNumber = '';
        this.state.weight = '';
        this.state.notes = '';
        this.state.shippedBySignature = '';
        this.state.isSaveInProgress = false;
        this.state.hours = 0;
        this.state.minutes = 0;
        this.state.Technician = '';
        this.state.awoId = '';
    }

    initializeGroups = () => {
        let groups = [];
        this.state.assets.filter(asset => {
            if (groups.indexOf(asset.groupNumber) === -1) {
                groups.push({ groupNumber: asset.groupNumber, groupName: asset.groupName });
            }
        });

        groups.sort(function (a, b) {
            return (a.groupNumber - b.groupNumber);
        });

        this.state.groups = groups.map(group => {
            return {
                value: group,
                selected: false
            };
        });
    }

    customerShipLocationChanged = () => {
        this.state.customerContainer = '';
    }

    customerContainerChanged = () => {
        this.state.removeFromContainer = false;
        this.commit();
    }

    multiSelectGroupsChanged = () => {
        if (this.state.multiSelectGroups) {
            var selectedGroups = this.state.multiSelectGroups.map(group => group.id);
            this.state.groups.forEach(group => {
                if (selectedGroups.includes(group.value.groupNumber)) {
                    group.selected = true;
                    this.selectSelectedGroupAssets(group.value.groupNumber, true);
                }
                else {
                    group.selected = false;
                    this.selectSelectedGroupAssets(group.value.groupNumber, false);
                }
            });
        }
        else {
            this.state.groups.forEach(group => {
                group.selected = false;
                this.selectSelectedGroupAssets(group.value.groupNumber, false);
            });
        }
        this.commit();
    }

    selectSelectedGroupAssets = (groupNumber, status) => {
        this.state.assets
            .filter(asset => (asset.groupNumber === groupNumber))
            .forEach(asset => {
                asset.selected = status;
            }
            );
    }

    customerContainerSearch = () => {
        if (!this.state.customerShipLocation) {
            return null;
        }
        const self = this;
        return (searchTerm) => ajax.get('lookup/searchCustomerContainersForLocation', {
            customerLocationId: self.state.customerShipLocation.id,
            searchTerm: searchTerm
        }).then(results => results).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    customerShipLocationSearch = () => {
        const self = this;
        return (searchTerm) => ajax.get('lookup/searchCustomerLocations', {
            facilityId: self.state.facilityId,
            customerId: self.state.customerId,
            searchTerm: searchTerm
        }).then(results => results)
            .catch(err => {
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
    }

    customerShipLocationSearchShipping = () => {
        var self = this;
        return (searchTerm) => ajax.get('lookup/searchCustomerLocations', {
            facilityId: self.state.facilityId,
            customerId: self.state.customerId,
            searchTerm: searchTerm
        }).then(results => results).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    selectNoAssets = () => {
        this.state.assets.forEach(asset => {
            asset.selected = false;
        });
        this.state.multiSelectGroups = '';
        
        this.commit();
    }

    selectScrappedAssets = () => {
        this.selectNoAssets();
        this.state.assets
            .filter(asset => this.isAssetScrapped(asset))
            .forEach(asset => {
                asset.selected = true;
            });
        this.commit();
    }

    selectNoSelectedAssets = () => {
        this.state.selectedAssets.forEach(asset => {
            asset.selected = false;
        });
        this.commit();
    }

    selectAllAssets = () => {
        this.state.assets.forEach(asset => {
            asset.selected = true;
        });
        this.commit();
    }

    selectAllSelectedAssets = () => {
        this.state.selectedAssets.forEach(asset => {
            asset.selected = true;
        });
        this.commit();
    }

    toggleSelection = (asset) => {
        asset.selected = !asset.selected;
        this.commit();
    }

    deselectAllGroups = () => {
        this.state.multiSelectGroups = '';
        
        // this.state.groups.forEach(group => { group.selected = false; });
        this.state.selectedtedgroups = [];
        this.selectNoAssets();
        this.commit();
    }

    toggleGroupSelection = (clickedGroup) => {        
        const Idx = this.state.selectedtedgroups.indexOf(clickedGroup);
        if(Idx>-1)
        {
            this.state.selectedtedgroups.splice(Idx, 1);
            this.state.assets.filter(asset => (asset.group_name === clickedGroup))
            .forEach(x=>x.selected = false);
        }
        else{
            this.state.selectedtedgroups.push(clickedGroup);
            this.state.assets.filter(asset => (asset.group_name === clickedGroup))
            .forEach(x=>x.selected = true);
        }
        this.commit();
    }

    selectAndTransfer = (asset) => {
        asset.selected = true;
        if (_.List(this.state.assets).contains(asset)) {
            this.moveToSelected();
        }
        else {
            this.removeFromSelected();
        }
    }

    moveToSelected = () => {       
        var selectedgroup =[... new Set(this.state.assets.filter(x=>x.selected === true).map(x=>(x.group_name).toString()))];
        selectedgroup.forEach(x=> {  
           var assets =  this.state.assets.filter(k=>k.group_name === x);           
           assets.forEach(asset => {         
                var idx = this.state.assets.indexOf(asset);
                asset.selected = false;
                this.state.assets.splice(idx, 1);
                this.state.selectedAssets.push(asset);
               
            });
        
        });
        // sort selected list
        this.state.selectedAssets.sort(function (a, b) {
            return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
        });
        this.commit();
    }

    removeFromSelected = () => {        
        var selectedgroup =[... new Set(this.state.selectedAssets.filter(x=>x.selected === true).map(x=>(x.group_name).toString()))];
        selectedgroup.forEach(x=> {  
            var assets =  this.state.selectedAssets.filter(k=>k.group_name === x);           
            assets.forEach(asset => {         
                 var idx = this.state.selectedAssets.indexOf(asset);
                 asset.selected = false;
                 this.state.selectedAssets.splice(idx, 1);
                 this.state.assets.push(asset);
                
             });
         
         });
        // sort primary list
        this.state.assets.sort(function (a, b) {
            return (a.primarySerialNumber.localeCompare(b.primarySerialNumber));
        });
        this.commit();
    }

    selectCurrentAsset = (asset) => {
        asset.selected = true;
        this.state.currentAsset = asset;
        this.commit();
    }

    clearSignature = () => {
        this.state.shippedBySignature = '';
        this.commit();
    }

    updateSignature = (signature) => {
        this.state.shippedBySignature = signature;
        this.commit();
    }

    isAssetScrapped = (asset) => {
        return (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped);
    }

    canShipAssets = () => {
        let success = true;

        if (!this.state.shippedBySignature) {
            notification.action('warning').post('Your signature is required');
            success = false;
        }

        if (!this.state.customerShipLocation) {
            notification.action('warning').post('You must select a Customer Ship Location');
            success = false;
        }

        if (SAPService.isSAPFacility() && this.state.customerShipLocation && this.state.customerShipLocation.error) {
            notification.action('warning').post('An invalid ship location has been selected - ' + this.state.customerShipLocation.error);
            success = false;
        }

        if (!this.state.shippedBySignature) {
            notification.action('warning').post('Your signature is required');
            success = false;
        }

        if (this.state.selectedAssets.length === 0) {
            notification.action('warning').post('You must select at least one asset');
            success = false;
        }

        if (!success) {
            this.commit();
        }

        return success;
    }

    save = (cb) => {
        if (!this.canShipAssets()) {
            return;
        }
        const loginInfo = store.get('InteServLoginInfo');
        const hours = parseFloat(isNaN(this.state.hours) ? 0 : this.state.hours);
        const minutes = parseFloat(isNaN(this.state.minutes) ? 0 : this.state.minutes);
        const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
      
        if (SAPService.isSAPFacility() && SAPService.useTTS()) {
            // if(hours>23)
            // {
            //   notification.action('error').post("Hours cannot be more than 23");
            //   return false;
            // }
            // if(minutes>60)
            // {
            //   notification.action('error').post("Minutes cannot be more than 60");
            //   return false;
            // }
            if (!loginInfo.isClockedIn) {
                notification.action('error').post("Currently Not Clocked In");
                return false;
            }
            if (loginInfo.isClockedOut) {
                notification.action('error').post("ClockedOut for the day");
                return false;
            }
            if (loginInfo.isBreakOut) {
                notification.action('error').post("Currently on Unpaid break");
                return false;
            }
            if (value <= 0) {
                notification.action('error').post("Shipping Time should be greater than 0");
                return false;
            }
            if (parseFloat(value).toFixed(1) <= 0) {
                notification.action('error').post("After rounding to 1 decimal, time will become 0. Shipping Time should be greater than 0");
                return false;
            }
            var availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
            if (value > availableTime) {
                notification.action('error').post("Shipping Time should not be more than Available Production Time");
                return false;
            }
        }
        
        this.state.isSaveInProgress = true;
        this.commit();
        const selectedAssetIds = this.state.selectedAssets.map(asset => { return asset.awo_sow_Id; });   // use only the ids

        const parameters = {           
            customerLocationId: this.state.customerShipLocation.id,
            containerId: this.state.customerContainer && this.state.customerContainer.id,
            carrier: this.state.carrier,
            trackingNumber: this.state.trackingNumber,
            weight: this.state.weight,
            notes: this.state.notes,
            shippedByName: LoginService.loginInfo.fullName,
            shippedBySignature: this.state.shippedBySignature,
            assets: selectedAssetIds,
            removeFromContainer: this.state.removeFromContainer,
            TransactionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
            Value: value,
            awoId : this.state?.awoId
        };

        ajax.post('awoprogess/shipAssets', parameters).then(results => {
            const msg = 'Shipped ' + selectedAssetIds.length + ' asset' + (selectedAssetIds.length === 1 ? '' : 's') + ' on AWO ' + this.state.awoNumber + ' to ' + this.state.customerShipLocation.name;
            notification.action('info').post({ msg: msg, title: 'AWO Assets Shipped' });
            // messages.channel('edit-job-asset-details').action('saved').post();
            //messages.channel('productiveTime').action('update').post(prodTime);  
            const data = {awoId: this.state?.awoId};   
            messages.channel('apply-assembly-levels').action('assign-data').post(data);
            cb();
            this.state.isSaveInProgress = false;
            this.commit();
        }).catch(err => {
            this.state.isSaveInProgress = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    selectScrapped = () => {
        this.selectNoAssets();
        this.state.assets
          .filter(asset => asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped)
          .forEach(asset => asset.selected = true);
    }
    getAWODetails = (awoId)=>{
        ajax.get(`lookup/awo/getawobyid` , {awoId : awoId}).then(x=>
            {
                if(x){
                    const {awoNumber,customer: { name: customerName} ,owner : {id : customerId},facility_GUID} = x;
                    this.state.awoNumber = awoNumber;
                    this.state.customerName = customerName;
                    this.state.customerId = customerId;
                    this.state.facilityId = facility_GUID;
                    this.state.isSaveInProgress = false;
                    this.state.hours = 0;
                    this.state.minutes = 0;
                    this.state.Technician = '';
                    this.state.awoId = awoId;
                    this.commit();
                }
            }).catch(x=> {
                notification.action('error').post({ msg:x.message, title: 'Error' });
            });
    }
}

export default ShipAwoAssetsController;  