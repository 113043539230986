import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification'
import ajax from '../../services/Ajax';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';

class BillOfMaterialController extends BaseController {
  constructor() {
    super('bom', {
      searchResults: [],
      selectedItem: '',
      bomId: '',
      name: '',
      description: '',
      errors: {},
      isSaving: false
    });

    this._validator = new Validator();
    this._validator.add('name', 'Name', [{ required: true }, { maxLength: { params: 2 } }]);
    this._validator.add('description', 'Description', [{ required: true }]);
  }

  load = () => {
    ajax.get('lookup/getBOMs', { searchTerm: '' }).then(results => {
      this.state.searchResults = results;
      if (results.length > 0) {
        this.selectItem(results[0]);
      }
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' }); // IList<BOMDto>
    });
  }

  selectItem = (item) => {
    this.state.selectedItem = item;
    this.state.bomId = item.bomId;
    this.state.name = item.name;
    this.state.description = item.description;
    this.state.errors = {};
    this.commit();
  }

  isSelected = (item) => {
    return (this.state.selectedItem === item);
  }

  newItem = () => {
    Object.assign(this.state, {
      bomId: Utils.emptyGuid,
      name: '',
      description: '',
      errors: {}
    });
    this.commit();
  }

  canSave = () => {
    this._validator.validateAll(this.state);

    const errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  save = () => {
    if (!this.canSave()) {
      return;
    }

    const s = this.state;
    const parameters = {
      bomId: s.bomId,
      name: s.name,
      description: s.description
    };

    this.state.isSaving = true;
    this.commit();
    ajax.post('billOfMaterial/save', parameters).then(() => {
      this.state.isSaving = false;
      this.commit();
      if (this.adding) {
        notification.action('success').post('Saved new BOM');
      }
      else {
        notification.action('success').post('Saved changes for ' + s.name + ' – ' + s.description);
        this.load();
      }
    }).catch(err => {
      this.state.isSaving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error Saving BOM' }); // IList<BOMDto>
    }); // in: bomDto, out: void
  }
}

export default BillOfMaterialController;