import React from 'react';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import co from '../../lib/Co';

class BroadcastMessagesController extends BaseController {
  constructor() {
    super('messages', {
      message         : '',
      title           : '',
      severityInfo    : true,
      severitySuccess : false,
      severityWarning : false,
      severityError   : false
    });
  }

  clearAllRadioButtons = () =>{
    this.state.severityInfo    = false;
    this.state.severitySuccess = false;
    this.state.severityWarning = false;
    this.state.severityError   = false;
  }

  severityInfoChanged = () => {
    this.clearAllRadioButtons();
    this.state.severityInfo = true;
    this.commit();
  }

  severitySuccessChanged = () => {
    this.clearAllRadioButtons();
    this.state.severitySuccess = true;
    this.commit();
  }

  severityWarningChanged = () => {
    this.clearAllRadioButtons();
    this.state.severityWarning = true;
    this.commit();
  }

  severityErrorChanged = () => {
    this.clearAllRadioButtons();
    this.state.severityError = true;
    this.commit();
  }

  clear = () => {
    this.state.message  = '';
    this.state.title    = '';
    this.state.severity = '';
    this.commit();
  }

  readyToSend = () => {
    if (!this.state.title) {
      notification.action('warning').post('You must enter a Title');
      return false;
    }
    if (!this.state.message) {
      notification.action('warning').post('You must enter a Message');
      return false;
    }
    if (!this.getSeverity()) {
      notification.action('warning').post('You must select a Severity');
      return false;
    }
    return true;
  }

  getSeverity = () => {
    if (this.state.severityInfo) {
      return AppConstants.MessageSeverity.Info;
    }
    if (this.state.severitySuccess) {
      return AppConstants.MessageSeverity.Success;
    }
    if (this.state.severityWarning) {
      return AppConstants.MessageSeverity.Warning;
    }
    if (this.state.severityError) {
      return AppConstants.MessageSeverity.Error;
    }
    return '';
  }

  sendMessage = () => {
    if (!this.readyToSend()) {
      return;
    }

    const message = {
      title    : this.state.title,
      message  : this.state.message,
      severity : this.getSeverity()
    };

    const confirmation = <div><h5 style={{marginTop: '-30px', marginBottom: '20px'}}><span>Please confirm you wish to send the following message to </span><span className='highlight-message'>all</span><span> InteServ users:</span></h5></div>;
    const borderStyle = {borderBottom: 'solid 2px lightgray', marginTop: '6px', marginBottom: '6px'};

    co(this, function*() {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={700} height={350} header='Confirm Broadcast Message'>
          {confirmation}
          <div className='form-group' style={{textAlign: 'left'}}>
            <div classname='row col-sm-12'>
              <div className='col-sm-2'><strong>Title</strong></div>
              <div className='col-sm-10'>{message.title}</div>
            </div>

            <div className='col-sm-12' style={borderStyle} />

            <div classname='row col-sm-12'>
              <div className='col-sm-2'><strong>Message</strong></div>
              <div className='col-sm-10'>{message.message}</div>
            </div>

            <div className='col-sm-12' style={borderStyle} />

            <div classname='row col-sm-12'>
              <div className='col-sm-2'><strong>Severity</strong></div>
              <div className='col-sm-10'>{Utils.capitalizeFirstLetter(message.severity)}</div>
            </div>
          </div>
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      yield ajax.post('messaging/broadcast', message);
    });
  }
}

export default BroadcastMessagesController;
