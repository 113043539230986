import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import GroupBadge from '../../components/GroupBadge';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Binder from '../../lib/Binder';
import ServiceLevelLinkController from '../controllers/ServiceLevelLinkController';
import Search from '../components/Search';
import Searchers from '../utility/Searchers';
import List from '../../components/List';
import Jumbotron from '../../components/Jumbotron';
import Utils from '../utility/Utils';
import SearchOptions from '../views/SearchOptions';
import AssignedLaborCodesTable from '../views/AssignedLaborCodesTable';

const ManDescSelection = (props) => {

    const onSelectResult = (result) => {
        props.controller.toggleSelection(result, true);
    }

    const selectAllManufacturerDescriptions = () => {
        props.controller.selectAllManufacturerDescriptions(true);
    }

    const clearManufacturerDescriptionSelection = () => {
        props.controller.selectAllManufacturerDescriptions(false);
    }

    const controller    = props.controller;
    const binder        = props.binder;
    const searchResults = [...controller.state.manDescSearchResults];
    const selectedCount = controller.getSelectedManDesc().length;
    const results       = searchResults.map(result => {
        var className = 'search-result';
        if (result.dimmed) {
            className += ' dimmed';
        }

        return (
            <CSSTransition
                classNames='fade'
                timeout={300}>
                <List.Item key={result.manDescId} className={className} onClick={() => { onSelectResult(result); }} variant={result.selected ? 'info' : ''} style={{ cursor: 'pointer' }}> 
                    <span className='font-weight-bold family-code'>{result.familyCode.name}</span>&nbsp;&nbsp;
                    <span className='font-weight-bold manufacturer'>{result.manufacturer.name}</span>&nbsp;&nbsp;
                    <span>{result.description}</span>
                </List.Item>
            </CSSTransition>
        );
      });

    let badgeText  = '0 selected';
    if (results.length) {
      badgeText = selectedCount + ' / ' + results.length + ' selected';
    }

    return (
        <div>
            <Search id='searchManDesc' className='mb-2' label='Manufacturer Descriptions' placeholder='search manufacturer descriptions' bind={binder.bind('searchManDesc')}  clear={controller.clearManDescSearch} search={controller.searchManDesc}>
                <div className='col'>
                    <div className='row'>
                        <GroupBadge pill={false} icon='fa-square' variant='success' onClick={selectAllManufacturerDescriptions} className='m-1'>Select All</GroupBadge>
                        <Form.Link className='m-1' onClick={clearManufacturerDescriptionSelection} value='Clear selection'></Form.Link>
                        <Badge className='m-1' variant='info'>{badgeText}</Badge>
                    </div>
                </div>
            </Search>
            <Form.AutoComplete bind={binder.bind('selectedCustomerForManDescSearch')} placeholder='select a customer (optional)' search={Searchers.customerSearch()} />
            <Jumbotron className='search-results'>
                <TransitionGroup component={List} >
                    {results}
                </TransitionGroup>
            </Jumbotron>           
        </div>
    );
}

const LaborCodeSelection = (props) => {

    const onSelectResult = (result) => {
        props.controller.toggleSelection(result);
    }

    const controller = props.controller;
    const binder     = props.binder;
    const searchResults = [...controller.state.laborCodeSearchResults];
    const results       = searchResults.map(result => {

        return (
            <CSSTransition  
                classNames='fade'
                timeout={300}>
                <List.Item key={result.laborCodeId} className='search-result' onClick={() => { onSelectResult(result); }} variant={result.selected ? 'info' : ''} style={{ cursor: 'pointer' }}>
                    <span className='font-weight-bold labor-code'>{result.laborCodeName}</span>&nbsp;&nbsp;
                    <span>{result.description}</span>
                </List.Item>
            </CSSTransition>
        );
      });

      const selectedCount = controller.getSelectedLaborCodes().length;
      let badgeText     = '0 selected'; // default
      if (results.length) {
        badgeText = selectedCount + ' / ' + results.length + ' selected';
      }

    return (
        <div>
            <Search className='mb-2 labor-code' label='Labor Codes' placeholder='search labor codes' hint='(optional)' bind={binder.bind('searchLaborCode')} clear={controller.clearLaborCodeSearch} search={controller.searchLaborCode}>
                <Badge className='m-1' variant='info'>{badgeText}</Badge>
            </Search>
            <Jumbotron className='search-results'>
                <TransitionGroup component={List} >
                    {results}
                </TransitionGroup>
            </Jumbotron>           
        </div>
    );
}

const CustomerSelection = (props) => {

    const onSelectResult = (result) => {
        props.controller.selectCustomer(result);
    }

    const controller = props.controller;
    const customers = [...controller.state.distinctCustomers];
    const results = customers.map(result => {

        return (
            <CSSTransition
                classNames='fade'
                timeout={300}>
                <List.Item key={result.id} className='search-result' onClick={() => { onSelectResult(result); }} variant={result.selected ? 'info' : ''} style={{ cursor: 'pointer' }}>
                    <span className='font-weight-bold customer'>{result.name}</span>
                </List.Item>
            </CSSTransition>
        );
      });

    return (
        <div>
            <Form.Label name='Customers' />
            <Jumbotron className='customers'>
                <TransitionGroup component={List} >
                    {results}
                </TransitionGroup>
            </Jumbotron>           
        </div>
    );
}

const ServiceLevelSelection = (props) => {

    const onSelectResult = (result) => {
        props.controller.toggleSelection(result);
    }

    const controller = props.controller;
    const binder = props.binder;
    const customerServiceLevelFilter = controller.state.customerServiceLevelFilter;
    const results = [...controller.state.customerServiceLevels]
        .filter(result => Utils.contains(result.customerServiceLevelName, customerServiceLevelFilter))
        .map(result => {
            return (
                <CSSTransition
                    classNames='fade'
                    timeout={300}>
                    <List.Item key={result.customerServiceLevelId} className='search-result' onClick={() => { onSelectResult(result); }} variant={result.selected ? 'info' : ''} style={{ cursor: 'pointer' }}>
                        <span className='font-weight-bold'>{result.customerServiceLevelName}</span>&nbsp;
                        {result.isSAPServiceLevel && <span className='font-weight-bold sap-service-level'>SAP</span>}
                    </List.Item>
                </CSSTransition>
            );
        });

    const selectedCount = controller.getSelectedCustomerServiceLevels().length;
    let badgeText = '0 selected'; // default
    if (results.length) {
        badgeText = selectedCount + ' / ' + results.length + ' selected';
    }

    return (
        <div>
            <Search className='mb-2 customer-service-levels' label='Customer Service Levels' placeholder='filter service levels' bind={binder.bind('customerServiceLevelFilter')} icon='fa-filter' prependLabelIcon='fa-filter' clear={controller.clearCustomerServiceLevelFilter} search={controller.filterCustomerServiceLevels}>
                <Badge className='m-1' variant='info'>{badgeText}</Badge>
            </Search>
            <Jumbotron className='search-results'>
                <TransitionGroup component={List} >
                    {results}
                </TransitionGroup>
            </Jumbotron>           
        </div>
    );
}

const ApplyButton = (props) => {
    const handleApply = () => {
        props.controller.applyAssignedLaborCodes();
    }

    const canApply = props.controller.canApply();

    return(
        <div className='col-md-4 offset-md-4'>
            <Button icon='fa-plus-circle' size='lg' variant='primary' block={true} disabled={!canApply} onClick={handleApply}>Apply and Save</Button>
        </div>
    );

}

const SearchComboBoxes = (props) => {
    const binder       = props.binder;
    const controller   = props.controller;

    const formatResult = item => {
        var spanSAP = item.result.isSAPServiceLevel ? <span className='sap-service-level float-right select2-tag'> SAP</span> : null;
        if (item.result.isSAPServiceLevel) {
            return <span>{item.text} {spanSAP}</span>;
        }
        return <span>{item.text}</span>;
    };

    const customerServiceLevelProps = controller.state.selectedCustomerForSearch ? { search: controller.customerServiceLevelSearch() } : { data: [] };
    
    return (
        <div className='col-12'>
            <div className='row'>
                <div className='col-md-4'><Form.AutoComplete label='Customer' bind={binder.bind('selectedCustomerForSearch')} prependLabelIcon='fa fa-filter' className='required' placeholder='select a customer' search={Searchers.customerSearch()} /></div>
                <div className='col-md-4'><Form.AutoComplete label='Customer Service Level' formatResult={formatResult} formatSelection={formatResult} bind={binder.bind('selectedServiceLevelForSearch')} prependLabelIcon='fa fa-filter' className='required' placeholder='select a customer service level' {...customerServiceLevelProps} /></div>
            </div>
        </div>
    );
}

const ServiceLevelLinkPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-link fa-flip-horizontal' />;

class ServiceLevelLinkPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();            
    }

    static defaultProps = {
        controller: new ServiceLevelLinkController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        const items = controller.state.assignedLaborCodes;
        const matchesCount = items.length;
        const unFilteredCount = controller.state.unfilteredAssignedLaborCodesCount;
        let badgeText = '0 matches'; // default
        if (matchesCount) {
            badgeText = Utils.pluralize('match', 'es', matchesCount) + ' / ' + unFilteredCount;
        }
        return (
            <Page {...this.props} pageTitle='InteServ · Service Level Links' id='service-level-link-page'>
                <ServiceLevelLinkPageHeader pageTitle={'Service Level Links'} />
                <Page.Content>
                    <div className='row mb-2'>
                        <div className='col-12'><h5>Create new links</h5></div>
                        <div className='col-md-3'><ManDescSelection controller={controller} binder={binder} /></div>
                        <div className='col-md-3'><LaborCodeSelection controller={controller} binder={binder} /></div>
                        <div className='col-md-3'><CustomerSelection controller={controller} /></div>
                        <div className='col-md-3'><ServiceLevelSelection controller={controller} binder={binder} /></div>
                    </div>
                    <div className='row horizontal-rule pb-4'>
                        <ApplyButton controller={controller} />
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'><h5>Review &amp; Delete links</h5></div>
                    </div>
                    <div className='row'>
                        <SearchComboBoxes controller={controller} binder={binder} />
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Search id='searchAssignedLaborCode' className='assigned-labor-code' label='Enter search text' placeholder='search assigned labor codes' bind={binder.bind('searchAssignedLaborCode')}  clear={controller.clearsearchAssignedLaborCodeSearch} search={controller.searchAssignedLaborCodes} >
                                <Badge className='m-1' variant='info'>{badgeText}</Badge>
                            </Search>
                        </div>
                        <div className='col-md-8'><SearchOptions binder={binder} enabled={controller.state.searchStyleEnabled}/></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'><AssignedLaborCodesTable controller={controller} removeRow={controller.removeAssignedLaborCode} isLoading={controller.state.isLoadingAssignedLaborCodes} /></div>
                    </div>
                </Page.Content>
            </Page>
        );

    }
}

export default ServiceLevelLinkPage;
