import React, { useState, useMemo, useCallback, useEffect } from 'react';
import RDG from 'react-data-grid';
import styled from 'styled-components';

const ReactDataGrid = styled(RDG)`
    color: #212529 !important;
    height: ${props => props.rows.length ? 'inherit !important' : '5.313rem !important'};
    overflow-y: ${props => props.rows.length ? props.overflowY : 'auto'} !important;
    overflow-x: ${props => props.overflowX} !important;
    border: 1px solid #dee2e6 !important;

    .rdg-header-row{
        background-color: #007bff;
        color: #fff;
        line-height: unset !important;
        .rdg-cell{
            border-bottom: 2px solid #dee2e6 !important;
        }   
    }

    .rdg-header-sort-cell{
        display: unset !important;
    }
        
    .rdg-row{
        line-height: unset !important;
    }

    @media (min-width:1400px){
        .rdg-row{
            contain: unset !important;
        }
    }
    .rdg-row-even{
        background-color: rgba(0,0,0,.05);
    }

    .rdg-cell{
        padding: .3rem;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }   

    .rdg-row-selected{
        background-color: #b8daff !important;
        border-color: #7abaff;
        .rdg-cell{
            border-color: #7abaff;
        }       
    }

    .rdg-cell-selected{
        box-shadow: unset !important;
    }
`;

const BigGrid = (defaultProps) => {

    const props = {
        className: '',
        onScroll: () => { },
        onRowClick: () => { },
        overflowX: 'scroll',
        overflowY: 'scroll',
        ...defaultProps
    }

    const SortType = {
        NONE: 'NONE',
        ASC: 'ASC',
        DESC: 'DESC'
    };

    const [sort, setSort] = useState({ sortColumn: 'primarySerialNumber', sortDirection: SortType.NONE });
    const [selectedRows, setSelectedRows] = useState(new Set([]));

    const columns = [...props.columns].map((item) => {
        const sortable = !item.disableSortBy;
        const column = {
            name: item.Header,
            key: item.accessor,
            sortable: sortable,
            ...item,
        }

        if (typeof item.Header === 'function') {
            column.headerRenderer = () => <div >{item.Header()}</div>;
        }

        if (item.Cell) {
            column.formatter = (cellInfo) => item.Cell({ row: { original: cellInfo.row }, cell: { value: cellInfo.row[item.accessor] } });
        }

        return column;
    });

    const rows = [...props.data].map((item, index) => ({ rowIndex: index, ...item }));

    const className = 'rdg-light table table-bordered' + props.className;

    const EmptyRowsRenderer = () => {
        return <div className='p-1'>{props.noDataText}</div>;
    }

    const handleSort = useCallback((columnKey, direction) => {
        setSort({ sortColumn: columnKey, sortDirection: direction });
    }, []);

    const getVal = (obj, key) => {
        if (!obj) {
            return '';
        }

        if (key.indexOf('.') === -1) {
            return obj[key];
        }

        var parts = key.split('.');
        for (var i = 0; i < parts.length; ++i) {
            if (obj) {
                obj = obj[parts[i]];
            }
        }
        return obj;
    }

    const sorter = (a, b, sortDir, sortKey) => {
        var sortVal = 0;

        var aVal = getVal(a, sortKey);
        if (aVal === null) {
            return (sortDir === SortType.ASC) ? -1 : 1;
        }

        var bVal = getVal(b, sortKey);
        if (bVal === null) {
            return (sortDir === SortType.ASC) ? -1 : 1;
        }

        if ((typeof aVal === 'string') && (typeof bVal === 'string')) {
            sortVal = aVal.localeCompare(bVal);
        }
        else {
            if (aVal > bVal) {
                sortVal = 1;
            }

            if (aVal < bVal) {
                sortVal = -1;
            }
        }
        return sortVal;
    }

    const sortedRows = useMemo(() => {
        if (sort.sortDirection === SortType.NONE) return rows;

        let sortedRows = [...rows];
        sortedRows = sortedRows.sort((a, b) => sorter(a, b, sort.sortDirection, sort.sortColumn));
        return sort.sortDirection === SortType.DESC ? sortedRows.reverse() : sortedRows;

    }, [rows, sort.sortDirection, sort.sortColumn]);

    const setSortIcons = () => {
        const columns = [...document.getElementsByClassName('rdg-header-sort-cell')];
        columns.forEach(item => {
            item.childNodes[1].className = 'd-none';
            const span = document.createElement('span');
            if (item.childNodes[2]) {
                item.removeChild(item.childNodes[2])
            }
            item.appendChild(span);
            if (item.childNodes[1].innerText === '\u25B2') {
                item.childNodes[2].className = 'ml-2 fas fa-sort-amount-down-alt';
            }
            else if (item.childNodes[1].innerText === '\u25BC') {
                item.childNodes[2].className = 'ml-2 fas fa-sort-amount-up-alt';
            }
            else {
                item.childNodes[2].className = 'ml-2 fas fas fa-sort';
            }
        });
    }

    const onScroll = (event) => {
        setSortIcons();
        props.onScroll(event);
    }

    useEffect(() => { setTimeout(setSortIcons, 50) });

    const onRowClick = (rowIdx, row, column) => {
        const selectedRowItems = selectedRows;
        if (selectedRowItems.has(row.rowIndex)) {
            selectedRowItems.delete(row.rowIndex)
        }
        else {
            if (!props.allowMultiSelect) {
                selectedRowItems.clear();
            }
            selectedRowItems.add(row.rowIndex)
        }
        setSelectedRows(selectedRowItems);
        const rowItems = [...selectedRowItems].map(i => {
            const item = { ...rows.find(r => r.rowIndex === i) };
            delete item.rowIndex;
            return item;
        });
        if (props.allowMultiSelect) {
            props.onRowClick(rowItems);
        }
        else {
            props.onRowClick(rowItems[0]);
        }
    }

    useEffect(() => {
        const selectedRowItems = selectedRows;
        switch (props.selectionType) {
            case 'all':
                selectedRowItems.clear();
                const rowIndexes = rows.map(r => r.rowIndex)
                setSelectedRows(new Set([...rowIndexes]));
                break;
            case 'none':
                selectedRowItems.clear();
                setSelectedRows(selectedRowItems);
                break;
            case 'some':
                break;
            default:
                break;
        }
    }, [props.selectionType]);

    return (
        <ReactDataGrid
            {...props}
            className={className}
            columns={columns}
            rows={sortedRows}
            rowKeyGetter={row => row.rowIndex}
            sortColumn={sort.sortColumn}
            sortDirection={sort.sortDirection}
            onSort={handleSort}
            onScroll={onScroll}
            emptyRowsRenderer={EmptyRowsRenderer}
            onRowClick={onRowClick}
            selectedRows={[...selectedRows].length ? props.selectionType === 'all' ?  new Set([...rows.map(r => r.rowIndex)]) : props.selectionType === 'none' ? new Set([]) : selectedRows : selectedRows}
            onSelectedRowsChange={setSelectedRows}
        />
    )
}

export default BigGrid;