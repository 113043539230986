import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import co from '../../lib/Co';
class AssetStampBandDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }


  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.OK ? true : false;

  isEmpty = (data) => {
    return (!data || (!data.bandColor && !data.clipColor && !data.colorBandData && !data.stainlessBandData && !data.pressureBandData));
  }

  render = () => {
    const data = this.props.controller.state.stampBandData;

    if (this.isEmpty(data)) {
      let header = this.props.header;
      if (data.primarySerialNumber) {
        header += ' for ' + data.primarySerialNumber;
      }

      return (
        <Dialog {...this.props} header={header} width={650} height={210} id='stampband-dlg' onClose={this.handleClose}>
          <Dialog.Body>
            <form role='form'>
              <div className='row'>
                <div className='col-12' style={{ padding: '10px' }}>
                  <div className='col-12' style={{ marginTop: '35px', textAlign: 'center' }}>There is no stamp / band data for this asset</div>
                </div>
              </div>
            </form>
          </Dialog.Body>
          <Dialog.OKButtons />
        </Dialog>
      );
    }

    // replace spaces with unicode spaces; otherwise, they will collapse
    const colorBandData = (data.colorBandData || '').replace(/ /g, '\u00a0');
    const stainlessBandData = (data.stainlessBandData || '').replace(/ /g, '\u00a0');
    const pressureBandData = (data.pressureBandData || '').replace(/ /g, '\u00a0');

    return (
      <Dialog {...this.props} header={this.props.header + ' for ' + data.primarySerialNumber} width={650} height={410} id='stampband-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div className='row'>
              <div className='col-12' style={{ padding: '10px', fontSize: '1.1rem !important' }}>
                <div className='col-12'><Form.ColourSwatch label='Band Color' value={data.bandColor} color='white' backgroundColor={data.bandColor || 'white'} /></div>
                <div className='col-12'><Form.ColourSwatch label='Clip Color' value={data.clipColor} color='white' backgroundColor={data.clipColor || 'white'} /></div>
                <div className='col-12'><Form.StaticData label='Stamp Band Data' value={colorBandData} /></div>
                <div className='col-12'><Form.StaticData label='Stainless Band Data' value={stainlessBandData} /></div>
                <div className='col-12'><Form.StaticData label='Pressure Band Data' value={pressureBandData} /></div>
              </div>
            </div>
          </form>
        </Dialog.Body>
        <Dialog.OKButtons />
      </Dialog>
    );
  }
}

export default AssetStampBandDialog;
