import React, { useMemo } from 'react';
import { Router, useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import AppConstants from '../utility/AppConstants';
import Controller from '../../mixins/Controller';
import SAPService from '../services/SAPService';
import Authentication from '../services/authentication';
import Grid from '../../components/Grid';
import Form from '../../components/Form';
import DuplicateAWOController from '../controllers/DuplicateAWOController';
import Table from '../../components/Table';
import DropZone from '../../components/DropZone';
import GroupBadge from '../../components/GroupBadge';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import notifications from '../../services/Notification';
import Dialog from '../../components/Dialog';
import co from '../../lib/Co';

const AddAWO = (props) => {
  const controller = props.controller;
  const binder = props.binder;

  const handleAddToList = () => {
    props.controller.addToList();
  }

  const handleAddNewRow = () => {
    props.controller.handleAddNewRow();
  }

  const getCellProps = (cell) => {
    let style = {};
    if (cell && cell.column.id === 'state.group') {
      style = {
        width: '100px'
      };
    }
    const rowProps = { style: style };
    return rowProps;

  }
  if (controller.state.parentChildList.length === 0) {
    controller.getAWOParentChildList();
  }

  if (controller.state.addedAWOSystemConfigurationRows.length === 0) {
    controller.getDefaultSystemConfigRow();
  }
  const divClassName = controller.state.errors.addedAWOSystemConfigurationRows ? ' has-error' : '';
  let data = [...controller.state.addedAWOSystemConfigurationRows];
  let assemblyWorkOrderData = [...controller.state.addedAssemblyWorkOrderRows];
  const parentcontroller = controller;
  const disableAddToList = controller.state.addedAssemblyWorkOrderRows.length > 49 ? true : false;
  const isSAPFacility = SAPService.isSAPFacility();
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          const system_GUID = controller.state.system_GUID;
          return system_GUID ? <ActionIcon icon='fa-trash' className='remove-handle' onClick={() => { parentcontroller.removeSystemconfiguration(system_GUID) }} /> : <ActionIcon icon='fa-trash' className='remove-handle' action={controller.remove} />
        }
      },
      {
        Header: 'Parent / Child',
        accessor: 'state.isChild',
        Cell: (cellInfo, binder) => {
          const value = cellInfo.value ? cellInfo.value : '';
          return <span><Form.AutoComplete label='' className='required' bind={binder.bind('isChild')} search={Searchers.awoParentChildSearch()} minimumResultsForSearch={-1} /></span>
        }
      },
      {
        Header: 'Group',
        accessor: 'state.group',
        width: 100,
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          var ischild = cellInfo.row.original.state?.isChild?.id;
          return <span><Form.AutoComplete label='' className='required' bind={binder.bind('group')} search={() => controller.awoGroupSearch(cellInfo.row.original.state)} /></span>
        }
      },
      {
        Header: 'Position / Package',
        accessor: 'state.positionPackage',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          return <span><Form.AutoComplete label='' className='required' bind={binder.bind('positionPackage')} search={Searchers.awoPackageSearch()} /></span>

        }
      },
      {
        Header: 'Equipment Description',
        accessor: 'state.equipmentDescription',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          return <span><Form.AutoComplete label='' className='required' bind={binder.bind('equipmentDescription')} search={Searchers.awoEquipmentDescriptionSearch()} /></span>
        }
      },
      {
        Header: 'Dependent Equipment Description',
        accessor: 'state.dependentEquipment',
        Cell: (cellInfo, binder) => {
          return <div style={{ width: "100%" }}>
            <div className='row'>
              <div className='col-12'><Form.AutoComplete label='' style={{ width: '100%' }} className='required' bind={binder.bind('dependentEquipment')} search={Searchers.awoDependentEquipmentDescriptionSearch()} /></div>
              {/* <div className='col-4'><Form.CheckBox label='' bind={binder.bind('dependentChild')}></Form.CheckBox></div> */}
            </div>
          </div>
        }
      },
      {
        Header: 'Order',
        accessor: 'state.order',
        Cell: (cellInfo, binder) => {
          return <div style={{ width: "100%" }}>
            <div className='row'>
              <div className='col-12'><Form.Input label='' className='required' type='number' min='1' bind={binder.bind('order')} /></div>
            </div>
          </div>
        }
      }

    ],
    [data]);


  const columns_2 =
    useMemo(
      () => [
        {
          Header: '',
          accessor: 'action',
          Cell: (cellInfo) => {
            const controller = cellInfo.row.original;
            return <ActionIcon icon='fa-trash' className='remove-handle' action={controller.remove} />
          }
        },
        {
          Header: 'AWO Number',
          accessor: 'state.awoNumber',
          isVisible: !isSAPFacility,
          Cell: (cellInfo, binder) => {
            const controller = cellInfo.row.original;
            const errors = controller.state.errors;
            const value = cellInfo.value;
            return <span>{value}</span>
          }
        },
        {
          Header: 'AWO Customer',
          accessor: 'state.awoCustomer',
          width: 100,
          Cell: (cellInfo, binder) => {
            const controller = cellInfo.row.original;
            const errors = controller.state.errors;
            const value = cellInfo.value ? cellInfo.value.name : '';
            return <span>{value}</span>
          }
        },
        {
          Header: 'Pad / Worksite / Rig',
          accessor: 'state.pad',
          Cell: (cellInfo) => {
            const controller = cellInfo.row.original;
            const value = cellInfo.value ? cellInfo.value : '';
            return <span>{value}</span>
          }
        },
        {
          Header: 'Well Name',
          accessor: 'state.wellName',
          Cell: (cellInfo) => {
            const controller = cellInfo.row.original;
            const value = cellInfo.value ? cellInfo.value : '';
            return <span>{value}</span>
          }
        },
        {
          Header: 'System',
          accessor: 'state.system',
          Cell: (cellInfo, binder) => {
            const value = cellInfo.value ? cellInfo.value.name : '';
            return <span>{value}</span>
          }
        },
        {
          Header: 'CWP',
          accessor: 'state.cwp',
          Cell: (cellInfo, binder) => {
            const value = cellInfo.value ? cellInfo.value.name : '';
            return <span>{value}</span>

          }
        },
        {
          Header: 'Equipment Required Date',
          accessor: 'state.equipmentRequiredDate',
          Cell: (cellInfo, binder) => {
            const value = cellInfo.value ? cellInfo.value : '';
            return <span style={{ textAlign: "center" }}>{value}</span>

          }
        },
      ],
      [assemblyWorkOrderData]);

  const disableRemove = controller.state.hasFile || controller.state.fileName
    ? false
    : true;

  const handleUploadDiagram = (res) => {
    var fileType = (res.file.name.split('.')[1] || '').toLowerCase();
    if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'pdf') {
      props.controller.uploadFile(res);
    }
    else {
      notifications.action('error').post('Uploaded file is not of acceptable format .Please upload either image file or pdf file');
    }
  }
  const uploadedFile = disableRemove === false ? controller.state.uploadedFile : '';
  const fileType = uploadedFile?.fileType?.toLowerCase();
  const isImageFile = fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? true : false;

  const handleRemoveDiagram = () => {
    props.controller.removeUploadedFile();
  }

  const OpenInNewTab = () => {
    const fileURL = controller.state.previewUrl;
    const fileName = controller.state.uploadedFile?.fileName;
    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.download = fileName;
    tempLink.click();
    tempLink.remove();
    return true;
  }
  const uploadedfileextn = (controller.state.fileName?.split('.')[1] || '')?.toLowerCase();
  const isImageFileuploaded = uploadedfileextn === 'jpg' || uploadedfileextn === 'jpeg' || uploadedfileextn === 'png' ? true : false;
  const imageField = disableRemove === true ?
    <img className='img-responsive center-block' alt={''} src={'./assets/images/asset_diagrams/NoDiagramAvailable.png?1682673439650'} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '267px', maxWidth: '267px' }} />
    : isImageFile === true ? <a><img alt={''} src={uploadedFile.fileData} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '267px', maxWidth: '267px' }} onClick={() => OpenInNewTab()} /></a>
      : controller.state.fileDownloadUrl !== '' ? <a href={uploadedfileextn ? controller.state.fileDownloadUrl : null} target="#"><img className='img-responsive center-block' alt={''} src={isImageFileuploaded ? controller.state.fileDownloadUrl : './assets/images/asset_diagrams/PDF.png?1682673439650'} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '267px', maxWidth: '267px' }} onClick={!uploadedfileextn ? () => { OpenInNewTab(); } : null} /></a>
        : <a><img className='img-responsive center-block' alt={''} src={'./assets/images/asset_diagrams/PDF.png?1682673439650'} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '267px', maxWidth: '267px' }} onClick={() => OpenInNewTab()} /></a>
    ;


  const handleClearList = () => {
    props.controller.clearList();
  }

  const uploadReplaceDiagramText = controller.state.hasFile || controller.state.existingFileName!=''
    ? 'Replace'
    : 'Upload';

  return (
    <>
      <div className='col-12'>
        <div><Form.Header className='alert-warning'>System Configuration - <span style={{ fontWeight: 'normal' }}>Add a new row for each asset</span></Form.Header></div>
        <div className='row'>
          <div className='col-xl-10 col-lg-12 mb-2 div-awo-system-configuration assembly-grid'>
            <div className='row'>
              <div className='col-12'>
                <div style={{ overflowY: "auto" }} className='mb-2'>
                  <Grid id='results-table' getCellProps={getCellProps} columns={columns} data={data} editable={true} canAddNewRow={false} showPagination={false} noDataText='' />
                </div>
              </div>
              <div className='col-12'>
                <Button icon='fa-plus' variant='success' size='sm' className='m-1' onClick={handleAddNewRow}>Add New Row</Button>
                <Button icon='fa fa-check-circle' variant='success' size='sm' className='m-1 float-right' onClick={handleAddToList} disabled={disableAddToList}>Add to List</Button>
                <Button icon='fa-times' variant='info' size='sm' className='m-1 float-right' onClick={handleClearList}>Clear</Button>
                {/* <Button icon='fa fa-check-circle' variant='success' size='sm' className='m-1 float-right' onClick={handleAddToList}>Add to List</Button> */}
              </div>
            </div>
          </div>
          <div className='col-xl-2 col-lg-12 div-awo-system-configuration assembly-image'>
            <div className='row'>
              <div className='col-12 text-center'>
                <div className='justify-content-center'>
                  {imageField}
                  {/* <img className='img-responsive center-block' alt={''} src={'./assets/images/asset_diagrams/NoDiagramAvailable.png?1682673439650'} style={{ borderWidth: '0px', border: 'solid 1px #7f9db9', cursor: 'pointer', maxHeight: '300px', maxWidth: '300px' }} /> */}
                </div>
              </div>
              <div className='col-12 text-center'>
                <div className='d-flex justify-content-center'>
                  <Form.Header className='alert-warning' style={{ width: "267px" }}>
                    <div className='col-1g-12 col-xl-12'>
                      <DropZone onDrop={handleUploadDiagram} multiple={false} disabled={false} style={{ borderStyle: 'none', padding: 'unset', display: 'unset' }} width={170} height={23}>
                        <div className='row'>
                          <div className='col-xl-12 col-lg-12'>
                            <Form.FieldHint style={{ width: "100%" }} hint='Drag &amp; Drop &rarr;' />&nbsp;
                          </div>
                          <div className='col-xl-12 col-lg-12'>
                            <GroupBadge style={{ width: "100%" }} className='m-1' type='file' variant='success' pill={false} icon='fa-upload' disabled={false}>{uploadReplaceDiagramText}</GroupBadge>
                          </div>
                        </div>
                      </DropZone>
                    </div>
                    <div className='col-xl-12 col-lg-12'>
                      <GroupBadge style={{ width: "100%" }} className='m-1' variant='danger' icon='fa-trash' pill={false} onClick={handleRemoveDiagram} disabled={disableRemove}>Remove</GroupBadge>
                    </div>
                  </Form.Header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12  mt-2'>
        <div><Form.Header className='alert-warning'>Add Assembly Work Order</Form.Header></div>
        <div className='row'>
          <div className='col-lg-8'>
            <div id='add-assets-table'>
              <Grid id='results-table' getCellProps={getCellProps} columns={columns_2} data={assemblyWorkOrderData} editable={true} canAddNewRow={false} showPagination={false} noDataText='No AWO Record' />
            </div>
          </div>
          <div className='col-lg-4'>
            <div class="h-100 d-flex justify-content-center">
              <div className='row'>
                {isSAPFacility && <div className='col-12'>
                  <br />
                  <br />
                  <br />
                  <label className='minutes-label mr-1'>Minutes <i className='fa fa-exclamation-triangle text-warning' /></label><Form.Input label='' className='required' type='number' min='0' bind={binder.bind('minutes')} />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const EditForm = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  const errors = controller.state.errors;
  const facilityId = LoginService.loginInfo.facilityId;
  const ownerId = controller.state.owner?.id;
  const functionLocation = controller.state.functionalLocation?.name;
  const isSAPFacility = SAPService.isSAPFacility();
  const rowWidth = "col-md-3";
  let currentYear = new Date();
  return (
    <div className='col-12'>
      <div>
        <Form.Header className='alert-warning'>Customer</Form.Header>
      </div>
      <div id='customer' className='row'>
        {!isSAPFacility && <div className={rowWidth}><Form.Input disabled={false} label='AWO Number' className='required' bind={binder.bind('awoNumber')} maxlength='40' error={errors.awoNumber} /></div>}
        <div className={rowWidth}><Form.AutoComplete label='AWO Customer' enabled={true} className='required' bind={binder.bind('awoCustomer')} search={Searchers.awocustomerSearch()} error={errors.awoCustomer} /></div>
        <div className={rowWidth}><Form.AutoComplete enabled={true} label='Owner' className='required' disabled={true} bind={binder.bind('owner')} search={Searchers.customerSearchByFacility(facilityId)} error={errors.owner} /></div>
        {isSAPFacility && <div className={rowWidth}><Form.AutoComplete error={errors.customerLocation} label='Customer Location' id='customer-location' className='required' bind={binder.bind('customerLocation')} search={controller.customerLocationSearch()} /></div>}
        <div className={rowWidth}><Form.AutoComplete error={errors.serviceLine} className='required' label='Service Line' bind={binder.bind('serviceLine')} search={Searchers.awoServiceLineSearch()} /></div>
        <div className='col-md-12'><Form.Input label='AWO Description' className='required' bind={binder.bind('awoDescription')} maxlength='40' error={errors.awoDescription} /></div>
        <div className='col-md-2'><Form.AutoComplete label='CWP' bind={binder.bind('cwp')} search={Searchers.awoCWPSearch()} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Functional Location' bind={binder.bind('functionalLocation')} search={Searchers.getCustomerFunctionalLocationList(ownerId)} /></div>
        <div className='col-md-3'><Form.AutoComplete label='Container Description' bind={binder.bind('containerDescription')} search={Searchers.getCustomerContainerListByFunctionalLocation(ownerId, functionLocation)} /></div>
        <div className='col-md-4'><Form.Date label='Equipment Required Date' placeholder='dd-mon-yyyy' className='required' bind={binder.bind('equipmentRequiredDate')} error={errors.equipmentRequiredDate} /></div>
      </div>
      <div>
        <Form.Header className='alert-warning'>Worksite Information</Form.Header>
      </div>
      <div id='customer' className='row'>
        <div className='col-md-4'><Form.Input label='Pad / Worksite / Rig' bind={binder.bind('pad')} maxlength='40' /></div>
        <div className='col-md-4'><Form.Input label='Well Name' maxlength='40' bind={binder.bind('wellName')} /></div>
        <div className='col-md-4'><Form.AutoComplete label='Well Color' bind={binder.bind('wellColor')} search={Searchers.awoWellColorSearch()} /></div>
        <div className='col-md-4'><Form.AutoComplete error={errors.system} className='required' label='System' bind={binder.bind('system')} search={Searchers.awoSystemSearch()} /></div>
      </div>
    </div>
  );

}

const Buttons = (props) => {
  let history = useHistory();

  const handleCreate = () => {
    props.controller.createAWOs();
  }


  const handleClose = () => {
    co(this, function* () {
      const bodyText = 'Please confirm you wish to exit the Duplicate Assembly Work Order page. All data will be lost and new AWO(s) will not be created.';
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Close Duplicate Assembly Work Order'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (!result.OK) {
        return;
      }
      history.goBack();
    });
  }

  const validateNumberToCreate = (evt) => {
    const value = parseInt(evt.target.value, 10);
    if (value < 1) {
      value = 1;
    }
    if (value > 50) {
      value = 50;
    }
    evt.target.value = value;
  }




  const binder = props.binder;

  return (

    <div className='col-md-12 mt-2' >
      <div className='d-flex justify-content-end m-2'>
        <Button icon='fa-save' variant='primary' size='sm' className='mr-1 action-button' onClick={handleCreate} disabled={props.controller.state.isSaving}>Save</Button>
        <Button icon='fa-times' variant='secondary' size='sm' className='mr-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}

const CreateAssetPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class DuplicateAwoPage extends React.Component {


  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const awoId = this.props.match.params.awoId;
    this.props.controller.load(awoId);

  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new DuplicateAWOController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const title = 'Duplicate Assembly Work Order from ' + controller.state.awoNumberHeader;

    return (
      <Page {...this.props} pageTitle='InteServ · Duplicate AWO' id='duplicate-awo-page'>
        <CreateAssetPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row content'>
            <div className='col-12'>
              <EditForm controller={controller} binder={binder} />
              <AddAWO controller={controller} binder={binder} />
              <Buttons controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default DuplicateAwoPage;