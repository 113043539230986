import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import AppConstants from '../utility/AppConstants';

class JobNotesController {
  constructor(parent) {
    this.parent = parent;
    this.job = '';
    this.notes = [];

    const self = this;
    messages.channel('job-status').action('updated').subscribe(data => {
      const job = self.job;
      if (!job || (job.id !== data.jobId)) {
        return;
      }
      self.loadNotes(job.id, true);
    });
  }

  getNotes = () => {
    return this.notes;
  }

  getTitle = () => {
    const job = this.job;
    return 'Job Notes — ' + (job && job.jobNumber);
  }

  load = (jobId) => {
    ajax.get('lookup/getJob', { jobId: jobId }).then(job => {
      this.job = job;
      this.parent.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // JobLookupDto
  }

  loadNotes = (jobId, flashNewNote) => {
    ajax.get('lookup/getJobNotes/', { jobId: jobId }).then(results => {
      this.notes = results;
      this.parent.commit();

      if (flashNewNote) {
        const newNote = results[results.length - 1];
        if (newNote.noteId != 'undefined')
          this.parent.flashNote(newNote.noteId);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // IList<NoteDto>
  }

  canAddNotes = () => {
    return ((LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep) || (LoginService.isTechnician));
  }

  addNote = (text, usersToNotify) => {
    const jobId = this.job.id;
    const parameters = {
      entityId: jobId,
      noteType: AppConstants.NoteTypes.Job,
      text: text,
      usersToNotify: usersToNotify
    };
    this.parent.state.saveInProgress = true;
    this.parent.commit();

    ajax.post('jobProgress/addNote', parameters).then(() => {
      this.loadNotes(jobId, true);
      this.parent.clearNoteText();
      this.parent.state.saveInProgress = false;
      this.parent.commit();
    }).catch(err => {
      this.parent.state.saveInProgress = false;
      this.parent.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: AddNoteDto, out: void
  }
}

export default JobNotesController;