import React from 'react';
import { useHistory, useLocation } from 'react-router'
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Page from '../../components/Page';
import EditJobController from '../controllers/EditJobController';
import AppConstants from '../utility/AppConstants';
import PageHeader from '../components/PageHeader';
import Authentication from '../services/authentication';
import Searchers from '../utility/Searchers';

const Buttons = (props) => {
  let history = useHistory();

  const handleSave = () => {
    props.controller.save(() => {
      history.goBack();
    });
  }

  const handleClose = () => {
    history.goBack();
  }

  const controller = props.controller;
  return (
    <div className='col-12'>
      <div className='mb-1 d-flex justify-content-end'>
        <Button icon='fa-save' id='btn-save' variant='primary' size='sm' className='m-1 action-button' disabled={controller.state.isSaveInProgress} onClick={handleSave}>Save</Button>
        <Button icon='fa-times' id='btn-cancel' variant='secondary' size='sm' className='m-1 action-button' onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
}
const EditForm = (props) => {
  const binder = props.binder;
  const controller = props.controller;
  const errors = controller.state.errors;
  const useSAP = controller.useSAP();
  const canEdit = controller.adding;
  const fldCustomer = canEdit
    ? <div className={useSAP ? 'col-md-4' : 'col-md-3'}><Form.AutoComplete label='Customer' id='customer' className='required' bind={binder.bind('customer')} error={errors.customer} search={controller.customerSearch()} /></div>
    : <div className={useSAP ? 'col-md-4' : 'col-md-3'}><Form.StaticData label='Customer' value={controller.state.customer.name} /></div>;
  const fldCustomerLocation = canEdit ? <div className={useSAP ? 'col-md-4' : 'col-md-3'}><Form.AutoComplete label='Customer Location' id='customer-location' className='required' bind={binder.bind('customerLocation')} error={errors.customerLocation} search={controller.customerLocationSearch()} /></div>
    : <div className={useSAP ? 'col-md-4' : 'col-md-3'}><Form.StaticData label='Customer Location' value={controller.state.customerLocation.name} /></div>;
    const JobsList = () =>{
      const list= Searchers.jobTypeSearch().call();
      return useSAP ?list:list.filter((x) =>  x.name!== 'MMR');
    }
    const fldJobType = controller.getStatus(controller.state.JobDetails)
    ? <Form.AutoComplete label='Job Type' id='job-type' minimumResultsForSearch={-1} className='required' bind={binder.bind('jobType')} error={errors.jobType} search={JobsList} />
    : <Form.StaticData label='Job Type' value={controller.state.jobType.name} />;
  const firstRow = useSAP
    ? <div className='row'>
      <div className='col-md-4'>{fldJobType}</div>
      {fldCustomer}
      {fldCustomerLocation}
    </div>
    : <div className='row'>
      {canEdit
        ? <div className='col-md-3'><Form.Input label='Job Number' className='required' bind={binder.bind('jobNumber')} error={errors.jobNumber} /></div>
        : <div className='col-md-3'><Form.StaticData label='Job Number' value={controller.state.jobNumber} /></div>
      }
      {canEdit
        ? <div className='col-md-3'><Form.AutoComplete label='Job Type' id='job-type' minimumResultsForSearch={-1} className='required' bind={binder.bind('jobType')} error={errors.jobType} data={AppConstants.JobType} /></div>
        : <div className='col-md-3'><Form.StaticData label='Job Type' value={controller.state.jobType.name} /></div>
      }
      {fldCustomer}
      {fldCustomerLocation}
    </div>;
  const fldJobDate = canEdit
    ? <div className='col-sm-4'><Form.Date label='Job Date' placeholder='dd-mon-yyyy' className='required' bind={binder.bind('jobDate')} error={errors.jobDate} /></div>
    : <div className='col-sm-4'><Form.StaticData label='Job Date' value={controller.state.jobDate} /></div>;
  const fldReceiveDate = controller.EnableRecDate(controller.state.JobDetails)
    ? <div className='col-sm-4'><Form.Date label='Receive Date' placeholder='dd-mon-yyyy' className='required' bind={binder.bind('receiveDate')} error={errors.receiveDate} /></div>
    : <div className='col-sm-4'><Form.StaticData label='Receive Date' value={controller.state.receiveDate} /></div>;
  const profitCostCenter = controller.getStatus(controller.state.JobDetails)
    ? <Form.AutoComplete label='Profit/Cost Center' id='profit-costcenter' className='required' minimumResultsForSearch={-1} bind={binder.bind('profitcostcenterId')} error={errors.profitcostcenterId} search={controller.profitcostSearch()} />
    : <Form.StaticData label='Profit/Cost Center' value={controller.state.shortText} />
  const fldProfitCostCenter = useSAP
    ? <div className='col-sm-4'>{profitCostCenter}</div>
    : null;
    const fldWBSNumber = useSAP
    ? <div className='col-sm-4'><Form.Input label='WBS Number' bind={binder.bind('wbsnumber')} /></div>
    : null;
  return (
    <div id='row edit-job-page' className='col-md-12'>
      {firstRow}

      <div className='row'><div className='col-md-12'><Form.Input label='Description' className='required' bind={binder.bind('jobDescription')} error={errors.jobDescription} /></div></div>

      <div className='row'>
        {fldJobDate}
        <div className='col-md-4'><Form.Date label='Requested Completion Date' placeholder='dd-mon-yyyy' className='required' bind={binder.bind('requiredDate')} error={errors.requiredDate} /></div>
        {fldReceiveDate}
      </div>

      <div className='row'>
        <div className='col-md-4'><Form.Input label='Delivery Ticket' bind={binder.bind('deliveryTicket')} /></div>
        <div className='col-md-4'><Form.Input label='Customer Job Number' bind={binder.bind('customerJobNumber')} /></div>
        <div className='col-md-4'><Form.Input label='Customer PO Number' bind={binder.bind('customerPO')} /></div>
      </div>

      <div className='row'>
        <div className='col-md-4'><Form.AutoComplete label='Accounting Indicator' enabled={canEdit} id='accounting-indicator' minimumResultsForSearch={-1} className='required' bind={binder.bind('accountingIndicator')} error={errors.accountingIndicator} search={Searchers.accountingIndicator()} /></div>
        {fldProfitCostCenter}
        <div className='col-md-4'><Form.Input label='Customer Standard' bind={binder.bind('customerStandard')} /></div>
      </div>
      <div className='row'>
        <div className='col-md-8'><Form.TextArea rows={3} label='Notes' bind={binder.bind('notes')} style={{ marginBottom: '10px' }} /></div>
        {fldWBSNumber}
      </div>
    </div>
  );
}

const EditJobPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle}/>;

class EditJobPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandCoordinator();
    const jobId   = this.props.match.params.jobId;
    const dropoff = this.props.location.dropoff;
    this.props.controller.load(jobId, dropoff);
  }

  static defaultProps = {
    controller: new EditJobController()
  }

  handleLeftArrow = () => {
    const history = useHistory();
    history.goBack();
  }

  render() {
    const controller = this.props.controller;
    const binder   = new Binder(this);
    const number   = controller.state.jobNumber;
    const tabTitle = (controller.adding ? 'Create ' : 'Edit ') + 'Job';
    const title    = (controller.adding ? 'Create ' : 'Edit ') + 'Job' + (number ? (' — ' + number) : '');

    return (
      <Page {...this.props} pageTitle={'InteServ · '+ tabTitle} id='edit-job-page'>
        <EditJobPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row content'>
            <EditForm controller={controller} binder={binder} />
            <Buttons controller={controller} />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default EditJobPage;