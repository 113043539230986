import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants  from '../utility/AppConstants';

class CustomerDescriptionWallReadingRowController extends BaseController {
  constructor(parentController, wallReading, custDescId) {
    super('cust-desc-wall-reading-row-controller-' + CustomerDescriptionWallReadingRowController.key++, {
      custDescId               : custDescId,
      manDescReadingId         : wallReading ? wallReading.manDescReadingId         : Utils.emptyGuid,
      custDescManReadingId     : wallReading ? wallReading.custDescManReadingId     : Utils.emptyGuid,
      readingId                : wallReading ? wallReading.readingId                : '',
      readingType              : wallReading ? wallReading.readingType              : AppConstants.ReadingType.Customer,
      newDimension             : wallReading ? wallReading.newDimension             : '',
      minimumDimension         : wallReading ? wallReading.minimumDimension         : '',
      customerMinimumDimension : wallReading ? wallReading.customerMinimumDimension : '',
      customerSelected         : wallReading ? wallReading.readingType === AppConstants.ReadingType.Manufacturer ? wallReading.customerSelected  : true : true,
      errors                   : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('readingId',                'ID',               [{required: true}]);
    this._validator.add('customerMinimumDimension', 'Customer Minimum', [{required: true}]);
    if (this.state.readingType ===  AppConstants.ReadingType.Customer) {
      this._validator.add('newDimension', 'New Dimension', [{required: true}]);
    }
  }

  static key = 1;

  customerSelectedChanged = () => {
    if (!this.state.customerSelected) {
      this.state.customerMinimumDimension = 0;
    }
    this.commit();
    this.parentController.commit();
  }

  canRemoveRow = () => {
    return this.state.readingType === AppConstants.ReadingType.Customer;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeWallReadingRow(this);
  }
}
    
export default CustomerDescriptionWallReadingRowController;
  