import React from 'react';
import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import LoginService from '../services/LoginService';
import co from '../../lib/Co';
import Dialog from '../../components/Dialog';


class EditUserController extends BaseController {
  constructor() {
    super('edit-user', EditUserController.getInitialState());

    this.adding = false;
    this._validator = new Validator();
    this._validator.add('loginName', 'Login Name', [{ required: true }]);
    this._validator.add('role', 'Role', [{ required: true }]);
    this._validator.add('culture', 'Culture', [{ required: true }]);
    this._validator.add('organization', 'Organization', [{ required: true }]);

    messages.channel('edit-user').action('password-reset').subscribe(user => {
      if (!user) {
        return;
      }
      this.load(user.userId, user.customer && user.customer.id, user.customer && user.customer.name);
    });

    messages.channel('edit-customer-location').action('saved').subscribe(() => {
      this.loadUserCustomerLocations();
    });

    messages.channel('edit-user-facilities').action('saved').subscribe(() => {
      this.loadUserFacilities();
    });

    messages.channel('edit-user').action('saved').subscribe(user => {
      this.load(user.userId, user.customer && user.customer.id, user.customer && user.customer.name);
    });
  }

  static getInitialState = () => {
    return {
      userId: Utils.emptyGuid,
      loginName: '',
      loginEnabled: true,
      displayAccount: true, // !this.state.searchCustomers, // force to false for Customers
      pin: '',
      email: '',
      firstName: '',
      lastName: '',
      customerAccount: false,
      role: '',
      culture: '',
      organization: AppConstants.Organizations[0],
      currentFacility: '',
      customer: {},
      userFacilities: [],
      userCustomerLocations: [],
      lastLogin: '',
      errors: {},
      passwordChangeDate: '',
      daysUntilPasswordExpires: '',
      employeeID: '',
      sapProfile: '',
      costCenter: '',
      timeZone: '',
      deleteExistingSgnature: false,
      hasDiagram: false,
      signatureImage: {},
      previewUrl: '',
      imgUrl: '',
      updateSignature:false,
    };
  }

  load = (userId, customerId, customerName) => {
    if (userId === Utils.emptyGuid) {
      this.adding = true;
      this.merge(EditUserController.getInitialState());
      this.state.customer = {
        id: customerId,
        name: customerName
      };
      this.state.customerAccount = !!customerId;
      this.state.hasDiagram = false;
      this.loadAllCustomerLocations();
      this.loadUserCustomerLocations();
      this.loadAllFacilities();
      this.loadUserFacilities();
      this.commit();
    }
    else {
      this.adding = false;
      ajax.get('lookup/getUser/', { userId: userId }).then((results) => {
        this.merge(results);
        this.loadAllCustomerLocations();
        this.loadUserCustomerLocations();
        this.loadAllFacilities();
        this.loadUserFacilities();
        this.state.hasDiagram = results.signatureFileName;
        this.state.updateSignature= false;
        this.state.signatureImage={};
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
  }

  merge = (user) => {
    this.state = Object.assign(this.state, user);
    this.state.organization = AppConstants.Organizations[user.orgId - 1];
    this.state.sapProfile = user.sapProfileName ? { id: user.sapProfile, name: user.sapProfileName } : '';
    this.state.timeZone = (user.timeZone === null || user.timeZone === '')
      ? ''
      : { id: user.timeZoneId, name: user.timeZone }
    this.state.errors = {};
  }

  loadCultures = () => {
    return ajax.get('lookup/getCultures').then((results) => {
      this.state.cultures = results;
      return results;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  loadAllFacilities = () => {
    ajax.get('lookup/getAllFacilities').then((results) => {
      this.state.allFacilities = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  loadUserFacilities = () => {
    ajax.get('lookup/getUserFacilities', { userId: this.state.userId }).then((results) => {
      this.state.userFacilities = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  loadAllCustomerLocations = () => {
    this.state.allCustomerLocations = [];
    var customer = this.state.customer;
    var customerId = customer && customer.id;
    if (!customerId) {
      return;
    }

    if (!this.state.userId) {
      return;
    }

    ajax.get('lookup/getAllCustomerLocationsForCustomer', { customerId: customerId }).then((results) => {
      this.state.allCustomerLocations = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  loadUserCustomerLocations = () => {
    this.state.userCustomerLocations = [];

    var customer = this.state.customer;
    var customerId = customer && customer.id;
    if (!customerId) {
      return;
    }

    var userId = this.state.userId;
    if (!userId) {
      return;
    }

    this.state.userCustomerLocations = [];
    ajax.get('lookup/getUserCustomerLocations', { userId: userId, customerId: customerId }).then((results) => {
      this.state.userCustomerLocations = results;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  facilitySearch = () => {
    const self = this;
    return function* () {
      if (self.state.userFacilities && self.state.userFacilities.length) {
        return self.state.userFacilities;
      }
      return self.loadUserFacilities();
    };
  }

  canSave = () => {
    this._validator.validateAll(this.state);

    const errors = Object.keys(this.state.errors);

    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }

  save = (cb) => {
    if (!this.canSave()) {
      return;
    }

    let user = this.state;
    const parameters = {
      userId: user.userId,
      loginName: user.loginName,
      loginEnabled: user.loginEnabled,
      displayAccount: user.displayAccount,
      pin: user.pin,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      currentFacilityId: user.currentFacility && user.currentFacility.id,
      customerId: user.customer && user.customer.id,
      customerAccount: user.customerAccount,
      roleId: user.role && user.role.id,
      cultureId: user.culture && user.culture.id,
      orgId: user.organization.id,
      employeeID: user.employeeID,
      sapProfile: user.sapProfile && user.sapProfile.id,
      costCenter: user.costCenter,
      timeZone: user.timeZone && user.timeZone.id,
      signatureImage: this.state.signatureImage,
      deleteExistingSgnature: this.state.deleteExistingSgnature,
      updateSignature: this.state.updateSignature,
    };

    ajax.post('user/save', parameters).then((userId) => {
      if (this.adding) {
        notification.action('success').post('Saved new User');
        ajax.get('lookup/getUser', { userId: userId }).then((data) => {
          user = data;
          messages.channel('edit-user').action('saved').post(user);
          cb(userId);
        }).catch(err => {
          notification.action('error').post({ msg: err.message, title: 'Error' });
        });
      }
      else {
        LoginService.updateEmployeeId(user.employeeID, user.userId);
        notification.action('success').post('Saved changes for ' + user.loginName);
        messages.channel('edit-user').action('saved').post(user);
        cb(userId);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  uploadSignature = (res, file) => {

    const fileType = res.file.name.split('.')[1].toLowerCase() || '';
    if (fileType != 'png' && fileType != 'jpg' && fileType != 'jpeg') {
      notification.action('error').post({ msg: 'Invalid file type. .' + fileType + ' is an unrecognized file type to upload', title: 'File Error' });
      return false;
    }

    this.state.signatureImage = {
      id: this.state.userId,
      fileName: file.fileName,
      fileType: file.fileType,
      imgUrl: file.url
    };
    this.state.hasDiagram = true;
    this.state.imgUrl = file.url;
    this.setPreviewImageLink(URL.createObjectURL(res.file));
    this.commit();
  }

  setPreviewImageLink = (url) => {
    this.state.previewUrl = url;
  }

  removeDiagram = () => {
    if (!this.state.hasDiagram) {
      notification.action('warning').post('No diagram – nothing to remove');
      return;
    }
    const message = 'Please confirm you wish to remove this diagram – the file will be deleted';
    const custDescId = this.state.custDescId;

    co(this, function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={300} header='Confirm Remove Diagram'>
          {message}
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        //   yield ajax.post('customerDescription/removeDiagram', custDescId); // in: Guid, out: void
        //   messages.channel('edit-cust-desc').action('saved').post({ custDescId: custDescId });
        this.state.hasDiagram = false;
        this.state.signatureImage = {};
        this.state.previewUrl = '';
        this.state.imgUrl=this.state.altImgUrl;
        this.state.updateSignature= true;
        if(this.state.signatureFileName && this.state.signatureFileName!=''){
          this.state.deleteExistingSgnature=true;
        }
        this.state.signatureFileName='';
        this.commit();
      }
    });
  }

  SaveCroppedImage = (res, croppedImageLink) => {
    this.state.signatureImage.imgUrl = res;
    this.state.imgUrl = croppedImageLink;
    this.state.updateSignature= true;
    this.setPreviewImageLink(croppedImageLink);
    console.log('croppedImageLink - '+croppedImageLink);
    this.commit();
    return true;
  }

  validateUploadedFile=(type)=>{
    const fileType = type.toLowerCase() || '';
    if (fileType != 'png' && fileType != 'jpg' && fileType != 'jpeg') {
      notification.action('error').post({ msg: 'Invalid file type. .' + fileType + ' is an unrecognized file type to upload', title: 'File Error' });
      return false;
    }
    return true;
  }
}

export default EditUserController;