import React from 'react';
import ActionIcon from '../../components/ActionIcon';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Utils from '../utility/Utils';
import Table from '../../components/Table';

class DimensionValueRow extends React.Component{
  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);

    this.binder = new Binder(this);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleRemoveRow = () => {
    this.props.controller.removeRow();
  }

  render = () => {
    const controller         = this.props.controller;
    const parentController   = controller.parentController;
    const binder             = this.binder;
    const errors             = controller.state.errors;
    const isViewMode         = parentController.state.isViewMode;
    const minimumDimension     = controller.state.minimumDimension != 0 ?  controller.state.minimumDimension : '';
    const canDeleteRow       = controller.state.minimumDimensionMappedId == Utils.emptyGuid && !isViewMode;
    const tdAction           = canDeleteRow  
                                ? <Table.Data className='col-action'><ActionIcon icon='fa-trash' className='remove-handle' action={this.handleRemoveRow} /></Table.Data>
                                : <Table.Data className='col-action'></Table.Data>;
    const fldminimumDimension  = <Form.StaticData  value={minimumDimension}/>
    const fldactualDimension   = <Form.Input bind={binder.bind('actualDimension')}  className='required' error={errors.actualDimension} placeholder='Enter a value' />
    return (
        <Table.Row>
          {tdAction}
          <Table.Data className='col-minimumDimension'>{fldminimumDimension}</Table.Data>
          <Table.Data className='col-actualDimension' >{fldactualDimension}</Table.Data>
        </Table.Row>
    );
  }
}

export default DimensionValueRow;
