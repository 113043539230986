import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import store from 'store';
import Page from '../../components/Page';
import Badge from '../../components/Badge';
import PageHeader from '../components/PageHeader';
import Form from '../../components/Form';
import Grid from '../../components/Grid';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Authentication from '../services/authentication';
import UsersController from '../controllers/UsersController';
import Search from '../components/Search';
import Utils from '../utility/Utils';
import Roles from '../utility/Roles';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants  from '../utility/AppConstants';
import LoginService from '../services/LoginService';
import Jumbotron from '../../components/Jumbotron';

const SearchHeader = (props) => {

    const binder               = props.binder;
    const controller           = props.controller;
    const enableSearchCustomer = controller.state.searchCustomers;

    const formatResult = (item) =>  {
        if ((item.result.count !== undefined) && (item.result.count !== -1)) {
            let style = { top: '-0.125rem'}; 
            if (item.result.count === 0) {
                style = { ...style, backgroundColor: 'lightgrey', color: 'grey' };
            }
            else{
                style = { ...style, backgroundColor: 'lightgreen', color: 'black' };
            }
            return <div className='d-flex justify-content-between'><span>{item.text}</span><span><Badge style={style}>{item.result.count}</Badge></span></div>;
        }
        return <div>{item.text}</div>;
    };

    return (
        <div>
            <div className='row'>
                <div className='col-md-6'>
                    <Search className='mb-2' label='Enter search text' placeholder='login, first, or last name, email or role' bind={binder.bind('searchText')} clear={controller.clearUserSearch} search={controller.searchUsers} />
                </div>
                <div className='col-md-6'>
                    <div className={controller.state.searchCustomer ? 'text-danger font-weight-bold' : null}><Form.Label name='Customers' prependLabelIcon='fa-filter' /></div>
                    <Form.AutoComplete  formatResult={formatResult} formatSelection={formatResult}  enabled={enableSearchCustomer}  bind={binder.bind('searchCustomer')} search={controller.customerSearch()} />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='d-inline-block mr-3'>
                        <Form.Label name='Login enabled or disabled' />
                        <Form.RadioInput bind={binder.bind('searchEnabled')} label='Enabled' />
                        <Form.RadioInput bind={binder.bind('searchDisabled')} label='Disabled' />
                    </div>
                    <div className='d-inline-block'>
                        <Form.Label name='User type to search' />
                        <Form.RadioInput bind={binder.bind('searchFMC')} label='TechnipFMC users' />
                        <Form.RadioInput bind={binder.bind('searchCustomers')} label='Customers' />
                    </div>
                </div>
            </div>           
        </div>
    );

}

const Results = (props) => {

    const history = useHistory();

    const handleEdit = (user, customer) => {
        var data = {
            userId: user.userId,
            customerId: customer && customer.customerId,
            customerName: customer && customer.customerName
        };
        store.set('edit-user', data);
        history.push('/users/' + user.userId);
    };

    const handleRowClick = (user) => {
        props.controller.loadUserDetails(user);
    };

    const controller = props.controller;
    const searchFMC = controller.state.searchFMC;
    const searchEnabled = controller.state.searchEnabled;
    const data = controller.state.searchResults || [];
    const matchesCount = controller.state.searchResults.length;
    let matchesLabel;
    if ((matchesCount === controller.state.totalSearchResultsCount) || (controller.state.totalSearchResultsCount === -1)) {
      matchesLabel = Utils.pluralize('match', 'es', matchesCount);
    }
    else {
      matchesLabel = matchesCount + ' / ' + controller.state.totalSearchResultsCount + ' matches';
    }
    const noUsers = <span>No <span className='err-msg'>{controller.state.searchFMC ? 'users' : 'customers'}</span> meet the search criteria defined above</span>;
    const noDataText   = controller.state.isLoading ? 'Loading users that meet the search criteria defined above': noUsers;
    const getRoleClass = (user) => {

        if (user.role && user.role.name === Roles.Administrator) {
            return ' user-administrator';
        }
        if (user.role && user.role.name === Roles.SuperUser) {
            return ' user-super-user';
        }
        if (user.role && user.role.name === Roles.DataClerk) {
            return ' user-data-clerk';
        }
        if (user.role && user.role.name === Roles.Coordinator) {
            return ' user-coordinator';
        }
        if (user.role && user.role.name === Roles.SAPRep) {
            return ' user-sap-rep';
        }
        if (user.role && user.role.name === Roles.Technician) {
            return ' user-technician';
        }
        return '';
    }

    const getRowProps = (row) => {
        const user = row.original;
        let className = !searchEnabled ? 'text-muted ' : getRoleClass(user);
        let lastLogin = DateTimeFormatter.formatDate(user.lastLogin);
        if (!lastLogin) {
            className += ' font-italic ';
        }
        const rowProps = { className: className };
        return rowProps;
    }

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'loginDisabled',
                isVisible: !searchEnabled,
                disableSortBy: true,
                Cell: (cellInfo) =>  <i className='text-danger fa fa-ban' />
            },
            {
                Header: 'Login Name',
                accessor: 'loginName',
                Cell: (cellInfo) =>  <Form.Link value={cellInfo.cell.value} onClick={(e) => handleEdit(cellInfo.row.original, controller.customer)}/>
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: searchFMC ? 'Current Facility' : 'Customer',
                accessor: searchFMC ? 'currentFacility.name' : 'customer.name',
            },
            {
                Header: 'Role',
                accessor: 'role.name',
            },
            {
                Header: 'Culture',
                accessor: 'culture.name',
            },
            {
                Header: 'Last Login',
                accessor: 'lastLogin',
                Cell: (cellInfo) => {
                    let lastLogin = DateTimeFormatter.formatDate(cellInfo.cell.value);
                    if (!lastLogin) {
                        lastLogin = 'never';
                    }
                    else {
                        lastLogin = DateTimeFormatter.fromNowTime(cellInfo.cell.value)
                    }
                    return lastLogin;
                }
            },
        ],
        [searchFMC, searchEnabled]
    )
    return (
        <div className='col-md-12'>
            <div className='d-flex justify-content-end'>
                <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
            </div>
            <Grid columns={columns} data={data} onRowClick={handleRowClick} noDataText={noDataText} enableDefaultRowSelect={true} getRowProps={getRowProps}/>
        </div>     
    );

}


const Buttons = (props) => {
    const history = useHistory();

    const controller = props.controller;

    const handleEdit = (user) => {
        var data = {
            userId: user.userId,
            customerId: user.customer && user.customer.id,
            customerName: user.customer && user.customer.name
        };
        store.set('edit-user', data);
        history.push('/users/' + user.userId);
    };

    const handleCreate = () => {
        const customer   = controller.state.searchCustomer;
        const data = {
            userId: '',
            customerId: customer && customer.customerId,
            customerName: customer && customer.customerName
        };
        store.set('edit-user', data);
        history.push('/users/' + Utils.emptyGuid);
    };
    
    
    const user       = controller.state.selectedUser;
    const canEdit    = user.userId;
    const canAdd     = (((controller.state.searchCustomers && controller.state.searchCustomer)) || !controller.state.searchCustomers);


    return (
        <div className='col mb-1'>
            <div className='row'>
                <Button disabled={!canEdit} icon='fas fa-pencil-alt' variant='primary' size='sm' className='action-button m-1' onClick={e => { handleEdit(user) }}> Edit... </Button>
                <Button disabled={!canAdd} icon='fa-plus-circle'variant='success' size='sm' className='action-button m-1' onClick={handleCreate}>Create New...</Button>
            </div>
        </div>
    );
}

const Details = (props) => {

    const handleNewUser = () => {
        props.controller.newUser();
    }

    const isUserSelected = (user) => {
        return user && user.userId;
    }
    
    const controller              = props.controller;
    const selectedUser            = controller.state.selectedUser;
    const fldEmployeeId           = !controller.state.searchCustomers
                                   ?  <Form.StaticData label='Employee Id' value={selectedUser.employeeID} />
                                   :  null; 
    let datePasswordLastChanged = DateTimeFormatter.formatDate(selectedUser.passwordChangeDate);
    if (isUserSelected(selectedUser) && !datePasswordLastChanged) {
      datePasswordLastChanged = 'unknown';
    }

    let passwordExpiredClassName = null;
    let passwordExpiredIcon      = null;
    let daysUntilPasswordExpires = selectedUser.daysUntilPasswordExpires;

    if ((daysUntilPasswordExpires === 0) && (datePasswordLastChanged === 'unknown')) {
      daysUntilPasswordExpires = '';
    }
    else if (daysUntilPasswordExpires === 0) {
      daysUntilPasswordExpires = 'expires today';
      passwordExpiredClassName = 'late-error';
      passwordExpiredIcon      = 'late-error fa-key';
    }
    else if (daysUntilPasswordExpires < 0) {
      daysUntilPasswordExpires = 'expired ' + Utils.pluralize('day', 's', -daysUntilPasswordExpires) + ' ago';
      passwordExpiredClassName = 'late-error';
      passwordExpiredIcon      = 'late-error fa-key';
    }
    else if (daysUntilPasswordExpires < AppConstants.PasswordExpirationWarningDays) {
      daysUntilPasswordExpires = LoginService.passwordExpirationMessage(daysUntilPasswordExpires);
      passwordExpiredClassName = 'late-warning';
      passwordExpiredIcon      = 'late-warning fa-key';
    }

    return (
        <Jumbotron className='col'>
            <div className='row'>
                <div className='col-md-12'><Form.StaticData label='Login Name' value={selectedUser.loginName} /></div>
                <div className='col-md-6'><Form.StaticData label='First Name' value={selectedUser.firstName} /></div>
                <div className='col-md-6'><Form.StaticData label='Last Name' value={selectedUser.lastName} /></div>
                <div className='col-md-12'><Form.StaticData label='Email' value={selectedUser.email} /></div>
                <div className='col-md-6'><Form.StaticData label='PIN' value={selectedUser.pin} /></div>
                <div className='col-md-6'>{fldEmployeeId}</div>
                <div className='col-md-6'><Form.StaticData label='Login Enabled' value={Utils.formatBool(selectedUser.loginEnabled)} /></div>
                <div className='col-md-6'><Form.StaticData label='Display Account' value={Utils.formatBool(selectedUser.displayAccount)} /></div>
                <div className='col-md-6'><Form.StaticData label='Date Created' value={DateTimeFormatter.formatDate(selectedUser.dateCreated)} /></div>
                <div className='col-md-6'><Form.StaticData label='Number of Logins' value={selectedUser.numberOfLogins} /></div>
                <div className='col-md-6'><Form.StaticData label='Date password last changed' value={datePasswordLastChanged} className={passwordExpiredClassName} /></div>
                <div className='col-md-6'><Form.StaticData label='Days until password expires' value={daysUntilPasswordExpires} className={passwordExpiredClassName} icon={passwordExpiredIcon} /></div>
            </div>
        </Jumbotron>
    );
}

const UsersPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fas fa-users' />

class UsersPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandSuperUser();
    }

    static defaultProps = {
        controller: new UsersController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Page {...this.props} pageTitle='InteServ · Users' id='users-page'>
                <UsersPageHeader pageTitle={'Users'} />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <SearchHeader controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <Results controller={controller} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Buttons controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <Details controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
};

export default UsersPage;
