import React from 'react';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';

const NoSuchRoutePageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />

const NoSuchRoutePage = (props) => {

    const title = 'Page does not exist';

    return (
        <Page {...props} pageTitle='InteServ · No Such Page' id='no-such-route-page'>
            <NoSuchRoutePageHeader pageTitle={title} />
            <Page.Content>
                <div style={{textAlign: 'center'}}>
                        <div className='mt-5'> 
                            <span className='h5'>404 - Page not found</span>
                        </div>
                </div>
            </Page.Content>
        </Page>
    );
}

export default NoSuchRoutePage;