import React from 'react';
import AssetChartController from '../controllers/AssetChartController';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notifications from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import AssetChartDialog from '../dialogs/AssetChartDialog';

class FieldAssetManagementController extends BaseController {
  constructor() {
    super('receiving', {
      currentAsset      : '',
      searchText        : '',
      receivedAssets    : [],
      boneyards         : [],
      currentCustomerId : '',
      serviceHistory    : [],
      qrCanvasVisibility: false
    });

    messages.channel('edit-asset').action('saved').subscribe(savedAsset => {
      var currentAsset = this.state.currentAsset;
      if (currentAsset && (currentAsset.assetId === savedAsset.assetId)) {
        this.state.currentAsset = savedAsset;
        this.commit();
      }
    });

    messages.channel('create-new-asset').action('saved').subscribe(newAsset => {
      this.state.currentAsset   = newAsset;
      this.state.searchText     = newAsset.primarySerialNumber;
      this.state.serviceHistory = [];
      this.commit();
    });

    messages.channel('transfer-to-idle-assets').action('saved').subscribe(() => {
      this.state.receivedAssets    = [];
      this.state.currentAsset      = '';
      this.state.currentCustomerId = '';
      this.state.serviceHistory    = [];
      this.commit();
    });

    messages.channel('assign-assets-to-job').action('saved').subscribe(() => {
      this.state.receivedAssets    = [];
      this.state.currentAsset      = '';
      this.state.currentCustomerId = '';
      this.state.serviceHistory    = [];
      this.commit();
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.clearResults();
        this.state.currentAsset = '';
        this.state.searchText   = '';
      }
    });
  }

  clearSearchText = () => {
    this.state.searchText = '';
    this.commit();
    document.getElementById('searchText').focus();
  }

  loadAsset = (assetId) => {
    co(this, function*() {
      if (assetId) {
        const result                = yield ajax.get('lookup/getAsset/', { assetId: assetId }); // ReceivedAssetDto
        this.state.serviceHistory = [];
        if (result !== null) {
          this.state.currentAsset = result;
          this.state.searchText   = result.primarySerialNumber;
        }
        this.commit();
      }
    });
  }

  handleRfidSearch = (value) => {
    const serialNumber = { value: '' };
    if (Utils.tryProcessAsRfidTag(value, serialNumber )) {
      this.state.searchText = value; // don't update searchText with decoded rfid tag - let server re-do that
      this.findAsset();
    }
  }

  findAsset = () => {
    const searchText = this.state.searchText;
    if (!searchText) {
      return;
    }

    this.state.currentAsset   = '';
    this.state.serviceHistory = [];

    co(this, function*() {
      this.state.searchText   = searchText;
      var result              = yield ajax.get('lookup/searchAsset', {serialNumber: searchText, customerId: LoginService.loginInfo.customerId}); // ReceivedAssetDto
      this.state.currentAsset = result;
      this.state.searchText   = result.primarySerialNumber;
      notifications.action('info').post({msg: 'Found asset with serial number ' + result.primarySerialNumber + ': ' + result.description.name, title: 'Asset Found'});
      this.commit();
    });
  }

  getChart = () => {
    const asset = this.state.currentAsset;
    if (!asset || !asset.hasChart) {
      return;
    }

    co(this, function*() {
      const result = yield ajax.get('lookup/getAssetChart', {chartHeaderId: asset.chartHeaderId}); // AssetChartDto
      if (result) {
        this.showChart(result);
      }
      else {
        notifications.action('warning').post({msg: 'No chart data available for asset with serial number ' + asset.primarySerialNumber, title: 'Asset Chart Not Found'});
      }
    });// AssetChartDto
  }

  showChart = (chart) => {
    co(this, function*(){
      yield Dialog.showDialogWaitForResult(<AssetChartDialog controller={new AssetChartController(chart)} header='Pressure Test Chart' />);
    });
  }

  canPerformBoneyardSearch = (facilityId, customerId) => {
    if (!facilityId) {
      notifications.action('warning').post({msg: 'You don\'t have a current facility defined', title: 'Unable to locate Idle Asset locations'});
      return false;
    }

    if (!customerId) {
      // notifications.action('warning').post({msg: 'You must add at least 1 asset to the Received Assets list so the customer can be determined', title: 'Unable to locate Idle Asset locations'});
      return false;
    }
    return true;
  }

  getBoneyards = () => {
    var facilityId = LoginService.loginInfo && LoginService.loginInfo.facilityId;
    var customerId = this.state.currentCustomerId;

    if (!this.canPerformBoneyardSearch(facilityId, customerId)) {
      return;
    }

    co(this, function*() {
      try {
        var results          = yield ajax.get('lookup/getBoneyards', {customerId: customerId, facilityId: facilityId}); // IList<LookupDto>
        this.state.boneyards = results;
        this.commit();
      }
      catch (err) {
        this.state.boneyards = [];
        this.commit();
        notifications.action('warning').post({msg: err, title: 'No idle asset locations'});
      }
    });
  }

  canProcess = (asset) => {
    return (asset && asset.isReceivable);
  }

  canAssignToJob = (asset) => {
    return (this.state.receivedAssets.length > 0);
  }

  canTransferToIdleAssets = () => {
    return ((this.state.receivedAssets.length > 0) && (this.state.boneyards.length > 0));
  }

  isAssetReceived = (newAsset) => {
    let isReceived = false;
    this.state.receivedAssets.forEach(asset => {
      isReceived |= (newAsset.assetId === asset.assetId);
    });
    return isReceived;
  }

  canReceiveAsset = (asset) => {
    if (this.isAssetReceived(asset)) {
      notifications.action('warning').post({msg: 'Asset with serial number ' + asset.primarySerialNumber + ' already exists in the Received Assets list', title: 'Unable to receive asset again'});
      return false;
    }
    else if (this.state.currentCustomerId && (asset.customer.id !== this.state.currentCustomerId)) {
      notifications.action('warning').post({msg: 'You cannot receive an asset for a different customer than has already been started', title: 'Unable to change customer while receiving'});
      return false;
    }
    else if (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped) {
      notifications.action('warning').post({msg: 'You cannot receive an asset that has previously been scrapped', title: 'Unable to receive scrapped iron'});
      return false;
    }
    return true;
  }

  receiveAsset = (asset) => {
    if (!this.canReceiveAsset(asset)) {
      return;
    }

    this.state.searchText = '';
    this.state.receivedAssets.unshift(asset);
    if (this.state.receivedAssets.length === 1) {
      this.state.currentCustomerId = asset.customer.id;
      this.getBoneyards();
    }
    this.state.currentAsset = '';
    this.commit();
  }

  clearResults = () => {
    this.state.receivedAssets    = [];
    this.state.boneyards         = [];
    this.state.currentCustomerId = '';
    this.commit();
  }

  removeReceivedAsset = (asset) => {
    var receivedAssets = this.state.receivedAssets;
    receivedAssets.splice(receivedAssets.indexOf(asset), 1);
    if (receivedAssets.length === 0) {
      this.state.currentCustomerId = '';
      this.state.boneyards         = [];
    }
    this.commit();
  }

  clearSearchResults = () => {
    this.state.currentAsset = '';
    this.state.searchText   = '';
    this.commit();
  }

  toggleQrVisiblity = () =>{
    this.state.qrCanvasVisibility = !this.state.qrCanvasVisibility;
    this.commit();
  }

  findAssetWithQRCode = (code) =>{
    if(!code){
      return '';
    }
    const url = code.split('/');
    if(url.length === 6 && Utils.contains(code, 'http') && Utils.contains(code, 'scannedqrasset')) {
      const assetId = url[url.length - 1];
      co(this, function*() {
        if (assetId) {
          const result                = yield ajax.get('lookup/getAsset/', { assetId: assetId }); // ReceivedAssetDto
          this.state.serviceHistory = [];
          if (result !== null) {
            this.state.currentAsset = result;
            this.state.searchText   = result.primarySerialNumber;
            this.commit();
          }
          else{
            notifications.action('error').post({msg: 'Asset not found'});
          }
        }
      });
    }
    else{
      notifications.action('warning').post({msg: 'Invalid QR'});
    }
  }
}

export default FieldAssetManagementController;
