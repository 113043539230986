import store from 'store';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import ServiceLevelHelper from '../services/ServiceLevelHelper';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import co from '../../lib/Co';
import { isDebuggerStatement } from 'typescript';

class SelectAssemblyLevelController extends BaseController {
  constructor() {
    super('select-awo-service-levels', {
      assemblylevels: [],
      tests: [],
      assets: [],
      indeterminateAssemblyLevels: [],
      awoId: '',
      awoNumber: '',
      customerName: '',
      selectedAssemblyLevel: '',
      hours: '0',
      minutes: '0',
      isSaveInProgress: false,
      groupCount: 0,
      configIds: [],
      selectedConfigIds: [],
    });
    this.data = '';
    this.load();
    messages.channel('assembly-levels').action('assign-data').subscribe((data) => {
      this.data = data;
    });
  }


  load = () => {
    this.clear();
    let configIds = '';
    let selectedConfigIds = [];
    var awoData = this.data;
    if (awoData === '') {
      return false;
    }
    
   var selectedConfis = awoData?.configs?.filter(config => config.state.isChild === false).map(x => {
      return configIds = configIds + x.state.awo_SOW_GUID + ','
    });
    var selectedConfigs = awoData?.configs?.map(x => {
        selectedConfigIds.push(x.state.awo_SOW_GUID)
       return selectedConfigIds;
    });

    this.state.groupCount = awoData?.configs?.filter(config => config.state.isChild === false).length;
 
    var parameters = {
      awoId: awoData.awoId,
      configs: configIds
    };

    co(this, function* () {
      ajax.get('lookup/awo/SearchAssemblyLevels/', parameters).then(results => {// ReceivedAssetDto
        if (results !== null) {
          var result = results;
          this.commit();

          this.state.configIds = result.configIds.map(asset => asset);
          this.state.assemblylevels = ServiceLevelHelper.initializeAssemblyLevels(result.assemblylevels);
          this.state.tests = ServiceLevelHelper.findDistinctTestsInAlphabeticOrder(result.assemblylevels);
          this.state.indeterminateAssemblyLevels = undefined;// ServiceLevelHelper.findSelectedNonIntersectingServiceLevels(this.state.assets);
          this.state.selectedAssemblyLevel = '';
          const selectedAssemblyLevels = this.state.assemblylevels?.filter(sl => sl.performed);
          if (selectedAssemblyLevels.length > 0) {
            let selectedAssemblyLevel = selectedAssemblyLevels[0];
            this.state.selectedAssemblyLevel = selectedAssemblyLevel;
          }
          this.state.hours = 0;
          this.state.minutes = '0';
          this.state.selectedConfigIds = selectedConfigIds;
          this.state.isSaveInProgress = false;
          this.commit();
        }
      });
    });
    this.state.awoId = awoData.awoId;
    this.state.awoNumber = awoData.awoNumber;
    this.state.customerName = awoData.customerName;
    this.commit();
  }

  clear = () => {
    this.state.awoId = '';
    this.state.assets = [];
    this.state.assemblyLevels = [];
    this.state.tests = [];
    this.state.indeterminateAssemblyLevels = [];
  }

  selectAssemblyLevel = (serviceLevel) => {
    // SAP facility - radio button click
    this.state.assemblylevels.forEach(sl => {
      sl.performed = false;
      sl.indeterminate = false;
    });
    serviceLevel.performed = true;
    this.state.selectedAssemblyLevel = serviceLevel;
    this.state.indeterminateAssemblyLevels = [];
    //  this.state.selectedBillOfMaterial = '';
    this.commit();
  }

  toggleAssemblyLevelSelection = (assemblyLevel) => {
    // non-SAP facility - check box click
    assemblyLevel.indeterminate = false;
    assemblyLevel.performed = !assemblyLevel.performed;
    this.state.selectedAssemblyLevel = assemblyLevel;
    this.commit();
  }

  canSave = () => {
    const assemblylevel = this.state.selectedAssemblyLevel;
    if (!assemblylevel) {
      notification.action('error').post('You must select an Assembly Level');
      return false;
    }
    if (!SAPService.isSAPFacility()) {
      return true;
    }
    return true;
  }

  save = (cb) => {

    if (!this.canSave()) {
      return;
    }
    const state = this.state;
    const hours = parseFloat(isNaN(state.hours) ? 0 : state.hours);
    const minutes = parseFloat(isNaN(state.minutes) ? 0 : state.minutes);
    const value = ((60 * (isNaN(hours) ? 0 : hours)) + (isNaN(minutes) ? 0 : minutes));
    const loginInfo = store.get('InteServLoginInfo');
    if (SAPService.isSAPFacility() && SAPService.useTTS()) {
      if (!loginInfo.isClockedIn) {
        notification.action('error').post("Currently Not Clocked In");
        return false;
      }
      if (loginInfo.isClockedOut) {
        notification.action('error').post("ClockedOut for the day");
        return false;
      }
      if (value <= 0) {
        notification.action('error').post("Receiving Time should be greater than 0");
        return false;
      }
      if (parseFloat(value).toFixed(1) <= 0) {
        notification.action('error').post("After rounding to 1 decimal, time will become 0. Receiving Time should be greater than 0");
        return false;
      }
      // if(hours>23)
      // {
      //   notification.action('error').post("Hours cannot be more than 23");
      //   return false;
      // }
      // if(minutes>60)
      // {
      //   notification.action('error').post("Minutes cannot be more than 60");
      //   return false;
      // }
      // if(value<this.state.assets.length)  
      // {
      //   notification.action('error').post("Minimum 1 minute should be recorded for each asset");
      //   return false;
      // }  
      const availableTime = (DateTimeFormatter.getDuration(loginInfo.clockedInTime, loginInfo.isBreakOut ? loginInfo.breakOutTime : DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())) - (loginInfo.totalBreakTime + (loginInfo.totalProductiveTime || 0)));
      if (value > availableTime) {
        notification.action('error').post("Receiving Time should not be more than Available Production Time");
        return false;
      }
      if (loginInfo.isBreakOut) {
        notification.action('error').post("Currently on Unpaid break");
        return false;
      }
    }
    const addedServiceLevelIds = this.state.assemblylevels.filter(assemblyLevel => assemblyLevel.performed).map(assemblyLevel => assemblyLevel.assemblyLevelId);
    //const removedServiceLevelIds = this.state.assemblyLevels.filter(assemblyLevel => !assemblyLevel.performed).map(assemblyLevel => assemblyLevel.assemblyLevelId);
    if (!addedServiceLevelIds.length) {
      notification.action('info').post({ msg: 'No changes requested', title: 'Assembly Level Changes' });
      cb();
      return;
    }
    let assemblylevels=[];
    const awoNumber = this.state.awoNumber;
    const assemblylevel={
      awoSOWAssemblyLevelId :this.state.selectedAssemblyLevel.awoSOWAssemblyLevelId,
      assemblyLevelId: this.state.selectedAssemblyLevel.assemblyLevelId,
      assemblyLevelName :this.state.selectedAssemblyLevel.assemblyLevelName,
      required :this.state.selectedAssemblyLevel.required,
      performed :this.state.selectedAssemblyLevel.performed,
      awo_Guid :this.state.awoId,
    }
    assemblylevels.push(assemblylevel);
    this.state.isSaveInProgress = true;
    this.commit();
   
    const parameters = {
      configIds: this.state.selectedConfigIds,
      assemblylevels: assemblylevels,
      transactionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
      value : this.state.minutes,
      awoId : this.state.awoId
    };
    ajax.post('awoprogess/assignAssemblyLevels', parameters).then(() => {
      //const modifiedCount = (addedServiceLevelIds + removedServiceLevelIds);
      const msg = 'Modified Assembly level'+ ' for ' + this.state.groupCount + ' group' + (this.state.groupCount=== 1 ? '' : 's')+ ' of Assembly Work Order '+awoNumber;
      notification.action('info').post({ msg: msg, title: 'Assembly Level Changes' });
      //messages.channel('assign-service-levels').action('saved').post({ awo_Guid: awo_Guid, configIs: this.state.assets });
      this.state.isSaveInProgress = false;
      const data = { awoId: this.state.awoId};
      messages.channel('apply-assembly-levels').action('assign-data').post(data);
      this.commit();
      cb();
    }).catch(err => {
      this.state.isSaveInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }
}

export default SelectAssemblyLevelController;
