import React from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';

class PrintCertificateOptionDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render() {
    var binder = new Binder(this);
    var controller = this.props.controller;
    var errors = controller.state.errors;

    return (
      <Dialog {...this.props} header={this.props.header} width={420} height={220} id='print-cert-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row m-2'>
            <div className='col-md-4'><Form.CheckBox label='Standard' bind={binder.bind('standardReport')} /></div>
            <div className='col-md-4'><Form.CheckBox label='Non-Standard' bind={binder.bind('nonStandardReport')} /></div>
          </div>
        </Dialog.Body>
        <Dialog.Buttons>
          <Dialog.Button variant='primary' size='sm' className='btn' icon='fa-upload' result={{ Save: true }}>Generate Report</Dialog.Button>
          <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Cancel: true }}>Close</Dialog.Button>
        </Dialog.Buttons>
      </Dialog>
    );
  }
}

export default PrintCertificateOptionDialog;