import Immutable from 'immutable';

const isEqual = (oldValue, newValue) => {
  if (oldValue === newValue) {
    return true;
  }

  if (!oldValue && !newValue) {
    return true;
  }

  if (!oldValue || !newValue) {
    return false;
  }

  if (oldValue.isSame && oldValue.isSame(newValue)) {
    return true;
  }

  if ((oldValue.id && newValue.id) && (oldValue.id === newValue.id)) {
    return true;
  }

  return false;
}

class Binder {
  constructor(component) {
    this._component = component;
    this._cache     = component.__cache;
    if (!this._cache) {
      component.__cache = this._cache = {};
    }
  }

  reference = (property) => {
    return new ReferenceBinder(this._component, property);
  }

  bind = (property) => {
    var cache = this._cache[property];
    if (cache) {
      cache.__self = this;
      return cache;
    }

    var propertyPath = property.split('.');
    var getStatement = Immutable.Seq(propertyPath).reverse().reduce(function(prev, current) {
      if (!prev) {
        return '((_ref = _ref["' + current + '"]) !== undefined ? _ref : void 0)';
      }
      return '((_ref = _ref["' + current + '"]) !== undefined && _ref !== null ? ' + prev + ' : void 0)';
    }, null);

    var getter = new Function('obj', 'var _ref = obj; if (!_ref) { return _ref; } return ' + getStatement + ';');

    var updateState = function(state, newValue) {
      var obj = state;

      for (var i = 0; i < propertyPath.length - 1; i++) {
        var p = propertyPath[i];
        var v = obj[p];
        if (v === undefined) {
          v = obj[p] = {};
        }
        obj = v;
      }
      obj[propertyPath[propertyPath.length - 1]] = newValue;
    };

    var setter = function(newValue) {
      var oldValue = getter.call(this, this._component.state);
      if (isEqual(oldValue, newValue)) {//!this._component.isMounted() || 
        return;
      }

      f.changed.__oldValue = oldValue;
      updateState(this._component.state, newValue);
      if (this._component.onPropertyChanged) {
        this._component.onPropertyChanged({path: propertyPath, old: oldValue, new: newValue});
      }
      else {
        this._component.setState(this._component.state);
      }
    };

    var f = function(v) {
      if (v === undefined) {
        return f.changed.__oldValue = getter.call(f.__self, f.__self._component.state);
      }
      setter.call(f.__self, v);
    };

    f.changed = function() {
      var oldValue = f.changed.__oldValue;
      var newValue = getter.call(f.__self, f.__self._component.state);
      if (!isEqual(oldValue, newValue)) {
        if (!f.changed.__queued) {
          f.changed.__queued = true;
          setTimeout(function() {
            f.changed.__oldValue = newValue;
            f.changed.__queued   = false;
          }, 0);
        }
        return true;
      }
      return false;
    };

    f.changed.__oldValue = undefined;

    f.convert = function(converter) {
      var c = function(v) {
        if (v === undefined) {
          return converter.from(f());
        }
        f(converter.to(v));
      };

      c.changed = f.changed;
      return c;
    };

    this._cache[property] = f;
    f.__self = this;
    return f;
  }
}

class ReferenceBinder extends Binder {
  constructor(component, property) {
    super(component);
    this._propertyPrefix = property;
  }

  bind = (property) => {
    return super.bind(this._propertyPrefix + '.' + property);
  }
}

Binder.__cache = {};
Binder.isEqual = isEqual;
export default Binder;