import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import ajax from '../../services/Ajax';

class UsersController extends BaseController {
    constructor() {
        super('users', UsersController.getInitialState());

        messages.channel('edit-user').action('password-reset').subscribe(user => {
            this.searchUsers(user);
        });

        messages.channel('edit-user').action('saved').subscribe(user => {
            this.searchUsers(user);
        });

        messages.channel('login').action('loggedin').subscribe(data => {
            if (!data) {
                this.state.searchCustomer = '';
                this.state.searchText = '';
                this.clearResults();
            }
        });
    }

    static getInitialState = () => {
        return {
            searchResults: [],
            totalSearchResultsCount: -1,
            selectedUser: {},
            cachedUser: null,
            searchText: '',
            searchCustomer: '',
            searchFMC: true,
            searchCustomers: false,
            searchEnabled: true,
            searchDisabled: false,
            roles: []
        }
    }

    searchCustomerChanged = () => {
        this.clearResults();
    }

    clearUserSearch = () => {
        this.state.searchText = '';
        this.commit();
    }

    clearResults = () => {
        this.state.searchResults = [];
        this.state.totalSearchResultsCount = -1;
        this.state.selectedUser = {};
    }

    loadUserDetails = (user) => {
        this.state.selectedUser = user;
        this.commit();
    }

    searchFMCChanged = () => {
        this.state.searchCustomers = false;
        this.state.searchCustomer = null;
        this.clearResults();
    }

    searchCustomersChanged = () => {
        this.state.searchFMC = false;
        this.clearResults();
    }

    searchEnabledChanged = () => {
        this.state.searchDisabled = false;
        this.clearResults();
    }

    searchDisabledChanged = () => {
        this.state.searchEnabled = false;
        this.clearResults();
    }

    isSelected = (user) => {
        if (!this.state.selectedUser) {
            return false;
        }

        return (user.userId === this.state.selectedUser.userId);
    }

    loadUserById = (userId) => {
        this.state.searchResults.forEach(user => {
            if (user.userId === userId) {
                this.loadUser(user);
            }
        });
    }

    newUser = () => {
        const user = {
            userId: '',
            loginName: '',
            loginEnabled: true,
            displayAccount: !this.state.searchCustomers, // force to false for Customers
            pin: '',
            email: '',
            firstName: '',
            lastName: '',
            customerAccount: this.state.searchCustomer,
            currentFacility: {},
            customer: {},
            role: ''
        };

        const state = this.state;
        state.cachedUser = user;
        state.selectedUser = Utils.clone(user);
        this.commit();
    }

    loadUser = (user) => {
        const state = this.state;
        state.cachedUser = user;
        state.selectedUser = Utils.clone(user);
    }

    customerSearch = () => (searchTerm) => ajax.get('lookup/searchCustomersWithUserCount', { searchTerm: searchTerm }).then(results => results).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });

    searchUsers = (userToSelect) => {
        if (userToSelect) {
            this.state.searchText = userToSelect.loginName;
        }

        const searchText = this.state.searchText;

        this.clearResults();
        this.state.searchText = searchText;

        const parameters = {
            customerId: this.state.searchCustomer ? this.state.searchCustomer.id : Utils.emptyGuid,
            searchFMC: this.state.searchFMC ? 1 : 0,
            searchEnabled: this.state.searchEnabled ? 1 : 0,
            searchTerm: searchText
        };

        this.state.searchResults = [];
        this.state.isLoading = true;
        ajax.get('lookup/searchUsers', parameters).then((results) => {
            this.state.searchResults = results ? results : [];
            ajax.get('lookup/searchUsersCount', parameters).then((data) => {
                this.state.totalSearchResultsCount = data;
                this.state.isLoading = false;

                if (this.state.searchResults.length) {
                    this.loadUser(results[0]);
                }
                this.commit();
            }).catch(err => {
                this.state.isLoading = false;
                this.commit();
                notification.action('error').post({ msg: err.message, title: 'Error' });
            });
        }).catch(err => {
            this.state.isLoading = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }
}

export default UsersController;