import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import Utils from '../utility/Utils';

class ConfirmReapplyServiceLevelsController extends BaseController {
  constructor(jobId, jobNumber, assetCount, selectedAssets) {
    super('confirm-reapply-service-levels', {
      jobId               : jobId,
      jobNumber           : jobNumber,
      assetCount          : assetCount,
      selectedAssets      : selectedAssets,
      reapplyAll          : false,
      reapplySelectedOnly : false
    });
    this.saving = false;
  }

  reapplyAllChanged = () => {
    this.state.reapplySelectedOnly = false;
  }

  reapplySelectedOnlyChanged = () => {
    this.state.reapplyAll = false;
  }

  save = () => {
      const assetCount         = this.state.assetCount;
      const selectedAssetCount = this.state.selectedAssets.length;
      const applyToAll         = ((selectedAssetCount === 0) || (selectedAssetCount === assetCount) || this.state.reapplyAll);

      // If the radio buttons are displayed but they don't choose one, just exit
      if (!applyToAll && !this.state.reapplyAll && !this.state.reapplySelectedOnly) {
        return true;
      }

      if (selectedAssetCount === 0) {
        selectedAssetCount = assetCount;
      }
      this.saving = true;
      this.commit();

      const jobNumber          = this.state.jobNumber;
      const selectedAssets     = this.state.selectedAssets;
      const parameters         = applyToAll
                                 ? { jobId  : this.state.jobId }
                                 : {
                                   jobId  : this.state.jobId,
                                   assets : selectedAssets.map(asset => { return { jobSOWId: asset.jobSOWId, assetId: asset.assetId }; })
                                 };
      return ajax.post('jobProgress/reapplyServiceLevels', parameters).then(()=>{
        this.saving = false;
        this.commit();
        notification.action('info').post({msg: 'Scheduled Reapply Service Levels for ' + Utils.pluralize('asset', 's', selectedAssetCount) + ' on job # ' + jobNumber});
        return true;
      }).catch(err => {
        this.saving = false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
    }); // ReapplyServiceLevelsDto
    }
}

export default ConfirmReapplyServiceLevelsController;
