import React from 'react';
import AppConstants from './AppConstants';
import Helper from '../../lib/Helper';

const emptyGuid = '00000000-0000-0000-0000-000000000000';

const isYieldable = (fn) => {
    return fn && fn.next && fn.throw;
}

const isEqual = (oldValue, newValue) => {
    if (oldValue === newValue) {
        return true;
    }

    if (!oldValue && !newValue) {
        return true;
    }

    if (!oldValue || !newValue) {
        return false;
    }

    if (oldValue.isSame && oldValue.isSame(newValue)) {
        return true;
    }

    if (oldValue.id && newValue.id && oldValue.name && newValue.name && (oldValue.id === newValue.id) && (oldValue.name === newValue.name)) {
        return true;
    }

    return false;
}

const nextTick = (fn) => {
    var queued = false;

    return function () {
        var args = arguments;
        if (queued) {
            return;
        }

        queued = true;
        setTimeout(() => {
            fn.apply(this, args);
            queued = false;
        }, 0);
    };
}

const debounce = (quietMillis, fn) => {
    return Helper.throttle(quietMillis, fn, true);
}

const newGuid = () => {
    var d = Date.now();
    var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

const mergeClassFrom = (class2, class1) => {
    if (!class1) {
        class1 = '';
    }

    if (!class2) {
        class2 = '';
    }

    return class1 + ' ' + class2;
}

const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const isPlainObj = (o) => {
    return typeof o === 'object' && o.constructor === Object;
}

const isArray = (o) => {
    return o instanceof Array;
}

const clone = (obj) => {
    var dest;
    if ((obj === null) || (obj === undefined)) {
        return obj;
    }

    if (isArray(obj)) {
        dest = [];
        for (var i = 0; i < obj.length; i++) {
            dest.push(clone(obj[i]));
        }
        return dest;
    }

    if (!isPlainObj(obj)) {
        return obj;
    }

    dest = {};
    var keys = Object.keys(obj);
    for (var j = 0; j < keys.length; j++) {
        var key = keys[j];
        dest[key] = clone(obj[key]);
    }

    return dest;
}

const formatDollars = (value, flag) => {
    if (flag == 1)
        return '$ ' + value.toFixed(2);
    else
        return 'Qty: ' + value.toFixed(2);
}

const formatHours = (value) => {
    if (value) {
        var hours = isNaN(value) ? 0 : value / 60;
        return (Math.round((isNaN(hours) ? 0 : hours) * 100) / 100).toFixed(1) + 'h';
    }
}

const formatBool = (bool) => {
    if (bool === undefined) {
        return '';
    }

    return !!bool ? 'Yes' : 'No';
}

const getDistinct = (list) => {
    var o = {};
    var i;
    var l = list.length;
    var r = [];

    for (i = 0; i < l; i += 1) {
        o[list[i]] = list[i];
    }
    for (i in o) r.push(o[i]);
    return r;
}

const arrayContains = (a, obj) => {
    var i = a.length;
    while (i--) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
}

const endsWith = (string, suffix) => {
    return string.indexOf(suffix, string.length - suffix.length) !== -1;
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const tryProcessAsRfidTag = (searchText, serialNumber) => {
    if (isEPCTag(searchText)) {
        serialNumber.value = searchText;
        return true;
    }

    if (!searchText.endsWith('00')) {
        // all RFID tags end with 00
        // if they don't, it's not an RFID tag
        serialNumber.value = searchText;
        return false;
    }
    // however, some non-RFID serial numbers also end with 00
    // they will likely throw an exception which will also trigger sending back the original string
    // this will allow a 'normal' lookup by primary & secondary serial number

    var isRfidUserDataTag = false;
    try {
        var decodedValue = decodeRfidTag(searchText);
        if (decodedValue.startsWith('FMC|')) {
            isRfidUserDataTag = true;
            serialNumber.value = decodedValue.split('|')[1];
        }
        else if (decodedValue.startsWith('HAL-B')) {
            isRfidUserDataTag = true;
            serialNumber.value = decodedValue.substring(8);
        }
        else {
            serialNumber.value = searchText;
        }
        return isRfidUserDataTag;
    }
    catch (err) {
        serialNumber.value = searchText;
        return false;
    }
}

const isEPCTag = (searchText) => {
    if (searchText.length !== 24) {
        return false;
    }

    for (var i = 0; i < searchText.length; ++i) {
        var char = searchText.substring(i, i + 1).toUpperCase();
        if ('0123456789ABCDEF'.indexOf(char) === -1) {
            return false;
        }
    }
    return true;
}

const decodeRfidTag = (serialNumber) => {
    var result = '';
    for (var i = 0; i < serialNumber.length; i += 2) {
        var hs = serialNumber.substring(i, i + 2);
        if (hs === '00') {
            break;
        }
        var character = String.fromCharCode(parseInt(hs, 16));
        result += character;
    }
    return result;
}

String.prototype.replaceAll = function (search, replace) {
    // if replace is not sent, return original string otherwise it will
    // replace search string with 'undefined'.
    if (replace === undefined) {
        return this.toString();
    }
    return this.replace(new RegExp('[' + search + ']', 'g'), replace);
};

Number.prototype.toFixedDown = function (digits) {
    var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)');
    var m = this.toString().match(re);
    return m ? parseFloat(m[1]) : this.valueOf();
};

const pluralize = (singularPrefix, pluralSuffix, count, includeCount) => {
    var displayCount = (includeCount === undefined) ? true : includeCount;
    if (count === 1) {
        return displayCount
            ? count + ' ' + singularPrefix
            : singularPrefix;
    }
    return displayCount
        ? count + ' ' + singularPrefix + pluralSuffix
        : singularPrefix + pluralSuffix;
}

const makeParagraphElement = (text, style) => {
    return text
        ? <p> <div style={style} dangerouslySetInnerHTML={{__html : text}} /></p>
        : <br />;
}

const makeParagraphElements = (text, style) => {
    return text.trim().split('\n').map(t => makeParagraphElement(t, style));
}

const findDuplicateInArray = (arr) => {
    var cache = {};
    var results = [];
    for (var i = 0, len = arr.length; i < len; i++) {
        if (cache[arr[i]] === true) {
            results.push(arr[i]);
        } else {
            cache[arr[i]] = true;
        }
    }
    return results;
}

const isValidGuid = (n) => {
    var pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return pattern.test(n)
}

const convertBase64toBlob = (base64Data, contentType) => {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

const filterDeletedRecords = (items) => {
    return items.filter((item) => item.state.recordStatus !== AppConstants.RecordStatus.Deleted);
}

const getResultForAutoComplete = r => {
    return {
        id: r.id || r.id === 0 ? r.id : r.code,
        code: r.code,
        text: r.name,
        count: (r.count && (r.count !== -1)) ? r.count : -1,
        result: r
    }
}

const getBlobType = (type) => {
    let applicationType = 'plain/text';
    switch (type) {
        case 'txt':
            applicationType = 'plain/text'; break;
        case 'pdf':
            applicationType = 'application/pdf'; break;
        case 'gif':
            applicationType = 'image/gif'; break;
        case 'jpg':
        case 'jpeg':
        case 'png':
            applicationType = 'image/jpg'; break;
    }
    return applicationType;
}

const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = parseFloat(mins % 60).toFixed(1);
    //m = m.toFixed(1);
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
}

const convertMinsToHrsMinsHeader = (mins) => {
    let h = Math.floor(mins / 60);
    let m = Math.floor(mins % 60);
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
}

const pad = (number, length) => {

    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    while (str.length > length) {
        str = str.substr(1, str.length);
    }
    return str;

}

const createSearch = (items) => {
    return (searchTerm) => {
      if (!searchTerm || (searchTerm === '') || (searchTerm === '*')) {
        return items.map(el => el);
      }
      var re = new RegExp(searchTerm, 'i');
      if (items) {
        var results = items.filter(el => {
          const Name = el.Name || el.name;
          const Code = el.Code || el.code;
          return (re.test(Name) || re.test(Code));
        });
      }
      if (results.length > 0) {
        return results;
      }
      return [];
    };
  }

const Utils = {
    isYieldable: isYieldable,
    throttle: Helper.throttle,
    debounce: debounce,
    mergeClassFrom: mergeClassFrom,
    clone: clone,
    isPlainObj: isPlainObj,
    isArray: isArray,
    isNumeric: isNumeric,
    newGuid: newGuid,
    nextTick: nextTick,
    isEqual: isEqual,
    emptyGuid: emptyGuid,
    formatDollars: formatDollars,
    formatBool: formatBool,
    getDistinct: getDistinct,
    arrayContains: arrayContains,
    contains: Helper.contains,
    endsWith: endsWith,
    capitalizeFirstLetter: capitalizeFirstLetter,
    tryProcessAsRfidTag: tryProcessAsRfidTag,
    pluralize: pluralize,
    makeParagraphElements: makeParagraphElements,
    find_duplicate_in_array: findDuplicateInArray,
    convertBase64toBlob: convertBase64toBlob,
    filterDeletedRecords: filterDeletedRecords,
    getResultForAutoComplete: getResultForAutoComplete,
    getBlobType: getBlobType,
    convertMinsToHrsMins: convertMinsToHrsMins,
    convertMinsToHrsMinsHeader: convertMinsToHrsMinsHeader,
    isValidGuid: isValidGuid,
    pad: pad,
    formatHours: formatHours,
    createSearch: createSearch
};

export default Utils;