import store from 'store';
import Moment from 'moment';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants  from '../utility/AppConstants';
import Utils from '../utility/Utils';

class LateTimeEntryController extends BaseController {
    constructor() {
        super('late-time-entry', {
            shiftStartDate: DateTimeFormatter.formatDropdownDate(DateTimeFormatter.today()),
            hours: '0',
            minutes: '0',
            reason: '',
            period: {id: 1, name: 'AM'},
            errors: {},
        });

        this.saving = false;
        this._validator = new Validator();
        this._validator.add('shiftStartDate', 'Shift start date', [{ required: true }]);
        this._validator.add('reason', 'Reason', [{ required: true }]);
    }

    periodSearch = () => Utils.createSearch(AppConstants.Period);

    getAsDate = (day, hrs, mins, ampm) => {
        let hours = Number(hrs);
        const minutes = Number(mins);
        const AMPM = ampm;
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        let sHours = hours.toString();
        let sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        const time = sHours + ":" + sMinutes + ":00";
        const newDate = DateTimeFormatter.getLocal(Moment(day + " " + time));
        return newDate;
    }

    saveLateEntry = () => {
        const s = this.state;
        this._validator.validateAll(this.state);        
        this.commit();
        const errors = Object.keys(this.state.errors);
        if (errors.length) {
            return false;
        }
        
        const hours = isNaN(s.hours) ? 0 : s.hours;
        const minutes = isNaN(s.minutes) ? 0 : s.minutes;
        if (hours > 12) {
            notification.action('error').post("Hours cannot be more than 12");
            return false;
        }
        if (minutes > 59) {
            notification.action('error').post("Minutes cannot be more than 59");
            return false;
        }

        const shiftStartDateTime = this.getAsDate(s.shiftStartDate, hours, minutes, s.period.name);
        const max = DateTimeFormatter.getDuration(DateTimeFormatter.formatLocalTime(shiftStartDateTime), DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()));
        if (max < 0) {
            notification.action('error').post("Shift Start Time cannot be more than current time");
            return false;
        }
        if (max > 960) {
            notification.action('error').post("Shift Start Time cannot be more than 16 hours");
            return false;
        }
        const loginInfo = store.get('InteServLoginInfo');
        if (loginInfo && loginInfo.lastClockOutTime) {
            const pre = DateTimeFormatter.getDuration(DateTimeFormatter.formatLocalTime(shiftStartDateTime), DateTimeFormatter.formatLocalTime(loginInfo.lastClockOutTime));
            if (pre > 0) {
                notification.action('error').post("Shift Start Time should be after last clockout time(" + DateTimeFormatter.formatLocalTime(loginInfo.lastClockOutTime) + ")");
                return false;
            }
        }
        if (loginInfo && loginInfo.clockedInTime) {
            const preval = DateTimeFormatter.getDuration(DateTimeFormatter.formatLocalTime(shiftStartDateTime), DateTimeFormatter.formatLocalTime(loginInfo.clockedInTime));
            if (preval < 0) {
                notification.action('error').post("Shift Start Time should be before last clockin time(" + DateTimeFormatter.formatLocalTime(loginInfo.clockedInTime) + ")");
                return false;
            }
        }

        const utcshiftTime = Moment(shiftStartDateTime).utc();

        const params = {
            shiftStartDateTime: DateTimeFormatter.formatLocalTime(shiftStartDateTime),
            reason: s.reason,
            utcshiftTime: utcshiftTime,
            today: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now())
        };

        this.saving = true;
        
        return ajax.post('manhours/saveLateEntryTime', params).then((response) => {
            this.saving = false;
            this.commit();
            notification.action('success').post('Late Time Entry successful');
            return response;
        }).catch(err => {
            this.saving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }
}

export default LateTimeEntryController;