import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import Utils from '../utility/Utils';
import DateTimeFormatter from '../../lib/DateTimeFormatter';


class ConfirmReapplyAssemblyLevelsController extends BaseController {
  constructor(awoId, awoNumber, groups, selectedGroups, allConfigs, selectedConfigs, customerName) {
    console.log(selectedGroups);
    super('confirm-reapply-assembly-levels', {
      awoId: awoId,
      awoNumber: awoNumber,
      groups: groups,
      selectedGroups: selectedGroups,
      allConfigs: allConfigs,
      selectedConfigs: selectedConfigs,
      customerName: customerName,
      reapplySelectedOnly: false,
      reapplyAll: false

    });
    this.saving = false;
  }

  reapplyAllChanged = () => {
    this.state.reapplySelectedOnly = false;
  }

  reapplySelectedOnlyChanged = () => {
    this.state.reapplyAll = false;
  }

  save = (cb=()=>{}) => {
    const awoId = this.state.awoId;
    let groupCount = 0;
    let configIds = [];
    if (awoId === undefined || awoId === '') {
      return false;
    }    
    const awoNumber = this.state.awoNumber;
    const customerName = this.state.customerName;
    if (this.state.reapplyAll === true) {
      configIds = this.state.allConfigs;
      groupCount = this.state.groups.length;
    }
    if (this.state.reapplySelectedOnly === true || (this.state.selectedGroups.length === this.state.groups.length)) {
      configIds = this.state.selectedConfigs;
      groupCount = this.state.selectedGroups.length;
    }
    if(this.state.selectedGroups.length 
      !== this.state.groups.length && this.state.reapplySelectedOnly === false && this.state.reapplyAll === false)
      {
        notification.action('error').post({ msg: `Please choose the option to reapply to all group or only selected from the options`, title: 'Error' });
        return;
      }

    const parameters = {
      configIds: configIds,
      transactionDate: DateTimeFormatter.formatDate(DateTimeFormatter.today()),
    };
    ajax.post('awoprogess/reApplyAssemblyLevels', parameters).then(() => {
      //const modifiedCount = (addedServiceLevelIds + removedServiceLevelIds);
      const msg = 'Reapplied assembly level' + ' for ' + groupCount + ' group' + (groupCount === 1 ? '' : 's') + ' of Assembly Work Order ' + awoNumber;
      notification.action('info').post({ msg: msg, title: 'Assembly Level Changes' });
      //messages.channel('assign-service-levels').action('saved').post({ awo_Guid: awo_Guid, configIs: this.state.assets });
      this.state.isSaveInProgress = false;
      this.commit();
      cb();
    }).catch(err => {
      this.state.isSaveInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
    this.commit();
  }
}

export default ConfirmReapplyAssemblyLevelsController;
