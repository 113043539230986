import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import Validator from '../../lib/Validator';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';
import CustomerServiceLevelBOMRowController from './CustomerServiceLevelBOMRowController';
import CustomerServiceLevelTestRowController from './CustomerServiceLevelTestRowController';
import { isFunctionTypeNode } from 'typescript';

class CustomerServiceLevelController extends BaseController {
    constructor() {
        super('customer-service-levels', CustomerServiceLevelController.getInitialState('', '', '', ''));
        this.adding = false;
        this.state.selectedServiceLevel = '';

        this._validator = new Validator();
        this._validator.add('customerServiceLevelName', 'Customer Service Level Name', [{ required: true }]);
        this._validator.add('parentServiceLevel', 'Master Service Level', [{ required: true }]);
        this._validator.add('intervalDays', 'Interval Days', [{ required: true }]);
        this._validator.add('hierarchyLevel', 'Hierarchy Level', [{ required: true }]);

        messages.channel('edit-customer-service-level').action('saved').subscribe(customerServiceLevelId => {
            if (!customerServiceLevelId) {
                return;
            }
            this.load(customerServiceLevelId, null, this.state.customerId, this.state.customerName);
        });
    }

    selectedServiceLevelChanged = () => {
        const customerServiceLevelId = this.state.selectedServiceLevel.id;
        this.load(customerServiceLevelId, this.state.prototypeServiceLevelId, this.state.customer.id, this.state.customer.name);
    }

    parentServiceLevelChanged = (data) => {
        const newValue = data && data.new && data.new.name;
        if (!newValue) {
            return;
        }
        this.state.customerServiceLevelName = newValue;
    }

    load = (customerServiceLevelId, prototypeServiceLevelId, customerId, customerName) => {
        if (customerServiceLevelId === Utils.emptyGuid) {
            this.adding = true;
            this.state = CustomerServiceLevelController.getInitialState(customerServiceLevelId, prototypeServiceLevelId, customerId, customerName);
            if (!prototypeServiceLevelId) {
                this.commit();
                return;
            }
            ajax.get('lookup/getPrototypeServiceLevel/', { customerServiceLevelId: prototypeServiceLevelId }).then(result => {
                result.customer = { id: customerId, name: customerName };
                this.merge(result);
                this.commit();
            }); // CustomerServiceLevelDto
        }
        else {
            this.adding = false;
            ajax.get('lookup/getCustomerServiceLevel/', { customerServiceLevelId: customerServiceLevelId }).then(result => {
                this.merge(result);
                this.commit();
            }); // CustomerServiceLevelDto
        }
    }

    merge = (customerServiceLevel) => {
        if (!customerServiceLevel) {
            return;
        }
        this.state = Object.assign({}, customerServiceLevel); // , this.state.customer);
        this.state.tests = [];
        this.state.boms = [];
        this.state.errors = {};
        customerServiceLevel.tests.forEach(test => this.addNewTestRow(test));
        customerServiceLevel.boms.forEach(bom => this.addNewBOMRow(bom));
    }

    static getInitialState = (customerServiceLevelId, prototypeServiceLevelId, customerId, customerName) => {
        return {
            customerServiceLevelId: customerServiceLevelId,
            customerServiceLevelName: '',
            prototypeServiceLevelId: prototypeServiceLevelId,
            customer: { id: customerId, name: customerName },
            parentServiceLevel: '',
            fmcServiceLevel: '',
            alternateName: '',
            intervalDays: 0,
            required: false,
            active: true,
            isSAPServiceLevel: false,
            nisd: false,
            laborCode: '',
            hierarchyLevel: 0,
            tests: [],
            boms: [],
            errors: {},
            isSaving: false,
            newRowCount: 0,
        };
    }

    search = (searchType) => {
        return (searchTerm) => ajax.get('lookup/searchLookupList/' + searchType.replaceAll(' ', '%20'), {
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    bomSearch = () => {
        return (searchTerm) => ajax.get('lookup/searchBOM/', {
            searchTerm: searchTerm
        }).then(result => result).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    addNewTestRow = (test) => {
        this.state.tests.push(new CustomerServiceLevelTestRowController(this, test));
        // this.renumberTests();
        this.commit();
    }

    removeTestRow = (row) => {
        const idx = this.state.tests.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.tests.splice(idx, 1);
        // this.renumberTests();
        this.commit();
    }

    addNewBOMRow = (bom) => {
        this.state.boms.push(new CustomerServiceLevelBOMRowController(this, bom));
        if (!bom) {
            this.state.newRowCount = this.state.newRowCount ? this.state.newRowCount + 1 : 1;
        }
        this.commit();
    }

    removeBOMRow = (row) => {
        const idx = this.state.boms.indexOf(row);
        if (idx === -1) {
            return;
        }
        this.state.boms.splice(idx, 1);
        this.state.newRowCount = this.state.newRowCount > 0 ? this.state.newRowCount - 1 : this.state.newRowCount;
        this.commit();
    }

    addDefaultBOMRow = () => {
        ajax.get('lookup/getNoBOMRequired/')
            .then(result => {
                const bom = {
                    bom: {
                        id: result.bomId,
                        name: result.name + ' - ' + result.description
                    }
                }
                this.addNewBOMRow(bom);
                this.state.newRowCount = this.state.newRowCount ? this.state.newRowCount + 1 : 1;
                this.commit();
            });

    }

    renumberTests = () => {
        this.state.tests.forEach((t, i) => {
            t.setOrder(i + 1);
        });
        this.state.tests.sort((a, b) => {
            return (a.state.operationOrder - b.state.operationOrder);
        });
        this.commit();
    }

    autoCodeTests = () => {
        this.state.tests.forEach(t => t.autoCode());
        this.commit();
    }

    validateTestRows = (state) => {
        state.tests.forEach(row => {
            const testName = row.state.testName;
            const parentServiceLevelTest = row.state.parentServiceLevelTest;
            const operationOrder = row.state.operationOrder;

            if (testName) {
                delete row.state.errors.testName;
            }
            else {
                row.state.errors.testName = 'Test Name must be specified';
            }

            if (parentServiceLevelTest) {
                delete row.state.errors.parentServiceLevelTest;
            }
            else {
                row.state.errors.parentServiceLevelTest = 'A value for Master Service Level Test must be selected';
            }

            if (operationOrder) {
                delete row.state.errors.operationOrder;
            }
            else {
                row.state.errors.operationOrder = 'A value for Operation Order must be selected';
            }
        });
    }


    validateBOMRows = (state) => {
            var validBom= true;
        state.boms.forEach(row => {
            const bom = row.state.bom;
         
          if(!bom){
              validBom= false;
              notification.action('error').post({ msg: 'A value for Bill of Material must be selected', title: 'BOM' });
          }
        });
        return validBom;
        
    }


    duplicate = () => {
        const customerServiceLevel = Object.assign({}, this.state);
        customerServiceLevel.tests = this.state.tests.map(t => t.state);
        customerServiceLevel.tests.forEach(t => t.customerServiceLevelTestId = Utils.emptyGuid);
        customerServiceLevel.customerServiceLevelId = Utils.emptyGuid;
        this.merge(customerServiceLevel);
        this.commit();
        const message = 'Duplicate service level created from ' + this.state.customerServiceLevelName;
        notification.action('info').post({ msg: message, title: 'Customer Service Level Duplicated' });
    }

    customerServiceLevelSearch = () => {
        const self = this;
        const customerId = self.state.customer && self.state.customer.id;
        return (searchTerm) => ajax.get('lookup/getAllServiceLevelsForCustomer', {
            customerId: customerId,
            searchTerm: searchTerm
        }).then(result => {
            result.map(r => {
                return {
                    id: r.customerServiceLevelId,
                    name: r.customerServiceLevelName,
                    isSAPServiceLevel: r.isSAPServiceLevel
                }
            })
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    canSave = () => {
        this.state.errors = {};
        this._validator.validateAll(this.state);
        if(!this.validateBOMRows(this.state)){
            return false;
        }
        this.validateTestRows(this.state);
        const errors = Object.keys(this.state.errors);
        const testRowErrors = this.state.tests.reduce((acc, curr) => {
            return acc + Object.keys(curr.state.errors).length;
        }, 0);

        if (!errors.length && !testRowErrors) {
            return true;
        }
        this.commit();
        return false;
    }

    save = (cb) => {
        if (!this.canSave()) {
            return;
        }

        const s = this.state;
        let customerServiceLevelId = s.customerServiceLevelId;
        const customerServiceLevelName = s.customerServiceLevelName;
        const customerName = s.customer.name;
        let parameters = Object.assign({}, s);
        parameters.tests = s.tests.map(test => test.state);
        parameters.boms = s.boms.map(bom => bom.state);

        if (!parameters.fmcServiceLevel) {
            const message = 'It is recommended to enter a value for FMC Service Level if the service created is a required annual service (Level 1) or an annual teardown service (Level 2)';
            notification.action('warning').post(message);
        }
        this.state.isSaving = true;
        this.commit();
        ajax.post('serviceLevel/save', parameters).then(customerServiceLevelId => {

            this.state.isSaving = false;
            this.commit();
            if (this.adding) {
                notification.action('success').post('Saved new Service Level ' + customerServiceLevelName);
                if (cb) {
                    cb(customerServiceLevelId);
                }
            }
            else {
                notification.action('success').post('Saved changes to Service Level ' + customerServiceLevelName + ' for ' + customerName);
            }

            if (!parameters.tests.length) {
                notification.action('warning').post('There are no tests defined for the Service Level ' + customerServiceLevelName + ' for ' + customerName);
            }

            messages.channel('edit-customer-service-level').action('saved').post(customerServiceLevelId);
        }).catch(err => {
            this.state.isSaving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
        }); // in: CustomerServiceLevelDto, out: Guid
    }
}

export default CustomerServiceLevelController;