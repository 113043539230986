import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Controller from '../../mixins/Controller';
import SAPService from '../services/SAPService';
import LoginService from '../services/LoginService';
import EditFieldAssetController from '../controllers/EditFieldAssetController';
import Authentication from '../services/authentication';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import FormBuilder from '../../components/FormBuilder';

const EditForm = (props) => {
  const binder = props.binder;
  const asset = props.controller.state || {};
  const customer = asset && asset.customer && asset.customer.name;
  const description = asset && asset.description && asset.description.name;
  let statusColourClass = 'status' + asset.status; // e.g. statusScrapped
  if (asset.status === 'Weld Repair' || asset.status === 'Failed Inspection')
    statusColourClass = 'asset-status statusWeldRepair'
    if (asset.status === 'OS/Machining Repair') 
    statusColourClass = 'asset-status statusOSRepair'
  let assetStatus = asset.status;
  if (asset.rejectReason) {
    if (assetStatus != 'Passed')
      assetStatus += ' (' + asset.rejectReason + ')';
  }
  let equipmentNumberValue = asset.equipmentNumber;
  let equipmentClass = '';
  if (props.controller.canRetrieveEquipmentNumber()) {
    equipmentNumberValue = 'Click \'Save\' to request an Equipment # from SAP';
    equipmentClass = 'sap-error';
  }
  return (
    <div>
      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Customer' value={customer} /></div>
        <div className='col-md-6'><Form.StaticData label='Mfg Part #' value={asset.mfgPartNumber} /></div>
      </div>

      <div className='row'>
        <div className='col-md-6'><Form.StaticData label='Description' value={description} /></div>
        <div className='col-md-6'><Form.StaticData label='In Service Date' value={DateTimeFormatter.formatDate(asset.inServiceDate)} /></div>
      </div>

      <div className='row'>
        <div className='col-md-4'>
        <Form.StaticData label='Primary Serial #' value={asset.primarySerialNumber} />
          {/* {LoginService.isCustomer
            ? <Form.StaticData label='Primary Serial #' value={asset.primarySerialNumber} />
            : <Form.Input label='Primary Serial #' bind={binder.bind('primarySerialNumber')} className='required uppercase' error={asset.errors.primarySerialNumber} />
          } */}
        </div>
        <div className='col-md-4'>
        <Form.StaticData label='Secondary Serial #' value={asset.secondarySerialNumber} />
          {/* {LoginService.isCustomer
            ? <Form.StaticData label='Secondary Serial #' value={asset.secondarySerialNumber} />
            : <Form.Input label='Secondary Serial #' bind={binder.bind('secondarySerialNumber')} className='uppercase' />
          } */}
        </div>
        <div className='col-md-4'>
        <Form.StaticData label='Equipment #' value={asset.equipmentNumber} />
          {/* {LoginService.isCustomer
            ? <Form.StaticData label='Equipment #' value={asset.equipmentNumber} />
            : (SAPService.isSAPFacility() ? <Form.StaticData label='Equipment #' className={equipmentClass} value={equipmentNumberValue} /> : <Form.NumberInput label='Equipment #' bind={binder.bind('equipmentNumber')} />)
          } */}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          {LoginService.isCustomer
            ? <Form.StaticData label='Batch #' value={asset.batchNumber} />
            : <Form.Input label='Batch #' bind={binder.bind('batchNumber')} maxLength='250' />
          }
        </div>
        <div className='col-md-4'>
        <Form.StaticData label='Born On Date' value={DateTimeFormatter.formatDate(asset.bornOnDate)} />
          {/* {LoginService.isCustomer
            ? <Form.StaticData label='Born On Date' value={DateTimeFormatter.formatDate(asset.bornOnDate)} />
            : <Form.Date label='Born On Date' bind={binder.bind('bornOnDate')} className='required' display='inline-block' error={asset.errors.bornOnDate} />
          } */}
        </div>
        <div className='col-md-4'>
          <Form.StaticData label='Asset Status' value={assetStatus} className={statusColourClass} />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <Form.TextArea label='Notes' bind={binder.bind('notes')} rows={5} />
        </div>
      </div>
    </div>
  );
}

const FieldAssetForm = (props) => {
  const binder = props.binder;
  const controller = props.controller;
  const customFields = controller.getCustomFields();
  if (!customFields || !customFields.length) {
    return null;
  }

  const key = 1;
  const controls = customFields.map(controlElement => {
    return <div key={key++} className='col-md-4'>{FormBuilder.buildControl(controller, binder, controlElement)}</div>;
  });

  return (
    <div className='row'>
      <div className='col-md-12 mt-2'>
        {controls}
      </div>
    </div>
  );
}

const Buttons = (props) => {
  let history = useHistory();

  const handleSave = () => {
    props.controller.save(() => {
      history.goBack();
    })
  }

  const handleCancel = () => {
    history.goBack();
  }

  return (
    <div className='col-12'>
      <div className='mb-1 d-flex justify-content-end'>
        <Button icon='fa-save' id='btn-save' className='m-1 action-button' variant='primary' size='sm' onClick={handleSave} disabled={props.controller.state.isSaving}>Save</Button>
        <Button icon='fa-times' id='btn-cancel' className='m-1 action-button' variant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
      </div>
    </div>
  );
}

const EditFieldAssetPageHeader = (props) => <PageHeader showBackNavigation={true} title={props.pageTitle} />;

class EditFieldAssetPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    const assetId = this.props.match.params.assetId;
    this.props.controller.load(assetId);
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new EditFieldAssetController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);
    const title = 'Edit Asset — ' + controller.state.primarySerialNumber;
    return (
      <Page {...this.props} pageTitle='InteServ · Edit Asset' id='edit-asset-page'>
        <EditFieldAssetPageHeader pageTitle={title} />
        <Page.Content>
          <div className='row'>
            <div className='col-md-12'>
              <EditForm controller={controller} binder={binder} />
              <FieldAssetForm controller={controller} binder={binder} />
              <Buttons controller={controller} />
            </div>
          </div>
        </Page.Content>
      </Page>
    )
  }
}

export default EditFieldAssetPage;
