import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dropdown from '../../components/Dropdown';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import SAPJobProgressController from '../controllers/SAPJobProgressController';
import Authentication from '../services/authentication';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import AppConstants from '../utility/AppConstants';
import Jumbotron from '../../components/Jumbotron';
import Table from '../../components/Table';
import Grid from '../../components/Grid';
import Search from '../components/Search';
import TopPagination from '../components/TopPagination';

const SearchHeader = (props) => {
    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }

        props.controller.state.offset = 0;
        props.controller.searchJobs();
    }

    const handleMouseSearch = () => {
        props.controller.state.offset = 0;
        props.controller.searchJobs();
    }

    const clearSearch = () => {
        props.controller.clearSearchText();
    }

    const binder = props.binder;
    const controller = props.controller;
    const formatFacilityResult = item => {
        const spanSAP = item.result.useSAP ?
            <span className='user-facility-sap float-right select2-tag'> SAP</span> : null;
        if (item.result.useSAP) {
            return <span>{item.text} {spanSAP}</span>;
        }
        return <span>{item.text}</span>;
    };
    const formatCustomerResult = item => {
        const spanSAP = item.result.useSAP ?
            <span className='user-facility-sap float-right select2-tag'> SAP</span> : null;
        if (item.result.useSAP) {
            return <span>{item.text} {spanSAP}</span>;
        }
        return <span>{item.text}</span>;
    };


    return (
        <div {...props} id='search-criteria'>
            <div className='row'>
                <div className='search-container col-md-5 col-sm-12' onKeyDown={handleKeyboardSearch}>
                <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='job number, date, or description' autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
                    {/* <Form.Input id='searchText' className='search-input text-uppercase'
                        label='Enter search text' prependLabelIcon='fa-search' placeholder='job number, date, or description' autoFocus='true'
                        bind={binder.bind('searchText')} />
                    <ActionIcon icon='fa-times-circle' className='clearable' size='2x' action={clearSearch} />
                    <Button icon='fa-search' className='btn-primary btn-search-icon' onClick={handleMouseSearch} /> */}
                </div>

                <div className='col-md-4 col-sm-6'>
                    <Form.Label name='Job status' />
                    <Form.RadioInput bind={binder.bind('searchActive')} label='Active' />
                    <Form.RadioInput bind={binder.bind('searchCompleted')} label='Completed' />
                </div>

                <div className='col-md-3 new-jobs'>
                    <Form.CheckBox label='Include New Jobs' bind={binder.bind('includeNewJobs')} />
                </div>
            </div>

            <div className='row'>
                <div id='facility-search' className='col-md-4 col-sm-6'>
                    <Form.AutoComplete formatResult={formatFacilityResult} label='Facilities' prependLabelIcon='fa-filter' formatSelection={formatFacilityResult}
                        bind={binder.bind('facility')}
                        placeholder='Select a facility to filter your results'
                        search={controller.facilitySearch()} />
                </div>

                <div id='customer-search' className='col-md-4 col-sm-6'>
                    <Form.AutoComplete formatResult={formatCustomerResult} label='Customers' prependLabelIcon='fa-filter' formatSelection={formatCustomerResult}
                        bind={binder.bind('customer')}
                        placeholder='Select a customer to filter your results'
                        search={controller.customersSearch()} />
                </div>

                <div id='facility-search' className='col-md-4 col-sm-6'>
                    <Form.AutoComplete bind={binder.bind('sapStatus')} label='SAP Status' prependLabelIcon='fa-filter'
                        placeholder='Select an SAP Status to filter your results'
                        data={AppConstants.JobSAPStatus} />
                </div>
            </div>
        </div>
    );
}

const ResultRow = (props) => {
    let history = useHistory();
    const selectRow = (job) => {
        props.controller.loadJobDetails(job);
    }

    const getSAPStatusElement = (job) => {
        let element = null;
        let statusValue = 0;
        const warning = (job.sapErrorMessage !== '')
            ? <span>&nbsp;<i className='fa fa-warning sap-error' /></span>
            : null;

        if (warning) {
            statusValue++;
        }

        if ((job.sapStatus == 'QUNR' || job.sapStatus == 'QUNC') && job.assetCount === 0) {
            // SAP && non-SAP
            element = <Table.Data className='job-no-assets status-column' title='There are no assets on this job'><i
                className='far fa-circle' />{warning}</Table.Data>;
        }
        else if (job.sapStatus == 'QUOR' && !job.isQuoteSent && !job.isInvoiced && !job.canInvoice && !job.isCompleting && !job.canComplete) {
            statusValue += 35;
            element = <Table.Data className='job-setting-up status-column' title='Quote Override'><i className='fa fa-edit' />{warning}</Table.Data>;
        }
        else if (job.isQuoteSent) {
            statusValue += 38;
            element = <Table.Data className='job-setting-up status-column' title='Quote sent to customer for approval'><i className='fa fa-money' />{warning}</Table.Data>;
        }
        else if (job.isClosed) {
            // SAP && non-SAP
            statusValue += 90;
            element = <Table.Data className='job-closed status-column' title='Job is closed – no further changes can be made'><i
                className='fa fa-ban' />{warning}</Table.Data>;
        } else if (job.isInvoiced) {
            // SAP only
            statusValue += 80;
            element = <Table.Data className='job-invoiced status-column' title='Waiting for accounting to complete invoicing'><i
                className='fa fa-clock' />{warning}</Table.Data>;
        } else if (job.canInvoice) {
            // SAP only
            statusValue += 70;
            element =
                <Table.Data className='job-invoicable status-column' title='This job can be sent to accounting for invoicing'><i
                    className='fa fa-usd' />{warning}</Table.Data>;
        } else if (job.isCompleting) {
            // SAP only
            statusValue += 60;
            element = <Table.Data className='job-completing status-column' title='Waiting for job to be completed in SAP'><i
                className='fa fa-clock' />{warning}</Table.Data>;
        } else if (job.canComplete) {
            // SAP && non-SAP
            statusValue += 50;
            var title = 'Click Complete Job to signal that all inspection activities are complete and to remove this entry from the list';
            if (job.useSAP) {
                title = 'Click Complete Job to signal SAP that all inspection activities are complete';
            }
            element = <Table.Data className='job-completion-candidate status-column' title={title}><i
                className='fa fa-check' />{warning}</Table.Data>;
        } else if (job.isWaitingToRetry) {
            statusValue += 45;
            element = <Table.Data className='job-retry-status-update status-column'
                title='Job is attempting to retry updating Status in SAP'><i
                    className='fa fa-clock' />{warning}</Table.Data>;
        } else if (job.isInspecting) {
            // SAP && non-SAP
            statusValue += 40;
            element = <Table.Data className='job-active status-column'
                title='Job is active, assets can still be received, and asset inspections can still take place'>
                <i className='fa fa-cogs' />{warning}</Table.Data>;
        } else if (job.canLockForInspection) {
            // SAP && non-SAP
            statusValue += 30;
            element = <Table.Data className='job-lock-for-inspection-candidate status-column'
                title='Click Lock Assets to allow Technicians to being inspecting the assets on this job'><i
                    className='fas fa-lock-open' />{warning}</Table.Data>;
        } else if (job.isWaiting) {
            // SAP && non-SAP
            statusValue += 20;
            element = job.awaitingLockForInspection
                ? <Table.Data className='job-creating-service-orders status-column'
                    title='Waiting for Lock Assets request to complete'><i className='fa fa-clock' />{warning}</Table.Data>
                :
                <Table.Data className='job-creating-service-orders status-column' title='Waiting for SAP request to complete'><i
                    className='fa fa-clock' />{warning}</Table.Data>;
        } else if (job.isCreated) {
            statusValue += 10;
            element = <Table.Data className='job-setting-up status-column'
                title='Assets can still be assigned to this job from the Receiving page'><i
                    className='fa fa-download' />{warning}</Table.Data>;
        }
        else {
            statusValue += 100;
            element = <Table.Data></Table.Data>;
        }

        // set sorting value
        job.status = statusValue;
        return element;
    }

    // Note: also sets sorting value property 'status'
    const getStatusElement = (showSAPStatus, job) => {
        return showSAPStatus ? getSAPStatusElement(job) : this.getNonSAPStatusElement(job);
    }

    const navigateToDropoff = (dropoffId) => {
        if (dropoffId === Utils.emptyGuid) {
            return;
        }
        history.push('editdropoff/' + dropoffId);
    }

    const handleNotes = (job) => {
        if (!job) {
            return;
        }
        history.push('notes/' + AppConstants.NoteTypes.Job + '/' + job.id);
    }

    const controller = props.controller;
    const job = props.job;
    const showSAPStatus = SAPService.isSAPFacility();
    let className = '';
    if (job.sapStatus == 'QUOR')
        className = 'quorselected'
    if (controller.isSelected(job)) {
        className = 'selected';
    }

    let requiredDateClassName = className;
    const today = DateTimeFormatter.today().format('YYYYMMDD');
    const requiredDate = DateTimeFormatter.getMoment(job.requiredDate).format('YYYYMMDD');

    if (today > requiredDate) {
        requiredDateClassName += ' late-error';
    } else if (today === requiredDate) {
        requiredDateClassName += ' late-warning';
    }

    const jobLink = (job.assetCount > 0)
        ? <Form.Link className='select-link' to={'/jobprogressassets/' + job.id} value={job.jobNumber} />
        : job.jobNumber;
    const statusElement = getStatusElement(true, job);
    const noteCountBadge = job.noteCount
        ? <Badge variant='warning' onClick={() => handleNotes(job)}>{job.noteCount}</Badge>
        : '';

    return (
        <Table.Row id={job.id} onClick={() => { selectRow(job); }} job={job} isSelected={controller.isSelected(job)}>
            {statusElement}
            <Table.Data className={className} style={{ textAlign: 'center' }}>{noteCountBadge}</Table.Data>
            <Table.Data className={className}>{job.sapStatus}</Table.Data>
            <Table.Data className={className}>{jobLink}</Table.Data>
            <Table.Data className={className}>{job.facilityName}</Table.Data>
            <Table.Data className={className}>{job.customerName}</Table.Data>
            <Table.Data className={className}>{DateTimeFormatter.formatDate(job.jobDate)}</Table.Data>
            <Table.Data className={requiredDateClassName}>{DateTimeFormatter.formatDate(job.requiredDate)}</Table.Data>
            <Table.Data className={className}>{job.assetCount}</Table.Data>
            <Table.Data className={className}>{job.shippedCount}</Table.Data>
            <Table.Data className={className}>{job.jobType}</Table.Data>
        </Table.Row>
    );
}

const Results = (props) => {

    let history = useHistory();

    const getResultData = () => {
        let showSAPStatus = SAPService.isSAPFacility();
        const state = props.controller.state;
        let searchResults = state.searchResults || [];
        searchResults.map((job) => {
            job.status = getStatusElement(showSAPStatus, job);
            return job;
        });
        return searchResults;
    }

    const getStatusElement = (showSAPStatus, job) => {
        return showSAPStatus ? getSAPStatusElement(job) : getNonSAPStatusElement(job);
    }

    const getNonSAPStatusElement = (job) => {
        let statusValue = 0;

        if (job.assetCount === 0) {
        }
        else if (job.isComplete) {
            statusValue += 90;
        }
        else if (job.canComplete) {
            statusValue += 50;
        }
        else if (job.isInspecting) {
            statusValue += 40;
        }
        else if (job.isCreated) {
            statusValue += 10;
        }
        else {
            statusValue += 100;
        }

        return statusValue;
    }

    const getSAPStatusElement = (job) => {
        var statusValue = 0;
        var warning = (job.sapErrorMessage !== '')
            ? <span>&nbsp;<i className='fa fa-warning sap-error' /></span>
            : null;

        if (warning) {
            statusValue++;
        }

        if ((job.sapStatus == 'QUNR' || job.sapStatus == 'QUNC') && job.assetCount === 0) {
        }
        else if (job.isClosed) {
            // SAP && non-SAP
            statusValue += 90;
        }
        else if (job.isQuoteSent) {
            statusValue += 38;
        }
        else if (job.sapStatus == 'QUOR' && !job.isQuoteSent && !job.isInvoiced && !job.canInvoice && !job.isCompleting && !job.canComplete) {
            statusValue += 35;
        }
        else if (job.isInvoiced) {
            // SAP only
            statusValue += 80;
        }
        else if (job.canInvoice) {
            // SAP only
            statusValue += 70;
        }
        else if (job.isCompleting) {
            // SAP only
            statusValue += 60;
        }
        else if (job.canComplete) {
            // SAP && non-SAP
            statusValue += 50;
        }
        else if (job.isWaitingToRetry) {
            statusValue += 45;
        }
        else if (job.isInspecting) {
            // SAP && non-SAP
            statusValue += 40;
        }
        else if (job.canLockForInspection) {
            // SAP && non-SAP
            statusValue += 30;
        }
        else if (job.isWaiting) {
            // SAP && non-SAP
            statusValue += 20;
        }
        else if (job.isCreated) {
            statusValue += 10;
        }

        else {
            statusValue += 100;
        }

        return statusValue;
    }

    const handleRowClick = (job) => {
        props.controller.loadJobDetails(job);
    }

    const handleNotes = (id) => {
        history.push('/notes/' + AppConstants.NoteTypes.Job + '/' + id);
    }

    const getRowProps = (row) => {
        const job = row.original;
        const rowProps = { id: job.id };
        if (job.sapStatus === 'QUOR') {
            rowProps.style = { backgroundColor: '#FFA07A' };
        }
        return rowProps;
    }

    const showSAPStatus = SAPService.isSAPFacility();
    const data = getResultData();
    const handlePageChange = () => {
        TopPagination.Toggle();
    }

    useEffect(() => {
        handlePageChange();
    }, [data]);
    const count = props.controller.state.searchResults.length;
    const activeJobsLabel = Utils.pluralize('match', 'es', count);
    const totalAssetCount = props.controller.state.searchResults.reduce((acc, curr) => {
        return acc + curr.assetCount;
    }, 0);
    const totalAssetCountLabel = Utils.pluralize('total asset', 's', totalAssetCount);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'status',
                Cell: cellInfo => {
                    let element = null;
                    let statusValue = 0;

                    if (SAPService.isSAPFacility()) {
                        const warning = (cellInfo.row.original.sapErrorMessage !== '')
                            ? <span>&nbsp;<i className='fa fa-exclamation-triangle sap-error' /></span>
                            : null;

                        if (warning) {
                            statusValue++;
                        }

                        if ((cellInfo.row.original.sapStatus == 'QUNR' || cellInfo.row.original.sapStatus == 'QUNC') && cellInfo.row.original.assetCount === 0) {
                            // SAP && non-SAP
                            element = <span className='job-no-assets status-column' title='There are no assets on this job - can be locked'><i className='far fa-circle' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isClosed) {
                            // SAP && non-SAP
                            statusValue += 90;
                            element = <span className='job-closed status-column' title='Job is closed – no further changes can be made'><i className='fa fa-ban' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.sapStatus == 'QUOR' && !cellInfo.row.original.isQuoteSent && !cellInfo.row.original.isInvoiced && !cellInfo.row.original.canInvoice && !cellInfo.row.original.isCompleting && !cellInfo.row.original.canComplete) {
                            statusValue += 35;
                            element = <span className='job-setting-up status-column' title='Quote Override'><i className='fa fa-edit' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isQuoteSent) {
                            statusValue += 38;
                            element = <span className='job-setting-up status-column' title='Quote sent to customer for approval'><i className='fa fa-money-bill-alt' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isInvoiced) {
                            // SAP only
                            statusValue += 80;
                            element = <span className='job-invoiced status-column' title='Waiting for accounting to complete invoicing'><i className='far fa-clock' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.canInvoice) {
                            // SAP only
                            statusValue += 70;
                            element = <span className='job-invoicable status-column' title='This job can be sent to accounting for invoicing'><i className='fa fa-dollar-sign' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isCompleting) {
                            // SAP only
                            statusValue += 60;
                            element = <span className='job-completing status-column' title='Waiting for job to be completed in SAP'><i className='far fa-clock' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.canComplete) {
                            // SAP && non-SAP
                            statusValue += 50;
                            var title = 'Click Complete Job to signal that all inspection activities are complete and to remove this entry from the list';
                            if (cellInfo.row.original.useSAP) {
                                title = 'Click Complete Job to signal SAP that all inspection activities are complete';
                            }
                            element = <span className='job-completion-candidate status-column' title={title}><i className='fa fa-check' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isWaitingToRetry) {
                            statusValue += 45;
                            element = <span className='job-retry-status-update status-column' title='Job is attempting to retry updating Status in SAP'><i className='far fa-clock' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isInspecting) {
                            // SAP && non-SAP
                            statusValue += 40;
                            element = <span className='job-active status-column' title='Job is active, assets can still be received, and asset inspections can still take place'><i className='fa fa-cogs' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.canLockForInspection) {
                            // SAP && non-SAP
                            statusValue += 30;
                            element = <span className='job-lock-for-inspection-candidate status-column' title='Click Lock Assets to allow Technicians to being inspecting the assets on this job'><i className='fas fa-lock-open' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isWaiting) {
                            // SAP && non-SAP
                            statusValue += 20;
                            element = cellInfo.row.original.awaitingLockForInspection
                                ? <span className='job-creating-service-orders status-column' title='Waiting for Lock Assets request to complete'><i className='far fa-clock' />{warning}</span>
                                : <span className='job-creating-service-orders status-column' title='Waiting for SAP request to complete'><i className='far fa-clock' />{warning}</span>;
                        }
                        else if (cellInfo.row.original.isCreated) {
                            statusValue += 10;
                            element = <span className='job-setting-up status-column' title='Assets can still be assigned to this job from the Receiving page'><i className='fa fa-download' />{warning}</span>;
                        }
                        else {
                            statusValue += 100;
                            element = <span></span>;
                        }

                        // set sorting value
                        cellInfo.row.original.status = statusValue;
                    }

                    else {
                        if (cellInfo.row.original.assetCount === 0) {
                            // SAP && non-SAP
                            element = <span className='job-no-assets status-column' title='There are no assets on this job'><i className='far fa-circle' /></span>;
                        }
                        else if (cellInfo.row.original.isComplete) {
                            statusValue += 90;
                            element = <span className='job-closed status-column' title='Job is complete – no further changes can be made'><i className='fa fa-ban' /></span>;
                        }
                        else if (cellInfo.row.original.canComplete) {
                            statusValue += 50;
                            element = <span className='job-completion-candidate status-column' title="Click 'Complete Job' to remove this entry from the list"><i className='fa fa-check' /></span>;
                        }
                        else if (cellInfo.row.original.isInspecting) {
                            statusValue += 40;
                            element = <span className='job-active status-column' title='There are still assets that need to be shipped – unable to complete at this time'><i className='fa fa-cogs' /></span>;
                        }
                        else if (cellInfo.row.original.isCreated) {
                            statusValue += 10;
                            element = <span className='job-setting-up status-column' title='Assets can still be assigned to this job from the Receiving page'><i className='fa fa-download' /></span>;
                        }
                        else {
                            statusValue += 100;
                            element = <span></span>;
                        }

                        // set sorting value
                        cellInfo.row.original.status = statusValue;
                    }
                    return element;
                }
            },
            {
                Header: 'Notes',
                accessor: 'noteCount',
                disableSortBy: true,
                Cell: cellInfo => cellInfo.cell.value
                    ? <Badge variant='warning' className='note-badge' onClick={() => handleNotes(cellInfo.row.original.id)}>{cellInfo.cell.value}</Badge>
                    : null
            },
            {
                Header: 'SAP Status',
                accessor: 'sapStatus'
            },
            {
                Header: 'Job Number',
                accessor: 'jobNumber',
                Cell: (cellInfo) => {
                    let display = '';
                    if (cellInfo.row.original.assetCount > 0) {
                        display = <Form.Link to={'/jobprogressassets/' + cellInfo.row.original.id} value={cellInfo.cell.value} />
                    }
                    else {
                        display = <span>{cellInfo.cell.value}</span>
                    }
                    return display;
                }
            },
            {
                Header: 'Facility',
                accessor: 'facilityName'
            },
            {
                Header: 'Customer',
                accessor: 'customerName'
            },
            {
                Header: 'Job Date',
                accessor: 'jobDate',
                Cell: cellInfo => <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
            },
            {
                Header: 'Requested Date',
                accessor: 'requiredDate',
                Cell: cellInfo => {
                    let requiredDateClassName = '';
                    const today = DateTimeFormatter.today().format('YYYYMMDD');
                    const requiredDate = DateTimeFormatter.getMoment(cellInfo.cell.value).format('YYYYMMDD');
                    if (today > requiredDate) {
                        requiredDateClassName += ' late-error';
                    } else if (today === requiredDate) {
                        requiredDateClassName += ' late-warning';
                    }
                    return <span className={requiredDateClassName}>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
                }
            },
            {
                Header: 'Asset Count',
                accessor: 'assetCount',
            },
            ,
            {
                Header: 'Shipped',
                accessor: 'shippedCount',
            },
            {
                Header: 'Job Type',
                accessor: 'jobType'
            },
        ],
        [showSAPStatus]
    )
    return (
        <div className='col-md-12'>
            <div className='d-flex justify-content-between'>
                <TopPagination />
                <div className='mt-2 mr-2'>
                    <Badge variant='info' className='mr-1'> {activeJobsLabel}</Badge>
                    <Badge variant='info' className='mr-1'> {totalAssetCountLabel}</Badge>
                </div>
            </div>
            <Grid id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText='No jobs meet the search criteria defined above' enableDefaultRowSelect={true} getRowProps={getRowProps} onPageChange={handlePageChange} />
        </div>
    );
}

class ResultsList extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount = () => {
        const stream = this.props.controller.flash.delay(0);
        stream.onValue(this.flash);
        this.dispose = () => stream.offValue(this.flash);
    }

    componentWillUnmount = () => {
        this.dispose();
    }

    flash = (jobId) => {
        document.getElementById('results-table').find('#' + jobId + ' td')
            .velocity({ backgroundColor: '#FFFF9F' }, { duration: 1000 })
            .velocity('reverse');
    }

    getNonSAPStatusElement = (job) => {
        let statusValue = 0;

        if (job.assetCount === 0) {
        }
        else if (job.isComplete) {
            statusValue += 90;
        }
        else if (job.canComplete) {
            statusValue += 50;
        }
        else if (job.isInspecting) {
            statusValue += 40;
        }
        else if (job.isCreated) {
            statusValue += 10;
        }
        else {
            statusValue += 100;
        }

        // set sorting value
        //job.status = statusValue;
        return statusValue;
    }

    getSAPStatusElement = (job) => {
        let statusValue = 0;
        const warning = (job.sapErrorMessage !== '')
            ? <span>&nbsp;<i className='fa fa-warning sap-error' /></span>
            : null;

        if (warning) {
            statusValue++;
        }

        if ((job.sapStatus == 'QUNR' || job.sapStatus == 'QUNC') && job.assetCount === 0) {
        }
        else if (job.isQuoteSent) {
            statusValue += 38;
        }
        else if (job.sapStatus == 'QUOR' && !job.isQuoteSent && !job.isInvoiced && !job.canInvoice && !job.isCompleting && !job.canComplete) {
            statusValue += 35;
        }
        else if (job.isClosed) {
            // SAP && non-SAP
            statusValue += 90;
        }
        else if (job.isInvoiced) {
            // SAP only
            statusValue += 80;
        }
        else if (job.canInvoice) {
            // SAP only
            statusValue += 70;
        }
        else if (job.isCompleting) {
            // SAP only
            statusValue += 60;
        }
        else if (job.canComplete) {
            // SAP && non-SAP
            statusValue += 50;
        }
        else if (job.isWaitingToRetry) {
            statusValue += 45;
        }
        else if (job.isInspecting) {
            // SAP && non-SAP
            statusValue += 40;
        }
        else if (job.canLockForInspection) {
            // SAP && non-SAP
            statusValue += 30;
        }
        else if (job.isWaiting) {
            // SAP && non-SAP
            statusValue += 20;
        }
        else if (job.isCreated) {
            statusValue += 10;
        }
        else {
            statusValue += 100;
        }

        // set sorting value
        //job.status = statusValue;
        return statusValue;
    }

    getData = () => {
        const showSAPStatus = SAPService.isSAPFacility();
        const jobs = this.props.controller.state.searchResults || [];
        return jobs;
    }
    getResultData = () => {
        const showSAPStatus = SAPService.isSAPFacility();
        let results = [];
        const state = this.props.controller.state;
        let searchResults = state.searchResults || [];
        const jobsWithStatus = searchResults.map((job) => {
            job.status = this.getSAPStatusElement(job);
            return job;
        });
        searchResults = jobsWithStatus;
        const pageLimit = state.pageLimit || 50;
        const offset = state.offset || 0;
        results = searchResults.length < offset ? searchResults : searchResults ? searchResults.slice(offset, offset + pageLimit) : [];
        return results;
    }
    render = () => {
        const controller = this.props.controller;
        //let jobs                 = this.getData();
        let jobs = this.getResultData();
        const colSpanCount = 11;
        const loadingMessage = controller.state.isLoading && <Table.Row>
            <Table.Data />
            <Table.Data colSpan={colSpanCount} className='loading'>Loading jobs that match the search criteria defined above
            </Table.Data>
        </Table.Row>;
        const nojobsMessage = !loadingMessage && !jobs.length && <Table.Row>
            <Table.Data />
            <Table.Data colSpan={colSpanCount} className='no-search-results'>No jobs meet the search criteria defined above</Table.Data>
        </Table.Row>;
        const hdrSAPStatus = <Table.Head sortKey='sapStatus,jobNumber' getData={this.getData} sorter={this.sortData}>SAP
            Status</Table.Head>;

        jobs = jobs.map(job => <ResultRow key={job.id} controller={controller} job={job} />);

        return (
            <div id='search-results'>
                <Table id='results-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head sortKey='status,jobNumber' className='status-column' getData={this.getData}
                                sorter={this.sortData} />
                            <Table.Head className='bg-primary table-dark'>Notes</Table.Head>
                            {hdrSAPStatus}
                            <Table.Head sortKey='jobNumber' getData={this.getData} sorter={this.sortData}>Job
                            Number</Table.Head>
                            <Table.Head sortKey='facilityName,customerName,jobNumber' getData={this.getData}
                                sorter={this.sortData}>Facility</Table.Head>
                            <Table.Head sortKey='customerName,jobNumber' getData={this.getData}
                                sorter={this.sortData}>Customer</Table.Head>
                            <Table.Head sortKey='jobDate,jobNumber' getData={this.getData} sorter={this.sortData}>Job
                            Date</Table.Head>
                            <Table.Head sortKey='requiredDate,jobNumber' getData={this.getData} sorter={this.sortData}>Requested
                            Date</Table.Head>
                            <Table.Head sortKey='assetCount,jobNumber' getData={this.getData} sorter={this.sortData}>Asset
                            Count</Table.Head>
                            <Table.Head sortKey='shippedCount,jobNumber' getData={this.getData}
                                sorter={this.sortData}>Shipped</Table.Head>
                            <Table.Head sortKey='jobType,jobNumber' getData={this.getData} sorter={this.sortData}>Job
                            Type</Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {jobs}
                        {loadingMessage}
                        {nojobsMessage}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const Details = (props) => {

    let history = useHistory();

    const navigateToDropoff = (dropoffId) => {
        if (dropoffId === Utils.emptyGuid) {
            return;
        }
        history.push('editdropoff/' + dropoffId);
    }
    const navigateToShippingTicketDetails = (shippingTicketId) => {
        if (shippingTicketId) {
            history.push('shippingtickets/' + shippingTicketId);
        }
    }
    const getShippingTicketLink = (isSAPFacility, ticket) => {
        // display upload icon if ticket hasn't been uploaded yet
        // TODO: uncomment code when SAP POD Upload service is available
        const prependIcon = (isSAPFacility && ticket.isPODUploaded) ? 'fas fa-upload' : '';
        return <Form.Link icon={prependIcon} value={ticket.name} onClick={() => { navigateToShippingTicketDetails(ticket.id); }} />;
    }
    // const binder      = props.binder;
    const controller = props.controller;
    const selectedJob = controller.state.selectedJob;
    const divSAPErrorCode = selectedJob.sapErrorCode
        ? <div className='col-md-12 sap-error'><Form.StaticData style={{ wordWrap: 'break-word' }}
            label='SAP Error Code'
            value={selectedJob.sapErrorCode} /></div>
        : null;
    const divSAPErrorMessage = selectedJob.sapErrorMessage
        ? <div className='col-md-12 sap-error'><Form.StaticData label='SAP Error Message'
            value={selectedJob.sapErrorMessage} /></div>
        : null;
    const accountingIndicator = selectedJob
        ? selectedJob.accountingIndicator
        : null;
    let dropoffLink = selectedJob.deliveryTicket; // default
    if (selectedJob.dropoffForms && selectedJob.dropoffForms.length) {
        const dropoffLinks = selectedJob.dropoffForms.map(form => <Form.Link key={form.id} to={'/dropoff/' + form.id} value={form.name} />);
        dropoffLink = dropoffLinks.slice(1).reduce(function (xs, x) {
            return xs.concat([', ', x]);
        }, [dropoffLinks[0]]);
    }
    let shippingTicketLink = '';
    const isSAPFacility = SAPService.isSAPFacility();
    if (selectedJob.shippingTickets && selectedJob.shippingTickets.length) {
        const shippingTicketLinks = selectedJob.shippingTickets.map(ticket => getShippingTicketLink(isSAPFacility, ticket));
        shippingTicketLink = shippingTicketLinks.slice(1).reduce(function (xs, x) {
            return xs.concat([', ', x]);
        }, [shippingTicketLinks[0]]); // comma-separated list of links
    }
    return (
        <Jumbotron {...props} id='job-description' className='form-group'>
            <div className='row'>
                <div className='col-md-12'><Form.StaticData label='Facility' value={selectedJob.facilityName} />
                </div>
                <div className='col-md-12 row'>
                    <div className='col-md-6'><Form.StaticData label='Job Number' value={selectedJob.jobNumber} /></div>
                    {isSAPFacility ? <div className='col-md-6'><Form.StaticData label='Quote Number' value={selectedJob.quoteNumber} /></div> : null}
                </div>
                <div className='col-md-12'><Form.StaticData label='Customer' value={selectedJob.customerName} />
                </div>
                <div className='col-md-12'><Form.StaticData label='Customer Location'
                    value={selectedJob.customerLocation} /></div>
                <div className='col-md-12'><Form.StaticData label='Job Type' value={selectedJob.jobType} /></div>
                <div className='row col-md-12'>
                    <div className='col-md-6'><Form.StaticData label='Job Date'
                        value={DateTimeFormatter.formatDate(selectedJob.jobDate)} />
                    </div>
                    <div className='col-md-6'><Form.StaticData label='Requested Date'
                        value={DateTimeFormatter.formatDate(selectedJob.requiredDate)} />
                    </div>
                </div>
                <div className='row col-md-12'>
                    <div className='col-md-6'><Form.StaticData label='Customer Job Number'
                        value={selectedJob.customerJobNumber} /></div>
                    <div className='col-md-6'><Form.StaticData label='Customer PO' value={selectedJob.customerPO} />
                    </div>
                </div>
                {selectedJob.useSAP &&
                    <div className='row col-md-12'>
                        <div className='col-md-6'><Form.StaticData label='Accounting Indicator'
                            value={accountingIndicator} /></div>
                        <div className='col-md-6'><Form.StaticData label='SAP Status' value={selectedJob.sapStatus} />
                        </div>
                    </div>
                }
                {!selectedJob.useSAP &&
                    <div className='col-md-12'><Form.StaticData label='Accounting Indicator'
                        value={accountingIndicator} /></div>
                }
                <div className='col-md-12'><Form.StaticData label='Job Description'
                    value={selectedJob.jobDescription} /></div>
                <div className='col-md-12'><Form.StaticData label='Job Notes' value={selectedJob.jobNotes} /></div>
                <div className='col-md-12'><Form.StaticData label='Drop-Off Number' value={dropoffLink} /></div>
                <div className='col-md-12'><Form.StaticData label='Shipping Ticket Number'
                    value={shippingTicketLink} /></div>
                {divSAPErrorCode}
                {divSAPErrorMessage}
            </div>
        </Jumbotron>
    );
}

const Buttons = (props) => {

    const handleEstimateQuote = (job) => {
        props.controller.EstimateQuote(job);
    }
    const handleOverride = (job) => {
        props.controller.Override(job);
    }
    const controller = props.controller;
    const job = controller.state.selectedJob;
    const isSAPJob = job.useSAP;
    const canShowNotes = job;
    const canPerformAdhocTransactions = controller.canPerformAdhocTransactions(job);
    const canEstimate = job && job.assetSOCount > 0 && controller.canEstimateQuote(job);
    const canOverride = job && job.assetSOCount > 0 && controller.canOverrideJob(job);
    return (
        <div className='mb-1'>
            <div className='col-md-12'>
                <div className='row'>
                    <Button icon='fa-pencil-alt' disabled={!job || (job.assetCount === 0)}
                        variant='primary' size='sm' className='action-button m-1' to={'/jobprogressassets/' + job.id}>Asset details ...</Button>
                    <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Job Actions'>
                        <Dropdown.MenuItem icon='fa-star' disabled={!isSAPJob || !canPerformAdhocTransactions} to={'/adhoctransactions/' + job.id}>Adhoc Transactions ...</Dropdown.MenuItem>
                        <Dropdown.MenuItem icon='fa-money-bill-alt' disabled={!canEstimate} onClick={() => { handleEstimateQuote(job); }}>Request Quote ...</Dropdown.MenuItem>
                        {/* <Dropdown.MenuItem  icon='fa-edit'                    disabled={!canOverride}                 onClick={() => { handleOverride(job);       }}>Override ...</Dropdown.MenuItem >  */}
                    </Dropdown.Button>
                    <Button icon='fa-comments' disabled={!canShowNotes} to={'/notes/' + AppConstants.NoteTypes.Job + '/' + job.id} variant='info' size='sm' className='action-button btn-text-wrap m-1'>Notes ...</Button>
                    <Button icon='fa-money-bill-alt' disabled={!job} variant='success' size='sm' className='action-button btn-text-wrap m-1' to={'/quotesdetail/' + job.jobNumber + '/' + job.id}>Quote Details...</Button>
                </div>
            </div>            
        </div >
    );
}

const SAPJobProgressPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-comments' />

class SAPJobProgressPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
    }
    componentDidMount = () => {
        this.initializeMixin();
        this.demandSAPRep();
    }
    static defaultProps = {
        controller: new SAPJobProgressController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Page {...this.props} pageTitle='InteServ · SAP ISR Job Progress' id='sap-job-progress-page'>
                <SAPJobProgressPageHeader pageTitle={'SAP ISR Job Progress'} />
                <Page.Content>
                    <div className='row'>
                        <div {...this.props} className='col-md-8'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <SearchHeader controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12'>
                                    <Results controller={controller} binder={binder} />
                                </div>
                            </div>
                        </div>

                        <div {...this.props} className='col-md-4'>
                            <Buttons controller={controller} />
                            <Details controller={controller} binder={binder} />
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}
export default SAPJobProgressPage;
