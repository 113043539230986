import React, { useMemo } from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import Searchers from '../utility/Searchers';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import store from 'store';

const AssetGrid = (props)=>{
    const assetSelected = (assetId)=>{
      
       props.controller.addremoveassets(assetId); 
    }
    
    const columns = useMemo(()=>[
        
        {
            Header: 'Serial Number',
            accessor: 'primarySerialNumber',
            disableSortBy: true,
            Cell: cellInfo => {
              return <div>{cellInfo.cell.value}</div>
            }
          },
          {
            Header: 'Equipment Number',
            accessor: 'equipmentNumber',
            disableSortBy: true,
            Cell: cellInfo => {
              return <div>{cellInfo.cell.value}</div>
            }
          },
          {
            Header: 'MaintPlant',
            accessor: 'plantCode',
            disableSortBy: true,
            Cell: cellInfo => {
              const currentPlantCode = store.get('currentFacility')?.plantCode;
              return <div style={{color : cellInfo.cell.value != currentPlantCode ?  "red" : null}}>{cellInfo.cell.value}</div>
            }
          },
          {
            Header: 'Functional Location',
            accessor: 'functionalLocation',
            disableSortBy: true,
            Cell: cellInfo => {
                return   <div style={{color : cellInfo.cell.value ?  "red" : null}}>{cellInfo.cell.value}</div>
            }
          },
          {
            Header: 'Container Description',
            accessor: 'container',
            disableSortBy: true,
            Cell: cellInfo => {
                return<div style={{color : cellInfo.cell.value ?  "red" : null}}>{cellInfo.cell.value}</div>
            }
          },
          {
            Header: 'Add to Received Assets',
            accessor: 'abc',
            disableSortBy: true,
            Cell: cellInfo => {
              return <div>{
                 
                <input type='checkbox' checked = {props.controller.state.finalassets.includes(cellInfo.row.original.assetId)} onChange={()=>{assetSelected(cellInfo.row.original.assetId)}}></input>
                
              }</div>
            }
        },

    ],[])
    const controller = props.controller;
    const assets = controller.state.selectedassets;
    return  <Grid  columns={columns} data={assets} />;
}
class ReceiveAssetDialog extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }
    handleClose = (result) => 
    {
      var switchresult = false;
     if(result.Cancel)
     {
        switchresult = true;
     }
     if(result.Save)
     {
       switchresult = this.props.controller.save();
     }
     if(result.SelectNone)
     {
        switchresult = this.props.controller.selectNoneAsset();
     }
     if(result.SelectAll)
     {
        switchresult = this.props.controller.selectAllAssets();
     }
      return switchresult;
    }
    render() {
        const currentPlantCode = store.get('currentFacility')?.plantCode;
        const headertext = <><span style={{color:"red"}}>Warning: </span>Asset(s) belong to below referenced MaintPlant, Functional Location or Container. Select the asset(s) you wish to receive into {currentPlantCode}.</>
       
        return ( <Dialog {...this.props} header={this.props.header} width={700} height={430} id='psm-details-dlg' onClose={this.handleClose} className='psm_dialog'>
            <Dialog.Body>
                <div className='col-md-12'>
                    <div className='row'>
                        {headertext}
                    </div>
                    <div className='row'>
                    <AssetGrid {...this.props}/>
                    </div>
                  
                </div>
            </Dialog.Body>
            <Dialog.Buttons>
            <Dialog.Button icon='far fa-square' variant='success' size='sm' className='btn' result={{ SelectAll: true }}>Select All</Dialog.Button>
            <Dialog.Button icon='far fa-square' variant='danger' size='sm' className='btn'  result={{ SelectNone: true }}>Select None</Dialog.Button>
            <Dialog.Button variant='primary' size='sm' className='btn' icon='' result={{ Save: true }}>Save</Dialog.Button>                
            <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Cancel: true }}>Close</Dialog.Button>
            </Dialog.Buttons>
        </Dialog>)
    }
}
export default ReceiveAssetDialog;