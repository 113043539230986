import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Table from '../../components/Table';
import Binder from '../../lib/Binder';
import Utils from '../utility/Utils';
import Search from '../components/Search';

class MoveContainerDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();;
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  addContainerNode = (rows, container, indentLevel) => {
    const controller = this.props.controller;
    const selectedContainerId = controller.state.selectedParentContainer
      ? controller.state.selectedParentContainer.containerId
      : Utils.emptyGuid;
    let className = container.hasAccess ? '' : 'no-container-access';
    className += (container.description === controller.noParentContainerDescription()) ? ' no-parent-container' : '';
    className += (selectedContainerId === container.containerId) ? ' selected' : '';
    if (!controller.canSelectContainer(container)) {
      className += ' invalid-parent-container';
    }

    const padding = (5 + ((indentLevel * 20)) + 'px'); // indent the hierarchy
    const style = { paddingLeft: padding };
    let description = container.description; // default
    if (container.matchesSearch) {
      description = <span className={'search-term-match'}>{container.description}</span>;
    }

    rows.push(
      <Table.Row key={container.containerId} onClick={function () { controller.selectContainer(container); }}>
        <Table.Data style={style} className={className}>{description}</Table.Data>
      </Table.Row>
    );

    if (!container.containers || !container.containers.length) {
      return;
    }
    container.containers.forEach(child => this.addContainerNode(rows, child, indentLevel + 1));
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const data = this.props.controller.state.containers || [];
    let results = [];

    data.forEach(container => this.addContainerNode(results, container, 0));

    const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data className='loading'>Loading containers</Table.Data></Table.Row>;
    const noResultsMessage = !loadingMessage && !results.length && <Table.Row><Table.Data className='no-search-results'>No containers exists for the selected customer</Table.Data></Table.Row>;
    const header = <Table.Row>
      <Table.Head>Select the container's new parent</Table.Head>
    </Table.Row>;
    const message = <span>Select new parent container for {controller.state.container.description}</span>;
    const disableSearch= !controller.state.parentContainerSearchText.trim().length>0;
    
    return (
      <Dialog {...this.props} header={this.props.header} width={600} height={500} id='move-container-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row'>
            <div className='col-md-12'>
              <Search className='mb-2' id='parentContainerSearchText' label={message} prependLabelIcon='fa fa-search' inputClassName='search-input text-uppercase' placeholder='Please enter text to search' disable={disableSearch} autoFocus='true' bind={binder.bind('parentContainerSearchText')} enableSearch ={controller.enableSearch} clear={controller.clearContainerSearchText} search={controller.containerSearch}></Search>
            </div>
            <div className='form-group col-md-12 search-results-containers'>
              <Table id='results-table'>
                <Table.Header>
                  {header}
                </Table.Header>
                <Table.Body>
                  {results}
                  {loadingMessage}
                  {noResultsMessage}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons />
      </Dialog>
    );
  }
}

export default MoveContainerDialog;