import React from 'react';
import Button from './Button';
import Cx from 'classnames';
import PropTypes from 'prop-types';

const GroupBadge = (defaultProps) => {

    const props = {
        pill: true,
        variant: 'secondary',
        ...defaultProps
    }

    const classes = Cx({
        ' badge-pill': props.pill
    });

    let className = 'badge' + classes;
    if(props.className){
        className += ' ' + props.className;
    }
    
    const style = {
        display: 'inline-block',
        ...props.style
    }

    return <Button {...props} style={style} className={className}>{props.children}</Button>
}

GroupBadge.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

export default GroupBadge;