import React, { useMemo } from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import SAPService from '../services/SAPService';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import ShippingDetails from '../pages/ShippingDetails'
import ShipAssetsToContainerDialogController from '../controllers/ShipAssetsToContainerDialogController';
import Searchers from '../utility/Searchers';
import store from 'store';

var ReceivingLocation = (props) => {
  const binder = props.binder;
  const controller = props.controller;
  const defaultGUID = Utils.emptyGuid;
  const customerId = controller.state.customerId ? controller.state.customerId : defaultGUID;
  const loginDetail = store.get('InteServLoginInfo');
  const removeFromContainerDisable = controller.state.customerContainer ? true : false;
  const disableGroupName= controller.state.jobNumber?false:true;
  const groupSelection = 
          controller.state.isExistingJob
              ? <div className="col-md-12">
                <div className='row'>
                  <div className='col-md-4'>
                    <Form.AutoComplete label='Group #' dataToggle="tooltip" dataPlacement="top" title="Leave blank to auto-assign" bind={binder.bind('groupNumber')} search={controller.groupNumberSearch()} minimumResultsForSearch={-1} />
                  </div>
                  <div className='col-md-8'>
                    <Form.Input label='Group Name' bind={binder.bind('groupName')} maxLength="30" />
                  </div>
                </div>
              </div>
              : <div className='col-md-12'>
                <Form.Input label='Group Name' bind={binder.bind('groupName')} disabled={disableGroupName} enabled={!disableGroupName} maxLength="30" />
              </div>
             ;

  return (
    <div className='form-group col-md-12 ship-form-container'>
      <div className='row'>
        <div className='col-md-6'>
            <Form.AutoComplete label='Receiving Location' id='receivingLocation' className='required' error={controller.state.errors.receivingLocation} bind={binder.bind('receivingLocation')} search={controller.receivingLocationSearch()} />
            {(SAPService.isSAPFacility() && controller.state.customerName === 'TechnipFMC Drilling & Completions') ? <Form.AutoComplete label='Cost Center' dataToggle="tooltip" dataPlacement="top" bind={binder.bind('costCenter')} search={Searchers.searchReceivngCostCenter(LoginService.loginInfo.facilityId)} /> : null}
        </div>
        <div className='col-md-6'>
            <Form.CheckBox label='Remove From Container' bind={binder.bind('removeFromContainer')} enabled={!removeFromContainerDisable} disabled={removeFromContainerDisable} />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
            <Form.AutoComplete label='Job Number' id='jobNumber' bind={binder.bind('jobNumber')} search={Searchers.searchActiveJobs(customerId, loginDetail ? loginDetail.facilityId : defaultGUID)} />
        </div>
        <div>
            {groupSelection}
        </div>
      </div>
    <div className='row'>
        <div className='col-md-6'>
            <Form.AutoComplete label='Assembly Work Order Number' id='awoNumber' bind={binder.bind('awoNumber')} search={Searchers.searchActiveAWOs(customerId, loginDetail ? loginDetail.facilityId : defaultGUID)} />
        </div>
      </div>
    </div>
  );
}
class ShipAssetsToContainerDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  static defaultProps = {
    controller: new ShipAssetsToContainerDialogController()
  }

  handleClearSignature = () => {
    this.props.controller.clearSignature();
  }

  handleUpdateSignature = (signature) => {
    this.props.controller.updateSignature(signature);
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  render = () => {
    const controller = this.props.controller;
    const data = controller.state;
    const binder = new Binder(this);
    const labelText = 'Select the assets you wish to ship';
    const jobNumber = data.jobNumber;
    const customerName = data.customerName;
    const customerLocation = data.customerLocation;
    const selectedAssets = controller.state.selectedAssets || [];
    const canRemoveFromContainer = selectedAssets.reduce((acc, curr) => {
      return acc || (curr.containerId !== Utils.emptyGuid);
    }, false);
    const removeFromContainer = canRemoveFromContainer
      ? <div className='col-lg-6'>
        <Form.CheckBox label='Remove from Container' bind={binder.bind('removeFromContainer')} />
      </div>
      : null;
    
      const dialogHeader= 'Receive Assets to Job/AWO or Ship Assets'; 
    return (
      <Dialog {...this.props} header={dialogHeader} width={800} height={600}
        id='ship-assets-dialog' onClose={this.handleClose}>
        <Dialog.Body>
          <div className='row ship-form-container-dlg'>
            <div className='col'>
              <div className='row'>
                <ReceivingLocation controller={controller} binder={binder} />
              </div>
              <div className=''>
                <ShippingDetails controller={controller} binder={binder}
                  clearSignature={this.handleClearSignature}
                  updateSignature={this.handleUpdateSignature} receiveOrShip='true' parentPage='shipassetstocontainer'/>
              </div>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons />
      </Dialog>
    );
  }
};

export default ShipAssetsToContainerDialog;