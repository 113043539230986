import React from 'react';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Validator from '../../lib/Validator';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

class GaugeAssetScrapController extends BaseController {
  constructor(asset, dailyCalId) {
    super('gauge-asset-scrapped', {
      asset             : asset,
      dailyCalId        : dailyCalId,
      approverSignature : '',
      errors            : {},
      rejectReason      : '',
      approverName      : ''      
    });

    this._validator = new Validator();
    this._validator.add('approverName'      , 'Approved By'        , [{required: true}]);
    this._validator.add('approverSignature' , 'Approver Signature' , [{required: true}]);
    this._validator.add('rejectReason'      , 'Reject Reason'      , [{required: true}]);
  }

  clearSignature = () => {
    this.state.approverSignature = '';
    this.commit();
  }

  updateSignature = (signature) => {
    this.state.approverSignature = signature;
    this.commit();
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  save = () => {
    if (!this.canSave()) {
      return false;
    }
      const s = this.state;
      const parameters = {
        assetId             : s.asset.assetId,
        dailyCalId          : s.dailyCalId,
        signature           : s.approverSignature,
        rejectReasonId      : s.rejectReason ? s.rejectReason.id : '',
        approvedByName      : s.approverName
      };
      return ajax.post('gauge/scrapGaugeAsset', parameters).then(result=>{
        if(result){
            messages.channel('gauges').action('updated').post({assetId: s.asset.assetId, dailyCalId:s.dailyCalId});
            notification.action('success').post(`Asset scrapped successfully on ${DateTimeFormatter.formatDate(DateTimeFormatter.today())}`);           
          } 
          return true;
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
    });    
    }
}

export default GaugeAssetScrapController;

