import _ from 'immutable';
import Utils from '../utility/Utils';

// find all selected service levels of the selected assets
const findSelectedIntersectingAssemblyLevels = (assets) => {
  let allServiceLevels = [];
  let processedFirstAsset = false;
  let configs = assets.configs;
  configs.forEach(asset => {
    if (!processedFirstAsset) {
      // push all service levels from the 1st asset onto the list
      // this forms the basis against which all other assets' service levels will be compared
      processedFirstAsset = true;
      let serviceLevels = asset.assemblyLevels || [];
      serviceLevels.filter(serviceLevel => serviceLevel.performed).forEach(serviceLevel => allServiceLevels.push(Utils.clone(serviceLevel)));
    }
    else {
      // otherwise, enumerate all service levels in allServiceLevels against those in the current asset
      // if the service level is not found , remove it from allServiceLevels
      // what we're left with should be the intersection of all selected assets
      let index = allServiceLevels.length - 1;
      while (index >= 0) {
        let foundMatch = false;
        let currentServiceLevel = allServiceLevels[index];
        if (asset.assemblyLevels) {
          asset.assemblyLevels.forEach(serviceLevel => {
            if ((currentServiceLevel.assemblyLevelName === serviceLevel.assemblyLevelName) && serviceLevel.performed) {
              foundMatch = true;
            }
          });
        }
        if (!foundMatch) {
          // no intersection for this service level, remove it from the 'master list'
          allServiceLevels.splice(index, 1);
        }
        index--;
      }
    }
  });
  return allServiceLevels;
}

const findSelectedNonIntersectingAssemblyLevels = (assets) => {
  if (!assets.length) {
    return [];
  }

  // push all service levels for selected assets onto a list
  let allServiceLevels = _.List();
  assets.filter(asset => asset.selected).forEach(asset => {
    let serviceLevels = asset.serviceLevels || [];
    serviceLevels.filter(serviceLevel => serviceLevel.performed).forEach(serviceLevel => {
      let level = {
        serviceLevelId: serviceLevel.serviceLevelId,
        serviceLevelName: serviceLevel.serviceLevelName,
        fmcServiceLevelName: serviceLevel.fmcServiceLevelName,
        hierarchyLevel: serviceLevel.hierarchyLevel,
        intervalDays: serviceLevel.intervalDays,
        required: serviceLevel.required,
        tests: Utils.clone(serviceLevel.tests)
      };
      let containsServiceLevel = false;
      allServiceLevels.forEach(serviceLevelToTest => {
        if (serviceLevelToTest.serviceLevelId === level.serviceLevelId) {
          containsServiceLevel = true;
        }
      });
      if (!containsServiceLevel) {
        allServiceLevels = allServiceLevels.push(level);
      }
    });
  });

  // remove the intersecting service levels
  let intersectingServiceLevels = findSelectedNonIntersectingAssemblyLevels(assets);
  for (let i = allServiceLevels.size - 1; i >= 0; --i) {
    let serviceLevel = allServiceLevels.get(i);
    intersectingServiceLevels.forEach(intersectingServiceLevel => {
      if (intersectingServiceLevel.serviceLevelName === serviceLevel.serviceLevelName) {
        allServiceLevels = allServiceLevels.splice(i, 1);
      }
    });
  }
  // we're left with the 'extras' - i.e.: they don't match the intersecting entries
  return allServiceLevels.toArray();
}

const initializeServiceLevels = (assets) => {
  let results = [];
  assets.forEach(asset => {
    if (!results.length) {
      // set the service levels to those on on the list
      // this forms the basis against which all other assets' service levels will be compared
      if (asset.serviceLevels) {
        asset.serviceLevels.forEach(serviceLevel => {
          results.push({
            serviceLevelId: serviceLevel.serviceLevelId,
            hierarchyLevel: serviceLevel.hierarchyLevel,
            serviceLevelName: serviceLevel.serviceLevelName,
            required: serviceLevel.required,
            performedCount: serviceLevel.performed ? 1 : 0,
            tests: Utils.clone(serviceLevel.tests),
            isRejectLaborCode: serviceLevel.isRejectLaborCode,
            isSAPServiceLevel: serviceLevel.isSAPServiceLevel,
            billsOfMaterial: serviceLevel.billsOfMaterial
          });
        });
      }
    }
    else {
      // otherwise, enumerate the service levels in results (resultServiceLevel) against those in the current asset
      // if the service level is not found, remove it from results
      // what we're left with should be the intersection of all selected assets

      let index = results.length - 1;
      while (index >= 0) {
        let foundMatch = false;
        let resultServiceLevel = results[index];
        if (asset.serviceLevels) {
          asset.serviceLevels.forEach(serviceLevel => {
            if (resultServiceLevel.serviceLevelName === serviceLevel.serviceLevelName) {
              foundMatch = true;
              if (serviceLevel.performed) {
                resultServiceLevel.performedCount++;
              }
            }
          });
        }
        if (!foundMatch) {
          // no intersection for this service level, remove it from the 'master list'
          results.splice(index, 1);
        }
        index--;
      }
    }
  });
  results.forEach(serviceLevel => {
    serviceLevel.performed = (serviceLevel.performedCount > 0);
    serviceLevel.indeterminate = ((serviceLevel.performedCount > 0) && (serviceLevel.performedCount < assets.length));
  });
  return results;
}

const doesTestApplyToServiceLevel = (serviceLevel, test) => {
  const a = serviceLevel.tests.reduce((acc, curr) => {
    return (acc || (curr.testName === test));
  }, false);
  return a;
}

// find all tests of the service levels
const findDistinctTests = (levels) => {
  let serviceLevels = levels || [];
  let allTests = [];
  serviceLevels.forEach(serviceLevel => {
    serviceLevel.tests.forEach(test => {
      if (!_.List(allTests).contains(test.testName) && (test.testName !== null)) {
        allTests.push(test.testName);
      }
    });
  });
  return allTests;
}

const hasTestsInConflict = (levels, test) => {
  let serviceLevels = levels || [];
  let usageCount = serviceLevels.reduce((acc, curr) => {
    if (!curr.performed || curr.indeterminate) {
      return acc;
    }

    let match = curr.tests.some(t => t.testName === test);
    if (match) {
      return acc + 1;
    }

    return acc;
  }, 0);
  return (usageCount > 1);
}

const hasConflictingTests = (asset) => {
  let tests = _.List();
  let hasConflict = false;
  let serviceLevels = asset.serviceLevels || [];
  serviceLevels
    .filter(serviceLevel => serviceLevel.performed)
    .forEach(serviceLevel => {
      serviceLevel.tests.forEach(test => {
        if (tests.contains(test.testName)) {
          hasConflict = true;
        }
        tests = tests.push(test.testName);
      });
    });
  return hasConflict;
}


const initializeAssemblyLevels = (assets) => {
  let results = [];
  if (assets === undefined || assets.length < 1) {
    return false;
  }
  if (assets.length > 0) {
    results = [];
    assets.forEach(serviceLevel => {
      results.push({
        assemblyLevelId: serviceLevel.assemblyLevelId,
        assemblyLevelName: serviceLevel.assemblyLevelName,
        required: serviceLevel.required,
        performedCount: serviceLevel.performed ? 1 : 0,
        tests: Utils.clone(serviceLevel.tests),
        isSAPAssemblyLevel: serviceLevel.isSAPAssemblyLevel,
        awo_Guid: serviceLevel.awo_Guid,
        awo_System_Config_Id: serviceLevel.awo_System_Config_Id
      });
    });
  }


  results.forEach(serviceLevel => {
    serviceLevel.performed = (serviceLevel.performedCount > 0);
    serviceLevel.indeterminate = ((serviceLevel.performedCount > 0) && (serviceLevel.performedCount < assets.length));
  });
  return results;
}

// find all tests of the service levels
const findDistinctTestsInAlphabeticOrder = (levels) => {
  let serviceLevels = levels || [];
  let allTests = [];
  serviceLevels.forEach(serviceLevel => {
    serviceLevel.tests.forEach(test => {
      if (!_.List(allTests).contains(test.testName) && (test.testName !== null)) {
        allTests.push(test.testName);
      }
    });
  });
  return allTests.sort();
}

const AssemblylevelHelper = {
  findSelectedIntersectingAssemblyLevels: findSelectedIntersectingAssemblyLevels,
  findSelectedNonIntersectingAssemblyLevels: findSelectedNonIntersectingAssemblyLevels,
  initializeServiceLevels: initializeServiceLevels,
  doesTestApplyToServiceLevel: doesTestApplyToServiceLevel,
  findDistinctTests: findDistinctTests,
  hasTestsInConflict: hasTestsInConflict,
  hasConflictingTests: hasConflictingTests,
  initializeAssemblyLevels: initializeAssemblyLevels,
  findDistinctTestsInAlphabeticOrder: findDistinctTestsInAlphabeticOrder
};

export default AssemblylevelHelper;
