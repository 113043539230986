import React, { useMemo } from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Utils from '../utility/Utils';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import InspectionGaugesRow from '../pages/InspectionGaugesRow';
import Searchers from '../utility/Searchers';
import AppConstants from '../utility/AppConstants';
import Table from '../../components/Table';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';


class InspectionGaugesDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }


  componentDidMount = () => {
    this.initializeMixin();
    this.props.controller.constructJSONObject();
    this.props.controller.load();
  }


  handleClose = (result) => result.Cancel || result.OK ? true : this.props.controller.save();

  render = () => {
    var controller = this.props.controller;
    const binder = new Binder(this);
    const saveCancelButtons = controller.state.jobStatus || controller.state.assets[0].sapStatus == AppConstants.AssetSAPStatus.TECO 
      ? <Dialog.OKButtons />
      : <Dialog.SaveCancelButtons saving={controller.state.disableSave}/>;
    const buttons =  controller.state.primrySerNumber?.id >= 0 ? saveCancelButtons : <Dialog.Buttons><Dialog.CloseButton /></Dialog.Buttons>;

    return (
      <Dialog {...this.props} className='pass-dialog' header={this.props.header} modal={true} width={730} height={700} id='gauges-test-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <InspectionGaugesTable controller={controller} binder={binder} />
          </form>
        </Dialog.Body>
        {buttons}
      </Dialog>
    );
  }
}

const InspectionGaugesTable = (props) => {

  var controller = props.controller;
  const rows = Utils.filterDeletedRecords(controller.state.rows) || [];
  var altResult = [...rows];
  const binder = props.binder;
  var testName = controller.state.testName;
  var assetCount = controller.state.assets.length;
  var title = <h4 className='col-sm-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '5px', paddingleft: '0px', width: '95%' }}>Select Gauge Serial Number(s) for the <span className='font-weight-bold text-primary'>{testName}</span> operation for the {Utils.pluralize('selected asset', 's', assetCount)}</h4>
  var NotApp = <span style={{ float: 'right' }}><Form.CheckBox label='N/A' bind={binder.bind('isNotApplicable')} enabled={controller.state.isNotApplicable} /></span>;
  var individualGridTitle = <h4 className='col-sm-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '3px' }}>List of Added Gauges for Individual Serial Numbers</h4>
  var assetDropdown = assetCount == 1 ?
   <Form.AutoComplete className='required' id='select-asset-id' bind={binder.bind('primrySerNumber')} search={controller.handleSearch()} allowClear={false} disabled={true} /> :
   <Form.AutoComplete className='required' id='select-asset-id' bind={binder.bind('primrySerNumber')} data={controller.state.assetJSONArray} placeholder='Select a Primary Serial to filter your results' /> ;
  const readMode = controller.state.jobStatus || controller.state.assets[0].sapStatus == AppConstants.AssetSAPStatus.TECO;
  var data = [];
  if(controller.state.primrySerNumber?.id == 1){
    const result = [];
    rows.find((a, index) => {
      var temp = rows.find((x, ind)=> x.state.serialNumber?.name === a.state.serialNumber?.name && index !== ind );
      if(temp && !result.map(x => x.state.serialNumber?.name).includes(temp.state.serialNumber?.name))
      {
        result.push(temp);
      }
      else if(a.state.gaugeRowGUID === Utils.emptyGuid && a.state.recordStatus === 'A' && a.state.psn === 'All Selected Primary Serial Numbers'){
        result.push(a);
      }
    });
    data = result;    
  }
  else if(controller.state.primrySerNumber?.name){
    rows.forEach(x => {
       if(controller.state.primrySerNumber?.name == x.state.psn){
        data.push(x);
      }
    });
  }

  data.forEach(x => {
    if(x.state.serialNumber){
      var index = altResult.map(function(p) { return p.state.gaugeRowGUID}).indexOf(x.state.gaugeRowGUID);
      altResult.splice(index, 1);
      altResult =  altResult.filter(function(e){
        if(e.state.serialNumber?.name && x.state.serialNumber?.name){
          return e.state.serialNumber.name !== x.state.serialNumber.name;
        }
      });
    }
  });

  const handleAddNewRow = () => {
    controller.addNewRowData();
    controller.commit();
  }

  const handleChange = () => {
    controller.canSave();
    controller.commit();
  }

  const getCellProps = (cell) => {
    let style = {};
    if (cell && cell.column.id === 'action') {
      style = {
        width: '0px'
      };
    }
    // if (cell && cell.column.id === 'state.description') {
    //   style = {
    //     width: '320px'
    //   };
    // }
    if (cell && cell.column.id === 'state.serialNumber') {
      style = {
        width: '400px' 
      };
      
      }
      if (cell && cell.column.id === "state.calibrationDate") {       
        style = {
          width: '100px'
        };
      }
    
    const rowProps = { style: style };
    return rowProps;
  }
  var disableAddNewRow = controller.isLoading || controller.state.isNotApplicable;
  const getClassName = (expiryDate) => {
    let className = '';
    const warningDate = DateTimeFormatter.now().add('days', 90).format('YYYYMMDD');
    if ((DateTimeFormatter.getMoment(expiryDate).format('YYYYMMDD') < DateTimeFormatter.getMoment(warningDate).format('YYYYMMDD')) &&
      (DateTimeFormatter.getMoment(expiryDate).format('YYYYMMDD') > DateTimeFormatter.now().format('YYYYMMDD'))) {
      className = 'gauge-about-to-expire';
    }
    else if (DateTimeFormatter.getMoment(expiryDate).format('YYYYMMDD') <= DateTimeFormatter.now().format('YYYYMMDD')) {
      className = 'gauge-expired';
    }
    else {
      className = 'not-expired';
    }
    return className;
  }

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return !readMode ? <ActionIcon icon='fa-trash' className='remove-handle' action={controller.removeRow} />
            : <span></span>;
        }
      },
      //{
      //  Header: 'Primary Serial number',
      //  accessor: 'state.psn',
      //  Cell: (cellInfo) => {
      //    const controller = cellInfo.row.original;
      //    return <span>{controller.state.psn}</span>
      //  }
      //},
      {
        Header: 'Serial No and Description',
        accessor: 'state.serialNumber',
        Cell: (cellInfo, binder) => {
          const controller = cellInfo.row.original;
          const errors = controller.state.errors;
          return !readMode
              ?  <Form.AutoComplete style={{width:"500px"}} onChange={handleChange} className='required' bind={binder.bind('serialNumber')} search={ controller.parentController.serialNumbersSearch(LoginService.loginInfo.facilityId)} error={errors.serialNumber} />
            : <Form.StaticData value={controller.state.serialNumber.name} />
        }
      },
      // {
      //   Header: 'Serial No Description',
      //   accessor: 'state.description',
      //   Cell: (cellInfo) => {
      //     const controller = cellInfo.row.original;
      //     return <span>{controller.state.description}</span>
      //   }
      // },
      {
        Header: 'Exp. Date',
        accessor: 'state.calibrationDate',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
            const className = getClassName(controller.state.calibrationDate);
           return <span className={'calibration-date ' + className}>{controller.state.calibrationDate}</span>
          //return <span>{controller.state.calibrationDate}</span>
        }
      },
    ],
    []);

  const altColumns = useMemo(
    () => [
      {
        Header: 'Serial No and Description',
        accessor: 'state.serialNumber',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          return controller.state.serialNumber 
             ? <Form.StaticData value={controller.state.serialNumber.name} />
             : <span></span>
        }
      },
      // {
      //   Header: 'Serial No Description',
      //   accessor: 'state.description',
      //   Cell: (cellInfo) => {
      //     const controller = cellInfo.row.original;
      //     return <span>{controller.state.description}</span>
      //   }
      // },
      {
        Header: 'Exp. Date',
        accessor: 'state.calibrationDate',
        Cell: (cellInfo) => {
          const controller = cellInfo.row.original;
          const className = getClassName(controller.state.calibrationDate);
           return <span className={'calibration-date ' + className}>{controller.state.calibrationDate}</span>         
        }
      },
    ],
    []);

  return (
    <div>
      {NotApp}
      {title}
      {assetDropdown}
      <div id='add-Gauges-table'>
      <Grid id='results-table' columns={columns} data={data} editable={true} getCellProps={getCellProps} canAddNewRow={false} canAddDefaultValueButton={controller.state.primrySerNumber?.id >= 0 ? true : false} showPagination={false} noDataText='No Records' addDefaultValueText='Click to add a new row' onAddDefaultValue={handleAddNewRow} disableAddDefaultValueButton={disableAddNewRow} />
      </div>
      { controller.state.primrySerNumber?.id && controller.state.primrySerNumber.id == 1 ? 
      <div id='add-Gauges-alt-table'>
         {individualGridTitle}
        <Grid id='results-alt-table' columns={altColumns} data={altResult} editable={true} getCellProps={getCellProps} canAddNewRow={false} showPagination={false} noDataText='No Records' />
      </div> : null
      }
    </div>
  );

}

export default InspectionGaugesDialog;