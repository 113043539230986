import React from 'react';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import SignaturePad from '../../components/SignaturePad';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Controller from '../../mixins/Controller';

class AssetScrapSignatureDialog extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.OK ? true : false;

  isEmpty = (data) => {
    return (!data);
  }

  render = () => {
    const data = this.props.controller.state;
    if (this.isEmpty(data)) {
      let header = this.props.header;
      if (data.primarySerialNumber) {
        header += ' for ' + data.primarySerialNumber;
      }

      return (
        <Dialog {...this.props} header={header} width={650} height={210} id='scrap-signature-dlg' onClose={this.handleClose}>
          <Dialog.Body>
            <form role='form'>
              <div className='row'>
                <div className='col-md-12' style={{ padding: '10px' }}>
                  <div className='pt-4 text-center'>There is no scrap signature for this asset</div>
                </div>
              </div>
            </form>
          </Dialog.Body>
          <Dialog.OKButtons />
        </Dialog>
      );
    }

    return (
      <Dialog {...this.props} header={this.props.header + ' for ' + data.primarySerialNumber} width={600} height={630} id='scrap-signature-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form'>
            <div className='row'>

              <div className='col-12 p-2' style={{ fontSize: '1rem !important' }}>
                <div className='col-12'><Form.StaticData label='Failing Test' value={data.failingTestName} /></div>
                <div className='col-12'><Form.StaticData label='Reject Reason' value={data.rejectReason} /></div>
                <div className='col-12'><Form.StaticData label='Scrap Date' value={DateTimeFormatter.formatDate(data.dateAdded)} /></div>
                <div className='col-12'><Form.StaticData label='Inspecting Technician' value={data.recordedByName} /></div>
                <div className='col-12'><Form.StaticData label='Scrap Approved By' value={data.signatureName || '« no signature recorded »'} /></div>
                <div className='col-12'><SignaturePad id='signature-pad' className='col-sm-6 required' label='Signature' signature={data.signature} disabled={true} /></div>
              </div>
            </div>

          </form>
        </Dialog.Body>
        <Dialog.OKButtons />
      </Dialog>
    );
  }
}

export default AssetScrapSignatureDialog;
