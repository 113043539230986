import Binder from '../../lib/Binder';
import Form from '../../components/Form';
import React, {useMemo} from 'react';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import LibertyReportPageController from '../controllers/LibertyReportPageController';
import Controller from '../../mixins/Controller';
import Authentication from '../services/authentication';
import Button from '../../components/Button';
import Grid from '../../components/Grid';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Search from '../components/Search';

const LaborReportPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fas fa-file-download' />
const SearchHeader = (props) => {
    const binder = props.binder;
    const data = props.controller.state.jobs;
    const handleGenerate = () => {
        props.controller.generate();
    }
    const handleSearch = (serialNo) => {
        props.controller.serialNoSearch(serialNo);
    }
    const clearSearch = () => {
        props.controller.clearSearchText();
    }
    const handleClick = (FileName) => {
        props.controller.handler(FileName);
    }
    const columns = useMemo(
        () => [
            {
                Header: 'Request  Number',
                accessor: 'RequestNumber',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.RequestNumber}</span>;
                    //return <a onClick={() => handleClick(controller.JobNumber, controller.ShippingTicket)} className='d-flex text-decoration-none'>{controller.ShippingTicket}</a>
                }
            },
            {
                Header: 'Request  Date',
                accessor: 'RequestDate',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.RequestDate}</span>;
                }
            },
            {
                Header: 'Customer Name',
                accessor: 'customerName',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.CustomerName}</span>;
                    //return <a onClick={() => handleClick(controller.JobNumber, controller.ShippingTicket)} className='d-flex text-decoration-none'>{controller.ShippingTicket}</a>
                }
            },
            {
                Header: 'Job Number',
                accessor: 'jobNumber',
                Cell: (cellInfo, binder) => {                   
                    const controller = cellInfo.row.original;
                    return <span>{controller.JobNumber}</span>;
                }
            },
            {
                Header: 'Shipping Ticket',
                accessor: 'ShippingTicket',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.ShippingTicket}</span>;
                }
            },
            {
                Header: 'Container',
                accessor: 'Container',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.Container}</span>;
                }
            },
            {
                Header: 'Download Report',
                accessor: 'isReportgenerated',
                Cell: (cellInfo, binder) => {                   
                    const controller = cellInfo.row.original;
                    return controller.IsReportGenerated  ?                     
                     <a onClick={() => handleClick(controller.FileName)} className='d-flex text-decoration-none'>Download</a>
                    : controller.HasErrors ? <>Report Generation Failed</> : <span>Report Generation is In Progress</span>;
                }
            },
            
            {
                Header: 'Expiration Date',
                accessor: 'ExiprationDate',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.ExiprationDate}</span>;                   
                }
            },
            
            // {
            //     Header: 'Facility',
            //     accessor: 'facility',
            //     Cell: (cellInfo, binder) => {
            //         const controller = cellInfo.row.original;
            //         return <span>{controller.Facility}</span>;
            //     }
            // }
        ],
        []
    )
    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
          return;
        }
        props.controller.loadJobs();
      }
      const handleMouseSearch = () => {
        props.controller.loadJobs();
      }
      const clearJobSearch = ()=>{
        props.controller.clearSearchJob();
      }
    return (
        <div>
            {/* <div className='row'>
                <div className='col-md-3'>
                    <Form.Date label='Assets Shipped Date' placeholder='dd-mon-yyyy' className='required' bind={binder.bind('jobCompletionDate')} />
                </div>
                <div className='col-md-3 mt-3'>
                    <Button icon='fa-download' id='btn-save' variant='primary' size='sm' className='m-3 action-button' disabled={false} onClick={handleGenerate}>Download</Button>
                </div>
            </div>
            <div className="row">
                <div className='col-md-4'><div className='text-center my-2'><span className='h4'>Or</span></div></div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-3'>
                    <Search className='mb-2' id='primarySerialNumber' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='Primary Serial Number' autoFocus='true' bind={binder.bind('primarySerialNumber')} clear={clearSearch} search={handleSearch}></Search>
                </div>
            </div> */}
            <div className='row'>
            <div className='search-container col-md-6' onKeyDown={handleKeyboardSearch}>
        <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder='Enter job number or customer name or request number' autoFocus='true' bind={binder.bind('searchText')} clear={clearJobSearch} search={handleMouseSearch}></Search>
        </div>
            </div>
            <div className='row'>
               
                <div className='col-md-10'>
                    <Grid id='results-table' columns={columns} data={data} noDataText='No results to be shown.' />
                </div>
                
            </div>
        </div>
    );
}
class LaborReportPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        this.props.controller.loadJobs(null);
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandCoordinator();
      }

    static defaultProps = {
        controller: new LibertyReportPageController()
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);
        return (
            <Page pageTitle='InteServ · My Report Requests' id='liberty-report-page'>
                <LaborReportPageHeader pageTitle={'My Report Requests'} />
                    <Page.Content>
                        <SearchHeader controller={controller} binder={binder} />
                    </Page.Content>
            </Page>
        );
    }
};

export default LaborReportPage