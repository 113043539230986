import BaseController from '../../lib/BaseController';
import notification from '../../services/Notification';
import Validator from '../../lib/Validator';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';

class ChangeAssetOwnershipController extends BaseController {
  constructor(asset,routedFrom) {
    super('change-asset-ownership', {
      asset       : Object.assign({}, asset),
      assets      : Array.isArray(asset) ? asset : [],
      newCustomer : '',
      newLocation : '',
      errors      : {},
      routedFrom  : routedFrom ? routedFrom : ''
    });

    this.saving = false;
    this._validator = new Validator();
    this._validator.add('newCustomer', 'Customer', [{required: true}]);
    if(routedFrom && routedFrom=='AssetDetail'){
      this._validator.add('newLocation', 'Location', [{required: true}]);
    }
  }

  newCustomerChanged() {
    this.state.newLocation = '';
    this.commit();
  }

  locationSearch() {
    var self = this;

    return function* (searchTerm) {
      var parameters = {
        customerId : self.state.newCustomer.id,
        searchTerm : searchTerm
      };
      var results = yield ajax.get('lookup/SearchNonTechnipCustomerLocationList', parameters); // IList<LookupDto>
      return results;
    };
  }

  canChangeOwnership() {
    this._validator.validateAll(this.state);
    var errors = Object.keys(this.state.errors);
    if (!errors.length) {
      if (this.state.asset.customer && this.state.asset.customer.id === this.state.newCustomer.id) {
        this.state.errors.newCustomer = 'Customer must be changed to a different value';
      }
      else {
        return true;
      }
    }
    this.commit();
    return false;
  }

  changeOwnership = (parentController) => {
    if (!this.canChangeOwnership()) {
      return false;
    }

    var state = this.state;
    var assetIds = [];
    var asset = state.asset;
    var parameters = {};
    var url = '';
    if (state.assets && state.assets.length) {
      assetIds = state.assets.map((item) => {
        return { id: item.assetId, name: item.primarySerialNumber };
      });
      parameters = {
        assetIds: assetIds,
        customerId: state.newCustomer.id,
        locationId: state.newLocation.id
      };
      url = 'receiving/changeAsstesOwnership';
    } else {
      parameters = {
        assetId: asset.assetId,
        customerId: state.newCustomer.id,
        locationId: state.newLocation.id
      };
      url = 'receiving/changeOwnership';
    }
      this.saving = true;
      this.commit();
      return ajax.post(url, parameters).then(result=>{
        this.saving = true;
        this.commit();
        if (result && result.length) {
          notification.action('error').post('Please see error panel for the error details');
        }
        else {
          if (state.assets && state.assets.length) {
            notification.action('success').post(`Changed ownership of assets to ${state.newCustomer.name}`, 'Changed Asset Ownership');
          }
          else {
            notification.action('success').post(`Changed ownership of asset ${asset.primarySerialNumber} from ${asset.customer.name} to ${state.newCustomer.name}`, 'Changed Asset Ownership');
          }
        }
        messages.channel('edit-asset').action('updated').post(asset);
        if (parentController) {
          parentController.setTransferErrors(result);
        }
        return true;
      }).catch(err => {
        this.saving = false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
    });
  }
}

export default ChangeAssetOwnershipController;
