import store from 'store';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';

class ChangeFacilityController extends BaseController {
    constructor() {
        super('change-facility', {
            userId: LoginService.loginInfo ? LoginService.loginInfo.userId : '',
            facility: LoginService.loginInfo
                ? {
                    id: LoginService.loginInfo.facilityId,
                    name: LoginService.loginInfo.facilityName
                }
                : {}
        });

        messages.channel('change-facility').action('changed').subscribe(facility => {
            this.loadFacility(facility.id);
        });

        this.saving = false;
    }

    facilitySearch = () => (searchTerm) => ajax.get('lookup/searchFacilitiesForUser', { searchTerm: searchTerm }).then(results => results).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });

    loadFacility = (facilityId) => {
        const parameters = {
          facilityId : facilityId
        };
    
        ajax.get('lookup/getFacility', parameters).then((results) => {
            store.set('currentFacility', results);
            const loginDetail = store.get('InteServLoginInfo');
            loginDetail.enableDMS = results.enableDMS;
            store.set('InteServLoginInfo', loginDetail);
            messages.channel('clockInProcess_Cli').action('start').post();
            messages.channel('load-plant-Code').action('reload').post();
          }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });         
      }

    save = () => {
        if (!this.state.facility) {
            notification.action('error').post('Please select the Facility');
            return;
        }
        this.saving = true;
        this.commit();
        const parameters = {
            userId: this.state.userId,
            facilityId: this.state.facility.id
        };

        return ajax.post('user/changeFacility', parameters).then((facility) => {
            this.saving = false;
            this.commit();
            if (facility === null) {
                notification.action('error').post('Unable to change facility');
                return false;
            }
            LoginService.updateFacility(facility);
            notification.action('success').post('Changed facility to ' + facility.name);
            messages.channel('change-facility').action('changed').post(facility);
            return true;
        }).catch(err => {
            this.saving = false;
            this.commit();
            notification.action('error').post({ msg: err.message, title: 'Error' });
            return false;
        });

    }

}

export default ChangeFacilityController;