import _ from 'immutable';
import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import Utils from '../utility/Utils';

class SelectUserCustomerLocationsController extends BaseController {
  constructor(user, userCustomerLocations, allCustomerLocations) {
    super('change-user-customerlocations', {
      user: user,
      userCustomerLocations: Utils.clone(userCustomerLocations),
      allCustomerLocations: allCustomerLocations,
      availableCustomerLocationsFilterTerm: ''
    });
  }

  selectNoCustomerLocations = () => {
    this.state.allCustomerLocations.forEach(customerLocation => {
      customerLocation.selected = false;
    });
    this.commit();
  }

  selectNoUserCustomerLocations = () => {
    this.state.userCustomerLocations.forEach(customerLocation => {
      customerLocation.selected = false;
    });
    this.commit();
  }

  selectAllCustomerLocations = () => {
    this.state.allCustomerLocations.forEach(customerLocation => {
      customerLocation.selected = true;
    });
    this.commit();
  }

  selectAllUserCustomerLocations = () => {
    this.state.userCustomerLocations.forEach(customerLocation => {
      customerLocation.selected = true;
    });
    this.commit();
  }

  filterAllCustomerLocations = () => {
    this.commit();
  }

  clearFilterAllCustomerLocations = () => {
    this.state.availableCustomerLocationsFilterTerm = '';
    this.commit();
  }

  getFilteredAllCustomerLocations = () => {
    const searchTerm = this.state.availableCustomerLocationsFilterTerm;
    if (!searchTerm) {
      return this.state.allCustomerLocations;
    }

    const terms = searchTerm.split(',');
    if (!terms) {
      return this.state.allCustomerLocations;
    }

    let filtered = [];
    this.state.allCustomerLocations.forEach(location => {
      terms.forEach(term => {
        if (Utils.contains(location.name, term)) {
          filtered.push(location);
          return;
        }
      });
    });
    return filtered;
  }

  toggleSelection = (customerLocation) => {
    customerLocation.selected = !customerLocation.selected;
    this.commit();
  }

  selectAndTransfer = (customerLocation) => {
    customerLocation.selected = true;
    const inSelectedList = _.List(this.state.userCustomerLocations).contains(customerLocation);
    if (!inSelectedList) {
      this.moveToSelected();
    }
    else {
      this.removeFromSelected();
    }
  }

  moveToSelected = () => {
    this.state.allCustomerLocations.forEach(customerLocation => {
      if (customerLocation.selected) {
        customerLocation.selected = false;

        let found = false;
        for (let i in this.state.userCustomerLocations) {
          if (this.state.userCustomerLocations[i].id === customerLocation.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.state.userCustomerLocations.push(Utils.clone(customerLocation));
        }
      }
    });

    // sort selected list
    this.state.userCustomerLocations.sort(function (a, b) {
      return (a.name.localeCompare(b.name));
    });
    this.commit();
  }

  removeFromSelected = () => {
    let i = this.state.userCustomerLocations.length;
    while (i--) {
      const customerLocation = this.state.userCustomerLocations[i];
      if (customerLocation.selected) {
        this.state.userCustomerLocations.splice(i, 1);
      }
    }
    this.commit();
  }

  save = () => {
    const selectedcustomerLocationIds = this.state.userCustomerLocations.map(customerLocation => { return customerLocation.id; });   // use only the ids
    const user = this.state.user;
    const parameters = {
      userId: user.userId,
      selectedcustomerLocationIds: selectedcustomerLocationIds
    };

    return ajax.post('user/changeUserCustomerLocations', parameters).then(() => {
      // in: ChangeUserCustomerLocationsDto, out: void
      // const msg = 'Updated available customer locations for {firstName} {lastName}'.interpolate(user);
      // notification.action('info').post({msg: msg, title: 'User Customer Locations Updated'});
      messages.channel('edit-customer-location').action('saved').post();
      messages.channel('edit-user').action('saved').post(user);
      return true;
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
      return false;
    });
  }
}

export default SelectUserCustomerLocationsController;
