import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';

class LoginActivityController extends BaseController {
  constructor(user) {
    super('user-login-activity', {
      user          : user,
      loginActivity : []
    });
  }

  getLoginActivity = () => {
    const userId = this.state.user.userId;
    ajax.get('lookup/getLoginActivity', { userId: userId }).then((loginActivity) => {
      this.state.loginActivity = loginActivity;
      this.commit();
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }
}

export default LoginActivityController;