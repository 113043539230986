import BaseController from '../../lib/BaseController';
import PressureTestRowController from './PressureTestRowController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';

class ManualPressureTestController extends BaseController {
  constructor() {
    super('manual-pressure-test', {
      overrideSettings:[]
    });
  }

  load = (queryData) => {
    if(queryData){
      ajax.get('lookup/getManualPressureTestSettings/', queryData).then(results => {
        this.state = Object.assign({}, results, { canEdit: queryData.canEdit === true }, { errors: {} });
        this.initializeCustomerDescriptionPressureTestSettings(this.state.customerDescriptionSettings);
        this.initializeOverridePressureTestSettings(this.state.overrideSettings);
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // out: ManualPressureTestSettingsDto
    }
  }

  initializeCustomerDescriptionPressureTestSettings = (pressureTestSettings) => {
    this.state.customerDescriptionSettings = [];
    pressureTestSettings.forEach(pressureTestSetting => this.addCustomerDescriptionPressureTestRow(pressureTestSetting));
  }

  addCustomerDescriptionPressureTestRow = (setting) => {
    this.state.customerDescriptionSettings.push(new PressureTestRowController(this, 'cd-', setting));
  }

  initializeOverridePressureTestSettings = (pressureTestSettings) => {
    this.state.overrideSettings = [];
    pressureTestSettings.forEach(pressureTestSetting => this.addNewPressureTestRow(pressureTestSetting));
  }

  addNewPressureTestRow = (setting) => {
    this.state.overrideSettings.push(new PressureTestRowController(this, 'over-', setting));
    this.commit();
  }

  removePressureTestSettingRow = (row) => {
    let idx = this.state.overrideSettings.indexOf(row);
    if (idx === -1) {
      return;
    }
    this.state.overrideSettings.splice(idx, 1);
    this.commit();
  }

  validatePressureTestSettingRows = (settings) => {
    settings.forEach(row => row.validate());
  }

  canSave = () => {
    this.validatePressureTestSettingRows(this.state.overrideSettings);
    let pressureTestSettingRowErrors = this.state.overrideSettings.reduce((acc, curr) => {
      return acc + Object.keys(curr.state.errors).length;
    }, 0);

    if (!pressureTestSettingRowErrors) {
      this.commit();
      return true;
    }

    this.commit();
    return false;
  }

  save = () => {
    if (!this.canSave()) {
      return;
    }
    const s = this.state;
    const parameters = {
      customerId: s.customer && s.customer.id,
      jobId: s.job && s.job.id,
      familyCodeId: s.familyCode && s.familyCode.id,
      cwpId: s.cwp && s.cwp.id,
      pressureTestSettings: s.overrideSettings.map(setting => setting.state)
    };
    ajax.post('inspection/saveManualPressureTestSettings', parameters).then(() => {
      notification.action('success').post(`Saved changes to Manual Pressure Test Settings for ${s.familyCode.name} and ${s.cwp.name} on job ${s.job.name}`);
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: UpdateManualPressureTestSettingsDto, out: void
  }
}

export default ManualPressureTestController;
