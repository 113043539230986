import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Validator from '../../lib/Validator';
import LoginService from '../services/LoginService';

class AddReleaseNotesController extends BaseController {
  constructor() {
    super('add-release-note', AddReleaseNotesController.getInitialState());
    this.editMode = false;
    this._validator = new Validator();
    this._validator.add('requestedDate', 'Release Date', [{ required: true }]);
    this._validator.add('noteText', 'Release Note', [{ required: true }]);
  }

  static getInitialState = () => {
    return {
      noteText: '',
      requestedDate: '',
      saveInProgress: false,
      errors: {}
    };
  }

  getTitle = () => {
    return 'Release Notes';
  }

  load = (rnId) => {
    if (rnId) {
      this.editMode = true;
      ajax.get('lookup/getReleaseNote', { releaseNoteId: rnId }).then(releaseNote => {
        this.state.requestedDate = DateTimeFormatter.formatDate(releaseNote.date);
        this.state.noteText = releaseNote.note;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      }); // ReleaseNotesDto
    }
    else {
      this.editMode = false;
      this.state = AddReleaseNotesController.getInitialState();
      this.commit();
    }
  }

  canAddNotes = () => {
    return ((LoginService.isAdministrator || LoginService.isCoordinator || LoginService.isSAPRep) || (LoginService.isTechnician));
  }

  canSave = () => {
    this._validator.validateAll(this.state);
    const errors = Object.keys(this.state.errors);
    if (!errors.length) {
      return true;
    }
    this.commit();
    return false;
  }

  addNote = (cb, rnId) => {
    if (!this.canSave()) {
      return;
    }

    const parameters = {
      createdDate: this.state.requestedDate,
      releaseNotes: this.state.noteText,
      id: rnId
    };


    if (this.editMode) {
      this.state.saveInProgress = true;
      this.commit();
      ajax.post('lookup/updateReleaseNotes', parameters).then(() => {
        this.state.saveInProgress = false;
        this.commit();
        notification.action('success').post('Release Notes updated successfully')
        cb();
      }).catch(err => {
        this.state.saveInProgress = false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });   // in: AddNoteDto, out: void
    }
    else {
      this.state.saveInProgress = true;
      this.commit();
      ajax.post('lookup/addReleaseNotes', parameters).then(() => {
        this.state.saveInProgress = false;
        this.commit();
        notification.action('success').post('Release Notes added successfully')
        cb();
      }).catch(err => {
        this.state.saveInProgress = false;
        this.commit();
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });; // in: AddNoteDto, out: void
    }

  }
}

export default AddReleaseNotesController;
