import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';

class AdminQueryParametersController extends BaseController {
  constructor(query) {
    super('reportparameters', {
      query: query
    });
    query.parameters.forEach(parameter => this.state[parameter.name] = parameter.value);
  }

  loadReport = () => {
    const query   = this.state.query;
    return ajax.post('lookup/loadQuery', { queryId: query.queryId, parameters: query.parameters  }).then(results=>{
        messages.channel('adminquery').action('data-loaded').post(results);
        return true;
    }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
        return false;
    }); // object
  }
}

export default AdminQueryParametersController;