const Controller = {
  onPropertyChanged(data) {
    this.props.controller.state = this.state;
    this.props.controller.handlePropertyChanged(data);
  },

  initializeMixin() {
    this._disposable = [];
    this.setState(this.props.controller.state);
    this.props.controller.prevState = this.props.controller.state;
    this.using(this.props.controller.channel.action('commit').subscribe(state => {
      this.props.controller.prevState = this.props.controller.state;
      this.setState(state);
    }));
  },

  using() {
    Array.prototype.slice.call(arguments, 0).forEach((d) => {
      if (!d.dispose) {
        return;
      }

      delete d.isDisposed;
      this._disposable.push(d);
    });
  },

  componentWillUnmount() {
    for (var i = this._disposable.length - 1; i >= 0; i--) {
      var objToDispose = this._disposable[i];
      if (objToDispose && objToDispose.dispose && !objToDispose.isDisposed) {
        objToDispose.isDisposed = true;
        objToDispose.dispose();
      }
    }

    this._disposable = [];
  }
}

export default Controller;