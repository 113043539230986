import BaseController from '../../lib/BaseController';
import Utils from '../utility/Utils';
import Validator from '../../lib/Validator';
import AppConstants  from '../utility/AppConstants';

class CustDescDimensionRowController extends BaseController {
  constructor(parentController, dimension, custDescId) {
    super('cust-desc-dimension-reading-row-controller-' + CustDescDimensionRowController.key++, {
      custDescId                 : custDescId,
      manDescDimensionId         : dimension ? dimension.manDescDimensionId         : Utils.emptyGuid,
      custDescManDimensionId     : dimension ? dimension.custDescManDimensionId     : Utils.emptyGuid,
      dimensionId                : dimension ? dimension.dimensionId                : '',
      readingType                : dimension ? dimension.readingType                : AppConstants.ReadingType.Customer,
      minimumDimension           : dimension ? dimension.minimumDimension           : '',
      customerMinimumDimension   : dimension ? dimension.customerMinimumDimension   : '',
      customerSelected           : dimension ? dimension.readingType === AppConstants.ReadingType.Manufacturer ? dimension.customerSelected  : true : true,
      errors                     : {},
      newDimension               : dimension ? dimension.newDimension           : '',
      dimensionLocation          : dimension && dimension.dimensionLocationId != Utils.emptyGuid ? {id: dimension.dimensionLocationId, name: dimension.dimensionLocationName}  : '',
    });
    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('dimensionId',        'ID',               [{required: true}]);
    this._validator.add('newDimension', 'New Dimension',    [{required: true}]);
    this._validator.add('minimumDimension', 'Minimum Dimension',    [{required: true}]);
  }

  static key = 1;

  customerSelectedChanged = () => {
    if (!this.state.customerSelected) {
      this.state.customerMinimumDimension = 0;
    }
    this.commit();
    this.parentController.commit();
  }


  canRemoveRow = () => {
    return this.state.readingType === AppConstants.ReadingType.Customer;
  }

  removeRow = () => {
    if (!this.canRemoveRow()) {
      return;
    }
    this.parentController.removeDimensionRow(this);
  }

}
    
export default CustDescDimensionRowController;
  