import React, { useMemo } from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import Searchers from '../utility/Searchers';
import Grid from '../../components/Grid';
import ActionIcon from '../../components/ActionIcon';

const TechnicalDocTable = (props) => {

  const getCellProps = (cell) => {
    let style = {};
    if (cell && cell.column.id === 'action') {
      style = {
        width: '20px'
      };
    }
    if (cell && cell.column.id === 'state.Document_Name') {
      style = {
        width: '200px'
      };
    }
    if (cell && cell.column.id === 'state.Document_Link') {
      style = {
        width: '450px'
      };
    }
    const rowProps = { style: style };
    return rowProps;

  }
  const controller = props.controller;
  const data = props.controller.state.technicalDocuments;
  const isReadOnly = props.controller.state.isReadOnly;
  const binder = props.binder;

  const EditableColumns = useMemo(() => [
    {
      Header: '',
      accessor: 'action',
      isVisible: !isReadOnly,
      Cell: (cellInfo) => {
        return !isReadOnly
          ? <ActionIcon icon='fa-trash' className='remove-handle' action={() => { controller.removeLink(cellInfo.row.index) }} />
          : <span></span>;
      }
    },
    {
      Header: 'Document Name',
      accessor: 'state.Document_Name',
      Cell: (cellInfo, binder) => {
        return isReadOnly ? <div style={{ textAlign: "left", width: "100%" }}><a href={cellInfo.row.original.state.Document_Link} target='_blank'>{cellInfo.row.original.state.Document_Name}</a> </div> : <span><Form.Input label='' className='required' type='text' bind={binder.bind('Document_Name')} maxLength="30" /></span>
      }
    },
    {
      Header: 'Document Link',
      accessor: 'state.Document_Link',
      isVisible: !isReadOnly,
      Cell: (cellInfo, binder) => {
        return <div><Form.Input label='' className='required' type='text' bind={binder.bind('Document_Link')} /></div>
      }
    }
  ], [])

  return (<div id='service-level-results'>
    <form role='form'>
      <Grid id='results-table' getCellProps={getCellProps} columns={EditableColumns} data={data} editable={!isReadOnly} canAddNewRow={false} showPagination={isReadOnly} noDataText='No Technical Documents Added' />
    </form>
  </div>);
}
class PSMDetailsDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();
  handleCloseTechnicalDocument = (result) => {
    const controller = this.props.controller;
    if (result.Add) {
      return controller.addNewLink();
    }
    else if (result.Save) {
      return controller.saveLink();
    }
    else {
      return true;
    }
  }
  handleClear = () => this.props.controller.clear();

  render() {
    var binder = new Binder(this);
    var controller = this.props.controller;
    var errors = controller.state.errors;
    const psmLink = controller.state.psmLink;
    const isTechnicalDoc = controller.state.isTechnicalDoc;
    const isReadOnly = controller.state.isTechnicalDoc && controller.state.isReadOnly;
    const enableSave = controller.state.psmNumber !== null && controller.state.psmNumber?.length > 0 && psmLink !== null && psmLink?.length > 0;
    const displayClearButton = controller.state.displayClearButton;
    const clearButton = displayClearButton ? <div className='close-btn'><Button icon='' variant='secondary' size='sm' id='btn-save' className='' onClick={this.handleClear} disabled={false}> Clear</Button></div> : null;
    var psmLinkField = displayClearButton
      ? <Form.StaticData label='PSM Link' value={psmLink} />
      : <Form.Input bind={binder.bind('psmLink')} label='PSM Link' error={errors.psmLink} />
    var serviceLevelField = <div><Form.AutoComplete label='Service Level' id='service-level' allowClear={true} className='' bind={binder.bind('serviceLevelId')} search={Searchers.searchServiceLevelsForCustomer(controller.state.customerId)} /></div>
    return (
      !isTechnicalDoc ?
        <Dialog {...this.props} header={this.props.header} width={650} height={430} id='psm-details-dlg' onClose={this.handleClose} className='psm_dialog'>
          <Dialog.Body>
            <div className='col-md-12'>
              <Form.Input bind={binder.bind('psmNumber')} label='PSM Number' error={errors.psmNumber} />
              <div>
                {psmLinkField}
                {clearButton}
                {serviceLevelField}
              </div>
            </div>
          </Dialog.Body>
          <Dialog.Buttons>
            <Dialog.Button variant='primary' size='sm' className='btn' icon='' saving={!enableSave} result={{ Save: true }}>Save</Dialog.Button>
            <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Cancel: true }}>Close</Dialog.Button>
          </Dialog.Buttons>
        </Dialog> :
        <Dialog {...this.props} header={this.props.header} width={isReadOnly ? 600 : 850} height={430} id='psm-details-dlg' onClose={this.handleCloseTechnicalDocument} className='psm_dialog'>
          <Dialog.Body>
            <div className='col-md-12'>
              <TechnicalDocTable controller={controller} binder={binder} />
            </div>
          </Dialog.Body>
          <Dialog.Buttons>
            {!isReadOnly ? <Dialog.Button variant='success' size='sm' className='btn' icon='' result={{ Add: true }}>Add New Link</Dialog.Button> : <></>}
            {!isReadOnly ? <Dialog.Button variant='primary' size='sm' className='btn' icon='' result={{ Save: true }}>Save</Dialog.Button> : <></>}
            <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Cancel: true }}>Close</Dialog.Button>
          </Dialog.Buttons>
        </Dialog>
    );
  }
}

export default PSMDetailsDialog;