import React from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import co from '../../lib/Co';

class AssemblyOperationTestNotesDialog extends React.Component {

  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();


  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const errors = controller.state.errors;

    return (
      <Dialog {...this.props} model={true} allowKeyDownEvent={true} className='inspection-notes-dialog' header='Notes' width={500} height={250} onClose={this.handleClose}>
        <Dialog.Body>
          <div className='notes-content'>
            <Form.TextArea id='notes' rows={5} bind={binder.bind('notes')} maxLength='1000' />
          </div>
        </Dialog.Body>
        <Dialog.SaveCancelButtons />
      </Dialog>
    );
  }
}

export default AssemblyOperationTestNotesDialog;
