import Moment from 'moment';

const minDate              = '01-Jan-0001';
const minDateTime          = '01-Jan-0001 12:00 am';
const minDateTime2         = '01-Jan-1900 12:00 am';
const minDropdownDate      = '01-Jan-0001';

const DateTimeFormats = {
    DATE_DROPDOWN_FORMAT : 'DD-MMM-YYYY',
    DATE_FORMAT          : 'DD-MMM-YYYY',
    DATETIME_FORMAT      : 'DD-MMM-YYYY h:mm a',
    DATE_FILENAME_FORMAT : 'DDMMMYYYY',
    FULL_DATETIME_FORMAT : 'ddd DD MMM YYYY h:mm a',
    TIME_FORMAT          : 'h:mm a'
};

const dayBeforeYesterday = () => {
  return Moment().subtract(2, 'd').startOf('day');
}

const yesterday = () => {
  return Moment().subtract(1, 'd').startOf('day');
}

const today = () => {
  return Moment().startOf('day');
}

const tomorrow = () => {
  return Moment().add(1, 'd').startOf('day');
}

const dayAfterTomorrow = () => {
  return Moment().add(2, 'd').startOf('day');
}

const getLocal = (value) => {
    return Moment(value);
}

const now = () => {
  return Moment();
}

const isMinDateTime = (value) => {
    return _isMinDateTime(Moment.isMoment(value) ? value.format(DateTimeFormats.DATETIME_FORMAT) : Moment(value).format(DateTimeFormats.DATETIME_FORMAT));
}
  
const _isMinDateTime = (value) => {
    return ((value === minDateTime) || (value === minDateTime2));
}

const getMoment = (value) => {
    if (Moment.isMoment(value)) {
      return value;
    }
    const momentValue = Moment(value);
    return momentValue;
}

const formatDate = (date) => {
    if (!date) {
      return '';
    }
  
    const formattedDate = Moment(date).format(DateTimeFormats.DATE_FORMAT);
    if (formattedDate === minDate) {
      return '';
    }
    return getMoment(date).format(DateTimeFormats.DATE_FORMAT);
}

const formatDateTime = (date) => {
    if (!date) {
      return '';
    }
  
    const formattedDateTime = Moment.isMoment(date) ? date.format(DateTimeFormats.DATETIME_FORMAT) : Moment(date).format(DateTimeFormats.DATETIME_FORMAT);
    if (_isMinDateTime(formattedDateTime)) {
      return '';
    }
    const value = Moment.isMoment(date)
            ? date.format(DateTimeFormats.DATETIME_FORMAT)
            : getMoment(date).format(DateTimeFormats.DATETIME_FORMAT);
    return value;
  }
  
const formatFullDateTime = (date) => {
    if (!date) {
      return '';
    }
  
    const formattedDateTime = Moment.isMoment(date) ? date.format(DateTimeFormats.DATETIME_FORMAT) : Moment(date).format(DateTimeFormats.DATETIME_FORMAT);
    if (_isMinDateTime(formattedDateTime)) {
      return '';
    }
    const value = Moment.isMoment(date)
            ? date.format(DateTimeFormats.FULL_DATETIME_FORMAT)
            : getMoment(date).format(DateTimeFormats.FULL_DATETIME_FORMAT);
    return value;
}

const formatLocalTime = (value) => {
    return getMoment(value).format(DateTimeFormats.DATETIME_FORMAT);
}
  
const formatTime =  (value) => {
    var formattedDateTime = Moment.isMoment(value) ? value.format(DateTimeFormats.DATETIME_FORMAT) : Moment(value).format(DateTimeFormats.DATETIME_FORMAT);
    if (_isMinDateTime(formattedDateTime)) {
      return '';
    }
    return getMoment(value).format(DateTimeFormats.TIME_FORMAT);
} 
  
const fromNowTime = (value) => {
    return getMoment(value).fromNow();
}
  
const formatDropdownDate = (date) => {
    if (!date) {
      return '';
    }
  
    const formattedDate = getMoment(date).format(DateTimeFormats.DATE_DROPDOWN_FORMAT);
    return (formattedDate === minDropdownDate) ? '' : formattedDate;
}

const formatFileNameDate = (date) => {
  if (!date) {
    return '';
  }

  const formattedDate = getMoment(date).format(DateTimeFormats.DATE_FILENAME_FORMAT);
  return (formattedDate === minDropdownDate) ? '' : formattedDate;
}
  
const formatDuration = (totalMinutes, includeSeconds) => {
    const MINUTES_PER_DAY = (60 * 24);
  
    includeSeconds = !!includeSeconds;
  
    const    days   = parseInt((totalMinutes / MINUTES_PER_DAY), 10);
    totalMinutes -= (days * MINUTES_PER_DAY);
  
    const hours     = parseInt((totalMinutes / 60), 10);
    totalMinutes -= (hours * 60);
  
    const seconds   = parseInt((60 * (totalMinutes - parseInt(totalMinutes, 10))), 10);
    const minutes   = parseFloat(totalMinutes).toFixed(1);
  
    var s;
    if (days > 0) {
      s = days + 'd ' + hours + 'h ' + minutes + 'm ' + (includeSeconds ? seconds + 's' : '');
    }
    else if (hours > 0) {
      s = hours + 'h ' + minutes + 'm ' + (includeSeconds ? seconds + 's' : '');
    }
    else if (minutes > 0) {
      s = minutes + 'm ' + (includeSeconds ? seconds + 's' : '');
    }
    else {
      s = (includeSeconds ? seconds + 's' : '');
    }
    return s;
}

const getDuration = (from,to) =>{
  var duration = Moment.duration(getMoment(to).diff(getMoment(from)));
  var Minutes = duration.asMinutes();
  Minutes = parseFloat(Minutes).toFixed(1);
  return Minutes;
}

const DateTimeFormatter = {
  dateTimeFormats     : DateTimeFormats,
  isMinDateTime       : isMinDateTime,
  minDate             : minDate,
  minDateTime         : minDateTime,
  minDropdownDate     : minDropdownDate,
  formatDate          : formatDate,
  formatDateTime      : formatDateTime,
  formatFullDateTime  : formatFullDateTime,
  formatDropdownDate  : formatDropdownDate,
  formatDuration      : formatDuration,
  formatLocalTime     : formatLocalTime,
  formatTime          : formatTime,
  fromNowTime         : fromNowTime,
  getMoment           : getMoment,
  getLocal            : getLocal,
  dayBeforeYesterday  : dayBeforeYesterday,
  yesterday           : yesterday,
  today               : today,
  tomorrow            : tomorrow,
  dayAfterTomorrow    : dayAfterTomorrow,       
  now                 : now,
  getDuration         : getDuration,
  formatFileNameDate  : formatFileNameDate 
}

export default DateTimeFormatter;