import _   from 'immutable';
import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';
import AppConstants  from '../utility/AppConstants';

class ThicknessReadingRowController extends BaseController {
  constructor(parentController, thickness) {
    super('dimension-row-controller-' + ThicknessReadingRowController.key++, {
      thicknessReadingId        : thickness ? thickness.thicknessReadingId        : Utils.emptyGuid,
      readingSourceId           : thickness ? thickness.readingSourceId           : Utils.emptyGuid,  
      minimumDimension          : thickness ? thickness.minimumDimension          : 0,
      newDimension              : thickness ? thickness.newDimension              : 0,
      readingId                 : thickness ? thickness.readingId                 : 0,
      readingValue              : thickness ? thickness.readingValue              : 0,
      readingType               : thickness ? thickness.readingType               : 0,
      recordStatus              : thickness ? thickness.recordStatus              :'',
      errors                    : {}
    });

    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('readingValue', 'Reading Value', [{required: true}]);
  }

  static key = 1;

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
  }

  readingValueChanged = () => {
    this.state.recordStatus = AppConstants.RecordStatus.Modified;
    this.commit();
  }

}

export default ThicknessReadingRowController;