import React from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';

class PickJobDialog extends React.Component{
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }  

    handleClose = (result) =>  result.Cancel ? true : this.props.controller.save();
    
    render = () => {
        const binder        = new Binder(this);
        const controller    = this.props.controller;
        const dropoffNumber = controller.state.dropoffNumber;
        const message       = 'Select the job to which you wish to attach Drop-Off form #' + dropoffNumber;
    
        return (
          <Dialog {...this.props} header={this.props.header} width={620} height={230} id='pick-job-dlg' onClose={this.handleClose}>
            <Dialog.Body>
              <div className='row'>
                <div className='col-12 my-2'> <span className='center h5'>{message}</span></div>
                <div className='col-12'> <Form.AutoComplete bind={binder.bind('selectedJob')} placeholder='Select a job' search={controller.jobSearch()} /></div>
              </div>
            </Dialog.Body>
            <Dialog.OKCancelButtons />
          </Dialog>
        );
    }
}

export default PickJobDialog;