import React from 'react';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Form from '../../components/Form';
import Keypad from '../../components/Keypad';
import Searchers from '../utility/Searchers';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import SAPService from '../services/SAPService';
import AppConstants from '../utility/AppConstants';
import Roles from '../utility/Roles';

class InspectionPassedDialog extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
    //this.props.controller.getInspectionNotes();
  }

  handleClose = (result) => result.Cancel ? true : this.props.controller.save();

  handleKeyPressOnDate = (evt) => {
    return false;
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const isSAPFacility = SAPService.isSAPFacility();
    const isOSRepair = controller.state.isOSRepair;
    const searchController = controller.searchController;
    const assetCount = controller.state.assets.length;
    const testName = controller.state.testName;
    const value = binder.bind('inspectionMinutes')();
    const zeroTimeEnabled = !!controller.state.vendor;
    const errors = controller.state.errors;
    const title = isOSRepair
      ? <h5 className='col-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '5px' }}>Please confirm you wish to OS/Machining repair the <span className='font-weight-bold text-primary'>{testName}</span> operation for the {Utils.pluralize('selected asset', 's', assetCount)}</h5>
      : <h5 className='col-12' style={{ borderBottom: 'solid 1px #d8d8d8', paddingBottom: '5px' }}>Please confirm you wish to pass the <span className='font-weight-bold text-primary'>{testName}</span> operation for the {Utils.pluralize('selected asset', 's', assetCount)}</h5>
    const msg = <h6>Last chance to record some time against {(assetCount === 1 ? 'this ' : 'these ')} {Utils.pluralize(' asset', 's', assetCount, false)}</h6>;
    const facilityId = LoginService.loginInfo.facilityId;
    const fldTechnician = isSAPFacility
      ? <Form.AutoComplete label='Technician' bind={binder.bind('technician')} search={Searchers.activeUsersForSapFacilityWithEmployeeId(facilityId)} />
      : <Form.AutoComplete label='Technician' bind={binder.bind('technician')} search={Searchers.activeUsersForFacility(facilityId)} />;
    const notes = controller.state.assets.length === 1
      ? <div className='col-md-12'><Form.TextArea id='notes' label='Notes' bind={binder.bind('notes')} maxLength='1000' /></div>
      : null;
    var backDatingNote = LoginService.loginInfo.role === Roles.Coordinator
      ? <div className='backdating-note-message'>{AppConstants.BackDatingNote}</div>
      : null;

    let formatResult = function (item) {
      let vendorBackgroundColor = '';
      if (item.result.type !== undefined) {
        switch (item.result.type.toLowerCase()) {
          case '3rd party':
            vendorBackgroundColor = 'success';
            break;

          case 'customer':
            vendorBackgroundColor = 'info';
            break;

          default:
            break;
        }
        if (vendorBackgroundColor !== '') {
          return <span>{item.text}<Badge variant={vendorBackgroundColor}  className='float-right result-span'>{item.result.type}</Badge></span>;
        }
        return <span>{item.text}</span>;
      }
      return <span>{item.text}</span>;
    };

    // var showGuageDropdown = false;
    // if(testName === 'Mag Particle Inspection (MPI)' 
    // || testName === 'Thickness (UT)' 
    // || testName === 'Gauge' 
    // || testName === 'Pressure Test') {
    //   showGuageDropdown = true;
    // }
    const familyCode = controller.state.assets[0].familyCodeId;
    return (
      <Dialog {...this.props} allowKeyDownEvent={true} className='pass-dialog' header={this.props.header} modal={true} width={625} height={550} id='pass-test-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <form role='form' id='pass-test-dlg-form'>
            <div className='row'>
                {title}
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-6' style={{ borderRight: 'solid 1px #D8D8D8' }}>
                    <div className='text-left'>{msg}</div>
                    <div className='text-center keypad-value my-3'>{value} min</div>
                    <Keypad className='text-center inspection-keypad my-3' bind={binder.bind('inspectionMinutes')} allowDecimal={true} minValue={0} maxValue={720} enabled={!controller.state.recordZeroTime} />
                  </div>
                  <div id='pass-dialog-right-div' className='col-md-6'>
                    {LoginService.isCoordinator && !SAPService.useTTS() &&
                      <div className='col-md-12'>
                        {fldTechnician}
                        <Form.Date display='inline-block' label='Inspection Date:' bind={binder.bind('inspectionDate')} error={errors.inspectionDate} onKeyPress={this.handleKeyPressOnDate} />
                      </div>
                    }
                    {isSAPFacility && isOSRepair && <div className='col-md-12'>
                      <Form.Date className='required' display='inline-block' label='Scheduled Completion Date:' bind={binder.bind('completionDate')} error={errors.completionDate} onKeyPress={this.handleKeyPressOnDate} />
                    </div>
                    }
                    {isSAPFacility && isOSRepair && <div className='col-md-12'>
                      <Form.AutoComplete label='Reject Reason' bind={binder.bind('rejectReason')} error={errors.rejectReason} search={Searchers.rejectReasonSearch(familyCode)} className='required' />
                    </div>
                    }
                    <div className='col-md-12' style={{ marginTop: '20px' }}>
                      <Form.AutoComplete formatResult={formatResult} formatSelection={formatResult} label='3rd party Vendor / Customer' hint='optional' bind={binder.bind('vendor')} search={searchController.vendorSearch()} />
                    </div>
                    {/* {
                // showGuageDropdown ? 
                // <div className='col-sm-12' style={{marginTop: '20px'}}>
                //   <Form.AutoComplete formatResult={formatResult} formatSelection={formatResult} 
                //   label='Gauges / Part No' hint='optional' bind={binder.bind('guageSerPrim')} 
                //   search={searchController.guageSerPrimData()} />
                // </div> 
                // : null
              } */}
                    {!SAPService.useTTS() && <div className='col-md-12'><Form.CheckBox label='Record 0 time' bind={binder.bind('recordZeroTime')} enabled={zeroTimeEnabled} disabled={!zeroTimeEnabled} /></div>}
                    {notes}
                    {!SAPService.useTTS() && backDatingNote}
                  </div>
                </div>
              </div>
              <div id='attachmentPopup' />
            </div>
          </form>
        </Dialog.Body>
        <Dialog.SaveCancelButtons saving={this.props.controller.saving} />
      </Dialog>
    );
  }
}

export default InspectionPassedDialog;
