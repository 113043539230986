import BaseController from '../../lib/BaseController';
import notifications from '../../services/Notification';
import Validator from '../../lib/Validator';
import Utils from '../utility/Utils';

class InspectionTestNotesController extends BaseController {
  constructor(testName) {
    super('inspection-test-notes', {
      notesId           : Utils.emptyGuid,
      jobId             : '',
      testName          : testName,
      assetId           : '',
      notes             : '' ,
      errors            : {},
    });

    this._validator = new Validator();
  }

  canSave() {
    this._validator.validateAll(this.state);

    var errors = Object.keys(this.state.errors);    

    if (!errors.length) {
      return true;
    }

    this.commit();
    return false;
  }

  *save() {
    try {
      if (!this.canSave()) {
        return;
      }
      var s          = this.state;
      var parameters = {
        notesId           : s.notesId,
        jobId             : s.jobId,
        testName          : s.testName,
        assetId           : s.assetId,
        notes             : s.notes
      };

      // yield ajax.post('inspection/addTestNotes', parameters);
      // notifications.action('success').post('Notes Saved successfully');
      // messages.channel('reload-test-results').action('saved').post();
      return true;
    }
    catch (err) {
      notifications.action('error').post(err.message);
      return false;
    }
  }
}

export default InspectionTestNotesController;