import React from 'react';
import Controller from '../../mixins/Controller';
import Dialog from '../../components/Dialog';
import Utils from '../utility/Utils';
import JobSowGroupRow from '../pages/JobSowGroupRow';
import Table from '../../components/Table';

const JobSowGroupsList = (props) => {

    const controller = props.controller;
    const rows = controller.state.groups.map(group => <JobSowGroupRow key={group.id} controller={group} />);
    const groupValues = controller.state.groups.length == 0 ?
        <Table.Row><Table.Data>No Records Found</Table.Data></Table.Row> : rows;



    return (
        <div className='col-md-12'>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.Head>Group Number</Table.Head>
                        <Table.Head>Group Name</Table.Head>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {groupValues}
                </Table.Body>
            </Table>
        </div>
    );
}

class JobProgressDetailsGroupDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    handleClose = (result) => result.Cancel || result.OK ? true : this.props.controller.saveGroupName();

    render = () => {
        const controller = this.props.controller;
        return (
            <Dialog {...this.props} modal={true} header={this.props.header} width={600} height={640} id='renameGroups' onClose={this.handleClose} >
                <Dialog.Body>
                    <JobSowGroupsList controller={controller} />
                </Dialog.Body>
                <Dialog.SaveCancelButtons saving={controller.saving} />
            </Dialog>
        );
    }
}

export default JobProgressDetailsGroupDialog;
