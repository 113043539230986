import co from './Co';
import {SortType} from './DataSorter';
import Messages from '../services/Messages';

class BaseController {
  constructor(channel, state) {
    this.state = (state || {});
    this.prevState = (state || {});
    this.channel = Messages.channel(channel);
  }

  shouldYield = (fn) =>{
    return (fn && fn.next && fn.throw);
  }

  handlePropertyChanged = (data) => {
    co(this, function* () {
      var path = data.path;
      if (!path || (path.length === 0)) {
        return;
      }

      var property = path[path.length - 1];
      var handleChanged = this[property + 'Changed'];
      var fn;
      this._data = data;
      if (handleChanged) {
        fn = handleChanged.call(this, data);
        if (this.shouldYield(fn)) {
          yield fn;
        }
      }
      else if (this.unhandledPropertyChanged) {
        fn = this.unhandledPropertyChanged.call(this, data);
        if (this.shouldYield(fn)) {
          yield fn;
        }
      }
      this.commit();
    });
  }

  clearSort = () => {
    if (this.state.sortState) {
      this.state.sortState = {
        sortDir : SortType.ASC,
        sortKey : '',
      };
      this.commit();
    }
  }

  commit = () => {
    this.channel.action('commit').post(this.state);
  }

  getState = () =>{
    return this.state;
  }
}

export default BaseController;