import BaseController from '../../lib/BaseController';
import Validator from '../../lib/Validator';

class PressureTestRowController extends BaseController {
  constructor(parentController, channelPrefix, setting) {
    super(channelPrefix + 'pressure-test-row-controller-'+ PressureTestRowController.key++, {
      ptStepId                    : setting ? setting.ptStepId             : '',
      ptId                        : setting ? setting.ptId                 : '',
      stepId                      : setting ? setting.stepId               : 0,
      customerId                  : setting ? setting.customerId           : '',
      familyCodeId                : setting ? setting.familyCodeId         : '',
      duration                    : setting ? setting.duration             : 0,
      holdPressure                : setting ? setting.holdPressure         : 0,
      allowableDrop               : setting ? setting.allowableDrop        : 0,
      maxAllowablePressure        : setting ? setting.maxAllowablePressure : 0,
      testMode                    : setting ? setting.testMode             : '',
      restrictDrop                : setting ? setting.restrictDrop         : false,    
      allowableDropPercent        : '',
      maxAllowablePressurePercent : '',
      errors                      : {}
    });

    this.recalcPercentages();
    this.parentController = parentController;
    this._validator       = new Validator();
    this._validator.add('stepId'               , 'Step ID'                , [{required: true}, {isNumeric: true}, {minValue: {params: 1}}]);
    this._validator.add('duration'             , 'Duration (Time)'        , [{required: true}, {isNumeric: true}, {minValue: {params: 0}}]);
    this._validator.add('holdPressure'         , 'Hold Pressure (PSI)'    , [{required: true}, {isNumeric: true}, {minValue: {params: 0}}]);
    this._validator.add('allowableDrop'        , 'Allowable Drop (PSI)'   , [{required: true}, {isNumeric: true}, {minValue: {params: 0}}]);
    this._validator.add('maxAllowablePressure' , 'Max Allowable Pressure' , [{required: true}, {isNumeric: true}, {minValue: {params: 0}}]); // maxValue: greater than holdPressure?
    this._validator.add('testMode'             , 'Test Mode'              , [{required: true}]);
  }

  static key = 1;

  canRemoveRow = () => {
    return true;
  }

  holdPressureChanged = (data) => {
    this.state.holdPressure = this.convertToValue(data.new);
    this.validateBetweenFields();
    this.recalcPercentages();
  }

  convertToValue = (value) => {
    const newValue = parseFloat(value, 10);
    return isNaN(newValue) ? 0 : newValue;
  }

  allowableDropChanged = (data) => {
    const value = this.convertToValue(data.new);
    if (data.new.endsWith('%')) {
      this.state.allowableDrop = (value.toFixedDown(1) / 100 * this.state.holdPressure).toFixed(0);
    }
    else {
      this.state.allowableDrop = parseInt(value,10);
    }
    this.recalcPercentages();
  }

  maxAllowablePressureChanged = (data) => {
    const value = this.convertToValue(data.new);
    if (data.new.endsWith('%')) {
      this.state.maxAllowablePressure = (value.toFixedDown(1) / 100 * this.state.holdPressure).toFixed(0);
    }
    else {
      this.state.maxAllowablePressure = parseInt(value,10);
    }
    this.recalcPercentages();
  }

  recalcPercentages = () => {
    if (this.state.holdPressure === 0) {
      this.state.allowableDropPercent        = '';
      this.state.maxAllowablePressurePercent = '';
    }
    else {
      this.state.allowableDropPercent        = (this.state.allowableDrop * 100 / this.state.holdPressure).toFixed(1);
      this.state.maxAllowablePressurePercent = (this.state.maxAllowablePressure * 100 / this.state.holdPressure).toFixed(1);
    }
    this.validateBetweenFields();
  }

   /* restrictDropChanged = () => {
        this.state.holdPressure = this.state.restrictDrop ? this.state.holdPressure + this.state.allowableDrop :
            this.state.holdPressure - this.state.allowableDrop;
    }*/
  removeRow = () => {
    if (this.canRemoveRow()) {
      this.parentController.removePressureTestSettingRow(this);
    }
  }

  validateBetweenFields = () => {
    delete this.state.errors.holdPressure;
    delete this.state.errors.allowableDrop;
    delete this.state.errors.maxAllowablePressure;

    if (this.state.allowableDrop > this.state.holdPressure) {
      this.state.errors.allowableDrop = 'Allowable Drop (PSI) must be <= Hold Pressure';
      this.state.errors.holdPressure = 'Hold Pressure (PSI) must be >= Allowable Drop';
      this.commit();
    }

    if (this.state.maxAllowablePressure <= this.state.holdPressure) {
      this.state.errors.maxAllowablePressure = 'Max Allowable Pressure (PSI) must be > Hold Pressure';
      this.state.errors.holdPressure = 'Hold Pressure (PSI) must be < Max Allowable Pressure';
      this.commit();
    }
  }

  validate = () => {
    this.state.errors = {};
    this._validator.validateAll(this.state);
    this.validateBetweenFields();
  }
}
    
export default PressureTestRowController;
  