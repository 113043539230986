import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';

class PickJobController extends BaseController {
  constructor(dropoffForm) {
    super('pick-job', {
      dropoffId: dropoffForm.dropoffId,
      dropoffNumber: dropoffForm.dropoffNumber,
      customerId: dropoffForm.customerId,
      facilityId: dropoffForm.facilityId,
      selectedJob: ''
    });
  }

  jobSearch = () => {
    const facilityId = this.state.facilityId;
    const customerId = this.state.customerId;
    return (searchTerm) => ajax.get('lookup/searchActiveJobs', {
      facilityId: facilityId,
      customerId: customerId,
      searchTerm: searchTerm
  }).then(result => result).catch(err => {
    notification.action('error').post({ msg: err.message, title: 'Error' });
});
  }

  save = () => {
    const dropoffId = this.state.dropoffId;
    const dropoffNumber = this.state.dropoffNumber;
    const jobId = this.state.selectedJob.id;
    const jobNumber = this.state.selectedJob.name;

    const parameters = {
      dropoffId: dropoffId,
      jobId: jobId
    };

    this.state.saving = true;
    this.commit();
    return ajax.post('dropoff/attachToJob', parameters).then(() => {
      this.state.saving = false;
      this.commit();
      notification.action('success').post('Attached Drop-Off Form #' + dropoffNumber + ' to Job #' + jobNumber);
      messages.channel('edit-dropoff').action('saved').post(dropoffId);
      return true;
    }).catch(err => {
      this.state.saving = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // in: AttachDropoffToJobDto, out: void
  }
}

export default PickJobController;