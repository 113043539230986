import React, { useMemo, useEffect } from 'react';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Authentication from '../services/authentication';
import Button from '../../components/Button';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import ReleaseNotesController from '../controllers/ReleaseNotesController';
import Badge from '../../components/Badge';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import LoginService from '../services/LoginService';
import Utils from '../utility/Utils';
import Search from '../components/Search';
import Grid from '../../components/Grid';

const SearchHeader = (props) => {

  const handleMouseSearch = () => {
    props.controller.searchReleaseNotes();
  }

  const clearSearch = () => {
    props.controller.clearSearch();
  }

  const binder = props.binder;
  const controller = props.controller;
  const placeholder = 'notes'; // 'number, creation date, application, or category';

  return (
    <div className='row align-items-center' {...props} id='search-criteria'>
      <div className='col-md-6'>
        <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' label='Enter search text' placeholder={placeholder} autoFocus='true' bind={binder.bind('searchText')} clear={clearSearch} search={handleMouseSearch}></Search>
      </div>
      <div className='col-md-6  d-flex justify-content-center add-notes'>
        {LoginService.isAdministrator && <div><Button to={'/addreleasenotes'} icon='fa-plus-circle' variant='success' size='sm' className='m-1'> Add Notes </Button></div>}
      </div>
    </div>
  );
}

const ResultsList = (props) => {
  const controller = props.controller;
  const data = controller.state.searchResults;
  const matchesCount = controller.state.searchResults.length;
  const matchesLabel = Utils.pluralize('match', 'es', matchesCount);

  const columns = useMemo(
    () => [
      {
        Header: 'Released Date',
        accessor: 'date',
        Cell: cellInfo => LoginService.isAdministrator
          ? <Form.Link to={'/addreleasenotes/' + cellInfo.row.original.id} value={DateTimeFormatter.formatDate(cellInfo.cell.value)} />
          : <span>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</span>
      },
      {
        Header: 'Release Notes',
        accessor: 'note',
        Cell: (cellInfo) => cellInfo.cell.value.split("\n").map((item) => <span>{item}<br /></span>)
      },
    ],
    []
  )
  return (
    <div className=''>
      <div className='d-flex justify-content-end'>
        <div className='mt-2 mr-2'><Badge variant='info'>{matchesLabel}</Badge></div>
      </div>
      <Grid id='results-table' columns={columns} data={data} showPagination={false} noDataText='No Release notes meet the search criteria defined above'/>
    </div>
  );
}

const ReleaseNotesPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-file-text-o fa-flip-horizontal' showBackNavigation={true} />

class ReleaseNotesPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.searchReleaseNotes();
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new ReleaseNotesController()
  }

  render = () => {
    const controller = this.props.controller;
    const binder = new Binder(this);

    return (
      <Page {...this.props} pageTitle='InteServ · releaseNotes' id='releaseNotes-page'>
        <ReleaseNotesPageHeader pageTitle='Release Notes' />
        <Page.Content>
          <div className='row'>
            <div {...this.props} className='col-md-12'>
              <SearchHeader controller={controller} binder={binder} />
              <ResultsList controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default ReleaseNotesPage;