import React from 'react';
import {Tabs as RBTabs, TabContainer as RBTabContainer} from 'react-bootstrap';

const Tabs = (defaultProps) => {

  const props = {
    variant: 'tabs',
    transition: false,
    ...defaultProps
  }
  
  return <RBTabs {...props}>{props.children}</RBTabs>;
}

export default Tabs;